import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  slsOrdFulfillmentChartResetTimestamp: ['criteria'],

  slsOrdFulfillmentChartFetchIsLoading: ['boolean'],
  slsOrdFulfillmentChartFetch: ['criteria'],
  slsOrdFulfillmentChartFetchSuccess: ['data'],

  slsOrdFulfillmentChartChangeProcess: ['formikBag', 'process'],

  slsOrdFulfillmentChartSetVisibleAverageKeys: ['visibleKeys']
});

export const SlsOrdFulfillmentChartTypes = Types;
export default Creators;
