import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import treeChanges from 'tree-changes';

import { Card, Tabs, Tooltip, Spin } from 'antd';

import InvAuditProcessActions from '../../Stores/InvAuditProcess/Actions';

import CycleCount03Table from '../CycleCount03/CycleCount03Table';
import WhseJob1601Table from '../WhseJob1601/WhseJob1601Table';
import WhseJob160102Table from '../WhseJob160102/WhseJob160102Table';
import CycleCount02Table from '../CycleCount02/CycleCount02Table';
import CountAdj01Table from '../CountAdj01/CountAdj01Table';
import CountAdjSync01Table from '../CountAdjSync01/CountAdjSync01Table';
import CountAdjSync02Form from '../CountAdjSync02/CountAdjSync02Form';
import CountAdjFServ01Table from '../CountAdjFServ01/CountAdjFServ01Table';
import CountAdjFServ02Form from '../CountAdjFServ02/CountAdjFServ02Form';

const { TabPane } = Tabs;

class InvAuditProcessScreen extends React.PureComponent {
  componentDidMount() {
    const { curSiteFlowId, fetchInvAuditProcess } = this.props;
    if (curSiteFlowId > 0) {
      fetchInvAuditProcess();
    }
  }

  componentDidUpdate(prevProps) {
    const { curSiteFlowId, fetchInvAuditProcess } = this.props;

    const { changed } = treeChanges(prevProps, this.props);

    if (changed('curSiteFlowId')) {
      if (curSiteFlowId > 0) {
        fetchInvAuditProcess();
      }
    }
  }

  componentWillUnmount() {}

  render() {
    const { intl, match, process, appPath, processIsLoading } = this.props;

    return (
      <Card>
        <Spin spinning={processIsLoading}>
          <Tabs type="card" activeKey={match.params.proc_type}>
            {process.map(value => (
              <TabPane
                tab={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <Tooltip
                    placement="bottom"
                    title={intl.formatMessage({ id: `${value.proc_type.toLowerCase()}_desc` })}
                  >
                    <Link
                      style={value.proc_type !== match.params.proc_type ? { color: 'black' } : {}}
                      to={`${appPath}/invAuditProcess/index/${value.proc_type}`}
                    >
                      <FormattedMessage id={`${value.proc_type.toLowerCase()}_label`} />
                    </Link>
                  </Tooltip>
                }
                key={value.proc_type}
              />
            ))}
          </Tabs>
        </Spin>

        {match.params.proc_type === 'CYCLE_COUNT_03' && <CycleCount03Table match={match} />}
        {match.params.proc_type === 'WHSE_JOB_16_01' && <WhseJob1601Table match={match} />}
        {match.params.proc_type === 'WHSE_JOB_16_01_02' && <WhseJob160102Table match={match} />}
        {match.params.proc_type === 'CYCLE_COUNT_02' && <CycleCount02Table match={match} />}
        {match.params.proc_type === 'COUNT_ADJ_01' && <CountAdj01Table match={match} />}
        {match.params.proc_type === 'COUNT_ADJ_SYNC_01' && <CountAdjSync01Table match={match} />}
        {match.params.proc_type === 'COUNT_ADJ_SYNC_02' && <CountAdjSync02Form match={match} />}
        {match.params.proc_type === 'COUNT_ADJ_F_SERV_01' && <CountAdjFServ01Table match={match} />}
        {match.params.proc_type === 'COUNT_ADJ_F_SERV_02' && <CountAdjFServ02Form match={match} />}
      </Card>
    );
  }
}

InvAuditProcessScreen.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object,
  appPath: PropTypes.string,
  process: PropTypes.arrayOf(PropTypes.object),
  processIsLoading: PropTypes.bool,

  curSiteFlowId: PropTypes.number,
  fetchInvAuditProcess: PropTypes.func
};

InvAuditProcessScreen.defaultProps = {
  intl: {},
  match: {},
  appPath: '',
  process: [],
  processIsLoading: false,

  curSiteFlowId: 0,
  fetchInvAuditProcess() {}
};

const mapStateToProps = state => ({
  appPath: state.app.appPath,
  process: state.invAuditProcess.process,
  processIsLoading: state.invAuditProcess.processIsLoading,

  curSiteFlowId: state.app.curSiteFlowId
});

const mapDispatchToProps = dispatch => ({
  fetchInvAuditProcess: () => dispatch(InvAuditProcessActions.invAuditProcessFetchInvAuditProcess())
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(InvAuditProcessScreen));
