import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  storageBayDetailResetModelTimestamp: null,
  storageBayDetailSetResId: ['resId'],

  storageBayDetailInitModel: ['storageBayType'],
  storageBayDetailShowModel: ['resId'],
  storageBayDetailShowModelSuccess: ['model'],

  storageBayDetailUpdateModel: ['formikBag', 'model'],
  storageBayDetailCreateModel: ['formikBag', 'model'],
  storageBayDetailUpdateModelSuccess: ['model'],

  storageBayDetailShowModelLoading: ['boolean'],

  storageBayDetailFetchStorageRowOptions: ['search'],
  storageBayDetailFetchStorageRowOptionLoading: ['boolean'],
  storageBayDetailFetchStorageRowOptionSuccess: ['options']
});

export const StorageBayDetailTypes = Types;
export default Creators;
