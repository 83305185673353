import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import treeChanges from 'tree-changes';

import {
  ArrowLeftOutlined,
  CheckOutlined,
  CloseOutlined,
  CloudDownloadOutlined,
  FileSearchOutlined,
  FormOutlined,
  LaptopOutlined,
  RedoOutlined,
  ReloadOutlined,
  PlusSquareOutlined,
  MinusSquareOutlined
} from '@ant-design/icons';

import { Row, Col, Table, Popconfirm, Button, Badge, Card, Space } from 'antd';

import AntDesignTable from '../../Components/AntDesignTable';
import CycleCount02DetailActions from '../../Stores/CycleCount02Detail/Actions';
import AppActions from '../../Stores/App/Actions';
import UtilService from '../../Services/UtilService';

class DetailTable extends React.PureComponent {
  constructor() {
    super();

    this.useOnTableChange = this.useOnTableChange.bind(this);
    this.getDocumentColumns = this.getDocumentColumns.bind(this);

    this.handleSearch = this.handleSearch.bind(this);
    this.handleReset = this.handleReset.bind(this);

    this.useConfirmDtl = this.useConfirmDtl.bind(this);
    this.useDropDtl = this.useDropDtl.bind(this);
    this.useRecountDtl = this.useRecountDtl.bind(this);
    this.useResetRecountDtl = this.useResetRecountDtl.bind(this);

    this.useOnRefresh = this.useOnRefresh.bind(this);
    this.useOnAutoConfirm = this.useOnAutoConfirm.bind(this);
    this.useOnAutoGroupConfirm = this.useOnAutoGroupConfirm.bind(this);
    this.useOnDownloadCsv = this.useOnDownloadCsv.bind(this);
    this.useShowWorkspace = this.useShowWorkspace.bind(this);
    this.showExpandedRow = this.showExpandedRow.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    const {
      hdrId,
      showDetails,
      showDetailsSuccess,
      currentPage,
      sorts,
      filters,
      pageSize
    } = this.props;

    const { changed } = treeChanges(prevProps, this.props);

    if (changed('timestamp')) {
      if (hdrId === 0) {
        showDetailsSuccess([]);
      } else {
        showDetails(hdrId, currentPage, sorts, filters, pageSize);
      }
    }
  }

  componentWillUnmount() {}

  handleSearch(selectedKeys, confirm) {
    // eslint-disable-next-line no-unused-vars
    const { intl } = this.props;

    confirm();
  }

  handleReset(clearFilters) {
    // eslint-disable-next-line no-unused-vars
    const { intl } = this.props;

    clearFilters();
  }

  getDocumentColumns() {
    const { intl, sorts, filters } = this.props;

    return [
      {
        // fixed: 'left',
        width: 20,
        align: 'right',
        title: intl.formatMessage({ id: 'job' }),
        // sort field
        dataIndex: 'job_no',
        ...AntDesignTable.getColumnMultiSortProps(sorts, 'job_no'),
        // filter field
        key: 'job_no',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'job_no' }),
          'job_no',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.job_no}</>
      },
      {
        width: 50,
        align: 'left',
        title: intl.formatMessage({ id: 'storage_bin' }),
        // sort field
        dataIndex: 'storage_bin_code',
        ...AntDesignTable.getColumnMultiSortProps(sorts, 'storage_bin_code'),
        // filter field
        key: 'storage_bin_code',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'storage_bin' }),
          'storage_bin_code',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.storage_bin_code}</>
      },
      {
        width: 50,
        align: 'center',
        title: intl.formatMessage({ id: 'status' }),
        // sort field
        dataIndex: 'physical_count_status',
        // filter field
        key: 'physical_count_status',
        ...AntDesignTable.getColumnSearchPhysicalCountStatusProps(filters, 'physical_count_status'),
        render: (text, record) => (
          <>
            {record.str_physical_count_statuses.map(value => (
              <div
                key={value}
                style={{
                  color: UtilService.processStrPhysicalCountStatusColor(value),
                  fontWeight: 'bold'
                }}
              >
                {intl.formatMessage({ id: value })}
              </div>
            ))}
          </>
        )
      },
      {
        width: 50,
        align: 'right',
        title: intl.formatMessage({ id: 'balance_qty' }),
        // sort field
        dataIndex: 'balance_unit_qty',
        // filter field
        key: 'balance_unit_qty',
        render: (text, record) =>
          new Intl.NumberFormat([], {
            style: 'decimal',
            minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
            maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
          }).format(record.balance_unit_qty)
      },
      {
        width: 50,
        align: 'right',
        title: intl.formatMessage({ id: 'count_qty' }),
        // sort field
        dataIndex: 'ttl_count_unit_qty',
        // filter field
        key: 'ttl_count_unit_qty',
        render: (text, record) =>
          new Intl.NumberFormat([], {
            style: 'decimal',
            minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
            maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
          }).format(record.ttl_count_unit_qty)
      },
      {
        width: 50,
        align: 'right',
        title: `${intl.formatMessage({ id: 'variance_qty' })}`,
        // sort field
        dataIndex: 'abs_variance_qty',
        // filter field
        key: 'abs_variance_qty',
        render: (text, record) =>
          new Intl.NumberFormat([], {
            style: 'decimal',
            minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
            maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
          }).format(record.abs_variance_qty)
      }
    ];
  }

  getExpandedColumns() {
    const { intl } = this.props;

    return [
      {
        width: 100,
        align: 'center',
        title: intl.formatMessage({ id: 'status' }),
        // sort field
        dataIndex: 'str_physical_count_status',
        sorter: {
          compare: (a, b) => a.str_physical_count_status > b.str_physical_count_status,
          multiple: 2
        },
        // filter field
        key: 'str_physical_count_status',
        render: (text, record) => (
          <>
            <div
              style={{
                color: UtilService.processStrPhysicalCountStatusColor(
                  record.str_physical_count_status
                ),
                fontWeight: 'bold'
              }}
            >
              {intl.formatMessage({ id: record.str_physical_count_status })}
            </div>
            <div>{record.worker_01_username}</div>
          </>
        )
      },
      {
        width: 120,
        align: 'left',
        title: intl.formatMessage({ id: 'pallet_id' }),
        // sort field
        dataIndex: 'handling_unit_barcode',
        sorter: {
          compare: (a, b) => a.handling_unit_barcode > b.handling_unit_barcode,
          multiple: 11
        },
        // filter field
        key: 'handling_unit_barcode',
        render: (text, record) => record.handling_unit_barcode
      },
      {
        // fixed: 'left',
        width: 70,
        align: 'right',
        title: intl.formatMessage({ id: 'group' }),
        // sort field
        dataIndex: 'group_no',
        sorter: {
          compare: (a, b) => a.group_no - b.group_no,
          multiple: 10
        },
        // filter field
        key: 'group_no',
        render: (text, record) => record.group_no
      },
      {
        width: 120,
        align: 'left',
        title: intl.formatMessage({ id: 'company' }),
        // sort field
        dataIndex: 'company_code',
        sorter: {
          compare: (a, b) => a.company_code > b.company_code,
          multiple: 9
        },
        // filter field
        key: 'company_code',
        render: (text, record) => record.company_code
      },
      {
        width: 120,
        align: 'left',
        title: intl.formatMessage({ id: 'code' }),
        // sort field
        dataIndex: 'item_code',
        sorter: {
          compare: (a, b) => a.item_code > b.item_code,
          multiple: 8
        },
        // filter field
        key: 'item_code',
        render: (text, record) => record.item_code
      },
      {
        width: 150,
        align: 'left',
        title: intl.formatMessage({ id: 'description' }),
        // sort field
        dataIndex: 'desc_01',
        sorter: {
          compare: (a, b) => a.desc_01 > b.desc_01,
          multiple: 7
        },
        // filter field
        key: 'desc_01',
        render: (text, record) => (
          <>
            {record.desc_01}
            {record.desc_02 ? (
              <>
                <br />
                {record.desc_02}
              </>
            ) : (
              ''
            )}
            {record.desc_03 ? (
              <>
                <br />
                {record.desc_03}
              </>
            ) : (
              ''
            )}
          </>
        )
      },
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'item_batch' }),
        // sort field
        dataIndex: 'expiry_date',
        sorter: {
          compare: (a, b) => a.expiry_date > b.expiry_date,
          multiple: 6
        },
        // filter field
        key: 'expiry_date',
        render: (text, record) => (
          <>
            <div style={{ fontWeight: 'bold' }}>{record.batch_serial_no}</div>
            {record.expiry_date ? <div>{record.expiry_date}</div> : ''}
            {record.receipt_date ? <>{record.receipt_date}</> : ''}
          </>
        )
      },
      {
        width: 100,
        align: 'right',
        title: intl.formatMessage({ id: 'balance_qty' }),
        // sort field
        dataIndex: 'balance_unit_qty',
        sorter: {
          compare: (a, b) => a.balance_unit_qty - b.balance_unit_qty,
          multiple: 5
        },
        // filter field
        key: 'balance_unit_qty',
        render: (text, record) =>
          new Intl.NumberFormat([], {
            style: 'decimal',
            minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
            maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
          }).format(record.balance_unit_qty)
      },
      {
        width: 100,
        align: 'right',
        title: intl.formatMessage({ id: 'count_qty' }),
        // sort field
        dataIndex: 'ttl_count_unit_qty',
        sorter: {
          compare: (a, b) => a.ttl_count_unit_qty - b.ttl_count_unit_qty,
          multiple: 4
        },
        // filter field
        key: 'ttl_count_unit_qty',
        render: (text, record) =>
          new Intl.NumberFormat([], {
            style: 'decimal',
            minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
            maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
          }).format(record.ttl_count_unit_qty)
      },
      {
        width: 100,
        align: 'right',
        title: `${intl.formatMessage({ id: 'variance_qty' })}`,
        // sort field
        dataIndex: 'abs_variance_qty',
        sorter: {
          compare: (a, b) => a.abs_variance_qty - b.abs_variance_qty,
          multiple: 3
        },
        // filter field
        key: 'abs_variance_qty',
        render: (text, record) =>
          new Intl.NumberFormat([], {
            style: 'decimal',
            minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
            maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
          }).format(record.abs_variance_qty)
      },

      {
        width: 90,
        fixed: 'right',
        key: 'action',
        render: (text, record) => (
          <>
            <Popconfirm
              placement="left"
              title={`#${record.line_no} - ${record.storage_bin_code || ''} - ${record.item_code ||
                ''}`}
              icon={<CheckOutlined style={{ color: '#34A835' }} />}
              onConfirm={() => this.useConfirmDtl(record)}
              onCancel={() => {}}
              okText={intl.formatMessage({ id: 'yes_confirm_it' })}
              cancelText={intl.formatMessage({ id: 'cancel' })}
            >
              <Button
                type="primary"
                disabled={record.physical_count_status <= 10}
                icon={<CheckOutlined />}
                style={{ backgroundColor: '#34A835' }}
              />
            </Popconfirm>
            <Popconfirm
              placement="left"
              title={`#${record.line_no} - ${record.storage_bin_code || ''} - ${record.item_code ||
                ''}`}
              icon={<CloseOutlined style={{ color: '#007ad9' }} />}
              onConfirm={() => this.useDropDtl(record)}
              onCancel={() => {}}
              okText={intl.formatMessage({ id: 'yes_drop_it' })}
              cancelText={intl.formatMessage({ id: 'cancel' })}
            >
              <Button
                type="primary"
                disabled={record.physical_count_status <= 10}
                icon={<CloseOutlined />}
                style={{ backgroundColor: '#007ad9' }}
              />
            </Popconfirm>

            <Popconfirm
              placement="left"
              title={`#${record.line_no} - ${record.storage_bin_code || ''} - ${record.item_code ||
                ''}`}
              icon={<FormOutlined style={{ color: '#e91224' }} />}
              onConfirm={() => this.useRecountDtl(record)}
              onCancel={() => {}}
              okText={intl.formatMessage({ id: 'yes_recount_it' })}
              cancelText={intl.formatMessage({ id: 'cancel' })}
            >
              <Button
                type="primary"
                disabled={record.physical_count_status <= 10}
                icon={<FormOutlined />}
                style={{ backgroundColor: '#e91224' }}
              />
            </Popconfirm>
            <Popconfirm
              placement="left"
              title={`#${record.line_no} - ${record.storage_bin_code || ''} - ${record.item_code ||
                ''}`}
              icon={<RedoOutlined style={{ color: '#ffba01' }} />}
              onConfirm={() => this.useResetRecountDtl(record)}
              onCancel={() => {}}
              okText={intl.formatMessage({ id: 'yes_reset_it' })}
              cancelText={intl.formatMessage({ id: 'cancel' })}
            >
              <Button
                type="primary"
                disabled={record.physical_count_status <= 10}
                icon={<RedoOutlined />}
                style={{ backgroundColor: '#ffba01' }}
              />
            </Popconfirm>
          </>
        )
      }
    ];
  }

  showExpandedRow(record) {
    const { documentIsLoading } = this.props;

    return (
      <Card bordered={false} size="small">
        <Table
          size="small"
          // rowSelection={rowSelection}
          rowKey="id"
          bordered={false}
          pagination={false}
          columns={this.getExpandedColumns()}
          dataSource={record.details}
          loading={documentIsLoading}
          rowClassName={rowData => {
            return UtilService.processIdCssBackground(rowData.color_index);
          }}
          // defaultExpandAllRows
          // scroll={{ x: 750 }}
        />
      </Card>
    );
  }

  useConfirmDtl(record) {
    const { updateRecount } = this.props;

    updateRecount(record.id, 'MARK_CONFIRMED');
  }

  useDropDtl(record) {
    const { updateRecount } = this.props;

    updateRecount(record.id, 'MARK_DROPPED');
  }

  useRecountDtl(record) {
    const { updateRecount } = this.props;

    updateRecount(record.id, 'MARK_RECOUNT');
  }

  useResetRecountDtl(record) {
    const { updateRecount } = this.props;

    updateRecount(record.id, 'RESET');
  }

  useOnRefresh() {
    const { resetTimestamp } = this.props;

    resetTimestamp();
  }

  useOnAutoConfirm() {
    const { hdrId, autoConfirm } = this.props;

    autoConfirm(hdrId);
  }

  useOnAutoGroupConfirm() {
    const { hdrId, autoGroupConfirm } = this.props;

    autoGroupConfirm(hdrId);
  }

  useOnDownloadCsv() {
    const { hdrId, downloadExcel } = this.props;

    downloadExcel(hdrId);
  }

  useOnTableChange(pagination, filters, sorter) {
    const { resetTimestamp } = this.props;

    const processedFilters = AntDesignTable.processFilters(filters);
    const processedSorts = AntDesignTable.processSorts(sorter);

    resetTimestamp(pagination.current, processedSorts, processedFilters, pagination.pageSize);
  }

  useShowWorkspace() {
    const { setWorkspaceVisible } = this.props;

    setWorkspaceVisible(true);
  }

  render() {
    const {
      intl,
      historyGoBack,
      expandAllRows,
      collapseAllRows,
      documentDetails,
      documentIsLoading,
      currentPage,
      pageSize,
      total,
      expandedRows,
      setExpandedRows
    } = this.props;

    const recountDetails = [];
    documentDetails.forEach(storageBinDetail => {
      storageBinDetail.details.forEach(curValue => {
        if (curValue.str_physical_count_status === 'MARK_RECOUNT') {
          recountDetails.push(curValue);
        }
      });
    });

    return (
      <>
        <Table
          size="small"
          rowKey="id"
          pagination={{
            current: currentPage,
            pageSize: parseInt(pageSize, 10),
            total,
            showTotal: () => `${total} storage bins`
          }}
          columns={this.getDocumentColumns()}
          dataSource={documentDetails}
          loading={documentIsLoading}
          title={() => (
            <>
              <Row type="flex" justify="space-between" gutter={[0, 16]}>
                <Col>
                  <Space>
                    <Button
                      type="primary"
                      // disabled={!isValid}
                      loading={documentIsLoading}
                      onClick={historyGoBack}
                      icon={<ArrowLeftOutlined />}
                    >
                      {intl.formatMessage({ id: 'back' })}
                    </Button>
                    <Button
                      type="primary"
                      // disabled={!isValid}
                      loading={documentIsLoading}
                      onClick={expandAllRows}
                      icon={<PlusSquareOutlined />}
                    />
                    <Button
                      type="primary"
                      // disabled={!isValid}
                      loading={documentIsLoading}
                      onClick={collapseAllRows}
                      icon={<MinusSquareOutlined />}
                    />
                    <Button
                      type="primary"
                      // disabled={!isValid}
                      loading={documentIsLoading}
                      onClick={this.useOnRefresh}
                      icon={<ReloadOutlined />}
                    >
                      {intl.formatMessage({ id: 'refresh_all' })}
                    </Button>
                    <Button
                      type="primary"
                      loading={documentIsLoading}
                      onClick={this.useOnDownloadCsv}
                      icon={<CloudDownloadOutlined />}
                    >
                      {intl.formatMessage({ id: 'excel' })}
                    </Button>
                  </Space>
                </Col>
                <Col>
                  <Space>
                    <Button
                      type="primary"
                      // disabled={!isValid}
                      loading={documentIsLoading}
                      onClick={this.useOnAutoConfirm}
                      icon={<FileSearchOutlined />}
                    >
                      {intl.formatMessage({ id: 'balance_confirm' })}
                    </Button>
                    <Button
                      danger
                      // disabled={!isValid}
                      loading={documentIsLoading}
                      onClick={this.useOnAutoGroupConfirm}
                      icon={<FileSearchOutlined />}
                    >
                      {intl.formatMessage({ id: 'group_confirm' })}
                    </Button>
                  </Space>
                </Col>
              </Row>
            </>
          )}
          bordered
          onChange={this.useOnTableChange}
          expandedRowRender={this.showExpandedRow}
          expandedRowKeys={expandedRows}
          onExpandedRowsChange={rowKeys => {
            setExpandedRows(rowKeys);
          }}
          scroll={{ x: 950 }}
        />

        <div
          style={{
            zIndex: 10000,
            position: 'fixed',
            bottom: '35px',
            right: '35px'
          }}
        >
          <Badge count={recountDetails.length}>
            <Button
              size="large"
              type="primary"
              shape="circle"
              icon={<LaptopOutlined />}
              onClick={this.useShowWorkspace}
              loading={documentIsLoading}
            />
          </Badge>
        </div>
      </>
    );
  }
}

DetailTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,

  resetTimestamp: PropTypes.func,
  historyGoBack: PropTypes.func,
  setWorkspaceVisible: PropTypes.func,
  showDetails: PropTypes.func,
  showDetailsSuccess: PropTypes.func,
  autoConfirm: PropTypes.func,
  autoGroupConfirm: PropTypes.func,
  hdrId: PropTypes.number,
  documentIsLoading: PropTypes.bool,
  documentDetails: PropTypes.arrayOf(PropTypes.object),
  initDocumentDetail: PropTypes.shape({}),

  sorts: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  filters: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),

  currentPage: PropTypes.number,
  pageSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  total: PropTypes.number,

  expandedRows: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  setExpandedRows: PropTypes.func,

  updateRecount: PropTypes.func,
  downloadExcel: PropTypes.func,
  expandAllRows: PropTypes.func,
  collapseAllRows: PropTypes.func
};

DetailTable.defaultProps = {
  intl: {},

  resetTimestamp() {},
  historyGoBack() {},
  setWorkspaceVisible() {},
  showDetails() {},
  showDetailsSuccess() {},
  autoConfirm() {},
  autoGroupConfirm() {},
  hdrId: 0,
  documentDetails: [],
  documentIsLoading: false,
  initDocumentDetail: {},

  sorts: {},
  filters: {},

  currentPage: 1,
  pageSize: '20',
  total: 0,

  expandedRows: [],
  setExpandedRows() {},

  updateRecount() {},
  downloadExcel() {},
  expandAllRows() {},
  collapseAllRows() {}
};

const mapStateToProps = state => ({
  timestamp: state.cycleCount02Detail.timestamp,
  hdrId: state.cycleCount02Detail.hdrId,
  documentDetails: state.cycleCount02Detail.documentDetails,
  documentIsLoading: state.cycleCount02Detail.documentIsLoading,

  sorts: state.cycleCount02Detail.sorts,
  filters: state.cycleCount02Detail.filters,

  currentPage: state.cycleCount02Detail.currentPage,
  pageSize: state.cycleCount02Detail.pageSize,
  total: state.cycleCount02Detail.total,

  expandedRows: state.cycleCount02Detail.expandedRows
});

const mapDispatchToProps = dispatch => ({
  historyGoBack: () => dispatch(AppActions.appHistoryGoBack()),
  resetTimestamp: (currentPage, sorts, filters, pageSize) =>
    dispatch(
      CycleCount02DetailActions.cycleCount02DetailResetTimestamp(
        currentPage,
        sorts,
        filters,
        pageSize
      )
    ),
  setWorkspaceVisible: boolean =>
    dispatch(CycleCount02DetailActions.cycleCount02DetailSetWorkspaceVisible(boolean)),

  showDetails: (hdrId, currentPage, sorts, filters, pageSize) =>
    dispatch(
      CycleCount02DetailActions.cycleCount02DetailShowDetails(
        hdrId,
        currentPage,
        sorts,
        filters,
        pageSize
      )
    ),
  showDetailsSuccess: details =>
    dispatch(CycleCount02DetailActions.cycleCount02DetailShowDetailsSuccess(details)),

  updateRecount: (dtlId, action) =>
    dispatch(CycleCount02DetailActions.cycleCount02DetailUpdateRecount(dtlId, action)),

  autoConfirm: hdrId => dispatch(CycleCount02DetailActions.cycleCount02DetailAutoConfirm(hdrId)),
  autoGroupConfirm: hdrId =>
    dispatch(CycleCount02DetailActions.cycleCount02DetailAutoGroupConfirm(hdrId)),

  setExpandedRows: expandedRows =>
    dispatch(CycleCount02DetailActions.cycleCount02DetailSetExpandedRows(expandedRows)),
  downloadExcel: hdrId => dispatch(CycleCount02DetailActions.cycleCount02DetailDownload(hdrId)),
  expandAllRows: () => dispatch(CycleCount02DetailActions.cycleCount02DetailExpandAllRows()),
  collapseAllRows: () => dispatch(CycleCount02DetailActions.cycleCount02DetailCollapseAllRows())
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(DetailTable));
