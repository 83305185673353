/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { DelOrdFServ01Types } from './Actions';
import INITIAL_STATE from './InitialState';

export const delOrdFServ01ShowSyncSettingLoading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const delOrdFServ01ShowSyncSettingSuccess = (state, { syncSetting }) => ({
  ...state,
  syncSetting
});

export const delOrdFServ01ShowBatchJobStatusSuccess = (state, { batchJobStatus }) => ({
  ...state,
  batchJobStatus
});

export const delOrdFServ01ExportLoading = (state, { boolean }) => ({
  ...state,
  exportIsLoading: boolean
});

export const delOrdFServ01ResetTimestamp = (state, { currentPage, sorts, filters, pageSize }) => ({
  ...state,
  currentPage: currentPage || state.currentPage,
  sorts: sorts || state.sorts,
  filters: filters || state.filters,
  pageSize: pageSize || state.pageSize,
  timestamp: Date.now()
  // selectedDocuments: INITIAL_STATE.selectedDocuments
});

export const delOrdFServ01FetchDelOrdFServ01Loading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const delOrdFServ01FetchDelOrdFServ01Success = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  documents,
  currentPage,
  lastPage,
  total,
  pageSize
});

export const delOrdFServ01AddSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = state.selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: [
      ...state.selectedDocuments,
      ...selectedDocuments.filter(data => {
        return !selectIds.includes(data.id);
      })
    ]
  };
};

export const delOrdFServ01RemoveSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: state.selectedDocuments.filter(data => {
      return !selectIds.includes(data.id);
    })
  };
};

export const delOrdFServ01SetWorkspaceVisible = (state, { boolean }) => ({
  ...state,
  workspaceIsVisible: boolean
});

export const delOrdFServ01ExportSuccess = state => ({
  ...state,
  timestamp: Date.now(),
  selectedDocuments: []
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [DelOrdFServ01Types.DEL_ORD_F_SERV01_SHOW_SYNC_SETTING_LOADING]: delOrdFServ01ShowSyncSettingLoading,
  [DelOrdFServ01Types.DEL_ORD_F_SERV01_SHOW_SYNC_SETTING_SUCCESS]: delOrdFServ01ShowSyncSettingSuccess,
  [DelOrdFServ01Types.DEL_ORD_F_SERV01_SHOW_BATCH_JOB_STATUS_SUCCESS]: delOrdFServ01ShowBatchJobStatusSuccess,

  [DelOrdFServ01Types.DEL_ORD_F_SERV01_RESET_TIMESTAMP]: delOrdFServ01ResetTimestamp,
  [DelOrdFServ01Types.DEL_ORD_F_SERV01_FETCH_DEL_ORD_F_SERV01_LOADING]: delOrdFServ01FetchDelOrdFServ01Loading,
  [DelOrdFServ01Types.DEL_ORD_F_SERV01_FETCH_DEL_ORD_F_SERV01_SUCCESS]: delOrdFServ01FetchDelOrdFServ01Success,
  [DelOrdFServ01Types.DEL_ORD_F_SERV01_ADD_SELECTED_DOCUMENTS]: delOrdFServ01AddSelectedDocuments,
  [DelOrdFServ01Types.DEL_ORD_F_SERV01_REMOVE_SELECTED_DOCUMENTS]: delOrdFServ01RemoveSelectedDocuments,
  [DelOrdFServ01Types.DEL_ORD_F_SERV01_SET_WORKSPACE_VISIBLE]: delOrdFServ01SetWorkspaceVisible,
  [DelOrdFServ01Types.DEL_ORD_F_SERV01_EXPORT_LOADING]: delOrdFServ01ExportLoading,
  [DelOrdFServ01Types.DEL_ORD_F_SERV01_EXPORT_SUCCESS]: delOrdFServ01ExportSuccess
});
