import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  lspSlsRtnDetailResetTimestamp: null,
  lspSlsRtnDetailSetHdrId: ['hdrId', 'itemId'],

  lspSlsRtnDetailInitHeader: null,
  lspSlsRtnDetailShowHeader: ['hdrId'],
  lspSlsRtnDetailShowHeaderSuccess: ['documentHeader'],

  lspSlsRtnDetailUpdateHeader: ['formikBag', 'documentHeader'],
  lspSlsRtnDetailCreateHeader: ['formikBag', 'documentHeader'],
  lspSlsRtnDetailUpdateDocumentSuccess: ['documentHeader', 'documentDetails'],

  lspSlsRtnDetailShowDocumentLoading: ['boolean'],
  lspSlsRtnDetailTransitionToStatus: ['formikBag', 'hdrId', 'docStatus'],

  lspSlsRtnDetailShowDetails: ['hdrId'],
  lspSlsRtnDetailShowDetailsSuccess: ['documentDetails'],

  lspSlsRtnDetailSetDetailVisible: ['boolean'],
  lspSlsRtnDetailSetDocumentDetail: ['documentDetail'],

  lspSlsRtnDetailUpdateDetails: ['formikBag', 'hdrId', 'documentDetails'],
  lspSlsRtnDetailCreateDetail: ['formikBag', 'hdrId', 'documentDetail'],
  lspSlsRtnDetailDeleteDetail: ['hdrId', 'documentDetail'],

  lspSlsRtnDetailFetchDebtorOptions: ['search'],
  lspSlsRtnDetailFetchDebtorOptionLoading: ['boolean'],
  lspSlsRtnDetailFetchDebtorOptionSuccess: ['options'],
  lspSlsRtnDetailChangeDebtor: ['formikBag', 'debtorId'],

  lspSlsRtnDetailFetchSalesmanOptions: ['search'],
  lspSlsRtnDetailFetchSalesmanOptionLoading: ['boolean'],
  lspSlsRtnDetailFetchSalesmanOptionSuccess: ['options'],

  lspSlsRtnDetailFetchDeliveryPointOptions: ['debtorId', 'search'],
  lspSlsRtnDetailFetchDeliveryPointOptionLoading: ['boolean'],
  lspSlsRtnDetailFetchDeliveryPointOptionSuccess: ['options'],
  lspSlsRtnDetailChangeDeliveryPoint: ['formikBag', 'deliveryPointId'],

  lspSlsRtnDetailFetchCreditTermOptions: ['search'],
  lspSlsRtnDetailFetchCreditTermOptionLoading: ['boolean'],
  lspSlsRtnDetailFetchCreditTermOptionSuccess: ['options'],

  lspSlsRtnDetailFetchCurrencyOptions: ['search'],
  lspSlsRtnDetailFetchCurrencyOptionLoading: ['boolean'],
  lspSlsRtnDetailFetchCurrencyOptionSuccess: ['options'],
  lspSlsRtnDetailChangeCurrency: ['formikBag', 'currencyId'],

  lspSlsRtnDetailFetchLocationOptions: ['search'],
  lspSlsRtnDetailFetchLocationOptionLoading: ['boolean'],
  lspSlsRtnDetailFetchLocationOptionSuccess: ['options'],

  lspSlsRtnDetailFetchItemOptions: ['search'],
  lspSlsRtnDetailFetchItemOptionLoading: ['boolean'],
  lspSlsRtnDetailFetchItemOptionSuccess: ['options'],
  lspSlsRtnDetailChangeItem: ['formikBag', 'hdrId', 'itemId'],

  lspSlsRtnDetailFetchUomOptions: ['itemId', 'search'],
  lspSlsRtnDetailFetchUomOptionLoading: ['boolean'],
  lspSlsRtnDetailFetchUomOptionSuccess: ['options'],
  lspSlsRtnDetailChangeUom: ['formikBag', 'hdrId', 'itemId', 'uomId'],

  lspSlsRtnDetailFetchProjectOptions: ['search'],
  lspSlsRtnDetailFetchProjectOptionLoading: ['boolean'],
  lspSlsRtnDetailFetchProjectOptionSuccess: ['options']
});

export const LspSlsRtnDetailTypes = Types;
export default Creators;
