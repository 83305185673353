export const DocSubTypeMap = {
  EIT: 6,
  6: 'EIT',

  PIT: 7,
  7: 'PIT',

  HIT: 8,
  8: 'HIT',

  BIT: 9,
  9: 'BIT',

  ISSUE_TO_WAREHOUSE: 10,
  10: 'ISSUE_TO_WAREHOUSE',

  RECEIVE_BY_WAREHOUSE: 11,
  11: 'RECEIVE_BY_WAREHOUSE',

  MGR: 12,
  12: 'MGR',

  ECN: 13,
  13: 'ECN',

  GENERAL: 14,
  14: 'GENERAL',

  SECOND_GRADE: 15,
  15: 'SECOND_GRADE',

  WASH: 16,
  16: 'WASH'
};

export const DocSubTypeOptions = [
  { value: DocSubTypeMap.EIT, label: 'EIT' },
  { value: DocSubTypeMap.PIT, label: 'PIT' },
  { value: DocSubTypeMap.HIT, label: 'HIT' },
  { value: DocSubTypeMap.BIT, label: 'BIT' },
  { value: DocSubTypeMap.ISSUE_TO_WAREHOUSE, label: 'ISSUE_TO_WAREHOUSE' },
  { value: DocSubTypeMap.RECEIVE_BY_WAREHOUSE, label: 'RECEIVE_BY_WAREHOUSE' },
  { value: DocSubTypeMap.MGR, label: 'MGR' },
  { value: DocSubTypeMap.ECN, label: 'ECN' },
  { value: DocSubTypeMap.GENERAL, label: 'GENERAL' },
  { value: DocSubTypeMap.SECOND_GRADE, label: 'SECOND_GRADE' },
  { value: DocSubTypeMap.WASH, label: 'WASH' }
];
