import React from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';

import { UndoOutlined, PlayCircleOutlined } from '@ant-design/icons';

import { Button, Row, Col, Typography } from 'antd';
import {
  FormikTransfer,
  FormikDatePicker,
  FormikSelect,
  FormikButton
} from '../../Components/Formik';
import TransferInTrackingReportActions from '../../Stores/TransferInTrackingReport/Actions';

const { Text } = Typography;

class CriteriaForm extends React.PureComponent {
  constructor() {
    super();

    this.handleSearchDivision = this.handleSearchDivision.bind(this);
    this.handleSearchFrLocation = this.handleSearchFrLocation.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate() {}

  componentWillUnmount() {}

  handleSearchDivision(form, value) {
    const { fetchDivisionOptions } = this.props;

    fetchDivisionOptions(value);
  }

  handleSearchFrLocation(form, value) {
    const { fetchFrLocationOptions } = this.props;

    fetchFrLocationOptions(value);
  }

  render() {
    const {
      intl,
      criteria,
      reportIsLoading,
      transferInTracking,
      divisionOptions,
      divisionIsLoading,
      frLocationOptions,
      frLocationIsLoading
    } = this.props;

    return (
      <Formik
        enableReinitialize
        initialValues={criteria}
        onSubmit={(values, formikBag) => {
          transferInTracking(formikBag, values);
        }}
        validationSchema={Yup.object().shape({
          doc_date: Yup.date().min(
            new Date(1969, 12, 1),
            intl.formatMessage({ id: 'doc_date_is_required' })
          )
        })}
      >
        {({
          // values,
          // handleChange,
          // errors,
          // setFieldTouched,
          dirty,
          // touched,
          isSubmitting,
          // isValid,
          handleSubmit,
          handleReset
        }) => (
          <Form>
            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'start_date' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="start_date"
                  component={FormikDatePicker}
                  disabled={isSubmitting || reportIsLoading}
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'end_date' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="end_date"
                  component={FormikDatePicker}
                  disabled={isSubmitting || reportIsLoading}
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'division' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="division_ids_select2"
                  component={FormikSelect}
                  showArrow
                  filterOption={false}
                  onSearch={this.handleSearchDivision}
                  notFoundContent={null}
                  loading={divisionIsLoading}
                  options={divisionOptions}
                  disabled={isSubmitting || reportIsLoading}
                  selectMode="multiple"
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'from_location' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="fr_location_ids_select2"
                  component={FormikSelect}
                  showArrow
                  filterOption={false}
                  onSearch={this.handleSearchFrLocation}
                  notFoundContent={null}
                  loading={frLocationIsLoading}
                  options={frLocationOptions}
                  disabled={isSubmitting || reportIsLoading}
                  selectMode="multiple"
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={24}>
                <Field
                  name="columns"
                  component={FormikTransfer}
                  intl={intl}
                  disabled={isSubmitting || reportIsLoading}
                />
              </Col>
            </Row>

            <Row type="flex" justify="space-between" gutter={[0, 16]}>
              <Col span={24}>
                <Button
                  disabled={!dirty}
                  loading={isSubmitting || reportIsLoading}
                  onClick={handleReset}
                  icon={<UndoOutlined />}
                  type="primary"
                >
                  {intl.formatMessage({ id: 'reset' })}
                </Button>
                <Field
                  name="submit_action"
                  value="run_report"
                  component={FormikButton}
                  loading={isSubmitting || reportIsLoading}
                  onClick={handleSubmit}
                  label={intl.formatMessage({
                    id: 'run_report'
                  })}
                  icon={<PlayCircleOutlined />}
                  type="primary"
                />
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    );
  }
}

CriteriaForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,

  // eslint-disable-next-line react/forbid-prop-types
  criteria: PropTypes.object,
  reportIsLoading: PropTypes.bool,
  transferInTracking: PropTypes.func,
  fetchDivisionOptions: PropTypes.func,

  divisionOptions: PropTypes.arrayOf(PropTypes.object),
  divisionIsLoading: PropTypes.bool,

  fetchFrLocationOptions: PropTypes.func,
  frLocationOptions: PropTypes.arrayOf(PropTypes.object),
  frLocationIsLoading: PropTypes.bool
};

CriteriaForm.defaultProps = {
  intl: {},

  criteria: {},
  reportIsLoading: false,
  transferInTracking() {},
  fetchDivisionOptions() {},

  divisionOptions: [],
  divisionIsLoading: false,

  fetchFrLocationOptions() {},
  frLocationOptions: [],
  frLocationIsLoading: false
};

const mapStateToProps = state => ({
  timestamp: state.transferInTrackingReport.timestamp,
  criteria: state.transferInTrackingReport.criteria,

  reportIsLoading: state.transferInTrackingReport.reportIsLoading,

  divisionOptions: state.transferInTrackingReport.divisionOptions,
  divisionIsLoading: state.transferInTrackingReport.divisionIsLoading,

  frLocationOptions: state.transferInTrackingReport.frLocationOptions,
  frLocationIsLoading: state.transferInTrackingReport.frLocationIsLoading
});

const mapDispatchToProps = dispatch => ({
  transferInTracking: (formikBag, criteria) =>
    dispatch(
      TransferInTrackingReportActions.transferInTrackingReportTransferInTracking(
        formikBag,
        criteria
      )
    ),

  fetchDivisionOptions: search =>
    dispatch(TransferInTrackingReportActions.transferInTrackingReportFetchDivisionOptions(search)),
  fetchFrLocationOptions: search =>
    dispatch(TransferInTrackingReportActions.transferInTrackingReportFetchFrLocationOptions(search))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CriteriaForm));
