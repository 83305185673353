/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { LocationList01Types } from './Actions';
import INITIAL_STATE from './InitialState';

export const locationList01ResetTimestamp = (state, { currentPage, sorts, filters, pageSize }) => ({
  ...state,
  currentPage: currentPage || state.currentPage,
  sorts: sorts || state.sorts,
  filters: filters || state.filters,
  pageSize: pageSize || state.pageSize,
  timestamp: Date.now(),
  // selectedResources: INITIAL_STATE.selectedResources,
  toLocationOption: INITIAL_STATE.toLocationOption
});

export const locationList01FetchLocationList01Loading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const locationList01FetchLocationList01Success = (
  state,
  { resources, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  resources,
  currentPage,
  lastPage,
  total,
  pageSize
});

export const locationList01AddSelectedResources = (state, { selectedResources }) => {
  const selectIds = state.selectedResources.map(value => value.id);
  return {
    ...state,
    selectedResources: [
      ...state.selectedResources,
      ...selectedResources.filter(data => {
        return !selectIds.includes(data.id);
      })
    ]
  };
};

export const locationList01RemoveSelectedResources = (state, { selectedResources }) => {
  const selectIds = selectedResources.map(value => value.id);
  return {
    ...state,
    selectedResources: state.selectedResources.filter(data => {
      return !selectIds.includes(data.id);
    })
  };
};

export const locationList01SetWorkspaceVisible = (state, { boolean }) => ({
  ...state,
  workspaceIsVisible: boolean
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [LocationList01Types.LOCATION_LIST01_RESET_TIMESTAMP]: locationList01ResetTimestamp,
  [LocationList01Types.LOCATION_LIST01_FETCH_LOCATION_LIST01_LOADING]: locationList01FetchLocationList01Loading,
  [LocationList01Types.LOCATION_LIST01_FETCH_LOCATION_LIST01_SUCCESS]: locationList01FetchLocationList01Success,
  [LocationList01Types.LOCATION_LIST01_ADD_SELECTED_RESOURCES]: locationList01AddSelectedResources,
  [LocationList01Types.LOCATION_LIST01_REMOVE_SELECTED_RESOURCES]: locationList01RemoveSelectedResources,
  [LocationList01Types.LOCATION_LIST01_SET_WORKSPACE_VISIBLE]: locationList01SetWorkspaceVisible
});
