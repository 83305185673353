import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import treeChanges from 'tree-changes';

import { DeleteOutlined, EditOutlined, ReloadOutlined, ArrowLeftOutlined } from '@ant-design/icons';

import { Table, Popconfirm, Button, Row, Col } from 'antd';

import DocTypeDocNoIndexActions from '../../Stores/DocTypeDocNoIndex/Actions';
import AppActions from '../../Stores/App/Actions';

class DocNoTable extends React.PureComponent {
  constructor() {
    super();

    this.getDocumentColumns = this.getDocumentColumns.bind(this);
    this.useOnSelectItem = this.useOnSelectItem.bind(this);
    this.useCreateDocNo = this.useCreateDocNo.bind(this);
    this.useOnDeleteItem = this.useOnDeleteItem.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    const { docType, showDocNos, resetTimestamp } = this.props;

    const { changed } = treeChanges(prevProps, this.props);
    if (changed('curDivisionId')) {
      resetTimestamp();
    }
    if (changed('timestamp')) {
      showDocNos(docType);
    }
  }

  componentWillUnmount() {}

  getDocumentColumns() {
    const { intl } = this.props;

    return [
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'prefix' }),
        // sort field
        dataIndex: 'doc_prefix',
        // filter field
        key: 'doc_prefix',
        render: (text, record) => <>{record.doc_prefix}</>
      },
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'running_number' }),
        // sort field
        dataIndex: 'running_no',
        // filter field
        key: 'running_no',
        render: (text, record) => <>{record.running_no}</>
      },
      {
        width: 50,
        align: 'left',
        title: intl.formatMessage({ id: 'length' }),
        // sort field
        dataIndex: 'running_no_length',
        // filter field
        key: 'running_no_length',
        render: (text, record) => <>{record.running_no_length}</>
      },
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'suffix' }),
        // sort field
        dataIndex: 'doc_suffix',
        // filter field
        key: 'doc_suffix',
        render: (text, record) => <>{record.doc_suffix}</>
      },
      {
        width: 150,
        align: 'left',
        title: intl.formatMessage({ id: 'site' }),
        // sort field
        dataIndex: 'site',
        // filter field
        key: 'site',
        render: (text, record) => (
          <>
            {record.sites.reduce((lastStr, data) => {
              let newStr = '';
              if (lastStr.length > 0) {
                newStr = `${lastStr}/${data.code}`;
              } else {
                newStr = data.code;
              }
              return newStr;
            }, '')}
          </>
        )
      },
      {
        width: 150,
        align: 'left',
        title: intl.formatMessage({ id: 'division' }),
        // sort field
        dataIndex: 'division',
        // filter field
        key: 'division',
        render: (text, record) => (
          <>
            {record.divisions.reduce((lastStr, data) => {
              let newStr = '';
              if (lastStr.length > 0) {
                newStr = `${lastStr}/${data.code}`;
              } else {
                newStr = data.code;
              }
              return newStr;
            }, '')}
          </>
        )
      },
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'doc_sub_type' }),
        // sort field
        dataIndex: 'doc_sub_type_str',
        // filter field
        key: 'doc_sub_type_str',
        render: (text, record) => <>{record.doc_sub_type_str}</>
      },
      {
        fixed: 'right',
        width: 50,
        key: 'action',
        render: (text, record) => (
          <>
            <Button
              type="dashed"
              icon={<EditOutlined />}
              onClick={() => this.useOnSelectItem(record)}
            />
            <Popconfirm
              placement="left"
              title={intl.formatMessage({ id: 'are_you_sure_to_remove_this_line' })}
              onConfirm={() => this.useOnDeleteItem(record)}
              onCancel={() => {}}
              okText={intl.formatMessage({ id: 'yes' })}
              cancelText={intl.formatMessage({ id: 'cancel' })}
            >
              <Button type="dashed" icon={<DeleteOutlined />} />
            </Popconfirm>
          </>
        )
      }
    ];
  }

  useOnSelectItem(record) {
    const { setDocNo, setFormVisible } = this.props;

    setDocNo(record);

    setFormVisible(true);
  }

  useOnDeleteItem(record) {
    const { deleteDocNo } = this.props;

    deleteDocNo(record);
  }

  useCreateDocNo() {
    const { initDocNo, setFormVisible, setDocNo } = this.props;

    setDocNo(initDocNo);

    setFormVisible(true);
  }

  render() {
    const { intl, docNos, docNoIsLoading, resetTimestamp, historyGoBack } = this.props;

    return (
      <>
        <Table
          size="small"
          // rowSelection={rowSelection}
          rowKey="id"
          pagination={{ pageSize: 20 }}
          columns={this.getDocumentColumns()}
          dataSource={docNos}
          loading={docNoIsLoading}
          bordered
          title={() => (
            <>
              <Row type="flex" justify="start" gutter={[0, 16]}>
                <Col span={3}>
                  <Button
                    type="primary"
                    // disabled={!isValid}
                    loading={docNoIsLoading}
                    onClick={historyGoBack}
                    icon={<ArrowLeftOutlined />}
                  >
                    {intl.formatMessage({
                      id: 'back'
                    })}
                  </Button>
                </Col>
                <Col span={1}>
                  <Button
                    type="primary"
                    // disabled={!isValid}
                    loading={docNoIsLoading}
                    onClick={() => resetTimestamp()}
                    icon={<ReloadOutlined />}
                  />
                </Col>
                <Col span={2}>
                  <Button type="primary" loading={docNoIsLoading} onClick={this.useCreateDocNo}>
                    {intl.formatMessage({ id: 'new' })}
                  </Button>
                </Col>
              </Row>
            </>
          )}
          scroll={{ x: 950 }}
        />
      </>
    );
  }
}

DocNoTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  resetTimestamp: PropTypes.func,
  historyGoBack: PropTypes.func,
  showDocNos: PropTypes.func,
  setDocNo: PropTypes.func,
  setFormVisible: PropTypes.func,
  deleteDocNo: PropTypes.func,
  docType: PropTypes.string,
  docNoIsLoading: PropTypes.bool,
  docNos: PropTypes.arrayOf(PropTypes.object),
  initDocNo: PropTypes.shape({})
};

DocNoTable.defaultProps = {
  intl: {},
  resetTimestamp() {},
  historyGoBack() {},
  showDocNos() {},
  setDocNo() {},
  setFormVisible() {},
  deleteDocNo() {},
  docType: '',
  docNos: [],
  docNoIsLoading: false,
  initDocNo: {}
};

const mapStateToProps = state => ({
  curDivisionId: state.app.curDivisionId,
  timestamp: state.docTypeDocNoIndex.timestamp,
  docType: state.docTypeDocNoIndex.docType,
  docNos: state.docTypeDocNoIndex.docNos,
  docNoIsLoading: state.docTypeDocNoIndex.docNoIsLoading,
  initDocNo: state.docTypeDocNoIndex.initDocNo
});

const mapDispatchToProps = dispatch => ({
  historyGoBack: () => dispatch(AppActions.appHistoryGoBack()),
  resetTimestamp: () => dispatch(DocTypeDocNoIndexActions.docTypeDocNoIndexResetTimestamp()),
  showDocNos: docType => dispatch(DocTypeDocNoIndexActions.docTypeDocNoIndexShowDocNos(docType)),
  setFormVisible: boolean =>
    dispatch(DocTypeDocNoIndexActions.docTypeDocNoIndexSetFormVisible(boolean)),
  setDocNo: docNo => dispatch(DocTypeDocNoIndexActions.docTypeDocNoIndexSetDocNo(docNo)),
  deleteDocNo: docNo => dispatch(DocTypeDocNoIndexActions.docTypeDocNoIndexDeleteDocNo(docNo))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(DocNoTable));
