import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  gdsRcpt0201ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  gdsRcpt0201GoToDocument: ['hdrId'],
  gdsRcpt0201FetchGdsRcpt0201: ['currentPage', 'sorts', 'filters', 'pageSize'],
  gdsRcpt0201FetchGdsRcpt0201Loading: ['boolean'],
  gdsRcpt0201FetchGdsRcpt0201Success: ['documents', 'currentPage', 'lastPage', 'total', 'pageSize'],
  gdsRcpt0201AddSelectedDocuments: ['selectedDocuments'],
  gdsRcpt0201RemoveSelectedDocuments: ['selectedDocuments'],
  gdsRcpt0201SetWorkspaceVisible: ['boolean'],
  gdsRcpt0201PrintGdsRcpt0201: ['hdrIds'],
  gdsRcpt0201PrintGdsRcpt0201Loading: ['boolean']
});

export const GdsRcpt0201Types = Types;
export default Creators;
