import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import treeChanges from 'tree-changes';

import { ArrowLeftOutlined, ReloadOutlined } from '@ant-design/icons';

import { Row, Col, Table, Button, Card, Typography } from 'antd';

import AntDesignTable from '../../Components/AntDesignTable';
import AuditRes01Actions from '../../Stores/AuditRes01/Actions';
import AppActions from '../../Stores/App/Actions';

const { Text } = Typography;

class AuditRes01Table extends React.PureComponent {
  constructor() {
    super();

    this.useOnTableChange = this.useOnTableChange.bind(this);

    this.handleSearch = this.handleSearch.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.getDocumentColumns = this.getDocumentColumns.bind(this);
    this.getExpandedColumns = this.getExpandedColumns.bind(this);
    this.showExpandedRow = this.showExpandedRow.bind(this);
  }

  componentDidMount() {
    const { resType, resetTimestamp } = this.props;

    if (resType) {
      resetTimestamp();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      resType,
      resetTimestamp,
      fetchAuditRes01,
      currentPage,
      sorts,
      filters,
      pageSize
    } = this.props;

    const { changed } = treeChanges(prevProps, this.props);

    if (changed('resType') || changed('resId') || changed('auditType')) {
      resetTimestamp();
    }

    if (changed('timestamp')) {
      if (resType) {
        fetchAuditRes01(currentPage, sorts, filters, pageSize);
      }
    }
  }

  componentWillUnmount() {}

  handleSearch(selectedKeys, confirm) {
    // eslint-disable-next-line no-unused-vars
    const { intl } = this.props;

    confirm();
  }

  handleReset(clearFilters) {
    // eslint-disable-next-line no-unused-vars
    const { intl } = this.props;

    clearFilters();
  }

  getDocumentColumns() {
    const { intl, sorts, filters } = this.props;

    return [
      {
        width: 110,
        align: 'left',
        title: intl.formatMessage({ id: 'created_at' }),
        // sort field
        dataIndex: 'created_at',
        ...AntDesignTable.getColumnSortProps(sorts, 'created_at'),
        // filter field
        key: 'created_at',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'created_at' }),
          'created_at',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => record.created_at
      },
      {
        width: 80,
        align: 'left',
        title: intl.formatMessage({ id: 'event' }),
        // sort field
        dataIndex: 'event',
        ...AntDesignTable.getColumnSortProps(sorts, 'event'),
        // filter field
        key: 'event',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'event' }),
          'event',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.event}</>
      },
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'user' }),
        // sort field
        dataIndex: 'user',
        ...AntDesignTable.getColumnSortProps(sorts, 'user'),
        // filter field
        key: 'user',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'user' }),
          'user',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.username}</>
      },
      {
        width: 70,
        align: 'left',
        title: intl.formatMessage({ id: 'values' }),
        // sort field
        dataIndex: 'new_values',
        // filter field
        key: 'values',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'values' }),
          'values',
          this.handleSearch,
          this.handleReset
        ),
        render: () => <></>
      },
      {
        width: 115,
        align: 'left',
        title: intl.formatMessage({ id: 'ip_address' }),
        // sort field
        dataIndex: 'ip_address',
        ...AntDesignTable.getColumnSortProps(sorts, 'ip_address'),
        // filter field
        key: 'ip_address',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'ip_address' }),
          'ip_address',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.ip_address}</>
      },
      {
        width: 150,
        align: 'left',
        title: intl.formatMessage({ id: 'user_agent' }),
        // sort field
        dataIndex: 'user_agent',
        ...AntDesignTable.getColumnSortProps(sorts, 'user_agent'),
        // filter field
        key: 'user_agent',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'user_agent' }),
          'user_agent',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.user_agent}</>
      },
      {
        width: 150,
        align: 'left',
        title: intl.formatMessage({ id: 'url' }),
        // sort field
        dataIndex: 'url',
        ...AntDesignTable.getColumnSortProps(sorts, 'url'),
        // filter field
        key: 'url',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'url' }),
          'url',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.url}</>
      }
    ];
  }

  getExpandedColumns() {
    const { intl } = this.props;

    return [
      {
        align: 'left',
        title: intl.formatMessage({ id: 'name' }),
        // sort field
        dataIndex: 'name',
        // filter field
        key: 'name',
        render: (text, record) => <>{record.name}</>
      },
      {
        align: 'left',
        title: intl.formatMessage({ id: 'value' }),
        // sort field
        dataIndex: 'value',
        // filter field
        key: 'value',
        render: (text, record) => <>{record.value}</>
      }
    ];
  }

  showExpandedRow(record) {
    const { intl, fetchIsLoading } = this.props;

    return (
      <Card bordered={false}>
        <Row type="flex" justify="space-between" gutter={[0, 16]}>
          <Col span={12}>
            <Table
              size="small"
              // rowSelection={rowSelection}
              rowKey="name"
              bordered={false}
              pagination={false}
              columns={this.getExpandedColumns()}
              dataSource={record.old_values}
              loading={fetchIsLoading}
              defaultExpandAllRows
              title={() => (
                <>
                  <Row type="flex" justify="center" gutter={[0, 16]}>
                    <Col span={3}>
                      <Text strong>{intl.formatMessage({ id: 'old' })}</Text>
                    </Col>
                  </Row>
                </>
              )}
            />
          </Col>
          <Col span={12}>
            <Table
              size="small"
              // rowSelection={rowSelection}
              rowKey="name"
              bordered={false}
              pagination={false}
              columns={this.getExpandedColumns()}
              dataSource={record.new_values}
              loading={fetchIsLoading}
              defaultExpandAllRows
              title={() => (
                <>
                  <Row type="flex" justify="center" gutter={[0, 16]}>
                    <Col span={3}>
                      <Text strong>{intl.formatMessage({ id: 'new' })}</Text>
                    </Col>
                  </Row>
                </>
              )}
            />
          </Col>
        </Row>
      </Card>
    );
  }

  useOnTableChange(pagination, filters, sorter) {
    const { resetTimestamp } = this.props;

    const processedFilters = AntDesignTable.processFilters(filters);
    const processedSorts = AntDesignTable.processSorts(sorter);

    resetTimestamp(pagination.current, processedSorts, processedFilters, pagination.pageSize);
  }

  render() {
    const {
      intl,
      resetTimestamp,
      currentPage,
      pageSize,
      total,
      documents,
      fetchIsLoading,
      expandedRows,
      setExpandedRows,
      historyGoBack
    } = this.props;

    return (
      <>
        <Table
          size="small"
          // rowSelection={rowSelection}
          rowKey="id"
          pagination={{
            current: currentPage,
            pageSize: parseInt(pageSize, 10),
            total,
            showTotal: () => `${total} items`
          }}
          columns={this.getDocumentColumns()}
          dataSource={documents}
          loading={fetchIsLoading}
          bordered
          onChange={this.useOnTableChange}
          expandedRowRender={this.showExpandedRow}
          expandedRowKeys={expandedRows}
          onExpandedRowsChange={rowKeys => {
            setExpandedRows(rowKeys);
          }}
          title={() => (
            <>
              <Row type="flex" justify="space-between" gutter={[0, 16]}>
                <Col span={2}>
                  <Button
                    type="primary"
                    // disabled={!isValid}
                    loading={fetchIsLoading}
                    onClick={() => resetTimestamp()}
                    icon={<ReloadOutlined />}
                  />
                </Col>
                <Col span={2}>
                  <Button
                    type="primary"
                    // disabled={!isValid}
                    loading={fetchIsLoading}
                    onClick={historyGoBack}
                    icon={<ArrowLeftOutlined />}
                  >
                    {intl.formatMessage({
                      id: 'back'
                    })}
                  </Button>
                </Col>
              </Row>
            </>
          )}
          scroll={{ x: 950 }}
        />
      </>
    );
  }
}

AuditRes01Table.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  resetTimestamp: PropTypes.func,

  documents: PropTypes.arrayOf(PropTypes.object),

  sorts: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  filters: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),

  currentPage: PropTypes.number,
  pageSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  total: PropTypes.number,
  fetchIsLoading: PropTypes.bool,

  resType: PropTypes.string,
  fetchAuditRes01: PropTypes.func,

  expandedRows: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  setExpandedRows: PropTypes.func,

  historyGoBack: PropTypes.func
};

AuditRes01Table.defaultProps = {
  intl: {},
  resetTimestamp() {},

  documents: [],

  sorts: {},
  filters: {},

  currentPage: 1,
  pageSize: '20',
  total: 0,
  fetchIsLoading: false,

  resType: '',
  fetchAuditRes01() {},

  expandedRows: [],
  setExpandedRows() {},

  historyGoBack() {}
};

const mapStateToProps = state => ({
  timestamp: state.auditRes01.timestamp,

  documents: state.auditRes01.documents,

  sorts: state.auditRes01.sorts,
  filters: state.auditRes01.filters,

  currentPage: state.auditRes01.currentPage,
  pageSize: state.auditRes01.pageSize,
  total: state.auditRes01.total,
  fetchIsLoading: state.auditRes01.fetchIsLoading,

  resType: state.auditRes01.resType,
  resId: state.auditRes01.resId,
  auditType: state.auditRes01.auditType,

  expandedRows: state.auditRes01.expandedRows
});

const mapDispatchToProps = dispatch => ({
  resetTimestamp: (currentPage, sorts, filters, pageSize) =>
    dispatch(AuditRes01Actions.auditRes01ResetTimestamp(currentPage, sorts, filters, pageSize)),

  fetchAuditRes01: (currentPage, sorts, filters, pageSize) =>
    dispatch(AuditRes01Actions.auditRes01FetchAuditRes01(currentPage, sorts, filters, pageSize)),

  setExpandedRows: expandedRows =>
    dispatch(AuditRes01Actions.auditRes01SetExpandedRows(expandedRows)),

  historyGoBack: () => dispatch(AppActions.appHistoryGoBack())
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AuditRes01Table));
