import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  locationDetailResetModelTimestamp: null,
  locationDetailSetResId: ['resId'],

  locationDetailInitModel: null,
  locationDetailShowModel: ['resId'],
  locationDetailShowModelSuccess: ['model'],

  locationDetailUpdateModel: ['formikBag', 'model'],
  locationDetailCreateModel: ['formikBag', 'model'],
  locationDetailUpdateModelSuccess: ['model'],

  locationDetailShowModelLoading: ['boolean']
});

export const LocationDetailTypes = Types;
export default Creators;
