/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { GdsRcptFServ01Types } from './Actions';
import INITIAL_STATE from './InitialState';

export const gdsRcptFServ01ShowSyncSettingLoading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const gdsRcptFServ01ShowSyncSettingSuccess = (state, { syncSetting }) => ({
  ...state,
  syncSetting
});

export const gdsRcptFServ01ShowBatchJobStatusSuccess = (state, { batchJobStatus }) => ({
  ...state,
  batchJobStatus
});

export const gdsRcptFServ01ExportLoading = (state, { boolean }) => ({
  ...state,
  exportIsLoading: boolean
});

export const gdsRcptFServ01ResetTimestamp = (state, { currentPage, sorts, filters, pageSize }) => ({
  ...state,
  currentPage: currentPage || state.currentPage,
  sorts: sorts || state.sorts,
  filters: filters || state.filters,
  pageSize: pageSize || state.pageSize,
  timestamp: Date.now()
  // selectedDocuments: INITIAL_STATE.selectedDocuments
});

export const gdsRcptFServ01FetchGdsRcptFServ01Loading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const gdsRcptFServ01FetchGdsRcptFServ01Success = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  documents,
  currentPage,
  lastPage,
  total,
  pageSize
});

export const gdsRcptFServ01AddSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = state.selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: [
      ...state.selectedDocuments,
      ...selectedDocuments.filter(data => {
        return !selectIds.includes(data.id);
      })
    ]
  };
};

export const gdsRcptFServ01RemoveSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: state.selectedDocuments.filter(data => {
      return !selectIds.includes(data.id);
    })
  };
};

export const gdsRcptFServ01SetWorkspaceVisible = (state, { boolean }) => ({
  ...state,
  workspaceIsVisible: boolean
});

export const gdsRcptFServ01ExportSuccess = state => ({
  ...state,
  timestamp: Date.now(),
  selectedDocuments: []
});

export const gdsRcptFServ01SetExpandedRows = (state, { expandedRows }) => ({
  ...state,
  expandedRows
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [GdsRcptFServ01Types.GDS_RCPT_F_SERV01_SHOW_SYNC_SETTING_LOADING]: gdsRcptFServ01ShowSyncSettingLoading,
  [GdsRcptFServ01Types.GDS_RCPT_F_SERV01_SHOW_SYNC_SETTING_SUCCESS]: gdsRcptFServ01ShowSyncSettingSuccess,
  [GdsRcptFServ01Types.GDS_RCPT_F_SERV01_SHOW_BATCH_JOB_STATUS_SUCCESS]: gdsRcptFServ01ShowBatchJobStatusSuccess,

  [GdsRcptFServ01Types.GDS_RCPT_F_SERV01_RESET_TIMESTAMP]: gdsRcptFServ01ResetTimestamp,
  [GdsRcptFServ01Types.GDS_RCPT_F_SERV01_FETCH_GDS_RCPT_F_SERV01_LOADING]: gdsRcptFServ01FetchGdsRcptFServ01Loading,
  [GdsRcptFServ01Types.GDS_RCPT_F_SERV01_FETCH_GDS_RCPT_F_SERV01_SUCCESS]: gdsRcptFServ01FetchGdsRcptFServ01Success,
  [GdsRcptFServ01Types.GDS_RCPT_F_SERV01_ADD_SELECTED_DOCUMENTS]: gdsRcptFServ01AddSelectedDocuments,
  [GdsRcptFServ01Types.GDS_RCPT_F_SERV01_REMOVE_SELECTED_DOCUMENTS]: gdsRcptFServ01RemoveSelectedDocuments,
  [GdsRcptFServ01Types.GDS_RCPT_F_SERV01_SET_WORKSPACE_VISIBLE]: gdsRcptFServ01SetWorkspaceVisible,
  [GdsRcptFServ01Types.GDS_RCPT_F_SERV01_EXPORT_LOADING]: gdsRcptFServ01ExportLoading,
  [GdsRcptFServ01Types.GDS_RCPT_F_SERV01_EXPORT_SUCCESS]: gdsRcptFServ01ExportSuccess,

  [GdsRcptFServ01Types.GDS_RCPT_F_SERV01_SET_EXPANDED_ROWS]: gdsRcptFServ01SetExpandedRows
});
