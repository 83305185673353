import React from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';

import { ArrowLeftOutlined, UndoOutlined, SaveOutlined } from '@ant-design/icons';

import { Button, Row, Col, Typography, Card } from 'antd';
import treeChanges from 'tree-changes';
import { FormikInput, FormikButton } from '../../Components/Formik';
import CompanyDetailActions from '../../Stores/CompanyDetail/Actions';
import AppActions from '../../Stores/App/Actions';

const { Text } = Typography;

class ModelForm extends React.PureComponent {
  componentDidMount() {
    const { resId, resetTimestamp } = this.props;

    if (resId > 0) {
      resetTimestamp();
    }
  }

  componentDidUpdate(prevProps) {
    const { resId, resetTimestamp, initModel, showModel } = this.props;

    const { changed } = treeChanges(prevProps, this.props);

    if (changed('resId')) {
      resetTimestamp();
    }
    if (changed('timestamp')) {
      if (resId <= 0) {
        initModel();
      } else {
        showModel(resId);
      }
    }
  }

  componentWillUnmount() {}

  render() {
    const {
      intl,
      match,
      model,
      createModel,
      updateModel,
      historyGoBack,
      modelIsLoading
    } = this.props;

    const disabled = false;

    return (
      <Formik
        enableReinitialize
        initialValues={model}
        onSubmit={(values, formikBag) => {
          if (match.params.action === 'create') {
            createModel(formikBag, values);
          } else {
            updateModel(formikBag, values);
          }
        }}
        validationSchema={Yup.object().shape({
          code: Yup.string().required(intl.formatMessage({ id: 'code_is_required' })),
          name_01: Yup.string().required(intl.formatMessage({ id: 'name_is_required' }))
        })}
      >
        {({
          // values,
          // handleChange,
          // errors,
          // setFieldTouched,
          dirty,
          // touched,
          isSubmitting,
          // isValid,
          handleSubmit,
          handleReset
        }) => (
          <Form>
            <Card title={intl.formatMessage({ id: 'general' })}>
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'code' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="code"
                    component={FormikInput}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'name' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="name_01"
                    component={FormikInput}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
              </Row>
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3} />
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="name_02"
                    component={FormikInput}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'unit_no' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="unit_no"
                    component={FormikInput}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
              </Row>
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'building_name' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="building_name"
                    component={FormikInput}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
              </Row>
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'street_name' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="street_name"
                    component={FormikInput}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
              </Row>
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'district_01' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="district_01"
                    component={FormikInput}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
              </Row>
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'district_02' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="district_02"
                    component={FormikInput}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
              </Row>
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'postcode' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="postcode"
                    component={FormikInput}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
              </Row>
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'state_name' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="state_name"
                    component={FormikInput}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
              </Row>
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'country_name' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="country_name"
                    component={FormikInput}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
              </Row>
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'attention' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="attention"
                    component={FormikInput}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
              </Row>
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'phone' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={10}>
                  <Field
                    name="phone_01"
                    component={FormikInput}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
                <Col span={10}>
                  <Field
                    name="phone_02"
                    component={FormikInput}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
              </Row>
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'fax' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={10}>
                  <Field
                    name="fax_01"
                    component={FormikInput}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
                <Col span={10}>
                  <Field
                    name="fax_01"
                    component={FormikInput}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
              </Row>
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'email' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={10}>
                  <Field
                    name="email_01"
                    component={FormikInput}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
                <Col span={10}>
                  <Field
                    name="email_02"
                    component={FormikInput}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
              </Row>
            </Card>

            <Card>
              <Row type="flex" justify="space-between" gutter={[0, 16]}>
                <Col span={8}>
                  <Button
                    type="primary"
                    // disabled={!isValid}
                    loading={isSubmitting}
                    onClick={historyGoBack}
                    icon={<ArrowLeftOutlined />}
                  >
                    {intl.formatMessage({
                      id: 'back'
                    })}
                  </Button>
                </Col>
                <Col span={6}>
                  <Button
                    type="primary"
                    disabled={!dirty}
                    loading={isSubmitting || modelIsLoading}
                    onClick={handleReset}
                    icon={<UndoOutlined />}
                  >
                    {intl.formatMessage({ id: 'reset' })}
                  </Button>
                  <Field
                    name="submit_action"
                    type="primary"
                    value="update"
                    component={FormikButton}
                    // disabled={values.status >= 50}
                    loading={isSubmitting || modelIsLoading}
                    onClick={handleSubmit}
                    label={intl.formatMessage({
                      id: match.params.action === 'create' ? 'create' : 'update'
                    })}
                    icon={<SaveOutlined />}
                  />
                </Col>
              </Row>
            </Card>
          </Form>
        )}
      </Formik>
    );
  }
}

ModelForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object,
  resetTimestamp: PropTypes.func,

  initModel: PropTypes.func,
  showModel: PropTypes.func,
  createModel: PropTypes.func,
  updateModel: PropTypes.func,
  historyGoBack: PropTypes.func,
  resId: PropTypes.number,
  model: PropTypes.shape({
    status: PropTypes.number
  }),
  modelIsLoading: PropTypes.bool
};

ModelForm.defaultProps = {
  intl: {},
  match: {},
  resetTimestamp() {},

  initModel() {},
  showModel() {},
  createModel() {},
  updateModel() {},
  historyGoBack() {},
  resId: 0,
  model: { code: '' },
  modelIsLoading: false
};

const mapStateToProps = state => ({
  apiUrl: state.app.apiUrl,
  timestamp: state.companyDetail.modelTimestamp,
  resId: state.companyDetail.resId,
  model: state.companyDetail.model,
  modelIsLoading: state.companyDetail.modelIsLoading
});

const mapDispatchToProps = dispatch => ({
  resetTimestamp: () => dispatch(CompanyDetailActions.companyDetailResetModelTimestamp()),
  initModel: () => dispatch(CompanyDetailActions.companyDetailInitModel()),
  showModel: resId => dispatch(CompanyDetailActions.companyDetailShowModel(resId)),

  updateModel: (formikBag, model) =>
    dispatch(CompanyDetailActions.companyDetailUpdateModel(formikBag, model)),
  createModel: (formikBag, model) =>
    dispatch(CompanyDetailActions.companyDetailCreateModel(formikBag, model)),

  historyGoBack: () => dispatch(AppActions.appHistoryGoBack())
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ModelForm));
