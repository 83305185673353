import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  inbOrd06ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  inbOrd06GoToPrint: ['document'],
  inbOrd06GoToDocument: ['hdrId'],
  inbOrd06NewDocument: null,
  inbOrd06FetchInbOrd06: ['currentPage', 'sorts', 'filters', 'pageSize'],
  inbOrd06FetchInbOrd06Loading: ['boolean'],
  inbOrd06FetchInbOrd06Success: ['documents', 'currentPage', 'lastPage', 'total', 'pageSize'],
  inbOrd06AddSelectedDocuments: ['selectedDocuments'],
  inbOrd06RemoveSelectedDocuments: ['selectedDocuments'],
  inbOrd06SetWorkspaceVisible: ['boolean'],
  inbOrd06CreateInbOrd06: ['hdrIds'],
  inbOrd06CreateInbOrd06Loading: ['boolean'],
  inbOrd06CreateInbOrd06Success: ['newDocuments']
});

export const InbOrd06Types = Types;
export default Creators;
