/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */
import { createReducer } from 'reduxsauce';
import { GdsIssIndexTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const gdsIssIndexResetTimestamp = (state, { currentPage, sorts, filters, pageSize }) => ({
  ...state,
  currentPage: currentPage || state.currentPage,
  sorts: sorts || state.sorts,
  filters: filters || state.filters,
  pageSize: pageSize || state.pageSize,
  timestamp: Date.now()
  // selectedDocuments: INITIAL_STATE.selectedDocuments
});

export const gdsIssIndexFetchGdsIssIndexLoading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const gdsIssIndexFetchGdsIssIndexSuccess = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  documents,
  currentPage,
  lastPage,
  total,
  pageSize
});

export const gdsIssIndexAddSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = state.selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: [
      ...state.selectedDocuments,
      ...selectedDocuments.filter(data => {
        return !selectIds.includes(data.id);
      })
    ]
  };
};

export const gdsIssIndexRemoveSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: state.selectedDocuments.filter(data => {
      return !selectIds.includes(data.id);
    })
  };
};

export const gdsIssIndexSetWorkspaceVisible = (state, { boolean }) => ({
  ...state,
  workspaceIsVisible: boolean
});

export const gdsIssIndexCreateGdsIssIndexLoading = (state, { boolean }) => ({
  ...state,
  createIsLoading: boolean
});

export const gdsIssIndexCreateGdsIssIndexSuccess = (state, { newDocuments }) => ({
  ...state,
  timestamp: Date.now(),
  selectedDocuments: [],
  newDocuments
});

export const gdsIssIndexWorkspaceLoading = (state, { boolean }) => ({
  ...state,
  workspaceIsLoading: boolean
});

export const gdsIssIndexResetGdsIssFServ01Success = state => ({
  ...state,
  timestamp: Date.now(),
  selectedDocuments: []
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [GdsIssIndexTypes.GDS_ISS_INDEX_RESET_TIMESTAMP]: gdsIssIndexResetTimestamp,
  [GdsIssIndexTypes.GDS_ISS_INDEX_FETCH_GDS_ISS_INDEX_LOADING]: gdsIssIndexFetchGdsIssIndexLoading,
  [GdsIssIndexTypes.GDS_ISS_INDEX_FETCH_GDS_ISS_INDEX_SUCCESS]: gdsIssIndexFetchGdsIssIndexSuccess,
  [GdsIssIndexTypes.GDS_ISS_INDEX_ADD_SELECTED_DOCUMENTS]: gdsIssIndexAddSelectedDocuments,
  [GdsIssIndexTypes.GDS_ISS_INDEX_REMOVE_SELECTED_DOCUMENTS]: gdsIssIndexRemoveSelectedDocuments,
  [GdsIssIndexTypes.GDS_ISS_INDEX_SET_WORKSPACE_VISIBLE]: gdsIssIndexSetWorkspaceVisible,
  [GdsIssIndexTypes.GDS_ISS_INDEX_CREATE_GDS_ISS_INDEX_LOADING]: gdsIssIndexCreateGdsIssIndexLoading,
  [GdsIssIndexTypes.GDS_ISS_INDEX_CREATE_GDS_ISS_INDEX_SUCCESS]: gdsIssIndexCreateGdsIssIndexSuccess,
  [GdsIssIndexTypes.GDS_ISS_INDEX_WORKSPACE_LOADING]: gdsIssIndexWorkspaceLoading,
  [GdsIssIndexTypes.GDS_ISS_INDEX_RESET_GDS_ISS_F_SERV01_SUCCESS]: gdsIssIndexResetGdsIssFServ01Success
});
