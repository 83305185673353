import { put, call, select, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import ApiService from '../Services/ApiService';
import AppActions from '../Stores/App/Actions';
import GdsRcptDetailReportActions, {
  GdsRcptDetailReportTypes
} from '../Stores/GdsRcptDetailReport/Actions';

const getAppStore = state => state.app;

export function* gdsRcptDetailReportInitGdsRcptDetail() {
  try {
    yield put(GdsRcptDetailReportActions.gdsRcptDetailReportReportLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `salesReport/initGdsRcptDetail/${app.curSiteFlowId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(
        GdsRcptDetailReportActions.gdsRcptDetailReportInitGdsRcptDetailSuccess(result.data)
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(GdsRcptDetailReportActions.gdsRcptDetailReportReportLoading(false));
  }
}

export function* gdsRcptDetailReportGdsRcptDetail({ formikBag, criteria }) {
  try {
    formikBag.setSubmitting(true);
    yield put(GdsRcptDetailReportActions.gdsRcptDetailReportReportLoading(true));

    const objCriteria = {};
    if ('start_date' in criteria) {
      objCriteria.start_date = criteria.start_date;
    }
    if ('end_date' in criteria) {
      objCriteria.end_date = criteria.end_date;
    }
    if ('division_ids_select2' in criteria) {
      const values = criteria.division_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.division_ids = values.map(entry => {
          return entry.value;
        });
      }
    }
    if ('biz_partner_ids_select2' in criteria) {
      const values = criteria.biz_partner_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.biz_partner_ids = values.map(entry => {
          return entry.value;
        });
      }
    }
    if ('debtor_ids_select2' in criteria) {
      const values = criteria.debtor_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.debtor_ids = values.map(entry => {
          return entry.value;
        });
      }
    }

    let columns = [];
    if (criteria.columns.target.length > 0) {
      columns = criteria.columns.target.map(entry => {
        return entry.column;
      });
    }

    const app = yield select(getAppStore);
    const postData = {
      sorts: [],
      criteria: objCriteria,
      columns
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `salesReport/gdsRcptDetail/${app.curSiteFlowId}`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      yield put(
        GdsRcptDetailReportActions.gdsRcptDetailReportGdsRcptDetailSuccess(
          criteria,
          result.data.data
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
    yield put(GdsRcptDetailReportActions.gdsRcptDetailReportReportLoading(false));
  }
}

export function* gdsRcptDetailReportFetchDivisionOptions({ search }) {
  try {
    yield put(GdsRcptDetailReportActions.gdsRcptDetailReportFetchDivisionOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `division/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.name_01}`
      }));

      yield put(GdsRcptDetailReportActions.gdsRcptDetailReportFetchDivisionOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(GdsRcptDetailReportActions.gdsRcptDetailReportFetchDivisionOptionLoading(false));
  }
}

export function* gdsRcptDetailReportFetchBizPartnerOptions({ search }) {
  try {
    yield put(GdsRcptDetailReportActions.gdsRcptDetailReportFetchBizPartnerOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `bizPartner/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.company_name_01}`
      }));

      yield put(
        GdsRcptDetailReportActions.gdsRcptDetailReportFetchBizPartnerOptionSuccess(options)
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(GdsRcptDetailReportActions.gdsRcptDetailReportFetchBizPartnerOptionLoading(false));
  }
}

export function* gdsRcptDetailReportFetchDebtorOptions({ search }) {
  try {
    yield put(GdsRcptDetailReportActions.gdsRcptDetailReportFetchDebtorOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      debtorType: 1, // 0 = normal, 1 = principal
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `debtor/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.company_name_01} ${d.company_name_02}`
      }));

      yield put(GdsRcptDetailReportActions.gdsRcptDetailReportFetchDebtorOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(GdsRcptDetailReportActions.gdsRcptDetailReportFetchDebtorOptionLoading(false));
  }
}

export const saga = [
  takeLatest(
    GdsRcptDetailReportTypes.GDS_RCPT_DETAIL_REPORT_INIT_GDS_RCPT_DETAIL,
    gdsRcptDetailReportInitGdsRcptDetail
  ),
  takeLatest(
    GdsRcptDetailReportTypes.GDS_RCPT_DETAIL_REPORT_GDS_RCPT_DETAIL,
    gdsRcptDetailReportGdsRcptDetail
  ),

  takeLatest(
    GdsRcptDetailReportTypes.GDS_RCPT_DETAIL_REPORT_FETCH_DIVISION_OPTIONS,
    gdsRcptDetailReportFetchDivisionOptions
  ),
  takeLatest(
    GdsRcptDetailReportTypes.GDS_RCPT_DETAIL_REPORT_FETCH_BIZ_PARTNER_OPTIONS,
    gdsRcptDetailReportFetchBizPartnerOptions
  ),

  takeLatest(
    GdsRcptDetailReportTypes.GDS_RCPT_DETAIL_REPORT_FETCH_DEBTOR_OPTIONS,
    gdsRcptDetailReportFetchDebtorOptions
  )
];
