import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  stkRcpt03ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  stkRcpt03GoToDocument: ['hdrId'],
  stkRcpt03FetchStkRcpt03: ['currentPage', 'sorts', 'filters', 'pageSize'],
  stkRcpt03FetchStkRcpt03Loading: ['boolean'],
  stkRcpt03FetchStkRcpt03Success: ['documents', 'currentPage', 'lastPage', 'total', 'pageSize'],
  stkRcpt03AddSelectedDocuments: ['selectedDocuments'],
  stkRcpt03RemoveSelectedDocuments: ['selectedDocuments'],
  stkRcpt03SetWorkspaceVisible: ['boolean'],
  stkRcpt03CreateStkRcpt03: ['hdrIds', 'toStorageBinId'],
  stkRcpt03CreateStkRcpt03Loading: ['boolean'],
  stkRcpt03CreateStkRcpt03Success: ['newDocuments']
});

export const StkRcpt03Types = Types;
export default Creators;
