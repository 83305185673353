/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { TrfToStoreProcessTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const trfToStoreProcessFetchTrfToStoreProcessLoading = (state, { boolean }) => ({
  ...state,
  processIsLoading: boolean
});

export const trfToStoreProcessFetchTrfToStoreProcessSuccess = (state, { process }) => ({
  ...state,
  process
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [TrfToStoreProcessTypes.TRF_TO_STORE_PROCESS_FETCH_TRF_TO_STORE_PROCESS_LOADING]: trfToStoreProcessFetchTrfToStoreProcessLoading,
  [TrfToStoreProcessTypes.TRF_TO_STORE_PROCESS_FETCH_TRF_TO_STORE_PROCESS_SUCCESS]: trfToStoreProcessFetchTrfToStoreProcessSuccess
});
