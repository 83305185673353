import { put, call, select, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import ApiService from '../Services/ApiService';
import AppActions from '../Stores/App/Actions';
import LoadList01Actions, { LoadList01Types } from '../Stores/LoadList01/Actions';

const getAppStore = state => state.app;

const getLoadList01Store = state => state.loadList01;

export function* loadList01FetchTransportOptions({ search }) {
  try {
    yield put(LoadList01Actions.loadList01FetchTransportOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId: app.curSiteFlowId,
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `transport/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({ value: d.id, label: `${d.code}` }));

      yield put(LoadList01Actions.loadList01FetchTransportOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(LoadList01Actions.loadList01FetchTransportOptionLoading(false));
  }
}

export function* loadList01FetchDriver01Options({ search }) {
  try {
    yield put(LoadList01Actions.loadList01FetchDriver01OptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId: app.curSiteFlowId,
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `user/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.username} ${d.first_name} ${d.last_name}`
      }));

      yield put(LoadList01Actions.loadList01FetchDriver01OptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(LoadList01Actions.loadList01FetchDriver01OptionLoading(false));
  }
}

export function* loadList01FetchDeliveryMan01Options({ search }) {
  try {
    yield put(LoadList01Actions.loadList01FetchDeliveryMan01OptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId: app.curSiteFlowId,
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `user/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.username} ${d.first_name} ${d.last_name}`
      }));

      yield put(LoadList01Actions.loadList01FetchDeliveryMan01OptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(LoadList01Actions.loadList01FetchDeliveryMan01OptionLoading(false));
  }
}

export function* loadList01FetchDeliveryMan02Options({ search }) {
  try {
    yield put(LoadList01Actions.loadList01FetchDeliveryMan02OptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId: app.curSiteFlowId,
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `user/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.username} ${d.first_name} ${d.last_name}`
      }));

      yield put(LoadList01Actions.loadList01FetchDeliveryMan02OptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(LoadList01Actions.loadList01FetchDeliveryMan02OptionLoading(false));
  }
}

export function* loadList01ScanBarcode({ value }) {
  try {
    yield put(LoadList01Actions.loadList01FetchLoadList01Loading(true));

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId: app.curSiteFlowId,
      value
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `loadList/addDetailsByBarcode`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const loadList01 = yield select(getLoadList01Store);

      const { outbOrdHdrs: oldOutbOrdHdrs, inbOrdHdrs: oldInbOrdHdrs } = loadList01;
      const { outb_ord_hdrs: retOutbOrdHdrs, inb_ord_hdrs: retInbOrdHdrs } = result.data;

      // remove outbOrdHdrs if existed
      const filterOutbOrdHdrs = oldOutbOrdHdrs.filter(oldOutbOrdHdr => {
        const foundHdrId = retOutbOrdHdrs.reduce((lastId, data, index) => {
          if (oldOutbOrdHdr.id === data.id) {
            retOutbOrdHdrs.splice(index, 1);
            return data.id;
          }
          return lastId;
        }, -1);

        return foundHdrId === -1;
      });

      // append the remaining retOutbOrdHdrs
      const newOutbOrdHdrs = [];
      filterOutbOrdHdrs.forEach(data => {
        newOutbOrdHdrs.push(data);
      });
      retOutbOrdHdrs.forEach(data => {
        newOutbOrdHdrs.push(data);
      });

      // remove inbOrdHdrs if existed
      const filterInbOrdHdrs = oldInbOrdHdrs.filter(oldInbOrdHdr => {
        const foundHdrId = retInbOrdHdrs.reduce((lastId, data, index) => {
          if (oldInbOrdHdr.id === data.id) {
            retInbOrdHdrs.splice(index, 1);
            return data.id;
          }
          return lastId;
        }, -1);

        return foundHdrId === -1;
      });

      // append the remaining retInbOrdHdrs
      const newInbOrdHdrs = [];
      filterInbOrdHdrs.forEach(data => {
        newInbOrdHdrs.push(data);
      });
      retInbOrdHdrs.forEach(data => {
        newInbOrdHdrs.push(data);
      });

      yield put(LoadList01Actions.loadList01ScanBarcodeSuccess(newOutbOrdHdrs, newInbOrdHdrs));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(LoadList01Actions.loadList01FetchLoadList01Loading(false));
  }
}

export function* loadList01SearchDocument({ search }) {
  if (search.length < 5) {
    return;
  }
  try {
    yield put(LoadList01Actions.loadList01SearchDocumentLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId: app.curSiteFlowId,
      search
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `loadList/searchDocument`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: `${d.res_type}${d.id}`,
        label: `${d.doc_code} ${d.doc_date} ${d.ref_code_01}`,
        docCode: d.doc_code,
        docDate: d.doc_date,
        refCode01: d.ref_code_01
      }));

      yield put(LoadList01Actions.loadList01SearchDocumentSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(LoadList01Actions.loadList01SearchDocumentLoading(false));
  }
}

export function* loadList01UpOutbOrd({ outbOrdHdr }) {
  yield put(LoadList01Actions.loadList01FetchLoadList01Loading(true));

  const loadList01 = yield select(getLoadList01Store);
  const { outbOrdHdrs } = loadList01;

  const oldIndex = outbOrdHdrs.indexOf(outbOrdHdr);
  const newIndex = oldIndex - 1;

  if (newIndex >= 0) {
    outbOrdHdrs.splice(newIndex, 0, outbOrdHdrs.splice(oldIndex, 1)[0]);
    yield put(LoadList01Actions.loadList01UpdateOutbOrds(outbOrdHdrs));
  }

  yield put(LoadList01Actions.loadList01FetchLoadList01Loading(false));
}

export function* loadList01DownOutbOrd({ outbOrdHdr }) {
  yield put(LoadList01Actions.loadList01FetchLoadList01Loading(true));

  const loadList01 = yield select(getLoadList01Store);
  const { outbOrdHdrs } = loadList01;

  const oldIndex = outbOrdHdrs.indexOf(outbOrdHdr);
  const newIndex = oldIndex + 1;

  if (newIndex < outbOrdHdrs.length) {
    outbOrdHdrs.splice(newIndex, 0, outbOrdHdrs.splice(oldIndex, 1)[0]);
    yield put(LoadList01Actions.loadList01UpdateOutbOrds(outbOrdHdrs));
  }

  yield put(LoadList01Actions.loadList01FetchLoadList01Loading(false));
}

export function* loadList01UpInbOrd({ inbOrdHdr }) {
  yield put(LoadList01Actions.loadList01FetchLoadList01Loading(true));

  const loadList01 = yield select(getLoadList01Store);
  const { inbOrdHdrs } = loadList01;

  const oldIndex = inbOrdHdrs.indexOf(inbOrdHdr);
  const newIndex = oldIndex - 1;

  if (newIndex >= 0) {
    inbOrdHdrs.splice(newIndex, 0, inbOrdHdrs.splice(oldIndex, 1)[0]);
    yield put(LoadList01Actions.loadList01UpdateInbOrds(inbOrdHdrs));
  }

  yield put(LoadList01Actions.loadList01FetchLoadList01Loading(false));
}

export function* loadList01DownInbOrd({ inbOrdHdr }) {
  yield put(LoadList01Actions.loadList01FetchLoadList01Loading(true));

  const loadList01 = yield select(getLoadList01Store);
  const { inbOrdHdrs } = loadList01;

  const oldIndex = inbOrdHdrs.indexOf(inbOrdHdr);
  const newIndex = oldIndex + 1;

  if (newIndex < inbOrdHdrs.length) {
    inbOrdHdrs.splice(newIndex, 0, inbOrdHdrs.splice(oldIndex, 1)[0]);
    yield put(LoadList01Actions.loadList01UpdateInbOrds(inbOrdHdrs));
  }

  yield put(LoadList01Actions.loadList01FetchLoadList01Loading(false));
}

export function* loadList01CreateLoadList01({
  formikBag,
  formDetail,
  outbOrdHdrs: oldOutbOrdHdrs,
  inbOrdHdrs: oldinbOrdHdrs
}) {
  formikBag.setSubmitting(true);
  try {
    const app = yield select(getAppStore);

    let lineNo = 1;
    const outbOrdHdrs = oldOutbOrdHdrs.map(d => {
      lineNo += 1;
      return {
        line_no: lineNo,
        id: d.id
      };
    });

    lineNo = 1;
    const inbOrdHdrs = oldinbOrdHdrs.map(d => {
      lineNo += 1;
      return {
        line_no: lineNo,
        id: d.id
      };
    });

    const postData = {
      data: formDetail,
      outbOrdHdrs,
      inbOrdHdrs
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      'loadList/createProcess/LOAD_LIST_01',
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      yield put(LoadList01Actions.loadList01CreateLoadList01Success(result.data));
      formikBag.resetForm();

      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_SUCCESS_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export const saga = [
  takeLatest(LoadList01Types.LOAD_LIST01_FETCH_TRANSPORT_OPTIONS, loadList01FetchTransportOptions),
  takeLatest(LoadList01Types.LOAD_LIST01_FETCH_DRIVER01_OPTIONS, loadList01FetchDriver01Options),
  takeLatest(
    LoadList01Types.LOAD_LIST01_FETCH_DELIVERY_MAN01_OPTIONS,
    loadList01FetchDeliveryMan01Options
  ),
  takeLatest(
    LoadList01Types.LOAD_LIST01_FETCH_DELIVERY_MAN02_OPTIONS,
    loadList01FetchDeliveryMan02Options
  ),
  takeLatest(LoadList01Types.LOAD_LIST01_SCAN_BARCODE, loadList01ScanBarcode),
  takeLatest(LoadList01Types.LOAD_LIST01_SEARCH_DOCUMENT, loadList01SearchDocument),
  takeLatest(LoadList01Types.LOAD_LIST01_UP_OUTB_ORD, loadList01UpOutbOrd),
  takeLatest(LoadList01Types.LOAD_LIST01_DOWN_OUTB_ORD, loadList01DownOutbOrd),
  takeLatest(LoadList01Types.LOAD_LIST01_UP_INB_ORD, loadList01UpInbOrd),
  takeLatest(LoadList01Types.LOAD_LIST01_DOWN_INB_ORD, loadList01DownInbOrd),
  takeLatest(LoadList01Types.LOAD_LIST01_CREATE_LOAD_LIST01, loadList01CreateLoadList01)
];
