import { put, call, select, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import { push } from 'connected-react-router';
import ApiService from '../Services/ApiService';
import AppActions from '../Stores/App/Actions';
import SlsOrdIndexActions, { SlsOrdIndexTypes } from '../Stores/SlsOrdIndex/Actions';

const getAppStore = state => state.app;

export function* slsOrdIndexFetchSlsOrdIndex({ currentPage, sorts, filters, pageSize }) {
  try {
    yield put(SlsOrdIndexActions.slsOrdIndexFetchSlsOrdIndexLoading(true));

    const processedSorts = [];
    Object.entries(sorts).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      processedSorts.push(`${key}:${value === 'ascend' ? 'ASC' : 'DESC'}`);
    });

    const processedFilters = [];
    Object.entries(filters).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      if (value) {
        processedFilters.push(`${key}:${value}`);
      }
    });

    const app = yield select(getAppStore);
    const getData = {
      page: currentPage,
      sorts: processedSorts,
      filters: processedFilters,
      pageSize
    };

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `slsOrd/index/${app.curDivisionId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      // if nowCurrentPage is more than lastPage, then nowCurrentPage = lastPage
      let nowCurrentPage = result.data.current_page;
      if (nowCurrentPage > result.data.last_page) {
        nowCurrentPage = result.data.last_page;
      }

      yield put(
        SlsOrdIndexActions.slsOrdIndexFetchSlsOrdIndexSuccess(
          result.data.data,
          nowCurrentPage,
          result.data.last_page,
          result.data.total,
          result.data.per_page
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(SlsOrdIndexActions.slsOrdIndexFetchSlsOrdIndexLoading(false));
  }
}

export function* slsOrdIndexGoToDocument({ hdrId }) {
  const app = yield select(getAppStore);
  yield put(push(`${app.appPath}/slsOrdDetail/update/${hdrId}`));
}

export function* slsOrdIndexGoToAudit({ hdrId }) {
  const app = yield select(getAppStore);
  yield put(push(`${app.appPath}/auditResource/SLS_ORD/${hdrId}/SlsOrdHdr`));
}

export function* slsOrdIndexResetSlsOrdFServ02({ hdrIds }) {
  try {
    yield put(SlsOrdIndexActions.slsOrdIndexWorkspaceLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      hdrIds
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `slsOrd/fServProcess/RESET_SLS_ORD_F_SERV_02/${app.curSiteFlowId}/${app.curDivisionId}`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      yield put(SlsOrdIndexActions.slsOrdIndexResetSlsOrdFServ02Success());

      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_SUCCESS_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(SlsOrdIndexActions.slsOrdIndexWorkspaceLoading(false));
  }
}

export const saga = [
  takeLatest(SlsOrdIndexTypes.SLS_ORD_INDEX_FETCH_SLS_ORD_INDEX, slsOrdIndexFetchSlsOrdIndex),
  takeLatest(SlsOrdIndexTypes.SLS_ORD_INDEX_GO_TO_DOCUMENT, slsOrdIndexGoToDocument),
  takeLatest(SlsOrdIndexTypes.SLS_ORD_INDEX_GO_TO_AUDIT, slsOrdIndexGoToAudit),
  takeLatest(SlsOrdIndexTypes.SLS_ORD_INDEX_RESET_SLS_ORD_F_SERV02, slsOrdIndexResetSlsOrdFServ02)
];
