import { put, call, select, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import { replace } from 'connected-react-router';
import ApiService from '../Services/ApiService';
import AppActions from '../Stores/App/Actions';
import CompanyDetailActions, { CompanyDetailTypes } from '../Stores/CompanyDetail/Actions';

const getAppStore = state => state.app;

export function* companyDetailInitModel() {
  try {
    yield put(CompanyDetailActions.companyDetailShowModelLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `company/initModel`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(CompanyDetailActions.companyDetailShowModelSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(CompanyDetailActions.companyDetailShowModelLoading(false));
  }
}

export function* companyDetailShowModel({ resId }) {
  try {
    yield put(CompanyDetailActions.companyDetailShowModelLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `company/showModel/${resId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(CompanyDetailActions.companyDetailShowModelSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(CompanyDetailActions.companyDetailShowModelLoading(false));
  }
}

export function* companyDetailUpdateModel({ formikBag, model }) {
  formikBag.setSubmitting(true);
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: model
    };

    const result = yield call(
      ApiService.putApi, // function
      app.apiUrl,
      'company/updateModel',
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      const { model: retModel } = result.data;

      yield put(CompanyDetailActions.companyDetailUpdateModelSuccess(retModel));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* companyDetailCreateModel({ formikBag, model }) {
  formikBag.setSubmitting(true);
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: model
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `company/createModel`,
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      yield put(replace(`${app.appPath}/companyDetail/update/${result.data}`));
      yield put(CompanyDetailActions.companyDetailSetResId(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export const saga = [
  takeLatest(CompanyDetailTypes.COMPANY_DETAIL_INIT_MODEL, companyDetailInitModel),
  takeLatest(CompanyDetailTypes.COMPANY_DETAIL_SHOW_MODEL, companyDetailShowModel),

  takeLatest(CompanyDetailTypes.COMPANY_DETAIL_UPDATE_MODEL, companyDetailUpdateModel),
  takeLatest(CompanyDetailTypes.COMPANY_DETAIL_CREATE_MODEL, companyDetailCreateModel)
];
