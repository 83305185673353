/* eslint-disable prefer-spread */
import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { injectIntl } from 'react-intl';
import './styles.css';

import { ResponsiveBar } from '@nivo/bar';

import { Card, Row, Col, Typography } from 'antd';
import CustomBar from './CustomBar';
import SelectAveragesCheckBox from './SelectAveragesCheckBox';

const { Text } = Typography;

class LspSlsOrdFulfillmentChart extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      allowedKeys: ['doc_date', 'ord_created', 'ord_picked', 'ord_loaded', 'ord_delivered']
    };

    this.customToolTipRef = React.createRef(null);

    this.formatData = this.formatData.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.renderCustomBar = this.renderCustomBar.bind(this);
  }

  handleFilter(element) {
    const { allowedKeys } = this.state;

    const index = allowedKeys.indexOf(element);

    if (index < 0) {
      const newAllowedKeys = [...allowedKeys, element];
      this.setState({ allowedKeys: newAllowedKeys });
    } else {
      const newAllowedKeys = [...allowedKeys];
      newAllowedKeys.splice(index, 1);
      this.setState({ allowedKeys: newAllowedKeys });
    }
  }

  // eslint-disable-next-line class-methods-use-this
  formatData(data) {
    const str = data.toString().split('.');
    if (str[0].length >= 3) {
      str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
    }
    if (str[1] && str[1].length >= 5) {
      str[1] = str[1].replace(/(\d{3})/g, '$1 ');
    }

    return str.join('.');
  }

  renderCustomBar(customBarProps, heightToValueRatio, numOfDates) {
    return (
      <CustomBar
        customBarProps={customBarProps}
        tooltipRef={this.customToolTipRef}
        heightToValueRatio={Number.isFinite(heightToValueRatio) ? heightToValueRatio : 0}
        numOfDates={numOfDates}
      />
    );
  }

  render() {
    const {
      intl,
      lspSlsOrdFulfillmentChartDataIsLoading,
      lspSlsOrdFulfillmentChartData
    } = this.props;

    const { allowedKeys } = this.state;

    const theme = {
      axis: {
        ticks: {
          text: { fontSize: '14px' }
        }
      }
    };

    const keys = ['ord_created', 'ord_picked', 'ord_loaded', 'ord_delivered'];

    const colors = ['red', 'gold', 'blue', 'green'];

    const filteredData = lspSlsOrdFulfillmentChartData.map(element =>
      Object.keys(element)
        .filter(key => {
          if (key === 'averageData') return true;
          return allowedKeys.includes(key);
        })
        .reduce((obj, key1) => {
          return {
            ...obj,
            [key1]: element[key1]
          };
        }, {})
    );

    const numOfDates = filteredData.length;

    /*
    The total height of the graph is 440, taken from the declared div height minus the top and bottom margin. 
    The nivo package will scale the largest data point to a height of 440.
    The next segment of code finds the maximum data point for the entire data set.
    From this, we can find out the ratio of height (in px) to value (unit, kg, case, etc)
    CustomBar gets rendered for each data point, and finding the height to value ratio for each point is imprecise causing multiple average lines. So, we fix 1 height to value ratio for precision.
     */

    const maxOrdCreated =
      Math.max.apply(
        Math,
        filteredData.map(o => o.ord_created)
      ) || 0;

    const maxOrdPicked =
      Math.max.apply(
        Math,
        filteredData.map(o => o.ord_picked)
      ) || 0;

    const maxOrdLoaded =
      Math.max.apply(
        Math,
        filteredData.map(o => o.ord_loaded)
      ) || 0;

    const maxOrdDelivered =
      Math.max.apply(
        Math,
        filteredData.map(o => o.ord_delivered)
      ) || 0;

    const maxValue = Math.max(maxOrdCreated, maxOrdPicked, maxOrdLoaded, maxOrdDelivered);

    const heightToValueRatio = (600 - 80 - 80) / maxValue;

    // End segment for value to height ratio calculation.

    return (
      <Card
        loading={lspSlsOrdFulfillmentChartDataIsLoading}
        title={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <>
            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={1}>
                <Text>{intl.formatMessage({ id: 'avg' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={22}>
                <SelectAveragesCheckBox />
              </Col>
            </Row>
          </>
        }
      >
        <div style={{ height: 600, width: '100%', margin: '0 auto' }}>
          <ResponsiveBar
            margin={{ left: 70, top: 80, bottom: 80, right: 0 }}
            colors={colors}
            data={filteredData}
            indexBy="doc_date"
            groupMode="grouped"
            keys={keys}
            padding={0.1}
            axisLeft={{ format: v => this.formatData(v) }}
            axisBottom={{
              tickSize: 1,
              tickPadding: 10,
              tickRotation: -60
            }}
            barComponent={props => this.renderCustomBar(props, heightToValueRatio, numOfDates)}
            legends={[
              {
                data: keys.map((key, index) => {
                  return {
                    color: allowedKeys.includes(key) ? colors[index] : 'rgba(1, 1, 1, .1)',
                    id: key,
                    label: intl.formatMessage({ id: key })
                  };
                }),
                anchor: 'top',
                onClick: datum => this.handleFilter(datum.id),
                direction: 'row',
                justify: false,
                translateX: 0,
                translateY: -80,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: 'left-to-right',
                itemOpacity: 0.85,
                symbolSize: 20,
                effects: [
                  {
                    on: 'hover',
                    style: {
                      itemOpacity: 1
                    }
                  }
                ]
              }
            ]}
            theme={theme}
          />
          <div className="custom_tooltip" ref={this.customToolTipRef} />
        </div>
      </Card>
    );
  }
}

LspSlsOrdFulfillmentChart.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  lspSlsOrdFulfillmentChartData: PropTypes.arrayOf(PropTypes.object),
  lspSlsOrdFulfillmentChartDataIsLoading: PropTypes.bool
};

LspSlsOrdFulfillmentChart.defaultProps = {
  intl: {},
  lspSlsOrdFulfillmentChartData: [{}],
  lspSlsOrdFulfillmentChartDataIsLoading: false
};

const mapStateToProps = state => {
  return {
    lspSlsOrdFulfillmentChartData: state.lspSlsOrdFulfillmentChart.lspSlsOrdFulfillmentData,
    lspSlsOrdFulfillmentChartDataIsLoading:
      state.lspSlsOrdFulfillmentChart.lspSlsOrdFulfillmentDataIsLoading
  };
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(LspSlsOrdFulfillmentChart));
