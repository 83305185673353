import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  pickList01ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  pickList01GoToDocument: ['hdrId'],
  pickList01FetchPickList01: ['currentPage', 'sorts', 'filters', 'pageSize'],
  pickList01FetchPickList01Loading: ['boolean'],
  pickList01FetchPickList01Success: ['documents', 'currentPage', 'lastPage', 'total', 'pageSize'],
  pickList01AddSelectedDocuments: ['selectedDocuments'],
  pickList01RemoveSelectedDocuments: ['selectedDocuments'],
  pickList01SetWorkspaceVisible: ['boolean'],
  pickList01PrintPickList01: ['hdrIds'],
  pickList01CreatePickList01: ['hdrIds', 'toStorageBinId'],
  pickList01CreatePickList01Loading: ['boolean'],
  pickList01CreatePickList01Success: ['newDocuments'],

  pickList01FetchToStorageBinOptions: ['search'],
  pickList01FetchToStorageBinOptionLoading: ['boolean'],
  pickList01FetchToStorageBinOptionSuccess: ['options'],
  pickList01SetToStorageBinOption: ['option'],

  pickList01SetExpandedRows: ['expandedRows']
});

export const PickList01Types = Types;
export default Creators;
