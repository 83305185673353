import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  storageBinList01ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  storageBinList01GoToResource: ['hdrId'],
  storageBinList01GoToAudit: ['hdrId'],
  storageBinList01FetchStorageBinList01: ['currentPage', 'sorts', 'filters', 'pageSize'],
  storageBinList01FetchStorageBinList01Loading: ['boolean'],
  storageBinList01FetchStorageBinList01Success: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],
  storageBinList01AddSelectedResources: ['selectedResources'],
  storageBinList01RemoveSelectedResources: ['selectedResources'],
  storageBinList01SetWorkspaceVisible: ['boolean'],

  storageBinList01SetBarcodeVisible: ['boolean'],
  storageBinList01ShowBarcode: ['barcode', 'code', 'desc01', 'desc02'],

  storageBinList01PrintStorageBinList01: ['ids'],
  storageBinList01PrintStorageBinList01Loading: ['boolean'],

  storageBinList01NewResource: null
});

export const StorageBinList01Types = Types;
export default Creators;
