import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  gdsRcptIndexResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  gdsRcptIndexGoToPrint: ['document'],
  gdsRcptIndexGoToPrintHandlingUnit: ['document'],
  gdsRcptIndexGoToDocument: ['hdrId'],
  gdsRcptIndexGoToAudit: ['hdrId'],
  gdsRcptIndexFetchGdsRcptIndex: ['currentPage', 'sorts', 'filters', 'pageSize'],
  gdsRcptIndexFetchGdsRcptIndexLoading: ['boolean'],
  gdsRcptIndexFetchGdsRcptIndexSuccess: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],
  gdsRcptIndexAddSelectedDocuments: ['selectedDocuments'],
  gdsRcptIndexRemoveSelectedDocuments: ['selectedDocuments'],
  gdsRcptIndexSetWorkspaceVisible: ['boolean'],

  gdsRcptIndexWorkspaceLoading: ['boolean'],
  gdsRcptIndexResetGdsRcptFServ01: ['hdrIds'],
  gdsRcptIndexResetGdsRcptFServ01Success: null,

  gdsRcptIndexSetExpandedRows: ['expandedRows'],
  gdsRcptIndexFetchExpandedDetails: ['hdrId'],
  gdsRcptIndexFetchExpandedLoading: ['boolean'],
  gdsRcptIndexFetchExpandedSuccess: ['details']
});

export const GdsRcptIndexTypes = Types;
export default Creators;
