import { put, call, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
// import ApiService from '../Services/ApiService';
// import AppActions from '../Stores/App/Actions';
import RoleProcessActions, { RoleProcessTypes } from '../Stores/RoleProcess/Actions';

// const getAppStore = state => state.app;

export function* roleProcessFetchRoleProcess() {
  try {
    yield put(RoleProcessActions.roleProcessFetchRoleProcessLoading(true));

    const result = [
      { proc_type: 'ROLE_LIST_01', icon: 'fa fa-cubes' },
      { proc_type: 'ROLE_EXCEL_01', icon: 'fa fa-file-excel-o' }
    ];
    yield put(RoleProcessActions.roleProcessFetchRoleProcessSuccess(result));

    /*
    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `site/indexItemFlow/${app.curSiteFlowId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );
    
    if (result.isSuccess === true) {
      yield put(RoleProcessActions.roleProcessFetchRoleProcessSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
    */
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(RoleProcessActions.roleProcessFetchRoleProcessLoading(false));
  }
}

export const saga = [
  takeLatest(RoleProcessTypes.ROLE_PROCESS_FETCH_ROLE_PROCESS, roleProcessFetchRoleProcess)
];
