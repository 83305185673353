import { put, call, select, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import { push } from 'connected-react-router';
import ApiService from '../Services/ApiService';
import AppActions from '../Stores/App/Actions';
import ProdIssFServ01Actions, { ProdIssFServ01Types } from '../Stores/ProdIssFServ01/Actions';

const getAppStore = state => state.app;

export function* prodIssFServ01ShowSyncSetting() {
  try {
    yield put(ProdIssFServ01Actions.prodIssFServ01ShowSyncSettingLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `syncSetting/showDivisionSetting/PROD_ISS_F_SERV_01/${app.curDivisionId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(ProdIssFServ01Actions.prodIssFServ01ShowSyncSettingSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(ProdIssFServ01Actions.prodIssFServ01ShowSyncSettingLoading(false));
  }
}

export function* prodIssFServ01UpdateSyncSetting({ formikBag, syncSetting }) {
  formikBag.setSubmitting(true);
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: syncSetting
    };

    const result = yield call(
      ApiService.putApi, // function
      app.apiUrl,
      `syncSetting/updateDivisionSetting/PROD_ISS_F_SERV_01/${app.curDivisionId}`,
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_SUCCESS_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* prodIssFServ01ShowBatchJobStatus() {
  try {
    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `batchJobStatus/showBatchJobStatus/PROD_ISS_F_SERV_01`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(ProdIssFServ01Actions.prodIssFServ01ShowBatchJobStatusSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  }
}

export function* prodIssFServ01Export({ hdrIds }) {
  try {
    yield put(ProdIssFServ01Actions.prodIssFServ01ExportLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      divisionId: app.curDivisionId,
      hdrIds
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `prodIss/fServProcess/PROD_ISS_F_SERV_01/${app.curSiteFlowId}/${app.curDivisionId}`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      yield put(ProdIssFServ01Actions.prodIssFServ01ExportSuccess());

      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_SUCCESS_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(ProdIssFServ01Actions.prodIssFServ01ExportLoading(false));
  }
}

export function* prodIssFServ01GoToDocument({ hdrId }) {
  const app = yield select(getAppStore);
  yield put(push(`${app.appPath}/prodIssDetail/update/${hdrId}`));
}

export function* prodIssFServ01FetchProdIssFServ01({ currentPage, sorts, filters, pageSize }) {
  try {
    yield put(ProdIssFServ01Actions.prodIssFServ01FetchProdIssFServ01Loading(true));

    const processedSorts = [];
    Object.entries(sorts).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      processedSorts.push(`${key}:${value === 'ascend' ? 'ASC' : 'DESC'}`);
    });

    const processedFilters = [];
    Object.entries(filters).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      if (value) {
        processedFilters.push(`${key}:${value}`);
      }
    });

    const app = yield select(getAppStore);
    const getData = {
      page: currentPage,
      sorts: processedSorts,
      filters: processedFilters,
      pageSize
    };

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `prodIss/indexProcess/PROD_ISS_F_SERV_01/${app.curDivisionId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      // if nowCurrentPage is more than lastPage, then nowCurrentPage = lastPage
      let nowCurrentPage = result.data.current_page;
      if (nowCurrentPage > result.data.last_page) {
        nowCurrentPage = result.data.last_page;
      }

      yield put(
        ProdIssFServ01Actions.prodIssFServ01FetchProdIssFServ01Success(
          result.data.data,
          nowCurrentPage,
          result.data.last_page,
          result.data.total,
          result.data.per_page
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(ProdIssFServ01Actions.prodIssFServ01FetchProdIssFServ01Loading(false));
  }
}

export const saga = [
  takeLatest(
    ProdIssFServ01Types.PROD_ISS_F_SERV01_SHOW_BATCH_JOB_STATUS,
    prodIssFServ01ShowBatchJobStatus
  ),
  takeLatest(
    ProdIssFServ01Types.PROD_ISS_F_SERV01_SHOW_SYNC_SETTING,
    prodIssFServ01ShowSyncSetting
  ),

  takeLatest(
    ProdIssFServ01Types.PROD_ISS_F_SERV01_UPDATE_SYNC_SETTING,
    prodIssFServ01UpdateSyncSetting
  ),

  takeLatest(ProdIssFServ01Types.PROD_ISS_F_SERV01_GO_TO_DOCUMENT, prodIssFServ01GoToDocument),
  takeLatest(
    ProdIssFServ01Types.PROD_ISS_F_SERV01_FETCH_PROD_ISS_F_SERV01,
    prodIssFServ01FetchProdIssFServ01
  ),
  takeLatest(ProdIssFServ01Types.PROD_ISS_F_SERV01_EXPORT, prodIssFServ01Export)
];
