import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  transportProcessFetchTransportProcess: null,
  transportProcessFetchTransportProcessLoading: ['boolean'],
  transportProcessFetchTransportProcessSuccess: ['process']
});

export const TransportProcessTypes = Types;
export default Creators;
