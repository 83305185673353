import { put, call, select, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import ApiService from '../Services/ApiService';
import AppActions from '../Stores/App/Actions';
import LspSlsOrdExcel01Actions, { LspSlsOrdExcel01Types } from '../Stores/LspSlsOrdExcel01/Actions';

const getAppStore = state => state.app;

const getLspSlsOrdExcel01Store = state => state.lspSlsOrdExcel01;

export function* lspSlsOrdExcel01ShowBatchJobStatus() {
  try {
    const app = yield select(getAppStore);
    const lspSlsOrdExcel01 = yield select(getLspSlsOrdExcel01Store);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `batchJobStatus/showBatchJobStatus/${lspSlsOrdExcel01.strProcType}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(LspSlsOrdExcel01Actions.lspSlsOrdExcel01ShowBatchJobStatusSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  }
}

export function* lspSlsOrdExcel01UploadExcel({ formikBag, projectId, debtorId, slsOrdFile }) {
  try {
    formikBag.setSubmitting(true);
    yield put(LspSlsOrdExcel01Actions.lspSlsOrdExcel01UploadLoading(true));

    const app = yield select(getAppStore);

    yield put(LspSlsOrdExcel01Actions.lspSlsOrdExcel01SetStrProcType('LSP_SLS_ORD_EXCEL_01'));
    // eslint-disable-next-line no-undef
    const postData = new FormData();
    postData.append('file', slsOrdFile);

    const getData = {};

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `lspSlsOrd/uploadProcess/LSP_SLS_ORD_EXCEL_01/${app.curSiteFlowId}/${app.curDivisionId}/${projectId}/${debtorId}`,
      app.token,
      postData,
      getData,
      'multipart/form-data'
    );

    if (result.isSuccess === true) {
      // result.data is total
      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_SUCCESS_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
    yield put(LspSlsOrdExcel01Actions.lspSlsOrdExcel01UploadLoading(false));
  }
}

export function* lspSlsOrdExcel01FetchDebtorOptions({ search }) {
  try {
    yield put(LspSlsOrdExcel01Actions.lspSlsOrdExcel01FetchDebtorOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      debtorType: 1, // 0 = normal, 1 = principal
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `debtor/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.company_name_01} ${d.company_name_02}`
      }));

      yield put(LspSlsOrdExcel01Actions.lspSlsOrdExcel01FetchDebtorOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(LspSlsOrdExcel01Actions.lspSlsOrdExcel01FetchDebtorOptionLoading(false));
  }
}

export function* lspSlsOrdExcel01FetchProjectOptions({ search }) {
  try {
    yield put(LspSlsOrdExcel01Actions.lspSlsOrdExcel01FetchProjectOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `project/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(LspSlsOrdExcel01Actions.lspSlsOrdExcel01FetchProjectOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(LspSlsOrdExcel01Actions.lspSlsOrdExcel01FetchProjectOptionLoading(false));
  }
}

export const saga = [
  takeLatest(
    LspSlsOrdExcel01Types.LSP_SLS_ORD_EXCEL01_SHOW_BATCH_JOB_STATUS,
    lspSlsOrdExcel01ShowBatchJobStatus
  ),
  takeLatest(LspSlsOrdExcel01Types.LSP_SLS_ORD_EXCEL01_UPLOAD_EXCEL, lspSlsOrdExcel01UploadExcel),

  takeLatest(
    LspSlsOrdExcel01Types.LSP_SLS_ORD_EXCEL01_FETCH_DEBTOR_OPTIONS,
    lspSlsOrdExcel01FetchDebtorOptions
  ),

  takeLatest(
    LspSlsOrdExcel01Types.LSP_SLS_ORD_EXCEL01_FETCH_PROJECT_OPTIONS,
    lspSlsOrdExcel01FetchProjectOptions
  )
];
