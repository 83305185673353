import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  advShipIndexResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  advShipIndexGoToDocument: ['hdrId'],
  advShipIndexGoToAudit: ['hdrId'],
  advShipIndexFetchAdvShipIndex: ['currentPage', 'sorts', 'filters', 'pageSize'],
  advShipIndexFetchAdvShipIndexLoading: ['boolean'],
  advShipIndexFetchAdvShipIndexSuccess: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],
  advShipIndexAddSelectedDocuments: ['selectedDocuments'],
  advShipIndexRemoveSelectedDocuments: ['selectedDocuments'],
  advShipIndexSetWorkspaceVisible: ['boolean'],
  advShipIndexCreateAdvShipIndex: ['hdrIds'],
  advShipIndexCreateAdvShipIndexLoading: ['boolean'],
  advShipIndexCreateAdvShipIndexSuccess: ['newDocuments'],

  advShipIndexWorkspaceLoading: ['boolean'],
  advShipIndexResetAdvShipFServ02: ['hdrIds'],
  advShipIndexResetAdvShipFServ02Success: null,

  advShipIndexOpenDocument: ['hdrId'],
  advShipIndexTransitionToVoid: ['hdrIds']
});

export const AdvShipIndexTypes = Types;
export default Creators;
