/**
 * The initial values for the redux state.
 */
export default {
  timestamp: 0,
  resources: [],
  fetchIsLoading: false,
  sorts: {
    line_no: 'ascend'
  },
  filters: {},
  pageSize: '20',
  currentPage: 1,
  lastPage: 10,
  total: 0,
  selectedResources: [],
  manageRoleIsVisible: false,
  selectedResource: {},
  assignIsLoading: false,

  roleOption: { value: 0, label: '' },
  roleOptions: [],
  roleIsLoading: false,

  barcodeDialogBarcode: '',
  barcodeDialogUsername: '',
  barcodeDialogFirstName: '',
  barcodeDialogLastName: '',
  barcodeDialogIsVisible: false
};
