import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  gdsRcptRtnDetailBoardResetWipInbOrdTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  gdsRcptRtnDetailBoardFetchWipInbOrd: ['currentPage', 'sorts', 'filters', 'pageSize'],
  gdsRcptRtnDetailBoardFetchWipInbOrdLoading: ['boolean'],
  gdsRcptRtnDetailBoardFetchWipInbOrdSuccess: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],

  gdsRcptRtnDetailBoardResetWipGdsRcptTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  gdsRcptRtnDetailBoardFetchWipGdsRcpt: ['currentPage', 'sorts', 'filters', 'pageSize'],
  gdsRcptRtnDetailBoardFetchWipGdsRcptLoading: ['boolean'],
  gdsRcptRtnDetailBoardFetchWipGdsRcptSuccess: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],

  gdsRcptRtnDetailBoardResetWipWhseJobTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  gdsRcptRtnDetailBoardFetchWipWhseJob: ['currentPage', 'sorts', 'filters', 'pageSize'],
  gdsRcptRtnDetailBoardFetchWipWhseJobLoading: ['boolean'],
  gdsRcptRtnDetailBoardFetchWipWhseJobSuccess: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],

  gdsRcptRtnDetailBoardResetCompleteGdsRcptTimestamp: [
    'currentPage',
    'sorts',
    'filters',
    'pageSize'
  ],
  gdsRcptRtnDetailBoardFetchCompleteGdsRcpt: ['currentPage', 'sorts', 'filters', 'pageSize'],
  gdsRcptRtnDetailBoardFetchCompleteGdsRcptLoading: ['boolean'],
  gdsRcptRtnDetailBoardFetchCompleteGdsRcptSuccess: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],

  gdsRcptRtnDetailBoardSetWipWhseJobDialogVisible: ['boolean'],
  gdsRcptRtnDetailBoardShowWipWhseJobSummary: ['docId'],
  gdsRcptRtnDetailBoardShowWipWhseJobSummaryLoading: ['boolean'],
  gdsRcptRtnDetailBoardShowWipWhseJobSummarySuccess: ['document'],

  gdsRcptRtnDetailBoardSetWipGdsRcptDialogVisible: ['boolean'],
  gdsRcptRtnDetailBoardShowWipGdsRcptSummary: ['docId'],
  gdsRcptRtnDetailBoardShowWipGdsRcptSummaryLoading: ['boolean'],
  gdsRcptRtnDetailBoardShowWipGdsRcptSummarySuccess: ['document'],

  gdsRcptRtnDetailBoardSetWipInbOrdDialogVisible: ['boolean'],
  gdsRcptRtnDetailBoardShowWipInbOrdSummary: ['docId'],
  gdsRcptRtnDetailBoardShowWipInbOrdSummaryLoading: ['boolean'],
  gdsRcptRtnDetailBoardShowWipInbOrdSummarySuccess: ['document'],

  gdsRcptRtnDetailBoardSetCompleteGdsRcptDialogVisible: ['boolean'],
  gdsRcptRtnDetailBoardShowCompleteGdsRcptSummary: ['docId'],
  gdsRcptRtnDetailBoardShowCompleteGdsRcptSummaryLoading: ['boolean'],
  gdsRcptRtnDetailBoardShowCompleteGdsRcptSummarySuccess: ['document']
});

export const GdsRcptRtnDetailBoardTypes = Types;
export default Creators;
