import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  stkRcptSync04ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  stkRcptSync04GoToDocument: ['hdrId'],
  stkRcptSync04FetchStkRcptSync04: ['currentPage', 'sorts', 'filters', 'pageSize'],
  stkRcptSync04FetchStkRcptSync04Loading: ['boolean'],
  stkRcptSync04FetchStkRcptSync04Success: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],
  stkRcptSync04AddSelectedDocuments: ['selectedDocuments'],
  stkRcptSync04RemoveSelectedDocuments: ['selectedDocuments'],
  stkRcptSync04SetWorkspaceVisible: ['boolean'],

  stkRcptSync04SyncNow: ['hdrIds'],
  stkRcptSync04SyncNowSuccess: null,
  stkRcptSync04SyncLoading: ['boolean']
});

export const StkRcptSync04Types = Types;
export default Creators;
