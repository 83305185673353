import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  slsOrdDetailBoardResetWipSlsOrdTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  slsOrdDetailBoardFetchWipSlsOrd: ['currentPage', 'sorts', 'filters', 'pageSize'],
  slsOrdDetailBoardFetchWipSlsOrdLoading: ['boolean'],
  slsOrdDetailBoardFetchWipSlsOrdSuccess: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],

  slsOrdDetailBoardResetWipOutbOrdTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  slsOrdDetailBoardFetchWipOutbOrd: ['currentPage', 'sorts', 'filters', 'pageSize'],
  slsOrdDetailBoardFetchWipOutbOrdLoading: ['boolean'],
  slsOrdDetailBoardFetchWipOutbOrdSuccess: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],

  slsOrdDetailBoardResetWipInvDocTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  slsOrdDetailBoardFetchWipInvDoc: ['currentPage', 'sorts', 'filters', 'pageSize'],
  slsOrdDetailBoardFetchWipInvDocLoading: ['boolean'],
  slsOrdDetailBoardFetchWipInvDocSuccess: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],

  slsOrdDetailBoardResetCompleteLoadListTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  slsOrdDetailBoardFetchCompleteLoadList: ['currentPage', 'sorts', 'filters', 'pageSize'],
  slsOrdDetailBoardFetchCompleteLoadListLoading: ['boolean'],
  slsOrdDetailBoardFetchCompleteLoadListSuccess: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],

  slsOrdDetailBoardSetWipSlsOrdDialogVisible: ['boolean'],
  slsOrdDetailBoardShowWipSlsOrdSummary: ['docId'],
  slsOrdDetailBoardShowWipSlsOrdSummaryLoading: ['boolean'],
  slsOrdDetailBoardShowWipSlsOrdSummarySuccess: ['document'],

  slsOrdDetailBoardSetWipOutbOrdDialogVisible: ['boolean'],
  slsOrdDetailBoardShowWipOutbOrdSummary: ['docId'],
  slsOrdDetailBoardShowWipOutbOrdSummaryLoading: ['boolean'],
  slsOrdDetailBoardShowWipOutbOrdSummarySuccess: ['document'],

  slsOrdDetailBoardSetWipInvDocDialogVisible: ['boolean'],
  slsOrdDetailBoardShowWipInvDocSummary: ['docId'],
  slsOrdDetailBoardShowWipInvDocSummaryLoading: ['boolean'],
  slsOrdDetailBoardShowWipInvDocSummarySuccess: ['document'],

  slsOrdDetailBoardSetCompleteLoadListDialogVisible: ['boolean'],
  slsOrdDetailBoardShowCompleteLoadListSummary: ['docId'],
  slsOrdDetailBoardShowCompleteLoadListSummaryLoading: ['boolean'],
  slsOrdDetailBoardShowCompleteLoadListSummarySuccess: ['document']
});

export const SlsOrdDetailBoardTypes = Types;
export default Creators;
