import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  putAwayRtnIndexResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  putAwayRtnIndexGoToDocument: ['hdrId'],
  putAwayRtnIndexGoToAudit: ['hdrId'],
  putAwayRtnIndexFetchPutAwayRtnIndex: ['currentPage', 'sorts', 'filters', 'pageSize'],
  putAwayRtnIndexFetchPutAwayRtnIndexLoading: ['boolean'],
  putAwayRtnIndexFetchPutAwayRtnIndexSuccess: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],
  putAwayRtnIndexAddSelectedDocuments: ['selectedDocuments'],
  putAwayRtnIndexRemoveSelectedDocuments: ['selectedDocuments'],
  putAwayRtnIndexSetWorkspaceVisible: ['boolean'],

  putAwayRtnIndexWorkspaceLoading: ['boolean'],
  putAwayRtnIndexResetPutAwaySync01: ['hdrIds'],
  putAwayRtnIndexResetPutAwaySync01Success: null,
  putAwayRtnIndexResetPutAwayFServ01: ['hdrIds'],
  putAwayRtnIndexResetPutAwayFServ01Success: null
});

export const PutAwayRtnIndexTypes = Types;
export default Creators;
