/**
 * The initial values for the redux state.
 */
export default {
  criteria: {
    doc_date: new Date(),
    company_id_select2: {
      value: 0,
      label: ''
    },
    location_id_select2: {
      value: 0,
      label: ''
    },
    item_group_01_ids_select2: [],
    item_ids_select2: []
  },
  batchJobStatus: {
    proc_type: 0,
    user_id: 0,
    status_number: 0
  },
  exportIsLoading: false,

  companyOptions: [],
  companyIsLoading: false,

  locationOptions: [],
  locationIsLoading: false,

  itemGroup01Options: [],
  itemGroup01IsLoading: false,

  itemOptions: [],
  itemIsLoading: false
};
