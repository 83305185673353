import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  userList01ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  userList01GoToResource: ['resId'],
  userList01NewResource: null,
  userList01GoToAudit: ['resId'],
  userList01FetchUserList01: ['currentPage', 'sorts', 'filters', 'pageSize'],
  userList01FetchUserList01Loading: ['boolean'],
  userList01FetchUserList01Success: ['resources', 'currentPage', 'lastPage', 'total', 'pageSize'],
  userList01AddSelectedResources: ['selectedResources'],
  userList01RemoveSelectedResources: ['selectedResources'],
  userList01SetManageRoleVisible: ['resource', 'boolean'],
  userList01RemoveRole: ['resId', 'roleId'],
  userList01AddRole: ['resId', 'roleId'],
  userList01ManageRoleSuccess: ['roles'],
  userList01ManageRoleLoading: ['boolean'],

  userList01FetchRoleOptions: ['resId', 'search'],
  userList01FetchRoleOptionLoading: ['boolean'],
  userList01FetchRoleOptionSuccess: ['options'],
  userList01SetRoleOption: ['option'],

  userList01SetBarcodeVisible: ['boolean'],
  userList01ShowBarcode: ['barcode', 'username', 'firstName', 'lastName']
});

export const UserList01Types = Types;
export default Creators;
