import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  whseJob0301ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  whseJob0301GoToDocument: ['hdrId'],
  whseJob0301FetchWhseJob0301: ['currentPage', 'sorts', 'filters', 'pageSize'],
  whseJob0301FetchWhseJob0301Loading: ['boolean'],
  whseJob0301FetchWhseJob0301Success: ['documents', 'currentPage', 'lastPage', 'total', 'pageSize'],
  whseJob0301AddSelectedDocuments: ['selectedDocuments'],
  whseJob0301RemoveSelectedDocuments: ['selectedDocuments'],
  whseJob0301SetWorkspaceVisible: ['boolean'],
  whseJob0301CreateWhseJob0301: ['hdrIds', 'toStorageBinId'],
  whseJob0301CreateWhseJob0301Loading: ['boolean'],
  whseJob0301CreateWhseJob0301Success: ['newDocuments']
});

export const WhseJob0301Types = Types;
export default Creators;
