import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  gdsRcptDetailResetTimestamp: null,
  gdsRcptDetailSetHdrId: ['hdrId'],

  gdsRcptDetailInitHeader: null,
  gdsRcptDetailShowHeader: ['hdrId'],
  gdsRcptDetailShowHeaderSuccess: ['documentHeader'],

  gdsRcptDetailUpdateHeader: ['formikBag', 'documentHeader'],
  gdsRcptDetailCreateHeader: ['formikBag', 'documentHeader'],
  gdsRcptDetailUpdateDocumentSuccess: ['documentHeader', 'documentDetails'],

  gdsRcptDetailShowDocumentLoading: ['boolean'],
  gdsRcptDetailTransitionToStatus: ['formikBag', 'hdrId', 'docStatus'],

  gdsRcptDetailShowDetails: ['hdrId'],
  gdsRcptDetailShowDetailsSuccess: ['documentDetails'],

  gdsRcptDetailSetDetailVisible: ['boolean'],
  gdsRcptDetailSetDocumentDetail: ['documentDetail'],

  gdsRcptDetailUpdateDetails: ['formikBag', 'hdrId', 'documentDetails'],
  gdsRcptDetailCreateDetail: ['formikBag', 'hdrId', 'documentDetail'],
  gdsRcptDetailDeleteDetail: ['hdrId', 'documentDetail'],

  gdsRcptDetailSetWhseJobType: ['whseJobType'],

  gdsRcptDetailFetchCompanyOptions: ['search'],
  gdsRcptDetailFetchCompanyOptionLoading: ['boolean'],
  gdsRcptDetailFetchCompanyOptionSuccess: ['options'],

  gdsRcptDetailFetchItemOptions: ['search'],
  gdsRcptDetailFetchItemOptionLoading: ['boolean'],
  gdsRcptDetailFetchItemOptionSuccess: ['options'],
  gdsRcptDetailChangeItem: ['formikBag', 'hdrId', 'itemId'],

  gdsRcptDetailFetchItemBatchOptions: ['itemId', 'search'],
  gdsRcptDetailFetchItemBatchOptionLoading: ['boolean'],
  gdsRcptDetailFetchItemBatchOptionSuccess: ['options'],
  gdsRcptDetailChangeItemBatch: ['formikBag', 'hdrId', 'itemBatchId'],

  gdsRcptDetailFetchUomOptions: ['itemId', 'search'],
  gdsRcptDetailFetchUomOptionLoading: ['boolean'],
  gdsRcptDetailFetchUomOptionSuccess: ['options'],
  gdsRcptDetailChangeUom: ['formikBag', 'hdrId', 'itemId', 'uomId'],

  gdsRcptDetailFetchItemCond01Options: ['search'],
  gdsRcptDetailFetchItemCond01OptionLoading: ['boolean'],
  gdsRcptDetailFetchItemCond01OptionSuccess: ['options'],

  gdsRcptDetailFetchToStorageBinOptions: ['search'],
  gdsRcptDetailFetchToStorageBinOptionLoading: ['boolean'],
  gdsRcptDetailFetchToStorageBinOptionSuccess: ['options'],

  gdsRcptDetailFetchToHandlingUnitOptions: ['search'],
  gdsRcptDetailFetchToHandlingUnitOptionLoading: ['boolean'],
  gdsRcptDetailFetchToHandlingUnitOptionSuccess: ['options'],

  gdsRcptDetailSetDetailSummaryVisible: ['boolean']
});

export const GdsRcptDetailTypes = Types;
export default Creators;
