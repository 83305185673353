/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { StkTrfDetailTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const stkTrfDetailResetTimestamp = state => ({
  ...state,
  timestamp: Date.now()
});

export const stkTrfDetailSetHdrId = (state, { hdrId, itemId }) => ({
  ...state,
  timestamp: Date.now(),
  documentIsLoading: false,
  hdrId,
  itemId,
  detailIsVisible: false,
  currencyOptions: []
});

export const stkTrfDetailShowDocumentLoading = (state, { boolean }) => ({
  ...state,
  documentIsLoading: boolean
});

export const stkTrfDetailShowHeaderSuccess = (state, { documentHeader }) => ({
  ...state,
  documentHeader,
  currencyOptions: [],
  toDivisionOptions: [],
  toLocationOptions: [],
  itemOptions: [],
  uomOptions: [],
  locationOptions: [],
  hdrToLocationOptions: []
});

export const stkTrfDetailUpdateDocumentSuccess = (state, { documentHeader, documentDetails }) => ({
  ...state,
  documentHeader,
  documentDetails
});

export const stkTrfDetailShowDetailsSuccess = (state, { documentDetails }) => ({
  ...state,
  documentDetails
});

export const stkTrfDetailSetDetailVisible = (state, { boolean }) => ({
  ...state,
  detailIsVisible: boolean
});

export const stkTrfDetailSetDocumentDetail = (state, { documentDetail }) => ({
  ...state,
  documentDetail,
  itemOptions: [],
  uomOptions: []
});

export const stkTrfDetailFetchCurrencyOptionLoading = (state, { boolean }) => ({
  ...state,
  currencyIsLoading: boolean
});

export const stkTrfDetailFetchCurrencyOptionSuccess = (state, { options }) => ({
  ...state,
  currencyOptions: options
});

export const stkTrfDetailFetchToDivisionOptionLoading = (state, { boolean }) => ({
  ...state,
  toDivisionIsLoading: boolean
});

export const stkTrfDetailFetchToDivisionOptionSuccess = (state, { options }) => ({
  ...state,
  toDivisionOptions: options
});

export const stkTrfDetailFetchToLocationOptionLoading = (state, { boolean }) => ({
  ...state,
  toLocationIsLoading: boolean
});

export const stkTrfDetailFetchToLocationOptionSuccess = (state, { options }) => ({
  ...state,
  toLocationOptions: options
});

export const stkTrfDetailFetchItemOptionLoading = (state, { boolean }) => ({
  ...state,
  itemIsLoading: boolean
});

export const stkTrfDetailFetchItemOptionSuccess = (state, { options }) => ({
  ...state,
  itemOptions: options
});

export const stkTrfDetailFetchUomOptionLoading = (state, { boolean }) => ({
  ...state,
  uomIsLoading: boolean
});

export const stkTrfDetailFetchUomOptionSuccess = (state, { options }) => ({
  ...state,
  uomOptions: options
});

// force refresh the formik form
export const stkTrfDetailUpdateDetails = (state, { documentDetails }) => ({
  ...state,
  documentDetail: documentDetails[0]
});

// force refresh the formik form
export const stkTrfDetailCreateDetail = (state, { documentDetail }) => ({
  ...state,
  documentDetail
});

export const stkTrfDetailFetchLocationOptionLoading = (state, { boolean }) => ({
  ...state,
  locationIsLoading: boolean
});

export const stkTrfDetailFetchLocationOptionSuccess = (state, { options }) => ({
  ...state,
  locationOptions: options
});

export const stkTrfDetailChangeToDivision = state => {
  // reset the select2 cache

  return {
    ...state,
    toLocationOptions: []
  };
};

export const stkTrfDetailFetchHdrToLocationOptionLoading = (state, { boolean }) => ({
  ...state,
  hdrToLocationIsLoading: boolean
});

export const stkTrfDetailFetchHdrToLocationOptionSuccess = (state, { options }) => ({
  ...state,
  hdrToLocationOptions: options
});

export const stkTrfDetailFetchProjectOptionLoading = (state, { boolean }) => ({
  ...state,
  projectIsLoading: boolean
});

export const stkTrfDetailFetchProjectOptionSuccess = (state, { options }) => ({
  ...state,
  projectOptions: options
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [StkTrfDetailTypes.STK_TRF_DETAIL_RESET_TIMESTAMP]: stkTrfDetailResetTimestamp,
  [StkTrfDetailTypes.STK_TRF_DETAIL_SET_HDR_ID]: stkTrfDetailSetHdrId,

  [StkTrfDetailTypes.STK_TRF_DETAIL_SHOW_HEADER_SUCCESS]: stkTrfDetailShowHeaderSuccess,

  [StkTrfDetailTypes.STK_TRF_DETAIL_UPDATE_DOCUMENT_SUCCESS]: stkTrfDetailUpdateDocumentSuccess,

  [StkTrfDetailTypes.STK_TRF_DETAIL_SHOW_DOCUMENT_LOADING]: stkTrfDetailShowDocumentLoading,

  [StkTrfDetailTypes.STK_TRF_DETAIL_SHOW_DETAILS_SUCCESS]: stkTrfDetailShowDetailsSuccess,

  [StkTrfDetailTypes.STK_TRF_DETAIL_SET_DETAIL_VISIBLE]: stkTrfDetailSetDetailVisible,
  [StkTrfDetailTypes.STK_TRF_DETAIL_SET_DOCUMENT_DETAIL]: stkTrfDetailSetDocumentDetail,

  [StkTrfDetailTypes.STK_TRF_DETAIL_UPDATE_DETAILS]: stkTrfDetailUpdateDetails,
  [StkTrfDetailTypes.STK_TRF_DETAIL_CREATE_DETAIL]: stkTrfDetailCreateDetail,

  [StkTrfDetailTypes.STK_TRF_DETAIL_FETCH_CURRENCY_OPTION_LOADING]: stkTrfDetailFetchCurrencyOptionLoading,
  [StkTrfDetailTypes.STK_TRF_DETAIL_FETCH_CURRENCY_OPTION_SUCCESS]: stkTrfDetailFetchCurrencyOptionSuccess,

  [StkTrfDetailTypes.STK_TRF_DETAIL_FETCH_TO_DIVISION_OPTION_LOADING]: stkTrfDetailFetchToDivisionOptionLoading,
  [StkTrfDetailTypes.STK_TRF_DETAIL_FETCH_TO_DIVISION_OPTION_SUCCESS]: stkTrfDetailFetchToDivisionOptionSuccess,

  [StkTrfDetailTypes.STK_TRF_DETAIL_FETCH_TO_LOCATION_OPTION_LOADING]: stkTrfDetailFetchToLocationOptionLoading,
  [StkTrfDetailTypes.STK_TRF_DETAIL_FETCH_TO_LOCATION_OPTION_SUCCESS]: stkTrfDetailFetchToLocationOptionSuccess,
  [StkTrfDetailTypes.STK_TRF_DETAIL_CHANGE_TO_DIVISION]: stkTrfDetailChangeToDivision,

  [StkTrfDetailTypes.STK_TRF_DETAIL_FETCH_ITEM_OPTION_LOADING]: stkTrfDetailFetchItemOptionLoading,
  [StkTrfDetailTypes.STK_TRF_DETAIL_FETCH_ITEM_OPTION_SUCCESS]: stkTrfDetailFetchItemOptionSuccess,

  [StkTrfDetailTypes.STK_TRF_DETAIL_FETCH_UOM_OPTION_LOADING]: stkTrfDetailFetchUomOptionLoading,
  [StkTrfDetailTypes.STK_TRF_DETAIL_FETCH_UOM_OPTION_SUCCESS]: stkTrfDetailFetchUomOptionSuccess,

  [StkTrfDetailTypes.STK_TRF_DETAIL_FETCH_LOCATION_OPTION_LOADING]: stkTrfDetailFetchLocationOptionLoading,
  [StkTrfDetailTypes.STK_TRF_DETAIL_FETCH_LOCATION_OPTION_SUCCESS]: stkTrfDetailFetchLocationOptionSuccess,

  [StkTrfDetailTypes.STK_TRF_DETAIL_FETCH_HDR_TO_LOCATION_OPTION_LOADING]: stkTrfDetailFetchHdrToLocationOptionLoading,
  [StkTrfDetailTypes.STK_TRF_DETAIL_FETCH_HDR_TO_LOCATION_OPTION_SUCCESS]: stkTrfDetailFetchHdrToLocationOptionSuccess,

  [StkTrfDetailTypes.STK_TRF_DETAIL_FETCH_PROJECT_OPTION_LOADING]: stkTrfDetailFetchProjectOptionLoading,
  [StkTrfDetailTypes.STK_TRF_DETAIL_FETCH_PROJECT_OPTION_SUCCESS]: stkTrfDetailFetchProjectOptionSuccess
});
