import { put, call, select, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import { replace } from 'connected-react-router';
import ApiService from '../Services/ApiService';
import UtilService from '../Services/UtilService';
import AppActions from '../Stores/App/Actions';
import StkRcptDetailActions, { StkRcptDetailTypes } from '../Stores/StkRcptDetail/Actions';

const getAppStore = state => state.app;

const getStkRcptDetailStore = state => state.stkRcptDetail;

export function* stkRcptDetailInitHeader({ docSubType }) {
  try {
    yield put(StkRcptDetailActions.stkRcptDetailShowDocumentLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `stkRcpt/initHeader/${app.curDivisionId}/${docSubType}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(StkRcptDetailActions.stkRcptDetailShowHeaderSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(StkRcptDetailActions.stkRcptDetailShowDocumentLoading(false));
  }
}

export function* stkRcptDetailShowHeader({ hdrId }) {
  try {
    yield put(StkRcptDetailActions.stkRcptDetailShowDocumentLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `stkRcpt/showHeader/${hdrId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(StkRcptDetailActions.stkRcptDetailShowHeaderSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(StkRcptDetailActions.stkRcptDetailShowDocumentLoading(false));
  }
}

export function* stkRcptDetailTransitionToStatus({ formikBag, hdrId, docStatus }) {
  formikBag.setSubmitting(true);
  yield put(StkRcptDetailActions.stkRcptDetailShowDocumentLoading(true));
  try {
    const app = yield select(getAppStore);

    const postData = {
      hdrId,
      docStatus
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      'stkRcpt/transitionToStatus',
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      const stkRcptDetail = yield select(getStkRcptDetailStore);

      const {
        documentHeader: oldDocumentHeader,
        documentDetails: oldDocumentDetails
      } = stkRcptDetail;

      const {
        document_header: retDocumentHeader,
        document_details: retDocumentDetails,
        deleted_details: retDeletedDetails
      } = result.data;

      const processed = UtilService.processHeaderDetails(
        oldDocumentHeader,
        oldDocumentDetails,
        retDocumentHeader,
        retDocumentDetails,
        retDeletedDetails
      );

      yield put(
        StkRcptDetailActions.stkRcptDetailUpdateDocumentSuccess(
          retDocumentHeader,
          processed.details
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
    yield put(StkRcptDetailActions.stkRcptDetailShowDocumentLoading(false));
  }
}

export function* stkRcptDetailUpdateHeader({ formikBag, documentHeader }) {
  formikBag.setSubmitting(true);
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: documentHeader
    };

    const result = yield call(
      ApiService.putApi, // function
      app.apiUrl,
      'stkRcpt/updateHeader',
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      const stkRcptDetail = yield select(getStkRcptDetailStore);

      const {
        documentHeader: oldDocumentHeader,
        documentDetails: oldDocumentDetails
      } = stkRcptDetail;

      const {
        document_header: retDocumentHeader,
        document_details: retDocumentDetails,
        deleted_details: retDeletedDetails
      } = result.data;

      const processed = UtilService.processHeaderDetails(
        oldDocumentHeader,
        oldDocumentDetails,
        retDocumentHeader,
        retDocumentDetails,
        retDeletedDetails
      );

      yield put(
        StkRcptDetailActions.stkRcptDetailUpdateDocumentSuccess(
          retDocumentHeader,
          processed.details
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* stkRcptDetailShowDetails({ hdrId }) {
  try {
    yield put(StkRcptDetailActions.stkRcptDetailShowDocumentLoading(true));

    let result = {
      isSuccess: true,
      data: []
    };

    if (hdrId > 0) {
      const app = yield select(getAppStore);
      const getData = {};

      result = yield call(
        ApiService.getApi, // function
        app.apiUrl,
        `stkRcpt/showDetails/${hdrId}`,
        app.token,
        getData,
        'multipart/form-data' // params
      );
    }

    if (result.isSuccess === true) {
      yield put(StkRcptDetailActions.stkRcptDetailShowDetailsSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(StkRcptDetailActions.stkRcptDetailShowDocumentLoading(false));
  }
}

export function* stkRcptDetailUpdateDetails({ formikBag, hdrId, documentDetails }) {
  formikBag.setSubmitting(true);
  yield put(StkRcptDetailActions.stkRcptDetailShowDocumentLoading(true));
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: documentDetails
    };

    const result = yield call(
      ApiService.putApi, // function
      app.apiUrl,
      `stkRcpt/updateDetails/${hdrId}`,
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      const stkRcptDetail = yield select(getStkRcptDetailStore);

      const {
        documentHeader: oldDocumentHeader,
        documentDetails: oldDocumentDetails
      } = stkRcptDetail;

      const {
        document_header: retDocumentHeader,
        document_details: retDocumentDetails,
        deleted_details: retDeletedDetails
      } = result.data;

      const processed = UtilService.processHeaderDetails(
        oldDocumentHeader,
        oldDocumentDetails,
        retDocumentHeader,
        retDocumentDetails,
        retDeletedDetails
      );

      yield put(
        StkRcptDetailActions.stkRcptDetailUpdateDocumentSuccess(
          retDocumentHeader,
          processed.details
        )
      );

      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_SUCCESS_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
    yield put(StkRcptDetailActions.stkRcptDetailShowDocumentLoading(false));
  }
}

export function* stkRcptDetailFetchCurrencyOptions({ search }) {
  try {
    yield put(StkRcptDetailActions.stkRcptDetailFetchCurrencyOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `currency/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code}`
      }));

      yield put(StkRcptDetailActions.stkRcptDetailFetchCurrencyOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(StkRcptDetailActions.stkRcptDetailFetchCurrencyOptionLoading(false));
  }
}

export function* stkRcptDetailChangeCurrency({ formikBag, currencyId }) {
  try {
    formikBag.setSubmitting(true);

    const app = yield select(getAppStore);
    const postData = {
      currencyId
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `stkRcpt/changeCurrency`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      Object.entries(result.data).forEach(entry => {
        const key = entry[0];
        const value = entry[1];
        formikBag.setFieldValue(key, value);
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* stkRcptDetailFetchFrDivisionOptions({ docSubType, search }) {
  try {
    yield put(StkRcptDetailActions.stkRcptDetailFetchFrDivisionOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `stkRcpt/divisionSelect2/${app.curSiteFlowId}/${docSubType}`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code}`
      }));

      yield put(StkRcptDetailActions.stkRcptDetailFetchFrDivisionOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(StkRcptDetailActions.stkRcptDetailFetchFrDivisionOptionLoading(false));
  }
}

export function* stkRcptDetailFetchFrLocationOptions({ hdrId, docSubType, search }) {
  try {
    yield put(StkRcptDetailActions.stkRcptDetailFetchFrLocationOptionLoading(true));

    let filters = [];
    if (docSubType === 4) {
      // TRANSFER_FROM_STORE
      filters = [
        {
          field: 'location_types',
          value: [
            4 // STORE_STOCK
          ]
        }
      ];
    } else if (docSubType === 5) {
      // TRANSFER_FROM_VAN
      filters = [
        {
          field: 'location_types',
          value: [
            5 // VAN_STOCK
          ]
        }
      ];
    }

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `stkRcpt/locationSelect2/${hdrId}`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(StkRcptDetailActions.stkRcptDetailFetchFrLocationOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(StkRcptDetailActions.stkRcptDetailFetchFrLocationOptionLoading(false));
  }
}

export function* stkRcptDetailCreateHeader({ formikBag, documentHeader }) {
  formikBag.setSubmitting(true);
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: documentHeader
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      'stkRcpt/createHeader',
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      yield put(replace(`${app.appPath}/stkRcptDetail/update/${result.data}`));
      yield put(StkRcptDetailActions.stkRcptDetailSetHdrId(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* stkRcptDetailFetchItemOptions({ search }) {
  try {
    yield put(StkRcptDetailActions.stkRcptDetailFetchItemOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `item/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(StkRcptDetailActions.stkRcptDetailFetchItemOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(StkRcptDetailActions.stkRcptDetailFetchItemOptionLoading(false));
  }
}

export function* stkRcptDetailChangeItem({ formikBag, hdrId, itemId }) {
  try {
    formikBag.setSubmitting(true);

    const app = yield select(getAppStore);
    const postData = {
      hdrId,
      itemId
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `stkRcpt/changeItem`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      Object.entries(result.data).forEach(entry => {
        const key = entry[0];
        const value = entry[1];
        formikBag.setFieldValue(key, value);
      });

      // reset the select2 cache
      formikBag.setFieldValue('uom_select2', {
        value: 0,
        label: ''
      });
      formikBag.setFieldValue('uom_rate', 1);

      yield put(StkRcptDetailActions.stkRcptDetailFetchUomOptionSuccess([]));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* stkRcptDetailChangeUom({ formikBag, hdrId, itemId, uomId }) {
  try {
    formikBag.setSubmitting(true);

    const app = yield select(getAppStore);
    const postData = {
      hdrId,
      itemId,
      uomId
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `stkRcpt/changeItemUom`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      Object.entries(result.data).forEach(entry => {
        const key = entry[0];
        const value = entry[1];
        formikBag.setFieldValue(key, value);
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* stkRcptDetailFetchUomOptions({ itemId, search }) {
  try {
    yield put(StkRcptDetailActions.stkRcptDetailFetchUomOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: [
        {
          field: 'item_id',
          value: itemId
        }
      ]
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `itemUom/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.uom_id,
        label: `${d.uom_code}`
      }));

      yield put(StkRcptDetailActions.stkRcptDetailFetchUomOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(StkRcptDetailActions.stkRcptDetailFetchUomOptionLoading(false));
  }
}

export function* stkRcptDetailCreateDetail({ formikBag, hdrId, documentDetail }) {
  formikBag.setSubmitting(true);
  yield put(StkRcptDetailActions.stkRcptDetailShowDocumentLoading(true));
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: documentDetail
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `stkRcpt/createDetail/${hdrId}`,
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      const stkRcptDetail = yield select(getStkRcptDetailStore);

      const {
        documentHeader: oldDocumentHeader,
        documentDetails: oldDocumentDetails
      } = stkRcptDetail;

      const {
        document_header: retDocumentHeader,
        document_details: retDocumentDetails,
        deleted_details: retDeletedDetails
      } = result.data;

      const processed = UtilService.processHeaderDetails(
        oldDocumentHeader,
        oldDocumentDetails,
        retDocumentHeader,
        retDocumentDetails,
        retDeletedDetails
      );

      yield put(
        StkRcptDetailActions.stkRcptDetailUpdateDocumentSuccess(
          retDocumentHeader,
          processed.details
        )
      );

      yield put(
        StkRcptDetailActions.stkRcptDetailSetDocumentDetail(stkRcptDetail.initDocumentDetail)
      );

      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_SUCCESS_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
    yield put(StkRcptDetailActions.stkRcptDetailShowDocumentLoading(false));
  }
}

export function* stkRcptDetailDeleteDetail({ hdrId, documentDetail }) {
  yield put(StkRcptDetailActions.stkRcptDetailShowDocumentLoading(true));
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: [{ id: documentDetail.id }]
    };

    const result = yield call(
      ApiService.deleteApi, // function
      app.apiUrl,
      `stkRcpt/deleteDetails/${hdrId}`,
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      const stkRcptDetail = yield select(getStkRcptDetailStore);

      const {
        documentHeader: oldDocumentHeader,
        documentDetails: oldDocumentDetails
      } = stkRcptDetail;

      const {
        document_header: retDocumentHeader,
        document_details: retDocumentDetails,
        deleted_details: retDeletedDetails
      } = result.data;

      const processed = UtilService.processHeaderDetails(
        oldDocumentHeader,
        oldDocumentDetails,
        retDocumentHeader,
        retDocumentDetails,
        retDeletedDetails
      );

      yield put(
        StkRcptDetailActions.stkRcptDetailUpdateDocumentSuccess(
          retDocumentHeader,
          processed.details
        )
      );

      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_SUCCESS_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(StkRcptDetailActions.stkRcptDetailShowDocumentLoading(false));
  }
}

export function* stkRcptDetailFetchLocationOptions({ docSubType, search }) {
  try {
    yield put(StkRcptDetailActions.stkRcptDetailFetchLocationOptionLoading(true));

    let filters = [];
    if (docSubType === 2) {
      // TRANSFER_TO_STORE
      filters = [
        {
          field: 'location_types',
          value: [
            4 // STORE_STOCK
          ]
        }
      ];
    } else if (docSubType === 3) {
      // TRANSFER_TO_VAN
      filters = [
        {
          field: 'location_types',
          value: [
            5 // VAN_STOCK
          ]
        }
      ];
    }

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId: app.curSiteFlowId,
      search,
      filters
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `location/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(StkRcptDetailActions.stkRcptDetailFetchLocationOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(StkRcptDetailActions.stkRcptDetailFetchLocationOptionLoading(false));
  }
}

export function* stkRcptDetailFetchProjectOptions({ siteFlowId, search }) {
  try {
    yield put(StkRcptDetailActions.stkRcptDetailFetchProjectOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId,
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `project/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(StkRcptDetailActions.stkRcptDetailFetchProjectOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(StkRcptDetailActions.stkRcptDetailFetchProjectOptionLoading(false));
  }
}

export const saga = [
  takeLatest(StkRcptDetailTypes.STK_RCPT_DETAIL_INIT_HEADER, stkRcptDetailInitHeader),
  takeLatest(StkRcptDetailTypes.STK_RCPT_DETAIL_SHOW_HEADER, stkRcptDetailShowHeader),

  takeLatest(StkRcptDetailTypes.STK_RCPT_DETAIL_UPDATE_HEADER, stkRcptDetailUpdateHeader),
  takeLatest(StkRcptDetailTypes.STK_RCPT_DETAIL_CREATE_HEADER, stkRcptDetailCreateHeader),

  takeLatest(
    StkRcptDetailTypes.STK_RCPT_DETAIL_TRANSITION_TO_STATUS,
    stkRcptDetailTransitionToStatus
  ),

  takeLatest(StkRcptDetailTypes.STK_RCPT_DETAIL_SHOW_DETAILS, stkRcptDetailShowDetails),

  takeLatest(StkRcptDetailTypes.STK_RCPT_DETAIL_UPDATE_DETAILS, stkRcptDetailUpdateDetails),
  takeLatest(StkRcptDetailTypes.STK_RCPT_DETAIL_CREATE_DETAIL, stkRcptDetailCreateDetail),
  takeLatest(StkRcptDetailTypes.STK_RCPT_DETAIL_DELETE_DETAIL, stkRcptDetailDeleteDetail),

  takeLatest(
    StkRcptDetailTypes.STK_RCPT_DETAIL_FETCH_CURRENCY_OPTIONS,
    stkRcptDetailFetchCurrencyOptions
  ),
  takeLatest(StkRcptDetailTypes.STK_RCPT_DETAIL_CHANGE_CURRENCY, stkRcptDetailChangeCurrency),

  takeLatest(
    StkRcptDetailTypes.STK_RCPT_DETAIL_FETCH_FR_DIVISION_OPTIONS,
    stkRcptDetailFetchFrDivisionOptions
  ),

  takeLatest(
    StkRcptDetailTypes.STK_RCPT_DETAIL_FETCH_FR_LOCATION_OPTIONS,
    stkRcptDetailFetchFrLocationOptions
  ),

  takeLatest(
    StkRcptDetailTypes.STK_RCPT_DETAIL_FETCH_LOCATION_OPTIONS,
    stkRcptDetailFetchLocationOptions
  ),

  takeLatest(StkRcptDetailTypes.STK_RCPT_DETAIL_FETCH_ITEM_OPTIONS, stkRcptDetailFetchItemOptions),
  takeLatest(StkRcptDetailTypes.STK_RCPT_DETAIL_CHANGE_ITEM, stkRcptDetailChangeItem),

  takeLatest(StkRcptDetailTypes.STK_RCPT_DETAIL_CHANGE_UOM, stkRcptDetailChangeUom),
  takeLatest(StkRcptDetailTypes.STK_RCPT_DETAIL_FETCH_UOM_OPTIONS, stkRcptDetailFetchUomOptions),

  takeLatest(
    StkRcptDetailTypes.STK_RCPT_DETAIL_FETCH_PROJECT_OPTIONS,
    stkRcptDetailFetchProjectOptions
  )
];
