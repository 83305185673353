import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import treeChanges from 'tree-changes';

import { Row, Col, Modal, Table } from 'antd';

import GdsRcptDetailActions from '../../Stores/GdsRcptDetail/Actions';

class DetailSummaryDialog extends React.PureComponent {
  constructor() {
    super();

    this.state = {
      treeData: []
    };

    this.handleCancel = this.handleCancel.bind(this);
    this.getDocumentColumns = this.getDocumentColumns.bind(this);
    this.useOnVisible = this.useOnVisible.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    const { changedTo } = treeChanges(prevProps, this.props);

    if (changedTo('detailSummaryIsVisible', true)) {
      this.useOnVisible();
    }
  }

  componentWillUnmount() {}

  handleCancel() {
    const { setDetailSummaryVisible } = this.props;

    setDetailSummaryVisible(false);
  }

  getDocumentColumns() {
    const { intl } = this.props;

    return [
      {
        width: 120,
        align: 'left',
        title: intl.formatMessage({ id: 'item_cond_01' }),
        // sort field
        dataIndex: 'item_cond_01_code',
        // filter field
        key: 'item_cond_01_code',
        render: (text, record) => <>{record.item_cond_01_code}</>
      },
      {
        width: 120,
        align: 'left',
        title: intl.formatMessage({ id: 'code' }),
        // sort field
        dataIndex: 'item_code',
        // filter field
        key: 'item_code',
        render: (text, record) => <>{record.item_code}</>
      },
      {
        width: 150,
        align: 'left',
        title: intl.formatMessage({ id: 'description' }),
        // sort field
        dataIndex: 'desc_01',
        // filter field
        key: 'desc_01',
        render: (text, record) => (
          <>
            {record.desc_01}
            {record.desc_02 ? (
              <>
                <br />
                {record.desc_02}
              </>
            ) : (
              ''
            )}
            {record.desc_03 ? (
              <>
                <br />
                {record.desc_03}
              </>
            ) : (
              ''
            )}
          </>
        )
      },
      {
        width: 100,
        align: 'right',
        title: intl.formatMessage({ id: 'case' }),
        // sort field
        dataIndex: 'case_qty',
        // filter field
        key: 'case_qty',
        render: (text, record) => (
          <>
            {new Intl.NumberFormat([], {
              style: 'decimal',
              minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
              maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
            }).format(record.case_qty)}
            <br />
            {record.item_case_uom_code}
          </>
        )
      },
      {
        width: 100,
        align: 'right',
        title: intl.formatMessage({ id: 'loose' }),
        // sort field
        dataIndex: 'loose_qty',
        // filter field
        key: 'loose_qty',
        render: (text, record) => (
          <>
            {new Intl.NumberFormat([], {
              style: 'decimal',
              minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
              maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
            }).format(record.loose_qty)}
            <br />
            {record.item_loose_uom_code}
          </>
        )
      },
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'expiry_date' }),
        // sort field
        dataIndex: 'expiry_date',
        // filter field
        key: 'expiry_date',
        render: (text, record) => (
          <>
            <div style={{ fontWeight: 'bold' }}>{record.batch_serial_no}</div>
            {record.expiry_date ? <div>{record.expiry_date}</div> : ''}
          </>
        )
      },
      {
        width: 120,
        align: 'left',
        title: intl.formatMessage({ id: 'company' }),
        // sort field
        dataIndex: 'company_code',
        // filter field
        key: 'company_code',
        render: (text, record) => <>{record.company_code}</>
      },
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'to_storage_bin' }),
        // sort field
        dataIndex: 'to_storage_bin_code',
        // filter field
        key: 'to_storage_bin_code',
        render: (text, record) => <>{record.to_storage_bin_code}</>
      }
    ];
  }

  useOnVisible() {
    const { documentDetails } = this.props;

    const hashByKey1 = {};
    const detailByKey1 = {};
    const detailByKey3 = {};
    documentDetails.forEach(data => {
      const key1 = data.item_code;
      const key2 = data.item_cond_01_code;
      const key3 = `${data.item_code}/${data.item_cond_01_code}`;

      let hashByKey2 = {};
      if (key1 in hashByKey1) {
        hashByKey2 = hashByKey1[key1];
      }

      let tmpDetails = [];
      if (key2 in hashByKey2) {
        tmpDetails = hashByKey2[key2];
      }

      tmpDetails.push(data);
      hashByKey2[key2] = tmpDetails;
      hashByKey1[key1] = hashByKey2;

      detailByKey1[key1] = {
        ...data,
        id: Math.floor(Math.random() * 10000) + 1,
        batch_serial_no: '',
        expiry_date: '',
        company_code: '',
        to_storage_bin_code: '',
        to_handling_unit_barcode: '',
        item_cond_01_code: ''
      };
      detailByKey3[key3] = {
        ...data,
        id: Math.floor(Math.random() * 10000) + 2,
        item_code: '',
        desc_01: '',
        desc_02: '',
        batch_serial_no: '',
        expiry_date: '',
        company_code: '',
        to_storage_bin_code: '',
        to_handling_unit_barcode: ''
      };
    });

    const treeData = [];
    Object.entries(hashByKey1).forEach(entry1 => {
      let key1UnitQty = 0;
      let key1CaseQty = 0;
      let key1LooseQty = 0;

      const key1 = entry1[0];
      const hashByKey2 = entry1[1];
      const key1ParentDetail = detailByKey1[key1];

      const key2ParentDetails = [];
      Object.entries(hashByKey2).forEach(entry2 => {
        let key2UnitQty = 0;
        let key2CaseQty = 0;
        let key2LooseQty = 0;

        const key2 = entry2[0];
        const tmpDetails = entry2[1];

        tmpDetails.forEach(tmpDetail => {
          key1UnitQty += parseFloat(tmpDetail.unit_qty);
          key1CaseQty += parseFloat(tmpDetail.case_qty);
          key1LooseQty += parseFloat(tmpDetail.loose_qty);

          key2UnitQty += parseFloat(tmpDetail.unit_qty);
          key2CaseQty += parseFloat(tmpDetail.case_qty);
          key2LooseQty += parseFloat(tmpDetail.loose_qty);
        });

        const key3 = `${key1}/${key2}`;
        const key2ParentDetail = detailByKey3[key3];
        key2ParentDetail.unit_qty = key2UnitQty;
        key2ParentDetail.case_qty = key2CaseQty;
        key2ParentDetail.loose_qty = key2LooseQty;
        key2ParentDetail.children = tmpDetails;
        key2ParentDetails.push(key2ParentDetail);
      });
      key1ParentDetail.unit_qty = key1UnitQty;
      key1ParentDetail.case_qty = key1CaseQty;
      key1ParentDetail.loose_qty = key1LooseQty;
      key1ParentDetail.children = key2ParentDetails;

      treeData.push(key1ParentDetail);
    });

    this.setState({
      treeData
    });
  }

  render() {
    const { intl, detailSummaryIsVisible, documentIsLoading } = this.props;
    const { treeData } = this.state;

    return (
      <>
        <Modal
          visible={detailSummaryIsVisible}
          title={intl.formatMessage({ id: 'details_summary' })}
          // style={{top:20}}
          width="80%"
          centered
          // onOk={this.handleOk}
          okButtonProps={{ disabled: true }}
          onCancel={this.handleCancel}
        >
          <Row type="flex" justify="center" gutter={[0, 8]}>
            <Col span={24}>
              <Table
                // rowSelection={rowSelection}
                rowKey="id"
                pagination={false}
                columns={this.getDocumentColumns()}
                dataSource={treeData}
                loading={documentIsLoading}
                bordered
              />
            </Col>
          </Row>
        </Modal>
      </>
    );
  }
}

DetailSummaryDialog.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  setDetailSummaryVisible: PropTypes.func,
  detailSummaryIsVisible: PropTypes.bool,
  documentDetails: PropTypes.arrayOf(PropTypes.object),

  documentIsLoading: PropTypes.bool
};

DetailSummaryDialog.defaultProps = {
  intl: {},
  setDetailSummaryVisible() {},
  detailSummaryIsVisible: false,
  documentDetails: [],

  documentIsLoading: false
};

const mapStateToProps = state => ({
  detailSummaryIsVisible: state.gdsRcptDetail.detailSummaryIsVisible,

  documentDetails: state.gdsRcptDetail.documentDetails,
  documentIsLoading: state.gdsRcptDetail.documentIsLoading
});

const mapDispatchToProps = dispatch => ({
  setDetailSummaryVisible: boolean =>
    dispatch(GdsRcptDetailActions.gdsRcptDetailSetDetailSummaryVisible(boolean))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(DetailSummaryDialog));
