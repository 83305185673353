import React from 'react';
import { animated, to } from 'react-spring';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';

class CustomBar extends React.PureComponent {
  constructor() {
    super();
    this.formatData = this.formatData.bind(this);
    this.getCustomToolTipHTML = this.getCustomToolTipHTML.bind(this);
    this.onMouseHoverHandler = this.onMouseHoverHandler.bind(this);
    this.onMouseLeaveHandler = this.onMouseLeaveHandler.bind(this);
  }

  // eslint-disable-next-line class-methods-use-this
  onMouseHoverHandler(event, action, fulfillmentType, fulfillmentValue) {
    const { tooltipRef } = this.props;
    const xPos = event.pageX;
    const yPos = event.pageY;
    const xPosCenter = xPos - 320;
    const yPosCenter = yPos - 280;

    if (!tooltipRef.current) {
      return;
    }

    tooltipRef.current.style.transform = `translate(${xPosCenter}px, ${yPosCenter}px)`;

    if (action === 'move') {
      return;
    }

    tooltipRef.current.style.display = 'inline-flex';
    tooltipRef.current.innerHTML = this.getCustomToolTipHTML(fulfillmentType, fulfillmentValue);
  }

  // eslint-disable-next-line class-methods-use-this
  onMouseLeaveHandler() {
    const { tooltipRef } = this.props;

    if (tooltipRef.current) {
      tooltipRef.current.style.display = 'none';
    }
  }

  // eslint-disable-next-line class-methods-use-this
  getCustomToolTipHTML(fulfillmentType, value) {
    const { intl } = this.props;

    return `<span><strong>${intl.formatMessage({
      id: fulfillmentType
    })} </strong>: <strong>${value}</strong></span>`;
  }

  // eslint-disable-next-line class-methods-use-this
  formatData(data) {
    const str = data.toString().split('.');
    if (str[0].length >= 3) {
      str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
    }
    if (str[1] && str[1].length >= 5) {
      str[1] = str[1].replace(/(\d{3})/g, '$1 ');
    }

    return str.join('.');
  }

  render() {
    const { customBarProps, visibleAverageKeys, heightToValueRatio, numOfDates } = this.props;

    const { bar, style } = customBarProps;
    const springHeight = style.height;

    const { x, y, width, height, data, color } = bar;
    const { id: fulfillmentType, value: fulfillmentValue } = data;

    // extract average data
    const { averageData } = data.data;

    const avgOrdCreated = averageData.ord_created;
    const avgOrdCreatedYValue =
      fulfillmentType === 'ord_created'
        ? (fulfillmentValue - avgOrdCreated) * heightToValueRatio + y
        : 0;

    const avgOrdPicked = averageData.ord_picked;
    const avgOrdPickedYValue =
      fulfillmentType === 'ord_picked'
        ? (fulfillmentValue - avgOrdPicked) * heightToValueRatio + y
        : 0;

    const avgOrdLoaded = averageData.ord_loaded;
    const avgOrdLoadedYValue =
      fulfillmentType === 'ord_loaded'
        ? (fulfillmentValue - avgOrdLoaded) * heightToValueRatio + y
        : 0;

    const avgOrdDelivered = averageData.ord_delivered;
    const avgOrdDeliveredYValue =
      fulfillmentType === 'ord_delivered'
        ? (fulfillmentValue - avgOrdDelivered) * heightToValueRatio + y
        : 0;

    // average line position - by trial and error
    const avgLineLength = 1.66 * 4 * numOfDates * width;

    // average line label positioning
    const avgLabelX = -30;
    const getAvgLabelY = avgLineYValue => avgLineYValue;

    const renderAvgLabelBox = avgLineYValue => {
      return (
        <rect
          x={-69}
          y={avgLineYValue - 6}
          width="80"
          height="13"
          style={{ fill: 'rgb(255,255,255)', strokeWidth: 0.2, stroke: color }}
        />
      );
    };

    return (
      <g>
        <animated.rect
          transform={`rotate(180,${x + 0.5 * width}, ${y + 0.5 * height})`}
          fill={color}
          height={to(springHeight, value => Math.max(value, 0))}
          width={width}
          x={x}
          y={y}
          onMouseEnter={
            event => this.onMouseHoverHandler(event, 'enter', fulfillmentType, fulfillmentValue)
            // eslint-disable-next-line react/jsx-curly-newline
          }
          onMouseMove={
            event => this.onMouseHoverHandler(event, 'move', fulfillmentType, fulfillmentValue)
            // eslint-disable-next-line react/jsx-curly-newline
          }
          onMouseLeave={this.onMouseLeaveHandler}
        />
        <text
          x={x + width / 2}
          height={to(springHeight, value => Math.max(value, 0))}
          y={y - 15}
          textAnchor="middle"
          dominantBaseline="central"
          style={{
            fontSize: 12,
            pointerEvents: 'none',
            fill: 'black',
            opacity: 0.75
          }}
        >
          {data.value > 0 ? this.formatData(data.value) : null}
        </text>
        {avgOrdCreatedYValue > 0 && visibleAverageKeys.includes('ord_created') ? (
          <>
            <path
              d={`M${0.2 * width},${avgOrdCreatedYValue}L${avgLineLength},${avgOrdCreatedYValue}`}
              fill="none"
              stroke="hsl(0, 100%, 30%)"
              strokeDasharray="5,5"
            />
            {renderAvgLabelBox(avgOrdCreatedYValue)}
            <text
              x={avgLabelX}
              y={getAvgLabelY(avgOrdCreatedYValue)}
              textAnchor="middle"
              dominantBaseline="central"
              style={{
                fontSize: 12,
                pointerEvents: 'none',
                fill: 'black',
                opacity: 1
              }}
            >
              {this.formatData(avgOrdCreated)}
            </text>
          </>
        ) : null}
        {avgOrdPickedYValue > 0 && visibleAverageKeys.includes('ord_picked') ? (
          <>
            <path
              d={`M${0.2 * width},${avgOrdPickedYValue}L${avgLineLength},${avgOrdPickedYValue}`}
              fill="none"
              stroke={color}
              strokeDasharray="5,5"
            />
            {renderAvgLabelBox(avgOrdPickedYValue)}
            <text
              x={avgLabelX}
              y={getAvgLabelY(avgOrdPickedYValue)}
              textAnchor="middle"
              dominantBaseline="central"
              style={{
                fontSize: 12,
                pointerEvents: 'none',
                fill: 'black',
                opacity: 1
              }}
            >
              {this.formatData(avgOrdPicked)}
            </text>
          </>
        ) : null}
        {avgOrdLoadedYValue > 0 && visibleAverageKeys.includes('ord_loaded') ? (
          <>
            <path
              d={`M${0.2 * width},${avgOrdLoadedYValue}L${avgLineLength},${avgOrdLoadedYValue}`}
              fill={color}
              stroke={color}
              strokeDasharray="5,5"
            />
            {renderAvgLabelBox(avgOrdLoadedYValue)}
            <text
              x={avgLabelX}
              y={getAvgLabelY(avgOrdLoadedYValue)}
              textAnchor="middle"
              dominantBaseline="central"
              style={{
                fontSize: 12,
                pointerEvents: 'none',
                fill: 'black',
                opacity: 1
              }}
            >
              {this.formatData(avgOrdLoaded)}
            </text>
          </>
        ) : null}
        {avgOrdDeliveredYValue > 0 && visibleAverageKeys.includes('ord_delivered') ? (
          <>
            <path
              d={`M${0.2 *
                width},${avgOrdDeliveredYValue}L${avgLineLength},${avgOrdDeliveredYValue}`}
              fill="none"
              stroke={color}
              strokeDasharray="5,5"
            />
            {renderAvgLabelBox(avgOrdDeliveredYValue)}
            <text
              x={avgLabelX}
              y={getAvgLabelY(avgOrdDeliveredYValue)}
              textAnchor="middle"
              dominantBaseline="central"
              style={{
                fontSize: 12,
                pointerEvents: 'none',
                fill: 'black',
                opacity: 1
              }}
            >
              {this.formatData(avgOrdDelivered)}
            </text>
          </>
        ) : null}
      </g>
    );
  }
}

CustomBar.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  tooltipRef: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  customBarProps: PropTypes.object,
  visibleAverageKeys: PropTypes.arrayOf(PropTypes.string),
  heightToValueRatio: PropTypes.number,
  numOfDates: PropTypes.number
};

CustomBar.defaultProps = {
  intl: {},
  tooltipRef: {},
  customBarProps: {},
  visibleAverageKeys: [],
  heightToValueRatio: 0,
  numOfDates: 0
};

const mapStateToProps = state => ({
  visibleAverageKeys: state.slsOrdFulfillmentChart.slsOrdFulfillmentChartVisibleAverageKeys
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CustomBar));
