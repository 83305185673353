import { put, call, select, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import ApiService from '../Services/ApiService';
import AppActions from '../Stores/App/Actions';
import PickingAllocErrorReportActions, {
  PickingAllocErrorReportTypes
} from '../Stores/PickingAllocErrorReport/Actions';

const getAppStore = state => state.app;

export function* pickingAllocErrorReportInitPickingAllocError() {
  try {
    yield put(PickingAllocErrorReportActions.pickingAllocErrorReportReportLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `whseReport/initPickingAllocError/${app.curSiteFlowId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(
        PickingAllocErrorReportActions.pickingAllocErrorReportInitPickingAllocErrorSuccess(
          result.data
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(PickingAllocErrorReportActions.pickingAllocErrorReportReportLoading(false));
  }
}

export function* pickingAllocErrorReportPickingAllocError({ formikBag, criteria }) {
  try {
    formikBag.setSubmitting(true);
    yield put(PickingAllocErrorReportActions.pickingAllocErrorReportReportLoading(true));

    const objCriteria = {};
    if ('item_ids_select2' in criteria) {
      const values = criteria.item_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.item_ids = values.map(entry => {
          return entry.value;
        });
      }
    }
    if ('item_group_01_ids_select2' in criteria) {
      const values = criteria.item_group_01_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.item_group_01_ids = values.map(entry => {
          return entry.value;
        });
      }
    }
    if ('item_group_02_ids_select2' in criteria) {
      const values = criteria.item_group_02_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.item_group_02_ids = values.map(entry => {
          return entry.value;
        });
      }
    }
    if ('item_group_03_ids_select2' in criteria) {
      const values = criteria.item_group_03_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.item_group_03_ids = values.map(entry => {
          return entry.value;
        });
      }
    }
    if ('storage_bin_ids_select2' in criteria) {
      const values = criteria.storage_bin_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.storage_bin_ids = values.map(entry => {
          return entry.value;
        });
      }
    }
    if ('storage_row_ids_select2' in criteria) {
      const values = criteria.storage_row_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.storage_row_ids = values.map(entry => {
          return entry.value;
        });
      }
    }
    if ('storage_bay_ids_select2' in criteria) {
      const values = criteria.storage_bay_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.storage_bay_ids = values.map(entry => {
          return entry.value;
        });
      }
    }
    if ('location_ids_select2' in criteria) {
      const values = criteria.location_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.location_ids = values.map(entry => {
          return entry.value;
        });
      }
    }

    let columns = [];
    if (criteria.columns.target.length > 0) {
      columns = criteria.columns.target.map(entry => {
        return entry.column;
      });
    }

    const app = yield select(getAppStore);
    const postData = {
      sorts: [],
      criteria: objCriteria,
      columns
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `whseReport/pickingAllocError/${app.curSiteFlowId}`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      yield put(
        PickingAllocErrorReportActions.pickingAllocErrorReportPickingAllocErrorSuccess(
          criteria,
          result.data.data
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
    yield put(PickingAllocErrorReportActions.pickingAllocErrorReportReportLoading(false));
  }
}

export function* pickingAllocErrorReportFetchItemOptions({ search }) {
  try {
    yield put(PickingAllocErrorReportActions.pickingAllocErrorReportFetchItemOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `item/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(
        PickingAllocErrorReportActions.pickingAllocErrorReportFetchItemOptionSuccess(options)
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(PickingAllocErrorReportActions.pickingAllocErrorReportFetchItemOptionLoading(false));
  }
}

export function* pickingAllocErrorReportFetchItemGroup01Options({ search }) {
  try {
    yield put(
      PickingAllocErrorReportActions.pickingAllocErrorReportFetchItemGroup01OptionLoading(true)
    );

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `itemGroup01/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(
        PickingAllocErrorReportActions.pickingAllocErrorReportFetchItemGroup01OptionSuccess(options)
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(
      PickingAllocErrorReportActions.pickingAllocErrorReportFetchItemGroup01OptionLoading(false)
    );
  }
}

export function* pickingAllocErrorReportFetchItemGroup02Options({ search }) {
  try {
    yield put(
      PickingAllocErrorReportActions.pickingAllocErrorReportFetchItemGroup02OptionLoading(true)
    );

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `itemGroup02/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(
        PickingAllocErrorReportActions.pickingAllocErrorReportFetchItemGroup02OptionSuccess(options)
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(
      PickingAllocErrorReportActions.pickingAllocErrorReportFetchItemGroup02OptionLoading(false)
    );
  }
}

export function* pickingAllocErrorReportFetchItemGroup03Options({ search }) {
  try {
    yield put(
      PickingAllocErrorReportActions.pickingAllocErrorReportFetchItemGroup03OptionLoading(true)
    );

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `itemGroup03/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(
        PickingAllocErrorReportActions.pickingAllocErrorReportFetchItemGroup03OptionSuccess(options)
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(
      PickingAllocErrorReportActions.pickingAllocErrorReportFetchItemGroup03OptionLoading(false)
    );
  }
}

export function* pickingAllocErrorReportFetchStorageBinOptions({ search }) {
  try {
    yield put(
      PickingAllocErrorReportActions.pickingAllocErrorReportFetchStorageBinOptionLoading(true)
    );

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId: app.curSiteFlowId,
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `storageBin/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(
        PickingAllocErrorReportActions.pickingAllocErrorReportFetchStorageBinOptionSuccess(options)
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(
      PickingAllocErrorReportActions.pickingAllocErrorReportFetchStorageBinOptionLoading(false)
    );
  }
}

export function* pickingAllocErrorReportFetchStorageRowOptions({ search }) {
  try {
    yield put(
      PickingAllocErrorReportActions.pickingAllocErrorReportFetchStorageRowOptionLoading(true)
    );

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId: app.curSiteFlowId,
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `storageRow/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(
        PickingAllocErrorReportActions.pickingAllocErrorReportFetchStorageRowOptionSuccess(options)
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(
      PickingAllocErrorReportActions.pickingAllocErrorReportFetchStorageRowOptionLoading(false)
    );
  }
}

export function* pickingAllocErrorReportFetchStorageBayOptions({ search }) {
  try {
    yield put(
      PickingAllocErrorReportActions.pickingAllocErrorReportFetchStorageBayOptionLoading(true)
    );

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId: app.curSiteFlowId,
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `storageBay/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(
        PickingAllocErrorReportActions.pickingAllocErrorReportFetchStorageBayOptionSuccess(options)
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(
      PickingAllocErrorReportActions.pickingAllocErrorReportFetchStorageBayOptionLoading(false)
    );
  }
}

export function* pickingAllocErrorReportFetchLocationOptions({ search }) {
  try {
    yield put(
      PickingAllocErrorReportActions.pickingAllocErrorReportFetchLocationOptionLoading(true)
    );

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId: app.curSiteFlowId,
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `location/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(
        PickingAllocErrorReportActions.pickingAllocErrorReportFetchLocationOptionSuccess(options)
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(
      PickingAllocErrorReportActions.pickingAllocErrorReportFetchLocationOptionLoading(false)
    );
  }
}

export const saga = [
  takeLatest(
    PickingAllocErrorReportTypes.PICKING_ALLOC_ERROR_REPORT_INIT_PICKING_ALLOC_ERROR,
    pickingAllocErrorReportInitPickingAllocError
  ),
  takeLatest(
    PickingAllocErrorReportTypes.PICKING_ALLOC_ERROR_REPORT_PICKING_ALLOC_ERROR,
    pickingAllocErrorReportPickingAllocError
  ),
  takeLatest(
    PickingAllocErrorReportTypes.PICKING_ALLOC_ERROR_REPORT_FETCH_ITEM_OPTIONS,
    pickingAllocErrorReportFetchItemOptions
  ),
  takeLatest(
    PickingAllocErrorReportTypes.PICKING_ALLOC_ERROR_REPORT_FETCH_ITEM_GROUP01_OPTIONS,
    pickingAllocErrorReportFetchItemGroup01Options
  ),
  takeLatest(
    PickingAllocErrorReportTypes.PICKING_ALLOC_ERROR_REPORT_FETCH_ITEM_GROUP02_OPTIONS,
    pickingAllocErrorReportFetchItemGroup02Options
  ),
  takeLatest(
    PickingAllocErrorReportTypes.PICKING_ALLOC_ERROR_REPORT_FETCH_ITEM_GROUP03_OPTIONS,
    pickingAllocErrorReportFetchItemGroup03Options
  ),
  takeLatest(
    PickingAllocErrorReportTypes.PICKING_ALLOC_ERROR_REPORT_FETCH_STORAGE_BIN_OPTIONS,
    pickingAllocErrorReportFetchStorageBinOptions
  ),
  takeLatest(
    PickingAllocErrorReportTypes.PICKING_ALLOC_ERROR_REPORT_FETCH_STORAGE_ROW_OPTIONS,
    pickingAllocErrorReportFetchStorageRowOptions
  ),
  takeLatest(
    PickingAllocErrorReportTypes.PICKING_ALLOC_ERROR_REPORT_FETCH_STORAGE_BAY_OPTIONS,
    pickingAllocErrorReportFetchStorageBayOptions
  ),
  takeLatest(
    PickingAllocErrorReportTypes.PICKING_ALLOC_ERROR_REPORT_FETCH_LOCATION_OPTIONS,
    pickingAllocErrorReportFetchLocationOptions
  )
];
