import { put, call, select, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import ApiService from '../Services/ApiService';
import AppActions from '../Stores/App/Actions';
import LspSlsOrdTrackingReportActions, {
  LspSlsOrdTrackingReportTypes
} from '../Stores/LspSlsOrdTrackingReport/Actions';

const getAppStore = state => state.app;

export function* lspSlsOrdTrackingReportInitLspSlsOrdTracking() {
  try {
    yield put(LspSlsOrdTrackingReportActions.lspSlsOrdTrackingReportReportLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `logisticReport/initLspSlsOrdTracking/${app.curSiteFlowId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(
        LspSlsOrdTrackingReportActions.lspSlsOrdTrackingReportInitLspSlsOrdTrackingSuccess(
          result.data
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(LspSlsOrdTrackingReportActions.lspSlsOrdTrackingReportReportLoading(false));
  }
}

export function* lspSlsOrdTrackingReportLspSlsOrdTracking({ formikBag, criteria }) {
  try {
    formikBag.setSubmitting(true);
    yield put(LspSlsOrdTrackingReportActions.lspSlsOrdTrackingReportReportLoading(true));

    const objCriteria = {};
    if ('start_date' in criteria) {
      objCriteria.start_date = criteria.start_date;
    }
    if ('end_date' in criteria) {
      objCriteria.end_date = criteria.end_date;
    }
    if ('division_ids_select2' in criteria) {
      const values = criteria.division_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.division_ids = values.map(entry => {
          return entry.value;
        });
      }
    }
    if ('biz_partner_ids_select2' in criteria) {
      const values = criteria.biz_partner_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.biz_partner_ids = values.map(entry => {
          return entry.value;
        });
      }
    }
    if ('debtor_ids_select2' in criteria) {
      const values = criteria.debtor_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.debtor_ids = values.map(entry => {
          return entry.value;
        });
      }
    }
    if ('delivery_point_ids_select2' in criteria) {
      const values = criteria.delivery_point_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.delivery_point_ids = values.map(entry => {
          return entry.value;
        });
      }
    }
    if ('transport_ids_select2' in criteria) {
      const values = criteria.transport_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.transport_ids = values.map(entry => {
          return entry.value;
        });
      }
    }

    let columns = [];
    if (criteria.columns.target.length > 0) {
      columns = criteria.columns.target.map(entry => {
        return entry.column;
      });
    }

    const app = yield select(getAppStore);
    const postData = {
      sorts: [],
      criteria: objCriteria,
      columns
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `logisticReport/lspSlsOrdTracking/${app.curSiteFlowId}`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      yield put(
        LspSlsOrdTrackingReportActions.lspSlsOrdTrackingReportLspSlsOrdTrackingSuccess(
          criteria,
          result.data.data
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
    yield put(LspSlsOrdTrackingReportActions.lspSlsOrdTrackingReportReportLoading(false));
  }
}

export function* lspSlsOrdTrackingReportFetchDivisionOptions({ search }) {
  try {
    yield put(
      LspSlsOrdTrackingReportActions.lspSlsOrdTrackingReportFetchDivisionOptionLoading(true)
    );

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `division/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.name_01}`
      }));

      yield put(
        LspSlsOrdTrackingReportActions.lspSlsOrdTrackingReportFetchDivisionOptionSuccess(options)
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(
      LspSlsOrdTrackingReportActions.lspSlsOrdTrackingReportFetchDivisionOptionLoading(false)
    );
  }
}

export function* lspSlsOrdTrackingReportFetchDeliveryPointOptions({ search }) {
  try {
    yield put(
      LspSlsOrdTrackingReportActions.lspSlsOrdTrackingReportFetchDeliveryPointOptionLoading(true)
    );

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `deliveryPoint/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.company_name_01}`
      }));

      yield put(
        LspSlsOrdTrackingReportActions.lspSlsOrdTrackingReportFetchDeliveryPointOptionSuccess(
          options
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(
      LspSlsOrdTrackingReportActions.lspSlsOrdTrackingReportFetchDeliveryPointOptionLoading(false)
    );
  }
}

export function* lspSlsOrdTrackingReportFetchDebtorOptions({ search }) {
  try {
    yield put(LspSlsOrdTrackingReportActions.lspSlsOrdTrackingReportFetchDebtorOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      debtorType: 1, // 0 = normal, 1 = principal
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `debtor/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.company_name_01} ${d.company_name_02}`
      }));

      yield put(
        LspSlsOrdTrackingReportActions.lspSlsOrdTrackingReportFetchDebtorOptionSuccess(options)
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(
      LspSlsOrdTrackingReportActions.lspSlsOrdTrackingReportFetchDebtorOptionLoading(false)
    );
  }
}

export function* lspSlsOrdTrackingReportFetchTransportOptions({ search }) {
  try {
    yield put(
      LspSlsOrdTrackingReportActions.lspSlsOrdTrackingReportFetchTransportOptionLoading(true)
    );

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId: app.curSiteFlowId,
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `transport/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01} ${d.desc_02}`
      }));

      yield put(
        LspSlsOrdTrackingReportActions.lspSlsOrdTrackingReportFetchTransportOptionSuccess(options)
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(
      LspSlsOrdTrackingReportActions.lspSlsOrdTrackingReportFetchTransportOptionLoading(false)
    );
  }
}

export const saga = [
  takeLatest(
    LspSlsOrdTrackingReportTypes.LSP_SLS_ORD_TRACKING_REPORT_INIT_LSP_SLS_ORD_TRACKING,
    lspSlsOrdTrackingReportInitLspSlsOrdTracking
  ),
  takeLatest(
    LspSlsOrdTrackingReportTypes.LSP_SLS_ORD_TRACKING_REPORT_LSP_SLS_ORD_TRACKING,
    lspSlsOrdTrackingReportLspSlsOrdTracking
  ),

  takeLatest(
    LspSlsOrdTrackingReportTypes.LSP_SLS_ORD_TRACKING_REPORT_FETCH_DIVISION_OPTIONS,
    lspSlsOrdTrackingReportFetchDivisionOptions
  ),
  takeLatest(
    LspSlsOrdTrackingReportTypes.LSP_SLS_ORD_TRACKING_REPORT_FETCH_DELIVERY_POINT_OPTIONS,
    lspSlsOrdTrackingReportFetchDeliveryPointOptions
  ),

  takeLatest(
    LspSlsOrdTrackingReportTypes.LSP_SLS_ORD_TRACKING_REPORT_FETCH_DEBTOR_OPTIONS,
    lspSlsOrdTrackingReportFetchDebtorOptions
  ),

  takeLatest(
    LspSlsOrdTrackingReportTypes.LSP_SLS_ORD_TRACKING_REPORT_FETCH_TRANSPORT_OPTIONS,
    lspSlsOrdTrackingReportFetchTransportOptions
  )
];
