import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import treeChanges from 'tree-changes';

import { Card, Tabs, Tooltip, Spin } from 'antd';

import LspReceivingProcessActions from '../../Stores/LspReceivingProcess/Actions';

import LspAdvShipExcel01Form from '../LspAdvShipExcel01/LspAdvShipExcel01Form';
import InbOrd03Table from '../InbOrd03/InbOrd03Table';

const { TabPane } = Tabs;

class LspReceivingProcessScreen extends React.PureComponent {
  componentDidMount() {
    const { curDivisionId, fetchLspReceivingProcess } = this.props;

    if (curDivisionId > 0) {
      fetchLspReceivingProcess();
    }
  }

  componentDidUpdate(prevProps) {
    const { curDivisionId, fetchLspReceivingProcess } = this.props;
    const { changed } = treeChanges(prevProps, this.props);
    if (changed('curDivisionId')) {
      if (curDivisionId > 0) {
        fetchLspReceivingProcess();
      }
    }
  }

  componentWillUnmount() {}

  render() {
    const { intl, match, process, appPath, processIsLoading } = this.props;

    return (
      <Card>
        <Spin spinning={processIsLoading}>
          <Tabs type="card" activeKey={match.params.proc_type}>
            {process.map(value => (
              <TabPane
                tab={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <Tooltip
                    placement="bottom"
                    title={intl.formatMessage({ id: `${value.proc_type.toLowerCase()}_desc` })}
                  >
                    <Link
                      style={value.proc_type !== match.params.proc_type ? { color: 'black' } : {}}
                      to={`${appPath}/lspReceivingProcess/index/${value.proc_type}`}
                    >
                      <FormattedMessage id={`${value.proc_type.toLowerCase()}_label`} />
                    </Link>
                  </Tooltip>
                }
                key={value.proc_type}
              />
            ))}
          </Tabs>
        </Spin>

        {match.params.proc_type === 'LSP_ADV_SHIP_EXCEL_01' && (
          <LspAdvShipExcel01Form match={match} />
        )}
        {match.params.proc_type === 'INB_ORD_03' && <InbOrd03Table match={match} />}
      </Card>
    );
  }
}

LspReceivingProcessScreen.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object,
  appPath: PropTypes.string,
  process: PropTypes.arrayOf(PropTypes.object),
  processIsLoading: PropTypes.bool,

  curDivisionId: PropTypes.number,
  fetchLspReceivingProcess: PropTypes.func
};

LspReceivingProcessScreen.defaultProps = {
  intl: {},
  match: {},
  appPath: '',
  process: [],
  processIsLoading: false,

  curDivisionId: 0,
  fetchLspReceivingProcess() {}
};

const mapStateToProps = state => ({
  appPath: state.app.appPath,
  process: state.lspReceivingProcess.process,
  processIsLoading: state.lspReceivingProcess.processIsLoading,

  curDivisionId: state.app.curDivisionId
});

const mapDispatchToProps = dispatch => ({
  fetchLspReceivingProcess: () =>
    dispatch(LspReceivingProcessActions.lspReceivingProcessFetchLspReceivingProcess())
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(LspReceivingProcessScreen));
