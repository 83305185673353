import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import treeChanges from 'tree-changes';

import { Card, Tabs, Tooltip, Spin } from 'antd';

import PurchaseProcessActions from '../../Stores/PurchaseProcess/Actions';

import AdvShipSync01Form from '../AdvShipSync01/AdvShipSync01Form';
import AdvShipSync02Form from '../AdvShipSync02/AdvShipSync02Form';
import AdvShipSync03Form from '../AdvShipSync03/AdvShipSync03Form';
import InbOrd01Table from '../InbOrd01/InbOrd01Table';
import AdvShipExcel01Form from '../AdvShipExcel01/AdvShipExcel01Form';
import AdvShipFServ01Form from '../AdvShipFServ01/AdvShipFServ01Form';
import PutAwaySync01Table from '../PutAwaySync01/PutAwaySync01Table';
import GdsRcptFServ01Table from '../GdsRcptFServ01/GdsRcptFServ01Table';
import AdvShipFServ02Table from '../AdvShipFServ02/AdvShipFServ02Table';
import GdsRcptSync01Table from '../GdsRcptSync01/GdsRcptSync01Table';

const { TabPane } = Tabs;

class PurchaseProcessScreen extends React.PureComponent {
  componentDidMount() {
    const { curDivisionId, fetchPurchaseProcess } = this.props;

    if (curDivisionId > 0) {
      fetchPurchaseProcess();
    }
  }

  componentDidUpdate(prevProps) {
    const { curDivisionId, fetchPurchaseProcess } = this.props;
    const { changed } = treeChanges(prevProps, this.props);
    if (changed('curDivisionId')) {
      if (curDivisionId > 0) {
        fetchPurchaseProcess();
      }
    }
  }

  componentWillUnmount() {}

  render() {
    const { intl, match, process, appPath, processIsLoading } = this.props;

    return (
      <Card>
        <Spin spinning={processIsLoading}>
          <Tabs type="card" activeKey={match.params.proc_type}>
            {process.map(value => (
              <TabPane
                tab={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <Tooltip
                    placement="bottom"
                    title={intl.formatMessage({ id: `${value.proc_type.toLowerCase()}_desc` })}
                  >
                    <Link
                      style={value.proc_type !== match.params.proc_type ? { color: 'black' } : {}}
                      to={`${appPath}/purchaseProcess/index/${value.proc_type}`}
                    >
                      <FormattedMessage id={`${value.proc_type.toLowerCase()}_label`} />
                    </Link>
                  </Tooltip>
                }
                key={value.proc_type}
              />
            ))}
          </Tabs>
        </Spin>

        {match.params.proc_type === 'ADV_SHIP_SYNC_01' && <AdvShipSync01Form match={match} />}
        {match.params.proc_type === 'ADV_SHIP_SYNC_02' && <AdvShipSync02Form match={match} />}
        {match.params.proc_type === 'ADV_SHIP_SYNC_03' && <AdvShipSync03Form match={match} />}
        {match.params.proc_type === 'INB_ORD_01' && <InbOrd01Table match={match} />}
        {match.params.proc_type === 'ADV_SHIP_EXCEL_01' && <AdvShipExcel01Form match={match} />}
        {match.params.proc_type === 'ADV_SHIP_F_SERV_01' && <AdvShipFServ01Form match={match} />}
        {match.params.proc_type === 'PUT_AWAY_SYNC_01' && <PutAwaySync01Table match={match} />}
        {match.params.proc_type === 'GDS_RCPT_F_SERV_01' && <GdsRcptFServ01Table match={match} />}
        {match.params.proc_type === 'ADV_SHIP_F_SERV_02' && <AdvShipFServ02Table match={match} />}
        {match.params.proc_type === 'GDS_RCPT_SYNC_01' && <GdsRcptSync01Table match={match} />}
      </Card>
    );
  }
}

PurchaseProcessScreen.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object,
  appPath: PropTypes.string,
  process: PropTypes.arrayOf(PropTypes.object),
  processIsLoading: PropTypes.bool,

  curDivisionId: PropTypes.number,
  fetchPurchaseProcess: PropTypes.func
};

PurchaseProcessScreen.defaultProps = {
  intl: {},
  match: {},
  appPath: '',
  process: [],
  processIsLoading: false,

  curDivisionId: 0,
  fetchPurchaseProcess() {}
};

const mapStateToProps = state => ({
  appPath: state.app.appPath,
  process: state.purchaseProcess.process,
  processIsLoading: state.purchaseProcess.processIsLoading,

  curDivisionId: state.app.curDivisionId
});

const mapDispatchToProps = dispatch => ({
  fetchPurchaseProcess: () => dispatch(PurchaseProcessActions.purchaseProcessFetchPurchaseProcess())
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PurchaseProcessScreen));
