/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { SiteDetailTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const siteDetailResetModelTimestamp = state => ({
  ...state,
  modelTimestamp: Date.now()
});

export const siteDetailSetResId = (state, { resId }) => ({
  ...state,
  modelTimestamp: Date.now(),
  resId
});

export const siteDetailShowModelLoading = (state, { boolean }) => ({
  ...state,
  modelIsLoading: boolean
});

export const siteDetailShowModelSuccess = (state, { model }) => ({
  ...state,
  model
});

export const siteDetailUpdateModelSuccess = (state, { model }) => ({
  ...state,
  model
});

export const siteDetailFetchPickListDocNoOptionLoading = (state, { boolean }) => ({
  ...state,
  pickListDocNoIsLoading: boolean
});

export const siteDetailFetchPickListDocNoOptionSuccess = (state, { options }) => ({
  ...state,
  pickListDocNoOptions: options
});

export const siteDetailFetchPackListDocNoOptionLoading = (state, { boolean }) => ({
  ...state,
  packListDocNoIsLoading: boolean
});

export const siteDetailFetchPackListDocNoOptionSuccess = (state, { options }) => ({
  ...state,
  packListDocNoOptions: options
});

export const siteDetailFetchGdsRcptDocNoOptionLoading = (state, { boolean }) => ({
  ...state,
  gdsRcptDocNoIsLoading: boolean
});

export const siteDetailFetchGdsRcptDocNoOptionSuccess = (state, { options }) => ({
  ...state,
  gdsRcptDocNoOptions: options
});

export const siteDetailFetchPutAwayDocNoOptionLoading = (state, { boolean }) => ({
  ...state,
  putAwayDocNoIsLoading: boolean
});

export const siteDetailFetchPutAwayDocNoOptionSuccess = (state, { options }) => ({
  ...state,
  putAwayDocNoOptions: options
});

export const siteDetailFetchGdsIssDocNoOptionLoading = (state, { boolean }) => ({
  ...state,
  gdsIssDocNoIsLoading: boolean
});

export const siteDetailFetchGdsIssDocNoOptionSuccess = (state, { options }) => ({
  ...state,
  gdsIssDocNoOptions: options
});

export const siteDetailFetchBinTrfDocNoOptionLoading = (state, { boolean }) => ({
  ...state,
  binTrfDocNoIsLoading: boolean
});

export const siteDetailFetchBinTrfDocNoOptionSuccess = (state, { options }) => ({
  ...state,
  binTrfDocNoOptions: options
});

export const siteDetailFetchCycleCountDocNoOptionLoading = (state, { boolean }) => ({
  ...state,
  cycleCountDocNoIsLoading: boolean
});

export const siteDetailFetchCycleCountDocNoOptionSuccess = (state, { options }) => ({
  ...state,
  cycleCountDocNoOptions: options
});

export const siteDetailFetchCountAdjDocNoOptionLoading = (state, { boolean }) => ({
  ...state,
  countAdjDocNoIsLoading: boolean
});

export const siteDetailFetchCountAdjDocNoOptionSuccess = (state, { options }) => ({
  ...state,
  countAdjDocNoOptions: options
});

export const siteDetailFetchWhseJobDocNoOptionLoading = (state, { boolean }) => ({
  ...state,
  whseJobDocNoIsLoading: boolean
});

export const siteDetailFetchWhseJobDocNoOptionSuccess = (state, { options }) => ({
  ...state,
  whseJobDocNoOptions: options
});

export const siteDetailFetchLoadListDocNoOptionLoading = (state, { boolean }) => ({
  ...state,
  loadListDocNoIsLoading: boolean
});

export const siteDetailFetchLoadListDocNoOptionSuccess = (state, { options }) => ({
  ...state,
  loadListDocNoOptions: options
});

export const siteDetailFetchPrfDelDocNoOptionLoading = (state, { boolean }) => ({
  ...state,
  prfDelDocNoIsLoading: boolean
});

export const siteDetailFetchPrfDelDocNoOptionSuccess = (state, { options }) => ({
  ...state,
  prfDelDocNoOptions: options
});

export const siteDetailFetchFailDelDocNoOptionLoading = (state, { boolean }) => ({
  ...state,
  failDelDocNoIsLoading: boolean
});

export const siteDetailFetchFailDelDocNoOptionSuccess = (state, { options }) => ({
  ...state,
  failDelDocNoOptions: options
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [SiteDetailTypes.SITE_DETAIL_RESET_MODEL_TIMESTAMP]: siteDetailResetModelTimestamp,
  [SiteDetailTypes.SITE_DETAIL_SET_RES_ID]: siteDetailSetResId,
  [SiteDetailTypes.SITE_DETAIL_SHOW_MODEL_LOADING]: siteDetailShowModelLoading,
  [SiteDetailTypes.SITE_DETAIL_SHOW_MODEL_SUCCESS]: siteDetailShowModelSuccess,
  [SiteDetailTypes.SITE_DETAIL_UPDATE_MODEL_SUCCESS]: siteDetailUpdateModelSuccess,

  [SiteDetailTypes.SITE_DETAIL_FETCH_PICK_LIST_DOC_NO_OPTION_LOADING]: siteDetailFetchPickListDocNoOptionLoading,
  [SiteDetailTypes.SITE_DETAIL_FETCH_PICK_LIST_DOC_NO_OPTION_SUCCESS]: siteDetailFetchPickListDocNoOptionSuccess,

  [SiteDetailTypes.SITE_DETAIL_FETCH_PACK_LIST_DOC_NO_OPTION_LOADING]: siteDetailFetchPackListDocNoOptionLoading,
  [SiteDetailTypes.SITE_DETAIL_FETCH_PACK_LIST_DOC_NO_OPTION_SUCCESS]: siteDetailFetchPackListDocNoOptionSuccess,

  [SiteDetailTypes.SITE_DETAIL_FETCH_GDS_RCPT_DOC_NO_OPTION_LOADING]: siteDetailFetchGdsRcptDocNoOptionLoading,
  [SiteDetailTypes.SITE_DETAIL_FETCH_GDS_RCPT_DOC_NO_OPTION_SUCCESS]: siteDetailFetchGdsRcptDocNoOptionSuccess,

  [SiteDetailTypes.SITE_DETAIL_FETCH_PUT_AWAY_DOC_NO_OPTION_LOADING]: siteDetailFetchPutAwayDocNoOptionLoading,
  [SiteDetailTypes.SITE_DETAIL_FETCH_PUT_AWAY_DOC_NO_OPTION_SUCCESS]: siteDetailFetchPutAwayDocNoOptionSuccess,

  [SiteDetailTypes.SITE_DETAIL_FETCH_GDS_ISS_DOC_NO_OPTION_LOADING]: siteDetailFetchGdsIssDocNoOptionLoading,
  [SiteDetailTypes.SITE_DETAIL_FETCH_GDS_ISS_DOC_NO_OPTION_SUCCESS]: siteDetailFetchGdsIssDocNoOptionSuccess,

  [SiteDetailTypes.SITE_DETAIL_FETCH_BIN_TRF_DOC_NO_OPTION_LOADING]: siteDetailFetchBinTrfDocNoOptionLoading,
  [SiteDetailTypes.SITE_DETAIL_FETCH_BIN_TRF_DOC_NO_OPTION_SUCCESS]: siteDetailFetchBinTrfDocNoOptionSuccess,

  [SiteDetailTypes.SITE_DETAIL_FETCH_CYCLE_COUNT_DOC_NO_OPTION_LOADING]: siteDetailFetchCycleCountDocNoOptionLoading,
  [SiteDetailTypes.SITE_DETAIL_FETCH_CYCLE_COUNT_DOC_NO_OPTION_SUCCESS]: siteDetailFetchCycleCountDocNoOptionSuccess,

  [SiteDetailTypes.SITE_DETAIL_FETCH_COUNT_ADJ_DOC_NO_OPTION_LOADING]: siteDetailFetchCountAdjDocNoOptionLoading,
  [SiteDetailTypes.SITE_DETAIL_FETCH_COUNT_ADJ_DOC_NO_OPTION_SUCCESS]: siteDetailFetchCountAdjDocNoOptionSuccess,

  [SiteDetailTypes.SITE_DETAIL_FETCH_WHSE_JOB_DOC_NO_OPTION_LOADING]: siteDetailFetchWhseJobDocNoOptionLoading,
  [SiteDetailTypes.SITE_DETAIL_FETCH_WHSE_JOB_DOC_NO_OPTION_SUCCESS]: siteDetailFetchWhseJobDocNoOptionSuccess,

  [SiteDetailTypes.SITE_DETAIL_FETCH_LOAD_LIST_DOC_NO_OPTION_LOADING]: siteDetailFetchLoadListDocNoOptionLoading,
  [SiteDetailTypes.SITE_DETAIL_FETCH_LOAD_LIST_DOC_NO_OPTION_SUCCESS]: siteDetailFetchLoadListDocNoOptionSuccess,

  [SiteDetailTypes.SITE_DETAIL_FETCH_PRF_DEL_DOC_NO_OPTION_LOADING]: siteDetailFetchPrfDelDocNoOptionLoading,
  [SiteDetailTypes.SITE_DETAIL_FETCH_PRF_DEL_DOC_NO_OPTION_SUCCESS]: siteDetailFetchPrfDelDocNoOptionSuccess,

  [SiteDetailTypes.SITE_DETAIL_FETCH_FAIL_DEL_DOC_NO_OPTION_LOADING]: siteDetailFetchFailDelDocNoOptionLoading,
  [SiteDetailTypes.SITE_DETAIL_FETCH_FAIL_DEL_DOC_NO_OPTION_SUCCESS]: siteDetailFetchFailDelDocNoOptionSuccess
});
