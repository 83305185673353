/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { SlsRtnProcessTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const slsRtnProcessFetchSlsRtnProcessLoading = (state, { boolean }) => ({
  ...state,
  processIsLoading: boolean
});

export const slsRtnProcessFetchSlsRtnProcessSuccess = (state, { process }) => ({
  ...state,
  process
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [SlsRtnProcessTypes.SLS_RTN_PROCESS_FETCH_SLS_RTN_PROCESS_LOADING]: slsRtnProcessFetchSlsRtnProcessLoading,
  [SlsRtnProcessTypes.SLS_RTN_PROCESS_FETCH_SLS_RTN_PROCESS_SUCCESS]: slsRtnProcessFetchSlsRtnProcessSuccess
});
