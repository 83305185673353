import { put, call, select, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import ApiService from '../Services/ApiService';
import UtilService from '../Services/UtilService';
import AppActions from '../Stores/App/Actions';
import WhseJob06DetailActions, { WhseJob06DetailTypes } from '../Stores/WhseJob06Detail/Actions';

const getAppStore = state => state.app;

const getWhseJob06DetailStore = state => state.whseJob06Detail;

export function* whseJob06DetailShowHeader({ hdrId }) {
  try {
    yield put(WhseJob06DetailActions.whseJob06DetailShowDocumentLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `whseJob/showHeader/${hdrId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(WhseJob06DetailActions.whseJob06DetailShowHeaderSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(WhseJob06DetailActions.whseJob06DetailShowDocumentLoading(false));
  }
}

export function* whseJob06DetailTransitionToStatus({ formikBag, hdrId, docStatus }) {
  formikBag.setSubmitting(true);
  yield put(WhseJob06DetailActions.whseJob06DetailShowDocumentLoading(true));
  try {
    const app = yield select(getAppStore);

    const postData = {
      hdrId,
      docStatus
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      'whseJob/transitionToStatus',
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      const whseJob06Detail = yield select(getWhseJob06DetailStore);

      const { outbOrdHdrs: oldOutbOrdHdrs, inbOrdHdrs: oldInbOrdHdrs } = whseJob06Detail;

      const {
        document_header: retWhseJob06Hdr,
        outb_ord_hdrs: retOutbOrdHdrs,
        deleted_outb_ord_hdrs: retDeletedOutbOrdHdrs,
        inb_ord_hdrs: retInbOrdHdrs,
        deleted_inb_ord_hdrs: retDeletedInbOrdHdrs
      } = result.data;

      const processedOutbOrd = UtilService.processDetails(
        oldOutbOrdHdrs,
        retOutbOrdHdrs,
        retDeletedOutbOrdHdrs
      );
      const newOutbOrdHdrs = processedOutbOrd.details;

      const processedInbOrd = UtilService.processDetails(
        oldInbOrdHdrs,
        retInbOrdHdrs,
        retDeletedInbOrdHdrs
      );
      const newInbOrdHdrs = processedInbOrd.details;

      yield put(
        WhseJob06DetailActions.whseJob06DetailUpdateDocumentSuccess(
          retWhseJob06Hdr,
          newOutbOrdHdrs,
          newInbOrdHdrs
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
    yield put(WhseJob06DetailActions.whseJob06DetailShowDocumentLoading(false));
  }
}

export function* whseJob06DetailUpdateHeader({ formikBag, documentHeader }) {
  formikBag.setSubmitting(true);
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: documentHeader
    };

    const result = yield call(
      ApiService.putApi, // function
      app.apiUrl,
      'whseJob/updateHeader',
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      const whseJob06Detail = yield select(getWhseJob06DetailStore);

      const { outbOrdHdrs: oldOutbOrdHdrs, inbOrdHdrs: oldInbOrdHdrs } = whseJob06Detail;
      const {
        document_header: retWhseJob06Hdr,
        outb_ord_hdrs: retOutbOrdHdrs,
        deleted_outb_ord_hdrs: retDeletedOutbOrdHdrs,
        inb_ord_hdrs: retInbOrdHdrs,
        deleted_inb_ord_hdrs: retDeletedInbOrdHdrs
      } = result.data;

      const processedOutbOrd = UtilService.processDetails(
        oldOutbOrdHdrs,
        retOutbOrdHdrs,
        retDeletedOutbOrdHdrs
      );
      const newOutbOrdHdrs = processedOutbOrd.details;

      const processedInbOrd = UtilService.processDetails(
        oldInbOrdHdrs,
        retInbOrdHdrs,
        retDeletedInbOrdHdrs
      );
      const newInbOrdHdrs = processedInbOrd.details;

      yield put(
        WhseJob06DetailActions.whseJob06DetailUpdateDocumentSuccess(
          retWhseJob06Hdr,
          newOutbOrdHdrs,
          newInbOrdHdrs
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* whseJob06DetailShowOutbOrdHdrs({ hdrId }) {
  try {
    yield put(WhseJob06DetailActions.whseJob06DetailShowDocumentLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `whseJob/showOutbOrdHdrs/${hdrId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(WhseJob06DetailActions.whseJob06DetailShowOutbOrdHdrsSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(WhseJob06DetailActions.whseJob06DetailShowDocumentLoading(false));
  }
}

export function* whseJob06DetailShowInbOrdHdrs({ hdrId }) {
  try {
    yield put(WhseJob06DetailActions.whseJob06DetailShowDocumentLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `whseJob/showInbOrdHdrs/${hdrId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(WhseJob06DetailActions.whseJob06DetailShowInbOrdHdrsSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(WhseJob06DetailActions.whseJob06DetailShowDocumentLoading(false));
  }
}

export function* whseJob06DetailUpOutbOrd({ hdrId, outbOrdHdr }) {
  try {
    yield put(WhseJob06DetailActions.whseJob06DetailShowDocumentLoading(true));

    const app = yield select(getAppStore);

    const whseJob06Detail = yield select(getWhseJob06DetailStore);
    const { outbOrdHdrs: oldOutbOrdHdrs, inbOrdHdrs: oldInbOrdHdrs } = whseJob06Detail;

    const oldIndex = oldOutbOrdHdrs.indexOf(outbOrdHdr);
    const newIndex = oldIndex - 1;
    if (newIndex >= 0) {
      const postData = {
        outbOrdHdrs: [{ docCode01: outbOrdHdr.doc_code_01, lineNo: newIndex + 1, direction: -1 }]
      };

      const result = yield call(
        ApiService.postApi, // function
        app.apiUrl,
        `whseJob/moveOutbOrd/${hdrId}`,
        app.token,
        postData
      );

      if (result.isSuccess === true) {
        const { document_header: retWhseJob06Hdr, outb_ord_hdrs: retOutbOrdHdrs } = result.data;

        const processedOutbOrd = UtilService.processDetails(oldOutbOrdHdrs, retOutbOrdHdrs, []);
        const newOutbOrdHdrs = processedOutbOrd.details;
        newOutbOrdHdrs.sort((a, b) => (a.line_no < b.line_no ? -1 : 1));

        yield put(
          WhseJob06DetailActions.whseJob06DetailUpdateDocumentSuccess(
            retWhseJob06Hdr,
            newOutbOrdHdrs,
            oldInbOrdHdrs
          )
        );
      } else if (result.isTokenExpired === true) {
        yield put(AppActions.appTokenExpired(result.message));
      } else if (result.isPasswordExpired === true) {
        yield put(AppActions.appPasswordExpired(result.message));
      } else {
        yield call(notification.error, {
          message: result.message,
          duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
        });
      }
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(WhseJob06DetailActions.whseJob06DetailShowDocumentLoading(false));
  }
}

export function* whseJob06DetailDownOutbOrd({ hdrId, outbOrdHdr }) {
  try {
    yield put(WhseJob06DetailActions.whseJob06DetailShowDocumentLoading(true));

    const app = yield select(getAppStore);

    const whseJob06Detail = yield select(getWhseJob06DetailStore);
    const { outbOrdHdrs: oldOutbOrdHdrs, inbOrdHdrs: oldInbOrdHdrs } = whseJob06Detail;

    const oldIndex = oldOutbOrdHdrs.indexOf(outbOrdHdr);
    const newIndex = oldIndex + 1;
    if (newIndex < oldOutbOrdHdrs.length) {
      const postData = {
        outbOrdHdrs: [{ docCode01: outbOrdHdr.doc_code_01, lineNo: newIndex + 1, direction: 1 }]
      };

      const result = yield call(
        ApiService.postApi, // function
        app.apiUrl,
        `whseJob/moveOutbOrd/${hdrId}`,
        app.token,
        postData
      );

      if (result.isSuccess === true) {
        const { document_header: retWhseJob06Hdr, outb_ord_hdrs: retOutbOrdHdrs } = result.data;

        const processedOutbOrd = UtilService.processDetails(oldOutbOrdHdrs, retOutbOrdHdrs, []);
        const newOutbOrdHdrs = processedOutbOrd.details;
        newOutbOrdHdrs.sort((a, b) => (a.line_no < b.line_no ? -1 : 1));

        yield put(
          WhseJob06DetailActions.whseJob06DetailUpdateDocumentSuccess(
            retWhseJob06Hdr,
            newOutbOrdHdrs,
            oldInbOrdHdrs
          )
        );
      } else if (result.isTokenExpired === true) {
        yield put(AppActions.appTokenExpired(result.message));
      } else if (result.isPasswordExpired === true) {
        yield put(AppActions.appPasswordExpired(result.message));
      } else {
        yield call(notification.error, {
          message: result.message,
          duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
        });
      }
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(WhseJob06DetailActions.whseJob06DetailShowDocumentLoading(false));
  }
}

export function* whseJob06DetailUpInbOrd({ hdrId, inbOrdHdr }) {
  try {
    yield put(WhseJob06DetailActions.whseJob06DetailShowDocumentLoading(true));

    const app = yield select(getAppStore);

    const whseJob06Detail = yield select(getWhseJob06DetailStore);
    const { outbOrdHdrs: oldOutbOrdHdrs, inbOrdHdrs: oldInbOrdHdrs } = whseJob06Detail;

    const oldIndex = oldInbOrdHdrs.indexOf(inbOrdHdr);
    const newIndex = oldIndex - 1;
    if (newIndex >= 0) {
      const postData = {
        inbOrdHdrs: [{ inbOrdHdrId: inbOrdHdr.doc_code_01, lineNo: newIndex + 1, direction: -1 }]
      };

      const result = yield call(
        ApiService.postApi, // function
        app.apiUrl,
        `whseJob/moveInbOrd/${hdrId}`,
        app.token,
        postData
      );

      if (result.isSuccess === true) {
        const { document_header: retWhseJob06Hdr, inb_ord_hdrs: retInbOrdHdrs } = result.data;

        const processedInbOrd = UtilService.processDetails(oldInbOrdHdrs, retInbOrdHdrs, []);
        const newInbOrdHdrs = processedInbOrd.details;
        newInbOrdHdrs.sort((a, b) => (a.line_no < b.line_no ? -1 : 1));

        yield put(
          WhseJob06DetailActions.whseJob06DetailUpdateDocumentSuccess(
            retWhseJob06Hdr,
            oldOutbOrdHdrs,
            newInbOrdHdrs
          )
        );
      } else if (result.isTokenExpired === true) {
        yield put(AppActions.appTokenExpired(result.message));
      } else if (result.isPasswordExpired === true) {
        yield put(AppActions.appPasswordExpired(result.message));
      } else {
        yield call(notification.error, {
          message: result.message,
          duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
        });
      }
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(WhseJob06DetailActions.whseJob06DetailShowDocumentLoading(false));
  }
}

export function* whseJob06DetailDownInbOrd({ hdrId, inbOrdHdr }) {
  try {
    yield put(WhseJob06DetailActions.whseJob06DetailShowDocumentLoading(true));

    const app = yield select(getAppStore);

    const whseJob06Detail = yield select(getWhseJob06DetailStore);
    const { outbOrdHdrs: oldOutbOrdHdrs, inbOrdHdrs: oldInbOrdHdrs } = whseJob06Detail;

    const oldIndex = oldInbOrdHdrs.indexOf(inbOrdHdr);
    const newIndex = oldIndex + 1;
    if (newIndex < oldInbOrdHdrs.length) {
      const postData = {
        inbOrdHdrs: [{ inbOrdHdrId: inbOrdHdr.doc_code_01, lineNo: newIndex + 1, direction: 1 }]
      };

      const result = yield call(
        ApiService.postApi, // function
        app.apiUrl,
        `whseJob/moveInbOrd/${hdrId}`,
        app.token,
        postData
      );

      if (result.isSuccess === true) {
        const { document_header: retWhseJob06Hdr, inb_ord_hdrs: retInbOrdHdrs } = result.data;

        const processedInbOrd = UtilService.processDetails(oldInbOrdHdrs, retInbOrdHdrs, []);
        const newInbOrdHdrs = processedInbOrd.details;
        newInbOrdHdrs.sort((a, b) => (a.line_no < b.line_no ? -1 : 1));

        yield put(
          WhseJob06DetailActions.whseJob06DetailUpdateDocumentSuccess(
            retWhseJob06Hdr,
            oldOutbOrdHdrs,
            newInbOrdHdrs
          )
        );
      } else if (result.isTokenExpired === true) {
        yield put(AppActions.appTokenExpired(result.message));
      } else if (result.isPasswordExpired === true) {
        yield put(AppActions.appPasswordExpired(result.message));
      } else {
        yield call(notification.error, {
          message: result.message,
          duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
        });
      }
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(WhseJob06DetailActions.whseJob06DetailShowDocumentLoading(false));
  }
}

export function* whseJob06DetailFetchTransportOptions({ search }) {
  try {
    yield put(WhseJob06DetailActions.whseJob06DetailFetchTransportOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId: app.curSiteFlowId,
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `transport/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({ value: d.id, label: `${d.code}` }));

      yield put(WhseJob06DetailActions.whseJob06DetailFetchTransportOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(WhseJob06DetailActions.whseJob06DetailFetchTransportOptionLoading(false));
  }
}

export function* whseJob06DetailFetchDriver01Options({ search }) {
  try {
    yield put(WhseJob06DetailActions.whseJob06DetailFetchDriver01OptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId: app.curSiteFlowId,
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `user/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({ value: d.id, label: `${d.username}` }));

      yield put(WhseJob06DetailActions.whseJob06DetailFetchDriver01OptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(WhseJob06DetailActions.whseJob06DetailFetchDriver01OptionLoading(false));
  }
}

export function* whseJob06DetailFetchDeliveryMan01Options({ search }) {
  try {
    yield put(WhseJob06DetailActions.whseJob06DetailFetchDeliveryMan01OptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId: app.curSiteFlowId,
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `user/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({ value: d.id, label: `${d.username}` }));

      yield put(WhseJob06DetailActions.whseJob06DetailFetchDeliveryMan01OptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(WhseJob06DetailActions.whseJob06DetailFetchDeliveryMan01OptionLoading(false));
  }
}

export function* whseJob06DetailFetchDeliveryMan02Options({ search }) {
  try {
    yield put(WhseJob06DetailActions.whseJob06DetailFetchDeliveryMan02OptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId: app.curSiteFlowId,
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `user/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({ value: d.id, label: `${d.username}` }));

      yield put(WhseJob06DetailActions.whseJob06DetailFetchDeliveryMan02OptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(WhseJob06DetailActions.whseJob06DetailFetchDeliveryMan02OptionLoading(false));
  }
}

export function* whseJob06DetailFetchWorkerOptions({ search }) {
  try {
    yield put(WhseJob06DetailActions.whseJob06DetailFetchWorkerOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `user/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({ value: d.id, label: `${d.username}` }));

      yield put(WhseJob06DetailActions.whseJob06DetailFetchWorkerOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(WhseJob06DetailActions.whseJob06DetailFetchWorkerOptionLoading(false));
  }
}

export const saga = [
  takeLatest(WhseJob06DetailTypes.WHSE_JOB06_DETAIL_SHOW_HEADER, whseJob06DetailShowHeader),

  takeLatest(WhseJob06DetailTypes.WHSE_JOB06_DETAIL_UPDATE_HEADER, whseJob06DetailUpdateHeader),

  takeLatest(
    WhseJob06DetailTypes.WHSE_JOB06_DETAIL_TRANSITION_TO_STATUS,
    whseJob06DetailTransitionToStatus
  ),

  takeLatest(
    WhseJob06DetailTypes.WHSE_JOB06_DETAIL_SHOW_OUTB_ORD_HDRS,
    whseJob06DetailShowOutbOrdHdrs
  ),
  takeLatest(
    WhseJob06DetailTypes.WHSE_JOB06_DETAIL_SHOW_INB_ORD_HDRS,
    whseJob06DetailShowInbOrdHdrs
  ),

  takeLatest(WhseJob06DetailTypes.WHSE_JOB06_DETAIL_UP_OUTB_ORD, whseJob06DetailUpOutbOrd),
  takeLatest(WhseJob06DetailTypes.WHSE_JOB06_DETAIL_DOWN_OUTB_ORD, whseJob06DetailDownOutbOrd),
  takeLatest(WhseJob06DetailTypes.WHSE_JOB06_DETAIL_UP_INB_ORD, whseJob06DetailUpInbOrd),
  takeLatest(WhseJob06DetailTypes.WHSE_JOB06_DETAIL_DOWN_INB_ORD, whseJob06DetailDownInbOrd),

  takeLatest(
    WhseJob06DetailTypes.WHSE_JOB06_DETAIL_FETCH_TRANSPORT_OPTIONS,
    whseJob06DetailFetchTransportOptions
  ),
  takeLatest(
    WhseJob06DetailTypes.WHSE_JOB06_DETAIL_FETCH_DRIVER01_OPTIONS,
    whseJob06DetailFetchDriver01Options
  ),
  takeLatest(
    WhseJob06DetailTypes.WHSE_JOB06_DETAIL_FETCH_DELIVERY_MAN01_OPTIONS,
    whseJob06DetailFetchDeliveryMan01Options
  ),
  takeLatest(
    WhseJob06DetailTypes.WHSE_JOB06_DETAIL_FETCH_DELIVERY_MAN02_OPTIONS,
    whseJob06DetailFetchDeliveryMan02Options
  ),
  takeLatest(
    WhseJob06DetailTypes.WHSE_JOB06_DETAIL_FETCH_WORKER_OPTIONS,
    whseJob06DetailFetchWorkerOptions
  )
];
