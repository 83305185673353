import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  advShipExcel01SetStrProcType: ['strProcType'],
  advShipExcel01ShowBatchJobStatus: null,
  advShipExcel01ShowBatchJobStatusSuccess: ['batchJobStatus'],
  advShipExcel01UploadExcel: ['formikBag', 'file'],
  advShipExcel01UploadLoading: ['boolean']
});

export const AdvShipExcel01Types = Types;
export default Creators;
