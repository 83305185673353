/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */
import { createReducer } from 'reduxsauce';
import { OutbOrd05Types } from './Actions';
import INITIAL_STATE from './InitialState';

export const outbOrd05ResetTimestamp = (state, { currentPage, sorts, filters, pageSize }) => ({
  ...state,
  currentPage: currentPage || state.currentPage,
  sorts: sorts || state.sorts,
  filters: filters || state.filters,
  pageSize: pageSize || state.pageSize,
  timestamp: Date.now()
});

export const outbOrd05FetchOutbOrd05Loading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const outbOrd05FetchOutbOrd05Success = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  documents,
  currentPage,
  lastPage,
  total,
  pageSize
});

export const outbOrd05AddSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = state.selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: [
      ...state.selectedDocuments,
      ...selectedDocuments.filter(data => {
        return !selectIds.includes(data.id);
      })
    ]
  };
};

export const outbOrd05RemoveSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: state.selectedDocuments.filter(data => {
      return !selectIds.includes(data.id);
    })
  };
};

export const outbOrd05SetWorkspaceVisible = (state, { boolean }) => ({
  ...state,
  workspaceIsVisible: boolean
});

export const outbOrd05CreateOutbOrd05Loading = (state, { boolean }) => ({
  ...state,
  createIsLoading: boolean
});

export const outbOrd05CreateOutbOrd05Success = (state, { newDocuments }) => ({
  ...state,
  timestamp: Date.now(),
  selectedDocuments: [],
  newDocuments
});

export const outbOrd05SetExpandedRows = (state, { expandedRows }) => ({
  ...state,
  expandedRows
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [OutbOrd05Types.OUTB_ORD05_RESET_TIMESTAMP]: outbOrd05ResetTimestamp,
  [OutbOrd05Types.OUTB_ORD05_FETCH_OUTB_ORD05_LOADING]: outbOrd05FetchOutbOrd05Loading,
  [OutbOrd05Types.OUTB_ORD05_FETCH_OUTB_ORD05_SUCCESS]: outbOrd05FetchOutbOrd05Success,
  [OutbOrd05Types.OUTB_ORD05_ADD_SELECTED_DOCUMENTS]: outbOrd05AddSelectedDocuments,
  [OutbOrd05Types.OUTB_ORD05_REMOVE_SELECTED_DOCUMENTS]: outbOrd05RemoveSelectedDocuments,
  [OutbOrd05Types.OUTB_ORD05_SET_WORKSPACE_VISIBLE]: outbOrd05SetWorkspaceVisible,
  [OutbOrd05Types.OUTB_ORD05_CREATE_OUTB_ORD05_LOADING]: outbOrd05CreateOutbOrd05Loading,
  [OutbOrd05Types.OUTB_ORD05_CREATE_OUTB_ORD05_SUCCESS]: outbOrd05CreateOutbOrd05Success,
  [OutbOrd05Types.OUTB_ORD05_SET_EXPANDED_ROWS]: outbOrd05SetExpandedRows
});
