/**
 * The initial values for the redux state.
 */
export default {
  wipLspSlsOrdTimestamp: 0,
  wipLspSlsOrds: [],
  wipLspSlsOrdIsLoading: false,
  wipLspSlsOrdSorts: {
    doc_date: 'ascend',
    doc_code: 'ascend'
  },
  wipLspSlsOrdFilters: {},
  wipLspSlsOrdPageSize: '20',
  wipLspSlsOrdCurrentPage: 1,
  wipLspSlsOrdLastPage: 10,
  wipLspSlsOrdTotal: 0,

  wipOutbOrdTimestamp: 0,
  wipOutbOrds: [],
  wipOutbOrdIsLoading: false,
  wipOutbOrdSorts: {
    doc_date: 'ascend',
    doc_code: 'ascend'
  },
  wipOutbOrdFilters: {},
  wipOutbOrdPageSize: '20',
  wipOutbOrdCurrentPage: 1,
  wipOutbOrdLastPage: 10,
  wipOutbOrdTotal: 0,

  wipInvDocTimestamp: 0,
  wipInvDocs: [],
  wipInvDocIsLoading: false,
  wipInvDocSorts: {
    doc_date: 'ascend',
    doc_code: 'ascend'
  },
  wipInvDocFilters: {},
  wipInvDocPageSize: '20',
  wipInvDocCurrentPage: 1,
  wipInvDocLastPage: 10,
  wipInvDocTotal: 0,

  completeLoadListTimestamp: 0,
  completeLoadLists: [],
  completeLoadListIsLoading: false,
  completeLoadListSorts: {
    doc_date: 'ascend',
    doc_code: 'ascend'
  },
  completeLoadListFilters: {},
  completeLoadListPageSize: '20',
  completeLoadListCurrentPage: 1,
  completeLoadListLastPage: 10,
  completeLoadListTotal: 0,

  wipLspSlsOrdDialogIsVisible: false,
  wipLspSlsOrdDocId: 0,
  wipLspSlsOrdDocument: { doc_code: '', doc_status: 3 },
  wipLspSlsOrdDocumentIsLoading: false,

  wipOutbOrdDialogIsVisible: false,
  wipOutbOrdDocId: 0,
  wipOutbOrdDocument: { doc_code: '', doc_status: 3 },
  wipOutbOrdDocumentIsLoading: false,

  wipInvDocDialogIsVisible: false,
  wipInvDocDocId: 0,
  wipInvDocDocument: { doc_code: '', doc_status: 3 },
  wipInvDocDocumentIsLoading: false,

  completeLoadListDialogIsVisible: false,
  completeLoadListDocId: 0,
  completeLoadListDocument: { doc_code: '', doc_status: 3 },
  completeLoadListDocumentIsLoading: false
};
