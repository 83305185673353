/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { DelOrdDetailTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const delOrdDetailResetTimestamp = state => ({
  ...state,
  timestamp: Date.now()
});

export const delOrdDetailSetHdrId = (state, { hdrId, itemId }) => ({
  ...state,
  timestamp: Date.now(),
  documentIsLoading: false,
  hdrId,
  itemId,
  detailIsVisible: false,
  salesmanOptions: [],
  deliveryPointOptions: [],
  creditTermOptions: [],
  currencyOptions: []
});

export const delOrdDetailShowDocumentLoading = (state, { boolean }) => ({
  ...state,
  documentIsLoading: boolean
});

export const delOrdDetailShowHeaderSuccess = (state, { documentHeader }) => ({
  ...state,
  documentHeader
});

export const delOrdDetailUpdateDocumentSuccess = (state, { documentHeader, documentDetails }) => ({
  ...state,
  documentHeader,
  documentDetails
});

export const delOrdDetailShowDetailsSuccess = (state, { documentDetails }) => ({
  ...state,
  documentDetails
});

export const delOrdDetailSetDetailVisible = (state, { boolean }) => ({
  ...state,
  detailIsVisible: boolean
});

export const delOrdDetailSetDocumentDetail = (state, { documentDetail }) => ({
  ...state,
  documentDetail,
  itemOptions: [],
  uomOptions: []
});

export const delOrdDetailFetchSalesmanOptionLoading = (state, { boolean }) => ({
  ...state,
  salesmanIsLoading: boolean
});

export const delOrdDetailFetchSalesmanOptionSuccess = (state, { options }) => ({
  ...state,
  salesmanOptions: options
});

export const delOrdDetailFetchDeliveryPointOptionLoading = (state, { boolean }) => ({
  ...state,
  deliveryPointIsLoading: boolean
});

export const delOrdDetailFetchDeliveryPointOptionSuccess = (state, { options }) => ({
  ...state,
  deliveryPointOptions: options
});

export const delOrdDetailFetchCreditTermOptionLoading = (state, { boolean }) => ({
  ...state,
  creditTermIsLoading: boolean
});

export const delOrdDetailFetchCreditTermOptionSuccess = (state, { options }) => ({
  ...state,
  creditTermOptions: options
});

export const delOrdDetailFetchCurrencyOptionLoading = (state, { boolean }) => ({
  ...state,
  currencyIsLoading: boolean
});

export const delOrdDetailFetchCurrencyOptionSuccess = (state, { options }) => ({
  ...state,
  currencyOptions: options
});

export const delOrdDetailFetchItemOptionLoading = (state, { boolean }) => ({
  ...state,
  itemIsLoading: boolean
});

export const delOrdDetailFetchItemOptionSuccess = (state, { options }) => ({
  ...state,
  itemOptions: options
});

export const delOrdDetailFetchUomOptionLoading = (state, { boolean }) => ({
  ...state,
  uomIsLoading: boolean
});

export const delOrdDetailFetchUomOptionSuccess = (state, { options }) => ({
  ...state,
  uomOptions: options
});

// force refresh the formik form
export const delOrdDetailUpdateDetails = (state, { documentDetails }) => ({
  ...state,
  documentDetail: documentDetails[0]
});

// force refresh the formik form
export const delOrdDetailCreateDetail = (state, { documentDetail }) => ({
  ...state,
  documentDetail
});

export const delOrdDetailFetchLocationOptionLoading = (state, { boolean }) => ({
  ...state,
  locationIsLoading: boolean
});

export const delOrdDetailFetchLocationOptionSuccess = (state, { options }) => ({
  ...state,
  locationOptions: options
});

export const delOrdDetailFetchProjectOptionLoading = (state, { boolean }) => ({
  ...state,
  projectIsLoading: boolean
});

export const delOrdDetailFetchProjectOptionSuccess = (state, { options }) => ({
  ...state,
  projectOptions: options
});

export const delOrdDetailSetPickedIsVisible = (state, { boolean }) => ({
  ...state,
  pickedIsVisible: boolean,
  pickedTimestamp: boolean === true ? Date.now() : state.pickedTimestamp
});

export const delOrdDetailShowPickedLoading = (state, { boolean }) => ({
  ...state,
  pickedIsLoading: boolean
});

export const delOrdDetailShowPickedDetailsSuccess = (state, { pickedDetails }) => ({
  ...state,
  pickedDetails
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [DelOrdDetailTypes.DEL_ORD_DETAIL_RESET_TIMESTAMP]: delOrdDetailResetTimestamp,
  [DelOrdDetailTypes.DEL_ORD_DETAIL_SET_HDR_ID]: delOrdDetailSetHdrId,

  [DelOrdDetailTypes.DEL_ORD_DETAIL_SHOW_HEADER_SUCCESS]: delOrdDetailShowHeaderSuccess,

  [DelOrdDetailTypes.DEL_ORD_DETAIL_UPDATE_DOCUMENT_SUCCESS]: delOrdDetailUpdateDocumentSuccess,

  [DelOrdDetailTypes.DEL_ORD_DETAIL_SHOW_DOCUMENT_LOADING]: delOrdDetailShowDocumentLoading,

  [DelOrdDetailTypes.DEL_ORD_DETAIL_SHOW_DETAILS_SUCCESS]: delOrdDetailShowDetailsSuccess,

  [DelOrdDetailTypes.DEL_ORD_DETAIL_SET_DETAIL_VISIBLE]: delOrdDetailSetDetailVisible,
  [DelOrdDetailTypes.DEL_ORD_DETAIL_SET_DOCUMENT_DETAIL]: delOrdDetailSetDocumentDetail,

  [DelOrdDetailTypes.DEL_ORD_DETAIL_UPDATE_DETAILS]: delOrdDetailUpdateDetails,
  [DelOrdDetailTypes.DEL_ORD_DETAIL_CREATE_DETAIL]: delOrdDetailCreateDetail,

  [DelOrdDetailTypes.DEL_ORD_DETAIL_FETCH_SALESMAN_OPTION_LOADING]: delOrdDetailFetchSalesmanOptionLoading,
  [DelOrdDetailTypes.DEL_ORD_DETAIL_FETCH_SALESMAN_OPTION_SUCCESS]: delOrdDetailFetchSalesmanOptionSuccess,

  [DelOrdDetailTypes.DEL_ORD_DETAIL_FETCH_DELIVERY_POINT_OPTION_LOADING]: delOrdDetailFetchDeliveryPointOptionLoading,
  [DelOrdDetailTypes.DEL_ORD_DETAIL_FETCH_DELIVERY_POINT_OPTION_SUCCESS]: delOrdDetailFetchDeliveryPointOptionSuccess,

  [DelOrdDetailTypes.DEL_ORD_DETAIL_FETCH_CREDIT_TERM_OPTION_LOADING]: delOrdDetailFetchCreditTermOptionLoading,
  [DelOrdDetailTypes.DEL_ORD_DETAIL_FETCH_CREDIT_TERM_OPTION_SUCCESS]: delOrdDetailFetchCreditTermOptionSuccess,

  [DelOrdDetailTypes.DEL_ORD_DETAIL_FETCH_CURRENCY_OPTION_LOADING]: delOrdDetailFetchCurrencyOptionLoading,
  [DelOrdDetailTypes.DEL_ORD_DETAIL_FETCH_CURRENCY_OPTION_SUCCESS]: delOrdDetailFetchCurrencyOptionSuccess,

  [DelOrdDetailTypes.DEL_ORD_DETAIL_FETCH_ITEM_OPTION_LOADING]: delOrdDetailFetchItemOptionLoading,
  [DelOrdDetailTypes.DEL_ORD_DETAIL_FETCH_ITEM_OPTION_SUCCESS]: delOrdDetailFetchItemOptionSuccess,

  [DelOrdDetailTypes.DEL_ORD_DETAIL_FETCH_UOM_OPTION_LOADING]: delOrdDetailFetchUomOptionLoading,
  [DelOrdDetailTypes.DEL_ORD_DETAIL_FETCH_UOM_OPTION_SUCCESS]: delOrdDetailFetchUomOptionSuccess,

  [DelOrdDetailTypes.DEL_ORD_DETAIL_FETCH_LOCATION_OPTION_LOADING]: delOrdDetailFetchLocationOptionLoading,
  [DelOrdDetailTypes.DEL_ORD_DETAIL_FETCH_LOCATION_OPTION_SUCCESS]: delOrdDetailFetchLocationOptionSuccess,

  [DelOrdDetailTypes.DEL_ORD_DETAIL_FETCH_PROJECT_OPTION_LOADING]: delOrdDetailFetchProjectOptionLoading,
  [DelOrdDetailTypes.DEL_ORD_DETAIL_FETCH_PROJECT_OPTION_SUCCESS]: delOrdDetailFetchProjectOptionSuccess,

  [DelOrdDetailTypes.DEL_ORD_DETAIL_SET_PICKED_IS_VISIBLE]: delOrdDetailSetPickedIsVisible,
  [DelOrdDetailTypes.DEL_ORD_DETAIL_SHOW_PICKED_LOADING]: delOrdDetailShowPickedLoading,

  [DelOrdDetailTypes.DEL_ORD_DETAIL_SHOW_PICKED_DETAILS_SUCCESS]: delOrdDetailShowPickedDetailsSuccess
});
