/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { GdsRcpt01Types } from './Actions';
import INITIAL_STATE from './InitialState';

export const gdsRcpt01ResetTimestamp = (state, { currentPage, sorts, filters, pageSize }) => ({
  ...state,
  currentPage: currentPage || state.currentPage,
  sorts: sorts || state.sorts,
  filters: filters || state.filters,
  pageSize: pageSize || state.pageSize,
  timestamp: Date.now(),
  // selectedDocuments: INITIAL_STATE.selectedDocuments,
  toStorageBinOptions: INITIAL_STATE.toStorageBinOptions,
  toStorageBinOption: INITIAL_STATE.toStorageBinOption
});

export const gdsRcpt01FetchGdsRcpt01Loading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const gdsRcpt01FetchGdsRcpt01Success = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  documents,
  currentPage,
  lastPage,
  total,
  pageSize
});

export const gdsRcpt01AddSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = state.selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: [
      ...state.selectedDocuments,
      ...selectedDocuments.filter(data => {
        return !selectIds.includes(data.id);
      })
    ]
  };
};

export const gdsRcpt01RemoveSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: state.selectedDocuments.filter(data => {
      return !selectIds.includes(data.id);
    })
  };
};

export const gdsRcpt01SetWorkspaceVisible = (state, { boolean }) => ({
  ...state,
  workspaceIsVisible: boolean
});

export const gdsRcpt01CreateGdsRcpt01Loading = (state, { boolean }) => ({
  ...state,
  createIsLoading: boolean
});

export const gdsRcpt01CreateGdsRcpt01Success = (state, { newDocuments }) => ({
  ...state,
  timestamp: Date.now(),
  selectedDocuments: [],
  newDocuments
});

export const gdsRcpt01FetchToStorageBinOptionLoading = (state, { boolean }) => ({
  ...state,
  toStorageBinIsLoading: boolean
});

export const gdsRcpt01FetchToStorageBinOptionSuccess = (state, { options }) => ({
  ...state,
  toStorageBinOptions: options
});

export const gdsRcpt01SetToStorageBinOption = (state, { option }) => ({
  ...state,
  toStorageBinOption: option
});

export const gdsRcpt01SetMinPalletQtyPerc = (state, { value }) => ({
  ...state,
  minPalletQtyPerc: value
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [GdsRcpt01Types.GDS_RCPT01_RESET_TIMESTAMP]: gdsRcpt01ResetTimestamp,
  [GdsRcpt01Types.GDS_RCPT01_FETCH_GDS_RCPT01_LOADING]: gdsRcpt01FetchGdsRcpt01Loading,
  [GdsRcpt01Types.GDS_RCPT01_FETCH_GDS_RCPT01_SUCCESS]: gdsRcpt01FetchGdsRcpt01Success,
  [GdsRcpt01Types.GDS_RCPT01_ADD_SELECTED_DOCUMENTS]: gdsRcpt01AddSelectedDocuments,
  [GdsRcpt01Types.GDS_RCPT01_REMOVE_SELECTED_DOCUMENTS]: gdsRcpt01RemoveSelectedDocuments,
  [GdsRcpt01Types.GDS_RCPT01_SET_WORKSPACE_VISIBLE]: gdsRcpt01SetWorkspaceVisible,
  [GdsRcpt01Types.GDS_RCPT01_CREATE_GDS_RCPT01_LOADING]: gdsRcpt01CreateGdsRcpt01Loading,
  [GdsRcpt01Types.GDS_RCPT01_CREATE_GDS_RCPT01_SUCCESS]: gdsRcpt01CreateGdsRcpt01Success,
  [GdsRcpt01Types.GDS_RCPT01_FETCH_TO_STORAGE_BIN_OPTION_LOADING]: gdsRcpt01FetchToStorageBinOptionLoading,
  [GdsRcpt01Types.GDS_RCPT01_FETCH_TO_STORAGE_BIN_OPTION_SUCCESS]: gdsRcpt01FetchToStorageBinOptionSuccess,
  [GdsRcpt01Types.GDS_RCPT01_SET_TO_STORAGE_BIN_OPTION]: gdsRcpt01SetToStorageBinOption,
  [GdsRcpt01Types.GDS_RCPT01_SET_MIN_PALLET_QTY_PERC]: gdsRcpt01SetMinPalletQtyPerc
});
