import { put, call, select, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import { push } from 'connected-react-router';
import FileSaver from 'file-saver';
import ApiService from '../Services/ApiService';
import AppActions from '../Stores/App/Actions';
import StorageBayList01Actions, { StorageBayList01Types } from '../Stores/StorageBayList01/Actions';

const getAppStore = state => state.app;

export function* storageBayList01FetchStorageBayList01({ currentPage, sorts, filters, pageSize }) {
  try {
    yield put(StorageBayList01Actions.storageBayList01FetchStorageBayList01Loading(true));

    const processedSorts = [];
    Object.entries(sorts).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      processedSorts.push(`${key}:${value === 'ascend' ? 'ASC' : 'DESC'}`);
    });

    const processedFilters = [];
    Object.entries(filters).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      if (value) {
        processedFilters.push(`${key}:${value}`);
      }
    });

    const app = yield select(getAppStore);
    const getData = {
      page: currentPage,
      sorts: processedSorts,
      filters: processedFilters,
      pageSize
    };

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `storageBay/indexProcess/STORAGE_BAY_LIST_01/${app.curSiteFlowId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      // if nowCurrentPage is more than lastPage, then nowCurrentPage = lastPage
      let nowCurrentPage = result.data.current_page;
      if (nowCurrentPage > result.data.last_page) {
        nowCurrentPage = result.data.last_page;
      }

      yield put(
        StorageBayList01Actions.storageBayList01FetchStorageBayList01Success(
          result.data.data,
          nowCurrentPage,
          result.data.last_page,
          result.data.total,
          result.data.per_page
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(StorageBayList01Actions.storageBayList01FetchStorageBayList01Loading(false));
  }
}

export function* storageBayList01UploadExcel({ file }) {
  try {
    yield put(StorageBayList01Actions.storageBayList01UploadLoading(true));

    const app = yield select(getAppStore);

    // eslint-disable-next-line no-undef
    const postData = new FormData();
    postData.append('file', file);

    const getData = {};

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `storageBay/uploadStorageBayList01/${app.curSiteFlowId}`,
      app.token,
      postData,
      getData,
      'multipart/form-data'
    );

    if (result.isSuccess === true) {
      // result.data is total
      yield put(StorageBayList01Actions.storageBayList01ResetTimestamp());

      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_SUCCESS_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(StorageBayList01Actions.storageBayList01UploadLoading(false));
  }
}

export function* storageBayList01DownloadExcel() {
  try {
    yield put(StorageBayList01Actions.storageBayList01UploadLoading(true));

    const app = yield select(getAppStore);

    const getData = {};

    const result = yield call(
      ApiService.downloadGetApi, // function
      app.apiUrl,
      `storageBay/downloadStorageBayList01/${app.curSiteFlowId}`,
      app.token,
      getData,
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    );

    if (result.isSuccess === true) {
      FileSaver.saveAs(result.data, `STORAGE_BAY_LIST_01.XLSX`);
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(StorageBayList01Actions.storageBayList01UploadLoading(false));
  }
}

export function* storageBayList01GoToDocument({ hdrId }) {
  const app = yield select(getAppStore);
  yield put(push(`${app.appPath}/storageBayDetail/update/${hdrId}`));
}

export const saga = [
  takeLatest(
    StorageBayList01Types.STORAGE_BAY_LIST01_FETCH_STORAGE_BAY_LIST01,
    storageBayList01FetchStorageBayList01
  ),
  takeLatest(StorageBayList01Types.STORAGE_BAY_LIST01_GO_TO_DOCUMENT, storageBayList01GoToDocument),
  takeLatest(StorageBayList01Types.STORAGE_BAY_LIST01_UPLOAD_EXCEL, storageBayList01UploadExcel),
  takeLatest(StorageBayList01Types.STORAGE_BAY_LIST01_DOWNLOAD_EXCEL, storageBayList01DownloadExcel)
];
