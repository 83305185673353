import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  whseJob1502IndexResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  whseJob1502IndexGoToWhseJobDocument: ['hdrId'],
  whseJob1502IndexGoToAudit: ['hdrId'],
  whseJob1502IndexFetchWhseJob1502Index: ['currentPage', 'sorts', 'filters', 'pageSize'],
  whseJob1502IndexFetchWhseJob1502IndexLoading: ['boolean'],
  whseJob1502IndexFetchWhseJob1502IndexSuccess: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],
  whseJob1502IndexAddSelectedDocuments: ['selectedDocuments'],
  whseJob1502IndexRemoveSelectedDocuments: ['selectedDocuments'],
  whseJob1502IndexSetWorkspaceVisible: ['boolean']
});

export const WhseJob1502IndexTypes = Types;
export default Creators;
