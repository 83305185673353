import React from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';

import { ArrowLeftOutlined, UndoOutlined, SaveOutlined } from '@ant-design/icons';

import { Button, Row, Col, Typography, Card } from 'antd';
import treeChanges from 'tree-changes';
import { FormikInput, FormikButton, FormikSelect } from '../../Components/Formik';
import SiteDetailActions from '../../Stores/SiteDetail/Actions';
import AppActions from '../../Stores/App/Actions';

const { Text } = Typography;

class ModelForm extends React.PureComponent {
  constructor() {
    super();

    this.handleSearchPickListDocNo = this.handleSearchPickListDocNo.bind(this);
    this.handleSearchPackListDocNo = this.handleSearchPackListDocNo.bind(this);
    this.handleSearchGdsRcptDocNo = this.handleSearchGdsRcptDocNo.bind(this);
    this.handleSearchPutAwayDocNo = this.handleSearchPutAwayDocNo.bind(this);
    this.handleSearchGdsIssDocNo = this.handleSearchGdsIssDocNo.bind(this);
    this.handleSearchBinTrfDocNo = this.handleSearchBinTrfDocNo.bind(this);
    this.handleSearchCycleCountDocNo = this.handleSearchCycleCountDocNo.bind(this);
    this.handleSearchCountAdjDocNo = this.handleSearchCountAdjDocNo.bind(this);
    this.handleSearchWhseJobDocNo = this.handleSearchWhseJobDocNo.bind(this);
    this.handleSearchLoadListDocNo = this.handleSearchLoadListDocNo.bind(this);
    this.handleSearchPrfDelDocNo = this.handleSearchPrfDelDocNo.bind(this);
  }

  componentDidMount() {
    const { resId, resetTimestamp } = this.props;

    if (resId > 0) {
      resetTimestamp();
    }
  }

  componentDidUpdate(prevProps) {
    const { resId, resetTimestamp, initModel, showModel } = this.props;

    const { changed } = treeChanges(prevProps, this.props);

    if (changed('resId')) {
      resetTimestamp();
    }
    if (changed('timestamp')) {
      if (resId <= 0) {
        initModel();
      } else {
        showModel(resId);
      }
    }
  }

  componentWillUnmount() {}

  handleSearchPickListDocNo(form, value) {
    const { fetchDocNoOptions } = this.props;

    fetchDocNoOptions('PickListHdr', value);
  }

  handleSearchPackListDocNo(form, value) {
    const { fetchDocNoOptions } = this.props;

    fetchDocNoOptions('PackListHdr', value);
  }

  handleSearchGdsRcptDocNo(form, value) {
    const { fetchDocNoOptions } = this.props;

    fetchDocNoOptions('GdsRcptHdr', value);
  }

  handleSearchPutAwayDocNo(form, value) {
    const { fetchDocNoOptions } = this.props;

    fetchDocNoOptions('PutAwayHdr', value);
  }

  handleSearchGdsIssDocNo(form, value) {
    const { fetchDocNoOptions } = this.props;

    fetchDocNoOptions('GdsIssHdr', value);
  }

  handleSearchBinTrfDocNo(form, value) {
    const { fetchDocNoOptions } = this.props;

    fetchDocNoOptions('BinTrfHdr', value);
  }

  handleSearchCycleCountDocNo(form, value) {
    const { fetchDocNoOptions } = this.props;

    fetchDocNoOptions('CycleCountHdr', value);
  }

  handleSearchCountAdjDocNo(form, value) {
    const { fetchDocNoOptions } = this.props;

    fetchDocNoOptions('CountAdjHdr', value);
  }

  handleSearchWhseJobDocNo(form, value) {
    const { fetchDocNoOptions } = this.props;

    fetchDocNoOptions('WhseJobHdr', value);
  }

  handleSearchLoadListDocNo(form, value) {
    const { fetchDocNoOptions } = this.props;

    fetchDocNoOptions('LoadListHdr', value);
  }

  handleSearchPrfDelDocNo(form, value) {
    const { fetchDocNoOptions } = this.props;

    fetchDocNoOptions('PrfDelHdr', value);
  }

  render() {
    const {
      intl,
      match,
      model,
      createModel,
      updateModel,
      historyGoBack,
      modelIsLoading,

      pickListDocNoIsLoading,
      pickListDocNoOptions,

      packListDocNoIsLoading,
      packListDocNoOptions,

      gdsRcptDocNoIsLoading,
      gdsRcptDocNoOptions,

      putAwayDocNoIsLoading,
      putAwayDocNoOptions,

      gdsIssDocNoIsLoading,
      gdsIssDocNoOptions,

      binTrfDocNoIsLoading,
      binTrfDocNoOptions,

      cycleCountDocNoIsLoading,
      cycleCountDocNoOptions,

      countAdjDocNoIsLoading,
      countAdjDocNoOptions,

      whseJobDocNoIsLoading,
      whseJobDocNoOptions,

      loadListDocNoIsLoading,
      loadListDocNoOptions,

      prfDelDocNoIsLoading,
      prfDelDocNoOptions,

      goToDocTypeDocNo
    } = this.props;

    const disabled = false;

    return (
      <Formik
        enableReinitialize
        initialValues={model}
        onSubmit={(values, formikBag) => {
          if (match.params.action === 'create') {
            createModel(formikBag, values);
          } else {
            updateModel(formikBag, values);
          }
        }}
        validationSchema={Yup.object().shape({
          code: Yup.string().required(intl.formatMessage({ id: 'code_is_required' })),
          desc_01: Yup.string().required(intl.formatMessage({ id: 'description_is_required' })),
          pick_list_doc_no_select2: Yup.object().shape({
            value: Yup.number().min(1, intl.formatMessage({ id: 'pick_list_doc_code_is_required' }))
          }),
          pack_list_doc_no_select2: Yup.object().shape({
            value: Yup.number().min(1, intl.formatMessage({ id: 'pack_list_doc_code_is_required' }))
          }),
          gds_rcpt_doc_no_select2: Yup.object().shape({
            value: Yup.number().min(1, intl.formatMessage({ id: 'gds_rcpt_doc_code_is_required' }))
          }),
          put_away_doc_no_select2: Yup.object().shape({
            value: Yup.number().min(1, intl.formatMessage({ id: 'put_away_doc_code_is_required' }))
          }),
          bin_trf_doc_no_select2: Yup.object().shape({
            value: Yup.number().min(1, intl.formatMessage({ id: 'bin_trf_doc_code_is_required' }))
          }),
          cycle_count_doc_no_select2: Yup.object().shape({
            value: Yup.number().min(
              1,
              intl.formatMessage({ id: 'cycle_count_doc_code_is_required' })
            )
          }),
          count_adj_doc_no_select2: Yup.object().shape({
            value: Yup.number().min(
              1,
              intl.formatMessage({ id: 'count_adjustment_doc_code_is_required' })
            )
          }),
          whse_job_doc_no_select2: Yup.object().shape({
            value: Yup.number().min(1, intl.formatMessage({ id: 'whse_job_doc_code_is_required' }))
          }),
          load_list_doc_no_select2: Yup.object().shape({
            value: Yup.number().min(1, intl.formatMessage({ id: 'load_list_doc_code_is_required' }))
          }),
          prf_del_doc_no_select2: Yup.object().shape({
            value: Yup.number().min(1, intl.formatMessage({ id: 'prf_del_doc_code_is_required' }))
          })
        })}
      >
        {({
          // values,
          // handleChange,
          // errors,
          // setFieldTouched,
          dirty,
          // touched,
          isSubmitting,
          // isValid,
          handleSubmit,
          handleReset
        }) => (
          <Form>
            <Card title={intl.formatMessage({ id: 'general' })}>
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'code' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="code"
                    component={FormikInput}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'ref_code' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="ref_code_01"
                    component={FormikInput}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'description' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="desc_01"
                    component={FormikInput}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
              </Row>
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3} />
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="desc_02"
                    component={FormikInput}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
              </Row>
            </Card>
            <Card title={intl.formatMessage({ id: 'document_codes' })}>
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'pick_list' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={17}>
                  <Field
                    name="pick_list_doc_no_select2"
                    component={FormikSelect}
                    showArrow
                    filterOption={false}
                    onSearch={this.handleSearchPickListDocNo}
                    notFoundContent={null}
                    loading={pickListDocNoIsLoading}
                    options={pickListDocNoOptions}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
                <Col span={3}>
                  <Button type="dashed" onClick={() => goToDocTypeDocNo('PickListHdr')} block>
                    {intl.formatMessage({ id: 'add' })}
                  </Button>
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'pack_list' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={17}>
                  <Field
                    name="pack_list_doc_no_select2"
                    component={FormikSelect}
                    showArrow
                    filterOption={false}
                    onSearch={this.handleSearchPackListDocNo}
                    notFoundContent={null}
                    loading={packListDocNoIsLoading}
                    options={packListDocNoOptions}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
                <Col span={3}>
                  <Button type="dashed" onClick={() => goToDocTypeDocNo('PackListHdr')} block>
                    {intl.formatMessage({ id: 'add' })}
                  </Button>
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'load_list' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={17}>
                  <Field
                    name="load_list_doc_no_select2"
                    component={FormikSelect}
                    showArrow
                    filterOption={false}
                    onSearch={this.handleSearchLoadListDocNo}
                    notFoundContent={null}
                    loading={loadListDocNoIsLoading}
                    options={loadListDocNoOptions}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
                <Col span={3}>
                  <Button type="dashed" onClick={() => goToDocTypeDocNo('LoadListHdr')} block>
                    {intl.formatMessage({ id: 'add' })}
                  </Button>
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'proof_of_delivery' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={17}>
                  <Field
                    name="prf_del_doc_no_select2"
                    component={FormikSelect}
                    showArrow
                    filterOption={false}
                    onSearch={this.handleSearchPrfDelDocNo}
                    notFoundContent={null}
                    loading={prfDelDocNoIsLoading}
                    options={prfDelDocNoOptions}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
                <Col span={3}>
                  <Button type="dashed" onClick={() => goToDocTypeDocNo('PrfDelHdr')} block>
                    {intl.formatMessage({ id: 'add' })}
                  </Button>
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'goods_receipt' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={17}>
                  <Field
                    name="gds_rcpt_doc_no_select2"
                    component={FormikSelect}
                    showArrow
                    filterOption={false}
                    onSearch={this.handleSearchGdsRcptDocNo}
                    notFoundContent={null}
                    loading={gdsRcptDocNoIsLoading}
                    options={gdsRcptDocNoOptions}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
                <Col span={3}>
                  <Button type="dashed" onClick={() => goToDocTypeDocNo('GdsRcptHdr')} block>
                    {intl.formatMessage({ id: 'add' })}
                  </Button>
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'put_away' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={17}>
                  <Field
                    name="put_away_doc_no_select2"
                    component={FormikSelect}
                    showArrow
                    filterOption={false}
                    onSearch={this.handleSearchPutAwayDocNo}
                    notFoundContent={null}
                    loading={putAwayDocNoIsLoading}
                    options={putAwayDocNoOptions}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
                <Col span={3}>
                  <Button type="dashed" onClick={() => goToDocTypeDocNo('PutAwayHdr')} block>
                    {intl.formatMessage({ id: 'add' })}
                  </Button>
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'goods_issue' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={17}>
                  <Field
                    name="gds_iss_doc_no_select2"
                    component={FormikSelect}
                    showArrow
                    filterOption={false}
                    onSearch={this.handleSearchGdsIssDocNo}
                    notFoundContent={null}
                    loading={gdsIssDocNoIsLoading}
                    options={gdsIssDocNoOptions}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
                <Col span={3}>
                  <Button type="dashed" onClick={() => goToDocTypeDocNo('GdsIssHdr')} block>
                    {intl.formatMessage({ id: 'add' })}
                  </Button>
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'bin_transfer' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={17}>
                  <Field
                    name="bin_trf_doc_no_select2"
                    component={FormikSelect}
                    showArrow
                    filterOption={false}
                    onSearch={this.handleSearchBinTrfDocNo}
                    notFoundContent={null}
                    loading={binTrfDocNoIsLoading}
                    options={binTrfDocNoOptions}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
                <Col span={3}>
                  <Button type="dashed" onClick={() => goToDocTypeDocNo('BinTrfHdr')} block>
                    {intl.formatMessage({ id: 'add' })}
                  </Button>
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'cycle_count' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={17}>
                  <Field
                    name="cycle_count_doc_no_select2"
                    component={FormikSelect}
                    showArrow
                    filterOption={false}
                    onSearch={this.handleSearchCycleCountDocNo}
                    notFoundContent={null}
                    loading={cycleCountDocNoIsLoading}
                    options={cycleCountDocNoOptions}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
                <Col span={3}>
                  <Button type="dashed" onClick={() => goToDocTypeDocNo('CycleCountHdr')} block>
                    {intl.formatMessage({ id: 'add' })}
                  </Button>
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'count_adjustment' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={17}>
                  <Field
                    name="count_adj_doc_no_select2"
                    component={FormikSelect}
                    showArrow
                    filterOption={false}
                    onSearch={this.handleSearchCountAdjDocNo}
                    notFoundContent={null}
                    loading={countAdjDocNoIsLoading}
                    options={countAdjDocNoOptions}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
                <Col span={3}>
                  <Button type="dashed" onClick={() => goToDocTypeDocNo('CountAdjHdr')} block>
                    {intl.formatMessage({ id: 'add' })}
                  </Button>
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'mobile_job' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={17}>
                  <Field
                    name="whse_job_doc_no_select2"
                    component={FormikSelect}
                    showArrow
                    filterOption={false}
                    onSearch={this.handleSearchWhseJobDocNo}
                    notFoundContent={null}
                    loading={whseJobDocNoIsLoading}
                    options={whseJobDocNoOptions}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
                <Col span={3}>
                  <Button type="dashed" onClick={() => goToDocTypeDocNo('WhseJobHdr')} block>
                    {intl.formatMessage({ id: 'add' })}
                  </Button>
                </Col>
              </Row>
            </Card>
            <Row type="flex" justify="space-between" gutter={[0, 16]}>
              <Col span={8}>
                <Button
                  type="primary"
                  // disabled={!isValid}
                  loading={isSubmitting}
                  onClick={historyGoBack}
                  icon={<ArrowLeftOutlined />}
                >
                  {intl.formatMessage({
                    id: 'back'
                  })}
                </Button>
              </Col>
              <Col span={6}>
                <Button
                  type="primary"
                  disabled={!dirty}
                  loading={isSubmitting || modelIsLoading}
                  onClick={handleReset}
                  icon={<UndoOutlined />}
                >
                  {intl.formatMessage({ id: 'reset' })}
                </Button>
                <Field
                  name="submit_action"
                  type="primary"
                  value="update"
                  component={FormikButton}
                  // disabled={values.status >= 50}
                  loading={isSubmitting || modelIsLoading}
                  onClick={handleSubmit}
                  label={intl.formatMessage({
                    id: match.params.action === 'create' ? 'create' : 'update'
                  })}
                  icon={<SaveOutlined />}
                />
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    );
  }
}

ModelForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object,
  resetTimestamp: PropTypes.func,

  initModel: PropTypes.func,
  showModel: PropTypes.func,
  createModel: PropTypes.func,
  updateModel: PropTypes.func,
  historyGoBack: PropTypes.func,
  resId: PropTypes.number,
  model: PropTypes.shape({
    status: PropTypes.number
  }),
  modelIsLoading: PropTypes.bool,

  fetchDocNoOptions: PropTypes.func,
  pickListDocNoIsLoading: PropTypes.bool,
  pickListDocNoOptions: PropTypes.arrayOf(PropTypes.object),

  packListDocNoIsLoading: PropTypes.bool,
  packListDocNoOptions: PropTypes.arrayOf(PropTypes.object),

  gdsRcptDocNoIsLoading: PropTypes.bool,
  gdsRcptDocNoOptions: PropTypes.arrayOf(PropTypes.object),

  putAwayDocNoIsLoading: PropTypes.bool,
  putAwayDocNoOptions: PropTypes.arrayOf(PropTypes.object),

  gdsIssDocNoIsLoading: PropTypes.bool,
  gdsIssDocNoOptions: PropTypes.arrayOf(PropTypes.object),

  binTrfDocNoIsLoading: PropTypes.bool,
  binTrfDocNoOptions: PropTypes.arrayOf(PropTypes.object),

  cycleCountDocNoIsLoading: PropTypes.bool,
  cycleCountDocNoOptions: PropTypes.arrayOf(PropTypes.object),

  countAdjDocNoIsLoading: PropTypes.bool,
  countAdjDocNoOptions: PropTypes.arrayOf(PropTypes.object),

  whseJobDocNoIsLoading: PropTypes.bool,
  whseJobDocNoOptions: PropTypes.arrayOf(PropTypes.object),

  loadListDocNoIsLoading: PropTypes.bool,
  loadListDocNoOptions: PropTypes.arrayOf(PropTypes.object),

  prfDelDocNoIsLoading: PropTypes.bool,
  prfDelDocNoOptions: PropTypes.arrayOf(PropTypes.object),

  goToDocTypeDocNo: PropTypes.func
};

ModelForm.defaultProps = {
  intl: {},
  match: {},
  resetTimestamp() {},

  initModel() {},
  showModel() {},
  createModel() {},
  updateModel() {},
  historyGoBack() {},
  resId: 0,
  model: { code: '' },
  modelIsLoading: false,

  fetchDocNoOptions() {},
  pickListDocNoIsLoading: false,
  pickListDocNoOptions: [],

  packListDocNoIsLoading: false,
  packListDocNoOptions: [],

  gdsRcptDocNoIsLoading: false,
  gdsRcptDocNoOptions: [],

  putAwayDocNoIsLoading: false,
  putAwayDocNoOptions: [],

  gdsIssDocNoIsLoading: false,
  gdsIssDocNoOptions: [],

  binTrfDocNoIsLoading: false,
  binTrfDocNoOptions: [],

  cycleCountDocNoIsLoading: false,
  cycleCountDocNoOptions: [],

  countAdjDocNoIsLoading: false,
  countAdjDocNoOptions: [],

  whseJobDocNoIsLoading: false,
  whseJobDocNoOptions: [],

  loadListDocNoIsLoading: false,
  loadListDocNoOptions: [],

  prfDelDocNoIsLoading: false,
  prfDelDocNoOptions: [],

  goToDocTypeDocNo() {}
};

const mapStateToProps = state => ({
  apiUrl: state.app.apiUrl,
  timestamp: state.siteDetail.modelTimestamp,
  resId: state.siteDetail.resId,
  model: state.siteDetail.model,
  modelIsLoading: state.siteDetail.modelIsLoading,

  pickListDocNoIsLoading: state.siteDetail.pickListDocNoIsLoading,
  pickListDocNoOptions: state.siteDetail.pickListDocNoOptions,

  packListDocNoIsLoading: state.siteDetail.packListDocNoIsLoading,
  packListDocNoOptions: state.siteDetail.packListDocNoOptions,

  gdsRcptDocNoIsLoading: state.siteDetail.gdsRcptDocNoIsLoading,
  gdsRcptDocNoOptions: state.siteDetail.gdsRcptDocNoOptions,

  putAwayDocNoIsLoading: state.siteDetail.putAwayDocNoIsLoading,
  putAwayDocNoOptions: state.siteDetail.putAwayDocNoOptions,

  gdsIssDocNoIsLoading: state.siteDetail.gdsIssDocNoIsLoading,
  gdsIssDocNoOptions: state.siteDetail.gdsIssDocNoOptions,

  binTrfDocNoIsLoading: state.siteDetail.binTrfDocNoIsLoading,
  binTrfDocNoOptions: state.siteDetail.binTrfDocNoOptions,

  cycleCountDocNoIsLoading: state.siteDetail.cycleCountDocNoIsLoading,
  cycleCountDocNoOptions: state.siteDetail.cycleCountDocNoOptions,

  countAdjDocNoIsLoading: state.siteDetail.countAdjDocNoIsLoading,
  countAdjDocNoOptions: state.siteDetail.countAdjDocNoOptions,

  whseJobDocNoIsLoading: state.siteDetail.whseJobDocNoIsLoading,
  whseJobDocNoOptions: state.siteDetail.whseJobDocNoOptions,

  loadListDocNoIsLoading: state.siteDetail.loadListDocNoIsLoading,
  loadListDocNoOptions: state.siteDetail.loadListDocNoOptions,

  prfDelDocNoIsLoading: state.siteDetail.prfDelDocNoIsLoading,
  prfDelDocNoOptions: state.siteDetail.prfDelDocNoOptions
});

const mapDispatchToProps = dispatch => ({
  resetTimestamp: () => dispatch(SiteDetailActions.siteDetailResetModelTimestamp()),
  initModel: () => dispatch(SiteDetailActions.siteDetailInitModel()),
  showModel: resId => dispatch(SiteDetailActions.siteDetailShowModel(resId)),

  updateModel: (formikBag, model) =>
    dispatch(SiteDetailActions.siteDetailUpdateModel(formikBag, model)),
  createModel: (formikBag, model) =>
    dispatch(SiteDetailActions.siteDetailCreateModel(formikBag, model)),

  fetchDocNoOptions: (docType, search) =>
    dispatch(SiteDetailActions.siteDetailFetchDocNoOptions(docType, search)),

  goToDocTypeDocNo: docType => dispatch(SiteDetailActions.siteDetailGoToDocTypeDocNo(docType)),

  historyGoBack: () => dispatch(AppActions.appHistoryGoBack())
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ModelForm));
