/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { PutAway02Types } from './Actions';
import INITIAL_STATE from './InitialState';

export const putAway02ResetTimestamp = (state, { currentPage, sorts, filters, pageSize }) => ({
  ...state,
  currentPage: currentPage || state.currentPage,
  sorts: sorts || state.sorts,
  filters: filters || state.filters,
  pageSize: pageSize || state.pageSize,
  timestamp: Date.now()
  // selectedDocuments: INITIAL_STATE.selectedDocuments
});

export const putAway02FetchPutAway02Loading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const putAway02FetchPutAway02Success = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  documents,
  currentPage,
  lastPage,
  total,
  pageSize
});

export const putAway02AddSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = state.selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: [
      ...state.selectedDocuments,
      ...selectedDocuments.filter(data => {
        return !selectIds.includes(data.id);
      })
    ]
  };
};

export const putAway02RemoveSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: state.selectedDocuments.filter(data => {
      return !selectIds.includes(data.id);
    })
  };
};

export const putAway02SetWorkspaceVisible = (state, { boolean }) => ({
  ...state,
  workspaceIsVisible: boolean
});

export const putAway02CreatePutAway02Loading = (state, { boolean }) => ({
  ...state,
  createIsLoading: boolean
});

export const putAway02CreatePutAway02Success = (state, { newDocuments }) => ({
  ...state,
  timestamp: Date.now(),
  selectedDocuments: [],
  newDocuments
});

export const putAway02SetExpandedRows = (state, { expandedRows }) => ({
  ...state,
  expandedRows
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [PutAway02Types.PUT_AWAY02_RESET_TIMESTAMP]: putAway02ResetTimestamp,
  [PutAway02Types.PUT_AWAY02_FETCH_PUT_AWAY02_LOADING]: putAway02FetchPutAway02Loading,
  [PutAway02Types.PUT_AWAY02_FETCH_PUT_AWAY02_SUCCESS]: putAway02FetchPutAway02Success,
  [PutAway02Types.PUT_AWAY02_ADD_SELECTED_DOCUMENTS]: putAway02AddSelectedDocuments,
  [PutAway02Types.PUT_AWAY02_REMOVE_SELECTED_DOCUMENTS]: putAway02RemoveSelectedDocuments,
  [PutAway02Types.PUT_AWAY02_SET_WORKSPACE_VISIBLE]: putAway02SetWorkspaceVisible,
  [PutAway02Types.PUT_AWAY02_CREATE_PUT_AWAY02_LOADING]: putAway02CreatePutAway02Loading,
  [PutAway02Types.PUT_AWAY02_CREATE_PUT_AWAY02_SUCCESS]: putAway02CreatePutAway02Success,
  [PutAway02Types.PUT_AWAY02_SET_EXPANDED_ROWS]: putAway02SetExpandedRows
});
