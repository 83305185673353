import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  putAway03ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  putAway03GoToDocument: ['hdrId'],
  putAway03NewDocument: null,
  putAway03FetchPutAway03: ['currentPage', 'sorts', 'filters', 'pageSize'],
  putAway03FetchPutAway03Loading: ['boolean'],
  putAway03FetchPutAway03Success: ['documents', 'currentPage', 'lastPage', 'total', 'pageSize'],
  putAway03AddSelectedDocuments: ['selectedDocuments'],
  putAway03RemoveSelectedDocuments: ['selectedDocuments'],
  putAway03SetWorkspaceVisible: ['boolean'],
  putAway03CreatePutAway03: ['hdrIds'],
  putAway03CreatePutAway03Loading: ['boolean'],
  putAway03CreatePutAway03Success: ['newDocuments']
});

export const PutAway03Types = Types;
export default Creators;
