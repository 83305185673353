import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  itemManufactureExcelShowBatchJobStatus: null,
  itemManufactureExcelShowBatchJobStatusSuccess: ['batchJobStatus'],
  itemManufactureExcelUploadExcel: ['file', 'procType'],
  itemManufactureExcelUploadLoading: ['boolean'],
  itemManufactureExcelDownloadExcel: ['itemType'],

  itemManufactureExcelDownloadItemBatches: ['itemId'],
  itemManufactureExcelUploadItemBatches: ['file'],

  itemManufactureExcelDownloadItemDocNo: [],
  itemManufactureExcelUploadItemDocNo: ['file']
});

export const ItemManufactureExcelTypes = Types;
export default Creators;
