/**
 * The initial values for the redux state.
 */
export default {
  timestamp: 0,
  docType: '',
  docNoIsLoading: false,
  docNos: [],
  formIsVisible: false,

  docNo: {
    id: 0,
    doc_type: '',
    doc_prefix: '',
    running_no: 1,
    running_no_length: 6,
    doc_suffix: '',
    is_enforce: 0
  },
  initDocNo: {
    id: 0,
    doc_type: '',
    doc_prefix: '',
    running_no: 1,
    running_no_length: 6,
    doc_suffix: '',
    is_enforce: 0
  }
};
