import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  siteDetailResetModelTimestamp: null,
  siteDetailSetResId: ['resId'],

  siteDetailInitModel: null,
  siteDetailShowModel: ['resId'],
  siteDetailShowModelSuccess: ['model'],

  siteDetailUpdateModel: ['formikBag', 'model'],
  siteDetailCreateModel: ['formikBag', 'model'],
  siteDetailUpdateModelSuccess: ['model'],

  siteDetailShowModelLoading: ['boolean'],

  siteDetailFetchDocNoOptions: ['docType', 'search'],

  siteDetailFetchPickListDocNoOptionLoading: ['boolean'],
  siteDetailFetchPickListDocNoOptionSuccess: ['options'],

  siteDetailFetchPackListDocNoOptionLoading: ['boolean'],
  siteDetailFetchPackListDocNoOptionSuccess: ['options'],

  siteDetailFetchGdsRcptDocNoOptionLoading: ['boolean'],
  siteDetailFetchGdsRcptDocNoOptionSuccess: ['options'],

  siteDetailFetchPutAwayDocNoOptionLoading: ['boolean'],
  siteDetailFetchPutAwayDocNoOptionSuccess: ['options'],

  siteDetailFetchGdsIssDocNoOptionLoading: ['boolean'],
  siteDetailFetchGdsIssDocNoOptionSuccess: ['options'],

  siteDetailFetchBinTrfDocNoOptionLoading: ['boolean'],
  siteDetailFetchBinTrfDocNoOptionSuccess: ['options'],

  siteDetailFetchCycleCountDocNoOptionLoading: ['boolean'],
  siteDetailFetchCycleCountDocNoOptionSuccess: ['options'],

  siteDetailFetchCountAdjDocNoOptionLoading: ['boolean'],
  siteDetailFetchCountAdjDocNoOptionSuccess: ['options'],

  siteDetailFetchWhseJobDocNoOptionLoading: ['boolean'],
  siteDetailFetchWhseJobDocNoOptionSuccess: ['options'],

  siteDetailFetchLoadListDocNoOptionLoading: ['boolean'],
  siteDetailFetchLoadListDocNoOptionSuccess: ['options'],

  siteDetailFetchPrfDelDocNoOptionLoading: ['boolean'],
  siteDetailFetchPrfDelDocNoOptionSuccess: ['options'],

  siteDetailFetchFailDelDocNoOptionLoading: ['boolean'],
  siteDetailFetchFailDelDocNoOptionSuccess: ['options'],

  siteDetailGoToDocTypeDocNo: ['docType']
});

export const SiteDetailTypes = Types;
export default Creators;
