import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  stkRcptIndexResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  stkRcptIndexFetchStkRcptIndex: ['currentPage', 'sorts', 'filters', 'pageSize'],
  stkRcptIndexFetchStkRcptIndexLoading: ['boolean'],
  stkRcptIndexFetchStkRcptIndexSuccess: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],
  stkRcptIndexAddSelectedDocuments: ['selectedDocuments'],
  stkRcptIndexRemoveSelectedDocuments: ['selectedDocuments'],
  stkRcptIndexSetWorkspaceVisible: ['boolean'],
  stkRcptIndexGoToDocument: ['hdrId'],
  stkRcptIndexGoToAudit: ['hdrId']
});

export const StkRcptIndexTypes = Types;
export default Creators;
