/**
 * The initial values for the redux state.
 */
export default {
  timestamp: 0,
  currentPage: 0,
  criteria: {
    item_ids_select2: [],
    item_group_01_ids_select2: [],
    item_group_02_ids_select2: [],
    item_group_03_ids_select2: [],
    storage_bin_ids_select2: [],
    storage_row_ids_select2: [],
    storage_bay_ids_select2: [],
    location_ids_select2: [],
    columns: {
      source: [],
      target: []
    }
  },
  sorts: {},
  filters: {},
  reportData: [],
  reportIsLoading: false,

  itemOptions: [],
  itemIsLoading: false,

  itemGroup01Options: [],
  itemGroup01IsLoading: false,

  itemGroup02Options: [],
  itemGroup02IsLoading: false,

  itemGroup03Options: [],
  itemGroup03IsLoading: false,

  storageBinOptions: [],
  storageBinIsLoading: false,

  storageRowOptions: [],
  storageRowIsLoading: false,

  storageBayOptions: [],
  storageBayIsLoading: false,

  locationOptions: [],
  locationIsLoading: false
};
