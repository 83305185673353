import { put, call, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
// import ApiService from '../Services/ApiService';
// import AppActions from '../Stores/App/Actions';
import LayerFarmProcessActions, { LayerFarmProcessTypes } from '../Stores/LayerFarmProcess/Actions';

// const getAppStore = state => state.app;

// eslint-disable-next-line import/prefer-default-export
export function* layerFarmProcessFetchList() {
  try {
    yield put(LayerFarmProcessActions.layerFarmProcessFetchListLoading(true));

    const result = [
      { proc_type: 'LAYER_FARM_LIST', icon: 'fa fa-cubes' },
      { proc_type: 'LAYER_FARM_EXCEL', icon: 'fa fa-file-excel-o' }
    ];
    yield put(LayerFarmProcessActions.layerFarmProcessFetchListSuccess(result));
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(LayerFarmProcessActions.layerFarmProcessFetchListLoading(false));
  }
}

export const saga = [
  takeLatest(LayerFarmProcessTypes.LAYER_FARM_PROCESS_FETCH_LIST, layerFarmProcessFetchList)
];
