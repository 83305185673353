/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { WhseJob15DetailTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const whseJob15DetailResetTimestamp = state => ({
  ...state,
  timestamp: Date.now()
});

export const whseJob15DetailSetHdrId = (state, { hdrId }) => ({
  ...state,
  timestamp: Date.now(),
  documentIsLoading: false,
  hdrId,
  detailIsVisible: false
});

export const whseJob15DetailShowDocumentLoading = (state, { boolean }) => ({
  ...state,
  documentIsLoading: boolean
});

export const whseJob15DetailShowHeaderSuccess = (state, { documentHeader }) => ({
  ...state,
  documentHeader
});

export const whseJob15DetailUpdateDocumentSuccess = (
  state,
  { documentHeader, documentDetails }
) => ({
  ...state,
  documentHeader,
  documentDetails
});

export const whseJob15DetailShowDetailsSuccess = (state, { documentDetails }) => ({
  ...state,
  documentDetails
});

export const whseJob15DetailSetDetailVisible = (state, { boolean }) => ({
  ...state,
  detailIsVisible: boolean
});

export const whseJob15DetailSetWhseJobType = (state, { whseJobType }) => ({
  ...state,
  whseJobType
});

export const whseJob15DetailSetDocumentDetail = (state, { documentDetail }) => ({
  ...state,
  documentDetail,
  whseJobType: documentDetail.whse_job_type,
  companyOptions: [],
  frStorageBinOptions: [],
  quantBalOptions: [],
  uomOptions: [],
  toStorageBinOptions: []
});

export const whseJob15DetailFetchFrStorageBinOptionLoading = (state, { boolean }) => ({
  ...state,
  frStorageBinIsLoading: boolean
});

export const whseJob15DetailFetchFrStorageBinOptionSuccess = (state, { options }) => ({
  ...state,
  frStorageBinOptions: options
});

export const whseJob15DetailFetchQuantBalOptionLoading = (state, { boolean }) => ({
  ...state,
  quantBalIsLoading: boolean
});

export const whseJob15DetailFetchQuantBalOptionSuccess = (state, { options }) => ({
  ...state,
  quantBalOptions: options
});

export const whseJob15DetailFetchItemBatchOptionLoading = (state, { boolean }) => ({
  ...state,
  itemBatchIsLoading: boolean
});

export const whseJob15DetailFetchItemBatchOptionSuccess = (state, { options }) => ({
  ...state,
  itemBatchOptions: options
});

export const whseJob15DetailFetchUomOptionLoading = (state, { boolean }) => ({
  ...state,
  uomIsLoading: boolean
});

export const whseJob15DetailFetchUomOptionSuccess = (state, { options }) => ({
  ...state,
  uomOptions: options
});

export const whseJob15DetailFetchItemCond01OptionLoading = (state, { boolean }) => ({
  ...state,
  itemCond01IsLoading: boolean
});

export const whseJob15DetailFetchItemCond01OptionSuccess = (state, { options }) => ({
  ...state,
  itemCond01Options: options
});

export const whseJob15DetailFetchToStorageBinOptionLoading = (state, { boolean }) => ({
  ...state,
  toStorageBinIsLoading: boolean
});

export const whseJob15DetailFetchToStorageBinOptionSuccess = (state, { options }) => ({
  ...state,
  toStorageBinOptions: options
});

export const whseJob15DetailFetchToHandlingUnitOptionLoading = (state, { boolean }) => ({
  ...state,
  toHandlingUnitIsLoading: boolean
});

export const whseJob15DetailFetchToHandlingUnitOptionSuccess = (state, { options }) => ({
  ...state,
  toHandlingUnitOptions: options
});

// force refresh the formik form
export const whseJob15DetailUpdateDetails = (state, { documentDetails }) => ({
  ...state,
  documentDetail: documentDetails[0]
});

// force refresh the formik form
export const whseJob15DetailCreateDetail = (state, { documentDetail }) => ({
  ...state,
  documentDetail
});

export const whseJob15DetailFetchWorkerOptionLoading = (state, { boolean }) => ({
  ...state,
  workerIsLoading: boolean
});

export const whseJob15DetailFetchWorkerOptionSuccess = (state, { options }) => ({
  ...state,
  workerOptions: options
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [WhseJob15DetailTypes.WHSE_JOB15_DETAIL_RESET_TIMESTAMP]: whseJob15DetailResetTimestamp,
  [WhseJob15DetailTypes.WHSE_JOB15_DETAIL_SET_HDR_ID]: whseJob15DetailSetHdrId,

  [WhseJob15DetailTypes.WHSE_JOB15_DETAIL_SHOW_HEADER_SUCCESS]: whseJob15DetailShowHeaderSuccess,

  [WhseJob15DetailTypes.WHSE_JOB15_DETAIL_UPDATE_DOCUMENT_SUCCESS]: whseJob15DetailUpdateDocumentSuccess,

  [WhseJob15DetailTypes.WHSE_JOB15_DETAIL_SHOW_DOCUMENT_LOADING]: whseJob15DetailShowDocumentLoading,

  [WhseJob15DetailTypes.WHSE_JOB15_DETAIL_SHOW_DETAILS_SUCCESS]: whseJob15DetailShowDetailsSuccess,

  [WhseJob15DetailTypes.WHSE_JOB15_DETAIL_SET_DETAIL_VISIBLE]: whseJob15DetailSetDetailVisible,
  [WhseJob15DetailTypes.WHSE_JOB15_DETAIL_SET_DOCUMENT_DETAIL]: whseJob15DetailSetDocumentDetail,

  [WhseJob15DetailTypes.WHSE_JOB15_DETAIL_UPDATE_DETAILS]: whseJob15DetailUpdateDetails,
  [WhseJob15DetailTypes.WHSE_JOB15_DETAIL_CREATE_DETAIL]: whseJob15DetailCreateDetail,

  [WhseJob15DetailTypes.WHSE_JOB15_DETAIL_SET_WHSE_JOB_TYPE]: whseJob15DetailSetWhseJobType,

  [WhseJob15DetailTypes.WHSE_JOB15_DETAIL_FETCH_FR_STORAGE_BIN_OPTION_LOADING]: whseJob15DetailFetchFrStorageBinOptionLoading,
  [WhseJob15DetailTypes.WHSE_JOB15_DETAIL_FETCH_FR_STORAGE_BIN_OPTION_SUCCESS]: whseJob15DetailFetchFrStorageBinOptionSuccess,

  [WhseJob15DetailTypes.WHSE_JOB15_DETAIL_FETCH_QUANT_BAL_OPTION_LOADING]: whseJob15DetailFetchQuantBalOptionLoading,
  [WhseJob15DetailTypes.WHSE_JOB15_DETAIL_FETCH_QUANT_BAL_OPTION_SUCCESS]: whseJob15DetailFetchQuantBalOptionSuccess,

  [WhseJob15DetailTypes.WHSE_JOB15_DETAIL_FETCH_UOM_OPTION_LOADING]: whseJob15DetailFetchUomOptionLoading,
  [WhseJob15DetailTypes.WHSE_JOB15_DETAIL_FETCH_UOM_OPTION_SUCCESS]: whseJob15DetailFetchUomOptionSuccess,

  [WhseJob15DetailTypes.WHSE_JOB15_DETAIL_FETCH_ITEM_COND01_OPTION_LOADING]: whseJob15DetailFetchItemCond01OptionLoading,
  [WhseJob15DetailTypes.WHSE_JOB15_DETAIL_FETCH_ITEM_COND01_OPTION_SUCCESS]: whseJob15DetailFetchItemCond01OptionSuccess,

  [WhseJob15DetailTypes.WHSE_JOB15_DETAIL_FETCH_TO_STORAGE_BIN_OPTION_LOADING]: whseJob15DetailFetchToStorageBinOptionLoading,
  [WhseJob15DetailTypes.WHSE_JOB15_DETAIL_FETCH_TO_STORAGE_BIN_OPTION_SUCCESS]: whseJob15DetailFetchToStorageBinOptionSuccess,

  [WhseJob15DetailTypes.WHSE_JOB15_DETAIL_FETCH_WORKER_OPTION_LOADING]: whseJob15DetailFetchWorkerOptionLoading,
  [WhseJob15DetailTypes.WHSE_JOB15_DETAIL_FETCH_WORKER_OPTION_SUCCESS]: whseJob15DetailFetchWorkerOptionSuccess
});
