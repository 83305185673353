import React from 'react';
import { connect } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';

import { PlayCircleOutlined } from '@ant-design/icons';

import { Row, Col, Typography } from 'antd';
import { FormikDatePicker, FormikSelect, FormikButton } from '../../Components/Formik';
import WorkerPerformanceChartActions from '../../Stores/WorkerPerformanceChart/Actions';

const { Text } = Typography;

class CriteriaForm extends React.PureComponent {
  componentDidMount() {}

  componentDidUpdate() {}

  componentWillUnmount() {}

  render() {
    const { intl, criteria, chartIsLoading, resetTimestamp } = this.props;

    const whseProcOptions = [
      { value: 'WHSE_JOB_14_01', label: 'Gds Rcpt' },
      { value: 'WHSE_JOB_14_02', label: 'Gds Rcpt Return' },
      { value: 'WHSE_JOB_15_01', label: 'Put Away' },
      { value: 'WHSE_JOB_15_02', label: 'Put Away Return' },
      { value: 'WHSE_JOB_03_01', label: 'Pick List' },
      { value: 'PACK_LIST_01', label: 'Pack List' },
      { value: 'WHSE_JOB_06_01', label: 'Load List' }
    ];

    const timePeriodTypeOptions = [
      { label: 'Daily', value: 'daily' },
      { label: 'Weekly', value: 'weekly' },
      { label: 'Monthly', value: 'monthly' },
      { label: 'Yearly', value: 'yearly' }
    ];

    return (
      <Formik
        enableReinitialize
        initialValues={criteria}
        onSubmit={(values, formikBag) => {
          formikBag.setSubmitting(true);
          try {
            const tmpCriteria = {};

            if (values.whse_job_proc.value) {
              tmpCriteria.whse_job_proc = values.whse_job_proc.value;
            } else {
              tmpCriteria.whse_job_proc = values.whse_job_proc;
            }

            if (values.time_period_type.value) {
              tmpCriteria.time_period_type = values.time_period_type.value;
            } else {
              tmpCriteria.time_period_type = values.time_period_type;
            }

            if (values.query_date.value) {
              tmpCriteria.query_date = values.query_date.value;
            } else {
              tmpCriteria.query_date = values.query_date;
            }

            resetTimestamp(tmpCriteria);
          } finally {
            formikBag.setSubmitting(false);
          }
        }}
      >
        {({
          // values,
          // handleChange,
          // errors,
          // setFieldTouched,
          // dirty,
          // touched,
          isSubmitting,
          // isValid,
          handleSubmit
          // handleReset
        }) => (
          <Form>
            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'whse_job_proc' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={10}>
                <Field
                  name="whse_job_proc"
                  component={FormikSelect}
                  options={whseProcOptions}
                  disabled={isSubmitting || chartIsLoading}
                />
              </Col>
              <Col span={10}>
                <Text> </Text>
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'date' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={10}>
                <Field
                  name="query_date"
                  component={FormikDatePicker}
                  disabled={isSubmitting || chartIsLoading}
                />
              </Col>
              <Col span={10}>
                <Text> </Text>
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'range' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={10}>
                <Field
                  name="time_period_type"
                  component={FormikSelect}
                  showArrow
                  notFoundContent={null}
                  options={timePeriodTypeOptions}
                  disabled={isSubmitting || chartIsLoading}
                />
              </Col>
              <Col span={10}>
                <Text> </Text>
              </Col>
            </Row>

            <Row type="flex" justify="space-between" gutter={[0, 16]} style={{ height: '10px' }} />

            <Row
              type="flex"
              justify="space-between"
              gutter={[0, 16]}
              style={{ marginBottom: '10px' }}
            >
              <Col span={24}>
                <Field
                  component={FormikButton}
                  loading={isSubmitting || chartIsLoading}
                  onClick={handleSubmit}
                  label={intl.formatMessage({
                    id: 'run_report'
                  })}
                  icon={<PlayCircleOutlined />}
                  type="primary"
                />
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    );
  }
}

CriteriaForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,

  resetTimestamp: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  criteria: PropTypes.object,
  chartIsLoading: PropTypes.bool
};

CriteriaForm.defaultProps = {
  intl: {},

  resetTimestamp() {},
  criteria: {},
  chartIsLoading: false
};

const mapStateToProps = state => ({
  criteria: state.workerPerformanceChart.criteria,
  chartIsLoading: state.workerPerformanceChart.workerPerformanceDataIsLoading,

  whseProcOptions: state.workerPerformanceChart.whseProcOptions,
  timePeriodTypeOptions: state.workerPerformanceChart.timePeriodTypeOptions,
  timePeriodOptions: state.workerPerformanceChart.timePeriodOptions,
  timePeriodOptionsIsLoading: state.workerPerformanceChart.timePeriodOptionsIsLoading
});

const mapDispatchToProps = dispatch => ({
  resetTimestamp: criteria =>
    dispatch(WorkerPerformanceChartActions.workerPerformanceChartResetTimestamp(criteria))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CriteriaForm));
