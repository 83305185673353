/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { LoadListDetailBoardTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const loadListDetailBoardResetWipPickListTimestamp = (
  state,
  { currentPage, sorts, filters, pageSize }
) => ({
  ...state,
  wipPickListCurrentPage: currentPage || state.wipPickListCurrentPage,
  wipPickListSorts: sorts || state.wipPickListSorts,
  wipPickListFilters: filters || state.wipPickListFilters,
  wipPickListPageSize: pageSize || state.wipPickListPageSize,
  wipPickListTimestamp: Date.now()
});

export const loadListDetailBoardFetchWipPickListLoading = (state, { boolean }) => ({
  ...state,
  wipPickListIsLoading: boolean
});

export const loadListDetailBoardFetchWipPickListSuccess = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  wipPickLists: documents,
  wipPickListCurrentPage: currentPage,
  wipPickListLastPage: lastPage,
  wipPickListTotal: total,
  wipPickListPageSize: pageSize
});

export const loadListDetailBoardResetWipLoadListTimestamp = (
  state,
  { currentPage, sorts, filters, pageSize }
) => ({
  ...state,
  wipLoadListCurrentPage: currentPage || state.wipLoadListCurrentPage,
  wipLoadListSorts: sorts || state.wipLoadListSorts,
  wipLoadListFilters: filters || state.wipLoadListFilters,
  wipLoadListPageSize: pageSize || state.wipLoadListPageSize,
  wipLoadListTimestamp: Date.now()
});

export const loadListDetailBoardFetchWipLoadListLoading = (state, { boolean }) => ({
  ...state,
  wipLoadListIsLoading: boolean
});

export const loadListDetailBoardFetchWipLoadListSuccess = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  wipLoadLists: documents,
  wipLoadListCurrentPage: currentPage,
  wipLoadListLastPage: lastPage,
  wipLoadListTotal: total,
  wipLoadListPageSize: pageSize
});

export const loadListDetailBoardResetWipWhseJobTimestamp = (
  state,
  { currentPage, sorts, filters, pageSize }
) => ({
  ...state,
  wipWhseJobCurrentPage: currentPage || state.wipWhseJobCurrentPage,
  wipWhseJobSorts: sorts || state.wipWhseJobSorts,
  wipWhseJobFilters: filters || state.wipWhseJobFilters,
  wipWhseJobPageSize: pageSize || state.wipWhseJobPageSize,
  wipWhseJobTimestamp: Date.now()
});

export const loadListDetailBoardFetchWipWhseJobLoading = (state, { boolean }) => ({
  ...state,
  wipWhseJobIsLoading: boolean
});

export const loadListDetailBoardFetchWipWhseJobSuccess = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  wipWhseJobs: documents,
  wipWhseJobCurrentPage: currentPage,
  wipWhseJobLastPage: lastPage,
  wipWhseJobTotal: total,
  wipWhseJobPageSize: pageSize
});

export const loadListDetailBoardResetCompleteLoadListTimestamp = (
  state,
  { currentPage, sorts, filters, pageSize }
) => ({
  ...state,
  completeLoadListCurrentPage: currentPage || state.completeLoadListCurrentPage,
  completeLoadListSorts: sorts || state.completeLoadListSorts,
  completeLoadListFilters: filters || state.completeLoadListFilters,
  completeLoadListPageSize: pageSize || state.completeLoadListPageSize,
  completeLoadListTimestamp: Date.now()
});

export const loadListDetailBoardFetchCompleteLoadListLoading = (state, { boolean }) => ({
  ...state,
  completeLoadListIsLoading: boolean
});

export const loadListDetailBoardFetchCompleteLoadListSuccess = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  completeLoadLists: documents,
  completeLoadListCurrentPage: currentPage,
  completeLoadListLastPage: lastPage,
  completeLoadListTotal: total,
  completeLoadListPageSize: pageSize
});

export const loadListDetailBoardShowWipPickListSummary = (state, { docId }) => ({
  ...state,
  wipPickListDocId: docId,
  wipPickListDialogIsVisible: true
});

export const loadListDetailBoardSetWipPickListDialogVisible = (state, { boolean }) => ({
  ...state,
  wipPickListDialogIsVisible: boolean
});

export const loadListDetailBoardShowWipPickListSummaryLoading = (state, { boolean }) => ({
  ...state,
  wipPickListDocumentIsLoading: boolean
});

export const loadListDetailBoardShowWipPickListSummarySuccess = (state, { document }) => ({
  ...state,
  wipPickListDocument: document
});

export const loadListDetailBoardShowWipLoadListSummary = (state, { docId }) => ({
  ...state,
  wipLoadListDocId: docId,
  wipLoadListDialogIsVisible: true
});

export const loadListDetailBoardSetWipLoadListDialogVisible = (state, { boolean }) => ({
  ...state,
  wipLoadListDialogIsVisible: boolean
});

export const loadListDetailBoardShowWipLoadListSummaryLoading = (state, { boolean }) => ({
  ...state,
  wipLoadListDocumentIsLoading: boolean
});

export const loadListDetailBoardShowWipLoadListSummarySuccess = (state, { document }) => ({
  ...state,
  wipLoadListDocument: document
});

export const loadListDetailBoardShowWipWhseJobSummary = (state, { docId }) => ({
  ...state,
  wipWhseJobDocId: docId,
  wipWhseJobDialogIsVisible: true
});

export const loadListDetailBoardSetWipWhseJobDialogVisible = (state, { boolean }) => ({
  ...state,
  wipWhseJobDialogIsVisible: boolean
});

export const loadListDetailBoardShowWipWhseJobSummaryLoading = (state, { boolean }) => ({
  ...state,
  wipWhseJobDocumentIsLoading: boolean
});

export const loadListDetailBoardShowWipWhseJobSummarySuccess = (state, { document }) => ({
  ...state,
  wipWhseJobDocument: document
});

export const loadListDetailBoardShowCompleteLoadListSummary = (state, { docId }) => ({
  ...state,
  completeLoadListDocId: docId,
  completeLoadListDialogIsVisible: true
});

export const loadListDetailBoardSetCompleteLoadListDialogVisible = (state, { boolean }) => ({
  ...state,
  completeLoadListDialogIsVisible: boolean
});

export const loadListDetailBoardShowCompleteLoadListSummaryLoading = (state, { boolean }) => ({
  ...state,
  completeLoadListDocumentIsLoading: boolean
});

export const loadListDetailBoardShowCompleteLoadListSummarySuccess = (state, { document }) => ({
  ...state,
  completeLoadListDocument: document
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [LoadListDetailBoardTypes.LOAD_LIST_DETAIL_BOARD_RESET_WIP_PICK_LIST_TIMESTAMP]: loadListDetailBoardResetWipPickListTimestamp,
  [LoadListDetailBoardTypes.LOAD_LIST_DETAIL_BOARD_FETCH_WIP_PICK_LIST_LOADING]: loadListDetailBoardFetchWipPickListLoading,
  [LoadListDetailBoardTypes.LOAD_LIST_DETAIL_BOARD_FETCH_WIP_PICK_LIST_SUCCESS]: loadListDetailBoardFetchWipPickListSuccess,

  [LoadListDetailBoardTypes.LOAD_LIST_DETAIL_BOARD_RESET_WIP_LOAD_LIST_TIMESTAMP]: loadListDetailBoardResetWipLoadListTimestamp,
  [LoadListDetailBoardTypes.LOAD_LIST_DETAIL_BOARD_FETCH_WIP_LOAD_LIST_LOADING]: loadListDetailBoardFetchWipLoadListLoading,
  [LoadListDetailBoardTypes.LOAD_LIST_DETAIL_BOARD_FETCH_WIP_LOAD_LIST_SUCCESS]: loadListDetailBoardFetchWipLoadListSuccess,

  [LoadListDetailBoardTypes.LOAD_LIST_DETAIL_BOARD_RESET_WIP_WHSE_JOB_TIMESTAMP]: loadListDetailBoardResetWipWhseJobTimestamp,
  [LoadListDetailBoardTypes.LOAD_LIST_DETAIL_BOARD_FETCH_WIP_WHSE_JOB_LOADING]: loadListDetailBoardFetchWipWhseJobLoading,
  [LoadListDetailBoardTypes.LOAD_LIST_DETAIL_BOARD_FETCH_WIP_WHSE_JOB_SUCCESS]: loadListDetailBoardFetchWipWhseJobSuccess,

  [LoadListDetailBoardTypes.LOAD_LIST_DETAIL_BOARD_RESET_COMPLETE_LOAD_LIST_TIMESTAMP]: loadListDetailBoardResetCompleteLoadListTimestamp,
  [LoadListDetailBoardTypes.LOAD_LIST_DETAIL_BOARD_FETCH_COMPLETE_LOAD_LIST_LOADING]: loadListDetailBoardFetchCompleteLoadListLoading,
  [LoadListDetailBoardTypes.LOAD_LIST_DETAIL_BOARD_FETCH_COMPLETE_LOAD_LIST_SUCCESS]: loadListDetailBoardFetchCompleteLoadListSuccess,

  [LoadListDetailBoardTypes.LOAD_LIST_DETAIL_BOARD_SHOW_WIP_PICK_LIST_SUMMARY]: loadListDetailBoardShowWipPickListSummary,
  [LoadListDetailBoardTypes.LOAD_LIST_DETAIL_BOARD_SET_WIP_PICK_LIST_DIALOG_VISIBLE]: loadListDetailBoardSetWipPickListDialogVisible,
  [LoadListDetailBoardTypes.LOAD_LIST_DETAIL_BOARD_SHOW_WIP_PICK_LIST_SUMMARY_LOADING]: loadListDetailBoardShowWipPickListSummaryLoading,
  [LoadListDetailBoardTypes.LOAD_LIST_DETAIL_BOARD_SHOW_WIP_PICK_LIST_SUMMARY_SUCCESS]: loadListDetailBoardShowWipPickListSummarySuccess,

  [LoadListDetailBoardTypes.LOAD_LIST_DETAIL_BOARD_SHOW_WIP_LOAD_LIST_SUMMARY]: loadListDetailBoardShowWipLoadListSummary,
  [LoadListDetailBoardTypes.LOAD_LIST_DETAIL_BOARD_SET_WIP_LOAD_LIST_DIALOG_VISIBLE]: loadListDetailBoardSetWipLoadListDialogVisible,
  [LoadListDetailBoardTypes.LOAD_LIST_DETAIL_BOARD_SHOW_WIP_LOAD_LIST_SUMMARY_LOADING]: loadListDetailBoardShowWipLoadListSummaryLoading,
  [LoadListDetailBoardTypes.LOAD_LIST_DETAIL_BOARD_SHOW_WIP_LOAD_LIST_SUMMARY_SUCCESS]: loadListDetailBoardShowWipLoadListSummarySuccess,

  [LoadListDetailBoardTypes.LOAD_LIST_DETAIL_BOARD_SHOW_WIP_WHSE_JOB_SUMMARY]: loadListDetailBoardShowWipWhseJobSummary,
  [LoadListDetailBoardTypes.LOAD_LIST_DETAIL_BOARD_SET_WIP_WHSE_JOB_DIALOG_VISIBLE]: loadListDetailBoardSetWipWhseJobDialogVisible,
  [LoadListDetailBoardTypes.LOAD_LIST_DETAIL_BOARD_SHOW_WIP_WHSE_JOB_SUMMARY_LOADING]: loadListDetailBoardShowWipWhseJobSummaryLoading,
  [LoadListDetailBoardTypes.LOAD_LIST_DETAIL_BOARD_SHOW_WIP_WHSE_JOB_SUMMARY_SUCCESS]: loadListDetailBoardShowWipWhseJobSummarySuccess,

  [LoadListDetailBoardTypes.LOAD_LIST_DETAIL_BOARD_SHOW_COMPLETE_LOAD_LIST_SUMMARY]: loadListDetailBoardShowCompleteLoadListSummary,
  [LoadListDetailBoardTypes.LOAD_LIST_DETAIL_BOARD_SET_COMPLETE_LOAD_LIST_DIALOG_VISIBLE]: loadListDetailBoardSetCompleteLoadListDialogVisible,
  [LoadListDetailBoardTypes.LOAD_LIST_DETAIL_BOARD_SHOW_COMPLETE_LOAD_LIST_SUMMARY_LOADING]: loadListDetailBoardShowCompleteLoadListSummaryLoading,
  [LoadListDetailBoardTypes.LOAD_LIST_DETAIL_BOARD_SHOW_COMPLETE_LOAD_LIST_SUMMARY_SUCCESS]: loadListDetailBoardShowCompleteLoadListSummarySuccess
});
