/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { LspSupplierReturnProcessTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const lspSupplierReturnProcessFetchLspSupplierReturnProcessLoading = (
  state,
  { boolean }
) => ({
  ...state,
  processIsLoading: boolean
});

export const lspSupplierReturnProcessFetchLspSupplierReturnProcessSuccess = (
  state,
  { process }
) => ({
  ...state,
  process
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [LspSupplierReturnProcessTypes.LSP_SUPPLIER_RETURN_PROCESS_FETCH_LSP_SUPPLIER_RETURN_PROCESS_LOADING]: lspSupplierReturnProcessFetchLspSupplierReturnProcessLoading,
  [LspSupplierReturnProcessTypes.LSP_SUPPLIER_RETURN_PROCESS_FETCH_LSP_SUPPLIER_RETURN_PROCESS_SUCCESS]: lspSupplierReturnProcessFetchLspSupplierReturnProcessSuccess
});
