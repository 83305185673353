import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  countAdjFServ02Export: ['formikBag', 'criteria'],
  countAdjFServ02ExportSuccess: null,
  countAdjFServ02ExportLoading: ['boolean'],

  countAdjFServ02ShowBatchJobStatus: null,
  countAdjFServ02ShowBatchJobStatusSuccess: ['batchJobStatus'],

  countAdjFServ02FetchCompanyOptions: ['search'],
  countAdjFServ02FetchCompanyOptionLoading: ['boolean'],
  countAdjFServ02FetchCompanyOptionSuccess: ['options'],

  countAdjFServ02FetchLocationOptions: ['search'],
  countAdjFServ02FetchLocationOptionLoading: ['boolean'],
  countAdjFServ02FetchLocationOptionSuccess: ['options'],

  countAdjFServ02FetchItemOptions: ['search'],
  countAdjFServ02FetchItemOptionLoading: ['boolean'],
  countAdjFServ02FetchItemOptionSuccess: ['options']
});

export const CountAdjFServ02Types = Types;
export default Creators;
