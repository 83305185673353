/**
 * The initial values for the redux state.
 */
export default {
  batchJobStatus: {
    proc_type: 0,
    user_id: 0,
    status_number: 0
  },
  strProcType: 'LSP_SLS_ORD_EXCEL_01',
  uploadIsLoading: false,

  debtorOptions: [],
  debtorIsLoading: false,

  projectOptions: [],
  projectIsLoading: false
};
