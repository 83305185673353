import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  whseJob060101ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  whseJob060101GoToWhseJobDocument: ['hdrId'],
  whseJob060101FetchWhseJob060101: ['currentPage', 'sorts', 'filters', 'pageSize'],
  whseJob060101FetchWhseJob060101Loading: ['boolean'],
  whseJob060101FetchWhseJob060101Success: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],
  whseJob060101AddSelectedDocuments: ['selectedDocuments'],
  whseJob060101RemoveSelectedDocuments: ['selectedDocuments'],
  whseJob060101SetWorkspaceVisible: ['boolean'],
  whseJob060101PrintWhseJob060101: ['hdrIds'],
  whseJob060101PrintWhseJob060101Loading: ['boolean']
});

export const WhseJob060101Types = Types;
export default Creators;
