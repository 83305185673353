import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  binTrf02ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  binTrf02GoToDocument: ['hdrId'],
  binTrf02NewDocument: null,
  binTrf02FetchBinTrf02: ['currentPage', 'sorts', 'filters', 'pageSize'],
  binTrf02FetchBinTrf02Loading: ['boolean'],
  binTrf02FetchBinTrf02Success: ['documents', 'currentPage', 'lastPage', 'total', 'pageSize'],
  binTrf02AddSelectedDocuments: ['selectedDocuments'],
  binTrf02RemoveSelectedDocuments: ['selectedDocuments'],
  binTrf02SetWorkspaceVisible: ['boolean'],
  binTrf02PrintBinTrf02: ['hdrIds'],
  binTrf02PrintBinTrf02Loading: ['boolean']
});

export const BinTrf02Types = Types;
export default Creators;
