/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { GdsRcptDetailBoardTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const gdsRcptDetailBoardResetWipInbOrdTimestamp = (
  state,
  { currentPage, sorts, filters, pageSize }
) => ({
  ...state,
  wipInbOrdCurrentPage: currentPage || state.wipInbOrdCurrentPage,
  wipInbOrdSorts: sorts || state.wipInbOrdSorts,
  wipInbOrdFilters: filters || state.wipInbOrdFilters,
  wipInbOrdPageSize: pageSize || state.wipInbOrdPageSize,
  wipInbOrdTimestamp: Date.now()
});

export const gdsRcptDetailBoardFetchWipInbOrdLoading = (state, { boolean }) => ({
  ...state,
  wipInbOrdIsLoading: boolean
});

export const gdsRcptDetailBoardFetchWipInbOrdSuccess = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  wipInbOrds: documents,
  wipInbOrdCurrentPage: currentPage,
  wipInbOrdLastPage: lastPage,
  wipInbOrdTotal: total,
  wipInbOrdPageSize: pageSize
});

export const gdsRcptDetailBoardResetWipGdsRcptTimestamp = (
  state,
  { currentPage, sorts, filters, pageSize }
) => ({
  ...state,
  wipGdsRcptCurrentPage: currentPage || state.wipGdsRcptCurrentPage,
  wipGdsRcptSorts: sorts || state.wipGdsRcptSorts,
  wipGdsRcptFilters: filters || state.wipGdsRcptFilters,
  wipGdsRcptPageSize: pageSize || state.wipGdsRcptPageSize,
  wipGdsRcptTimestamp: Date.now()
});

export const gdsRcptDetailBoardFetchWipGdsRcptLoading = (state, { boolean }) => ({
  ...state,
  wipGdsRcptIsLoading: boolean
});

export const gdsRcptDetailBoardFetchWipGdsRcptSuccess = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  wipGdsRcpts: documents,
  wipGdsRcptCurrentPage: currentPage,
  wipGdsRcptLastPage: lastPage,
  wipGdsRcptTotal: total,
  wipGdsRcptPageSize: pageSize
});

export const gdsRcptDetailBoardResetWipWhseJobTimestamp = (
  state,
  { currentPage, sorts, filters, pageSize }
) => ({
  ...state,
  wipWhseJobCurrentPage: currentPage || state.wipWhseJobCurrentPage,
  wipWhseJobSorts: sorts || state.wipWhseJobSorts,
  wipWhseJobFilters: filters || state.wipWhseJobFilters,
  wipWhseJobPageSize: pageSize || state.wipWhseJobPageSize,
  wipWhseJobTimestamp: Date.now()
});

export const gdsRcptDetailBoardFetchWipWhseJobLoading = (state, { boolean }) => ({
  ...state,
  wipWhseJobIsLoading: boolean
});

export const gdsRcptDetailBoardFetchWipWhseJobSuccess = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  wipWhseJobs: documents,
  wipWhseJobCurrentPage: currentPage,
  wipWhseJobLastPage: lastPage,
  wipWhseJobTotal: total,
  wipWhseJobPageSize: pageSize
});

export const gdsRcptDetailBoardResetCompleteGdsRcptTimestamp = (
  state,
  { currentPage, sorts, filters, pageSize }
) => ({
  ...state,
  completeGdsRcptCurrentPage: currentPage || state.completeGdsRcptCurrentPage,
  completeGdsRcptSorts: sorts || state.completeGdsRcptSorts,
  completeGdsRcptFilters: filters || state.completeGdsRcptFilters,
  completeGdsRcptPageSize: pageSize || state.completeGdsRcptPageSize,
  completeGdsRcptTimestamp: Date.now()
});

export const gdsRcptDetailBoardFetchCompleteGdsRcptLoading = (state, { boolean }) => ({
  ...state,
  completeGdsRcptIsLoading: boolean
});

export const gdsRcptDetailBoardFetchCompleteGdsRcptSuccess = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  completeGdsRcpts: documents,
  completeGdsRcptCurrentPage: currentPage,
  completeGdsRcptLastPage: lastPage,
  completeGdsRcptTotal: total,
  completeGdsRcptPageSize: pageSize
});

export const gdsRcptDetailBoardShowWipWhseJobSummary = (state, { docId }) => ({
  ...state,
  wipWhseJobDocId: docId,
  wipWhseJobDialogIsVisible: true
});

export const gdsRcptDetailBoardSetWipWhseJobDialogVisible = (state, { boolean }) => ({
  ...state,
  wipWhseJobDialogIsVisible: boolean
});

export const gdsRcptDetailBoardShowWipWhseJobSummaryLoading = (state, { boolean }) => ({
  ...state,
  wipWhseJobDocumentIsLoading: boolean
});

export const gdsRcptDetailBoardShowWipWhseJobSummarySuccess = (state, { document }) => ({
  ...state,
  wipWhseJobDocument: document
});

export const gdsRcptDetailBoardShowWipGdsRcptSummary = (state, { docId }) => ({
  ...state,
  wipGdsRcptDocId: docId,
  wipGdsRcptDialogIsVisible: true
});

export const gdsRcptDetailBoardSetWipGdsRcptDialogVisible = (state, { boolean }) => ({
  ...state,
  wipGdsRcptDialogIsVisible: boolean
});

export const gdsRcptDetailBoardShowWipGdsRcptSummaryLoading = (state, { boolean }) => ({
  ...state,
  wipGdsRcptDocumentIsLoading: boolean
});

export const gdsRcptDetailBoardShowWipGdsRcptSummarySuccess = (state, { document }) => ({
  ...state,
  wipGdsRcptDocument: document
});

export const gdsRcptDetailBoardShowWipInbOrdSummary = (state, { docId }) => ({
  ...state,
  wipInbOrdDocId: docId,
  wipInbOrdDialogIsVisible: true
});

export const gdsRcptDetailBoardSetWipInbOrdDialogVisible = (state, { boolean }) => ({
  ...state,
  wipInbOrdDialogIsVisible: boolean
});

export const gdsRcptDetailBoardShowWipInbOrdSummaryLoading = (state, { boolean }) => ({
  ...state,
  wipInbOrdDocumentIsLoading: boolean
});

export const gdsRcptDetailBoardShowWipInbOrdSummarySuccess = (state, { document }) => ({
  ...state,
  wipInbOrdDocument: document
});

export const gdsRcptDetailBoardShowCompleteGdsRcptSummary = (state, { docId }) => ({
  ...state,
  completeGdsRcptDocId: docId,
  completeGdsRcptDialogIsVisible: true
});

export const gdsRcptDetailBoardSetCompleteGdsRcptDialogVisible = (state, { boolean }) => ({
  ...state,
  completeGdsRcptDialogIsVisible: boolean
});

export const gdsRcptDetailBoardShowCompleteGdsRcptSummaryLoading = (state, { boolean }) => ({
  ...state,
  completeGdsRcptDocumentIsLoading: boolean
});

export const gdsRcptDetailBoardShowCompleteGdsRcptSummarySuccess = (state, { document }) => ({
  ...state,
  completeGdsRcptDocument: document
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [GdsRcptDetailBoardTypes.GDS_RCPT_DETAIL_BOARD_RESET_WIP_INB_ORD_TIMESTAMP]: gdsRcptDetailBoardResetWipInbOrdTimestamp,
  [GdsRcptDetailBoardTypes.GDS_RCPT_DETAIL_BOARD_FETCH_WIP_INB_ORD_LOADING]: gdsRcptDetailBoardFetchWipInbOrdLoading,
  [GdsRcptDetailBoardTypes.GDS_RCPT_DETAIL_BOARD_FETCH_WIP_INB_ORD_SUCCESS]: gdsRcptDetailBoardFetchWipInbOrdSuccess,

  [GdsRcptDetailBoardTypes.GDS_RCPT_DETAIL_BOARD_RESET_WIP_GDS_RCPT_TIMESTAMP]: gdsRcptDetailBoardResetWipGdsRcptTimestamp,
  [GdsRcptDetailBoardTypes.GDS_RCPT_DETAIL_BOARD_FETCH_WIP_GDS_RCPT_LOADING]: gdsRcptDetailBoardFetchWipGdsRcptLoading,
  [GdsRcptDetailBoardTypes.GDS_RCPT_DETAIL_BOARD_FETCH_WIP_GDS_RCPT_SUCCESS]: gdsRcptDetailBoardFetchWipGdsRcptSuccess,

  [GdsRcptDetailBoardTypes.GDS_RCPT_DETAIL_BOARD_RESET_WIP_WHSE_JOB_TIMESTAMP]: gdsRcptDetailBoardResetWipWhseJobTimestamp,
  [GdsRcptDetailBoardTypes.GDS_RCPT_DETAIL_BOARD_FETCH_WIP_WHSE_JOB_LOADING]: gdsRcptDetailBoardFetchWipWhseJobLoading,
  [GdsRcptDetailBoardTypes.GDS_RCPT_DETAIL_BOARD_FETCH_WIP_WHSE_JOB_SUCCESS]: gdsRcptDetailBoardFetchWipWhseJobSuccess,

  [GdsRcptDetailBoardTypes.GDS_RCPT_DETAIL_BOARD_RESET_COMPLETE_GDS_RCPT_TIMESTAMP]: gdsRcptDetailBoardResetCompleteGdsRcptTimestamp,
  [GdsRcptDetailBoardTypes.GDS_RCPT_DETAIL_BOARD_FETCH_COMPLETE_GDS_RCPT_LOADING]: gdsRcptDetailBoardFetchCompleteGdsRcptLoading,
  [GdsRcptDetailBoardTypes.GDS_RCPT_DETAIL_BOARD_FETCH_COMPLETE_GDS_RCPT_SUCCESS]: gdsRcptDetailBoardFetchCompleteGdsRcptSuccess,

  [GdsRcptDetailBoardTypes.GDS_RCPT_DETAIL_BOARD_SHOW_WIP_WHSE_JOB_SUMMARY]: gdsRcptDetailBoardShowWipWhseJobSummary,
  [GdsRcptDetailBoardTypes.GDS_RCPT_DETAIL_BOARD_SET_WIP_WHSE_JOB_DIALOG_VISIBLE]: gdsRcptDetailBoardSetWipWhseJobDialogVisible,
  [GdsRcptDetailBoardTypes.GDS_RCPT_DETAIL_BOARD_SHOW_WIP_WHSE_JOB_SUMMARY_LOADING]: gdsRcptDetailBoardShowWipWhseJobSummaryLoading,
  [GdsRcptDetailBoardTypes.GDS_RCPT_DETAIL_BOARD_SHOW_WIP_WHSE_JOB_SUMMARY_SUCCESS]: gdsRcptDetailBoardShowWipWhseJobSummarySuccess,

  [GdsRcptDetailBoardTypes.GDS_RCPT_DETAIL_BOARD_SHOW_WIP_GDS_RCPT_SUMMARY]: gdsRcptDetailBoardShowWipGdsRcptSummary,
  [GdsRcptDetailBoardTypes.GDS_RCPT_DETAIL_BOARD_SET_WIP_GDS_RCPT_DIALOG_VISIBLE]: gdsRcptDetailBoardSetWipGdsRcptDialogVisible,
  [GdsRcptDetailBoardTypes.GDS_RCPT_DETAIL_BOARD_SHOW_WIP_GDS_RCPT_SUMMARY_LOADING]: gdsRcptDetailBoardShowWipGdsRcptSummaryLoading,
  [GdsRcptDetailBoardTypes.GDS_RCPT_DETAIL_BOARD_SHOW_WIP_GDS_RCPT_SUMMARY_SUCCESS]: gdsRcptDetailBoardShowWipGdsRcptSummarySuccess,

  [GdsRcptDetailBoardTypes.GDS_RCPT_DETAIL_BOARD_SHOW_WIP_INB_ORD_SUMMARY]: gdsRcptDetailBoardShowWipInbOrdSummary,
  [GdsRcptDetailBoardTypes.GDS_RCPT_DETAIL_BOARD_SET_WIP_INB_ORD_DIALOG_VISIBLE]: gdsRcptDetailBoardSetWipInbOrdDialogVisible,
  [GdsRcptDetailBoardTypes.GDS_RCPT_DETAIL_BOARD_SHOW_WIP_INB_ORD_SUMMARY_LOADING]: gdsRcptDetailBoardShowWipInbOrdSummaryLoading,
  [GdsRcptDetailBoardTypes.GDS_RCPT_DETAIL_BOARD_SHOW_WIP_INB_ORD_SUMMARY_SUCCESS]: gdsRcptDetailBoardShowWipInbOrdSummarySuccess,

  [GdsRcptDetailBoardTypes.GDS_RCPT_DETAIL_BOARD_SHOW_COMPLETE_GDS_RCPT_SUMMARY]: gdsRcptDetailBoardShowCompleteGdsRcptSummary,
  [GdsRcptDetailBoardTypes.GDS_RCPT_DETAIL_BOARD_SET_COMPLETE_GDS_RCPT_DIALOG_VISIBLE]: gdsRcptDetailBoardSetCompleteGdsRcptDialogVisible,
  [GdsRcptDetailBoardTypes.GDS_RCPT_DETAIL_BOARD_SHOW_COMPLETE_GDS_RCPT_SUMMARY_LOADING]: gdsRcptDetailBoardShowCompleteGdsRcptSummaryLoading,
  [GdsRcptDetailBoardTypes.GDS_RCPT_DETAIL_BOARD_SHOW_COMPLETE_GDS_RCPT_SUMMARY_SUCCESS]: gdsRcptDetailBoardShowCompleteGdsRcptSummarySuccess
});
