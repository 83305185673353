/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { GdsRtnDetailReportTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const gdsRtnDetailReportReportLoading = (state, { boolean }) => ({
  ...state,
  reportIsLoading: boolean
});

export const gdsRtnDetailReportInitGdsRtnDetailSuccess = (state, { criteria }) => ({
  ...state,
  criteria
});

export const gdsRtnDetailReportGdsRtnDetailSuccess = (state, { criteria, reportData }) => ({
  ...state,
  criteria,
  reportData
});

export const gdsRtnDetailReportFetchDivisionOptionLoading = (state, { boolean }) => ({
  ...state,
  divisionIsLoading: boolean
});

export const gdsRtnDetailReportFetchDivisionOptionSuccess = (state, { options }) => ({
  ...state,
  divisionOptions: options
});

export const gdsRtnDetailReportFetchDeliveryPointOptionLoading = (state, { boolean }) => ({
  ...state,
  deliveryPointIsLoading: boolean
});

export const gdsRtnDetailReportFetchDeliveryPointOptionSuccess = (state, { options }) => ({
  ...state,
  deliveryPointOptions: options
});

export const gdsRtnDetailReportFetchDebtorOptionLoading = (state, { boolean }) => ({
  ...state,
  debtorIsLoading: boolean
});

export const gdsRtnDetailReportFetchDebtorOptionSuccess = (state, { options }) => ({
  ...state,
  debtorOptions: options
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [GdsRtnDetailReportTypes.GDS_RTN_DETAIL_REPORT_REPORT_LOADING]: gdsRtnDetailReportReportLoading,
  [GdsRtnDetailReportTypes.GDS_RTN_DETAIL_REPORT_INIT_GDS_RTN_DETAIL_SUCCESS]: gdsRtnDetailReportInitGdsRtnDetailSuccess,
  [GdsRtnDetailReportTypes.GDS_RTN_DETAIL_REPORT_GDS_RTN_DETAIL_SUCCESS]: gdsRtnDetailReportGdsRtnDetailSuccess,

  [GdsRtnDetailReportTypes.GDS_RTN_DETAIL_REPORT_FETCH_DIVISION_OPTION_LOADING]: gdsRtnDetailReportFetchDivisionOptionLoading,
  [GdsRtnDetailReportTypes.GDS_RTN_DETAIL_REPORT_FETCH_DIVISION_OPTION_SUCCESS]: gdsRtnDetailReportFetchDivisionOptionSuccess,

  [GdsRtnDetailReportTypes.GDS_RTN_DETAIL_REPORT_FETCH_DELIVERY_POINT_OPTION_LOADING]: gdsRtnDetailReportFetchDeliveryPointOptionLoading,
  [GdsRtnDetailReportTypes.GDS_RTN_DETAIL_REPORT_FETCH_DELIVERY_POINT_OPTION_SUCCESS]: gdsRtnDetailReportFetchDeliveryPointOptionSuccess,

  [GdsRtnDetailReportTypes.GDS_RTN_DETAIL_REPORT_FETCH_DEBTOR_OPTION_LOADING]: gdsRtnDetailReportFetchDebtorOptionLoading,
  [GdsRtnDetailReportTypes.GDS_RTN_DETAIL_REPORT_FETCH_DEBTOR_OPTION_SUCCESS]: gdsRtnDetailReportFetchDebtorOptionSuccess
});
