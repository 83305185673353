import { put, call, select, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import { replace, push } from 'connected-react-router';
import FileSaver from 'file-saver';
import ApiService from '../Services/ApiService';
import AppActions from '../Stores/App/Actions';
import DivisionDetailActions, { DivisionDetailTypes } from '../Stores/DivisionDetail/Actions';

const getAppStore = state => state.app;

export function* divisionDetailInitModel() {
  try {
    yield put(DivisionDetailActions.divisionDetailShowModelLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `division/initModel`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(DivisionDetailActions.divisionDetailShowModelSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(DivisionDetailActions.divisionDetailShowModelLoading(false));
  }
}

export function* divisionDetailShowModel({ resId }) {
  try {
    yield put(DivisionDetailActions.divisionDetailShowModelLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `division/showModel/${resId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(DivisionDetailActions.divisionDetailShowModelSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(DivisionDetailActions.divisionDetailShowModelLoading(false));
  }
}

export function* divisionDetailUpdateModel({ formikBag, model }) {
  formikBag.setSubmitting(true);
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: model
    };

    const result = yield call(
      ApiService.putApi, // function
      app.apiUrl,
      'division/updateModel',
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      const { model: retModel } = result.data;

      yield put(DivisionDetailActions.divisionDetailUpdateModelSuccess(retModel));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* divisionDetailCreateModel({ formikBag, model }) {
  formikBag.setSubmitting(true);
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: model
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `division/createModel`,
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      yield put(replace(`${app.appPath}/divisionDetail/update/${result.data}`));
      yield put(DivisionDetailActions.divisionDetailSetResId(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* divisionDetailFetchSiteFlowOptions({ search }) {
  try {
    yield put(DivisionDetailActions.divisionDetailFetchSiteFlowOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `siteFlow/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code}`
      }));

      yield put(DivisionDetailActions.divisionDetailFetchSiteFlowOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(DivisionDetailActions.divisionDetailFetchSiteFlowOptionLoading(false));
  }
}

export function* divisionDetailFetchCompanyOptions({ search }) {
  try {
    yield put(DivisionDetailActions.divisionDetailFetchCompanyOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `company/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code}`
      }));

      yield put(DivisionDetailActions.divisionDetailFetchCompanyOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(DivisionDetailActions.divisionDetailFetchCompanyOptionLoading(false));
  }
}

export function* divisionDetailShowInvTxnFlows({ resId }) {
  try {
    yield put(DivisionDetailActions.divisionDetailShowInvTxnFlowsLoading(true));

    let result = {
      isSuccess: true,
      data: []
    };

    if (resId > 0) {
      const app = yield select(getAppStore);
      const getData = {};

      result = yield call(
        ApiService.getApi, // function
        app.apiUrl,
        `invTxnFlow/showTxnFlows/${resId}`,
        app.token,
        getData,
        'multipart/form-data' // params
      );
    }

    if (result.isSuccess === true) {
      yield put(DivisionDetailActions.divisionDetailShowInvTxnFlowsSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(DivisionDetailActions.divisionDetailShowInvTxnFlowsLoading(false));
  }
}

export function* divisionDetailInvTxnFlowsUploadExcel({ resId, file }) {
  try {
    yield put(DivisionDetailActions.divisionDetailInvTxnFlowsUploadLoading(true));

    const app = yield select(getAppStore);

    // eslint-disable-next-line no-undef
    const postData = new FormData();
    postData.append('file', file);

    const getData = {};

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `invTxnFlow/uploadTxnFlows/${resId}`,
      app.token,
      postData,
      getData,
      'multipart/form-data'
    );

    if (result.isSuccess === true) {
      // result.data is total
      yield put(DivisionDetailActions.divisionDetailResetInvTxnFlowTimestamp());

      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_SUCCESS_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(DivisionDetailActions.divisionDetailInvTxnFlowsUploadLoading(false));
  }
}

export function* divisionDetailInvTxnFlowsDownloadExcel({ resId }) {
  try {
    yield put(DivisionDetailActions.divisionDetailInvTxnFlowsUploadLoading(true));

    const app = yield select(getAppStore);

    const getData = {};

    const result = yield call(
      ApiService.downloadGetApi, // function
      app.apiUrl,
      `invTxnFlow/downloadTxnFlows/${resId}`,
      app.token,
      getData,
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    );

    if (result.isSuccess === true) {
      FileSaver.saveAs(result.data, `INV_TXN_FLOW.XLSX`);
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(DivisionDetailActions.divisionDetailInvTxnFlowsUploadLoading(false));
  }
}

export function* divisionDetailShowPrintDocSettings({ resId }) {
  try {
    yield put(DivisionDetailActions.divisionDetailShowPrintDocSettingsLoading(true));

    let result = {
      isSuccess: true,
      data: []
    };

    if (resId > 0) {
      const app = yield select(getAppStore);
      const getData = {};

      result = yield call(
        ApiService.getApi, // function
        app.apiUrl,
        `printDocSetting/showDivisionSettings/${resId}`,
        app.token,
        getData,
        'multipart/form-data' // params
      );
    }

    if (result.isSuccess === true) {
      yield put(DivisionDetailActions.divisionDetailShowPrintDocSettingsSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(DivisionDetailActions.divisionDetailShowPrintDocSettingsLoading(false));
  }
}

export function* divisionDetailPrintDocSettingsUploadExcel({ resId, file }) {
  try {
    yield put(DivisionDetailActions.divisionDetailPrintDocSettingsUploadLoading(true));

    const app = yield select(getAppStore);

    // eslint-disable-next-line no-undef
    const postData = new FormData();
    postData.append('file', file);

    const getData = {};

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `printDocSetting/uploadDivisionSettings/${resId}`,
      app.token,
      postData,
      getData,
      'multipart/form-data'
    );

    if (result.isSuccess === true) {
      // result.data is total
      yield put(DivisionDetailActions.divisionDetailResetPrintDocSettingTimestamp());

      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_SUCCESS_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(DivisionDetailActions.divisionDetailPrintDocSettingsUploadLoading(false));
  }
}

export function* divisionDetailPrintDocSettingsDownloadExcel({ resId }) {
  try {
    yield put(DivisionDetailActions.divisionDetailPrintDocSettingsUploadLoading(true));

    const app = yield select(getAppStore);

    const getData = {};

    const result = yield call(
      ApiService.downloadGetApi, // function
      app.apiUrl,
      `printDocSetting/downloadDivisionSettings/${resId}`,
      app.token,
      getData,
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    );

    if (result.isSuccess === true) {
      FileSaver.saveAs(result.data, `DIVISION_PRINT_DOC_SETTING.XLSX`);
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(DivisionDetailActions.divisionDetailPrintDocSettingsUploadLoading(false));
  }
}

export function* divisionDetailShowUserDivisions({ resId }) {
  try {
    yield put(DivisionDetailActions.divisionDetailShowUserDivisionsLoading(true));

    let result = {
      isSuccess: true,
      data: []
    };

    if (resId > 0) {
      const app = yield select(getAppStore);
      const getData = {};

      result = yield call(
        ApiService.getApi, // function
        app.apiUrl,
        `userDivision/showUsers/${resId}`,
        app.token,
        getData,
        'multipart/form-data' // params
      );
    }

    if (result.isSuccess === true) {
      yield put(DivisionDetailActions.divisionDetailShowUserDivisionsSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(DivisionDetailActions.divisionDetailShowUserDivisionsLoading(false));
  }
}

export function* divisionDetailUserDivisionsUploadExcel({ resId, file }) {
  try {
    yield put(DivisionDetailActions.divisionDetailUserDivisionsUploadLoading(true));

    const app = yield select(getAppStore);

    // eslint-disable-next-line no-undef
    const postData = new FormData();
    postData.append('file', file);

    const getData = {};

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `userDivision/uploadUsers/${resId}`,
      app.token,
      postData,
      getData,
      'multipart/form-data'
    );

    if (result.isSuccess === true) {
      // result.data is total
      yield put(DivisionDetailActions.divisionDetailResetUserDivisionTimestamp());

      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_SUCCESS_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(DivisionDetailActions.divisionDetailUserDivisionsUploadLoading(false));
  }
}

export function* divisionDetailUserDivisionsDownloadExcel({ resId }) {
  try {
    yield put(DivisionDetailActions.divisionDetailUserDivisionsUploadLoading(true));

    const app = yield select(getAppStore);

    const getData = {};

    const result = yield call(
      ApiService.downloadGetApi, // function
      app.apiUrl,
      `userDivision/downloadUsers/${resId}`,
      app.token,
      getData,
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    );

    if (result.isSuccess === true) {
      FileSaver.saveAs(result.data, `USER_DIVISION.XLSX`);
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(DivisionDetailActions.divisionDetailUserDivisionsUploadLoading(false));
  }
}

export function* divisionDetailFetchDocNoOptions({ docType, search }) {
  try {
    if (docType === 'SlsOrdHdr') {
      yield put(DivisionDetailActions.divisionDetailFetchSlsOrdDocNoOptionLoading(true));
    } else if (docType === 'SlsRtnHdr') {
      yield put(DivisionDetailActions.divisionDetailFetchSlsRtnDocNoOptionLoading(true));
    } else if (docType === 'DelOrdHdr') {
      yield put(DivisionDetailActions.divisionDetailFetchDelOrdDocNoOptionLoading(true));
    } else if (docType === 'SlsInvHdr') {
      yield put(DivisionDetailActions.divisionDetailFetchSlsInvDocNoOptionLoading(true));
    } else if (docType === 'RtnRcptHdr') {
      yield put(DivisionDetailActions.divisionDetailFetchRtnRcptDocNoOptionLoading(true));
    } else if (docType === 'AdvShipHdr') {
      yield put(DivisionDetailActions.divisionDetailFetchAdvShipDocNoOptionLoading(true));
    } else if (docType === 'PurInvHdr') {
      yield put(DivisionDetailActions.divisionDetailFetchPurInvDocNoOptionLoading(true));
    } else if (docType === 'PurRtnHdr') {
      yield put(DivisionDetailActions.divisionDetailFetchPurRtnDocNoOptionLoading(true));
    } else if (docType === 'LspSlsOrdHdr') {
      yield put(DivisionDetailActions.divisionDetailFetchLspSlsOrdDocNoOptionLoading(true));
    } else if (docType === 'LspSlsRtnHdr') {
      yield put(DivisionDetailActions.divisionDetailFetchLspSlsRtnDocNoOptionLoading(true));
    } else if (docType === 'LspAdvShipHdr') {
      yield put(DivisionDetailActions.divisionDetailFetchLspAdvShipDocNoOptionLoading(true));
    } else if (docType === 'LspDelOrdHdr') {
      yield put(DivisionDetailActions.divisionDetailFetchLspDelOrdDocNoOptionLoading(true));
    } else if (docType === 'ProdOrdHdr') {
      yield put(DivisionDetailActions.divisionDetailFetchProdOrdDocNoOptionLoading(true));
    } else if (docType === 'ProdIssHdr') {
      yield put(DivisionDetailActions.divisionDetailFetchProdIssDocNoOptionLoading(true));
    } else if (docType === 'ProdRcptHdr') {
      yield put(DivisionDetailActions.divisionDetailFetchProdRcptDocNoOptionLoading(true));
    } else if (docType === 'ProdSheetHdr') {
      yield put(DivisionDetailActions.divisionDetailFetchProdSheetDocNoOptionLoading(true));
    } else if (docType === 'OutbOrdHdr') {
      yield put(DivisionDetailActions.divisionDetailFetchOutbOrdDocNoOptionLoading(true));
    } else if (docType === 'InbOrdHdr') {
      yield put(DivisionDetailActions.divisionDetailFetchInbOrdDocNoOptionLoading(true));
    } else if (docType === 'TrfOrdHdr') {
      yield put(DivisionDetailActions.divisionDetailFetchTrfOrdDocNoOptionLoading(true));
    } else if (docType === 'StkIssHdr') {
      yield put(DivisionDetailActions.divisionDetailFetchStkIssDocNoOptionLoading(true));
    } else if (docType === 'StkRcptHdr') {
      yield put(DivisionDetailActions.divisionDetailFetchStkRcptDocNoOptionLoading(true));
    } else if (docType === 'StkTrfHdr') {
      yield put(DivisionDetailActions.divisionDetailFetchStkTrfDocNoOptionLoading(true));
    }

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: [
        {
          field: 'doc_type',
          value: docType
        }
      ]
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `docNo/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => {
        const divisionCodes = d.divisions.reduce((lastStr, data) => {
          let newStr = '';
          if (lastStr.length > 0) {
            newStr = `${lastStr}/${data.code}`;
          } else {
            newStr = data.code;
          }
          return newStr;
        }, '');

        return { value: d.id, label: `${d.doc_code} ${divisionCodes}` };
      });

      if (docType === 'SlsOrdHdr') {
        yield put(DivisionDetailActions.divisionDetailFetchSlsOrdDocNoOptionSuccess(options));
      } else if (docType === 'SlsRtnHdr') {
        yield put(DivisionDetailActions.divisionDetailFetchSlsRtnDocNoOptionSuccess(options));
      } else if (docType === 'DelOrdHdr') {
        yield put(DivisionDetailActions.divisionDetailFetchDelOrdDocNoOptionSuccess(options));
      } else if (docType === 'SlsInvHdr') {
        yield put(DivisionDetailActions.divisionDetailFetchSlsInvDocNoOptionSuccess(options));
      } else if (docType === 'RtnRcptHdr') {
        yield put(DivisionDetailActions.divisionDetailFetchRtnRcptDocNoOptionSuccess(options));
      } else if (docType === 'AdvShipHdr') {
        yield put(DivisionDetailActions.divisionDetailFetchAdvShipDocNoOptionSuccess(options));
      } else if (docType === 'PurInvHdr') {
        yield put(DivisionDetailActions.divisionDetailFetchPurInvDocNoOptionSuccess(options));
      } else if (docType === 'PurRtnHdr') {
        yield put(DivisionDetailActions.divisionDetailFetchPurRtnDocNoOptionSuccess(options));
      } else if (docType === 'LspSlsOrdHdr') {
        yield put(DivisionDetailActions.divisionDetailFetchLspSlsOrdDocNoOptionSuccess(options));
      } else if (docType === 'LspSlsRtnHdr') {
        yield put(DivisionDetailActions.divisionDetailFetchLspSlsRtnDocNoOptionSuccess(options));
      } else if (docType === 'LspAdvShipHdr') {
        yield put(DivisionDetailActions.divisionDetailFetchLspAdvShipDocNoOptionSuccess(options));
      } else if (docType === 'LspDelOrdHdr') {
        yield put(DivisionDetailActions.divisionDetailFetchLspDelOrdDocNoOptionSuccess(options));
      } else if (docType === 'ProdOrdHdr') {
        yield put(DivisionDetailActions.divisionDetailFetchProdOrdDocNoOptionSuccess(options));
      } else if (docType === 'ProdIssHdr') {
        yield put(DivisionDetailActions.divisionDetailFetchProdIssDocNoOptionSuccess(options));
      } else if (docType === 'ProdRcptHdr') {
        yield put(DivisionDetailActions.divisionDetailFetchProdRcptDocNoOptionSuccess(options));
      } else if (docType === 'ProdSheetHdr') {
        yield put(DivisionDetailActions.divisionDetailFetchProdSheetDocNoOptionSuccess(options));
      } else if (docType === 'OutbOrdHdr') {
        yield put(DivisionDetailActions.divisionDetailFetchOutbOrdDocNoOptionSuccess(options));
      } else if (docType === 'InbOrdHdr') {
        yield put(DivisionDetailActions.divisionDetailFetchInbOrdDocNoOptionSuccess(options));
      } else if (docType === 'TrfOrdHdr') {
        yield put(DivisionDetailActions.divisionDetailFetchTrfOrdDocNoOptionSuccess(options));
      } else if (docType === 'StkIssHdr') {
        yield put(DivisionDetailActions.divisionDetailFetchStkIssDocNoOptionSuccess(options));
      } else if (docType === 'StkRcptHdr') {
        yield put(DivisionDetailActions.divisionDetailFetchStkRcptDocNoOptionSuccess(options));
      } else if (docType === 'StkTrfHdr') {
        yield put(DivisionDetailActions.divisionDetailFetchStkTrfDocNoOptionSuccess(options));
      } else if (docType === 'WorkOrdHdr') {
        yield put(DivisionDetailActions.divisionDetailFetchWorkOrdDocNoOptionSuccess(options));
      }
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    if (docType === 'SlsOrdHdr') {
      yield put(DivisionDetailActions.divisionDetailFetchSlsOrdDocNoOptionLoading(false));
    } else if (docType === 'SlsRtnHdr') {
      yield put(DivisionDetailActions.divisionDetailFetchSlsRtnDocNoOptionLoading(false));
    } else if (docType === 'DelOrdHdr') {
      yield put(DivisionDetailActions.divisionDetailFetchDelOrdDocNoOptionLoading(false));
    } else if (docType === 'SlsInvHdr') {
      yield put(DivisionDetailActions.divisionDetailFetchSlsInvDocNoOptionLoading(false));
    } else if (docType === 'RtnRcptHdr') {
      yield put(DivisionDetailActions.divisionDetailFetchRtnRcptDocNoOptionLoading(false));
    } else if (docType === 'AdvShipHdr') {
      yield put(DivisionDetailActions.divisionDetailFetchAdvShipDocNoOptionLoading(false));
    } else if (docType === 'PurInvHdr') {
      yield put(DivisionDetailActions.divisionDetailFetchPurInvDocNoOptionLoading(false));
    } else if (docType === 'PurRtnHdr') {
      yield put(DivisionDetailActions.divisionDetailFetchPurRtnDocNoOptionLoading(false));
    } else if (docType === 'LspSlsOrdHdr') {
      yield put(DivisionDetailActions.divisionDetailFetchLspSlsOrdDocNoOptionLoading(false));
    } else if (docType === 'LspSlsRtnHdr') {
      yield put(DivisionDetailActions.divisionDetailFetchLspSlsRtnDocNoOptionLoading(false));
    } else if (docType === 'LspAdvShipHdr') {
      yield put(DivisionDetailActions.divisionDetailFetchLspAdvShipDocNoOptionLoading(false));
    } else if (docType === 'LspDelOrdHdr') {
      yield put(DivisionDetailActions.divisionDetailFetchLspDelOrdDocNoOptionLoading(false));
    } else if (docType === 'ProdOrdHdr') {
      yield put(DivisionDetailActions.divisionDetailFetchProdOrdDocNoOptionLoading(false));
    } else if (docType === 'ProdIssHdr') {
      yield put(DivisionDetailActions.divisionDetailFetchProdIssDocNoOptionLoading(false));
    } else if (docType === 'ProdRcptHdr') {
      yield put(DivisionDetailActions.divisionDetailFetchProdRcptDocNoOptionLoading(false));
    } else if (docType === 'ProdSheetHdr') {
      yield put(DivisionDetailActions.divisionDetailFetchProdSheetDocNoOptionLoading(false));
    } else if (docType === 'OutbOrdHdr') {
      yield put(DivisionDetailActions.divisionDetailFetchOutbOrdDocNoOptionLoading(false));
    } else if (docType === 'InbOrdHdr') {
      yield put(DivisionDetailActions.divisionDetailFetchInbOrdDocNoOptionLoading(false));
    } else if (docType === 'TrfOrdHdr') {
      yield put(DivisionDetailActions.divisionDetailFetchTrfOrdDocNoOptionLoading(false));
    } else if (docType === 'StkIssHdr') {
      yield put(DivisionDetailActions.divisionDetailFetchStkIssDocNoOptionLoading(false));
    } else if (docType === 'StkRcptHdr') {
      yield put(DivisionDetailActions.divisionDetailFetchStkRcptDocNoOptionLoading(false));
    } else if (docType === 'StkTrfHdr') {
      yield put(DivisionDetailActions.divisionDetailFetchStkTrfDocNoOptionLoading(false));
    } else if (docType === 'WorkOrdHdr') {
      yield put(DivisionDetailActions.divisionDetailFetchWorkOrdDocNoOptionLoading(false));
    }
  }
}

export function* divisionDetailGoToDocTypeDocNo({ docType }) {
  const app = yield select(getAppStore);
  yield put(push(`${app.appPath}/docTypeDocNoIndex/${docType}`));
}

export const saga = [
  takeLatest(DivisionDetailTypes.DIVISION_DETAIL_INIT_MODEL, divisionDetailInitModel),
  takeLatest(DivisionDetailTypes.DIVISION_DETAIL_SHOW_MODEL, divisionDetailShowModel),

  takeLatest(DivisionDetailTypes.DIVISION_DETAIL_UPDATE_MODEL, divisionDetailUpdateModel),
  takeLatest(DivisionDetailTypes.DIVISION_DETAIL_CREATE_MODEL, divisionDetailCreateModel),

  takeLatest(
    DivisionDetailTypes.DIVISION_DETAIL_FETCH_SITE_FLOW_OPTIONS,
    divisionDetailFetchSiteFlowOptions
  ),

  takeLatest(
    DivisionDetailTypes.DIVISION_DETAIL_FETCH_COMPANY_OPTIONS,
    divisionDetailFetchCompanyOptions
  ),

  takeLatest(DivisionDetailTypes.DIVISION_DETAIL_SHOW_INV_TXN_FLOWS, divisionDetailShowInvTxnFlows),
  takeLatest(
    DivisionDetailTypes.DIVISION_DETAIL_INV_TXN_FLOWS_UPLOAD_EXCEL,
    divisionDetailInvTxnFlowsUploadExcel
  ),
  takeLatest(
    DivisionDetailTypes.DIVISION_DETAIL_INV_TXN_FLOWS_DOWNLOAD_EXCEL,
    divisionDetailInvTxnFlowsDownloadExcel
  ),

  takeLatest(
    DivisionDetailTypes.DIVISION_DETAIL_SHOW_PRINT_DOC_SETTINGS,
    divisionDetailShowPrintDocSettings
  ),
  takeLatest(
    DivisionDetailTypes.DIVISION_DETAIL_PRINT_DOC_SETTINGS_UPLOAD_EXCEL,
    divisionDetailPrintDocSettingsUploadExcel
  ),
  takeLatest(
    DivisionDetailTypes.DIVISION_DETAIL_PRINT_DOC_SETTINGS_DOWNLOAD_EXCEL,
    divisionDetailPrintDocSettingsDownloadExcel
  ),

  takeLatest(
    DivisionDetailTypes.DIVISION_DETAIL_SHOW_USER_DIVISIONS,
    divisionDetailShowUserDivisions
  ),
  takeLatest(
    DivisionDetailTypes.DIVISION_DETAIL_USER_DIVISIONS_UPLOAD_EXCEL,
    divisionDetailUserDivisionsUploadExcel
  ),
  takeLatest(
    DivisionDetailTypes.DIVISION_DETAIL_USER_DIVISIONS_DOWNLOAD_EXCEL,
    divisionDetailUserDivisionsDownloadExcel
  ),

  takeLatest(
    DivisionDetailTypes.DIVISION_DETAIL_FETCH_DOC_NO_OPTIONS,
    divisionDetailFetchDocNoOptions
  ),

  takeLatest(
    DivisionDetailTypes.DIVISION_DETAIL_GO_TO_DOC_TYPE_DOC_NO,
    divisionDetailGoToDocTypeDocNo
  )
];
