import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import treeChanges from 'tree-changes';

import { Card, Tabs } from 'antd';

import AuditRes01Actions from '../../Stores/AuditRes01/Actions';

import AuditRes01Table from './AuditRes01Table';

const { TabPane } = Tabs;

class AuditRes01Screen extends React.PureComponent {
  componentDidMount() {
    const { match, setResType } = this.props;
    setResType(match.params.res_type, match.params.res_id, match.params.audit_type);
  }

  componentDidUpdate(prevProps) {
    const { match, fetchAuditTypes, setResType } = this.props;

    const { changed } = treeChanges(prevProps, this.props);

    setResType(match.params.res_type, match.params.res_id, match.params.audit_type);

    if (changed('resType')) {
      fetchAuditTypes();
    }
  }

  componentWillUnmount() {}

  render() {
    const { intl, match, resType, resId, auditTypes, appPath } = this.props;

    return (
      <Card
        title={intl.formatMessage({
          id: 'audit_trail'
        })}
      >
        <Tabs type="card" activeKey={match.params.audit_type}>
          {auditTypes.map(value => (
            <TabPane
              tab={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <Link
                  style={value.audit_type !== match.params.audit_type ? { color: 'black' } : {}}
                  to={`${appPath}/auditResource/${resType}/${resId}/${value.audit_type}`}
                  replace
                >
                  {value.audit_type}
                </Link>
              }
              key={value.audit_type}
            />
          ))}
        </Tabs>
        <AuditRes01Table match={match} />
      </Card>
    );
  }
}

AuditRes01Screen.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object,
  appPath: PropTypes.string,

  resType: PropTypes.string,
  resId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  auditTypes: PropTypes.arrayOf(PropTypes.object),

  setResType: PropTypes.func,
  fetchAuditTypes: PropTypes.func
};

AuditRes01Screen.defaultProps = {
  intl: {},
  match: {},
  appPath: '',

  resType: '',
  resId: 0,
  auditTypes: [],

  setResType() {},
  fetchAuditTypes() {}
};

const mapStateToProps = state => ({
  appPath: state.app.appPath,
  auditTypes: state.auditRes01.auditTypes,
  auditTypeIsLoading: state.auditRes01.auditTypeIsLoading,

  resType: state.auditRes01.resType,
  resId: state.auditRes01.resId
});

const mapDispatchToProps = dispatch => ({
  setResType: (resType, resId, auditType) =>
    dispatch(AuditRes01Actions.auditRes01SetResType(resType, resId, auditType)),
  fetchAuditTypes: () => dispatch(AuditRes01Actions.auditRes01FetchAuditTypes())
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AuditRes01Screen));
