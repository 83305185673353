/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { CompanyList01Types } from './Actions';
import INITIAL_STATE from './InitialState';

export const companyList01ResetTimestamp = (state, { currentPage, sorts, filters, pageSize }) => ({
  ...state,
  currentPage: currentPage || state.currentPage,
  sorts: sorts || state.sorts,
  filters: filters || state.filters,
  pageSize: pageSize || state.pageSize,
  timestamp: Date.now()
  // selectedDocuments: INITIAL_STATE.selectedDocuments
});

export const companyList01FetchCompanyList01Loading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const companyList01FetchCompanyList01Success = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  documents,
  currentPage,
  lastPage,
  total,
  pageSize
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [CompanyList01Types.COMPANY_LIST01_RESET_TIMESTAMP]: companyList01ResetTimestamp,
  [CompanyList01Types.COMPANY_LIST01_FETCH_COMPANY_LIST01_LOADING]: companyList01FetchCompanyList01Loading,
  [CompanyList01Types.COMPANY_LIST01_FETCH_COMPANY_LIST01_SUCCESS]: companyList01FetchCompanyList01Success
});
