import { put, call, select, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import { push } from 'connected-react-router';
import FileSaver from 'file-saver';
import ApiService from '../Services/ApiService';
import AppActions from '../Stores/App/Actions';
import PickList01Actions, { PickList01Types } from '../Stores/PickList01/Actions';

const getAppStore = state => state.app;

export function* pickList01FetchPickList01({ currentPage, sorts, filters, pageSize }) {
  try {
    yield put(PickList01Actions.pickList01FetchPickList01Loading(true));

    const processedSorts = [];
    Object.entries(sorts).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      processedSorts.push(`${key}:${value === 'ascend' ? 'ASC' : 'DESC'}`);
    });

    const processedFilters = [];
    Object.entries(filters).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      if (value) {
        processedFilters.push(`${key}:${value}`);
      }
    });

    const app = yield select(getAppStore);
    const getData = {
      page: currentPage,
      sorts: processedSorts,
      filters: processedFilters,
      pageSize
    };

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `pickList/indexProcess/PICK_LIST_01/${app.curSiteFlowId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      // if nowCurrentPage is more than lastPage, then nowCurrentPage = lastPage
      let nowCurrentPage = result.data.current_page;
      if (nowCurrentPage > result.data.last_page) {
        nowCurrentPage = result.data.last_page;
      }

      yield put(
        PickList01Actions.pickList01FetchPickList01Success(
          result.data.data,
          nowCurrentPage,
          result.data.last_page,
          result.data.total,
          result.data.per_page
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(PickList01Actions.pickList01FetchPickList01Loading(false));
  }
}

export function* pickList01PrintPickList01({ hdrIds }) {
  try {
    yield put(PickList01Actions.pickList01CreatePickList01Loading(true));

    const app = yield select(getAppStore);

    const postData = {
      hdrIds
    };

    const result = yield call(
      ApiService.downloadGetApi, // function
      app.apiUrl,
      `pickList/printProcess/PICK_LIST_01_02/${app.curSiteFlowId}`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      FileSaver.saveAs(result.data, 'PickList0102.pdf');
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(PickList01Actions.pickList01CreatePickList01Loading(false));
  }
}

export function* pickList01CreatePickList01({ hdrIds, toStorageBinId }) {
  try {
    yield put(PickList01Actions.pickList01CreatePickList01Loading(true));

    const app = yield select(getAppStore);

    const postData = {
      hdrIds,
      toStorageBinId
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `pickList/createProcess/PICK_LIST_01`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      yield put(PickList01Actions.pickList01CreatePickList01Success(result.data));

      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_SUCCESS_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(PickList01Actions.pickList01CreatePickList01Loading(false));
  }
}

export function* pickList01FetchToStorageBinOptions({ search }) {
  try {
    yield put(PickList01Actions.pickList01FetchToStorageBinOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId: app.curSiteFlowId,
      search,
      filters: [
        {
          field: 'bin_type',
          value: 2 // LOADING_AREA
        }
      ]
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `storageBin/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({ value: d.id, label: `${d.code} ${d.desc_01}` }));

      yield put(PickList01Actions.pickList01FetchToStorageBinOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(PickList01Actions.pickList01FetchToStorageBinOptionLoading(false));
  }
}

export function* pickList01GoToDocument({ hdrId }) {
  const app = yield select(getAppStore);
  yield put(push(`${app.appPath}/outbOrdDetail/update/${hdrId}`));
}

export const saga = [
  takeLatest(PickList01Types.PICK_LIST01_GO_TO_DOCUMENT, pickList01GoToDocument),
  takeLatest(PickList01Types.PICK_LIST01_FETCH_PICK_LIST01, pickList01FetchPickList01),
  takeLatest(
    PickList01Types.PICK_LIST01_FETCH_TO_STORAGE_BIN_OPTIONS,
    pickList01FetchToStorageBinOptions
  ),
  takeLatest(PickList01Types.PICK_LIST01_PRINT_PICK_LIST01, pickList01PrintPickList01),
  takeLatest(PickList01Types.PICK_LIST01_CREATE_PICK_LIST01, pickList01CreatePickList01)
];
