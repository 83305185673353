import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';

import { Select } from 'antd';

import AppActions from '../../Stores/App/Actions';

const { Option } = Select;

class SiteFlowSelect extends React.PureComponent {
  constructor() {
    super();

    this.handleSelectChange = this.handleSelectChange.bind(this);
  }

  componentDidMount() {
    const { fetchSiteFlowOptions } = this.props;

    fetchSiteFlowOptions();
  }

  componentDidUpdate() {}

  componentWillUnmount() {}

  handleSelectChange(value) {
    const { changeSiteFlow } = this.props;

    changeSiteFlow(value);
  }

  render() {
    const { curSiteFlowId, siteFlowOptions, siteFlowIsLoading } = this.props;

    const processedOptions = siteFlowOptions.map(d => (
      <Option value={d.value} key={d.value}>{`${d.label}`}</Option>
    ));

    return (
      <Select
        mode="default"
        showSearch={false}
        style={{ width: 80 }}
        value={curSiteFlowId}
        loading={siteFlowIsLoading}
        onChange={this.handleSelectChange}
      >
        {processedOptions}
      </Select>
    );
  }
}

SiteFlowSelect.propTypes = {
  fetchSiteFlowOptions: PropTypes.func,
  changeSiteFlow: PropTypes.func,
  siteFlowIsLoading: PropTypes.bool,
  siteFlowOptions: PropTypes.arrayOf(PropTypes.object),

  curSiteFlowId: PropTypes.number
};

SiteFlowSelect.defaultProps = {
  fetchSiteFlowOptions() {},
  changeSiteFlow() {},
  siteFlowIsLoading: false,
  siteFlowOptions: [],

  curSiteFlowId: 0
};

const mapStateToProps = state => ({
  curSiteFlowId: state.app.curSiteFlowId,

  siteFlowIsLoading: state.app.siteFlowIsLoading,
  siteFlowOptions: state.app.siteFlowOptions
});

const mapDispatchToProps = dispatch => ({
  fetchSiteFlowOptions: () => dispatch(AppActions.appFetchSiteFlowOptions()),
  changeSiteFlow: siteFlowId => dispatch(AppActions.appChangeSiteFlow(siteFlowId))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SiteFlowSelect));
