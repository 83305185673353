import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import treeChanges from 'tree-changes';

import { Card, Tabs, Tooltip, Spin } from 'antd';

import GdsDelProcessActions from '../../Stores/GdsDelProcess/Actions';

import PickList01Table from '../PickList01/PickList01Table';
import WhseJob0301Table from '../WhseJob0301/WhseJob0301Table';
import WhseJob030102Table from '../WhseJob030102/WhseJob030102Table';
import InvDoc01Table from '../InvDoc01/InvDoc01Table';
import InvDoc0101Table from '../InvDoc0101/InvDoc0101Table';
import InvDoc0102Table from '../InvDoc0102/InvDoc0102Table';
import LoadList01Pane from '../LoadList01/LoadList01Pane';
import LoadList0101Table from '../LoadList0101/LoadList0101Table';
import PrfDel01Pane from '../PrfDel01/PrfDel01Pane';
import FailDel01Pane from '../FailDel01/FailDel01Pane';
import PickList0101Table from '../PickList0101/PickList0101Table';
import WhseJob0601Table from '../WhseJob0601/WhseJob0601Table';
import WhseJob0602Table from '../WhseJob0602/WhseJob0602Table';
import WhseJob060101Table from '../WhseJob060101/WhseJob060101Table';
import WhseJob060201Table from '../WhseJob060201/WhseJob060201Table';
import LoadListSync01Table from '../LoadListSync01/LoadListSync01Table';
import PrfDelSync01Table from '../PrfDelSync01/PrfDelSync01Table';

const { TabPane } = Tabs;

class GdsDelProcessScreen extends React.PureComponent {
  componentDidMount() {
    const { curSiteFlowId, fetchGdsDelProcess } = this.props;
    if (curSiteFlowId > 0) {
      fetchGdsDelProcess();
    }
  }

  componentDidUpdate(prevProps) {
    const { curSiteFlowId, fetchGdsDelProcess } = this.props;

    const { changed } = treeChanges(prevProps, this.props);

    if (changed('curSiteFlowId')) {
      if (curSiteFlowId > 0) {
        fetchGdsDelProcess();
      }
    }
  }

  componentWillUnmount() {}

  render() {
    const { intl, match, process, appPath, processIsLoading } = this.props;

    return (
      <Card>
        <Spin spinning={processIsLoading}>
          <Tabs type="card" activeKey={match.params.proc_type}>
            {process.map(value => (
              <TabPane
                tab={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <Tooltip
                    placement="bottom"
                    title={intl.formatMessage({ id: `${value.proc_type.toLowerCase()}_desc` })}
                  >
                    <Link
                      style={value.proc_type !== match.params.proc_type ? { color: 'black' } : {}}
                      to={`${appPath}/gdsDelProcess/index/${value.proc_type}`}
                    >
                      <FormattedMessage id={`${value.proc_type.toLowerCase()}_label`} />
                    </Link>
                  </Tooltip>
                }
                key={value.proc_type}
              />
            ))}
          </Tabs>
        </Spin>

        {match.params.proc_type === 'PICK_LIST_01' && <PickList01Table match={match} />}
        {match.params.proc_type === 'WHSE_JOB_03_01' && <WhseJob0301Table match={match} />}
        {match.params.proc_type === 'WHSE_JOB_03_01_02' && <WhseJob030102Table match={match} />}
        {match.params.proc_type === 'INV_DOC_01' && <InvDoc01Table match={match} />}
        {match.params.proc_type === 'INV_DOC_01_01' && <InvDoc0101Table match={match} />}
        {match.params.proc_type === 'INV_DOC_01_02' && <InvDoc0102Table match={match} />}
        {match.params.proc_type === 'LOAD_LIST_01' && <LoadList01Pane match={match} />}
        {match.params.proc_type === 'LOAD_LIST_01_01' && <LoadList0101Table match={match} />}
        {match.params.proc_type === 'PRF_DEL_01' && <PrfDel01Pane match={match} />}
        {match.params.proc_type === 'FAIL_DEL_01' && <FailDel01Pane match={match} />}
        {match.params.proc_type === 'PICK_LIST_01_01' && <PickList0101Table match={match} />}
        {match.params.proc_type === 'WHSE_JOB_06_01' && <WhseJob0601Table match={match} />}
        {match.params.proc_type === 'WHSE_JOB_06_01_01' && <WhseJob060101Table match={match} />}
        {match.params.proc_type === 'WHSE_JOB_06_02' && <WhseJob0602Table match={match} />}
        {match.params.proc_type === 'WHSE_JOB_06_02_01' && <WhseJob060201Table match={match} />}
        {match.params.proc_type === 'LOAD_LIST_SYNC_01' && <LoadListSync01Table match={match} />}
        {match.params.proc_type === 'PRF_DEL_SYNC_01' && <PrfDelSync01Table match={match} />}
      </Card>
    );
  }
}

GdsDelProcessScreen.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object,
  appPath: PropTypes.string,
  process: PropTypes.arrayOf(PropTypes.object),
  processIsLoading: PropTypes.bool,

  curSiteFlowId: PropTypes.number,
  fetchGdsDelProcess: PropTypes.func
};

GdsDelProcessScreen.defaultProps = {
  intl: {},
  match: {},
  appPath: '',
  process: [],
  processIsLoading: false,

  curSiteFlowId: 0,
  fetchGdsDelProcess() {}
};

const mapStateToProps = state => ({
  appPath: state.app.appPath,
  process: state.gdsDelProcess.process,
  processIsLoading: state.gdsDelProcess.processIsLoading,

  curSiteFlowId: state.app.curSiteFlowId
});

const mapDispatchToProps = dispatch => ({
  fetchGdsDelProcess: () => dispatch(GdsDelProcessActions.gdsDelProcessFetchGdsDelProcess())
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(GdsDelProcessScreen));
