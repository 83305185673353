/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { ProdOrdDetailTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const prodOrdDetailResetTimestamp = state => ({
  ...state,
  timestamp: Date.now()
});

export const prodOrdDetailSetHdrId = (state, { hdrId, itemId }) => ({
  ...state,
  timestamp: Date.now(),
  documentIsLoading: false,
  hdrId,
  itemId,
  detailIsVisible: false,
  salesmanOptions: [],
  deliveryPointOptions: [],
  creditTermOptions: [],
  currencyOptions: []
});

export const prodOrdDetailShowDocumentLoading = (state, { boolean }) => ({
  ...state,
  documentIsLoading: boolean
});

export const prodOrdDetailShowHeaderSuccess = (state, { documentHeader }) => ({
  ...state,
  documentHeader
});

export const prodOrdDetailUpdateDocumentSuccess = (state, { documentHeader, documentDetails }) => ({
  ...state,
  documentHeader,
  documentDetails
});

export const prodOrdDetailShowDetailsSuccess = (state, { documentDetails }) => ({
  ...state,
  documentDetails
});

export const prodOrdDetailSetDetailVisible = (state, { boolean }) => ({
  ...state,
  detailIsVisible: boolean
});

export const prodOrdDetailSetDocumentDetail = (state, { documentDetail }) => ({
  ...state,
  documentDetail,
  dtlItemOptions: [],
  dtlItemBatchOptions: [],
  dtlUomOptions: []
});

export const prodOrdDetailFetchCurrencyOptionLoading = (state, { boolean }) => ({
  ...state,
  currencyIsLoading: boolean
});

export const prodOrdDetailFetchCurrencyOptionSuccess = (state, { options }) => ({
  ...state,
  currencyOptions: options
});

// force refresh the formik form
export const prodOrdDetailUpdateDetails = (state, { documentDetails }) => ({
  ...state,
  documentDetail: documentDetails[0]
});

// force refresh the formik form
export const prodOrdDetailCreateDetail = (state, { documentDetail }) => ({
  ...state,
  documentDetail
});

export const prodOrdDetailFetchProdStorageBinOptionLoading = (state, { boolean }) => ({
  ...state,
  prodStorageBinIsLoading: boolean
});

export const prodOrdDetailFetchProdStorageBinOptionSuccess = (state, { options }) => ({
  ...state,
  prodStorageBinOptions: options
});

export const prodOrdDetailFetchDtlItemOptionLoading = (state, { boolean }) => ({
  ...state,
  dtlItemIsLoading: boolean
});

export const prodOrdDetailFetchDtlItemOptionSuccess = (state, { options }) => ({
  ...state,
  dtlItemOptions: options
});

export const prodOrdDetailFetchDtlItemBatchOptionLoading = (state, { boolean }) => ({
  ...state,
  dtlItemBatchIsLoading: boolean
});

export const prodOrdDetailFetchDtlItemBatchOptionSuccess = (state, { options }) => ({
  ...state,
  dtlItemBatchOptions: options
});

export const prodOrdDetailFetchDtlUomOptionLoading = (state, { boolean }) => ({
  ...state,
  dtlUomIsLoading: boolean
});

export const prodOrdDetailFetchDtlUomOptionSuccess = (state, { options }) => ({
  ...state,
  dtlUomOptions: options
});

export const prodOrdDetailFetchDtlLocationOptionLoading = (state, { boolean }) => ({
  ...state,
  dtlLocationIsLoading: boolean
});

export const prodOrdDetailFetchDtlLocationOptionSuccess = (state, { options }) => ({
  ...state,
  dtlLocationOptions: options
});

export const prodOrdDetailFetchProjectOptionLoading = (state, { boolean }) => ({
  ...state,
  projectIsLoading: boolean
});

export const prodOrdDetailFetchProjectOptionSuccess = (state, { options }) => ({
  ...state,
  projectOptions: options
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [ProdOrdDetailTypes.PROD_ORD_DETAIL_RESET_TIMESTAMP]: prodOrdDetailResetTimestamp,
  [ProdOrdDetailTypes.PROD_ORD_DETAIL_SET_HDR_ID]: prodOrdDetailSetHdrId,

  [ProdOrdDetailTypes.PROD_ORD_DETAIL_SHOW_HEADER_SUCCESS]: prodOrdDetailShowHeaderSuccess,

  [ProdOrdDetailTypes.PROD_ORD_DETAIL_UPDATE_DOCUMENT_SUCCESS]: prodOrdDetailUpdateDocumentSuccess,

  [ProdOrdDetailTypes.PROD_ORD_DETAIL_SHOW_DOCUMENT_LOADING]: prodOrdDetailShowDocumentLoading,

  [ProdOrdDetailTypes.PROD_ORD_DETAIL_SHOW_DETAILS_SUCCESS]: prodOrdDetailShowDetailsSuccess,

  [ProdOrdDetailTypes.PROD_ORD_DETAIL_SET_DETAIL_VISIBLE]: prodOrdDetailSetDetailVisible,
  [ProdOrdDetailTypes.PROD_ORD_DETAIL_SET_DOCUMENT_DETAIL]: prodOrdDetailSetDocumentDetail,

  [ProdOrdDetailTypes.PROD_ORD_DETAIL_UPDATE_DETAILS]: prodOrdDetailUpdateDetails,
  [ProdOrdDetailTypes.PROD_ORD_DETAIL_CREATE_DETAIL]: prodOrdDetailCreateDetail,

  [ProdOrdDetailTypes.PROD_ORD_DETAIL_FETCH_CURRENCY_OPTION_LOADING]: prodOrdDetailFetchCurrencyOptionLoading,
  [ProdOrdDetailTypes.PROD_ORD_DETAIL_FETCH_CURRENCY_OPTION_SUCCESS]: prodOrdDetailFetchCurrencyOptionSuccess,

  [ProdOrdDetailTypes.PROD_ORD_DETAIL_FETCH_PROD_STORAGE_BIN_OPTION_LOADING]: prodOrdDetailFetchProdStorageBinOptionLoading,
  [ProdOrdDetailTypes.PROD_ORD_DETAIL_FETCH_PROD_STORAGE_BIN_OPTION_SUCCESS]: prodOrdDetailFetchProdStorageBinOptionSuccess,

  [ProdOrdDetailTypes.PROD_ORD_DETAIL_FETCH_DTL_ITEM_OPTION_LOADING]: prodOrdDetailFetchDtlItemOptionLoading,
  [ProdOrdDetailTypes.PROD_ORD_DETAIL_FETCH_DTL_ITEM_OPTION_SUCCESS]: prodOrdDetailFetchDtlItemOptionSuccess,

  [ProdOrdDetailTypes.PROD_ORD_DETAIL_FETCH_DTL_ITEM_BATCH_OPTION_LOADING]: prodOrdDetailFetchDtlItemBatchOptionLoading,
  [ProdOrdDetailTypes.PROD_ORD_DETAIL_FETCH_DTL_ITEM_BATCH_OPTION_SUCCESS]: prodOrdDetailFetchDtlItemBatchOptionSuccess,

  [ProdOrdDetailTypes.PROD_ORD_DETAIL_FETCH_DTL_UOM_OPTION_LOADING]: prodOrdDetailFetchDtlUomOptionLoading,
  [ProdOrdDetailTypes.PROD_ORD_DETAIL_FETCH_DTL_UOM_OPTION_SUCCESS]: prodOrdDetailFetchDtlUomOptionSuccess,

  [ProdOrdDetailTypes.PROD_ORD_DETAIL_FETCH_DTL_LOCATION_OPTION_LOADING]: prodOrdDetailFetchDtlLocationOptionLoading,
  [ProdOrdDetailTypes.PROD_ORD_DETAIL_FETCH_DTL_LOCATION_OPTION_SUCCESS]: prodOrdDetailFetchDtlLocationOptionSuccess,

  [ProdOrdDetailTypes.PROD_ORD_DETAIL_FETCH_PROJECT_OPTION_LOADING]: prodOrdDetailFetchProjectOptionLoading,
  [ProdOrdDetailTypes.PROD_ORD_DETAIL_FETCH_PROJECT_OPTION_SUCCESS]: prodOrdDetailFetchProjectOptionSuccess
});
