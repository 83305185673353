import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  countAdjReasonList01ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  countAdjReasonList01GoToResource: ['resId'],
  countAdjReasonList01NewResource: null,
  countAdjReasonList01FetchCountAdjReasonList01: ['currentPage', 'sorts', 'filters', 'pageSize'],
  countAdjReasonList01FetchCountAdjReasonList01Loading: ['boolean'],
  countAdjReasonList01FetchCountAdjReasonList01Success: [
    'resources',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],
  countAdjReasonList01AddSelectedResources: ['selectedResources'],
  countAdjReasonList01RemoveSelectedResources: ['selectedResources'],
  countAdjReasonList01SetWorkspaceVisible: ['boolean']
});

export const CountAdjReasonList01Types = Types;
export default Creators;
