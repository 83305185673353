import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';

import { CloudDownloadOutlined } from '@ant-design/icons';

import { Table, Row, Col, Button } from 'antd';
import moment from 'moment';

import FileSaver from 'file-saver';
import XLSX from 'xlsx';

class ReportTable extends React.PureComponent {
  constructor() {
    super();

    this.useOnDownloadCsv = this.useOnDownloadCsv.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate() {}

  componentWillUnmount() {}

  useOnDownloadCsv() {
    const { intl, reportData, criteria } = this.props;

    const startOfMonth = moment()
      .clone()
      .startOf('month')
      .format('YYYY-MM-DD');
    const isShowCaseQty = criteria.is_show_case_qty;
    const mappedDetails = reportData.map(dataEntry => {
      return criteria.columns.target.reduce((lastObj, columnEntry) => {
        const newObj = { ...lastObj };

        if (columnEntry.column === 'months') {
          for (let i = criteria.no_of_months; i >= 1; i -= 1) {
            const month = moment(startOfMonth)
              .subtract(i, 'months')
              .format('MMM YYYY');
            newObj[
              `${month} (${
                isShowCaseQty > 0
                  ? intl.formatMessage({ id: 'CASES' })
                  : intl.formatMessage({ id: 'UNIT' })
              })`
            ] = dataEntry[month];
          }
        } else if (
          columnEntry.column === 'ito_qty' ||
          columnEntry.column === 'item_case_uom_rate'
        ) {
          newObj[intl.formatMessage({ id: columnEntry.column })] = dataEntry[columnEntry.column];
        } else if (
          columnEntry.column === 'rsvd_qty' ||
          columnEntry.column === 'avail_qty' ||
          columnEntry.column === 'balance_qty' ||
          columnEntry.column === 'ams_qty' ||
          columnEntry.column === 'os_sls_ord_qty' ||
          columnEntry.column === 'os_adv_ship_qty' ||
          columnEntry.column === 'os_trf_out_qty' ||
          columnEntry.column === 'os_trf_in_qty' ||
          columnEntry.column === '7days_safety_stock_qty' ||
          columnEntry.column === '14days_safety_stock_qty' ||
          columnEntry.column === '21days_safety_stock_qty' ||
          columnEntry.column === '30days_safety_stock_qty' ||
          columnEntry.column === '45days_safety_stock_qty' ||
          columnEntry.column === '60days_safety_stock_qty' ||
          columnEntry.column === '90days_safety_stock_qty' ||
          columnEntry.column === '120days_safety_stock_qty'
        ) {
          newObj[
            `${intl.formatMessage({ id: columnEntry.column })} (${
              isShowCaseQty > 0
                ? intl.formatMessage({ id: 'CASES' })
                : intl.formatMessage({ id: 'UNIT' })
            })`
          ] = dataEntry[columnEntry.column];
        } else {
          newObj[intl.formatMessage({ id: columnEntry.column })] = dataEntry[columnEntry.column];
        }

        return newObj;
      }, {});
    });

    const ws = XLSX.utils.json_to_sheet(mappedDetails);

    const fileType = 'application/octet-stream';

    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'csv', type: 'array' });
    // eslint-disable-next-line no-undef
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, `StockReorderPlanningV2.csv`);
  }

  render() {
    const { intl, criteria, reportData, reportIsLoading } = this.props;

    const startOfMonth = moment()
      .clone()
      .startOf('month')
      .format('YYYY-MM-DD');
    const isShowCaseQty = criteria.is_show_case_qty;
    const dynamicColumns = [];
    criteria.columns.target.forEach(entry => {
      if (entry.column === 'months') {
        for (let i = criteria.no_of_months; i >= 1; i -= 1) {
          const month = moment(startOfMonth)
            .subtract(i, 'months')
            .format('MMM YYYY');

          dynamicColumns.push({
            width: 100,
            align: 'right',
            title: `${month} (${
              isShowCaseQty > 0
                ? intl.formatMessage({ id: 'CASES' })
                : intl.formatMessage({ id: 'UNIT' })
            })`,
            // sort field
            dataIndex: month,
            sorter: {
              compare: (a, b) => a[month] - b[month],
              multiple: 1
            },
            // filter field
            key: month,
            render: text => (
              <>
                {new Intl.NumberFormat([], {
                  style: 'decimal',
                  minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                  maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
                }).format(text)}
              </>
            )
          });
        }
      } else if (entry.column === 'ito_qty' || entry.column === 'item_case_uom_rate') {
        dynamicColumns.push({
          width: 100,
          align: 'right',
          title: intl.formatMessage({ id: entry.column }),
          // sort field
          dataIndex: entry.column,
          sorter: {
            compare: (a, b) => a[entry.column] - b[entry.column],
            multiple: 1
          },
          // filter field
          key: entry.column,
          render: text => (
            <>
              {new Intl.NumberFormat([], {
                style: 'decimal',
                minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
              }).format(text)}
            </>
          )
        });
      } else if (
        entry.column === 'rsvd_qty' ||
        entry.column === 'avail_qty' ||
        entry.column === 'balance_qty' ||
        entry.column === 'ams_qty' ||
        entry.column === 'os_sls_ord_qty' ||
        entry.column === 'os_adv_ship_qty' ||
        entry.column === 'os_trf_out_qty' ||
        entry.column === 'os_trf_in_qty' ||
        entry.column === '7days_safety_stock_qty' ||
        entry.column === '14days_safety_stock_qty' ||
        entry.column === '21days_safety_stock_qty' ||
        entry.column === '30days_safety_stock_qty' ||
        entry.column === '45days_safety_stock_qty' ||
        entry.column === '60days_safety_stock_qty' ||
        entry.column === '90days_safety_stock_qty' ||
        entry.column === '120days_safety_stock_qty'
      ) {
        dynamicColumns.push({
          width: 100,
          align: 'right',
          title: `${intl.formatMessage({ id: entry.column })} (${
            isShowCaseQty > 0
              ? intl.formatMessage({ id: 'CASES' })
              : intl.formatMessage({ id: 'UNIT' })
          })`,
          // sort field
          dataIndex: entry.column,
          sorter: {
            compare: (a, b) => a[entry.column] - b[entry.column],
            multiple: 1
          },
          // filter field
          key: entry.column,
          render: text => (
            <>
              {new Intl.NumberFormat([], {
                style: 'decimal',
                minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
              }).format(text)}
            </>
          )
        });
      } else {
        dynamicColumns.push({
          width: 100,
          align: 'left',
          title: intl.formatMessage({ id: entry.column }),
          // sort field
          dataIndex: entry.column,
          sorter: {
            compare: (a, b) => `${a[entry.column]}`.localeCompare(b[entry.column]),
            multiple: 1
          },
          // filter field
          key: entry.column,
          render: text => <>{text}</>
        });
      }
    });

    return (
      <>
        <Table
          size="small"
          // rowSelection={rowSelection}
          rowKey="item_id"
          pagination={{
            pageSize: 20,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
          }}
          columns={dynamicColumns}
          dataSource={reportData}
          loading={reportIsLoading}
          bordered
          title={() => (
            <>
              <Row type="flex" justify="space-between" gutter={[0, 16]}>
                <Col span={8}>
                  <Button
                    type="primary"
                    // disabled={!isValid}
                    loading={reportIsLoading}
                    onClick={this.useOnDownloadCsv}
                    icon={<CloudDownloadOutlined />}
                  >
                    {intl.formatMessage({ id: 'excel' })}
                  </Button>
                </Col>
              </Row>
            </>
          )}
          scroll={{ x: 950 }}
        />
      </>
    );
  }
}

ReportTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,

  criteria: PropTypes.shape({
    is_show_case_qty: PropTypes.number,
    no_of_months: PropTypes.number,
    columns: PropTypes.shape({
      source: PropTypes.arrayOf(PropTypes.object),
      target: PropTypes.arrayOf(PropTypes.object)
    })
  }),
  reportData: PropTypes.arrayOf(PropTypes.object),
  reportIsLoading: PropTypes.bool
};

ReportTable.defaultProps = {
  intl: {},

  criteria: {
    is_show_case_qty: 1,
    no_of_months: 6,
    columns: {
      source: [],
      target: []
    }
  },
  reportData: [],
  reportIsLoading: false
};

const mapStateToProps = state => ({
  criteria: state.stockReorderPlanningV2Report.criteria,

  reportData: state.stockReorderPlanningV2Report.reportData,
  reportIsLoading: state.stockReorderPlanningV2Report.reportIsLoading
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ReportTable));
