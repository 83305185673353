/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { PickListDetailBoardTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const pickListDetailBoardResetWipOutbOrdTimestamp = (
  state,
  { currentPage, sorts, filters, pageSize }
) => ({
  ...state,
  wipOutbOrdCurrentPage: currentPage || state.wipOutbOrdCurrentPage,
  wipOutbOrdSorts: sorts || state.wipOutbOrdSorts,
  wipOutbOrdFilters: filters || state.wipOutbOrdFilters,
  wipOutbOrdPageSize: pageSize || state.wipOutbOrdPageSize,
  wipOutbOrdTimestamp: Date.now()
});

export const pickListDetailBoardFetchWipOutbOrdLoading = (state, { boolean }) => ({
  ...state,
  wipOutbOrdIsLoading: boolean
});

export const pickListDetailBoardFetchWipOutbOrdSuccess = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  wipOutbOrds: documents,
  wipOutbOrdCurrentPage: currentPage,
  wipOutbOrdLastPage: lastPage,
  wipOutbOrdTotal: total,
  wipOutbOrdPageSize: pageSize
});

export const pickListDetailBoardResetWipPickListTimestamp = (
  state,
  { currentPage, sorts, filters, pageSize }
) => ({
  ...state,
  wipPickListCurrentPage: currentPage || state.wipPickListCurrentPage,
  wipPickListSorts: sorts || state.wipPickListSorts,
  wipPickListFilters: filters || state.wipPickListFilters,
  wipPickListPageSize: pageSize || state.wipPickListPageSize,
  wipPickListTimestamp: Date.now()
});

export const pickListDetailBoardFetchWipPickListLoading = (state, { boolean }) => ({
  ...state,
  wipPickListIsLoading: boolean
});

export const pickListDetailBoardFetchWipPickListSuccess = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  wipPickLists: documents,
  wipPickListCurrentPage: currentPage,
  wipPickListLastPage: lastPage,
  wipPickListTotal: total,
  wipPickListPageSize: pageSize
});

export const pickListDetailBoardResetWipWhseJobTimestamp = (
  state,
  { currentPage, sorts, filters, pageSize }
) => ({
  ...state,
  wipWhseJobCurrentPage: currentPage || state.wipWhseJobCurrentPage,
  wipWhseJobSorts: sorts || state.wipWhseJobSorts,
  wipWhseJobFilters: filters || state.wipWhseJobFilters,
  wipWhseJobPageSize: pageSize || state.wipWhseJobPageSize,
  wipWhseJobTimestamp: Date.now()
});

export const pickListDetailBoardFetchWipWhseJobLoading = (state, { boolean }) => ({
  ...state,
  wipWhseJobIsLoading: boolean
});

export const pickListDetailBoardFetchWipWhseJobSuccess = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  wipWhseJobs: documents,
  wipWhseJobCurrentPage: currentPage,
  wipWhseJobLastPage: lastPage,
  wipWhseJobTotal: total,
  wipWhseJobPageSize: pageSize
});

export const pickListDetailBoardResetCompletePickListTimestamp = (
  state,
  { currentPage, sorts, filters, pageSize }
) => ({
  ...state,
  completePickListCurrentPage: currentPage || state.completePickListCurrentPage,
  completePickListSorts: sorts || state.completePickListSorts,
  completePickListFilters: filters || state.completePickListFilters,
  completePickListPageSize: pageSize || state.completePickListPageSize,
  completePickListTimestamp: Date.now()
});

export const pickListDetailBoardFetchCompletePickListLoading = (state, { boolean }) => ({
  ...state,
  completePickListIsLoading: boolean
});

export const pickListDetailBoardFetchCompletePickListSuccess = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  completePickLists: documents,
  completePickListCurrentPage: currentPage,
  completePickListLastPage: lastPage,
  completePickListTotal: total,
  completePickListPageSize: pageSize
});

export const pickListDetailBoardShowWipWhseJobSummary = (state, { docId }) => ({
  ...state,
  wipWhseJobDocId: docId,
  wipWhseJobDialogIsVisible: true
});

export const pickListDetailBoardSetWipWhseJobDialogVisible = (state, { boolean }) => ({
  ...state,
  wipWhseJobDialogIsVisible: boolean
});

export const pickListDetailBoardShowWipWhseJobSummaryLoading = (state, { boolean }) => ({
  ...state,
  wipWhseJobDocumentIsLoading: boolean
});

export const pickListDetailBoardShowWipWhseJobSummarySuccess = (state, { document }) => ({
  ...state,
  wipWhseJobDocument: document
});

export const pickListDetailBoardShowWipPickListSummary = (state, { docId }) => ({
  ...state,
  wipPickListDocId: docId,
  wipPickListDialogIsVisible: true
});

export const pickListDetailBoardSetWipPickListDialogVisible = (state, { boolean }) => ({
  ...state,
  wipPickListDialogIsVisible: boolean
});

export const pickListDetailBoardShowWipPickListSummaryLoading = (state, { boolean }) => ({
  ...state,
  wipPickListDocumentIsLoading: boolean
});

export const pickListDetailBoardShowWipPickListSummarySuccess = (state, { document }) => ({
  ...state,
  wipPickListDocument: document
});

export const pickListDetailBoardShowWipOutbOrdSummary = (state, { docId }) => ({
  ...state,
  wipOutbOrdDocId: docId,
  wipOutbOrdDialogIsVisible: true
});

export const pickListDetailBoardSetWipOutbOrdDialogVisible = (state, { boolean }) => ({
  ...state,
  wipOutbOrdDialogIsVisible: boolean
});

export const pickListDetailBoardShowWipOutbOrdSummaryLoading = (state, { boolean }) => ({
  ...state,
  wipOutbOrdDocumentIsLoading: boolean
});

export const pickListDetailBoardShowWipOutbOrdSummarySuccess = (state, { document }) => ({
  ...state,
  wipOutbOrdDocument: document
});

export const pickListDetailBoardShowCompletePickListSummary = (state, { docId }) => ({
  ...state,
  completePickListDocId: docId,
  completePickListDialogIsVisible: true
});

export const pickListDetailBoardSetCompletePickListDialogVisible = (state, { boolean }) => ({
  ...state,
  completePickListDialogIsVisible: boolean
});

export const pickListDetailBoardShowCompletePickListSummaryLoading = (state, { boolean }) => ({
  ...state,
  completePickListDocumentIsLoading: boolean
});

export const pickListDetailBoardShowCompletePickListSummarySuccess = (state, { document }) => ({
  ...state,
  completePickListDocument: document
});

export const pickListDetailBoardFetchLoadingBinOptionsSuccess = (state, { options }) => ({
  ...state,
  loadingBinOptions: options
});

export const pickListDetailBoardChangeLoadingBin = (state, { loadingBinId }) => ({
  ...state,
  loadingBinId,
  wipPickListTimestamp: Date.now(),
  wipWhseJobTimestamp: Date.now(),
  completePickListTimestamp: Date.now()
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [PickListDetailBoardTypes.PICK_LIST_DETAIL_BOARD_RESET_WIP_OUTB_ORD_TIMESTAMP]: pickListDetailBoardResetWipOutbOrdTimestamp,
  [PickListDetailBoardTypes.PICK_LIST_DETAIL_BOARD_FETCH_WIP_OUTB_ORD_LOADING]: pickListDetailBoardFetchWipOutbOrdLoading,
  [PickListDetailBoardTypes.PICK_LIST_DETAIL_BOARD_FETCH_WIP_OUTB_ORD_SUCCESS]: pickListDetailBoardFetchWipOutbOrdSuccess,

  [PickListDetailBoardTypes.PICK_LIST_DETAIL_BOARD_RESET_WIP_PICK_LIST_TIMESTAMP]: pickListDetailBoardResetWipPickListTimestamp,
  [PickListDetailBoardTypes.PICK_LIST_DETAIL_BOARD_FETCH_WIP_PICK_LIST_LOADING]: pickListDetailBoardFetchWipPickListLoading,
  [PickListDetailBoardTypes.PICK_LIST_DETAIL_BOARD_FETCH_WIP_PICK_LIST_SUCCESS]: pickListDetailBoardFetchWipPickListSuccess,

  [PickListDetailBoardTypes.PICK_LIST_DETAIL_BOARD_RESET_WIP_WHSE_JOB_TIMESTAMP]: pickListDetailBoardResetWipWhseJobTimestamp,
  [PickListDetailBoardTypes.PICK_LIST_DETAIL_BOARD_FETCH_WIP_WHSE_JOB_LOADING]: pickListDetailBoardFetchWipWhseJobLoading,
  [PickListDetailBoardTypes.PICK_LIST_DETAIL_BOARD_FETCH_WIP_WHSE_JOB_SUCCESS]: pickListDetailBoardFetchWipWhseJobSuccess,

  [PickListDetailBoardTypes.PICK_LIST_DETAIL_BOARD_RESET_COMPLETE_PICK_LIST_TIMESTAMP]: pickListDetailBoardResetCompletePickListTimestamp,
  [PickListDetailBoardTypes.PICK_LIST_DETAIL_BOARD_FETCH_COMPLETE_PICK_LIST_LOADING]: pickListDetailBoardFetchCompletePickListLoading,
  [PickListDetailBoardTypes.PICK_LIST_DETAIL_BOARD_FETCH_COMPLETE_PICK_LIST_SUCCESS]: pickListDetailBoardFetchCompletePickListSuccess,

  [PickListDetailBoardTypes.PICK_LIST_DETAIL_BOARD_SHOW_WIP_WHSE_JOB_SUMMARY]: pickListDetailBoardShowWipWhseJobSummary,
  [PickListDetailBoardTypes.PICK_LIST_DETAIL_BOARD_SET_WIP_WHSE_JOB_DIALOG_VISIBLE]: pickListDetailBoardSetWipWhseJobDialogVisible,
  [PickListDetailBoardTypes.PICK_LIST_DETAIL_BOARD_SHOW_WIP_WHSE_JOB_SUMMARY_LOADING]: pickListDetailBoardShowWipWhseJobSummaryLoading,
  [PickListDetailBoardTypes.PICK_LIST_DETAIL_BOARD_SHOW_WIP_WHSE_JOB_SUMMARY_SUCCESS]: pickListDetailBoardShowWipWhseJobSummarySuccess,

  [PickListDetailBoardTypes.PICK_LIST_DETAIL_BOARD_SHOW_WIP_PICK_LIST_SUMMARY]: pickListDetailBoardShowWipPickListSummary,
  [PickListDetailBoardTypes.PICK_LIST_DETAIL_BOARD_SET_WIP_PICK_LIST_DIALOG_VISIBLE]: pickListDetailBoardSetWipPickListDialogVisible,
  [PickListDetailBoardTypes.PICK_LIST_DETAIL_BOARD_SHOW_WIP_PICK_LIST_SUMMARY_LOADING]: pickListDetailBoardShowWipPickListSummaryLoading,
  [PickListDetailBoardTypes.PICK_LIST_DETAIL_BOARD_SHOW_WIP_PICK_LIST_SUMMARY_SUCCESS]: pickListDetailBoardShowWipPickListSummarySuccess,

  [PickListDetailBoardTypes.PICK_LIST_DETAIL_BOARD_SHOW_WIP_OUTB_ORD_SUMMARY]: pickListDetailBoardShowWipOutbOrdSummary,
  [PickListDetailBoardTypes.PICK_LIST_DETAIL_BOARD_SET_WIP_OUTB_ORD_DIALOG_VISIBLE]: pickListDetailBoardSetWipOutbOrdDialogVisible,
  [PickListDetailBoardTypes.PICK_LIST_DETAIL_BOARD_SHOW_WIP_OUTB_ORD_SUMMARY_LOADING]: pickListDetailBoardShowWipOutbOrdSummaryLoading,
  [PickListDetailBoardTypes.PICK_LIST_DETAIL_BOARD_SHOW_WIP_OUTB_ORD_SUMMARY_SUCCESS]: pickListDetailBoardShowWipOutbOrdSummarySuccess,

  [PickListDetailBoardTypes.PICK_LIST_DETAIL_BOARD_SHOW_COMPLETE_PICK_LIST_SUMMARY]: pickListDetailBoardShowCompletePickListSummary,
  [PickListDetailBoardTypes.PICK_LIST_DETAIL_BOARD_SET_COMPLETE_PICK_LIST_DIALOG_VISIBLE]: pickListDetailBoardSetCompletePickListDialogVisible,
  [PickListDetailBoardTypes.PICK_LIST_DETAIL_BOARD_SHOW_COMPLETE_PICK_LIST_SUMMARY_LOADING]: pickListDetailBoardShowCompletePickListSummaryLoading,
  [PickListDetailBoardTypes.PICK_LIST_DETAIL_BOARD_SHOW_COMPLETE_PICK_LIST_SUMMARY_SUCCESS]: pickListDetailBoardShowCompletePickListSummarySuccess,
  [PickListDetailBoardTypes.PICK_LIST_DETAIL_BOARD_FETCH_LOADING_BIN_OPTIONS_SUCCESS]: pickListDetailBoardFetchLoadingBinOptionsSuccess,

  [PickListDetailBoardTypes.PICK_LIST_DETAIL_BOARD_CHANGE_LOADING_BIN]: pickListDetailBoardChangeLoadingBin
});
