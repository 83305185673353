import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  prodSheetIndexResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  prodSheetIndexFetchProdSheetIndex: ['currentPage', 'sorts', 'filters', 'pageSize'],
  prodSheetIndexFetchProdSheetIndexLoading: ['boolean'],
  prodSheetIndexFetchProdSheetIndexSuccess: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],
  prodSheetIndexAddSelectedDocuments: ['selectedDocuments'],
  prodSheetIndexRemoveSelectedDocuments: ['selectedDocuments'],
  prodSheetIndexSetWorkspaceVisible: ['boolean'],
  prodSheetIndexGoToDocument: ['hdrId'],
  prodSheetIndexGoToAudit: ['hdrId'],

  prodSheetIndexWorkspaceLoading: ['boolean'],
  prodSheetIndexResetProdSheetFServ01: ['hdrIds'],
  prodSheetIndexResetProdSheetFServ01Success: null
});

export const ProdSheetIndexTypes = Types;
export default Creators;
