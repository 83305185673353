import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  siteFlowList01ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  siteFlowList01NewResource: null,
  siteFlowList01GoToResource: ['hdrId'],
  siteFlowList01FetchSiteFlowList01: ['currentPage', 'sorts', 'filters', 'pageSize'],
  siteFlowList01FetchSiteFlowList01Loading: ['boolean'],
  siteFlowList01FetchSiteFlowList01Success: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ]
});

export const SiteFlowList01Types = Types;
export default Creators;
