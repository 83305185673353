import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { injectIntl } from 'react-intl';

import { SearchOutlined } from '@ant-design/icons';

import { Card, Row, Col, Select, Button, Typography } from 'antd';
import BarcodeReader from '../../Components/BarcodeReader';
import FailDel01Form from './FailDel01Form';
import OutbOrdTable from './OutbOrdTable';
import FailDel01Actions from '../../Stores/FailDel01/Actions';

const { Option } = Select;
const { Text } = Typography;

class FailDel01Pane extends React.PureComponent {
  constructor() {
    super();

    this.state = { searchValue: '' };

    this.handleScan = this.handleScan.bind(this);
    this.handleError = this.handleError.bind(this);

    this.handleSearchDocument = this.handleSearchDocument.bind(this);
    this.handleAddDocument = this.handleAddDocument.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate() {}

  componentWillUnmount() {}

  handleScan(data) {
    const { scanBarcode } = this.props;

    scanBarcode(data);
  }

  handleAddDocument() {
    const { scanBarcode } = this.props;
    const { searchValue } = this.state;

    scanBarcode(searchValue);
  }

  handleError(msg, err) {
    const { scanBarcodeError } = this.props;

    scanBarcodeError(err);
  }

  handleSearchDocument(value) {
    const { searchDocument } = this.props;

    searchDocument(value);
  }

  render() {
    const { intl, match, documentOptions, documentIsLoading, fetchIsLoading } = this.props;
    const { searchValue } = this.state;

    const processedOptions = documentOptions.map(d => (
      <Option value={d.value} key={d.value}>
        <b>{d.docCode}</b>
        {` ${d.docDate} `}
        <b>{d.refCode01}</b>
      </Option>
    ));

    return (
      <>
        <BarcodeReader onError={this.handleError} onScan={this.handleScan} />
        <FailDel01Form match={match} />

        <Card>
          <Row type="flex" justify="center" gutter={[8, 8]}>
            <Col span={3}>
              <Text>{intl.formatMessage({ id: 'doc_code' })}</Text>
            </Col>
            <Col span={1}>
              <Text>:</Text>
            </Col>
            <Col span={17}>
              <Select
                style={{ width: '100%' }}
                mode="default"
                showSearch
                value={searchValue}
                showArrow
                filterOption={false}
                /*
            onDropdownVisibleChange={open => {
              if (open && processedOptions.length === 0) {
                this.handleSearchDocument('');
              }
            }}
            */
                onSearch={value => {
                  this.handleSearchDocument(value);
                }}
                onSelect={value => {
                  this.setState({
                    searchValue: value
                  });
                }}
                onDeselect={() => {
                  this.setState({
                    searchValue: ''
                  });
                }}
                notFoundContent={null}
                loading={documentIsLoading}
              >
                {processedOptions}
              </Select>
              <Text style={{ color: 'red' }}>
                {`*${intl.formatMessage({
                  id: 'please_enter_at_least_5_characters'
                })}`}
              </Text>
            </Col>
            <Col span={3}>
              <Button
                type="primary"
                disabled={searchValue.length === 0}
                loading={fetchIsLoading || documentIsLoading}
                onClick={this.handleAddDocument}
                icon={<SearchOutlined />}
              >
                {intl.formatMessage({ id: 'search' })}
              </Button>
            </Col>
          </Row>
        </Card>
        <OutbOrdTable match={match} />
      </>
    );
  }
}

FailDel01Pane.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object,

  scanBarcode: PropTypes.func,
  scanBarcodeError: PropTypes.func,

  fetchIsLoading: PropTypes.bool,

  searchDocument: PropTypes.func,
  documentOptions: PropTypes.arrayOf(PropTypes.object),
  documentIsLoading: PropTypes.bool
};

FailDel01Pane.defaultProps = {
  intl: {},
  match: {},

  scanBarcode() {},
  scanBarcodeError() {},

  fetchIsLoading: false,

  searchDocument() {},
  documentOptions: [],
  documentIsLoading: false
};

const mapStateToProps = state => ({
  appPath: state.app.appPath,

  fetchIsLoading: state.failDel01.fetchIsLoading,
  documentOptions: state.failDel01.documentOptions,
  documentIsLoading: state.failDel01.documentIsLoading
});

const mapDispatchToProps = dispatch => ({
  scanBarcode: value => dispatch(FailDel01Actions.failDel01ScanBarcode(value)),
  scanBarcodeError: value => dispatch(FailDel01Actions.failDel01ScanBarcodeError(value)),
  searchDocument: value => dispatch(FailDel01Actions.failDel01SearchDocument(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(FailDel01Pane));
