import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  stkIssFServ01ShowSyncSetting: null,
  stkIssFServ01ShowSyncSettingLoading: ['boolean'],
  stkIssFServ01ShowSyncSettingSuccess: ['syncSetting'],
  stkIssFServ01UpdateSyncSetting: ['formikBag', 'syncSetting'],
  stkIssFServ01ShowBatchJobStatus: null,
  stkIssFServ01ShowBatchJobStatusSuccess: ['batchJobStatus'],

  stkIssFServ01ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  stkIssFServ01GoToDocument: ['hdrId'],
  stkIssFServ01FetchStkIssFServ01: ['currentPage', 'sorts', 'filters', 'pageSize'],
  stkIssFServ01FetchStkIssFServ01Loading: ['boolean'],
  stkIssFServ01FetchStkIssFServ01Success: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],
  stkIssFServ01AddSelectedDocuments: ['selectedDocuments'],
  stkIssFServ01RemoveSelectedDocuments: ['selectedDocuments'],
  stkIssFServ01SetWorkspaceVisible: ['boolean'],

  stkIssFServ01Export: ['hdrIds'],
  stkIssFServ01ExportSuccess: null,
  stkIssFServ01ExportLoading: ['boolean']
});

export const StkIssFServ01Types = Types;
export default Creators;
