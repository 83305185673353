import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import treeChanges from 'tree-changes';
import moment from 'moment';

import { EditOutlined, LaptopOutlined, ReloadOutlined } from '@ant-design/icons';

import { Card, Row, Col, Table, Button, Badge, Tag } from 'antd';

import AntDesignTable from '../../Components/AntDesignTable';
import OutbOrd08Actions from '../../Stores/OutbOrd08/Actions';

import OutbOrd08Dialog from './OutbOrd08Dialog';

class OutbOrd08Table extends React.PureComponent {
  constructor() {
    super();

    this.useOnTableChange = this.useOnTableChange.bind(this);
    this.useShowWorkspace = this.useShowWorkspace.bind(this);

    this.handleSearch = this.handleSearch.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.getDocumentColumns = this.getDocumentColumns.bind(this);
    this.getExpandedColumns = this.getExpandedColumns.bind(this);
    this.showExpandedRow = this.showExpandedRow.bind(this);
  }

  componentDidMount() {
    const { curDivisionId, setWorkspaceVisible, resetTimestamp } = this.props;

    if (curDivisionId > 0) {
      setWorkspaceVisible(false);
      resetTimestamp();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      curDivisionId,
      resetTimestamp,
      fetchOutbOrd08,
      currentPage,
      sorts,
      filters,
      pageSize
    } = this.props;

    const { changed } = treeChanges(prevProps, this.props);

    if (changed('curDivisionId')) {
      resetTimestamp();
    }

    if (changed('timestamp')) {
      if (curDivisionId > 0) {
        fetchOutbOrd08(currentPage, sorts, filters, pageSize);
      }
    }
  }

  componentWillUnmount() {}

  handleSearch(selectedKeys, confirm) {
    // eslint-disable-next-line no-unused-vars
    const { intl } = this.props;

    confirm();
  }

  handleReset(clearFilters) {
    // eslint-disable-next-line no-unused-vars
    const { intl } = this.props;

    clearFilters();
  }

  getDocumentColumns() {
    const { intl, sorts, filters, goToDocument } = this.props;

    return [
      {
        width: 50,
        // fixed: 'left',
        align: 'left',
        title: '',
        // sort field
        dataIndex: 'document_tags',
        // filter field
        key: 'document_tags',
        render: (text, record) =>
          record.is_out_of_stock ? <Tag color="#ffff00">&nbsp;&nbsp;</Tag> : ''
      },
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'est_del_date' }),
        // sort field
        dataIndex: 'est_del_date',
        ...AntDesignTable.getColumnMultiSortProps(sorts, 'est_del_date'),
        // filter field
        key: 'est_del_date',
        ...AntDesignTable.getColumnSearchDateProps(
          filters,
          intl.formatMessage({ id: 'est_del_date' }),
          'est_del_date',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{moment(record.est_del_date).format('YYYY-MM-DD')}</>
      },
      {
        width: 130,
        // fixed: 'left',
        align: 'left',
        title: intl.formatMessage({ id: 'doc_code' }),
        // sort field
        dataIndex: 'doc_code',
        ...AntDesignTable.getColumnSortProps(sorts, 'doc_code'),
        // filter field
        key: 'doc_code',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'doc_code' }),
          'doc_code',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => (
          <>
            {record.doc_code}
            {Array.isArray(record.lender_divisions) && record.lender_divisions.length > 0 ? (
              <div>
                {record.lender_divisions.map(el => (
                  <Tag color="red">{el.code}</Tag>
                ))}
              </div>
            ) : (
              ''
            )}
          </>
        )
      },
      {
        width: 100,
        // fixed: 'left',
        align: 'left',
        title: intl.formatMessage({ id: 'doc_status' }),
        // sort field
        dataIndex: 'doc_status',
        ...AntDesignTable.getColumnSortProps(sorts, 'doc_status'),
        // filter field
        ...AntDesignTable.getColumnSearchDocStatusProps(filters, 'doc_status'),
        render: (text, record) => <>{record.str_doc_status}</>
      },
      {
        width: 150,
        align: 'left',
        title: intl.formatMessage({ id: 'biz_partner' }),
        // sort field
        dataIndex: 'biz_partner_code',
        ...AntDesignTable.getColumnSortProps(sorts, 'biz_partner_code'),
        // filter field
        key: 'biz_partner',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'biz_partner' }),
          'biz_partner',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => (
          <>
            <b>{record.biz_partner_code}</b>
            <br />
            {record.biz_partner_company_name_01}
          </>
        )
      },
      {
        width: 125,
        align: 'left',
        title: intl.formatMessage({ id: 'purchaser' }),
        // sort field
        dataIndex: 'purchaser_username',
        ...AntDesignTable.getColumnSortProps(sorts, 'purchaser_username'),
        // filter field
        key: 'purchaser',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'purchaser' }),
          'purchaser',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => (
          <>
            <b>{record.purchaser_username}</b>
          </>
        )
      },
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'doc_date' }),
        // sort field
        dataIndex: 'doc_date',
        ...AntDesignTable.getColumnSortProps(sorts, 'doc_date'),
        // filter field
        key: 'doc_date',
        ...AntDesignTable.getColumnSearchDateProps(
          filters,
          intl.formatMessage({ id: 'doc_date' }),
          'doc_date',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{moment(record.doc_date).format('YYYY-MM-DD')}</>
      },
      {
        width: 150,
        align: 'left',
        title: intl.formatMessage({ id: 'desc' }),
        // sort field
        dataIndex: 'desc_01',
        ...AntDesignTable.getColumnSortProps(sorts, 'desc_01'),
        // filter field
        key: 'desc',
        render: (text, record) => <>{record.desc_01}</>
      },
      {
        width: 120,
        fixed: 'right',
        align: 'right',
        title: intl.formatMessage({ id: 'net_amt' }),
        // sort field
        dataIndex: 'net_amt',
        ...AntDesignTable.getColumnSortProps(sorts, 'net_amt'),
        // filter field
        key: 'net_amt',
        render: (text, record) => (
          <>
            <div style={{ textAlign: 'right', fontWeight: 'bold' }}>
              {new Intl.NumberFormat([], {
                style: 'decimal',
                minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
              }).format(record.net_amt)}
            </div>

            <div style={{ textAlign: 'right' }}>
              {new Intl.NumberFormat([], {
                style: 'decimal',
                minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
              }).format(record.case_decimal_qty)}
              <b>
                &nbsp;
                {intl.formatMessage({ id: 'case_decimal_qty' })}
              </b>
            </div>

            <div style={{ textAlign: 'right' }}>
              {new Intl.NumberFormat([], {
                style: 'decimal',
                minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
              }).format(record.gross_weight)}
              <b>
                &nbsp;
                {intl.formatMessage({ id: 'gross_weight' })}
              </b>
            </div>

            <div style={{ textAlign: 'right' }}>
              {new Intl.NumberFormat([], {
                style: 'decimal',
                minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
              }).format(record.cubic_meter)}
              <b>
                &nbsp;
                {intl.formatMessage({ id: 'cubic_meter' })}
              </b>
            </div>
          </>
        )
      },

      {
        width: 50,
        fixed: 'right',
        key: 'action',
        render: (text, record) => (
          <>
            <Button type="dashed" icon={<EditOutlined />} onClick={() => goToDocument(record.id)} />
          </>
        )
      }
    ];
  }

  getExpandedColumns() {
    const { intl } = this.props;

    return [
      {
        width: 50,
        // fixed: 'left',
        align: 'left',
        title: '',
        // sort field
        dataIndex: 'document_tags',
        // filter field
        key: 'document_tags',
        render: (text, record) =>
          parseFloat(record.request_qty) > parseFloat(record.available_qty) ? (
            <Tag color="#ffff00">&nbsp;&nbsp;</Tag>
          ) : (
            ''
          )
      },
      {
        width: 30,
        align: 'left',
        title: '',
        // sort field
        dataIndex: 'line_no',
        sorter: (a, b) => a.line_no - b.line_no,
        defaultSortOrder: 'ascend',
        // filter field
        key: 'line_no',
        render: (text, record) => <>{record.line_no}</>
      },
      {
        width: 200,
        align: 'left',
        title: intl.formatMessage({ id: 'description' }),
        // sort field
        dataIndex: 'desc_01',
        sorter: (a, b) => `${a.desc_01}`.localeCompare(b.desc_01),
        // filter field
        key: 'desc_01',
        render: (text, record) => (
          <>
            <div style={{ fontWeight: 'bold' }}>{record.item_code}</div>
            {record.desc_01}
            {record.desc_02 ? (
              <>
                <br />
                {record.desc_02}
              </>
            ) : (
              ''
            )}
            {record.desc_03 ? (
              <>
                <br />
                {record.desc_03}
              </>
            ) : (
              ''
            )}
          </>
        )
      },
      {
        width: 90,
        align: 'left',
        title: intl.formatMessage({ id: 'expiry_date' }),
        // sort field
        dataIndex: 'expiry_date',
        // filter field
        key: 'expiry_date',
        render: (text, record) => (
          <>
            <div style={{ fontWeight: 'bold' }}>{record.batch_serial_no}</div>
            {record.expiry_date ? <div>{record.expiry_date}</div> : ''}
            {record.receipt_date ? <>{record.receipt_date}</> : ''}
          </>
        )
      },
      {
        width: 90,
        align: 'right',
        title: intl.formatMessage({ id: 'case' }),
        // sort field
        dataIndex: 'case_qty',
        sorter: (a, b) => a.case_qty - b.case_qty,
        // filter field
        key: 'case_qty',
        render: (text, record) =>
          record.case_qty > 0 ? (
            <>
              {new Intl.NumberFormat([], {
                style: 'decimal',
                minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
              }).format(record.case_qty)}
              <br />
              {record.item_case_uom_code}
            </>
          ) : (
            ''
          )
      },
      {
        width: 90,
        align: 'right',
        title: intl.formatMessage({ id: 'loose' }),
        // sort field
        dataIndex: 'loose_qty',
        sorter: (a, b) => a.loose_qty - b.loose_qty,
        // filter field
        key: 'loose_qty',
        render: (text, record) =>
          record.loose_qty > 0 ? (
            <>
              {new Intl.NumberFormat([], {
                style: 'decimal',
                minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
              }).format(record.loose_qty)}
              <br />
              {record.item_loose_uom_code}
            </>
          ) : (
            ''
          )
      },
      {
        width: 90,
        align: 'right',
        title: intl.formatMessage({ id: 'request_qty' }),
        // sort field
        dataIndex: 'request_qty',
        sorter: (a, b) => a.request_qty - b.request_qty,
        // filter field
        key: 'request_qty',
        render: (text, record) =>
          record.request_qty > 0 ? (
            <>
              {new Intl.NumberFormat([], {
                style: 'decimal',
                minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
              }).format(record.request_qty)}
            </>
          ) : (
            ''
          )
      },
      {
        width: 100,
        align: 'right',
        title: intl.formatMessage({ id: 'reserved_qty' }),
        // sort field
        dataIndex: 'reserved_qty',
        sorter: (a, b) => a.reserved_qty - b.reserved_qty,
        // filter field
        key: 'reserved_qty',
        render: (text, record) =>
          record.reserved_qty > 0 ? (
            <>
              {new Intl.NumberFormat([], {
                style: 'decimal',
                minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
              }).format(record.reserved_qty)}
            </>
          ) : (
            ''
          )
      },
      {
        width: 100,
        align: 'right',
        title: intl.formatMessage({ id: 'available_qty' }),
        // sort field
        dataIndex: 'available_qty',
        sorter: (a, b) => a.request_qty - b.request_qty,
        // filter field
        key: 'available_qty',
        render: (text, record) =>
          record.available_qty > 0 ? (
            <>
              {new Intl.NumberFormat([], {
                style: 'decimal',
                minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
              }).format(record.available_qty)}
            </>
          ) : (
            ''
          )
      }
    ];
  }

  useOnTableChange(pagination, filters, sorter) {
    const { resetTimestamp } = this.props;

    const processedFilters = AntDesignTable.processFilters(filters);
    const processedSorts = AntDesignTable.processSorts(sorter);

    resetTimestamp(pagination.current, processedSorts, processedFilters, pagination.pageSize);
  }

  useShowWorkspace() {
    const { setWorkspaceVisible } = this.props;

    setWorkspaceVisible(true);
  }

  showExpandedRow(record) {
    const { fetchIsLoading } = this.props;

    return (
      <Card bordered={false} size="small">
        <Table
          size="small"
          // rowSelection={rowSelection}
          rowKey="id"
          bordered={false}
          pagination={{
            pageSize: 20
          }}
          columns={this.getExpandedColumns()}
          dataSource={record.details}
          loading={fetchIsLoading}
          // defaultExpandAllRows
          // scroll={{ x: 750 }}
        />
      </Card>
    );
  }

  render() {
    const {
      intl,
      curDivisionId,
      resetTimestamp,
      newDocument,
      currentPage,
      pageSize,
      total,
      documents,
      fetchIsLoading,
      selectedDocuments,
      addSelectedDocuments,
      removeSelectedDocuments,
      expandedRows,
      setExpandedRows
    } = this.props;

    return (
      <Card
        title={intl.formatMessage({ id: 'purchase_returns' })}
        bordered={false}
        headStyle={{ textAlign: 'center' }}
        bodyStyle={{ padding: 0 }}
      >
        <Table
          size="small"
          // rowSelection={rowSelection}
          rowKey="id"
          pagination={{
            current: currentPage,
            pageSize: parseInt(pageSize, 10),
            total,
            showTotal: () => `${total} items`
          }}
          columns={this.getDocumentColumns()}
          dataSource={documents}
          loading={fetchIsLoading}
          bordered
          rowSelection={{
            selectedRowKeys: selectedDocuments.map(value => value.id),
            onChange: () => {
              // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            },
            onSelect: (record, selected) => {
              // console.log(`onSelect record: `, record, 'selected: ', selected, 'selectedRows: ', selectedRows);
              if (selected === true) {
                addSelectedDocuments([record]);
              } else {
                removeSelectedDocuments([record]);
              }
            },
            onSelectAll: (selected, selectedRows, changeRows) => {
              // console.log('onSelectAll selected: ', selected, 'selectedRows: ', selectedRows, 'changeRows: ', changeRows);
              if (selected === true) {
                addSelectedDocuments(changeRows);
              } else {
                removeSelectedDocuments(changeRows);
              }
            },
            onSelectInvert: selectedRows => {
              // console.log('onSelectInvert selectedRows: ', selectedRows);
              removeSelectedDocuments(selectedRows);
            },
            getCheckboxProps: record => ({
              // Column configuration not to be checked
              disabled: record.str_doc_status !== 'COMPLETE'
              // name: record.name,
            })
          }}
          onChange={this.useOnTableChange}
          expandedRowRender={this.showExpandedRow}
          expandedRowKeys={expandedRows}
          onExpandedRowsChange={rowKeys => {
            setExpandedRows(rowKeys);
          }}
          title={() => (
            <>
              <Row type="flex" justify="start" gutter={[0, 16]}>
                <Col span={1}>
                  <Button
                    type="primary"
                    // disabled={!isValid}
                    loading={fetchIsLoading}
                    onClick={() => resetTimestamp()}
                    icon={<ReloadOutlined />}
                  />
                </Col>
                <Col span={5}>
                  <Button
                    type="primary"
                    disabled={curDivisionId === 0}
                    loading={fetchIsLoading}
                    onClick={() => newDocument()}
                    // icon={<PlusOutlined />}
                  >
                    {intl.formatMessage({ id: 'new' })}
                  </Button>
                </Col>
              </Row>
            </>
          )}
          scroll={{ x: 950 }}
        />

        <div
          style={{
            position: 'fixed',
            bottom: '50px',
            right: '50px',
            zIndex: 9999
          }}
        >
          <Badge count={selectedDocuments.length}>
            <Button
              size="large"
              type="primary"
              shape="circle"
              icon={<LaptopOutlined />}
              onClick={this.useShowWorkspace}
              loading={fetchIsLoading}
            />
          </Badge>
        </div>

        <OutbOrd08Dialog />
      </Card>
    );
  }
}

OutbOrd08Table.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  resetTimestamp: PropTypes.func,
  goToDocument: PropTypes.func,
  newDocument: PropTypes.func,

  setWorkspaceVisible: PropTypes.func,

  addSelectedDocuments: PropTypes.func,
  removeSelectedDocuments: PropTypes.func,
  documents: PropTypes.arrayOf(PropTypes.object),
  selectedDocuments: PropTypes.arrayOf(PropTypes.object),

  sorts: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  filters: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),

  currentPage: PropTypes.number,
  pageSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  total: PropTypes.number,
  fetchIsLoading: PropTypes.bool,

  expandedRows: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  setExpandedRows: PropTypes.func,

  curDivisionId: PropTypes.number,
  fetchOutbOrd08: PropTypes.func
};

OutbOrd08Table.defaultProps = {
  intl: {},
  resetTimestamp() {},
  goToDocument() {},
  newDocument() {},

  setWorkspaceVisible() {},

  addSelectedDocuments() {},
  removeSelectedDocuments() {},
  documents: [],
  selectedDocuments: [],

  sorts: {},
  filters: {},

  currentPage: 1,
  pageSize: '20',
  total: 0,
  fetchIsLoading: false,

  expandedRows: [],
  setExpandedRows() {},

  curDivisionId: 0,
  fetchOutbOrd08() {}
};

const mapStateToProps = state => ({
  timestamp: state.outbOrd08.timestamp,
  workspaceIsVisible: state.outbOrd08.workspaceIsVisible,

  documents: state.outbOrd08.documents,
  selectedDocuments: state.outbOrd08.selectedDocuments,

  sorts: state.outbOrd08.sorts,
  filters: state.outbOrd08.filters,

  currentPage: state.outbOrd08.currentPage,
  pageSize: state.outbOrd08.pageSize,
  total: state.outbOrd08.total,
  fetchIsLoading: state.outbOrd08.fetchIsLoading,

  expandedRows: state.outbOrd08.expandedRows,

  curDivisionId: state.app.curDivisionId
});

const mapDispatchToProps = dispatch => ({
  resetTimestamp: (currentPage, sorts, filters, pageSize) =>
    dispatch(OutbOrd08Actions.outbOrd08ResetTimestamp(currentPage, sorts, filters, pageSize)),
  goToDocument: hdrId => dispatch(OutbOrd08Actions.outbOrd08GoToDocument(hdrId)),
  newDocument: () => dispatch(OutbOrd08Actions.outbOrd08NewDocument()),

  setWorkspaceVisible: boolean => dispatch(OutbOrd08Actions.outbOrd08SetWorkspaceVisible(boolean)),

  addSelectedDocuments: selectedDocuments =>
    dispatch(OutbOrd08Actions.outbOrd08AddSelectedDocuments(selectedDocuments)),
  removeSelectedDocuments: selectedDocuments =>
    dispatch(OutbOrd08Actions.outbOrd08RemoveSelectedDocuments(selectedDocuments)),

  fetchOutbOrd08: (currentPage, sorts, filters, pageSize) =>
    dispatch(OutbOrd08Actions.outbOrd08FetchOutbOrd08(currentPage, sorts, filters, pageSize)),

  setExpandedRows: expandedRows => dispatch(OutbOrd08Actions.outbOrd08SetExpandedRows(expandedRows))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(OutbOrd08Table));
