import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  countAdjReasonProcessFetchCountAdjReasonProcess: null,
  countAdjReasonProcessFetchCountAdjReasonProcessLoading: ['boolean'],
  countAdjReasonProcessFetchCountAdjReasonProcessSuccess: ['process']
});

export const CountAdjReasonProcessTypes = Types;
export default Creators;
