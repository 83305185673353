/**
 * The initial values for the redux state.
 */
export default {
  fetchIsLoading: false,
  createIsLoading: false,

  toStorageBinOptions: [],
  toStorageBinIsLoading: false,

  outbOrdHdrs: [],

  documentOptions: [],
  documentIsLoading: false,

  newDocuments: []
};
