import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  whseJob1401ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  whseJob1401GoToDocument: ['hdrId'],
  whseJob1401FetchWhseJob1401: ['currentPage', 'sorts', 'filters', 'pageSize'],
  whseJob1401FetchWhseJob1401Loading: ['boolean'],
  whseJob1401FetchWhseJob1401Success: ['documents', 'currentPage', 'lastPage', 'total', 'pageSize'],
  whseJob1401AddSelectedDocuments: ['selectedDocuments'],
  whseJob1401RemoveSelectedDocuments: ['selectedDocuments'],
  whseJob1401SetWorkspaceVisible: ['boolean'],
  whseJob1401CreateWhseJob1401: ['hdrIds'],
  whseJob1401CreateWhseJob1401Loading: ['boolean'],
  whseJob1401CreateWhseJob1401Success: ['newDocuments']
});

export const WhseJob1401Types = Types;
export default Creators;
