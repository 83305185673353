import { put, call, select, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import ApiService from '../Services/ApiService';
import AppActions from '../Stores/App/Actions';
import AdvShipFServ01Actions, { AdvShipFServ01Types } from '../Stores/AdvShipFServ01/Actions';

const getAppStore = state => state.app;

export function* advShipFServ01ShowBatchJobStatus() {
  try {
    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `batchJobStatus/showBatchJobStatus/ADV_SHIP_F_SERV_01`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(AdvShipFServ01Actions.advShipFServ01ShowBatchJobStatusSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  }
}

export function* advShipFServ01Import({ formikBag }) {
  try {
    formikBag.setSubmitting(true);
    yield put(AdvShipFServ01Actions.advShipFServ01ImportLoading(true));

    const app = yield select(getAppStore);

    // eslint-disable-next-line no-undef
    const postData = new FormData();

    const getData = {};

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `advShip/fServProcess/ADV_SHIP_F_SERV_01/${app.curSiteFlowId}/${app.curDivisionId}`,
      app.token,
      postData,
      getData,
      'multipart/form-data'
    );

    if (result.isSuccess === true) {
      // result.data is total
      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_SUCCESS_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
    yield put(AdvShipFServ01Actions.advShipFServ01ImportLoading(false));
  }
}

export function* advShipFServ01ShowSyncSetting() {
  try {
    yield put(AdvShipFServ01Actions.advShipFServ01ShowSyncSettingLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `syncSetting/showDivisionSetting/ADV_SHIP_F_SERV_01/${app.curDivisionId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(AdvShipFServ01Actions.advShipFServ01ShowSyncSettingSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(AdvShipFServ01Actions.advShipFServ01ShowSyncSettingLoading(false));
  }
}

export function* advShipFServ01UpdateSyncSetting({ formikBag, syncSetting }) {
  formikBag.setSubmitting(true);
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: syncSetting
    };

    const result = yield call(
      ApiService.putApi, // function
      app.apiUrl,
      `syncSetting/updateDivisionSetting/ADV_SHIP_F_SERV_01/${app.curDivisionId}`,
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_SUCCESS_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export const saga = [
  takeLatest(
    AdvShipFServ01Types.ADV_SHIP_F_SERV01_SHOW_BATCH_JOB_STATUS,
    advShipFServ01ShowBatchJobStatus
  ),
  takeLatest(AdvShipFServ01Types.ADV_SHIP_F_SERV01_IMPORT, advShipFServ01Import),
  takeLatest(
    AdvShipFServ01Types.ADV_SHIP_F_SERV01_SHOW_SYNC_SETTING,
    advShipFServ01ShowSyncSetting
  ),
  takeLatest(
    AdvShipFServ01Types.ADV_SHIP_F_SERV01_UPDATE_SYNC_SETTING,
    advShipFServ01UpdateSyncSetting
  )
];
