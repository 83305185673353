/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { OutbOrdAnalysisReportTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const outbOrdAnalysisReportReportLoading = (state, { boolean }) => ({
  ...state,
  reportIsLoading: boolean
});

export const outbOrdAnalysisReportInitOutbOrdAnalysisSuccess = (state, { criteria }) => ({
  ...state,
  criteria
});

export const outbOrdAnalysisReportOutbOrdAnalysisSuccess = (state, { criteria, reportData }) => ({
  ...state,
  criteria,
  reportData
});

export const outbOrdAnalysisReportFetchItemOptionLoading = (state, { boolean }) => ({
  ...state,
  itemIsLoading: boolean
});

export const outbOrdAnalysisReportFetchItemOptionSuccess = (state, { options }) => ({
  ...state,
  itemOptions: options
});

export const outbOrdAnalysisReportFetchItemGroup01OptionLoading = (state, { boolean }) => ({
  ...state,
  itemGroup01IsLoading: boolean
});

export const outbOrdAnalysisReportFetchItemGroup01OptionSuccess = (state, { options }) => ({
  ...state,
  itemGroup01Options: options
});

export const outbOrdAnalysisReportFetchItemGroup02OptionLoading = (state, { boolean }) => ({
  ...state,
  itemGroup02IsLoading: boolean
});

export const outbOrdAnalysisReportFetchItemGroup02OptionSuccess = (state, { options }) => ({
  ...state,
  itemGroup02Options: options
});

export const outbOrdAnalysisReportFetchItemGroup03OptionLoading = (state, { boolean }) => ({
  ...state,
  itemGroup03IsLoading: boolean
});

export const outbOrdAnalysisReportFetchItemGroup03OptionSuccess = (state, { options }) => ({
  ...state,
  itemGroup03Options: options
});

export const outbOrdAnalysisReportFetchDivisionOptionLoading = (state, { boolean }) => ({
  ...state,
  divisionIsLoading: boolean
});

export const outbOrdAnalysisReportFetchDivisionOptionSuccess = (state, { options }) => ({
  ...state,
  divisionOptions: options
});

export const outbOrdAnalysisReportFetchDeliveryPointOptionLoading = (state, { boolean }) => ({
  ...state,
  deliveryPointIsLoading: boolean
});

export const outbOrdAnalysisReportFetchDeliveryPointOptionSuccess = (state, { options }) => ({
  ...state,
  deliveryPointOptions: options
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [OutbOrdAnalysisReportTypes.OUTB_ORD_ANALYSIS_REPORT_REPORT_LOADING]: outbOrdAnalysisReportReportLoading,
  [OutbOrdAnalysisReportTypes.OUTB_ORD_ANALYSIS_REPORT_INIT_OUTB_ORD_ANALYSIS_SUCCESS]: outbOrdAnalysisReportInitOutbOrdAnalysisSuccess,
  [OutbOrdAnalysisReportTypes.OUTB_ORD_ANALYSIS_REPORT_OUTB_ORD_ANALYSIS_SUCCESS]: outbOrdAnalysisReportOutbOrdAnalysisSuccess,

  [OutbOrdAnalysisReportTypes.OUTB_ORD_ANALYSIS_REPORT_FETCH_ITEM_OPTION_LOADING]: outbOrdAnalysisReportFetchItemOptionLoading,
  [OutbOrdAnalysisReportTypes.OUTB_ORD_ANALYSIS_REPORT_FETCH_ITEM_OPTION_SUCCESS]: outbOrdAnalysisReportFetchItemOptionSuccess,

  [OutbOrdAnalysisReportTypes.OUTB_ORD_ANALYSIS_REPORT_FETCH_ITEM_GROUP01_OPTION_LOADING]: outbOrdAnalysisReportFetchItemGroup01OptionLoading,
  [OutbOrdAnalysisReportTypes.OUTB_ORD_ANALYSIS_REPORT_FETCH_ITEM_GROUP01_OPTION_SUCCESS]: outbOrdAnalysisReportFetchItemGroup01OptionSuccess,

  [OutbOrdAnalysisReportTypes.OUTB_ORD_ANALYSIS_REPORT_FETCH_ITEM_GROUP02_OPTION_LOADING]: outbOrdAnalysisReportFetchItemGroup02OptionLoading,
  [OutbOrdAnalysisReportTypes.OUTB_ORD_ANALYSIS_REPORT_FETCH_ITEM_GROUP02_OPTION_SUCCESS]: outbOrdAnalysisReportFetchItemGroup02OptionSuccess,

  [OutbOrdAnalysisReportTypes.OUTB_ORD_ANALYSIS_REPORT_FETCH_ITEM_GROUP03_OPTION_LOADING]: outbOrdAnalysisReportFetchItemGroup03OptionLoading,
  [OutbOrdAnalysisReportTypes.OUTB_ORD_ANALYSIS_REPORT_FETCH_ITEM_GROUP03_OPTION_SUCCESS]: outbOrdAnalysisReportFetchItemGroup03OptionSuccess,

  [OutbOrdAnalysisReportTypes.OUTB_ORD_ANALYSIS_REPORT_FETCH_ITEM_OPTION_LOADING]: outbOrdAnalysisReportFetchItemOptionLoading,
  [OutbOrdAnalysisReportTypes.OUTB_ORD_ANALYSIS_REPORT_FETCH_ITEM_OPTION_SUCCESS]: outbOrdAnalysisReportFetchItemOptionSuccess,

  [OutbOrdAnalysisReportTypes.OUTB_ORD_ANALYSIS_REPORT_FETCH_DIVISION_OPTION_LOADING]: outbOrdAnalysisReportFetchDivisionOptionLoading,
  [OutbOrdAnalysisReportTypes.OUTB_ORD_ANALYSIS_REPORT_FETCH_DIVISION_OPTION_SUCCESS]: outbOrdAnalysisReportFetchDivisionOptionSuccess,

  [OutbOrdAnalysisReportTypes.OUTB_ORD_ANALYSIS_REPORT_FETCH_DELIVERY_POINT_OPTION_LOADING]: outbOrdAnalysisReportFetchDeliveryPointOptionLoading,
  [OutbOrdAnalysisReportTypes.OUTB_ORD_ANALYSIS_REPORT_FETCH_DELIVERY_POINT_OPTION_SUCCESS]: outbOrdAnalysisReportFetchDeliveryPointOptionSuccess
});
