/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { LoadListDetailTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const loadListDetailResetTimestamp = state => ({
  ...state,
  timestamp: Date.now()
});

export const loadListDetailSetHdrId = (state, { hdrId }) => ({
  ...state,
  timestamp: Date.now(),
  documentIsLoading: false,
  hdrId,
  detailIsVisible: false
});

export const loadListDetailShowDocumentLoading = (state, { boolean }) => ({
  ...state,
  documentIsLoading: boolean
});

export const loadListDetailShowHeaderSuccess = (state, { documentHeader }) => ({
  ...state,
  documentHeader
});

export const loadListDetailUpdateDocumentSuccess = (
  state,
  { documentHeader, outbOrdHdrs, inbOrdHdrs }
) => ({
  ...state,
  documentHeader,
  outbOrdHdrs,
  inbOrdHdrs
});

export const loadListDetailShowOutbOrdHdrsSuccess = (state, { outbOrdHdrs }) => ({
  ...state,
  outbOrdHdrs
});

export const loadListDetailShowInbOrdHdrsSuccess = (state, { inbOrdHdrs }) => ({
  ...state,
  inbOrdHdrs
});

export const loadListDetailSearchDocumentLoading = (state, { boolean }) => ({
  ...state,
  documentIsLoading: boolean
});

export const loadListDetailSearchDocumentSuccess = (state, { options }) => ({
  ...state,
  documentOptions: options
});

export const loadListDetailFetchLoadListDetailLoading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const loadListDetailFetchTransportOptionLoading = (state, { boolean }) => ({
  ...state,
  transportIsLoading: boolean
});

export const loadListDetailFetchTransportOptionSuccess = (state, { options }) => ({
  ...state,
  transportOptions: options
});

export const loadListDetailFetchDriver01OptionLoading = (state, { boolean }) => ({
  ...state,
  driver01IsLoading: boolean
});

export const loadListDetailFetchDriver01OptionSuccess = (state, { options }) => ({
  ...state,
  driver01Options: options
});

export const loadListDetailFetchDeliveryMan01OptionLoading = (state, { boolean }) => ({
  ...state,
  deliveryMan01IsLoading: boolean
});

export const loadListDetailFetchDeliveryMan01OptionSuccess = (state, { options }) => ({
  ...state,
  deliveryMan01Options: options
});

export const loadListDetailFetchDeliveryMan02OptionLoading = (state, { boolean }) => ({
  ...state,
  deliveryMan02IsLoading: boolean
});

export const loadListDetailFetchDeliveryMan02OptionSuccess = (state, { options }) => ({
  ...state,
  deliveryMan02Options: options
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [LoadListDetailTypes.LOAD_LIST_DETAIL_RESET_TIMESTAMP]: loadListDetailResetTimestamp,
  [LoadListDetailTypes.LOAD_LIST_DETAIL_SET_HDR_ID]: loadListDetailSetHdrId,
  [LoadListDetailTypes.LOAD_LIST_DETAIL_SHOW_HEADER_SUCCESS]: loadListDetailShowHeaderSuccess,
  [LoadListDetailTypes.LOAD_LIST_DETAIL_UPDATE_DOCUMENT_SUCCESS]: loadListDetailUpdateDocumentSuccess,
  [LoadListDetailTypes.LOAD_LIST_DETAIL_SHOW_DOCUMENT_LOADING]: loadListDetailShowDocumentLoading,
  [LoadListDetailTypes.LOAD_LIST_DETAIL_SHOW_OUTB_ORD_HDRS_SUCCESS]: loadListDetailShowOutbOrdHdrsSuccess,
  [LoadListDetailTypes.LOAD_LIST_DETAIL_SHOW_INB_ORD_HDRS_SUCCESS]: loadListDetailShowInbOrdHdrsSuccess,
  [LoadListDetailTypes.LOAD_LIST_DETAIL_SEARCH_DOCUMENT_LOADING]: loadListDetailSearchDocumentLoading,
  [LoadListDetailTypes.LOAD_LIST_DETAIL_SEARCH_DOCUMENT_SUCCESS]: loadListDetailSearchDocumentSuccess,
  [LoadListDetailTypes.LOAD_LIST_DETAIL_FETCH_LOAD_LIST_DETAIL_LOADING]: loadListDetailFetchLoadListDetailLoading,
  [LoadListDetailTypes.LOAD_LIST_DETAIL_FETCH_TRANSPORT_OPTION_LOADING]: loadListDetailFetchTransportOptionLoading,
  [LoadListDetailTypes.LOAD_LIST_DETAIL_FETCH_TRANSPORT_OPTION_SUCCESS]: loadListDetailFetchTransportOptionSuccess,
  [LoadListDetailTypes.LOAD_LIST_DETAIL_FETCH_DRIVER01_OPTION_LOADING]: loadListDetailFetchDriver01OptionLoading,
  [LoadListDetailTypes.LOAD_LIST_DETAIL_FETCH_DRIVER01_OPTION_SUCCESS]: loadListDetailFetchDriver01OptionSuccess,
  [LoadListDetailTypes.LOAD_LIST_DETAIL_FETCH_DELIVERY_MAN01_OPTION_LOADING]: loadListDetailFetchDeliveryMan01OptionLoading,
  [LoadListDetailTypes.LOAD_LIST_DETAIL_FETCH_DELIVERY_MAN01_OPTION_SUCCESS]: loadListDetailFetchDeliveryMan01OptionSuccess,
  [LoadListDetailTypes.LOAD_LIST_DETAIL_FETCH_DELIVERY_MAN02_OPTION_LOADING]: loadListDetailFetchDeliveryMan02OptionLoading,
  [LoadListDetailTypes.LOAD_LIST_DETAIL_FETCH_DELIVERY_MAN02_OPTION_SUCCESS]: loadListDetailFetchDeliveryMan02OptionSuccess
});
