import React from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';

import { DeleteOutlined, UndoOutlined, SaveOutlined } from '@ant-design/icons';

import { Row, Col, Modal, Table, Button, Popconfirm, Typography } from 'antd';

import { FormikInputNumber, FormikButton } from '../../Components/Formik';

import PalletLabelList01Actions from '../../Stores/PalletLabelList01/Actions';

const { Text } = Typography;

class PalletLabelList01Dialog extends React.PureComponent {
  constructor() {
    super();

    this.handleCancel = this.handleCancel.bind(this);
    this.useOnPrintDocument = this.useOnPrintDocument.bind(this);

    this.getSelectedDocumentColumns = this.getSelectedDocumentColumns.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate() {}

  componentWillUnmount() {}

  handleCancel() {
    const { setWorkspaceVisible } = this.props;

    setWorkspaceVisible(false);
  }

  getSelectedDocumentColumns() {
    const { intl, removeSelectedDocuments } = this.props;

    return [
      {
        align: 'left',
        title: intl.formatMessage({ id: 'barcode' }),
        // sort field
        dataIndex: 'barcode',
        sorter: (a, b) => `${a.barcode}`.localeCompare(b.barcode),
        // filter field
        key: 'barcode',
        render: (text, record) => <>{record.barcode}</>
      },
      {
        align: 'left',
        title: intl.formatMessage({ id: 'created_at' }),
        // sort field
        dataIndex: 'created_at',
        sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
        // filter field
        key: 'created_at',
        render: (text, record) => <>{record.created_at}</>
      },
      {
        align: 'left',
        title: intl.formatMessage({ id: 'print' }),
        // sort field
        dataIndex: 'print_count',
        sorter: (a, b) => a.print_count - b.print_count,
        // filter field
        key: 'print_count',
        render: (text, record) => <>{record.print_count}</>
      },
      {
        align: 'left',
        title: intl.formatMessage({ id: 'first_printed_at' }),
        // sort field
        dataIndex: 'first_printed_at',
        sorter: (a, b) => new Date(a.first_printed_at) - new Date(b.first_printed_at),
        // filter field
        key: 'first_printed_at',
        render: (text, record) => <>{record.first_printed_at}</>
      },
      {
        align: 'left',
        title: intl.formatMessage({ id: 'last_printed_at' }),
        // sort field
        dataIndex: 'last_printed_at',
        sorter: (a, b) => new Date(a.last_printed_at) - new Date(b.last_printed_at),
        // filter field
        key: 'last_printed_at',
        render: (text, record) => <>{record.last_printed_at}</>
      },
      {
        key: 'action',
        render: (text, record) => (
          <>
            <Popconfirm
              placement="left"
              title={intl.formatMessage({ id: 'are_you_sure_to_remove_this_document' })}
              onConfirm={() => {
                removeSelectedDocuments([record]);
              }}
              onCancel={() => {}}
              okText={intl.formatMessage({ id: 'yes' })}
              cancelText={intl.formatMessage({ id: 'cancel' })}
            >
              <Button type="dashed" icon={<DeleteOutlined />} />
            </Popconfirm>
          </>
        )
      }
    ];
  }

  useOnPrintDocument() {
    const { selectedDocuments, printPalletLabelList01 } = this.props;

    const ids = selectedDocuments.map(value => {
      return value.id;
    });

    printPalletLabelList01(ids);
  }

  render() {
    const {
      intl,
      selectedDocuments,
      workspaceIsVisible,
      printIsLoading,
      createPalletLabelList01
    } = this.props;

    return (
      <>
        <Modal
          visible={workspaceIsVisible}
          title={intl.formatMessage({ id: 'manage_pallet_labels' })}
          // style={{top:20}}
          width="80%"
          centered
          // onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <Button
              type="primary"
              key="print"
              disabled={selectedDocuments.length === 0}
              loading={printIsLoading}
              onClick={this.useOnPrintDocument}
            >
              {intl.formatMessage({ id: 'print' })}
            </Button>
          ]}
        >
          <Row type="flex" justify="center" gutter={[0, 8]}>
            <Col span={24}>
              <Formik
                enableReinitialize
                initialValues={{ no_of_pallets: 0 }}
                onSubmit={(values, formikBag) => {
                  createPalletLabelList01(formikBag, values.no_of_pallets);
                }}
                validationSchema={Yup.object().shape({
                  no_of_pallets: Yup.number()
                    .min(0, intl.formatMessage({ id: 'qty_must_be_greater_than_0' }))
                    .max(10, intl.formatMessage({ id: 'qty_must_be_less_than_10' }))
                })}
              >
                {({
                  values,
                  // handleChange,
                  // errors,
                  // setFieldTouched,
                  dirty,
                  // touched,
                  isSubmitting,
                  // isValid,
                  handleSubmit,
                  handleReset
                }) => (
                  <Form>
                    <Row type="flex" justify="center" gutter={[8, 8]}>
                      <Col span={3}>
                        <Text>{intl.formatMessage({ id: 'no_of_pallets' })}</Text>
                      </Col>
                      <Col span={1}>
                        <Text>:</Text>
                      </Col>
                      <Col span={8}>
                        <Field name="no_of_pallets" component={FormikInputNumber} />
                      </Col>
                      <Col span={12} />
                    </Row>

                    <Row type="flex" justify="end" gutter={[0, 16]}>
                      <Col span={5}>
                        <Button
                          type="primary"
                          disabled={!dirty}
                          loading={isSubmitting || printIsLoading}
                          onClick={handleReset}
                          icon={<UndoOutlined />}
                        >
                          {intl.formatMessage({ id: 'reset' })}
                        </Button>
                        <Field
                          type="primary"
                          name="submit_action"
                          value="update"
                          component={FormikButton}
                          loading={isSubmitting || printIsLoading}
                          onClick={handleSubmit}
                          label={intl.formatMessage({
                            id: values.id > 0 ? 'update' : 'create'
                          })}
                          icon={<SaveOutlined />}
                        />
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>
          <Row type="flex" justify="center" gutter={[0, 8]}>
            <Col span={24}>
              <Table
                // rowSelection={rowSelection}
                rowKey="id"
                pagination={false}
                columns={this.getSelectedDocumentColumns()}
                dataSource={selectedDocuments}
                loading={printIsLoading}
                bordered
                title={() => <b>{intl.formatMessage({ id: 'selected_labels' })}</b>}
              />
            </Col>
          </Row>
        </Modal>
      </>
    );
  }
}

PalletLabelList01Dialog.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  setWorkspaceVisible: PropTypes.func,
  workspaceIsVisible: PropTypes.bool,

  removeSelectedDocuments: PropTypes.func,
  selectedDocuments: PropTypes.arrayOf(PropTypes.object),

  printIsLoading: PropTypes.bool,

  printPalletLabelList01: PropTypes.func,
  createPalletLabelList01: PropTypes.func
};

PalletLabelList01Dialog.defaultProps = {
  intl: {},
  setWorkspaceVisible() {},
  workspaceIsVisible: false,

  removeSelectedDocuments() {},
  selectedDocuments: [],

  printIsLoading: false,

  printPalletLabelList01() {},
  createPalletLabelList01() {}
};

const mapStateToProps = state => ({
  workspaceIsVisible: state.palletLabelList01.workspaceIsVisible,

  selectedDocuments: state.palletLabelList01.selectedDocuments,

  printIsLoading: state.palletLabelList01.printIsLoading
});

const mapDispatchToProps = dispatch => ({
  setWorkspaceVisible: boolean =>
    dispatch(PalletLabelList01Actions.palletLabelList01SetWorkspaceVisible(boolean)),

  removeSelectedDocuments: selectedDocuments =>
    dispatch(PalletLabelList01Actions.palletLabelList01RemoveSelectedDocuments(selectedDocuments)),

  printPalletLabelList01: ids =>
    dispatch(PalletLabelList01Actions.palletLabelList01PrintPalletLabelList01(ids)),

  createPalletLabelList01: (formikBag, noOfPallets) =>
    dispatch(
      PalletLabelList01Actions.palletLabelList01CreatePalletLabelList01(formikBag, noOfPallets)
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PalletLabelList01Dialog));
