import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  slsInvIndexResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  slsInvIndexFetchSlsInvIndex: ['currentPage', 'sorts', 'filters', 'pageSize'],
  slsInvIndexFetchSlsInvIndexLoading: ['boolean'],
  slsInvIndexFetchSlsInvIndexSuccess: ['documents', 'currentPage', 'lastPage', 'total', 'pageSize'],
  slsInvIndexAddSelectedDocuments: ['selectedDocuments'],
  slsInvIndexRemoveSelectedDocuments: ['selectedDocuments'],
  slsInvIndexSetWorkspaceVisible: ['boolean'],
  slsInvIndexGoToDocument: ['hdrId'],
  slsInvIndexGoToAudit: ['hdrId'],

  slsInvIndexWorkspaceLoading: ['boolean'],
  slsInvIndexResetSlsInvSync01: ['hdrIds'],
  slsInvIndexResetSlsInvSync01Success: null
});

export const SlsInvIndexTypes = Types;
export default Creators;
