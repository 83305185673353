import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  countAdjSync01ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  countAdjSync01GoToDocument: ['hdrId'],
  countAdjSync01FetchCountAdjSync01: ['currentPage', 'sorts', 'filters', 'pageSize'],
  countAdjSync01FetchCountAdjSync01Loading: ['boolean'],
  countAdjSync01FetchCountAdjSync01Success: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],
  countAdjSync01AddSelectedDocuments: ['selectedDocuments'],
  countAdjSync01RemoveSelectedDocuments: ['selectedDocuments'],
  countAdjSync01SetWorkspaceVisible: ['boolean'],

  countAdjSync01SyncNow: ['hdrIds'],
  countAdjSync01SyncNowSuccess: null,
  countAdjSync01SyncLoading: ['boolean']
});

export const CountAdjSync01Types = Types;
export default Creators;
