import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  loadListIndexResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  loadListIndexGoToPrint: ['document'],
  loadListIndexGoToDocument: ['hdrId'],
  loadListIndexGoToAudit: ['hdrId'],
  loadListIndexFetchLoadListIndex: ['currentPage', 'sorts', 'filters', 'pageSize'],
  loadListIndexFetchLoadListIndexLoading: ['boolean'],
  loadListIndexFetchLoadListIndexSuccess: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],
  loadListIndexAddSelectedDocuments: ['selectedDocuments'],
  loadListIndexRemoveSelectedDocuments: ['selectedDocuments'],
  loadListIndexSetWorkspaceVisible: ['boolean'],

  loadListIndexWorkspaceLoading: ['boolean'],
  loadListIndexResetLoadListSync01: ['hdrIds'],
  loadListIndexResetLoadListSync01Success: null
});

export const LoadListIndexTypes = Types;
export default Creators;
