/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { AuditRes01Types } from './Actions';
import INITIAL_STATE from './InitialState';

export const auditRes01ResetTimestamp = (state, { currentPage, sorts, filters, pageSize }) => ({
  ...state,
  currentPage: currentPage || state.currentPage,
  sorts: sorts || state.sorts,
  filters: filters || state.filters,
  pageSize: pageSize || state.pageSize,
  timestamp: Date.now()
});

export const auditRes01SetResType = (state, { resType, resId, auditType }) => ({
  ...state,
  resType,
  resId,
  auditType
});

export const auditRes01FetchAuditTypeLoading = (state, { boolean }) => ({
  ...state,
  auditTypeIsLoading: boolean
});

export const auditRes01FetchAuditTypeSuccess = (state, { types }) => ({
  ...state,
  auditTypes: types
});

export const auditRes01FetchAuditRes01Loading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const auditRes01FetchAuditRes01Success = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  documents,
  currentPage,
  lastPage,
  total,
  pageSize
});

export const auditRes01SetExpandedRows = (state, { expandedRows }) => ({
  ...state,
  expandedRows
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [AuditRes01Types.AUDIT_RES01_RESET_TIMESTAMP]: auditRes01ResetTimestamp,
  [AuditRes01Types.AUDIT_RES01_SET_RES_TYPE]: auditRes01SetResType,
  [AuditRes01Types.AUDIT_RES01_FETCH_AUDIT_TYPE_LOADING]: auditRes01FetchAuditTypeLoading,
  [AuditRes01Types.AUDIT_RES01_FETCH_AUDIT_TYPE_SUCCESS]: auditRes01FetchAuditTypeSuccess,
  [AuditRes01Types.AUDIT_RES01_FETCH_AUDIT_RES01_LOADING]: auditRes01FetchAuditRes01Loading,
  [AuditRes01Types.AUDIT_RES01_FETCH_AUDIT_RES01_SUCCESS]: auditRes01FetchAuditRes01Success,
  [AuditRes01Types.AUDIT_RES01_SET_EXPANDED_ROWS]: auditRes01SetExpandedRows
});
