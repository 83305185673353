import React from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';

import { UndoOutlined, PlayCircleOutlined } from '@ant-design/icons';

import { Button, Row, Col, Typography, Radio } from 'antd';
import {
  FormikTransfer,
  FormikSelect,
  FormikButton,
  FormikRadio,
  FormikInput
} from '../../Components/Formik';
import StockReorderPlanningV2ReportActions from '../../Stores/StockReorderPlanningV2Report/Actions';

const { Text } = Typography;

class CriteriaForm extends React.PureComponent {
  constructor() {
    super();

    this.handleSearchCompany = this.handleSearchCompany.bind(this);
    this.handleSearchItem = this.handleSearchItem.bind(this);
    this.handleSearchItemGroup01 = this.handleSearchItemGroup01.bind(this);
    this.handleSearchItemGroup02 = this.handleSearchItemGroup02.bind(this);
    this.handleSearchItemGroup03 = this.handleSearchItemGroup03.bind(this);

    this.handleSearchLocation = this.handleSearchLocation.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate() {}

  componentWillUnmount() {}

  handleSearchCompany(form, value) {
    const { fetchCompanyOptions } = this.props;

    fetchCompanyOptions(value);
  }

  handleSearchItem(form, value) {
    const { fetchItemOptions } = this.props;

    fetchItemOptions(value);
  }

  handleSearchItemGroup01(form, value) {
    const { fetchItemGroup01Options } = this.props;

    fetchItemGroup01Options(value);
  }

  handleSearchItemGroup02(form, value) {
    const { fetchItemGroup02Options } = this.props;

    fetchItemGroup02Options(value);
  }

  handleSearchItemGroup03(form, value) {
    const { fetchItemGroup03Options } = this.props;

    fetchItemGroup03Options(value);
  }

  handleSearchLocation(form, value) {
    const { fetchLocationOptions } = this.props;

    fetchLocationOptions(value);
  }

  render() {
    const {
      intl,
      criteria,
      reportIsLoading,
      stockReorderPlanningV2,
      companyOptions,
      companyIsLoading,
      itemOptions,
      itemIsLoading,
      itemGroup01Options,
      itemGroup01IsLoading,
      itemGroup02Options,
      itemGroup02IsLoading,
      itemGroup03Options,
      itemGroup03IsLoading,
      locationOptions,
      locationIsLoading
    } = this.props;

    const isShowCaseQtyOptions = [
      <Radio.Button key={1} value={1}>
        {intl.formatMessage({ id: 'CASES' })}
      </Radio.Button>,
      <Radio.Button key={0} value={0}>
        {intl.formatMessage({ id: 'UNIT' })}
      </Radio.Button>
    ];

    const noOfMonthsOptions = [
      <Radio.Button key={6} value={6}>
        {`6 ${intl.formatMessage({ id: 'month' })}`}
      </Radio.Button>,
      <Radio.Button key={9} value={9}>
        {`9 ${intl.formatMessage({ id: 'month' })}`}
      </Radio.Button>,
      <Radio.Button key={12} value={12}>
        {`12 ${intl.formatMessage({ id: 'month' })}`}
      </Radio.Button>
    ];

    const monthlyPickOptions = [
      <Radio.Button key={0} value={0}>
        {intl.formatMessage({ id: 'all' })}
      </Radio.Button>,
      <Radio.Button key={1} value={1}>
        {intl.formatMessage({ id: 'sales_only' })}
      </Radio.Button>,
      <Radio.Button key={2} value={2}>
        {intl.formatMessage({ id: 'transfer_only' })}
      </Radio.Button>
    ];

    return (
      <Formik
        enableReinitialize
        initialValues={criteria}
        onSubmit={(values, formikBag) => {
          stockReorderPlanningV2(formikBag, values);
        }}
        validationSchema={Yup.object().shape({
          doc_date: Yup.date().min(
            new Date(1969, 12, 1),
            intl.formatMessage({ id: 'doc_date_is_required' })
          )
        })}
      >
        {({
          // values,
          // handleChange,
          // errors,
          // setFieldTouched,
          dirty,
          // touched,
          isSubmitting,
          // isValid,
          handleSubmit,
          handleReset
        }) => (
          <Form>
            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'is_show_case_qty' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="is_show_case_qty"
                  component={FormikRadio}
                  options={isShowCaseQtyOptions}
                  disabled={isSubmitting || reportIsLoading}
                />
              </Col>
            </Row>
            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'no_of_months' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="no_of_months"
                  component={FormikRadio}
                  options={noOfMonthsOptions}
                  disabled={isSubmitting || reportIsLoading}
                />
              </Col>
            </Row>
            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'monthly_pick_options' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="monthly_pick_options"
                  component={FormikRadio}
                  options={monthlyPickOptions}
                  disabled={isSubmitting || reportIsLoading}
                />
              </Col>
            </Row>
            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'company' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="company_ids_select2"
                  component={FormikSelect}
                  showArrow
                  filterOption={false}
                  onSearch={this.handleSearchCompany}
                  notFoundContent={null}
                  loading={companyIsLoading}
                  options={companyOptions}
                  disabled={isSubmitting || reportIsLoading}
                  selectMode="multiple"
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'item' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="item_ids_select2"
                  component={FormikSelect}
                  showArrow
                  filterOption={false}
                  onSearch={this.handleSearchItem}
                  notFoundContent={null}
                  loading={itemIsLoading}
                  options={itemOptions}
                  disabled={isSubmitting || reportIsLoading}
                  selectMode="multiple"
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'item_ref_code_01' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="item_ref_code_01"
                  component={FormikInput}
                  showArrow
                  loading={itemIsLoading}
                  options={itemOptions}
                  disabled={isSubmitting || reportIsLoading}
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'brand' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="item_group_01_ids_select2"
                  component={FormikSelect}
                  showArrow
                  filterOption={false}
                  onSearch={this.handleSearchItemGroup01}
                  notFoundContent={null}
                  loading={itemGroup01IsLoading}
                  options={itemGroup01Options}
                  disabled={isSubmitting || reportIsLoading}
                  selectMode="multiple"
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'category' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="item_group_02_ids_select2"
                  component={FormikSelect}
                  showArrow
                  filterOption={false}
                  onSearch={this.handleSearchItemGroup02}
                  notFoundContent={null}
                  loading={itemGroup02IsLoading}
                  options={itemGroup02Options}
                  disabled={isSubmitting || reportIsLoading}
                  selectMode="multiple"
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'manufacturer' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="item_group_03_ids_select2"
                  component={FormikSelect}
                  showArrow
                  filterOption={false}
                  onSearch={this.handleSearchItemGroup03}
                  notFoundContent={null}
                  loading={itemGroup03IsLoading}
                  options={itemGroup03Options}
                  disabled={isSubmitting || reportIsLoading}
                  selectMode="multiple"
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'location' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="location_ids_select2"
                  component={FormikSelect}
                  showArrow
                  filterOption={false}
                  onSearch={this.handleSearchLocation}
                  notFoundContent={null}
                  loading={locationIsLoading}
                  options={locationOptions}
                  disabled={isSubmitting || reportIsLoading}
                  selectMode="multiple"
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={24}>
                <Field
                  name="columns"
                  component={FormikTransfer}
                  intl={intl}
                  disabled={isSubmitting || reportIsLoading}
                />
              </Col>
            </Row>

            <Row type="flex" justify="space-between" gutter={[0, 16]}>
              <Col span={24}>
                <Button
                  disabled={!dirty}
                  loading={isSubmitting || reportIsLoading}
                  onClick={handleReset}
                  icon={<UndoOutlined />}
                  type="primary"
                >
                  {intl.formatMessage({ id: 'reset' })}
                </Button>
                <Field
                  name="submit_action"
                  value="run_report"
                  component={FormikButton}
                  loading={isSubmitting || reportIsLoading}
                  onClick={handleSubmit}
                  label={intl.formatMessage({
                    id: 'run_report'
                  })}
                  icon={<PlayCircleOutlined />}
                  type="primary"
                />
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    );
  }
}

CriteriaForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,

  // eslint-disable-next-line react/forbid-prop-types
  criteria: PropTypes.object,
  reportIsLoading: PropTypes.bool,
  stockReorderPlanningV2: PropTypes.func,
  fetchCompanyOptions: PropTypes.func,
  fetchItemOptions: PropTypes.func,
  fetchItemGroup01Options: PropTypes.func,
  fetchItemGroup02Options: PropTypes.func,
  fetchItemGroup03Options: PropTypes.func,
  fetchLocationOptions: PropTypes.func,

  companyOptions: PropTypes.arrayOf(PropTypes.object),
  companyIsLoading: PropTypes.bool,

  itemOptions: PropTypes.arrayOf(PropTypes.object),
  itemIsLoading: PropTypes.bool,

  itemGroup01Options: PropTypes.arrayOf(PropTypes.object),
  itemGroup01IsLoading: PropTypes.bool,

  itemGroup02Options: PropTypes.arrayOf(PropTypes.object),
  itemGroup02IsLoading: PropTypes.bool,

  itemGroup03Options: PropTypes.arrayOf(PropTypes.object),
  itemGroup03IsLoading: PropTypes.bool,

  locationOptions: PropTypes.arrayOf(PropTypes.object),
  locationIsLoading: PropTypes.bool
};

CriteriaForm.defaultProps = {
  intl: {},

  criteria: {},
  reportIsLoading: false,
  stockReorderPlanningV2() {},
  fetchCompanyOptions() {},
  fetchItemOptions() {},
  fetchItemGroup01Options() {},
  fetchItemGroup02Options() {},
  fetchItemGroup03Options() {},
  fetchLocationOptions() {},

  companyOptions: [],
  companyIsLoading: false,

  itemOptions: [],
  itemIsLoading: false,

  itemGroup01Options: [],
  itemGroup01IsLoading: false,

  itemGroup02Options: [],
  itemGroup02IsLoading: false,

  itemGroup03Options: [],
  itemGroup03IsLoading: false,

  locationOptions: [],
  locationIsLoading: false
};

const mapStateToProps = state => ({
  timestamp: state.stockReorderPlanningV2Report.timestamp,
  criteria: state.stockReorderPlanningV2Report.criteria,

  reportIsLoading: state.stockReorderPlanningV2Report.reportIsLoading,

  companyOptions: state.stockReorderPlanningV2Report.companyOptions,
  companyIsLoading: state.stockReorderPlanningV2Report.companyIsLoading,

  itemOptions: state.stockReorderPlanningV2Report.itemOptions,
  itemIsLoading: state.stockReorderPlanningV2Report.itemIsLoading,

  itemGroup01Options: state.stockReorderPlanningV2Report.itemGroup01Options,
  itemGroup01IsLoading: state.stockReorderPlanningV2Report.itemGroup01IsLoading,

  itemGroup02Options: state.stockReorderPlanningV2Report.itemGroup02Options,
  itemGroup02IsLoading: state.stockReorderPlanningV2Report.itemGroup02IsLoading,

  itemGroup03Options: state.stockReorderPlanningV2Report.itemGroup03Options,
  itemGroup03IsLoading: state.stockReorderPlanningV2Report.itemGroup03IsLoading,

  locationOptions: state.stockReorderPlanningV2Report.locationOptions,
  locationIsLoading: state.stockReorderPlanningV2Report.locationIsLoading
});

const mapDispatchToProps = dispatch => ({
  stockReorderPlanningV2: (formikBag, criteria) =>
    dispatch(
      StockReorderPlanningV2ReportActions.stockReorderPlanningV2ReportStockReorderPlanningV2(
        formikBag,
        criteria
      )
    ),

  fetchCompanyOptions: search =>
    dispatch(
      StockReorderPlanningV2ReportActions.stockReorderPlanningV2ReportFetchCompanyOptions(search)
    ),
  fetchItemOptions: search =>
    dispatch(
      StockReorderPlanningV2ReportActions.stockReorderPlanningV2ReportFetchItemOptions(search)
    ),
  fetchItemGroup01Options: search =>
    dispatch(
      StockReorderPlanningV2ReportActions.stockReorderPlanningV2ReportFetchItemGroup01Options(
        search
      )
    ),
  fetchItemGroup02Options: search =>
    dispatch(
      StockReorderPlanningV2ReportActions.stockReorderPlanningV2ReportFetchItemGroup02Options(
        search
      )
    ),
  fetchItemGroup03Options: search =>
    dispatch(
      StockReorderPlanningV2ReportActions.stockReorderPlanningV2ReportFetchItemGroup03Options(
        search
      )
    ),
  fetchLocationOptions: search =>
    dispatch(
      StockReorderPlanningV2ReportActions.stockReorderPlanningV2ReportFetchLocationOptions(search)
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CriteriaForm));
