/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { PickListReservedStockReportTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const pickListReservedStockReportReportLoading = (state, { boolean }) => ({
  ...state,
  reportIsLoading: boolean
});

export const pickListReservedStockReportInitPickListReservedStockSuccess = (
  state,
  { criteria }
) => ({
  ...state,
  criteria
});

export const pickListReservedStockReportPickListReservedStockSuccess = (
  state,
  { criteria, reportData }
) => ({
  ...state,
  criteria,
  reportData
});

export const pickListReservedStockReportFetchItemOptionLoading = (state, { boolean }) => ({
  ...state,
  itemIsLoading: boolean
});

export const pickListReservedStockReportFetchItemOptionSuccess = (state, { options }) => ({
  ...state,
  itemOptions: options
});

export const pickListReservedStockReportFetchItemGroup01OptionLoading = (state, { boolean }) => ({
  ...state,
  itemGroup01IsLoading: boolean
});

export const pickListReservedStockReportFetchItemGroup01OptionSuccess = (state, { options }) => ({
  ...state,
  itemGroup01Options: options
});

export const pickListReservedStockReportFetchItemGroup02OptionLoading = (state, { boolean }) => ({
  ...state,
  itemGroup02IsLoading: boolean
});

export const pickListReservedStockReportFetchItemGroup02OptionSuccess = (state, { options }) => ({
  ...state,
  itemGroup02Options: options
});

export const pickListReservedStockReportFetchItemGroup03OptionLoading = (state, { boolean }) => ({
  ...state,
  itemGroup03IsLoading: boolean
});

export const pickListReservedStockReportFetchItemGroup03OptionSuccess = (state, { options }) => ({
  ...state,
  itemGroup03Options: options
});

export const pickListReservedStockReportFetchStorageBinOptionLoading = (state, { boolean }) => ({
  ...state,
  storageBinIsLoading: boolean
});

export const pickListReservedStockReportFetchStorageBinOptionSuccess = (state, { options }) => ({
  ...state,
  storageBinOptions: options
});

export const pickListReservedStockReportFetchStorageRowOptionLoading = (state, { boolean }) => ({
  ...state,
  storageRowIsLoading: boolean
});

export const pickListReservedStockReportFetchStorageRowOptionSuccess = (state, { options }) => ({
  ...state,
  storageRowOptions: options
});

export const pickListReservedStockReportFetchStorageBayOptionLoading = (state, { boolean }) => ({
  ...state,
  storageBayIsLoading: boolean
});

export const pickListReservedStockReportFetchStorageBayOptionSuccess = (state, { options }) => ({
  ...state,
  storageBayOptions: options
});

export const pickListReservedStockReportFetchLocationOptionLoading = (state, { boolean }) => ({
  ...state,
  locationIsLoading: boolean
});

export const pickListReservedStockReportFetchLocationOptionSuccess = (state, { options }) => ({
  ...state,
  locationOptions: options
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [PickListReservedStockReportTypes.PICK_LIST_RESERVED_STOCK_REPORT_REPORT_LOADING]: pickListReservedStockReportReportLoading,
  [PickListReservedStockReportTypes.PICK_LIST_RESERVED_STOCK_REPORT_INIT_PICK_LIST_RESERVED_STOCK_SUCCESS]: pickListReservedStockReportInitPickListReservedStockSuccess,
  [PickListReservedStockReportTypes.PICK_LIST_RESERVED_STOCK_REPORT_PICK_LIST_RESERVED_STOCK_SUCCESS]: pickListReservedStockReportPickListReservedStockSuccess,

  [PickListReservedStockReportTypes.PICK_LIST_RESERVED_STOCK_REPORT_FETCH_ITEM_OPTION_LOADING]: pickListReservedStockReportFetchItemOptionLoading,
  [PickListReservedStockReportTypes.PICK_LIST_RESERVED_STOCK_REPORT_FETCH_ITEM_OPTION_SUCCESS]: pickListReservedStockReportFetchItemOptionSuccess,

  [PickListReservedStockReportTypes.PICK_LIST_RESERVED_STOCK_REPORT_FETCH_ITEM_GROUP01_OPTION_LOADING]: pickListReservedStockReportFetchItemGroup01OptionLoading,
  [PickListReservedStockReportTypes.PICK_LIST_RESERVED_STOCK_REPORT_FETCH_ITEM_GROUP01_OPTION_SUCCESS]: pickListReservedStockReportFetchItemGroup01OptionSuccess,

  [PickListReservedStockReportTypes.PICK_LIST_RESERVED_STOCK_REPORT_FETCH_ITEM_GROUP02_OPTION_LOADING]: pickListReservedStockReportFetchItemGroup02OptionLoading,
  [PickListReservedStockReportTypes.PICK_LIST_RESERVED_STOCK_REPORT_FETCH_ITEM_GROUP02_OPTION_SUCCESS]: pickListReservedStockReportFetchItemGroup02OptionSuccess,

  [PickListReservedStockReportTypes.PICK_LIST_RESERVED_STOCK_REPORT_FETCH_ITEM_GROUP03_OPTION_LOADING]: pickListReservedStockReportFetchItemGroup03OptionLoading,
  [PickListReservedStockReportTypes.PICK_LIST_RESERVED_STOCK_REPORT_FETCH_ITEM_GROUP03_OPTION_SUCCESS]: pickListReservedStockReportFetchItemGroup03OptionSuccess,

  [PickListReservedStockReportTypes.PICK_LIST_RESERVED_STOCK_REPORT_FETCH_STORAGE_BIN_OPTION_LOADING]: pickListReservedStockReportFetchStorageBinOptionLoading,
  [PickListReservedStockReportTypes.PICK_LIST_RESERVED_STOCK_REPORT_FETCH_STORAGE_BIN_OPTION_SUCCESS]: pickListReservedStockReportFetchStorageBinOptionSuccess,

  [PickListReservedStockReportTypes.PICK_LIST_RESERVED_STOCK_REPORT_FETCH_STORAGE_ROW_OPTION_LOADING]: pickListReservedStockReportFetchStorageRowOptionLoading,
  [PickListReservedStockReportTypes.PICK_LIST_RESERVED_STOCK_REPORT_FETCH_STORAGE_ROW_OPTION_SUCCESS]: pickListReservedStockReportFetchStorageRowOptionSuccess,

  [PickListReservedStockReportTypes.PICK_LIST_RESERVED_STOCK_REPORT_FETCH_STORAGE_BAY_OPTION_LOADING]: pickListReservedStockReportFetchStorageBayOptionLoading,
  [PickListReservedStockReportTypes.PICK_LIST_RESERVED_STOCK_REPORT_FETCH_STORAGE_BAY_OPTION_SUCCESS]: pickListReservedStockReportFetchStorageBayOptionSuccess,

  [PickListReservedStockReportTypes.PICK_LIST_RESERVED_STOCK_REPORT_FETCH_LOCATION_OPTION_LOADING]: pickListReservedStockReportFetchLocationOptionLoading,
  [PickListReservedStockReportTypes.PICK_LIST_RESERVED_STOCK_REPORT_FETCH_LOCATION_OPTION_SUCCESS]: pickListReservedStockReportFetchLocationOptionSuccess
});
