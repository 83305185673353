/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { PrincipalDetailTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const principalDetailResetModelTimestamp = state => ({
  ...state,
  modelTimestamp: Date.now()
});

export const principalDetailSetResId = (state, { resId }) => ({
  ...state,
  modelTimestamp: Date.now(),
  resId
});

export const principalDetailShowModelLoading = (state, { boolean }) => ({
  ...state,
  modelIsLoading: boolean
});

export const principalDetailShowModelSuccess = (state, { model }) => ({
  ...state,
  model
});

export const principalDetailUpdateModelSuccess = (state, { model }) => ({
  ...state,
  model
});

export const principalDetailShowDeliveryPointSuccess = (
  state,
  { deliveryPoints, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  deliveryPoints,
  deliveryPointCurrentPage: currentPage,
  deliveryPointLastPage: lastPage,
  deliveryPointTotal: total,
  deliveryPointPageSize: pageSize
});

export const principalDetailFetchAreaOptionLoading = (state, { boolean }) => ({
  ...state,
  areaIsLoading: boolean
});

export const principalDetailFetchAreaOptionSuccess = (state, { options }) => ({
  ...state,
  areaOptions: options
});

export const principalDetailResetDeliveryPointTimestamp = (
  state,
  { currentPage, sorts, filters, pageSize }
) => ({
  ...state,
  deliveryPointCurrentPage: currentPage || state.deliveryPointCurrentPage,
  deliveryPointSorts: sorts || state.deliveryPointSorts,
  deliveryPointFilters: filters || state.deliveryPointFilters,
  deliveryPointPageSize: pageSize || state.deliveryPointPageSize,
  deliveryPointTimestamp: Date.now()
});

export const principalDetailShowBizPartnerSuccess = (
  state,
  { bizPartners, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  bizPartners,
  bizPartnerCurrentPage: currentPage,
  bizPartnerLastPage: lastPage,
  bizPartnerTotal: total,
  bizPartnerPageSize: pageSize
});

export const principalDetailResetBizPartnerTimestamp = (
  state,
  { currentPage, sorts, filters, pageSize }
) => ({
  ...state,
  bizPartnerCurrentPage: currentPage || state.bizPartnerCurrentPage,
  bizPartnerSorts: sorts || state.bizPartnerSorts,
  bizPartnerFilters: filters || state.bizPartnerFilters,
  bizPartnerPageSize: pageSize || state.bizPartnerPageSize,
  bizPartnerTimestamp: Date.now()
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [PrincipalDetailTypes.PRINCIPAL_DETAIL_RESET_MODEL_TIMESTAMP]: principalDetailResetModelTimestamp,
  [PrincipalDetailTypes.PRINCIPAL_DETAIL_SET_RES_ID]: principalDetailSetResId,
  [PrincipalDetailTypes.PRINCIPAL_DETAIL_SHOW_MODEL_LOADING]: principalDetailShowModelLoading,
  [PrincipalDetailTypes.PRINCIPAL_DETAIL_SHOW_MODEL_SUCCESS]: principalDetailShowModelSuccess,
  [PrincipalDetailTypes.PRINCIPAL_DETAIL_UPDATE_MODEL_SUCCESS]: principalDetailUpdateModelSuccess,

  [PrincipalDetailTypes.PRINCIPAL_DETAIL_FETCH_AREA_OPTION_LOADING]: principalDetailFetchAreaOptionLoading,
  [PrincipalDetailTypes.PRINCIPAL_DETAIL_FETCH_AREA_OPTION_SUCCESS]: principalDetailFetchAreaOptionSuccess,

  [PrincipalDetailTypes.PRINCIPAL_DETAIL_RESET_DELIVERY_POINT_TIMESTAMP]: principalDetailResetDeliveryPointTimestamp,
  [PrincipalDetailTypes.PRINCIPAL_DETAIL_SHOW_DELIVERY_POINT_SUCCESS]: principalDetailShowDeliveryPointSuccess,

  [PrincipalDetailTypes.PRINCIPAL_DETAIL_RESET_BIZ_PARTNER_TIMESTAMP]: principalDetailResetBizPartnerTimestamp,
  [PrincipalDetailTypes.PRINCIPAL_DETAIL_SHOW_BIZ_PARTNER_SUCCESS]: principalDetailShowBizPartnerSuccess
});
