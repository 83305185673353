import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  lspSlsOrdDetailResetTimestamp: null,
  lspSlsOrdDetailSetHdrId: ['hdrId', 'itemId'],

  lspSlsOrdDetailInitHeader: null,
  lspSlsOrdDetailShowHeader: ['hdrId'],
  lspSlsOrdDetailShowHeaderSuccess: ['documentHeader'],

  lspSlsOrdDetailUpdateHeader: ['formikBag', 'documentHeader'],
  lspSlsOrdDetailCreateHeader: ['formikBag', 'documentHeader'],
  lspSlsOrdDetailUpdateDocumentSuccess: ['documentHeader', 'documentDetails'],

  lspSlsOrdDetailShowDocumentLoading: ['boolean'],
  lspSlsOrdDetailTransitionToStatus: ['formikBag', 'hdrId', 'docStatus'],

  lspSlsOrdDetailShowDetails: ['hdrId'],
  lspSlsOrdDetailShowDetailsSuccess: ['documentDetails'],

  lspSlsOrdDetailSetDetailVisible: ['boolean'],
  lspSlsOrdDetailSetDocumentDetail: ['documentDetail'],

  lspSlsOrdDetailUpdateDetails: ['formikBag', 'hdrId', 'documentDetails'],
  lspSlsOrdDetailCreateDetail: ['formikBag', 'hdrId', 'documentDetail'],
  lspSlsOrdDetailDeleteDetail: ['hdrId', 'documentDetail'],

  lspSlsOrdDetailFetchDebtorOptions: ['search'],
  lspSlsOrdDetailFetchDebtorOptionLoading: ['boolean'],
  lspSlsOrdDetailFetchDebtorOptionSuccess: ['options'],
  lspSlsOrdDetailChangeDebtor: ['formikBag', 'debtorId'],

  lspSlsOrdDetailFetchSalesmanOptions: ['search'],
  lspSlsOrdDetailFetchSalesmanOptionLoading: ['boolean'],
  lspSlsOrdDetailFetchSalesmanOptionSuccess: ['options'],

  lspSlsOrdDetailFetchDeliveryPointOptions: ['debtorId', 'search'],
  lspSlsOrdDetailFetchDeliveryPointOptionLoading: ['boolean'],
  lspSlsOrdDetailFetchDeliveryPointOptionSuccess: ['options'],
  lspSlsOrdDetailChangeDeliveryPoint: ['formikBag', 'deliveryPointId'],

  lspSlsOrdDetailFetchCreditTermOptions: ['search'],
  lspSlsOrdDetailFetchCreditTermOptionLoading: ['boolean'],
  lspSlsOrdDetailFetchCreditTermOptionSuccess: ['options'],

  lspSlsOrdDetailFetchCurrencyOptions: ['search'],
  lspSlsOrdDetailFetchCurrencyOptionLoading: ['boolean'],
  lspSlsOrdDetailFetchCurrencyOptionSuccess: ['options'],
  lspSlsOrdDetailChangeCurrency: ['formikBag', 'currencyId'],

  lspSlsOrdDetailFetchLocationOptions: ['search'],
  lspSlsOrdDetailFetchLocationOptionLoading: ['boolean'],
  lspSlsOrdDetailFetchLocationOptionSuccess: ['options'],

  lspSlsOrdDetailFetchItemOptions: ['search'],
  lspSlsOrdDetailFetchItemOptionLoading: ['boolean'],
  lspSlsOrdDetailFetchItemOptionSuccess: ['options'],
  lspSlsOrdDetailChangeItem: ['formikBag', 'hdrId', 'itemId'],

  lspSlsOrdDetailFetchItemBatchOptions: ['itemId', 'search'],
  lspSlsOrdDetailFetchItemBatchOptionLoading: ['boolean'],
  lspSlsOrdDetailFetchItemBatchOptionSuccess: ['options'],
  lspSlsOrdDetailChangeItemBatch: ['formikBag', 'hdrId', 'itemBatchId'],

  lspSlsOrdDetailFetchUomOptions: ['itemId', 'search'],
  lspSlsOrdDetailFetchUomOptionLoading: ['boolean'],
  lspSlsOrdDetailFetchUomOptionSuccess: ['options'],
  lspSlsOrdDetailChangeUom: ['formikBag', 'hdrId', 'itemId', 'uomId'],

  lspSlsOrdDetailFetchProjectOptions: ['search'],
  lspSlsOrdDetailFetchProjectOptionLoading: ['boolean'],
  lspSlsOrdDetailFetchProjectOptionSuccess: ['options']
});

export const LspSlsOrdDetailTypes = Types;
export default Creators;
