/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { RtnRcptDetailTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const rtnRcptDetailResetTimestamp = state => ({
  ...state,
  timestamp: Date.now()
});

export const rtnRcptDetailSetHdrId = (state, { hdrId, itemId }) => ({
  ...state,
  timestamp: Date.now(),
  documentIsLoading: false,
  hdrId,
  itemId,
  detailIsVisible: false,
  salesmanOptions: [],
  deliveryPointOptions: [],
  creditTermOptions: [],
  currencyOptions: []
});

export const rtnRcptDetailShowDocumentLoading = (state, { boolean }) => ({
  ...state,
  documentIsLoading: boolean
});

export const rtnRcptDetailShowHeaderSuccess = (state, { documentHeader }) => ({
  ...state,
  documentHeader
});

export const rtnRcptDetailUpdateDocumentSuccess = (state, { documentHeader, documentDetails }) => ({
  ...state,
  documentHeader,
  documentDetails
});

export const rtnRcptDetailShowDetailsSuccess = (state, { documentDetails }) => ({
  ...state,
  documentDetails
});

export const rtnRcptDetailSetDetailVisible = (state, { boolean }) => ({
  ...state,
  detailIsVisible: boolean
});

export const rtnRcptDetailSetDocumentDetail = (state, { documentDetail }) => ({
  ...state,
  documentDetail,
  itemOptions: [],
  uomOptions: []
});

export const rtnRcptDetailFetchSalesmanOptionLoading = (state, { boolean }) => ({
  ...state,
  salesmanIsLoading: boolean
});

export const rtnRcptDetailFetchSalesmanOptionSuccess = (state, { options }) => ({
  ...state,
  salesmanOptions: options
});

export const rtnRcptDetailFetchDeliveryPointOptionLoading = (state, { boolean }) => ({
  ...state,
  deliveryPointIsLoading: boolean
});

export const rtnRcptDetailFetchDeliveryPointOptionSuccess = (state, { options }) => ({
  ...state,
  deliveryPointOptions: options
});

export const rtnRcptDetailFetchCreditTermOptionLoading = (state, { boolean }) => ({
  ...state,
  creditTermIsLoading: boolean
});

export const rtnRcptDetailFetchCreditTermOptionSuccess = (state, { options }) => ({
  ...state,
  creditTermOptions: options
});

export const rtnRcptDetailFetchCurrencyOptionLoading = (state, { boolean }) => ({
  ...state,
  currencyIsLoading: boolean
});

export const rtnRcptDetailFetchCurrencyOptionSuccess = (state, { options }) => ({
  ...state,
  currencyOptions: options
});

export const rtnRcptDetailFetchItemOptionLoading = (state, { boolean }) => ({
  ...state,
  itemIsLoading: boolean
});

export const rtnRcptDetailFetchItemOptionSuccess = (state, { options }) => ({
  ...state,
  itemOptions: options
});

export const rtnRcptDetailFetchUomOptionLoading = (state, { boolean }) => ({
  ...state,
  uomIsLoading: boolean
});

export const rtnRcptDetailFetchUomOptionSuccess = (state, { options }) => ({
  ...state,
  uomOptions: options
});

// force refresh the formik form
export const rtnRcptDetailUpdateDetails = (state, { documentDetails }) => ({
  ...state,
  documentDetail: documentDetails[0]
});

// force refresh the formik form
export const rtnRcptDetailCreateDetail = (state, { documentDetail }) => ({
  ...state,
  documentDetail
});

export const rtnRcptDetailFetchLocationOptionLoading = (state, { boolean }) => ({
  ...state,
  locationIsLoading: boolean
});

export const rtnRcptDetailFetchLocationOptionSuccess = (state, { options }) => ({
  ...state,
  locationOptions: options
});

export const rtnRcptDetailFetchProjectOptionLoading = (state, { boolean }) => ({
  ...state,
  projectIsLoading: boolean
});

export const rtnRcptDetailFetchProjectOptionSuccess = (state, { options }) => ({
  ...state,
  projectOptions: options
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [RtnRcptDetailTypes.RTN_RCPT_DETAIL_RESET_TIMESTAMP]: rtnRcptDetailResetTimestamp,
  [RtnRcptDetailTypes.RTN_RCPT_DETAIL_SET_HDR_ID]: rtnRcptDetailSetHdrId,

  [RtnRcptDetailTypes.RTN_RCPT_DETAIL_SHOW_HEADER_SUCCESS]: rtnRcptDetailShowHeaderSuccess,

  [RtnRcptDetailTypes.RTN_RCPT_DETAIL_UPDATE_DOCUMENT_SUCCESS]: rtnRcptDetailUpdateDocumentSuccess,

  [RtnRcptDetailTypes.RTN_RCPT_DETAIL_SHOW_DOCUMENT_LOADING]: rtnRcptDetailShowDocumentLoading,

  [RtnRcptDetailTypes.RTN_RCPT_DETAIL_SHOW_DETAILS_SUCCESS]: rtnRcptDetailShowDetailsSuccess,

  [RtnRcptDetailTypes.RTN_RCPT_DETAIL_SET_DETAIL_VISIBLE]: rtnRcptDetailSetDetailVisible,
  [RtnRcptDetailTypes.RTN_RCPT_DETAIL_SET_DOCUMENT_DETAIL]: rtnRcptDetailSetDocumentDetail,

  [RtnRcptDetailTypes.RTN_RCPT_DETAIL_UPDATE_DETAILS]: rtnRcptDetailUpdateDetails,
  [RtnRcptDetailTypes.RTN_RCPT_DETAIL_CREATE_DETAIL]: rtnRcptDetailCreateDetail,

  [RtnRcptDetailTypes.RTN_RCPT_DETAIL_FETCH_SALESMAN_OPTION_LOADING]: rtnRcptDetailFetchSalesmanOptionLoading,
  [RtnRcptDetailTypes.RTN_RCPT_DETAIL_FETCH_SALESMAN_OPTION_SUCCESS]: rtnRcptDetailFetchSalesmanOptionSuccess,

  [RtnRcptDetailTypes.RTN_RCPT_DETAIL_FETCH_DELIVERY_POINT_OPTION_LOADING]: rtnRcptDetailFetchDeliveryPointOptionLoading,
  [RtnRcptDetailTypes.RTN_RCPT_DETAIL_FETCH_DELIVERY_POINT_OPTION_SUCCESS]: rtnRcptDetailFetchDeliveryPointOptionSuccess,

  [RtnRcptDetailTypes.RTN_RCPT_DETAIL_FETCH_CREDIT_TERM_OPTION_LOADING]: rtnRcptDetailFetchCreditTermOptionLoading,
  [RtnRcptDetailTypes.RTN_RCPT_DETAIL_FETCH_CREDIT_TERM_OPTION_SUCCESS]: rtnRcptDetailFetchCreditTermOptionSuccess,

  [RtnRcptDetailTypes.RTN_RCPT_DETAIL_FETCH_CURRENCY_OPTION_LOADING]: rtnRcptDetailFetchCurrencyOptionLoading,
  [RtnRcptDetailTypes.RTN_RCPT_DETAIL_FETCH_CURRENCY_OPTION_SUCCESS]: rtnRcptDetailFetchCurrencyOptionSuccess,

  [RtnRcptDetailTypes.RTN_RCPT_DETAIL_FETCH_ITEM_OPTION_LOADING]: rtnRcptDetailFetchItemOptionLoading,
  [RtnRcptDetailTypes.RTN_RCPT_DETAIL_FETCH_ITEM_OPTION_SUCCESS]: rtnRcptDetailFetchItemOptionSuccess,

  [RtnRcptDetailTypes.RTN_RCPT_DETAIL_FETCH_UOM_OPTION_LOADING]: rtnRcptDetailFetchUomOptionLoading,
  [RtnRcptDetailTypes.RTN_RCPT_DETAIL_FETCH_UOM_OPTION_SUCCESS]: rtnRcptDetailFetchUomOptionSuccess,

  [RtnRcptDetailTypes.RTN_RCPT_DETAIL_FETCH_LOCATION_OPTION_LOADING]: rtnRcptDetailFetchLocationOptionLoading,
  [RtnRcptDetailTypes.RTN_RCPT_DETAIL_FETCH_LOCATION_OPTION_SUCCESS]: rtnRcptDetailFetchLocationOptionSuccess,

  [RtnRcptDetailTypes.RTN_RCPT_DETAIL_FETCH_PROJECT_OPTION_LOADING]: rtnRcptDetailFetchProjectOptionLoading,
  [RtnRcptDetailTypes.RTN_RCPT_DETAIL_FETCH_PROJECT_OPTION_SUCCESS]: rtnRcptDetailFetchProjectOptionSuccess
});
