import React from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';

import { ArrowLeftOutlined, UndoOutlined, SaveOutlined } from '@ant-design/icons';

import { Card, Button, Row, Col, Typography } from 'antd';
import treeChanges from 'tree-changes';
import {
  FormikInput,
  FormikText,
  FormikDatePicker,
  FormikButton,
  FormikSelect,
  FormikInputNumber,
  FormikTextNumber
} from '../../Components/Formik';
import OutbOrdDetailActions from '../../Stores/OutbOrdDetail/Actions';
import AppActions from '../../Stores/App/Actions';

const { Text } = Typography;

class HeaderForm extends React.PureComponent {
  constructor() {
    super();

    this.handlePurchaserSearch = this.handlePurchaserSearch.bind(this);
    this.handleBizPartnerSearch = this.handleBizPartnerSearch.bind(this);
    this.handleSalesmanSearch = this.handleSalesmanSearch.bind(this);
    this.handleDeliveryPointSearch = this.handleDeliveryPointSearch.bind(this);
    this.handleCreditTermSearch = this.handleCreditTermSearch.bind(this);
    this.handleCurrencySearch = this.handleCurrencySearch.bind(this);

    this.handleChangeDeliveryPoint = this.handleChangeDeliveryPoint.bind(this);
    this.handleChangeCurrency = this.handleChangeCurrency.bind(this);

    this.handleProjectSearch = this.handleProjectSearch.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    const { hdrId, initHeader, showHeader } = this.props;

    const { changed } = treeChanges(prevProps, this.props);
    if (changed('timestamp')) {
      if (hdrId <= 0) {
        initHeader();
      } else {
        showHeader(hdrId);
      }
    }
  }

  componentWillUnmount() {}

  handlePurchaserSearch(form, value) {
    const { fetchPurchaserOptions } = this.props;

    fetchPurchaserOptions(value);
  }

  handleBizPartnerSearch(form, value) {
    const { fetchBizPartnerOptions } = this.props;

    fetchBizPartnerOptions(value);
  }

  handleSalesmanSearch(form, value) {
    const { fetchSalesmanOptions } = this.props;

    fetchSalesmanOptions(value);
  }

  handleDeliveryPointSearch(form, value) {
    const { fetchDeliveryPointOptions } = this.props;

    fetchDeliveryPointOptions(value);
  }

  handleCreditTermSearch(form, value) {
    const { fetchCreditTermOptions } = this.props;

    fetchCreditTermOptions(value);
  }

  handleCurrencySearch(form, value) {
    const { fetchCurrencyOptions } = this.props;

    fetchCurrencyOptions(value);
  }

  handleProjectSearch(form, value) {
    const { fetchProjectOptions } = this.props;

    fetchProjectOptions(value);
  }

  handleChangeDeliveryPoint(form, value) {
    // params: form, value, option
    const { changeDeliveryPoint } = this.props;

    changeDeliveryPoint(form, value);
  }

  handleChangeCurrency(form, value) {
    // params: form, value, option
    const { changeCurrency } = this.props;

    changeCurrency(form, value);
  }

  render() {
    const {
      intl,
      hdrId,
      match,
      documentHeader,
      transitionToStatus,
      createHeader,
      updateHeader,
      historyGoBack,
      documentIsLoading,
      salesmanIsLoading,
      salesmanOptions,
      deliveryPointIsLoading,
      deliveryPointOptions,
      purchaserIsLoading,
      purchaserOptions,
      bizPartnerIsLoading,
      bizPartnerOptions,
      creditTermIsLoading,
      creditTermOptions,
      currencyIsLoading,
      currencyOptions,
      projectIsLoading,
      projectOptions
    } = this.props;

    const disabled = documentHeader && documentHeader.doc_status >= 50;

    return (
      <Formik
        enableReinitialize
        initialValues={documentHeader}
        onSubmit={(values, formikBag) => {
          if (values.submit_action === 'update') {
            if (match.params.action === 'create') {
              createHeader(formikBag, values);
            } else {
              updateHeader(formikBag, values);
            }
          } else if (values.submit_action === 'void') {
            transitionToStatus(formikBag, hdrId, 'VOID');
          } else if (values.submit_action === 'draft') {
            transitionToStatus(formikBag, hdrId, 'DRAFT');
          } else if (values.submit_action === 'wip') {
            transitionToStatus(formikBag, hdrId, 'WIP');
          } else if (values.submit_action === 'complete') {
            transitionToStatus(formikBag, hdrId, 'COMPLETE');
          }
        }}
        validationSchema={Yup.object().shape({
          doc_no_id:
            match.params.action === 'create'
              ? Yup.number().moreThan(0, intl.formatMessage({ id: 'doc_code_is_required' }))
              : '',
          doc_date: Yup.date().min(
            new Date(1969, 12, 1),
            intl.formatMessage({ id: 'doc_date_is_required' })
          ),
          /*
          credit_term_select2: Yup.object().shape({
            value: Yup.number().min(1, intl.formatMessage({ id: 'credit_term_is_required' }))
          }),
          */
          currency_select2: Yup.object().shape({
            value: Yup.number().min(1, intl.formatMessage({ id: 'currency_is_required' }))
          }),
          currency_rate: Yup.number().moreThan(
            0,
            intl.formatMessage({ id: 'currency_rate_must_be_greater_than_0' })
          )
        })}
      >
        {({
          values,
          // handleChange,
          // errors,
          // setFieldTouched,
          dirty,
          // touched,
          isSubmitting,
          // isValid,
          handleSubmit,
          handleReset
        }) => (
          <Form>
            <Card type="inner" title={intl.formatMessage({ id: 'basic_data' })}>
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'doc_code' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  {match.params.action === 'create' ? (
                    <Field
                      name="doc_no_id"
                      component={FormikSelect}
                      showArrow
                      filterOption={false}
                      notFoundContent={null}
                      options={values.doc_no_id_options}
                      disabled={disabled || documentIsLoading}
                    />
                  ) : (
                    <Field name="doc_code" component={FormikText} />
                  )}
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'doc_status' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="submit_action"
                    value="void"
                    component={FormikButton}
                    style={
                      values.doc_status === 2 ? { backgroundColor: 'red', color: 'white' } : {}
                    }
                    disabled={
                      values.doc_status === 2 ||
                      match.params.action === 'create' ||
                      values.doc_status > 3
                    }
                    loading={isSubmitting || documentIsLoading}
                    onClick={handleSubmit}
                    label={intl.formatMessage({
                      id: 'void'
                    })}
                  />
                  <Field
                    name="submit_action"
                    value="draft"
                    component={FormikButton}
                    style={
                      values.doc_status === 3 ? { backgroundColor: 'red', color: 'white' } : {}
                    }
                    disabled={values.doc_status === 3 || match.params.action === 'create'}
                    loading={isSubmitting || documentIsLoading}
                    onClick={handleSubmit}
                    label={intl.formatMessage({
                      id: 'draft'
                    })}
                  />
                  <Field
                    name="submit_action"
                    value="wip"
                    component={FormikButton}
                    style={
                      values.doc_status === 50 ? { backgroundColor: 'red', color: 'white' } : {}
                    }
                    disabled={values.doc_status === 50 || match.params.action === 'create'}
                    loading={isSubmitting || documentIsLoading}
                    onClick={handleSubmit}
                    label={intl.formatMessage({
                      id: 'WIP'
                    })}
                  />
                  <Field
                    name="submit_action"
                    value="complete"
                    component={FormikButton}
                    style={
                      values.doc_status === 100 ? { backgroundColor: 'green', color: 'white' } : {}
                    }
                    disabled={values.doc_status === 100 || match.params.action === 'create'}
                    loading={isSubmitting || documentIsLoading}
                    onClick={handleSubmit}
                    label={intl.formatMessage({
                      id: 'complete'
                    })}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'doc_date' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={8}>
                  <Field
                    name="doc_date"
                    component={FormikDatePicker}
                    disabled={disabled || documentIsLoading}
                  />
                </Col>

                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'est_del_date' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={8}>
                  <Field
                    name="est_del_date"
                    component={FormikDatePicker}
                    disabled={disabled || documentIsLoading}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'ref_code_01' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={8}>
                  <Field
                    name="ref_code_01"
                    component={FormikInput}
                    disabled={disabled || documentIsLoading}
                  />
                </Col>

                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'ref_code_02' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={8}>
                  <Field
                    name="ref_code_02"
                    component={FormikInput}
                    disabled={disabled || documentIsLoading}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'division' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={8}>
                  <Field
                    name="division_code"
                    component={FormikText}
                    disabled={disabled || documentIsLoading}
                  />
                </Col>

                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'company' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={8}>
                  <Field
                    name="company_code"
                    component={FormikText}
                    disabled={disabled || documentIsLoading}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'project' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={8}>
                  <Field
                    name="project_select2"
                    component={FormikSelect}
                    showArrow
                    filterOption={false}
                    onSearch={this.handleProjectSearch}
                    notFoundContent={null}
                    loading={projectIsLoading}
                    options={projectOptions}
                    disabled={disabled || documentIsLoading}
                    allowClear
                  />
                </Col>

                <Col span={3} />
                <Col span={1} />
                <Col span={8} />
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'desc_01' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="desc_01"
                    component={FormikInput}
                    disabled={disabled || documentIsLoading}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'desc_02' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="desc_02"
                    component={FormikInput}
                    disabled={disabled || documentIsLoading}
                  />
                </Col>
              </Row>
            </Card>

            {documentHeader.cur_res_type === '01' || // SLS_ORD
            documentHeader.cur_res_type === '04' || // SLS_INV
            documentHeader.cur_res_type === '10' || // DEL_ORD
            documentHeader.cur_res_type === '33' || // PROD_ORD
            documentHeader.cur_res_type === '34' || // PROD_ISS
            documentHeader.cur_res_type === '35' || // PROD_RCPT
            documentHeader.cur_res_type === '37' || // TRF_ORD
            documentHeader.cur_res_type === '23' || // LSP_SLS_ORD
            documentHeader.cur_res_type === '30' ? ( // LSP_DEL_ORD
              <Card type="inner" title={intl.formatMessage({ id: 'delivery_point' })}>
                <Row type="flex" justify="center" gutter={[8, 8]}>
                  <Col span={3}>
                    <Text>{intl.formatMessage({ id: 'salesman' })}</Text>
                  </Col>
                  <Col span={1}>
                    <Text>:</Text>
                  </Col>
                  <Col span={20}>
                    <Field
                      name="salesman_select2"
                      component={FormikSelect}
                      showArrow
                      filterOption={false}
                      onSearch={this.handleSalesmanSearch}
                      notFoundContent={null}
                      loading={salesmanIsLoading}
                      options={salesmanOptions}
                      disabled={disabled || documentIsLoading}
                    />
                  </Col>
                </Row>
                <Row type="flex" justify="center" gutter={[8, 8]}>
                  <Col span={3}>
                    <Text>{intl.formatMessage({ id: 'delivery_point' })}</Text>
                  </Col>
                  <Col span={1}>
                    <Text>:</Text>
                  </Col>
                  <Col span={20}>
                    <Field
                      name="delivery_point_select2"
                      component={FormikSelect}
                      showArrow
                      filterOption={false}
                      onSearch={this.handleDeliveryPointSearch}
                      notFoundContent={null}
                      loading={deliveryPointIsLoading}
                      options={deliveryPointOptions}
                      disabled={disabled || documentIsLoading}
                      onChange={this.handleChangeDeliveryPoint}
                    />
                  </Col>
                </Row>

                <Row type="flex" justify="center" gutter={[8, 8]}>
                  <Col span={3}>
                    <Text>{intl.formatMessage({ id: 'unit_no' })}</Text>
                  </Col>
                  <Col span={1}>
                    <Text>:</Text>
                  </Col>
                  <Col span={8}>
                    <Field name="delivery_point_unit_no" component={FormikText} />
                  </Col>

                  <Col span={3}>
                    <Text>{intl.formatMessage({ id: 'building_name' })}</Text>
                  </Col>
                  <Col span={1}>
                    <Text>:</Text>
                  </Col>
                  <Col span={8}>
                    <Field name="delivery_point_building_name" component={FormikText} />
                  </Col>
                </Row>

                <Row type="flex" justify="center" gutter={[8, 8]}>
                  <Col span={3}>
                    <Text>{intl.formatMessage({ id: 'street_name' })}</Text>
                  </Col>
                  <Col span={1}>
                    <Text>:</Text>
                  </Col>
                  <Col span={20}>
                    <Field name="delivery_point_street_name" component={FormikText} />
                  </Col>
                </Row>

                <Row type="flex" justify="center" gutter={[8, 8]}>
                  <Col span={3}>
                    <Text>{intl.formatMessage({ id: 'district_01' })}</Text>
                  </Col>
                  <Col span={1}>
                    <Text>:</Text>
                  </Col>
                  <Col span={20}>
                    <Field name="delivery_point_district_01" component={FormikText} />
                  </Col>
                </Row>

                <Row type="flex" justify="center" gutter={[8, 8]}>
                  <Col span={3}>
                    <Text>{intl.formatMessage({ id: 'district_02' })}</Text>
                  </Col>
                  <Col span={1}>
                    <Text>:</Text>
                  </Col>
                  <Col span={20}>
                    <Field name="delivery_point_district_02" component={FormikText} />
                  </Col>
                </Row>

                <Row type="flex" justify="center" gutter={[8, 8]}>
                  <Col span={3}>
                    <Text>{intl.formatMessage({ id: 'postcode' })}</Text>
                  </Col>
                  <Col span={1}>
                    <Text>:</Text>
                  </Col>
                  <Col span={8}>
                    <Field name="delivery_point_postcode" component={FormikText} />
                  </Col>

                  <Col span={3}>
                    <Text>{intl.formatMessage({ id: 'state_name' })}</Text>
                  </Col>
                  <Col span={1}>
                    <Text>:</Text>
                  </Col>
                  <Col span={8}>
                    <Field name="delivery_point_state_name" component={FormikText} />
                  </Col>
                </Row>

                <Row type="flex" justify="center" gutter={[8, 8]}>
                  <Col span={3}>
                    <Text>{intl.formatMessage({ id: 'country_name' })}</Text>
                  </Col>
                  <Col span={1}>
                    <Text>:</Text>
                  </Col>
                  <Col span={20}>
                    <Field name="delivery_point_country_name" component={FormikText} />
                  </Col>
                </Row>
              </Card>
            ) : (
              ''
            )}

            {documentHeader.cur_res_type === '31' || // PUR_RTN
            documentHeader.cur_res_type === '32' || // RTN_DEL
            documentHeader.cur_res_type === '26' || // LSP_PUR_RTN
            documentHeader.cur_res_type === '29' ? ( // LSP_RTN_DEL
              <Card type="inner" title={intl.formatMessage({ id: 'biz_partner' })}>
                <Row type="flex" justify="center" gutter={[8, 8]}>
                  <Col span={3}>
                    <Text>{intl.formatMessage({ id: 'purchaser' })}</Text>
                  </Col>
                  <Col span={1}>
                    <Text>:</Text>
                  </Col>
                  <Col span={20}>
                    <Field
                      name="purchaser_select2"
                      component={FormikSelect}
                      showArrow
                      filterOption={false}
                      onSearch={this.handlePurchaserSearch}
                      notFoundContent={null}
                      loading={purchaserIsLoading}
                      options={purchaserOptions}
                      disabled={disabled || documentIsLoading}
                    />
                  </Col>
                </Row>
                <Row type="flex" justify="center" gutter={[8, 8]}>
                  <Col span={3}>
                    <Text>{intl.formatMessage({ id: 'biz_partner' })}</Text>
                  </Col>
                  <Col span={1}>
                    <Text>:</Text>
                  </Col>
                  <Col span={20}>
                    <Field
                      name="biz_partner_select2"
                      component={FormikSelect}
                      showArrow
                      filterOption={false}
                      onSearch={this.handleBizPartnerSearch}
                      notFoundContent={null}
                      loading={bizPartnerIsLoading}
                      options={bizPartnerOptions}
                      disabled={disabled || documentIsLoading}
                      onChange={this.handleChangeBizPartner}
                    />
                  </Col>
                </Row>

                <Row type="flex" justify="center" gutter={[8, 8]}>
                  <Col span={3}>
                    <Text>{intl.formatMessage({ id: 'unit_no' })}</Text>
                  </Col>
                  <Col span={1}>
                    <Text>:</Text>
                  </Col>
                  <Col span={8}>
                    <Field name="biz_partner_unit_no" component={FormikText} />
                  </Col>

                  <Col span={3}>
                    <Text>{intl.formatMessage({ id: 'building_name' })}</Text>
                  </Col>
                  <Col span={1}>
                    <Text>:</Text>
                  </Col>
                  <Col span={8}>
                    <Field name="biz_partner_building_name" component={FormikText} />
                  </Col>
                </Row>

                <Row type="flex" justify="center" gutter={[8, 8]}>
                  <Col span={3}>
                    <Text>{intl.formatMessage({ id: 'street_name' })}</Text>
                  </Col>
                  <Col span={1}>
                    <Text>:</Text>
                  </Col>
                  <Col span={20}>
                    <Field name="biz_partner_street_name" component={FormikText} />
                  </Col>
                </Row>

                <Row type="flex" justify="center" gutter={[8, 8]}>
                  <Col span={3}>
                    <Text>{intl.formatMessage({ id: 'district_01' })}</Text>
                  </Col>
                  <Col span={1}>
                    <Text>:</Text>
                  </Col>
                  <Col span={20}>
                    <Field name="biz_partner_district_01" component={FormikText} />
                  </Col>
                </Row>

                <Row type="flex" justify="center" gutter={[8, 8]}>
                  <Col span={3}>
                    <Text>{intl.formatMessage({ id: 'district_02' })}</Text>
                  </Col>
                  <Col span={1}>
                    <Text>:</Text>
                  </Col>
                  <Col span={20}>
                    <Field name="biz_partner_district_02" component={FormikText} />
                  </Col>
                </Row>

                <Row type="flex" justify="center" gutter={[8, 8]}>
                  <Col span={3}>
                    <Text>{intl.formatMessage({ id: 'postcode' })}</Text>
                  </Col>
                  <Col span={1}>
                    <Text>:</Text>
                  </Col>
                  <Col span={8}>
                    <Field name="biz_partner_postcode" component={FormikText} />
                  </Col>

                  <Col span={3}>
                    <Text>{intl.formatMessage({ id: 'state_name' })}</Text>
                  </Col>
                  <Col span={1}>
                    <Text>:</Text>
                  </Col>
                  <Col span={8}>
                    <Field name="biz_partner_state_name" component={FormikText} />
                  </Col>
                </Row>

                <Row type="flex" justify="center" gutter={[8, 8]}>
                  <Col span={3}>
                    <Text>{intl.formatMessage({ id: 'country_name' })}</Text>
                  </Col>
                  <Col span={1}>
                    <Text>:</Text>
                  </Col>
                  <Col span={20}>
                    <Field name="biz_partner_country_name" component={FormikText} />
                  </Col>
                </Row>
              </Card>
            ) : (
              ''
            )}

            <Card type="inner" title={intl.formatMessage({ id: 'billing_summary' })}>
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'credit_term' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="credit_term_select2"
                    component={FormikSelect}
                    showArrow
                    filterOption={false}
                    onSearch={this.handleCreditTermSearch}
                    notFoundContent={null}
                    loading={creditTermIsLoading}
                    options={creditTermOptions}
                    disabled={disabled || documentIsLoading}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'currency' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={8}>
                  <Field
                    name="currency_select2"
                    component={FormikSelect}
                    showArrow
                    filterOption={false}
                    onSearch={this.handleCurrencySearch}
                    notFoundContent={null}
                    loading={currencyIsLoading}
                    options={currencyOptions}
                    disabled={disabled || documentIsLoading}
                    onChange={this.handleCurrency}
                  />
                </Col>

                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'rate' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={8}>
                  <Field
                    name="currency_rate"
                    component={FormikTextNumber}
                    minimumfractiondigits={6}
                    maximumfractiondigits={6}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'disc_val_01' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={8}>
                  <Field
                    name="hdr_disc_val_01"
                    component={FormikInputNumber}
                    disabled={disabled || documentIsLoading}
                    precision={parseInt(process.env.REACT_APP_DECIMAL_SCALE, 10)}
                  />
                </Col>

                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'disc_perc_01' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={8}>
                  <Field
                    name="hdr_disc_perc_01"
                    component={FormikInputNumber}
                    disabled={disabled || documentIsLoading}
                    precision={parseInt(process.env.REACT_APP_DECIMAL_SCALE, 10)}
                    max={100}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'disc_val_02' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={8}>
                  <Field
                    name="hdr_disc_val_02"
                    component={FormikInputNumber}
                    disabled={disabled || documentIsLoading}
                    precision={parseInt(process.env.REACT_APP_DECIMAL_SCALE, 10)}
                  />
                </Col>

                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'disc_perc_02' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={8}>
                  <Field
                    name="hdr_disc_perc_02"
                    component={FormikInputNumber}
                    disabled={disabled || documentIsLoading}
                    precision={parseInt(process.env.REACT_APP_DECIMAL_SCALE, 10)}
                    max={100}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'disc_val_03' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={8}>
                  <Field
                    name="hdr_disc_val_03"
                    component={FormikInputNumber}
                    disabled={disabled || documentIsLoading}
                    precision={parseInt(process.env.REACT_APP_DECIMAL_SCALE, 10)}
                  />
                </Col>

                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'disc_perc_03' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={8}>
                  <Field
                    name="hdr_disc_perc_03"
                    component={FormikInputNumber}
                    disabled={disabled || documentIsLoading}
                    precision={parseInt(process.env.REACT_APP_DECIMAL_SCALE, 10)}
                    max={100}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'disc_val_04' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={8}>
                  <Field
                    name="hdr_disc_val_04"
                    component={FormikInputNumber}
                    disabled={disabled || documentIsLoading}
                    precision={parseInt(process.env.REACT_APP_DECIMAL_SCALE, 10)}
                  />
                </Col>

                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'disc_perc_04' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={8}>
                  <Field
                    name="hdr_disc_perc_04"
                    component={FormikInputNumber}
                    disabled={disabled || documentIsLoading}
                    precision={parseInt(process.env.REACT_APP_DECIMAL_SCALE, 10)}
                    max={100}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'disc_amt' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={8}>
                  <Field
                    name="disc_amt"
                    component={FormikTextNumber}
                    minimumfractiondigits={process.env.REACT_APP_DECIMAL_SCALE}
                    maximumfractiondigits={process.env.REACT_APP_DECIMAL_SCALE}
                  />
                </Col>

                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'tax_amt' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={8}>
                  <Field
                    name="tax_amt"
                    component={FormikTextNumber}
                    minimumfractiondigits={process.env.REACT_APP_DECIMAL_SCALE}
                    maximumfractiondigits={process.env.REACT_APP_DECIMAL_SCALE}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'round_adj_amt' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={8}>
                  <Field
                    name="round_adj_amt"
                    component={FormikTextNumber}
                    minimumfractiondigits={process.env.REACT_APP_DECIMAL_SCALE}
                    maximumfractiondigits={process.env.REACT_APP_DECIMAL_SCALE}
                  />
                </Col>

                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'net_amt' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={8}>
                  <Field
                    name="net_amt"
                    component={FormikTextNumber}
                    minimumfractiondigits={process.env.REACT_APP_DECIMAL_SCALE}
                    maximumfractiondigits={process.env.REACT_APP_DECIMAL_SCALE}
                  />
                </Col>
              </Row>
            </Card>

            <Card>
              <Row type="flex" justify="space-between" gutter={[0, 16]}>
                <Col span={8}>
                  <Button
                    type="primary"
                    // disabled={!isValid}
                    loading={isSubmitting}
                    onClick={historyGoBack}
                    icon={<ArrowLeftOutlined />}
                  >
                    {intl.formatMessage({
                      id: 'back'
                    })}
                  </Button>
                </Col>
                <Col span={6}>
                  <Button
                    type="primary"
                    disabled={!dirty}
                    loading={isSubmitting || documentIsLoading}
                    onClick={handleReset}
                    icon={<UndoOutlined />}
                  >
                    {intl.formatMessage({ id: 'reset' })}
                  </Button>
                  <Field
                    type="primary"
                    name="submit_action"
                    value="update"
                    component={FormikButton}
                    loading={isSubmitting || documentIsLoading}
                    onClick={handleSubmit}
                    label={intl.formatMessage({
                      id: match.params.action === 'create' ? 'create' : 'update'
                    })}
                    icon={<SaveOutlined />}
                  />
                </Col>
              </Row>
            </Card>
          </Form>
        )}
      </Formik>
    );
  }
}

HeaderForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object,
  initHeader: PropTypes.func,
  showHeader: PropTypes.func,
  transitionToStatus: PropTypes.func,
  createHeader: PropTypes.func,
  updateHeader: PropTypes.func,
  historyGoBack: PropTypes.func,
  hdrId: PropTypes.number,
  documentHeader: PropTypes.shape({
    doc_status: PropTypes.number,
    cur_res_type: PropTypes.string
  }),
  documentIsLoading: PropTypes.bool,

  fetchSalesmanOptions: PropTypes.func,
  salesmanIsLoading: PropTypes.bool,
  salesmanOptions: PropTypes.arrayOf(PropTypes.object),
  fetchDeliveryPointOptions: PropTypes.func,
  deliveryPointIsLoading: PropTypes.bool,
  deliveryPointOptions: PropTypes.arrayOf(PropTypes.object),

  fetchPurchaserOptions: PropTypes.func,
  purchaserIsLoading: PropTypes.bool,
  purchaserOptions: PropTypes.arrayOf(PropTypes.object),
  fetchBizPartnerOptions: PropTypes.func,
  bizPartnerIsLoading: PropTypes.bool,
  bizPartnerOptions: PropTypes.arrayOf(PropTypes.object),

  fetchCreditTermOptions: PropTypes.func,
  creditTermIsLoading: PropTypes.bool,
  creditTermOptions: PropTypes.arrayOf(PropTypes.object),
  fetchCurrencyOptions: PropTypes.func,
  currencyIsLoading: PropTypes.bool,
  currencyOptions: PropTypes.arrayOf(PropTypes.object),

  changeDeliveryPoint: PropTypes.func,
  changeCurrency: PropTypes.func,

  fetchProjectOptions: PropTypes.func,
  projectIsLoading: PropTypes.bool,
  projectOptions: PropTypes.arrayOf(PropTypes.object)
};

HeaderForm.defaultProps = {
  intl: {},
  match: {},
  initHeader() {},
  showHeader() {},
  transitionToStatus() {},
  createHeader() {},
  updateHeader() {},
  historyGoBack() {},
  hdrId: 0,
  documentHeader: { doc_status: 3, cur_res_type: 0 },
  documentIsLoading: false,

  fetchSalesmanOptions() {},
  salesmanIsLoading: false,
  salesmanOptions: [],
  fetchDeliveryPointOptions() {},
  deliveryPointIsLoading: false,
  deliveryPointOptions: [],

  fetchPurchaserOptions() {},
  purchaserIsLoading: false,
  purchaserOptions: [],
  fetchBizPartnerOptions() {},
  bizPartnerIsLoading: false,
  bizPartnerOptions: [],

  fetchCreditTermOptions() {},
  creditTermIsLoading: false,
  creditTermOptions: [],
  fetchCurrencyOptions() {},
  currencyIsLoading: false,
  currencyOptions: [],

  changeDeliveryPoint() {},
  changeCurrency() {},

  fetchProjectOptions() {},
  projectIsLoading: false,
  projectOptions: []
};

const mapStateToProps = state => ({
  apiUrl: state.app.apiUrl,
  timestamp: state.outbOrdDetail.timestamp,
  hdrId: state.outbOrdDetail.hdrId,
  documentHeader: state.outbOrdDetail.documentHeader,
  documentIsLoading: state.outbOrdDetail.documentIsLoading,

  salesmanIsLoading: state.outbOrdDetail.salesmanIsLoading,
  salesmanOptions: state.outbOrdDetail.salesmanOptions,

  deliveryPointIsLoading: state.outbOrdDetail.deliveryPointIsLoading,
  deliveryPointOptions: state.outbOrdDetail.deliveryPointOptions,

  purchaserIsLoading: state.outbOrdDetail.purchaserIsLoading,
  purchaserOptions: state.outbOrdDetail.purchaserOptions,

  creditTermIsLoading: state.outbOrdDetail.creditTermIsLoading,
  creditTermOptions: state.outbOrdDetail.creditTermOptions,

  currencyIsLoading: state.outbOrdDetail.currencyIsLoading,
  currencyOptions: state.outbOrdDetail.currencyOptions,

  projectIsLoading: state.outbOrdDetail.projectIsLoading,
  projectOptions: state.outbOrdDetail.projectOptions
});

const mapDispatchToProps = dispatch => ({
  initHeader: () => dispatch(OutbOrdDetailActions.outbOrdDetailInitHeader()),
  showHeader: hdrId => dispatch(OutbOrdDetailActions.outbOrdDetailShowHeader(hdrId)),
  transitionToStatus: (formikBag, hdrId, docStatus) =>
    dispatch(OutbOrdDetailActions.outbOrdDetailTransitionToStatus(formikBag, hdrId, docStatus)),
  updateHeader: (formikBag, documentHeader) =>
    dispatch(OutbOrdDetailActions.outbOrdDetailUpdateHeader(formikBag, documentHeader)),
  createHeader: (formikBag, documentHeader) =>
    dispatch(OutbOrdDetailActions.outbOrdDetailCreateHeader(formikBag, documentHeader)),
  historyGoBack: () => dispatch(AppActions.appHistoryGoBack()),

  fetchSalesmanOptions: search =>
    dispatch(OutbOrdDetailActions.outbOrdDetailFetchSalesmanOptions(search)),

  fetchDeliveryPointOptions: search =>
    dispatch(OutbOrdDetailActions.outbOrdDetailFetchDeliveryPointOptions(search)),
  changeDeliveryPoint: (formikBag, deliveryPointId) =>
    dispatch(OutbOrdDetailActions.outbOrdDetailChangeDeliveryPoint(formikBag, deliveryPointId)),

  fetchCreditTermOptions: search =>
    dispatch(OutbOrdDetailActions.outbOrdDetailFetchCreditTermOptions(search)),

  fetchCurrencyOptions: search =>
    dispatch(OutbOrdDetailActions.outbOrdDetailFetchCurrencyOptions(search)),
  changeCurrency: (formikBag, hdrId, deliveryPointId) =>
    dispatch(OutbOrdDetailActions.outbOrdDetailChangeCurrency(formikBag, hdrId, deliveryPointId)),

  fetchProjectOptions: search =>
    dispatch(OutbOrdDetailActions.outbOrdDetailFetchProjectOptions(search))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(HeaderForm));
