import React from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';

import { ArrowLeftOutlined, UndoOutlined, SaveOutlined } from '@ant-design/icons';

import { Button, Row, Col, Typography } from 'antd';
import treeChanges from 'tree-changes';
import { FormikInput, FormikButton } from '../../Components/Formik';
import AreaDetailActions from '../../Stores/AreaDetail/Actions';
import AppActions from '../../Stores/App/Actions';

const { Text } = Typography;

class ModelForm extends React.PureComponent {
  componentDidMount() {}

  componentDidUpdate(prevProps) {
    const { resId, initModel, showModel } = this.props;

    const { changed } = treeChanges(prevProps, this.props);
    if (changed('timestamp')) {
      if (resId <= 0) {
        initModel();
      } else {
        showModel(resId);
      }
    }
  }

  componentWillUnmount() {}

  render() {
    const {
      intl,
      match,
      model,
      createModel,
      updateModel,
      historyGoBack,
      modelIsLoading
    } = this.props;

    const disabled = false;

    return (
      <Formik
        enableReinitialize
        initialValues={model}
        onSubmit={(values, formikBag) => {
          if (match.params.action === 'create') {
            createModel(formikBag, values);
          } else {
            updateModel(formikBag, values);
          }
        }}
        validationSchema={Yup.object().shape({
          code: Yup.string().required(intl.formatMessage({ id: 'code_is_required' })),
          area_type: Yup.number().min(1, intl.formatMessage({ id: 'type_is_required' })),
          desc_01: Yup.string().required(intl.formatMessage({ id: 'description_is_required' }))
        })}
      >
        {({
          // values,
          // handleChange,
          // errors,
          // setFieldTouched,
          dirty,
          // touched,
          isSubmitting,
          // isValid,
          handleSubmit,
          handleReset
        }) => (
          <Form>
            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'code' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field name="code" component={FormikInput} disabled={disabled || modelIsLoading} />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'description' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="desc_01"
                  component={FormikInput}
                  disabled={disabled || modelIsLoading}
                />
              </Col>
            </Row>
            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3} />
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="desc_02"
                  component={FormikInput}
                  disabled={disabled || modelIsLoading}
                />
              </Col>
            </Row>

            <Row type="flex" justify="space-between" gutter={[0, 16]}>
              <Col span={8}>
                <Button
                  type="primary"
                  // disabled={!isValid}
                  loading={isSubmitting}
                  onClick={historyGoBack}
                  icon={<ArrowLeftOutlined />}
                >
                  {intl.formatMessage({
                    id: 'back'
                  })}
                </Button>
              </Col>
              <Col span={5}>
                <Button
                  type="primary"
                  disabled={!dirty}
                  loading={isSubmitting || modelIsLoading}
                  onClick={handleReset}
                  icon={<UndoOutlined />}
                >
                  {intl.formatMessage({ id: 'reset' })}
                </Button>
                <Field
                  name="submit_action"
                  type="primary"
                  value="update"
                  component={FormikButton}
                  // disabled={values.status >= 50}
                  loading={isSubmitting || modelIsLoading}
                  onClick={handleSubmit}
                  label={intl.formatMessage({
                    id: match.params.action === 'create' ? 'create' : 'update'
                  })}
                  icon={<SaveOutlined />}
                />
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    );
  }
}

ModelForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object,

  initModel: PropTypes.func,
  showModel: PropTypes.func,
  createModel: PropTypes.func,
  updateModel: PropTypes.func,
  historyGoBack: PropTypes.func,
  resId: PropTypes.number,
  model: PropTypes.shape({
    status: PropTypes.number
  }),
  modelIsLoading: PropTypes.bool
};

ModelForm.defaultProps = {
  intl: {},
  match: {},

  initModel() {},
  showModel() {},
  createModel() {},
  updateModel() {},
  historyGoBack() {},
  resId: 0,
  model: { status: 2 },
  modelIsLoading: false
};

const mapStateToProps = state => ({
  apiUrl: state.app.apiUrl,
  timestamp: state.areaDetail.timestamp,
  resId: state.areaDetail.resId,
  model: state.areaDetail.model,
  modelIsLoading: state.areaDetail.modelIsLoading
});

const mapDispatchToProps = dispatch => ({
  initModel: () => dispatch(AreaDetailActions.areaDetailInitModel()),
  showModel: resId => dispatch(AreaDetailActions.areaDetailShowModel(resId)),

  updateModel: (formikBag, model) =>
    dispatch(AreaDetailActions.areaDetailUpdateModel(formikBag, model)),
  createModel: (formikBag, model) =>
    dispatch(AreaDetailActions.areaDetailCreateModel(formikBag, model)),

  historyGoBack: () => dispatch(AppActions.appHistoryGoBack())
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ModelForm));
