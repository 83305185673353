import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import treeChanges from 'tree-changes';

import './LoadListDetailBoardKanban.css';

import { List } from 'antd';
import { Card, Badge } from 'react-bootstrap';

import LoadListDetailBoardActions from '../../Stores/LoadListDetailBoard/Actions';

class WipPickListTable extends React.PureComponent {
  constructor() {
    super();

    this.renderItem = this.renderItem.bind(this);
    this.useOnListChange = this.useOnListChange.bind(this);
  }

  componentDidMount() {
    const { curSiteFlowId, resetTimestamp } = this.props;

    if (curSiteFlowId > 0) {
      resetTimestamp();
    }
  }

  componentDidUpdate(prevProps) {
    const { curSiteFlowId, fetchWipPickList, currentPage, sorts, filters, pageSize } = this.props;
    const { resetTimestamp } = this.props;

    const { changed } = treeChanges(prevProps, this.props);

    if (changed('curSiteFlowId')) {
      resetTimestamp();
    }

    if (changed('timestamp')) {
      if (curSiteFlowId > 0) {
        fetchWipPickList(currentPage, sorts, filters, pageSize);
      }
    }
  }

  componentWillUnmount() {}

  handleSearch(selectedKeys, confirm) {
    // eslint-disable-next-line no-unused-vars
    const { intl } = this.props;

    confirm();
  }

  handleReset(clearFilters) {
    // eslint-disable-next-line no-unused-vars
    const { intl } = this.props;

    clearFilters();
  }

  useOnListChange(page, pageSize) {
    const { resetTimestamp, sorts, filters } = this.props;

    resetTimestamp(page, sorts, filters, pageSize);
  }

  renderItem(record) {
    const { intl, showWipPickListSummary } = this.props;

    let color = 'badge-default'; // red
    if (record.doc_date_aging < 1) {
      color = 'badge-green'; // green
    } else if (record.doc_date_aging < 2) {
      color = 'badge-lime'; // lime
    } else if (record.doc_date_aging < 3) {
      color = 'badge-yellow'; // yellow
    } else if (record.doc_date_aging < 4) {
      color = 'badge-gold'; // gold
    } else if (record.doc_date_aging < 5) {
      color = 'badge-orange'; // orange
    } else if (record.doc_date_aging < 6) {
      color = 'badge-pink'; // pink
    }

    return (
      // FIX: change card style to bootstrap design,
      // FIX: clicking the any part of the card will pop up the modal
      <Card className="rowcard" key={record.id} onClick={() => showWipPickListSummary(record.id)}>
        <Card.Header>
          {record.doc_code}
          {record.ref_code_01 !== record.doc_code && <>{` ${record.ref_code_01}`}</>}
        </Card.Header>
        <Card.Body>
          <div className="progressinfo">
            <div>
              <h4>
                <Badge className={color}>{record.doc_date_aging}</Badge>
              </h4>
              <h6 className="daypassed">
                day(s)
                <br />
                passed
              </h6>
            </div>
            <div>
              <Card.Title className="infotext" style={{ fontSize: '15px', wordBreak: 'break-all' }}>
                {record.to_storage_bin_code}
                <br />
                {record.areas.map(value => (
                  <span key={`${record.id}:${value.code}`}>{` ${value.code}`}</span>
                ))}
              </Card.Title>
              <Card.Text className="infotext">
                {record.delivery_points.map((value, index) => (
                  <span key={`${record.id}:${value.code}`}>
                    {index !== 0 && <br />}
                    <b>{`${index + 1} `}</b>
                    {value.company_name_01}
                  </span>
                ))}
              </Card.Text>
            </div>
          </div>
        </Card.Body>
        <Card.Footer>
          <small className="datetime">{record.created_at}</small>
          <small className="lineno">
            {`${record.ttl_line_no} ${intl.formatMessage({ id: 'lines' })}`}
          </small>
        </Card.Footer>
      </Card>
    );
  }

  render() {
    const { intl, currentPage, pageSize, total, documents, fetchIsLoading } = this.props;

    return (
      // FIX: wrap the list with bootstrap card design
      <Card className="columncard" text="dark">
        <Card.Header className="wippicklist">
          {`${total} ${intl.formatMessage({ id: 'pick_lists' })}`}
        </Card.Header>
        <Card.Body className="scr">
          <List
            loading={fetchIsLoading}
            itemLayout="horizontal"
            dataSource={documents}
            renderItem={this.renderItem}
            pagination={{
              current: currentPage,
              pageSize: parseInt(pageSize, 10),
              total,
              size: 'small',
              simple: 'simple',
              onChange: this.useOnListChange
            }}
          />
        </Card.Body>
      </Card>
    );
  }
}

WipPickListTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  resetTimestamp: PropTypes.func,

  documents: PropTypes.arrayOf(PropTypes.object),

  sorts: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  filters: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),

  currentPage: PropTypes.number,
  pageSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  total: PropTypes.number,
  fetchIsLoading: PropTypes.bool,

  curSiteFlowId: PropTypes.number,
  fetchWipPickList: PropTypes.func,
  showWipPickListSummary: PropTypes.func
};

WipPickListTable.defaultProps = {
  intl: {},
  resetTimestamp() {},
  documents: [],

  sorts: {},
  filters: {},

  currentPage: 1,
  pageSize: '20',
  total: 0,
  fetchIsLoading: false,

  curSiteFlowId: 0,
  fetchWipPickList() {},
  showWipPickListSummary() {}
};

const mapStateToProps = state => ({
  timestamp: state.loadListDetailBoard.wipPickListTimestamp,

  documents: state.loadListDetailBoard.wipPickLists,

  sorts: state.loadListDetailBoard.wipPickListSorts,
  filters: state.loadListDetailBoard.wipPickListFilters,

  currentPage: state.loadListDetailBoard.wipPickListCurrentPage,
  pageSize: state.loadListDetailBoard.wipPickListPageSize,
  total: state.loadListDetailBoard.wipPickListTotal,
  fetchIsLoading: state.loadListDetailBoard.wipPickListIsLoading,

  curSiteFlowId: state.app.curSiteFlowId
});

const mapDispatchToProps = dispatch => ({
  resetTimestamp: (currentPage, sorts, filters, pageSize) =>
    dispatch(
      LoadListDetailBoardActions.loadListDetailBoardResetWipPickListTimestamp(
        currentPage,
        sorts,
        filters,
        pageSize
      )
    ),

  fetchWipPickList: (currentPage, sorts, filters, pageSize) =>
    dispatch(
      LoadListDetailBoardActions.loadListDetailBoardFetchWipPickList(
        currentPage,
        sorts,
        filters,
        pageSize
      )
    ),

  showWipPickListSummary: docId =>
    dispatch(LoadListDetailBoardActions.loadListDetailBoardShowWipPickListSummary(docId))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(WipPickListTable));
