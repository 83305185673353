/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { DeliveryPointDetailTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const deliveryPointDetailResetModelTimestamp = state => ({
  ...state,
  modelTimestamp: Date.now()
});

export const deliveryPointDetailSetResId = (state, { resId }) => ({
  ...state,
  modelTimestamp: Date.now(),
  resId
});

export const deliveryPointDetailShowModelLoading = (state, { boolean }) => ({
  ...state,
  modelIsLoading: boolean
});

export const deliveryPointDetailShowModelSuccess = (state, { model }) => ({
  ...state,
  model
});

export const deliveryPointDetailUpdateModelSuccess = (state, { model }) => ({
  ...state,
  model
});

export const deliveryPointDetailFetchDebtorGroup01OptionLoading = (state, { boolean }) => ({
  ...state,
  debtorGroup01IsLoading: boolean
});

export const deliveryPointDetailFetchDebtorGroup01OptionSuccess = (state, { options }) => ({
  ...state,
  debtorGroup01Options: options
});

export const deliveryPointDetailFetchDebtorGroup02OptionLoading = (state, { boolean }) => ({
  ...state,
  debtorGroup02IsLoading: boolean
});

export const deliveryPointDetailFetchDebtorGroup02OptionSuccess = (state, { options }) => ({
  ...state,
  debtorGroup02Options: options
});

export const deliveryPointDetailFetchDebtorGroup03OptionLoading = (state, { boolean }) => ({
  ...state,
  debtorGroup03IsLoading: boolean
});

export const deliveryPointDetailFetchDebtorGroup03OptionSuccess = (state, { options }) => ({
  ...state,
  debtorGroup03Options: options
});

export const deliveryPointDetailFetchAreaOptionLoading = (state, { boolean }) => ({
  ...state,
  areaIsLoading: boolean
});

export const deliveryPointDetailFetchAreaOptionSuccess = (state, { options }) => ({
  ...state,
  areaOptions: options
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [DeliveryPointDetailTypes.DELIVERY_POINT_DETAIL_RESET_MODEL_TIMESTAMP]: deliveryPointDetailResetModelTimestamp,
  [DeliveryPointDetailTypes.DELIVERY_POINT_DETAIL_SET_RES_ID]: deliveryPointDetailSetResId,
  [DeliveryPointDetailTypes.DELIVERY_POINT_DETAIL_SHOW_MODEL_LOADING]: deliveryPointDetailShowModelLoading,
  [DeliveryPointDetailTypes.DELIVERY_POINT_DETAIL_SHOW_MODEL_SUCCESS]: deliveryPointDetailShowModelSuccess,
  [DeliveryPointDetailTypes.DELIVERY_POINT_DETAIL_UPDATE_MODEL_SUCCESS]: deliveryPointDetailUpdateModelSuccess,

  [DeliveryPointDetailTypes.DELIVERY_POINT_DETAIL_FETCH_DEBTOR_GROUP01_OPTION_LOADING]: deliveryPointDetailFetchDebtorGroup01OptionLoading,
  [DeliveryPointDetailTypes.DELIVERY_POINT_DETAIL_FETCH_DEBTOR_GROUP01_OPTION_SUCCESS]: deliveryPointDetailFetchDebtorGroup01OptionSuccess,

  [DeliveryPointDetailTypes.DELIVERY_POINT_DETAIL_FETCH_DEBTOR_GROUP02_OPTION_LOADING]: deliveryPointDetailFetchDebtorGroup02OptionLoading,
  [DeliveryPointDetailTypes.DELIVERY_POINT_DETAIL_FETCH_DEBTOR_GROUP02_OPTION_SUCCESS]: deliveryPointDetailFetchDebtorGroup02OptionSuccess,

  [DeliveryPointDetailTypes.DELIVERY_POINT_DETAIL_FETCH_DEBTOR_GROUP03_OPTION_LOADING]: deliveryPointDetailFetchDebtorGroup03OptionLoading,
  [DeliveryPointDetailTypes.DELIVERY_POINT_DETAIL_FETCH_DEBTOR_GROUP03_OPTION_SUCCESS]: deliveryPointDetailFetchDebtorGroup03OptionSuccess,

  [DeliveryPointDetailTypes.DELIVERY_POINT_DETAIL_FETCH_AREA_OPTION_LOADING]: deliveryPointDetailFetchAreaOptionLoading,
  [DeliveryPointDetailTypes.DELIVERY_POINT_DETAIL_FETCH_AREA_OPTION_SUCCESS]: deliveryPointDetailFetchAreaOptionSuccess
});
