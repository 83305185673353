import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  stkRcptDetailResetTimestamp: null,
  stkRcptDetailSetHdrId: ['hdrId', 'itemId'],

  stkRcptDetailInitHeader: ['docSubType'],
  stkRcptDetailShowHeader: ['hdrId'],
  stkRcptDetailShowHeaderSuccess: ['documentHeader'],

  stkRcptDetailUpdateHeader: ['formikBag', 'documentHeader'],
  stkRcptDetailCreateHeader: ['formikBag', 'documentHeader'],
  stkRcptDetailUpdateDocumentSuccess: ['documentHeader', 'documentDetails'],

  stkRcptDetailShowDocumentLoading: ['boolean'],
  stkRcptDetailTransitionToStatus: ['formikBag', 'hdrId', 'docStatus'],

  stkRcptDetailShowDetails: ['hdrId'],
  stkRcptDetailShowDetailsSuccess: ['documentDetails'],

  stkRcptDetailSetDetailVisible: ['boolean'],
  stkRcptDetailSetDocumentDetail: ['documentDetail'],

  stkRcptDetailUpdateDetails: ['formikBag', 'hdrId', 'documentDetails'],
  stkRcptDetailCreateDetail: ['formikBag', 'hdrId', 'documentDetail'],
  stkRcptDetailDeleteDetail: ['hdrId', 'documentDetail'],

  stkRcptDetailFetchCurrencyOptions: ['search'],
  stkRcptDetailFetchCurrencyOptionLoading: ['boolean'],
  stkRcptDetailFetchCurrencyOptionSuccess: ['options'],
  stkRcptDetailChangeCurrency: ['formikBag', 'currencyId'],

  stkRcptDetailFetchFrDivisionOptions: ['docSubType', 'search'],
  stkRcptDetailFetchFrDivisionOptionLoading: ['boolean'],
  stkRcptDetailFetchFrDivisionOptionSuccess: ['options'],
  stkRcptDetailChangeFrDivision: ['formikBag', 'frDivisionId'],

  stkRcptDetailFetchFrLocationOptions: ['hdrId', 'docSubType', 'search'],
  stkRcptDetailFetchFrLocationOptionLoading: ['boolean'],
  stkRcptDetailFetchFrLocationOptionSuccess: ['options'],

  stkRcptDetailFetchLocationOptions: ['docSubType', 'search'],
  stkRcptDetailFetchLocationOptionLoading: ['boolean'],
  stkRcptDetailFetchLocationOptionSuccess: ['options'],

  stkRcptDetailFetchItemOptions: ['search'],
  stkRcptDetailFetchItemOptionLoading: ['boolean'],
  stkRcptDetailFetchItemOptionSuccess: ['options'],
  stkRcptDetailChangeItem: ['formikBag', 'hdrId', 'itemId'],

  stkRcptDetailFetchUomOptions: ['itemId', 'search'],
  stkRcptDetailFetchUomOptionLoading: ['boolean'],
  stkRcptDetailFetchUomOptionSuccess: ['options'],
  stkRcptDetailChangeUom: ['formikBag', 'hdrId', 'itemId', 'uomId'],

  stkRcptDetailFetchProjectOptions: ['search'],
  stkRcptDetailFetchProjectOptionLoading: ['boolean'],
  stkRcptDetailFetchProjectOptionSuccess: ['options']
});

export const StkRcptDetailTypes = Types;
export default Creators;
