import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';

import { Card } from 'antd';

import AuditUser01Actions from '../../Stores/AuditUser01/Actions';

import AuditUser01Table from './AuditUser01Table';

class AuditUser01Screen extends React.PureComponent {
  componentDidMount() {
    const { match, setUser } = this.props;
    setUser(match.params.user_id);
  }

  componentDidUpdate() {
    const { match, setUser } = this.props;

    setUser(match.params.user_id);
  }

  componentWillUnmount() {}

  render() {
    const { intl, match } = this.props;

    return (
      <Card
        title={intl.formatMessage({
          id: 'audit_trail'
        })}
      >
        <AuditUser01Table match={match} />
      </Card>
    );
  }
}

AuditUser01Screen.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object,

  setUser: PropTypes.func
};

AuditUser01Screen.defaultProps = {
  intl: {},
  match: {},

  setUser() {}
};

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  setUser: userId => dispatch(AuditUser01Actions.auditUser01SetUser(userId))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AuditUser01Screen));
