import React from 'react';
import { animated, to } from 'react-spring';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';

class CustomBar extends React.PureComponent {
  constructor() {
    super();
    this.formatData = this.formatData.bind(this);
    this.getCustomToolTipHTML = this.getCustomToolTipHTML.bind(this);
    this.onMouseHoverHandler = this.onMouseHoverHandler.bind(this);
    this.onMouseLeaveHandler = this.onMouseLeaveHandler.bind(this);
  }

  // eslint-disable-next-line class-methods-use-this
  onMouseHoverHandler(event, action, performanceValue) {
    const { tooltipRef } = this.props;
    const xPos = event.pageX;
    const yPos = event.pageY;
    const xPosCenter = xPos - 320;
    const yPosCenter = yPos - 280;

    if (!tooltipRef.current) {
      return;
    }

    tooltipRef.current.style.transform = `translate(${xPosCenter}px, ${yPosCenter}px)`;

    if (action === 'move') {
      return;
    }

    tooltipRef.current.style.display = 'inline-flex';
    tooltipRef.current.innerHTML = this.getCustomToolTipHTML(performanceValue);
  }

  // eslint-disable-next-line class-methods-use-this
  onMouseLeaveHandler() {
    const { tooltipRef } = this.props;

    if (tooltipRef.current) {
      tooltipRef.current.style.display = 'none';
    }
  }

  // eslint-disable-next-line class-methods-use-this
  getCustomToolTipHTML(value) {
    const { intl, unit } = this.props;

    return `<span><strong>${intl.formatMessage({
      id: unit
    })} </strong>: <strong>${this.formatData(value)}</strong></span>`;
  }

  // eslint-disable-next-line class-methods-use-this
  formatData(data) {
    if (!data) return data;
    const str = data.toString().split('.');
    if (str[0].length >= 3) {
      str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
    }
    if (str[1] && str[1].length >= 5) {
      str[1] = str[1].replace(/(\d{3})/g, '$1 ');
    }

    return str.join('.');
  }

  render() {
    const {
      customBarProps,
      unit,
      summaryData,
      avgData,
      graphHeight,
      heightToValueRatio
    } = this.props;

    const { bar, style } = customBarProps;
    const { x, y, width, height, data, color } = bar;
    // extract data
    const { data: workerData } = data;
    const workerPerformanceValue = workerData[unit];
    const totalPerformanceValue = summaryData[unit];
    const avgPerformanceValue = avgData[unit];

    // calculate contribution
    const calculatedWorkerContribution =
      (workerPerformanceValue * 100) / totalPerformanceValue || 0;

    // extract average data
    const avgDataXValue = avgPerformanceValue * heightToValueRatio || 0;

    // calculate label position, so as not to intersect average line
    const calculateLabelPosition = () => {
      // space smallest label values from y axis
      let newLabelXPosition = 0;
      if (width < 100) {
        newLabelXPosition = 50;
      } else {
        newLabelXPosition = x + width / 2;
      }

      // space label values from average line
      if (newLabelXPosition - avgDataXValue > 20 || avgDataXValue - newLabelXPosition > 20) {
        newLabelXPosition += 0; // don't offset
      } else {
        newLabelXPosition -= 20; // move left by 20
      }

      return newLabelXPosition;
    };

    const calculateAvgLineLength = () => graphHeight - 120; // graph height minus top and bottom margins

    return (
      <g>
        <animated.rect
          transform={`rotate(0,${x + 0.5 * width}, ${y + 0.5 * height})`}
          fill={color}
          height={height}
          width={to(style.width, value => Math.max(value, 0))}
          x={x}
          y={y}
          onMouseEnter={
            event => this.onMouseHoverHandler(event, 'enter', workerPerformanceValue)
            // eslint-disable-next-line react/jsx-curly-newline
          }
          onMouseMove={
            event => this.onMouseHoverHandler(event, 'move', workerPerformanceValue)
            // eslint-disable-next-line react/jsx-curly-newline
          }
          onMouseLeave={this.onMouseLeaveHandler}
        />
        <text
          x={calculateLabelPosition()}
          y={y + height / 2}
          textAnchor="middle"
          dominantBaseline="central"
          style={{
            fontSize: 12,
            pointerEvents: 'none',
            fill: 'black',
            opacity: 1
          }}
        >
          {calculatedWorkerContribution > 0 ? `${calculatedWorkerContribution.toFixed(2)}%` : null}
        </text>
        <>
          <path
            d={`M${avgDataXValue},0L${avgDataXValue},${calculateAvgLineLength()}`}
            fill="none"
            stroke="hsl(0, 100%, 30%)"
            strokeDasharray="5,5"
          />
        </>
      </g>
    );
  }
}

CustomBar.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  customBarProps: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  tooltipRef: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  summaryData: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  avgData: PropTypes.object,
  unit: PropTypes.string,
  graphHeight: PropTypes.number,
  heightToValueRatio: PropTypes.number
};

CustomBar.defaultProps = {
  intl: {},
  customBarProps: {},
  tooltipRef: {},
  summaryData: {},
  avgData: {},
  unit: '',
  graphHeight: 0,
  heightToValueRatio: 0
};

const mapStateToProps = state => ({
  summaryData: state.workerPerformanceChart.summaryData,
  avgData: state.workerPerformanceChart.avgData
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CustomBar));
