import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  gdsRcptFServ01ShowSyncSetting: null,
  gdsRcptFServ01ShowSyncSettingLoading: ['boolean'],
  gdsRcptFServ01ShowSyncSettingSuccess: ['syncSetting'],
  gdsRcptFServ01UpdateSyncSetting: ['formikBag', 'syncSetting'],
  gdsRcptFServ01ShowBatchJobStatus: null,
  gdsRcptFServ01ShowBatchJobStatusSuccess: ['batchJobStatus'],

  gdsRcptFServ01ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  gdsRcptFServ01GoToDocument: ['hdrId'],
  gdsRcptFServ01FetchGdsRcptFServ01: ['currentPage', 'sorts', 'filters', 'pageSize'],
  gdsRcptFServ01FetchGdsRcptFServ01Loading: ['boolean'],
  gdsRcptFServ01FetchGdsRcptFServ01Success: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],
  gdsRcptFServ01AddSelectedDocuments: ['selectedDocuments'],
  gdsRcptFServ01RemoveSelectedDocuments: ['selectedDocuments'],
  gdsRcptFServ01SetWorkspaceVisible: ['boolean'],

  gdsRcptFServ01Export: ['hdrIds'],
  gdsRcptFServ01ExportSuccess: null,
  gdsRcptFServ01ExportLoading: ['boolean'],

  gdsRcptFServ01SetExpandedRows: ['expandedRows']
});

export const GdsRcptFServ01Types = Types;
export default Creators;
