import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';

import { CloudDownloadOutlined } from '@ant-design/icons';

import { Table, Row, Col, Button } from 'antd';

import FileSaver from 'file-saver';
import XLSX from 'xlsx';

class ReportTable extends React.PureComponent {
  constructor() {
    super();

    this.useOnDownloadCsv = this.useOnDownloadCsv.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate() {}

  componentWillUnmount() {}

  useOnDownloadCsv() {
    const { intl, reportData, criteria } = this.props;

    const mappedDetails = reportData.map(dataEntry => {
      return criteria.columns.target.reduce((lastObj, columnEntry) => {
        const newObj = { ...lastObj };
        newObj[
          columnEntry.title ? columnEntry.title : intl.formatMessage({ id: columnEntry.column })
        ] = dataEntry[columnEntry.column];
        return newObj;
      }, {});
    });

    const ws = XLSX.utils.json_to_sheet(mappedDetails);

    const fileType = 'application/octet-stream';

    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'csv', type: 'array' });
    // eslint-disable-next-line no-undef
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, `ShipmentTracking.csv`);
  }

  render() {
    const { intl, criteria, reportData, reportIsLoading } = this.props;

    const dynamicColumns = criteria.columns.target.map(entry => {
      if (
        entry.key.includes('amt') ||
        entry.key.includes('qty') ||
        entry.key.includes('gross_weight') ||
        entry.key.includes('cubic_meter') ||
        entry.key === 'pack_list_hdr_case_qty' ||
        entry.key === 'pack_list_hdr_case_qty_02' ||
        entry.key === 'pack_list_hdr_case_qty_03' ||
        entry.key === 'pack_list_hdr_case_qty_04' ||
        entry.key === 'pack_list_hdr_case_qty_05'
      ) {
        return {
          width: 100,
          align: 'right',
          title: entry.title ? entry.title : intl.formatMessage({ id: entry.column }),
          // sort field
          dataIndex: entry.column,
          sorter: {
            compare: (a, b) => a[entry.column] - b[entry.column],
            multiple: 1
          },
          // filter field
          key: entry.column,
          render: text =>
            text === 0 ? (
              ''
            ) : (
              <>
                {new Intl.NumberFormat([], {
                  style: 'decimal',
                  minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                  maximumFractionDigits: 6
                }).format(text)}
              </>
            )
        };
      }

      return {
        width: 100,
        align: 'left',
        title: entry.title ? entry.title : intl.formatMessage({ id: entry.column }),
        // sort field
        dataIndex: entry.column,
        sorter: {
          compare: (a, b) => `${a[entry.column]}`.localeCompare(b[entry.column]),
          multiple: 1
        },
        // filter field
        key: entry.column,
        render: text => <>{text}</>
      };
    });

    return (
      <>
        <Table
          size="small"
          // rowSelection={rowSelection}
          rowKey="id"
          pagination={{
            pageSize: 20,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
          }}
          columns={dynamicColumns}
          dataSource={reportData}
          loading={reportIsLoading}
          bordered
          title={() => (
            <>
              <Row type="flex" justify="space-between" gutter={[0, 16]}>
                <Col span={8}>
                  <Button
                    type="primary"
                    // disabled={!isValid}
                    loading={reportIsLoading}
                    onClick={this.useOnDownloadCsv}
                    icon={<CloudDownloadOutlined />}
                  >
                    {intl.formatMessage({ id: 'excel' })}
                  </Button>
                </Col>
              </Row>
            </>
          )}
          scroll={{ x: 950 }}
        />
      </>
    );
  }
}

ReportTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,

  criteria: PropTypes.shape({
    columns: PropTypes.shape({
      source: PropTypes.arrayOf(PropTypes.object),
      target: PropTypes.arrayOf(PropTypes.object)
    })
  }),
  reportData: PropTypes.arrayOf(PropTypes.object),
  reportIsLoading: PropTypes.bool
};

ReportTable.defaultProps = {
  intl: {},

  criteria: {
    columns: {
      source: [],
      target: []
    }
  },
  reportData: [],
  reportIsLoading: false
};

const mapStateToProps = state => ({
  criteria: state.shipmentTrackingReport.criteria,

  reportData: state.shipmentTrackingReport.reportData,
  reportIsLoading: state.shipmentTrackingReport.reportIsLoading
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ReportTable));
