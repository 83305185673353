import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  putAwayDetailBoardResetWipGdsRcptTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  putAwayDetailBoardFetchWipGdsRcpt: ['currentPage', 'sorts', 'filters', 'pageSize'],
  putAwayDetailBoardFetchWipGdsRcptLoading: ['boolean'],
  putAwayDetailBoardFetchWipGdsRcptSuccess: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],

  putAwayDetailBoardResetWipPutAwayTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  putAwayDetailBoardFetchWipPutAway: ['currentPage', 'sorts', 'filters', 'pageSize'],
  putAwayDetailBoardFetchWipPutAwayLoading: ['boolean'],
  putAwayDetailBoardFetchWipPutAwaySuccess: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],

  putAwayDetailBoardResetWipWhseJobTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  putAwayDetailBoardFetchWipWhseJob: ['currentPage', 'sorts', 'filters', 'pageSize'],
  putAwayDetailBoardFetchWipWhseJobLoading: ['boolean'],
  putAwayDetailBoardFetchWipWhseJobSuccess: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],

  putAwayDetailBoardResetCompletePutAwayTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  putAwayDetailBoardFetchCompletePutAway: ['currentPage', 'sorts', 'filters', 'pageSize'],
  putAwayDetailBoardFetchCompletePutAwayLoading: ['boolean'],
  putAwayDetailBoardFetchCompletePutAwaySuccess: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],

  putAwayDetailBoardSetWipGdsRcptDialogVisible: ['boolean'],
  putAwayDetailBoardShowWipGdsRcptSummary: ['docId'],
  putAwayDetailBoardShowWipGdsRcptSummaryLoading: ['boolean'],
  putAwayDetailBoardShowWipGdsRcptSummarySuccess: ['document'],

  putAwayDetailBoardSetWipPutAwayDialogVisible: ['boolean'],
  putAwayDetailBoardShowWipPutAwaySummary: ['docId'],
  putAwayDetailBoardShowWipPutAwaySummaryLoading: ['boolean'],
  putAwayDetailBoardShowWipPutAwaySummarySuccess: ['document'],

  putAwayDetailBoardSetWipWhseJobDialogVisible: ['boolean'],
  putAwayDetailBoardShowWipWhseJobSummary: ['docId'],
  putAwayDetailBoardShowWipWhseJobSummaryLoading: ['boolean'],
  putAwayDetailBoardShowWipWhseJobSummarySuccess: ['document'],

  putAwayDetailBoardSetCompletePutAwayDialogVisible: ['boolean'],
  putAwayDetailBoardShowCompletePutAwaySummary: ['docId'],
  putAwayDetailBoardShowCompletePutAwaySummaryLoading: ['boolean'],
  putAwayDetailBoardShowCompletePutAwaySummarySuccess: ['document']
});

export const PutAwayDetailBoardTypes = Types;
export default Creators;
