import { put, call, select, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import { replace } from 'connected-react-router';
import ApiService from '../Services/ApiService';
import UtilService from '../Services/UtilService';
import AppActions from '../Stores/App/Actions';
import DelOrdDetailActions, { DelOrdDetailTypes } from '../Stores/DelOrdDetail/Actions';

const getAppStore = state => state.app;

const getDelOrdDetailStore = state => state.delOrdDetail;

export function* delOrdDetailInitHeader() {
  try {
    yield put(DelOrdDetailActions.delOrdDetailShowDocumentLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `delOrd/initHeader/${app.curDivisionId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(DelOrdDetailActions.delOrdDetailShowHeaderSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(DelOrdDetailActions.delOrdDetailShowDocumentLoading(false));
  }
}

export function* delOrdDetailShowHeader({ hdrId }) {
  try {
    yield put(DelOrdDetailActions.delOrdDetailShowDocumentLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `delOrd/showHeader/${hdrId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(DelOrdDetailActions.delOrdDetailShowHeaderSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(DelOrdDetailActions.delOrdDetailShowDocumentLoading(false));
  }
}

export function* delOrdDetailTransitionToStatus({ formikBag, hdrId, docStatus }) {
  formikBag.setSubmitting(true);
  yield put(DelOrdDetailActions.delOrdDetailShowDocumentLoading(true));
  try {
    const app = yield select(getAppStore);

    const postData = {
      hdrId,
      docStatus
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      'delOrd/transitionToStatus',
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      const delOrdDetail = yield select(getDelOrdDetailStore);

      const {
        documentHeader: oldDocumentHeader,
        documentDetails: oldDocumentDetails
      } = delOrdDetail;

      const {
        document_header: retDocumentHeader,
        document_details: retDocumentDetails,
        deleted_details: retDeletedDetails
      } = result.data;

      const processed = UtilService.processHeaderDetails(
        oldDocumentHeader,
        oldDocumentDetails,
        retDocumentHeader,
        retDocumentDetails,
        retDeletedDetails
      );

      yield put(
        DelOrdDetailActions.delOrdDetailUpdateDocumentSuccess(retDocumentHeader, processed.details)
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
    yield put(DelOrdDetailActions.delOrdDetailShowDocumentLoading(false));
  }
}

export function* delOrdDetailUpdateHeader({ formikBag, documentHeader }) {
  formikBag.setSubmitting(true);
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: documentHeader
    };

    const result = yield call(
      ApiService.putApi, // function
      app.apiUrl,
      'delOrd/updateHeader',
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      const delOrdDetail = yield select(getDelOrdDetailStore);

      const {
        documentHeader: oldDocumentHeader,
        documentDetails: oldDocumentDetails
      } = delOrdDetail;

      const {
        document_header: retDocumentHeader,
        document_details: retDocumentDetails,
        deleted_details: retDeletedDetails
      } = result.data;

      const processed = UtilService.processHeaderDetails(
        oldDocumentHeader,
        oldDocumentDetails,
        retDocumentHeader,
        retDocumentDetails,
        retDeletedDetails
      );

      yield put(
        DelOrdDetailActions.delOrdDetailUpdateDocumentSuccess(retDocumentHeader, processed.details)
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* delOrdDetailShowDetails({ hdrId }) {
  try {
    yield put(DelOrdDetailActions.delOrdDetailShowDocumentLoading(true));

    let result = {
      isSuccess: true,
      data: []
    };

    if (hdrId > 0) {
      const app = yield select(getAppStore);
      const getData = {};

      result = yield call(
        ApiService.getApi, // function
        app.apiUrl,
        `delOrd/showDetails/${hdrId}`,
        app.token,
        getData,
        'multipart/form-data' // params
      );
    }

    if (result.isSuccess === true) {
      yield put(DelOrdDetailActions.delOrdDetailShowDetailsSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(DelOrdDetailActions.delOrdDetailShowDocumentLoading(false));
  }
}

export function* delOrdDetailUpdateDetails({ formikBag, hdrId, documentDetails }) {
  formikBag.setSubmitting(true);
  yield put(DelOrdDetailActions.delOrdDetailShowDocumentLoading(true));
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: documentDetails
    };

    const result = yield call(
      ApiService.putApi, // function
      app.apiUrl,
      `delOrd/updateDetails/${hdrId}`,
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      const delOrdDetail = yield select(getDelOrdDetailStore);

      const {
        documentHeader: oldDocumentHeader,
        documentDetails: oldDocumentDetails
      } = delOrdDetail;

      const {
        document_header: retDocumentHeader,
        document_details: retDocumentDetails,
        deleted_details: retDeletedDetails
      } = result.data;

      const processed = UtilService.processHeaderDetails(
        oldDocumentHeader,
        oldDocumentDetails,
        retDocumentHeader,
        retDocumentDetails,
        retDeletedDetails
      );

      yield put(
        DelOrdDetailActions.delOrdDetailUpdateDocumentSuccess(retDocumentHeader, processed.details)
      );

      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_SUCCESS_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
    yield put(DelOrdDetailActions.delOrdDetailShowDocumentLoading(false));
  }
}

export function* delOrdDetailFetchSalesmanOptions({ search }) {
  try {
    yield put(DelOrdDetailActions.delOrdDetailFetchSalesmanOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `user/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({ value: d.id, label: `${d.username}` }));

      yield put(DelOrdDetailActions.delOrdDetailFetchSalesmanOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(DelOrdDetailActions.delOrdDetailFetchSalesmanOptionLoading(false));
  }
}

export function* delOrdDetailFetchDeliveryPointOptions({ search }) {
  try {
    yield put(DelOrdDetailActions.delOrdDetailFetchDeliveryPointOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `deliveryPoint/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.company_name_01}`
      }));

      yield put(DelOrdDetailActions.delOrdDetailFetchDeliveryPointOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(DelOrdDetailActions.delOrdDetailFetchDeliveryPointOptionLoading(false));
  }
}

export function* delOrdDetailFetchCreditTermOptions({ search }) {
  try {
    yield put(DelOrdDetailActions.delOrdDetailFetchCreditTermOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `creditTerm/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code}`
      }));

      yield put(DelOrdDetailActions.delOrdDetailFetchCreditTermOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(DelOrdDetailActions.delOrdDetailFetchCreditTermOptionLoading(false));
  }
}

export function* delOrdDetailFetchCurrencyOptions({ search }) {
  try {
    yield put(DelOrdDetailActions.delOrdDetailFetchCurrencyOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `currency/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code}`
      }));

      yield put(DelOrdDetailActions.delOrdDetailFetchCurrencyOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(DelOrdDetailActions.delOrdDetailFetchCurrencyOptionLoading(false));
  }
}

export function* delOrdDetailChangeDeliveryPoint({ formikBag, deliveryPointId }) {
  try {
    formikBag.setSubmitting(true);

    const app = yield select(getAppStore);
    const postData = {
      deliveryPointId
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `delOrd/changeDeliveryPoint`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      Object.entries(result.data).forEach(entry => {
        const key = entry[0];
        const value = entry[1];
        formikBag.setFieldValue(key, value);
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* delOrdDetailChangeCurrency({ formikBag, currencyId }) {
  try {
    formikBag.setSubmitting(true);

    const app = yield select(getAppStore);
    const postData = {
      currencyId
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `delOrd/changeCurrency`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      Object.entries(result.data).forEach(entry => {
        const key = entry[0];
        const value = entry[1];
        formikBag.setFieldValue(key, value);
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* delOrdDetailCreateHeader({ formikBag, documentHeader }) {
  formikBag.setSubmitting(true);
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: documentHeader
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      'delOrd/createHeader',
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      yield put(replace(`${app.appPath}/delOrdDetail/update/${result.data}`));
      yield put(DelOrdDetailActions.delOrdDetailSetHdrId(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* delOrdDetailFetchItemOptions({ search }) {
  try {
    yield put(DelOrdDetailActions.delOrdDetailFetchItemOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `item/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(DelOrdDetailActions.delOrdDetailFetchItemOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(DelOrdDetailActions.delOrdDetailFetchItemOptionLoading(false));
  }
}

export function* delOrdDetailChangeItem({ formikBag, hdrId, itemId }) {
  try {
    formikBag.setSubmitting(true);

    const app = yield select(getAppStore);
    const postData = {
      hdrId,
      itemId
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `delOrd/changeItem`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      Object.entries(result.data).forEach(entry => {
        const key = entry[0];
        const value = entry[1];
        formikBag.setFieldValue(key, value);
      });

      // reset the select2 cache
      formikBag.setFieldValue('uom_select2', {
        value: 0,
        label: ''
      });
      formikBag.setFieldValue('uom_rate', 1);

      yield put(DelOrdDetailActions.delOrdDetailFetchUomOptionSuccess([]));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* delOrdDetailChangeUom({ formikBag, hdrId, itemId, uomId }) {
  try {
    formikBag.setSubmitting(true);

    const app = yield select(getAppStore);
    const postData = {
      hdrId,
      itemId,
      uomId
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `delOrd/changeItemUom`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      Object.entries(result.data).forEach(entry => {
        const key = entry[0];
        const value = entry[1];
        formikBag.setFieldValue(key, value);
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* delOrdDetailFetchUomOptions({ itemId, search }) {
  try {
    yield put(DelOrdDetailActions.delOrdDetailFetchUomOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: [
        {
          field: 'item_id',
          value: itemId
        }
      ]
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `itemUom/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.uom_id,
        label: `${d.uom_code}`
      }));

      yield put(DelOrdDetailActions.delOrdDetailFetchUomOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(DelOrdDetailActions.delOrdDetailFetchUomOptionLoading(false));
  }
}

export function* delOrdDetailCreateDetail({ formikBag, hdrId, documentDetail }) {
  formikBag.setSubmitting(true);
  yield put(DelOrdDetailActions.delOrdDetailShowDocumentLoading(true));
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: documentDetail
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `delOrd/createDetail/${hdrId}`,
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      const delOrdDetail = yield select(getDelOrdDetailStore);

      const {
        documentHeader: oldDocumentHeader,
        documentDetails: oldDocumentDetails
      } = delOrdDetail;

      const {
        document_header: retDocumentHeader,
        document_details: retDocumentDetails,
        deleted_details: retDeletedDetails
      } = result.data;

      const processed = UtilService.processHeaderDetails(
        oldDocumentHeader,
        oldDocumentDetails,
        retDocumentHeader,
        retDocumentDetails,
        retDeletedDetails
      );

      yield put(
        DelOrdDetailActions.delOrdDetailUpdateDocumentSuccess(retDocumentHeader, processed.details)
      );

      yield put(DelOrdDetailActions.delOrdDetailSetDocumentDetail(delOrdDetail.initDocumentDetail));

      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_SUCCESS_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
    yield put(DelOrdDetailActions.delOrdDetailShowDocumentLoading(false));
  }
}

export function* delOrdDetailDeleteDetail({ hdrId, documentDetail }) {
  yield put(DelOrdDetailActions.delOrdDetailShowDocumentLoading(true));
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: [{ id: documentDetail.id }]
    };

    const result = yield call(
      ApiService.deleteApi, // function
      app.apiUrl,
      `delOrd/deleteDetails/${hdrId}`,
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      const delOrdDetail = yield select(getDelOrdDetailStore);

      const {
        documentHeader: oldDocumentHeader,
        documentDetails: oldDocumentDetails
      } = delOrdDetail;

      const {
        document_header: retDocumentHeader,
        document_details: retDocumentDetails,
        deleted_details: retDeletedDetails
      } = result.data;

      const processed = UtilService.processHeaderDetails(
        oldDocumentHeader,
        oldDocumentDetails,
        retDocumentHeader,
        retDocumentDetails,
        retDeletedDetails
      );

      yield put(
        DelOrdDetailActions.delOrdDetailUpdateDocumentSuccess(retDocumentHeader, processed.details)
      );

      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_SUCCESS_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(DelOrdDetailActions.delOrdDetailShowDocumentLoading(false));
  }
}

export function* delOrdDetailFetchLocationOptions({ search }) {
  try {
    yield put(DelOrdDetailActions.delOrdDetailFetchLocationOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId: app.curSiteFlowId,
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `location/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(DelOrdDetailActions.delOrdDetailFetchLocationOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(DelOrdDetailActions.delOrdDetailFetchLocationOptionLoading(false));
  }
}

export function* delOrdDetailFetchProjectOptions({ siteFlowId, search }) {
  try {
    yield put(DelOrdDetailActions.delOrdDetailFetchProjectOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId,
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `project/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(DelOrdDetailActions.delOrdDetailFetchProjectOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(DelOrdDetailActions.delOrdDetailFetchProjectOptionLoading(false));
  }
}

export function* delOrdDetailShowPickedDetails({ hdrId }) {
  try {
    yield put(DelOrdDetailActions.delOrdDetailShowPickedLoading(true));

    let result = {
      isSuccess: true,
      data: []
    };

    if (hdrId > 0) {
      const app = yield select(getAppStore);
      const getData = {};

      result = yield call(
        ApiService.getApi, // function
        app.apiUrl,
        `delOrd/showPickListOutbOrdDtls/${hdrId}`,
        app.token,
        getData,
        'multipart/form-data' // params
      );
    }

    if (result.isSuccess === true) {
      yield put(DelOrdDetailActions.delOrdDetailShowPickedDetailsSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(DelOrdDetailActions.delOrdDetailShowPickedLoading(false));
  }
}

export const saga = [
  takeLatest(DelOrdDetailTypes.DEL_ORD_DETAIL_INIT_HEADER, delOrdDetailInitHeader),
  takeLatest(DelOrdDetailTypes.DEL_ORD_DETAIL_SHOW_HEADER, delOrdDetailShowHeader),

  takeLatest(DelOrdDetailTypes.DEL_ORD_DETAIL_UPDATE_HEADER, delOrdDetailUpdateHeader),
  takeLatest(DelOrdDetailTypes.DEL_ORD_DETAIL_CREATE_HEADER, delOrdDetailCreateHeader),

  takeLatest(DelOrdDetailTypes.DEL_ORD_DETAIL_TRANSITION_TO_STATUS, delOrdDetailTransitionToStatus),

  takeLatest(DelOrdDetailTypes.DEL_ORD_DETAIL_SHOW_DETAILS, delOrdDetailShowDetails),

  takeLatest(DelOrdDetailTypes.DEL_ORD_DETAIL_UPDATE_DETAILS, delOrdDetailUpdateDetails),
  takeLatest(DelOrdDetailTypes.DEL_ORD_DETAIL_CREATE_DETAIL, delOrdDetailCreateDetail),
  takeLatest(DelOrdDetailTypes.DEL_ORD_DETAIL_DELETE_DETAIL, delOrdDetailDeleteDetail),

  takeLatest(
    DelOrdDetailTypes.DEL_ORD_DETAIL_FETCH_SALESMAN_OPTIONS,
    delOrdDetailFetchSalesmanOptions
  ),

  takeLatest(
    DelOrdDetailTypes.DEL_ORD_DETAIL_FETCH_DELIVERY_POINT_OPTIONS,
    delOrdDetailFetchDeliveryPointOptions
  ),
  takeLatest(
    DelOrdDetailTypes.DEL_ORD_DETAIL_CHANGE_DELIVERY_POINT,
    delOrdDetailChangeDeliveryPoint
  ),

  takeLatest(
    DelOrdDetailTypes.DEL_ORD_DETAIL_FETCH_CREDIT_TERM_OPTIONS,
    delOrdDetailFetchCreditTermOptions
  ),

  takeLatest(
    DelOrdDetailTypes.DEL_ORD_DETAIL_FETCH_CURRENCY_OPTIONS,
    delOrdDetailFetchCurrencyOptions
  ),
  takeLatest(DelOrdDetailTypes.DEL_ORD_DETAIL_CHANGE_CURRENCY, delOrdDetailChangeCurrency),

  takeLatest(
    DelOrdDetailTypes.DEL_ORD_DETAIL_FETCH_LOCATION_OPTIONS,
    delOrdDetailFetchLocationOptions
  ),

  takeLatest(DelOrdDetailTypes.DEL_ORD_DETAIL_FETCH_ITEM_OPTIONS, delOrdDetailFetchItemOptions),
  takeLatest(DelOrdDetailTypes.DEL_ORD_DETAIL_CHANGE_ITEM, delOrdDetailChangeItem),

  takeLatest(DelOrdDetailTypes.DEL_ORD_DETAIL_CHANGE_UOM, delOrdDetailChangeUom),
  takeLatest(DelOrdDetailTypes.DEL_ORD_DETAIL_FETCH_UOM_OPTIONS, delOrdDetailFetchUomOptions),

  takeLatest(
    DelOrdDetailTypes.DEL_ORD_DETAIL_FETCH_PROJECT_OPTIONS,
    delOrdDetailFetchProjectOptions
  ),

  takeLatest(DelOrdDetailTypes.DEL_ORD_DETAIL_SHOW_PICKED_DETAILS, delOrdDetailShowPickedDetails)
];
