import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import moment from 'moment';

import { DeleteOutlined } from '@ant-design/icons';

import { Row, Col, Modal, Table, Button, Popconfirm } from 'antd';

import GdsRcptRtnIndexActions from '../../Stores/GdsRcptRtnIndex/Actions';

class GdsRcptRtnIndexDialog extends React.PureComponent {
  constructor() {
    super();

    this.handleCancel = this.handleCancel.bind(this);
    this.useOnResetGdsRcptFServ02 = this.useOnResetGdsRcptFServ02.bind(this);

    this.getSelectedDocumentColumns = this.getSelectedDocumentColumns.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate() {}

  componentWillUnmount() {}

  handleCancel() {
    const { setWorkspaceVisible } = this.props;

    setWorkspaceVisible(false);
  }

  getSelectedDocumentColumns() {
    const { intl, removeSelectedDocuments } = this.props;

    return [
      {
        align: 'left',
        title: intl.formatMessage({ id: 'doc_code' }),
        // sort field
        dataIndex: 'doc_code',
        sorter: (a, b) => `${a.doc_code}`.localeCompare(b.doc_code),
        // filter field
        key: 'doc_code',
        render: (text, record) => (
          <>
            <b>{record.doc_code}</b>
            <br />
            {record.str_doc_status}
          </>
        )
      },
      {
        align: 'left',
        title: intl.formatMessage({ id: 'doc_date' }),
        // sort field
        dataIndex: 'doc_date',
        sorter: (a, b) => new Date(a.doc_date) - new Date(b.doc_date),
        // filter field
        key: 'doc_date',
        render: (text, record) => <>{moment(record.doc_date).format('YYYY-MM-DD')}</>
      },
      {
        width: 90,
        align: 'left',
        title: intl.formatMessage({ id: 'area' }),
        // sort field
        dataIndex: 'delivery_point_area_code',
        // filter field
        key: 'delivery_point_area',
        render: (text, record) =>
          record.inb_ord_hdr && (
            <>
              <b>{record.inb_ord_hdr.delivery_point_area_code}</b>
              <br />
              {record.inb_ord_hdr.delivery_point_area_desc_01}
            </>
          )
      },
      {
        width: 120,
        align: 'left',
        title: intl.formatMessage({ id: 'salesman' }),
        // sort field
        dataIndex: 'salesman_username',
        // filter field
        key: 'salesman',
        render: (text, record) =>
          record.inb_ord_hdr && <b>{record.inb_ord_hdr.salesman_username}</b>
      },
      {
        width: 150,
        align: 'left',
        title: intl.formatMessage({ id: 'delivery_point' }),
        // sort field
        dataIndex: 'delivery_point_code',
        // filter field
        key: 'delivery_point',
        render: (text, record) =>
          record.inb_ord_hdr && (
            <>
              <b>{record.inb_ord_hdr.delivery_point_code}</b>
              <br />
              {record.inb_ord_hdr.delivery_point_company_name_01}
            </>
          )
      },
      {
        width: 150,
        // fixed: 'right',
        align: 'left',
        title: intl.formatMessage({ id: 'documents' }),
        // sort field
        dataIndex: 'inb_ord_hdr_code',
        // filter field
        key: 'inb_ord_hdr',
        render: (text, record) =>
          record.inb_ord_hdr && (
            <div key={record.inb_ord_hdr.id} style={{ width: '100%', textAlign: 'right' }}>
              <div>
                <b>{record.inb_ord_hdr.shipping_doc_code}</b>
              </div>
              <div>
                <b>{record.inb_ord_hdr.ref_code_01}</b>
              </div>
              <div>
                {new Intl.NumberFormat([], {
                  style: 'decimal',
                  minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                  maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
                }).format(record.inb_ord_hdr.net_amt)}
              </div>
            </div>
          )
      },

      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'desc_01' }),
        // sort field
        dataIndex: 'desc_01',
        // filter field
        key: 'desc_01',
        render: (text, record) => <>{record.desc_01}</>
      },
      {
        key: 'action',
        render: (text, record) => (
          <>
            <Popconfirm
              placement="left"
              title={intl.formatMessage({ id: 'are_you_sure_to_remove_this_document' })}
              onConfirm={() => {
                removeSelectedDocuments([record]);
              }}
              onCancel={() => {}}
              okText={intl.formatMessage({ id: 'yes' })}
              cancelText={intl.formatMessage({ id: 'cancel' })}
            >
              <Button type="dashed" icon={<DeleteOutlined />} />
            </Popconfirm>
          </>
        )
      }
    ];
  }

  useOnResetGdsRcptFServ02() {
    const { selectedDocuments, resetGdsRcptFServ02 } = this.props;

    const hdrIds = selectedDocuments.map(value => {
      return value.id;
    });

    resetGdsRcptFServ02(hdrIds);
  }

  render() {
    const { intl, selectedDocuments, workspaceIsVisible, workspaceIsLoading } = this.props;

    return (
      <>
        <Modal
          visible={workspaceIsVisible}
          title={intl.formatMessage({ id: 'selected_documents' })}
          // style={{top:20}}
          width="80%"
          centered
          // onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <Button
              key="create"
              disabled={selectedDocuments.length === 0}
              loading={workspaceIsLoading}
              onClick={this.useOnResetGdsRcptFServ02}
            >
              {intl.formatMessage({ id: 'reset_ax_export' })}
            </Button>
          ]}
        >
          <Row type="flex" justify="center" gutter={[0, 8]}>
            <Col span={24}>
              <Table
                // rowSelection={rowSelection}
                rowKey="id"
                pagination={false}
                columns={this.getSelectedDocumentColumns()}
                dataSource={selectedDocuments}
                loading={workspaceIsLoading}
                bordered
                title={() => <b>{intl.formatMessage({ id: 'selected_documents' })}</b>}
              />
            </Col>
          </Row>
        </Modal>
      </>
    );
  }
}

GdsRcptRtnIndexDialog.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  setWorkspaceVisible: PropTypes.func,
  workspaceIsVisible: PropTypes.bool,

  removeSelectedDocuments: PropTypes.func,
  selectedDocuments: PropTypes.arrayOf(PropTypes.object),

  workspaceIsLoading: PropTypes.bool,

  resetGdsRcptFServ02: PropTypes.func
};

GdsRcptRtnIndexDialog.defaultProps = {
  intl: {},
  setWorkspaceVisible() {},
  workspaceIsVisible: false,

  removeSelectedDocuments() {},
  selectedDocuments: [],

  workspaceIsLoading: false,

  resetGdsRcptFServ02() {}
};

const mapStateToProps = state => ({
  workspaceIsVisible: state.gdsRcptRtnIndex.workspaceIsVisible,

  selectedDocuments: state.gdsRcptRtnIndex.selectedDocuments,

  workspaceIsLoading: state.gdsRcptRtnIndex.workspaceIsLoading
});

const mapDispatchToProps = dispatch => ({
  setWorkspaceVisible: boolean =>
    dispatch(GdsRcptRtnIndexActions.gdsRcptRtnIndexSetWorkspaceVisible(boolean)),

  removeSelectedDocuments: selectedDocuments =>
    dispatch(GdsRcptRtnIndexActions.gdsRcptRtnIndexRemoveSelectedDocuments(selectedDocuments)),

  resetGdsRcptFServ02: hdrIds =>
    dispatch(GdsRcptRtnIndexActions.gdsRcptRtnIndexResetGdsRcptFServ02(hdrIds))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(GdsRcptRtnIndexDialog));
