import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import treeChanges from 'tree-changes';

import { Card, Tabs, Tooltip, Spin } from 'antd';

import ItemProcessActions from '../../Stores/ItemProcess/Actions';

import ItemList01Table from '../ItemList01/ItemList01Table';
import ItemList02Table from '../ItemList02/ItemList02Table';
import ItemExcel01Table from '../ItemExcel01/ItemExcel01Table';
import ItemBatchExcel01Table from '../ItemBatchExcel01/ItemBatchExcel01Table';
import ItemSync01Form from '../ItemSync01/ItemSync01Form';
import ItemSync0101Form from '../ItemSync0101/ItemSync0101Form';

const { TabPane } = Tabs;

class ItemProcessScreen extends React.PureComponent {
  componentDidMount() {
    const { curSiteFlowId, fetchItemProcess } = this.props;
    if (curSiteFlowId > 0) {
      fetchItemProcess();
    }
  }

  componentDidUpdate(prevProps) {
    const { curSiteFlowId, fetchItemProcess } = this.props;

    const { changed } = treeChanges(prevProps, this.props);

    if (changed('curSiteFlowId')) {
      if (curSiteFlowId > 0) {
        fetchItemProcess();
      }
    }
  }

  componentWillUnmount() {}

  render() {
    const { intl, match, process, appPath, processIsLoading } = this.props;

    return (
      <Card>
        <Spin spinning={processIsLoading}>
          <Tabs type="card" activeKey={match.params.proc_type}>
            {process.map(value => (
              <TabPane
                tab={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <Tooltip
                    placement="bottom"
                    title={intl.formatMessage({ id: `${value.proc_type.toLowerCase()}_desc` })}
                  >
                    <Link
                      style={value.proc_type !== match.params.proc_type ? { color: 'black' } : {}}
                      to={`${appPath}/itemProcess/index/${value.proc_type}`}
                    >
                      <FormattedMessage id={`${value.proc_type.toLowerCase()}_label`} />
                    </Link>
                  </Tooltip>
                }
                key={value.proc_type}
              />
            ))}
          </Tabs>
        </Spin>

        {match.params.proc_type === 'ITEM_LIST_01' && <ItemList01Table match={match} />}
        {match.params.proc_type === 'ITEM_LIST_02' && <ItemList02Table match={match} />}
        {match.params.proc_type === 'ITEM_EXCEL_01' && <ItemExcel01Table match={match} />}
        {match.params.proc_type === 'ITEM_BATCH_EXCEL_01' && (
          <ItemBatchExcel01Table match={match} />
        )}
        {match.params.proc_type === 'ITEM_SYNC_01' && <ItemSync01Form match={match} />}
        {match.params.proc_type === 'ITEM_SYNC_01_01' && <ItemSync0101Form match={match} />}
      </Card>
    );
  }
}

ItemProcessScreen.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object,
  appPath: PropTypes.string,
  process: PropTypes.arrayOf(PropTypes.object),
  processIsLoading: PropTypes.bool,

  curSiteFlowId: PropTypes.number,
  fetchItemProcess: PropTypes.func
};

ItemProcessScreen.defaultProps = {
  intl: {},
  match: {},
  appPath: '',
  process: [],
  processIsLoading: false,

  curSiteFlowId: 0,
  fetchItemProcess() {}
};

const mapStateToProps = state => ({
  appPath: state.app.appPath,
  process: state.itemProcess.process,
  processIsLoading: state.itemProcess.processIsLoading,

  curSiteFlowId: state.app.curSiteFlowId
});

const mapDispatchToProps = dispatch => ({
  fetchItemProcess: () => dispatch(ItemProcessActions.itemProcessFetchItemProcess())
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ItemProcessScreen));
