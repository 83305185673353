import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  whseJob060201ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  whseJob060201GoToWhseJobDocument: ['hdrId'],
  whseJob060201FetchWhseJob060201: ['currentPage', 'sorts', 'filters', 'pageSize'],
  whseJob060201FetchWhseJob060201Loading: ['boolean'],
  whseJob060201FetchWhseJob060201Success: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],
  whseJob060201AddSelectedDocuments: ['selectedDocuments'],
  whseJob060201RemoveSelectedDocuments: ['selectedDocuments'],
  whseJob060201SetWorkspaceVisible: ['boolean'],
  whseJob060201PrintWhseJob060201: ['hdrIds'],
  whseJob060201PrintWhseJob060201Loading: ['boolean'],

  whseJob060201SetExpandedRows: ['expandedRows']
});

export const WhseJob060201Types = Types;
export default Creators;
