import React from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';

import { UndoOutlined, SaveOutlined } from '@ant-design/icons';

import { Button, Row, Col, Typography } from 'antd';
import {
  FormikInput,
  FormikSelect,
  FormikButton,
  FormikText,
  FormikInputNumber,
  FormikDatePicker,
  FormikTextNumber
} from '../../Components/Formik';
import CycleCountDetailActions from '../../Stores/CycleCountDetail/Actions';
import BatchSerialControl from '../../Services/Env/BatchSerialControl';

const { Text } = Typography;

class WhseJobType15Form extends React.PureComponent {
  constructor() {
    super();

    this.handleCompanySearch = this.handleCompanySearch.bind(this);
    this.handleSearchItem = this.handleSearchItem.bind(this);
    this.handleSearchItemBatch = this.handleSearchItemBatch.bind(this);
    this.handleSearchUom = this.handleSearchUom.bind(this);
    this.handleSearchStorageBin = this.handleSearchStorageBin.bind(this);
    this.handleSearchHandlingUnit = this.handleSearchHandlingUnit.bind(this);
    this.handleSearchQuantBal = this.handleSearchQuantBal.bind(this);

    this.handleChangeItem = this.handleChangeItem.bind(this);
    this.handleChangeItemBatch = this.handleChangeItemBatch.bind(this);
    this.handleChangeUom = this.handleChangeUom.bind(this);
    this.handleChangeStorageBin = this.handleChangeStorageBin.bind(this);
    this.handleChangeQuantBal = this.handleChangeQuantBal.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate() {}

  componentWillUnmount() {}

  handleCompanySearch(form, value) {
    const { fetchCompanyOptions } = this.props;

    fetchCompanyOptions(value);
  }

  handleSearchItemBatch(form, value) {
    const { fetchItemBatchOptions } = this.props;
    const { values } = form;

    fetchItemBatchOptions(values.item_select2.value, value);
  }

  handleSearchItem(form, value) {
    const { fetchItemOptions } = this.props;

    fetchItemOptions(value);
  }

  handleSearchUom(form, value) {
    const { fetchUomOptions } = this.props;
    const { values } = form;

    fetchUomOptions(values.item_select2.value, value);
  }

  handleSearchStorageBin(form, value) {
    const { fetchStorageBinOptions } = this.props;

    fetchStorageBinOptions(value);
  }

  handleSearchHandlingUnit(form, value) {
    const { fetchHandlingUnitOptions } = this.props;

    fetchHandlingUnitOptions(value);
  }

  handleSearchQuantBal(form, value) {
    const { fetchQuantBalOptions } = this.props;
    const { values } = form;

    const companyId = values.company_select2.value;
    const storageBinId = values.storage_bin_select2.value;
    if (companyId > 0 && storageBinId > 0) {
      fetchQuantBalOptions(companyId, storageBinId, value);
    }
  }

  handleChangeItem(form, value) {
    // params: form, value, option
    const { hdrId, changeItem } = this.props;

    changeItem(form, hdrId, value);
  }

  handleChangeItemBatch(form, value) {
    // params: form, value, option
    const { hdrId, changeItemBatch } = this.props;

    changeItemBatch(form, hdrId, value);
  }

  handleChangeUom(form, value) {
    // params: form, value, option
    const { hdrId, changeUom } = this.props;
    const { values } = form;

    changeUom(form, hdrId, values.item_select2.value, value);
  }

  handleChangeQuantBal(form, value) {
    // params: form, value, option

    const { hdrId, changeQuantBal } = this.props;

    changeQuantBal(form, hdrId, value);
  }

  handleChangeStorageBin(form) {
    // params: form, value, option

    const { fetchQuantBalOptionSuccess } = this.props;
    const { values: curValues, setFieldValue } = form;

    if ('storage_bin_select2' in curValues) {
      setFieldValue('quant_bal_select2', {
        value: 0,
        label: ''
      });
      setFieldValue('handling_unit_barcode', '');
      setFieldValue('item_code', '');
      setFieldValue('item_desc_01', '');
      setFieldValue('item_desc_02', '');

      fetchQuantBalOptionSuccess([]);
    }
  }

  render() {
    const {
      intl,
      hdrId,
      documentHeader,
      documentDetail,
      updateDetails,
      createDetail,
      documentIsLoading,
      companyIsLoading,
      companyOptions,
      itemIsLoading,
      itemOptions,
      itemBatchIsLoading,
      itemBatchOptions,
      uomIsLoading,
      uomOptions,
      storageBinIsLoading,
      storageBinOptions,
      quantBalIsLoading,
      quantBalOptions,
      handlingUnitIsLoading,
      handlingUnitOptions
    } = this.props;

    const disabled = documentHeader && documentHeader.doc_status >= 50;
    return (
      <Formik
        enableReinitialize
        initialValues={documentDetail}
        onSubmit={(values, formikBag) => {
          // remove the submit_action field
          const processedValues = { ...values, whse_job_type: 15 };
          delete processedValues.submit_action;

          if (values.id > 0) {
            updateDetails(formikBag, hdrId, [processedValues]);
          } else {
            createDetail(formikBag, hdrId, processedValues);
          }
        }}
        validationSchema={Yup.object().shape({
          company_select2: Yup.object().shape({
            value: Yup.number().min(1, intl.formatMessage({ id: 'company_is_required' }))
          }),
          storage_bin_select2: Yup.object().shape({
            value: Yup.number().min(1, intl.formatMessage({ id: 'storage_bin_is_required' }))
          }),
          /*
          item_select2: Yup.object().shape({
            value: Yup.number().min(1, intl.formatMessage({ id: 'item_is_required' }))
          }),
          item_batch_select2: Yup.object().shape({
            value: Yup.number().min(1, intl.formatMessage({ id: 'item_batch_is_required' }))
          }),
          uom_select2: Yup.object().shape({
            value: Yup.number().min(1, intl.formatMessage({ id: 'uom_is_required' }))
          }),
          qty: Yup.number().moreThan(0, intl.formatMessage({ id: 'qty_must_be_greater_than_0' }))
          */
          uom_select2: Yup.object().when('qty', {
            is: qty => qty > 0,
            then: Yup.object().shape({
              value: Yup.number().min(1, intl.formatMessage({ id: 'uom_is_required' }))
            })
          })
        })}
      >
        {({
          values,
          // handleChange,
          // errors,
          // setFieldTouched,
          dirty,
          // touched,
          isSubmitting,
          // isValid,
          handleSubmit,
          handleReset
        }) => {
          const isBatchSerialControl = BatchSerialControl.isBatchSerialControl(
            values.batch_serial_control
          );

          return (
            <Form>
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'company' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="company_select2"
                    component={FormikSelect}
                    showArrow
                    filterOption={false}
                    onSearch={this.handleCompanySearch}
                    notFoundContent={null}
                    loading={companyIsLoading}
                    options={companyOptions}
                    disabled={disabled}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'storage_bin' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="storage_bin_select2"
                    component={FormikSelect}
                    showArrow
                    filterOption={false}
                    onSearch={this.handleSearchStorageBin}
                    notFoundContent={null}
                    loading={storageBinIsLoading}
                    options={storageBinOptions}
                    disabled={disabled}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'quant_bal' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="quant_bal_select2"
                    component={FormikSelect}
                    showArrow
                    filterOption={false}
                    onSearch={this.handleSearchQuantBal}
                    notFoundContent={null}
                    loading={quantBalIsLoading}
                    options={quantBalOptions}
                    disabled={disabled}
                    onChange={this.handleChangeQuantBal}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'pallet_id' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="handling_unit_select2"
                    component={FormikSelect}
                    showArrow
                    filterOption={false}
                    onSearch={this.handleSearchHandlingUnit}
                    notFoundContent={null}
                    loading={handlingUnitIsLoading}
                    options={handlingUnitOptions}
                    disabled={disabled}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'item' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="item_select2"
                    component={FormikSelect}
                    showArrow
                    filterOption={false}
                    onSearch={this.handleSearchItem}
                    notFoundContent={null}
                    loading={itemIsLoading}
                    options={itemOptions}
                    disabled={disabled}
                    onChange={this.handleChangeItem}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'item_desc' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field name="desc_01" component={FormikText} />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text />
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field name="desc_02" component={FormikText} />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'item_batch' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="item_batch_select2"
                    component={FormikSelect}
                    showArrow
                    filterOption={false}
                    onSearch={this.handleSearchItemBatch}
                    notFoundContent={null}
                    loading={itemBatchIsLoading}
                    options={itemBatchOptions}
                    disabled={disabled}
                    onChange={this.handleChangeItemBatch}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'batch_serial_no' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="batch_serial_no"
                    component={FormikInput}
                    disabled={!isBatchSerialControl || disabled}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'expiry_date' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="expiry_date"
                    component={FormikDatePicker}
                    disabled={disabled || documentIsLoading}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'receipt_date' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="receipt_date"
                    component={FormikDatePicker}
                    disabled={disabled || documentIsLoading}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'qty' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="qty"
                    component={FormikInputNumber}
                    precision={values.qty_scale || 0}
                    disabled={disabled}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text />
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={10}>
                  <Field
                    name="uom_select2"
                    component={FormikSelect}
                    showArrow
                    filterOption={false}
                    onSearch={this.handleSearchUom}
                    notFoundContent={null}
                    loading={uomIsLoading}
                    options={uomOptions}
                    disabled={disabled}
                    onChange={this.handleChangeUom}
                  />
                </Col>
                <Col span={10}>
                  <Field
                    name="uom_rate"
                    component={FormikTextNumber}
                    minimumfractiondigits={6}
                    maximumfractiondigits={6}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="end" gutter={[0, 16]}>
                <Col span={5}>
                  <Button
                    type="primary"
                    disabled={!dirty}
                    loading={isSubmitting || documentIsLoading}
                    onClick={handleReset}
                    icon={<UndoOutlined />}
                  >
                    {intl.formatMessage({ id: 'reset' })}
                  </Button>
                  <Field
                    type="primary"
                    name="submit_action"
                    value="update"
                    component={FormikButton}
                    disabled={disabled}
                    loading={isSubmitting || documentIsLoading}
                    onClick={handleSubmit}
                    label={intl.formatMessage({
                      id: values.id > 0 ? 'update' : 'create'
                    })}
                    icon={<SaveOutlined />}
                  />
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

WhseJobType15Form.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  updateDetails: PropTypes.func,
  createDetail: PropTypes.func,
  hdrId: PropTypes.number,
  documentHeader: PropTypes.shape({
    doc_status: PropTypes.number
  }),
  documentDetail: PropTypes.shape({ batch_serial_control: PropTypes.number }),
  documentIsLoading: PropTypes.bool,

  fetchCompanyOptions: PropTypes.func,
  companyIsLoading: PropTypes.bool,
  companyOptions: PropTypes.arrayOf(PropTypes.object),

  fetchItemOptions: PropTypes.func,
  changeItem: PropTypes.func,
  itemIsLoading: PropTypes.bool,
  itemOptions: PropTypes.arrayOf(PropTypes.object),

  fetchItemBatchOptions: PropTypes.func,
  changeItemBatch: PropTypes.func,
  itemBatchIsLoading: PropTypes.bool,
  itemBatchOptions: PropTypes.arrayOf(PropTypes.object),

  fetchUomOptions: PropTypes.func,
  changeUom: PropTypes.func,
  uomIsLoading: PropTypes.bool,
  uomOptions: PropTypes.arrayOf(PropTypes.object),

  fetchStorageBinOptions: PropTypes.func,
  storageBinIsLoading: PropTypes.bool,
  storageBinOptions: PropTypes.arrayOf(PropTypes.object),

  fetchHandlingUnitOptions: PropTypes.func,
  handlingUnitIsLoading: PropTypes.bool,
  handlingUnitOptions: PropTypes.arrayOf(PropTypes.object),

  fetchQuantBalOptions: PropTypes.func,
  fetchQuantBalOptionSuccess: PropTypes.func,
  changeQuantBal: PropTypes.func,
  quantBalIsLoading: PropTypes.bool,
  quantBalOptions: PropTypes.arrayOf(PropTypes.object)
};

WhseJobType15Form.defaultProps = {
  intl: {},
  updateDetails() {},
  createDetail() {},
  hdrId: 0,
  documentHeader: { doc_status: 3 },
  documentDetail: { batch_serial_control: 0 },
  documentIsLoading: false,

  fetchCompanyOptions() {},
  companyIsLoading: false,
  companyOptions: [],

  fetchItemOptions() {},
  changeItem() {},
  itemIsLoading: false,
  itemOptions: [],

  fetchItemBatchOptions() {},
  changeItemBatch() {},
  itemBatchIsLoading: false,
  itemBatchOptions: [],

  fetchUomOptions() {},
  changeUom() {},
  uomIsLoading: false,
  uomOptions: [],

  fetchStorageBinOptions() {},
  storageBinIsLoading: false,
  storageBinOptions: [],

  fetchHandlingUnitOptions() {},
  handlingUnitIsLoading: false,
  handlingUnitOptions: [],

  fetchQuantBalOptions() {},
  fetchQuantBalOptionSuccess() {},
  quantBalIsLoading: false,
  quantBalOptions: [],
  changeQuantBal() {}
};

const mapStateToProps = state => ({
  hdrId: state.cycleCountDetail.hdrId,
  documentHeader: state.cycleCountDetail.documentHeader,
  documentDetail: state.cycleCountDetail.documentDetail,
  documentIsLoading: state.cycleCountDetail.documentIsLoading,

  companyIsLoading: state.cycleCountDetail.companyIsLoading,
  companyOptions: state.cycleCountDetail.companyOptions,

  itemIsLoading: state.cycleCountDetail.itemIsLoading,
  itemOptions: state.cycleCountDetail.itemOptions,

  itemBatchIsLoading: state.cycleCountDetail.itemBatchIsLoading,
  itemBatchOptions: state.cycleCountDetail.itemBatchOptions,

  uomIsLoading: state.cycleCountDetail.uomIsLoading,
  uomOptions: state.cycleCountDetail.uomOptions,

  storageBinIsLoading: state.cycleCountDetail.storageBinIsLoading,
  storageBinOptions: state.cycleCountDetail.storageBinOptions,

  handlingUnitIsLoading: state.cycleCountDetail.handlingUnitIsLoading,
  handlingUnitOptions: state.cycleCountDetail.handlingUnitOptions,

  quantBalIsLoading: state.cycleCountDetail.quantBalIsLoading,
  quantBalOptions: state.cycleCountDetail.quantBalOptions
});

const mapDispatchToProps = dispatch => ({
  updateDetails: (formikBag, hdrId, documentDetails) =>
    dispatch(
      CycleCountDetailActions.cycleCountDetailUpdateDetails(formikBag, hdrId, documentDetails)
    ),
  createDetail: (formikBag, hdrId, documentDetail) =>
    dispatch(
      CycleCountDetailActions.cycleCountDetailCreateDetail(formikBag, hdrId, documentDetail)
    ),

  fetchCompanyOptions: search =>
    dispatch(CycleCountDetailActions.cycleCountDetailFetchCompanyOptions(search)),

  fetchItemOptions: search =>
    dispatch(CycleCountDetailActions.cycleCountDetailFetchItemOptions(search)),
  changeItem: (formikBag, hdrId, itemId) =>
    dispatch(CycleCountDetailActions.cycleCountDetailChangeItem(formikBag, hdrId, itemId)),

  fetchItemBatchOptions: (itemId, search) =>
    dispatch(CycleCountDetailActions.cycleCountDetailFetchItemBatchOptions(itemId, search)),
  changeItemBatch: (formikBag, hdrId, itemBatchId) =>
    dispatch(
      CycleCountDetailActions.cycleCountDetailChangeItemBatch(formikBag, hdrId, itemBatchId)
    ),

  fetchUomOptions: (itemId, search) =>
    dispatch(CycleCountDetailActions.cycleCountDetailFetchUomOptions(itemId, search)),
  fetchUomOptionSuccess: options =>
    dispatch(CycleCountDetailActions.cycleCountDetailFetchUomOptionSuccess(options)),
  changeUom: (formikBag, hdrId, itemId, uomId) =>
    dispatch(CycleCountDetailActions.cycleCountDetailChangeUom(formikBag, hdrId, itemId, uomId)),

  fetchStorageBinOptions: search =>
    dispatch(CycleCountDetailActions.cycleCountDetailFetchStorageBinOptions(search)),

  fetchHandlingUnitOptions: search =>
    dispatch(CycleCountDetailActions.cycleCountDetailFetchHandlingUnitOptions(search)),

  fetchQuantBalOptions: (companyId, storageBinId, search) =>
    dispatch(
      CycleCountDetailActions.cycleCountDetailFetchQuantBalOptions(companyId, storageBinId, search)
    ),
  fetchQuantBalOptionSuccess: options =>
    dispatch(CycleCountDetailActions.cycleCountDetailFetchQuantBalOptionSuccess(options)),
  changeQuantBal: (formikBag, hdrId, quantBalId) =>
    dispatch(CycleCountDetailActions.cycleCountDetailChangeQuantBal(formikBag, hdrId, quantBalId))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(WhseJobType15Form));
