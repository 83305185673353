/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { SiteList01Types } from './Actions';
import INITIAL_STATE from './InitialState';

export const siteList01ResetTimestamp = (state, { currentPage, sorts, filters, pageSize }) => ({
  ...state,
  currentPage: currentPage || state.currentPage,
  sorts: sorts || state.sorts,
  filters: filters || state.filters,
  pageSize: pageSize || state.pageSize,
  timestamp: Date.now(),
  // selectedDocuments: INITIAL_STATE.selectedDocuments,
  toSiteOptions: INITIAL_STATE.toSiteOptions,
  toSiteOption: INITIAL_STATE.toSiteOption
});

export const siteList01FetchSiteList01Loading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const siteList01FetchSiteList01Success = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  documents,
  currentPage,
  lastPage,
  total,
  pageSize
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [SiteList01Types.SITE_LIST01_RESET_TIMESTAMP]: siteList01ResetTimestamp,
  [SiteList01Types.SITE_LIST01_FETCH_SITE_LIST01_LOADING]: siteList01FetchSiteList01Loading,
  [SiteList01Types.SITE_LIST01_FETCH_SITE_LIST01_SUCCESS]: siteList01FetchSiteList01Success
});
