/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { ProdOrdExcel01Types } from './Actions';
import INITIAL_STATE from './InitialState';

export const prodOrdExcel01SetStrProcType = (state, { strProcType }) => ({
  ...state,
  strProcType
});

export const prodOrdExcel01ShowBatchJobStatusSuccess = (state, { batchJobStatus }) => ({
  ...state,
  batchJobStatus
});

export const prodOrdExcel01UploadLoading = (state, { boolean }) => ({
  ...state,
  uploadIsLoading: boolean
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [ProdOrdExcel01Types.PROD_ORD_EXCEL01_SET_STR_PROC_TYPE]: prodOrdExcel01SetStrProcType,
  [ProdOrdExcel01Types.PROD_ORD_EXCEL01_SHOW_BATCH_JOB_STATUS_SUCCESS]: prodOrdExcel01ShowBatchJobStatusSuccess,
  [ProdOrdExcel01Types.PROD_ORD_EXCEL01_UPLOAD_LOADING]: prodOrdExcel01UploadLoading
});
