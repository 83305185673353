import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  delOrdFServ01ShowSyncSetting: null,
  delOrdFServ01ShowSyncSettingLoading: ['boolean'],
  delOrdFServ01ShowSyncSettingSuccess: ['syncSetting'],
  delOrdFServ01UpdateSyncSetting: ['formikBag', 'syncSetting'],
  delOrdFServ01ShowBatchJobStatus: null,
  delOrdFServ01ShowBatchJobStatusSuccess: ['batchJobStatus'],

  delOrdFServ01ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  delOrdFServ01GoToDocument: ['hdrId'],
  delOrdFServ01FetchDelOrdFServ01: ['currentPage', 'sorts', 'filters', 'pageSize'],
  delOrdFServ01FetchDelOrdFServ01Loading: ['boolean'],
  delOrdFServ01FetchDelOrdFServ01Success: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],
  delOrdFServ01AddSelectedDocuments: ['selectedDocuments'],
  delOrdFServ01RemoveSelectedDocuments: ['selectedDocuments'],
  delOrdFServ01SetWorkspaceVisible: ['boolean'],

  delOrdFServ01Export: ['hdrIds'],
  delOrdFServ01ExportSuccess: null,
  delOrdFServ01ExportLoading: ['boolean']
});

export const DelOrdFServ01Types = Types;
export default Creators;
