import { put, call, select, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import { push } from 'connected-react-router';
import FileSaver from 'file-saver';
import ApiService from '../Services/ApiService';
import AppActions from '../Stores/App/Actions';
import OutbOrd05Actions, { OutbOrd05Types } from '../Stores/OutbOrd05/Actions';

const getAppStore = state => state.app;

export function* outbOrd05FetchOutbOrd05({ currentPage, sorts, filters, pageSize }) {
  try {
    yield put(OutbOrd05Actions.outbOrd05FetchOutbOrd05Loading(true));

    const processedSorts = [];
    Object.entries(sorts).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      processedSorts.push(`${key}:${value === 'ascend' ? 'ASC' : 'DESC'}`);
    });

    const processedFilters = [];
    Object.entries(filters).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      if (value) {
        processedFilters.push(`${key}:${value}`);
      }
    });

    const app = yield select(getAppStore);
    const getData = {
      page: currentPage,
      sorts: processedSorts,
      filters: processedFilters,
      pageSize
    };

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `outbOrd/indexProcess/OUTB_ORD_05/${app.curDivisionId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      // if nowCurrentPage is more than lastPage, then nowCurrentPage = lastPage
      let nowCurrentPage = result.data.current_page;
      if (nowCurrentPage > result.data.last_page) {
        nowCurrentPage = result.data.last_page;
      }

      yield put(
        OutbOrd05Actions.outbOrd05FetchOutbOrd05Success(
          result.data.data,
          nowCurrentPage,
          result.data.last_page,
          result.data.total,
          result.data.per_page
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(OutbOrd05Actions.outbOrd05FetchOutbOrd05Loading(false));
  }
}

export function* outbOrd05CreateOutbOrd05({ hdrIds }) {
  try {
    yield put(OutbOrd05Actions.outbOrd05CreateOutbOrd05Loading(true));

    const app = yield select(getAppStore);

    const postData = {
      hdrIds
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `outbOrd/createProcess/OUTB_ORD_05`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      yield put(OutbOrd05Actions.outbOrd05CreateOutbOrd05Success(result.data));

      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_SUCCESS_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(OutbOrd05Actions.outbOrd05CreateOutbOrd05Loading(false));
  }
}

export function* outbOrd05GoToDocument({ hdrId }) {
  const app = yield select(getAppStore);
  yield put(push(`${app.appPath}/trfOrdDetail/update/${hdrId}`));
}

export function* outbOrd05NewDocument() {
  const app = yield select(getAppStore);
  yield put(push(`${app.appPath}/trfOrdDetail/create/${app.curDivisionId}/SITE_TRANSFER`));
}

export function* outbOrd05CheckStock({ hdrIds }) {
  try {
    yield put(OutbOrd05Actions.outbOrd05CreateOutbOrd05Loading(true));

    const app = yield select(getAppStore);

    const getData = {
      hdrIds
    };

    const result = yield call(
      ApiService.downloadGetApi, // function
      app.apiUrl,
      `trfOrd/checkStock`,
      app.token,
      getData,
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    );

    if (result.isSuccess === true) {
      FileSaver.saveAs(result.data, 'CHECK_STOCK.XLSX');
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(OutbOrd05Actions.outbOrd05CreateOutbOrd05Loading(false));
  }
}

export function* outbOrd05GoToPrint({ document }) {
  try {
    yield put(OutbOrd05Actions.outbOrd05FetchOutbOrd05Loading(true));

    const app = yield select(getAppStore);

    const getData = {
      hdrIds: [document.id]
    };

    const result = yield call(
      ApiService.downloadGetApi, // function
      app.apiUrl,
      `trfOrd/print/${app.curDivisionId}`,
      app.token,
      getData,
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    );

    if (result.isSuccess === true) {
      FileSaver.saveAs(result.data, `TrfOrd_${document.doc_code}.pdf`);
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(OutbOrd05Actions.outbOrd05FetchOutbOrd05Loading(false));
  }
}

export const saga = [
  takeLatest(OutbOrd05Types.OUTB_ORD05_FETCH_OUTB_ORD05, outbOrd05FetchOutbOrd05),
  takeLatest(OutbOrd05Types.OUTB_ORD05_CREATE_OUTB_ORD05, outbOrd05CreateOutbOrd05),
  takeLatest(OutbOrd05Types.OUTB_ORD05_GO_TO_PRINT, outbOrd05GoToPrint),
  takeLatest(OutbOrd05Types.OUTB_ORD05_GO_TO_DOCUMENT, outbOrd05GoToDocument),
  takeLatest(OutbOrd05Types.OUTB_ORD05_NEW_DOCUMENT, outbOrd05NewDocument),
  takeLatest(OutbOrd05Types.OUTB_ORD05_CHECK_STOCK, outbOrd05CheckStock)
];
