/**
 * The initial values for the redux state.
 */
export default {
  fetchIsLoading: false,
  createIsLoading: false,

  transportOptions: [],
  transportIsLoading: false,

  driver01Options: [],
  driver01IsLoading: false,

  deliveryMan01Options: [],
  deliveryMan01IsLoading: false,

  deliveryMan02Options: [],
  deliveryMan02IsLoading: false,

  outbOrdHdrs: [],
  inbOrdHdrs: [],

  documentOptions: [],
  documentIsLoading: false,

  newDocuments: []
};
