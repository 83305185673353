/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { WhseJob1402IndexTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const whseJob1402IndexResetTimestamp = (
  state,
  { currentPage, sorts, filters, pageSize }
) => ({
  ...state,
  currentPage: currentPage || state.currentPage,
  sorts: sorts || state.sorts,
  filters: filters || state.filters,
  pageSize: pageSize || state.pageSize,
  timestamp: Date.now(),
  // selectedDocuments: INITIAL_STATE.selectedDocuments,
  fetchIsLoading: false
});

export const whseJob1402IndexFetchWhseJob1402IndexLoading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const whseJob1402IndexFetchWhseJob1402IndexSuccess = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  documents,
  currentPage,
  lastPage,
  total,
  pageSize
});

export const whseJob1402IndexAddSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = state.selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: [
      ...state.selectedDocuments,
      ...selectedDocuments.filter(data => {
        return !selectIds.includes(data.id);
      })
    ].slice(0, 5)
  };
};

export const whseJob1402IndexRemoveSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: state.selectedDocuments.filter(data => {
      return !selectIds.includes(data.id);
    })
  };
};

export const whseJob1402IndexSetWorkspaceVisible = (state, { boolean }) => ({
  ...state,
  workspaceIsVisible: boolean
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [WhseJob1402IndexTypes.WHSE_JOB1402_INDEX_RESET_TIMESTAMP]: whseJob1402IndexResetTimestamp,
  [WhseJob1402IndexTypes.WHSE_JOB1402_INDEX_FETCH_WHSE_JOB1402_INDEX_LOADING]: whseJob1402IndexFetchWhseJob1402IndexLoading,
  [WhseJob1402IndexTypes.WHSE_JOB1402_INDEX_FETCH_WHSE_JOB1402_INDEX_SUCCESS]: whseJob1402IndexFetchWhseJob1402IndexSuccess,
  [WhseJob1402IndexTypes.WHSE_JOB1402_INDEX_ADD_SELECTED_DOCUMENTS]: whseJob1402IndexAddSelectedDocuments,
  [WhseJob1402IndexTypes.WHSE_JOB1402_INDEX_REMOVE_SELECTED_DOCUMENTS]: whseJob1402IndexRemoveSelectedDocuments,
  [WhseJob1402IndexTypes.WHSE_JOB1402_INDEX_SET_WORKSPACE_VISIBLE]: whseJob1402IndexSetWorkspaceVisible
});
