import { put, call, select, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import { push } from 'connected-react-router';
import ApiService from '../Services/ApiService';
import AppActions from '../Stores/App/Actions';
import ProdOrdFServ02Actions, { ProdOrdFServ02Types } from '../Stores/ProdOrdFServ02/Actions';

const getAppStore = state => state.app;

export function* prodOrdFServ02ShowSyncSetting() {
  try {
    yield put(ProdOrdFServ02Actions.prodOrdFServ02ShowSyncSettingLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `syncSetting/showDivisionSetting/PROD_ORD_F_SERV_02/${app.curDivisionId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(ProdOrdFServ02Actions.prodOrdFServ02ShowSyncSettingSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(ProdOrdFServ02Actions.prodOrdFServ02ShowSyncSettingLoading(false));
  }
}

export function* prodOrdFServ02UpdateSyncSetting({ formikBag, syncSetting }) {
  formikBag.setSubmitting(true);
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: syncSetting
    };

    const result = yield call(
      ApiService.putApi, // function
      app.apiUrl,
      `syncSetting/updateDivisionSetting/PROD_ORD_F_SERV_02/${app.curDivisionId}`,
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_SUCCESS_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* prodOrdFServ02ShowBatchJobStatus() {
  try {
    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `batchJobStatus/showBatchJobStatus/PROD_ORD_F_SERV_02`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(ProdOrdFServ02Actions.prodOrdFServ02ShowBatchJobStatusSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  }
}

export function* prodOrdFServ02Export({ hdrIds }) {
  try {
    yield put(ProdOrdFServ02Actions.prodOrdFServ02ExportLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      divisionId: app.curDivisionId,
      hdrIds
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `prodOrd/fServProcess/PROD_ORD_F_SERV_02/${app.curSiteFlowId}/${app.curDivisionId}`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      yield put(ProdOrdFServ02Actions.prodOrdFServ02ExportSuccess());

      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_SUCCESS_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(ProdOrdFServ02Actions.prodOrdFServ02ExportLoading(false));
  }
}

export function* prodOrdFServ02GoToDocument({ hdrId }) {
  const app = yield select(getAppStore);
  yield put(push(`${app.appPath}/prodOrdDetail/update/${hdrId}`));
}

export function* prodOrdFServ02FetchProdOrdFServ02({ currentPage, sorts, filters, pageSize }) {
  try {
    yield put(ProdOrdFServ02Actions.prodOrdFServ02FetchProdOrdFServ02Loading(true));

    const processedSorts = [];
    Object.entries(sorts).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      processedSorts.push(`${key}:${value === 'ascend' ? 'ASC' : 'DESC'}`);
    });

    const processedFilters = [];
    Object.entries(filters).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      if (value) {
        processedFilters.push(`${key}:${value}`);
      }
    });

    const app = yield select(getAppStore);
    const getData = {
      page: currentPage,
      sorts: processedSorts,
      filters: processedFilters,
      pageSize
    };

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `prodOrd/indexProcess/PROD_ORD_F_SERV_02/${app.curDivisionId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      // if nowCurrentPage is more than lastPage, then nowCurrentPage = lastPage
      let nowCurrentPage = result.data.current_page;
      if (nowCurrentPage > result.data.last_page) {
        nowCurrentPage = result.data.last_page;
      }

      yield put(
        ProdOrdFServ02Actions.prodOrdFServ02FetchProdOrdFServ02Success(
          result.data.data,
          nowCurrentPage,
          result.data.last_page,
          result.data.total,
          result.data.per_page
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(ProdOrdFServ02Actions.prodOrdFServ02FetchProdOrdFServ02Loading(false));
  }
}

export const saga = [
  takeLatest(
    ProdOrdFServ02Types.PROD_ORD_F_SERV02_SHOW_BATCH_JOB_STATUS,
    prodOrdFServ02ShowBatchJobStatus
  ),
  takeLatest(
    ProdOrdFServ02Types.PROD_ORD_F_SERV02_SHOW_SYNC_SETTING,
    prodOrdFServ02ShowSyncSetting
  ),

  takeLatest(
    ProdOrdFServ02Types.PROD_ORD_F_SERV02_UPDATE_SYNC_SETTING,
    prodOrdFServ02UpdateSyncSetting
  ),

  takeLatest(ProdOrdFServ02Types.PROD_ORD_F_SERV02_GO_TO_DOCUMENT, prodOrdFServ02GoToDocument),
  takeLatest(
    ProdOrdFServ02Types.PROD_ORD_F_SERV02_FETCH_PROD_ORD_F_SERV02,
    prodOrdFServ02FetchProdOrdFServ02
  ),
  takeLatest(ProdOrdFServ02Types.PROD_ORD_F_SERV02_EXPORT, prodOrdFServ02Export)
];
