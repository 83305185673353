import { put, call, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
// import ApiService from '../Services/ApiService';
// import AppActions from '../Stores/App/Actions';
import DebtorProcessActions, { DebtorProcessTypes } from '../Stores/DebtorProcess/Actions';

// const getAppStore = state => state.app;

// eslint-disable-next-line import/prefer-default-export
export function* debtorProcessFetchDebtorProcess() {
  try {
    yield put(DebtorProcessActions.debtorProcessFetchDebtorProcessLoading(true));

    const result = [
      { proc_type: 'DEBTOR_LIST_01', icon: 'fa fa-cubes' }
      // { proc_type: 'DEBTOR_EXCEL_01', icon: 'fa fa-file-excel-o' },
      // { proc_type: 'DEBTOR_SYNC_01', icon: 'fa fa-refresh' }
    ];
    yield put(DebtorProcessActions.debtorProcessFetchDebtorProcessSuccess(result));

    /*
    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `site/indexItemFlow/${app.curSiteFlowId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );
    
    if (result.isSuccess === true) {
      yield put(DebtorProcessActions.debtorProcessFetchDebtorProcessSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
    */
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(DebtorProcessActions.debtorProcessFetchDebtorProcessLoading(false));
  }
}

export const saga = [
  takeLatest(
    DebtorProcessTypes.DEBTOR_PROCESS_FETCH_DEBTOR_PROCESS,
    debtorProcessFetchDebtorProcess
  )
];
