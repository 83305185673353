import React from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';

import { UndoOutlined, SaveOutlined } from '@ant-design/icons';

import { Button, Row, Col, Typography } from 'antd';
import {
  FormikSelect,
  FormikButton,
  FormikTextNumber,
  FormikInputNumber,
  FormikInput
} from '../../Components/Formik';
import OutbOrdDetailActions from '../../Stores/OutbOrdDetail/Actions';

const { Text } = Typography;

class DetailForm extends React.PureComponent {
  constructor() {
    super();

    this.handleSearchLocation = this.handleSearchLocation.bind(this);

    this.handleSearchItem = this.handleSearchItem.bind(this);
    this.handleSearchUom = this.handleSearchUom.bind(this);

    this.handleChangeItem = this.handleChangeItem.bind(this);
    this.handleChangeUom = this.handleChangeUom.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate() {}

  componentWillUnmount() {}

  handleSearchLocation(form, value) {
    const { fetchLocationOptions } = this.props;

    fetchLocationOptions(value);
  }

  handleSearchItem(form, value) {
    const { fetchItemOptions } = this.props;

    fetchItemOptions(value);
  }

  handleSearchUom(form, value) {
    const { fetchUomOptions } = this.props;
    const { values } = form;

    fetchUomOptions(values.item_select2.value, value);
  }

  handleChangeItem(form, value) {
    // params: form, value, option
    const { hdrId, changeItem } = this.props;

    changeItem(form, hdrId, value);
  }

  handleChangeUom(form, value) {
    // params: form, value, option
    const { hdrId, changeUom } = this.props;
    const { values } = form;

    changeUom(form, hdrId, values.item_select2.value, value);
  }

  render() {
    const {
      intl,
      hdrId,
      documentHeader,
      documentDetail,
      locationIsLoading,
      locationOptions,
      itemIsLoading,
      itemOptions,
      uomIsLoading,
      uomOptions,
      updateDetails,
      createDetail,
      documentIsLoading
    } = this.props;

    const disabled = documentHeader && documentHeader.doc_status >= 50;
    return (
      <Formik
        enableReinitialize
        initialValues={documentDetail}
        onSubmit={(values, formikBag) => {
          // remove the submit_action field
          const processedValues = { ...values };
          delete processedValues.submit_action;

          if (values.id > 0) {
            updateDetails(formikBag, hdrId, [processedValues]);
          } else {
            createDetail(formikBag, hdrId, processedValues);
          }
        }}
        validationSchema={Yup.object().shape({
          location_select2: Yup.object().shape({
            value: Yup.number().min(1, intl.formatMessage({ id: 'location_is_required' }))
          }),
          item_select2: Yup.object().shape({
            value: Yup.number().min(1, intl.formatMessage({ id: 'item_is_required' }))
          }),
          qty: Yup.number().moreThan(-1, intl.formatMessage({ id: 'qty_must_be_positive' })),
          uom_select2: Yup.object().shape({
            value: Yup.number().min(1, intl.formatMessage({ id: 'uom_is_required' }))
          })
        })}
      >
        {({
          values,
          // handleChange,
          // errors,
          // setFieldTouched,
          dirty,
          // touched,
          isSubmitting,
          // isValid,
          handleSubmit,
          handleReset
        }) => (
          <Form>
            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'location' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="location_select2"
                  component={FormikSelect}
                  showArrow
                  filterOption={false}
                  onSearch={this.handleSearchLocation}
                  notFoundContent={null}
                  loading={locationIsLoading}
                  options={locationOptions}
                  disabled={disabled}
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'item' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="item_select2"
                  component={FormikSelect}
                  showArrow
                  filterOption={false}
                  onSearch={this.handleSearchItem}
                  notFoundContent={null}
                  loading={itemIsLoading}
                  options={itemOptions}
                  disabled={disabled}
                  onChange={this.handleChangeItem}
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'desc_01' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field name="desc_01" component={FormikInput} disabled={disabled} />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'desc_02' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field name="desc_02" component={FormikInput} disabled={disabled} />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'desc_03' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field name="desc_03" component={FormikInput} disabled={disabled} />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'qty' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={8}>
                <Field
                  name="qty"
                  component={FormikInputNumber}
                  precision={values.qty_scale || 0}
                  disabled={disabled}
                />
              </Col>
              <Col span={12} />
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'uom' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={8}>
                <Field
                  name="uom_select2"
                  component={FormikSelect}
                  showArrow
                  filterOption={false}
                  onSearch={this.handleSearchUom}
                  notFoundContent={null}
                  loading={uomIsLoading}
                  options={uomOptions}
                  disabled={disabled}
                  onChange={this.handleChangeUom}
                />
              </Col>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'rate' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={8}>
                <Field
                  name="uom_rate"
                  component={FormikTextNumber}
                  minimumfractiondigits={6}
                  maximumfractiondigits={6}
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'sale_price' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={8}>
                <Field
                  name="sale_price"
                  component={FormikInputNumber}
                  disabled={disabled}
                  precision={parseInt(process.env.REACT_APP_DECIMAL_SCALE, 10)}
                />
              </Col>

              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'price_disc' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={8}>
                <Field
                  name="price_disc"
                  component={FormikInputNumber}
                  disabled={disabled}
                  precision={parseInt(process.env.REACT_APP_DECIMAL_SCALE, 10)}
                  max={100}
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'disc_val_01' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={8}>
                <Field
                  name="dtl_disc_val_01"
                  component={FormikInputNumber}
                  disabled={disabled}
                  precision={parseInt(process.env.REACT_APP_DECIMAL_SCALE, 10)}
                />
              </Col>

              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'disc_perc_01' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={8}>
                <Field
                  name="dtl_disc_perc_01"
                  component={FormikInputNumber}
                  disabled={disabled}
                  precision={parseInt(process.env.REACT_APP_DECIMAL_SCALE, 10)}
                  max={100}
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'disc_val_02' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={8}>
                <Field
                  name="dtl_disc_val_02"
                  component={FormikInputNumber}
                  disabled={disabled}
                  precision={parseInt(process.env.REACT_APP_DECIMAL_SCALE, 10)}
                />
              </Col>

              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'disc_perc_02' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={8}>
                <Field
                  name="dtl_disc_perc_02"
                  component={FormikInputNumber}
                  disabled={disabled}
                  precision={parseInt(process.env.REACT_APP_DECIMAL_SCALE, 10)}
                  max={100}
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'disc_val_03' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={8}>
                <Field
                  name="dtl_disc_val_03"
                  component={FormikInputNumber}
                  disabled={disabled}
                  precision={parseInt(process.env.REACT_APP_DECIMAL_SCALE, 10)}
                />
              </Col>

              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'disc_perc_03' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={8}>
                <Field
                  name="dtl_disc_perc_03"
                  component={FormikInputNumber}
                  disabled={disabled}
                  precision={parseInt(process.env.REACT_APP_DECIMAL_SCALE, 10)}
                  max={100}
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'disc_val_04' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={8}>
                <Field
                  name="dtl_disc_val_04"
                  component={FormikInputNumber}
                  disabled={disabled}
                  precision={parseInt(process.env.REACT_APP_DECIMAL_SCALE, 10)}
                />
              </Col>

              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'disc_perc_04' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={8}>
                <Field
                  name="dtl_disc_perc_04"
                  component={FormikInputNumber}
                  disabled={disabled}
                  precision={parseInt(process.env.REACT_APP_DECIMAL_SCALE, 10)}
                  max={100}
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'tax_perc_01' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={8}>
                <Field
                  name="dtl_tax_perc_01"
                  component={FormikInputNumber}
                  disabled={disabled}
                  precision={parseInt(process.env.REACT_APP_DECIMAL_SCALE, 10)}
                />
              </Col>
              <Col span={12} />
            </Row>

            <Row type="flex" justify="end" gutter={[0, 16]}>
              <Col span={5}>
                <Button
                  type="primary"
                  disabled={!dirty}
                  loading={isSubmitting || documentIsLoading}
                  onClick={handleReset}
                  icon={<UndoOutlined />}
                >
                  {intl.formatMessage({ id: 'reset' })}
                </Button>
                <Field
                  type="primary"
                  name="submit_action"
                  value="update"
                  component={FormikButton}
                  disabled={disabled}
                  loading={isSubmitting || documentIsLoading}
                  onClick={handleSubmit}
                  label={intl.formatMessage({
                    id: values.id > 0 ? 'update' : 'create'
                  })}
                  icon={<SaveOutlined />}
                />
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    );
  }
}

DetailForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  updateDetails: PropTypes.func,
  createDetail: PropTypes.func,
  hdrId: PropTypes.number,
  documentHeader: PropTypes.shape({
    doc_status: PropTypes.number
  }),
  documentDetail: PropTypes.shape({}),
  documentIsLoading: PropTypes.bool,

  fetchLocationOptions: PropTypes.func,
  locationIsLoading: PropTypes.bool,
  locationOptions: PropTypes.arrayOf(PropTypes.object),

  fetchItemOptions: PropTypes.func,
  changeItem: PropTypes.func,
  itemIsLoading: PropTypes.bool,
  itemOptions: PropTypes.arrayOf(PropTypes.object),

  fetchUomOptions: PropTypes.func,
  changeUom: PropTypes.func,
  uomIsLoading: PropTypes.bool,
  uomOptions: PropTypes.arrayOf(PropTypes.object)
};

DetailForm.defaultProps = {
  intl: {},
  updateDetails() {},
  createDetail() {},
  hdrId: 0,
  documentHeader: { doc_status: 3 },
  documentDetail: {},
  documentIsLoading: false,

  fetchLocationOptions() {},
  locationIsLoading: false,
  locationOptions: [],

  fetchItemOptions() {},
  fetchUomOptions() {},
  changeItem() {},
  changeUom() {},
  itemIsLoading: false,
  itemOptions: [],
  uomIsLoading: false,
  uomOptions: []
};

const mapStateToProps = state => ({
  hdrId: state.outbOrdDetail.hdrId,
  documentHeader: state.outbOrdDetail.documentHeader,
  documentDetail: state.outbOrdDetail.documentDetail,
  documentIsLoading: state.outbOrdDetail.documentIsLoading,

  locationIsLoading: state.outbOrdDetail.locationIsLoading,
  locationOptions: state.outbOrdDetail.locationOptions,

  itemIsLoading: state.outbOrdDetail.itemIsLoading,
  itemOptions: state.outbOrdDetail.itemOptions,

  uomIsLoading: state.outbOrdDetail.uomIsLoading,
  uomOptions: state.outbOrdDetail.uomOptions
});

const mapDispatchToProps = dispatch => ({
  updateDetails: (formikBag, hdrId, documentDetails) =>
    dispatch(OutbOrdDetailActions.outbOrdDetailUpdateDetails(formikBag, hdrId, documentDetails)),
  createDetail: (formikBag, hdrId, documentDetail) =>
    dispatch(OutbOrdDetailActions.outbOrdDetailCreateDetail(formikBag, hdrId, documentDetail)),

  fetchLocationOptions: search =>
    dispatch(OutbOrdDetailActions.outbOrdDetailFetchLocationOptions(search)),

  fetchItemOptions: search => dispatch(OutbOrdDetailActions.outbOrdDetailFetchItemOptions(search)),
  changeItem: (formikBag, hdrId, itemId) =>
    dispatch(OutbOrdDetailActions.outbOrdDetailChangeItem(formikBag, hdrId, itemId)),

  fetchUomOptions: (itemId, search) =>
    dispatch(OutbOrdDetailActions.outbOrdDetailFetchUomOptions(itemId, search)),
  changeUom: (formikBag, hdrId, itemId, uomId) =>
    dispatch(OutbOrdDetailActions.outbOrdDetailChangeUom(formikBag, hdrId, itemId, uomId))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(DetailForm));
