/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { StockAgingReportTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const stockAgingReportReportLoading = (state, { boolean }) => ({
  ...state,
  reportIsLoading: boolean
});

export const stockAgingReportInitStockAgingSuccess = (state, { criteria }) => ({
  ...state,
  criteria
});

export const stockAgingReportStockAgingSuccess = (state, { criteria, reportData }) => ({
  ...state,
  criteria,
  reportData
});

export const stockAgingReportFetchCompanyOptionLoading = (state, { boolean }) => ({
  ...state,
  companyIsLoading: boolean
});

export const stockAgingReportFetchCompanyOptionSuccess = (state, { options }) => ({
  ...state,
  companyOptions: options
});

export const stockAgingReportFetchItemOptionLoading = (state, { boolean }) => ({
  ...state,
  itemIsLoading: boolean
});

export const stockAgingReportFetchItemOptionSuccess = (state, { options }) => ({
  ...state,
  itemOptions: options
});

export const stockAgingReportFetchItemGroup01OptionLoading = (state, { boolean }) => ({
  ...state,
  itemGroup01IsLoading: boolean
});

export const stockAgingReportFetchItemGroup01OptionSuccess = (state, { options }) => ({
  ...state,
  itemGroup01Options: options
});

export const stockAgingReportFetchItemGroup02OptionLoading = (state, { boolean }) => ({
  ...state,
  itemGroup02IsLoading: boolean
});

export const stockAgingReportFetchItemGroup02OptionSuccess = (state, { options }) => ({
  ...state,
  itemGroup02Options: options
});

export const stockAgingReportFetchItemGroup03OptionLoading = (state, { boolean }) => ({
  ...state,
  itemGroup03IsLoading: boolean
});

export const stockAgingReportFetchItemGroup03OptionSuccess = (state, { options }) => ({
  ...state,
  itemGroup03Options: options
});

export const stockAgingReportFetchLocationOptionLoading = (state, { boolean }) => ({
  ...state,
  locationIsLoading: boolean
});

export const stockAgingReportFetchLocationOptionSuccess = (state, { options }) => ({
  ...state,
  locationOptions: options
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [StockAgingReportTypes.STOCK_AGING_REPORT_REPORT_LOADING]: stockAgingReportReportLoading,
  [StockAgingReportTypes.STOCK_AGING_REPORT_INIT_STOCK_AGING_SUCCESS]: stockAgingReportInitStockAgingSuccess,
  [StockAgingReportTypes.STOCK_AGING_REPORT_STOCK_AGING_SUCCESS]: stockAgingReportStockAgingSuccess,

  [StockAgingReportTypes.STOCK_AGING_REPORT_FETCH_COMPANY_OPTION_LOADING]: stockAgingReportFetchCompanyOptionLoading,
  [StockAgingReportTypes.STOCK_AGING_REPORT_FETCH_COMPANY_OPTION_SUCCESS]: stockAgingReportFetchCompanyOptionSuccess,

  [StockAgingReportTypes.STOCK_AGING_REPORT_FETCH_ITEM_OPTION_LOADING]: stockAgingReportFetchItemOptionLoading,
  [StockAgingReportTypes.STOCK_AGING_REPORT_FETCH_ITEM_OPTION_SUCCESS]: stockAgingReportFetchItemOptionSuccess,

  [StockAgingReportTypes.STOCK_AGING_REPORT_FETCH_ITEM_GROUP01_OPTION_LOADING]: stockAgingReportFetchItemGroup01OptionLoading,
  [StockAgingReportTypes.STOCK_AGING_REPORT_FETCH_ITEM_GROUP01_OPTION_SUCCESS]: stockAgingReportFetchItemGroup01OptionSuccess,

  [StockAgingReportTypes.STOCK_AGING_REPORT_FETCH_ITEM_GROUP02_OPTION_LOADING]: stockAgingReportFetchItemGroup02OptionLoading,
  [StockAgingReportTypes.STOCK_AGING_REPORT_FETCH_ITEM_GROUP02_OPTION_SUCCESS]: stockAgingReportFetchItemGroup02OptionSuccess,

  [StockAgingReportTypes.STOCK_AGING_REPORT_FETCH_ITEM_GROUP03_OPTION_LOADING]: stockAgingReportFetchItemGroup03OptionLoading,
  [StockAgingReportTypes.STOCK_AGING_REPORT_FETCH_ITEM_GROUP03_OPTION_SUCCESS]: stockAgingReportFetchItemGroup03OptionSuccess,

  [StockAgingReportTypes.STOCK_AGING_REPORT_FETCH_LOCATION_OPTION_LOADING]: stockAgingReportFetchLocationOptionLoading,
  [StockAgingReportTypes.STOCK_AGING_REPORT_FETCH_LOCATION_OPTION_SUCCESS]: stockAgingReportFetchLocationOptionSuccess
});
