import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import treeChanges from 'tree-changes';

import { AuditOutlined, EditOutlined, LaptopOutlined, ReloadOutlined } from '@ant-design/icons';

import { Row, Col, Table, Button, Badge } from 'antd';

import AntDesignTable from '../../Components/AntDesignTable';
import StorageBinList01Actions from '../../Stores/StorageBinList01/Actions';

import BarcodeDialog from './BarcodeDialog';
import StorageBinList01Dialog from './StorageBinList01Dialog';

class StorageBinList01Table extends React.PureComponent {
  constructor() {
    super();

    this.useOnTableChange = this.useOnTableChange.bind(this);
    this.useShowWorkspace = this.useShowWorkspace.bind(this);

    this.handleSearch = this.handleSearch.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.getDocumentColumns = this.getDocumentColumns.bind(this);
  }

  componentDidMount() {
    const { curSiteFlowId, setWorkspaceVisible, resetTimestamp } = this.props;

    if (curSiteFlowId > 0) {
      setWorkspaceVisible(false);
      resetTimestamp();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      curSiteFlowId,
      resetTimestamp,
      fetchStorageBinList01,
      currentPage,
      sorts,
      filters,
      pageSize
    } = this.props;

    const { changed } = treeChanges(prevProps, this.props);

    if (changed('curSiteFlowId')) {
      resetTimestamp();
    }

    if (changed('timestamp')) {
      if (curSiteFlowId > 0) {
        fetchStorageBinList01(currentPage, sorts, filters, pageSize);
      }
    }
  }

  componentWillUnmount() {}

  handleSearch(selectedKeys, confirm) {
    // eslint-disable-next-line no-unused-vars
    const { intl } = this.props;

    confirm();
  }

  handleReset(clearFilters) {
    // eslint-disable-next-line no-unused-vars
    const { intl } = this.props;

    clearFilters();
  }

  getDocumentColumns() {
    const { intl, sorts, filters, goToResource, showBarcode, goToAudit } = this.props;

    return [
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'code' }),
        // sort field
        dataIndex: 'code',
        // filter field
        key: 'code',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'code' }),
          'code',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.code}</>
      },
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'barcode' }),
        // sort field
        dataIndex: 'barcode',
        // filter field
        key: 'barcode',
        render: (text, record) => (
          <Button
            type="link"
            onClick={() => showBarcode(record.barcode, record.code, record.desc_01, record.desc_02)}
          >
            {record.barcode}
          </Button>
        )
      },
      {
        width: 150,
        align: 'left',
        title: intl.formatMessage({ id: 'description' }),
        // sort field
        dataIndex: 'desc_01',
        ...AntDesignTable.getColumnSortProps(sorts, 'desc_01'),
        // filter field
        key: 'desc_01',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'description' }),
          'desc_01',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.desc_01}</>
      },
      {
        width: 110,
        align: 'left',
        title: intl.formatMessage({ id: 'storage_row' }),
        // sort field
        dataIndex: 'storage_row_code',
        ...AntDesignTable.getColumnSortProps(sorts, 'storage_row_code'),
        // filter field
        key: 'storage_row_code',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'storage_row' }),
          'storage_row_code',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.storage_row_code}</>
      },
      {
        width: 110,
        align: 'left',
        title: intl.formatMessage({ id: 'storage_bay' }),
        // sort field
        dataIndex: 'storage_bay_code',
        ...AntDesignTable.getColumnSortProps(sorts, 'storage_bay_code'),
        // filter field
        key: 'storage_bay_code',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'storage_bay' }),
          'storage_bay_code',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.storage_bay_code}</>
      },
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'level' }),
        // sort field
        dataIndex: 'level',
        ...AntDesignTable.getColumnSortProps(sorts, 'level'),
        // filter field
        key: 'level',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'level' }),
          'level',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.level}</>
      },
      {
        width: 110,
        align: 'left',
        title: intl.formatMessage({ id: 'storage_type' }),
        // sort field
        dataIndex: 'storage_type_code',
        ...AntDesignTable.getColumnSortProps(sorts, 'storage_type_code'),
        // filter field
        key: 'storage_type_code',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'storage_type' }),
          'storage_type_code',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.storage_type_code}</>
      },
      {
        width: 110,
        align: 'left',
        title: intl.formatMessage({ id: 'location' }),
        // sort field
        dataIndex: 'location_code',
        ...AntDesignTable.getColumnSortProps(sorts, 'location_code'),
        // filter field
        key: 'location_code',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'location' }),
          'location_code',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.location_code}</>
      },
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'status' }),
        // sort field
        dataIndex: 'str_bin_status',
        ...AntDesignTable.getColumnSortProps(sorts, 'str_bin_status'),
        // filter field
        key: 'str_bin_status',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'status' }),
          'str_bin_status',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.str_bin_status}</>
      },
      {
        width: 100,
        fixed: 'right',
        align: 'left',
        title: intl.formatMessage({ id: 'last_count_date' }),
        // sort field
        dataIndex: 'last_count_date',
        ...AntDesignTable.getColumnSortProps(sorts, 'last_count_date'),
        // filter field
        key: 'last_count_date',
        ...AntDesignTable.getColumnSearchDateProps(
          filters,
          intl.formatMessage({ id: 'last_count_date' }),
          'last_count_date',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.last_count_date}</>
      },
      {
        width: 50,
        fixed: 'right',
        title: '',
        key: 'action',
        render: (text, record) => (
          <>
            <Button type="dashed" icon={<EditOutlined />} onClick={() => goToResource(record.id)} />
            <Button type="dashed" icon={<AuditOutlined />} onClick={() => goToAudit(record.id)} />
          </>
        )
      }
    ];
  }

  useOnTableChange(pagination, filters, sorter) {
    const { resetTimestamp } = this.props;

    const processedFilters = AntDesignTable.processFilters(filters);
    const processedSorts = AntDesignTable.processSorts(sorter);

    resetTimestamp(pagination.current, processedSorts, processedFilters, pagination.pageSize);
  }

  useShowWorkspace() {
    const { setWorkspaceVisible } = this.props;

    setWorkspaceVisible(true);
  }

  render() {
    const {
      intl,
      curSiteFlowId,
      resetTimestamp,
      currentPage,
      pageSize,
      total,
      documents,
      fetchIsLoading,
      newResource,
      selectedResources,
      addSelectedResources,
      removeSelectedResources
    } = this.props;

    return (
      <>
        <Table
          size="small"
          // rowSelection={rowSelection}
          rowKey="id"
          pagination={{
            current: currentPage,
            pageSize: parseInt(pageSize, 10),
            total,
            showTotal: () => `${total} items`
          }}
          columns={this.getDocumentColumns()}
          dataSource={documents}
          loading={fetchIsLoading}
          bordered
          rowSelection={{
            selectedRowKeys: selectedResources.map(value => value.id),
            onChange: () => {
              // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            },
            onSelect: (record, selected) => {
              // console.log(`onSelect record: `, record, 'selected: ', selected, 'selectedRows: ', selectedRows);
              if (selected === true) {
                addSelectedResources([record]);
              } else {
                removeSelectedResources([record]);
              }
            },
            onSelectAll: (selected, selectedRows, changeRows) => {
              // console.log('onSelectAll selected: ', selected, 'selectedRows: ', selectedRows, 'changeRows: ', changeRows);
              if (selected === true) {
                addSelectedResources(changeRows);
              } else {
                removeSelectedResources(changeRows);
              }
            },
            onSelectInvert: selectedRows => {
              // console.log('onSelectInvert selectedRows: ', selectedRows);
              removeSelectedResources(selectedRows);
            },
            getCheckboxProps: () => ({
              // Column configuration not to be checked
              disabled: false
              // name: record.name,
            })
          }}
          onChange={this.useOnTableChange}
          title={() => (
            <>
              <Row type="flex" justify="start" gutter={[0, 16]}>
                <Col span={1}>
                  <Button
                    type="primary"
                    // disabled={!isValid}
                    loading={fetchIsLoading}
                    onClick={() => resetTimestamp()}
                    icon={<ReloadOutlined />}
                  />
                </Col>
                <Col span={5}>
                  <Button
                    type="primary"
                    disabled={curSiteFlowId === 0}
                    loading={fetchIsLoading}
                    onClick={() => newResource()}
                    // icon={<PlusOutlined />}
                  >
                    {intl.formatMessage({ id: 'new' })}
                  </Button>
                </Col>
              </Row>
            </>
          )}
          scroll={{ x: 950 }}
        />

        <div
          style={{
            position: 'fixed',
            bottom: '50px',
            right: '50px',
            zIndex: 9999
          }}
        >
          <Badge count={selectedResources.length}>
            <Button
              size="large"
              type="primary"
              shape="circle"
              icon={<LaptopOutlined />}
              onClick={this.useShowWorkspace}
              loading={fetchIsLoading}
            />
          </Badge>
        </div>
        <BarcodeDialog />
        <StorageBinList01Dialog />
      </>
    );
  }
}

StorageBinList01Table.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  resetTimestamp: PropTypes.func,
  goToResource: PropTypes.func,
  newResource: PropTypes.func,
  goToAudit: PropTypes.func,

  setWorkspaceVisible: PropTypes.func,

  addSelectedResources: PropTypes.func,
  removeSelectedResources: PropTypes.func,
  documents: PropTypes.arrayOf(PropTypes.object),
  selectedResources: PropTypes.arrayOf(PropTypes.object),

  sorts: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  filters: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),

  currentPage: PropTypes.number,
  pageSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  total: PropTypes.number,
  fetchIsLoading: PropTypes.bool,

  curSiteFlowId: PropTypes.number,
  fetchStorageBinList01: PropTypes.func,

  showBarcode: PropTypes.func
};

StorageBinList01Table.defaultProps = {
  intl: {},
  resetTimestamp() {},
  goToResource() {},
  newResource() {},
  goToAudit() {},

  setWorkspaceVisible() {},

  addSelectedResources() {},
  removeSelectedResources() {},
  documents: [],
  selectedResources: [],

  sorts: {},
  filters: {},

  currentPage: 1,
  pageSize: '20',
  total: 0,
  fetchIsLoading: false,

  curSiteFlowId: 0,
  fetchStorageBinList01() {},

  showBarcode() {}
};

const mapStateToProps = state => ({
  timestamp: state.storageBinList01.timestamp,
  workspaceIsVisible: state.storageBinList01.workspaceIsVisible,

  documents: state.storageBinList01.documents,
  selectedResources: state.storageBinList01.selectedResources,

  sorts: state.storageBinList01.sorts,
  filters: state.storageBinList01.filters,

  currentPage: state.storageBinList01.currentPage,
  pageSize: state.storageBinList01.pageSize,
  total: state.storageBinList01.total,
  fetchIsLoading: state.storageBinList01.fetchIsLoading,

  curSiteFlowId: state.app.curSiteFlowId
});

const mapDispatchToProps = dispatch => ({
  resetTimestamp: (currentPage, sorts, filters, pageSize) =>
    dispatch(
      StorageBinList01Actions.storageBinList01ResetTimestamp(currentPage, sorts, filters, pageSize)
    ),
  goToResource: resId => dispatch(StorageBinList01Actions.storageBinList01GoToResource(resId)),
  newResource: () => dispatch(StorageBinList01Actions.storageBinList01NewResource()),
  goToAudit: resId => dispatch(StorageBinList01Actions.storageBinList01GoToAudit(resId)),
  setWorkspaceVisible: boolean =>
    dispatch(StorageBinList01Actions.storageBinList01SetWorkspaceVisible(boolean)),

  addSelectedResources: selectedResources =>
    dispatch(StorageBinList01Actions.storageBinList01AddSelectedResources(selectedResources)),
  removeSelectedResources: selectedResources =>
    dispatch(StorageBinList01Actions.storageBinList01RemoveSelectedResources(selectedResources)),

  fetchStorageBinList01: (currentPage, sorts, filters, pageSize) =>
    dispatch(
      StorageBinList01Actions.storageBinList01FetchStorageBinList01(
        currentPage,
        sorts,
        filters,
        pageSize
      )
    ),
  showBarcode: (barcode, code, desc01, desc02) =>
    dispatch(StorageBinList01Actions.storageBinList01ShowBarcode(barcode, code, desc01, desc02))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(StorageBinList01Table));
