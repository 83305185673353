/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */
import { createReducer } from 'reduxsauce';
import { OutbOrdIndexTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const outbOrdIndexResetTimestamp = (state, { currentPage, sorts, filters, pageSize }) => ({
  ...state,
  currentPage: currentPage || state.currentPage,
  sorts: sorts || state.sorts,
  filters: filters || state.filters,
  pageSize: pageSize || state.pageSize,
  timestamp: Date.now(),
  selectedDocuments: []
});

export const outbOrdIndexFetchOutbOrdIndexLoading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const outbOrdIndexFetchOutbOrdIndexSuccess = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  documents,
  currentPage,
  lastPage,
  total,
  pageSize
});

export const outbOrdIndexAddSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = state.selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: [
      ...state.selectedDocuments,
      ...selectedDocuments.filter(data => {
        return !selectIds.includes(data.id);
      })
    ]
  };
};

export const outbOrdIndexRemoveSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: state.selectedDocuments.filter(data => {
      return !selectIds.includes(data.id);
    })
  };
};

export const outbOrdIndexSetWorkspaceVisible = (state, { boolean }) => ({
  ...state,
  workspaceIsVisible: boolean
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [OutbOrdIndexTypes.OUTB_ORD_INDEX_RESET_TIMESTAMP]: outbOrdIndexResetTimestamp,
  [OutbOrdIndexTypes.OUTB_ORD_INDEX_FETCH_OUTB_ORD_INDEX_LOADING]: outbOrdIndexFetchOutbOrdIndexLoading,
  [OutbOrdIndexTypes.OUTB_ORD_INDEX_FETCH_OUTB_ORD_INDEX_SUCCESS]: outbOrdIndexFetchOutbOrdIndexSuccess,
  [OutbOrdIndexTypes.OUTB_ORD_INDEX_ADD_SELECTED_DOCUMENTS]: outbOrdIndexAddSelectedDocuments,
  [OutbOrdIndexTypes.OUTB_ORD_INDEX_REMOVE_SELECTED_DOCUMENTS]: outbOrdIndexRemoveSelectedDocuments,
  [OutbOrdIndexTypes.OUTB_ORD_INDEX_SET_WORKSPACE_VISIBLE]: outbOrdIndexSetWorkspaceVisible
});
