import { put, call, select, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import { replace } from 'connected-react-router';
import ApiService from '../Services/ApiService';
import UtilService from '../Services/UtilService';
import AppActions from '../Stores/App/Actions';
import UserDetailActions, { UserDetailTypes } from '../Stores/UserDetail/Actions';

const getAppStore = state => state.app;

const getUserDetailStore = state => state.userDetail;

export function* userDetailInitModel() {
  try {
    yield put(UserDetailActions.userDetailShowModelLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `user/initModel`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(UserDetailActions.userDetailShowModelSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(UserDetailActions.userDetailShowModelLoading(false));
  }
}

export function* userDetailShowModel({ resId }) {
  try {
    yield put(UserDetailActions.userDetailShowModelLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `user/showModel/${resId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(UserDetailActions.userDetailShowModelSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(UserDetailActions.userDetailShowModelLoading(false));
  }
}

export function* userDetailUpdateModel({ formikBag, model }) {
  formikBag.setSubmitting(true);
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: model
    };

    const result = yield call(
      ApiService.putApi, // function
      app.apiUrl,
      'user/updateModel',
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      const { model: retModel } = result.data;

      yield put(UserDetailActions.userDetailUpdateModelSuccess(retModel));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* userDetailCreateModel({ formikBag, model }) {
  formikBag.setSubmitting(true);
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: model
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `user/createModel`,
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      yield put(replace(`${app.appPath}/userDetail/update/${result.data}`));
      yield put(UserDetailActions.userDetailSetResId(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* userDetailShowUserSiteFlows({ resId }) {
  try {
    yield put(UserDetailActions.userDetailShowUserSiteFlowLoading(true));

    let result = {
      isSuccess: true,
      data: []
    };

    if (resId > 0) {
      const app = yield select(getAppStore);
      const getData = {};

      result = yield call(
        ApiService.getApi, // function
        app.apiUrl,
        `user/showSiteFlows/${resId}`,
        app.token,
        getData,
        'multipart/form-data' // params
      );
    }

    if (result.isSuccess === true) {
      yield put(UserDetailActions.userDetailShowUserSiteFlowSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(UserDetailActions.userDetailShowUserSiteFlowLoading(false));
  }
}

export function* userDetailFetchSiteFlowOptions({ resId, search }) {
  try {
    yield put(UserDetailActions.userDetailFetchSiteFlowOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: [
        {
          field: 'user_id',
          operator: 'NOT IN',
          value: resId
        }
      ]
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `siteFlow/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({ value: d.id, label: `${d.code}` }));

      yield put(UserDetailActions.userDetailFetchSiteFlowOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(UserDetailActions.userDetailFetchSiteFlowOptionLoading(false));
  }
}

export function* userDetailAddUserSiteFlow({ resId, siteFlowId }) {
  try {
    yield put(UserDetailActions.userDetailShowUserSiteFlowLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      data: [{ site_flow_id: siteFlowId }]
    };

    const result = yield call(
      ApiService.putApi, // function
      app.apiUrl,
      `user/updateSiteFlows/${resId}`,
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      const userDetail = yield select(getUserDetailStore);

      const { userSiteFlows: oldUserSiteFlows } = userDetail;

      const {
        user_site_flows: retUserSiteFlows,
        deleted_user_site_flows: retDeletedUserSiteFlows
      } = result.data;

      const processed = UtilService.processResources(
        oldUserSiteFlows,
        retUserSiteFlows,
        retDeletedUserSiteFlows
      );

      yield put(UserDetailActions.userDetailShowUserSiteFlowSuccess(processed.resources));

      // clear the select2 options and option
      yield put(UserDetailActions.userDetailFetchSiteFlowOptionSuccess([]));
      yield put(UserDetailActions.userDetailSetSiteFlowOption({ value: 0, label: '' }));

      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_SUCCESS_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(UserDetailActions.userDetailShowUserSiteFlowLoading(false));
  }
}

export function* userDetailRemoveUserSiteFlow({ resId, userSiteFlowId }) {
  try {
    yield put(UserDetailActions.userDetailShowUserSiteFlowLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      data: [{ user_site_flow_id: userSiteFlowId }]
    };

    const result = yield call(
      ApiService.deleteApi, // function
      app.apiUrl,
      `user/deleteSiteFlows/${resId}`,
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      const userDetail = yield select(getUserDetailStore);

      const { userSiteFlows: oldUserSiteFlows } = userDetail;

      const {
        user_site_flows: retUserSiteFlows,
        deleted_user_site_flows: retDeletedUserSiteFlows
      } = result.data;

      const processed = UtilService.processResources(
        oldUserSiteFlows,
        retUserSiteFlows,
        retDeletedUserSiteFlows
      );

      yield put(UserDetailActions.userDetailShowUserSiteFlowSuccess(processed.resources));

      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_SUCCESS_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(UserDetailActions.userDetailShowUserSiteFlowLoading(false));
  }
}

export function* userDetailShowUserDivisions({ resId }) {
  try {
    yield put(UserDetailActions.userDetailShowUserDivisionLoading(true));

    let result = {
      isSuccess: true,
      data: []
    };

    if (resId > 0) {
      const app = yield select(getAppStore);
      const getData = {};

      result = yield call(
        ApiService.getApi, // function
        app.apiUrl,
        `user/showDivisions/${resId}`,
        app.token,
        getData,
        'multipart/form-data' // params
      );
    }

    if (result.isSuccess === true) {
      yield put(UserDetailActions.userDetailShowUserDivisionSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(UserDetailActions.userDetailShowUserDivisionLoading(false));
  }
}

export function* userDetailFetchDivisionOptions({ resId, search }) {
  try {
    yield put(UserDetailActions.userDetailFetchDivisionOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: [
        {
          field: 'user_id',
          operator: 'NOT IN',
          value: resId
        }
      ]
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `division/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({ value: d.id, label: `${d.code}` }));

      yield put(UserDetailActions.userDetailFetchDivisionOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(UserDetailActions.userDetailFetchDivisionOptionLoading(false));
  }
}

export function* userDetailAddUserDivision({ resId, divisionId }) {
  try {
    yield put(UserDetailActions.userDetailShowUserDivisionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      data: [{ division_id: divisionId }]
    };

    const result = yield call(
      ApiService.putApi, // function
      app.apiUrl,
      `user/updateDivisions/${resId}`,
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      const userDetail = yield select(getUserDetailStore);

      const { userDivisions: oldUserDivisions } = userDetail;

      const {
        user_divisions: retUserDivisions,
        deleted_user_divisions: retDeletedUserDivisions
      } = result.data;

      const processed = UtilService.processResources(
        oldUserDivisions,
        retUserDivisions,
        retDeletedUserDivisions
      );

      yield put(UserDetailActions.userDetailShowUserDivisionSuccess(processed.resources));

      // clear the select2 options and option
      yield put(UserDetailActions.userDetailFetchDivisionOptionSuccess([]));
      yield put(UserDetailActions.userDetailSetDivisionOption({ value: 0, label: '' }));

      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_SUCCESS_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(UserDetailActions.userDetailShowUserDivisionLoading(false));
  }
}

export function* userDetailRemoveUserDivision({ resId, userDivisionId }) {
  try {
    yield put(UserDetailActions.userDetailShowUserDivisionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      data: [{ user_division_id: userDivisionId }]
    };

    const result = yield call(
      ApiService.deleteApi, // function
      app.apiUrl,
      `user/deleteDivisions/${resId}`,
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      const userDetail = yield select(getUserDetailStore);

      const { userDivisions: oldUserDivisions } = userDetail;

      const {
        user_divisions: retUserDivisions,
        deleted_user_divisions: retDeletedUserDivisions
      } = result.data;

      const processed = UtilService.processResources(
        oldUserDivisions,
        retUserDivisions,
        retDeletedUserDivisions
      );

      yield put(UserDetailActions.userDetailShowUserDivisionSuccess(processed.resources));

      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_SUCCESS_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(UserDetailActions.userDetailShowUserDivisionLoading(false));
  }
}

export function* userDetailResetPassword() {
  yield put(UserDetailActions.userDetailShowModelLoading(true));
  try {
    const app = yield select(getAppStore);

    const userDetail = yield select(getUserDetailStore);

    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `user/resetPassword/${userDetail.resId}`,
      app.token,
      getData // params
    );

    if (result.isSuccess === true) {
      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_SUCCESS_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(UserDetailActions.userDetailShowModelLoading(false));
  }
}

export const saga = [
  takeLatest(UserDetailTypes.USER_DETAIL_INIT_MODEL, userDetailInitModel),
  takeLatest(UserDetailTypes.USER_DETAIL_SHOW_MODEL, userDetailShowModel),

  takeLatest(UserDetailTypes.USER_DETAIL_UPDATE_MODEL, userDetailUpdateModel),
  takeLatest(UserDetailTypes.USER_DETAIL_CREATE_MODEL, userDetailCreateModel),

  takeLatest(UserDetailTypes.USER_DETAIL_SHOW_USER_SITE_FLOWS, userDetailShowUserSiteFlows),
  takeLatest(UserDetailTypes.USER_DETAIL_FETCH_SITE_FLOW_OPTIONS, userDetailFetchSiteFlowOptions),

  takeLatest(UserDetailTypes.USER_DETAIL_ADD_USER_SITE_FLOW, userDetailAddUserSiteFlow),
  takeLatest(UserDetailTypes.USER_DETAIL_REMOVE_USER_SITE_FLOW, userDetailRemoveUserSiteFlow),

  takeLatest(UserDetailTypes.USER_DETAIL_SHOW_USER_DIVISIONS, userDetailShowUserDivisions),
  takeLatest(UserDetailTypes.USER_DETAIL_FETCH_DIVISION_OPTIONS, userDetailFetchDivisionOptions),

  takeLatest(UserDetailTypes.USER_DETAIL_ADD_USER_DIVISION, userDetailAddUserDivision),
  takeLatest(UserDetailTypes.USER_DETAIL_REMOVE_USER_DIVISION, userDetailRemoveUserDivision),

  takeLatest(UserDetailTypes.USER_DETAIL_RESET_PASSWORD, userDetailResetPassword)
];
