/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { PutAwayDetailTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const putAwayDetailResetTimestamp = state => ({
  ...state,
  timestamp: Date.now()
});

export const putAwayDetailSetHdrId = (state, { hdrId }) => ({
  ...state,
  timestamp: Date.now(),
  documentIsLoading: false,
  hdrId,
  detailIsVisible: false
});

export const putAwayDetailShowDocumentLoading = (state, { boolean }) => ({
  ...state,
  documentIsLoading: boolean
});

export const putAwayDetailShowHeaderSuccess = (state, { documentHeader }) => ({
  ...state,
  documentHeader
});

export const putAwayDetailUpdateDocumentSuccess = (state, { documentHeader, documentDetails }) => ({
  ...state,
  documentHeader,
  documentDetails
});

export const putAwayDetailShowDetailsSuccess = (state, { documentDetails }) => ({
  ...state,
  documentDetails
});

export const putAwayDetailSetDetailVisible = (state, { boolean }) => ({
  ...state,
  detailIsVisible: boolean
});

export const putAwayDetailSetWhseJobType = (state, { whseJobType }) => ({
  ...state,
  whseJobType
});

export const putAwayDetailSetDocumentDetail = (state, { documentDetail }) => ({
  ...state,
  documentDetail,
  whseJobType: documentDetail.whse_job_type,
  companyOptions: [],
  frStorageBinOptions: [],
  quantBalOptions: [],
  uomOptions: [],
  toStorageBinOptions: []
});

export const putAwayDetailFetchFrStorageBinOptionLoading = (state, { boolean }) => ({
  ...state,
  frStorageBinIsLoading: boolean
});

export const putAwayDetailFetchFrStorageBinOptionSuccess = (state, { options }) => ({
  ...state,
  frStorageBinOptions: options
});

export const putAwayDetailFetchQuantBalOptionLoading = (state, { boolean }) => ({
  ...state,
  quantBalIsLoading: boolean
});

export const putAwayDetailFetchQuantBalOptionSuccess = (state, { options }) => ({
  ...state,
  quantBalOptions: options
});

export const putAwayDetailFetchItemBatchOptionLoading = (state, { boolean }) => ({
  ...state,
  itemBatchIsLoading: boolean
});

export const putAwayDetailFetchItemBatchOptionSuccess = (state, { options }) => ({
  ...state,
  itemBatchOptions: options
});

export const putAwayDetailFetchUomOptionLoading = (state, { boolean }) => ({
  ...state,
  uomIsLoading: boolean
});

export const putAwayDetailFetchUomOptionSuccess = (state, { options }) => ({
  ...state,
  uomOptions: options
});

export const putAwayDetailFetchItemCond01OptionLoading = (state, { boolean }) => ({
  ...state,
  itemCond01IsLoading: boolean
});

export const putAwayDetailFetchItemCond01OptionSuccess = (state, { options }) => ({
  ...state,
  itemCond01Options: options
});

export const putAwayDetailFetchToStorageBinOptionLoading = (state, { boolean }) => ({
  ...state,
  toStorageBinIsLoading: boolean
});

export const putAwayDetailFetchToStorageBinOptionSuccess = (state, { options }) => ({
  ...state,
  toStorageBinOptions: options
});

export const putAwayDetailFetchToHandlingUnitOptionLoading = (state, { boolean }) => ({
  ...state,
  toHandlingUnitIsLoading: boolean
});

export const putAwayDetailFetchToHandlingUnitOptionSuccess = (state, { options }) => ({
  ...state,
  toHandlingUnitOptions: options
});

// force refresh the formik form
export const putAwayDetailUpdateDetails = (state, { documentDetails }) => ({
  ...state,
  documentDetail: documentDetails[0]
});

// force refresh the formik form
export const putAwayDetailCreateDetail = (state, { documentDetail }) => ({
  ...state,
  documentDetail
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [PutAwayDetailTypes.PUT_AWAY_DETAIL_RESET_TIMESTAMP]: putAwayDetailResetTimestamp,
  [PutAwayDetailTypes.PUT_AWAY_DETAIL_SET_HDR_ID]: putAwayDetailSetHdrId,

  [PutAwayDetailTypes.PUT_AWAY_DETAIL_SHOW_HEADER_SUCCESS]: putAwayDetailShowHeaderSuccess,

  [PutAwayDetailTypes.PUT_AWAY_DETAIL_UPDATE_DOCUMENT_SUCCESS]: putAwayDetailUpdateDocumentSuccess,

  [PutAwayDetailTypes.PUT_AWAY_DETAIL_SHOW_DOCUMENT_LOADING]: putAwayDetailShowDocumentLoading,

  [PutAwayDetailTypes.PUT_AWAY_DETAIL_SHOW_DETAILS_SUCCESS]: putAwayDetailShowDetailsSuccess,

  [PutAwayDetailTypes.PUT_AWAY_DETAIL_SET_DETAIL_VISIBLE]: putAwayDetailSetDetailVisible,
  [PutAwayDetailTypes.PUT_AWAY_DETAIL_SET_DOCUMENT_DETAIL]: putAwayDetailSetDocumentDetail,

  [PutAwayDetailTypes.PUT_AWAY_DETAIL_UPDATE_DETAILS]: putAwayDetailUpdateDetails,
  [PutAwayDetailTypes.PUT_AWAY_DETAIL_CREATE_DETAIL]: putAwayDetailCreateDetail,

  [PutAwayDetailTypes.PUT_AWAY_DETAIL_SET_WHSE_JOB_TYPE]: putAwayDetailSetWhseJobType,

  [PutAwayDetailTypes.PUT_AWAY_DETAIL_FETCH_FR_STORAGE_BIN_OPTION_LOADING]: putAwayDetailFetchFrStorageBinOptionLoading,
  [PutAwayDetailTypes.PUT_AWAY_DETAIL_FETCH_FR_STORAGE_BIN_OPTION_SUCCESS]: putAwayDetailFetchFrStorageBinOptionSuccess,

  [PutAwayDetailTypes.PUT_AWAY_DETAIL_FETCH_QUANT_BAL_OPTION_LOADING]: putAwayDetailFetchQuantBalOptionLoading,
  [PutAwayDetailTypes.PUT_AWAY_DETAIL_FETCH_QUANT_BAL_OPTION_SUCCESS]: putAwayDetailFetchQuantBalOptionSuccess,

  [PutAwayDetailTypes.PUT_AWAY_DETAIL_FETCH_UOM_OPTION_LOADING]: putAwayDetailFetchUomOptionLoading,
  [PutAwayDetailTypes.PUT_AWAY_DETAIL_FETCH_UOM_OPTION_SUCCESS]: putAwayDetailFetchUomOptionSuccess,

  [PutAwayDetailTypes.PUT_AWAY_DETAIL_FETCH_ITEM_COND01_OPTION_LOADING]: putAwayDetailFetchItemCond01OptionLoading,
  [PutAwayDetailTypes.PUT_AWAY_DETAIL_FETCH_ITEM_COND01_OPTION_SUCCESS]: putAwayDetailFetchItemCond01OptionSuccess,

  [PutAwayDetailTypes.PUT_AWAY_DETAIL_FETCH_TO_STORAGE_BIN_OPTION_LOADING]: putAwayDetailFetchToStorageBinOptionLoading,
  [PutAwayDetailTypes.PUT_AWAY_DETAIL_FETCH_TO_STORAGE_BIN_OPTION_SUCCESS]: putAwayDetailFetchToStorageBinOptionSuccess
});
