import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  slsOrdFServ01ShowSyncSetting: null,
  slsOrdFServ01ShowSyncSettingLoading: ['boolean'],
  slsOrdFServ01ShowSyncSettingSuccess: ['syncSetting'],
  slsOrdFServ01UpdateSyncSetting: ['formikBag', 'syncSetting'],
  slsOrdFServ01ShowBatchJobStatus: null,
  slsOrdFServ01ShowBatchJobStatusSuccess: ['batchJobStatus'],
  slsOrdFServ01Import: ['formikBag'],
  slsOrdFServ01ImportLoading: ['boolean']
});

export const SlsOrdFServ01Types = Types;
export default Creators;
