import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  whseJob0602ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  whseJob0602GoToDocument: ['hdrId'],
  whseJob0602FetchWhseJob0602: ['currentPage', 'sorts', 'filters', 'pageSize'],
  whseJob0602FetchWhseJob0602Loading: ['boolean'],
  whseJob0602FetchWhseJob0602Success: ['documents', 'currentPage', 'lastPage', 'total', 'pageSize'],
  whseJob0602AddSelectedDocuments: ['selectedDocuments'],
  whseJob0602RemoveSelectedDocuments: ['selectedDocuments'],
  whseJob0602SetWorkspaceVisible: ['boolean'],
  whseJob0602CreateWhseJob0602: ['hdrIds', 'toStorageBinId'],
  whseJob0602CreateWhseJob0602Loading: ['boolean'],
  whseJob0602CreateWhseJob0602Success: ['newDocuments']
});

export const WhseJob0602Types = Types;
export default Creators;
