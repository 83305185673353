/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { BinTrf01Types } from './Actions';
import INITIAL_STATE from './InitialState';

export const binTrf01ResetTimestamp = (state, { currentPage, sorts, criteria, pageSize }) => ({
  ...state,
  currentPage: currentPage || state.currentPage,
  sorts: sorts || state.sorts,
  criteria: criteria || state.criteria,
  pageSize: pageSize || state.pageSize,
  timestamp: Date.now(),
  // selectedDocuments: INITIAL_STATE.selectedDocuments,
  toStorageBinOptions: INITIAL_STATE.toStorageBinOptions,
  toStorageBinOption: INITIAL_STATE.toStorageBinOption
});

export const binTrf01FetchBinTrf01Loading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const binTrf01FetchBinTrf01Success = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => {
  const calcDates = documents.reduce(
    (lastCalcDate, data) => {
      const newCalcDate = lastCalcDate;
      if (data.calc_date_01 > newCalcDate.month01) {
        newCalcDate.month01 = data.calc_date_01;
      }
      if (data.calc_date_02 > newCalcDate.month02) {
        newCalcDate.month02 = data.calc_date_02;
      }
      if (data.calc_date_03 > newCalcDate.month03) {
        newCalcDate.month03 = data.calc_date_03;
      }
      if (data.calc_date_04 > newCalcDate.month04) {
        newCalcDate.month04 = data.calc_date_04;
      }
      if (data.calc_date_05 > newCalcDate.month05) {
        newCalcDate.month05 = data.calc_date_05;
      }
      if (data.calc_date_06 > newCalcDate.month06) {
        newCalcDate.month06 = data.calc_date_06;
      }
      if (data.balance_query_at > newCalcDate.balanceQueryAt) {
        newCalcDate.balanceQueryAt = data.balance_query_at;
      }
      return newCalcDate;
    },
    {
      month01: '1970-01-01 00:00:00',
      month02: '1970-01-01 00:00:00',
      month03: '1970-01-01 00:00:00',
      month04: '1970-01-01 00:00:00',
      month05: '1970-01-01 00:00:00',
      month06: '1970-01-01 00:00:00',
      balanceQueryAt: '1970-01-01 00:00:00'
    }
  );
  return {
    ...state,
    documents,
    currentPage,
    lastPage,
    total,
    pageSize,
    calcDates
  };
};

export const binTrf01AddSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = state.selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: [
      ...state.selectedDocuments,
      ...selectedDocuments.filter(data => {
        return !selectIds.includes(data.id);
      })
    ]
  };
};

export const binTrf01RemoveSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: state.selectedDocuments.filter(data => {
      return !selectIds.includes(data.id);
    })
  };
};

export const binTrf01SetWorkspaceVisible = (state, { boolean }) => ({
  ...state,
  workspaceIsVisible: boolean
});

export const binTrf01FetchCompanyOptionLoading = (state, { boolean }) => ({
  ...state,
  companyIsLoading: boolean
});

export const binTrf01FetchCompanyOptionSuccess = (state, { options }) => ({
  ...state,
  companyOptions: options
});

export const binTrf01FetchItemOptionLoading = (state, { boolean }) => ({
  ...state,
  itemIsLoading: boolean
});

export const binTrf01FetchItemOptionSuccess = (state, { options }) => ({
  ...state,
  itemOptions: options
});

export const binTrf01FetchItemGroup01OptionLoading = (state, { boolean }) => ({
  ...state,
  itemGroup01IsLoading: boolean
});

export const binTrf01FetchItemGroup01OptionSuccess = (state, { options }) => ({
  ...state,
  itemGroup01Options: options
});

export const binTrf01FetchItemGroup02OptionLoading = (state, { boolean }) => ({
  ...state,
  itemGroup02IsLoading: boolean
});

export const binTrf01FetchItemGroup02OptionSuccess = (state, { options }) => ({
  ...state,
  itemGroup02Options: options
});

export const binTrf01FetchItemGroup03OptionLoading = (state, { boolean }) => ({
  ...state,
  itemGroup03IsLoading: boolean
});

export const binTrf01FetchItemGroup03OptionSuccess = (state, { options }) => ({
  ...state,
  itemGroup03Options: options
});

export const binTrf01ShowBatchJobStatusSuccess = (state, { batchJobStatus }) => ({
  ...state,
  batchJobStatus
});

export const binTrf01BatchJobLoading = (state, { batchJobCode }) => ({
  ...state,
  batchJobIsLoading: batchJobCode
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [BinTrf01Types.BIN_TRF01_RESET_TIMESTAMP]: binTrf01ResetTimestamp,
  [BinTrf01Types.BIN_TRF01_FETCH_BIN_TRF01_LOADING]: binTrf01FetchBinTrf01Loading,
  [BinTrf01Types.BIN_TRF01_FETCH_BIN_TRF01_SUCCESS]: binTrf01FetchBinTrf01Success,
  [BinTrf01Types.BIN_TRF01_ADD_SELECTED_DOCUMENTS]: binTrf01AddSelectedDocuments,
  [BinTrf01Types.BIN_TRF01_REMOVE_SELECTED_DOCUMENTS]: binTrf01RemoveSelectedDocuments,
  [BinTrf01Types.BIN_TRF01_SET_WORKSPACE_VISIBLE]: binTrf01SetWorkspaceVisible,

  [BinTrf01Types.BIN_TRF01_FETCH_COMPANY_OPTION_LOADING]: binTrf01FetchCompanyOptionLoading,
  [BinTrf01Types.BIN_TRF01_FETCH_COMPANY_OPTION_SUCCESS]: binTrf01FetchCompanyOptionSuccess,

  [BinTrf01Types.BIN_TRF01_FETCH_ITEM_OPTION_LOADING]: binTrf01FetchItemOptionLoading,
  [BinTrf01Types.BIN_TRF01_FETCH_ITEM_OPTION_SUCCESS]: binTrf01FetchItemOptionSuccess,

  [BinTrf01Types.BIN_TRF01_FETCH_ITEM_GROUP01_OPTION_LOADING]: binTrf01FetchItemGroup01OptionLoading,
  [BinTrf01Types.BIN_TRF01_FETCH_ITEM_GROUP01_OPTION_SUCCESS]: binTrf01FetchItemGroup01OptionSuccess,

  [BinTrf01Types.BIN_TRF01_FETCH_ITEM_GROUP02_OPTION_LOADING]: binTrf01FetchItemGroup02OptionLoading,
  [BinTrf01Types.BIN_TRF01_FETCH_ITEM_GROUP02_OPTION_SUCCESS]: binTrf01FetchItemGroup02OptionSuccess,

  [BinTrf01Types.BIN_TRF01_FETCH_ITEM_GROUP03_OPTION_LOADING]: binTrf01FetchItemGroup03OptionLoading,
  [BinTrf01Types.BIN_TRF01_FETCH_ITEM_GROUP03_OPTION_SUCCESS]: binTrf01FetchItemGroup03OptionSuccess,

  [BinTrf01Types.BIN_TRF01_SHOW_BATCH_JOB_STATUS_SUCCESS]: binTrf01ShowBatchJobStatusSuccess,
  [BinTrf01Types.BIN_TRF01_BATCH_JOB_LOADING]: binTrf01BatchJobLoading
});
