import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  purchaseReturnProcessFetchPurchaseReturnProcess: null,
  purchaseReturnProcessFetchPurchaseReturnProcessLoading: ['boolean'],
  purchaseReturnProcessFetchPurchaseReturnProcessSuccess: ['process']
});

export const PurchaseReturnProcessTypes = Types;
export default Creators;
