import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  transportList01ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  transportList01GoToResource: ['resId'],
  transportList01NewResource: null,
  transportList01FetchTransportList01: ['currentPage', 'sorts', 'filters', 'pageSize'],
  transportList01FetchTransportList01Loading: ['boolean'],
  transportList01FetchTransportList01Success: [
    'resources',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],
  transportList01AddSelectedResources: ['selectedResources'],
  transportList01RemoveSelectedResources: ['selectedResources'],
  transportList01SetWorkspaceVisible: ['boolean']
});

export const TransportList01Types = Types;
export default Creators;
