import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  loadListDetailResetTimestamp: null,
  loadListDetailSetHdrId: ['hdrId'],

  loadListDetailInitHeader: null,
  loadListDetailShowHeader: ['hdrId'],
  loadListDetailShowHeaderSuccess: ['documentHeader'],

  loadListDetailUpdateHeader: ['formikBag', 'documentHeader'],
  loadListDetailCreateHeader: ['formikBag', 'documentHeader'],
  loadListDetailUpdateDocumentSuccess: ['documentHeader', 'outbOrdHdrs', 'inbOrdHdrs'],

  loadListDetailShowDocumentLoading: ['boolean'],
  loadListDetailTransitionToStatus: ['formikBag', 'hdrId', 'docStatus'],

  loadListDetailShowOutbOrdHdrs: ['hdrId'],
  loadListDetailShowOutbOrdHdrsSuccess: ['outbOrdHdrs'],

  loadListDetailShowInbOrdHdrs: ['hdrId'],
  loadListDetailShowInbOrdHdrsSuccess: ['inbOrdHdrs'],

  loadListDetailScanBarcode: ['hdrId', 'value'],
  loadListDetailScanBarcodeError: ['value'],

  loadListDetailFetchTransportOptions: ['search'],
  loadListDetailFetchTransportOptionLoading: ['boolean'],
  loadListDetailFetchTransportOptionSuccess: ['options'],
  loadListDetailChangeTransport: ['formikBag', 'hdrId', 'itemId'],

  loadListDetailFetchDriver01Options: ['search'],
  loadListDetailFetchDriver01OptionLoading: ['boolean'],
  loadListDetailFetchDriver01OptionSuccess: ['options'],

  loadListDetailFetchDeliveryMan01Options: ['search'],
  loadListDetailFetchDeliveryMan01OptionLoading: ['boolean'],
  loadListDetailFetchDeliveryMan01OptionSuccess: ['options'],

  loadListDetailFetchDeliveryMan02Options: ['search'],
  loadListDetailFetchDeliveryMan02OptionLoading: ['boolean'],
  loadListDetailFetchDeliveryMan02OptionSuccess: ['options'],

  loadListDetailSearchDocument: ['search'],
  loadListDetailSearchDocumentLoading: ['boolean'],
  loadListDetailSearchDocumentSuccess: ['options'],

  loadListDetailFetchLoadListDetailLoading: ['boolean'],

  loadListDetailUpOutbOrd: ['hdrId', 'outbOrdHdr'],
  loadListDetailDownOutbOrd: ['hdrId', 'outbOrdHdr'],

  loadListDetailUpInbOrd: ['hdrId', 'inbOrdHdr'],
  loadListDetailDownInbOrd: ['hdrId', 'inbOrdHdr'],

  loadListDetailDeleteDetailsByOrdHdrIds: ['hdrId', 'outbOrdHdrIds', 'inbOrdHdrIds']
});

export const LoadListDetailTypes = Types;
export default Creators;
