/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { WorkerPackListAnalysisReportTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const workerPackListAnalysisReportReportLoading = (state, { boolean }) => ({
  ...state,
  reportIsLoading: boolean
});

export const workerPackListAnalysisReportInitWorkerPackListAnalysisSuccess = (
  state,
  { criteria }
) => ({
  ...state,
  criteria
});

export const workerPackListAnalysisReportWorkerPackListAnalysisSuccess = (
  state,
  { criteria, reportData }
) => ({
  ...state,
  criteria,
  reportData
});

export const workerPackListAnalysisReportFetchStorageBayOptionSuccess = (state, { options }) => ({
  ...state,
  storageBayOptions: options
});

export const workerPackListAnalysisReportFetchLocationOptionLoading = (state, { boolean }) => ({
  ...state,
  locationIsLoading: boolean
});

export const workerPackListAnalysisReportFetchLocationOptionSuccess = (state, { options }) => ({
  ...state,
  locationOptions: options
});

export const workerPackListAnalysisReportFetchCompanyOptionLoading = (state, { boolean }) => ({
  ...state,
  companyIsLoading: boolean
});

export const workerPackListAnalysisReportFetchCompanyOptionSuccess = (state, { options }) => ({
  ...state,
  companyOptions: options
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [WorkerPackListAnalysisReportTypes.WORKER_PACK_LIST_ANALYSIS_REPORT_REPORT_LOADING]: workerPackListAnalysisReportReportLoading,
  [WorkerPackListAnalysisReportTypes.WORKER_PACK_LIST_ANALYSIS_REPORT_INIT_WORKER_PACK_LIST_ANALYSIS_SUCCESS]: workerPackListAnalysisReportInitWorkerPackListAnalysisSuccess,
  [WorkerPackListAnalysisReportTypes.WORKER_PACK_LIST_ANALYSIS_REPORT_WORKER_PACK_LIST_ANALYSIS_SUCCESS]: workerPackListAnalysisReportWorkerPackListAnalysisSuccess,

  [WorkerPackListAnalysisReportTypes.WORKER_PACK_LIST_ANALYSIS_REPORT_FETCH_COMPANY_OPTION_LOADING]: workerPackListAnalysisReportFetchCompanyOptionLoading,
  [WorkerPackListAnalysisReportTypes.WORKER_PACK_LIST_ANALYSIS_REPORT_FETCH_COMPANY_OPTION_SUCCESS]: workerPackListAnalysisReportFetchCompanyOptionSuccess,

  [WorkerPackListAnalysisReportTypes.WORKER_PACK_LIST_ANALYSIS_REPORT_FETCH_LOCATION_OPTION_LOADING]: workerPackListAnalysisReportFetchLocationOptionLoading,
  [WorkerPackListAnalysisReportTypes.WORKER_PACK_LIST_ANALYSIS_REPORT_FETCH_LOCATION_OPTION_SUCCESS]: workerPackListAnalysisReportFetchLocationOptionSuccess
});
