/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { WorkerGdsRcptAnalysisReportTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const workerGdsRcptAnalysisReportReportLoading = (state, { boolean }) => ({
  ...state,
  reportIsLoading: boolean
});

export const workerGdsRcptAnalysisReportInitWorkerGdsRcptAnalysisSuccess = (
  state,
  { criteria }
) => ({
  ...state,
  criteria
});

export const workerGdsRcptAnalysisReportWorkerGdsRcptAnalysisSuccess = (
  state,
  { criteria, reportData }
) => ({
  ...state,
  criteria,
  reportData
});

export const workerGdsRcptAnalysisReportFetchUserOptionLoading = (state, { boolean }) => ({
  ...state,
  userIsLoading: boolean
});

export const workerGdsRcptAnalysisReportFetchUserOptionSuccess = (state, { options }) => ({
  ...state,
  userOptions: options
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [WorkerGdsRcptAnalysisReportTypes.WORKER_GDS_RCPT_ANALYSIS_REPORT_REPORT_LOADING]: workerGdsRcptAnalysisReportReportLoading,
  [WorkerGdsRcptAnalysisReportTypes.WORKER_GDS_RCPT_ANALYSIS_REPORT_INIT_WORKER_GDS_RCPT_ANALYSIS_SUCCESS]: workerGdsRcptAnalysisReportInitWorkerGdsRcptAnalysisSuccess,
  [WorkerGdsRcptAnalysisReportTypes.WORKER_GDS_RCPT_ANALYSIS_REPORT_WORKER_GDS_RCPT_ANALYSIS_SUCCESS]: workerGdsRcptAnalysisReportWorkerGdsRcptAnalysisSuccess,

  [WorkerGdsRcptAnalysisReportTypes.WORKER_GDS_RCPT_ANALYSIS_REPORT_FETCH_USER_OPTION_LOADING]: workerGdsRcptAnalysisReportFetchUserOptionLoading,
  [WorkerGdsRcptAnalysisReportTypes.WORKER_GDS_RCPT_ANALYSIS_REPORT_FETCH_USER_OPTION_SUCCESS]: workerGdsRcptAnalysisReportFetchUserOptionSuccess
});
