/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { StorageBinDetailTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const storageBinDetailResetModelTimestamp = state => ({
  ...state,
  modelTimestamp: Date.now()
});

export const storageBinDetailSetResId = (state, { resId }) => ({
  ...state,
  modelTimestamp: Date.now(),
  resId
});

export const storageBinDetailShowModelLoading = (state, { boolean }) => ({
  ...state,
  modelIsLoading: boolean
});

export const storageBinDetailShowModelSuccess = (state, { model }) => ({
  ...state,
  model
});

export const storageBinDetailUpdateModelSuccess = (state, { model }) => ({
  ...state,
  model
});

export const storageBinDetailFetchStorageRowOptionLoading = (state, { boolean }) => ({
  ...state,
  storageRowIsLoading: boolean
});

export const storageBinDetailFetchStorageRowOptionSuccess = (state, { options }) => ({
  ...state,
  storageRowOptions: options
});

export const storageBinDetailFetchStorageBayOptionLoading = (state, { boolean }) => ({
  ...state,
  storageBayIsLoading: boolean
});

export const storageBinDetailFetchStorageBayOptionSuccess = (state, { options }) => ({
  ...state,
  storageBayOptions: options
});

export const storageBinDetailFetchStorageTypeOptionLoading = (state, { boolean }) => ({
  ...state,
  storageTypeIsLoading: boolean
});

export const storageBinDetailFetchStorageTypeOptionSuccess = (state, { options }) => ({
  ...state,
  storageTypeOptions: options
});

export const storageBinDetailFetchLocationOptionLoading = (state, { boolean }) => ({
  ...state,
  locationIsLoading: boolean
});

export const storageBinDetailFetchLocationOptionSuccess = (state, { options }) => ({
  ...state,
  locationOptions: options
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [StorageBinDetailTypes.STORAGE_BIN_DETAIL_RESET_MODEL_TIMESTAMP]: storageBinDetailResetModelTimestamp,
  [StorageBinDetailTypes.STORAGE_BIN_DETAIL_SET_RES_ID]: storageBinDetailSetResId,
  [StorageBinDetailTypes.STORAGE_BIN_DETAIL_SHOW_MODEL_LOADING]: storageBinDetailShowModelLoading,
  [StorageBinDetailTypes.STORAGE_BIN_DETAIL_SHOW_MODEL_SUCCESS]: storageBinDetailShowModelSuccess,
  [StorageBinDetailTypes.STORAGE_BIN_DETAIL_UPDATE_MODEL_SUCCESS]: storageBinDetailUpdateModelSuccess,

  [StorageBinDetailTypes.STORAGE_BIN_DETAIL_FETCH_STORAGE_ROW_OPTION_LOADING]: storageBinDetailFetchStorageRowOptionLoading,
  [StorageBinDetailTypes.STORAGE_BIN_DETAIL_FETCH_STORAGE_ROW_OPTION_SUCCESS]: storageBinDetailFetchStorageRowOptionSuccess,

  [StorageBinDetailTypes.STORAGE_BIN_DETAIL_FETCH_STORAGE_BAY_OPTION_LOADING]: storageBinDetailFetchStorageBayOptionLoading,
  [StorageBinDetailTypes.STORAGE_BIN_DETAIL_FETCH_STORAGE_BAY_OPTION_SUCCESS]: storageBinDetailFetchStorageBayOptionSuccess,

  [StorageBinDetailTypes.STORAGE_BIN_DETAIL_FETCH_STORAGE_TYPE_OPTION_LOADING]: storageBinDetailFetchStorageTypeOptionLoading,
  [StorageBinDetailTypes.STORAGE_BIN_DETAIL_FETCH_STORAGE_TYPE_OPTION_SUCCESS]: storageBinDetailFetchStorageTypeOptionSuccess,

  [StorageBinDetailTypes.STORAGE_BIN_DETAIL_FETCH_LOCATION_OPTION_LOADING]: storageBinDetailFetchLocationOptionLoading,
  [StorageBinDetailTypes.STORAGE_BIN_DETAIL_FETCH_LOCATION_OPTION_SUCCESS]: storageBinDetailFetchLocationOptionSuccess
});
