import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  siteList01ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  siteList01NewResource: null,
  siteList01GoToResource: ['resId'],
  siteList01FetchSiteList01: ['currentPage', 'sorts', 'filters', 'pageSize'],
  siteList01FetchSiteList01Loading: ['boolean'],
  siteList01FetchSiteList01Success: ['documents', 'currentPage', 'lastPage', 'total', 'pageSize']
});

export const SiteList01Types = Types;
export default Creators;
