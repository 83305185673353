import { put, call, select, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import ApiService from '../Services/ApiService';
import AppActions from '../Stores/App/Actions';
import CycleCountAnalysisReportActions, {
  CycleCountAnalysisReportTypes
} from '../Stores/CycleCountAnalysisReport/Actions';

const getAppStore = state => state.app;

export function* cycleCountAnalysisReportInitCycleCountAnalysis() {
  try {
    yield put(CycleCountAnalysisReportActions.cycleCountAnalysisReportReportLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `whseReport/initCycleCountAnalysis/${app.curSiteFlowId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(
        CycleCountAnalysisReportActions.cycleCountAnalysisReportInitCycleCountAnalysisSuccess(
          result.data
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(CycleCountAnalysisReportActions.cycleCountAnalysisReportReportLoading(false));
  }
}

export function* cycleCountAnalysisReportCycleCountAnalysis({ formikBag, criteria }) {
  try {
    formikBag.setSubmitting(true);
    yield put(CycleCountAnalysisReportActions.cycleCountAnalysisReportReportLoading(true));

    const objCriteria = {};
    if ('start_date' in criteria) {
      objCriteria.start_date = criteria.start_date;
    }
    if ('end_date' in criteria) {
      objCriteria.end_date = criteria.end_date;
    }
    if ('item_ids_select2' in criteria) {
      const values = criteria.item_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.item_ids = values.map(entry => {
          return entry.value;
        });
      }
    }
    if ('item_group_01_ids_select2' in criteria) {
      const values = criteria.item_group_01_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.item_group_01_ids = values.map(entry => {
          return entry.value;
        });
      }
    }
    if ('item_group_02_ids_select2' in criteria) {
      const values = criteria.item_group_02_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.item_group_02_ids = values.map(entry => {
          return entry.value;
        });
      }
    }
    if ('item_group_03_ids_select2' in criteria) {
      const values = criteria.item_group_03_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.item_group_03_ids = values.map(entry => {
          return entry.value;
        });
      }
    }
    if ('storage_bin_ids_select2' in criteria) {
      const values = criteria.storage_bin_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.storage_bin_ids = values.map(entry => {
          return entry.value;
        });
      }
    }
    if ('storage_row_ids_select2' in criteria) {
      const values = criteria.storage_row_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.storage_row_ids = values.map(entry => {
          return entry.value;
        });
      }
    }
    if ('storage_bay_ids_select2' in criteria) {
      const values = criteria.storage_bay_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.storage_bay_ids = values.map(entry => {
          return entry.value;
        });
      }
    }
    if ('location_ids_select2' in criteria) {
      const values = criteria.location_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.location_ids = values.map(entry => {
          return entry.value;
        });
      }
    }

    let columns = [];
    if (criteria.columns.target.length > 0) {
      columns = criteria.columns.target.map(entry => {
        return entry.column;
      });
    }

    const app = yield select(getAppStore);
    const postData = {
      sorts: [],
      criteria: objCriteria,
      columns
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `whseReport/cycleCountAnalysis/${app.curSiteFlowId}`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      yield put(
        CycleCountAnalysisReportActions.cycleCountAnalysisReportCycleCountAnalysisSuccess(
          criteria,
          result.data.data
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
    yield put(CycleCountAnalysisReportActions.cycleCountAnalysisReportReportLoading(false));
  }
}

export function* cycleCountAnalysisReportFetchItemOptions({ search }) {
  try {
    yield put(CycleCountAnalysisReportActions.cycleCountAnalysisReportFetchItemOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `item/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(
        CycleCountAnalysisReportActions.cycleCountAnalysisReportFetchItemOptionSuccess(options)
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(
      CycleCountAnalysisReportActions.cycleCountAnalysisReportFetchItemOptionLoading(false)
    );
  }
}

export function* cycleCountAnalysisReportFetchItemGroup01Options({ search }) {
  try {
    yield put(
      CycleCountAnalysisReportActions.cycleCountAnalysisReportFetchItemGroup01OptionLoading(true)
    );

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `itemGroup01/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(
        CycleCountAnalysisReportActions.cycleCountAnalysisReportFetchItemGroup01OptionSuccess(
          options
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(
      CycleCountAnalysisReportActions.cycleCountAnalysisReportFetchItemGroup01OptionLoading(false)
    );
  }
}

export function* cycleCountAnalysisReportFetchItemGroup02Options({ search }) {
  try {
    yield put(
      CycleCountAnalysisReportActions.cycleCountAnalysisReportFetchItemGroup02OptionLoading(true)
    );

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `itemGroup02/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(
        CycleCountAnalysisReportActions.cycleCountAnalysisReportFetchItemGroup02OptionSuccess(
          options
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(
      CycleCountAnalysisReportActions.cycleCountAnalysisReportFetchItemGroup02OptionLoading(false)
    );
  }
}

export function* cycleCountAnalysisReportFetchItemGroup03Options({ search }) {
  try {
    yield put(
      CycleCountAnalysisReportActions.cycleCountAnalysisReportFetchItemGroup03OptionLoading(true)
    );

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `itemGroup03/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(
        CycleCountAnalysisReportActions.cycleCountAnalysisReportFetchItemGroup03OptionSuccess(
          options
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(
      CycleCountAnalysisReportActions.cycleCountAnalysisReportFetchItemGroup03OptionLoading(false)
    );
  }
}

export function* cycleCountAnalysisReportFetchStorageBinOptions({ search }) {
  try {
    yield put(
      CycleCountAnalysisReportActions.cycleCountAnalysisReportFetchStorageBinOptionLoading(true)
    );

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId: app.curSiteFlowId,
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `storageBin/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(
        CycleCountAnalysisReportActions.cycleCountAnalysisReportFetchStorageBinOptionSuccess(
          options
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(
      CycleCountAnalysisReportActions.cycleCountAnalysisReportFetchStorageBinOptionLoading(false)
    );
  }
}

export function* cycleCountAnalysisReportFetchStorageRowOptions({ search }) {
  try {
    yield put(
      CycleCountAnalysisReportActions.cycleCountAnalysisReportFetchStorageRowOptionLoading(true)
    );

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId: app.curSiteFlowId,
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `storageRow/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(
        CycleCountAnalysisReportActions.cycleCountAnalysisReportFetchStorageRowOptionSuccess(
          options
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(
      CycleCountAnalysisReportActions.cycleCountAnalysisReportFetchStorageRowOptionLoading(false)
    );
  }
}

export function* cycleCountAnalysisReportFetchStorageBayOptions({ search }) {
  try {
    yield put(
      CycleCountAnalysisReportActions.cycleCountAnalysisReportFetchStorageBayOptionLoading(true)
    );

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId: app.curSiteFlowId,
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `storageBay/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(
        CycleCountAnalysisReportActions.cycleCountAnalysisReportFetchStorageBayOptionSuccess(
          options
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(
      CycleCountAnalysisReportActions.cycleCountAnalysisReportFetchStorageBayOptionLoading(false)
    );
  }
}

export function* cycleCountAnalysisReportFetchLocationOptions({ search }) {
  try {
    yield put(
      CycleCountAnalysisReportActions.cycleCountAnalysisReportFetchLocationOptionLoading(true)
    );

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId: app.curSiteFlowId,
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `location/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(
        CycleCountAnalysisReportActions.cycleCountAnalysisReportFetchLocationOptionSuccess(options)
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(
      CycleCountAnalysisReportActions.cycleCountAnalysisReportFetchLocationOptionLoading(false)
    );
  }
}

export const saga = [
  takeLatest(
    CycleCountAnalysisReportTypes.CYCLE_COUNT_ANALYSIS_REPORT_INIT_CYCLE_COUNT_ANALYSIS,
    cycleCountAnalysisReportInitCycleCountAnalysis
  ),
  takeLatest(
    CycleCountAnalysisReportTypes.CYCLE_COUNT_ANALYSIS_REPORT_CYCLE_COUNT_ANALYSIS,
    cycleCountAnalysisReportCycleCountAnalysis
  ),
  takeLatest(
    CycleCountAnalysisReportTypes.CYCLE_COUNT_ANALYSIS_REPORT_FETCH_ITEM_OPTIONS,
    cycleCountAnalysisReportFetchItemOptions
  ),
  takeLatest(
    CycleCountAnalysisReportTypes.CYCLE_COUNT_ANALYSIS_REPORT_FETCH_ITEM_GROUP01_OPTIONS,
    cycleCountAnalysisReportFetchItemGroup01Options
  ),
  takeLatest(
    CycleCountAnalysisReportTypes.CYCLE_COUNT_ANALYSIS_REPORT_FETCH_ITEM_GROUP02_OPTIONS,
    cycleCountAnalysisReportFetchItemGroup02Options
  ),
  takeLatest(
    CycleCountAnalysisReportTypes.CYCLE_COUNT_ANALYSIS_REPORT_FETCH_ITEM_GROUP03_OPTIONS,
    cycleCountAnalysisReportFetchItemGroup03Options
  ),
  takeLatest(
    CycleCountAnalysisReportTypes.CYCLE_COUNT_ANALYSIS_REPORT_FETCH_STORAGE_BIN_OPTIONS,
    cycleCountAnalysisReportFetchStorageBinOptions
  ),
  takeLatest(
    CycleCountAnalysisReportTypes.CYCLE_COUNT_ANALYSIS_REPORT_FETCH_STORAGE_ROW_OPTIONS,
    cycleCountAnalysisReportFetchStorageRowOptions
  ),
  takeLatest(
    CycleCountAnalysisReportTypes.CYCLE_COUNT_ANALYSIS_REPORT_FETCH_STORAGE_BAY_OPTIONS,
    cycleCountAnalysisReportFetchStorageBayOptions
  ),
  takeLatest(
    CycleCountAnalysisReportTypes.CYCLE_COUNT_ANALYSIS_REPORT_FETCH_LOCATION_OPTIONS,
    cycleCountAnalysisReportFetchLocationOptions
  )
];
