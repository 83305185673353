import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import treeChanges from 'tree-changes';

import { Select } from 'antd';

import AppActions from '../../Stores/App/Actions';

const { Option } = Select;

class DivisionSelect extends React.PureComponent {
  constructor() {
    super();

    this.handleSelectChange = this.handleSelectChange.bind(this);
  }

  componentDidMount() {
    const { curSiteFlowId, fetchDivisionOptions } = this.props;

    if (curSiteFlowId > 0) {
      fetchDivisionOptions(curSiteFlowId);
    }
  }

  componentDidUpdate(prevProps) {
    const { curSiteFlowId, fetchDivisionOptions } = this.props;

    const { changed } = treeChanges(prevProps, this.props);

    if (changed('curSiteFlowId')) {
      if (curSiteFlowId > 0) {
        fetchDivisionOptions(curSiteFlowId);
      }
    }
  }

  componentWillUnmount() {}

  handleSelectChange(value) {
    const { changeDivision } = this.props;

    changeDivision(value);
  }

  render() {
    const { curDivisionId, divisionOptions, divisionIsLoading } = this.props;

    const processedOptions = divisionOptions.map(d => (
      <Option value={d.value} key={d.value}>{`${d.label}`}</Option>
    ));

    return (
      <Select
        mode="default"
        showSearch={false}
        style={{ width: 130 }}
        value={curDivisionId}
        loading={divisionIsLoading}
        onChange={this.handleSelectChange}
      >
        {processedOptions}
      </Select>
    );
  }
}

DivisionSelect.propTypes = {
  fetchDivisionOptions: PropTypes.func,
  changeDivision: PropTypes.func,
  divisionIsLoading: PropTypes.bool,
  divisionOptions: PropTypes.arrayOf(PropTypes.object),

  curSiteFlowId: PropTypes.number,
  curDivisionId: PropTypes.number
};

DivisionSelect.defaultProps = {
  fetchDivisionOptions() {},
  changeDivision() {},
  divisionIsLoading: false,
  divisionOptions: [],

  curSiteFlowId: 0,
  curDivisionId: 0
};

const mapStateToProps = state => ({
  curSiteFlowId: state.app.curSiteFlowId,
  curDivisionId: state.app.curDivisionId,

  divisionIsLoading: state.app.divisionIsLoading,
  divisionOptions: state.app.divisionOptions
});

const mapDispatchToProps = dispatch => ({
  fetchDivisionOptions: siteFlowId => dispatch(AppActions.appFetchDivisionOptions(siteFlowId)),
  changeDivision: divisionId => dispatch(AppActions.appChangeDivision(divisionId))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(DivisionSelect));
