import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  pickList0101ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  pickList0101GoToDocument: ['hdrId'],
  pickList0101FetchPickList0101: ['currentPage', 'sorts', 'filters', 'pageSize'],
  pickList0101FetchPickList0101Loading: ['boolean'],
  pickList0101FetchPickList0101Success: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],
  pickList0101AddSelectedDocuments: ['selectedDocuments'],
  pickList0101RemoveSelectedDocuments: ['selectedDocuments'],
  pickList0101SetWorkspaceVisible: ['boolean'],

  pickList0101PrintPickList0101: ['hdrIds'],
  pickList0101PrintPickList0101Loading: ['boolean']
});

export const PickList0101Types = Types;
export default Creators;
