import { put, call, select, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import { replace } from 'connected-react-router';
import ApiService from '../Services/ApiService';
import UtilService from '../Services/UtilService';
import AppActions from '../Stores/App/Actions';
import LoadListDetailActions, { LoadListDetailTypes } from '../Stores/LoadListDetail/Actions';

const getAppStore = state => state.app;

const getLoadListDetailStore = state => state.loadListDetail;

export function* loadListDetailInitHeader() {
  try {
    yield put(LoadListDetailActions.loadListDetailShowDocumentLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `loadList/initHeader/${app.curSiteFlowId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(LoadListDetailActions.loadListDetailShowHeaderSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(LoadListDetailActions.loadListDetailShowDocumentLoading(false));
  }
}

export function* loadListDetailShowHeader({ hdrId }) {
  try {
    yield put(LoadListDetailActions.loadListDetailShowDocumentLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `loadList/showHeader/${hdrId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(LoadListDetailActions.loadListDetailShowHeaderSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(LoadListDetailActions.loadListDetailShowDocumentLoading(false));
  }
}

export function* loadListDetailTransitionToStatus({ formikBag, hdrId, docStatus }) {
  formikBag.setSubmitting(true);
  yield put(LoadListDetailActions.loadListDetailShowDocumentLoading(true));
  try {
    const app = yield select(getAppStore);

    const postData = {
      hdrId,
      docStatus
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      'loadList/transitionToStatus',
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      const loadListDetail = yield select(getLoadListDetailStore);

      const { outbOrdHdrs: oldOutbOrdHdrs, inbOrdHdrs: oldInbOrdHdrs } = loadListDetail;

      const {
        document_header: retLoadListHdr,
        outb_ord_hdrs: retOutbOrdHdrs,
        deleted_outb_ord_hdrs: retDeletedOutbOrdHdrs,
        inb_ord_hdrs: retInbOrdHdrs,
        deleted_inb_ord_hdrs: retDeletedInbOrdHdrs
      } = result.data;

      const processedOutbOrd = UtilService.processDetails(
        oldOutbOrdHdrs,
        retOutbOrdHdrs,
        retDeletedOutbOrdHdrs
      );
      const newOutbOrdHdrs = processedOutbOrd.details;

      const processedInbOrd = UtilService.processDetails(
        oldInbOrdHdrs,
        retInbOrdHdrs,
        retDeletedInbOrdHdrs
      );
      const newInbOrdHdrs = processedInbOrd.details;

      yield put(
        LoadListDetailActions.loadListDetailUpdateDocumentSuccess(
          retLoadListHdr,
          newOutbOrdHdrs,
          newInbOrdHdrs
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
    yield put(LoadListDetailActions.loadListDetailShowDocumentLoading(false));
  }
}

export function* loadListDetailUpdateHeader({ formikBag, documentHeader }) {
  formikBag.setSubmitting(true);
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: documentHeader
    };

    const result = yield call(
      ApiService.putApi, // function
      app.apiUrl,
      'loadList/updateHeader',
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      const loadListDetail = yield select(getLoadListDetailStore);

      const { outbOrdHdrs: oldOutbOrdHdrs, inbOrdHdrs: oldInbOrdHdrs } = loadListDetail;
      const {
        document_header: retLoadListHdr,
        outb_ord_hdrs: retOutbOrdHdrs,
        deleted_outb_ord_hdrs: retDeletedOutbOrdHdrs,
        inb_ord_hdrs: retInbOrdHdrs,
        deleted_inb_ord_hdrs: retDeletedInbOrdHdrs
      } = result.data;

      const processedOutbOrd = UtilService.processDetails(
        oldOutbOrdHdrs,
        retOutbOrdHdrs,
        retDeletedOutbOrdHdrs
      );
      const newOutbOrdHdrs = processedOutbOrd.details;

      const processedInbOrd = UtilService.processDetails(
        oldInbOrdHdrs,
        retInbOrdHdrs,
        retDeletedInbOrdHdrs
      );
      const newInbOrdHdrs = processedInbOrd.details;

      yield put(
        LoadListDetailActions.loadListDetailUpdateDocumentSuccess(
          retLoadListHdr,
          newOutbOrdHdrs,
          newInbOrdHdrs
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* loadListDetailShowOutbOrdHdrs({ hdrId }) {
  try {
    yield put(LoadListDetailActions.loadListDetailShowDocumentLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `loadList/showOutbOrdHdrs/${hdrId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(LoadListDetailActions.loadListDetailShowOutbOrdHdrsSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(LoadListDetailActions.loadListDetailShowDocumentLoading(false));
  }
}

export function* loadListDetailShowInbOrdHdrs({ hdrId }) {
  try {
    yield put(LoadListDetailActions.loadListDetailShowDocumentLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `loadList/showInbOrdHdrs/${hdrId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(LoadListDetailActions.loadListDetailShowInbOrdHdrsSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(LoadListDetailActions.loadListDetailShowDocumentLoading(false));
  }
}

export function* loadListDetailCreateHeader({ formikBag, documentHeader }) {
  formikBag.setSubmitting(true);
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: documentHeader
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      'loadList/createHeader',
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      yield put(replace(`${app.appPath}/loadListDetail/update/${result.data}`));
      yield put(LoadListDetailActions.loadListDetailSetHdrId(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* loadListDetailScanBarcode({ hdrId, value }) {
  try {
    yield put(LoadListDetailActions.loadListDetailFetchLoadListDetailLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId: app.curSiteFlowId,
      hdrId,
      value
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `loadList/updateDetailsByBarcode`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const loadListDetail = yield select(getLoadListDetailStore);

      const { outbOrdHdrs: oldOutbOrdHdrs, inbOrdHdrs: oldInbOrdHdrs } = loadListDetail;
      const {
        document_header: retLoadListHdr,
        outb_ord_hdrs: retOutbOrdHdrs,
        deleted_outb_ord_hdrs: retDeletedOutbOrdHdrs,
        inb_ord_hdrs: retInbOrdHdrs,
        deleted_inb_ord_hdrs: retDeletedInbOrdHdrs
      } = result.data;

      const processedOutbOrd = UtilService.processDetails(
        oldOutbOrdHdrs,
        retOutbOrdHdrs,
        retDeletedOutbOrdHdrs
      );
      const newOutbOrdHdrs = processedOutbOrd.details;

      const processedInbOrd = UtilService.processDetails(
        oldInbOrdHdrs,
        retInbOrdHdrs,
        retDeletedInbOrdHdrs
      );
      const newInbOrdHdrs = processedInbOrd.details;

      yield put(
        LoadListDetailActions.loadListDetailUpdateDocumentSuccess(
          retLoadListHdr,
          newOutbOrdHdrs,
          newInbOrdHdrs
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(LoadListDetailActions.loadListDetailFetchLoadListDetailLoading(false));
  }
}

export function* loadListDetailSearchDocument({ search }) {
  if (search.length < 5) {
    return;
  }
  try {
    yield put(LoadListDetailActions.loadListDetailSearchDocumentLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId: app.curSiteFlowId,
      search
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `loadList/searchDocument`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: `${d.res_type}${d.id}`,
        label: `${d.doc_code} ${d.doc_date} ${d.ref_code_01}`,
        docCode: d.doc_code,
        docDate: d.doc_date,
        refCode01: d.ref_code_01
      }));

      yield put(LoadListDetailActions.loadListDetailSearchDocumentSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(LoadListDetailActions.loadListDetailSearchDocumentLoading(false));
  }
}

export function* loadListDetailUpOutbOrd({ hdrId, outbOrdHdr }) {
  try {
    yield put(LoadListDetailActions.loadListDetailShowDocumentLoading(true));

    const app = yield select(getAppStore);

    const loadListDetail = yield select(getLoadListDetailStore);
    const { outbOrdHdrs: oldOutbOrdHdrs, inbOrdHdrs: oldInbOrdHdrs } = loadListDetail;

    const oldIndex = oldOutbOrdHdrs.indexOf(outbOrdHdr);
    const newIndex = oldIndex - 1;
    if (newIndex >= 0) {
      const postData = {
        outbOrdHdrs: [{ outbOrdHdrId: outbOrdHdr.id, lineNo: newIndex + 1, direction: -1 }]
      };

      const result = yield call(
        ApiService.postApi, // function
        app.apiUrl,
        `loadList/moveOutbOrd/${hdrId}`,
        app.token,
        postData
      );

      if (result.isSuccess === true) {
        const { document_header: retLoadListHdr, outb_ord_hdrs: retOutbOrdHdrs } = result.data;

        const processedOutbOrd = UtilService.processDetails(oldOutbOrdHdrs, retOutbOrdHdrs, []);
        const newOutbOrdHdrs = processedOutbOrd.details;
        newOutbOrdHdrs.sort((a, b) => (a.line_no < b.line_no ? -1 : 1));

        yield put(
          LoadListDetailActions.loadListDetailUpdateDocumentSuccess(
            retLoadListHdr,
            newOutbOrdHdrs,
            oldInbOrdHdrs
          )
        );
      } else if (result.isTokenExpired === true) {
        yield put(AppActions.appTokenExpired(result.message));
      } else if (result.isPasswordExpired === true) {
        yield put(AppActions.appPasswordExpired(result.message));
      } else {
        yield call(notification.error, {
          message: result.message,
          duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
        });
      }
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(LoadListDetailActions.loadListDetailShowDocumentLoading(false));
  }
}

export function* loadListDetailDownOutbOrd({ hdrId, outbOrdHdr }) {
  try {
    yield put(LoadListDetailActions.loadListDetailShowDocumentLoading(true));

    const app = yield select(getAppStore);

    const loadListDetail = yield select(getLoadListDetailStore);
    const { outbOrdHdrs: oldOutbOrdHdrs, inbOrdHdrs: oldInbOrdHdrs } = loadListDetail;

    const oldIndex = oldOutbOrdHdrs.indexOf(outbOrdHdr);
    const newIndex = oldIndex + 1;
    if (newIndex < oldOutbOrdHdrs.length) {
      const postData = {
        outbOrdHdrs: [{ outbOrdHdrId: outbOrdHdr.id, lineNo: newIndex + 1, direction: 1 }]
      };

      const result = yield call(
        ApiService.postApi, // function
        app.apiUrl,
        `loadList/moveOutbOrd/${hdrId}`,
        app.token,
        postData
      );

      if (result.isSuccess === true) {
        const { document_header: retLoadListHdr, outb_ord_hdrs: retOutbOrdHdrs } = result.data;

        const processedOutbOrd = UtilService.processDetails(oldOutbOrdHdrs, retOutbOrdHdrs, []);
        const newOutbOrdHdrs = processedOutbOrd.details;
        newOutbOrdHdrs.sort((a, b) => (a.line_no < b.line_no ? -1 : 1));

        yield put(
          LoadListDetailActions.loadListDetailUpdateDocumentSuccess(
            retLoadListHdr,
            newOutbOrdHdrs,
            oldInbOrdHdrs
          )
        );
      } else if (result.isTokenExpired === true) {
        yield put(AppActions.appTokenExpired(result.message));
      } else if (result.isPasswordExpired === true) {
        yield put(AppActions.appPasswordExpired(result.message));
      } else {
        yield call(notification.error, {
          message: result.message,
          duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
        });
      }
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(LoadListDetailActions.loadListDetailShowDocumentLoading(false));
  }
}

export function* loadListDetailUpInbOrd({ hdrId, inbOrdHdr }) {
  try {
    yield put(LoadListDetailActions.loadListDetailShowDocumentLoading(true));

    const app = yield select(getAppStore);

    const loadListDetail = yield select(getLoadListDetailStore);
    const { outbOrdHdrs: oldOutbOrdHdrs, inbOrdHdrs: oldInbOrdHdrs } = loadListDetail;

    const oldIndex = oldInbOrdHdrs.indexOf(inbOrdHdr);
    const newIndex = oldIndex - 1;
    if (newIndex >= 0) {
      const postData = {
        inbOrdHdrs: [{ inbOrdHdrId: inbOrdHdr.id, lineNo: newIndex + 1, direction: -1 }]
      };

      const result = yield call(
        ApiService.postApi, // function
        app.apiUrl,
        `loadList/moveInbOrd/${hdrId}`,
        app.token,
        postData
      );

      if (result.isSuccess === true) {
        const { document_header: retLoadListHdr, inb_ord_hdrs: retInbOrdHdrs } = result.data;

        const processedInbOrd = UtilService.processDetails(oldInbOrdHdrs, retInbOrdHdrs, []);
        const newInbOrdHdrs = processedInbOrd.details;
        newInbOrdHdrs.sort((a, b) => (a.line_no < b.line_no ? -1 : 1));

        yield put(
          LoadListDetailActions.loadListDetailUpdateDocumentSuccess(
            retLoadListHdr,
            oldOutbOrdHdrs,
            newInbOrdHdrs
          )
        );
      } else if (result.isTokenExpired === true) {
        yield put(AppActions.appTokenExpired(result.message));
      } else if (result.isPasswordExpired === true) {
        yield put(AppActions.appPasswordExpired(result.message));
      } else {
        yield call(notification.error, {
          message: result.message,
          duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
        });
      }
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(LoadListDetailActions.loadListDetailShowDocumentLoading(false));
  }
}

export function* loadListDetailDownInbOrd({ hdrId, inbOrdHdr }) {
  try {
    yield put(LoadListDetailActions.loadListDetailShowDocumentLoading(true));

    const app = yield select(getAppStore);

    const loadListDetail = yield select(getLoadListDetailStore);
    const { outbOrdHdrs: oldOutbOrdHdrs, inbOrdHdrs: oldInbOrdHdrs } = loadListDetail;

    const oldIndex = oldInbOrdHdrs.indexOf(inbOrdHdr);
    const newIndex = oldIndex + 1;
    if (newIndex < oldInbOrdHdrs.length) {
      const postData = {
        inbOrdHdrs: [{ inbOrdHdrId: inbOrdHdr.id, lineNo: newIndex + 1, direction: 1 }]
      };

      const result = yield call(
        ApiService.postApi, // function
        app.apiUrl,
        `loadList/moveInbOrd/${hdrId}`,
        app.token,
        postData
      );

      if (result.isSuccess === true) {
        const { document_header: retLoadListHdr, inb_ord_hdrs: retInbOrdHdrs } = result.data;

        const processedInbOrd = UtilService.processDetails(oldInbOrdHdrs, retInbOrdHdrs, []);
        const newInbOrdHdrs = processedInbOrd.details;
        newInbOrdHdrs.sort((a, b) => (a.line_no < b.line_no ? -1 : 1));

        yield put(
          LoadListDetailActions.loadListDetailUpdateDocumentSuccess(
            retLoadListHdr,
            oldOutbOrdHdrs,
            newInbOrdHdrs
          )
        );
      } else if (result.isTokenExpired === true) {
        yield put(AppActions.appTokenExpired(result.message));
      } else if (result.isPasswordExpired === true) {
        yield put(AppActions.appPasswordExpired(result.message));
      } else {
        yield call(notification.error, {
          message: result.message,
          duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
        });
      }
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(LoadListDetailActions.loadListDetailShowDocumentLoading(false));
  }
}

export function* loadListDetailFetchTransportOptions({ search }) {
  try {
    yield put(LoadListDetailActions.loadListDetailFetchTransportOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId: app.curSiteFlowId,
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `transport/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({ value: d.id, label: `${d.code}` }));

      yield put(LoadListDetailActions.loadListDetailFetchTransportOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(LoadListDetailActions.loadListDetailFetchTransportOptionLoading(false));
  }
}

export function* loadListDetailFetchDriver01Options({ search }) {
  try {
    yield put(LoadListDetailActions.loadListDetailFetchDriver01OptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId: app.curSiteFlowId,
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `user/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({ value: d.id, label: `${d.username}` }));

      yield put(LoadListDetailActions.loadListDetailFetchDriver01OptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(LoadListDetailActions.loadListDetailFetchDriver01OptionLoading(false));
  }
}

export function* loadListDetailFetchDeliveryMan01Options({ search }) {
  try {
    yield put(LoadListDetailActions.loadListDetailFetchDeliveryMan01OptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId: app.curSiteFlowId,
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `user/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({ value: d.id, label: `${d.username}` }));

      yield put(LoadListDetailActions.loadListDetailFetchDeliveryMan01OptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(LoadListDetailActions.loadListDetailFetchDeliveryMan01OptionLoading(false));
  }
}

export function* loadListDetailFetchDeliveryMan02Options({ search }) {
  try {
    yield put(LoadListDetailActions.loadListDetailFetchDeliveryMan02OptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId: app.curSiteFlowId,
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `user/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({ value: d.id, label: `${d.username}` }));

      yield put(LoadListDetailActions.loadListDetailFetchDeliveryMan02OptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(LoadListDetailActions.loadListDetailFetchDeliveryMan02OptionLoading(false));
  }
}

export function* loadListDetailDeleteDetailsByOrdHdrIds({ hdrId, outbOrdHdrIds, inbOrdHdrIds }) {
  try {
    yield put(LoadListDetailActions.loadListDetailFetchLoadListDetailLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      hdrId,
      outbOrdHdrIds,
      inbOrdHdrIds
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `loadList/deleteDetailsByOrdHdrIds`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const loadListDetail = yield select(getLoadListDetailStore);

      const { outbOrdHdrs: oldOutbOrdHdrs, inbOrdHdrs: oldInbOrdHdrs } = loadListDetail;
      const {
        document_header: retLoadListHdr,
        outb_ord_hdrs: retOutbOrdHdrs,
        deleted_outb_ord_hdrs: retDeletedOutbOrdHdrs,
        inb_ord_hdrs: retInbOrdHdrs,
        deleted_inb_ord_hdrs: retDeletedInbOrdHdrs
      } = result.data;

      const processedOutbOrd = UtilService.processDetails(
        oldOutbOrdHdrs,
        retOutbOrdHdrs,
        retDeletedOutbOrdHdrs
      );
      const newOutbOrdHdrs = processedOutbOrd.details;

      const processedInbOrd = UtilService.processDetails(
        oldInbOrdHdrs,
        retInbOrdHdrs,
        retDeletedInbOrdHdrs
      );
      const newInbOrdHdrs = processedInbOrd.details;

      yield put(
        LoadListDetailActions.loadListDetailUpdateDocumentSuccess(
          retLoadListHdr,
          newOutbOrdHdrs,
          newInbOrdHdrs
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(LoadListDetailActions.loadListDetailFetchLoadListDetailLoading(false));
  }
}

export const saga = [
  takeLatest(LoadListDetailTypes.LOAD_LIST_DETAIL_INIT_HEADER, loadListDetailInitHeader),
  takeLatest(LoadListDetailTypes.LOAD_LIST_DETAIL_SHOW_HEADER, loadListDetailShowHeader),

  takeLatest(LoadListDetailTypes.LOAD_LIST_DETAIL_UPDATE_HEADER, loadListDetailUpdateHeader),
  takeLatest(LoadListDetailTypes.LOAD_LIST_DETAIL_CREATE_HEADER, loadListDetailCreateHeader),

  takeLatest(
    LoadListDetailTypes.LOAD_LIST_DETAIL_TRANSITION_TO_STATUS,
    loadListDetailTransitionToStatus
  ),

  takeLatest(
    LoadListDetailTypes.LOAD_LIST_DETAIL_SHOW_OUTB_ORD_HDRS,
    loadListDetailShowOutbOrdHdrs
  ),
  takeLatest(LoadListDetailTypes.LOAD_LIST_DETAIL_SHOW_INB_ORD_HDRS, loadListDetailShowInbOrdHdrs),

  takeLatest(LoadListDetailTypes.LOAD_LIST_DETAIL_SCAN_BARCODE, loadListDetailScanBarcode),
  takeLatest(LoadListDetailTypes.LOAD_LIST_DETAIL_SEARCH_DOCUMENT, loadListDetailSearchDocument),

  takeLatest(LoadListDetailTypes.LOAD_LIST_DETAIL_UP_OUTB_ORD, loadListDetailUpOutbOrd),
  takeLatest(LoadListDetailTypes.LOAD_LIST_DETAIL_DOWN_OUTB_ORD, loadListDetailDownOutbOrd),
  takeLatest(LoadListDetailTypes.LOAD_LIST_DETAIL_UP_INB_ORD, loadListDetailUpInbOrd),
  takeLatest(LoadListDetailTypes.LOAD_LIST_DETAIL_DOWN_INB_ORD, loadListDetailDownInbOrd),

  takeLatest(
    LoadListDetailTypes.LOAD_LIST_DETAIL_FETCH_TRANSPORT_OPTIONS,
    loadListDetailFetchTransportOptions
  ),
  takeLatest(
    LoadListDetailTypes.LOAD_LIST_DETAIL_FETCH_DRIVER01_OPTIONS,
    loadListDetailFetchDriver01Options
  ),
  takeLatest(
    LoadListDetailTypes.LOAD_LIST_DETAIL_FETCH_DELIVERY_MAN01_OPTIONS,
    loadListDetailFetchDeliveryMan01Options
  ),
  takeLatest(
    LoadListDetailTypes.LOAD_LIST_DETAIL_FETCH_DELIVERY_MAN02_OPTIONS,
    loadListDetailFetchDeliveryMan02Options
  ),

  takeLatest(
    LoadListDetailTypes.LOAD_LIST_DETAIL_DELETE_DETAILS_BY_ORD_HDR_IDS,
    loadListDetailDeleteDetailsByOrdHdrIds
  )
];
