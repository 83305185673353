import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  putAwayRtnDetailBoardResetWipGdsRcptTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  putAwayRtnDetailBoardFetchWipGdsRcpt: ['currentPage', 'sorts', 'filters', 'pageSize'],
  putAwayRtnDetailBoardFetchWipGdsRcptLoading: ['boolean'],
  putAwayRtnDetailBoardFetchWipGdsRcptSuccess: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],

  putAwayRtnDetailBoardResetWipPutAwayTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  putAwayRtnDetailBoardFetchWipPutAway: ['currentPage', 'sorts', 'filters', 'pageSize'],
  putAwayRtnDetailBoardFetchWipPutAwayLoading: ['boolean'],
  putAwayRtnDetailBoardFetchWipPutAwaySuccess: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],

  putAwayRtnDetailBoardResetWipWhseJobTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  putAwayRtnDetailBoardFetchWipWhseJob: ['currentPage', 'sorts', 'filters', 'pageSize'],
  putAwayRtnDetailBoardFetchWipWhseJobLoading: ['boolean'],
  putAwayRtnDetailBoardFetchWipWhseJobSuccess: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],

  putAwayRtnDetailBoardResetCompletePutAwayTimestamp: [
    'currentPage',
    'sorts',
    'filters',
    'pageSize'
  ],
  putAwayRtnDetailBoardFetchCompletePutAway: ['currentPage', 'sorts', 'filters', 'pageSize'],
  putAwayRtnDetailBoardFetchCompletePutAwayLoading: ['boolean'],
  putAwayRtnDetailBoardFetchCompletePutAwaySuccess: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],

  putAwayRtnDetailBoardSetWipGdsRcptDialogVisible: ['boolean'],
  putAwayRtnDetailBoardShowWipGdsRcptSummary: ['docId'],
  putAwayRtnDetailBoardShowWipGdsRcptSummaryLoading: ['boolean'],
  putAwayRtnDetailBoardShowWipGdsRcptSummarySuccess: ['document'],

  putAwayRtnDetailBoardSetWipPutAwayDialogVisible: ['boolean'],
  putAwayRtnDetailBoardShowWipPutAwaySummary: ['docId'],
  putAwayRtnDetailBoardShowWipPutAwaySummaryLoading: ['boolean'],
  putAwayRtnDetailBoardShowWipPutAwaySummarySuccess: ['document'],

  putAwayRtnDetailBoardSetWipWhseJobDialogVisible: ['boolean'],
  putAwayRtnDetailBoardShowWipWhseJobSummary: ['docId'],
  putAwayRtnDetailBoardShowWipWhseJobSummaryLoading: ['boolean'],
  putAwayRtnDetailBoardShowWipWhseJobSummarySuccess: ['document'],

  putAwayRtnDetailBoardSetCompletePutAwayDialogVisible: ['boolean'],
  putAwayRtnDetailBoardShowCompletePutAwaySummary: ['docId'],
  putAwayRtnDetailBoardShowCompletePutAwaySummaryLoading: ['boolean'],
  putAwayRtnDetailBoardShowCompletePutAwaySummarySuccess: ['document']
});

export const PutAwayRtnDetailBoardTypes = Types;
export default Creators;
