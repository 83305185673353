import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  slsOrdFServ02ShowSyncSetting: null,
  slsOrdFServ02ShowSyncSettingLoading: ['boolean'],
  slsOrdFServ02ShowSyncSettingSuccess: ['syncSetting'],
  slsOrdFServ02UpdateSyncSetting: ['formikBag', 'syncSetting'],
  slsOrdFServ02ShowBatchJobStatus: null,
  slsOrdFServ02ShowBatchJobStatusSuccess: ['batchJobStatus'],

  slsOrdFServ02ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  slsOrdFServ02GoToDocument: ['hdrId'],
  slsOrdFServ02FetchSlsOrdFServ02: ['currentPage', 'sorts', 'filters', 'pageSize'],
  slsOrdFServ02FetchSlsOrdFServ02Loading: ['boolean'],
  slsOrdFServ02FetchSlsOrdFServ02Success: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],
  slsOrdFServ02AddSelectedDocuments: ['selectedDocuments'],
  slsOrdFServ02RemoveSelectedDocuments: ['selectedDocuments'],
  slsOrdFServ02SetWorkspaceVisible: ['boolean'],

  slsOrdFServ02Export: ['hdrIds'],
  slsOrdFServ02ExportSuccess: null,
  slsOrdFServ02ExportLoading: ['boolean']
});

export const SlsOrdFServ02Types = Types;
export default Creators;
