/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { StockCardReportTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const stockCardReportReportLoading = (state, { boolean }) => ({
  ...state,
  reportIsLoading: boolean
});

export const stockCardReportInitStockCardSuccess = (state, { criteria }) => ({
  ...state,
  criteria
});

export const stockCardReportStockCardSuccess = (state, { criteria, reportData }) => ({
  ...state,
  criteria,
  reportData
});

export const stockCardReportFetchItemOptionLoading = (state, { boolean }) => ({
  ...state,
  itemIsLoading: boolean
});

export const stockCardReportFetchItemOptionSuccess = (state, { options }) => ({
  ...state,
  itemOptions: options
});

export const stockCardReportFetchItemGroup01OptionLoading = (state, { boolean }) => ({
  ...state,
  itemGroup01IsLoading: boolean
});

export const stockCardReportFetchItemGroup01OptionSuccess = (state, { options }) => ({
  ...state,
  itemGroup01Options: options
});

export const stockCardReportFetchItemGroup02OptionLoading = (state, { boolean }) => ({
  ...state,
  itemGroup02IsLoading: boolean
});

export const stockCardReportFetchItemGroup02OptionSuccess = (state, { options }) => ({
  ...state,
  itemGroup02Options: options
});

export const stockCardReportFetchItemGroup03OptionLoading = (state, { boolean }) => ({
  ...state,
  itemGroup03IsLoading: boolean
});

export const stockCardReportFetchItemGroup03OptionSuccess = (state, { options }) => ({
  ...state,
  itemGroup03Options: options
});

export const stockCardReportFetchStorageBinOptionLoading = (state, { boolean }) => ({
  ...state,
  storageBinIsLoading: boolean
});

export const stockCardReportFetchStorageBinOptionSuccess = (state, { options }) => ({
  ...state,
  storageBinOptions: options
});

export const stockCardReportFetchStorageRowOptionLoading = (state, { boolean }) => ({
  ...state,
  storageRowIsLoading: boolean
});

export const stockCardReportFetchStorageRowOptionSuccess = (state, { options }) => ({
  ...state,
  storageRowOptions: options
});

export const stockCardReportFetchStorageBayOptionLoading = (state, { boolean }) => ({
  ...state,
  storageBayIsLoading: boolean
});

export const stockCardReportFetchStorageBayOptionSuccess = (state, { options }) => ({
  ...state,
  storageBayOptions: options
});

export const stockCardReportFetchLocationOptionLoading = (state, { boolean }) => ({
  ...state,
  locationIsLoading: boolean
});

export const stockCardReportFetchLocationOptionSuccess = (state, { options }) => ({
  ...state,
  locationOptions: options
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [StockCardReportTypes.STOCK_CARD_REPORT_REPORT_LOADING]: stockCardReportReportLoading,
  [StockCardReportTypes.STOCK_CARD_REPORT_INIT_STOCK_CARD_SUCCESS]: stockCardReportInitStockCardSuccess,
  [StockCardReportTypes.STOCK_CARD_REPORT_STOCK_CARD_SUCCESS]: stockCardReportStockCardSuccess,

  [StockCardReportTypes.STOCK_CARD_REPORT_FETCH_ITEM_OPTION_LOADING]: stockCardReportFetchItemOptionLoading,
  [StockCardReportTypes.STOCK_CARD_REPORT_FETCH_ITEM_OPTION_SUCCESS]: stockCardReportFetchItemOptionSuccess,

  [StockCardReportTypes.STOCK_CARD_REPORT_FETCH_ITEM_GROUP01_OPTION_LOADING]: stockCardReportFetchItemGroup01OptionLoading,
  [StockCardReportTypes.STOCK_CARD_REPORT_FETCH_ITEM_GROUP01_OPTION_SUCCESS]: stockCardReportFetchItemGroup01OptionSuccess,

  [StockCardReportTypes.STOCK_CARD_REPORT_FETCH_ITEM_GROUP02_OPTION_LOADING]: stockCardReportFetchItemGroup02OptionLoading,
  [StockCardReportTypes.STOCK_CARD_REPORT_FETCH_ITEM_GROUP02_OPTION_SUCCESS]: stockCardReportFetchItemGroup02OptionSuccess,

  [StockCardReportTypes.STOCK_CARD_REPORT_FETCH_ITEM_GROUP03_OPTION_LOADING]: stockCardReportFetchItemGroup03OptionLoading,
  [StockCardReportTypes.STOCK_CARD_REPORT_FETCH_ITEM_GROUP03_OPTION_SUCCESS]: stockCardReportFetchItemGroup03OptionSuccess,

  [StockCardReportTypes.STOCK_CARD_REPORT_FETCH_STORAGE_BIN_OPTION_LOADING]: stockCardReportFetchStorageBinOptionLoading,
  [StockCardReportTypes.STOCK_CARD_REPORT_FETCH_STORAGE_BIN_OPTION_SUCCESS]: stockCardReportFetchStorageBinOptionSuccess,

  [StockCardReportTypes.STOCK_CARD_REPORT_FETCH_STORAGE_ROW_OPTION_LOADING]: stockCardReportFetchStorageRowOptionLoading,
  [StockCardReportTypes.STOCK_CARD_REPORT_FETCH_STORAGE_ROW_OPTION_SUCCESS]: stockCardReportFetchStorageRowOptionSuccess,

  [StockCardReportTypes.STOCK_CARD_REPORT_FETCH_STORAGE_BAY_OPTION_LOADING]: stockCardReportFetchStorageBayOptionLoading,
  [StockCardReportTypes.STOCK_CARD_REPORT_FETCH_STORAGE_BAY_OPTION_SUCCESS]: stockCardReportFetchStorageBayOptionSuccess,

  [StockCardReportTypes.STOCK_CARD_REPORT_FETCH_LOCATION_OPTION_LOADING]: stockCardReportFetchLocationOptionLoading,
  [StockCardReportTypes.STOCK_CARD_REPORT_FETCH_LOCATION_OPTION_SUCCESS]: stockCardReportFetchLocationOptionSuccess
});
