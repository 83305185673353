import { put, call, select, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import { push } from 'connected-react-router';
import ApiService from '../Services/ApiService';
import AppActions from '../Stores/App/Actions';
import GdsRcpt01Actions, { GdsRcpt01Types } from '../Stores/GdsRcpt01/Actions';

const getAppStore = state => state.app;

export function* gdsRcpt01FetchGdsRcpt01({ currentPage, sorts, filters, pageSize }) {
  try {
    yield put(GdsRcpt01Actions.gdsRcpt01FetchGdsRcpt01Loading(true));

    const processedSorts = [];
    Object.entries(sorts).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      processedSorts.push(`${key}:${value === 'ascend' ? 'ASC' : 'DESC'}`);
    });

    const processedFilters = [];
    Object.entries(filters).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      if (value) {
        processedFilters.push(`${key}:${value}`);
      }
    });

    const app = yield select(getAppStore);
    const getData = {
      page: currentPage,
      sorts: processedSorts,
      filters: processedFilters,
      pageSize
    };

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `gdsRcpt/indexProcess/GDS_RCPT_01/${app.curSiteFlowId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      // if nowCurrentPage is more than lastPage, then nowCurrentPage = lastPage
      let nowCurrentPage = result.data.current_page;
      if (nowCurrentPage > result.data.last_page) {
        nowCurrentPage = result.data.last_page;
      }

      yield put(
        GdsRcpt01Actions.gdsRcpt01FetchGdsRcpt01Success(
          result.data.data,
          nowCurrentPage,
          result.data.last_page,
          result.data.total,
          result.data.per_page
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(GdsRcpt01Actions.gdsRcpt01FetchGdsRcpt01Loading(false));
  }
}

export function* gdsRcpt01CreateGdsRcpt01({ hdrIds, toStorageBinId, minPalletQtyPerc }) {
  try {
    yield put(GdsRcpt01Actions.gdsRcpt01CreateGdsRcpt01Loading(true));

    const app = yield select(getAppStore);

    const postData = {
      hdrIds,
      toStorageBinId,
      minPalletQtyPerc
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `gdsRcpt/createProcess/GDS_RCPT_01`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      yield put(GdsRcpt01Actions.gdsRcpt01CreateGdsRcpt01Success(result.data));

      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_SUCCESS_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(GdsRcpt01Actions.gdsRcpt01CreateGdsRcpt01Loading(false));
  }
}

export function* gdsRcpt01FetchToStorageBinOptions({ search }) {
  try {
    yield put(GdsRcpt01Actions.gdsRcpt01FetchToStorageBinOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId: app.curSiteFlowId,
      search,
      filters: [
        {
          field: 'bin_types',
          value: [
            1, // UNLOADING_AREA
            3, // UNLOADING_LOADING_AREA
            4, // BULK_STORAGE
            5, // PALLET_STORAGE
            6, // BROKEN_PALLET_STORAGE
            7, // CASE_STORAGE
            8, // BROKEN_CASE_STORAGE
            11, // BAD_PALLET_STORAGE
            12 // BAD_BROKEN_CASE_STORAGE
          ]
        }
      ]
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `storageBin/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({ value: d.id, label: `${d.code} ${d.desc_01}` }));

      yield put(GdsRcpt01Actions.gdsRcpt01FetchToStorageBinOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(GdsRcpt01Actions.gdsRcpt01FetchToStorageBinOptionLoading(false));
  }
}

export function* gdsRcpt01GoToDocument({ hdrId }) {
  const app = yield select(getAppStore);
  yield put(push(`${app.appPath}/inbOrdDetail/update/${hdrId}`));
}

export const saga = [
  takeLatest(GdsRcpt01Types.GDS_RCPT01_GO_TO_DOCUMENT, gdsRcpt01GoToDocument),
  takeLatest(GdsRcpt01Types.GDS_RCPT01_FETCH_GDS_RCPT01, gdsRcpt01FetchGdsRcpt01),
  takeLatest(
    GdsRcpt01Types.GDS_RCPT01_FETCH_TO_STORAGE_BIN_OPTIONS,
    gdsRcpt01FetchToStorageBinOptions
  ),
  takeLatest(GdsRcpt01Types.GDS_RCPT01_CREATE_GDS_RCPT01, gdsRcpt01CreateGdsRcpt01)
];
