import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  invDoc0102ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  invDoc0102GoToDocument: ['hdrId'],
  invDoc0102FetchInvDoc0102: ['currentPage', 'sorts', 'filters', 'pageSize'],
  invDoc0102FetchInvDoc0102Loading: ['boolean'],
  invDoc0102FetchInvDoc0102Success: ['documents', 'currentPage', 'lastPage', 'total', 'pageSize'],
  invDoc0102AddSelectedDocuments: ['selectedDocuments'],
  invDoc0102RemoveSelectedDocuments: ['selectedDocuments'],
  invDoc0102SetWorkspaceVisible: ['boolean'],
  invDoc0102PrintInvDoc0102: ['hdrIds'],
  invDoc0102PrintInvDoc0102Loading: ['boolean']
});

export const InvDoc0102Types = Types;
export default Creators;
