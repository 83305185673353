import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  putAway02ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  putAway02GoToDocument: ['hdrId'],
  putAway02FetchPutAway02: ['currentPage', 'sorts', 'filters', 'pageSize'],
  putAway02FetchPutAway02Loading: ['boolean'],
  putAway02FetchPutAway02Success: ['documents', 'currentPage', 'lastPage', 'total', 'pageSize'],
  putAway02AddSelectedDocuments: ['selectedDocuments'],
  putAway02RemoveSelectedDocuments: ['selectedDocuments'],
  putAway02SetWorkspaceVisible: ['boolean'],
  putAway02CreatePutAway02: ['hdrIds'],
  putAway02CreatePutAway02Loading: ['boolean'],
  putAway02CreatePutAway02Success: ['newDocuments'],

  putAway02SetExpandedRows: ['expandedRows']
});

export const PutAway02Types = Types;
export default Creators;
