import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  countAdjReasonDetailResetTimestamp: null,
  countAdjReasonDetailSetResId: ['resId'],

  countAdjReasonDetailInitModel: null,
  countAdjReasonDetailShowModel: ['resId'],
  countAdjReasonDetailShowModelSuccess: ['model'],

  countAdjReasonDetailUpdateModel: ['formikBag', 'model'],
  countAdjReasonDetailCreateModel: ['formikBag', 'model'],
  countAdjReasonDetailUpdateModelSuccess: ['model'],

  countAdjReasonDetailShowModelLoading: ['boolean']
});

export const CountAdjReasonDetailTypes = Types;
export default Creators;
