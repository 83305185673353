/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { TransferOutTrackingReportTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const transferOutTrackingReportReportLoading = (state, { boolean }) => ({
  ...state,
  reportIsLoading: boolean
});

export const transferOutTrackingReportInitTransferOutTrackingSuccess = (state, { criteria }) => ({
  ...state,
  criteria
});

export const transferOutTrackingReportTransferOutTrackingSuccess = (
  state,
  { criteria, reportData }
) => ({
  ...state,
  criteria,
  reportData
});

export const transferOutTrackingReportFetchDivisionOptionLoading = (state, { boolean }) => ({
  ...state,
  divisionIsLoading: boolean
});

export const transferOutTrackingReportFetchDivisionOptionSuccess = (state, { options }) => ({
  ...state,
  divisionOptions: options
});

export const transferOutTrackingReportFetchToLocationOptionLoading = (state, { boolean }) => ({
  ...state,
  toLocationIsLoading: boolean
});

export const transferOutTrackingReportFetchToLocationOptionSuccess = (state, { options }) => ({
  ...state,
  toLocationOptions: options
});

export const transferOutTrackingReportFetchTransportOptionLoading = (state, { boolean }) => ({
  ...state,
  transportIsLoading: boolean
});

export const transferOutTrackingReportFetchTransportOptionSuccess = (state, { options }) => ({
  ...state,
  transportOptions: options
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [TransferOutTrackingReportTypes.TRANSFER_OUT_TRACKING_REPORT_REPORT_LOADING]: transferOutTrackingReportReportLoading,
  [TransferOutTrackingReportTypes.TRANSFER_OUT_TRACKING_REPORT_INIT_TRANSFER_OUT_TRACKING_SUCCESS]: transferOutTrackingReportInitTransferOutTrackingSuccess,
  [TransferOutTrackingReportTypes.TRANSFER_OUT_TRACKING_REPORT_TRANSFER_OUT_TRACKING_SUCCESS]: transferOutTrackingReportTransferOutTrackingSuccess,

  [TransferOutTrackingReportTypes.TRANSFER_OUT_TRACKING_REPORT_FETCH_DIVISION_OPTION_LOADING]: transferOutTrackingReportFetchDivisionOptionLoading,
  [TransferOutTrackingReportTypes.TRANSFER_OUT_TRACKING_REPORT_FETCH_DIVISION_OPTION_SUCCESS]: transferOutTrackingReportFetchDivisionOptionSuccess,

  [TransferOutTrackingReportTypes.TRANSFER_OUT_TRACKING_REPORT_FETCH_TO_LOCATION_OPTION_LOADING]: transferOutTrackingReportFetchToLocationOptionLoading,
  [TransferOutTrackingReportTypes.TRANSFER_OUT_TRACKING_REPORT_FETCH_TO_LOCATION_OPTION_SUCCESS]: transferOutTrackingReportFetchToLocationOptionSuccess,

  [TransferOutTrackingReportTypes.TRANSFER_OUT_TRACKING_REPORT_FETCH_TRANSPORT_OPTION_LOADING]: transferOutTrackingReportFetchTransportOptionLoading,
  [TransferOutTrackingReportTypes.TRANSFER_OUT_TRACKING_REPORT_FETCH_TRANSPORT_OPTION_SUCCESS]: transferOutTrackingReportFetchTransportOptionSuccess
});
