/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { WhseJob03DetailTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const whseJob03DetailResetTimestamp = state => ({
  ...state,
  timestamp: Date.now()
});

export const whseJob03DetailSetHdrId = (state, { hdrId }) => ({
  ...state,
  timestamp: Date.now(),
  documentIsLoading: false,
  hdrId,
  detailIsVisible: false
});

export const whseJob03DetailShowDocumentLoading = (state, { boolean }) => ({
  ...state,
  documentIsLoading: boolean
});

export const whseJob03DetailShowHeaderSuccess = (state, { documentHeader }) => ({
  ...state,
  documentHeader
});

export const whseJob03DetailUpdateDocumentSuccess = (
  state,
  { documentHeader, documentDetails }
) => ({
  ...state,
  documentHeader,
  documentDetails
});

export const whseJob03DetailShowDetailsSuccess = (state, { documentDetails }) => ({
  ...state,
  documentDetails
});

export const whseJob03DetailSetDetailVisible = (state, { boolean }) => ({
  ...state,
  detailIsVisible: boolean
});

export const whseJob03DetailSetWhseJobType = (state, { whseJobType }) => ({
  ...state,
  whseJobType
});

export const whseJob03DetailSetDocumentDetail = (state, { documentDetail }) => ({
  ...state,
  documentDetail,
  whseJobType: documentDetail.whse_job_type,
  companyOptions: [],
  frStorageBinOptions: [],
  quantBalOptions: [],
  uomOptions: [],
  toStorageBinOptions: []
});

export const whseJob03DetailFetchCompanyOptionLoading = (state, { boolean }) => ({
  ...state,
  companyIsLoading: boolean
});

export const whseJob03DetailFetchCompanyOptionSuccess = (state, { options }) => ({
  ...state,
  companyOptions: options
});

export const whseJob03DetailFetchFrStorageBinOptionLoading = (state, { boolean }) => ({
  ...state,
  frStorageBinIsLoading: boolean
});

export const whseJob03DetailFetchFrStorageBinOptionSuccess = (state, { options }) => ({
  ...state,
  frStorageBinOptions: options
});

export const whseJob03DetailFetchQuantBalOptionLoading = (state, { boolean }) => ({
  ...state,
  quantBalIsLoading: boolean
});

export const whseJob03DetailFetchQuantBalOptionSuccess = (state, { options }) => ({
  ...state,
  quantBalOptions: options
});

export const whseJob03DetailFetchUomOptionLoading = (state, { boolean }) => ({
  ...state,
  uomIsLoading: boolean
});

export const whseJob03DetailFetchUomOptionSuccess = (state, { options }) => ({
  ...state,
  uomOptions: options
});

export const whseJob03DetailFetchToStorageBinOptionLoading = (state, { boolean }) => ({
  ...state,
  toStorageBinIsLoading: boolean
});

export const whseJob03DetailFetchToStorageBinOptionSuccess = (state, { options }) => ({
  ...state,
  toStorageBinOptions: options
});

// force refresh the formik form
export const whseJob03DetailUpdateDetails = (state, { documentDetails }) => ({
  ...state,
  documentDetail: documentDetails[0]
});

// force refresh the formik form
export const whseJob03DetailCreateDetail = (state, { documentDetail }) => ({
  ...state,
  documentDetail
});

export const whseJob03DetailFetchWorkerOptionLoading = (state, { boolean }) => ({
  ...state,
  workerIsLoading: boolean
});

export const whseJob03DetailFetchWorkerOptionSuccess = (state, { options }) => ({
  ...state,
  workerOptions: options
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [WhseJob03DetailTypes.WHSE_JOB03_DETAIL_RESET_TIMESTAMP]: whseJob03DetailResetTimestamp,
  [WhseJob03DetailTypes.WHSE_JOB03_DETAIL_SET_HDR_ID]: whseJob03DetailSetHdrId,

  [WhseJob03DetailTypes.WHSE_JOB03_DETAIL_SHOW_HEADER_SUCCESS]: whseJob03DetailShowHeaderSuccess,

  [WhseJob03DetailTypes.WHSE_JOB03_DETAIL_UPDATE_DOCUMENT_SUCCESS]: whseJob03DetailUpdateDocumentSuccess,

  [WhseJob03DetailTypes.WHSE_JOB03_DETAIL_SHOW_DOCUMENT_LOADING]: whseJob03DetailShowDocumentLoading,

  [WhseJob03DetailTypes.WHSE_JOB03_DETAIL_SHOW_DETAILS_SUCCESS]: whseJob03DetailShowDetailsSuccess,

  [WhseJob03DetailTypes.WHSE_JOB03_DETAIL_SET_DETAIL_VISIBLE]: whseJob03DetailSetDetailVisible,
  [WhseJob03DetailTypes.WHSE_JOB03_DETAIL_SET_DOCUMENT_DETAIL]: whseJob03DetailSetDocumentDetail,

  [WhseJob03DetailTypes.WHSE_JOB03_DETAIL_UPDATE_DETAILS]: whseJob03DetailUpdateDetails,
  [WhseJob03DetailTypes.WHSE_JOB03_DETAIL_CREATE_DETAIL]: whseJob03DetailCreateDetail,

  [WhseJob03DetailTypes.WHSE_JOB03_DETAIL_SET_WHSE_JOB_TYPE]: whseJob03DetailSetWhseJobType,

  [WhseJob03DetailTypes.WHSE_JOB03_DETAIL_FETCH_COMPANY_OPTION_LOADING]: whseJob03DetailFetchCompanyOptionLoading,
  [WhseJob03DetailTypes.WHSE_JOB03_DETAIL_FETCH_COMPANY_OPTION_SUCCESS]: whseJob03DetailFetchCompanyOptionSuccess,

  [WhseJob03DetailTypes.WHSE_JOB03_DETAIL_FETCH_FR_STORAGE_BIN_OPTION_LOADING]: whseJob03DetailFetchFrStorageBinOptionLoading,
  [WhseJob03DetailTypes.WHSE_JOB03_DETAIL_FETCH_FR_STORAGE_BIN_OPTION_SUCCESS]: whseJob03DetailFetchFrStorageBinOptionSuccess,

  [WhseJob03DetailTypes.WHSE_JOB03_DETAIL_FETCH_QUANT_BAL_OPTION_LOADING]: whseJob03DetailFetchQuantBalOptionLoading,
  [WhseJob03DetailTypes.WHSE_JOB03_DETAIL_FETCH_QUANT_BAL_OPTION_SUCCESS]: whseJob03DetailFetchQuantBalOptionSuccess,

  [WhseJob03DetailTypes.WHSE_JOB03_DETAIL_FETCH_UOM_OPTION_LOADING]: whseJob03DetailFetchUomOptionLoading,
  [WhseJob03DetailTypes.WHSE_JOB03_DETAIL_FETCH_UOM_OPTION_SUCCESS]: whseJob03DetailFetchUomOptionSuccess,

  [WhseJob03DetailTypes.WHSE_JOB03_DETAIL_FETCH_TO_STORAGE_BIN_OPTION_LOADING]: whseJob03DetailFetchToStorageBinOptionLoading,
  [WhseJob03DetailTypes.WHSE_JOB03_DETAIL_FETCH_TO_STORAGE_BIN_OPTION_SUCCESS]: whseJob03DetailFetchToStorageBinOptionSuccess,

  [WhseJob03DetailTypes.WHSE_JOB03_DETAIL_FETCH_WORKER_OPTION_LOADING]: whseJob03DetailFetchWorkerOptionLoading,
  [WhseJob03DetailTypes.WHSE_JOB03_DETAIL_FETCH_WORKER_OPTION_SUCCESS]: whseJob03DetailFetchWorkerOptionSuccess
});
