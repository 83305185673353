import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  stkIssSync04ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  stkIssSync04GoToDocument: ['hdrId'],
  stkIssSync04FetchStkIssSync04: ['currentPage', 'sorts', 'filters', 'pageSize'],
  stkIssSync04FetchStkIssSync04Loading: ['boolean'],
  stkIssSync04FetchStkIssSync04Success: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],
  stkIssSync04AddSelectedDocuments: ['selectedDocuments'],
  stkIssSync04RemoveSelectedDocuments: ['selectedDocuments'],
  stkIssSync04SetWorkspaceVisible: ['boolean'],

  stkIssSync04SyncNow: ['hdrIds'],
  stkIssSync04SyncNowSuccess: null,
  stkIssSync04SyncLoading: ['boolean']
});

export const StkIssSync04Types = Types;
export default Creators;
