import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  gdsRcptDetailReportInitGdsRcptDetail: null,
  gdsRcptDetailReportInitGdsRcptDetailSuccess: ['criteria'],
  gdsRcptDetailReportGdsRcptDetail: ['formikBag', 'criteria'],
  gdsRcptDetailReportGdsRcptDetailSuccess: ['criteria', 'reportData'],
  gdsRcptDetailReportReportLoading: ['boolean'],

  gdsRcptDetailReportFetchDivisionOptions: ['search'],
  gdsRcptDetailReportFetchDivisionOptionLoading: ['boolean'],
  gdsRcptDetailReportFetchDivisionOptionSuccess: ['options'],

  gdsRcptDetailReportFetchBizPartnerOptions: ['search'],
  gdsRcptDetailReportFetchBizPartnerOptionLoading: ['boolean'],
  gdsRcptDetailReportFetchBizPartnerOptionSuccess: ['options'],

  gdsRcptDetailReportFetchDebtorOptions: ['search'],
  gdsRcptDetailReportFetchDebtorOptionLoading: ['boolean'],
  gdsRcptDetailReportFetchDebtorOptionSuccess: ['options']
});

export const GdsRcptDetailReportTypes = Types;
export default Creators;
