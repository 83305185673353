import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';

import { Modal, Row, Col, Typography } from 'antd';

import UserList01Actions from '../../Stores/UserList01/Actions';

const QRCode = require('qrcode.react');

const { Text } = Typography;

class BarcodeDialog extends React.PureComponent {
  constructor() {
    super();

    this.handleCancel = this.handleCancel.bind(this);
  }

  handleCancel() {
    const { setDialogVisible } = this.props;

    setDialogVisible(false);
  }

  render() {
    const { intl, dialogIsVisible, barcode, username, firstName, lastName } = this.props;

    return (
      <>
        <Modal
          visible={dialogIsVisible}
          title={
            // eslint-disable-next-line react/jsx-wrap-multilines
            <Text>{`${username}`}</Text>
          }
          // style={{top:20}}
          width="50%"
          centered
          // onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={null}
          className="pick-list-dialog"
        >
          <Row type="flex">
            <Col span={20} style={{ margin: 'auto' }}>
              <DialogRow
                header={<Text>{intl.formatMessage({ id: 'barcode' })}</Text>}
                details={<QRCode value={barcode} size={200} />}
              />
              <DialogRow
                header={<Text>{intl.formatMessage({ id: 'name' })}</Text>}
                details={<Text>{`${firstName} ${lastName}`}</Text>}
              />
            </Col>
          </Row>
        </Modal>
      </>
    );
  }
}

function DialogRow(props) {
  // eslint-disable-next-line react/prop-types
  const { className, header, details } = props;
  return (
    <Row type="flex" gutter={[8, 8]} className={className || 'modalinfo-row'}>
      <Col span={5} className="modalinfo">
        {header}
      </Col>
      <Col span={19} className="modalinfo-details">
        {details}
      </Col>
    </Row>
  );
}

BarcodeDialog.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  setDialogVisible: PropTypes.func,
  dialogIsVisible: PropTypes.bool,
  barcode: PropTypes.string,
  username: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string
};

BarcodeDialog.defaultProps = {
  intl: {},
  setDialogVisible() {},
  dialogIsVisible: false,
  barcode: '',
  username: '',
  firstName: '',
  lastName: ''
};

const mapStateToProps = state => ({
  dialogIsVisible: state.userList01.barcodeDialogIsVisible,
  barcode: state.userList01.barcodeDialogBarcode,
  username: state.userList01.barcodeDialogUsername,
  firstName: state.userList01.barcodeDialogFirstName,
  lastName: state.userList01.barcodeDialogLastName
});

const mapDispatchToProps = dispatch => ({
  setDialogVisible: boolean => dispatch(UserList01Actions.userList01SetBarcodeVisible(boolean))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(BarcodeDialog));
