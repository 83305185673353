import { put, call, select, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import FileSaver from 'file-saver';
import ApiService from '../Services/ApiService';
import AppActions from '../Stores/App/Actions';
import PickingCriteriaExcel01Actions, {
  PickingCriteriaExcel01Types
} from '../Stores/PickingCriteriaExcel01/Actions';

const getAppStore = state => state.app;

export function* pickingCriteriaExcel01ShowBatchJobStatus() {
  try {
    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `batchJobStatus/showBatchJobStatus/PICKING_CRITERIA_EXCEL_01`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(
        PickingCriteriaExcel01Actions.pickingCriteriaExcel01ShowBatchJobStatusSuccess(result.data)
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  }
}

export function* pickingCriteriaExcel01UploadExcel({ file }) {
  try {
    yield put(PickingCriteriaExcel01Actions.pickingCriteriaExcel01UploadLoading(true));

    const app = yield select(getAppStore);

    // eslint-disable-next-line no-undef
    const postData = new FormData();
    postData.append('file', file);

    const getData = {};

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `pickingCriteria/uploadProcess/PICKING_CRITERIA_EXCEL_01/${app.curSiteFlowId}`,
      app.token,
      postData,
      getData,
      'multipart/form-data'
    );

    if (result.isSuccess === true) {
      // result.data is total
      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_SUCCESS_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(PickingCriteriaExcel01Actions.pickingCriteriaExcel01UploadLoading(false));
  }
}

export function* pickingCriteriaExcel01DownloadExcel() {
  try {
    yield put(PickingCriteriaExcel01Actions.pickingCriteriaExcel01UploadLoading(true));

    const app = yield select(getAppStore);

    const getData = {};

    const result = yield call(
      ApiService.downloadGetApi, // function
      app.apiUrl,
      `pickingCriteria/downloadProcess/PICKING_CRITERIA_EXCEL_01/${app.curSiteFlowId}`,
      app.token,
      getData,
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    );

    if (result.isSuccess === true) {
      FileSaver.saveAs(result.data, 'PICKING_CRITERIA_EXCEL_01.XLSX');
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(PickingCriteriaExcel01Actions.pickingCriteriaExcel01UploadLoading(false));
  }
}

export const saga = [
  takeLatest(
    PickingCriteriaExcel01Types.PICKING_CRITERIA_EXCEL01_SHOW_BATCH_JOB_STATUS,
    pickingCriteriaExcel01ShowBatchJobStatus
  ),
  takeLatest(
    PickingCriteriaExcel01Types.PICKING_CRITERIA_EXCEL01_UPLOAD_EXCEL,
    pickingCriteriaExcel01UploadExcel
  ),
  takeLatest(
    PickingCriteriaExcel01Types.PICKING_CRITERIA_EXCEL01_DOWNLOAD_EXCEL,
    pickingCriteriaExcel01DownloadExcel
  )
];
