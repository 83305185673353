import React from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';

import { ArrowLeftOutlined, UndoOutlined, SaveOutlined } from '@ant-design/icons';

import { Button, Row, Col, Typography } from 'antd';
import treeChanges from 'tree-changes';
import {
  FormikInput,
  FormikText,
  FormikDatePicker,
  FormikButton,
  FormikSelect
} from '../../Components/Formik';
import PrfDelDetailActions from '../../Stores/PrfDelDetail/Actions';
import AppActions from '../../Stores/App/Actions';

const { Text } = Typography;

class HeaderForm extends React.PureComponent {
  componentDidMount() {}

  componentDidUpdate(prevProps) {
    const { hdrId, initHeader, showHeader } = this.props;

    const { changed } = treeChanges(prevProps, this.props);
    if (changed('timestamp')) {
      if (hdrId <= 0) {
        initHeader();
      } else {
        showHeader(hdrId);
      }
    }
  }

  componentWillUnmount() {}

  render() {
    const {
      intl,
      hdrId,
      match,
      documentHeader,
      transitionToStatus,
      createHeader,
      updateHeader,
      historyGoBack,
      documentIsLoading
    } = this.props;

    const disabled = documentHeader && documentHeader.doc_status >= 50;

    return (
      <Formik
        enableReinitialize
        initialValues={documentHeader}
        onSubmit={(values, formikBag) => {
          if (values.submit_action === 'update') {
            if (match.params.action === 'create') {
              createHeader(formikBag, values);
            } else {
              updateHeader(formikBag, values);
            }
          } else if (values.submit_action === 'void') {
            transitionToStatus(formikBag, hdrId, 'VOID');
          } else if (values.submit_action === 'draft') {
            transitionToStatus(formikBag, hdrId, 'DRAFT');
          } else if (values.submit_action === 'wip') {
            transitionToStatus(formikBag, hdrId, 'WIP');
          } else if (values.submit_action === 'complete') {
            transitionToStatus(formikBag, hdrId, 'COMPLETE');
          }
        }}
        validationSchema={Yup.object().shape({
          doc_date: Yup.date().min(
            new Date(1969, 12, 1),
            intl.formatMessage({ id: 'delivery_date_is_required' })
          ),
          doc_rtn_date: Yup.date().min(
            new Date(0, 0, 0),
            intl.formatMessage({ id: 'doc_return_date_is_required' })
          ),
          transport_select2: Yup.object().shape({
            value: Yup.number().min(1, intl.formatMessage({ id: 'transport_is_required' }))
          }),
          driver_01_select2: Yup.object().shape({
            value: Yup.number().min(1, intl.formatMessage({ id: 'driver_is_required' }))
          }),
          delivery_man_01_select2: Yup.object().shape({
            value: Yup.number().min(1, intl.formatMessage({ id: 'delivery_man_is_required' }))
          })
        })}
      >
        {({
          values,
          // handleChange,
          // errors,
          // setFieldTouched,
          dirty,
          // touched,
          isSubmitting,
          // isValid,
          handleSubmit,
          handleReset
        }) => (
          <Form>
            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'doc_code' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                {match.params.action === 'create' ? (
                  <Field
                    name="doc_no_id"
                    component={FormikSelect}
                    showArrow
                    filterOption={false}
                    notFoundContent={null}
                    options={values.doc_no_id_options}
                    disabled={disabled || documentIsLoading}
                  />
                ) : (
                  <Field name="doc_code" component={FormikText} />
                )}
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'doc_status' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="submit_action"
                  value="void"
                  component={FormikButton}
                  style={values.doc_status === 2 ? { backgroundColor: 'red', color: 'white' } : {}}
                  disabled={
                    values.doc_status === 2 ||
                    match.params.action === 'create' ||
                    values.doc_status > 3
                  }
                  loading={isSubmitting || documentIsLoading}
                  onClick={handleSubmit}
                  label={intl.formatMessage({
                    id: 'void'
                  })}
                />
                <Field
                  name="submit_action"
                  value="draft"
                  component={FormikButton}
                  style={values.doc_status === 3 ? { backgroundColor: 'red', color: 'white' } : {}}
                  disabled={values.doc_status === 3 || match.params.action === 'create'}
                  loading={isSubmitting || documentIsLoading}
                  onClick={handleSubmit}
                  label={intl.formatMessage({
                    id: 'draft'
                  })}
                />
                <Field
                  name="submit_action"
                  value="wip"
                  component={FormikButton}
                  style={values.doc_status === 50 ? { backgroundColor: 'red', color: 'white' } : {}}
                  disabled={values.doc_status === 50 || match.params.action === 'create'}
                  loading={isSubmitting || documentIsLoading}
                  onClick={handleSubmit}
                  label={intl.formatMessage({
                    id: 'WIP'
                  })}
                />
                <Field
                  name="submit_action"
                  value="complete"
                  component={FormikButton}
                  style={
                    values.doc_status === 100 ? { backgroundColor: 'green', color: 'white' } : {}
                  }
                  disabled={values.doc_status === 100 || match.params.action === 'create'}
                  loading={isSubmitting || documentIsLoading}
                  onClick={handleSubmit}
                  label={intl.formatMessage({
                    id: 'complete'
                  })}
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'delivery_date' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="doc_date"
                  component={FormikDatePicker}
                  disabled={disabled || documentIsLoading}
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'doc_return_date' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="doc_rtn_date"
                  component={FormikDatePicker}
                  disabled={disabled || documentIsLoading}
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'ref_code' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={10}>
                <Field
                  name="ref_code_01"
                  component={FormikInput}
                  disabled={disabled || documentIsLoading}
                />
              </Col>
              <Col span={10}>
                <Field
                  name="ref_code_02"
                  component={FormikInput}
                  disabled={disabled || documentIsLoading}
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'desc_01' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="desc_01"
                  component={FormikInput}
                  disabled={disabled || documentIsLoading}
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'desc_02' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="desc_02"
                  component={FormikInput}
                  disabled={disabled || documentIsLoading}
                />
              </Col>
            </Row>

            <Row type="flex" justify="space-between" gutter={[0, 16]}>
              <Col span={8}>
                <Button
                  type="primary"
                  // disabled={!isValid}
                  loading={isSubmitting}
                  onClick={historyGoBack}
                  icon={<ArrowLeftOutlined />}
                >
                  {intl.formatMessage({
                    id: 'back'
                  })}
                </Button>
              </Col>
              <Col span={5}>
                <Button
                  type="primary"
                  disabled={!dirty}
                  loading={isSubmitting || documentIsLoading}
                  onClick={handleReset}
                  icon={<UndoOutlined />}
                >
                  {intl.formatMessage({ id: 'reset' })}
                </Button>
                <Field
                  name="submit_action"
                  type="primary"
                  value="update"
                  component={FormikButton}
                  disabled={values.doc_status >= 50}
                  loading={isSubmitting || documentIsLoading}
                  onClick={handleSubmit}
                  label={intl.formatMessage({
                    id: match.params.action === 'create' ? 'create' : 'update'
                  })}
                  icon={<SaveOutlined />}
                />
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    );
  }
}

HeaderForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object,

  initHeader: PropTypes.func,
  showHeader: PropTypes.func,
  transitionToStatus: PropTypes.func,
  createHeader: PropTypes.func,
  updateHeader: PropTypes.func,
  historyGoBack: PropTypes.func,
  hdrId: PropTypes.number,
  documentHeader: PropTypes.shape({
    doc_status: PropTypes.number
  }),
  documentIsLoading: PropTypes.bool
};

HeaderForm.defaultProps = {
  intl: {},
  match: {},

  initHeader() {},
  showHeader() {},
  transitionToStatus() {},
  createHeader() {},
  updateHeader() {},
  historyGoBack() {},
  hdrId: 0,
  documentHeader: { doc_status: 3 },
  documentIsLoading: false
};

const mapStateToProps = state => ({
  apiUrl: state.app.apiUrl,
  timestamp: state.prfDelDetail.timestamp,
  hdrId: state.prfDelDetail.hdrId,
  documentHeader: state.prfDelDetail.documentHeader,
  documentIsLoading: state.prfDelDetail.documentIsLoading
});

const mapDispatchToProps = dispatch => ({
  initHeader: () => dispatch(PrfDelDetailActions.prfDelDetailInitHeader()),
  showHeader: hdrId => dispatch(PrfDelDetailActions.prfDelDetailShowHeader(hdrId)),
  transitionToStatus: (formikBag, hdrId, docStatus) =>
    dispatch(PrfDelDetailActions.prfDelDetailTransitionToStatus(formikBag, hdrId, docStatus)),
  updateHeader: (formikBag, documentHeader) =>
    dispatch(PrfDelDetailActions.prfDelDetailUpdateHeader(formikBag, documentHeader)),
  createHeader: (formikBag, documentHeader) =>
    dispatch(PrfDelDetailActions.prfDelDetailCreateHeader(formikBag, documentHeader)),

  historyGoBack: () => dispatch(AppActions.appHistoryGoBack())
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(HeaderForm));
