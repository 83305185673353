import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  prodSheetDetailResetTimestamp: null,
  prodSheetDetailSetHdrId: ['hdrId'],

  prodSheetDetailInitHeader: null,
  prodSheetDetailShowHeader: ['hdrId'],
  prodSheetDetailShowHeaderSuccess: ['documentHeader'],

  prodSheetDetailUpdateHeader: ['formikBag', 'documentHeader'],
  prodSheetDetailCreateHeader: ['formikBag', 'documentHeader'],
  prodSheetDetailUpdateDocumentSuccess: ['documentHeader', 'documentDetails'],

  prodSheetDetailShowDocumentLoading: ['boolean'],
  prodSheetDetailTransitionToStatus: ['formikBag', 'hdrId', 'docStatus'],

  prodSheetDetailShowDetails: ['hdrId'],
  prodSheetDetailShowDetailsSuccess: ['documentDetails'],

  prodSheetDetailSetDetailVisible: ['boolean'],
  prodSheetDetailSetJobDetailVisible: ['boolean'],
  prodSheetDetailSetDocumentDetail: ['documentDetail'],

  prodSheetDetailUpdateDetails: ['formikBag', 'hdrId', 'documentDetails'],
  prodSheetDetailCreateDetail: ['formikBag', 'hdrId', 'documentDetail'],
  prodSheetDetailDeleteDetail: ['hdrId', 'documentDetail'],

  prodSheetDetailFetchCompanyOptions: ['search'],
  prodSheetDetailFetchCompanyOptionLoading: ['boolean'],
  prodSheetDetailFetchCompanyOptionSuccess: ['options'],

  prodSheetDetailFetchUserOptions: ['search'],
  prodSheetDetailFetchUserOptionLoading: ['boolean'],
  prodSheetDetailFetchUserOptionSuccess: ['options'],

  prodSheetDetailFetchItemOptions: ['search'],
  prodSheetDetailFetchItemOptionLoading: ['boolean'],
  prodSheetDetailFetchItemOptionSuccess: ['options'],
  prodSheetDetailChangeItem: ['formikBag', 'hdrId', 'itemId'],

  prodSheetDetailFetchItemBatchOptions: ['itemId', 'search'],
  prodSheetDetailFetchItemBatchOptionLoading: ['boolean'],
  prodSheetDetailFetchItemBatchOptionSuccess: ['options'],
  prodSheetDetailChangeItemBatch: ['formikBag', 'hdrId', 'itemBatchId'],

  prodSheetDetailFetchUomOptions: ['itemId', 'search'],
  prodSheetDetailFetchUomOptionLoading: ['boolean'],
  prodSheetDetailFetchUomOptionSuccess: ['options'],
  prodSheetDetailChangeUom: ['formikBag', 'hdrId', 'itemId', 'uomId'],

  prodSheetDetailFetchItemCond01Options: ['search'],
  prodSheetDetailFetchItemCond01OptionLoading: ['boolean'],
  prodSheetDetailFetchItemCond01OptionSuccess: ['options'],

  prodSheetDetailFetchStorageBinOptions: ['search'],
  prodSheetDetailFetchStorageBinOptionLoading: ['boolean'],
  prodSheetDetailFetchStorageBinOptionSuccess: ['options'],

  prodSheetDetailFetchHandlingUnitOptions: ['search'],
  prodSheetDetailFetchHandlingUnitOptionLoading: ['boolean'],
  prodSheetDetailFetchHandlingUnitOptionSuccess: ['options'],

  prodSheetDetailFetchStorageRowOptions: ['search'],
  prodSheetDetailFetchStorageRowOptionLoading: ['boolean'],
  prodSheetDetailFetchStorageRowOptionSuccess: ['options'],

  prodSheetDetailFetchStorageBayOptions: ['search'],
  prodSheetDetailFetchStorageBayOptionLoading: ['boolean'],
  prodSheetDetailFetchStorageBayOptionSuccess: ['options'],

  prodSheetDetailFetchQuantBalOptions: ['companyId', 'storageBinId', 'search'],
  prodSheetDetailFetchQuantBalOptionLoading: ['boolean'],
  prodSheetDetailFetchQuantBalOptionSuccess: ['options'],
  prodSheetDetailChangeQuantBal: ['formikBag', 'hdrId', 'quantBalId'],

  prodSheetDetailUploadExcel: ['hdrId', 'file'],
  prodSheetDetailUploadLoading: ['boolean'],
  prodSheetDetailDownloadExcel: ['hdrId']
});

export const ProdSheetDetailTypes = Types;
export default Creators;
