const processHeaderDetails = (
  oldDocumentHeader,
  oldDocumentDetails,
  retDocumentHeader,
  retDocumentDetails,
  retDeletedDetails
) => {
  // loop the old details, check if got any modification, and replace the old detail
  const newDocumentDetails = oldDocumentDetails.map(oldDetail => {
    let a = -1;
    const retDocumentDetail = retDocumentDetails.reduce((lastObj, data, index) => {
      if (oldDetail.id === data.id) {
        a = index;
        return data;
      }
      return lastObj;
    }, {});

    if (a >= 0) {
      let isModified = false;
      Object.entries(retDocumentDetail).forEach(entry => {
        const field = entry[0];
        const modifiedValue = entry[1];

        const oldValue = oldDetail[field];
        if (typeof oldValue === 'object') {
          if (JSON.stringify(oldValue) !== JSON.stringify(modifiedValue)) {
            // console.log('field', field, 'oldValue', oldValue, 'modifiedValue', modifiedValue);
            isModified = true;
          }
        } else if (oldValue !== modifiedValue) {
          // console.log('field', field, 'oldValue', oldValue, 'modifiedValue', modifiedValue);
          isModified = true;
        }
      });

      retDocumentDetails.splice(a, 1);

      if (isModified) {
        return { ...retDocumentDetail, is_modified: true };
      }
    }

    return { ...oldDetail, is_modified: false };
  });

  // loop the remaining new details, and add to model
  retDocumentDetails.map(value => {
    newDocumentDetails.splice(newDocumentDetails.length, 1, { ...value, is_modified: true });
    return { ...value, is_modified: true };
  });

  // loop the deleted details, and remove from model
  retDeletedDetails.map(value => {
    newDocumentDetails.forEach((newDetail, a) => {
      if (value.id === newDetail.id) {
        newDocumentDetails.splice(a, 1);
      }
    });
    return { ...value, is_deleted: true };
  });

  return {
    header: retDocumentHeader,
    details: newDocumentDetails
  };
};

const processResources = (oldResources, retResources, retDeletedResources) => {
  // loop the old details, check if got any modification, and replace the old detail
  const newResources = oldResources.map(oldDetail => {
    let a = -1;
    const retResource = retResources.reduce((lastObj, data, index) => {
      if (oldDetail.id === data.id) {
        a = index;
        return data;
      }
      return lastObj;
    }, {});

    if (a >= 0) {
      let isModified = false;
      Object.entries(retResource).forEach(entry => {
        const field = entry[0];
        const modifiedValue = entry[1];

        const oldValue = oldDetail[field];
        if (typeof oldValue === 'object') {
          if (JSON.stringify(oldValue) !== JSON.stringify(modifiedValue)) {
            // console.log('field', field, 'oldValue', oldValue, 'modifiedValue', modifiedValue);
            isModified = true;
          }
        } else if (oldValue !== modifiedValue) {
          // console.log('field', field, 'oldValue', oldValue, 'modifiedValue', modifiedValue);
          isModified = true;
        }
      });

      retResources.splice(a, 1);

      if (isModified) {
        return { ...retResource, is_modified: true };
      }
    }

    return { ...oldDetail, is_modified: false };
  });

  // loop the remaining new details, and add to model
  retResources.map(value => {
    newResources.splice(newResources.length, 1, { ...value, is_modified: true });
    return { ...value, is_modified: true };
  });

  // loop the deleted details, and remove from model
  retDeletedResources.map(value => {
    newResources.forEach((newDetail, a) => {
      if (value.id === newDetail.id) {
        newResources.splice(a, 1);
      }
    });
    return { ...value, is_deleted: true };
  });

  return {
    resources: newResources
  };
};

const processDetails = (oldDocumentDetails, retDocumentDetails, retDeletedDetails) => {
  // loop the old details, check if got any modification, and replace the old detail
  const newDocumentDetails = oldDocumentDetails.map(oldDetail => {
    let a = -1;
    const retDocumentDetail = retDocumentDetails.reduce((lastObj, data, index) => {
      if (oldDetail.id === data.id) {
        a = index;
        return data;
      }
      return lastObj;
    }, {});

    if (a >= 0) {
      let isModified = false;
      Object.entries(retDocumentDetail).forEach(entry => {
        const field = entry[0];
        const modifiedValue = entry[1];

        const oldValue = oldDetail[field];
        if (typeof oldValue === 'object') {
          if (JSON.stringify(oldValue) !== JSON.stringify(modifiedValue)) {
            // console.log('field', field, 'oldValue', oldValue, 'modifiedValue', modifiedValue);
            isModified = true;
          }
        } else if (oldValue !== modifiedValue) {
          // console.log('field', field, 'oldValue', oldValue, 'modifiedValue', modifiedValue);
          isModified = true;
        }
      });

      retDocumentDetails.splice(a, 1);

      if (isModified) {
        return { ...retDocumentDetail, is_modified: true };
      }
    }

    return { ...oldDetail, is_modified: false };
  });

  // loop the remaining new details, and add to model
  retDocumentDetails.map(value => {
    newDocumentDetails.splice(newDocumentDetails.length, 1, { ...value, is_modified: true });
    return { ...value, is_modified: true };
  });

  // loop the deleted details, and remove from model
  retDeletedDetails.map(value => {
    newDocumentDetails.forEach((newDetail, a) => {
      if (value.id === newDetail.id) {
        newDocumentDetails.splice(a, 1);
      }
    });
    return { ...value, is_deleted: true };
  });

  return {
    details: newDocumentDetails
  };
};

const cssBackground = [
  'hex-D0E0FF',
  'hex-F7E7CE', // Champagne
  // 'hex-FECBCF',
  'hex-F5F5DC', // Beige
  'hex-DDFFEE',
  'hex-EDC9AF', // Desert sand
  'hex-FFFACD',
  'hex-00FFFF', // Cyan
  'hex-7FFFD4', // Aquamarine
  'hex-FFD700', // Gold
  // 'hex-E0B0FF', // Mauve
  'hex-FFE5B4', // Peach
  'hex-CCCCFF', // Periwinkle
  'hex-C0C0C0', // Silver
  'hex-D2B48C', // Tan
  'hex-D2B4ED',
  'hex-F0EFB0'
];

const colors = [
  '#000000', // Black
  // '#E52B50', // Amaranth
  '#FFBF00', // Amber
  // '#9966CC', // Amethyst
  // '#FBCEB1', // Apricot
  '#7FFFD4', // Aquamarine
  // '#007FFF', // Azure
  '#89CFF0', // Baby blue
  // '#F5F5DC', // Beige
  // '#0000FF', // Blue
  '#0095B6', // Blue-green
  '#8A2BE2', // Blue-violet
  '#DE5D83', // Blush
  '#CD7F32', // Bronze
  '#964B00', // Brown
  // '#800020', // Burgundy
  // '#702963', // Byzantium
  // '#960018', // Carmine
  '#DE3163', // Cerise
  '#007BA7', // Cerulean
  // '#F7E7CE', // Champagne
  '#7FFF00', // Chartreuse green
  '#7B3F00', // Chocolate
  '#0047AB', // Cobalt blue
  // '#6F4E37', // Coffee
  '#B87333', // Copper
  '#FF7F50', // Coral
  '#DC143C', // Crimson
  '#00FFFF', // Cyan
  // '#EDC9AF', // Desert sand
  // '#7DF9FF', // Electric blue
  '#50C878', // Emerald
  '#00FF3F', // Erin
  '#FFD700', // Gold
  '#808080', // Gray
  '#008000', // Green
  '#3FFF00', // Harlequin
  '#4B0082', // Indigo
  '#FFFFF0', // Ivory
  '#00A86B', // Jade
  // '#29AB87', // Jungle green
  '#B57EDC', // Lavender
  // '#FFF700', // Lemon
  '#C8A2C8', // Lilac
  // '#BFFF00', // Lime
  '#FF00FF', // Magenta
  '#FF00AF', // Magenta rose
  '#800000', // Maroon
  '#E0B0FF', // Mauve
  '#000080', // Navy blue
  '#CC7722', // Ochre
  '#808000', // Olive
  '#FF6600', // Orange
  // '#FF4500', // Orange-red
  '#DA70D6', // Orchid
  // '#FFE5B4', // Peach
  // '#CCCCFF', // Periwinkle
  '#1C39BB', // Persian blue
  '#FD6C9E', // Pink
  // '#8E4585', // Plum
  '#003153', // Prussian blue
  '#CC8899', // Puce
  '#800080', // Purple
  '#E30B5C', // Raspberry
  '#FF0000', // Red
  '#C71585', // Red-violet
  '#FF007F', // Rose
  '#E0115F', // Ruby
  '#FA8072', // Salmon
  '#92000A', // Sangria
  '#0F52BA', // Sapphire
  '#FF2400', // Scarlet
  // '#C0C0C0', // Silver
  '#708090', // Slate gray
  // '#A7FC00', // Spring bud
  // '#00FF7F', // Spring green
  // '#D2B48C', // Tan
  '#483C32', // Taupe
  '#008080', // Teal
  '#40E0D0', // Turquoise
  // '#3F00FF', // Ultramarine
  '#7F00FF', // Violet
  '#40826D' // Viridian
  // '#FFFFFF', // White
  // '#FFFF00' // Yellow
];

const processIdColor = id => {
  return colors[id % colors.length];
};

const processIdCssBackground = id => {
  return cssBackground[id % cssBackground.length];
};

const processStrPhysicalCountStatusColor = strPhysicalCountStatus => {
  if (strPhysicalCountStatus === 'RECOUNT') {
    // RECOUNT
    return '#808080'; // Gray
  }
  if (strPhysicalCountStatus === 'MARK_DROPPED') {
    // MARK DROPPED
    return '#0000FF'; // blue
  }
  if (strPhysicalCountStatus === 'MARK_CONFIRMED') {
    // MARK_CONFIRMED
    return '#008000'; // green
  }
  if (strPhysicalCountStatus === 'MARK_RECOUNT') {
    // MARK_RECOUNT
    return '#FF0000'; // red
  }
  if (strPhysicalCountStatus === 'COUNTING') {
    // COUNTING
    return '#FF0000'; // red
  }
  if (strPhysicalCountStatus === 'COUNT_SUBMITTED') {
    // COUNT_SUBMITTED
    return '#FF4500'; // orange red
  }
  return '#000000';
};

export default {
  processDetails,
  processHeaderDetails,
  processResources,
  processIdColor,
  processIdCssBackground,
  processStrPhysicalCountStatusColor
};
