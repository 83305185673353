import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  inbOrdDetailResetTimestamp: null,
  inbOrdDetailSetHdrId: ['hdrId', 'itemId'],

  inbOrdDetailInitHeader: null,
  inbOrdDetailShowHeader: ['hdrId'],
  inbOrdDetailShowHeaderSuccess: ['documentHeader'],

  inbOrdDetailUpdateHeader: ['formikBag', 'documentHeader'],
  inbOrdDetailCreateHeader: ['formikBag', 'documentHeader'],
  inbOrdDetailUpdateDocumentSuccess: ['documentHeader', 'documentDetails'],

  inbOrdDetailShowDocumentLoading: ['boolean'],
  inbOrdDetailTransitionToStatus: ['formikBag', 'hdrId', 'docStatus'],

  inbOrdDetailShowDetails: ['hdrId'],
  inbOrdDetailShowDetailsSuccess: ['documentDetails'],

  inbOrdDetailSetDetailVisible: ['boolean'],
  inbOrdDetailSetDocumentDetail: ['documentDetail'],

  inbOrdDetailUpdateDetails: ['formikBag', 'hdrId', 'documentDetails'],
  inbOrdDetailCreateDetail: ['formikBag', 'hdrId', 'documentDetail'],
  inbOrdDetailDeleteDetail: ['hdrId', 'documentDetail'],

  inbOrdDetailFetchPurchaserOptions: ['search'],
  inbOrdDetailFetchPurchaserOptionLoading: ['boolean'],
  inbOrdDetailFetchPurchaserOptionSuccess: ['options'],

  inbOrdDetailFetchBizPartnerOptions: ['search'],
  inbOrdDetailFetchBizPartnerOptionLoading: ['boolean'],
  inbOrdDetailFetchBizPartnerOptionSuccess: ['options'],
  inbOrdDetailChangeBizPartner: ['formikBag', 'bizPartnerId'],

  inbOrdDetailFetchCreditTermOptions: ['search'],
  inbOrdDetailFetchCreditTermOptionLoading: ['boolean'],
  inbOrdDetailFetchCreditTermOptionSuccess: ['options'],

  inbOrdDetailFetchCurrencyOptions: ['search'],
  inbOrdDetailFetchCurrencyOptionLoading: ['boolean'],
  inbOrdDetailFetchCurrencyOptionSuccess: ['options'],
  inbOrdDetailChangeCurrency: ['formikBag', 'currencyId'],

  inbOrdDetailFetchLocationOptions: ['search'],
  inbOrdDetailFetchLocationOptionLoading: ['boolean'],
  inbOrdDetailFetchLocationOptionSuccess: ['options'],

  inbOrdDetailFetchItemOptions: ['search'],
  inbOrdDetailFetchItemOptionLoading: ['boolean'],
  inbOrdDetailFetchItemOptionSuccess: ['options'],
  inbOrdDetailChangeItem: ['formikBag', 'hdrId', 'itemId'],

  inbOrdDetailFetchUomOptions: ['itemId', 'search'],
  inbOrdDetailFetchUomOptionLoading: ['boolean'],
  inbOrdDetailFetchUomOptionSuccess: ['options'],
  inbOrdDetailChangeUom: ['formikBag', 'hdrId', 'itemId', 'uomId'],

  inbOrdDetailFetchProjectOptions: ['search'],
  inbOrdDetailFetchProjectOptionLoading: ['boolean'],
  inbOrdDetailFetchProjectOptionSuccess: ['options']
});

export const InbOrdDetailTypes = Types;
export default Creators;
