import { put, call, select, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import ApiService from '../Services/ApiService';
import AppActions from '../Stores/App/Actions';
import RtnRcptProcessActions, { RtnRcptProcessTypes } from '../Stores/RtnRcptProcess/Actions';

const getAppStore = state => state.app;

// eslint-disable-next-line import/prefer-default-export
export function* rtnRcptProcessFetchRtnRcptProcess() {
  try {
    yield put(RtnRcptProcessActions.rtnRcptProcessFetchRtnRcptProcessLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `site/indexRtnRcptFlow/${app.curSiteFlowId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );
    if (result.isSuccess === true) {
      yield put(RtnRcptProcessActions.rtnRcptProcessFetchRtnRcptProcessSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(RtnRcptProcessActions.rtnRcptProcessFetchRtnRcptProcessLoading(false));
  }
}

export const saga = [
  takeLatest(
    RtnRcptProcessTypes.RTN_RCPT_PROCESS_FETCH_RTN_RCPT_PROCESS,
    rtnRcptProcessFetchRtnRcptProcess
  )
];
