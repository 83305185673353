import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  areaProcessFetchAreaProcess: null,
  areaProcessFetchAreaProcessLoading: ['boolean'],
  areaProcessFetchAreaProcessSuccess: ['process']
});

export const AreaProcessTypes = Types;
export default Creators;
