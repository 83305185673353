import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import treeChanges from 'tree-changes';

import { Table, Modal } from 'antd';

import LspDelOrdDetailActions from '../../Stores/LspDelOrdDetail/Actions';

class PickedDialog extends React.PureComponent {
  constructor() {
    super();

    this.handleOk = this.handleOk.bind(this);
    this.handleCancel = this.handleCancel.bind(this);

    this.getDocumentColumns = this.getDocumentColumns.bind(this);
  }

  componentDidMount() {
    const { hdrId, showPickedDetails } = this.props;

    showPickedDetails(hdrId);
  }

  componentDidUpdate(prevProps) {
    const { hdrId, showPickedDetails } = this.props;

    const { changed } = treeChanges(prevProps, this.props);

    if (changed('timestamp')) {
      showPickedDetails(hdrId);
    }
  }

  componentWillUnmount() {}

  handleOk() {
    const { setPickedIsVisible } = this.props;

    setPickedIsVisible(false);
  }

  handleCancel() {
    const { setPickedIsVisible } = this.props;

    setPickedIsVisible(false);
  }

  getDocumentColumns() {
    const { intl } = this.props;

    return [
      {
        width: 50,
        fixed: 'left',
        align: 'right',
        title: '',
        // sort field
        dataIndex: 'line_no',
        // filter field
        key: 'line_no',
        render: (text, record) => <>{record.line_no}</>
      },
      {
        width: 80,
        align: 'left',
        title: intl.formatMessage({ id: 'location' }),
        // sort field
        dataIndex: 'location_code',
        // filter field
        key: 'location_code',
        render: (text, record) => <>{record.location_code}</>
      },
      {
        width: 120,
        align: 'left',
        title: intl.formatMessage({ id: 'code' }),
        // sort field
        dataIndex: 'item_code',
        // filter field
        key: 'item_code',
        render: (text, record) => <>{record.item_code}</>
      },
      {
        width: 180,
        align: 'left',
        title: intl.formatMessage({ id: 'description' }),
        // sort field
        dataIndex: 'desc_01',
        // filter field
        key: 'desc_01',
        render: (text, record) => (
          <>
            {record.item_desc_01}
            {record.item_desc_02 ? (
              <>
                <br />
                {record.item_desc_02}
              </>
            ) : (
              ''
            )}
            {record.item_desc_03 ? (
              <>
                <br />
                {record.item_desc_03}
              </>
            ) : (
              ''
            )}
          </>
        )
      },
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'expiry_date' }),
        // sort field
        dataIndex: 'expiry_date',
        // filter field
        key: 'expiry_date',
        render: (text, record) => (
          <>
            <div style={{ fontWeight: 'bold' }}>{record.batch_serial_no}</div>
            {record.expiry_date ? <div>{record.expiry_date}</div> : ''}
            {record.receipt_date ? <>{record.receipt_date}</> : ''}
          </>
        )
      },
      {
        width: 100,
        align: 'right',
        title: intl.formatMessage({ id: 'qty' }),
        // sort field
        dataIndex: 'qty',
        // filter field
        key: 'qty',
        render: (text, record) => (
          <>
            {new Intl.NumberFormat([], {
              style: 'decimal',
              minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
              maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
            }).format(record.qty)}
          </>
        )
      },
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'uom' }),
        // sort field
        dataIndex: 'uom_code',
        // filter field
        key: 'uom_code',
        render: (text, record) => <>{record.uom_code}</>
      }
    ];
  }

  render() {
    const { intl, pickedIsVisible, pickedDetails, pickedIsLoading } = this.props;

    return (
      <>
        <Modal
          visible={pickedIsVisible}
          title={intl.formatMessage({ id: 'picked_details' })}
          // style={{top:20}}
          width="80%"
          centered
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <Table
            size="small"
            // rowSelection={rowSelection}
            rowKey="id"
            pagination={{ pageSize: 20 }}
            columns={this.getDocumentColumns()}
            dataSource={pickedDetails}
            loading={pickedIsLoading}
            bordered
            scroll={{ x: 950 }}
          />
        </Modal>
      </>
    );
  }
}

PickedDialog.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  setPickedIsVisible: PropTypes.func,
  showPickedDetails: PropTypes.func,
  pickedIsVisible: PropTypes.bool,
  pickedIsLoading: PropTypes.bool,
  hdrId: PropTypes.number,
  pickedDetails: PropTypes.arrayOf(PropTypes.object)
};

PickedDialog.defaultProps = {
  intl: {},
  setPickedIsVisible() {},
  showPickedDetails() {},
  pickedIsVisible: false,
  pickedIsLoading: false,
  hdrId: 0,
  pickedDetails: []
};

const mapStateToProps = state => ({
  timestamp: state.lspDelOrdDetail.pickedTimestamp,
  pickedIsVisible: state.lspDelOrdDetail.pickedIsVisible,
  pickedIsLoading: state.lspDelOrdDetail.pickedIsLoading,
  hdrId: state.lspDelOrdDetail.hdrId,
  pickedDetails: state.lspDelOrdDetail.pickedDetails
});

const mapDispatchToProps = dispatch => ({
  setPickedIsVisible: boolean =>
    dispatch(LspDelOrdDetailActions.lspDelOrdDetailSetPickedIsVisible(boolean)),
  showPickedDetails: hdrId =>
    dispatch(LspDelOrdDetailActions.lspDelOrdDetailShowPickedDetails(hdrId))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PickedDialog));
