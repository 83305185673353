/**
 * The initial values for the redux state.
 */
export default {
  timestamp: 0,
  currentPage: 0,
  criteria: {
    start_date: '1970-01-01',
    end_date: '1970-01-01',
    division_ids_select2: [],
    delivery_point_ids_select2: [],
    columns: {
      source: [],
      target: []
    }
  },
  sorts: {
    doc_code: 'ascend'
  },
  filters: {},
  reportData: [],
  reportIsLoading: false,

  deliveryPointOptions: [],
  deliveryPointIsLoading: false
};
