/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { LspSlsOrdTrackingReportTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const lspSlsOrdTrackingReportReportLoading = (state, { boolean }) => ({
  ...state,
  reportIsLoading: boolean
});

export const lspSlsOrdTrackingReportInitLspSlsOrdTrackingSuccess = (state, { criteria }) => ({
  ...state,
  criteria
});

export const lspSlsOrdTrackingReportLspSlsOrdTrackingSuccess = (
  state,
  { criteria, reportData }
) => ({
  ...state,
  criteria,
  reportData
});

export const lspSlsOrdTrackingReportFetchDivisionOptionLoading = (state, { boolean }) => ({
  ...state,
  divisionIsLoading: boolean
});

export const lspSlsOrdTrackingReportFetchDivisionOptionSuccess = (state, { options }) => ({
  ...state,
  divisionOptions: options
});

export const lspSlsOrdTrackingReportFetchDeliveryPointOptionLoading = (state, { boolean }) => ({
  ...state,
  deliveryPointIsLoading: boolean
});

export const lspSlsOrdTrackingReportFetchDeliveryPointOptionSuccess = (state, { options }) => ({
  ...state,
  deliveryPointOptions: options
});

export const lspSlsOrdTrackingReportFetchDebtorOptionLoading = (state, { boolean }) => ({
  ...state,
  debtorIsLoading: boolean
});

export const lspSlsOrdTrackingReportFetchDebtorOptionSuccess = (state, { options }) => ({
  ...state,
  debtorOptions: options
});

export const lspSlsOrdTrackingReportFetchTransportOptionLoading = (state, { boolean }) => ({
  ...state,
  transportIsLoading: boolean
});

export const lspSlsOrdTrackingReportFetchTransportOptionSuccess = (state, { options }) => ({
  ...state,
  transportOptions: options
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [LspSlsOrdTrackingReportTypes.LSP_SLS_ORD_TRACKING_REPORT_REPORT_LOADING]: lspSlsOrdTrackingReportReportLoading,
  [LspSlsOrdTrackingReportTypes.LSP_SLS_ORD_TRACKING_REPORT_INIT_LSP_SLS_ORD_TRACKING_SUCCESS]: lspSlsOrdTrackingReportInitLspSlsOrdTrackingSuccess,
  [LspSlsOrdTrackingReportTypes.LSP_SLS_ORD_TRACKING_REPORT_LSP_SLS_ORD_TRACKING_SUCCESS]: lspSlsOrdTrackingReportLspSlsOrdTrackingSuccess,

  [LspSlsOrdTrackingReportTypes.LSP_SLS_ORD_TRACKING_REPORT_FETCH_DIVISION_OPTION_LOADING]: lspSlsOrdTrackingReportFetchDivisionOptionLoading,
  [LspSlsOrdTrackingReportTypes.LSP_SLS_ORD_TRACKING_REPORT_FETCH_DIVISION_OPTION_SUCCESS]: lspSlsOrdTrackingReportFetchDivisionOptionSuccess,

  [LspSlsOrdTrackingReportTypes.LSP_SLS_ORD_TRACKING_REPORT_FETCH_DELIVERY_POINT_OPTION_LOADING]: lspSlsOrdTrackingReportFetchDeliveryPointOptionLoading,
  [LspSlsOrdTrackingReportTypes.LSP_SLS_ORD_TRACKING_REPORT_FETCH_DELIVERY_POINT_OPTION_SUCCESS]: lspSlsOrdTrackingReportFetchDeliveryPointOptionSuccess,

  [LspSlsOrdTrackingReportTypes.LSP_SLS_ORD_TRACKING_REPORT_FETCH_DEBTOR_OPTION_LOADING]: lspSlsOrdTrackingReportFetchDebtorOptionLoading,
  [LspSlsOrdTrackingReportTypes.LSP_SLS_ORD_TRACKING_REPORT_FETCH_DEBTOR_OPTION_SUCCESS]: lspSlsOrdTrackingReportFetchDebtorOptionSuccess,

  [LspSlsOrdTrackingReportTypes.LSP_SLS_ORD_TRACKING_REPORT_FETCH_TRANSPORT_OPTION_LOADING]: lspSlsOrdTrackingReportFetchTransportOptionLoading,
  [LspSlsOrdTrackingReportTypes.LSP_SLS_ORD_TRACKING_REPORT_FETCH_TRANSPORT_OPTION_SUCCESS]: lspSlsOrdTrackingReportFetchTransportOptionSuccess
});
