import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';

import { DeleteOutlined } from '@ant-design/icons';

import { Row, Col, Modal, Table, Button, Popconfirm } from 'antd';

import StorageBinList01Actions from '../../Stores/StorageBinList01/Actions';

class StorageBinList01Dialog extends React.PureComponent {
  constructor() {
    super();

    this.handleCancel = this.handleCancel.bind(this);
    this.useOnPrintResource = this.useOnPrintResource.bind(this);

    this.getSelectedDocumentColumns = this.getSelectedDocumentColumns.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate() {}

  componentWillUnmount() {}

  handleCancel() {
    const { setWorkspaceVisible } = this.props;

    setWorkspaceVisible(false);
  }

  getSelectedDocumentColumns() {
    const { intl, removeSelectedResources } = this.props;

    return [
      {
        align: 'left',
        title: intl.formatMessage({ id: 'code' }),
        // sort field
        dataIndex: 'code',
        sorter: (a, b) => `${a.code}`.localeCompare(b.code),
        // filter field
        key: 'code',
        render: (text, record) => <>{record.code}</>
      },
      {
        align: 'left',
        title: intl.formatMessage({ id: 'barcode' }),
        // sort field
        dataIndex: 'barcode',
        sorter: (a, b) => `${a.barcode}`.localeCompare(b.barcode),
        // filter field
        key: 'barcode',
        render: (text, record) => <>{record.barcode}</>
      },
      {
        align: 'left',
        title: intl.formatMessage({ id: 'description' }),
        // sort field
        dataIndex: 'desc_01',
        sorter: (a, b) => `${a.desc_01}`.localeCompare(b.desc_01),
        // filter field
        key: 'desc_01',
        render: (text, record) => <>{record.desc_01}</>
      },
      {
        width: 110,
        align: 'left',
        title: intl.formatMessage({ id: 'storage_row' }),
        // sort field
        dataIndex: 'storage_row_code',
        sorter: (a, b) => `${a.storage_row_code}`.localeCompare(b.storage_row_code),
        // filter field
        key: 'storage_row_code',
        render: (text, record) => <>{record.storage_row_code}</>
      },
      {
        width: 110,
        align: 'left',
        title: intl.formatMessage({ id: 'storage_bay' }),
        // sort field
        dataIndex: 'storage_bay_code',
        sorter: (a, b) => `${a.storage_bay_code}`.localeCompare(b.storage_bay_code),
        // filter field
        key: 'storage_bay_code',
        render: (text, record) => <>{record.storage_bay_code}</>
      },
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'level' }),
        // sort field
        dataIndex: 'level',
        sorter: (a, b) => `${a.level}`.localeCompare(b.level),
        // filter field
        key: 'level',
        render: (text, record) => <>{record.level}</>
      },
      {
        key: 'action',
        render: (text, record) => (
          <>
            <Popconfirm
              placement="left"
              title={intl.formatMessage({ id: 'are_you_sure_to_remove_this_document' })}
              onConfirm={() => {
                removeSelectedResources([record]);
              }}
              onCancel={() => {}}
              okText={intl.formatMessage({ id: 'yes' })}
              cancelText={intl.formatMessage({ id: 'cancel' })}
            >
              <Button type="dashed" icon={<DeleteOutlined />} />
            </Popconfirm>
          </>
        )
      }
    ];
  }

  useOnPrintResource() {
    const { selectedResources, printStorageBinList01 } = this.props;

    const ids = selectedResources.map(value => {
      return value.id;
    });

    printStorageBinList01(ids);
  }

  render() {
    const {
      intl,
      selectedResources,
      workspaceIsVisible,
      printIsLoading
    } = this.props;

    return (
      <>
        <Modal
          visible={workspaceIsVisible}
          title={intl.formatMessage({ id: 'manage_storage_bins' })}
          // style={{top:20}}
          width="80%"
          centered
          // onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <Button
              type="primary"
              key="print"
              disabled={selectedResources.length === 0}
              loading={printIsLoading}
              onClick={this.useOnPrintResource}
            >
              {intl.formatMessage({ id: 'print' })}
            </Button>
          ]}
        >
          <Row type="flex" justify="center" gutter={[0, 8]}>
            <Col span={24}>
              <Table
                // rowSelection={rowSelection}
                rowKey="id"
                pagination={false}
                columns={this.getSelectedDocumentColumns()}
                dataSource={selectedResources}
                loading={printIsLoading}
                bordered
                title={() => <b>{intl.formatMessage({ id: 'selected_storage_bins' })}</b>}
              />
            </Col>
          </Row>
        </Modal>
      </>
    );
  }
}

StorageBinList01Dialog.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  setWorkspaceVisible: PropTypes.func,
  workspaceIsVisible: PropTypes.bool,

  removeSelectedResources: PropTypes.func,
  selectedResources: PropTypes.arrayOf(PropTypes.object),

  printIsLoading: PropTypes.bool,

  printStorageBinList01: PropTypes.func
};

StorageBinList01Dialog.defaultProps = {
  intl: {},
  setWorkspaceVisible() {},
  workspaceIsVisible: false,

  removeSelectedResources() {},
  selectedResources: [],

  printIsLoading: false,

  printStorageBinList01() {}
};

const mapStateToProps = state => ({
  workspaceIsVisible: state.storageBinList01.workspaceIsVisible,

  selectedResources: state.storageBinList01.selectedResources,

  printIsLoading: state.storageBinList01.printIsLoading
});

const mapDispatchToProps = dispatch => ({
  setWorkspaceVisible: boolean =>
    dispatch(StorageBinList01Actions.storageBinList01SetWorkspaceVisible(boolean)),

  removeSelectedResources: selectedResources =>
    dispatch(StorageBinList01Actions.storageBinList01RemoveSelectedResources(selectedResources)),

  printStorageBinList01: ids =>
    dispatch(StorageBinList01Actions.storageBinList01PrintStorageBinList01(ids))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(StorageBinList01Dialog));
