/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */
import { createReducer } from 'reduxsauce';
import { PurRtnIndexTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const purRtnIndexResetTimestamp = (state, { currentPage, sorts, filters, pageSize }) => ({
  ...state,
  currentPage: currentPage || state.currentPage,
  sorts: sorts || state.sorts,
  filters: filters || state.filters,
  pageSize: pageSize || state.pageSize,
  timestamp: Date.now()
  // selectedDocuments: INITIAL_STATE.selectedDocuments
});

export const purRtnIndexFetchPurRtnIndexLoading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const purRtnIndexFetchPurRtnIndexSuccess = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  documents,
  currentPage,
  lastPage,
  total,
  pageSize
});

export const purRtnIndexAddSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = state.selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: [
      ...state.selectedDocuments,
      ...selectedDocuments.filter(data => {
        return !selectIds.includes(data.id);
      })
    ]
  };
};

export const purRtnIndexRemoveSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: state.selectedDocuments.filter(data => {
      return !selectIds.includes(data.id);
    })
  };
};

export const purRtnIndexSetWorkspaceVisible = (state, { boolean }) => ({
  ...state,
  workspaceIsVisible: boolean
});

export const purRtnIndexCreatePurRtnIndexLoading = (state, { boolean }) => ({
  ...state,
  createIsLoading: boolean
});

export const purRtnIndexCreatePurRtnIndexSuccess = (state, { newDocuments }) => ({
  ...state,
  timestamp: Date.now(),
  selectedDocuments: [],
  newDocuments
});

export const purRtnIndexWorkspaceLoading = (state, { boolean }) => ({
  ...state,
  workspaceIsLoading: boolean
});

export const purRtnIndexResetPurRtnFServ02Success = state => ({
  ...state,
  timestamp: Date.now(),
  selectedDocuments: []
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [PurRtnIndexTypes.PUR_RTN_INDEX_RESET_TIMESTAMP]: purRtnIndexResetTimestamp,
  [PurRtnIndexTypes.PUR_RTN_INDEX_FETCH_PUR_RTN_INDEX_LOADING]: purRtnIndexFetchPurRtnIndexLoading,
  [PurRtnIndexTypes.PUR_RTN_INDEX_FETCH_PUR_RTN_INDEX_SUCCESS]: purRtnIndexFetchPurRtnIndexSuccess,
  [PurRtnIndexTypes.PUR_RTN_INDEX_ADD_SELECTED_DOCUMENTS]: purRtnIndexAddSelectedDocuments,
  [PurRtnIndexTypes.PUR_RTN_INDEX_REMOVE_SELECTED_DOCUMENTS]: purRtnIndexRemoveSelectedDocuments,
  [PurRtnIndexTypes.PUR_RTN_INDEX_SET_WORKSPACE_VISIBLE]: purRtnIndexSetWorkspaceVisible,
  [PurRtnIndexTypes.PUR_RTN_INDEX_CREATE_PUR_RTN_INDEX_LOADING]: purRtnIndexCreatePurRtnIndexLoading,
  [PurRtnIndexTypes.PUR_RTN_INDEX_CREATE_PUR_RTN_INDEX_SUCCESS]: purRtnIndexCreatePurRtnIndexSuccess,
  [PurRtnIndexTypes.PUR_RTN_INDEX_WORKSPACE_LOADING]: purRtnIndexWorkspaceLoading,
  [PurRtnIndexTypes.PUR_RTN_INDEX_RESET_PUR_RTN_F_SERV02_SUCCESS]: purRtnIndexResetPurRtnFServ02Success
});
