/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { WhseJob170201Types } from './Actions';
import INITIAL_STATE from './InitialState';

export const whseJob170201ResetTimestamp = (state, { currentPage, sorts, filters, pageSize }) => ({
  ...state,
  currentPage: currentPage || state.currentPage,
  sorts: sorts || state.sorts,
  filters: filters || state.filters,
  pageSize: pageSize || state.pageSize,
  timestamp: Date.now(),
  // selectedDocuments: INITIAL_STATE.selectedDocuments,
  fetchIsLoading: false,
  printIsLoading: false
});

export const whseJob170201FetchWhseJob170201Loading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const whseJob170201FetchWhseJob170201Success = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  documents,
  currentPage,
  lastPage,
  total,
  pageSize
});

export const whseJob170201AddSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = state.selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: [
      ...state.selectedDocuments,
      ...selectedDocuments.filter(data => {
        return !selectIds.includes(data.id);
      })
    ]
  };
};

export const whseJob170201RemoveSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: state.selectedDocuments.filter(data => {
      return !selectIds.includes(data.id);
    })
  };
};

export const whseJob170201SetWorkspaceVisible = (state, { boolean }) => ({
  ...state,
  workspaceIsVisible: boolean
});

export const whseJob170201PrintWhseJob170201Loading = (state, { boolean }) => ({
  ...state,
  printIsLoading: boolean
});

export const whseJob170201SetExpandedRows = (state, { expandedRows }) => ({
  ...state,
  expandedRows
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [WhseJob170201Types.WHSE_JOB170201_RESET_TIMESTAMP]: whseJob170201ResetTimestamp,
  [WhseJob170201Types.WHSE_JOB170201_FETCH_WHSE_JOB170201_LOADING]: whseJob170201FetchWhseJob170201Loading,
  [WhseJob170201Types.WHSE_JOB170201_FETCH_WHSE_JOB170201_SUCCESS]: whseJob170201FetchWhseJob170201Success,
  [WhseJob170201Types.WHSE_JOB170201_ADD_SELECTED_DOCUMENTS]: whseJob170201AddSelectedDocuments,
  [WhseJob170201Types.WHSE_JOB170201_REMOVE_SELECTED_DOCUMENTS]: whseJob170201RemoveSelectedDocuments,
  [WhseJob170201Types.WHSE_JOB170201_SET_WORKSPACE_VISIBLE]: whseJob170201SetWorkspaceVisible,
  [WhseJob170201Types.WHSE_JOB170201_PRINT_WHSE_JOB170201_LOADING]: whseJob170201PrintWhseJob170201Loading,

  [WhseJob170201Types.WHSE_JOB170201_SET_EXPANDED_ROWS]: whseJob170201SetExpandedRows
});
