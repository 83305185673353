import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  putAwayIndexResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  putAwayIndexGoToPrint: ['document'],
  putAwayIndexGoToDocument: ['hdrId'],
  putAwayIndexGoToAudit: ['hdrId'],
  putAwayIndexFetchPutAwayIndex: ['currentPage', 'sorts', 'filters', 'pageSize'],
  putAwayIndexFetchPutAwayIndexLoading: ['boolean'],
  putAwayIndexFetchPutAwayIndexSuccess: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],
  putAwayIndexAddSelectedDocuments: ['selectedDocuments'],
  putAwayIndexRemoveSelectedDocuments: ['selectedDocuments'],
  putAwayIndexSetWorkspaceVisible: ['boolean'],

  putAwayIndexWorkspaceLoading: ['boolean'],
  putAwayIndexResetPutAwaySync01: ['hdrIds'],
  putAwayIndexResetPutAwaySync01Success: null,
  putAwayIndexResetPutAwayFServ01: ['hdrIds'],
  putAwayIndexResetPutAwayFServ01Success: null
});

export const PutAwayIndexTypes = Types;
export default Creators;
