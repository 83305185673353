/**
 * The initial values for the redux state.
 */
export default {
  timestamp: 0,
  appSettingIsLoading: false,
  appSettingData: {
    cps: '',
    cps_ver: '',
    wms: '',
    wms_ver: ''
  }
};
