import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  inbOrd04ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  inbOrd04GoToDocument: ['hdrId'],
  inbOrd04NewDocument: null,
  inbOrd04FetchInbOrd04: ['currentPage', 'sorts', 'filters', 'pageSize'],
  inbOrd04FetchInbOrd04Loading: ['boolean'],
  inbOrd04FetchInbOrd04Success: ['documents', 'currentPage', 'lastPage', 'total', 'pageSize'],
  inbOrd04AddSelectedDocuments: ['selectedDocuments'],
  inbOrd04RemoveSelectedDocuments: ['selectedDocuments'],
  inbOrd04SetWorkspaceVisible: ['boolean'],
  inbOrd04CreateInbOrd04: ['hdrIds'],
  inbOrd04CreateInbOrd04Loading: ['boolean'],
  inbOrd04CreateInbOrd04Success: ['newDocuments'],

  inbOrd04SetExpandedRows: ['expandedRows']
});

export const InbOrd04Types = Types;
export default Creators;
