import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  failDel01FetchToStorageBinOptions: ['search'],
  failDel01FetchToStorageBinOptionLoading: ['boolean'],
  failDel01FetchToStorageBinOptionSuccess: ['options'],

  failDel01FetchFailDel01Loading: ['boolean'],
  failDel01CreateFailDel01Loading: ['boolean'],

  failDel01ScanBarcode: ['value'],
  failDel01ScanBarcodeSuccess: ['outbOrdHdrs'],
  failDel01ScanBarcodeError: ['value'],

  failDel01SearchDocument: ['search'],
  failDel01SearchDocumentLoading: ['boolean'],
  failDel01SearchDocumentSuccess: ['options'],

  failDel01CreateFailDel01: ['formikBag', 'formDetail', 'outbOrdHdrs'],
  failDel01CreateFailDel01Success: ['newDocuments'],

  failDel01UpOutbOrd: ['outbOrdHdr'],
  failDel01DownOutbOrd: ['outbOrdHdr'],
  failDel01UpdateOutbOrds: ['outbOrdHdrs']
});

export const FailDel01Types = Types;
export default Creators;
