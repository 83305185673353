/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { SlsOrdSync01Types } from './Actions';
import INITIAL_STATE from './InitialState';

export const slsOrdSync01ShowSyncSettingLoading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const slsOrdSync01ShowSyncSettingSuccess = (state, { syncSetting }) => ({
  ...state,
  syncSetting
});

export const slsOrdSync01ShowBatchJobStatusSuccess = (state, { batchJobStatus }) => ({
  ...state,
  batchJobStatus
});

export const slsOrdSync01SyncLoading = (state, { boolean }) => ({
  ...state,
  syncIsLoading: boolean
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [SlsOrdSync01Types.SLS_ORD_SYNC01_SHOW_SYNC_SETTING_LOADING]: slsOrdSync01ShowSyncSettingLoading,
  [SlsOrdSync01Types.SLS_ORD_SYNC01_SHOW_SYNC_SETTING_SUCCESS]: slsOrdSync01ShowSyncSettingSuccess,
  [SlsOrdSync01Types.SLS_ORD_SYNC01_SHOW_BATCH_JOB_STATUS_SUCCESS]: slsOrdSync01ShowBatchJobStatusSuccess,
  [SlsOrdSync01Types.SLS_ORD_SYNC01_SYNC_LOADING]: slsOrdSync01SyncLoading
});
