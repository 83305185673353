import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  outbOrdReservedStockReportInitOutbOrdReservedStock: null,
  outbOrdReservedStockReportInitOutbOrdReservedStockSuccess: ['criteria'],
  outbOrdReservedStockReportOutbOrdReservedStock: ['formikBag', 'criteria'],
  outbOrdReservedStockReportOutbOrdReservedStockSuccess: ['criteria', 'reportData'],
  outbOrdReservedStockReportReportLoading: ['boolean'],

  outbOrdReservedStockReportFetchItemOptions: ['search'],
  outbOrdReservedStockReportFetchItemOptionLoading: ['boolean'],
  outbOrdReservedStockReportFetchItemOptionSuccess: ['options'],

  outbOrdReservedStockReportFetchItemGroup01Options: ['search'],
  outbOrdReservedStockReportFetchItemGroup01OptionLoading: ['boolean'],
  outbOrdReservedStockReportFetchItemGroup01OptionSuccess: ['options'],

  outbOrdReservedStockReportFetchItemGroup02Options: ['search'],
  outbOrdReservedStockReportFetchItemGroup02OptionLoading: ['boolean'],
  outbOrdReservedStockReportFetchItemGroup02OptionSuccess: ['options'],

  outbOrdReservedStockReportFetchItemGroup03Options: ['search'],
  outbOrdReservedStockReportFetchItemGroup03OptionLoading: ['boolean'],
  outbOrdReservedStockReportFetchItemGroup03OptionSuccess: ['options'],

  outbOrdReservedStockReportFetchStorageBinOptions: ['search'],
  outbOrdReservedStockReportFetchStorageBinOptionLoading: ['boolean'],
  outbOrdReservedStockReportFetchStorageBinOptionSuccess: ['options'],

  outbOrdReservedStockReportFetchStorageRowOptions: ['search'],
  outbOrdReservedStockReportFetchStorageRowOptionLoading: ['boolean'],
  outbOrdReservedStockReportFetchStorageRowOptionSuccess: ['options'],

  outbOrdReservedStockReportFetchStorageBayOptions: ['search'],
  outbOrdReservedStockReportFetchStorageBayOptionLoading: ['boolean'],
  outbOrdReservedStockReportFetchStorageBayOptionSuccess: ['options'],

  outbOrdReservedStockReportFetchLocationOptions: ['search'],
  outbOrdReservedStockReportFetchLocationOptionLoading: ['boolean'],
  outbOrdReservedStockReportFetchLocationOptionSuccess: ['options'],

  outbOrdReservedStockReportFetchDivisionOptions: ['search'],
  outbOrdReservedStockReportFetchDivisionOptionLoading: ['boolean'],
  outbOrdReservedStockReportFetchDivisionOptionSuccess: ['options'],

  outbOrdReservedStockReportFetchDeliveryPointOptions: ['search'],
  outbOrdReservedStockReportFetchDeliveryPointOptionLoading: ['boolean'],
  outbOrdReservedStockReportFetchDeliveryPointOptionSuccess: ['options']
});

export const OutbOrdReservedStockReportTypes = Types;
export default Creators;
