import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  inbOrdIndexResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  inbOrdIndexFetchInbOrdIndex: ['currentPage', 'sorts', 'filters', 'pageSize'],
  inbOrdIndexFetchInbOrdIndexLoading: ['boolean'],
  inbOrdIndexFetchInbOrdIndexSuccess: ['documents', 'currentPage', 'lastPage', 'total', 'pageSize'],
  inbOrdIndexAddSelectedDocuments: ['selectedDocuments'],
  inbOrdIndexRemoveSelectedDocuments: ['selectedDocuments'],
  inbOrdIndexSetWorkspaceVisible: ['boolean'],
  inbOrdIndexGoToDocument: ['hdrId'],
  inbOrdIndexGoToAudit: ['hdrId']
});

export const InbOrdIndexTypes = Types;
export default Creators;
