/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { PickList01Types } from './Actions';
import INITIAL_STATE from './InitialState';

export const pickList01ResetTimestamp = (state, { currentPage, sorts, filters, pageSize }) => ({
  ...state,
  currentPage: currentPage || state.currentPage,
  sorts: sorts || state.sorts,
  filters: filters || state.filters,
  pageSize: pageSize || state.pageSize,
  timestamp: Date.now(),
  // selectedDocuments: INITIAL_STATE.selectedDocuments,
  toStorageBinOptions: INITIAL_STATE.toStorageBinOptions,
  toStorageBinOption: INITIAL_STATE.toStorageBinOption
});

export const pickList01FetchPickList01Loading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const pickList01FetchPickList01Success = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  documents,
  currentPage,
  lastPage,
  total,
  pageSize
});

export const pickList01AddSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = state.selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: [
      ...state.selectedDocuments,
      ...selectedDocuments.filter(data => {
        return !selectIds.includes(data.id);
      })
    ]
  };
};

export const pickList01RemoveSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: state.selectedDocuments.filter(data => {
      return !selectIds.includes(data.id);
    })
  };
};

export const pickList01SetWorkspaceVisible = (state, { boolean }) => ({
  ...state,
  workspaceIsVisible: boolean
});

export const pickList01CreatePickList01Loading = (state, { boolean }) => ({
  ...state,
  createIsLoading: boolean
});

export const pickList01CreatePickList01Success = (state, { newDocuments }) => ({
  ...state,
  timestamp: Date.now(),
  selectedDocuments: [],
  newDocuments
});

export const pickList01FetchToStorageBinOptionLoading = (state, { boolean }) => ({
  ...state,
  toStorageBinIsLoading: boolean
});

export const pickList01FetchToStorageBinOptionSuccess = (state, { options }) => ({
  ...state,
  toStorageBinOptions: options
});

export const pickList01SetToStorageBinOption = (state, { option }) => ({
  ...state,
  toStorageBinOption: option
});

export const pickList01SetExpandedRows = (state, { expandedRows }) => ({
  ...state,
  expandedRows
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [PickList01Types.PICK_LIST01_RESET_TIMESTAMP]: pickList01ResetTimestamp,
  [PickList01Types.PICK_LIST01_FETCH_PICK_LIST01_LOADING]: pickList01FetchPickList01Loading,
  [PickList01Types.PICK_LIST01_FETCH_PICK_LIST01_SUCCESS]: pickList01FetchPickList01Success,
  [PickList01Types.PICK_LIST01_ADD_SELECTED_DOCUMENTS]: pickList01AddSelectedDocuments,
  [PickList01Types.PICK_LIST01_REMOVE_SELECTED_DOCUMENTS]: pickList01RemoveSelectedDocuments,
  [PickList01Types.PICK_LIST01_SET_WORKSPACE_VISIBLE]: pickList01SetWorkspaceVisible,
  [PickList01Types.PICK_LIST01_CREATE_PICK_LIST01_LOADING]: pickList01CreatePickList01Loading,
  [PickList01Types.PICK_LIST01_CREATE_PICK_LIST01_SUCCESS]: pickList01CreatePickList01Success,
  [PickList01Types.PICK_LIST01_FETCH_TO_STORAGE_BIN_OPTION_LOADING]: pickList01FetchToStorageBinOptionLoading,
  [PickList01Types.PICK_LIST01_FETCH_TO_STORAGE_BIN_OPTION_SUCCESS]: pickList01FetchToStorageBinOptionSuccess,
  [PickList01Types.PICK_LIST01_SET_TO_STORAGE_BIN_OPTION]: pickList01SetToStorageBinOption,
  [PickList01Types.PICK_LIST01_SET_EXPANDED_ROWS]: pickList01SetExpandedRows
});
