/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { OutboundExpiryReportTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const outboundExpiryReportReportLoading = (state, { boolean }) => ({
  ...state,
  reportIsLoading: boolean
});

export const outboundExpiryReportInitOutboundExpirySuccess = (state, { criteria }) => ({
  ...state,
  criteria
});

export const outboundExpiryReportOutboundExpirySuccess = (state, { criteria, reportData }) => ({
  ...state,
  criteria,
  reportData
});

export const outboundExpiryReportFetchDivisionOptionLoading = (state, { boolean }) => ({
  ...state,
  divisionIsLoading: boolean
});

export const outboundExpiryReportFetchDivisionOptionSuccess = (state, { options }) => ({
  ...state,
  divisionOptions: options
});

export const outboundExpiryReportFetchDeliveryPointOptionLoading = (state, { boolean }) => ({
  ...state,
  deliveryPointIsLoading: boolean
});

export const outboundExpiryReportFetchDeliveryPointOptionSuccess = (state, { options }) => ({
  ...state,
  deliveryPointOptions: options
});

export const outboundExpiryReportFetchDebtorOptionLoading = (state, { boolean }) => ({
  ...state,
  debtorIsLoading: boolean
});

export const outboundExpiryReportFetchDebtorOptionSuccess = (state, { options }) => ({
  ...state,
  debtorOptions: options
});

export const outboundExpiryReportFetchItemOptionLoading = (state, { boolean }) => ({
  ...state,
  itemIsLoading: boolean
});

export const outboundExpiryReportFetchItemOptionSuccess = (state, { options }) => ({
  ...state,
  itemOptions: options
});

export const outboundExpiryReportFetchItemGroup01OptionLoading = (state, { boolean }) => ({
  ...state,
  itemGroup01IsLoading: boolean
});

export const outboundExpiryReportFetchItemGroup01OptionSuccess = (state, { options }) => ({
  ...state,
  itemGroup01Options: options
});

export const outboundExpiryReportFetchItemGroup02OptionLoading = (state, { boolean }) => ({
  ...state,
  itemGroup02IsLoading: boolean
});

export const outboundExpiryReportFetchItemGroup02OptionSuccess = (state, { options }) => ({
  ...state,
  itemGroup02Options: options
});

export const outboundExpiryReportFetchItemGroup03OptionLoading = (state, { boolean }) => ({
  ...state,
  itemGroup03IsLoading: boolean
});

export const outboundExpiryReportFetchItemGroup03OptionSuccess = (state, { options }) => ({
  ...state,
  itemGroup03Options: options
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [OutboundExpiryReportTypes.OUTBOUND_EXPIRY_REPORT_REPORT_LOADING]: outboundExpiryReportReportLoading,
  [OutboundExpiryReportTypes.OUTBOUND_EXPIRY_REPORT_INIT_OUTBOUND_EXPIRY_SUCCESS]: outboundExpiryReportInitOutboundExpirySuccess,
  [OutboundExpiryReportTypes.OUTBOUND_EXPIRY_REPORT_OUTBOUND_EXPIRY_SUCCESS]: outboundExpiryReportOutboundExpirySuccess,

  [OutboundExpiryReportTypes.OUTBOUND_EXPIRY_REPORT_FETCH_DIVISION_OPTION_LOADING]: outboundExpiryReportFetchDivisionOptionLoading,
  [OutboundExpiryReportTypes.OUTBOUND_EXPIRY_REPORT_FETCH_DIVISION_OPTION_SUCCESS]: outboundExpiryReportFetchDivisionOptionSuccess,

  [OutboundExpiryReportTypes.OUTBOUND_EXPIRY_REPORT_FETCH_DELIVERY_POINT_OPTION_LOADING]: outboundExpiryReportFetchDeliveryPointOptionLoading,
  [OutboundExpiryReportTypes.OUTBOUND_EXPIRY_REPORT_FETCH_DELIVERY_POINT_OPTION_SUCCESS]: outboundExpiryReportFetchDeliveryPointOptionSuccess,

  [OutboundExpiryReportTypes.OUTBOUND_EXPIRY_REPORT_FETCH_DEBTOR_OPTION_LOADING]: outboundExpiryReportFetchDebtorOptionLoading,
  [OutboundExpiryReportTypes.OUTBOUND_EXPIRY_REPORT_FETCH_DEBTOR_OPTION_SUCCESS]: outboundExpiryReportFetchDebtorOptionSuccess,

  [OutboundExpiryReportTypes.OUTBOUND_EXPIRY_REPORT_FETCH_ITEM_OPTION_LOADING]: outboundExpiryReportFetchItemOptionLoading,
  [OutboundExpiryReportTypes.OUTBOUND_EXPIRY_REPORT_FETCH_ITEM_OPTION_SUCCESS]: outboundExpiryReportFetchItemOptionSuccess,

  [OutboundExpiryReportTypes.OUTBOUND_EXPIRY_REPORT_FETCH_ITEM_GROUP01_OPTION_LOADING]: outboundExpiryReportFetchItemGroup01OptionLoading,
  [OutboundExpiryReportTypes.OUTBOUND_EXPIRY_REPORT_FETCH_ITEM_GROUP01_OPTION_SUCCESS]: outboundExpiryReportFetchItemGroup01OptionSuccess,

  [OutboundExpiryReportTypes.OUTBOUND_EXPIRY_REPORT_FETCH_ITEM_GROUP02_OPTION_LOADING]: outboundExpiryReportFetchItemGroup02OptionLoading,
  [OutboundExpiryReportTypes.OUTBOUND_EXPIRY_REPORT_FETCH_ITEM_GROUP02_OPTION_SUCCESS]: outboundExpiryReportFetchItemGroup02OptionSuccess,

  [OutboundExpiryReportTypes.OUTBOUND_EXPIRY_REPORT_FETCH_ITEM_GROUP03_OPTION_LOADING]: outboundExpiryReportFetchItemGroup03OptionLoading,
  [OutboundExpiryReportTypes.OUTBOUND_EXPIRY_REPORT_FETCH_ITEM_GROUP03_OPTION_SUCCESS]: outboundExpiryReportFetchItemGroup03OptionSuccess
});
