import { put, call, select, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import { push } from 'connected-react-router';
import ApiService from '../Services/ApiService';
import UtilService from '../Services/UtilService';
import AppActions from '../Stores/App/Actions';
import UserList01Actions, { UserList01Types } from '../Stores/UserList01/Actions';

const getAppStore = state => state.app;

const getUserList01SelectedResourceStore = state => state.userList01.selectedResource;

export function* userList01FetchUserList01({ currentPage, sorts, filters, pageSize }) {
  try {
    yield put(UserList01Actions.userList01FetchUserList01Loading(true));

    const processedSorts = [];
    Object.entries(sorts).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      processedSorts.push(`${key}:${value === 'ascend' ? 'ASC' : 'DESC'}`);
    });

    const processedFilters = [];
    Object.entries(filters).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      if (value) {
        processedFilters.push(`${key}:${value}`);
      }
    });

    const app = yield select(getAppStore);
    const getData = {
      page: currentPage,
      sorts: processedSorts,
      filters: processedFilters,
      pageSize
    };

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `user/indexProcess/USER_LIST_01`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      // if nowCurrentPage is more than lastPage, then nowCurrentPage = lastPage
      let nowCurrentPage = result.data.current_page;
      if (nowCurrentPage > result.data.last_page) {
        nowCurrentPage = result.data.last_page;
      }

      yield put(
        UserList01Actions.userList01FetchUserList01Success(
          result.data.data,
          nowCurrentPage,
          result.data.last_page,
          result.data.total,
          result.data.per_page
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(UserList01Actions.userList01FetchUserList01Loading(false));
  }
}

export function* userList01GoToResource({ resId }) {
  const app = yield select(getAppStore);
  yield put(push(`${app.appPath}/userDetail/update/${resId}`));
}

export function* userList01RemoveRole({ resId, roleId }) {
  try {
    yield put(UserList01Actions.userList01ManageRoleLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      data: [{ role_id: roleId }]
    };

    const result = yield call(
      ApiService.deleteApi, // function
      app.apiUrl,
      `user/deleteRoles/${resId}`,
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      const userList01SelectedResource = yield select(getUserList01SelectedResourceStore);

      const { roles: oldRoles } = userList01SelectedResource;

      const { roles: retRoles, deleted_roles: retDeletedRoles } = result.data;

      const processed = UtilService.processResources(oldRoles, retRoles, retDeletedRoles);

      yield put(UserList01Actions.userList01ManageRoleSuccess(processed.resources));

      yield put(UserList01Actions.userList01ResetTimestamp());

      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_SUCCESS_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(UserList01Actions.userList01ManageRoleLoading(false));
  }
}

export function* userList01FetchRoleOptions({ resId, search }) {
  try {
    yield put(UserList01Actions.userList01FetchRoleOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: [
        {
          field: 'user_id',
          operator: 'NOT IN',
          value: resId
        }
      ]
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `role/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({ value: d.id, label: `${d.desc_01}` }));

      yield put(UserList01Actions.userList01FetchRoleOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(UserList01Actions.userList01FetchRoleOptionLoading(false));
  }
}

export function* userList01AddRole({ resId, roleId }) {
  try {
    yield put(UserList01Actions.userList01ManageRoleLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      data: [{ role_id: roleId }]
    };

    const result = yield call(
      ApiService.putApi, // function
      app.apiUrl,
      `user/updateRoles/${resId}`,
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      const userList01SelectedResource = yield select(getUserList01SelectedResourceStore);

      const { roles: oldRoles } = userList01SelectedResource;

      const { roles: retRoles, deleted_roles: retDeletedRoles } = result.data;

      const processed = UtilService.processResources(oldRoles, retRoles, retDeletedRoles);

      yield put(UserList01Actions.userList01ManageRoleSuccess(processed.resources));

      yield put(UserList01Actions.userList01ResetTimestamp());

      // clear the select2 options and option
      yield put(UserList01Actions.userList01FetchRoleOptionSuccess([]));
      yield put(UserList01Actions.userList01SetRoleOption({ value: 0, label: '' }));

      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_SUCCESS_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(UserList01Actions.userList01ManageRoleLoading(false));
  }
}

export function* userList01GoToAudit({ resId }) {
  const app = yield select(getAppStore);
  yield put(push(`${app.appPath}/auditUser/${resId}`));
}

export function* userList01NewResource() {
  const app = yield select(getAppStore);
  yield put(push(`${app.appPath}/userDetail/create/${app.curSiteFlowId}`));
}

export const saga = [
  takeLatest(UserList01Types.USER_LIST01_GO_TO_RESOURCE, userList01GoToResource),
  takeLatest(UserList01Types.USER_LIST01_FETCH_USER_LIST01, userList01FetchUserList01),
  takeLatest(UserList01Types.USER_LIST01_REMOVE_ROLE, userList01RemoveRole),
  takeLatest(UserList01Types.USER_LIST01_ADD_ROLE, userList01AddRole),
  takeLatest(UserList01Types.USER_LIST01_FETCH_ROLE_OPTIONS, userList01FetchRoleOptions),
  takeLatest(UserList01Types.USER_LIST01_GO_TO_AUDIT, userList01GoToAudit),
  takeLatest(UserList01Types.USER_LIST01_NEW_RESOURCE, userList01NewResource)
];
