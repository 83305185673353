import { put, call, select, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import FileSaver from 'file-saver';
import ApiService from '../Services/ApiService';
import AppActions from '../Stores/App/Actions';
import ItemManufactureExcelActions, {
  ItemManufactureExcelTypes
} from '../Stores/ItemManufactureExcel/Actions';
import ItemDetailActions from '../Stores/ItemDetail/Actions';

const getAppStore = state => state.app;
const getItemDetailStore = state => state.itemDetail;

export function* itemManufactureExcelShowBatchJobStatus() {
  try {
    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `batchJobStatus/showBatchJobStatus/ITEM_EXCEL_01`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(
        ItemManufactureExcelActions.itemManufactureExcelShowBatchJobStatusSuccess(result.data)
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  }
}

export function* itemManufactureExcelUploadExcel({ file, procType }) {
  try {
    yield put(ItemManufactureExcelActions.itemManufactureExcelUploadLoading(true));

    const app = yield select(getAppStore);

    // eslint-disable-next-line no-undef
    const postData = new FormData();
    postData.append('file', file);

    const getData = {};

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `item/uploadProcess/${procType}/${app.curSiteFlowId}/${app.curDivisionId}`,
      app.token,
      postData,
      getData,
      'multipart/form-data'
    );

    if (result.isSuccess === true) {
      // result.data is total
      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_SUCCESS_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(ItemManufactureExcelActions.itemManufactureExcelUploadLoading(false));
  }
}

export function* itemManufactureExcelDownloadExcel({ itemType }) {
  try {
    yield put(ItemManufactureExcelActions.itemManufactureExcelUploadLoading(true));

    const app = yield select(getAppStore);

    const getData = {
      itemType
    };

    const result = yield call(
      ApiService.downloadGetApi, // function
      app.apiUrl,
      `item/manufacture/download/DOWNLOAD_MANUFACTURE_ITEM/${app.curSiteFlowId}/${app.curDivisionId}`,
      app.token,
      getData,
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    );

    if (result.isSuccess === true) {
      FileSaver.saveAs(result.data, 'ITEM_EXCEL_01.XLSX');
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(ItemManufactureExcelActions.itemManufactureExcelUploadLoading(false));
  }
}

export function* itemManufactureExcelDownloadItemBatches({ itemId }) {
  try {
    yield put(ItemManufactureExcelActions.itemManufactureExcelUploadLoading(true));

    const app = yield select(getAppStore);

    const getData = {
      itemId
    };

    const result = yield call(
      ApiService.downloadGetApi, // function
      app.apiUrl,
      `item/batches/download/${itemId}`,
      app.token,
      getData,
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    );

    if (result.isSuccess === true) {
      FileSaver.saveAs(result.data, 'ITEM_BATCHES_01.XLSX');
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(ItemManufactureExcelActions.itemManufactureExcelUploadLoading(false));
  }
}

export function* itemManufactureExcelUploadItemBatches({ file }) {
  try {
    yield put(ItemManufactureExcelActions.itemManufactureExcelUploadLoading(true));

    const app = yield select(getAppStore);
    const itemDetail = yield select(getItemDetailStore);

    // eslint-disable-next-line no-undef
    const postData = new FormData();
    postData.append('file', file);

    const getData = {};

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `item/batches/upload`,
      app.token,
      postData,
      getData,
      'multipart/form-data'
    );

    if (result.isSuccess === true) {
      // result.data is total
      yield put(
        ItemDetailActions.itemDetailResetItemBatchTimestamp(
          itemDetail.currentPage,
          itemDetail.sorts,
          itemDetail.filters,
          itemDetail.pageSize
        )
      );
      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_SUCCESS_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(ItemManufactureExcelActions.itemManufactureExcelUploadLoading(false));
  }
}

export function* itemManufactureExcelDownloadItemDocNo() {
  try {
    yield put(ItemManufactureExcelActions.itemManufactureExcelUploadLoading(true));

    const app = yield select(getAppStore);

    const getData = {};

    const result = yield call(
      ApiService.downloadGetApi, // function
      app.apiUrl,
      `item/docNo/download/${app.curDivisionId}`,
      app.token,
      getData,
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    );

    if (result.isSuccess === true) {
      FileSaver.saveAs(result.data, 'ITEM_DOC_NO_01.XLSX');
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(ItemManufactureExcelActions.itemManufactureExcelUploadLoading(false));
  }
}

export function* itemManufactureExcelUploadItemDocNo({ file }) {
  try {
    yield put(ItemManufactureExcelActions.itemManufactureExcelUploadLoading(true));

    const app = yield select(getAppStore);
    const itemDetail = yield select(getItemDetailStore);

    // eslint-disable-next-line no-undef
    const postData = new FormData();
    postData.append('file', file);

    const getData = {};

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `item/docNo/upload/${app.curDivisionId}`,
      app.token,
      postData,
      getData,
      'multipart/form-data'
    );

    if (result.isSuccess === true) {
      // result.data is total
      yield put(
        ItemDetailActions.itemDetailResetItemBatchTimestamp(
          itemDetail.currentPage,
          itemDetail.sorts,
          itemDetail.filters,
          itemDetail.pageSize
        )
      );
      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_SUCCESS_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(ItemManufactureExcelActions.itemManufactureExcelUploadLoading(false));
  }
}

export const saga = [
  takeLatest(
    ItemManufactureExcelTypes.ITEM_MANUFACTURE_EXCEL_SHOW_BATCH_JOB_STATUS,
    itemManufactureExcelShowBatchJobStatus
  ),
  takeLatest(
    ItemManufactureExcelTypes.ITEM_MANUFACTURE_EXCEL_UPLOAD_EXCEL,
    itemManufactureExcelUploadExcel
  ),
  takeLatest(
    ItemManufactureExcelTypes.ITEM_MANUFACTURE_EXCEL_DOWNLOAD_EXCEL,
    itemManufactureExcelDownloadExcel
  ),
  takeLatest(
    ItemManufactureExcelTypes.ITEM_MANUFACTURE_EXCEL_DOWNLOAD_ITEM_BATCHES,
    itemManufactureExcelDownloadItemBatches
  ),
  takeLatest(
    ItemManufactureExcelTypes.ITEM_MANUFACTURE_EXCEL_UPLOAD_ITEM_BATCHES,
    itemManufactureExcelUploadItemBatches
  ),
  takeLatest(
    ItemManufactureExcelTypes.ITEM_MANUFACTURE_EXCEL_DOWNLOAD_ITEM_DOC_NO,
    itemManufactureExcelDownloadItemDocNo
  ),
  takeLatest(
    ItemManufactureExcelTypes.ITEM_MANUFACTURE_EXCEL_UPLOAD_ITEM_DOC_NO,
    itemManufactureExcelUploadItemDocNo
  )
];
