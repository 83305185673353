import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  countAdjAnalysisReportInitCountAdjAnalysis: null,
  countAdjAnalysisReportInitCountAdjAnalysisSuccess: ['criteria'],
  countAdjAnalysisReportCountAdjAnalysis: ['formikBag', 'criteria'],
  countAdjAnalysisReportCountAdjAnalysisSuccess: ['criteria', 'reportData'],
  countAdjAnalysisReportReportLoading: ['boolean'],

  countAdjAnalysisReportFetchCompanyOptions: ['search'],
  countAdjAnalysisReportFetchCompanyOptionLoading: ['boolean'],
  countAdjAnalysisReportFetchCompanyOptionSuccess: ['options'],

  countAdjAnalysisReportFetchItemOptions: ['search'],
  countAdjAnalysisReportFetchItemOptionLoading: ['boolean'],
  countAdjAnalysisReportFetchItemOptionSuccess: ['options'],

  countAdjAnalysisReportFetchItemGroup01Options: ['search'],
  countAdjAnalysisReportFetchItemGroup01OptionLoading: ['boolean'],
  countAdjAnalysisReportFetchItemGroup01OptionSuccess: ['options'],

  countAdjAnalysisReportFetchItemGroup02Options: ['search'],
  countAdjAnalysisReportFetchItemGroup02OptionLoading: ['boolean'],
  countAdjAnalysisReportFetchItemGroup02OptionSuccess: ['options'],

  countAdjAnalysisReportFetchItemGroup03Options: ['search'],
  countAdjAnalysisReportFetchItemGroup03OptionLoading: ['boolean'],
  countAdjAnalysisReportFetchItemGroup03OptionSuccess: ['options'],

  countAdjAnalysisReportFetchStorageBinOptions: ['search'],
  countAdjAnalysisReportFetchStorageBinOptionLoading: ['boolean'],
  countAdjAnalysisReportFetchStorageBinOptionSuccess: ['options'],

  countAdjAnalysisReportFetchStorageRowOptions: ['search'],
  countAdjAnalysisReportFetchStorageRowOptionLoading: ['boolean'],
  countAdjAnalysisReportFetchStorageRowOptionSuccess: ['options'],

  countAdjAnalysisReportFetchStorageBayOptions: ['search'],
  countAdjAnalysisReportFetchStorageBayOptionLoading: ['boolean'],
  countAdjAnalysisReportFetchStorageBayOptionSuccess: ['options'],

  countAdjAnalysisReportFetchLocationOptions: ['search'],
  countAdjAnalysisReportFetchLocationOptionLoading: ['boolean'],
  countAdjAnalysisReportFetchLocationOptionSuccess: ['options']
});

export const CountAdjAnalysisReportTypes = Types;
export default Creators;
