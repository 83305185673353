import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  stockReorderPlanningReportInitStockReorderPlanning: null,
  stockReorderPlanningReportInitStockReorderPlanningSuccess: ['criteria'],
  stockReorderPlanningReportStockReorderPlanning: ['formikBag', 'criteria'],
  stockReorderPlanningReportStockReorderPlanningSuccess: ['criteria', 'reportData'],
  stockReorderPlanningReportReportLoading: ['boolean'],

  stockReorderPlanningReportFetchCompanyOptions: ['search'],
  stockReorderPlanningReportFetchCompanyOptionLoading: ['boolean'],
  stockReorderPlanningReportFetchCompanyOptionSuccess: ['options'],

  stockReorderPlanningReportFetchItemOptions: ['search'],
  stockReorderPlanningReportFetchItemOptionLoading: ['boolean'],
  stockReorderPlanningReportFetchItemOptionSuccess: ['options'],

  stockReorderPlanningReportFetchItemGroup01Options: ['search'],
  stockReorderPlanningReportFetchItemGroup01OptionLoading: ['boolean'],
  stockReorderPlanningReportFetchItemGroup01OptionSuccess: ['options'],

  stockReorderPlanningReportFetchItemGroup02Options: ['search'],
  stockReorderPlanningReportFetchItemGroup02OptionLoading: ['boolean'],
  stockReorderPlanningReportFetchItemGroup02OptionSuccess: ['options'],

  stockReorderPlanningReportFetchItemGroup03Options: ['search'],
  stockReorderPlanningReportFetchItemGroup03OptionLoading: ['boolean'],
  stockReorderPlanningReportFetchItemGroup03OptionSuccess: ['options'],

  stockReorderPlanningReportFetchLocationOptions: ['search'],
  stockReorderPlanningReportFetchLocationOptionLoading: ['boolean'],
  stockReorderPlanningReportFetchLocationOptionSuccess: ['options']
});

export const StockReorderPlanningReportTypes = Types;
export default Creators;
