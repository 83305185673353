import { put, call, select, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import ApiService from '../Services/ApiService';
import AppActions from '../Stores/App/Actions';
import LspSupplierReturnProcessActions, {
  LspSupplierReturnProcessTypes
} from '../Stores/LspSupplierReturnProcess/Actions';

const getAppStore = state => state.app;

// eslint-disable-next-line import/prefer-default-export
export function* lspSupplierReturnProcessFetchLspSupplierReturnProcess() {
  try {
    yield put(
      LspSupplierReturnProcessActions.lspSupplierReturnProcessFetchLspSupplierReturnProcessLoading(
        true
      )
    );

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `division/indexLspSupplierReturnFlow/${app.curDivisionId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );
    if (result.isSuccess === true) {
      yield put(
        LspSupplierReturnProcessActions.lspSupplierReturnProcessFetchLspSupplierReturnProcessSuccess(
          result.data
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(
      LspSupplierReturnProcessActions.lspSupplierReturnProcessFetchLspSupplierReturnProcessLoading(
        false
      )
    );
  }
}

export const saga = [
  takeLatest(
    LspSupplierReturnProcessTypes.LSP_SUPPLIER_RETURN_PROCESS_FETCH_LSP_SUPPLIER_RETURN_PROCESS,
    lspSupplierReturnProcessFetchLspSupplierReturnProcess
  )
];
