import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import treeChanges from 'tree-changes';

import { AuditOutlined, EditOutlined, LaptopOutlined, ReloadOutlined } from '@ant-design/icons';

import { Row, Col, Table, Button, Badge } from 'antd';

import AntDesignTable from '../../Components/AntDesignTable';
import LocationList01Actions from '../../Stores/LocationList01/Actions';

class LocationList01Table extends React.PureComponent {
  constructor() {
    super();

    this.useOnTableChange = this.useOnTableChange.bind(this);
    this.useShowWorkspace = this.useShowWorkspace.bind(this);

    this.handleSearch = this.handleSearch.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.getResourceColumns = this.getResourceColumns.bind(this);
  }

  componentDidMount() {
    const { curSiteFlowId, setWorkspaceVisible, resetTimestamp } = this.props;

    if (curSiteFlowId > 0) {
      setWorkspaceVisible(false);
      resetTimestamp();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      curSiteFlowId,
      resetTimestamp,
      fetchLocationList01,
      currentPage,
      sorts,
      filters,
      pageSize
    } = this.props;

    const { changed } = treeChanges(prevProps, this.props);

    if (changed('curSiteFlowId')) {
      resetTimestamp();
    }

    if (changed('timestamp')) {
      if (curSiteFlowId > 0) {
        fetchLocationList01(currentPage, sorts, filters, pageSize);
      }
    }
  }

  componentWillUnmount() {}

  handleSearch(selectedKeys, confirm) {
    // eslint-disable-next-line no-unused-vars
    const { intl } = this.props;

    confirm();
  }

  handleReset(clearFilters) {
    // eslint-disable-next-line no-unused-vars
    const { intl } = this.props;

    clearFilters();
  }

  getResourceColumns() {
    const { intl, sorts, filters, goToResource, goToAudit } = this.props;

    return [
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'code' }),
        // sort field
        dataIndex: 'code',
        ...AntDesignTable.getColumnSortProps(sorts, 'code'),
        // filter field
        key: 'code',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'code' }),
          'code',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.code}</>
      },
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'location_type' }),
        // sort field
        dataIndex: 'str_location_type',
        ...AntDesignTable.getColumnSortProps(sorts, 'str_location_type'),
        // filter field
        key: 'str_location_type',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'location_type' }),
          'str_location_type',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.str_location_type}</>
      },
      {
        width: 150,
        align: 'left',
        title: intl.formatMessage({ id: 'description' }),
        // sort field
        dataIndex: 'desc_01',
        ...AntDesignTable.getColumnSortProps(sorts, 'desc_01'),
        // filter field
        key: 'desc_01',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'description' }),
          'desc_01',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.desc_01}</>
      },
      {
        width: 200,
        align: 'left',
        title: intl.formatMessage({ id: 'address' }),
        // sort field
        dataIndex: 'address',
        sorter: (a, b) => `${a.unit_no}`.localeCompare(b.unit_no),
        // filter field
        key: 'address',
        render: (text, record) => (
          <>
            {record.unit_no}
            {record.building_name ? (
              <>
                <br />
                {record.building_name}
              </>
            ) : (
              ''
            )}
            {record.street_name ? (
              <>
                <br />
                {record.street_name}
              </>
            ) : (
              ''
            )}
            {record.district_01 ? (
              <>
                <br />
                {record.district_01}
              </>
            ) : (
              ''
            )}
            {record.district_02 ? (
              <>
                <br />
                {record.district_02}
              </>
            ) : (
              ''
            )}
            {record.postcode ? (
              <>
                <br />
                {record.postcode}
              </>
            ) : (
              ''
            )}
            {record.state_name ? (
              <>
                <br />
                {record.state_name}
              </>
            ) : (
              ''
            )}
            {record.country_name ? (
              <>
                <br />
                {record.country_name}
              </>
            ) : (
              ''
            )}
          </>
        )
      },
      {
        width: 50,
        fixed: 'right',
        title: '',
        key: 'action',
        render: (text, record) => (
          <>
            <Button type="dashed" icon={<EditOutlined />} onClick={() => goToResource(record.id)} />
            <Button type="dashed" icon={<AuditOutlined />} onClick={() => goToAudit(record.id)} />
          </>
        )
      }
    ];
  }

  useOnTableChange(pagination, filters, sorter) {
    const { resetTimestamp } = this.props;

    const processedFilters = AntDesignTable.processFilters(filters);
    const processedSorts = AntDesignTable.processSorts(sorter);

    resetTimestamp(pagination.current, processedSorts, processedFilters, pagination.pageSize);
  }

  useShowWorkspace() {
    const { setWorkspaceVisible } = this.props;

    setWorkspaceVisible(true);
  }

  render() {
    const {
      intl,
      curSiteFlowId,
      resetTimestamp,
      newResource,
      currentPage,
      pageSize,
      total,
      resources,
      fetchIsLoading,
      selectedResources,
      addSelectedResources,
      removeSelectedResources
    } = this.props;

    return (
      <>
        <Table
          size="small"
          // rowSelection={rowSelection}
          rowKey="id"
          pagination={{
            current: currentPage,
            pageSize: parseInt(pageSize, 10),
            total,
            showTotal: () => `${total} items`
          }}
          columns={this.getResourceColumns()}
          dataSource={resources}
          loading={fetchIsLoading}
          bordered
          rowSelection={{
            selectedRowKeys: selectedResources.map(value => value.id),
            onChange: () => {
              // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            },
            onSelect: (record, selected) => {
              // console.log(`onSelect record: `, record, 'selected: ', selected, 'selectedRows: ', selectedRows);
              if (selected === true) {
                addSelectedResources([record]);
              } else {
                removeSelectedResources([record]);
              }
            },
            onSelectAll: (selected, selectedRows, changeRows) => {
              // console.log('onSelectAll selected: ', selected, 'selectedRows: ', selectedRows, 'changeRows: ', changeRows);
              if (selected === true) {
                addSelectedResources(changeRows);
              } else {
                removeSelectedResources(changeRows);
              }
            },
            onSelectInvert: selectedRows => {
              // console.log('onSelectInvert selectedRows: ', selectedRows);
              removeSelectedResources(selectedRows);
            },
            getCheckboxProps: () => ({
              // Column configuration not to be checked
              disabled: true
              // name: record.name,
            })
          }}
          onChange={this.useOnTableChange}
          title={() => (
            <>
              <Row type="flex" justify="start" gutter={[0, 16]}>
                <Col span={1}>
                  <Button
                    type="primary"
                    // disabled={!isValid}
                    loading={fetchIsLoading}
                    onClick={() => resetTimestamp()}
                    icon={<ReloadOutlined />}
                  />
                </Col>
                <Col span={5}>
                  <Button
                    type="primary"
                    disabled={curSiteFlowId === 0}
                    loading={fetchIsLoading}
                    onClick={() => newResource()}
                    // icon={<PlusOutlined />}
                  >
                    {intl.formatMessage({ id: 'new' })}
                  </Button>
                </Col>
              </Row>
            </>
          )}
          scroll={{ x: 950 }}
        />

        <div
          style={{
            position: 'fixed',
            bottom: '50px',
            right: '50px'
          }}
        >
          <Badge count={selectedResources.length}>
            <Button
              size="large"
              type="primary"
              shape="circle"
              icon={<LaptopOutlined />}
              onClick={this.useShowWorkspace}
              loading={fetchIsLoading}
            />
          </Badge>
        </div>
      </>
    );
  }
}

LocationList01Table.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  resetTimestamp: PropTypes.func,
  goToResource: PropTypes.func,
  newResource: PropTypes.func,
  goToAudit: PropTypes.func,

  setWorkspaceVisible: PropTypes.func,

  addSelectedResources: PropTypes.func,
  removeSelectedResources: PropTypes.func,
  resources: PropTypes.arrayOf(PropTypes.object),
  selectedResources: PropTypes.arrayOf(PropTypes.object),

  sorts: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  filters: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),

  currentPage: PropTypes.number,
  pageSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  total: PropTypes.number,
  fetchIsLoading: PropTypes.bool,

  curSiteFlowId: PropTypes.number,
  fetchLocationList01: PropTypes.func
};

LocationList01Table.defaultProps = {
  intl: {},
  resetTimestamp() {},
  goToResource() {},
  newResource() {},
  goToAudit() {},

  setWorkspaceVisible() {},

  addSelectedResources() {},
  removeSelectedResources() {},
  resources: [],
  selectedResources: [],

  sorts: {},
  filters: {},

  currentPage: 1,
  pageSize: '20',
  total: 0,
  fetchIsLoading: false,

  curSiteFlowId: 0,
  fetchLocationList01() {}
};

const mapStateToProps = state => ({
  timestamp: state.locationList01.timestamp,
  workspaceIsVisible: state.locationList01.workspaceIsVisible,

  resources: state.locationList01.resources,
  selectedResources: state.locationList01.selectedResources,

  sorts: state.locationList01.sorts,
  filters: state.locationList01.filters,

  currentPage: state.locationList01.currentPage,
  pageSize: state.locationList01.pageSize,
  total: state.locationList01.total,
  fetchIsLoading: state.locationList01.fetchIsLoading,

  curSiteFlowId: state.app.curSiteFlowId
});

const mapDispatchToProps = dispatch => ({
  resetTimestamp: (currentPage, sorts, filters, pageSize) =>
    dispatch(
      LocationList01Actions.locationList01ResetTimestamp(currentPage, sorts, filters, pageSize)
    ),
  goToResource: hdrId => dispatch(LocationList01Actions.locationList01GoToResource(hdrId)),
  newResource: () => dispatch(LocationList01Actions.locationList01NewResource()),
  goToAudit: hdrId => dispatch(LocationList01Actions.locationList01GoToAudit(hdrId)),

  setWorkspaceVisible: boolean =>
    dispatch(LocationList01Actions.locationList01SetWorkspaceVisible(boolean)),

  addSelectedResources: selectedResources =>
    dispatch(LocationList01Actions.locationList01AddSelectedResources(selectedResources)),
  removeSelectedResources: selectedResources =>
    dispatch(LocationList01Actions.locationList01RemoveSelectedResources(selectedResources)),

  fetchLocationList01: (currentPage, sorts, filters, pageSize) =>
    dispatch(
      LocationList01Actions.locationList01FetchLocationList01(currentPage, sorts, filters, pageSize)
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(LocationList01Table));
