/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { WorkerPutAwayAnalysisReportTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const workerPutAwayAnalysisReportReportLoading = (state, { boolean }) => ({
  ...state,
  reportIsLoading: boolean
});

export const workerPutAwayAnalysisReportInitWorkerPutAwayAnalysisSuccess = (
  state,
  { criteria }
) => ({
  ...state,
  criteria
});

export const workerPutAwayAnalysisReportWorkerPutAwayAnalysisSuccess = (
  state,
  { criteria, reportData }
) => ({
  ...state,
  criteria,
  reportData
});

export const workerPutAwayAnalysisReportFetchUserOptionLoading = (state, { boolean }) => ({
  ...state,
  userIsLoading: boolean
});

export const workerPutAwayAnalysisReportFetchUserOptionSuccess = (state, { options }) => ({
  ...state,
  userOptions: options
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [WorkerPutAwayAnalysisReportTypes.WORKER_PUT_AWAY_ANALYSIS_REPORT_REPORT_LOADING]: workerPutAwayAnalysisReportReportLoading,
  [WorkerPutAwayAnalysisReportTypes.WORKER_PUT_AWAY_ANALYSIS_REPORT_INIT_WORKER_PUT_AWAY_ANALYSIS_SUCCESS]: workerPutAwayAnalysisReportInitWorkerPutAwayAnalysisSuccess,
  [WorkerPutAwayAnalysisReportTypes.WORKER_PUT_AWAY_ANALYSIS_REPORT_WORKER_PUT_AWAY_ANALYSIS_SUCCESS]: workerPutAwayAnalysisReportWorkerPutAwayAnalysisSuccess,

  [WorkerPutAwayAnalysisReportTypes.WORKER_PUT_AWAY_ANALYSIS_REPORT_FETCH_USER_OPTION_LOADING]: workerPutAwayAnalysisReportFetchUserOptionLoading,
  [WorkerPutAwayAnalysisReportTypes.WORKER_PUT_AWAY_ANALYSIS_REPORT_FETCH_USER_OPTION_SUCCESS]: workerPutAwayAnalysisReportFetchUserOptionSuccess
});
