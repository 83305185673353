import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  whseJob03DetailResetTimestamp: null,
  whseJob03DetailSetHdrId: ['hdrId'],

  whseJob03DetailInitHeader: null,
  whseJob03DetailShowHeader: ['hdrId'],
  whseJob03DetailShowHeaderSuccess: ['documentHeader'],

  whseJob03DetailUpdateHeader: ['formikBag', 'documentHeader'],
  whseJob03DetailCreateHeader: ['formikBag', 'documentHeader'],
  whseJob03DetailUpdateDocumentSuccess: ['documentHeader', 'documentDetails'],

  whseJob03DetailShowDocumentLoading: ['boolean'],
  whseJob03DetailTransitionToStatus: ['formikBag', 'hdrId', 'docStatus'],

  whseJob03DetailShowDetails: ['hdrId'],
  whseJob03DetailShowDetailsSuccess: ['documentDetails'],

  whseJob03DetailSetDetailVisible: ['boolean'],
  whseJob03DetailSetDocumentDetail: ['documentDetail'],

  whseJob03DetailUpdateDetails: ['formikBag', 'hdrId', 'documentDetails'],
  whseJob03DetailCreateDetail: ['formikBag', 'hdrId', 'documentDetail'],
  whseJob03DetailDeleteDetail: ['hdrId', 'documentDetail'],

  whseJob03DetailSetWhseJobType: ['whseJobType'],

  whseJob03DetailFetchCompanyOptions: ['search'],
  whseJob03DetailFetchCompanyOptionLoading: ['boolean'],
  whseJob03DetailFetchCompanyOptionSuccess: ['options'],

  whseJob03DetailFetchFrStorageBinOptions: ['search'],
  whseJob03DetailFetchFrStorageBinOptionLoading: ['boolean'],
  whseJob03DetailFetchFrStorageBinOptionSuccess: ['options'],

  whseJob03DetailFetchQuantBalOptions: ['companyId', 'storageBinId', 'search'],
  whseJob03DetailFetchQuantBalOptionLoading: ['boolean'],
  whseJob03DetailFetchQuantBalOptionSuccess: ['options'],
  whseJob03DetailChangeQuantBal: ['formikBag', 'hdrId', 'quantBalId'],

  whseJob03DetailFetchUomOptions: ['itemId', 'search'],
  whseJob03DetailFetchUomOptionLoading: ['boolean'],
  whseJob03DetailFetchUomOptionSuccess: ['options'],
  whseJob03DetailChangeUom: ['formikBag', 'hdrId', 'itemId', 'uomId'],

  whseJob03DetailFetchToStorageBinOptions: ['search'],
  whseJob03DetailFetchToStorageBinOptionLoading: ['boolean'],
  whseJob03DetailFetchToStorageBinOptionSuccess: ['options'],

  whseJob03DetailFetchWorkerOptions: ['search'],
  whseJob03DetailFetchWorkerOptionLoading: ['boolean'],
  whseJob03DetailFetchWorkerOptionSuccess: ['options']
});

export const WhseJob03DetailTypes = Types;
export default Creators;
