/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { LoadList0101Types } from './Actions';
import INITIAL_STATE from './InitialState';

export const loadList0101ResetTimestamp = (state, { currentPage, sorts, filters, pageSize }) => ({
  ...state,
  currentPage: currentPage || state.currentPage,
  sorts: sorts || state.sorts,
  filters: filters || state.filters,
  pageSize: pageSize || state.pageSize,
  timestamp: Date.now()
  // selectedDocuments: INITIAL_STATE.selectedDocuments
});

export const loadList0101FetchLoadList0101Loading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const loadList0101FetchLoadList0101Success = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  documents,
  currentPage,
  lastPage,
  total,
  pageSize
});

export const loadList0101AddSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = state.selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: [
      ...state.selectedDocuments,
      ...selectedDocuments.filter(data => {
        return !selectIds.includes(data.id);
      })
    ]
  };
};

export const loadList0101RemoveSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: state.selectedDocuments.filter(data => {
      return !selectIds.includes(data.id);
    })
  };
};

export const loadList0101SetWorkspaceVisible = (state, { boolean }) => ({
  ...state,
  workspaceIsVisible: boolean
});

export const loadList0101PrintLoadList0101Loading = (state, { boolean }) => ({
  ...state,
  printIsLoading: boolean
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [LoadList0101Types.LOAD_LIST0101_RESET_TIMESTAMP]: loadList0101ResetTimestamp,
  [LoadList0101Types.LOAD_LIST0101_FETCH_LOAD_LIST0101_LOADING]: loadList0101FetchLoadList0101Loading,
  [LoadList0101Types.LOAD_LIST0101_FETCH_LOAD_LIST0101_SUCCESS]: loadList0101FetchLoadList0101Success,
  [LoadList0101Types.LOAD_LIST0101_ADD_SELECTED_DOCUMENTS]: loadList0101AddSelectedDocuments,
  [LoadList0101Types.LOAD_LIST0101_REMOVE_SELECTED_DOCUMENTS]: loadList0101RemoveSelectedDocuments,
  [LoadList0101Types.LOAD_LIST0101_SET_WORKSPACE_VISIBLE]: loadList0101SetWorkspaceVisible,
  [LoadList0101Types.LOAD_LIST0101_PRINT_LOAD_LIST0101_LOADING]: loadList0101PrintLoadList0101Loading
});
