import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  outbOrdAnalysisReportInitOutbOrdAnalysis: null,
  outbOrdAnalysisReportInitOutbOrdAnalysisSuccess: ['criteria'],
  outbOrdAnalysisReportOutbOrdAnalysis: ['formikBag', 'criteria'],
  outbOrdAnalysisReportOutbOrdAnalysisSuccess: ['criteria', 'reportData'],
  outbOrdAnalysisReportReportLoading: ['boolean'],

  outbOrdAnalysisReportFetchItemOptions: ['search'],
  outbOrdAnalysisReportFetchItemOptionLoading: ['boolean'],
  outbOrdAnalysisReportFetchItemOptionSuccess: ['options'],

  outbOrdAnalysisReportFetchItemGroup01Options: ['search'],
  outbOrdAnalysisReportFetchItemGroup01OptionLoading: ['boolean'],
  outbOrdAnalysisReportFetchItemGroup01OptionSuccess: ['options'],

  outbOrdAnalysisReportFetchItemGroup02Options: ['search'],
  outbOrdAnalysisReportFetchItemGroup02OptionLoading: ['boolean'],
  outbOrdAnalysisReportFetchItemGroup02OptionSuccess: ['options'],

  outbOrdAnalysisReportFetchItemGroup03Options: ['search'],
  outbOrdAnalysisReportFetchItemGroup03OptionLoading: ['boolean'],
  outbOrdAnalysisReportFetchItemGroup03OptionSuccess: ['options'],

  outbOrdAnalysisReportFetchDivisionOptions: ['search'],
  outbOrdAnalysisReportFetchDivisionOptionLoading: ['boolean'],
  outbOrdAnalysisReportFetchDivisionOptionSuccess: ['options'],

  outbOrdAnalysisReportFetchDeliveryPointOptions: ['search'],
  outbOrdAnalysisReportFetchDeliveryPointOptionLoading: ['boolean'],
  outbOrdAnalysisReportFetchDeliveryPointOptionSuccess: ['options']
});

export const OutbOrdAnalysisReportTypes = Types;
export default Creators;
