/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { OutbOrdReservedStockReportTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const outbOrdReservedStockReportReportLoading = (state, { boolean }) => ({
  ...state,
  reportIsLoading: boolean
});

export const outbOrdReservedStockReportInitOutbOrdReservedStockSuccess = (state, { criteria }) => ({
  ...state,
  criteria
});

export const outbOrdReservedStockReportOutbOrdReservedStockSuccess = (
  state,
  { criteria, reportData }
) => ({
  ...state,
  criteria,
  reportData
});

export const outbOrdReservedStockReportFetchItemOptionLoading = (state, { boolean }) => ({
  ...state,
  itemIsLoading: boolean
});

export const outbOrdReservedStockReportFetchItemOptionSuccess = (state, { options }) => ({
  ...state,
  itemOptions: options
});

export const outbOrdReservedStockReportFetchItemGroup01OptionLoading = (state, { boolean }) => ({
  ...state,
  itemGroup01IsLoading: boolean
});

export const outbOrdReservedStockReportFetchItemGroup01OptionSuccess = (state, { options }) => ({
  ...state,
  itemGroup01Options: options
});

export const outbOrdReservedStockReportFetchItemGroup02OptionLoading = (state, { boolean }) => ({
  ...state,
  itemGroup02IsLoading: boolean
});

export const outbOrdReservedStockReportFetchItemGroup02OptionSuccess = (state, { options }) => ({
  ...state,
  itemGroup02Options: options
});

export const outbOrdReservedStockReportFetchItemGroup03OptionLoading = (state, { boolean }) => ({
  ...state,
  itemGroup03IsLoading: boolean
});

export const outbOrdReservedStockReportFetchItemGroup03OptionSuccess = (state, { options }) => ({
  ...state,
  itemGroup03Options: options
});

export const outbOrdReservedStockReportFetchStorageBinOptionLoading = (state, { boolean }) => ({
  ...state,
  storageBinIsLoading: boolean
});

export const outbOrdReservedStockReportFetchStorageBinOptionSuccess = (state, { options }) => ({
  ...state,
  storageBinOptions: options
});

export const outbOrdReservedStockReportFetchStorageRowOptionLoading = (state, { boolean }) => ({
  ...state,
  storageRowIsLoading: boolean
});

export const outbOrdReservedStockReportFetchStorageRowOptionSuccess = (state, { options }) => ({
  ...state,
  storageRowOptions: options
});

export const outbOrdReservedStockReportFetchStorageBayOptionLoading = (state, { boolean }) => ({
  ...state,
  storageBayIsLoading: boolean
});

export const outbOrdReservedStockReportFetchStorageBayOptionSuccess = (state, { options }) => ({
  ...state,
  storageBayOptions: options
});

export const outbOrdReservedStockReportFetchLocationOptionLoading = (state, { boolean }) => ({
  ...state,
  locationIsLoading: boolean
});

export const outbOrdReservedStockReportFetchLocationOptionSuccess = (state, { options }) => ({
  ...state,
  locationOptions: options
});

export const outbOrdReservedStockReportFetchDivisionOptionLoading = (state, { boolean }) => ({
  ...state,
  divisionIsLoading: boolean
});

export const outbOrdReservedStockReportFetchDivisionOptionSuccess = (state, { options }) => ({
  ...state,
  divisionOptions: options
});

export const outbOrdReservedStockReportFetchDeliveryPointOptionLoading = (state, { boolean }) => ({
  ...state,
  deliveryPointIsLoading: boolean
});

export const outbOrdReservedStockReportFetchDeliveryPointOptionSuccess = (state, { options }) => ({
  ...state,
  deliveryPointOptions: options
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [OutbOrdReservedStockReportTypes.OUTB_ORD_RESERVED_STOCK_REPORT_REPORT_LOADING]: outbOrdReservedStockReportReportLoading,
  [OutbOrdReservedStockReportTypes.OUTB_ORD_RESERVED_STOCK_REPORT_INIT_OUTB_ORD_RESERVED_STOCK_SUCCESS]: outbOrdReservedStockReportInitOutbOrdReservedStockSuccess,
  [OutbOrdReservedStockReportTypes.OUTB_ORD_RESERVED_STOCK_REPORT_OUTB_ORD_RESERVED_STOCK_SUCCESS]: outbOrdReservedStockReportOutbOrdReservedStockSuccess,

  [OutbOrdReservedStockReportTypes.OUTB_ORD_RESERVED_STOCK_REPORT_FETCH_ITEM_OPTION_LOADING]: outbOrdReservedStockReportFetchItemOptionLoading,
  [OutbOrdReservedStockReportTypes.OUTB_ORD_RESERVED_STOCK_REPORT_FETCH_ITEM_OPTION_SUCCESS]: outbOrdReservedStockReportFetchItemOptionSuccess,

  [OutbOrdReservedStockReportTypes.OUTB_ORD_RESERVED_STOCK_REPORT_FETCH_ITEM_GROUP01_OPTION_LOADING]: outbOrdReservedStockReportFetchItemGroup01OptionLoading,
  [OutbOrdReservedStockReportTypes.OUTB_ORD_RESERVED_STOCK_REPORT_FETCH_ITEM_GROUP01_OPTION_SUCCESS]: outbOrdReservedStockReportFetchItemGroup01OptionSuccess,

  [OutbOrdReservedStockReportTypes.OUTB_ORD_RESERVED_STOCK_REPORT_FETCH_ITEM_GROUP02_OPTION_LOADING]: outbOrdReservedStockReportFetchItemGroup02OptionLoading,
  [OutbOrdReservedStockReportTypes.OUTB_ORD_RESERVED_STOCK_REPORT_FETCH_ITEM_GROUP02_OPTION_SUCCESS]: outbOrdReservedStockReportFetchItemGroup02OptionSuccess,

  [OutbOrdReservedStockReportTypes.OUTB_ORD_RESERVED_STOCK_REPORT_FETCH_ITEM_GROUP03_OPTION_LOADING]: outbOrdReservedStockReportFetchItemGroup03OptionLoading,
  [OutbOrdReservedStockReportTypes.OUTB_ORD_RESERVED_STOCK_REPORT_FETCH_ITEM_GROUP03_OPTION_SUCCESS]: outbOrdReservedStockReportFetchItemGroup03OptionSuccess,

  [OutbOrdReservedStockReportTypes.OUTB_ORD_RESERVED_STOCK_REPORT_FETCH_STORAGE_BIN_OPTION_LOADING]: outbOrdReservedStockReportFetchStorageBinOptionLoading,
  [OutbOrdReservedStockReportTypes.OUTB_ORD_RESERVED_STOCK_REPORT_FETCH_STORAGE_BIN_OPTION_SUCCESS]: outbOrdReservedStockReportFetchStorageBinOptionSuccess,

  [OutbOrdReservedStockReportTypes.OUTB_ORD_RESERVED_STOCK_REPORT_FETCH_STORAGE_ROW_OPTION_LOADING]: outbOrdReservedStockReportFetchStorageRowOptionLoading,
  [OutbOrdReservedStockReportTypes.OUTB_ORD_RESERVED_STOCK_REPORT_FETCH_STORAGE_ROW_OPTION_SUCCESS]: outbOrdReservedStockReportFetchStorageRowOptionSuccess,

  [OutbOrdReservedStockReportTypes.OUTB_ORD_RESERVED_STOCK_REPORT_FETCH_STORAGE_BAY_OPTION_LOADING]: outbOrdReservedStockReportFetchStorageBayOptionLoading,
  [OutbOrdReservedStockReportTypes.OUTB_ORD_RESERVED_STOCK_REPORT_FETCH_STORAGE_BAY_OPTION_SUCCESS]: outbOrdReservedStockReportFetchStorageBayOptionSuccess,

  [OutbOrdReservedStockReportTypes.OUTB_ORD_RESERVED_STOCK_REPORT_FETCH_LOCATION_OPTION_LOADING]: outbOrdReservedStockReportFetchLocationOptionLoading,
  [OutbOrdReservedStockReportTypes.OUTB_ORD_RESERVED_STOCK_REPORT_FETCH_LOCATION_OPTION_SUCCESS]: outbOrdReservedStockReportFetchLocationOptionSuccess,

  [OutbOrdReservedStockReportTypes.OUTB_ORD_RESERVED_STOCK_REPORT_FETCH_DIVISION_OPTION_LOADING]: outbOrdReservedStockReportFetchDivisionOptionLoading,
  [OutbOrdReservedStockReportTypes.OUTB_ORD_RESERVED_STOCK_REPORT_FETCH_DIVISION_OPTION_SUCCESS]: outbOrdReservedStockReportFetchDivisionOptionSuccess,

  [OutbOrdReservedStockReportTypes.OUTB_ORD_RESERVED_STOCK_REPORT_FETCH_DELIVERY_POINT_OPTION_LOADING]: outbOrdReservedStockReportFetchDeliveryPointOptionLoading,
  [OutbOrdReservedStockReportTypes.OUTB_ORD_RESERVED_STOCK_REPORT_FETCH_DELIVERY_POINT_OPTION_SUCCESS]: outbOrdReservedStockReportFetchDeliveryPointOptionSuccess
});
