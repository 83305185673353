import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import './DialogStyle.css';

import { Modal, Row, Col, Typography, Skeleton, List } from 'antd';
import { Card } from 'react-bootstrap';
import moment from 'moment';

import LoadListDetailBoardActions from '../../Stores/LoadListDetailBoard/Actions';

const { Text } = Typography;

class WipPickListDialog extends React.PureComponent {
  constructor() {
    super();

    this.handleCancel = this.handleCancel.bind(this);
    this.convertExpiryDate = this.convertExpiryDate.bind(this);
    this.renderItem = this.renderItem.bind(this);
  }

  handleCancel() {
    const { setDialogVisible } = this.props;

    setDialogVisible(false);
  }

  convertExpiryDate(expiryDate) {
    // eslint-disable-next-line no-unused-vars
    const { intl } = this.props;

    let diffDays = 0;
    if (expiryDate !== null) {
      const thisDate = new Date();
      const oneDay = 24 * 60 * 60 * 1000;
      diffDays = Math.round(Math.abs((expiryDate.getTime() - thisDate.getTime()) / oneDay));
    }

    let backgroundColor = 'white';
    if (diffDays <= 90) {
      backgroundColor = 'red';
    } else if (diffDays <= 180) {
      backgroundColor = 'orange';
    } else if (diffDays <= 270) {
      backgroundColor = 'yellow';
    }
    return backgroundColor;
  }

  renderItem(record) {
    const { intl } = this.props;

    return (
      <Card className="rowcard" key={record.id}>
        <Card.Header>
          {`${record.shipping_doc_code} ${record.doc_code}`}
          {record.ref_code_01 !== record.shipping_doc_code && <>{` ${record.ref_code_01}`}</>}
          {` ${intl.formatMessage({ id: 'est_del_date' })}: ${moment(record.est_del_date).format(
            'YYYY-MM-DD'
          )}`}
        </Card.Header>
        <Card.Body>
          <Card.Title className="infotext" style={{ fontSize: '15px', wordBreak: 'break-all' }}>
            {`${record.delivery_point_code} ${record.delivery_point_company_name_01}`}
          </Card.Title>
          <Card.Text className="infotext">
            {record.delivery_point_unit_no}
            {record.delivery_point_building_name && (
              <>
                <br />
                {record.delivery_point_building_name}
              </>
            )}
            {record.delivery_point_street_name && (
              <>
                <br />
                {record.delivery_point_street_name}
              </>
            )}
            {record.delivery_point_district_01 && (
              <>
                <br />
                {record.delivery_point_district_01}
              </>
            )}
            {record.delivery_point_district_02 && (
              <>
                <br />
                {record.delivery_point_district_02}
              </>
            )}
            {record.delivery_point_postcode && (
              <>
                <br />
                {record.delivery_point_postcode}
              </>
            )}
            {record.delivery_point_state_name && (
              <>
                <br />
                {record.delivery_point_state_name}
              </>
            )}
            {record.delivery_point_country_name && (
              <>
                <br />
                {record.delivery_point_country_name}
              </>
            )}
          </Card.Text>
        </Card.Body>
      </Card>
    );
  }

  render() {
    const { intl, dialogIsVisible, document, documentIsLoading } = this.props;

    return (
      <>
        <Modal
          visible={dialogIsVisible}
          title={
            // eslint-disable-next-line react/jsx-wrap-multilines
            <Text>
              {document.doc_code}
              {document.ref_code_01 !== document.doc_code && <>{` ${document.ref_code_01}`}</>}
            </Text>
          }
          // style={{top:20}}
          width="50%"
          centered
          // onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={null}
          className="load-list-dialog"
        >
          {documentIsLoading === true ? (
            <Skeleton active />
          ) : (
            <Row type="flex">
              <Col span={20} style={{ margin: 'auto' }}>
                <DialogRow
                  header={<Text>{intl.formatMessage({ id: 'created_at' })}</Text>}
                  details={<Text>{document.created_at}</Text>}
                />
                <DialogRow
                  header={<Text>{intl.formatMessage({ id: 'doc_date' })}</Text>}
                  details={<Text>{moment(document.doc_date).format('YYYY-MM-DD')}</Text>}
                />
                <DialogRow
                  header={<Text>{intl.formatMessage({ id: 'to_storage_bin' })}</Text>}
                  details={<Text>{document.to_storage_bin_code}</Text>}
                />
                <DialogRow
                  header={<Text>{intl.formatMessage({ id: 'pallet_qty' })}</Text>}
                  details={
                    // eslint-disable-next-line react/jsx-wrap-multilines
                    <Text>
                      {new Intl.NumberFormat([], {
                        style: 'decimal',
                        minimumFractionDigits: 5,
                        maximumFractionDigits: 5
                      }).format(document.pallet_qty)}
                    </Text>
                  }
                />
                <DialogRow
                  header={<Text>{intl.formatMessage({ id: 'case_qty' })}</Text>}
                  details={
                    // eslint-disable-next-line react/jsx-wrap-multilines
                    <Text>
                      {new Intl.NumberFormat([], {
                        style: 'decimal',
                        minimumFractionDigits: 5,
                        maximumFractionDigits: 5
                      }).format(document.case_decimal_qty)}
                    </Text>
                  }
                />
                <DialogRow
                  header={<Text>{intl.formatMessage({ id: 'gross_weight' })}</Text>}
                  details={
                    // eslint-disable-next-line react/jsx-wrap-multilines
                    <Text>
                      {new Intl.NumberFormat([], {
                        style: 'decimal',
                        minimumFractionDigits: 5,
                        maximumFractionDigits: 5
                      }).format(document.gross_weight)}
                    </Text>
                  }
                />
                <DialogRow
                  header={<Text>{intl.formatMessage({ id: 'cubic_meter' })}</Text>}
                  details={
                    // eslint-disable-next-line react/jsx-wrap-multilines
                    <Text>
                      {new Intl.NumberFormat([], {
                        style: 'decimal',
                        minimumFractionDigits: 5,
                        maximumFractionDigits: 5
                      }).format(document.cubic_meter)}
                    </Text>
                  }
                />
                <Row type="flex" gutter={[8, 8]} className="modalinfo-row">
                  <Col span={24} className="modalinfo-details">
                    <List
                      itemLayout="horizontal"
                      dataSource={document.outb_ord_hdrs}
                      renderItem={this.renderItem}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
        </Modal>
      </>
    );
  }
}

function DialogRow(props) {
  // eslint-disable-next-line react/prop-types
  const { className, header, details } = props;
  return (
    <Row type="flex" gutter={[8, 8]} className={className || 'modalinfo-row'}>
      <Col span={5} className="modalinfo">
        {header}
      </Col>
      <Col span={19} className="modalinfo-details">
        {details}
      </Col>
    </Row>
  );
}

WipPickListDialog.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  setDialogVisible: PropTypes.func,
  dialogIsVisible: PropTypes.bool,
  document: PropTypes.shape({
    doc_code: PropTypes.string,
    ref_code_01: PropTypes.string,
    doc_status: PropTypes.number,
    created_at: PropTypes.string,
    doc_date: PropTypes.string,
    to_storage_bin_code: PropTypes.string,
    pallet_qty: PropTypes.string,
    case_decimal_qty: PropTypes.string,
    gross_weight: PropTypes.string,
    cubic_meter: PropTypes.string,
    outb_ord_hdrs: PropTypes.arrayOf(PropTypes.object)
  }),
  documentIsLoading: PropTypes.bool
};

WipPickListDialog.defaultProps = {
  intl: {},
  setDialogVisible() {},
  dialogIsVisible: false,
  document: {
    doc_code: '',
    doc_status: 3,
    created_at: '',
    doc_date: null,
    to_storage_bin_code: '',
    pallet_qty: '',
    case_decimal_qty: '',
    gross_weight: '',
    cubic_meter: '',
    outb_ord_hdrs: []
  },
  documentIsLoading: false
};

const mapStateToProps = state => ({
  timestamp: state.loadListDetailBoard.wipPickListDialogTimestamp,
  dialogIsVisible: state.loadListDetailBoard.wipPickListDialogIsVisible,
  docId: state.loadListDetailBoard.wipPickListDocId,
  document: state.loadListDetailBoard.wipPickListDocument,
  documentIsLoading: state.loadListDetailBoard.wipPickListDocumentIsLoading
});

const mapDispatchToProps = dispatch => ({
  setDialogVisible: boolean =>
    dispatch(LoadListDetailBoardActions.loadListDetailBoardSetWipPickListDialogVisible(boolean))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(WipPickListDialog));
