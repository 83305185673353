/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { WhsePerformanceChartTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const whsePerformanceChartResetTimestamp = (state, { criteria }) => ({
  ...state,
  criteria: criteria || state.criteria,
  whsePerformanceChartTimestamp: Date.now()
});

export const whsePerformanceChartFetchIsLoading = (state, { boolean }) => ({
  ...state,
  whsePerformanceDataIsLoading: boolean
});

export const whsePerformanceChartFetchSuccess = (state, { data }) => {
  return {
    ...state,
    whsePerformanceData: data
  };
};

export const whsePerformanceChartSetVisibleAverageKeys = (state, { visibleKeys }) => {
  return {
    ...state,
    whsePerformanceChartVisibleAverageKeys: visibleKeys
  };
};

export const whsePerformanceChartChangeProcess = (state, { formikBag, process }) => {
  formikBag.setSubmitting(true);
  let processOptions = [];

  if (process === 'daily') {
    processOptions = [
      { label: '7 days', value: '7d' },
      { label: '14 days', value: '14d' },
      { label: '21 days', value: '21d' },
      { label: '28 days', value: '28d' }
    ];
    formikBag.setFieldValue('time_period', '7d');
  } else if (process === 'weekly') {
    processOptions = [
      { label: '1 month', value: '1m' },
      { label: '2 months', value: '2m' },
      { label: '3 months', value: '3m' }
    ];
    formikBag.setFieldValue('time_period', '1m');
  } else {
    processOptions = [
      { label: '3 months', value: '3m' },
      { label: '6 months', value: '6m' },
      { label: '12 months', value: '12m' }
    ];
    formikBag.setFieldValue('time_period', '3m');
  }

  formikBag.setSubmitting(false);

  return { ...state, processOptions };
};

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [WhsePerformanceChartTypes.WHSE_PERFORMANCE_CHART_RESET_TIMESTAMP]: whsePerformanceChartResetTimestamp,
  [WhsePerformanceChartTypes.WHSE_PERFORMANCE_CHART_FETCH_IS_LOADING]: whsePerformanceChartFetchIsLoading,
  [WhsePerformanceChartTypes.WHSE_PERFORMANCE_CHART_FETCH_SUCCESS]: whsePerformanceChartFetchSuccess,
  [WhsePerformanceChartTypes.WHSE_PERFORMANCE_CHART_CHANGE_PROCESS]: whsePerformanceChartChangeProcess,
  [WhsePerformanceChartTypes.WHSE_PERFORMANCE_CHART_SET_VISIBLE_AVERAGE_KEYS]: whsePerformanceChartSetVisibleAverageKeys
});
