import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import treeChanges from 'tree-changes';

import { Card, Tabs, Tooltip, Spin } from 'antd';

import ProductionProcessActions from '../../Stores/ProductionProcess/Actions';

import ProdOrdFServ01Form from '../ProdOrdFServ01/ProdOrdFServ01Form';
import ProdOrdExcel01Form from '../ProdOrdExcel01/ProdOrdExcel01Form';
import OutbOrd04Table from '../OutbOrd04/OutbOrd04Table';
import ProdSheet01Table from '../ProdSheet01/ProdSheet01Table';
import ProdRcpt01Table from '../ProdRcpt01/ProdRcpt01Table';
import BinTrf03Table from '../BinTrf03/BinTrf03Table';
import ProdIssFServ01Table from '../ProdIssFServ01/ProdIssFServ01Table';
import ProdSheetFServ01Table from '../ProdSheetFServ01/ProdSheetFServ01Table';
import PutAway03Table from '../PutAway03/PutAway03Table';
import PutAwayFServ01Table from '../PutAwayFServ01/PutAwayFServ01Table';
import ProdOrdFServ02Table from '../ProdOrdFServ02/ProdOrdFServ02Table';

const { TabPane } = Tabs;

class ProductionProcessScreen extends React.PureComponent {
  componentDidMount() {
    const { curDivisionId, fetchProductionProcess } = this.props;

    if (curDivisionId > 0) {
      fetchProductionProcess();
    }
  }

  componentDidUpdate(prevProps) {
    const { curDivisionId, fetchProductionProcess } = this.props;
    const { changed } = treeChanges(prevProps, this.props);
    if (changed('curDivisionId')) {
      if (curDivisionId > 0) {
        fetchProductionProcess();
      }
    }
  }

  componentWillUnmount() {}

  render() {
    const { intl, match, process, appPath, processIsLoading } = this.props;

    return (
      <Card>
        <Spin spinning={processIsLoading}>
          <Tabs type="card" activeKey={match.params.proc_type}>
            {process.map(value => (
              <TabPane
                tab={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <Tooltip
                    placement="bottom"
                    title={intl.formatMessage({ id: `${value.proc_type.toLowerCase()}_desc` })}
                  >
                    <Link
                      style={value.proc_type !== match.params.proc_type ? { color: 'black' } : {}}
                      to={`${appPath}/productionProcess/index/${value.proc_type}`}
                    >
                      <FormattedMessage id={`${value.proc_type.toLowerCase()}_label`} />
                    </Link>
                  </Tooltip>
                }
                key={value.proc_type}
              />
            ))}
          </Tabs>
        </Spin>

        {match.params.proc_type === 'PROD_ORD_F_SERV_01' && <ProdOrdFServ01Form match={match} />}
        {match.params.proc_type === 'PROD_ORD_EXCEL_01' && <ProdOrdExcel01Form match={match} />}
        {match.params.proc_type === 'OUTB_ORD_04' && <OutbOrd04Table match={match} />}
        {match.params.proc_type === 'PROD_SHEET_01' && <ProdSheet01Table match={match} />}
        {match.params.proc_type === 'PROD_RCPT' && <ProdRcpt01Table match={match} />}
        {match.params.proc_type === 'BIN_TRF_03' && <BinTrf03Table match={match} />}
        {match.params.proc_type === 'PROD_ISS_F_SERV_01' && <ProdIssFServ01Table match={match} />}
        {match.params.proc_type === 'PROD_SHEET_F_SERV_01' && (
          <ProdSheetFServ01Table match={match} />
        )}
        {match.params.proc_type === 'PUT_AWAY_03' && <PutAway03Table match={match} />}
        {match.params.proc_type === 'PUT_AWAY_F_SERV_01' && <PutAwayFServ01Table match={match} />}
        {match.params.proc_type === 'PROD_ORD_F_SERV_02' && <ProdOrdFServ02Table match={match} />}
      </Card>
    );
  }
}

ProductionProcessScreen.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object,
  appPath: PropTypes.string,
  process: PropTypes.arrayOf(PropTypes.object),
  processIsLoading: PropTypes.bool,

  curDivisionId: PropTypes.number,
  fetchProductionProcess: PropTypes.func
};

ProductionProcessScreen.defaultProps = {
  intl: {},
  match: {},
  appPath: '',
  process: [],
  processIsLoading: false,

  curDivisionId: 0,
  fetchProductionProcess() {}
};

const mapStateToProps = state => ({
  appPath: state.app.appPath,
  process: state.productionProcess.process,
  processIsLoading: state.productionProcess.processIsLoading,

  curDivisionId: state.app.curDivisionId
});

const mapDispatchToProps = dispatch => ({
  fetchProductionProcess: () =>
    dispatch(ProductionProcessActions.productionProcessFetchProductionProcess())
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ProductionProcessScreen));
