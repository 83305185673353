import { put, call, select, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import { replace } from 'connected-react-router';
import ApiService from '../Services/ApiService';
import UtilService from '../Services/UtilService';
import AppActions from '../Stores/App/Actions';
import StkIssDetailActions, { StkIssDetailTypes } from '../Stores/StkIssDetail/Actions';

const getAppStore = state => state.app;

const getStkIssDetailStore = state => state.stkIssDetail;

export function* stkIssDetailInitHeader({ docSubType }) {
  try {
    yield put(StkIssDetailActions.stkIssDetailShowDocumentLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `stkIss/initHeader/${app.curDivisionId}/${docSubType}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(StkIssDetailActions.stkIssDetailShowHeaderSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(StkIssDetailActions.stkIssDetailShowDocumentLoading(false));
  }
}

export function* stkIssDetailShowHeader({ hdrId }) {
  try {
    yield put(StkIssDetailActions.stkIssDetailShowDocumentLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `stkIss/showHeader/${hdrId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(StkIssDetailActions.stkIssDetailShowHeaderSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(StkIssDetailActions.stkIssDetailShowDocumentLoading(false));
  }
}

export function* stkIssDetailTransitionToStatus({ formikBag, hdrId, docStatus }) {
  formikBag.setSubmitting(true);
  yield put(StkIssDetailActions.stkIssDetailShowDocumentLoading(true));
  try {
    const app = yield select(getAppStore);

    const postData = {
      hdrId,
      docStatus
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      'stkIss/transitionToStatus',
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      const stkIssDetail = yield select(getStkIssDetailStore);

      const {
        documentHeader: oldDocumentHeader,
        documentDetails: oldDocumentDetails
      } = stkIssDetail;

      const {
        document_header: retDocumentHeader,
        document_details: retDocumentDetails,
        deleted_details: retDeletedDetails
      } = result.data;

      const processed = UtilService.processHeaderDetails(
        oldDocumentHeader,
        oldDocumentDetails,
        retDocumentHeader,
        retDocumentDetails,
        retDeletedDetails
      );

      yield put(
        StkIssDetailActions.stkIssDetailUpdateDocumentSuccess(retDocumentHeader, processed.details)
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
    yield put(StkIssDetailActions.stkIssDetailShowDocumentLoading(false));
  }
}

export function* stkIssDetailUpdateHeader({ formikBag, documentHeader }) {
  formikBag.setSubmitting(true);
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: documentHeader
    };

    const result = yield call(
      ApiService.putApi, // function
      app.apiUrl,
      'stkIss/updateHeader',
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      const stkIssDetail = yield select(getStkIssDetailStore);

      const {
        documentHeader: oldDocumentHeader,
        documentDetails: oldDocumentDetails
      } = stkIssDetail;

      const {
        document_header: retDocumentHeader,
        document_details: retDocumentDetails,
        deleted_details: retDeletedDetails
      } = result.data;

      const processed = UtilService.processHeaderDetails(
        oldDocumentHeader,
        oldDocumentDetails,
        retDocumentHeader,
        retDocumentDetails,
        retDeletedDetails
      );

      yield put(
        StkIssDetailActions.stkIssDetailUpdateDocumentSuccess(retDocumentHeader, processed.details)
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* stkIssDetailShowDetails({ hdrId }) {
  try {
    yield put(StkIssDetailActions.stkIssDetailShowDocumentLoading(true));

    let result = {
      isSuccess: true,
      data: []
    };

    if (hdrId > 0) {
      const app = yield select(getAppStore);
      const getData = {};

      result = yield call(
        ApiService.getApi, // function
        app.apiUrl,
        `stkIss/showDetails/${hdrId}`,
        app.token,
        getData,
        'multipart/form-data' // params
      );
    }

    if (result.isSuccess === true) {
      yield put(StkIssDetailActions.stkIssDetailShowDetailsSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(StkIssDetailActions.stkIssDetailShowDocumentLoading(false));
  }
}

export function* stkIssDetailUpdateDetails({ formikBag, hdrId, documentDetails }) {
  formikBag.setSubmitting(true);
  yield put(StkIssDetailActions.stkIssDetailShowDocumentLoading(true));
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: documentDetails
    };

    const result = yield call(
      ApiService.putApi, // function
      app.apiUrl,
      `stkIss/updateDetails/${hdrId}`,
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      const stkIssDetail = yield select(getStkIssDetailStore);

      const {
        documentHeader: oldDocumentHeader,
        documentDetails: oldDocumentDetails
      } = stkIssDetail;

      const {
        document_header: retDocumentHeader,
        document_details: retDocumentDetails,
        deleted_details: retDeletedDetails
      } = result.data;

      const processed = UtilService.processHeaderDetails(
        oldDocumentHeader,
        oldDocumentDetails,
        retDocumentHeader,
        retDocumentDetails,
        retDeletedDetails
      );

      yield put(
        StkIssDetailActions.stkIssDetailUpdateDocumentSuccess(retDocumentHeader, processed.details)
      );

      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_SUCCESS_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
    yield put(StkIssDetailActions.stkIssDetailShowDocumentLoading(false));
  }
}

export function* stkIssDetailFetchCurrencyOptions({ search }) {
  try {
    yield put(StkIssDetailActions.stkIssDetailFetchCurrencyOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `currency/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code}`
      }));

      yield put(StkIssDetailActions.stkIssDetailFetchCurrencyOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(StkIssDetailActions.stkIssDetailFetchCurrencyOptionLoading(false));
  }
}

export function* stkIssDetailChangeCurrency({ formikBag, currencyId }) {
  try {
    formikBag.setSubmitting(true);

    const app = yield select(getAppStore);
    const postData = {
      currencyId
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `stkIss/changeCurrency`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      Object.entries(result.data).forEach(entry => {
        const key = entry[0];
        const value = entry[1];
        formikBag.setFieldValue(key, value);
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* stkIssDetailFetchToDivisionOptions({ docSubType, search }) {
  try {
    yield put(StkIssDetailActions.stkIssDetailFetchToDivisionOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `stkIss/divisionSelect2/${app.curSiteFlowId}/${docSubType}`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code}`
      }));

      yield put(StkIssDetailActions.stkIssDetailFetchToDivisionOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(StkIssDetailActions.stkIssDetailFetchToDivisionOptionLoading(false));
  }
}

export function* stkIssDetailFetchToLocationOptions({ hdrId, docSubType, search }) {
  try {
    yield put(StkIssDetailActions.stkIssDetailFetchToLocationOptionLoading(true));

    let filters = [];
    if (docSubType === 2) {
      // TRANSFER_TO_STORE
      filters = [
        {
          field: 'location_types',
          value: [
            4 // STORE_STOCK
          ]
        }
      ];
    } else if (docSubType === 3) {
      // TRANSFER_TO_VAN
      filters = [
        {
          field: 'location_types',
          value: [
            5 // VAN_STOCK
          ]
        }
      ];
    }

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `stkIss/locationSelect2/${hdrId}`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(StkIssDetailActions.stkIssDetailFetchToLocationOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(StkIssDetailActions.stkIssDetailFetchToLocationOptionLoading(false));
  }
}

export function* stkIssDetailFetchHdrToLocationOptions({ docSubType, search }) {
  try {
    yield put(StkIssDetailActions.stkIssDetailFetchHdrToLocationOptionLoading(true));

    let filters = [];
    if (docSubType === 2) {
      // TRANSFER_TO_STORE
      filters = [
        {
          field: 'location_types',
          value: [
            4 // STORE_STOCK
          ]
        }
      ];
    } else if (docSubType === 3) {
      // TRANSFER_TO_VAN
      filters = [
        {
          field: 'location_types',
          value: [
            5 // VAN_STOCK
          ]
        }
      ];
    }

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId: app.curSiteFlowId,
      search,
      filters
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `location/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(StkIssDetailActions.stkIssDetailFetchHdrToLocationOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(StkIssDetailActions.stkIssDetailFetchHdrToLocationOptionLoading(false));
  }
}

export function* stkIssDetailCreateHeader({ formikBag, documentHeader }) {
  formikBag.setSubmitting(true);
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: documentHeader
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      'stkIss/createHeader',
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      yield put(replace(`${app.appPath}/stkIssDetail/update/${result.data}`));
      yield put(StkIssDetailActions.stkIssDetailSetHdrId(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* stkIssDetailFetchItemOptions({ search }) {
  try {
    yield put(StkIssDetailActions.stkIssDetailFetchItemOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `item/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(StkIssDetailActions.stkIssDetailFetchItemOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(StkIssDetailActions.stkIssDetailFetchItemOptionLoading(false));
  }
}

export function* stkIssDetailChangeItem({ formikBag, hdrId, itemId }) {
  try {
    formikBag.setSubmitting(true);

    const app = yield select(getAppStore);
    const postData = {
      hdrId,
      itemId
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `stkIss/changeItem`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      Object.entries(result.data).forEach(entry => {
        const key = entry[0];
        const value = entry[1];
        formikBag.setFieldValue(key, value);
      });

      // reset the select2 cache
      formikBag.setFieldValue('uom_select2', {
        value: 0,
        label: ''
      });
      formikBag.setFieldValue('uom_rate', 1);

      yield put(StkIssDetailActions.stkIssDetailFetchUomOptionSuccess([]));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* stkIssDetailChangeUom({ formikBag, hdrId, itemId, uomId }) {
  try {
    formikBag.setSubmitting(true);

    const app = yield select(getAppStore);
    const postData = {
      hdrId,
      itemId,
      uomId
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `stkIss/changeItemUom`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      Object.entries(result.data).forEach(entry => {
        const key = entry[0];
        const value = entry[1];
        formikBag.setFieldValue(key, value);
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* stkIssDetailFetchUomOptions({ itemId, search }) {
  try {
    yield put(StkIssDetailActions.stkIssDetailFetchUomOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: [
        {
          field: 'item_id',
          value: itemId
        }
      ]
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `itemUom/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.uom_id,
        label: `${d.uom_code}`
      }));

      yield put(StkIssDetailActions.stkIssDetailFetchUomOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(StkIssDetailActions.stkIssDetailFetchUomOptionLoading(false));
  }
}

export function* stkIssDetailCreateDetail({ formikBag, hdrId, documentDetail }) {
  formikBag.setSubmitting(true);
  yield put(StkIssDetailActions.stkIssDetailShowDocumentLoading(true));
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: documentDetail
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `stkIss/createDetail/${hdrId}`,
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      const stkIssDetail = yield select(getStkIssDetailStore);

      const {
        documentHeader: oldDocumentHeader,
        documentDetails: oldDocumentDetails
      } = stkIssDetail;

      const {
        document_header: retDocumentHeader,
        document_details: retDocumentDetails,
        deleted_details: retDeletedDetails
      } = result.data;

      const processed = UtilService.processHeaderDetails(
        oldDocumentHeader,
        oldDocumentDetails,
        retDocumentHeader,
        retDocumentDetails,
        retDeletedDetails
      );

      yield put(
        StkIssDetailActions.stkIssDetailUpdateDocumentSuccess(retDocumentHeader, processed.details)
      );

      yield put(StkIssDetailActions.stkIssDetailSetDocumentDetail(stkIssDetail.initDocumentDetail));

      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_SUCCESS_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
    yield put(StkIssDetailActions.stkIssDetailShowDocumentLoading(false));
  }
}

export function* stkIssDetailDeleteDetail({ hdrId, documentDetail }) {
  yield put(StkIssDetailActions.stkIssDetailShowDocumentLoading(true));
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: [{ id: documentDetail.id }]
    };

    const result = yield call(
      ApiService.deleteApi, // function
      app.apiUrl,
      `stkIss/deleteDetails/${hdrId}`,
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      const stkIssDetail = yield select(getStkIssDetailStore);

      const {
        documentHeader: oldDocumentHeader,
        documentDetails: oldDocumentDetails
      } = stkIssDetail;

      const {
        document_header: retDocumentHeader,
        document_details: retDocumentDetails,
        deleted_details: retDeletedDetails
      } = result.data;

      const processed = UtilService.processHeaderDetails(
        oldDocumentHeader,
        oldDocumentDetails,
        retDocumentHeader,
        retDocumentDetails,
        retDeletedDetails
      );

      yield put(
        StkIssDetailActions.stkIssDetailUpdateDocumentSuccess(retDocumentHeader, processed.details)
      );

      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_SUCCESS_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(StkIssDetailActions.stkIssDetailShowDocumentLoading(false));
  }
}

export function* stkIssDetailFetchLocationOptions({ docSubType, search }) {
  try {
    yield put(StkIssDetailActions.stkIssDetailFetchLocationOptionLoading(true));

    let filters = [];
    if (docSubType === 4) {
      // TRANSFER_FROM_STORE
      filters = [
        {
          field: 'location_types',
          value: [
            4 // STORE_STOCK
          ]
        }
      ];
    } else if (docSubType === 5) {
      // TRANSFER_FROM_VAN
      filters = [
        {
          field: 'location_types',
          value: [
            5 // VAN_STOCK
          ]
        }
      ];
    }

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId: app.curSiteFlowId,
      search,
      filters
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `location/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(StkIssDetailActions.stkIssDetailFetchLocationOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(StkIssDetailActions.stkIssDetailFetchLocationOptionLoading(false));
  }
}

export function* stkIssDetailFetchProjectOptions({ siteFlowId, search }) {
  try {
    yield put(StkIssDetailActions.stkIssDetailFetchProjectOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId,
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `project/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(StkIssDetailActions.stkIssDetailFetchProjectOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(StkIssDetailActions.stkIssDetailFetchProjectOptionLoading(false));
  }
}

export const saga = [
  takeLatest(StkIssDetailTypes.STK_ISS_DETAIL_INIT_HEADER, stkIssDetailInitHeader),
  takeLatest(StkIssDetailTypes.STK_ISS_DETAIL_SHOW_HEADER, stkIssDetailShowHeader),

  takeLatest(StkIssDetailTypes.STK_ISS_DETAIL_UPDATE_HEADER, stkIssDetailUpdateHeader),
  takeLatest(StkIssDetailTypes.STK_ISS_DETAIL_CREATE_HEADER, stkIssDetailCreateHeader),

  takeLatest(StkIssDetailTypes.STK_ISS_DETAIL_TRANSITION_TO_STATUS, stkIssDetailTransitionToStatus),

  takeLatest(StkIssDetailTypes.STK_ISS_DETAIL_SHOW_DETAILS, stkIssDetailShowDetails),

  takeLatest(StkIssDetailTypes.STK_ISS_DETAIL_UPDATE_DETAILS, stkIssDetailUpdateDetails),
  takeLatest(StkIssDetailTypes.STK_ISS_DETAIL_CREATE_DETAIL, stkIssDetailCreateDetail),
  takeLatest(StkIssDetailTypes.STK_ISS_DETAIL_DELETE_DETAIL, stkIssDetailDeleteDetail),

  takeLatest(
    StkIssDetailTypes.STK_ISS_DETAIL_FETCH_CURRENCY_OPTIONS,
    stkIssDetailFetchCurrencyOptions
  ),
  takeLatest(StkIssDetailTypes.STK_ISS_DETAIL_CHANGE_CURRENCY, stkIssDetailChangeCurrency),

  takeLatest(
    StkIssDetailTypes.STK_ISS_DETAIL_FETCH_TO_DIVISION_OPTIONS,
    stkIssDetailFetchToDivisionOptions
  ),

  takeLatest(
    StkIssDetailTypes.STK_ISS_DETAIL_FETCH_TO_LOCATION_OPTIONS,
    stkIssDetailFetchToLocationOptions
  ),
  takeLatest(
    StkIssDetailTypes.STK_ISS_DETAIL_FETCH_HDR_TO_LOCATION_OPTIONS,
    stkIssDetailFetchHdrToLocationOptions
  ),

  takeLatest(
    StkIssDetailTypes.STK_ISS_DETAIL_FETCH_LOCATION_OPTIONS,
    stkIssDetailFetchLocationOptions
  ),

  takeLatest(StkIssDetailTypes.STK_ISS_DETAIL_FETCH_ITEM_OPTIONS, stkIssDetailFetchItemOptions),
  takeLatest(StkIssDetailTypes.STK_ISS_DETAIL_CHANGE_ITEM, stkIssDetailChangeItem),

  takeLatest(StkIssDetailTypes.STK_ISS_DETAIL_CHANGE_UOM, stkIssDetailChangeUom),
  takeLatest(StkIssDetailTypes.STK_ISS_DETAIL_FETCH_UOM_OPTIONS, stkIssDetailFetchUomOptions),

  takeLatest(
    StkIssDetailTypes.STK_ISS_DETAIL_FETCH_PROJECT_OPTIONS,
    stkIssDetailFetchProjectOptions
  )
];
