/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { WhseJob06DetailTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const whseJob06DetailResetTimestamp = state => ({
  ...state,
  timestamp: Date.now()
});

export const whseJob06DetailSetHdrId = (state, { hdrId }) => ({
  ...state,
  timestamp: Date.now(),
  documentIsLoading: false,
  hdrId,
  detailIsVisible: false
});

export const whseJob06DetailShowDocumentLoading = (state, { boolean }) => ({
  ...state,
  documentIsLoading: boolean
});

export const whseJob06DetailShowHeaderSuccess = (state, { documentHeader }) => ({
  ...state,
  documentHeader
});

export const whseJob06DetailUpdateDocumentSuccess = (
  state,
  { documentHeader, outbOrdHdrs, inbOrdHdrs }
) => ({
  ...state,
  documentHeader,
  outbOrdHdrs,
  inbOrdHdrs
});

export const whseJob06DetailShowOutbOrdHdrsSuccess = (state, { outbOrdHdrs }) => ({
  ...state,
  outbOrdHdrs
});

export const whseJob06DetailShowInbOrdHdrsSuccess = (state, { inbOrdHdrs }) => ({
  ...state,
  inbOrdHdrs
});

export const whseJob06DetailFetchTransportOptionLoading = (state, { boolean }) => ({
  ...state,
  transportIsLoading: boolean
});

export const whseJob06DetailFetchTransportOptionSuccess = (state, { options }) => ({
  ...state,
  transportOptions: options
});

export const whseJob06DetailFetchDriver01OptionLoading = (state, { boolean }) => ({
  ...state,
  driver01IsLoading: boolean
});

export const whseJob06DetailFetchDriver01OptionSuccess = (state, { options }) => ({
  ...state,
  driver01Options: options
});

export const whseJob06DetailFetchDeliveryMan01OptionLoading = (state, { boolean }) => ({
  ...state,
  deliveryMan01IsLoading: boolean
});

export const whseJob06DetailFetchDeliveryMan01OptionSuccess = (state, { options }) => ({
  ...state,
  deliveryMan01Options: options
});

export const whseJob06DetailFetchDeliveryMan02OptionLoading = (state, { boolean }) => ({
  ...state,
  deliveryMan02IsLoading: boolean
});

export const whseJob06DetailFetchDeliveryMan02OptionSuccess = (state, { options }) => ({
  ...state,
  deliveryMan02Options: options
});

export const whseJob06DetailFetchWorkerOptionLoading = (state, { boolean }) => ({
  ...state,
  workerIsLoading: boolean
});

export const whseJob06DetailFetchWorkerOptionSuccess = (state, { options }) => ({
  ...state,
  workerOptions: options
});
/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [WhseJob06DetailTypes.WHSE_JOB06_DETAIL_RESET_TIMESTAMP]: whseJob06DetailResetTimestamp,
  [WhseJob06DetailTypes.WHSE_JOB06_DETAIL_SET_HDR_ID]: whseJob06DetailSetHdrId,
  [WhseJob06DetailTypes.WHSE_JOB06_DETAIL_SHOW_HEADER_SUCCESS]: whseJob06DetailShowHeaderSuccess,
  [WhseJob06DetailTypes.WHSE_JOB06_DETAIL_UPDATE_DOCUMENT_SUCCESS]: whseJob06DetailUpdateDocumentSuccess,
  [WhseJob06DetailTypes.WHSE_JOB06_DETAIL_SHOW_DOCUMENT_LOADING]: whseJob06DetailShowDocumentLoading,
  [WhseJob06DetailTypes.WHSE_JOB06_DETAIL_SHOW_OUTB_ORD_HDRS_SUCCESS]: whseJob06DetailShowOutbOrdHdrsSuccess,
  [WhseJob06DetailTypes.WHSE_JOB06_DETAIL_SHOW_INB_ORD_HDRS_SUCCESS]: whseJob06DetailShowInbOrdHdrsSuccess,
  [WhseJob06DetailTypes.WHSE_JOB06_DETAIL_FETCH_TRANSPORT_OPTION_LOADING]: whseJob06DetailFetchTransportOptionLoading,
  [WhseJob06DetailTypes.WHSE_JOB06_DETAIL_FETCH_TRANSPORT_OPTION_SUCCESS]: whseJob06DetailFetchTransportOptionSuccess,
  [WhseJob06DetailTypes.WHSE_JOB06_DETAIL_FETCH_DRIVER01_OPTION_LOADING]: whseJob06DetailFetchDriver01OptionLoading,
  [WhseJob06DetailTypes.WHSE_JOB06_DETAIL_FETCH_DRIVER01_OPTION_SUCCESS]: whseJob06DetailFetchDriver01OptionSuccess,
  [WhseJob06DetailTypes.WHSE_JOB06_DETAIL_FETCH_DELIVERY_MAN01_OPTION_LOADING]: whseJob06DetailFetchDeliveryMan01OptionLoading,
  [WhseJob06DetailTypes.WHSE_JOB06_DETAIL_FETCH_DELIVERY_MAN01_OPTION_SUCCESS]: whseJob06DetailFetchDeliveryMan01OptionSuccess,
  [WhseJob06DetailTypes.WHSE_JOB06_DETAIL_FETCH_DELIVERY_MAN02_OPTION_LOADING]: whseJob06DetailFetchDeliveryMan02OptionLoading,
  [WhseJob06DetailTypes.WHSE_JOB06_DETAIL_FETCH_DELIVERY_MAN02_OPTION_SUCCESS]: whseJob06DetailFetchDeliveryMan02OptionSuccess,

  [WhseJob06DetailTypes.WHSE_JOB06_DETAIL_FETCH_WORKER_OPTION_LOADING]: whseJob06DetailFetchWorkerOptionLoading,
  [WhseJob06DetailTypes.WHSE_JOB06_DETAIL_FETCH_WORKER_OPTION_SUCCESS]: whseJob06DetailFetchWorkerOptionSuccess
});
