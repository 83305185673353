import React from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import moment from 'moment';

import { UndoOutlined, SaveOutlined } from '@ant-design/icons';

import { Card, Button, Row, Col, Typography } from 'antd';
import { FormikDatePicker, FormikButton, FormikInput } from '../../Components/Formik';
import PrfDel01Actions from '../../Stores/PrfDel01/Actions';

const { Text } = Typography;

class PrfDel01Form extends React.PureComponent {
  componentDidMount() {}

  componentDidUpdate() {}

  componentWillUnmount() {}

  render() {
    const { intl, outbOrdHdrs, createPrfDel01 } = this.props;

    return (
      <Card>
        <Formik
          enableReinitialize
          initialValues={{
            doc_date: moment(new Date()).format('YYYY-MM-DD'),
            doc_rtn_date: moment(new Date()).format('YYYY-MM-DD'),
            desc_01: '',
            desc_02: ''
          }}
          onSubmit={(values, formikBag) => {
            const processedValues = {
              doc_date: values.doc_date,
              doc_rtn_date: values.doc_rtn_date,
              desc_01: values.desc_01,
              desc_02: values.desc_02
            };
            createPrfDel01(formikBag, processedValues, outbOrdHdrs);
          }}
          validationSchema={Yup.object().shape({
            doc_date: Yup.date().min(
              new Date(1969, 12, 1),
              intl.formatMessage({ id: 'delivery_date_is_required' })
            ),
            doc_rtn_date: Yup.date().min(
              new Date(1969, 12, 1),
              intl.formatMessage({ id: 'doc_return_date_is_required' })
            )
          })}
        >
          {({
            // values,
            // handleChange,
            // errors,
            // setFieldTouched,
            dirty,
            // touched,
            isSubmitting,
            // isValid,
            handleSubmit,
            handleReset
          }) => (
            <Form>
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'delivery_date' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field name="doc_date" component={FormikDatePicker} />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'doc_return_date' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field name="doc_rtn_date" component={FormikDatePicker} />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'desc_01' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field name="desc_01" component={FormikInput} />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'desc_02' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field name="desc_02" component={FormikInput} />
                </Col>
              </Row>

              <Row type="flex" justify="space-between" gutter={[0, 16]}>
                <Col span={6}>
                  <Button
                    type="primary"
                    disabled={!dirty}
                    loading={isSubmitting}
                    onClick={handleReset}
                    icon={<UndoOutlined />}
                  >
                    {intl.formatMessage({ id: 'reset' })}
                  </Button>
                  <Field
                    name="submit_action"
                    type="primary"
                    value="create"
                    component={FormikButton}
                    disabled={outbOrdHdrs.length === 0}
                    loading={isSubmitting}
                    onClick={handleSubmit}
                    label={intl.formatMessage({
                      id: 'create'
                    })}
                    icon={<SaveOutlined />}
                  />
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </Card>
    );
  }
}

PrfDel01Form.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,

  outbOrdHdrs: PropTypes.arrayOf(PropTypes.object),

  createPrfDel01: PropTypes.func
};

PrfDel01Form.defaultProps = {
  intl: {},

  outbOrdHdrs: [],

  createPrfDel01() {}
};

const mapStateToProps = state => ({
  outbOrdHdrs: state.prfDel01.outbOrdHdrs
});

const mapDispatchToProps = dispatch => ({
  createPrfDel01: (formikBag, formDetail, outbOrdHdrs, inbOrdHdrs) =>
    dispatch(PrfDel01Actions.prfDel01CreatePrfDel01(formikBag, formDetail, outbOrdHdrs, inbOrdHdrs))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PrfDel01Form));
