/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { PutAwayIndexTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const putAwayIndexResetTimestamp = (state, { currentPage, sorts, filters, pageSize }) => ({
  ...state,
  currentPage: currentPage || state.currentPage,
  sorts: sorts || state.sorts,
  filters: filters || state.filters,
  pageSize: pageSize || state.pageSize,
  timestamp: Date.now(),
  // selectedDocuments: INITIAL_STATE.selectedDocuments,
  toStorageBinOptions: INITIAL_STATE.toStorageBinOptions,
  toStorageBinOption: INITIAL_STATE.toStorageBinOption
});

export const putAwayIndexFetchPutAwayIndexLoading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const putAwayIndexFetchPutAwayIndexSuccess = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  documents,
  currentPage,
  lastPage,
  total,
  pageSize
});

export const putAwayIndexAddSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = state.selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: [
      ...state.selectedDocuments,
      ...selectedDocuments.filter(data => {
        return !selectIds.includes(data.id);
      })
    ]
  };
};

export const putAwayIndexRemoveSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: state.selectedDocuments.filter(data => {
      return !selectIds.includes(data.id);
    })
  };
};

export const putAwayIndexSetWorkspaceVisible = (state, { boolean }) => ({
  ...state,
  workspaceIsVisible: boolean
});

export const putAwayIndexWorkspaceLoading = (state, { boolean }) => ({
  ...state,
  workspaceIsLoading: boolean
});

export const putAwayIndexResetPutAwaySync01Success = state => ({
  ...state,
  timestamp: Date.now(),
  selectedDocuments: []
});

export const putAwayIndexResetPutAwayFServ01Success = state => ({
  ...state,
  timestamp: Date.now(),
  selectedDocuments: []
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [PutAwayIndexTypes.PUT_AWAY_INDEX_RESET_TIMESTAMP]: putAwayIndexResetTimestamp,
  [PutAwayIndexTypes.PUT_AWAY_INDEX_FETCH_PUT_AWAY_INDEX_LOADING]: putAwayIndexFetchPutAwayIndexLoading,
  [PutAwayIndexTypes.PUT_AWAY_INDEX_FETCH_PUT_AWAY_INDEX_SUCCESS]: putAwayIndexFetchPutAwayIndexSuccess,
  [PutAwayIndexTypes.PUT_AWAY_INDEX_ADD_SELECTED_DOCUMENTS]: putAwayIndexAddSelectedDocuments,
  [PutAwayIndexTypes.PUT_AWAY_INDEX_REMOVE_SELECTED_DOCUMENTS]: putAwayIndexRemoveSelectedDocuments,
  [PutAwayIndexTypes.PUT_AWAY_INDEX_SET_WORKSPACE_VISIBLE]: putAwayIndexSetWorkspaceVisible,

  [PutAwayIndexTypes.PUT_AWAY_INDEX_WORKSPACE_LOADING]: putAwayIndexWorkspaceLoading,
  [PutAwayIndexTypes.PUT_AWAY_INDEX_RESET_PUT_AWAY_SYNC01_SUCCESS]: putAwayIndexResetPutAwaySync01Success,
  [PutAwayIndexTypes.PUT_AWAY_INDEX_RESET_PUT_AWAY_F_SERV01_SUCCESS]: putAwayIndexResetPutAwayFServ01Success
});
