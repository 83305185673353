import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  siteProcessFetchSiteProcess: null,
  siteProcessFetchSiteProcessLoading: ['boolean'],
  siteProcessFetchSiteProcessSuccess: ['process']
});

export const SiteProcessTypes = Types;
export default Creators;
