/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { ShipmentTrackingReportTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const shipmentTrackingReportReportLoading = (state, { boolean }) => ({
  ...state,
  reportIsLoading: boolean
});

export const shipmentTrackingReportInitShipmentTrackingSuccess = (state, { criteria }) => ({
  ...state,
  criteria
});

export const shipmentTrackingReportShipmentTrackingSuccess = (state, { criteria, reportData }) => ({
  ...state,
  criteria,
  reportData
});

export const shipmentTrackingReportFetchDivisionOptionLoading = (state, { boolean }) => ({
  ...state,
  divisionIsLoading: boolean
});

export const shipmentTrackingReportFetchDivisionOptionSuccess = (state, { options }) => ({
  ...state,
  divisionOptions: options
});

export const shipmentTrackingReportFetchDeliveryPointOptionLoading = (state, { boolean }) => ({
  ...state,
  deliveryPointIsLoading: boolean
});

export const shipmentTrackingReportFetchDeliveryPointOptionSuccess = (state, { options }) => ({
  ...state,
  deliveryPointOptions: options
});

export const shipmentTrackingReportFetchDebtorOptionLoading = (state, { boolean }) => ({
  ...state,
  debtorIsLoading: boolean
});

export const shipmentTrackingReportFetchDebtorOptionSuccess = (state, { options }) => ({
  ...state,
  debtorOptions: options
});

export const shipmentTrackingReportFetchTransportOptionLoading = (state, { boolean }) => ({
  ...state,
  transportIsLoading: boolean
});

export const shipmentTrackingReportFetchTransportOptionSuccess = (state, { options }) => ({
  ...state,
  transportOptions: options
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [ShipmentTrackingReportTypes.SHIPMENT_TRACKING_REPORT_REPORT_LOADING]: shipmentTrackingReportReportLoading,
  [ShipmentTrackingReportTypes.SHIPMENT_TRACKING_REPORT_INIT_SHIPMENT_TRACKING_SUCCESS]: shipmentTrackingReportInitShipmentTrackingSuccess,
  [ShipmentTrackingReportTypes.SHIPMENT_TRACKING_REPORT_SHIPMENT_TRACKING_SUCCESS]: shipmentTrackingReportShipmentTrackingSuccess,

  [ShipmentTrackingReportTypes.SHIPMENT_TRACKING_REPORT_FETCH_DIVISION_OPTION_LOADING]: shipmentTrackingReportFetchDivisionOptionLoading,
  [ShipmentTrackingReportTypes.SHIPMENT_TRACKING_REPORT_FETCH_DIVISION_OPTION_SUCCESS]: shipmentTrackingReportFetchDivisionOptionSuccess,

  [ShipmentTrackingReportTypes.SHIPMENT_TRACKING_REPORT_FETCH_DELIVERY_POINT_OPTION_LOADING]: shipmentTrackingReportFetchDeliveryPointOptionLoading,
  [ShipmentTrackingReportTypes.SHIPMENT_TRACKING_REPORT_FETCH_DELIVERY_POINT_OPTION_SUCCESS]: shipmentTrackingReportFetchDeliveryPointOptionSuccess,

  [ShipmentTrackingReportTypes.SHIPMENT_TRACKING_REPORT_FETCH_DEBTOR_OPTION_LOADING]: shipmentTrackingReportFetchDebtorOptionLoading,
  [ShipmentTrackingReportTypes.SHIPMENT_TRACKING_REPORT_FETCH_DEBTOR_OPTION_SUCCESS]: shipmentTrackingReportFetchDebtorOptionSuccess,

  [ShipmentTrackingReportTypes.SHIPMENT_TRACKING_REPORT_FETCH_TRANSPORT_OPTION_LOADING]: shipmentTrackingReportFetchTransportOptionLoading,
  [ShipmentTrackingReportTypes.SHIPMENT_TRACKING_REPORT_FETCH_TRANSPORT_OPTION_SUCCESS]: shipmentTrackingReportFetchTransportOptionSuccess
});
