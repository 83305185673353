import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  lspDeliveryProcessFetchLspDeliveryProcess: null,
  lspDeliveryProcessFetchLspDeliveryProcessLoading: ['boolean'],
  lspDeliveryProcessFetchLspDeliveryProcessSuccess: ['process']
});

export const LspDeliveryProcessTypes = Types;
export default Creators;
