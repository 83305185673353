/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { DelOrdSync01Types } from './Actions';
import INITIAL_STATE from './InitialState';

export const delOrdSync01ShowSyncSettingLoading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const delOrdSync01ShowSyncSettingSuccess = (state, { syncSetting }) => ({
  ...state,
  syncSetting
});

export const delOrdSync01ShowBatchJobStatusSuccess = (state, { batchJobStatus }) => ({
  ...state,
  batchJobStatus
});

export const delOrdSync01SyncLoading = (state, { boolean }) => ({
  ...state,
  syncIsLoading: boolean
});

export const delOrdSync01ResetTimestamp = (state, { currentPage, sorts, filters, pageSize }) => ({
  ...state,
  currentPage: currentPage || state.currentPage,
  sorts: sorts || state.sorts,
  filters: filters || state.filters,
  pageSize: pageSize || state.pageSize,
  timestamp: Date.now()
  // selectedDocuments: INITIAL_STATE.selectedDocuments
});

export const delOrdSync01FetchDelOrdSync01Loading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const delOrdSync01FetchDelOrdSync01Success = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  documents,
  currentPage,
  lastPage,
  total,
  pageSize
});

export const delOrdSync01AddSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = state.selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: [
      ...state.selectedDocuments,
      ...selectedDocuments.filter(data => {
        return !selectIds.includes(data.id);
      })
    ]
  };
};

export const delOrdSync01RemoveSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: state.selectedDocuments.filter(data => {
      return !selectIds.includes(data.id);
    })
  };
};

export const delOrdSync01SetWorkspaceVisible = (state, { boolean }) => ({
  ...state,
  workspaceIsVisible: boolean
});

export const delOrdSync01SyncNowSuccess = state => ({
  ...state,
  timestamp: Date.now(),
  selectedDocuments: []
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [DelOrdSync01Types.DEL_ORD_SYNC01_SHOW_SYNC_SETTING_LOADING]: delOrdSync01ShowSyncSettingLoading,
  [DelOrdSync01Types.DEL_ORD_SYNC01_SHOW_SYNC_SETTING_SUCCESS]: delOrdSync01ShowSyncSettingSuccess,
  [DelOrdSync01Types.DEL_ORD_SYNC01_SHOW_BATCH_JOB_STATUS_SUCCESS]: delOrdSync01ShowBatchJobStatusSuccess,
  // [DelOrdSync01Types.DEL_ORD_SYNC01_SYNC_LOADING]: delOrdSync01SyncLoading,

  [DelOrdSync01Types.DEL_ORD_SYNC01_RESET_TIMESTAMP]: delOrdSync01ResetTimestamp,
  [DelOrdSync01Types.DEL_ORD_SYNC01_FETCH_DEL_ORD_SYNC01_LOADING]: delOrdSync01FetchDelOrdSync01Loading,
  [DelOrdSync01Types.DEL_ORD_SYNC01_FETCH_DEL_ORD_SYNC01_SUCCESS]: delOrdSync01FetchDelOrdSync01Success,
  [DelOrdSync01Types.DEL_ORD_SYNC01_ADD_SELECTED_DOCUMENTS]: delOrdSync01AddSelectedDocuments,
  [DelOrdSync01Types.DEL_ORD_SYNC01_REMOVE_SELECTED_DOCUMENTS]: delOrdSync01RemoveSelectedDocuments,
  [DelOrdSync01Types.DEL_ORD_SYNC01_SET_WORKSPACE_VISIBLE]: delOrdSync01SetWorkspaceVisible,
  [DelOrdSync01Types.DEL_ORD_SYNC01_SYNC_LOADING]: delOrdSync01SyncLoading,
  [DelOrdSync01Types.DEL_ORD_SYNC01_SYNC_NOW_SUCCESS]: delOrdSync01SyncNowSuccess
});
