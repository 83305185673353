import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  deliveryPointList01ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  deliveryPointList01GoToDocument: ['hdrId'],
  deliveryPointList01FetchDeliveryPointList01: ['currentPage', 'sorts', 'filters', 'pageSize'],
  deliveryPointList01FetchDeliveryPointList01Loading: ['boolean'],
  deliveryPointList01FetchDeliveryPointList01Success: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],
  deliveryPointList01AddSelectedDocuments: ['selectedDocuments'],
  deliveryPointList01RemoveSelectedDocuments: ['selectedDocuments'],
  deliveryPointList01SetWorkspaceVisible: ['boolean']
});

export const DeliveryPointList01Types = Types;
export default Creators;
