import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Card, Tabs, Tooltip } from 'antd';
import ModelForm from './ModelForm';
import ItemBatchTable from './ItemBatchTable';
import ItemDocNoTable from './ItemDocNoTable';
import ItemDetailActions from '../../Stores/ItemDetail/Actions';

const { TabPane } = Tabs;

class ItemDetailScreen extends React.PureComponent {
  componentDidMount() {
    const { match, setReslId } = this.props;

    if (match.params.action === 'create') {
      setReslId(0);
    } else if (match.params.action === 'update') {
      setReslId(parseInt(match.params.id, 10));
    }
  }

  componentDidUpdate() {}

  componentWillUnmount() {}

  render() {
    const { intl, match, appPath, model } = this.props;

    return (
      <Card title={`${intl.formatMessage({ id: 'item' })} ${model.code}`}>
        <Tabs type="card" activeKey={match.params.proc_type}>
          <TabPane
            tab={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <Tooltip placement="bottom" title={intl.formatMessage({ id: `general` })}>
                <Link
                  style={match.params.proc_type !== 'GENERAL' ? { color: 'black' } : {}}
                  to={`${appPath}/itemDetail/${match.params.action}/${match.params.id}/GENERAL`}
                  replace
                >
                  <FormattedMessage id="general" />
                </Link>
              </Tooltip>
            }
            key="GENERAL"
          />
          <TabPane
            tab={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <Tooltip placement="bottom" title={intl.formatMessage({ id: `item_batch` })}>
                <Link
                  style={match.params.proc_type !== 'ITEM_BATCH' ? { color: 'black' } : {}}
                  to={`${appPath}/itemDetail/${match.params.action}/${match.params.id}/ITEM_BATCH`}
                  replace
                >
                  <FormattedMessage id="item_batch" />
                </Link>
              </Tooltip>
            }
            key="ITEM_BATCH"
          />
          <TabPane
            tab={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <Tooltip placement="bottom" title={intl.formatMessage({ id: `item_doc_no` })}>
                <Link
                  style={match.params.proc_type !== 'ITEM_DOC_NO' ? { color: 'black' } : {}}
                  to={`${appPath}/itemDetail/${match.params.action}/${match.params.id}/ITEM_DOC_NO`}
                  replace
                >
                  <FormattedMessage id="item_doc_no" />
                </Link>
              </Tooltip>
            }
            key="ITEM_DOC_NO"
          />
        </Tabs>

        {match.params.proc_type === 'GENERAL' && <ModelForm match={match} />}
        {match.params.proc_type === 'ITEM_BATCH' && <ItemBatchTable match={match} />}
        {match.params.proc_type === 'ITEM_DOC_NO' && <ItemDocNoTable match={match} />}
      </Card>
    );
  }
}

ItemDetailScreen.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object,
  appPath: PropTypes.string,

  setReslId: PropTypes.func,
  model: PropTypes.shape({
    code: PropTypes.string,
    status: PropTypes.number
  })
};

ItemDetailScreen.defaultProps = {
  intl: {},
  match: {},
  appPath: '',

  setReslId() {},
  model: { code: '', status: 2 }
};

const mapStateToProps = state => ({
  appPath: state.app.appPath,
  model: state.itemDetail.model
});

const mapDispatchToProps = dispatch => ({
  setReslId: resId => dispatch(ItemDetailActions.itemDetailSetResId(resId))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ItemDetailScreen));
