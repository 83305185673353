import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import treeChanges from 'tree-changes';
import moment from 'moment';

import { DeleteOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';

import { Table, Button, Popconfirm } from 'antd';

import PrfDelDetailActions from '../../Stores/PrfDelDetail/Actions';

class OutbOrdTable extends React.PureComponent {
  constructor() {
    super();

    this.state = { pageSize: 20 };

    this.getDocumentColumns = this.getDocumentColumns.bind(this);
    this.useOnDeleteItem = this.useOnDeleteItem.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    const { hdrId, showOutbOrdHdrs } = this.props;

    const { changed } = treeChanges(prevProps, this.props);

    if (changed('timestamp')) {
      showOutbOrdHdrs(hdrId);
    }
  }

  componentWillUnmount() {}

  getDocumentColumns() {
    const { intl, hdrId, upOutbOrd, downOutbOrd, outbOrdHdrs } = this.props;

    return [
      {
        width: 50,
        // fixed: 'left',
        key: 'action',
        render: (text, record) => (
          <>
            <Button
              type="dashed"
              icon={<UpOutlined />}
              size="small"
              onClick={() => upOutbOrd(hdrId, record)}
            />
            <Button
              type="dashed"
              icon={<DownOutlined />}
              size="small"
              onClick={() => downOutbOrd(hdrId, record)}
            />
          </>
        )
      },
      {
        width: 50,
        // fixed: 'right',
        key: 'action',
        render: (text, record) => (
          <>
            <Popconfirm
              placement="left"
              title={intl.formatMessage({ id: 'are_you_sure_to_remove_this_line' })}
              onConfirm={() => this.useOnDeleteItem(record)}
              onCancel={() => {}}
              okText={intl.formatMessage({ id: 'yes' })}
              cancelText={intl.formatMessage({ id: 'cancel' })}
            >
              <Button type="dashed" icon={<DeleteOutlined />} size="small" />
            </Popconfirm>
          </>
        )
      },
      {
        // fixed: 'left',
        width: 50,
        align: 'right',
        title: '#',
        key: 'line_no',
        render: (text, record) => (
          <span style={{ wordBreak: 'normal' }}>{outbOrdHdrs.indexOf(record) + 1}</span>
        )
      },
      {
        width: 120,
        align: 'left',
        title: intl.formatMessage({ id: 'shipping_doc_code' }),
        // sort field
        dataIndex: 'shipping_doc_code',
        // filter field
        key: 'shipping_doc_code',
        render: (text, record) => record.shipping_doc_code
      },
      {
        width: 120,
        align: 'left',
        title: intl.formatMessage({ id: 'ref_code_01' }),
        // sort field
        dataIndex: 'ref_code_01',
        // filter field
        key: 'ref_code_01',
        render: (text, record) => record.ref_code_01
      },
      {
        width: 105,
        align: 'left',
        title: intl.formatMessage({ id: 'doc_date' }),
        // sort field
        dataIndex: 'doc_date',
        // filter field
        key: 'doc_date',
        render: (text, record) => <>{moment(record.doc_date).format('YYYY-MM-DD')}</>
      },
      {
        width: 100,
        // fixed: 'right',
        align: 'right',
        title: intl.formatMessage({ id: 'net_amt' }),
        // sort field
        dataIndex: 'net_amt',
        // filter field
        key: 'net_amt',
        render: (text, record) => (
          <>
            {new Intl.NumberFormat([], {
              style: 'decimal',
              minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
              maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
            }).format(record.net_amt)}
          </>
        )
      },
      {
        width: 100,
        // fixed: 'right',
        align: 'right',
        title: intl.formatMessage({ id: 'case_decimal_qty' }),
        // sort field
        dataIndex: 'case_decimal_qty',
        // filter field
        key: 'case_decimal_qty',
        render: (text, record) => (
          <>
            {new Intl.NumberFormat([], {
              style: 'decimal',
              minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
              maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
            }).format(record.case_decimal_qty)}
          </>
        )
      },
      {
        width: 100,
        // fixed: 'right',
        align: 'right',
        title: intl.formatMessage({ id: 'gross_weight' }),
        // sort field
        dataIndex: 'gross_weight',
        // filter field
        key: 'gross_weight',
        render: (text, record) => (
          <>
            {new Intl.NumberFormat([], {
              style: 'decimal',
              minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
              maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
            }).format(record.gross_weight)}
          </>
        )
      },
      {
        width: 100,
        // fixed: 'right',
        align: 'right',
        title: intl.formatMessage({ id: 'cubic_meter' }),
        // sort field
        dataIndex: 'cubic_meter',
        // filter field
        key: 'cubic_meter',
        render: (text, record) => (
          <>
            {new Intl.NumberFormat([], {
              style: 'decimal',
              minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
              maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
            }).format(record.cubic_meter)}
          </>
        )
      },
      {
        width: 120,
        align: 'left',
        title: intl.formatMessage({ id: 'division' }),
        // sort field
        dataIndex: 'division_code',
        // filter field
        key: 'division',
        render: (text, record) => <>{record.division_code}</>
      },
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'area' }),
        // sort field
        dataIndex: 'delivery_point_area_code',
        // filter field
        key: 'delivery_point_area',
        render: (text, record) => (
          <>
            <b>{record.delivery_point_area_code}</b>
            <br />
            {record.delivery_point_area_desc_01}
          </>
        )
      },
      {
        width: 150,
        align: 'left',
        title: intl.formatMessage({ id: 'delivery_point' }),
        // sort field
        dataIndex: 'delivery_point_code',
        // filter field
        key: 'delivery_point',
        render: (text, record) => (
          <>
            <b>{record.delivery_point_code}</b>
            <br />
            {record.delivery_point_company_name_01}
          </>
        )
      },
      {
        width: 120,
        align: 'left',
        title: intl.formatMessage({ id: 'salesman' }),
        // sort field
        dataIndex: 'salesman_username',
        // filter field
        key: 'salesman',
        render: (text, record) => (
          <>
            <b>{record.salesman_username}</b>
          </>
        )
      }
    ];
  }

  useOnDeleteItem(record) {
    const { hdrId, deleteDetailsByOrdHdrIds } = this.props;

    deleteDetailsByOrdHdrIds(hdrId, [record.id]);
  }

  render() {
    const { intl, outbOrdHdrs, documentIsLoading, fetchIsLoading } = this.props;
    const { pageSize: statePageSize } = this.state;

    return (
      <Table
        title={() => intl.formatMessage({ id: 'outbound_orders' })}
        // rowSelection={rowSelection}
        rowKey="id"
        pagination={{
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
          showSizeChanger: true,
          pageSizeOptions: ['20', '50'],
          onShowSizeChange: (current, pageSize) => {
            this.setState({
              pageSize
            });
          },
          pageSize: statePageSize
        }}
        columns={this.getDocumentColumns()}
        dataSource={outbOrdHdrs}
        loading={documentIsLoading || fetchIsLoading}
        bordered
        rowClassName={rowData => {
          if ('is_modified' in rowData && rowData.is_modified === true) {
            return 'success-row';
          }
          return '';
        }}
        scroll={{ x: 950 }}
        summary={pageData => {
          // summarise the table
          const calcRow = pageData.reduce(
            (ttlObj, object) => {
              return {
                net_amt: ('net_amt' in ttlObj ? ttlObj.net_amt : 0) + parseFloat(object.net_amt),
                case_decimal_qty:
                  ('case_decimal_qty' in ttlObj ? ttlObj.case_decimal_qty : 0) +
                  parseFloat(object.case_decimal_qty),
                gross_weight:
                  ('gross_weight' in ttlObj ? ttlObj.gross_weight : 0) +
                  parseFloat(object.gross_weight),
                cubic_meter:
                  ('cubic_meter' in ttlObj ? ttlObj.cubic_meter : 0) +
                  parseFloat(object.cubic_meter)
              };
            },
            { net_amt: 0, case_decimal_qty: 0, gross_weight: 0, cubic_meter: 0 }
          );

          return (
            <>
              <tr className="ant-table-row">
                <td style={{ textAlign: 'right', fontWeight: 'bold' }} colSpan={6}>
                  {intl.formatMessage({ id: 'total' })}
                </td>
                <td style={{ textAlign: 'right', fontWeight: 'bold' }}>
                  {new Intl.NumberFormat([], {
                    style: 'decimal',
                    minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                    maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
                  }).format(calcRow.net_amt)}
                </td>
                <td style={{ textAlign: 'right', fontWeight: 'bold' }}>
                  {new Intl.NumberFormat([], {
                    style: 'decimal',
                    minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                    maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
                  }).format(calcRow.case_decimal_qty)}
                </td>
                <td style={{ textAlign: 'right', fontWeight: 'bold' }}>
                  {new Intl.NumberFormat([], {
                    style: 'decimal',
                    minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                    maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
                  }).format(calcRow.gross_weight)}
                </td>
                <td style={{ textAlign: 'right', fontWeight: 'bold' }}>
                  {new Intl.NumberFormat([], {
                    style: 'decimal',
                    minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                    maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
                  }).format(calcRow.cubic_meter)}
                </td>
              </tr>
            </>
          );
        }}
      />
    );
  }
}

OutbOrdTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  showOutbOrdHdrs: PropTypes.func,
  outbOrdHdrs: PropTypes.arrayOf(PropTypes.object),
  documentIsLoading: PropTypes.bool,
  fetchIsLoading: PropTypes.bool,

  hdrId: PropTypes.number,
  upOutbOrd: PropTypes.func,
  downOutbOrd: PropTypes.func,

  deleteDetailsByOrdHdrIds: PropTypes.func
};

OutbOrdTable.defaultProps = {
  intl: {},

  showOutbOrdHdrs() {},
  outbOrdHdrs: [],

  hdrId: 0,
  documentIsLoading: false,
  fetchIsLoading: false,

  upOutbOrd() {},
  downOutbOrd() {},

  deleteDetailsByOrdHdrIds() {}
};

const mapStateToProps = state => ({
  timestamp: state.prfDelDetail.timestamp,
  hdrId: state.prfDelDetail.hdrId,
  outbOrdHdrs: state.prfDelDetail.outbOrdHdrs,
  documentIsLoading: state.prfDelDetail.documentIsLoading,
  fetchIsLoading: state.prfDelDetail.fetchIsLoading
});

const mapDispatchToProps = dispatch => ({
  resetTimestamp: (currentPage, sorts, filters, pageSize) =>
    dispatch(PrfDelDetailActions.outbOrdIndexResetTimestamp(currentPage, sorts, filters, pageSize)),
  showOutbOrdHdrs: hdrId => dispatch(PrfDelDetailActions.prfDelDetailShowOutbOrdHdrs(hdrId)),

  upOutbOrd: (hdrId, outbOrdHdr) =>
    dispatch(PrfDelDetailActions.prfDelDetailUpOutbOrd(hdrId, outbOrdHdr)),
  downOutbOrd: (hdrId, outbOrdHdr) =>
    dispatch(PrfDelDetailActions.prfDelDetailDownOutbOrd(hdrId, outbOrdHdr)),

  deleteDetailsByOrdHdrIds: (hdrId, outbOrdHdrIds) =>
    dispatch(PrfDelDetailActions.prfDelDetailDeleteDetailsByOrdHdrIds(hdrId, outbOrdHdrIds))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(OutbOrdTable));
