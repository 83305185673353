import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import treeChanges from 'tree-changes';
import { Link } from 'react-router-dom';

import { Col, Row, Statistic, Spin } from 'antd';
import { Card } from 'react-bootstrap';

import SummaryBoardActions from '../../Stores/SummaryBoard/Actions';
import './SummaryBoardStyle.css';

class PickListSummaryCard extends React.PureComponent {
  componentDidMount() {
    const {
      resetTimestampOutbOrds,
      resetTimestampWip,
      resetTimestampWhseJobs,
      resetTimestampCompleted
    } = this.props;

    const { curSiteFlowId } = this.props;

    if (curSiteFlowId > 0) {
      resetTimestampOutbOrds();
      resetTimestampWip();
      resetTimestampWhseJobs();
      resetTimestampCompleted();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      curSiteFlowId,
      fetchSummaryOutbOrds,
      fetchSummaryWip,
      fetchSummaryWhseJobs,
      fetchSummaryCompleted
    } = this.props;

    const {
      resetTimestampOutbOrds,
      resetTimestampWip,
      resetTimestampWhseJobs,
      resetTimestampCompleted
    } = this.props;

    const { changed } = treeChanges(prevProps, this.props);

    if (changed('curSiteFlowId')) {
      resetTimestampOutbOrds();
      resetTimestampWip();
      resetTimestampWhseJobs();
      resetTimestampCompleted();
    }

    if (changed('summaryOutbOrdsTimestamp')) {
      if (curSiteFlowId > 0) {
        fetchSummaryOutbOrds();
      }
    }

    if (changed('summaryWipTimestamp')) {
      if (curSiteFlowId > 0) {
        fetchSummaryWip();
      }
    }

    if (changed('summaryWhseJobsTimestamp')) {
      if (curSiteFlowId > 0) {
        fetchSummaryWhseJobs();
      }
    }

    if (changed('summaryCompletedTimestamp')) {
      if (curSiteFlowId > 0) {
        fetchSummaryCompleted();
      }
    }
  }

  render() {
    const { summaryOutbOrds, summaryWip, summaryWhseJobs, summaryCompleted } = this.props;

    const { intl } = this.props;

    const {
      summaryOutbOrdsIsLoading,
      summaryWipIsLoading,
      summaryWhseJobsIsLoading,
      summaryCompletedIsLoading
    } = this.props;

    return (
      <Link to="/pickListDetailBoard" style={{ textDecoration: 'none' }}>
        <Card className="summary-card" text="dark">
          <Card.Header className="pick-list-header">
            {intl.formatMessage({ id: 'pick_list_summary' })}
          </Card.Header>
          <Row>
            <Col span={12} style={summaryOutbOrdsIsLoading ? { paddingTop: '10%' } : {}}>
              <Card.Body>
                {summaryOutbOrdsIsLoading ? (
                  <Spin />
                ) : (
                  <div>
                    <Statistic
                      title={intl.formatMessage({ id: 'outbound_orders' })}
                      value={summaryOutbOrds.total}
                    />
                    <div style={{ color: 'red' }}>{summaryOutbOrds.date_oldest}</div>
                  </div>
                )}
              </Card.Body>
            </Col>
            <Col span={12} style={summaryWipIsLoading ? { paddingTop: '10%' } : {}}>
              <Card.Body>
                {summaryWipIsLoading ? (
                  <Spin />
                ) : (
                  <div>
                    <Statistic title={intl.formatMessage({ id: 'WIP' })} value={summaryWip.total} />
                    <div style={{ color: 'red' }}>{summaryWip.date_oldest}</div>
                  </div>
                )}
              </Card.Body>
            </Col>
            <Col span={12} style={summaryWhseJobsIsLoading ? { paddingBottom: '10%' } : {}}>
              <Card.Body>
                {summaryWhseJobsIsLoading ? (
                  <Spin />
                ) : (
                  <div>
                    <Statistic
                      title={intl.formatMessage({ id: 'mobile_jobs' })}
                      value={summaryWhseJobs.total}
                    />
                    <div style={{ color: 'red' }}>{summaryWhseJobs.date_oldest}</div>
                  </div>
                )}
              </Card.Body>
            </Col>
            <Col span={12} style={summaryCompletedIsLoading ? { paddingBottom: '10%' } : {}}>
              <Card.Body>
                {summaryCompletedIsLoading ? (
                  <Spin />
                ) : (
                  <div>
                    <Statistic
                      title={intl.formatMessage({ id: 'completed' })}
                      value={summaryCompleted.total}
                    />
                    <div style={{ color: 'red' }}>{summaryCompleted.date_oldest}</div>
                  </div>
                )}
              </Card.Body>
            </Col>
          </Row>
        </Card>
      </Link>
    );
  }
}

PickListSummaryCard.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,

  // eslint-disable-next-line react/forbid-prop-types
  summaryOutbOrds: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  summaryWip: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  summaryWhseJobs: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  summaryCompleted: PropTypes.object,

  summaryOutbOrdsIsLoading: PropTypes.bool,
  summaryWipIsLoading: PropTypes.bool,
  summaryWhseJobsIsLoading: PropTypes.bool,
  summaryCompletedIsLoading: PropTypes.bool,

  curSiteFlowId: PropTypes.number,
  fetchSummaryOutbOrds: PropTypes.func,
  fetchSummaryWip: PropTypes.func,
  fetchSummaryWhseJobs: PropTypes.func,
  fetchSummaryCompleted: PropTypes.func,

  resetTimestampOutbOrds: PropTypes.func,
  resetTimestampWip: PropTypes.func,
  resetTimestampWhseJobs: PropTypes.func,
  resetTimestampCompleted: PropTypes.func
};

PickListSummaryCard.defaultProps = {
  intl: {},

  summaryOutbOrds: { total: 0, date_oldest: '0 days' },
  summaryWip: { total: 0, date_oldest: '0 days' },
  summaryWhseJobs: { total: 0, date_oldest: '0 days' },
  summaryCompleted: { total: 0, date_oldest: '0 days' },

  summaryOutbOrdsIsLoading: false,
  summaryWipIsLoading: false,
  summaryWhseJobsIsLoading: false,
  summaryCompletedIsLoading: false,

  curSiteFlowId: 0,
  fetchSummaryOutbOrds() {},
  fetchSummaryWip() {},
  fetchSummaryWhseJobs() {},
  fetchSummaryCompleted() {},

  resetTimestampOutbOrds() {},
  resetTimestampWip() {},
  resetTimestampWhseJobs() {},
  resetTimestampCompleted() {}
};

const mapStateToProps = state => ({
  summaryOutbOrdsTimestamp: state.summaryBoard.pickListSummaryOutbOrdsTimestamp,
  summaryOutbOrds: state.summaryBoard.pickListSummaryOutbOrds,

  summaryWipTimestamp: state.summaryBoard.pickListSummaryWipTimestamp,
  summaryWip: state.summaryBoard.pickListSummaryWip,

  summaryWhseJobsTimestamp: state.summaryBoard.pickListSummaryWhseJobsTimestamp,
  summaryWhseJobs: state.summaryBoard.pickListSummaryWhseJobs,

  summaryCompletedTimestamp: state.summaryBoard.pickListSummaryCompletedTimestamp,
  summaryCompleted: state.summaryBoard.pickListSummaryCompleted,

  summaryOutbOrdsIsLoading: state.summaryBoard.pickListSummaryOutbOrdsIsLoading,
  summaryWipIsLoading: state.summaryBoard.pickListSummaryWipIsLoading,
  summaryWhseJobsIsLoading: state.summaryBoard.pickListSummaryWhseJobsIsLoading,
  summaryCompletedIsLoading: state.summaryBoard.pickListSummaryCompletedIsLoading,

  curSiteFlowId: state.app.curSiteFlowId
});

const mapDispatchToProps = dispatch => ({
  resetTimestampOutbOrds: () =>
    dispatch(SummaryBoardActions.pickListSummaryBoardResetOutbOrdsTimestamp()),

  fetchSummaryOutbOrds: () => dispatch(SummaryBoardActions.pickListSummaryBoardFetchOutbOrds()),

  resetTimestampWip: () => dispatch(SummaryBoardActions.pickListSummaryBoardResetWipTimestamp()),

  fetchSummaryWip: () => dispatch(SummaryBoardActions.pickListSummaryBoardFetchWip()),

  resetTimestampWhseJobs: () =>
    dispatch(SummaryBoardActions.pickListSummaryBoardResetWhseJobsTimestamp()),

  fetchSummaryWhseJobs: () => dispatch(SummaryBoardActions.pickListSummaryBoardFetchWhseJobs()),

  resetTimestampCompleted: () =>
    dispatch(SummaryBoardActions.pickListSummaryBoardResetCompletedTimestamp()),

  fetchSummaryCompleted: () => dispatch(SummaryBoardActions.pickListSummaryBoardFetchCompleted())
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PickListSummaryCard));
