/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { PrincipalProcessTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const principalProcessFetchPrincipalProcessLoading = (state, { boolean }) => ({
  ...state,
  processIsLoading: boolean
});

export const principalProcessFetchPrincipalProcessSuccess = (state, { process }) => ({
  ...state,
  process
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [PrincipalProcessTypes.PRINCIPAL_PROCESS_FETCH_PRINCIPAL_PROCESS_LOADING]: principalProcessFetchPrincipalProcessLoading,
  [PrincipalProcessTypes.PRINCIPAL_PROCESS_FETCH_PRINCIPAL_PROCESS_SUCCESS]: principalProcessFetchPrincipalProcessSuccess
});
