import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  lspSlsOrdExcel01SetStrProcType: ['strProcType'],
  lspSlsOrdExcel01ShowBatchJobStatus: null,
  lspSlsOrdExcel01ShowBatchJobStatusSuccess: ['batchJobStatus'],
  lspSlsOrdExcel01UploadExcel: ['formikBag', 'projectId', 'debtorId', 'slsOrdFile'],
  lspSlsOrdExcel01UploadLoading: ['boolean'],

  lspSlsOrdExcel01FetchDebtorOptions: ['search'],
  lspSlsOrdExcel01FetchDebtorOptionLoading: ['boolean'],
  lspSlsOrdExcel01FetchDebtorOptionSuccess: ['options'],

  lspSlsOrdExcel01FetchProjectOptions: ['search'],
  lspSlsOrdExcel01FetchProjectOptionLoading: ['boolean'],
  lspSlsOrdExcel01FetchProjectOptionSuccess: ['options']
});

export const LspSlsOrdExcel01Types = Types;
export default Creators;
