import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  debtorDetailResetModelTimestamp: null,
  debtorDetailSetResId: ['resId'],

  debtorDetailInitModel: null,
  debtorDetailShowModel: ['resId'],
  debtorDetailShowModelSuccess: ['model'],

  debtorDetailUpdateModel: ['formikBag', 'model'],
  debtorDetailCreateModel: ['formikBag', 'model'],
  debtorDetailUpdateModelSuccess: ['model'],

  debtorDetailShowModelLoading: ['boolean'],

  debtorDetailFetchAreaOptions: ['search'],
  debtorDetailFetchAreaOptionLoading: ['boolean'],
  debtorDetailFetchAreaOptionSuccess: ['options'],

  debtorDetailResetDeliveryPointTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  debtorDetailShowDeliveryPoints: ['resId', 'currentPage', 'sorts', 'filters', 'pageSize'],
  debtorDetailShowDeliveryPointSuccess: [
    'deliveryPoints',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],
  debtorDetailGoToDeliveryPoint: ['deliveryPointId'],
  debtorDetailNewDeliveryPoint: ['debtorId']
});

export const DebtorDetailTypes = Types;
export default Creators;
