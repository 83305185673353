import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  whseJob1402ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  whseJob1402GoToDocument: ['hdrId'],
  whseJob1402FetchWhseJob1402: ['currentPage', 'sorts', 'filters', 'pageSize'],
  whseJob1402FetchWhseJob1402Loading: ['boolean'],
  whseJob1402FetchWhseJob1402Success: ['documents', 'currentPage', 'lastPage', 'total', 'pageSize'],
  whseJob1402AddSelectedDocuments: ['selectedDocuments'],
  whseJob1402RemoveSelectedDocuments: ['selectedDocuments'],
  whseJob1402SetWorkspaceVisible: ['boolean'],
  whseJob1402CreateWhseJob1402: ['hdrIds'],
  whseJob1402CreateWhseJob1402Loading: ['boolean'],
  whseJob1402CreateWhseJob1402Success: ['newDocuments']
});

export const WhseJob1402Types = Types;
export default Creators;
