import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  prodOrdFServ01ShowSyncSetting: null,
  prodOrdFServ01ShowSyncSettingLoading: ['boolean'],
  prodOrdFServ01ShowSyncSettingSuccess: ['syncSetting'],
  prodOrdFServ01UpdateSyncSetting: ['formikBag', 'syncSetting'],
  prodOrdFServ01ShowBatchJobStatus: null,
  prodOrdFServ01ShowBatchJobStatusSuccess: ['batchJobStatus'],
  prodOrdFServ01Import: ['formikBag'],
  prodOrdFServ01ImportLoading: ['boolean']
});

export const ProdOrdFServ01Types = Types;
export default Creators;
