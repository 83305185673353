import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  divisionList01ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  divisionList01NewResource: null,
  divisionList01GoToResource: ['resId'],
  divisionList01FetchDivisionList01: ['currentPage', 'sorts', 'filters', 'pageSize'],
  divisionList01FetchDivisionList01Loading: ['boolean'],
  divisionList01FetchDivisionList01Success: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],
  divisionList01FetchDivisionListById: ['currentPage', 'sorts', 'filters', 'pageSize']
});

export const DivisionList01Types = Types;
export default Creators;
