import { put, call, select, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import ApiService from '../Services/ApiService';
import AppActions from '../Stores/App/Actions';
import CountAdjSync02Actions, { CountAdjSync02Types } from '../Stores/CountAdjSync02/Actions';

const getAppStore = state => state.app;

export function* countAdjSync02ShowBatchJobStatus() {
  try {
    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `batchJobStatus/showBatchJobStatus/COUNT_ADJ_SYNC_02`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(CountAdjSync02Actions.countAdjSync02ShowBatchJobStatusSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  }
}

export function* countAdjSync02Export({ formikBag, criteria }) {
  formikBag.setSubmitting(true);
  try {
    yield put(CountAdjSync02Actions.countAdjSync02ExportLoading(true));

    const objCriteria = {};
    if ('doc_date' in criteria) {
      objCriteria.doc_date = criteria.doc_date;
    }
    if ('company_id_select2' in criteria) {
      objCriteria.company_id = criteria.company_id_select2.value;
    }
    if ('location_id_select2' in criteria) {
      objCriteria.location_id = criteria.location_id_select2.value;
    }
    if ('item_group_01_ids_select2' in criteria) {
      const values = criteria.item_group_01_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.item_group_01_ids = values.map(entry => {
          return entry.value;
        });
      }
    }
    if ('item_ids_select2' in criteria) {
      const values = criteria.item_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.item_ids = values.map(entry => {
          return entry.value;
        });
      }
    }

    const app = yield select(getAppStore);
    const postData = {
      criteria: objCriteria
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `countAdj/syncProcess/COUNT_ADJ_SYNC_02/${app.curSiteFlowId}/${app.curDivisionId}`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      yield put(CountAdjSync02Actions.countAdjSync02ExportSuccess());

      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_SUCCESS_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
    yield put(CountAdjSync02Actions.countAdjSync02ExportLoading(false));
  }
}

export function* countAdjSync02FetchCompanyOptions({ search }) {
  try {
    yield put(CountAdjSync02Actions.countAdjSync02FetchCompanyOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `company/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({ value: d.id, label: `${d.code}` }));

      yield put(CountAdjSync02Actions.countAdjSync02FetchCompanyOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(CountAdjSync02Actions.countAdjSync02FetchCompanyOptionLoading(false));
  }
}

export function* countAdjSync02FetchLocationOptions({ search }) {
  try {
    yield put(CountAdjSync02Actions.countAdjSync02FetchLocationOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId: app.curSiteFlowId,
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `location/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(CountAdjSync02Actions.countAdjSync02FetchLocationOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(CountAdjSync02Actions.countAdjSync02FetchLocationOptionLoading(false));
  }
}

export function* countAdjSync02FetchItemOptions({ search }) {
  try {
    yield put(CountAdjSync02Actions.countAdjSync02FetchItemOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `item/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(CountAdjSync02Actions.countAdjSync02FetchItemOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(CountAdjSync02Actions.countAdjSync02FetchItemOptionLoading(false));
  }
}

export function* countAdjSync02FetchItemGroup01Options({ search }) {
  try {
    yield put(CountAdjSync02Actions.countAdjSync02FetchItemGroup01OptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `itemGroup01/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(CountAdjSync02Actions.countAdjSync02FetchItemGroup01OptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(CountAdjSync02Actions.countAdjSync02FetchItemGroup01OptionLoading(false));
  }
}

export const saga = [
  takeLatest(
    CountAdjSync02Types.COUNT_ADJ_SYNC02_SHOW_BATCH_JOB_STATUS,
    countAdjSync02ShowBatchJobStatus
  ),
  takeLatest(CountAdjSync02Types.COUNT_ADJ_SYNC02_EXPORT, countAdjSync02Export),

  takeLatest(
    CountAdjSync02Types.COUNT_ADJ_SYNC02_FETCH_COMPANY_OPTIONS,
    countAdjSync02FetchCompanyOptions
  ),
  takeLatest(
    CountAdjSync02Types.COUNT_ADJ_SYNC02_FETCH_LOCATION_OPTIONS,
    countAdjSync02FetchLocationOptions
  ),
  takeLatest(
    CountAdjSync02Types.COUNT_ADJ_SYNC02_FETCH_ITEM_GROUP01_OPTIONS,
    countAdjSync02FetchItemGroup01Options
  ),
  takeLatest(
    CountAdjSync02Types.COUNT_ADJ_SYNC02_FETCH_ITEM_OPTIONS,
    countAdjSync02FetchItemOptions
  )
];
