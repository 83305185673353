import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import treeChanges from 'tree-changes';

import { AuditOutlined, EditOutlined, LaptopOutlined, ReloadOutlined } from '@ant-design/icons';

import { Card, Row, Col, Table, Button, Badge } from 'antd';
import moment from 'moment';

import CriteriaForm from './CriteriaForm';
import AntDesignTable from '../../Components/AntDesignTable';
import BinTrf01Actions from '../../Stores/BinTrf01/Actions';

class BinTrf01Table extends React.PureComponent {
  constructor() {
    super();

    this.useOnTableChange = this.useOnTableChange.bind(this);
    this.useShowWorkspace = this.useShowWorkspace.bind(this);

    this.handleSearch = this.handleSearch.bind(this);
    this.getDocumentColumns = this.getDocumentColumns.bind(this);
  }

  componentDidMount() {
    const { curSiteFlowId, setWorkspaceVisible, resetTimestamp } = this.props;

    if (curSiteFlowId > 0) {
      setWorkspaceVisible(false);
      resetTimestamp();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      curSiteFlowId,
      resetTimestamp,
      fetchBinTrf01,
      currentPage,
      sorts,
      criteria,
      pageSize
    } = this.props;

    const { changed } = treeChanges(prevProps, this.props);

    if (changed('curSiteFlowId')) {
      resetTimestamp();
    }

    if (changed('timestamp')) {
      if (curSiteFlowId > 0) {
        fetchBinTrf01(currentPage, sorts, criteria, pageSize);
      }
    }
  }

  componentWillUnmount() {}

  handleSearch(selectedKeys, confirm) {
    // eslint-disable-next-line no-unused-vars
    const { intl } = this.props;

    confirm();
  }

  getDocumentColumns() {
    const { intl, sorts, goToResource, goToAudit, calcDates } = this.props;

    return [
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'company_code' }),
        // sort field
        dataIndex: 'company_code',
        // filter field
        key: 'company_code',
        render: (text, record) => <>{record.company_code}</>
      },
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'item_code' }),
        // sort field
        dataIndex: 'item_code',
        // filter field
        key: 'item_code',
        render: (text, record) => <>{record.item_code}</>
      },
      {
        width: 150,
        align: 'left',
        title: intl.formatMessage({ id: 'description' }),
        // sort field
        dataIndex: 'item_desc_01',
        ...AntDesignTable.getColumnSortProps(sorts, 'item_desc_01'),
        // filter field
        key: 'item_desc_01',
        render: (text, record) => (
          <>
            {record.item_desc_01}
            {record.item_desc_02 ? (
              <>
                <br />
                {record.item_desc_02}
              </>
            ) : (
              ''
            )}
          </>
        )
      },
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'brand' }),
        // sort field
        dataIndex: 'item_group01_code',
        ...AntDesignTable.getColumnSortProps(sorts, 'item_group01_code'),
        // filter field
        key: 'item_group01_code_in',
        render: (text, record) => <>{record.item_group_01_code}</>
      },
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'category' }),
        // sort field
        dataIndex: 'item_group02_code',
        ...AntDesignTable.getColumnSortProps(sorts, 'item_group02_code'),
        // filter field
        key: 'item_group02_code_in',
        render: (text, record) => <>{record.item_group_02_code}</>
      },
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'manufacturer' }),
        // sort field
        dataIndex: 'item_group03_code',
        ...AntDesignTable.getColumnSortProps(sorts, 'item_group03_code'),
        // filter field
        key: 'item_group03_code_in',
        render: (text, record) => <>{record.item_group_03_code}</>
      },
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'status' }),
        // sort field
        dataIndex: 'status',
        ...AntDesignTable.getColumnSortProps(sorts, 'status'),
        // filter field
        key: 'status',
        render: (text, record) => <>{record.str_status}</>
      },
      {
        width: 110,
        align: 'right',
        title: intl.formatMessage({ id: 'cases_per_layer' }),
        // sort field
        dataIndex: 'cases_per_pallet_length',
        // filter field
        key: 'cases_per_pallet_length',
        render: (text, record) => (
          <>
            {new Intl.NumberFormat([], {
              style: 'decimal',
              minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
              maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
            }).format(record.cases_per_pallet_length)}
          </>
        )
      },
      {
        width: 110,
        align: 'right',
        title: intl.formatMessage({ id: 'no_of_layers' }),
        // sort field
        dataIndex: 'no_of_layers',
        // filter field
        key: 'no_of_layers',
        render: (text, record) => (
          <>
            {new Intl.NumberFormat([], {
              style: 'decimal',
              minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
              maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
            }).format(record.no_of_layers)}
          </>
        )
      },
      {
        width: 100,
        align: 'right',
        title: (
          <>
            <div>
              {intl.formatMessage({
                id: 'daily_case_qty'
              })}
            </div>
            <div>{moment(calcDates.month01).format('MMM YYYY')}</div>
          </>
        ),
        // sort field
        dataIndex: 'month_1',
        // filter field
        key: 'month_1',
        render: (text, record) => (
          <>
            {record.calc_date_01 !== '1970-01-01 00:00:00'
              ? new Intl.NumberFormat([], {
                  style: 'decimal',
                  minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                  maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
                }).format(record.calc_case_qty_01)
              : ''}
          </>
        )
      },
      {
        width: 100,
        align: 'right',
        title: (
          <>
            <div>
              {intl.formatMessage({
                id: 'daily_case_qty'
              })}
            </div>
            <div>{moment(calcDates.month02).format('MMM YYYY')}</div>
          </>
        ),
        // sort field
        dataIndex: 'month_2',
        // filter field
        key: 'month_2',
        render: (text, record) => (
          <>
            {record.calc_date_02 !== '1970-01-01 00:00:00'
              ? new Intl.NumberFormat([], {
                  style: 'decimal',
                  minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                  maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
                }).format(record.calc_case_qty_02)
              : ''}
          </>
        )
      },
      {
        width: 100,
        align: 'right',
        title: (
          <>
            <div>
              {intl.formatMessage({
                id: 'daily_case_qty'
              })}
            </div>
            <div>{moment(calcDates.month03).format('MMM YYYY')}</div>
          </>
        ),
        // sort field
        dataIndex: 'month_3',
        // filter field
        key: 'month_3',
        render: (text, record) => (
          <>
            {record.calc_date_03 !== '1970-01-01 00:00:00'
              ? new Intl.NumberFormat([], {
                  style: 'decimal',
                  minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                  maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
                }).format(record.calc_case_qty_03)
              : ''}
          </>
        )
      },
      {
        width: 100,
        align: 'right',
        title: (
          <>
            <div>
              {intl.formatMessage({
                id: 'daily_case_qty'
              })}
            </div>
            <div>{moment(calcDates.month04).format('MMM YYYY')}</div>
          </>
        ),
        // sort field
        dataIndex: 'month_4',
        // filter field
        key: 'month_4',
        render: (text, record) => (
          <>
            {record.calc_date_04 !== '1970-01-01 00:00:00'
              ? new Intl.NumberFormat([], {
                  style: 'decimal',
                  minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                  maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
                }).format(record.calc_case_qty_04)
              : ''}
          </>
        )
      },
      {
        width: 100,
        align: 'right',
        title: (
          <>
            <div>
              {intl.formatMessage({
                id: 'daily_case_qty'
              })}
            </div>
            <div>{moment(calcDates.month05).format('MMM YYYY')}</div>
          </>
        ),
        // sort field
        dataIndex: 'month_5',
        // filter field
        key: 'month_5',
        render: (text, record) => (
          <>
            {record.calc_date_05 !== '1970-01-01 00:00:00'
              ? new Intl.NumberFormat([], {
                  style: 'decimal',
                  minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                  maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
                }).format(record.calc_case_qty_05)
              : ''}
          </>
        )
      },
      {
        width: 100,
        align: 'right',
        title: (
          <>
            <div>
              {intl.formatMessage({
                id: 'daily_case_qty'
              })}
            </div>
            <div>{moment(calcDates.month06).format('MMM YYYY')}</div>
          </>
        ),
        // sort field
        dataIndex: 'month_6',
        // filter field
        key: 'month_6',
        render: (text, record) => (
          <>
            {record.calc_date_06 !== '1970-01-01 00:00:00'
              ? new Intl.NumberFormat([], {
                  style: 'decimal',
                  minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                  maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
                }).format(record.calc_case_qty_06)
              : ''}
          </>
        )
      },
      {
        width: 100,
        align: 'right',
        title: intl.formatMessage({ id: 'min_case_qty' }),
        // sort field
        dataIndex: 'min_unit_qty',
        // filter field
        key: 'min_unit_qty',
        render: (text, record) => (
          <>
            {new Intl.NumberFormat([], {
              style: 'decimal',
              minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
              maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
            }).format(record.min_calc_case_qty)}
          </>
        )
      },
      {
        width: 100,
        align: 'right',
        title: intl.formatMessage({ id: 'max_case_qty' }),
        // sort field
        dataIndex: 'max_unit_qty',
        // filter field
        key: 'max_unit_qty',
        render: (text, record) => (
          <>
            {new Intl.NumberFormat([], {
              style: 'decimal',
              minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
              maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
            }).format(record.max_calc_case_qty)}
          </>
        )
      },
      {
        width: 100,
        align: 'right',
        title: intl.formatMessage({ id: 'median_case_qty' }),
        // sort field
        dataIndex: 'median_unit_qty',
        // filter field
        key: 'median_unit_qty',
        render: (text, record) => (
          <>
            {new Intl.NumberFormat([], {
              style: 'decimal',
              minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
              maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
            }).format(record.median_calc_case_qty)}
          </>
        )
      },
      {
        width: 100,
        align: 'right',
        title: intl.formatMessage({ id: 'avg_case_qty' }),
        // sort field
        dataIndex: 'avg_unit_qty',
        // filter field
        key: 'avg_unit_qty',
        render: (text, record) => (
          <>
            {new Intl.NumberFormat([], {
              style: 'decimal',
              minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
              maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
            }).format(record.avg_calc_case_qty)}
          </>
        )
      },
      {
        width: 100,
        align: 'right',
        title: (
          <>
            <div>
              {intl.formatMessage({
                id: 'pick_face_case_qty'
              })}
            </div>
            <div>{calcDates.balanceQueryAt}</div>
          </>
        ),
        // sort field
        dataIndex: 'balance_unit_qty',
        // filter field
        key: 'balance_unit_qty',
        render: (text, record) => (
          <>
            {new Intl.NumberFormat([], {
              style: 'decimal',
              minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
              maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
            }).format(record.balance_case_qty)}
          </>
        )
      },
      {
        width: 100,
        align: 'right',
        title: (
          <>
            <div>
              {intl.formatMessage({
                id: 'replenishing_case_qty'
              })}
            </div>
          </>
        ),
        // sort field
        dataIndex: 'repln_unit_qty',
        // filter field
        key: 'repln_unit_qty',
        render: (text, record) => (
          <>
            {new Intl.NumberFormat([], {
              style: 'decimal',
              minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
              maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
            }).format(record.repln_case_qty)}
          </>
        )
      },
      {
        width: 100,
        align: 'right',
        title: (
          <>
            <div>
              {intl.formatMessage({
                id: 'min_pick_face_case_qty'
              })}
            </div>
          </>
        ),
        // sort field
        dataIndex: 'min_unit_qty',
        // filter field
        key: 'min_unit_qty',
        render: (text, record) => (
          <>
            {new Intl.NumberFormat([], {
              style: 'decimal',
              minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
              maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
            }).format(record.min_case_qty)}
          </>
        )
      },
      {
        width: 50,
        fixed: 'right',
        title: '',
        key: 'action',
        render: (text, record) => (
          <>
            <Button type="dashed" icon={<EditOutlined />} onClick={() => goToResource(record.id)} />
            <Button type="dashed" icon={<AuditOutlined />} onClick={() => goToAudit(record.id)} />
          </>
        )
      }
    ];
  }

  useOnTableChange(pagination, filters, sorter) {
    const { resetTimestamp, criteria } = this.props;

    const processedSorts = AntDesignTable.processSorts(sorter);

    resetTimestamp(pagination.current, processedSorts, criteria, pagination.pageSize);
  }

  useShowWorkspace() {
    const { setWorkspaceVisible } = this.props;

    setWorkspaceVisible(true);
  }

  render() {
    const {
      match,
      resetTimestamp,
      currentPage,
      pageSize,
      total,
      documents,
      fetchIsLoading,
      selectedDocuments,
      addSelectedDocuments,
      removeSelectedDocuments
    } = this.props;

    return (
      <>
        <Card>
          <CriteriaForm match={match} />
        </Card>
        <Table
          size="small"
          // rowSelection={rowSelection}
          rowKey="id"
          pagination={{
            current: currentPage,
            pageSize: parseInt(pageSize, 10),
            total,
            showTotal: () => `${total} items`
          }}
          columns={this.getDocumentColumns()}
          dataSource={documents}
          loading={fetchIsLoading}
          bordered
          rowSelection={{
            selectedRowKeys: selectedDocuments.map(value => value.id),
            onChange: () => {
              // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            },
            onSelect: (record, selected) => {
              // console.log(`onSelect record: `, record, 'selected: ', selected, 'selectedRows: ', selectedRows);
              if (selected === true) {
                addSelectedDocuments([record]);
              } else {
                removeSelectedDocuments([record]);
              }
            },
            onSelectAll: (selected, selectedRows, changeRows) => {
              // console.log('onSelectAll selected: ', selected, 'selectedRows: ', selectedRows, 'changeRows: ', changeRows);
              if (selected === true) {
                addSelectedDocuments(changeRows);
              } else {
                removeSelectedDocuments(changeRows);
              }
            },
            onSelectInvert: selectedRows => {
              // console.log('onSelectInvert selectedRows: ', selectedRows);
              removeSelectedDocuments(selectedRows);
            },
            getCheckboxProps: () => ({
              // Column configuration not to be checked
              disabled: true
              // name: record.name,
            })
          }}
          onChange={this.useOnTableChange}
          title={() => (
            <>
              <Row type="flex" justify="start" gutter={[0, 16]}>
                <Col span={1}>
                  <Button
                    type="primary"
                    // disabled={!isValid}
                    loading={fetchIsLoading}
                    onClick={() => resetTimestamp()}
                    icon={<ReloadOutlined />}
                  />
                </Col>
                <Col span={5} />
              </Row>
            </>
          )}
          scroll={{ x: 950 }}
        />

        <div
          style={{
            position: 'fixed',
            bottom: '50px',
            right: '50px',
            zIndex: 9999
          }}
        >
          <Badge count={selectedDocuments.length}>
            <Button
              size="large"
              type="primary"
              shape="circle"
              icon={<LaptopOutlined />}
              onClick={this.useShowWorkspace}
              loading={fetchIsLoading}
            />
          </Badge>
        </div>
      </>
    );
  }
}

BinTrf01Table.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object,
  resetTimestamp: PropTypes.func,
  goToResource: PropTypes.func,
  goToAudit: PropTypes.func,

  setWorkspaceVisible: PropTypes.func,

  calcDates: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  addSelectedDocuments: PropTypes.func,
  removeSelectedDocuments: PropTypes.func,
  documents: PropTypes.arrayOf(PropTypes.object),
  selectedDocuments: PropTypes.arrayOf(PropTypes.object),

  sorts: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  // eslint-disable-next-line react/forbid-prop-types
  criteria: PropTypes.object,

  currentPage: PropTypes.number,
  pageSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  total: PropTypes.number,
  fetchIsLoading: PropTypes.bool,

  curSiteFlowId: PropTypes.number,
  fetchBinTrf01: PropTypes.func
};

BinTrf01Table.defaultProps = {
  intl: {},
  match: {},
  resetTimestamp() {},
  goToResource() {},
  goToAudit() {},

  setWorkspaceVisible() {},

  calcDates: {},
  addSelectedDocuments() {},
  removeSelectedDocuments() {},
  documents: [],
  selectedDocuments: [],

  sorts: {},
  criteria: {},

  currentPage: 1,
  pageSize: '20',
  total: 0,
  fetchIsLoading: false,

  curSiteFlowId: 0,
  fetchBinTrf01() {}
};

const mapStateToProps = state => ({
  timestamp: state.binTrf01.timestamp,
  workspaceIsVisible: state.binTrf01.workspaceIsVisible,

  calcDates: state.binTrf01.calcDates,
  documents: state.binTrf01.documents,
  selectedDocuments: state.binTrf01.selectedDocuments,

  sorts: state.binTrf01.sorts,
  criteria: state.binTrf01.criteria,

  currentPage: state.binTrf01.currentPage,
  pageSize: state.binTrf01.pageSize,
  total: state.binTrf01.total,
  fetchIsLoading: state.binTrf01.fetchIsLoading,

  curSiteFlowId: state.app.curSiteFlowId
});

const mapDispatchToProps = dispatch => ({
  resetTimestamp: (currentPage, sorts, criteria, pageSize) =>
    dispatch(BinTrf01Actions.binTrf01ResetTimestamp(currentPage, sorts, criteria, pageSize)),
  goToResource: resId => dispatch(BinTrf01Actions.binTrf01GoToResource(resId)),
  goToAudit: hdrId => dispatch(BinTrf01Actions.binTrf01GoToAudit(hdrId)),

  setWorkspaceVisible: boolean => dispatch(BinTrf01Actions.binTrf01SetWorkspaceVisible(boolean)),

  addSelectedDocuments: selectedDocuments =>
    dispatch(BinTrf01Actions.binTrf01AddSelectedDocuments(selectedDocuments)),
  removeSelectedDocuments: selectedDocuments =>
    dispatch(BinTrf01Actions.binTrf01RemoveSelectedDocuments(selectedDocuments)),

  fetchBinTrf01: (currentPage, sorts, criteria, pageSize) =>
    dispatch(BinTrf01Actions.binTrf01FetchBinTrf01(currentPage, sorts, criteria, pageSize))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(BinTrf01Table));
