/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { LspSlsOrdDetailTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const lspSlsOrdDetailResetTimestamp = state => ({
  ...state,
  timestamp: Date.now()
});

export const lspSlsOrdDetailSetHdrId = (state, { hdrId, itemId }) => ({
  ...state,
  timestamp: Date.now(),
  documentIsLoading: false,
  hdrId,
  itemId,
  detailIsVisible: false,
  salesmanOptions: [],
  deliveryPointOptions: [],
  creditTermOptions: [],
  currencyOptions: []
});

export const lspSlsOrdDetailShowDocumentLoading = (state, { boolean }) => ({
  ...state,
  documentIsLoading: boolean
});

export const lspSlsOrdDetailShowHeaderSuccess = (state, { documentHeader }) => ({
  ...state,
  documentHeader
});

export const lspSlsOrdDetailUpdateDocumentSuccess = (
  state,
  { documentHeader, documentDetails }
) => ({
  ...state,
  documentHeader,
  documentDetails
});

export const lspSlsOrdDetailShowDetailsSuccess = (state, { documentDetails }) => ({
  ...state,
  documentDetails
});

export const lspSlsOrdDetailSetDetailVisible = (state, { boolean }) => ({
  ...state,
  detailIsVisible: boolean
});

export const lspSlsOrdDetailSetDocumentDetail = (state, { documentDetail }) => ({
  ...state,
  documentDetail,
  itemOptions: [],
  itemBatchOptions: [],
  uomOptions: []
});

export const lspSlsOrdDetailFetchDebtorOptionLoading = (state, { boolean }) => ({
  ...state,
  debtorIsLoading: boolean
});

export const lspSlsOrdDetailFetchDebtorOptionSuccess = (state, { options }) => ({
  ...state,
  debtorOptions: options
});

export const lspSlsOrdDetailFetchSalesmanOptionLoading = (state, { boolean }) => ({
  ...state,
  salesmanIsLoading: boolean
});

export const lspSlsOrdDetailFetchSalesmanOptionSuccess = (state, { options }) => ({
  ...state,
  salesmanOptions: options
});

export const lspSlsOrdDetailFetchDeliveryPointOptionLoading = (state, { boolean }) => ({
  ...state,
  deliveryPointIsLoading: boolean
});

export const lspSlsOrdDetailFetchDeliveryPointOptionSuccess = (state, { options }) => ({
  ...state,
  deliveryPointOptions: options
});

export const lspSlsOrdDetailFetchCreditTermOptionLoading = (state, { boolean }) => ({
  ...state,
  creditTermIsLoading: boolean
});

export const lspSlsOrdDetailFetchCreditTermOptionSuccess = (state, { options }) => ({
  ...state,
  creditTermOptions: options
});

export const lspSlsOrdDetailFetchCurrencyOptionLoading = (state, { boolean }) => ({
  ...state,
  currencyIsLoading: boolean
});

export const lspSlsOrdDetailFetchCurrencyOptionSuccess = (state, { options }) => ({
  ...state,
  currencyOptions: options
});

export const lspSlsOrdDetailFetchItemOptionLoading = (state, { boolean }) => ({
  ...state,
  itemIsLoading: boolean
});

export const lspSlsOrdDetailFetchItemOptionSuccess = (state, { options }) => ({
  ...state,
  itemOptions: options
});

export const lspSlsOrdDetailFetchItemBatchOptionLoading = (state, { boolean }) => ({
  ...state,
  itemBatchIsLoading: boolean
});

export const lspSlsOrdDetailFetchItemBatchOptionSuccess = (state, { options }) => ({
  ...state,
  itemBatchOptions: options
});

export const lspSlsOrdDetailFetchUomOptionLoading = (state, { boolean }) => ({
  ...state,
  uomIsLoading: boolean
});

export const lspSlsOrdDetailFetchUomOptionSuccess = (state, { options }) => ({
  ...state,
  uomOptions: options
});

// force refresh the formik form
export const lspSlsOrdDetailUpdateDetails = (state, { documentDetails }) => ({
  ...state,
  documentDetail: documentDetails[0]
});

// force refresh the formik form
export const lspSlsOrdDetailCreateDetail = (state, { documentDetail }) => ({
  ...state,
  documentDetail
});

export const lspSlsOrdDetailFetchLocationOptionLoading = (state, { boolean }) => ({
  ...state,
  locationIsLoading: boolean
});

export const lspSlsOrdDetailFetchLocationOptionSuccess = (state, { options }) => ({
  ...state,
  locationOptions: options
});

export const lspSlsOrdDetailFetchProjectOptionLoading = (state, { boolean }) => ({
  ...state,
  projectIsLoading: boolean
});

export const lspSlsOrdDetailFetchProjectOptionSuccess = (state, { options }) => ({
  ...state,
  projectOptions: options
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [LspSlsOrdDetailTypes.LSP_SLS_ORD_DETAIL_RESET_TIMESTAMP]: lspSlsOrdDetailResetTimestamp,
  [LspSlsOrdDetailTypes.LSP_SLS_ORD_DETAIL_SET_HDR_ID]: lspSlsOrdDetailSetHdrId,

  [LspSlsOrdDetailTypes.LSP_SLS_ORD_DETAIL_SHOW_HEADER_SUCCESS]: lspSlsOrdDetailShowHeaderSuccess,

  [LspSlsOrdDetailTypes.LSP_SLS_ORD_DETAIL_UPDATE_DOCUMENT_SUCCESS]: lspSlsOrdDetailUpdateDocumentSuccess,

  [LspSlsOrdDetailTypes.LSP_SLS_ORD_DETAIL_SHOW_DOCUMENT_LOADING]: lspSlsOrdDetailShowDocumentLoading,

  [LspSlsOrdDetailTypes.LSP_SLS_ORD_DETAIL_SHOW_DETAILS_SUCCESS]: lspSlsOrdDetailShowDetailsSuccess,

  [LspSlsOrdDetailTypes.LSP_SLS_ORD_DETAIL_SET_DETAIL_VISIBLE]: lspSlsOrdDetailSetDetailVisible,
  [LspSlsOrdDetailTypes.LSP_SLS_ORD_DETAIL_SET_DOCUMENT_DETAIL]: lspSlsOrdDetailSetDocumentDetail,

  [LspSlsOrdDetailTypes.LSP_SLS_ORD_DETAIL_UPDATE_DETAILS]: lspSlsOrdDetailUpdateDetails,
  [LspSlsOrdDetailTypes.LSP_SLS_ORD_DETAIL_CREATE_DETAIL]: lspSlsOrdDetailCreateDetail,

  [LspSlsOrdDetailTypes.LSP_SLS_ORD_DETAIL_FETCH_DEBTOR_OPTION_LOADING]: lspSlsOrdDetailFetchDebtorOptionLoading,
  [LspSlsOrdDetailTypes.LSP_SLS_ORD_DETAIL_FETCH_DEBTOR_OPTION_SUCCESS]: lspSlsOrdDetailFetchDebtorOptionSuccess,

  [LspSlsOrdDetailTypes.LSP_SLS_ORD_DETAIL_FETCH_SALESMAN_OPTION_LOADING]: lspSlsOrdDetailFetchSalesmanOptionLoading,
  [LspSlsOrdDetailTypes.LSP_SLS_ORD_DETAIL_FETCH_SALESMAN_OPTION_SUCCESS]: lspSlsOrdDetailFetchSalesmanOptionSuccess,

  [LspSlsOrdDetailTypes.LSP_SLS_ORD_DETAIL_FETCH_DELIVERY_POINT_OPTION_LOADING]: lspSlsOrdDetailFetchDeliveryPointOptionLoading,
  [LspSlsOrdDetailTypes.LSP_SLS_ORD_DETAIL_FETCH_DELIVERY_POINT_OPTION_SUCCESS]: lspSlsOrdDetailFetchDeliveryPointOptionSuccess,

  [LspSlsOrdDetailTypes.LSP_SLS_ORD_DETAIL_FETCH_CREDIT_TERM_OPTION_LOADING]: lspSlsOrdDetailFetchCreditTermOptionLoading,
  [LspSlsOrdDetailTypes.LSP_SLS_ORD_DETAIL_FETCH_CREDIT_TERM_OPTION_SUCCESS]: lspSlsOrdDetailFetchCreditTermOptionSuccess,

  [LspSlsOrdDetailTypes.LSP_SLS_ORD_DETAIL_FETCH_CURRENCY_OPTION_LOADING]: lspSlsOrdDetailFetchCurrencyOptionLoading,
  [LspSlsOrdDetailTypes.LSP_SLS_ORD_DETAIL_FETCH_CURRENCY_OPTION_SUCCESS]: lspSlsOrdDetailFetchCurrencyOptionSuccess,

  [LspSlsOrdDetailTypes.LSP_SLS_ORD_DETAIL_FETCH_ITEM_OPTION_LOADING]: lspSlsOrdDetailFetchItemOptionLoading,
  [LspSlsOrdDetailTypes.LSP_SLS_ORD_DETAIL_FETCH_ITEM_OPTION_SUCCESS]: lspSlsOrdDetailFetchItemOptionSuccess,

  [LspSlsOrdDetailTypes.LSP_SLS_ORD_DETAIL_FETCH_ITEM_BATCH_OPTION_LOADING]: lspSlsOrdDetailFetchItemBatchOptionLoading,
  [LspSlsOrdDetailTypes.LSP_SLS_ORD_DETAIL_FETCH_ITEM_BATCH_OPTION_SUCCESS]: lspSlsOrdDetailFetchItemBatchOptionSuccess,

  [LspSlsOrdDetailTypes.LSP_SLS_ORD_DETAIL_FETCH_UOM_OPTION_LOADING]: lspSlsOrdDetailFetchUomOptionLoading,
  [LspSlsOrdDetailTypes.LSP_SLS_ORD_DETAIL_FETCH_UOM_OPTION_SUCCESS]: lspSlsOrdDetailFetchUomOptionSuccess,

  [LspSlsOrdDetailTypes.LSP_SLS_ORD_DETAIL_FETCH_LOCATION_OPTION_LOADING]: lspSlsOrdDetailFetchLocationOptionLoading,
  [LspSlsOrdDetailTypes.LSP_SLS_ORD_DETAIL_FETCH_LOCATION_OPTION_SUCCESS]: lspSlsOrdDetailFetchLocationOptionSuccess,

  [LspSlsOrdDetailTypes.LSP_SLS_ORD_DETAIL_FETCH_PROJECT_OPTION_LOADING]: lspSlsOrdDetailFetchProjectOptionLoading,
  [LspSlsOrdDetailTypes.LSP_SLS_ORD_DETAIL_FETCH_PROJECT_OPTION_SUCCESS]: lspSlsOrdDetailFetchProjectOptionSuccess
});
