import { put, call, select, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import { replace, push } from 'connected-react-router';
import ApiService from '../Services/ApiService';
import AppActions from '../Stores/App/Actions';
import PrincipalDetailActions, { PrincipalDetailTypes } from '../Stores/PrincipalDetail/Actions';

const getAppStore = state => state.app;

export function* principalDetailInitModel() {
  try {
    yield put(PrincipalDetailActions.principalDetailShowModelLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const debtorType = 1; // principal

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `debtor/initModel/${debtorType}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(PrincipalDetailActions.principalDetailShowModelSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(PrincipalDetailActions.principalDetailShowModelLoading(false));
  }
}

export function* principalDetailShowModel({ resId }) {
  try {
    yield put(PrincipalDetailActions.principalDetailShowModelLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const debtorType = 1; // principal

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `debtor/showModel/${debtorType}/${resId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(PrincipalDetailActions.principalDetailShowModelSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(PrincipalDetailActions.principalDetailShowModelLoading(false));
  }
}

export function* principalDetailUpdateModel({ formikBag, model }) {
  formikBag.setSubmitting(true);
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: model
    };

    const result = yield call(
      ApiService.putApi, // function
      app.apiUrl,
      'debtor/updateModel',
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      const { model: retModel } = result.data;

      yield put(PrincipalDetailActions.principalDetailUpdateModelSuccess(retModel));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* principalDetailShowDeliveryPoints({
  resId,
  currentPage,
  sorts,
  filters,
  pageSize
}) {
  try {
    yield put(PrincipalDetailActions.principalDetailShowModelLoading(true));

    const processedSorts = [];
    Object.entries(sorts).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      processedSorts.push(`${key}:${value === 'ascend' ? 'ASC' : 'DESC'}`);
    });

    const processedFilters = [];
    Object.entries(filters).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      if (value) {
        processedFilters.push(`${key}:${value}`);
      }
    });

    const app = yield select(getAppStore);
    const getData = {
      page: currentPage,
      sorts: processedSorts,
      filters: processedFilters,
      pageSize
    };

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `debtor/showDeliveryPoints/${resId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      // if nowCurrentPage is more than lastPage, then nowCurrentPage = lastPage
      let nowCurrentPage = result.data.current_page;
      if (nowCurrentPage > result.data.last_page) {
        nowCurrentPage = result.data.last_page;
      }

      yield put(
        PrincipalDetailActions.principalDetailShowDeliveryPointSuccess(
          result.data.data,
          nowCurrentPage,
          result.data.last_page,
          result.data.total,
          result.data.per_page
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(PrincipalDetailActions.principalDetailShowModelLoading(false));
  }
}

export function* principalDetailCreateModel({ formikBag, model }) {
  formikBag.setSubmitting(true);
  try {
    const app = yield select(getAppStore);

    const debtorType = 1; // principal
    const postData = {
      data: model
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `debtor/createModel/${debtorType}`,
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      yield put(replace(`${app.appPath}/principalDetail/update/${result.data}/GENERAL`));
      yield put(PrincipalDetailActions.principalDetailSetResId(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* principalDetailFetchAreaOptions({ search }) {
  try {
    yield put(PrincipalDetailActions.principalDetailFetchAreaOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId: app.curSiteFlowId,
      search
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `area/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({ value: d.id, label: `${d.code}` }));

      yield put(PrincipalDetailActions.principalDetailFetchAreaOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(PrincipalDetailActions.principalDetailFetchAreaOptionLoading(false));
  }
}

export function* principalDetailGoToDeliveryPoint({ deliveryPointId }) {
  const app = yield select(getAppStore);
  yield put(push(`${app.appPath}/deliveryPointDetail/update/${deliveryPointId}`));
}

export function* principalDetailNewDeliveryPoint({ debtorId }) {
  const app = yield select(getAppStore);
  yield put(push(`${app.appPath}/deliveryPointDetail/create/${debtorId}`));
}

export function* principalDetailShowBizPartners({ resId, currentPage, sorts, filters, pageSize }) {
  try {
    yield put(PrincipalDetailActions.principalDetailShowModelLoading(true));

    const processedSorts = [];
    Object.entries(sorts).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      processedSorts.push(`${key}:${value === 'ascend' ? 'ASC' : 'DESC'}`);
    });

    const processedFilters = [];
    Object.entries(filters).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      if (value) {
        processedFilters.push(`${key}:${value}`);
      }
    });

    const app = yield select(getAppStore);
    const getData = {
      page: currentPage,
      sorts: processedSorts,
      filters: processedFilters,
      pageSize
    };

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `debtor/showBizPartners/${resId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      // if nowCurrentPage is more than lastPage, then nowCurrentPage = lastPage
      let nowCurrentPage = result.data.current_page;
      if (nowCurrentPage > result.data.last_page) {
        nowCurrentPage = result.data.last_page;
      }

      yield put(
        PrincipalDetailActions.principalDetailShowBizPartnerSuccess(
          result.data.data,
          nowCurrentPage,
          result.data.last_page,
          result.data.total,
          result.data.per_page
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(PrincipalDetailActions.principalDetailShowModelLoading(false));
  }
}

export function* principalDetailGoToBizPartner({ bizPartnerId }) {
  const app = yield select(getAppStore);
  yield put(push(`${app.appPath}/bizPartnerDetail/update/${bizPartnerId}`));
}

export function* principalDetailNewBizPartner({ debtorId }) {
  const app = yield select(getAppStore);
  yield put(push(`${app.appPath}/bizPartnerDetail/create/${debtorId}`));
}

export const saga = [
  takeLatest(PrincipalDetailTypes.PRINCIPAL_DETAIL_INIT_MODEL, principalDetailInitModel),
  takeLatest(PrincipalDetailTypes.PRINCIPAL_DETAIL_SHOW_MODEL, principalDetailShowModel),

  takeLatest(PrincipalDetailTypes.PRINCIPAL_DETAIL_UPDATE_MODEL, principalDetailUpdateModel),
  takeLatest(PrincipalDetailTypes.PRINCIPAL_DETAIL_CREATE_MODEL, principalDetailCreateModel),

  takeLatest(
    PrincipalDetailTypes.PRINCIPAL_DETAIL_SHOW_BIZ_PARTNERS,
    principalDetailShowBizPartners
  ),
  takeLatest(
    PrincipalDetailTypes.PRINCIPAL_DETAIL_SHOW_DELIVERY_POINTS,
    principalDetailShowDeliveryPoints
  ),

  takeLatest(
    PrincipalDetailTypes.PRINCIPAL_DETAIL_FETCH_AREA_OPTIONS,
    principalDetailFetchAreaOptions
  ),

  takeLatest(
    PrincipalDetailTypes.PRINCIPAL_DETAIL_GO_TO_DELIVERY_POINT,
    principalDetailGoToDeliveryPoint
  ),

  takeLatest(
    PrincipalDetailTypes.PRINCIPAL_DETAIL_NEW_DELIVERY_POINT,
    principalDetailNewDeliveryPoint
  ),

  takeLatest(
    PrincipalDetailTypes.PRINCIPAL_DETAIL_GO_TO_BIZ_PARTNER,
    principalDetailGoToBizPartner
  ),

  takeLatest(PrincipalDetailTypes.PRINCIPAL_DETAIL_NEW_BIZ_PARTNER, principalDetailNewBizPartner)
];
