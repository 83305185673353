import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import treeChanges from 'tree-changes';

import { Card, Tabs, Tooltip, Spin } from 'antd';

import RoleProcessActions from '../../Stores/RoleProcess/Actions';

import RoleList01Table from '../RoleList01/RoleList01Table';
import RoleExcel01Table from '../RoleExcel01/RoleExcel01Table';

const { TabPane } = Tabs;

class RoleProcessScreen extends React.PureComponent {
  componentDidMount() {
    const { curSiteFlowId, fetchRoleProcess } = this.props;
    if (curSiteFlowId > 0) {
      fetchRoleProcess();
    }
  }

  componentDidUpdate(prevProps) {
    const { curSiteFlowId, fetchRoleProcess } = this.props;

    const { changed } = treeChanges(prevProps, this.props);

    if (changed('curSiteFlowId')) {
      if (curSiteFlowId > 0) {
        fetchRoleProcess();
      }
    }
  }

  componentWillUnmount() {}

  render() {
    const { intl, match, process, appPath, processIsLoading } = this.props;

    return (
      <Card>
        <Spin spinning={processIsLoading}>
          <Tabs type="card" activeKey={match.params.proc_type}>
            {process.map(value => (
              <TabPane
                tab={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <Tooltip
                    placement="bottom"
                    title={intl.formatMessage({ id: `${value.proc_type.toLowerCase()}_desc` })}
                  >
                    <Link
                      style={value.proc_type !== match.params.proc_type ? { color: 'black' } : {}}
                      to={`${appPath}/roleProcess/index/${value.proc_type}`}
                    >
                      <FormattedMessage id={`${value.proc_type.toLowerCase()}_label`} />
                    </Link>
                  </Tooltip>
                }
                key={value.proc_type}
              />
            ))}
          </Tabs>
        </Spin>

        {match.params.proc_type === 'ROLE_LIST_01' && <RoleList01Table match={match} />}
        {match.params.proc_type === 'ROLE_EXCEL_01' && <RoleExcel01Table match={match} />}
      </Card>
    );
  }
}

RoleProcessScreen.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object,
  appPath: PropTypes.string,
  process: PropTypes.arrayOf(PropTypes.object),
  processIsLoading: PropTypes.bool,

  curSiteFlowId: PropTypes.number,
  fetchRoleProcess: PropTypes.func
};

RoleProcessScreen.defaultProps = {
  intl: {},
  match: {},
  appPath: '',
  process: [],
  processIsLoading: false,

  curSiteFlowId: 0,
  fetchRoleProcess() {}
};

const mapStateToProps = state => ({
  appPath: state.app.appPath,
  process: state.roleProcess.process,
  processIsLoading: state.roleProcess.processIsLoading,

  curSiteFlowId: state.app.curSiteFlowId
});

const mapDispatchToProps = dispatch => ({
  fetchRoleProcess: () =>
    dispatch(RoleProcessActions.roleProcessFetchRoleProcess())
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(RoleProcessScreen));
