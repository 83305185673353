import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { Card, Tabs, Tooltip } from 'antd';
import ModelForm from './ModelForm';
import DeliveryPointTable from './DeliveryPointTable';
import DebtorDetailActions from '../../Stores/DebtorDetail/Actions';

const { TabPane } = Tabs;

class DebtorDetailScreen extends React.PureComponent {
  componentDidMount() {
    const { match, setReslId } = this.props;

    if (match.params.action === 'create') {
      setReslId(0);
    } else if (match.params.action === 'update') {
      setReslId(parseInt(match.params.id, 10));
    }
  }

  componentDidUpdate() {}

  componentWillUnmount() {}

  render() {
    const { intl, match, appPath, model } = this.props;

    return (
      <Card title={`${intl.formatMessage({ id: 'debtor' })} ${model.code}`}>
        <Tabs type="card" activeKey={match.params.proc_type}>
          <TabPane
            tab={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <Tooltip placement="bottom" title={intl.formatMessage({ id: `general` })}>
                <Link
                  style={match.params.proc_type !== 'GENERAL' ? { color: 'black' } : {}}
                  to={`${appPath}/debtorDetail/${match.params.action}/${match.params.id}/GENERAL`}
                  replace
                >
                  <FormattedMessage id="general" />
                </Link>
              </Tooltip>
            }
            key="GENERAL"
          />
          <TabPane
            tab={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <Tooltip placement="bottom" title={intl.formatMessage({ id: `delivery_points` })}>
                <Link
                  style={match.params.proc_type !== 'DELIVERY_POINTS' ? { color: 'black' } : {}}
                  to={`${appPath}/debtorDetail/${match.params.action}/${match.params.id}/DELIVERY_POINTS`}
                  replace
                >
                  <FormattedMessage id="delivery_points" />
                </Link>
              </Tooltip>
            }
            key="DELIVERY_POINTS"
          />
        </Tabs>

        {match.params.proc_type === 'GENERAL' && <ModelForm match={match} />}
        {match.params.proc_type === 'DELIVERY_POINTS' && <DeliveryPointTable match={match} />}
      </Card>
    );
  }
}

DebtorDetailScreen.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object,
  appPath: PropTypes.string,

  setReslId: PropTypes.func,
  model: PropTypes.shape({
    code: PropTypes.string,
    status: PropTypes.number
  })
};

DebtorDetailScreen.defaultProps = {
  intl: {},
  match: {},
  appPath: '',

  setReslId() {},
  model: { code: '', status: 2 }
};

const mapStateToProps = state => ({
  appPath: state.app.appPath,

  model: state.debtorDetail.model
});

const mapDispatchToProps = dispatch => ({
  setReslId: resId => dispatch(DebtorDetailActions.debtorDetailSetResId(resId))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(DebtorDetailScreen));
