/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { StorageRowDetailTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const storageRowDetailResetModelTimestamp = state => ({
  ...state,
  modelTimestamp: Date.now()
});

export const storageRowDetailSetResId = (state, { resId }) => ({
  ...state,
  modelTimestamp: Date.now(),
  resId
});

export const storageRowDetailShowModelLoading = (state, { boolean }) => ({
  ...state,
  modelIsLoading: boolean
});

export const storageRowDetailShowModelSuccess = (state, { model }) => ({
  ...state,
  model
});

export const storageRowDetailUpdateModelSuccess = (state, { model }) => ({
  ...state,
  model
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [StorageRowDetailTypes.STORAGE_ROW_DETAIL_RESET_MODEL_TIMESTAMP]: storageRowDetailResetModelTimestamp,
  [StorageRowDetailTypes.STORAGE_ROW_DETAIL_SET_RES_ID]: storageRowDetailSetResId,
  [StorageRowDetailTypes.STORAGE_ROW_DETAIL_SHOW_MODEL_LOADING]: storageRowDetailShowModelLoading,
  [StorageRowDetailTypes.STORAGE_ROW_DETAIL_SHOW_MODEL_SUCCESS]: storageRowDetailShowModelSuccess,
  [StorageRowDetailTypes.STORAGE_ROW_DETAIL_UPDATE_MODEL_SUCCESS]: storageRowDetailUpdateModelSuccess
});
