import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  whseJob06IndexResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  whseJob06IndexGoToWhseJobDocument: ['hdrId'],
  whseJob06IndexGoToAudit: ['hdrId'],
  whseJob06IndexFetchWhseJob06Index: ['currentPage', 'sorts', 'filters', 'pageSize'],
  whseJob06IndexFetchWhseJob06IndexLoading: ['boolean'],
  whseJob06IndexFetchWhseJob06IndexSuccess: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],
  whseJob06IndexAddSelectedDocuments: ['selectedDocuments'],
  whseJob06IndexRemoveSelectedDocuments: ['selectedDocuments'],
  whseJob06IndexSetWorkspaceVisible: ['boolean']
});

export const WhseJob06IndexTypes = Types;
export default Creators;
