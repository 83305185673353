import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  storageTypeDetailResetModelTimestamp: null,
  storageTypeDetailSetResId: ['resId'],

  storageTypeDetailInitModel: ['storageTypeType'],
  storageTypeDetailShowModel: ['resId'],
  storageTypeDetailShowModelSuccess: ['model'],

  storageTypeDetailUpdateModel: ['formikBag', 'model'],
  storageTypeDetailCreateModel: ['formikBag', 'model'],
  storageTypeDetailUpdateModelSuccess: ['model'],

  storageTypeDetailShowModelLoading: ['boolean'],

  storageTypeDetailFetchStorageRowOptions: ['search'],
  storageTypeDetailFetchStorageRowOptionLoading: ['boolean'],
  storageTypeDetailFetchStorageRowOptionSuccess: ['options']
});

export const StorageTypeDetailTypes = Types;
export default Creators;
