import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import treeChanges from 'tree-changes';

import { Card, Tabs, Tooltip, Spin } from 'antd';

import StorageBinProcessActions from '../../Stores/StorageBinProcess/Actions';

import StorageBinList01Table from '../StorageBinList01/StorageBinList01Table';
import StorageRowList01Table from '../StorageRowList01/StorageRowList01Table';
import StorageBayList01Table from '../StorageBayList01/StorageBayList01Table';
import StorageBinExcel01Table from '../StorageBinExcel01/StorageBinExcel01Table';
import LocationList01Table from '../LocationList01/LocationList01Table';
import StorageTypeList01Table from '../StorageTypeList01/StorageTypeList01Table';

const { TabPane } = Tabs;

class StorageBinProcessScreen extends React.PureComponent {
  componentDidMount() {
    const { curSiteFlowId, fetchStorageBinProcess } = this.props;
    if (curSiteFlowId > 0) {
      fetchStorageBinProcess();
    }
  }

  componentDidUpdate(prevProps) {
    const { curSiteFlowId, fetchStorageBinProcess } = this.props;

    const { changed } = treeChanges(prevProps, this.props);

    if (changed('curSiteFlowId')) {
      if (curSiteFlowId > 0) {
        fetchStorageBinProcess();
      }
    }
  }

  componentWillUnmount() {}

  render() {
    const { intl, match, process, appPath, processIsLoading } = this.props;

    return (
      <Card>
        <Spin spinning={processIsLoading}>
          <Tabs type="card" activeKey={match.params.proc_type}>
            {process.map(value => (
              <TabPane
                tab={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <Tooltip
                    placement="bottom"
                    title={intl.formatMessage({ id: `${value.proc_type.toLowerCase()}_desc` })}
                  >
                    <Link
                      style={value.proc_type !== match.params.proc_type ? { color: 'black' } : {}}
                      to={`${appPath}/storageBinProcess/index/${value.proc_type}`}
                    >
                      <FormattedMessage id={`${value.proc_type.toLowerCase()}_label`} />
                    </Link>
                  </Tooltip>
                }
                key={value.proc_type}
              />
            ))}
          </Tabs>
        </Spin>

        {match.params.proc_type === 'STORAGE_BIN_LIST_01' && (
          <StorageBinList01Table match={match} />
        )}
        {match.params.proc_type === 'STORAGE_ROW_LIST_01' && (
          <StorageRowList01Table match={match} />
        )}
        {match.params.proc_type === 'STORAGE_BAY_LIST_01' && (
          <StorageBayList01Table match={match} />
        )}
        {match.params.proc_type === 'STORAGE_BIN_EXCEL_01' && (
          <StorageBinExcel01Table match={match} />
        )}
        {match.params.proc_type === 'STORAGE_TYPE_LIST_01' && (
          <StorageTypeList01Table match={match} />
        )}
        {match.params.proc_type === 'LOCATION_LIST_01' && <LocationList01Table match={match} />}
      </Card>
    );
  }
}

StorageBinProcessScreen.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object,
  appPath: PropTypes.string,
  process: PropTypes.arrayOf(PropTypes.object),
  processIsLoading: PropTypes.bool,

  curSiteFlowId: PropTypes.number,
  fetchStorageBinProcess: PropTypes.func
};

StorageBinProcessScreen.defaultProps = {
  intl: {},
  match: {},
  appPath: '',
  process: [],
  processIsLoading: false,

  curSiteFlowId: 0,
  fetchStorageBinProcess() {}
};

const mapStateToProps = state => ({
  appPath: state.app.appPath,
  process: state.storageBinProcess.process,
  processIsLoading: state.storageBinProcess.processIsLoading,

  curSiteFlowId: state.app.curSiteFlowId
});

const mapDispatchToProps = dispatch => ({
  fetchStorageBinProcess: () =>
    dispatch(StorageBinProcessActions.storageBinProcessFetchStorageBinProcess())
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(StorageBinProcessScreen));
