import { put, call, select, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import { replace } from 'connected-react-router';
import ApiService from '../Services/ApiService';
import AppActions from '../Stores/App/Actions';
import StorageRowDetailActions, { StorageRowDetailTypes } from '../Stores/StorageRowDetail/Actions';

const getAppStore = state => state.app;

export function* storageRowDetailInitModel({ storageRowType }) {
  try {
    yield put(StorageRowDetailActions.storageRowDetailShowModelLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `storageRow/initModel/${storageRowType}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(StorageRowDetailActions.storageRowDetailShowModelSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(StorageRowDetailActions.storageRowDetailShowModelLoading(false));
  }
}

export function* storageRowDetailShowModel({ resId }) {
  try {
    yield put(StorageRowDetailActions.storageRowDetailShowModelLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `storageRow/showModel/${resId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(StorageRowDetailActions.storageRowDetailShowModelSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(StorageRowDetailActions.storageRowDetailShowModelLoading(false));
  }
}

export function* storageRowDetailUpdateModel({ formikBag, model }) {
  formikBag.setSubmitting(true);
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: model
    };

    const result = yield call(
      ApiService.putApi, // function
      app.apiUrl,
      'storageRow/updateModel',
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      const { model: retModel } = result.data;

      yield put(StorageRowDetailActions.storageRowDetailUpdateModelSuccess(retModel));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* storageRowDetailCreateModel({ formikBag, model }) {
  formikBag.setSubmitting(true);
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: model
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `storageRow/createModel`,
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      yield put(replace(`${app.appPath}/storageRowDetail/update/${result.data}`));
      yield put(StorageRowDetailActions.storageRowDetailSetResId(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export const saga = [
  takeLatest(StorageRowDetailTypes.STORAGE_ROW_DETAIL_INIT_MODEL, storageRowDetailInitModel),
  takeLatest(StorageRowDetailTypes.STORAGE_ROW_DETAIL_SHOW_MODEL, storageRowDetailShowModel),

  takeLatest(StorageRowDetailTypes.STORAGE_ROW_DETAIL_UPDATE_MODEL, storageRowDetailUpdateModel),
  takeLatest(StorageRowDetailTypes.STORAGE_ROW_DETAIL_CREATE_MODEL, storageRowDetailCreateModel)
];
