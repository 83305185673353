import { put, call, select, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import ApiService from '../Services/ApiService';
import AppActions from '../Stores/App/Actions';
import LspAdvShipExcel01Actions, {
  LspAdvShipExcel01Types
} from '../Stores/LspAdvShipExcel01/Actions';

const getAppStore = state => state.app;

const getLspAdvShipExcel01Store = state => state.lspAdvShipExcel01;

export function* lspAdvShipExcel01ShowBatchJobStatus() {
  try {
    const app = yield select(getAppStore);
    const lspAdvShipExcel01 = yield select(getLspAdvShipExcel01Store);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `batchJobStatus/showBatchJobStatus/${lspAdvShipExcel01.strProcType}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(LspAdvShipExcel01Actions.lspAdvShipExcel01ShowBatchJobStatusSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  }
}

export function* lspAdvShipExcel01UploadExcel({ formikBag, projectId, debtorId, slsOrdFile }) {
  try {
    formikBag.setSubmitting(true);
    yield put(LspAdvShipExcel01Actions.lspAdvShipExcel01UploadLoading(true));

    const app = yield select(getAppStore);

    yield put(LspAdvShipExcel01Actions.lspAdvShipExcel01SetStrProcType('LSP_ADV_SHIP_EXCEL_01'));
    // eslint-disable-next-line no-undef
    const postData = new FormData();
    postData.append('file', slsOrdFile);

    const getData = {};

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `lspAdvShip/uploadProcess/LSP_ADV_SHIP_EXCEL_01/${app.curSiteFlowId}/${app.curDivisionId}/${projectId}/${debtorId}`,
      app.token,
      postData,
      getData,
      'multipart/form-data'
    );

    if (result.isSuccess === true) {
      // result.data is total
      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_SUCCESS_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
    yield put(LspAdvShipExcel01Actions.lspAdvShipExcel01UploadLoading(false));
  }
}

export function* lspAdvShipExcel01FetchDebtorOptions({ search }) {
  try {
    yield put(LspAdvShipExcel01Actions.lspAdvShipExcel01FetchDebtorOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      debtorType: 1, // 0 = normal, 1 = principal
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `debtor/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.company_name_01} ${d.company_name_02}`
      }));

      yield put(LspAdvShipExcel01Actions.lspAdvShipExcel01FetchDebtorOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(LspAdvShipExcel01Actions.lspAdvShipExcel01FetchDebtorOptionLoading(false));
  }
}

export function* lspAdvShipExcel01FetchProjectOptions({ search }) {
  try {
    yield put(LspAdvShipExcel01Actions.lspAdvShipExcel01FetchProjectOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `project/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(LspAdvShipExcel01Actions.lspAdvShipExcel01FetchProjectOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(LspAdvShipExcel01Actions.lspAdvShipExcel01FetchProjectOptionLoading(false));
  }
}

export const saga = [
  takeLatest(
    LspAdvShipExcel01Types.LSP_ADV_SHIP_EXCEL01_SHOW_BATCH_JOB_STATUS,
    lspAdvShipExcel01ShowBatchJobStatus
  ),
  takeLatest(
    LspAdvShipExcel01Types.LSP_ADV_SHIP_EXCEL01_UPLOAD_EXCEL,
    lspAdvShipExcel01UploadExcel
  ),

  takeLatest(
    LspAdvShipExcel01Types.LSP_ADV_SHIP_EXCEL01_FETCH_DEBTOR_OPTIONS,
    lspAdvShipExcel01FetchDebtorOptions
  ),

  takeLatest(
    LspAdvShipExcel01Types.LSP_ADV_SHIP_EXCEL01_FETCH_PROJECT_OPTIONS,
    lspAdvShipExcel01FetchProjectOptions
  )
];
