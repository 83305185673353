/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */
import { createReducer } from 'reduxsauce';
import { LspAdvShipIndexTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const lspAdvShipIndexResetTimestamp = (
  state,
  { currentPage, sorts, filters, pageSize }
) => ({
  ...state,
  currentPage: currentPage || state.currentPage,
  sorts: sorts || state.sorts,
  filters: filters || state.filters,
  pageSize: pageSize || state.pageSize,
  timestamp: Date.now()
  // selectedDocuments: INITIAL_STATE.selectedDocuments
});

export const lspAdvShipIndexFetchLspAdvShipIndexLoading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const lspAdvShipIndexFetchLspAdvShipIndexSuccess = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  documents,
  currentPage,
  lastPage,
  total,
  pageSize
});

export const lspAdvShipIndexAddSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = state.selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: [
      ...state.selectedDocuments,
      ...selectedDocuments.filter(data => {
        return !selectIds.includes(data.id);
      })
    ]
  };
};

export const lspAdvShipIndexRemoveSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: state.selectedDocuments.filter(data => {
      return !selectIds.includes(data.id);
    })
  };
};

export const lspAdvShipIndexSetWorkspaceVisible = (state, { boolean }) => ({
  ...state,
  workspaceIsVisible: boolean
});

export const lspAdvShipIndexCreateLspAdvShipIndexLoading = (state, { boolean }) => ({
  ...state,
  createIsLoading: boolean
});

export const lspAdvShipIndexCreateLspAdvShipIndexSuccess = (state, { newDocuments }) => ({
  ...state,
  timestamp: Date.now(),
  selectedDocuments: [],
  newDocuments
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [LspAdvShipIndexTypes.LSP_ADV_SHIP_INDEX_RESET_TIMESTAMP]: lspAdvShipIndexResetTimestamp,
  [LspAdvShipIndexTypes.LSP_ADV_SHIP_INDEX_FETCH_LSP_ADV_SHIP_INDEX_LOADING]: lspAdvShipIndexFetchLspAdvShipIndexLoading,
  [LspAdvShipIndexTypes.LSP_ADV_SHIP_INDEX_FETCH_LSP_ADV_SHIP_INDEX_SUCCESS]: lspAdvShipIndexFetchLspAdvShipIndexSuccess,
  [LspAdvShipIndexTypes.LSP_ADV_SHIP_INDEX_ADD_SELECTED_DOCUMENTS]: lspAdvShipIndexAddSelectedDocuments,
  [LspAdvShipIndexTypes.LSP_ADV_SHIP_INDEX_REMOVE_SELECTED_DOCUMENTS]: lspAdvShipIndexRemoveSelectedDocuments,
  [LspAdvShipIndexTypes.LSP_ADV_SHIP_INDEX_SET_WORKSPACE_VISIBLE]: lspAdvShipIndexSetWorkspaceVisible,
  [LspAdvShipIndexTypes.LSP_ADV_SHIP_INDEX_CREATE_LSP_ADV_SHIP_INDEX_LOADING]: lspAdvShipIndexCreateLspAdvShipIndexLoading,
  [LspAdvShipIndexTypes.LSP_ADV_SHIP_INDEX_CREATE_LSP_ADV_SHIP_INDEX_SUCCESS]: lspAdvShipIndexCreateLspAdvShipIndexSuccess
});
