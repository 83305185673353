import { put, call, select, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import ApiService from '../Services/ApiService';
import AppActions from '../Stores/App/Actions';
import AuditRes01Actions, { AuditRes01Types } from '../Stores/AuditRes01/Actions';

const getAppStore = state => state.app;

const getAuditRes01Store = state => state.auditRes01;

export function* auditRes01FetchAuditTypes() {
  try {
    yield put(AuditRes01Actions.auditRes01FetchAuditTypeLoading(true));

    const auditRes01 = yield select(getAuditRes01Store);

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `audit/showAuditTypes/${auditRes01.resType}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );
    if (result.isSuccess === true) {
      yield put(AuditRes01Actions.auditRes01FetchAuditTypeSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(AuditRes01Actions.auditRes01FetchAuditTypeLoading(false));
  }
}

export function* auditRes01FetchAuditRes01({ currentPage, sorts, filters, pageSize }) {
  try {
    yield put(AuditRes01Actions.auditRes01FetchAuditRes01Loading(true));

    const auditRes01 = yield select(getAuditRes01Store);

    const processedSorts = [];
    Object.entries(sorts).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      processedSorts.push(`${key}:${value === 'ascend' ? 'ASC' : 'DESC'}`);
    });

    const processedFilters = [];
    Object.entries(filters).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      if (value) {
        processedFilters.push(`${key}:${value}`);
      }
    });

    const app = yield select(getAppStore);
    const postData = {
      resType: auditRes01.resType,
      resId: auditRes01.resId,
      auditType: auditRes01.auditType
    };

    const getData = {
      page: currentPage,
      sorts: processedSorts,
      filters: processedFilters,
      pageSize
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `audit/auditResource`,
      app.token,
      postData, // params
      getData
    );

    if (result.isSuccess === true) {
      // if nowCurrentPage is more than lastPage, then nowCurrentPage = lastPage
      let nowCurrentPage = result.data.current_page;
      if (nowCurrentPage > result.data.last_page) {
        nowCurrentPage = result.data.last_page;
      }

      yield put(
        AuditRes01Actions.auditRes01FetchAuditRes01Success(
          result.data.data,
          nowCurrentPage,
          result.data.last_page,
          result.data.total,
          result.data.per_page
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(AuditRes01Actions.auditRes01FetchAuditRes01Loading(false));
  }
}

export const saga = [
  takeLatest(AuditRes01Types.AUDIT_RES01_FETCH_AUDIT_TYPES, auditRes01FetchAuditTypes),
  takeLatest(AuditRes01Types.AUDIT_RES01_FETCH_AUDIT_RES01, auditRes01FetchAuditRes01)
];
