/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { InvDoc03Types } from './Actions';
import INITIAL_STATE from './InitialState';

export const invDoc03ResetTimestamp = (state, { currentPage, sorts, filters, pageSize }) => ({
  ...state,
  currentPage: currentPage || state.currentPage,
  sorts: sorts || state.sorts,
  filters: filters || state.filters,
  pageSize: pageSize || state.pageSize,
  timestamp: Date.now()
  // selectedDocuments: INITIAL_STATE.selectedDocuments
});

export const invDoc03FetchInvDoc03Loading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const invDoc03FetchInvDoc03Success = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  documents,
  currentPage,
  lastPage,
  total,
  pageSize
});

export const invDoc03AddSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = state.selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: [
      ...state.selectedDocuments,
      ...selectedDocuments.filter(data => {
        return !selectIds.includes(data.id);
      })
    ]
  };
};

export const invDoc03RemoveSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: state.selectedDocuments.filter(data => {
      return !selectIds.includes(data.id);
    })
  };
};

export const invDoc03SetWorkspaceVisible = (state, { boolean }) => ({
  ...state,
  workspaceIsVisible: boolean
});

export const invDoc03CreateInvDoc03Loading = (state, { boolean }) => ({
  ...state,
  createIsLoading: boolean
});

export const invDoc03CreateInvDoc03Success = (state, { newDocuments }) => ({
  ...state,
  timestamp: Date.now(),
  selectedDocuments: [],
  newDocuments
});

export const invDoc03SetExpandedRows = (state, { expandedRows }) => ({
  ...state,
  expandedRows
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [InvDoc03Types.INV_DOC03_RESET_TIMESTAMP]: invDoc03ResetTimestamp,
  [InvDoc03Types.INV_DOC03_FETCH_INV_DOC03_LOADING]: invDoc03FetchInvDoc03Loading,
  [InvDoc03Types.INV_DOC03_FETCH_INV_DOC03_SUCCESS]: invDoc03FetchInvDoc03Success,
  [InvDoc03Types.INV_DOC03_ADD_SELECTED_DOCUMENTS]: invDoc03AddSelectedDocuments,
  [InvDoc03Types.INV_DOC03_REMOVE_SELECTED_DOCUMENTS]: invDoc03RemoveSelectedDocuments,
  [InvDoc03Types.INV_DOC03_SET_WORKSPACE_VISIBLE]: invDoc03SetWorkspaceVisible,
  [InvDoc03Types.INV_DOC03_CREATE_INV_DOC03_LOADING]: invDoc03CreateInvDoc03Loading,
  [InvDoc03Types.INV_DOC03_CREATE_INV_DOC03_SUCCESS]: invDoc03CreateInvDoc03Success,

  [InvDoc03Types.INV_DOC03_SET_EXPANDED_ROWS]: invDoc03SetExpandedRows
});
