import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import moment from 'moment';

import { DeleteOutlined } from '@ant-design/icons';

import { Row, Col, Modal, Table, Button, Popconfirm } from 'antd';

import GdsIss01Actions from '../../Stores/GdsIss01/Actions';

class GdsIss01Dialog extends React.PureComponent {
  constructor() {
    super();

    this.handleCancel = this.handleCancel.bind(this);
    this.useOnCreateDocument = this.useOnCreateDocument.bind(this);

    this.getSelectedDocumentColumns = this.getSelectedDocumentColumns.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate() {}

  componentWillUnmount() {}

  handleCancel() {
    const { setWorkspaceVisible } = this.props;

    setWorkspaceVisible(false);
  }

  getSelectedDocumentColumns() {
    const { intl, removeSelectedDocuments } = this.props;

    return [
      {
        align: 'left',
        title: intl.formatMessage({ id: 'purchaser' }),
        // sort field
        dataIndex: 'purchaser_username',
        sorter: (a, b) => `${a.purchaser_username}`.localeCompare(b.purchaser_username),
        // filter field
        key: 'salesman',
        render: (text, record) => (
          <>
            <b>{record.purchaser_username}</b>
          </>
        )
      },
      {
        align: 'left',
        title: intl.formatMessage({ id: 'business_partner' }),
        // sort field
        dataIndex: 'biz_partner_company_name_01',
        sorter: (a, b) =>
          `${a.biz_partner_company_name_01}`.localeCompare(b.biz_partner_company_name_01),
        // filter field
        key: 'biz_partner',
        render: (text, record) => (
          <>
            <b>{record.biz_partner_code}</b>
            <br />
            {record.biz_partner_company_name_01}
          </>
        )
      },
      {
        align: 'left',
        title: intl.formatMessage({ id: 'doc_code' }),
        // sort field
        dataIndex: 'doc_code',
        sorter: (a, b) => `${a.doc_code}`.localeCompare(b.doc_code),
        // filter field
        key: 'doc_code',
        render: (text, record) => (
          <>
            <b>{record.doc_code}</b>
            <br />
            {record.str_doc_status}
          </>
        )
      },
      {
        align: 'left',
        title: intl.formatMessage({ id: 'doc_date' }),
        // sort field
        dataIndex: 'doc_date',
        sorter: (a, b) => new Date(a.doc_date) - new Date(b.doc_date),
        // filter field
        key: 'doc_date',
        render: (text, record) => <>{moment(record.doc_date).format('YYYY-MM-DD')}</>
      },
      {
        align: 'right',
        title: intl.formatMessage({ id: 'net_amt' }),
        // sort field
        dataIndex: 'net_amt',
        sorter: (a, b) => a.net_amt - b.net_amt,
        // filter field
        key: 'net_amt',
        render: (text, record) => (
          <>
            <div style={{ textAlign: 'right' }}>
              {new Intl.NumberFormat([], {
                style: 'decimal',
                minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
              }).format(record.net_amt)}
            </div>
          </>
        )
      },
      {
        align: 'right',
        title: intl.formatMessage({ id: 'case_decimal_qty' }),
        // sort field
        dataIndex: 'case_decimal_qty',
        sorter: (a, b) => a.case_decimal_qty - b.case_decimal_qty,
        // filter field
        key: 'case_decimal_qty',
        render: (text, record) => (
          <>
            <div style={{ textAlign: 'right' }}>
              {new Intl.NumberFormat([], {
                style: 'decimal',
                minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
              }).format(record.case_decimal_qty)}
            </div>
          </>
        )
      },
      {
        align: 'right',
        title: intl.formatMessage({ id: 'gross_weight' }),
        // sort field
        dataIndex: 'gross_weight',
        sorter: (a, b) => a.gross_weight - b.gross_weight,
        // filter field
        key: 'gross_weight',
        render: (text, record) => (
          <>
            <div style={{ textAlign: 'right' }}>
              {new Intl.NumberFormat([], {
                style: 'decimal',
                minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
              }).format(record.gross_weight)}
            </div>
          </>
        )
      },
      {
        align: 'right',
        title: intl.formatMessage({ id: 'cubic_meter' }),
        // sort field
        dataIndex: 'cubic_meter',
        sorter: (a, b) => a.cubic_meter - b.cubic_meter,
        // filter field
        key: 'cubic_meter',
        render: (text, record) => (
          <>
            <div style={{ textAlign: 'right' }}>
              {new Intl.NumberFormat([], {
                style: 'decimal',
                minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
              }).format(record.cubic_meter)}
            </div>
          </>
        )
      },
      {
        key: 'action',
        render: (text, record) => (
          <>
            <Popconfirm
              placement="left"
              title={intl.formatMessage({ id: 'are_you_sure_to_remove_this_document' })}
              onConfirm={() => {
                removeSelectedDocuments([record]);
              }}
              onCancel={() => {}}
              okText={intl.formatMessage({ id: 'yes' })}
              cancelText={intl.formatMessage({ id: 'cancel' })}
            >
              <Button type="dashed" icon={<DeleteOutlined />} />
            </Popconfirm>
          </>
        )
      }
    ];
  }

  getNewDocumentColumns() {
    const { intl } = this.props;

    return [
      {
        align: 'left',
        title: intl.formatMessage({ id: 'doc_code' }),
        // sort field
        dataIndex: 'doc_code',
        sorter: (a, b) => `${a.doc_code}`.localeCompare(b.doc_code),
        // filter field
        key: 'doc_code',
        render: (text, record) => (
          <>
            <b>{record.doc_code}</b>
          </>
        )
      },
      {
        align: 'left',
        title: intl.formatMessage({ id: 'doc_date' }),
        // sort field
        dataIndex: 'doc_date',
        sorter: (a, b) => new Date(a.doc_date) - new Date(b.doc_date),
        // filter field
        key: 'doc_date',
        render: (text, record) => <>{moment(record.doc_date).format('YYYY-MM-DD')}</>
      },
      {
        align: 'left',
        title: intl.formatMessage({ id: 'doc_status' }),
        // sort field
        dataIndex: 'str_doc_status',
        sorter: (a, b) => `${a.str_doc_status}`.localeCompare(b.str_doc_status),
        // filter field
        key: 'str_doc_status',
        render: (text, record) => (
          <>
            <b>{record.str_doc_status}</b>
          </>
        )
      },
      {
        align: 'left',
        title: intl.formatMessage({ id: 'created_date' }),
        // sort field
        dataIndex: 'created_at',
        sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
        // filter field
        key: 'created_at',
        render: (text, record) => <>{moment(record.created_at).format('YYYY-MM-DD HH:mm:ss')}</>
      },
      {
        align: 'left',
        title: intl.formatMessage({ id: 'updated_date' }),
        // sort field
        dataIndex: 'updated_at',
        sorter: (a, b) => new Date(a.updated_at) - new Date(b.updated_at),
        // filter field
        key: 'updated_at',
        render: (text, record) => <>{moment(record.updated_at).format('YYYY-MM-DD HH:mm:ss')}</>
      }
    ];
  }

  useOnCreateDocument() {
    const { selectedDocuments, createGdsIss01 } = this.props;

    const hdrIds = selectedDocuments.map(value => {
      return value.id;
    });

    createGdsIss01(hdrIds);
  }

  render() {
    const {
      intl,
      newDocuments,
      selectedDocuments,
      workspaceIsVisible,
      createIsLoading
    } = this.props;

    return (
      <>
        <Modal
          visible={workspaceIsVisible}
          title={intl.formatMessage({ id: 'create_gds_iss' })}
          // style={{top:20}}
          width="80%"
          centered
          // onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <Button
              key="create"
              disabled={selectedDocuments.length === 0}
              loading={createIsLoading}
              onClick={this.useOnCreateDocument}
            >
              {intl.formatMessage({ id: 'create' })}
            </Button>
          ]}
        >
          <Row type="flex" justify="center" gutter={[0, 8]}>
            <Col span={24}>
              <Table
                // rowSelection={rowSelection}
                rowKey="id"
                pagination={false}
                columns={this.getSelectedDocumentColumns()}
                dataSource={selectedDocuments}
                loading={createIsLoading}
                bordered
                title={() => <b>{intl.formatMessage({ id: 'selected_documents' })}</b>}
                summary={pageData => {
                  const calcRow = pageData.reduce(
                    (ttlObj, object) => {
                      return {
                        net_amt:
                          ('net_amt' in ttlObj ? ttlObj.net_amt : 0) + parseFloat(object.net_amt),
                        case_decimal_qty:
                          ('case_decimal_qty' in ttlObj ? ttlObj.case_decimal_qty : 0) +
                          parseFloat(object.case_decimal_qty),
                        gross_weight:
                          ('gross_weight' in ttlObj ? ttlObj.gross_weight : 0) +
                          parseFloat(object.gross_weight),
                        cubic_meter:
                          ('cubic_meter' in ttlObj ? ttlObj.cubic_meter : 0) +
                          parseFloat(object.cubic_meter)
                      };
                    },
                    {
                      net_amt: 0,
                      case_decimal_qty: 0,
                      gross_weight: 0,
                      cubic_meter: 0
                    }
                  );

                  return (
                    <>
                      <tr className="ant-table-row">
                        <td style={{ textAlign: 'right', fontWeight: 'bold' }} colSpan={4}>
                          {intl.formatMessage({ id: 'total' })}
                        </td>
                        <td style={{ textAlign: 'right', fontWeight: 'bold' }}>
                          {new Intl.NumberFormat([], {
                            style: 'decimal',
                            minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                            maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
                          }).format(calcRow.net_amt)}
                        </td>
                        <td style={{ textAlign: 'right', fontWeight: 'bold' }}>
                          {new Intl.NumberFormat([], {
                            style: 'decimal',
                            minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                            maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
                          }).format(calcRow.case_decimal_qty)}
                        </td>
                        <td style={{ textAlign: 'right', fontWeight: 'bold' }}>
                          {new Intl.NumberFormat([], {
                            style: 'decimal',
                            minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                            maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
                          }).format(calcRow.gross_weight)}
                        </td>
                        <td style={{ textAlign: 'right', fontWeight: 'bold' }}>
                          {new Intl.NumberFormat([], {
                            style: 'decimal',
                            minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                            maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
                          }).format(calcRow.cubic_meter)}
                        </td>
                        <td />
                      </tr>
                    </>
                  );
                }}
              />
            </Col>
          </Row>
          <Row type="flex" justify="center" gutter={[0, 8]}>
            <Col span={24}>
              <Table
                size="small"
                // rowSelection={rowSelection}
                rowKey="id"
                pagination={false}
                columns={this.getNewDocumentColumns()}
                dataSource={newDocuments}
                loading={createIsLoading}
                bordered
                title={() => <b>{intl.formatMessage({ id: 'new_pick_lists' })}</b>}
              />
            </Col>
          </Row>
        </Modal>
      </>
    );
  }
}

GdsIss01Dialog.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  setWorkspaceVisible: PropTypes.func,
  workspaceIsVisible: PropTypes.bool,

  removeSelectedDocuments: PropTypes.func,
  newDocuments: PropTypes.arrayOf(PropTypes.object),
  selectedDocuments: PropTypes.arrayOf(PropTypes.object),

  createIsLoading: PropTypes.bool,

  createGdsIss01: PropTypes.func
};

GdsIss01Dialog.defaultProps = {
  intl: {},
  setWorkspaceVisible() {},
  workspaceIsVisible: false,

  removeSelectedDocuments() {},
  newDocuments: [],
  selectedDocuments: [],

  createIsLoading: false,

  createGdsIss01() {}
};

const mapStateToProps = state => ({
  workspaceIsVisible: state.gdsIss01.workspaceIsVisible,

  selectedDocuments: state.gdsIss01.selectedDocuments,
  newDocuments: state.gdsIss01.newDocuments,

  createIsLoading: state.gdsIss01.createIsLoading
});

const mapDispatchToProps = dispatch => ({
  setWorkspaceVisible: boolean => dispatch(GdsIss01Actions.gdsIss01SetWorkspaceVisible(boolean)),

  removeSelectedDocuments: selectedDocuments =>
    dispatch(GdsIss01Actions.gdsIss01RemoveSelectedDocuments(selectedDocuments)),

  createGdsIss01: (hdrIds, toStorageBinId) =>
    dispatch(GdsIss01Actions.gdsIss01CreateGdsIss01(hdrIds, toStorageBinId))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(GdsIss01Dialog));
