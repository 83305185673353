import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  delOrdSync01ShowSyncSetting: null,
  delOrdSync01ShowSyncSettingLoading: ['boolean'],
  delOrdSync01ShowSyncSettingSuccess: ['syncSetting'],
  delOrdSync01UpdateSyncSetting: ['formikBag', 'syncSetting'],
  delOrdSync01ShowBatchJobStatus: null,
  delOrdSync01ShowBatchJobStatusSuccess: ['batchJobStatus'],
  // delOrdSync01SyncNow: ['formikBag', 'divisionId'],
  // delOrdSync01SyncLoading: ['boolean'],

  delOrdSync01ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  delOrdSync01GoToDocument: ['hdrId'],
  delOrdSync01FetchDelOrdSync01: ['currentPage', 'sorts', 'filters', 'pageSize'],
  delOrdSync01FetchDelOrdSync01Loading: ['boolean'],
  delOrdSync01FetchDelOrdSync01Success: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],
  delOrdSync01AddSelectedDocuments: ['selectedDocuments'],
  delOrdSync01RemoveSelectedDocuments: ['selectedDocuments'],
  delOrdSync01SetWorkspaceVisible: ['boolean'],

  delOrdSync01SyncNow: ['hdrIds'],
  delOrdSync01SyncNowSuccess: null,
  delOrdSync01SyncLoading: ['boolean']
});

export const DelOrdSync01Types = Types;
export default Creators;
