/**
 * The initial values for the redux state.
 */
export default {
  wipOutbOrdTimestamp: 0,
  wipOutbOrds: [],
  wipOutbOrdIsLoading: false,
  wipOutbOrdSorts: {
    doc_date: 'ascend',
    doc_code: 'ascend'
  },
  wipOutbOrdFilters: {},
  wipOutbOrdPageSize: '20',
  wipOutbOrdCurrentPage: 1,
  wipOutbOrdLastPage: 10,
  wipOutbOrdTotal: 0,

  wipPickListTimestamp: 0,
  wipPickLists: [],
  wipPickListIsLoading: false,
  wipPickListSorts: {
    doc_date: 'ascend',
    doc_code: 'ascend'
  },
  wipPickListFilters: {},
  wipPickListPageSize: '20',
  wipPickListCurrentPage: 1,
  wipPickListLastPage: 10,
  wipPickListTotal: 0,

  wipWhseJobTimestamp: 0,
  wipWhseJobs: [],
  wipWhseJobIsLoading: false,
  wipWhseJobSorts: {
    doc_date: 'ascend',
    doc_code: 'ascend'
  },
  wipWhseJobFilters: {},
  wipWhseJobPageSize: '20',
  wipWhseJobCurrentPage: 1,
  wipWhseJobLastPage: 10,
  wipWhseJobTotal: 0,

  completePickListTimestamp: 0,
  completePickLists: [],
  completePickListIsLoading: false,
  completePickListSorts: {
    doc_date: 'ascend',
    doc_code: 'ascend'
  },
  completePickListFilters: {},
  completePickListPageSize: '20',
  completePickListCurrentPage: 1,
  completePickListLastPage: 10,
  completePickListTotal: 0,

  wipWhseJobDialogIsVisible: false,
  wipWhseJobDocId: 0,
  wipWhseJobDocument: { doc_code: '', doc_status: 3 },
  wipWhseJobDocumentIsLoading: false,

  wipPickListDialogIsVisible: false,
  wipPickListDocId: 0,
  wipPickListDocument: { doc_code: '', doc_status: 3 },
  wipPickListDocumentIsLoading: false,

  wipOutbOrdDialogIsVisible: false,
  wipOutbOrdDocId: 0,
  wipOutbOrdDocument: { doc_code: '', doc_status: 3 },
  wipOutbOrdDocumentIsLoading: false,

  completePickListDialogIsVisible: false,
  completePickListDocId: 0,
  completePickListDocument: { doc_code: '', doc_status: 3 },
  completePickListDocumentIsLoading: false,

  loadingBinOptions: [{ value: 0, label: 'Search Loading Bay' }],
  loadingBinId: 0
};
