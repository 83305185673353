import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  whseJob140101ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  whseJob140101GoToDocument: ['hdrId'],
  whseJob140101FetchWhseJob140101: ['currentPage', 'sorts', 'filters', 'pageSize'],
  whseJob140101FetchWhseJob140101Loading: ['boolean'],
  whseJob140101FetchWhseJob140101Success: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],
  whseJob140101AddSelectedDocuments: ['selectedDocuments'],
  whseJob140101RemoveSelectedDocuments: ['selectedDocuments'],
  whseJob140101SetWorkspaceVisible: ['boolean'],
  whseJob140101PrintWhseJob140101: ['hdrIds'],
  whseJob140101PrintWhseJob140101Loading: ['boolean'],
  whseJob140101PrintHandlingUnit: ['hdrIds']
});

export const WhseJob140101Types = Types;
export default Creators;
