/**
 * The initial values for the redux state.
 */
export default {
  resId: 0,

  modelTimestamp: 0,
  model: {
    status: 2,
    str_status: 'INACTIVE',
    code: '',
    ref_code_01: '',
    ref_code_02: '',
    company_name_01: '',
    company_name_02: ''
  },

  itemBatchTimestamp: 0,
  itemBatches: [],
  itemBatchSorts: {
    batch_serial_no: 'descend',
    expiry_date: 'descend'
  },
  itemBatchFilters: {},
  itemBatchPageSize: '20',
  itemBatchCurrentPage: 1,
  itemBatchLastPage: 10,
  itemBatchTotal: 100,

  modelIsLoading: false,

  itemGroup01Options: [],
  itemGroup01IsLoading: false,
  itemGroup02Options: [],
  itemGroup02IsLoading: false,
  itemGroup03Options: [],
  itemGroup03IsLoading: false,

  initItemDocNo: {
    id: 0,
    doc_prefix: '',
    running_no: 1,
    running_no_length: 6,
    doc_suffix: '',
    is_enforce: 0
  },
  itemDocNo: [],
  itemDocNoDtl: [],
  formIsVisible: false,

  uomOptions: [],
  uomIsLoading: false
};
