import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  outboundExpiryReportInitOutboundExpiry: null,
  outboundExpiryReportInitOutboundExpirySuccess: ['criteria'],
  outboundExpiryReportOutboundExpiry: ['formikBag', 'criteria'],
  outboundExpiryReportOutboundExpirySuccess: ['criteria', 'reportData'],
  outboundExpiryReportReportLoading: ['boolean'],

  outboundExpiryReportFetchDivisionOptions: ['search'],
  outboundExpiryReportFetchDivisionOptionLoading: ['boolean'],
  outboundExpiryReportFetchDivisionOptionSuccess: ['options'],

  outboundExpiryReportFetchDeliveryPointOptions: ['search'],
  outboundExpiryReportFetchDeliveryPointOptionLoading: ['boolean'],
  outboundExpiryReportFetchDeliveryPointOptionSuccess: ['options'],

  outboundExpiryReportFetchDebtorOptions: ['search'],
  outboundExpiryReportFetchDebtorOptionLoading: ['boolean'],
  outboundExpiryReportFetchDebtorOptionSuccess: ['options'],

  outboundExpiryReportFetchItemOptions: ['search'],
  outboundExpiryReportFetchItemOptionLoading: ['boolean'],
  outboundExpiryReportFetchItemOptionSuccess: ['options'],

  outboundExpiryReportFetchItemGroup01Options: ['search'],
  outboundExpiryReportFetchItemGroup01OptionLoading: ['boolean'],
  outboundExpiryReportFetchItemGroup01OptionSuccess: ['options'],

  outboundExpiryReportFetchItemGroup02Options: ['search'],
  outboundExpiryReportFetchItemGroup02OptionLoading: ['boolean'],
  outboundExpiryReportFetchItemGroup02OptionSuccess: ['options'],

  outboundExpiryReportFetchItemGroup03Options: ['search'],
  outboundExpiryReportFetchItemGroup03OptionLoading: ['boolean'],
  outboundExpiryReportFetchItemGroup03OptionSuccess: ['options']
});

export const OutboundExpiryReportTypes = Types;
export default Creators;
