import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import treeChanges from 'tree-changes';
import moment from 'moment';

import { EditOutlined, LaptopOutlined, ReloadOutlined } from '@ant-design/icons';

import { Row, Col, Table, Button, Badge, Card } from 'antd';

import AntDesignTable from '../../Components/AntDesignTable';
import WhseJob060201Actions from '../../Stores/WhseJob060201/Actions';

import WhseJob060201Dialog from './WhseJob060201Dialog';

class WhseJob060201Table extends React.PureComponent {
  constructor() {
    super();

    this.useOnTableChange = this.useOnTableChange.bind(this);
    this.useShowWorkspace = this.useShowWorkspace.bind(this);

    this.handleSearch = this.handleSearch.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.getDocumentColumns = this.getDocumentColumns.bind(this);
    this.getExpandedColumns = this.getExpandedColumns.bind(this);
    this.showExpandedRow = this.showExpandedRow.bind(this);
  }

  componentDidMount() {
    const { curSiteFlowId, setWorkspaceVisible, resetTimestamp } = this.props;

    if (curSiteFlowId > 0) {
      setWorkspaceVisible(false);
      resetTimestamp();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      curSiteFlowId,
      resetTimestamp,
      fetchWhseJob060201,
      currentPage,
      sorts,
      filters,
      pageSize
    } = this.props;

    const { changed } = treeChanges(prevProps, this.props);

    if (changed('curSiteFlowId')) {
      resetTimestamp();
    }

    if (changed('timestamp')) {
      if (curSiteFlowId > 0) {
        fetchWhseJob060201(currentPage, sorts, filters, pageSize);
      }
    }
  }

  componentWillUnmount() {}

  handleSearch(selectedKeys, confirm) {
    // eslint-disable-next-line no-unused-vars
    const { intl } = this.props;

    confirm();
  }

  handleReset(clearFilters) {
    // eslint-disable-next-line no-unused-vars
    const { intl } = this.props;

    clearFilters();
  }

  getDocumentColumns() {
    const { intl, sorts, filters } = this.props;

    return [
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'doc_code' }),
        // sort field
        dataIndex: 'doc_code',
        ...AntDesignTable.getColumnSortProps(sorts, 'doc_code'),
        // filter field
        key: 'doc_code',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'doc_code' }),
          'doc_code',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.doc_code}</>
      },
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'doc_date' }),
        // sort field
        dataIndex: 'doc_date',
        ...AntDesignTable.getColumnSortProps(sorts, 'doc_date'),
        // filter field
        key: 'doc_date',
        ...AntDesignTable.getColumnSearchDateProps(
          filters,
          intl.formatMessage({ id: 'doc_date' }),
          'doc_date',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{moment(record.doc_date).format('YYYY-MM-DD')}</>
      },
      {
        width: 115,
        align: 'left',
        title: intl.formatMessage({ id: 'printed_at' }),
        // sort field
        dataIndex: 'last_printed_at',
        ...AntDesignTable.getColumnSortProps(sorts, 'last_printed_at'),
        // filter field
        key: 'last_printed_at',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'printed_at' }),
          'last_printed_at',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => (
          <>
            {record.last_printed_at}
            <br />
            <b>{record.print_count}</b>
          </>
        )
      },
      {
        width: 90,
        align: 'left',
        title: intl.formatMessage({ id: 'from_storage_bin' }),
        // sort field
        dataIndex: 'storage_bin_code',
        // filter field
        key: 'storage_bin',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'from_storage_bin' }),
          'storage_bin',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.storage_bin_code}</>
      },
      {
        width: 90,
        align: 'left',
        title: intl.formatMessage({ id: 'transport' }),
        // sort field
        dataIndex: 'transport_code',
        // filter field
        key: 'transport',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'transport' }),
          'transport',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.transport_code}</>
      },
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'area' }),
        // sort field
        dataIndex: 'delivery_point_area_code',
        ...AntDesignTable.getColumnSortProps(sorts, 'delivery_point_area_code'),
        // filter field
        key: 'delivery_point_area',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'area' }),
          'delivery_point_area',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => (
          <>
            {record.areas.map(value => (
              <div key={`${record.id}:${value.code}`}>
                <b>{value.code}</b>
                <br />
                {value.desc_01}
              </div>
            ))}
          </>
        )
      },
      {
        width: 120,
        align: 'left',
        title: intl.formatMessage({ id: 'division' }),
        // sort field
        dataIndex: 'division_code',
        // filter field
        key: 'division',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'division' }),
          'division',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => (
          <>
            {record.divisions.map(value => (
              <div key={`${record.id}:${value.code}`}>
                <b>{value.code}</b>
              </div>
            ))}
          </>
        )
      },
      {
        width: 120,
        align: 'left',
        title: intl.formatMessage({ id: 'salesman' }),
        // sort field
        dataIndex: 'salesman_username',
        // filter field
        key: 'salesman',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'salesman' }),
          'salesman',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => (
          <>
            {record.salesmans.map(value => (
              <div key={`${record.id}:${value.username}`}>
                <b>{value.username}</b>
              </div>
            ))}
          </>
        )
      },
      {
        width: 150,
        align: 'left',
        title: intl.formatMessage({ id: 'delivery_point' }),
        // sort field
        dataIndex: 'delivery_point_code',
        ...AntDesignTable.getColumnSortProps(sorts, 'delivery_point_code'),
        // filter field
        key: 'delivery_point',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'delivery_point' }),
          'delivery_point',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => (
          <>
            {record.delivery_points.map(value => (
              <div key={`${record.id}:${value.code}`}>
                <b>{value.code}</b>
                <br />
                {value.company_name_01}
              </div>
            ))}
          </>
        )
      },
      {
        width: 150,
        // fixed: 'right',
        align: 'left',
        title: intl.formatMessage({ id: 'documents' }),
        // sort field
        dataIndex: 'outb_ord_hdr_code',
        ...AntDesignTable.getColumnSortProps(sorts, 'outb_ord_hdr_code'),
        // filter field
        key: 'outb_ord_hdr',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'documents' }),
          'outb_ord_hdr',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => (
          <>
            {record.outb_ord_hdrs.map(value => (
              <div key={`${record.id}:${value.id}`} style={{ width: '100%', textAlign: 'right' }}>
                <b>{value.shipping_doc_code}</b>
                {value.shipping_doc_code !== value.ref_code_01 && (
                  <>
                    <br />
                    {value.ref_code_01}
                  </>
                )}
                <div>
                  {new Intl.NumberFormat([], {
                    style: 'decimal',
                    minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                    maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
                  }).format(value.net_amt)}
                </div>
              </div>
            ))}
          </>
        )
      },
      {
        width: 150,
        // fixed: 'right',
        align: 'left',
        title: intl.formatMessage({ id: 'document_desc' }),
        // sort field
        dataIndex: 'outb_ord_hdr_desc_01',
        ...AntDesignTable.getColumnSortProps(sorts, 'outb_ord_hdr_desc_01'),
        // filter field
        key: 'outb_ord_hdr_desc_01',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'document_desc' }),
          'outb_ord_hdr_desc_01',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => (
          <>
            {record.outb_ord_hdrs.map(value => (
              <div key={`${record.id}:${value.id}`} style={{ width: '100%', textAlign: 'right' }}>
                <div>{`${value.desc_01}`}</div>
              </div>
            ))}
          </>
        )
      }
    ];
  }

  getExpandedColumns() {
    const { intl, goToWhseJobDocument } = this.props;

    return [
      {
        align: 'left',
        title: intl.formatMessage({ id: 'code' }),
        // sort field
        dataIndex: 'doc_code',
        // filter field
        key: 'doc_code',
        render: (text, record) => <>{record.doc_code}</>
      },
      {
        align: 'left',
        title: intl.formatMessage({ id: 'doc_status' }),
        // sort field
        dataIndex: 'str_doc_status',
        // filter field
        key: 'str_doc_status',
        render: (text, record) => <>{record.str_doc_status}</>
      },
      {
        align: 'left',
        title: intl.formatMessage({ id: 'worker' }),
        // sort field
        dataIndex: 'worker_01_username',
        // filter field
        key: 'worker_01_username',
        render: (text, record) => <>{record.worker_01_username}</>
      },
      {
        align: 'left',
        title: intl.formatMessage({ id: 'ref_code' }),
        // sort field
        dataIndex: 'ref_code_01',
        // filter field
        key: 'ref_code_01',
        render: (text, record) => <>{`${record.ref_code_01} ${record.ref_code_02}`}</>
      },

      {
        key: 'action',
        render: (text, record) => (
          <>
            <Button
              type="dashed"
              icon={<EditOutlined />}
              onClick={() => goToWhseJobDocument(record.id)}
            />
          </>
        )
      }
    ];
  }

  useOnTableChange(pagination, filters, sorter) {
    const { resetTimestamp } = this.props;

    const processedFilters = AntDesignTable.processFilters(filters);
    const processedSorts = AntDesignTable.processSorts(sorter);

    resetTimestamp(pagination.current, processedSorts, processedFilters, pagination.pageSize);
  }

  useShowWorkspace() {
    const { setWorkspaceVisible } = this.props;

    setWorkspaceVisible(true);
  }

  showExpandedRow(record) {
    const { fetchIsLoading } = this.props;

    return (
      <Card bordered={false}>
        <Table
          size="small"
          // rowSelection={rowSelection}
          rowKey="id"
          bordered={false}
          pagination={false}
          columns={this.getExpandedColumns()}
          dataSource={record.whse_job_hdrs}
          loading={fetchIsLoading}
          defaultExpandAllRows
        />
      </Card>
    );
  }

  render() {
    const {
      intl,
      resetTimestamp,
      currentPage,
      pageSize,
      total,
      documents,
      fetchIsLoading,
      selectedDocuments,
      addSelectedDocuments,
      removeSelectedDocuments,
      expandedRows,
      setExpandedRows
    } = this.props;

    return (
      <Card
        title={intl.formatMessage({ id: 'mobile_load_lists' })}
        bordered={false}
        headStyle={{ textAlign: 'center' }}
        bodyStyle={{ padding: 0 }}
      >
        <Table
          size="small"
          // rowSelection={rowSelection}
          rowKey="id"
          pagination={{
            current: currentPage,
            pageSize: parseInt(pageSize, 10),
            total,
            showTotal: () => `${total} items`
          }}
          columns={this.getDocumentColumns()}
          dataSource={documents}
          loading={fetchIsLoading}
          bordered
          rowSelection={{
            selectedRowKeys: selectedDocuments.map(value => value.id),
            onChange: () => {
              // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            },
            onSelect: (record, selected) => {
              // console.log(`onSelect record: `, record, 'selected: ', selected, 'selectedRows: ', selectedRows);
              if (selected === true) {
                addSelectedDocuments([record]);
              } else {
                removeSelectedDocuments([record]);
              }
            },
            onSelectAll: (selected, selectedRows, changeRows) => {
              // console.log('onSelectAll selected: ', selected, 'selectedRows: ', selectedRows, 'changeRows: ', changeRows);
              if (selected === true) {
                addSelectedDocuments(changeRows);
              } else {
                removeSelectedDocuments(changeRows);
              }
            },
            onSelectInvert: selectedRows => {
              // console.log('onSelectInvert selectedRows: ', selectedRows);
              removeSelectedDocuments(selectedRows);
            },
            getCheckboxProps: () => ({
              // Column configuration not to be checked
              // disabled: record.str_doc_status !== 'WIP'
              // name: record.name,
            })
          }}
          onChange={this.useOnTableChange}
          expandedRowRender={this.showExpandedRow}
          expandedRowKeys={expandedRows}
          onExpandedRowsChange={rowKeys => {
            setExpandedRows(rowKeys);
          }}
          title={() => (
            <>
              <Row type="flex" justify="space-between" gutter={[0, 16]}>
                <Col span={8}>
                  <Button
                    type="primary"
                    // disabled={!isValid}
                    loading={fetchIsLoading}
                    onClick={() => resetTimestamp()}
                    icon={<ReloadOutlined />}
                  />
                </Col>
              </Row>
            </>
          )}
          scroll={{ x: 950 }}
        />

        <div
          style={{
            position: 'fixed',
            bottom: '50px',
            right: '50px',
            zIndex: 9999
          }}
        >
          <Badge count={selectedDocuments.length}>
            <Button
              size="large"
              type="primary"
              shape="circle"
              icon={<LaptopOutlined />}
              onClick={this.useShowWorkspace}
              loading={fetchIsLoading}
            />
          </Badge>
        </div>

        <WhseJob060201Dialog />
      </Card>
    );
  }
}

WhseJob060201Table.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  resetTimestamp: PropTypes.func,
  goToWhseJobDocument: PropTypes.func,

  setWorkspaceVisible: PropTypes.func,

  addSelectedDocuments: PropTypes.func,
  removeSelectedDocuments: PropTypes.func,
  documents: PropTypes.arrayOf(PropTypes.object),
  selectedDocuments: PropTypes.arrayOf(PropTypes.object),

  sorts: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  filters: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),

  currentPage: PropTypes.number,
  pageSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  total: PropTypes.number,
  fetchIsLoading: PropTypes.bool,

  curSiteFlowId: PropTypes.number,
  fetchWhseJob060201: PropTypes.func,

  expandedRows: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  setExpandedRows: PropTypes.func
};

WhseJob060201Table.defaultProps = {
  intl: {},
  resetTimestamp() {},
  goToWhseJobDocument() {},

  setWorkspaceVisible() {},

  addSelectedDocuments() {},
  removeSelectedDocuments() {},
  documents: [],
  selectedDocuments: [],

  sorts: {},
  filters: {},

  currentPage: 1,
  pageSize: '20',
  total: 0,
  fetchIsLoading: false,

  curSiteFlowId: 0,
  fetchWhseJob060201() {},

  expandedRows: [],
  setExpandedRows() {}
};

const mapStateToProps = state => ({
  timestamp: state.whseJob060201.timestamp,
  workspaceIsVisible: state.whseJob060201.workspaceIsVisible,

  documents: state.whseJob060201.documents,
  selectedDocuments: state.whseJob060201.selectedDocuments,

  sorts: state.whseJob060201.sorts,
  filters: state.whseJob060201.filters,

  currentPage: state.whseJob060201.currentPage,
  pageSize: state.whseJob060201.pageSize,
  total: state.whseJob060201.total,
  fetchIsLoading: state.whseJob060201.fetchIsLoading,

  curSiteFlowId: state.app.curSiteFlowId,
  expandedRows: state.whseJob060201.expandedRows
});

const mapDispatchToProps = dispatch => ({
  resetTimestamp: (currentPage, sorts, filters, pageSize) =>
    dispatch(
      WhseJob060201Actions.whseJob060201ResetTimestamp(currentPage, sorts, filters, pageSize)
    ),
  goToWhseJobDocument: hdrId =>
    dispatch(WhseJob060201Actions.whseJob060201GoToWhseJobDocument(hdrId)),
  setWorkspaceVisible: boolean =>
    dispatch(WhseJob060201Actions.whseJob060201SetWorkspaceVisible(boolean)),

  addSelectedDocuments: selectedDocuments =>
    dispatch(WhseJob060201Actions.whseJob060201AddSelectedDocuments(selectedDocuments)),
  removeSelectedDocuments: selectedDocuments =>
    dispatch(WhseJob060201Actions.whseJob060201RemoveSelectedDocuments(selectedDocuments)),

  fetchWhseJob060201: (currentPage, sorts, filters, pageSize) =>
    dispatch(
      WhseJob060201Actions.whseJob060201FetchWhseJob060201(currentPage, sorts, filters, pageSize)
    ),

  setExpandedRows: expandedRows =>
    dispatch(WhseJob060201Actions.whseJob060201SetExpandedRows(expandedRows))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(WhseJob060201Table));
