import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  storageBayList01ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  storageBayList01GoToDocument: ['hdrId'],
  storageBayList01FetchStorageBayList01: ['currentPage', 'sorts', 'filters', 'pageSize'],
  storageBayList01FetchStorageBayList01Loading: ['boolean'],
  storageBayList01FetchStorageBayList01Success: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],
  storageBayList01AddSelectedDocuments: ['selectedDocuments'],
  storageBayList01RemoveSelectedDocuments: ['selectedDocuments'],
  storageBayList01SetWorkspaceVisible: ['boolean'],

  storageBayList01UploadExcel: ['file'],
  storageBayList01UploadLoading: ['boolean'],
  storageBayList01DownloadExcel: null
});

export const StorageBayList01Types = Types;
export default Creators;
