/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { WhseJob16DetailTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const whseJob16DetailResetTimestamp = state => ({
  ...state,
  timestamp: Date.now()
});

export const whseJob16DetailSetHdrId = (state, { hdrId }) => ({
  ...state,
  timestamp: Date.now(),
  documentIsLoading: false,
  hdrId,
  detailIsVisible: false,
  jobDetailIsVisible: false
});

export const whseJob16DetailShowDocumentLoading = (state, { boolean }) => ({
  ...state,
  documentIsLoading: boolean
});

export const whseJob16DetailShowHeaderSuccess = (state, { documentHeader }) => ({
  ...state,
  documentHeader
});

export const whseJob16DetailUpdateDocumentSuccess = (
  state,
  { documentHeader, documentDetails }
) => ({
  ...state,
  documentHeader,
  documentDetails
});

export const whseJob16DetailShowDetailsSuccess = (state, { documentDetails }) => ({
  ...state,
  documentDetails
});

export const whseJob16DetailSetDetailVisible = (state, { boolean }) => ({
  ...state,
  detailIsVisible: boolean
});

export const whseJob16DetailSetWhseJobType = (state, { whseJobType }) => ({
  ...state,
  whseJobType,
  storageBinOptions: [],
  documentDetail: {
    ...state.documentDetail,
    storage_bin_select2: { value: 0, label: '' }
  }
});

export const whseJob16DetailSetDocumentDetail = (state, { documentDetail }) => ({
  ...state,
  documentDetail,
  whseJobType: documentDetail.whse_job_type,
  companyOptions: [],
  quantBalOptions: [],
  itemBatchOptions: [],
  uomOptions: []
});

export const whseJob16DetailFetchCompanyOptionLoading = (state, { boolean }) => ({
  ...state,
  companyIsLoading: boolean
});

export const whseJob16DetailFetchCompanyOptionSuccess = (state, { options }) => ({
  ...state,
  companyOptions: options
});

export const whseJob16DetailFetchItemOptionLoading = (state, { boolean }) => ({
  ...state,
  itemIsLoading: boolean
});

export const whseJob16DetailFetchItemOptionSuccess = (state, { options }) => ({
  ...state,
  itemOptions: options
});

export const whseJob16DetailFetchItemBatchOptionLoading = (state, { boolean }) => ({
  ...state,
  itemBatchIsLoading: boolean
});

export const whseJob16DetailFetchItemBatchOptionSuccess = (state, { options }) => ({
  ...state,
  itemBatchOptions: options
});

export const whseJob16DetailFetchUomOptionLoading = (state, { boolean }) => ({
  ...state,
  uomIsLoading: boolean
});

export const whseJob16DetailFetchUomOptionSuccess = (state, { options }) => ({
  ...state,
  uomOptions: options
});

export const whseJob16DetailFetchStorageBinOptionLoading = (state, { boolean }) => ({
  ...state,
  storageBinIsLoading: boolean
});

export const whseJob16DetailFetchStorageBinOptionSuccess = (state, { options }) => ({
  ...state,
  storageBinOptions: options
});

export const whseJob16DetailFetchHandlingUnitOptionLoading = (state, { boolean }) => ({
  ...state,
  handlingUnitIsLoading: boolean
});

export const whseJob16DetailFetchHandlingUnitOptionSuccess = (state, { options }) => ({
  ...state,
  handlingUnitOptions: options
});

// force refresh the formik form
export const whseJob16DetailUpdateDetails = (state, { documentDetails }) => ({
  ...state,
  documentDetail: documentDetails[0]
});

// force refresh the formik form
export const whseJob16DetailCreateDetail = (state, { documentDetail }) => ({
  ...state,
  documentDetail
});

export const whseJob16DetailFetchStorageRowOptionLoading = (state, { boolean }) => ({
  ...state,
  storageRowIsLoading: boolean
});

export const whseJob16DetailFetchStorageRowOptionSuccess = (state, { options }) => ({
  ...state,
  storageRowOptions: options
});

export const whseJob16DetailFetchStorageBayOptionLoading = (state, { boolean }) => ({
  ...state,
  storageBayIsLoading: boolean
});

export const whseJob16DetailFetchStorageBayOptionSuccess = (state, { options }) => ({
  ...state,
  storageBayOptions: options
});

export const whseJob16DetailFetchWorkerOptionLoading = (state, { boolean }) => ({
  ...state,
  workerIsLoading: boolean
});

export const whseJob16DetailFetchWorkerOptionSuccess = (state, { options }) => ({
  ...state,
  workerOptions: options
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [WhseJob16DetailTypes.WHSE_JOB16_DETAIL_RESET_TIMESTAMP]: whseJob16DetailResetTimestamp,
  [WhseJob16DetailTypes.WHSE_JOB16_DETAIL_SET_HDR_ID]: whseJob16DetailSetHdrId,

  [WhseJob16DetailTypes.WHSE_JOB16_DETAIL_SHOW_HEADER_SUCCESS]: whseJob16DetailShowHeaderSuccess,

  [WhseJob16DetailTypes.WHSE_JOB16_DETAIL_UPDATE_DOCUMENT_SUCCESS]: whseJob16DetailUpdateDocumentSuccess,

  [WhseJob16DetailTypes.WHSE_JOB16_DETAIL_SHOW_DOCUMENT_LOADING]: whseJob16DetailShowDocumentLoading,

  [WhseJob16DetailTypes.WHSE_JOB16_DETAIL_SHOW_DETAILS_SUCCESS]: whseJob16DetailShowDetailsSuccess,

  [WhseJob16DetailTypes.WHSE_JOB16_DETAIL_SET_DETAIL_VISIBLE]: whseJob16DetailSetDetailVisible,
  [WhseJob16DetailTypes.WHSE_JOB16_DETAIL_SET_DOCUMENT_DETAIL]: whseJob16DetailSetDocumentDetail,

  [WhseJob16DetailTypes.WHSE_JOB16_DETAIL_UPDATE_DETAILS]: whseJob16DetailUpdateDetails,
  [WhseJob16DetailTypes.WHSE_JOB16_DETAIL_CREATE_DETAIL]: whseJob16DetailCreateDetail,

  [WhseJob16DetailTypes.WHSE_JOB16_DETAIL_SET_WHSE_JOB_TYPE]: whseJob16DetailSetWhseJobType,

  [WhseJob16DetailTypes.WHSE_JOB16_DETAIL_FETCH_COMPANY_OPTION_LOADING]: whseJob16DetailFetchCompanyOptionLoading,
  [WhseJob16DetailTypes.WHSE_JOB16_DETAIL_FETCH_COMPANY_OPTION_SUCCESS]: whseJob16DetailFetchCompanyOptionSuccess,

  [WhseJob16DetailTypes.WHSE_JOB16_DETAIL_FETCH_ITEM_OPTION_LOADING]: whseJob16DetailFetchItemOptionLoading,
  [WhseJob16DetailTypes.WHSE_JOB16_DETAIL_FETCH_ITEM_OPTION_SUCCESS]: whseJob16DetailFetchItemOptionSuccess,

  [WhseJob16DetailTypes.WHSE_JOB16_DETAIL_FETCH_ITEM_BATCH_OPTION_LOADING]: whseJob16DetailFetchItemBatchOptionLoading,
  [WhseJob16DetailTypes.WHSE_JOB16_DETAIL_FETCH_ITEM_BATCH_OPTION_SUCCESS]: whseJob16DetailFetchItemBatchOptionSuccess,

  [WhseJob16DetailTypes.WHSE_JOB16_DETAIL_FETCH_UOM_OPTION_LOADING]: whseJob16DetailFetchUomOptionLoading,
  [WhseJob16DetailTypes.WHSE_JOB16_DETAIL_FETCH_UOM_OPTION_SUCCESS]: whseJob16DetailFetchUomOptionSuccess,

  [WhseJob16DetailTypes.WHSE_JOB16_DETAIL_FETCH_STORAGE_BIN_OPTION_LOADING]: whseJob16DetailFetchStorageBinOptionLoading,
  [WhseJob16DetailTypes.WHSE_JOB16_DETAIL_FETCH_STORAGE_BIN_OPTION_SUCCESS]: whseJob16DetailFetchStorageBinOptionSuccess,

  [WhseJob16DetailTypes.WHSE_JOB16_DETAIL_FETCH_HANDLING_UNIT_OPTION_LOADING]: whseJob16DetailFetchHandlingUnitOptionLoading,
  [WhseJob16DetailTypes.WHSE_JOB16_DETAIL_FETCH_HANDLING_UNIT_OPTION_SUCCESS]: whseJob16DetailFetchHandlingUnitOptionSuccess,

  [WhseJob16DetailTypes.WHSE_JOB16_DETAIL_FETCH_STORAGE_ROW_OPTION_LOADING]: whseJob16DetailFetchStorageRowOptionLoading,
  [WhseJob16DetailTypes.WHSE_JOB16_DETAIL_FETCH_STORAGE_ROW_OPTION_SUCCESS]: whseJob16DetailFetchStorageRowOptionSuccess,

  [WhseJob16DetailTypes.WHSE_JOB16_DETAIL_FETCH_STORAGE_BAY_OPTION_LOADING]: whseJob16DetailFetchStorageBayOptionLoading,
  [WhseJob16DetailTypes.WHSE_JOB16_DETAIL_FETCH_STORAGE_BAY_OPTION_SUCCESS]: whseJob16DetailFetchStorageBayOptionSuccess,

  [WhseJob16DetailTypes.WHSE_JOB16_DETAIL_FETCH_WORKER_OPTION_LOADING]: whseJob16DetailFetchWorkerOptionLoading,
  [WhseJob16DetailTypes.WHSE_JOB16_DETAIL_FETCH_WORKER_OPTION_SUCCESS]: whseJob16DetailFetchWorkerOptionSuccess
});
