import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  companyDetailResetModelTimestamp: null,
  companyDetailSetResId: ['resId'],

  companyDetailInitModel: null,
  companyDetailShowModel: ['resId'],
  companyDetailShowModelSuccess: ['model'],

  companyDetailUpdateModel: ['formikBag', 'model'],
  companyDetailCreateModel: ['formikBag', 'model'],
  companyDetailUpdateModelSuccess: ['model'],

  companyDetailShowModelLoading: ['boolean']
});

export const CompanyDetailTypes = Types;
export default Creators;
