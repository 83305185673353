/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { ProdOrdFServ02Types } from './Actions';
import INITIAL_STATE from './InitialState';

export const prodOrdFServ02ShowSyncSettingLoading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const prodOrdFServ02ShowSyncSettingSuccess = (state, { syncSetting }) => ({
  ...state,
  syncSetting
});

export const prodOrdFServ02ShowBatchJobStatusSuccess = (state, { batchJobStatus }) => ({
  ...state,
  batchJobStatus
});

export const prodOrdFServ02ExportLoading = (state, { boolean }) => ({
  ...state,
  exportIsLoading: boolean
});

export const prodOrdFServ02ResetTimestamp = (state, { currentPage, sorts, filters, pageSize }) => ({
  ...state,
  currentPage: currentPage || state.currentPage,
  sorts: sorts || state.sorts,
  filters: filters || state.filters,
  pageSize: pageSize || state.pageSize,
  timestamp: Date.now()
  // selectedDocuments: INITIAL_STATE.selectedDocuments
});

export const prodOrdFServ02FetchProdOrdFServ02Loading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const prodOrdFServ02FetchProdOrdFServ02Success = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  documents,
  currentPage,
  lastPage,
  total,
  pageSize
});

export const prodOrdFServ02AddSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = state.selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: [
      ...state.selectedDocuments,
      ...selectedDocuments.filter(data => {
        return !selectIds.includes(data.id);
      })
    ]
  };
};

export const prodOrdFServ02RemoveSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: state.selectedDocuments.filter(data => {
      return !selectIds.includes(data.id);
    })
  };
};

export const prodOrdFServ02SetWorkspaceVisible = (state, { boolean }) => ({
  ...state,
  workspaceIsVisible: boolean
});

export const prodOrdFServ02ExportSuccess = state => ({
  ...state,
  timestamp: Date.now(),
  selectedDocuments: []
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [ProdOrdFServ02Types.PROD_ORD_F_SERV02_SHOW_SYNC_SETTING_LOADING]: prodOrdFServ02ShowSyncSettingLoading,
  [ProdOrdFServ02Types.PROD_ORD_F_SERV02_SHOW_SYNC_SETTING_SUCCESS]: prodOrdFServ02ShowSyncSettingSuccess,
  [ProdOrdFServ02Types.PROD_ORD_F_SERV02_SHOW_BATCH_JOB_STATUS_SUCCESS]: prodOrdFServ02ShowBatchJobStatusSuccess,

  [ProdOrdFServ02Types.PROD_ORD_F_SERV02_RESET_TIMESTAMP]: prodOrdFServ02ResetTimestamp,
  [ProdOrdFServ02Types.PROD_ORD_F_SERV02_FETCH_PROD_ORD_F_SERV02_LOADING]: prodOrdFServ02FetchProdOrdFServ02Loading,
  [ProdOrdFServ02Types.PROD_ORD_F_SERV02_FETCH_PROD_ORD_F_SERV02_SUCCESS]: prodOrdFServ02FetchProdOrdFServ02Success,
  [ProdOrdFServ02Types.PROD_ORD_F_SERV02_ADD_SELECTED_DOCUMENTS]: prodOrdFServ02AddSelectedDocuments,
  [ProdOrdFServ02Types.PROD_ORD_F_SERV02_REMOVE_SELECTED_DOCUMENTS]: prodOrdFServ02RemoveSelectedDocuments,
  [ProdOrdFServ02Types.PROD_ORD_F_SERV02_SET_WORKSPACE_VISIBLE]: prodOrdFServ02SetWorkspaceVisible,
  [ProdOrdFServ02Types.PROD_ORD_F_SERV02_EXPORT_LOADING]: prodOrdFServ02ExportLoading,
  [ProdOrdFServ02Types.PROD_ORD_F_SERV02_EXPORT_SUCCESS]: prodOrdFServ02ExportSuccess
});
