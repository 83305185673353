import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import treeChanges from 'tree-changes';
import moment from 'moment';

import { EditOutlined, ReloadOutlined, AuditOutlined, LaptopOutlined } from '@ant-design/icons';

import { Card, Row, Col, Table, Button, Tag, Badge, Popover } from 'antd';

import AntDesignTable from '../../Components/AntDesignTable';
import ProdSheetIndexActions from '../../Stores/ProdSheetIndex/Actions';
import ProdSheetIndexDialog from './ProdSheetIndexDialog';

class ProdSheetIndexTable extends React.PureComponent {
  constructor() {
    super();

    this.useOnTableChange = this.useOnTableChange.bind(this);
    this.useShowWorkspace = this.useShowWorkspace.bind(this);

    this.handleSearch = this.handleSearch.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.getDocumentColumns = this.getDocumentColumns.bind(this);
  }

  componentDidMount() {
    const { curDivisionId, setWorkspaceVisible, resetTimestamp } = this.props;

    if (curDivisionId > 0) {
      setWorkspaceVisible(false);
      resetTimestamp();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      curDivisionId,
      resetTimestamp,
      fetchProdSheetIndex,
      currentPage,
      sorts,
      filters,
      pageSize
    } = this.props;

    const { changed } = treeChanges(prevProps, this.props);

    if (changed('curDivisionId')) {
      resetTimestamp();
    }

    if (changed('timestamp')) {
      if (curDivisionId > 0) {
        fetchProdSheetIndex(currentPage, sorts, filters, pageSize);
      }
    }
  }

  componentWillUnmount() {}

  handleSearch(selectedKeys, confirm) {
    // eslint-disable-next-line no-unused-vars
    const { intl } = this.props;

    confirm();
  }

  handleReset(clearFilters) {
    // eslint-disable-next-line no-unused-vars
    const { intl } = this.props;

    clearFilters();
  }

  getDocumentColumns() {
    const { intl, sorts, filters, goToDocument, goToAudit } = this.props;

    return [
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'target_date' }),
        // sort field
        dataIndex: 'est_del_date',
        ...AntDesignTable.getColumnSortProps(sorts, 'est_del_date'),
        // filter field
        key: 'est_del_date',
        ...AntDesignTable.getColumnSearchDateProps(
          filters,
          intl.formatMessage({ id: 'target_date' }),
          'est_del_date',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{moment(record.est_del_date).format('YYYY-MM-DD')}</>
      },
      {
        width: 130,
        // fixed: 'left',
        align: 'left',
        title: intl.formatMessage({ id: 'doc_code' }),
        // sort field
        dataIndex: 'doc_code',
        ...AntDesignTable.getColumnSortProps(sorts, 'doc_code'),
        // filter field
        key: 'doc_code',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'doc_code' }),
          'doc_code',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.doc_code}</>
      },
      {
        width: 100,
        // fixed: 'left',
        align: 'left',
        title: intl.formatMessage({ id: 'doc_status' }),
        // sort field
        dataIndex: 'doc_status',
        ...AntDesignTable.getColumnSortProps(sorts, 'doc_status'),
        // filter field
        ...AntDesignTable.getColumnSearchDocStatusProps(filters, 'doc_status'),
        render: (text, record) => (
          <>
            <div>{record.str_doc_status}</div>
            {record.doc_flows.map(docData =>
              docData.doc_id === 0 ? (
                <Popover content={docData.doc_date} key={docData.doc_code}>
                  <Tag color="gold">{docData.doc_code}</Tag>
                </Popover>
              ) : (
                ''
              )
            )}
          </>
        )
      },
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'doc_date' }),
        // sort field
        dataIndex: 'doc_date',
        ...AntDesignTable.getColumnSortProps(sorts, 'doc_date'),
        // filter field
        key: 'doc_date',
        ...AntDesignTable.getColumnSearchDateProps(
          filters,
          intl.formatMessage({ id: 'doc_date' }),
          'doc_date',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{moment(record.doc_date).format('YYYY-MM-DD')}</>
      },
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'project' }),
        // sort field
        dataIndex: 'project_code',
        ...AntDesignTable.getColumnSortProps(sorts, 'project_code'),
        // filter field
        key: 'project_code',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'project' }),
          'project_code',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.project_code}</>
      },
      {
        width: 150,
        align: 'left',
        title: intl.formatMessage({ id: 'desc' }),
        // sort field
        dataIndex: 'desc_01',
        ...AntDesignTable.getColumnSortProps(sorts, 'desc_01'),
        // filter field
        key: 'desc',
        render: (text, record) => <>{record.desc_01}</>
      },
      {
        width: 120,
        fixed: 'right',
        align: 'right',
        title: intl.formatMessage({ id: 'net_amt' }),
        // sort field
        dataIndex: 'net_amt',
        ...AntDesignTable.getColumnSortProps(sorts, 'net_amt'),
        // filter field
        key: 'net_amt',
        render: (text, record) => (
          <>
            <div style={{ textAlign: 'right' }}>
              {new Intl.NumberFormat([], {
                style: 'decimal',
                minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
              }).format(record.case_decimal_qty)}
              <b>
                &nbsp;
                {intl.formatMessage({ id: 'case_decimal_qty' })}
              </b>
            </div>

            <div style={{ textAlign: 'right' }}>
              {new Intl.NumberFormat([], {
                style: 'decimal',
                minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
              }).format(record.gross_weight)}
              <b>
                &nbsp;
                {intl.formatMessage({ id: 'gross_weight' })}
              </b>
            </div>

            <div style={{ textAlign: 'right' }}>
              {new Intl.NumberFormat([], {
                style: 'decimal',
                minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
              }).format(record.cubic_meter)}
              <b>
                &nbsp;
                {intl.formatMessage({ id: 'cubic_meter' })}
              </b>
            </div>
          </>
        )
      },

      {
        width: 50,
        fixed: 'right',
        key: 'action',
        render: (text, record) => (
          <>
            <Button type="dashed" icon={<EditOutlined />} onClick={() => goToDocument(record.id)} />
            <Button type="dashed" icon={<AuditOutlined />} onClick={() => goToAudit(record.id)} />
          </>
        )
      }
    ];
  }

  useOnTableChange(pagination, filters, sorter) {
    const { resetTimestamp } = this.props;

    const processedFilters = AntDesignTable.processFilters(filters);
    const processedSorts = AntDesignTable.processSorts(sorter);

    resetTimestamp(pagination.current, processedSorts, processedFilters, pagination.pageSize);
  }

  useShowWorkspace() {
    const { setWorkspaceVisible } = this.props;

    setWorkspaceVisible(true);
  }

  render() {
    const {
      intl,
      resetTimestamp,
      currentPage,
      pageSize,
      total,
      documents,
      fetchIsLoading,
      selectedDocuments,
      addSelectedDocuments,
      removeSelectedDocuments
    } = this.props;

    return (
      <Card
        title={intl.formatMessage({ id: 'production_sheets' })}
        bordered={false}
        headStyle={{ textAlign: 'center' }}
        bodyStyle={{ padding: 0 }}
      >
        <Table
          size="small"
          // rowSelection={rowSelection}
          rowKey="id"
          pagination={{
            current: currentPage,
            pageSize: parseInt(pageSize, 10),
            total,
            showTotal: () => `${total} items`
          }}
          columns={this.getDocumentColumns()}
          dataSource={documents}
          loading={fetchIsLoading}
          bordered
          rowSelection={{
            selectedRowKeys: selectedDocuments.map(value => value.id),
            onChange: () => {
              // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            },
            onSelect: (record, selected) => {
              // console.log(`onSelect record: `, record, 'selected: ', selected, 'selectedRows: ', selectedRows);
              if (selected === true) {
                addSelectedDocuments([record]);
              } else {
                removeSelectedDocuments([record]);
              }
            },
            onSelectAll: (selected, selectedRows, changeRows) => {
              // console.log('onSelectAll selected: ', selected, 'selectedRows: ', selectedRows, 'changeRows: ', changeRows);
              if (selected === true) {
                addSelectedDocuments(changeRows);
              } else {
                removeSelectedDocuments(changeRows);
              }
            },
            onSelectInvert: selectedRows => {
              // console.log('onSelectInvert selectedRows: ', selectedRows);
              removeSelectedDocuments(selectedRows);
            },
            getCheckboxProps: () => ({
              // Column configuration not to be checked
              // disabled: record.str_doc_status !== 'COMPLETE'
              // name: record.name,
            })
          }}
          onChange={this.useOnTableChange}
          title={() => (
            <>
              <Row type="flex" justify="start" gutter={[0, 16]}>
                <Col span={1}>
                  <Button
                    type="primary"
                    // disabled={!isValid}
                    loading={fetchIsLoading}
                    onClick={() => resetTimestamp()}
                    icon={<ReloadOutlined />}
                  />
                </Col>
              </Row>
            </>
          )}
          scroll={{ x: 950 }}
        />

        <div
          style={{
            position: 'fixed',
            bottom: '50px',
            right: '50px',
            zIndex: 9999
          }}
        >
          <Badge count={selectedDocuments.length}>
            <Button
              size="large"
              type="primary"
              shape="circle"
              icon={<LaptopOutlined />}
              onClick={this.useShowWorkspace}
              loading={fetchIsLoading}
            />
          </Badge>
        </div>

        <ProdSheetIndexDialog />
      </Card>
    );
  }
}

ProdSheetIndexTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  resetTimestamp: PropTypes.func,
  goToDocument: PropTypes.func,
  goToAudit: PropTypes.func,

  setWorkspaceVisible: PropTypes.func,

  addSelectedDocuments: PropTypes.func,
  removeSelectedDocuments: PropTypes.func,
  documents: PropTypes.arrayOf(PropTypes.object),
  selectedDocuments: PropTypes.arrayOf(PropTypes.object),

  sorts: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  filters: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),

  currentPage: PropTypes.number,
  pageSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  total: PropTypes.number,
  fetchIsLoading: PropTypes.bool,

  curDivisionId: PropTypes.number,
  fetchProdSheetIndex: PropTypes.func
};

ProdSheetIndexTable.defaultProps = {
  intl: {},
  resetTimestamp() {},
  goToDocument() {},
  goToAudit() {},

  setWorkspaceVisible() {},

  addSelectedDocuments() {},
  removeSelectedDocuments() {},
  documents: [],
  selectedDocuments: [],

  sorts: {},
  filters: {},

  currentPage: 1,
  pageSize: '20',
  total: 0,
  fetchIsLoading: false,

  curDivisionId: 0,
  fetchProdSheetIndex() {}
};

const mapStateToProps = state => ({
  timestamp: state.prodSheetIndex.timestamp,
  workspaceIsVisible: state.prodSheetIndex.workspaceIsVisible,

  documents: state.prodSheetIndex.documents,
  selectedDocuments: state.prodSheetIndex.selectedDocuments,

  sorts: state.prodSheetIndex.sorts,
  filters: state.prodSheetIndex.filters,

  currentPage: state.prodSheetIndex.currentPage,
  pageSize: state.prodSheetIndex.pageSize,
  total: state.prodSheetIndex.total,
  fetchIsLoading: state.prodSheetIndex.fetchIsLoading,

  curDivisionId: state.app.curDivisionId
});

const mapDispatchToProps = dispatch => ({
  resetTimestamp: (currentPage, sorts, filters, pageSize) =>
    dispatch(
      ProdSheetIndexActions.prodSheetIndexResetTimestamp(currentPage, sorts, filters, pageSize)
    ),
  goToDocument: hdrId => dispatch(ProdSheetIndexActions.prodSheetIndexGoToDocument(hdrId)),
  goToAudit: hdrId => dispatch(ProdSheetIndexActions.prodSheetIndexGoToAudit(hdrId)),

  setWorkspaceVisible: boolean =>
    dispatch(ProdSheetIndexActions.prodSheetIndexSetWorkspaceVisible(boolean)),

  addSelectedDocuments: selectedDocuments =>
    dispatch(ProdSheetIndexActions.prodSheetIndexAddSelectedDocuments(selectedDocuments)),
  removeSelectedDocuments: selectedDocuments =>
    dispatch(ProdSheetIndexActions.prodSheetIndexRemoveSelectedDocuments(selectedDocuments)),

  fetchProdSheetIndex: (currentPage, sorts, filters, pageSize) =>
    dispatch(
      ProdSheetIndexActions.prodSheetIndexFetchProdSheetIndex(currentPage, sorts, filters, pageSize)
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ProdSheetIndexTable));
