/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { TransferInTrackingReportTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const transferInTrackingReportReportLoading = (state, { boolean }) => ({
  ...state,
  reportIsLoading: boolean
});

export const transferInTrackingReportInitTransferInTrackingSuccess = (state, { criteria }) => ({
  ...state,
  criteria
});

export const transferInTrackingReportTransferInTrackingSuccess = (
  state,
  { criteria, reportData }
) => ({
  ...state,
  criteria,
  reportData
});

export const transferInTrackingReportFetchDivisionOptionLoading = (state, { boolean }) => ({
  ...state,
  divisionIsLoading: boolean
});

export const transferInTrackingReportFetchDivisionOptionSuccess = (state, { options }) => ({
  ...state,
  divisionOptions: options
});

export const transferInTrackingReportFetchFrLocationOptionLoading = (state, { boolean }) => ({
  ...state,
  frLocationIsLoading: boolean
});

export const transferInTrackingReportFetchFrLocationOptionSuccess = (state, { options }) => ({
  ...state,
  frLocationOptions: options
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [TransferInTrackingReportTypes.TRANSFER_IN_TRACKING_REPORT_REPORT_LOADING]: transferInTrackingReportReportLoading,
  [TransferInTrackingReportTypes.TRANSFER_IN_TRACKING_REPORT_INIT_TRANSFER_IN_TRACKING_SUCCESS]: transferInTrackingReportInitTransferInTrackingSuccess,
  [TransferInTrackingReportTypes.TRANSFER_IN_TRACKING_REPORT_TRANSFER_IN_TRACKING_SUCCESS]: transferInTrackingReportTransferInTrackingSuccess,

  [TransferInTrackingReportTypes.TRANSFER_IN_TRACKING_REPORT_FETCH_DIVISION_OPTION_LOADING]: transferInTrackingReportFetchDivisionOptionLoading,
  [TransferInTrackingReportTypes.TRANSFER_IN_TRACKING_REPORT_FETCH_DIVISION_OPTION_SUCCESS]: transferInTrackingReportFetchDivisionOptionSuccess,

  [TransferInTrackingReportTypes.TRANSFER_IN_TRACKING_REPORT_FETCH_FR_LOCATION_OPTION_LOADING]: transferInTrackingReportFetchFrLocationOptionLoading,
  [TransferInTrackingReportTypes.TRANSFER_IN_TRACKING_REPORT_FETCH_FR_LOCATION_OPTION_SUCCESS]: transferInTrackingReportFetchFrLocationOptionSuccess
});
