/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { StockBalanceReportTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const stockBalanceReportReportLoading = (state, { boolean }) => ({
  ...state,
  reportIsLoading: boolean
});

export const stockBalanceReportInitStockBalanceSuccess = (state, { criteria }) => ({
  ...state,
  criteria
});

export const stockBalanceReportStockBalanceSuccess = (state, { criteria, reportData }) => ({
  ...state,
  criteria,
  reportData
});

export const stockBalanceReportFetchCompanyOptionLoading = (state, { boolean }) => ({
  ...state,
  companyIsLoading: boolean
});

export const stockBalanceReportFetchCompanyOptionSuccess = (state, { options }) => ({
  ...state,
  companyOptions: options
});

export const stockBalanceReportFetchItemOptionLoading = (state, { boolean }) => ({
  ...state,
  itemIsLoading: boolean
});

export const stockBalanceReportFetchItemOptionSuccess = (state, { options }) => ({
  ...state,
  itemOptions: options
});

export const stockBalanceReportFetchItemGroup01OptionLoading = (state, { boolean }) => ({
  ...state,
  itemGroup01IsLoading: boolean
});

export const stockBalanceReportFetchItemGroup01OptionSuccess = (state, { options }) => ({
  ...state,
  itemGroup01Options: options
});

export const stockBalanceReportFetchItemGroup02OptionLoading = (state, { boolean }) => ({
  ...state,
  itemGroup02IsLoading: boolean
});

export const stockBalanceReportFetchItemGroup02OptionSuccess = (state, { options }) => ({
  ...state,
  itemGroup02Options: options
});

export const stockBalanceReportFetchItemGroup03OptionLoading = (state, { boolean }) => ({
  ...state,
  itemGroup03IsLoading: boolean
});

export const stockBalanceReportFetchItemGroup03OptionSuccess = (state, { options }) => ({
  ...state,
  itemGroup03Options: options
});

export const stockBalanceReportFetchStorageBinOptionLoading = (state, { boolean }) => ({
  ...state,
  storageBinIsLoading: boolean
});

export const stockBalanceReportFetchStorageBinOptionSuccess = (state, { options }) => ({
  ...state,
  storageBinOptions: options
});

export const stockBalanceReportFetchStorageRowOptionLoading = (state, { boolean }) => ({
  ...state,
  storageRowIsLoading: boolean
});

export const stockBalanceReportFetchStorageRowOptionSuccess = (state, { options }) => ({
  ...state,
  storageRowOptions: options
});

export const stockBalanceReportFetchStorageBayOptionLoading = (state, { boolean }) => ({
  ...state,
  storageBayIsLoading: boolean
});

export const stockBalanceReportFetchStorageBayOptionSuccess = (state, { options }) => ({
  ...state,
  storageBayOptions: options
});

export const stockBalanceReportFetchLocationOptionLoading = (state, { boolean }) => ({
  ...state,
  locationIsLoading: boolean
});

export const stockBalanceReportFetchLocationOptionSuccess = (state, { options }) => ({
  ...state,
  locationOptions: options
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [StockBalanceReportTypes.STOCK_BALANCE_REPORT_REPORT_LOADING]: stockBalanceReportReportLoading,
  [StockBalanceReportTypes.STOCK_BALANCE_REPORT_INIT_STOCK_BALANCE_SUCCESS]: stockBalanceReportInitStockBalanceSuccess,
  [StockBalanceReportTypes.STOCK_BALANCE_REPORT_STOCK_BALANCE_SUCCESS]: stockBalanceReportStockBalanceSuccess,

  [StockBalanceReportTypes.STOCK_BALANCE_REPORT_FETCH_COMPANY_OPTION_LOADING]: stockBalanceReportFetchCompanyOptionLoading,
  [StockBalanceReportTypes.STOCK_BALANCE_REPORT_FETCH_COMPANY_OPTION_SUCCESS]: stockBalanceReportFetchCompanyOptionSuccess,

  [StockBalanceReportTypes.STOCK_BALANCE_REPORT_FETCH_ITEM_OPTION_LOADING]: stockBalanceReportFetchItemOptionLoading,
  [StockBalanceReportTypes.STOCK_BALANCE_REPORT_FETCH_ITEM_OPTION_SUCCESS]: stockBalanceReportFetchItemOptionSuccess,

  [StockBalanceReportTypes.STOCK_BALANCE_REPORT_FETCH_ITEM_GROUP01_OPTION_LOADING]: stockBalanceReportFetchItemGroup01OptionLoading,
  [StockBalanceReportTypes.STOCK_BALANCE_REPORT_FETCH_ITEM_GROUP01_OPTION_SUCCESS]: stockBalanceReportFetchItemGroup01OptionSuccess,

  [StockBalanceReportTypes.STOCK_BALANCE_REPORT_FETCH_ITEM_GROUP02_OPTION_LOADING]: stockBalanceReportFetchItemGroup02OptionLoading,
  [StockBalanceReportTypes.STOCK_BALANCE_REPORT_FETCH_ITEM_GROUP02_OPTION_SUCCESS]: stockBalanceReportFetchItemGroup02OptionSuccess,

  [StockBalanceReportTypes.STOCK_BALANCE_REPORT_FETCH_ITEM_GROUP03_OPTION_LOADING]: stockBalanceReportFetchItemGroup03OptionLoading,
  [StockBalanceReportTypes.STOCK_BALANCE_REPORT_FETCH_ITEM_GROUP03_OPTION_SUCCESS]: stockBalanceReportFetchItemGroup03OptionSuccess,

  [StockBalanceReportTypes.STOCK_BALANCE_REPORT_FETCH_STORAGE_BIN_OPTION_LOADING]: stockBalanceReportFetchStorageBinOptionLoading,
  [StockBalanceReportTypes.STOCK_BALANCE_REPORT_FETCH_STORAGE_BIN_OPTION_SUCCESS]: stockBalanceReportFetchStorageBinOptionSuccess,

  [StockBalanceReportTypes.STOCK_BALANCE_REPORT_FETCH_STORAGE_ROW_OPTION_LOADING]: stockBalanceReportFetchStorageRowOptionLoading,
  [StockBalanceReportTypes.STOCK_BALANCE_REPORT_FETCH_STORAGE_ROW_OPTION_SUCCESS]: stockBalanceReportFetchStorageRowOptionSuccess,

  [StockBalanceReportTypes.STOCK_BALANCE_REPORT_FETCH_STORAGE_BAY_OPTION_LOADING]: stockBalanceReportFetchStorageBayOptionLoading,
  [StockBalanceReportTypes.STOCK_BALANCE_REPORT_FETCH_STORAGE_BAY_OPTION_SUCCESS]: stockBalanceReportFetchStorageBayOptionSuccess,

  [StockBalanceReportTypes.STOCK_BALANCE_REPORT_FETCH_LOCATION_OPTION_LOADING]: stockBalanceReportFetchLocationOptionLoading,
  [StockBalanceReportTypes.STOCK_BALANCE_REPORT_FETCH_LOCATION_OPTION_SUCCESS]: stockBalanceReportFetchLocationOptionSuccess
});
