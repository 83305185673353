/**
 * The initial values for the redux state.
 */
export default {
  procType: 'FINISHED_ITEMS',
  batchJobStatus: {
    proc_type: 0,
    user_id: 0,
    status_number: 0
  },
  uploadIsLoading: false
};
