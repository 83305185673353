import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  purRtnIndexResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  purRtnIndexGoToDocument: ['hdrId'],
  purRtnIndexGoToAudit: ['hdrId'],
  purRtnIndexFetchPurRtnIndex: ['currentPage', 'sorts', 'filters', 'pageSize'],
  purRtnIndexFetchPurRtnIndexLoading: ['boolean'],
  purRtnIndexFetchPurRtnIndexSuccess: ['documents', 'currentPage', 'lastPage', 'total', 'pageSize'],
  purRtnIndexAddSelectedDocuments: ['selectedDocuments'],
  purRtnIndexRemoveSelectedDocuments: ['selectedDocuments'],
  purRtnIndexSetWorkspaceVisible: ['boolean'],
  purRtnIndexCreatePurRtnIndex: ['hdrIds'],
  purRtnIndexCreatePurRtnIndexLoading: ['boolean'],
  purRtnIndexCreatePurRtnIndexSuccess: ['newDocuments'],

  purRtnIndexWorkspaceLoading: ['boolean'],
  purRtnIndexResetPurRtnFServ02: ['hdrIds'],
  purRtnIndexResetPurRtnFServ02Success: null
});

export const PurRtnIndexTypes = Types;
export default Creators;
