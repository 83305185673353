import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  siteFlowDetailResetModelTimestamp: null,
  siteFlowDetailSetResId: ['resId'],

  siteFlowDetailInitModel: null,
  siteFlowDetailShowModel: ['resId'],
  siteFlowDetailShowModelSuccess: ['model'],

  siteFlowDetailUpdateModel: ['formikBag', 'model'],
  siteFlowDetailCreateModel: ['formikBag', 'model'],
  siteFlowDetailUpdateModelSuccess: ['model'],

  siteFlowDetailShowModelLoading: ['boolean'],

  siteFlowDetailFetchSiteOptions: ['search'],
  siteFlowDetailFetchSiteOptionLoading: ['boolean'],
  siteFlowDetailFetchSiteOptionSuccess: ['options'],

  siteFlowDetailResetWhseTxnFlowTimestamp: null,
  siteFlowDetailShowWhseTxnFlows: ['resId'],
  siteFlowDetailShowWhseTxnFlowsLoading: ['boolean'],
  siteFlowDetailShowWhseTxnFlowsSuccess: ['txnFlows'],

  siteFlowDetailWhseTxnFlowsUploadExcel: ['resId', 'file'],
  siteFlowDetailWhseTxnFlowsUploadLoading: ['boolean'],
  siteFlowDetailWhseTxnFlowsDownloadExcel: ['resId'],

  siteFlowDetailResetPrintDocSettingTimestamp: null,
  siteFlowDetailShowPrintDocSettings: ['resId'],
  siteFlowDetailShowPrintDocSettingsLoading: ['boolean'],
  siteFlowDetailShowPrintDocSettingsSuccess: ['settings'],

  siteFlowDetailPrintDocSettingsUploadExcel: ['resId', 'file'],
  siteFlowDetailPrintDocSettingsUploadLoading: ['boolean'],
  siteFlowDetailPrintDocSettingsDownloadExcel: ['resId']
});

export const SiteFlowDetailTypes = Types;
export default Creators;
