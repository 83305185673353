/**
 * The initial values for the redux state.
 */
export default {
  timestamp: 0,
  documents: [],
  fetchIsLoading: false,
  sorts: {
    code: 'ascend'
  },
  criteria: {
    company_ids_select2: [],
    item_ids_select2: [],
    item_group_01_ids_select2: [],
    item_group_02_ids_select2: [],
    item_group_03_ids_select2: []
  },
  pageSize: '20',
  currentPage: 1,
  lastPage: 10,
  total: 0,
  selectedDocuments: [],
  workspaceIsVisible: false,
  createIsLoading: false,
  newDocuments: [],
  calcDates: {
    month01: '1970-01-01 00:00:00',
    month02: '1970-01-01 00:00:00',
    month03: '1970-01-01 00:00:00',
    month04: '1970-01-01 00:00:00',
    month05: '1970-01-01 00:00:00',
    month06: '1970-01-01 00:00:00',
    balanceQueryAt: '1970-01-01 00:00:00'
  },

  companyOptions: [],
  companyIsLoading: false,

  itemOptions: [],
  itemIsLoading: false,

  itemGroup01Options: [],
  itemGroup01IsLoading: false,

  itemGroup02Options: [],
  itemGroup02IsLoading: false,

  itemGroup03Options: [],
  itemGroup03IsLoading: false,

  batchJobIsLoading: 0,
  batchJobStatus: {
    proc_type: 0,
    user_id: 0,
    status_number: 0
  }
};
