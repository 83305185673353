/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { CreditorDetailTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const creditorDetailResetModelTimestamp = state => ({
  ...state,
  modelTimestamp: Date.now()
});

export const creditorDetailSetResId = (state, { resId }) => ({
  ...state,
  modelTimestamp: Date.now(),
  resId
});

export const creditorDetailShowModelLoading = (state, { boolean }) => ({
  ...state,
  modelIsLoading: boolean
});

export const creditorDetailShowModelSuccess = (state, { model }) => ({
  ...state,
  model
});

export const creditorDetailUpdateModelSuccess = (state, { model }) => ({
  ...state,
  model
});

export const creditorDetailShowBizPartnerSuccess = (
  state,
  { bizPartners, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  bizPartners,
  bizPartnerCurrentPage: currentPage,
  bizPartnerLastPage: lastPage,
  bizPartnerTotal: total,
  bizPartnerPageSize: pageSize
});

export const creditorDetailResetBizPartnerTimestamp = (
  state,
  { currentPage, sorts, filters, pageSize }
) => ({
  ...state,
  bizPartnerCurrentPage: currentPage || state.bizPartnerCurrentPage,
  bizPartnerSorts: sorts || state.bizPartnerSorts,
  bizPartnerFilters: filters || state.bizPartnerFilters,
  bizPartnerPageSize: pageSize || state.bizPartnerPageSize,
  bizPartnerTimestamp: Date.now()
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [CreditorDetailTypes.CREDITOR_DETAIL_RESET_MODEL_TIMESTAMP]: creditorDetailResetModelTimestamp,
  [CreditorDetailTypes.CREDITOR_DETAIL_SET_RES_ID]: creditorDetailSetResId,
  [CreditorDetailTypes.CREDITOR_DETAIL_SHOW_MODEL_LOADING]: creditorDetailShowModelLoading,
  [CreditorDetailTypes.CREDITOR_DETAIL_SHOW_MODEL_SUCCESS]: creditorDetailShowModelSuccess,
  [CreditorDetailTypes.CREDITOR_DETAIL_UPDATE_MODEL_SUCCESS]: creditorDetailUpdateModelSuccess,

  [CreditorDetailTypes.CREDITOR_DETAIL_RESET_BIZ_PARTNER_TIMESTAMP]: creditorDetailResetBizPartnerTimestamp,
  [CreditorDetailTypes.CREDITOR_DETAIL_SHOW_BIZ_PARTNER_SUCCESS]: creditorDetailShowBizPartnerSuccess
});
