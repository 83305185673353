/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { PickingAllocErrorReportTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const pickingAllocErrorReportReportLoading = (state, { boolean }) => ({
  ...state,
  reportIsLoading: boolean
});

export const pickingAllocErrorReportInitPickingAllocErrorSuccess = (state, { criteria }) => ({
  ...state,
  criteria
});

export const pickingAllocErrorReportPickingAllocErrorSuccess = (
  state,
  { criteria, reportData }
) => ({
  ...state,
  criteria,
  reportData
});

export const pickingAllocErrorReportFetchItemOptionLoading = (state, { boolean }) => ({
  ...state,
  itemIsLoading: boolean
});

export const pickingAllocErrorReportFetchItemOptionSuccess = (state, { options }) => ({
  ...state,
  itemOptions: options
});

export const pickingAllocErrorReportFetchItemGroup01OptionLoading = (state, { boolean }) => ({
  ...state,
  itemGroup01IsLoading: boolean
});

export const pickingAllocErrorReportFetchItemGroup01OptionSuccess = (state, { options }) => ({
  ...state,
  itemGroup01Options: options
});

export const pickingAllocErrorReportFetchItemGroup02OptionLoading = (state, { boolean }) => ({
  ...state,
  itemGroup02IsLoading: boolean
});

export const pickingAllocErrorReportFetchItemGroup02OptionSuccess = (state, { options }) => ({
  ...state,
  itemGroup02Options: options
});

export const pickingAllocErrorReportFetchItemGroup03OptionLoading = (state, { boolean }) => ({
  ...state,
  itemGroup03IsLoading: boolean
});

export const pickingAllocErrorReportFetchItemGroup03OptionSuccess = (state, { options }) => ({
  ...state,
  itemGroup03Options: options
});

export const pickingAllocErrorReportFetchStorageBinOptionLoading = (state, { boolean }) => ({
  ...state,
  storageBinIsLoading: boolean
});

export const pickingAllocErrorReportFetchStorageBinOptionSuccess = (state, { options }) => ({
  ...state,
  storageBinOptions: options
});

export const pickingAllocErrorReportFetchStorageRowOptionLoading = (state, { boolean }) => ({
  ...state,
  storageRowIsLoading: boolean
});

export const pickingAllocErrorReportFetchStorageRowOptionSuccess = (state, { options }) => ({
  ...state,
  storageRowOptions: options
});

export const pickingAllocErrorReportFetchStorageBayOptionLoading = (state, { boolean }) => ({
  ...state,
  storageBayIsLoading: boolean
});

export const pickingAllocErrorReportFetchStorageBayOptionSuccess = (state, { options }) => ({
  ...state,
  storageBayOptions: options
});

export const pickingAllocErrorReportFetchLocationOptionLoading = (state, { boolean }) => ({
  ...state,
  locationIsLoading: boolean
});

export const pickingAllocErrorReportFetchLocationOptionSuccess = (state, { options }) => ({
  ...state,
  locationOptions: options
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [PickingAllocErrorReportTypes.PICKING_ALLOC_ERROR_REPORT_REPORT_LOADING]: pickingAllocErrorReportReportLoading,
  [PickingAllocErrorReportTypes.PICKING_ALLOC_ERROR_REPORT_INIT_PICKING_ALLOC_ERROR_SUCCESS]: pickingAllocErrorReportInitPickingAllocErrorSuccess,
  [PickingAllocErrorReportTypes.PICKING_ALLOC_ERROR_REPORT_PICKING_ALLOC_ERROR_SUCCESS]: pickingAllocErrorReportPickingAllocErrorSuccess,

  [PickingAllocErrorReportTypes.PICKING_ALLOC_ERROR_REPORT_FETCH_ITEM_OPTION_LOADING]: pickingAllocErrorReportFetchItemOptionLoading,
  [PickingAllocErrorReportTypes.PICKING_ALLOC_ERROR_REPORT_FETCH_ITEM_OPTION_SUCCESS]: pickingAllocErrorReportFetchItemOptionSuccess,

  [PickingAllocErrorReportTypes.PICKING_ALLOC_ERROR_REPORT_FETCH_ITEM_GROUP01_OPTION_LOADING]: pickingAllocErrorReportFetchItemGroup01OptionLoading,
  [PickingAllocErrorReportTypes.PICKING_ALLOC_ERROR_REPORT_FETCH_ITEM_GROUP01_OPTION_SUCCESS]: pickingAllocErrorReportFetchItemGroup01OptionSuccess,

  [PickingAllocErrorReportTypes.PICKING_ALLOC_ERROR_REPORT_FETCH_ITEM_GROUP02_OPTION_LOADING]: pickingAllocErrorReportFetchItemGroup02OptionLoading,
  [PickingAllocErrorReportTypes.PICKING_ALLOC_ERROR_REPORT_FETCH_ITEM_GROUP02_OPTION_SUCCESS]: pickingAllocErrorReportFetchItemGroup02OptionSuccess,

  [PickingAllocErrorReportTypes.PICKING_ALLOC_ERROR_REPORT_FETCH_ITEM_GROUP03_OPTION_LOADING]: pickingAllocErrorReportFetchItemGroup03OptionLoading,
  [PickingAllocErrorReportTypes.PICKING_ALLOC_ERROR_REPORT_FETCH_ITEM_GROUP03_OPTION_SUCCESS]: pickingAllocErrorReportFetchItemGroup03OptionSuccess,

  [PickingAllocErrorReportTypes.PICKING_ALLOC_ERROR_REPORT_FETCH_STORAGE_BIN_OPTION_LOADING]: pickingAllocErrorReportFetchStorageBinOptionLoading,
  [PickingAllocErrorReportTypes.PICKING_ALLOC_ERROR_REPORT_FETCH_STORAGE_BIN_OPTION_SUCCESS]: pickingAllocErrorReportFetchStorageBinOptionSuccess,

  [PickingAllocErrorReportTypes.PICKING_ALLOC_ERROR_REPORT_FETCH_STORAGE_ROW_OPTION_LOADING]: pickingAllocErrorReportFetchStorageRowOptionLoading,
  [PickingAllocErrorReportTypes.PICKING_ALLOC_ERROR_REPORT_FETCH_STORAGE_ROW_OPTION_SUCCESS]: pickingAllocErrorReportFetchStorageRowOptionSuccess,

  [PickingAllocErrorReportTypes.PICKING_ALLOC_ERROR_REPORT_FETCH_STORAGE_BAY_OPTION_LOADING]: pickingAllocErrorReportFetchStorageBayOptionLoading,
  [PickingAllocErrorReportTypes.PICKING_ALLOC_ERROR_REPORT_FETCH_STORAGE_BAY_OPTION_SUCCESS]: pickingAllocErrorReportFetchStorageBayOptionSuccess,

  [PickingAllocErrorReportTypes.PICKING_ALLOC_ERROR_REPORT_FETCH_LOCATION_OPTION_LOADING]: pickingAllocErrorReportFetchLocationOptionLoading,
  [PickingAllocErrorReportTypes.PICKING_ALLOC_ERROR_REPORT_FETCH_LOCATION_OPTION_SUCCESS]: pickingAllocErrorReportFetchLocationOptionSuccess
});
