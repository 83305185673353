import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router-dom';

import { Card, Col, Row, Image } from 'antd';

const { Meta } = Card;

const procCardStyle = {
  width: 100,
  textAlign: 'center'
};

class HomeScreen extends React.PureComponent {
  componentDidMount() {}

  componentDidUpdate() {}

  componentWillUnmount() {}

  render() {
    const { intl, appPath } = this.props;

    return (
      <div className="site-card-wrapper">
        <Row gutter={[10, 10]}>
          <Col span={16}>
            <Row gutter={[10, 10]}>
              <Col span={12}>
                <Card title={intl.formatMessage({ id: 'sales' })} bordered={false}>
                  <Row gutter={10}>
                    <Col span={12}>
                      <Link to={`${appPath}/salesProcess/index/OUTB_ORD_01`}>
                        <Card
                          style={procCardStyle}
                          hoverable
                          size="small"
                          cover={<Image src="./img/sales.png" preview={false} />}
                          bordered={false}
                        >
                          <Meta description={intl.formatMessage({ id: 'sales_and_billing' })} />
                        </Card>
                      </Link>
                    </Col>
                    <Col span={12}>
                      <Link to={`${appPath}/slsRtnProcess/index/INB_ORD_02`}>
                        <Card
                          style={procCardStyle}
                          hoverable
                          size="small"
                          cover={<Image src="./img/sales_return.png" preview={false} />}
                          bordered={false}
                        >
                          <Meta description={intl.formatMessage({ id: 'sales_return' })} />
                        </Card>
                      </Link>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col span={12}>
                <Card title={intl.formatMessage({ id: 'purchase' })} bordered={false}>
                  <Row gutter={10}>
                    <Col span={12}>
                      <Link to={`${appPath}/purchaseProcess/index/INB_ORD_01`}>
                        <Card
                          style={procCardStyle}
                          hoverable
                          size="small"
                          cover={<Image src="./img/purchase.png" preview={false} />}
                          bordered={false}
                        >
                          <Meta description={intl.formatMessage({ id: 'purchase' })} />
                        </Card>
                      </Link>
                    </Col>
                    <Col span={12}>
                      <Link to={`${appPath}/purchaseReturnProcess/index/OUTB_ORD_08`}>
                        <Card
                          style={procCardStyle}
                          hoverable
                          size="small"
                          cover={<Image src="./img/purchase_return.png" preview={false} />}
                          bordered={false}
                        >
                          <Meta description={intl.formatMessage({ id: 'purchase_return' })} />
                        </Card>
                      </Link>
                    </Col>
                  </Row>
                </Card>
              </Col>

              <Col span={24}>
                <Card title={intl.formatMessage({ id: 'logistic_service' })} bordered={false}>
                  <Row gutter={10}>
                    <Col span={6}>
                      <Link to={`${appPath}/lspDeliveryProcess/index/OUTB_ORD_02`}>
                        <Card
                          style={procCardStyle}
                          hoverable
                          size="small"
                          cover={<Image src="./img/logistic_delivery.png" preview={false} />}
                          bordered={false}
                        >
                          <Meta description={intl.formatMessage({ id: 'delivery' })} />
                        </Card>
                      </Link>
                    </Col>
                    <Col span={6}>
                      <Link to={`${appPath}/lspCustomerReturnProcess/index/INB_ORD_04`}>
                        <Card
                          style={procCardStyle}
                          hoverable
                          size="small"
                          cover={<Image src="./img/logistic_customer_return.png" preview={false} />}
                          bordered={false}
                        >
                          <Meta description={intl.formatMessage({ id: 'customer_return' })} />
                        </Card>
                      </Link>
                    </Col>
                    <Col span={6}>
                      <Link to={`${appPath}/lspReceivingProcess/index/INB_ORD_03`}>
                        <Card
                          style={procCardStyle}
                          hoverable
                          size="small"
                          cover={<Image src="./img/logistic_receiving.png" preview={false} />}
                          bordered={false}
                        >
                          <Meta description={intl.formatMessage({ id: 'receiving' })} />
                        </Card>
                      </Link>
                    </Col>
                    <Col span={6}>
                      <Link to={`${appPath}/lspSupplierReturnProcess/index/OUTB_ORD_03`}>
                        <Card
                          style={procCardStyle}
                          hoverable
                          size="small"
                          cover={<Image src="./img/logistic_supplier_return.png" preview={false} />}
                          bordered={false}
                        >
                          <Meta description={intl.formatMessage({ id: 'supplier_return' })} />
                        </Card>
                      </Link>
                    </Col>
                  </Row>
                </Card>
              </Col>

              <Col span={24}>
                <Card title={intl.formatMessage({ id: 'stock_transfer' })} bordered={false}>
                  <Row gutter={10}>
                    <Col span={4.8}>
                      <Link to={`${appPath}/siteTrfProcess/index/OUTB_ORD_05`}>
                        <Card
                          style={procCardStyle}
                          hoverable
                          size="small"
                          cover={<Image src="./img/site_transfer.png" preview={false} />}
                          bordered={false}
                        >
                          <Meta description={intl.formatMessage({ id: 'site_transfer' })} />
                        </Card>
                      </Link>
                    </Col>
                    <Col span={4.8}>
                      <Link to={`${appPath}/trfToStoreProcess/index/OUTB_ORD_06`}>
                        <Card
                          style={procCardStyle}
                          hoverable
                          size="small"
                          cover={<Image src="./img/transfer_to_store.png" preview={false} />}
                          bordered={false}
                        >
                          <Meta description={intl.formatMessage({ id: 'transfer_to_store' })} />
                        </Card>
                      </Link>
                    </Col>
                    <Col span={4.8}>
                      <Link to={`${appPath}/trfFromStoreProcess/index/INB_ORD_06`}>
                        <Card
                          style={procCardStyle}
                          hoverable
                          size="small"
                          cover={<Image src="./img/transfer_from_store.png" preview={false} />}
                          bordered={false}
                        >
                          <Meta description={intl.formatMessage({ id: 'transfer_from_store' })} />
                        </Card>
                      </Link>
                    </Col>
                    <Col span={4.8}>
                      <Link to={`${appPath}/trfToVanProcess/index/OUTB_ORD_07`}>
                        <Card
                          style={procCardStyle}
                          hoverable
                          size="small"
                          cover={<Image src="./img/transfer_to_van.png" preview={false} />}
                          bordered={false}
                        >
                          <Meta description={intl.formatMessage({ id: 'transfer_to_van' })} />
                        </Card>
                      </Link>
                    </Col>
                    <Col span={4.8}>
                      <Link to={`${appPath}/trfFromVanProcess/index/INB_ORD_07`}>
                        <Card
                          style={procCardStyle}
                          hoverable
                          size="small"
                          cover={<Image src="./img/transfer_from_van.png" preview={false} />}
                          bordered={false}
                        >
                          <Meta description={intl.formatMessage({ id: 'transfer_from_van' })} />
                        </Card>
                      </Link>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col span={8}>
            <Card title={intl.formatMessage({ id: 'outbound' })} bordered={false}>
              <Row gutter={10}>
                <Col span={12}>
                  <Link to={`${appPath}/gdsDelProcess/index/PICK_LIST_01`}>
                    <Card
                      style={procCardStyle}
                      hoverable
                      size="small"
                      cover={<Image src="./img/outb_goods_delivery.png" preview={false} />}
                      bordered={false}
                    >
                      <Meta description={intl.formatMessage({ id: 'goods_delivery' })} />
                    </Card>
                  </Link>
                </Col>
                <Col span={12}>
                  <Link to={`${appPath}/rtnDelProcess/index/GDS_ISS_01`}>
                    <Card
                      style={procCardStyle}
                      hoverable
                      size="small"
                      cover={<Image src="./img/outb_return_delivery.png" preview={false} />}
                      bordered={false}
                    >
                      <Meta description={intl.formatMessage({ id: 'return_delivery' })} />
                    </Card>
                  </Link>
                </Col>
              </Row>
            </Card>
            <Card
              title={intl.formatMessage({ id: 'inbound' })}
              bordered={false}
              style={{ marginTop: 10 }}
            >
              <Row gutter={10}>
                <Col span={12}>
                  <Link to={`${appPath}/gdsRcptProcess/index/GDS_RCPT_01`}>
                    <Card
                      style={procCardStyle}
                      hoverable
                      size="small"
                      cover={<Image src="./img/inb_goods_receipt.png" preview={false} />}
                      bordered={false}
                    >
                      <Meta description={intl.formatMessage({ id: 'goods_receipt' })} />
                    </Card>
                  </Link>
                </Col>
                <Col span={12}>
                  <Link to={`${appPath}/rtnRcptProcess/index/GDS_RCPT_02`}>
                    <Card
                      style={procCardStyle}
                      hoverable
                      size="small"
                      cover={<Image src="./img/inb_return_receipt.png" preview={false} />}
                      bordered={false}
                    >
                      <Meta description={intl.formatMessage({ id: 'return_receipt' })} />
                    </Card>
                  </Link>
                </Col>
              </Row>
            </Card>
            <Card
              title={intl.formatMessage({ id: 'internal_movement' })}
              bordered={false}
              style={{ marginTop: 10 }}
            >
              <Row gutter={10}>
                <Col span={12}>
                  <Link to={`${appPath}/binTrfProcess/index/WHSE_JOB_17_02`}>
                    <Card
                      style={procCardStyle}
                      hoverable
                      size="small"
                      cover={<Image src="./img/bin_transfer.png" preview={false} />}
                      bordered={false}
                    >
                      <Meta description={intl.formatMessage({ id: 'bin_transfer' })} />
                    </Card>
                  </Link>
                </Col>
                <Col span={12}>
                  <Link to={`${appPath}/invAuditProcess/index/WHSE_JOB_16_01`}>
                    <Card
                      style={procCardStyle}
                      hoverable
                      size="small"
                      cover={<Image src="./img/inventory_audit.png" preview={false} />}
                      bordered={false}
                    >
                      <Meta description={intl.formatMessage({ id: 'inventory_audit' })} />
                    </Card>
                  </Link>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

HomeScreen.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  appPath: PropTypes.string
};

HomeScreen.defaultProps = {
  intl: {},
  appPath: ''
};

const mapStateToProps = state => ({
  appPath: state.app.appPath
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(HomeScreen));
