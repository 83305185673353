import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  slsRtnDetailResetTimestamp: null,
  slsRtnDetailSetHdrId: ['hdrId', 'itemId'],

  slsRtnDetailInitHeader: null,
  slsRtnDetailShowHeader: ['hdrId'],
  slsRtnDetailShowHeaderSuccess: ['documentHeader'],

  slsRtnDetailUpdateHeader: ['formikBag', 'documentHeader'],
  slsRtnDetailCreateHeader: ['formikBag', 'documentHeader'],
  slsRtnDetailUpdateDocumentSuccess: ['documentHeader', 'documentDetails'],

  slsRtnDetailShowDocumentLoading: ['boolean'],
  slsRtnDetailTransitionToStatus: ['formikBag', 'hdrId', 'docStatus'],

  slsRtnDetailShowDetails: ['hdrId'],
  slsRtnDetailShowDetailsSuccess: ['documentDetails'],

  slsRtnDetailSetDetailVisible: ['boolean'],
  slsRtnDetailSetDocumentDetail: ['documentDetail'],

  slsRtnDetailUpdateDetails: ['formikBag', 'hdrId', 'documentDetails'],
  slsRtnDetailCreateDetail: ['formikBag', 'hdrId', 'documentDetail'],
  slsRtnDetailDeleteDetail: ['hdrId', 'documentDetail'],

  slsRtnDetailFetchSalesmanOptions: ['search'],
  slsRtnDetailFetchSalesmanOptionLoading: ['boolean'],
  slsRtnDetailFetchSalesmanOptionSuccess: ['options'],

  slsRtnDetailFetchDeliveryPointOptions: ['search'],
  slsRtnDetailFetchDeliveryPointOptionLoading: ['boolean'],
  slsRtnDetailFetchDeliveryPointOptionSuccess: ['options'],
  slsRtnDetailChangeDeliveryPoint: ['formikBag', 'deliveryPointId'],

  slsRtnDetailFetchCreditTermOptions: ['search'],
  slsRtnDetailFetchCreditTermOptionLoading: ['boolean'],
  slsRtnDetailFetchCreditTermOptionSuccess: ['options'],

  slsRtnDetailFetchCurrencyOptions: ['search'],
  slsRtnDetailFetchCurrencyOptionLoading: ['boolean'],
  slsRtnDetailFetchCurrencyOptionSuccess: ['options'],
  slsRtnDetailChangeCurrency: ['formikBag', 'currencyId'],

  slsRtnDetailFetchLocationOptions: ['search'],
  slsRtnDetailFetchLocationOptionLoading: ['boolean'],
  slsRtnDetailFetchLocationOptionSuccess: ['options'],

  slsRtnDetailFetchItemOptions: ['search'],
  slsRtnDetailFetchItemOptionLoading: ['boolean'],
  slsRtnDetailFetchItemOptionSuccess: ['options'],
  slsRtnDetailChangeItem: ['formikBag', 'hdrId', 'itemId'],

  slsRtnDetailFetchUomOptions: ['itemId', 'search'],
  slsRtnDetailFetchUomOptionLoading: ['boolean'],
  slsRtnDetailFetchUomOptionSuccess: ['options'],
  slsRtnDetailChangeUom: ['formikBag', 'hdrId', 'itemId', 'uomId'],

  slsRtnDetailFetchProjectOptions: ['search'],
  slsRtnDetailFetchProjectOptionLoading: ['boolean'],
  slsRtnDetailFetchProjectOptionSuccess: ['options']
});

export const SlsRtnDetailTypes = Types;
export default Creators;
