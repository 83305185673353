export default {
  NULL: 0,
  STRING: 1,
  STRING_UPPER_CASE: 2,
  DATE: 21,
  INTEGER: 41,

  DECIMAL01: 101,
  DECIMAL02: 102,
  DECIMAL03: 103,
  DECIMAL04: 104,
  DECIMAL05: 105
};
