import React from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';

import { UndoOutlined, SaveOutlined } from '@ant-design/icons';

import { Button, Row, Col, Typography } from 'antd';
import {
  FormikSelect,
  FormikButton,
  FormikText,
  FormikTextNumber,
  FormikInputNumber
} from '../../Components/Formik';
import PickListDetailActions from '../../Stores/PickListDetail/Actions';

const { Text } = Typography;

class WhseJobType04Form extends React.PureComponent {
  constructor() {
    super();

    this.handleCompanySearch = this.handleCompanySearch.bind(this);
    this.handleSearchStorageBin = this.handleSearchStorageBin.bind(this);
    this.handleSearchQuantBal = this.handleSearchQuantBal.bind(this);
    this.handleSearchUom = this.handleSearchUom.bind(this);
    this.handleSearchToStorageBin = this.handleSearchToStorageBin.bind(this);

    this.handleChangeStorageBin = this.handleChangeStorageBin.bind(this);
    this.handleChangeQuantBal = this.handleChangeQuantBal.bind(this);
    this.handleChangeUom = this.handleChangeUom.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate() {}

  componentWillUnmount() {}

  handleCompanySearch(form, value) {
    const { fetchCompanyOptions } = this.props;

    fetchCompanyOptions(value);
  }

  handleSearchStorageBin(form, value) {
    const { fetchFrStorageBinOptions } = this.props;

    fetchFrStorageBinOptions(value);
  }

  handleSearchQuantBal(form, value) {
    const { fetchQuantBalOptions } = this.props;
    const { values } = form;

    fetchQuantBalOptions(
      values.company_select2.value,
      values.storage_bin_select2.value,
      values.item_id,
      value
    );
  }

  handleSearchUom(form, value) {
    const { fetchUomOptions } = this.props;
    const { values } = form;

    fetchUomOptions(values.item_id, value);
  }

  handleSearchToStorageBin(form, value) {
    const { fetchToStorageBinOptions } = this.props;

    fetchToStorageBinOptions(value);
  }

  handleChangeStorageBin(form) {
    // params: form, value, option

    const { fetchQuantBalOptionSuccess } = this.props;
    const { values: curValues, setFieldValue } = form;

    if ('storage_bin_select2' in curValues) {
      setFieldValue('quant_bal_select2', {
        value: 0,
        label: ''
      });
      setFieldValue('handling_unit_barcode', '');
      // setFieldValue('item_code', '');
      // setFieldValue('item_desc_01', '');
      // setFieldValue('item_desc_02', '');

      fetchQuantBalOptionSuccess([]);
      // fetchUomOptionSuccess([]);
    }
  }

  handleChangeQuantBal(form, value) {
    // params: form, value, option

    const { hdrId, changeQuantBal } = this.props;

    changeQuantBal(form, hdrId, value);
  }

  handleChangeUom(form, value) {
    // params: form, value, option
    const { hdrId, changeUom } = this.props;
    const { values } = form;

    changeUom(form, hdrId, values.item_id, value);
  }

  render() {
    const {
      intl,
      hdrId,
      documentHeader,
      documentDetail,
      updateDetails,
      createDetail,
      documentIsLoading,
      companyIsLoading,
      companyOptions,
      frStorageBinIsLoading,
      frStorageBinOptions,
      quantBalIsLoading,
      quantBalOptions,
      uomIsLoading,
      uomOptions,
      toStorageBinIsLoading,
      toStorageBinOptions
    } = this.props;

    const disabled = documentHeader && documentHeader.doc_status >= 50;
    return (
      <Formik
        enableReinitialize
        initialValues={documentDetail}
        onSubmit={(values, formikBag) => {
          // remove the submit_action field
          const processedValues = { ...values, whse_job_type: 4 };
          delete processedValues.submit_action;

          if (values.id > 0) {
            updateDetails(formikBag, hdrId, [processedValues]);
          } else {
            createDetail(formikBag, hdrId, processedValues);
          }
        }}
        validationSchema={Yup.object().shape({
          company_select2: Yup.object().shape({
            value: Yup.number().min(1, intl.formatMessage({ id: 'company_is_required' }))
          }),
          storage_bin_select2: Yup.object().shape({
            value: Yup.number().min(1, intl.formatMessage({ id: 'from_storage_bin_is_required' }))
          }),
          quant_bal_select2: Yup.object().shape({
            value: Yup.number().min(1, intl.formatMessage({ id: 'quant_bal_is_required' }))
          }),
          uom_select2: Yup.object().shape({
            value: Yup.number().min(1, intl.formatMessage({ id: 'uom_is_required' }))
          }),
          qty: Yup.number().moreThan(-1, intl.formatMessage({ id: 'qty_must_be_positive' })),
          to_storage_bin_select2: Yup.object().shape({
            value: Yup.number().min(1, intl.formatMessage({ id: 'to_storage_bin_is_required' }))
          })
        })}
      >
        {({
          values,
          // handleChange,
          // errors,
          // setFieldTouched,
          dirty,
          // touched,
          isSubmitting,
          // isValid,
          handleSubmit,
          handleReset
        }) => (
          <Form>
            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'company' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="company_select2"
                  component={FormikSelect}
                  showArrow
                  filterOption={false}
                  onSearch={this.handleCompanySearch}
                  notFoundContent={null}
                  loading={companyIsLoading}
                  options={companyOptions}
                  disabled={disabled}
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'from_storage_bin' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="storage_bin_select2"
                  component={FormikSelect}
                  showArrow
                  filterOption={false}
                  onSearch={this.handleSearchStorageBin}
                  notFoundContent={null}
                  loading={frStorageBinIsLoading}
                  options={frStorageBinOptions}
                  disabled={disabled}
                  onChange={this.handleChangeStorageBin}
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'quant_bal' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="quant_bal_select2"
                  component={FormikSelect}
                  showArrow
                  filterOption={false}
                  onSearch={this.handleSearchQuantBal}
                  notFoundContent={null}
                  loading={quantBalIsLoading}
                  options={quantBalOptions}
                  disabled={disabled}
                  onChange={this.handleChangeQuantBal}
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'pallet_id' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field name="handling_unit_barcode" component={FormikText} />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'item_code' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field name="item_code" component={FormikText} />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'item_desc' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field name="desc_01" component={FormikText} />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text />
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field name="desc_02" component={FormikText} />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'batch_serial_no' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field name="batch_serial_no" component={FormikText} />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'expiry_date' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field name="expiry_date" component={FormikText} />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'receipt_date' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field name="receipt_date" component={FormikText} />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'qty' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="qty"
                  component={FormikInputNumber}
                  precision={values.qty_scale || 0}
                  disabled={disabled}
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text />
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={10}>
                <Field
                  name="uom_select2"
                  component={FormikSelect}
                  showArrow
                  filterOption={false}
                  onSearch={this.handleSearchUom}
                  notFoundContent={null}
                  loading={uomIsLoading}
                  options={uomOptions}
                  disabled={disabled}
                  onChange={this.handleChangeUom}
                />
              </Col>
              <Col span={10}>
                <Field
                  name="uom_rate"
                  component={FormikTextNumber}
                  minimumfractiondigits={6}
                  maximumfractiondigits={6}
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'to_storage_bin' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="to_storage_bin_select2"
                  component={FormikSelect}
                  showArrow
                  filterOption={false}
                  onSearch={this.handleSearchToStorageBin}
                  notFoundContent={null}
                  loading={toStorageBinIsLoading}
                  options={toStorageBinOptions}
                  disabled={disabled}
                />
              </Col>
            </Row>

            <Row type="flex" justify="end" gutter={[0, 16]}>
              <Col span={5}>
                <Button
                  type="primary"
                  disabled={!dirty}
                  loading={isSubmitting || documentIsLoading}
                  onClick={handleReset}
                  icon={<UndoOutlined />}
                >
                  {intl.formatMessage({ id: 'reset' })}
                </Button>
                <Field
                  type="primary"
                  name="submit_action"
                  value="update"
                  component={FormikButton}
                  disabled={disabled}
                  loading={isSubmitting || documentIsLoading}
                  onClick={handleSubmit}
                  label={intl.formatMessage({
                    id: values.id > 0 ? 'update' : 'create'
                  })}
                  icon={<SaveOutlined />}
                />
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    );
  }
}

WhseJobType04Form.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  updateDetails: PropTypes.func,
  createDetail: PropTypes.func,
  hdrId: PropTypes.number,
  documentHeader: PropTypes.shape({
    doc_status: PropTypes.number
  }),
  documentDetail: PropTypes.shape({}),
  documentIsLoading: PropTypes.bool,

  fetchCompanyOptions: PropTypes.func,
  companyIsLoading: PropTypes.bool,
  companyOptions: PropTypes.arrayOf(PropTypes.object),

  fetchFrStorageBinOptions: PropTypes.func,
  frStorageBinIsLoading: PropTypes.bool,
  frStorageBinOptions: PropTypes.arrayOf(PropTypes.object),

  fetchQuantBalOptions: PropTypes.func,
  fetchQuantBalOptionSuccess: PropTypes.func,
  changeQuantBal: PropTypes.func,
  quantBalIsLoading: PropTypes.bool,
  quantBalOptions: PropTypes.arrayOf(PropTypes.object),

  fetchUomOptions: PropTypes.func,
  changeUom: PropTypes.func,
  uomIsLoading: PropTypes.bool,
  uomOptions: PropTypes.arrayOf(PropTypes.object),

  fetchToStorageBinOptions: PropTypes.func,
  toStorageBinIsLoading: PropTypes.bool,
  toStorageBinOptions: PropTypes.arrayOf(PropTypes.object)
};

WhseJobType04Form.defaultProps = {
  intl: {},
  updateDetails() {},
  createDetail() {},
  hdrId: 0,
  documentHeader: { doc_status: 3 },
  documentDetail: {},
  documentIsLoading: false,

  fetchCompanyOptions() {},
  companyIsLoading: false,
  companyOptions: [],

  fetchFrStorageBinOptions() {},
  frStorageBinIsLoading: false,
  frStorageBinOptions: [],

  fetchQuantBalOptions() {},
  fetchQuantBalOptionSuccess() {},
  quantBalIsLoading: false,
  quantBalOptions: [],
  changeQuantBal() {},

  fetchUomOptions() {},
  changeUom() {},
  uomIsLoading: false,
  uomOptions: [],

  fetchToStorageBinOptions() {},
  toStorageBinIsLoading: false,
  toStorageBinOptions: []
};

const mapStateToProps = state => ({
  hdrId: state.pickListDetail.hdrId,
  documentHeader: state.pickListDetail.documentHeader,
  documentDetail: state.pickListDetail.documentDetail,
  documentIsLoading: state.pickListDetail.documentIsLoading,

  companyIsLoading: state.pickListDetail.companyIsLoading,
  companyOptions: state.pickListDetail.companyOptions,

  frStorageBinIsLoading: state.pickListDetail.frStorageBinIsLoading,
  frStorageBinOptions: state.pickListDetail.frStorageBinOptions,

  quantBalIsLoading: state.pickListDetail.quantBalIsLoading,
  quantBalOptions: state.pickListDetail.quantBalOptions,

  uomIsLoading: state.pickListDetail.uomIsLoading,
  uomOptions: state.pickListDetail.uomOptions,

  toStorageBinIsLoading: state.pickListDetail.toStorageBinIsLoading,
  toStorageBinOptions: state.pickListDetail.toStorageBinOptions
});

const mapDispatchToProps = dispatch => ({
  updateDetails: (formikBag, hdrId, documentDetails) =>
    dispatch(PickListDetailActions.pickListDetailUpdateDetails(formikBag, hdrId, documentDetails)),
  createDetail: (formikBag, hdrId, documentDetail) =>
    dispatch(PickListDetailActions.pickListDetailCreateDetail(formikBag, hdrId, documentDetail)),

  fetchCompanyOptions: search =>
    dispatch(PickListDetailActions.pickListDetailFetchCompanyOptions(search)),

  fetchFrStorageBinOptions: search =>
    dispatch(PickListDetailActions.pickListDetailFetchFrStorageBinOptions(search)),

  fetchToStorageBinOptions: search =>
    dispatch(PickListDetailActions.pickListDetailFetchToStorageBinOptions(search)),

  fetchUomOptions: (itemId, search) =>
    dispatch(PickListDetailActions.pickListDetailFetchUomOptions(itemId, search)),
  fetchUomOptionSuccess: options =>
    dispatch(PickListDetailActions.pickListDetailFetchUomOptionSuccess(options)),
  changeUom: (formikBag, hdrId, itemId, uomId) =>
    dispatch(PickListDetailActions.pickListDetailChangeUom(formikBag, hdrId, itemId, uomId)),

  fetchQuantBalOptions: (companyId, storageBinId, itemId, search) =>
    dispatch(
      PickListDetailActions.pickListDetailFetchQuantBalOptions(
        companyId,
        storageBinId,
        itemId,
        search
      )
    ),
  fetchQuantBalOptionSuccess: options =>
    dispatch(PickListDetailActions.pickListDetailFetchQuantBalOptionSuccess(options)),
  changeQuantBal: (formikBag, hdrId, quantBalId) =>
    dispatch(PickListDetailActions.pickListDetailChangeQuantBal(formikBag, hdrId, quantBalId))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(WhseJobType04Form));
