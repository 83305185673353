import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  putAwayFServ01ShowSyncSetting: null,
  putAwayFServ01ShowSyncSettingLoading: ['boolean'],
  putAwayFServ01ShowSyncSettingSuccess: ['syncSetting'],
  putAwayFServ01UpdateSyncSetting: ['formikBag', 'syncSetting'],
  putAwayFServ01ShowBatchJobStatus: null,
  putAwayFServ01ShowBatchJobStatusSuccess: ['batchJobStatus'],

  putAwayFServ01ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  putAwayFServ01GoToDocument: ['hdrId'],
  putAwayFServ01FetchPutAwayFServ01: ['currentPage', 'sorts', 'filters', 'pageSize'],
  putAwayFServ01FetchPutAwayFServ01Loading: ['boolean'],
  putAwayFServ01FetchPutAwayFServ01Success: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],
  putAwayFServ01AddSelectedDocuments: ['selectedDocuments'],
  putAwayFServ01RemoveSelectedDocuments: ['selectedDocuments'],
  putAwayFServ01SetWorkspaceVisible: ['boolean'],

  putAwayFServ01Export: ['hdrIds'],
  putAwayFServ01ExportSuccess: null,
  putAwayFServ01ExportLoading: ['boolean']
});

export const PutAwayFServ01Types = Types;
export default Creators;
