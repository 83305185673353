import { put, call, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
// import ApiService from '../Services/ApiService';
// import AppActions from '../Stores/App/Actions';
import DivisionProcessActions, { DivisionProcessTypes } from '../Stores/DivisionProcess/Actions';

// const getAppStore = state => state.app;

export function* divisionProcessFetchDivisionProcess() {
  try {
    yield put(DivisionProcessActions.divisionProcessFetchDivisionProcessLoading(true));

    const result = [{ proc_type: 'DIVISION_LIST_01', icon: 'fa fa-cubes' }];
    yield put(DivisionProcessActions.divisionProcessFetchDivisionProcessSuccess(result));

    /*
    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `division/indexItemFlow/${divisionFlowId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );
    
    if (result.isSuccess === true) {
      yield put(DivisionProcessActions.divisionProcessFetchDivisionProcessSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
    */
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(DivisionProcessActions.divisionProcessFetchDivisionProcessLoading(false));
  }
}

export const saga = [
  takeLatest(
    DivisionProcessTypes.DIVISION_PROCESS_FETCH_DIVISION_PROCESS,
    divisionProcessFetchDivisionProcess
  )
];
