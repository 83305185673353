/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { AdvShipFServ01Types } from './Actions';
import INITIAL_STATE from './InitialState';

export const advShipFServ01ShowSyncSettingLoading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const advShipFServ01ShowSyncSettingSuccess = (state, { syncSetting }) => ({
  ...state,
  syncSetting
});

export const advShipFServ01ShowBatchJobStatusSuccess = (state, { batchJobStatus }) => ({
  ...state,
  batchJobStatus
});

export const advShipFServ01ImportLoading = (state, { boolean }) => ({
  ...state,
  importIsLoading: boolean
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [AdvShipFServ01Types.ADV_SHIP_F_SERV01_SHOW_SYNC_SETTING_LOADING]: advShipFServ01ShowSyncSettingLoading,
  [AdvShipFServ01Types.ADV_SHIP_F_SERV01_SHOW_SYNC_SETTING_SUCCESS]: advShipFServ01ShowSyncSettingSuccess,
  [AdvShipFServ01Types.ADV_SHIP_F_SERV01_SHOW_BATCH_JOB_STATUS_SUCCESS]: advShipFServ01ShowBatchJobStatusSuccess,
  [AdvShipFServ01Types.ADV_SHIP_F_SERV01_IMPORT_LOADING]: advShipFServ01ImportLoading
});
