import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  layerFarmListResetTimestamp: ['procType', 'currentPage', 'sorts', 'filters', 'pageSize'],
  layerFarmListGoToResource: ['resId'],
  layerFarmListNewResource: null,
  layerFarmListGoToAudit: ['hdrId'],
  layerFarmListFetchList: ['procType', 'currentPage', 'sorts', 'filters', 'pageSize'],
  layerFarmListFetchListLoading: ['boolean'],
  layerFarmListFetchListSuccess: ['documents', 'currentPage', 'lastPage', 'total', 'pageSize'],
  layerFarmListAddSelectedDocuments: ['selectedDocuments'],
  layerFarmListRemoveSelectedDocuments: ['selectedDocuments'],
  layerFarmListSetWorkspaceVisible: ['boolean']
});

export const LayerFarmListTypes = Types;
export default Creators;
