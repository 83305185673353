import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import treeChanges from 'tree-changes';
import moment from 'moment';

import { AuditOutlined, EditOutlined, ReloadOutlined } from '@ant-design/icons';

import { Card, Row, Col, Table, Button } from 'antd';

import AntDesignTable from '../../Components/AntDesignTable';
import StkRcptIndexActions from '../../Stores/StkRcptIndex/Actions';

class StkRcptIndexTable extends React.PureComponent {
  constructor() {
    super();

    this.useOnTableChange = this.useOnTableChange.bind(this);
    this.useShowWorkspace = this.useShowWorkspace.bind(this);

    this.handleSearch = this.handleSearch.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.getDocumentColumns = this.getDocumentColumns.bind(this);
  }

  componentDidMount() {
    const { curDivisionId, setWorkspaceVisible, resetTimestamp } = this.props;

    if (curDivisionId > 0) {
      setWorkspaceVisible(false);
      resetTimestamp();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      curDivisionId,
      resetTimestamp,
      fetchStkRcptIndex,
      currentPage,
      sorts,
      filters,
      pageSize
    } = this.props;

    const { changed } = treeChanges(prevProps, this.props);

    if (changed('curDivisionId')) {
      resetTimestamp();
    }

    if (changed('timestamp')) {
      if (curDivisionId > 0) {
        fetchStkRcptIndex(currentPage, sorts, filters, pageSize);
      }
    }
  }

  componentWillUnmount() {}

  handleSearch(selectedKeys, confirm) {
    // eslint-disable-next-line no-unused-vars
    const { intl } = this.props;

    confirm();
  }

  handleReset(clearFilters) {
    // eslint-disable-next-line no-unused-vars
    const { intl } = this.props;

    clearFilters();
  }

  getDocumentColumns() {
    const { intl, sorts, filters, goToDocument, goToAudit } = this.props;

    return [
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'type' }),
        // sort field
        dataIndex: 'doc_sub_type',
        ...AntDesignTable.getColumnSortProps(sorts, 'doc_sub_type'),
        // filter field
        key: 'doc_sub_type',
        filters: [
          {
            text: intl.formatMessage({ id: 'SITE_TRANSFER' }),
            value: 1
          },
          {
            text: intl.formatMessage({ id: 'TRANSFER_TO_STORE' }),
            value: 2
          },
          {
            text: intl.formatMessage({ id: 'TRANSFER_FROM_STORE' }),
            value: 4
          },
          {
            text: intl.formatMessage({ id: 'TRANSFER_TO_VAN' }),
            value: 3
          },
          {
            text: intl.formatMessage({ id: 'TRANSFER_FROM_VAN' }),
            value: 5
          }
        ],
        render: (text, record) => <>{intl.formatMessage({ id: record.str_doc_sub_type })}</>
      },
      {
        width: 120,
        align: 'left',
        title: intl.formatMessage({ id: 'doc_code' }),
        // sort field
        dataIndex: 'doc_code',
        ...AntDesignTable.getColumnSortProps(sorts, 'doc_code'),
        // filter field
        key: 'doc_code',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'doc_code' }),
          'doc_code',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.doc_code}</>
      },
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'doc_date' }),
        // sort field
        dataIndex: 'doc_date',
        ...AntDesignTable.getColumnSortProps(sorts, 'doc_date'),
        // filter field
        key: 'doc_date',
        ...AntDesignTable.getColumnSearchDateProps(
          filters,
          intl.formatMessage({ id: 'doc_date' }),
          'doc_date',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{moment(record.doc_date).format('YYYY-MM-DD')}</>
      },
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'doc_status' }),
        // sort field
        dataIndex: 'doc_status',
        ...AntDesignTable.getColumnSortProps(sorts, 'doc_status'),
        // filter field
        ...AntDesignTable.getColumnSearchDocStatusProps(filters, 'doc_status'),
        render: (text, record) => <>{record.str_doc_status}</>
      },
      {
        width: 120,
        align: 'left',
        title: intl.formatMessage({ id: 'from_division' }),
        // sort field
        dataIndex: 'fr_division_code',
        ...AntDesignTable.getColumnMultiSortProps(sorts, 'fr_division_code'),
        // filter field
        key: 'fr_division',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'from_division' }),
          'fr_division',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => (
          <>
            <b>{record.fr_division_code}</b>
          </>
        )
      },
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'project' }),
        // sort field
        dataIndex: 'project_code',
        ...AntDesignTable.getColumnSortProps(sorts, 'project_code'),
        // filter field
        key: 'project_code',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'project' }),
          'project_code',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.project_code}</>
      },
      {
        width: 150,
        align: 'left',
        title: intl.formatMessage({ id: 'desc' }),
        // sort field
        dataIndex: 'desc_01',
        ...AntDesignTable.getColumnSortProps(sorts, 'desc_01'),
        // filter field
        key: 'desc',
        render: (text, record) => <>{record.desc_01}</>
      },
      {
        width: 100,
        align: 'right',
        title: intl.formatMessage({ id: 'net_amt' }),
        // sort field
        dataIndex: 'net_amt',
        ...AntDesignTable.getColumnSortProps(sorts, 'net_amt'),
        // filter field
        key: 'net_amt',
        render: (text, record) => (
          <>
            {new Intl.NumberFormat([], {
              style: 'decimal',
              minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
              maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
            }).format(record.net_amt)}
          </>
        )
      },

      {
        width: 50,
        fixed: 'right',
        key: 'action',
        render: (text, record) => (
          <>
            <Button type="dashed" icon={<EditOutlined />} onClick={() => goToDocument(record.id)} />
            <Button type="dashed" icon={<AuditOutlined />} onClick={() => goToAudit(record.id)} />
          </>
        )
      }
    ];
  }

  useOnTableChange(pagination, filters, sorter) {
    const { resetTimestamp } = this.props;

    const processedFilters = AntDesignTable.processFilters(filters);
    const processedSorts = AntDesignTable.processSorts(sorter);

    resetTimestamp(pagination.current, processedSorts, processedFilters, pagination.pageSize);
  }

  useShowWorkspace() {
    const { setWorkspaceVisible } = this.props;

    setWorkspaceVisible(true);
  }

  render() {
    const {
      intl,
      resetTimestamp,
      currentPage,
      pageSize,
      total,
      documents,
      fetchIsLoading,
      selectedDocuments,
      addSelectedDocuments,
      removeSelectedDocuments
    } = this.props;

    return (
      <Card title={intl.formatMessage({ id: 'stock_receipts' })}>
        <Table
          size="small"
          // rowSelection={rowSelection}
          rowKey="id"
          pagination={{
            current: currentPage,
            pageSize: parseInt(pageSize, 10),
            total,
            showTotal: () => `${total} items`
          }}
          columns={this.getDocumentColumns()}
          dataSource={documents}
          loading={fetchIsLoading}
          bordered
          rowSelection={{
            selectedRowKeys: selectedDocuments.map(value => value.id),
            onChange: () => {
              // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            },
            onSelect: (record, selected) => {
              // console.log(`onSelect record: `, record, 'selected: ', selected, 'selectedRows: ', selectedRows);
              if (selected === true) {
                addSelectedDocuments([record]);
              } else {
                removeSelectedDocuments([record]);
              }
            },
            onSelectAll: (selected, selectedRows, changeRows) => {
              // console.log('onSelectAll selected: ', selected, 'selectedRows: ', selectedRows, 'changeRows: ', changeRows);
              if (selected === true) {
                addSelectedDocuments(changeRows);
              } else {
                removeSelectedDocuments(changeRows);
              }
            },
            onSelectInvert: selectedRows => {
              // console.log('onSelectInvert selectedRows: ', selectedRows);
              removeSelectedDocuments(selectedRows);
            },
            getCheckboxProps: () => ({
              // Column configuration not to be checked
              disabled: true
              // name: record.name,
            })
          }}
          onChange={this.useOnTableChange}
          title={() => (
            <>
              <Row type="flex" justify="space-between" gutter={[0, 16]}>
                <Col span={8}>
                  <Button
                    type="primary"
                    // disabled={!isValid}
                    loading={fetchIsLoading}
                    onClick={() => resetTimestamp()}
                    icon={<ReloadOutlined />}
                  />
                </Col>
              </Row>
            </>
          )}
          scroll={{ x: 950 }}
        />
      </Card>
    );
  }
}

StkRcptIndexTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  resetTimestamp: PropTypes.func,
  goToDocument: PropTypes.func,
  goToAudit: PropTypes.func,

  setWorkspaceVisible: PropTypes.func,

  addSelectedDocuments: PropTypes.func,
  removeSelectedDocuments: PropTypes.func,
  documents: PropTypes.arrayOf(PropTypes.object),
  selectedDocuments: PropTypes.arrayOf(PropTypes.object),

  sorts: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  filters: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),

  currentPage: PropTypes.number,
  pageSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  total: PropTypes.number,
  fetchIsLoading: PropTypes.bool,

  curDivisionId: PropTypes.number,
  fetchStkRcptIndex: PropTypes.func
};

StkRcptIndexTable.defaultProps = {
  intl: {},
  resetTimestamp() {},
  goToDocument() {},
  goToAudit() {},

  setWorkspaceVisible() {},

  addSelectedDocuments() {},
  removeSelectedDocuments() {},
  documents: [],
  selectedDocuments: [],

  sorts: {},
  filters: {},

  currentPage: 1,
  pageSize: '20',
  total: 0,
  fetchIsLoading: false,

  curDivisionId: 0,
  fetchStkRcptIndex() {}
};

const mapStateToProps = state => ({
  timestamp: state.stkRcptIndex.timestamp,
  workspaceIsVisible: state.stkRcptIndex.workspaceIsVisible,

  documents: state.stkRcptIndex.documents,
  selectedDocuments: state.stkRcptIndex.selectedDocuments,

  sorts: state.stkRcptIndex.sorts,
  filters: state.stkRcptIndex.filters,

  currentPage: state.stkRcptIndex.currentPage,
  pageSize: state.stkRcptIndex.pageSize,
  total: state.stkRcptIndex.total,
  fetchIsLoading: state.stkRcptIndex.fetchIsLoading,

  curDivisionId: state.app.curDivisionId
});

const mapDispatchToProps = dispatch => ({
  resetTimestamp: (currentPage, sorts, filters, pageSize) =>
    dispatch(StkRcptIndexActions.stkRcptIndexResetTimestamp(currentPage, sorts, filters, pageSize)),
  goToDocument: hdrId => dispatch(StkRcptIndexActions.stkRcptIndexGoToDocument(hdrId)),
  goToAudit: hdrId => dispatch(StkRcptIndexActions.stkRcptIndexGoToAudit(hdrId)),

  setWorkspaceVisible: boolean =>
    dispatch(StkRcptIndexActions.stkRcptIndexSetWorkspaceVisible(boolean)),

  addSelectedDocuments: selectedDocuments =>
    dispatch(StkRcptIndexActions.stkRcptIndexAddSelectedDocuments(selectedDocuments)),
  removeSelectedDocuments: selectedDocuments =>
    dispatch(StkRcptIndexActions.stkRcptIndexRemoveSelectedDocuments(selectedDocuments)),

  fetchStkRcptIndex: (currentPage, sorts, filters, pageSize) =>
    dispatch(
      StkRcptIndexActions.stkRcptIndexFetchStkRcptIndex(currentPage, sorts, filters, pageSize)
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(StkRcptIndexTable));
