/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { ItemList01Types } from './Actions';
import INITIAL_STATE from './InitialState';

export const itemList01ResetTimestamp = (state, { currentPage, sorts, filters, pageSize }) => ({
  ...state,
  currentPage: currentPage || state.currentPage,
  sorts: sorts || state.sorts,
  filters: filters || state.filters,
  pageSize: pageSize || state.pageSize,
  timestamp: Date.now(),
  // selectedDocuments: INITIAL_STATE.selectedDocuments,
  toStorageBinOptions: INITIAL_STATE.toStorageBinOptions,
  toStorageBinOption: INITIAL_STATE.toStorageBinOption
});

export const itemList01FetchItemList01Loading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const itemList01FetchItemList01Success = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  documents,
  currentPage,
  lastPage,
  total,
  pageSize
});

export const itemList01AddSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = state.selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: [
      ...state.selectedDocuments,
      ...selectedDocuments.filter(data => {
        return !selectIds.includes(data.id);
      })
    ]
  };
};

export const itemList01RemoveSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: state.selectedDocuments.filter(data => {
      return !selectIds.includes(data.id);
    })
  };
};

export const itemList01SetWorkspaceVisible = (state, { boolean }) => ({
  ...state,
  workspaceIsVisible: boolean
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [ItemList01Types.ITEM_LIST01_RESET_TIMESTAMP]: itemList01ResetTimestamp,
  [ItemList01Types.ITEM_LIST01_FETCH_ITEM_LIST01_LOADING]: itemList01FetchItemList01Loading,
  [ItemList01Types.ITEM_LIST01_FETCH_ITEM_LIST01_SUCCESS]: itemList01FetchItemList01Success,
  [ItemList01Types.ITEM_LIST01_ADD_SELECTED_DOCUMENTS]: itemList01AddSelectedDocuments,
  [ItemList01Types.ITEM_LIST01_REMOVE_SELECTED_DOCUMENTS]: itemList01RemoveSelectedDocuments,
  [ItemList01Types.ITEM_LIST01_SET_WORKSPACE_VISIBLE]: itemList01SetWorkspaceVisible
});
