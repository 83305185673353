import React from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';

import { ArrowLeftOutlined, UndoOutlined, SaveOutlined } from '@ant-design/icons';

import { Card, Button, Row, Col, Typography } from 'antd';
import treeChanges from 'tree-changes';
import {
  FormikInput,
  FormikInputNumber,
  FormikText,
  FormikDatePicker,
  FormikButton,
  FormikSelect
} from '../../Components/Formik';
import GdsRcptDetailActions from '../../Stores/GdsRcptDetail/Actions';
import AppActions from '../../Stores/App/Actions';
import UserFieldType from '../../Services/Env/UserFieldType';
import UserFieldMode from '../../Services/Env/UserFieldMode';

const { Text } = Typography;

class HeaderForm extends React.PureComponent {
  componentDidMount() {}

  componentDidUpdate(prevProps) {
    const { hdrId, initHeader, showHeader } = this.props;

    const { changed } = treeChanges(prevProps, this.props);
    if (changed('timestamp')) {
      if (hdrId <= 0) {
        initHeader();
      } else {
        showHeader(hdrId);
      }
    }
  }

  componentWillUnmount() {}

  render() {
    const {
      intl,
      hdrId,
      match,
      documentHeader,
      transitionToStatus,
      createHeader,
      updateHeader,
      historyGoBack,
      documentIsLoading
    } = this.props;

    const disabled = documentHeader && documentHeader.doc_status >= 50;

    return (
      <Formik
        enableReinitialize
        initialValues={documentHeader}
        onSubmit={(values, formikBag) => {
          if (values.submit_action === 'update') {
            if (match.params.action === 'create') {
              createHeader(formikBag, values);
            } else {
              updateHeader(formikBag, values);
            }
          } else if (values.submit_action === 'void') {
            transitionToStatus(formikBag, hdrId, 'VOID');
          } else if (values.submit_action === 'draft') {
            transitionToStatus(formikBag, hdrId, 'DRAFT');
          } else if (values.submit_action === 'wip') {
            transitionToStatus(formikBag, hdrId, 'WIP');
          } else if (values.submit_action === 'complete') {
            transitionToStatus(formikBag, hdrId, 'COMPLETE');
          }
        }}
        validationSchema={Yup.object().shape({
          doc_date: Yup.date().min(
            new Date(1969, 12, 1),
            intl.formatMessage({ id: 'doc_date_is_required' })
          ),
          user_field_01_value:
            documentHeader.user_field_01_value >= UserFieldMode.REQUIRED
              ? Yup.string().required(
                  1,
                  `${documentHeader.user_field_01_label} ${intl.formatMessage({
                    id: 'is_required'
                  })}`
                )
              : '',
          user_field_02_value:
            documentHeader.user_field_02_value >= UserFieldMode.REQUIRED
              ? Yup.string().required(
                  1,
                  `${documentHeader.user_field_02_label} ${intl.formatMessage({
                    id: 'is_required'
                  })}`
                )
              : '',
          user_field_03_value:
            documentHeader.user_field_03_value >= UserFieldMode.REQUIRED
              ? Yup.string().required(
                  1,
                  `${documentHeader.user_field_03_label} ${intl.formatMessage({
                    id: 'is_required'
                  })}`
                )
              : '',
          user_field_04_value:
            documentHeader.user_field_04_value >= UserFieldMode.REQUIRED
              ? Yup.string().required(
                  1,
                  `${documentHeader.user_field_04_label} ${intl.formatMessage({
                    id: 'is_required'
                  })}`
                )
              : '',
          user_field_05_value:
            documentHeader.user_field_05_value >= UserFieldMode.REQUIRED
              ? Yup.string().required(
                  1,
                  `${documentHeader.user_field_05_label} ${intl.formatMessage({
                    id: 'is_required'
                  })}`
                )
              : '',
          user_field_06_value:
            documentHeader.user_field_06_value >= UserFieldMode.REQUIRED
              ? Yup.string().required(
                  1,
                  `${documentHeader.user_field_06_label} ${intl.formatMessage({
                    id: 'is_required'
                  })}`
                )
              : '',
          user_field_07_value:
            documentHeader.user_field_07_value >= UserFieldMode.REQUIRED
              ? Yup.string().required(
                  1,
                  `${documentHeader.user_field_07_label} ${intl.formatMessage({
                    id: 'is_required'
                  })}`
                )
              : '',
          user_field_08_value:
            documentHeader.user_field_08_value >= UserFieldMode.REQUIRED
              ? Yup.string().required(
                  1,
                  `${documentHeader.user_field_08_label} ${intl.formatMessage({
                    id: 'is_required'
                  })}`
                )
              : '',
          user_field_09_value:
            documentHeader.user_field_09_value >= UserFieldMode.REQUIRED
              ? Yup.string().required(
                  1,
                  `${documentHeader.user_field_09_label} ${intl.formatMessage({
                    id: 'is_required'
                  })}`
                )
              : '',
          user_field_10_value:
            documentHeader.user_field_10_value >= UserFieldMode.REQUIRED
              ? Yup.string().required(
                  1,
                  `${documentHeader.user_field_10_label} ${intl.formatMessage({
                    id: 'is_required'
                  })}`
                )
              : ''
        })}
      >
        {({
          values,
          // handleChange,
          // errors,
          // setFieldTouched,
          dirty,
          // touched,
          isSubmitting,
          // isValid,
          handleSubmit,
          handleReset
        }) => (
          <Form>
            <Card>
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'doc_code' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  {match.params.action === 'create' ? (
                    <Field
                      name="doc_no_id"
                      component={FormikSelect}
                      showArrow
                      filterOption={false}
                      notFoundContent={null}
                      options={values.doc_no_id_options}
                      disabled={disabled || documentIsLoading}
                    />
                  ) : (
                    <Field name="doc_code" component={FormikText} />
                  )}
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'doc_status' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="submit_action"
                    value="void"
                    component={FormikButton}
                    style={
                      values.doc_status === 2 ? { backgroundColor: 'red', color: 'white' } : {}
                    }
                    disabled={
                      values.doc_status === 2 ||
                      match.params.action === 'create' ||
                      values.doc_status > 3
                    }
                    loading={isSubmitting || documentIsLoading}
                    onClick={handleSubmit}
                    label={intl.formatMessage({
                      id: 'void'
                    })}
                  />
                  <Field
                    name="submit_action"
                    value="draft"
                    component={FormikButton}
                    style={
                      values.doc_status === 3 ? { backgroundColor: 'red', color: 'white' } : {}
                    }
                    disabled={values.doc_status === 3 || match.params.action === 'create'}
                    loading={isSubmitting || documentIsLoading}
                    onClick={handleSubmit}
                    label={intl.formatMessage({
                      id: 'draft'
                    })}
                  />
                  <Field
                    name="submit_action"
                    value="wip"
                    component={FormikButton}
                    style={
                      values.doc_status === 50 ? { backgroundColor: 'red', color: 'white' } : {}
                    }
                    disabled={values.doc_status === 50 || match.params.action === 'create'}
                    loading={isSubmitting || documentIsLoading}
                    onClick={handleSubmit}
                    label={intl.formatMessage({
                      id: 'WIP'
                    })}
                  />
                  <Field
                    name="submit_action"
                    value="complete"
                    component={FormikButton}
                    style={
                      values.doc_status === 100 ? { backgroundColor: 'green', color: 'white' } : {}
                    }
                    disabled={values.doc_status === 100 || match.params.action === 'create'}
                    loading={isSubmitting || documentIsLoading}
                    onClick={handleSubmit}
                    label={intl.formatMessage({
                      id: 'complete'
                    })}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'doc_date' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="doc_date"
                    component={FormikDatePicker}
                    disabled={disabled || documentIsLoading}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'ref_code' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={10}>
                  <Field
                    name="ref_code_01"
                    component={FormikInput}
                    disabled={disabled || documentIsLoading}
                  />
                </Col>
                <Col span={10}>
                  <Field
                    name="ref_code_02"
                    component={FormikInput}
                    disabled={disabled || documentIsLoading}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'desc_01' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="desc_01"
                    component={FormikInput}
                    disabled={disabled || documentIsLoading}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'desc_02' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="desc_02"
                    component={FormikInput}
                    disabled={disabled || documentIsLoading}
                  />
                </Col>
              </Row>

              {values.user_field_01_type > 0 && (
                <Row type="flex" justify="center" gutter={[8, 8]}>
                  <Col span={3}>
                    <Text>{values.user_field_01_label}</Text>
                  </Col>
                  <Col span={1}>
                    <Text>:</Text>
                  </Col>
                  <Col span={20}>
                    {(values.user_field_01_type === UserFieldType.STRING ||
                      values.user_field_01_type === UserFieldType.STRING_UPPER_CASE) && (
                      <Field
                        name="user_field_01_value"
                        component={FormikInput}
                        disabled={disabled || documentIsLoading}
                      />
                    )}
                    {values.user_field_01_type === UserFieldType.DATE && (
                      <Field
                        name="user_field_01_value"
                        component={FormikDatePicker}
                        disabled={disabled || documentIsLoading}
                      />
                    )}
                    {(values.user_field_01_type === UserFieldType.INTEGER ||
                      values.user_field_01_type === UserFieldType.DECIMAL01 ||
                      values.user_field_01_type === UserFieldType.DECIMAL02 ||
                      values.user_field_01_type === UserFieldType.DECIMAL03 ||
                      values.user_field_01_type === UserFieldType.DECIMAL04 ||
                      values.user_field_01_type === UserFieldType.DECIMAL05) && (
                      <Field
                        name="user_field_01_value"
                        component={FormikInputNumber}
                        disabled={disabled || documentIsLoading}
                      />
                    )}
                  </Col>
                </Row>
              )}
              {values.user_field_02_type > 0 && (
                <Row type="flex" justify="center" gutter={[8, 8]}>
                  <Col span={3}>
                    <Text>{values.user_field_02_label}</Text>
                  </Col>
                  <Col span={1}>
                    <Text>:</Text>
                  </Col>
                  <Col span={20}>
                    {(values.user_field_02_type === UserFieldType.STRING ||
                      values.user_field_02_type === UserFieldType.STRING_UPPER_CASE) && (
                      <Field
                        name="user_field_02_value"
                        component={FormikInput}
                        disabled={disabled || documentIsLoading}
                      />
                    )}
                    {values.user_field_02_type === UserFieldType.DATE && (
                      <Field
                        name="user_field_02_value"
                        component={FormikDatePicker}
                        disabled={disabled || documentIsLoading}
                      />
                    )}
                    {(values.user_field_02_type === UserFieldType.INTEGER ||
                      values.user_field_02_type === UserFieldType.DECIMAL01 ||
                      values.user_field_02_type === UserFieldType.DECIMAL02 ||
                      values.user_field_02_type === UserFieldType.DECIMAL03 ||
                      values.user_field_02_type === UserFieldType.DECIMAL04 ||
                      values.user_field_02_type === UserFieldType.DECIMAL05) && (
                      <Field
                        name="user_field_02_value"
                        component={FormikInputNumber}
                        disabled={disabled || documentIsLoading}
                      />
                    )}
                  </Col>
                </Row>
              )}
              {values.user_field_03_type > 0 && (
                <Row type="flex" justify="center" gutter={[8, 8]}>
                  <Col span={3}>
                    <Text>{values.user_field_03_label}</Text>
                  </Col>
                  <Col span={1}>
                    <Text>:</Text>
                  </Col>
                  <Col span={20}>
                    {(values.user_field_03_type === UserFieldType.STRING ||
                      values.user_field_03_type === UserFieldType.STRING_UPPER_CASE) && (
                      <Field
                        name="user_field_03_value"
                        component={FormikInput}
                        disabled={disabled || documentIsLoading}
                      />
                    )}
                    {values.user_field_03_type === UserFieldType.DATE && (
                      <Field
                        name="user_field_03_value"
                        component={FormikDatePicker}
                        disabled={disabled || documentIsLoading}
                      />
                    )}
                    {(values.user_field_03_type === UserFieldType.INTEGER ||
                      values.user_field_03_type === UserFieldType.DECIMAL01 ||
                      values.user_field_03_type === UserFieldType.DECIMAL02 ||
                      values.user_field_03_type === UserFieldType.DECIMAL03 ||
                      values.user_field_03_type === UserFieldType.DECIMAL04 ||
                      values.user_field_03_type === UserFieldType.DECIMAL05) && (
                      <Field
                        name="user_field_03_value"
                        component={FormikInputNumber}
                        precision={5}
                        disabled={disabled || documentIsLoading}
                      />
                    )}
                  </Col>
                </Row>
              )}
              {values.user_field_04_type > 0 && (
                <Row type="flex" justify="center" gutter={[8, 8]}>
                  <Col span={3}>
                    <Text>{values.user_field_04_label}</Text>
                  </Col>
                  <Col span={1}>
                    <Text>:</Text>
                  </Col>
                  <Col span={20}>
                    {(values.user_field_04_type === UserFieldType.STRING ||
                      values.user_field_04_type === UserFieldType.STRING_UPPER_CASE) && (
                      <Field
                        name="user_field_04_value"
                        component={FormikInput}
                        disabled={disabled || documentIsLoading}
                      />
                    )}
                    {values.user_field_04_type === UserFieldType.DATE && (
                      <Field
                        name="user_field_04_value"
                        component={FormikDatePicker}
                        disabled={disabled || documentIsLoading}
                      />
                    )}
                    {(values.user_field_04_type === UserFieldType.INTEGER ||
                      values.user_field_04_type === UserFieldType.DECIMAL01 ||
                      values.user_field_04_type === UserFieldType.DECIMAL02 ||
                      values.user_field_04_type === UserFieldType.DECIMAL03 ||
                      values.user_field_04_type === UserFieldType.DECIMAL04 ||
                      values.user_field_04_type === UserFieldType.DECIMAL05) && (
                      <Field
                        name="user_field_04_value"
                        component={FormikInputNumber}
                        disabled={disabled || documentIsLoading}
                      />
                    )}
                  </Col>
                </Row>
              )}
              {values.user_field_05_type > 0 && (
                <Row type="flex" justify="center" gutter={[8, 8]}>
                  <Col span={3}>
                    <Text>{values.user_field_05_label}</Text>
                  </Col>
                  <Col span={1}>
                    <Text>:</Text>
                  </Col>
                  <Col span={20}>
                    {(values.user_field_05_type === UserFieldType.STRING ||
                      values.user_field_05_type === UserFieldType.STRING_UPPER_CASE) && (
                      <Field
                        name="user_field_05_value"
                        component={FormikInput}
                        disabled={disabled || documentIsLoading}
                      />
                    )}
                    {values.user_field_05_type === UserFieldType.DATE && (
                      <Field
                        name="user_field_05_value"
                        component={FormikDatePicker}
                        disabled={disabled || documentIsLoading}
                      />
                    )}
                    {(values.user_field_05_type === UserFieldType.INTEGER ||
                      values.user_field_05_type === UserFieldType.DECIMAL01 ||
                      values.user_field_05_type === UserFieldType.DECIMAL02 ||
                      values.user_field_05_type === UserFieldType.DECIMAL03 ||
                      values.user_field_05_type === UserFieldType.DECIMAL04 ||
                      values.user_field_05_type === UserFieldType.DECIMAL05) && (
                      <Field
                        name="user_field_05_value"
                        component={FormikInputNumber}
                        disabled={disabled || documentIsLoading}
                      />
                    )}
                  </Col>
                </Row>
              )}
              {values.user_field_06_type > 0 && (
                <Row type="flex" justify="center" gutter={[8, 8]}>
                  <Col span={3}>
                    <Text>{values.user_field_06_label}</Text>
                  </Col>
                  <Col span={1}>
                    <Text>:</Text>
                  </Col>
                  <Col span={20}>
                    {(values.user_field_06_type === UserFieldType.STRING ||
                      values.user_field_06_type === UserFieldType.STRING_UPPER_CASE) && (
                      <Field
                        name="user_field_06_value"
                        component={FormikInput}
                        disabled={disabled || documentIsLoading}
                      />
                    )}
                    {values.user_field_06_type === UserFieldType.DATE && (
                      <Field
                        name="user_field_06_value"
                        component={FormikDatePicker}
                        disabled={disabled || documentIsLoading}
                      />
                    )}
                    {(values.user_field_06_type === UserFieldType.INTEGER ||
                      values.user_field_06_type === UserFieldType.DECIMAL01 ||
                      values.user_field_06_type === UserFieldType.DECIMAL02 ||
                      values.user_field_06_type === UserFieldType.DECIMAL03 ||
                      values.user_field_06_type === UserFieldType.DECIMAL04 ||
                      values.user_field_06_type === UserFieldType.DECIMAL05) && (
                      <Field
                        name="user_field_06_value"
                        component={FormikInputNumber}
                        disabled={disabled || documentIsLoading}
                      />
                    )}
                  </Col>
                </Row>
              )}
              {values.user_field_07_type > 0 && (
                <Row type="flex" justify="center" gutter={[8, 8]}>
                  <Col span={3}>
                    <Text>{values.user_field_07_label}</Text>
                  </Col>
                  <Col span={1}>
                    <Text>:</Text>
                  </Col>
                  <Col span={20}>
                    {(values.user_field_07_type === UserFieldType.STRING ||
                      values.user_field_07_type === UserFieldType.STRING_UPPER_CASE) && (
                      <Field
                        name="user_field_07_value"
                        component={FormikInput}
                        disabled={disabled || documentIsLoading}
                      />
                    )}
                    {values.user_field_07_type === UserFieldType.DATE && (
                      <Field
                        name="user_field_07_value"
                        component={FormikDatePicker}
                        disabled={disabled || documentIsLoading}
                      />
                    )}
                    {(values.user_field_07_type === UserFieldType.INTEGER ||
                      values.user_field_07_type === UserFieldType.DECIMAL01 ||
                      values.user_field_07_type === UserFieldType.DECIMAL02 ||
                      values.user_field_07_type === UserFieldType.DECIMAL03 ||
                      values.user_field_07_type === UserFieldType.DECIMAL04 ||
                      values.user_field_07_type === UserFieldType.DECIMAL05) && (
                      <Field
                        name="user_field_07_value"
                        component={FormikInputNumber}
                        disabled={disabled || documentIsLoading}
                      />
                    )}
                  </Col>
                </Row>
              )}
              {values.user_field_08_type > 0 && (
                <Row type="flex" justify="center" gutter={[8, 8]}>
                  <Col span={3}>
                    <Text>{values.user_field_08_label}</Text>
                  </Col>
                  <Col span={1}>
                    <Text>:</Text>
                  </Col>
                  <Col span={20}>
                    {(values.user_field_08_type === UserFieldType.STRING ||
                      values.user_field_08_type === UserFieldType.STRING_UPPER_CASE) && (
                      <Field
                        name="user_field_08_value"
                        component={FormikInput}
                        disabled={disabled || documentIsLoading}
                      />
                    )}
                    {values.user_field_08_type === UserFieldType.DATE && (
                      <Field
                        name="user_field_08_value"
                        component={FormikDatePicker}
                        disabled={disabled || documentIsLoading}
                      />
                    )}
                    {(values.user_field_08_type === UserFieldType.INTEGER ||
                      values.user_field_08_type === UserFieldType.DECIMAL01 ||
                      values.user_field_08_type === UserFieldType.DECIMAL02 ||
                      values.user_field_08_type === UserFieldType.DECIMAL03 ||
                      values.user_field_08_type === UserFieldType.DECIMAL04 ||
                      values.user_field_08_type === UserFieldType.DECIMAL05) && (
                      <Field
                        name="user_field_08_value"
                        component={FormikInputNumber}
                        disabled={disabled || documentIsLoading}
                      />
                    )}
                  </Col>
                </Row>
              )}
              {values.user_field_09_type > 0 && (
                <Row type="flex" justify="center" gutter={[8, 8]}>
                  <Col span={3}>
                    <Text>{values.user_field_09_label}</Text>
                  </Col>
                  <Col span={1}>
                    <Text>:</Text>
                  </Col>
                  <Col span={20}>
                    {(values.user_field_09_type === UserFieldType.STRING ||
                      values.user_field_09_type === UserFieldType.STRING_UPPER_CASE) && (
                      <Field
                        name="user_field_09_value"
                        component={FormikInput}
                        disabled={disabled || documentIsLoading}
                      />
                    )}
                    {values.user_field_09_type === UserFieldType.DATE && (
                      <Field
                        name="user_field_09_value"
                        component={FormikDatePicker}
                        disabled={disabled || documentIsLoading}
                      />
                    )}
                    {(values.user_field_09_type === UserFieldType.INTEGER ||
                      values.user_field_09_type === UserFieldType.DECIMAL01 ||
                      values.user_field_09_type === UserFieldType.DECIMAL02 ||
                      values.user_field_09_type === UserFieldType.DECIMAL03 ||
                      values.user_field_09_type === UserFieldType.DECIMAL04 ||
                      values.user_field_09_type === UserFieldType.DECIMAL05) && (
                      <Field
                        name="user_field_09_value"
                        component={FormikInputNumber}
                        disabled={disabled || documentIsLoading}
                      />
                    )}
                  </Col>
                </Row>
              )}
              {values.user_field_10_type > 0 && (
                <Row type="flex" justify="center" gutter={[8, 8]}>
                  <Col span={3}>
                    <Text>{values.user_field_10_label}</Text>
                  </Col>
                  <Col span={1}>
                    <Text>:</Text>
                  </Col>
                  <Col span={20}>
                    {(values.user_field_10_type === UserFieldType.STRING ||
                      values.user_field_10_type === UserFieldType.STRING_UPPER_CASE) && (
                      <Field
                        name="user_field_10_value"
                        component={FormikInput}
                        disabled={disabled || documentIsLoading}
                      />
                    )}
                    {values.user_field_10_type === UserFieldType.DATE && (
                      <Field
                        name="user_field_10_value"
                        component={FormikDatePicker}
                        disabled={disabled || documentIsLoading}
                      />
                    )}
                    {(values.user_field_10_type === UserFieldType.INTEGER ||
                      values.user_field_10_type === UserFieldType.DECIMAL01 ||
                      values.user_field_10_type === UserFieldType.DECIMAL02 ||
                      values.user_field_10_type === UserFieldType.DECIMAL03 ||
                      values.user_field_10_type === UserFieldType.DECIMAL04 ||
                      values.user_field_10_type === UserFieldType.DECIMAL05) && (
                      <Field
                        name="user_field_10_value"
                        component={FormikInputNumber}
                        disabled={disabled || documentIsLoading}
                      />
                    )}
                  </Col>
                </Row>
              )}

              <Row type="flex" justify="space-between" gutter={[0, 16]}>
                <Col span={8}>
                  <Button
                    type="primary"
                    // disabled={!isValid}
                    loading={isSubmitting}
                    onClick={historyGoBack}
                    icon={<ArrowLeftOutlined />}
                  >
                    {intl.formatMessage({
                      id: 'back'
                    })}
                  </Button>
                </Col>
                <Col span={6}>
                  <Button
                    type="primary"
                    disabled={!dirty}
                    loading={isSubmitting || documentIsLoading}
                    onClick={handleReset}
                    icon={<UndoOutlined />}
                  >
                    {intl.formatMessage({ id: 'reset' })}
                  </Button>
                  <Field
                    name="submit_action"
                    type="primary"
                    value="update"
                    component={FormikButton}
                    disabled={values.doc_status >= 50}
                    loading={isSubmitting || documentIsLoading}
                    onClick={handleSubmit}
                    label={intl.formatMessage({
                      id: match.params.action === 'create' ? 'create' : 'update'
                    })}
                    icon={<SaveOutlined />}
                  />
                </Col>
              </Row>
            </Card>
          </Form>
        )}
      </Formik>
    );
  }
}

HeaderForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object,
  initHeader: PropTypes.func,
  showHeader: PropTypes.func,
  transitionToStatus: PropTypes.func,
  createHeader: PropTypes.func,
  updateHeader: PropTypes.func,
  historyGoBack: PropTypes.func,
  hdrId: PropTypes.number,
  documentHeader: PropTypes.shape({
    doc_status: PropTypes.number,
    user_field_01_label: PropTypes.string,
    user_field_01_type: PropTypes.number,
    user_field_01_mode: PropTypes.number,
    user_field_01_value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    user_field_02_label: PropTypes.string,
    user_field_02_type: PropTypes.number,
    user_field_02_mode: PropTypes.number,
    user_field_02_value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    user_field_03_label: PropTypes.string,
    user_field_03_type: PropTypes.number,
    user_field_03_mode: PropTypes.number,
    user_field_03_value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    user_field_04_label: PropTypes.string,
    user_field_04_type: PropTypes.number,
    user_field_04_mode: PropTypes.number,
    user_field_04_value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    user_field_05_label: PropTypes.string,
    user_field_05_type: PropTypes.number,
    user_field_05_mode: PropTypes.number,
    user_field_05_value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    user_field_06_label: PropTypes.string,
    user_field_06_type: PropTypes.number,
    user_field_06_mode: PropTypes.number,
    user_field_06_value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    user_field_07_label: PropTypes.string,
    user_field_07_type: PropTypes.number,
    user_field_07_mode: PropTypes.number,
    user_field_07_value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    user_field_08_label: PropTypes.string,
    user_field_08_type: PropTypes.number,
    user_field_08_mode: PropTypes.number,
    user_field_08_value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    user_field_09_label: PropTypes.string,
    user_field_09_type: PropTypes.number,
    user_field_09_mode: PropTypes.number,
    user_field_09_value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    user_field_10_label: PropTypes.string,
    user_field_10_type: PropTypes.number,
    user_field_10_mode: PropTypes.number,
    user_field_10_value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  }),
  documentIsLoading: PropTypes.bool
};

HeaderForm.defaultProps = {
  intl: {},
  match: {},
  initHeader() {},
  showHeader() {},
  transitionToStatus() {},
  createHeader() {},
  updateHeader() {},
  historyGoBack() {},
  hdrId: 0,
  documentHeader: {
    doc_status: 3,
    user_field_01_label: '',
    user_field_01_type: 0,
    user_field_01_mode: 0,
    user_field_01_value: '',
    user_field_02_label: '',
    user_field_02_type: 0,
    user_field_02_mode: 0,
    user_field_02_value: '',
    user_field_03_label: '',
    user_field_03_type: 0,
    user_field_03_mode: 0,
    user_field_03_value: '',
    user_field_04_label: '',
    user_field_04_type: 0,
    user_field_04_mode: 0,
    user_field_04_value: '',
    user_field_05_label: '',
    user_field_05_type: 0,
    user_field_05_mode: 0,
    user_field_05_value: '',
    user_field_06_label: '',
    user_field_06_type: 0,
    user_field_06_mode: 0,
    user_field_06_value: '',
    user_field_07_label: '',
    user_field_07_type: 0,
    user_field_07_mode: 0,
    user_field_07_value: '',
    user_field_08_label: '',
    user_field_08_type: 0,
    user_field_08_mode: 0,
    user_field_08_value: '',
    user_field_09_label: '',
    user_field_09_type: 0,
    user_field_09_mode: 0,
    user_field_09_value: '',
    user_field_10_label: '',
    user_field_10_type: 0,
    user_field_10_mode: 0,
    user_field_10_value: ''
  },
  documentIsLoading: false
};

const mapStateToProps = state => ({
  apiUrl: state.app.apiUrl,
  timestamp: state.gdsRcptDetail.timestamp,
  hdrId: state.gdsRcptDetail.hdrId,
  documentHeader: state.gdsRcptDetail.documentHeader,
  documentIsLoading: state.gdsRcptDetail.documentIsLoading
});

const mapDispatchToProps = dispatch => ({
  initHeader: () => dispatch(GdsRcptDetailActions.gdsRcptDetailInitHeader()),
  showHeader: hdrId => dispatch(GdsRcptDetailActions.gdsRcptDetailShowHeader(hdrId)),
  transitionToStatus: (formikBag, hdrId, docStatus) =>
    dispatch(GdsRcptDetailActions.gdsRcptDetailTransitionToStatus(formikBag, hdrId, docStatus)),
  updateHeader: (formikBag, documentHeader) =>
    dispatch(GdsRcptDetailActions.gdsRcptDetailUpdateHeader(formikBag, documentHeader)),
  createHeader: (formikBag, documentHeader) =>
    dispatch(GdsRcptDetailActions.gdsRcptDetailCreateHeader(formikBag, documentHeader)),
  historyGoBack: () => dispatch(AppActions.appHistoryGoBack())
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(HeaderForm));
