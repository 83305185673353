/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { WorkerPickListAnalysisReportTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const workerPickListAnalysisReportReportLoading = (state, { boolean }) => ({
  ...state,
  reportIsLoading: boolean
});

export const workerPickListAnalysisReportInitWorkerPickListAnalysisSuccess = (
  state,
  { criteria }
) => ({
  ...state,
  criteria
});

export const workerPickListAnalysisReportWorkerPickListAnalysisSuccess = (
  state,
  { criteria, reportData }
) => ({
  ...state,
  criteria,
  reportData
});

export const workerPickListAnalysisReportFetchUserOptionLoading = (state, { boolean }) => ({
  ...state,
  userIsLoading: boolean
});

export const workerPickListAnalysisReportFetchUserOptionSuccess = (state, { options }) => ({
  ...state,
  userOptions: options
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [WorkerPickListAnalysisReportTypes.WORKER_PICK_LIST_ANALYSIS_REPORT_REPORT_LOADING]: workerPickListAnalysisReportReportLoading,
  [WorkerPickListAnalysisReportTypes.WORKER_PICK_LIST_ANALYSIS_REPORT_INIT_WORKER_PICK_LIST_ANALYSIS_SUCCESS]: workerPickListAnalysisReportInitWorkerPickListAnalysisSuccess,
  [WorkerPickListAnalysisReportTypes.WORKER_PICK_LIST_ANALYSIS_REPORT_WORKER_PICK_LIST_ANALYSIS_SUCCESS]: workerPickListAnalysisReportWorkerPickListAnalysisSuccess,

  [WorkerPickListAnalysisReportTypes.WORKER_PICK_LIST_ANALYSIS_REPORT_FETCH_USER_OPTION_LOADING]: workerPickListAnalysisReportFetchUserOptionLoading,
  [WorkerPickListAnalysisReportTypes.WORKER_PICK_LIST_ANALYSIS_REPORT_FETCH_USER_OPTION_SUCCESS]: workerPickListAnalysisReportFetchUserOptionSuccess
});
