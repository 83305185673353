import React from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';

import { UndoOutlined, PlayCircleOutlined } from '@ant-design/icons';

import { Button, Row, Col, Typography } from 'antd';
import {
  FormikTransfer,
  FormikDatePicker,
  FormikSelect,
  FormikButton
} from '../../Components/Formik';
import OutOfStockAnalysisReportActions from '../../Stores/OutOfStockAnalysisReport/Actions';

const { Text } = Typography;

class CriteriaForm extends React.PureComponent {
  constructor() {
    super();

    this.handleSearchItem = this.handleSearchItem.bind(this);
    this.handleSearchItemGroup01 = this.handleSearchItemGroup01.bind(this);
    this.handleSearchItemGroup02 = this.handleSearchItemGroup02.bind(this);
    this.handleSearchItemGroup03 = this.handleSearchItemGroup03.bind(this);

    this.handleSearchDivision = this.handleSearchDivision.bind(this);
    this.handleSearchDeliveryPoint = this.handleSearchDeliveryPoint.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate() {}

  componentWillUnmount() {}

  handleSearchItem(form, value) {
    const { fetchItemOptions } = this.props;

    fetchItemOptions(value);
  }

  handleSearchItemGroup01(form, value) {
    const { fetchItemGroup01Options } = this.props;

    fetchItemGroup01Options(value);
  }

  handleSearchItemGroup02(form, value) {
    const { fetchItemGroup02Options } = this.props;

    fetchItemGroup02Options(value);
  }

  handleSearchItemGroup03(form, value) {
    const { fetchItemGroup03Options } = this.props;

    fetchItemGroup03Options(value);
  }

  handleSearchDivision(form, value) {
    const { fetchDivisionOptions } = this.props;

    fetchDivisionOptions(value);
  }

  handleSearchDeliveryPoint(form, value) {
    const { fetchDeliveryPointOptions } = this.props;

    fetchDeliveryPointOptions(value);
  }

  render() {
    const {
      intl,
      criteria,
      reportIsLoading,
      outOfStockAnalysis,
      itemOptions,
      itemIsLoading,
      itemGroup01Options,
      itemGroup01IsLoading,
      itemGroup02Options,
      itemGroup02IsLoading,
      itemGroup03Options,
      itemGroup03IsLoading,
      divisionOptions,
      divisionIsLoading,
      deliveryPointOptions,
      deliveryPointIsLoading
    } = this.props;

    return (
      <Formik
        enableReinitialize
        initialValues={criteria}
        onSubmit={(values, formikBag) => {
          outOfStockAnalysis(formikBag, values);
        }}
        validationSchema={Yup.object().shape({
          doc_date: Yup.date().min(
            new Date(1969, 12, 1),
            intl.formatMessage({ id: 'doc_date_is_required' })
          )
        })}
      >
        {({
          // values,
          // handleChange,
          // errors,
          // setFieldTouched,
          dirty,
          // touched,
          isSubmitting,
          // isValid,
          handleSubmit,
          handleReset
        }) => (
          <Form>
            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'start_date' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="start_date"
                  component={FormikDatePicker}
                  disabled={isSubmitting || reportIsLoading}
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'end_date' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="end_date"
                  component={FormikDatePicker}
                  disabled={isSubmitting || reportIsLoading}
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'division' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="division_ids_select2"
                  component={FormikSelect}
                  showArrow
                  filterOption={false}
                  onSearch={this.handleSearchDivision}
                  notFoundContent={null}
                  loading={divisionIsLoading}
                  options={divisionOptions}
                  disabled={isSubmitting || reportIsLoading}
                  selectMode="multiple"
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'delivery_point' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="delivery_point_ids_select2"
                  component={FormikSelect}
                  showArrow
                  filterOption={false}
                  onSearch={this.handleSearchDeliveryPoint}
                  notFoundContent={null}
                  loading={deliveryPointIsLoading}
                  options={deliveryPointOptions}
                  disabled={isSubmitting || reportIsLoading}
                  selectMode="multiple"
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'item' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="item_ids_select2"
                  component={FormikSelect}
                  showArrow
                  filterOption={false}
                  onSearch={this.handleSearchItem}
                  notFoundContent={null}
                  loading={itemIsLoading}
                  options={itemOptions}
                  disabled={isSubmitting || reportIsLoading}
                  selectMode="multiple"
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'brand' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="item_group_01_ids_select2"
                  component={FormikSelect}
                  showArrow
                  filterOption={false}
                  onSearch={this.handleSearchItemGroup01}
                  notFoundContent={null}
                  loading={itemGroup01IsLoading}
                  options={itemGroup01Options}
                  disabled={isSubmitting || reportIsLoading}
                  selectMode="multiple"
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'category' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="item_group_02_ids_select2"
                  component={FormikSelect}
                  showArrow
                  filterOption={false}
                  onSearch={this.handleSearchItemGroup02}
                  notFoundContent={null}
                  loading={itemGroup02IsLoading}
                  options={itemGroup02Options}
                  disabled={isSubmitting || reportIsLoading}
                  selectMode="multiple"
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'manufacturer' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="item_group_03_ids_select2"
                  component={FormikSelect}
                  showArrow
                  filterOption={false}
                  onSearch={this.handleSearchItemGroup03}
                  notFoundContent={null}
                  loading={itemGroup03IsLoading}
                  options={itemGroup03Options}
                  disabled={isSubmitting || reportIsLoading}
                  selectMode="multiple"
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={24}>
                <Field
                  name="columns"
                  component={FormikTransfer}
                  intl={intl}
                  disabled={isSubmitting || reportIsLoading}
                />
              </Col>
            </Row>

            <Row type="flex" justify="space-between" gutter={[0, 16]}>
              <Col span={24}>
                <Button
                  disabled={!dirty}
                  loading={isSubmitting || reportIsLoading}
                  onClick={handleReset}
                  icon={<UndoOutlined />}
                  type="primary"
                >
                  {intl.formatMessage({ id: 'reset' })}
                </Button>
                <Field
                  name="submit_action"
                  value="run_report"
                  component={FormikButton}
                  loading={isSubmitting || reportIsLoading}
                  onClick={handleSubmit}
                  label={intl.formatMessage({
                    id: 'run_report'
                  })}
                  icon={<PlayCircleOutlined />}
                  type="primary"
                />
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    );
  }
}

CriteriaForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,

  // eslint-disable-next-line react/forbid-prop-types
  criteria: PropTypes.object,
  reportIsLoading: PropTypes.bool,
  outOfStockAnalysis: PropTypes.func,
  fetchItemOptions: PropTypes.func,
  fetchItemGroup01Options: PropTypes.func,
  fetchItemGroup02Options: PropTypes.func,
  fetchItemGroup03Options: PropTypes.func,
  fetchDivisionOptions: PropTypes.func,
  fetchDeliveryPointOptions: PropTypes.func,

  itemOptions: PropTypes.arrayOf(PropTypes.object),
  itemIsLoading: PropTypes.bool,

  itemGroup01Options: PropTypes.arrayOf(PropTypes.object),
  itemGroup01IsLoading: PropTypes.bool,

  itemGroup02Options: PropTypes.arrayOf(PropTypes.object),
  itemGroup02IsLoading: PropTypes.bool,

  itemGroup03Options: PropTypes.arrayOf(PropTypes.object),
  itemGroup03IsLoading: PropTypes.bool,

  divisionOptions: PropTypes.arrayOf(PropTypes.object),
  divisionIsLoading: PropTypes.bool,

  deliveryPointOptions: PropTypes.arrayOf(PropTypes.object),
  deliveryPointIsLoading: PropTypes.bool
};

CriteriaForm.defaultProps = {
  intl: {},

  criteria: {},
  reportIsLoading: false,
  outOfStockAnalysis() {},
  fetchItemOptions() {},
  fetchItemGroup01Options() {},
  fetchItemGroup02Options() {},
  fetchItemGroup03Options() {},
  fetchDivisionOptions() {},
  fetchDeliveryPointOptions() {},

  itemOptions: [],
  itemIsLoading: false,

  itemGroup01Options: [],
  itemGroup01IsLoading: false,

  itemGroup02Options: [],
  itemGroup02IsLoading: false,

  itemGroup03Options: [],
  itemGroup03IsLoading: false,

  divisionOptions: [],
  divisionIsLoading: false,

  deliveryPointOptions: [],
  deliveryPointIsLoading: false
};

const mapStateToProps = state => ({
  curSiteFlowId: state.app.curSiteFlowId,
  timestamp: state.outOfStockAnalysisReport.timestamp,
  criteria: state.outOfStockAnalysisReport.criteria,

  reportIsLoading: state.outOfStockAnalysisReport.reportIsLoading,

  itemOptions: state.outOfStockAnalysisReport.itemOptions,
  itemIsLoading: state.outOfStockAnalysisReport.itemIsLoading,

  itemGroup01Options: state.outOfStockAnalysisReport.itemGroup01Options,
  itemGroup01IsLoading: state.outOfStockAnalysisReport.itemGroup01IsLoading,

  itemGroup02Options: state.outOfStockAnalysisReport.itemGroup02Options,
  itemGroup02IsLoading: state.outOfStockAnalysisReport.itemGroup02IsLoading,

  itemGroup03Options: state.outOfStockAnalysisReport.itemGroup03Options,
  itemGroup03IsLoading: state.outOfStockAnalysisReport.itemGroup03IsLoading,

  divisionOptions: state.outOfStockAnalysisReport.divisionOptions,
  divisionIsLoading: state.outOfStockAnalysisReport.divisionIsLoading,

  deliveryPointOptions: state.outOfStockAnalysisReport.deliveryPointOptions,
  deliveryPointIsLoading: state.outOfStockAnalysisReport.deliveryPointIsLoading
});

const mapDispatchToProps = dispatch => ({
  outOfStockAnalysis: (formikBag, criteria) =>
    dispatch(
      OutOfStockAnalysisReportActions.outOfStockAnalysisReportOutOfStockAnalysis(
        formikBag,
        criteria
      )
    ),

  fetchItemOptions: search =>
    dispatch(OutOfStockAnalysisReportActions.outOfStockAnalysisReportFetchItemOptions(search)),
  fetchItemGroup01Options: search =>
    dispatch(
      OutOfStockAnalysisReportActions.outOfStockAnalysisReportFetchItemGroup01Options(search)
    ),
  fetchItemGroup02Options: search =>
    dispatch(
      OutOfStockAnalysisReportActions.outOfStockAnalysisReportFetchItemGroup02Options(search)
    ),
  fetchItemGroup03Options: search =>
    dispatch(
      OutOfStockAnalysisReportActions.outOfStockAnalysisReportFetchItemGroup03Options(search)
    ),

  fetchDivisionOptions: search =>
    dispatch(OutOfStockAnalysisReportActions.outOfStockAnalysisReportFetchDivisionOptions(search)),
  fetchDeliveryPointOptions: search =>
    dispatch(
      OutOfStockAnalysisReportActions.outOfStockAnalysisReportFetchDeliveryPointOptions(search)
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CriteriaForm));
