import { put, call, select, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import ApiService from '../Services/ApiService';
import AppActions from '../Stores/App/Actions';
import SlsOrdDetailBoardActions, {
  SlsOrdDetailBoardTypes
} from '../Stores/SlsOrdDetailBoard/Actions';

const getAppStore = state => state.app;

export function* slsOrdDetailBoardFetchWipSlsOrd({ currentPage, sorts, filters, pageSize }) {
  try {
    yield put(SlsOrdDetailBoardActions.slsOrdDetailBoardFetchWipSlsOrdLoading(true));

    const processedSorts = [];
    Object.entries(sorts).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      processedSorts.push(`${key}:${value === 'ascend' ? 'ASC' : 'DESC'}`);
    });

    const processedFilters = [];
    Object.entries(filters).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      if (value) {
        processedFilters.push(`${key}:${value}`);
      }
    });

    const app = yield select(getAppStore);
    const getData = {
      page: currentPage,
      sorts: processedSorts,
      filters: processedFilters,
      pageSize
    };

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `outbOrd/indexSlsOrdToDoOutbOrd/${app.curSiteFlowId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      // if nowCurrentPage is more than lastPage, then nowCurrentPage = lastPage
      let nowCurrentPage = result.data.current_page;
      if (nowCurrentPage > result.data.last_page) {
        nowCurrentPage = result.data.last_page;
      }

      yield put(
        SlsOrdDetailBoardActions.slsOrdDetailBoardFetchWipSlsOrdSuccess(
          result.data.data,
          nowCurrentPage,
          result.data.last_page,
          result.data.total,
          result.data.per_page
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(SlsOrdDetailBoardActions.slsOrdDetailBoardFetchWipSlsOrdLoading(false));
  }
}

export function* slsOrdDetailBoardFetchWipOutbOrd({ currentPage, sorts, filters, pageSize }) {
  try {
    yield put(SlsOrdDetailBoardActions.slsOrdDetailBoardFetchWipOutbOrdLoading(true));

    const processedSorts = [];
    Object.entries(sorts).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      processedSorts.push(`${key}:${value === 'ascend' ? 'ASC' : 'DESC'}`);
    });

    const processedFilters = [];
    Object.entries(filters).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      if (value) {
        processedFilters.push(`${key}:${value}`);
      }
    });

    const app = yield select(getAppStore);
    const getData = {
      page: currentPage,
      sorts: processedSorts,
      filters: processedFilters,
      pageSize
    };

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `outbOrd/indexSlsOrdToDoInvDoc/${app.curSiteFlowId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      // if nowCurrentPage is more than lastPage, then nowCurrentPage = lastPage
      let nowCurrentPage = result.data.current_page;
      if (nowCurrentPage > result.data.last_page) {
        nowCurrentPage = result.data.last_page;
      }

      yield put(
        SlsOrdDetailBoardActions.slsOrdDetailBoardFetchWipOutbOrdSuccess(
          result.data.data,
          nowCurrentPage,
          result.data.last_page,
          result.data.total,
          result.data.per_page
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(SlsOrdDetailBoardActions.slsOrdDetailBoardFetchWipOutbOrdLoading(false));
  }
}

export function* slsOrdDetailBoardFetchWipInvDoc({ currentPage, sorts, filters, pageSize }) {
  try {
    yield put(SlsOrdDetailBoardActions.slsOrdDetailBoardFetchWipInvDocLoading(true));

    const processedSorts = [];
    Object.entries(sorts).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      processedSorts.push(`${key}:${value === 'ascend' ? 'ASC' : 'DESC'}`);
    });

    const processedFilters = [];
    Object.entries(filters).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      if (value) {
        processedFilters.push(`${key}:${value}`);
      }
    });

    const app = yield select(getAppStore);
    const getData = {
      page: currentPage,
      sorts: processedSorts,
      filters: processedFilters,
      pageSize
    };

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `outbOrd/indexSlsOrdInvDocToDoLoadList/${app.curSiteFlowId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      // if nowCurrentPage is more than lastPage, then nowCurrentPage = lastPage
      let nowCurrentPage = result.data.current_page;
      if (nowCurrentPage > result.data.last_page) {
        nowCurrentPage = result.data.last_page;
      }

      yield put(
        SlsOrdDetailBoardActions.slsOrdDetailBoardFetchWipInvDocSuccess(
          result.data.data,
          nowCurrentPage,
          result.data.last_page,
          result.data.total,
          result.data.per_page
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(SlsOrdDetailBoardActions.slsOrdDetailBoardFetchWipInvDocLoading(false));
  }
}

export function* slsOrdDetailBoardFetchCompleteLoadList({ currentPage, sorts, filters, pageSize }) {
  try {
    yield put(SlsOrdDetailBoardActions.slsOrdDetailBoardFetchCompleteLoadListLoading(true));

    const processedSorts = [];
    Object.entries(sorts).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      processedSorts.push(`${key}:${value === 'ascend' ? 'ASC' : 'DESC'}`);
    });

    const processedFilters = [];
    Object.entries(filters).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      if (value) {
        processedFilters.push(`${key}:${value}`);
      }
    });

    const app = yield select(getAppStore);
    const getData = {
      page: currentPage,
      sorts: processedSorts,
      filters: processedFilters,
      pageSize
    };

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `outbOrd/indexSlsOrdInvDocToDoPrfDel/${app.curSiteFlowId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      // if nowCurrentPage is more than lastPage, then nowCurrentPage = lastPage
      let nowCurrentPage = result.data.current_page;
      if (nowCurrentPage > result.data.last_page) {
        nowCurrentPage = result.data.last_page;
      }

      yield put(
        SlsOrdDetailBoardActions.slsOrdDetailBoardFetchCompleteLoadListSuccess(
          result.data.data,
          nowCurrentPage,
          result.data.last_page,
          result.data.total,
          result.data.per_page
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(SlsOrdDetailBoardActions.slsOrdDetailBoardFetchCompleteLoadListLoading(false));
  }
}

export function* slsOrdDetailBoardShowWipSlsOrdSummary({ docId }) {
  try {
    yield put(SlsOrdDetailBoardActions.slsOrdDetailBoardShowWipSlsOrdSummaryLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `slsOrd/showSummary/${docId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(SlsOrdDetailBoardActions.slsOrdDetailBoardShowWipSlsOrdSummarySuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(SlsOrdDetailBoardActions.slsOrdDetailBoardShowWipSlsOrdSummaryLoading(false));
  }
}

export function* slsOrdDetailBoardShowWipOutbOrdSummary({ docId }) {
  try {
    yield put(SlsOrdDetailBoardActions.slsOrdDetailBoardShowWipOutbOrdSummaryLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `outbOrd/showSummary/${docId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(
        SlsOrdDetailBoardActions.slsOrdDetailBoardShowWipOutbOrdSummarySuccess(result.data)
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(SlsOrdDetailBoardActions.slsOrdDetailBoardShowWipOutbOrdSummaryLoading(false));
  }
}

export function* slsOrdDetailBoardShowWipInvDocSummary({ docId }) {
  try {
    yield put(SlsOrdDetailBoardActions.slsOrdDetailBoardShowWipInvDocSummaryLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `outbOrd/showSummary/${docId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(SlsOrdDetailBoardActions.slsOrdDetailBoardShowWipInvDocSummarySuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(SlsOrdDetailBoardActions.slsOrdDetailBoardShowWipInvDocSummaryLoading(false));
  }
}

export function* slsOrdDetailBoardShowCompleteLoadListSummary({ docId }) {
  try {
    yield put(SlsOrdDetailBoardActions.slsOrdDetailBoardShowCompleteLoadListSummaryLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `outbOrd/showSummary/${docId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(
        SlsOrdDetailBoardActions.slsOrdDetailBoardShowCompleteLoadListSummarySuccess(result.data)
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(SlsOrdDetailBoardActions.slsOrdDetailBoardShowCompleteLoadListSummaryLoading(false));
  }
}

export const saga = [
  takeLatest(
    SlsOrdDetailBoardTypes.SLS_ORD_DETAIL_BOARD_FETCH_WIP_SLS_ORD,
    slsOrdDetailBoardFetchWipSlsOrd
  ),

  takeLatest(
    SlsOrdDetailBoardTypes.SLS_ORD_DETAIL_BOARD_FETCH_WIP_OUTB_ORD,
    slsOrdDetailBoardFetchWipOutbOrd
  ),

  takeLatest(
    SlsOrdDetailBoardTypes.SLS_ORD_DETAIL_BOARD_FETCH_WIP_INV_DOC,
    slsOrdDetailBoardFetchWipInvDoc
  ),

  takeLatest(
    SlsOrdDetailBoardTypes.SLS_ORD_DETAIL_BOARD_FETCH_COMPLETE_LOAD_LIST,
    slsOrdDetailBoardFetchCompleteLoadList
  ),

  takeLatest(
    SlsOrdDetailBoardTypes.SLS_ORD_DETAIL_BOARD_SHOW_WIP_SLS_ORD_SUMMARY,
    slsOrdDetailBoardShowWipSlsOrdSummary
  ),

  takeLatest(
    SlsOrdDetailBoardTypes.SLS_ORD_DETAIL_BOARD_SHOW_WIP_OUTB_ORD_SUMMARY,
    slsOrdDetailBoardShowWipOutbOrdSummary
  ),

  takeLatest(
    SlsOrdDetailBoardTypes.SLS_ORD_DETAIL_BOARD_SHOW_WIP_INV_DOC_SUMMARY,
    slsOrdDetailBoardShowWipInvDocSummary
  ),

  takeLatest(
    SlsOrdDetailBoardTypes.SLS_ORD_DETAIL_BOARD_SHOW_COMPLETE_LOAD_LIST_SUMMARY,
    slsOrdDetailBoardShowCompleteLoadListSummary
  )
];
