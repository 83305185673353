/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { AreaList01Types } from './Actions';
import INITIAL_STATE from './InitialState';

export const areaList01ResetTimestamp = (state, { currentPage, sorts, filters, pageSize }) => ({
  ...state,
  currentPage: currentPage || state.currentPage,
  sorts: sorts || state.sorts,
  filters: filters || state.filters,
  pageSize: pageSize || state.pageSize,
  timestamp: Date.now(),
  selectedResources: INITIAL_STATE.selectedResources,
  toStorageBinOptions: INITIAL_STATE.toStorageBinOptions,
  toStorageBinOption: INITIAL_STATE.toStorageBinOption
});

export const areaList01FetchAreaList01Loading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const areaList01FetchAreaList01Success = (
  state,
  { resources, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  resources,
  currentPage,
  lastPage,
  total,
  pageSize
});

export const areaList01AddSelectedResources = (state, { selectedResources }) => {
  const selectIds = state.selectedResources.map(value => value.id);
  return {
    ...state,
    selectedResources: [
      ...state.selectedResources,
      ...selectedResources.filter(data => {
        return !selectIds.includes(data.id);
      })
    ]
  };
};

export const areaList01RemoveSelectedResources = (state, { selectedResources }) => {
  const selectIds = selectedResources.map(value => value.id);
  return {
    ...state,
    selectedResources: state.selectedResources.filter(data => {
      return !selectIds.includes(data.id);
    })
  };
};

export const areaList01SetWorkspaceVisible = (state, { boolean }) => ({
  ...state,
  workspaceIsVisible: boolean
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [AreaList01Types.AREA_LIST01_RESET_TIMESTAMP]: areaList01ResetTimestamp,
  [AreaList01Types.AREA_LIST01_FETCH_AREA_LIST01_LOADING]: areaList01FetchAreaList01Loading,
  [AreaList01Types.AREA_LIST01_FETCH_AREA_LIST01_SUCCESS]: areaList01FetchAreaList01Success,
  [AreaList01Types.AREA_LIST01_ADD_SELECTED_RESOURCES]: areaList01AddSelectedResources,
  [AreaList01Types.AREA_LIST01_REMOVE_SELECTED_RESOURCES]: areaList01RemoveSelectedResources,
  [AreaList01Types.AREA_LIST01_SET_WORKSPACE_VISIBLE]: areaList01SetWorkspaceVisible
});
