import { put, call, select, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import ApiService from '../Services/ApiService';
import AppActions from '../Stores/App/Actions';
import SlsOrdExcel01Actions, { SlsOrdExcel01Types } from '../Stores/SlsOrdExcel01/Actions';

const getAppStore = state => state.app;

const getSlsOrdExcel01Store = state => state.slsOrdExcel01;

export function* slsOrdExcel01ShowBatchJobStatus() {
  try {
    const app = yield select(getAppStore);
    const slsOrdExcel01 = yield select(getSlsOrdExcel01Store);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `batchJobStatus/showBatchJobStatus/${slsOrdExcel01.strProcType}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(SlsOrdExcel01Actions.slsOrdExcel01ShowBatchJobStatusSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  }
}

export function* slsOrdExcel01UploadExcel({ formikBag, file }) {
  try {
    formikBag.setSubmitting(true);
    yield put(SlsOrdExcel01Actions.slsOrdExcel01UploadLoading(true));

    const app = yield select(getAppStore);

    yield put(SlsOrdExcel01Actions.slsOrdExcel01SetStrProcType('SLS_ORD_EXCEL_01'));
    // eslint-disable-next-line no-undef
    const postData = new FormData();
    postData.append('file1', file);

    const getData = {};

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `slsOrd/uploadProcess/SLS_ORD_EXCEL_01/${app.curSiteFlowId}/${app.curDivisionId}`,
      app.token,
      postData,
      getData,
      'multipart/form-data'
    );

    if (result.isSuccess === true) {
      // result.data is total
      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_SUCCESS_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
    yield put(SlsOrdExcel01Actions.slsOrdExcel01UploadLoading(false));
  }
}

export const saga = [
  takeLatest(
    SlsOrdExcel01Types.SLS_ORD_EXCEL01_SHOW_BATCH_JOB_STATUS,
    slsOrdExcel01ShowBatchJobStatus
  ),
  takeLatest(SlsOrdExcel01Types.SLS_ORD_EXCEL01_UPLOAD_EXCEL, slsOrdExcel01UploadExcel)
];
