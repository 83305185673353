import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  lspSlsOrdFulfillmentChartResetTimestamp: ['criteria'],

  lspSlsOrdFulfillmentChartFetchIsLoading: ['boolean'],
  lspSlsOrdFulfillmentChartFetch: ['criteria'],
  lspSlsOrdFulfillmentChartFetchSuccess: ['data'],

  lspSlsOrdFulfillmentChartChangeProcess: ['formikBag', 'process'],

  lspSlsOrdFulfillmentChartSetVisibleAverageKeys: ['visibleKeys']
});

export const LspSlsOrdFulfillmentChartTypes = Types;
export default Creators;
