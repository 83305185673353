import { put, call, select, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import ApiService from '../Services/ApiService';
import AppActions from '../Stores/App/Actions';
import LspSlsOrdDetailBoardActions, {
  LspSlsOrdDetailBoardTypes
} from '../Stores/LspSlsOrdDetailBoard/Actions';

const getAppStore = state => state.app;

export function* lspSlsOrdDetailBoardFetchWipLspSlsOrd({ currentPage, sorts, filters, pageSize }) {
  try {
    yield put(LspSlsOrdDetailBoardActions.lspSlsOrdDetailBoardFetchWipLspSlsOrdLoading(true));

    const processedSorts = [];
    Object.entries(sorts).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      processedSorts.push(`${key}:${value === 'ascend' ? 'ASC' : 'DESC'}`);
    });

    const processedFilters = [];
    Object.entries(filters).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      if (value) {
        processedFilters.push(`${key}:${value}`);
      }
    });

    const app = yield select(getAppStore);
    const getData = {
      page: currentPage,
      sorts: processedSorts,
      filters: processedFilters,
      pageSize
    };

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `outbOrd/indexLspSlsOrdToDoOutbOrd/${app.curSiteFlowId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      // if nowCurrentPage is more than lastPage, then nowCurrentPage = lastPage
      let nowCurrentPage = result.data.current_page;
      if (nowCurrentPage > result.data.last_page) {
        nowCurrentPage = result.data.last_page;
      }

      yield put(
        LspSlsOrdDetailBoardActions.lspSlsOrdDetailBoardFetchWipLspSlsOrdSuccess(
          result.data.data,
          nowCurrentPage,
          result.data.last_page,
          result.data.total,
          result.data.per_page
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(LspSlsOrdDetailBoardActions.lspSlsOrdDetailBoardFetchWipLspSlsOrdLoading(false));
  }
}

export function* lspSlsOrdDetailBoardFetchWipOutbOrd({ currentPage, sorts, filters, pageSize }) {
  try {
    yield put(LspSlsOrdDetailBoardActions.lspSlsOrdDetailBoardFetchWipOutbOrdLoading(true));

    const processedSorts = [];
    Object.entries(sorts).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      processedSorts.push(`${key}:${value === 'ascend' ? 'ASC' : 'DESC'}`);
    });

    const processedFilters = [];
    Object.entries(filters).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      if (value) {
        processedFilters.push(`${key}:${value}`);
      }
    });

    const app = yield select(getAppStore);
    const getData = {
      page: currentPage,
      sorts: processedSorts,
      filters: processedFilters,
      pageSize
    };

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `outbOrd/indexLspSlsOrdToDoInvDoc/${app.curSiteFlowId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      // if nowCurrentPage is more than lastPage, then nowCurrentPage = lastPage
      let nowCurrentPage = result.data.current_page;
      if (nowCurrentPage > result.data.last_page) {
        nowCurrentPage = result.data.last_page;
      }

      yield put(
        LspSlsOrdDetailBoardActions.lspSlsOrdDetailBoardFetchWipOutbOrdSuccess(
          result.data.data,
          nowCurrentPage,
          result.data.last_page,
          result.data.total,
          result.data.per_page
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(LspSlsOrdDetailBoardActions.lspSlsOrdDetailBoardFetchWipOutbOrdLoading(false));
  }
}

export function* lspSlsOrdDetailBoardFetchWipInvDoc({ currentPage, sorts, filters, pageSize }) {
  try {
    yield put(LspSlsOrdDetailBoardActions.lspSlsOrdDetailBoardFetchWipInvDocLoading(true));

    const processedSorts = [];
    Object.entries(sorts).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      processedSorts.push(`${key}:${value === 'ascend' ? 'ASC' : 'DESC'}`);
    });

    const processedFilters = [];
    Object.entries(filters).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      if (value) {
        processedFilters.push(`${key}:${value}`);
      }
    });

    const app = yield select(getAppStore);
    const getData = {
      page: currentPage,
      sorts: processedSorts,
      filters: processedFilters,
      pageSize
    };

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `outbOrd/indexLspSlsOrdInvDocToDoLoadList/${app.curSiteFlowId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      // if nowCurrentPage is more than lastPage, then nowCurrentPage = lastPage
      let nowCurrentPage = result.data.current_page;
      if (nowCurrentPage > result.data.last_page) {
        nowCurrentPage = result.data.last_page;
      }

      yield put(
        LspSlsOrdDetailBoardActions.lspSlsOrdDetailBoardFetchWipInvDocSuccess(
          result.data.data,
          nowCurrentPage,
          result.data.last_page,
          result.data.total,
          result.data.per_page
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(LspSlsOrdDetailBoardActions.lspSlsOrdDetailBoardFetchWipInvDocLoading(false));
  }
}

export function* lspSlsOrdDetailBoardFetchCompleteLoadList({
  currentPage,
  sorts,
  filters,
  pageSize
}) {
  try {
    yield put(LspSlsOrdDetailBoardActions.lspSlsOrdDetailBoardFetchCompleteLoadListLoading(true));

    const processedSorts = [];
    Object.entries(sorts).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      processedSorts.push(`${key}:${value === 'ascend' ? 'ASC' : 'DESC'}`);
    });

    const processedFilters = [];
    Object.entries(filters).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      if (value) {
        processedFilters.push(`${key}:${value}`);
      }
    });

    const app = yield select(getAppStore);
    const getData = {
      page: currentPage,
      sorts: processedSorts,
      filters: processedFilters,
      pageSize
    };

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `outbOrd/indexLspSlsOrdInvDocToDoPrfDel/${app.curSiteFlowId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      // if nowCurrentPage is more than lastPage, then nowCurrentPage = lastPage
      let nowCurrentPage = result.data.current_page;
      if (nowCurrentPage > result.data.last_page) {
        nowCurrentPage = result.data.last_page;
      }

      yield put(
        LspSlsOrdDetailBoardActions.lspSlsOrdDetailBoardFetchCompleteLoadListSuccess(
          result.data.data,
          nowCurrentPage,
          result.data.last_page,
          result.data.total,
          result.data.per_page
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(LspSlsOrdDetailBoardActions.lspSlsOrdDetailBoardFetchCompleteLoadListLoading(false));
  }
}

export function* lspSlsOrdDetailBoardShowWipLspSlsOrdSummary({ docId }) {
  try {
    yield put(LspSlsOrdDetailBoardActions.lspSlsOrdDetailBoardShowWipLspSlsOrdSummaryLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `lspSlsOrd/showSummary/${docId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(
        LspSlsOrdDetailBoardActions.lspSlsOrdDetailBoardShowWipLspSlsOrdSummarySuccess(result.data)
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(
      LspSlsOrdDetailBoardActions.lspSlsOrdDetailBoardShowWipLspSlsOrdSummaryLoading(false)
    );
  }
}

export function* lspSlsOrdDetailBoardShowWipOutbOrdSummary({ docId }) {
  try {
    yield put(LspSlsOrdDetailBoardActions.lspSlsOrdDetailBoardShowWipOutbOrdSummaryLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `outbOrd/showSummary/${docId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(
        LspSlsOrdDetailBoardActions.lspSlsOrdDetailBoardShowWipOutbOrdSummarySuccess(result.data)
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(LspSlsOrdDetailBoardActions.lspSlsOrdDetailBoardShowWipOutbOrdSummaryLoading(false));
  }
}

export function* lspSlsOrdDetailBoardShowWipInvDocSummary({ docId }) {
  try {
    yield put(LspSlsOrdDetailBoardActions.lspSlsOrdDetailBoardShowWipInvDocSummaryLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `outbOrd/showSummary/${docId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(
        LspSlsOrdDetailBoardActions.lspSlsOrdDetailBoardShowWipInvDocSummarySuccess(result.data)
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(LspSlsOrdDetailBoardActions.lspSlsOrdDetailBoardShowWipInvDocSummaryLoading(false));
  }
}

export function* lspSlsOrdDetailBoardShowCompleteLoadListSummary({ docId }) {
  try {
    yield put(
      LspSlsOrdDetailBoardActions.lspSlsOrdDetailBoardShowCompleteLoadListSummaryLoading(true)
    );

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `outbOrd/showSummary/${docId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(
        LspSlsOrdDetailBoardActions.lspSlsOrdDetailBoardShowCompleteLoadListSummarySuccess(
          result.data
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(
      LspSlsOrdDetailBoardActions.lspSlsOrdDetailBoardShowCompleteLoadListSummaryLoading(false)
    );
  }
}

export const saga = [
  takeLatest(
    LspSlsOrdDetailBoardTypes.LSP_SLS_ORD_DETAIL_BOARD_FETCH_WIP_LSP_SLS_ORD,
    lspSlsOrdDetailBoardFetchWipLspSlsOrd
  ),

  takeLatest(
    LspSlsOrdDetailBoardTypes.LSP_SLS_ORD_DETAIL_BOARD_FETCH_WIP_OUTB_ORD,
    lspSlsOrdDetailBoardFetchWipOutbOrd
  ),

  takeLatest(
    LspSlsOrdDetailBoardTypes.LSP_SLS_ORD_DETAIL_BOARD_FETCH_WIP_INV_DOC,
    lspSlsOrdDetailBoardFetchWipInvDoc
  ),

  takeLatest(
    LspSlsOrdDetailBoardTypes.LSP_SLS_ORD_DETAIL_BOARD_FETCH_COMPLETE_LOAD_LIST,
    lspSlsOrdDetailBoardFetchCompleteLoadList
  ),

  takeLatest(
    LspSlsOrdDetailBoardTypes.LSP_SLS_ORD_DETAIL_BOARD_SHOW_WIP_LSP_SLS_ORD_SUMMARY,
    lspSlsOrdDetailBoardShowWipLspSlsOrdSummary
  ),

  takeLatest(
    LspSlsOrdDetailBoardTypes.LSP_SLS_ORD_DETAIL_BOARD_SHOW_WIP_OUTB_ORD_SUMMARY,
    lspSlsOrdDetailBoardShowWipOutbOrdSummary
  ),

  takeLatest(
    LspSlsOrdDetailBoardTypes.LSP_SLS_ORD_DETAIL_BOARD_SHOW_WIP_INV_DOC_SUMMARY,
    lspSlsOrdDetailBoardShowWipInvDocSummary
  ),

  takeLatest(
    LspSlsOrdDetailBoardTypes.LSP_SLS_ORD_DETAIL_BOARD_SHOW_COMPLETE_LOAD_LIST_SUMMARY,
    lspSlsOrdDetailBoardShowCompleteLoadListSummary
  )
];
