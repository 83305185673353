import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  countAdjSync02Export: ['formikBag', 'criteria'],
  countAdjSync02ExportSuccess: null,
  countAdjSync02ExportLoading: ['boolean'],

  countAdjSync02ShowBatchJobStatus: null,
  countAdjSync02ShowBatchJobStatusSuccess: ['batchJobStatus'],

  countAdjSync02FetchCompanyOptions: ['search'],
  countAdjSync02FetchCompanyOptionLoading: ['boolean'],
  countAdjSync02FetchCompanyOptionSuccess: ['options'],

  countAdjSync02FetchLocationOptions: ['search'],
  countAdjSync02FetchLocationOptionLoading: ['boolean'],
  countAdjSync02FetchLocationOptionSuccess: ['options'],

  countAdjSync02FetchItemGroup01Options: ['search'],
  countAdjSync02FetchItemGroup01OptionLoading: ['boolean'],
  countAdjSync02FetchItemGroup01OptionSuccess: ['options'],

  countAdjSync02FetchItemOptions: ['search'],
  countAdjSync02FetchItemOptionLoading: ['boolean'],
  countAdjSync02FetchItemOptionSuccess: ['options']
});

export const CountAdjSync02Types = Types;
export default Creators;
