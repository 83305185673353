/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { BinTrfProcessTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const binTrfProcessFetchBinTrfProcessLoading = (state, { boolean }) => ({
  ...state,
  processIsLoading: boolean
});

export const binTrfProcessFetchBinTrfProcessSuccess = (state, { process }) => ({
  ...state,
  process
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [BinTrfProcessTypes.BIN_TRF_PROCESS_FETCH_BIN_TRF_PROCESS_LOADING]: binTrfProcessFetchBinTrfProcessLoading,
  [BinTrfProcessTypes.BIN_TRF_PROCESS_FETCH_BIN_TRF_PROCESS_SUCCESS]: binTrfProcessFetchBinTrfProcessSuccess
});
