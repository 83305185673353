import { put, call, select, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import ApiService from '../Services/ApiService';
import AppActions from '../Stores/App/Actions';
import PickListDetailBoardActions, {
  PickListDetailBoardTypes
} from '../Stores/PickListDetailBoard/Actions';

const getAppStore = state => state.app;
const getPickListDetailBoard = state => state.pickListDetailBoard;

export function* pickListDetailBoardFetchWipOutbOrd({ currentPage, sorts, filters, pageSize }) {
  try {
    yield put(PickListDetailBoardActions.pickListDetailBoardFetchWipOutbOrdLoading(true));

    const processedSorts = [];
    Object.entries(sorts).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      processedSorts.push(`${key}:${value === 'ascend' ? 'ASC' : 'DESC'}`);
    });

    const processedFilters = [];
    Object.entries(filters).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      if (value) {
        processedFilters.push(`${key}:${value}`);
      }
    });

    const app = yield select(getAppStore);
    const getData = {
      page: currentPage,
      sorts: processedSorts,
      filters: processedFilters,
      pageSize
    };

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `outbOrd/indexToDoPickList/${app.curSiteFlowId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      // if nowCurrentPage is more than lastPage, then nowCurrentPage = lastPage
      let nowCurrentPage = result.data.current_page;
      if (nowCurrentPage > result.data.last_page) {
        nowCurrentPage = result.data.last_page;
      }

      yield put(
        PickListDetailBoardActions.pickListDetailBoardFetchWipOutbOrdSuccess(
          result.data.data,
          nowCurrentPage,
          result.data.last_page,
          result.data.total,
          result.data.per_page
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(PickListDetailBoardActions.pickListDetailBoardFetchWipOutbOrdLoading(false));
  }
}

export function* pickListDetailBoardFetchWipPickList({ currentPage, sorts, filters, pageSize }) {
  try {
    yield put(PickListDetailBoardActions.pickListDetailBoardFetchWipPickListLoading(true));

    const pickListDetailBoard = yield select(getPickListDetailBoard);

    const processedSorts = [];
    Object.entries(sorts).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      processedSorts.push(`${key}:${value === 'ascend' ? 'ASC' : 'DESC'}`);
    });

    const processedFilters = [];
    Object.entries(filters).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      if (value) {
        processedFilters.push(`${key}:${value}`);
      }
    });
    if (pickListDetailBoard.loadingBinId > 0) {
      processedFilters.push(`to_storage_bin_id:${pickListDetailBoard.loadingBinId}`);
    }

    const app = yield select(getAppStore);
    const getData = {
      page: currentPage,
      sorts: processedSorts,
      filters: processedFilters,
      pageSize
    };

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `pickList/indexToDoWhseJob/${app.curSiteFlowId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      // if nowCurrentPage is more than lastPage, then nowCurrentPage = lastPage
      let nowCurrentPage = result.data.current_page;
      if (nowCurrentPage > result.data.last_page) {
        nowCurrentPage = result.data.last_page;
      }

      yield put(
        PickListDetailBoardActions.pickListDetailBoardFetchWipPickListSuccess(
          result.data.data,
          nowCurrentPage,
          result.data.last_page,
          result.data.total,
          result.data.per_page
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(PickListDetailBoardActions.pickListDetailBoardFetchWipPickListLoading(false));
  }
}

export function* pickListDetailBoardFetchWipWhseJob({ currentPage, sorts, filters, pageSize }) {
  try {
    yield put(PickListDetailBoardActions.pickListDetailBoardFetchWipWhseJobLoading(true));

    const pickListDetailBoard = yield select(getPickListDetailBoard);

    const processedSorts = [];
    Object.entries(sorts).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      processedSorts.push(`${key}:${value === 'ascend' ? 'ASC' : 'DESC'}`);
    });

    const processedFilters = [];
    Object.entries(filters).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      if (value) {
        processedFilters.push(`${key}:${value}`);
      }
    });

    if (pickListDetailBoard.loadingBinId > 0) {
      processedFilters.push(`to_storage_bin_id:${pickListDetailBoard.loadingBinId}`);
    }

    const app = yield select(getAppStore);
    const getData = {
      page: currentPage,
      sorts: processedSorts,
      filters: processedFilters,
      pageSize
    };

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `whseJob/indexToDoPickList/${app.curSiteFlowId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      // if nowCurrentPage is more than lastPage, then nowCurrentPage = lastPage
      let nowCurrentPage = result.data.current_page;
      if (nowCurrentPage > result.data.last_page) {
        nowCurrentPage = result.data.last_page;
      }

      yield put(
        PickListDetailBoardActions.pickListDetailBoardFetchWipWhseJobSuccess(
          result.data.data,
          nowCurrentPage,
          result.data.last_page,
          result.data.total,
          result.data.per_page
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(PickListDetailBoardActions.pickListDetailBoardFetchWipWhseJobLoading(false));
  }
}

export function* pickListDetailBoardFetchCompletePickList({
  currentPage,
  sorts,
  filters,
  pageSize
}) {
  try {
    yield put(PickListDetailBoardActions.pickListDetailBoardFetchCompletePickListLoading(true));

    const pickListDetailBoard = yield select(getPickListDetailBoard);

    const processedSorts = [];
    Object.entries(sorts).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      processedSorts.push(`${key}:${value === 'ascend' ? 'ASC' : 'DESC'}`);
    });

    const processedFilters = [];
    Object.entries(filters).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      if (value) {
        processedFilters.push(`${key}:${value}`);
      }
    });
    if (pickListDetailBoard.loadingBinId > 0) {
      processedFilters.push(`to_storage_bin_id:${pickListDetailBoard.loadingBinId}`);
    }

    const app = yield select(getAppStore);
    const getData = {
      page: currentPage,
      sorts: processedSorts,
      filters: processedFilters,
      pageSize
    };

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `pickList/indexToDoLoadList/${app.curSiteFlowId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      // if nowCurrentPage is more than lastPage, then nowCurrentPage = lastPage
      let nowCurrentPage = result.data.current_page;
      if (nowCurrentPage > result.data.last_page) {
        nowCurrentPage = result.data.last_page;
      }

      yield put(
        PickListDetailBoardActions.pickListDetailBoardFetchCompletePickListSuccess(
          result.data.data,
          nowCurrentPage,
          result.data.last_page,
          result.data.total,
          result.data.per_page
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(PickListDetailBoardActions.pickListDetailBoardFetchCompletePickListLoading(false));
  }
}

export function* pickListDetailBoardShowWipOutbOrdSummary({ docId }) {
  try {
    yield put(PickListDetailBoardActions.pickListDetailBoardShowWipOutbOrdSummaryLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `outbOrd/showSummary/${docId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(
        PickListDetailBoardActions.pickListDetailBoardShowWipOutbOrdSummarySuccess(result.data)
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(PickListDetailBoardActions.pickListDetailBoardShowWipOutbOrdSummaryLoading(false));
  }
}

export function* pickListDetailBoardShowWipPickListSummary({ docId }) {
  try {
    yield put(PickListDetailBoardActions.pickListDetailBoardShowWipPickListSummaryLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `pickList/showSummary/${docId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(
        PickListDetailBoardActions.pickListDetailBoardShowWipPickListSummarySuccess(result.data)
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(PickListDetailBoardActions.pickListDetailBoardShowWipPickListSummaryLoading(false));
  }
}

export function* pickListDetailBoardShowWipWhseJobSummary({ docId }) {
  try {
    yield put(PickListDetailBoardActions.pickListDetailBoardShowWipWhseJobSummaryLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `whseJob/showPickListSummary/${docId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(
        PickListDetailBoardActions.pickListDetailBoardShowWipWhseJobSummarySuccess(result.data)
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(PickListDetailBoardActions.pickListDetailBoardShowWipWhseJobSummaryLoading(false));
  }
}

export function* pickListDetailBoardShowCompletePickListSummary({ docId }) {
  try {
    yield put(
      PickListDetailBoardActions.pickListDetailBoardShowCompletePickListSummaryLoading(true)
    );

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `pickList/showSummary/${docId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(
        PickListDetailBoardActions.pickListDetailBoardShowCompletePickListSummarySuccess(
          result.data
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(
      PickListDetailBoardActions.pickListDetailBoardShowCompletePickListSummaryLoading(false)
    );
  }
}

export function* pickListDetailBoardFetchLoadingBinOptions({ search }) {
  try {
    yield put(PickListDetailBoardActions.pickListDetailBoardFetchWipWhseJobLoading(true));

    const binTypes = [
      2 // LOADING_AREA
    ];

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId: app.curSiteFlowId,
      search,
      filters: [
        {
          field: 'bin_types',
          value: binTypes
        }
      ]
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `storageBin/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.site_code} ${d.code}`
      }));
      options.unshift({ value: 0, label: '-' });

      yield put(
        PickListDetailBoardActions.pickListDetailBoardFetchLoadingBinOptionsSuccess(options)
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(PickListDetailBoardActions.pickListDetailBoardFetchWipWhseJobLoading(false));
  }
}

export const saga = [
  takeLatest(
    PickListDetailBoardTypes.PICK_LIST_DETAIL_BOARD_FETCH_WIP_OUTB_ORD,
    pickListDetailBoardFetchWipOutbOrd
  ),

  takeLatest(
    PickListDetailBoardTypes.PICK_LIST_DETAIL_BOARD_FETCH_WIP_PICK_LIST,
    pickListDetailBoardFetchWipPickList
  ),

  takeLatest(
    PickListDetailBoardTypes.PICK_LIST_DETAIL_BOARD_FETCH_WIP_WHSE_JOB,
    pickListDetailBoardFetchWipWhseJob
  ),

  takeLatest(
    PickListDetailBoardTypes.PICK_LIST_DETAIL_BOARD_FETCH_COMPLETE_PICK_LIST,
    pickListDetailBoardFetchCompletePickList
  ),

  takeLatest(
    PickListDetailBoardTypes.PICK_LIST_DETAIL_BOARD_SHOW_WIP_OUTB_ORD_SUMMARY,
    pickListDetailBoardShowWipOutbOrdSummary
  ),

  takeLatest(
    PickListDetailBoardTypes.PICK_LIST_DETAIL_BOARD_SHOW_WIP_PICK_LIST_SUMMARY,
    pickListDetailBoardShowWipPickListSummary
  ),

  takeLatest(
    PickListDetailBoardTypes.PICK_LIST_DETAIL_BOARD_SHOW_WIP_WHSE_JOB_SUMMARY,
    pickListDetailBoardShowWipWhseJobSummary
  ),

  takeLatest(
    PickListDetailBoardTypes.PICK_LIST_DETAIL_BOARD_SHOW_COMPLETE_PICK_LIST_SUMMARY,
    pickListDetailBoardShowCompletePickListSummary
  ),

  takeLatest(
    PickListDetailBoardTypes.PICK_LIST_DETAIL_BOARD_FETCH_LOADING_BIN_OPTIONS,
    pickListDetailBoardFetchLoadingBinOptions
  )
];
