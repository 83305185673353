import React from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';

import { UndoOutlined, SaveOutlined } from '@ant-design/icons';

import { Button, Row, Col, Typography } from 'antd';
import {
  FormikInput,
  FormikSelect,
  FormikButton,
  FormikText,
  FormikInputNumber,
  FormikDatePicker,
  FormikTextNumber
} from '../../Components/Formik';
import GdsRcptDetailActions from '../../Stores/GdsRcptDetail/Actions';
import BatchSerialControl from '../../Services/Env/BatchSerialControl';
import UserFieldType from '../../Services/Env/UserFieldType';
import UserFieldMode from '../../Services/Env/UserFieldMode';

const { Text } = Typography;

class WhseJobType10Form extends React.PureComponent {
  constructor() {
    super();

    this.handleCompanySearch = this.handleCompanySearch.bind(this);
    this.handleSearchItem = this.handleSearchItem.bind(this);
    this.handleSearchItemBatch = this.handleSearchItemBatch.bind(this);
    this.handleSearchUom = this.handleSearchUom.bind(this);
    this.handleSearchItemCond01 = this.handleSearchItemCond01.bind(this);
    this.handleSearchToStorageBin = this.handleSearchToStorageBin.bind(this);
    this.handleSearchToHandlingUnit = this.handleSearchToHandlingUnit.bind(this);

    this.handleChangeItem = this.handleChangeItem.bind(this);
    this.handleChangeItemBatch = this.handleChangeItemBatch.bind(this);
    this.handleChangeUom = this.handleChangeUom.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate() {}

  componentWillUnmount() {}

  handleCompanySearch(form, value) {
    const { fetchCompanyOptions } = this.props;

    fetchCompanyOptions(value);
  }

  handleSearchItemBatch(form, value) {
    const { fetchItemBatchOptions } = this.props;
    const { values } = form;

    fetchItemBatchOptions(values.item_select2.value, value);
  }

  handleSearchItem(form, value) {
    const { fetchItemOptions } = this.props;

    fetchItemOptions(value);
  }

  handleSearchUom(form, value) {
    const { fetchUomOptions } = this.props;
    const { values } = form;

    fetchUomOptions(values.item_select2.value, value);
  }

  handleSearchItemCond01(form, value) {
    const { fetchItemCond01Options } = this.props;

    fetchItemCond01Options(value);
  }

  handleSearchToStorageBin(form, value) {
    const { fetchToStorageBinOptions } = this.props;

    fetchToStorageBinOptions(value);
  }

  handleSearchToHandlingUnit(form, value) {
    const { fetchToHandlingUnitOptions } = this.props;

    fetchToHandlingUnitOptions(value);
  }

  handleChangeItem(form, value) {
    // params: form, value, option
    const { hdrId, changeItem } = this.props;

    changeItem(form, hdrId, value);
  }

  handleChangeItemBatch(form, value) {
    // params: form, value, option
    const { hdrId, changeItemBatch } = this.props;

    changeItemBatch(form, hdrId, value);
  }

  handleChangeUom(form, value) {
    // params: form, value, option
    const { hdrId, changeUom } = this.props;
    const { values } = form;

    changeUom(form, hdrId, values.item_select2.value, value);
  }

  render() {
    const {
      intl,
      hdrId,
      documentHeader,
      documentDetail,
      updateDetails,
      createDetail,
      documentIsLoading,
      companyIsLoading,
      companyOptions,
      itemIsLoading,
      itemOptions,
      itemBatchIsLoading,
      itemBatchOptions,
      uomIsLoading,
      uomOptions,
      itemCond01IsLoading,
      itemCond01Options,
      toStorageBinIsLoading,
      toStorageBinOptions,
      toHandlingUnitIsLoading,
      toHandlingUnitOptions
    } = this.props;

    const disabled = documentHeader && documentHeader.doc_status >= 50;
    return (
      <Formik
        enableReinitialize
        initialValues={documentDetail}
        onSubmit={(values, formikBag) => {
          // remove the submit_action field
          const processedValues = { ...values, whse_job_type: 10 };
          delete processedValues.submit_action;

          if (values.id > 0) {
            updateDetails(formikBag, hdrId, [processedValues]);
          } else {
            createDetail(formikBag, hdrId, processedValues);
          }
        }}
        validationSchema={Yup.object().shape({
          company_select2: Yup.object().shape({
            value: Yup.number().min(1, intl.formatMessage({ id: 'company_is_required' }))
          }),
          item_select2: Yup.object().shape({
            value: Yup.number().min(1, intl.formatMessage({ id: 'item_is_required' }))
          }),
          /*
          item_batch_select2: Yup.object().shape({
            value: Yup.number().min(1, intl.formatMessage({ id: 'item_batch_is_required' }))
          }),
          */
          uom_select2: Yup.object().shape({
            value: Yup.number().min(2, intl.formatMessage({ id: 'uom_is_required' }))
          }),
          qty: Yup.number().moreThan(-1, intl.formatMessage({ id: 'qty_must_be_positive' })),
          item_cond_01_select2: Yup.object().shape({
            value: Yup.number().min(1, intl.formatMessage({ id: 'item_cond_01_is_required' }))
          }),
          to_storage_bin_select2: Yup.object().shape({
            value: Yup.number().min(1, intl.formatMessage({ id: 'to_storage_bin_is_required' }))
          }),
          to_handling_unit_select2: Yup.object().shape({
            value: Yup.number().min(1, intl.formatMessage({ id: 'to_pallet_id_is_required' }))
          }),
          user_field_01_value:
            documentDetail.user_field_01_value >= UserFieldMode.REQUIRED
              ? Yup.string().required(
                  1,
                  `${documentDetail.user_field_01_label} ${intl.formatMessage({
                    id: 'is_required'
                  })}`
                )
              : '',
          user_field_02_value:
            documentDetail.user_field_02_value >= UserFieldMode.REQUIRED
              ? Yup.string().required(
                  1,
                  `${documentDetail.user_field_02_label} ${intl.formatMessage({
                    id: 'is_required'
                  })}`
                )
              : '',
          user_field_03_value:
            documentDetail.user_field_03_value >= UserFieldMode.REQUIRED
              ? Yup.string().required(
                  1,
                  `${documentDetail.user_field_03_label} ${intl.formatMessage({
                    id: 'is_required'
                  })}`
                )
              : '',
          user_field_04_value:
            documentDetail.user_field_04_value >= UserFieldMode.REQUIRED
              ? Yup.string().required(
                  1,
                  `${documentDetail.user_field_04_label} ${intl.formatMessage({
                    id: 'is_required'
                  })}`
                )
              : '',
          user_field_05_value:
            documentDetail.user_field_05_value >= UserFieldMode.REQUIRED
              ? Yup.string().required(
                  1,
                  `${documentDetail.user_field_05_label} ${intl.formatMessage({
                    id: 'is_required'
                  })}`
                )
              : '',
          user_field_06_value:
            documentDetail.user_field_06_value >= UserFieldMode.REQUIRED
              ? Yup.string().required(
                  1,
                  `${documentDetail.user_field_06_label} ${intl.formatMessage({
                    id: 'is_required'
                  })}`
                )
              : '',
          user_field_07_value:
            documentDetail.user_field_07_value >= UserFieldMode.REQUIRED
              ? Yup.string().required(
                  1,
                  `${documentDetail.user_field_07_label} ${intl.formatMessage({
                    id: 'is_required'
                  })}`
                )
              : '',
          user_field_08_value:
            documentDetail.user_field_08_value >= UserFieldMode.REQUIRED
              ? Yup.string().required(
                  1,
                  `${documentDetail.user_field_08_label} ${intl.formatMessage({
                    id: 'is_required'
                  })}`
                )
              : '',
          user_field_09_value:
            documentDetail.user_field_09_value >= UserFieldMode.REQUIRED
              ? Yup.string().required(
                  1,
                  `${documentDetail.user_field_09_label} ${intl.formatMessage({
                    id: 'is_required'
                  })}`
                )
              : '',
          user_field_10_value:
            documentDetail.user_field_10_value >= UserFieldMode.REQUIRED
              ? Yup.string().required(
                  1,
                  `${documentDetail.user_field_10_label} ${intl.formatMessage({
                    id: 'is_required'
                  })}`
                )
              : ''
        })}
      >
        {({
          values,
          // handleChange,
          // errors,
          // setFieldTouched,
          dirty,
          // touched,
          isSubmitting,
          // isValid,
          handleSubmit,
          handleReset
        }) => {
          const isBatchSerialControl = BatchSerialControl.isBatchSerialControl(
            values.batch_serial_control
          );

          return (
            <Form>
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'company' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="company_select2"
                    component={FormikSelect}
                    showArrow
                    filterOption={false}
                    onSearch={this.handleCompanySearch}
                    notFoundContent={null}
                    loading={companyIsLoading}
                    options={companyOptions}
                    disabled={disabled}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'item' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="item_select2"
                    component={FormikSelect}
                    showArrow
                    filterOption={false}
                    onSearch={this.handleSearchItem}
                    notFoundContent={null}
                    loading={itemIsLoading}
                    options={itemOptions}
                    disabled={disabled}
                    onChange={this.handleChangeItem}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'item_desc' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field name="desc_01" component={FormikText} />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text />
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field name="desc_02" component={FormikText} />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'item_batch' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="item_batch_select2"
                    component={FormikSelect}
                    showArrow
                    filterOption={false}
                    onSearch={this.handleSearchItemBatch}
                    notFoundContent={null}
                    loading={itemBatchIsLoading}
                    options={itemBatchOptions}
                    disabled={disabled}
                    onChange={this.handleChangeItemBatch}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'batch_serial_no' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="batch_serial_no"
                    component={FormikInput}
                    disabled={!isBatchSerialControl || disabled}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'expiry_date' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="expiry_date"
                    component={FormikDatePicker}
                    disabled={disabled || documentIsLoading}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'receipt_date' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="receipt_date"
                    component={FormikDatePicker}
                    disabled={disabled || documentIsLoading}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'qty' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="qty"
                    component={FormikInputNumber}
                    precision={values.qty_scale || 0}
                    disabled={disabled}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text />
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={10}>
                  <Field
                    name="uom_select2"
                    component={FormikSelect}
                    showArrow
                    filterOption={false}
                    onSearch={this.handleSearchUom}
                    notFoundContent={null}
                    loading={uomIsLoading}
                    options={uomOptions}
                    disabled={disabled}
                    onChange={this.handleChangeUom}
                  />
                </Col>
                <Col span={10}>
                  <Field
                    name="uom_rate"
                    component={FormikTextNumber}
                    minimumfractiondigits={6}
                    maximumfractiondigits={6}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'item_cond_01' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="item_cond_01_select2"
                    component={FormikSelect}
                    showArrow
                    filterOption={false}
                    onSearch={this.handleSearchItemCond01}
                    notFoundContent={null}
                    loading={itemCond01IsLoading}
                    options={itemCond01Options}
                    disabled={disabled}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'to_storage_bin' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="to_storage_bin_select2"
                    component={FormikSelect}
                    showArrow
                    filterOption={false}
                    onSearch={this.handleSearchToStorageBin}
                    notFoundContent={null}
                    loading={toStorageBinIsLoading}
                    options={toStorageBinOptions}
                    disabled={disabled}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'to_pallet_id' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="to_handling_unit_select2"
                    component={FormikSelect}
                    showArrow
                    filterOption={false}
                    onSearch={this.handleSearchToHandlingUnit}
                    notFoundContent={null}
                    loading={toHandlingUnitIsLoading}
                    options={toHandlingUnitOptions}
                    disabled={disabled}
                  />
                </Col>
              </Row>

              {values.user_field_01_type > 0 && (
                <Row type="flex" justify="center" gutter={[8, 8]}>
                  <Col span={3}>
                    <Text>{values.user_field_01_label}</Text>
                  </Col>
                  <Col span={1}>
                    <Text>:</Text>
                  </Col>
                  <Col span={20}>
                    {(values.user_field_01_type === UserFieldType.STRING ||
                      values.user_field_01_type === UserFieldType.STRING_UPPER_CASE) && (
                      <Field
                        name="user_field_01_value"
                        component={FormikInput}
                        disabled={disabled || documentIsLoading}
                      />
                    )}
                    {values.user_field_01_type === UserFieldType.DATE && (
                      <Field
                        name="user_field_01_value"
                        component={FormikDatePicker}
                        disabled={disabled || documentIsLoading}
                      />
                    )}
                    {(values.user_field_01_type === UserFieldType.INTEGER ||
                      values.user_field_01_type === UserFieldType.DECIMAL01 ||
                      values.user_field_01_type === UserFieldType.DECIMAL02 ||
                      values.user_field_01_type === UserFieldType.DECIMAL03 ||
                      values.user_field_01_type === UserFieldType.DECIMAL04 ||
                      values.user_field_01_type === UserFieldType.DECIMAL05) && (
                      <Field
                        name="user_field_01_value"
                        component={FormikInputNumber}
                        disabled={disabled || documentIsLoading}
                      />
                    )}
                  </Col>
                </Row>
              )}
              {values.user_field_02_type > 0 && (
                <Row type="flex" justify="center" gutter={[8, 8]}>
                  <Col span={3}>
                    <Text>{values.user_field_02_label}</Text>
                  </Col>
                  <Col span={1}>
                    <Text>:</Text>
                  </Col>
                  <Col span={20}>
                    {(values.user_field_02_type === UserFieldType.STRING ||
                      values.user_field_02_type === UserFieldType.STRING_UPPER_CASE) && (
                      <Field
                        name="user_field_02_value"
                        component={FormikInput}
                        disabled={disabled || documentIsLoading}
                      />
                    )}
                    {values.user_field_02_type === UserFieldType.DATE && (
                      <Field
                        name="user_field_02_value"
                        component={FormikDatePicker}
                        disabled={disabled || documentIsLoading}
                      />
                    )}
                    {(values.user_field_02_type === UserFieldType.INTEGER ||
                      values.user_field_02_type === UserFieldType.DECIMAL01 ||
                      values.user_field_02_type === UserFieldType.DECIMAL02 ||
                      values.user_field_02_type === UserFieldType.DECIMAL03 ||
                      values.user_field_02_type === UserFieldType.DECIMAL04 ||
                      values.user_field_02_type === UserFieldType.DECIMAL05) && (
                      <Field
                        name="user_field_02_value"
                        component={FormikInputNumber}
                        disabled={disabled || documentIsLoading}
                      />
                    )}
                  </Col>
                </Row>
              )}
              {values.user_field_03_type > 0 && (
                <Row type="flex" justify="center" gutter={[8, 8]}>
                  <Col span={3}>
                    <Text>{values.user_field_03_label}</Text>
                  </Col>
                  <Col span={1}>
                    <Text>:</Text>
                  </Col>
                  <Col span={20}>
                    {(values.user_field_03_type === UserFieldType.STRING ||
                      values.user_field_03_type === UserFieldType.STRING_UPPER_CASE) && (
                      <Field
                        name="user_field_03_value"
                        component={FormikInput}
                        disabled={disabled || documentIsLoading}
                      />
                    )}
                    {values.user_field_03_type === UserFieldType.DATE && (
                      <Field
                        name="user_field_03_value"
                        component={FormikDatePicker}
                        disabled={disabled || documentIsLoading}
                      />
                    )}
                    {(values.user_field_03_type === UserFieldType.INTEGER ||
                      values.user_field_03_type === UserFieldType.DECIMAL01 ||
                      values.user_field_03_type === UserFieldType.DECIMAL02 ||
                      values.user_field_03_type === UserFieldType.DECIMAL03 ||
                      values.user_field_03_type === UserFieldType.DECIMAL04 ||
                      values.user_field_03_type === UserFieldType.DECIMAL05) && (
                      <Field
                        name="user_field_03_value"
                        component={FormikInputNumber}
                        precision={5}
                        disabled={disabled || documentIsLoading}
                      />
                    )}
                  </Col>
                </Row>
              )}
              {values.user_field_04_type > 0 && (
                <Row type="flex" justify="center" gutter={[8, 8]}>
                  <Col span={3}>
                    <Text>{values.user_field_04_label}</Text>
                  </Col>
                  <Col span={1}>
                    <Text>:</Text>
                  </Col>
                  <Col span={20}>
                    {(values.user_field_04_type === UserFieldType.STRING ||
                      values.user_field_04_type === UserFieldType.STRING_UPPER_CASE) && (
                      <Field
                        name="user_field_04_value"
                        component={FormikInput}
                        disabled={disabled || documentIsLoading}
                      />
                    )}
                    {values.user_field_04_type === UserFieldType.DATE && (
                      <Field
                        name="user_field_04_value"
                        component={FormikDatePicker}
                        disabled={disabled || documentIsLoading}
                      />
                    )}
                    {(values.user_field_04_type === UserFieldType.INTEGER ||
                      values.user_field_04_type === UserFieldType.DECIMAL01 ||
                      values.user_field_04_type === UserFieldType.DECIMAL02 ||
                      values.user_field_04_type === UserFieldType.DECIMAL03 ||
                      values.user_field_04_type === UserFieldType.DECIMAL04 ||
                      values.user_field_04_type === UserFieldType.DECIMAL05) && (
                      <Field
                        name="user_field_04_value"
                        component={FormikInputNumber}
                        disabled={disabled || documentIsLoading}
                      />
                    )}
                  </Col>
                </Row>
              )}
              {values.user_field_05_type > 0 && (
                <Row type="flex" justify="center" gutter={[8, 8]}>
                  <Col span={3}>
                    <Text>{values.user_field_05_label}</Text>
                  </Col>
                  <Col span={1}>
                    <Text>:</Text>
                  </Col>
                  <Col span={20}>
                    {(values.user_field_05_type === UserFieldType.STRING ||
                      values.user_field_05_type === UserFieldType.STRING_UPPER_CASE) && (
                      <Field
                        name="user_field_05_value"
                        component={FormikInput}
                        disabled={disabled || documentIsLoading}
                      />
                    )}
                    {values.user_field_05_type === UserFieldType.DATE && (
                      <Field
                        name="user_field_05_value"
                        component={FormikDatePicker}
                        disabled={disabled || documentIsLoading}
                      />
                    )}
                    {(values.user_field_05_type === UserFieldType.INTEGER ||
                      values.user_field_05_type === UserFieldType.DECIMAL01 ||
                      values.user_field_05_type === UserFieldType.DECIMAL02 ||
                      values.user_field_05_type === UserFieldType.DECIMAL03 ||
                      values.user_field_05_type === UserFieldType.DECIMAL04 ||
                      values.user_field_05_type === UserFieldType.DECIMAL05) && (
                      <Field
                        name="user_field_05_value"
                        component={FormikInputNumber}
                        disabled={disabled || documentIsLoading}
                      />
                    )}
                  </Col>
                </Row>
              )}
              {values.user_field_06_type > 0 && (
                <Row type="flex" justify="center" gutter={[8, 8]}>
                  <Col span={3}>
                    <Text>{values.user_field_06_label}</Text>
                  </Col>
                  <Col span={1}>
                    <Text>:</Text>
                  </Col>
                  <Col span={20}>
                    {(values.user_field_06_type === UserFieldType.STRING ||
                      values.user_field_06_type === UserFieldType.STRING_UPPER_CASE) && (
                      <Field
                        name="user_field_06_value"
                        component={FormikInput}
                        disabled={disabled || documentIsLoading}
                      />
                    )}
                    {values.user_field_06_type === UserFieldType.DATE && (
                      <Field
                        name="user_field_06_value"
                        component={FormikDatePicker}
                        disabled={disabled || documentIsLoading}
                      />
                    )}
                    {(values.user_field_06_type === UserFieldType.INTEGER ||
                      values.user_field_06_type === UserFieldType.DECIMAL01 ||
                      values.user_field_06_type === UserFieldType.DECIMAL02 ||
                      values.user_field_06_type === UserFieldType.DECIMAL03 ||
                      values.user_field_06_type === UserFieldType.DECIMAL04 ||
                      values.user_field_06_type === UserFieldType.DECIMAL05) && (
                      <Field
                        name="user_field_06_value"
                        component={FormikInputNumber}
                        disabled={disabled || documentIsLoading}
                      />
                    )}
                  </Col>
                </Row>
              )}
              {values.user_field_07_type > 0 && (
                <Row type="flex" justify="center" gutter={[8, 8]}>
                  <Col span={3}>
                    <Text>{values.user_field_07_label}</Text>
                  </Col>
                  <Col span={1}>
                    <Text>:</Text>
                  </Col>
                  <Col span={20}>
                    {(values.user_field_07_type === UserFieldType.STRING ||
                      values.user_field_07_type === UserFieldType.STRING_UPPER_CASE) && (
                      <Field
                        name="user_field_07_value"
                        component={FormikInput}
                        disabled={disabled || documentIsLoading}
                      />
                    )}
                    {values.user_field_07_type === UserFieldType.DATE && (
                      <Field
                        name="user_field_07_value"
                        component={FormikDatePicker}
                        disabled={disabled || documentIsLoading}
                      />
                    )}
                    {(values.user_field_07_type === UserFieldType.INTEGER ||
                      values.user_field_07_type === UserFieldType.DECIMAL01 ||
                      values.user_field_07_type === UserFieldType.DECIMAL02 ||
                      values.user_field_07_type === UserFieldType.DECIMAL03 ||
                      values.user_field_07_type === UserFieldType.DECIMAL04 ||
                      values.user_field_07_type === UserFieldType.DECIMAL05) && (
                      <Field
                        name="user_field_07_value"
                        component={FormikInputNumber}
                        disabled={disabled || documentIsLoading}
                      />
                    )}
                  </Col>
                </Row>
              )}
              {values.user_field_08_type > 0 && (
                <Row type="flex" justify="center" gutter={[8, 8]}>
                  <Col span={3}>
                    <Text>{values.user_field_08_label}</Text>
                  </Col>
                  <Col span={1}>
                    <Text>:</Text>
                  </Col>
                  <Col span={20}>
                    {(values.user_field_08_type === UserFieldType.STRING ||
                      values.user_field_08_type === UserFieldType.STRING_UPPER_CASE) && (
                      <Field
                        name="user_field_08_value"
                        component={FormikInput}
                        disabled={disabled || documentIsLoading}
                      />
                    )}
                    {values.user_field_08_type === UserFieldType.DATE && (
                      <Field
                        name="user_field_08_value"
                        component={FormikDatePicker}
                        disabled={disabled || documentIsLoading}
                      />
                    )}
                    {(values.user_field_08_type === UserFieldType.INTEGER ||
                      values.user_field_08_type === UserFieldType.DECIMAL01 ||
                      values.user_field_08_type === UserFieldType.DECIMAL02 ||
                      values.user_field_08_type === UserFieldType.DECIMAL03 ||
                      values.user_field_08_type === UserFieldType.DECIMAL04 ||
                      values.user_field_08_type === UserFieldType.DECIMAL05) && (
                      <Field
                        name="user_field_08_value"
                        component={FormikInputNumber}
                        disabled={disabled || documentIsLoading}
                      />
                    )}
                  </Col>
                </Row>
              )}
              {values.user_field_09_type > 0 && (
                <Row type="flex" justify="center" gutter={[8, 8]}>
                  <Col span={3}>
                    <Text>{values.user_field_09_label}</Text>
                  </Col>
                  <Col span={1}>
                    <Text>:</Text>
                  </Col>
                  <Col span={20}>
                    {(values.user_field_09_type === UserFieldType.STRING ||
                      values.user_field_09_type === UserFieldType.STRING_UPPER_CASE) && (
                      <Field
                        name="user_field_09_value"
                        component={FormikInput}
                        disabled={disabled || documentIsLoading}
                      />
                    )}
                    {values.user_field_09_type === UserFieldType.DATE && (
                      <Field
                        name="user_field_09_value"
                        component={FormikDatePicker}
                        disabled={disabled || documentIsLoading}
                      />
                    )}
                    {(values.user_field_09_type === UserFieldType.INTEGER ||
                      values.user_field_09_type === UserFieldType.DECIMAL01 ||
                      values.user_field_09_type === UserFieldType.DECIMAL02 ||
                      values.user_field_09_type === UserFieldType.DECIMAL03 ||
                      values.user_field_09_type === UserFieldType.DECIMAL04 ||
                      values.user_field_09_type === UserFieldType.DECIMAL05) && (
                      <Field
                        name="user_field_09_value"
                        component={FormikInputNumber}
                        disabled={disabled || documentIsLoading}
                      />
                    )}
                  </Col>
                </Row>
              )}
              {values.user_field_10_type > 0 && (
                <Row type="flex" justify="center" gutter={[8, 8]}>
                  <Col span={3}>
                    <Text>{values.user_field_10_label}</Text>
                  </Col>
                  <Col span={1}>
                    <Text>:</Text>
                  </Col>
                  <Col span={20}>
                    {(values.user_field_10_type === UserFieldType.STRING ||
                      values.user_field_10_type === UserFieldType.STRING_UPPER_CASE) && (
                      <Field
                        name="user_field_10_value"
                        component={FormikInput}
                        disabled={disabled || documentIsLoading}
                      />
                    )}
                    {values.user_field_10_type === UserFieldType.DATE && (
                      <Field
                        name="user_field_10_value"
                        component={FormikDatePicker}
                        disabled={disabled || documentIsLoading}
                      />
                    )}
                    {(values.user_field_10_type === UserFieldType.INTEGER ||
                      values.user_field_10_type === UserFieldType.DECIMAL01 ||
                      values.user_field_10_type === UserFieldType.DECIMAL02 ||
                      values.user_field_10_type === UserFieldType.DECIMAL03 ||
                      values.user_field_10_type === UserFieldType.DECIMAL04 ||
                      values.user_field_10_type === UserFieldType.DECIMAL05) && (
                      <Field
                        name="user_field_10_value"
                        component={FormikInputNumber}
                        disabled={disabled || documentIsLoading}
                      />
                    )}
                  </Col>
                </Row>
              )}

              <Row type="flex" justify="end" gutter={[0, 16]}>
                <Col span={5}>
                  <Button
                    type="primary"
                    disabled={!dirty}
                    loading={isSubmitting || documentIsLoading}
                    onClick={handleReset}
                    icon={<UndoOutlined />}
                  >
                    {intl.formatMessage({ id: 'reset' })}
                  </Button>
                  <Field
                    type="primary"
                    name="submit_action"
                    value="update"
                    component={FormikButton}
                    disabled={disabled}
                    loading={isSubmitting || documentIsLoading}
                    onClick={handleSubmit}
                    label={intl.formatMessage({
                      id: values.id > 0 ? 'update' : 'create'
                    })}
                    icon={<SaveOutlined />}
                  />
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

WhseJobType10Form.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  updateDetails: PropTypes.func,
  createDetail: PropTypes.func,
  hdrId: PropTypes.number,
  documentHeader: PropTypes.shape({
    doc_status: PropTypes.number
  }),
  documentDetail: PropTypes.shape({
    batch_serial_control: PropTypes.number,
    user_field_01_label: PropTypes.string,
    user_field_01_type: PropTypes.number,
    user_field_01_mode: PropTypes.number,
    user_field_01_value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    user_field_02_label: PropTypes.string,
    user_field_02_type: PropTypes.number,
    user_field_02_mode: PropTypes.number,
    user_field_02_value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    user_field_03_label: PropTypes.string,
    user_field_03_type: PropTypes.number,
    user_field_03_mode: PropTypes.number,
    user_field_03_value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    user_field_04_label: PropTypes.string,
    user_field_04_type: PropTypes.number,
    user_field_04_mode: PropTypes.number,
    user_field_04_value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    user_field_05_label: PropTypes.string,
    user_field_05_type: PropTypes.number,
    user_field_05_mode: PropTypes.number,
    user_field_05_value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    user_field_06_label: PropTypes.string,
    user_field_06_type: PropTypes.number,
    user_field_06_mode: PropTypes.number,
    user_field_06_value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    user_field_07_label: PropTypes.string,
    user_field_07_type: PropTypes.number,
    user_field_07_mode: PropTypes.number,
    user_field_07_value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    user_field_08_label: PropTypes.string,
    user_field_08_type: PropTypes.number,
    user_field_08_mode: PropTypes.number,
    user_field_08_value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    user_field_09_label: PropTypes.string,
    user_field_09_type: PropTypes.number,
    user_field_09_mode: PropTypes.number,
    user_field_09_value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    user_field_10_label: PropTypes.string,
    user_field_10_type: PropTypes.number,
    user_field_10_mode: PropTypes.number,
    user_field_10_value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  }),
  documentIsLoading: PropTypes.bool,

  fetchCompanyOptions: PropTypes.func,
  companyIsLoading: PropTypes.bool,
  companyOptions: PropTypes.arrayOf(PropTypes.object),

  fetchItemOptions: PropTypes.func,
  changeItem: PropTypes.func,
  itemIsLoading: PropTypes.bool,
  itemOptions: PropTypes.arrayOf(PropTypes.object),

  fetchItemBatchOptions: PropTypes.func,
  changeItemBatch: PropTypes.func,
  itemBatchIsLoading: PropTypes.bool,
  itemBatchOptions: PropTypes.arrayOf(PropTypes.object),

  fetchUomOptions: PropTypes.func,
  changeUom: PropTypes.func,
  uomIsLoading: PropTypes.bool,
  uomOptions: PropTypes.arrayOf(PropTypes.object),

  fetchItemCond01Options: PropTypes.func,
  itemCond01IsLoading: PropTypes.bool,
  itemCond01Options: PropTypes.arrayOf(PropTypes.object),

  fetchToStorageBinOptions: PropTypes.func,
  toStorageBinIsLoading: PropTypes.bool,
  toStorageBinOptions: PropTypes.arrayOf(PropTypes.object),

  fetchToHandlingUnitOptions: PropTypes.func,
  toHandlingUnitIsLoading: PropTypes.bool,
  toHandlingUnitOptions: PropTypes.arrayOf(PropTypes.object)
};

WhseJobType10Form.defaultProps = {
  intl: {},
  updateDetails() {},
  createDetail() {},
  hdrId: 0,
  documentHeader: { doc_status: 3 },
  documentDetail: {
    batch_serial_control: 0,
    user_field_01_label: '',
    user_field_01_type: 0,
    user_field_01_mode: 0,
    user_field_01_value: '',
    user_field_02_label: '',
    user_field_02_type: 0,
    user_field_02_mode: 0,
    user_field_02_value: '',
    user_field_03_label: '',
    user_field_03_type: 0,
    user_field_03_mode: 0,
    user_field_03_value: '',
    user_field_04_label: '',
    user_field_04_type: 0,
    user_field_04_mode: 0,
    user_field_04_value: '',
    user_field_05_label: '',
    user_field_05_type: 0,
    user_field_05_mode: 0,
    user_field_05_value: '',
    user_field_06_label: '',
    user_field_06_type: 0,
    user_field_06_mode: 0,
    user_field_06_value: '',
    user_field_07_label: '',
    user_field_07_type: 0,
    user_field_07_mode: 0,
    user_field_07_value: '',
    user_field_08_label: '',
    user_field_08_type: 0,
    user_field_08_mode: 0,
    user_field_08_value: '',
    user_field_09_label: '',
    user_field_09_type: 0,
    user_field_09_mode: 0,
    user_field_09_value: '',
    user_field_10_label: '',
    user_field_10_type: 0,
    user_field_10_mode: 0,
    user_field_10_value: ''
  },
  documentIsLoading: false,

  fetchCompanyOptions() {},
  companyIsLoading: false,
  companyOptions: [],

  fetchItemOptions() {},
  changeItem() {},
  itemIsLoading: false,
  itemOptions: [],

  fetchItemBatchOptions() {},
  changeItemBatch() {},
  itemBatchIsLoading: false,
  itemBatchOptions: [],

  fetchUomOptions() {},
  changeUom() {},
  uomIsLoading: false,
  uomOptions: [],

  fetchItemCond01Options() {},
  itemCond01IsLoading: false,
  itemCond01Options: [],

  fetchToStorageBinOptions() {},
  toStorageBinIsLoading: false,
  toStorageBinOptions: [],

  fetchToHandlingUnitOptions() {},
  toHandlingUnitIsLoading: false,
  toHandlingUnitOptions: []
};

const mapStateToProps = state => ({
  hdrId: state.gdsRcptDetail.hdrId,
  documentHeader: state.gdsRcptDetail.documentHeader,
  documentDetail: state.gdsRcptDetail.documentDetail,
  documentIsLoading: state.gdsRcptDetail.documentIsLoading,

  companyIsLoading: state.gdsRcptDetail.companyIsLoading,
  companyOptions: state.gdsRcptDetail.companyOptions,

  itemIsLoading: state.gdsRcptDetail.itemIsLoading,
  itemOptions: state.gdsRcptDetail.itemOptions,

  itemBatchIsLoading: state.gdsRcptDetail.itemBatchIsLoading,
  itemBatchOptions: state.gdsRcptDetail.itemBatchOptions,

  uomIsLoading: state.gdsRcptDetail.uomIsLoading,
  uomOptions: state.gdsRcptDetail.uomOptions,

  itemCond01IsLoading: state.gdsRcptDetail.itemCond01IsLoading,
  itemCond01Options: state.gdsRcptDetail.itemCond01Options,

  toStorageBinIsLoading: state.gdsRcptDetail.toStorageBinIsLoading,
  toStorageBinOptions: state.gdsRcptDetail.toStorageBinOptions,

  toHandlingUnitIsLoading: state.gdsRcptDetail.toHandlingUnitIsLoading,
  toHandlingUnitOptions: state.gdsRcptDetail.toHandlingUnitOptions
});

const mapDispatchToProps = dispatch => ({
  updateDetails: (formikBag, hdrId, documentDetails) =>
    dispatch(GdsRcptDetailActions.gdsRcptDetailUpdateDetails(formikBag, hdrId, documentDetails)),
  createDetail: (formikBag, hdrId, documentDetail) =>
    dispatch(GdsRcptDetailActions.gdsRcptDetailCreateDetail(formikBag, hdrId, documentDetail)),

  fetchCompanyOptions: search =>
    dispatch(GdsRcptDetailActions.gdsRcptDetailFetchCompanyOptions(search)),

  fetchItemOptions: search => dispatch(GdsRcptDetailActions.gdsRcptDetailFetchItemOptions(search)),
  changeItem: (formikBag, hdrId, itemId) =>
    dispatch(GdsRcptDetailActions.gdsRcptDetailChangeItem(formikBag, hdrId, itemId)),

  fetchItemBatchOptions: (itemId, search) =>
    dispatch(GdsRcptDetailActions.gdsRcptDetailFetchItemBatchOptions(itemId, search)),
  changeItemBatch: (formikBag, hdrId, itemBatchId) =>
    dispatch(GdsRcptDetailActions.gdsRcptDetailChangeItemBatch(formikBag, hdrId, itemBatchId)),

  fetchUomOptions: (itemId, search) =>
    dispatch(GdsRcptDetailActions.gdsRcptDetailFetchUomOptions(itemId, search)),
  fetchUomOptionSuccess: options =>
    dispatch(GdsRcptDetailActions.gdsRcptDetailFetchUomOptionSuccess(options)),
  changeUom: (formikBag, hdrId, itemId, uomId) =>
    dispatch(GdsRcptDetailActions.gdsRcptDetailChangeUom(formikBag, hdrId, itemId, uomId)),

  fetchItemCond01Options: search =>
    dispatch(GdsRcptDetailActions.gdsRcptDetailFetchItemCond01Options(search)),

  fetchToStorageBinOptions: search =>
    dispatch(GdsRcptDetailActions.gdsRcptDetailFetchToStorageBinOptions(search)),

  fetchToHandlingUnitOptions: search =>
    dispatch(GdsRcptDetailActions.gdsRcptDetailFetchToHandlingUnitOptions(search))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(WhseJobType10Form));
