import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  slsRtnSync01ShowSyncSetting: null,
  slsRtnSync01ShowSyncSettingLoading: ['boolean'],
  slsRtnSync01ShowSyncSettingSuccess: ['syncSetting'],
  slsRtnSync01UpdateSyncSetting: ['formikBag', 'syncSetting'],
  slsRtnSync01ShowBatchJobStatus: null,
  slsRtnSync01ShowBatchJobStatusSuccess: ['batchJobStatus'],
  slsRtnSync01SyncNow: ['formikBag'],
  slsRtnSync01SyncLoading: ['boolean']
});

export const SlsRtnSync01Types = Types;
export default Creators;
