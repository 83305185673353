/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { LspAdvShipDetailTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const lspAdvShipDetailResetTimestamp = state => ({
  ...state,
  timestamp: Date.now()
});

export const lspAdvShipDetailSetHdrId = (state, { hdrId, itemId }) => ({
  ...state,
  timestamp: Date.now(),
  documentIsLoading: false,
  hdrId,
  itemId,
  detailIsVisible: false,
  purchaserOptions: [],
  bizPartnerOptions: [],
  creditTermOptions: [],
  currencyOptions: []
});

export const lspAdvShipDetailShowDocumentLoading = (state, { boolean }) => ({
  ...state,
  documentIsLoading: boolean
});

export const lspAdvShipDetailShowHeaderSuccess = (state, { documentHeader }) => ({
  ...state,
  documentHeader
});

export const lspAdvShipDetailUpdateDocumentSuccess = (
  state,
  { documentHeader, documentDetails }
) => ({
  ...state,
  documentHeader,
  documentDetails
});

export const lspAdvShipDetailShowDetailsSuccess = (state, { documentDetails }) => ({
  ...state,
  documentDetails
});

export const lspAdvShipDetailSetDetailVisible = (state, { boolean }) => ({
  ...state,
  detailIsVisible: boolean
});

export const lspAdvShipDetailSetDocumentDetail = (state, { documentDetail }) => ({
  ...state,
  documentDetail,
  itemOptions: [],
  uomOptions: []
});

export const lspAdvShipDetailFetchDebtorOptionLoading = (state, { boolean }) => ({
  ...state,
  debtorIsLoading: boolean
});

export const lspAdvShipDetailFetchDebtorOptionSuccess = (state, { options }) => ({
  ...state,
  debtorOptions: options
});

export const lspAdvShipDetailFetchPurchaserOptionLoading = (state, { boolean }) => ({
  ...state,
  purchaserIsLoading: boolean
});

export const lspAdvShipDetailFetchPurchaserOptionSuccess = (state, { options }) => ({
  ...state,
  purchaserOptions: options
});

export const lspAdvShipDetailFetchBizPartnerOptionLoading = (state, { boolean }) => ({
  ...state,
  bizPartnerIsLoading: boolean
});

export const lspAdvShipDetailFetchBizPartnerOptionSuccess = (state, { options }) => ({
  ...state,
  bizPartnerOptions: options
});

export const lspAdvShipDetailFetchCreditTermOptionLoading = (state, { boolean }) => ({
  ...state,
  creditTermIsLoading: boolean
});

export const lspAdvShipDetailFetchCreditTermOptionSuccess = (state, { options }) => ({
  ...state,
  creditTermOptions: options
});

export const lspAdvShipDetailFetchCurrencyOptionLoading = (state, { boolean }) => ({
  ...state,
  currencyIsLoading: boolean
});

export const lspAdvShipDetailFetchCurrencyOptionSuccess = (state, { options }) => ({
  ...state,
  currencyOptions: options
});

export const lspAdvShipDetailFetchItemOptionLoading = (state, { boolean }) => ({
  ...state,
  itemIsLoading: boolean
});

export const lspAdvShipDetailFetchItemOptionSuccess = (state, { options }) => ({
  ...state,
  itemOptions: options
});

export const lspAdvShipDetailFetchUomOptionLoading = (state, { boolean }) => ({
  ...state,
  uomIsLoading: boolean
});

export const lspAdvShipDetailFetchUomOptionSuccess = (state, { options }) => ({
  ...state,
  uomOptions: options
});

// force refresh the formik form
export const lspAdvShipDetailUpdateDetails = (state, { documentDetails }) => ({
  ...state,
  documentDetail: documentDetails[0]
});

// force refresh the formik form
export const lspAdvShipDetailCreateDetail = (state, { documentDetail }) => ({
  ...state,
  documentDetail
});

export const lspAdvShipDetailFetchLocationOptionLoading = (state, { boolean }) => ({
  ...state,
  locationIsLoading: boolean
});

export const lspAdvShipDetailFetchLocationOptionSuccess = (state, { options }) => ({
  ...state,
  locationOptions: options
});

export const lspAdvShipDetailFetchProjectOptionLoading = (state, { boolean }) => ({
  ...state,
  projectIsLoading: boolean
});

export const lspAdvShipDetailFetchProjectOptionSuccess = (state, { options }) => ({
  ...state,
  projectOptions: options
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [LspAdvShipDetailTypes.LSP_ADV_SHIP_DETAIL_RESET_TIMESTAMP]: lspAdvShipDetailResetTimestamp,
  [LspAdvShipDetailTypes.LSP_ADV_SHIP_DETAIL_SET_HDR_ID]: lspAdvShipDetailSetHdrId,

  [LspAdvShipDetailTypes.LSP_ADV_SHIP_DETAIL_SHOW_HEADER_SUCCESS]: lspAdvShipDetailShowHeaderSuccess,

  [LspAdvShipDetailTypes.LSP_ADV_SHIP_DETAIL_UPDATE_DOCUMENT_SUCCESS]: lspAdvShipDetailUpdateDocumentSuccess,

  [LspAdvShipDetailTypes.LSP_ADV_SHIP_DETAIL_SHOW_DOCUMENT_LOADING]: lspAdvShipDetailShowDocumentLoading,

  [LspAdvShipDetailTypes.LSP_ADV_SHIP_DETAIL_SHOW_DETAILS_SUCCESS]: lspAdvShipDetailShowDetailsSuccess,

  [LspAdvShipDetailTypes.LSP_ADV_SHIP_DETAIL_SET_DETAIL_VISIBLE]: lspAdvShipDetailSetDetailVisible,
  [LspAdvShipDetailTypes.LSP_ADV_SHIP_DETAIL_SET_DOCUMENT_DETAIL]: lspAdvShipDetailSetDocumentDetail,

  [LspAdvShipDetailTypes.LSP_ADV_SHIP_DETAIL_UPDATE_DETAILS]: lspAdvShipDetailUpdateDetails,
  [LspAdvShipDetailTypes.LSP_ADV_SHIP_DETAIL_CREATE_DETAIL]: lspAdvShipDetailCreateDetail,

  [LspAdvShipDetailTypes.LSP_ADV_SHIP_DETAIL_FETCH_DEBTOR_OPTION_LOADING]: lspAdvShipDetailFetchDebtorOptionLoading,
  [LspAdvShipDetailTypes.LSP_ADV_SHIP_DETAIL_FETCH_DEBTOR_OPTION_SUCCESS]: lspAdvShipDetailFetchDebtorOptionSuccess,

  [LspAdvShipDetailTypes.LSP_ADV_SHIP_DETAIL_FETCH_PURCHASER_OPTION_LOADING]: lspAdvShipDetailFetchPurchaserOptionLoading,
  [LspAdvShipDetailTypes.LSP_ADV_SHIP_DETAIL_FETCH_PURCHASER_OPTION_SUCCESS]: lspAdvShipDetailFetchPurchaserOptionSuccess,

  [LspAdvShipDetailTypes.LSP_ADV_SHIP_DETAIL_FETCH_BIZ_PARTNER_OPTION_LOADING]: lspAdvShipDetailFetchBizPartnerOptionLoading,
  [LspAdvShipDetailTypes.LSP_ADV_SHIP_DETAIL_FETCH_BIZ_PARTNER_OPTION_SUCCESS]: lspAdvShipDetailFetchBizPartnerOptionSuccess,

  [LspAdvShipDetailTypes.LSP_ADV_SHIP_DETAIL_FETCH_CREDIT_TERM_OPTION_LOADING]: lspAdvShipDetailFetchCreditTermOptionLoading,
  [LspAdvShipDetailTypes.LSP_ADV_SHIP_DETAIL_FETCH_CREDIT_TERM_OPTION_SUCCESS]: lspAdvShipDetailFetchCreditTermOptionSuccess,

  [LspAdvShipDetailTypes.LSP_ADV_SHIP_DETAIL_FETCH_CURRENCY_OPTION_LOADING]: lspAdvShipDetailFetchCurrencyOptionLoading,
  [LspAdvShipDetailTypes.LSP_ADV_SHIP_DETAIL_FETCH_CURRENCY_OPTION_SUCCESS]: lspAdvShipDetailFetchCurrencyOptionSuccess,

  [LspAdvShipDetailTypes.LSP_ADV_SHIP_DETAIL_FETCH_ITEM_OPTION_LOADING]: lspAdvShipDetailFetchItemOptionLoading,
  [LspAdvShipDetailTypes.LSP_ADV_SHIP_DETAIL_FETCH_ITEM_OPTION_SUCCESS]: lspAdvShipDetailFetchItemOptionSuccess,

  [LspAdvShipDetailTypes.LSP_ADV_SHIP_DETAIL_FETCH_UOM_OPTION_LOADING]: lspAdvShipDetailFetchUomOptionLoading,
  [LspAdvShipDetailTypes.LSP_ADV_SHIP_DETAIL_FETCH_UOM_OPTION_SUCCESS]: lspAdvShipDetailFetchUomOptionSuccess,

  [LspAdvShipDetailTypes.LSP_ADV_SHIP_DETAIL_FETCH_LOCATION_OPTION_LOADING]: lspAdvShipDetailFetchLocationOptionLoading,
  [LspAdvShipDetailTypes.LSP_ADV_SHIP_DETAIL_FETCH_LOCATION_OPTION_SUCCESS]: lspAdvShipDetailFetchLocationOptionSuccess,

  [LspAdvShipDetailTypes.LSP_ADV_SHIP_DETAIL_FETCH_PROJECT_OPTION_LOADING]: lspAdvShipDetailFetchProjectOptionLoading,
  [LspAdvShipDetailTypes.LSP_ADV_SHIP_DETAIL_FETCH_PROJECT_OPTION_SUCCESS]: lspAdvShipDetailFetchProjectOptionSuccess
});
