/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { CycleCount02DetailTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const cycleCount02DetailResetTimestamp = (
  state,
  { currentPage, sorts, filters, pageSize }
) => ({
  ...state,
  currentPage: currentPage || state.currentPage,
  sorts: sorts || state.sorts,
  filters: filters || state.filters,
  pageSize: pageSize || state.pageSize,
  timestamp: Date.now(),
  documentIsLoading: false,
  // documentDetails: [],
  workspaceIsVisible: false
});

export const cycleCount02DetailSetHdrId = (state, { hdrId }) => ({
  ...state,
  timestamp: Date.now(),
  documentIsLoading: false,
  hdrId,
  workspaceIsVisible: false
});

export const cycleCount02DetailShowDocumentLoading = (state, { boolean }) => ({
  ...state,
  documentIsLoading: boolean
});

export const cycleCount02DetailShowDetailsSuccess = (
  state,
  { documentDetails, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  documentDetails,
  currentPage: currentPage || state.currentPage,
  lastPage: lastPage || state.lastPage,
  total: total || state.total,
  pageSize: pageSize || state.pageSize
});

export const cycleCount02DetailSetWorkspaceVisible = (state, { boolean }) => ({
  ...state,
  workspaceIsVisible: boolean
});

export const cycleCount02DetailCreateCycleCount02Success = (state, { newDocuments }) => ({
  ...state,
  timestamp: Date.now(),
  newDocuments
});

export const cycleCount02DetailSetExpandedRows = (state, { expandedRows }) => ({
  ...state,
  expandedRows
});

export const cycleCount02DetailExpandAllRows = state => ({
  ...state,
  expandedRows: state.documentDetails.map(value => value.id)
});

export const cycleCount02DetailCollapseAllRows = state => ({
  ...state,
  expandedRows: []
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [CycleCount02DetailTypes.CYCLE_COUNT02_DETAIL_RESET_TIMESTAMP]: cycleCount02DetailResetTimestamp,
  [CycleCount02DetailTypes.CYCLE_COUNT02_DETAIL_SET_HDR_ID]: cycleCount02DetailSetHdrId,

  [CycleCount02DetailTypes.CYCLE_COUNT02_DETAIL_SHOW_DOCUMENT_LOADING]: cycleCount02DetailShowDocumentLoading,
  [CycleCount02DetailTypes.CYCLE_COUNT02_DETAIL_SHOW_DETAILS_SUCCESS]: cycleCount02DetailShowDetailsSuccess,

  [CycleCount02DetailTypes.CYCLE_COUNT02_DETAIL_SET_WORKSPACE_VISIBLE]: cycleCount02DetailSetWorkspaceVisible,

  [CycleCount02DetailTypes.CYCLE_COUNT02_DETAIL_CREATE_CYCLE_COUNT02_SUCCESS]: cycleCount02DetailCreateCycleCount02Success,

  [CycleCount02DetailTypes.CYCLE_COUNT02_DETAIL_SET_EXPANDED_ROWS]: cycleCount02DetailSetExpandedRows,

  [CycleCount02DetailTypes.CYCLE_COUNT02_DETAIL_EXPAND_ALL_ROWS]: cycleCount02DetailExpandAllRows,
  [CycleCount02DetailTypes.CYCLE_COUNT02_DETAIL_COLLAPSE_ALL_ROWS]: cycleCount02DetailCollapseAllRows
});
