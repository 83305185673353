import React from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';

import { ArrowLeftOutlined, UndoOutlined, SaveOutlined } from '@ant-design/icons';

import { Button, Row, Col, Typography, Card } from 'antd';
import treeChanges from 'tree-changes';
import { FormikInput, FormikButton, FormikSelect, FormikDatePicker } from '../../Components/Formik';
import DivisionDetailActions from '../../Stores/DivisionDetail/Actions';
import AppActions from '../../Stores/App/Actions';

const { Text } = Typography;

class ModelForm extends React.PureComponent {
  constructor() {
    super();

    this.handleSearchSiteFlow = this.handleSearchSiteFlow.bind(this);
    this.handleSearchCompany = this.handleSearchCompany.bind(this);

    this.handleSearchSlsOrdDocNo = this.handleSearchSlsOrdDocNo.bind(this);
    this.handleSearchSlsRtnDocNo = this.handleSearchSlsRtnDocNo.bind(this);
    this.handleSearchDelOrdDocNo = this.handleSearchDelOrdDocNo.bind(this);
    this.handleSearchSlsInvDocNo = this.handleSearchSlsInvDocNo.bind(this);
    this.handleSearchRtnRcptDocNo = this.handleSearchRtnRcptDocNo.bind(this);
    this.handleSearchAdvShipDocNo = this.handleSearchAdvShipDocNo.bind(this);
    this.handleSearchPurInvDocNo = this.handleSearchPurInvDocNo.bind(this);
    this.handleSearchPurRtnDocNo = this.handleSearchPurRtnDocNo.bind(this);
    this.handleSearchLspSlsOrdDocNo = this.handleSearchLspSlsOrdDocNo.bind(this);
    this.handleSearchLspSlsRtnDocNo = this.handleSearchLspSlsRtnDocNo.bind(this);
    this.handleSearchLspAdvShipDocNo = this.handleSearchLspAdvShipDocNo.bind(this);
    this.handleSearchLspDelOrdDocNo = this.handleSearchLspDelOrdDocNo.bind(this);
    this.handleSearchProdOrdDocNo = this.handleSearchProdOrdDocNo.bind(this);
    this.handleSearchProdIssDocNo = this.handleSearchProdIssDocNo.bind(this);
    this.handleSearchProdRcptDocNo = this.handleSearchProdRcptDocNo.bind(this);
    this.handleSearchProdSheetDocNo = this.handleSearchProdSheetDocNo.bind(this);
    this.handleSearchOutbOrdDocNo = this.handleSearchOutbOrdDocNo.bind(this);
    this.handleSearchInbOrdDocNo = this.handleSearchInbOrdDocNo.bind(this);
    this.handleSearchTrfOrdDocNo = this.handleSearchTrfOrdDocNo.bind(this);
    this.handleSearchStkIssDocNo = this.handleSearchStkIssDocNo.bind(this);
    this.handleSearchStkRcptDocNo = this.handleSearchStkRcptDocNo.bind(this);
    this.handleSearchStkTrfDocNo = this.handleSearchStkTrfDocNo.bind(this);
    this.handleSearchWorkOrdDocNo = this.handleSearchWorkOrdDocNo.bind(this);
  }

  componentDidMount() {
    const { resId, resetTimestamp } = this.props;

    if (resId > 0) {
      resetTimestamp();
    }
  }

  componentDidUpdate(prevProps) {
    const { resId, resetTimestamp, initModel, showModel } = this.props;

    const { changed } = treeChanges(prevProps, this.props);

    if (changed('resId')) {
      resetTimestamp();
    }
    if (changed('timestamp')) {
      if (resId <= 0) {
        initModel();
      } else {
        showModel(resId);
      }
    }
  }

  componentWillUnmount() {}

  handleSearchSiteFlow(form, value) {
    const { fetchSiteFlowOptions } = this.props;

    fetchSiteFlowOptions(value);
  }

  handleSearchCompany(form, value) {
    const { fetchCompanyOptions } = this.props;

    fetchCompanyOptions(value);
  }

  handleSearchSlsOrdDocNo(form, value) {
    const { fetchDocNoOptions } = this.props;

    fetchDocNoOptions('SlsOrdHdr', value);
  }

  handleSearchSlsRtnDocNo(form, value) {
    const { fetchDocNoOptions } = this.props;

    fetchDocNoOptions('SlsRtnHdr', value);
  }

  handleSearchDelOrdDocNo(form, value) {
    const { fetchDocNoOptions } = this.props;

    fetchDocNoOptions('DelOrdHdr', value);
  }

  handleSearchSlsInvDocNo(form, value) {
    const { fetchDocNoOptions } = this.props;

    fetchDocNoOptions('SlsInvHdr', value);
  }

  handleSearchRtnRcptDocNo(form, value) {
    const { fetchDocNoOptions } = this.props;

    fetchDocNoOptions('RtnRcptHdr', value);
  }

  handleSearchAdvShipDocNo(form, value) {
    const { fetchDocNoOptions } = this.props;

    fetchDocNoOptions('AdvShipHdr', value);
  }

  handleSearchPurInvDocNo(form, value) {
    const { fetchDocNoOptions } = this.props;

    fetchDocNoOptions('PurInvHdr', value);
  }

  handleSearchPurRtnDocNo(form, value) {
    const { fetchDocNoOptions } = this.props;

    fetchDocNoOptions('PurRtnHdr', value);
  }

  handleSearchLspSlsOrdDocNo(form, value) {
    const { fetchDocNoOptions } = this.props;

    fetchDocNoOptions('LspSlsOrdHdr', value);
  }

  handleSearchLspSlsRtnDocNo(form, value) {
    const { fetchDocNoOptions } = this.props;

    fetchDocNoOptions('LspSlsRtnHdr', value);
  }

  handleSearchLspAdvShipDocNo(form, value) {
    const { fetchDocNoOptions } = this.props;

    fetchDocNoOptions('LspAdvShipHdr', value);
  }

  handleSearchLspDelOrdDocNo(form, value) {
    const { fetchDocNoOptions } = this.props;

    fetchDocNoOptions('LspDelOrdHdr', value);
  }

  handleSearchProdOrdDocNo(form, value) {
    const { fetchDocNoOptions } = this.props;

    fetchDocNoOptions('ProdOrdHdr', value);
  }

  handleSearchProdIssDocNo(form, value) {
    const { fetchDocNoOptions } = this.props;

    fetchDocNoOptions('ProdIssHdr', value);
  }

  handleSearchProdRcptDocNo(form, value) {
    const { fetchDocNoOptions } = this.props;

    fetchDocNoOptions('ProdRcptHdr', value);
  }

  handleSearchProdSheetDocNo(form, value) {
    const { fetchDocNoOptions } = this.props;

    fetchDocNoOptions('ProdSheetHdr', value);
  }

  handleSearchOutbOrdDocNo(form, value) {
    const { fetchDocNoOptions } = this.props;

    fetchDocNoOptions('OutbOrdHdr', value);
  }

  handleSearchInbOrdDocNo(form, value) {
    const { fetchDocNoOptions } = this.props;

    fetchDocNoOptions('InbOrdHdr', value);
  }

  handleSearchTrfOrdDocNo(form, value) {
    const { fetchDocNoOptions } = this.props;

    fetchDocNoOptions('TrfOrdHdr', value);
  }

  handleSearchStkIssDocNo(form, value) {
    const { fetchDocNoOptions } = this.props;

    fetchDocNoOptions('StkIssHdr', value);
  }

  handleSearchStkRcptDocNo(form, value) {
    const { fetchDocNoOptions } = this.props;

    fetchDocNoOptions('StkRcptHdr', value);
  }

  handleSearchStkTrfDocNo(form, value) {
    const { fetchDocNoOptions } = this.props;

    fetchDocNoOptions('StkTrfHdr', value);
  }

  handleSearchWorkOrdDocNo(form, value) {
    const { fetchDocNoOptions } = this.props;

    fetchDocNoOptions('WorkOrdHdr', value);
  }

  render() {
    const {
      intl,
      match,
      model,
      createModel,
      updateModel,
      historyGoBack,
      modelIsLoading,

      siteFlowIsLoading,
      siteFlowOptions,

      companyIsLoading,
      companyOptions,

      slsOrdDocNoIsLoading,
      slsOrdDocNoOptions,

      slsRtnDocNoIsLoading,
      slsRtnDocNoOptions,

      delOrdDocNoIsLoading,
      delOrdDocNoOptions,

      slsInvDocNoIsLoading,
      slsInvDocNoOptions,

      rtnRcptDocNoIsLoading,
      rtnRcptDocNoOptions,

      advShipDocNoIsLoading,
      advShipDocNoOptions,

      purInvDocNoIsLoading,
      purInvDocNoOptions,

      purRtnDocNoIsLoading,
      purRtnDocNoOptions,

      lspSlsOrdDocNoIsLoading,
      lspSlsOrdDocNoOptions,

      lspSlsRtnDocNoIsLoading,
      lspSlsRtnDocNoOptions,

      lspAdvShipDocNoIsLoading,
      lspAdvShipDocNoOptions,

      lspDelOrdDocNoIsLoading,
      lspDelOrdDocNoOptions,

      prodOrdDocNoIsLoading,
      prodOrdDocNoOptions,

      // prodIssDocNoIsLoading,
      // prodIssDocNoOptions,

      // prodRcptDocNoIsLoading,
      // prodRcptDocNoOptions,

      prodSheetDocNoIsLoading,
      prodSheetDocNoOptions,

      outbOrdDocNoIsLoading,
      outbOrdDocNoOptions,

      inbOrdDocNoIsLoading,
      inbOrdDocNoOptions,

      trfOrdDocNoIsLoading,
      trfOrdDocNoOptions,

      stkIssDocNoIsLoading,
      stkIssDocNoOptions,

      stkRcptDocNoIsLoading,
      stkRcptDocNoOptions,

      stkTrfDocNoIsLoading,
      stkTrfDocNoOptions,

      // workOrdDocNoIsLoading,
      // workOrdDocNoOptions,

      goToDocTypeDocNo
    } = this.props;

    const disabled = false;

    return (
      <Formik
        enableReinitialize
        initialValues={model}
        onSubmit={(values, formikBag) => {
          if (match.params.action === 'create') {
            createModel(formikBag, values);
          } else {
            updateModel(formikBag, values);
          }
        }}
        validationSchema={Yup.object().shape({
          code: Yup.string().required(intl.formatMessage({ id: 'code_is_required' })),
          name_01: Yup.string().required(intl.formatMessage({ id: 'name_is_required' })),
          site_flow_select2: Yup.object().shape({
            value: Yup.number().min(1, intl.formatMessage({ id: 'site_flow_is_required' }))
          }),
          company_select2: Yup.object().shape({
            value: Yup.number().min(1, intl.formatMessage({ id: 'company_is_required' }))
          })
        })}
      >
        {({
          // values,
          // handleChange,
          // errors,
          // setFieldTouched,
          dirty,
          // touched,
          isSubmitting,
          // isValid,
          handleSubmit,
          handleReset
        }) => (
          <Form>
            <Card title={intl.formatMessage({ id: 'general' })}>
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'code' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="code"
                    component={FormikInput}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'site_flow' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="site_flow_select2"
                    component={FormikSelect}
                    showArrow
                    filterOption={false}
                    onSearch={this.handleSearchSiteFlow}
                    notFoundContent={null}
                    loading={siteFlowIsLoading}
                    options={siteFlowOptions}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'company' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="company_select2"
                    component={FormikSelect}
                    showArrow
                    filterOption={false}
                    onSearch={this.handleSearchCompany}
                    notFoundContent={null}
                    loading={companyIsLoading}
                    options={companyOptions}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'ref_code' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="ref_code_01"
                    component={FormikInput}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'name' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="name_01"
                    component={FormikInput}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
              </Row>
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3} />
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="name_02"
                    component={FormikInput}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'sales_opening_date' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="sales_opening_date"
                    component={FormikDatePicker}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
              </Row>
            </Card>
            <Card title={intl.formatMessage({ id: 'document_codes' })}>
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'production_issue' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>

                <Col span={20}>
                  <Button type="dashed" onClick={() => goToDocTypeDocNo('ProdIssHdr')} block>
                    {intl.formatMessage({ id: 'add' })}
                  </Button>
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'production_receipt' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Button type="dashed" onClick={() => goToDocTypeDocNo('ProdRcptHdr')} block>
                    {intl.formatMessage({ id: 'add' })}
                  </Button>
                </Col>
              </Row>
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'work_order' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>

                <Col span={20}>
                  <Button type="dashed" onClick={() => goToDocTypeDocNo('WorkOrdHdr')} block>
                    {intl.formatMessage({ id: 'add' })}
                  </Button>
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'sales_order' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={17}>
                  <Field
                    name="sls_ord_doc_no_select2"
                    component={FormikSelect}
                    showArrow
                    filterOption={false}
                    onSearch={this.handleSearchSlsOrdDocNo}
                    notFoundContent={null}
                    loading={slsOrdDocNoIsLoading}
                    options={slsOrdDocNoOptions}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
                <Col span={3}>
                  <Button type="dashed" onClick={() => goToDocTypeDocNo('SlsOrdHdr')} block>
                    {intl.formatMessage({ id: 'add' })}
                  </Button>
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'sales_return' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={17}>
                  <Field
                    name="sls_rtn_doc_no_select2"
                    component={FormikSelect}
                    showArrow
                    filterOption={false}
                    onSearch={this.handleSearchSlsRtnDocNo}
                    notFoundContent={null}
                    loading={slsRtnDocNoIsLoading}
                    options={slsRtnDocNoOptions}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
                <Col span={3}>
                  <Button type="dashed" onClick={() => goToDocTypeDocNo('SlsRtnHdr')} block>
                    {intl.formatMessage({ id: 'add' })}
                  </Button>
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'delivery_order' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={17}>
                  <Field
                    name="del_ord_doc_no_select2"
                    component={FormikSelect}
                    showArrow
                    filterOption={false}
                    onSearch={this.handleSearchDelOrdDocNo}
                    notFoundContent={null}
                    loading={delOrdDocNoIsLoading}
                    options={delOrdDocNoOptions}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
                <Col span={3}>
                  <Button type="dashed" onClick={() => goToDocTypeDocNo('DelOrdHdr')} block>
                    {intl.formatMessage({ id: 'add' })}
                  </Button>
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'sales_invoice' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={17}>
                  <Field
                    name="sls_inv_doc_no_select2"
                    component={FormikSelect}
                    showArrow
                    filterOption={false}
                    onSearch={this.handleSearchSlsInvDocNo}
                    notFoundContent={null}
                    loading={slsInvDocNoIsLoading}
                    options={slsInvDocNoOptions}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
                <Col span={3}>
                  <Button type="dashed" onClick={() => goToDocTypeDocNo('SlsInvHdr')} block>
                    {intl.formatMessage({ id: 'add' })}
                  </Button>
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'cash_invoice' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={17}>
                  <Field
                    name="cash_inv_doc_no_select2"
                    component={FormikSelect}
                    showArrow
                    filterOption={false}
                    onSearch={this.handleSearchSlsInvDocNo}
                    notFoundContent={null}
                    loading={slsInvDocNoIsLoading}
                    options={slsInvDocNoOptions}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
                <Col span={3}>
                  <Button type="dashed" onClick={() => goToDocTypeDocNo('SlsInvHdr')} block>
                    {intl.formatMessage({ id: 'add' })}
                  </Button>
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'return_receipt' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={17}>
                  <Field
                    name="rtn_rcpt_doc_no_select2"
                    component={FormikSelect}
                    showArrow
                    filterOption={false}
                    onSearch={this.handleSearchRtnRcptDocNo}
                    notFoundContent={null}
                    loading={rtnRcptDocNoIsLoading}
                    options={rtnRcptDocNoOptions}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
                <Col span={3}>
                  <Button type="dashed" onClick={() => goToDocTypeDocNo('RtnRcptHdr')} block>
                    {intl.formatMessage({ id: 'add' })}
                  </Button>
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'advanced_shipment_notice' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={17}>
                  <Field
                    name="adv_ship_doc_no_select2"
                    component={FormikSelect}
                    showArrow
                    filterOption={false}
                    onSearch={this.handleSearchAdvShipDocNo}
                    notFoundContent={null}
                    loading={advShipDocNoIsLoading}
                    options={advShipDocNoOptions}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
                <Col span={3}>
                  <Button type="dashed" onClick={() => goToDocTypeDocNo('AdvShipHdr')} block>
                    {intl.formatMessage({ id: 'add' })}
                  </Button>
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'purchase_invoice' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={17}>
                  <Field
                    name="pur_inv_doc_no_select2"
                    component={FormikSelect}
                    showArrow
                    filterOption={false}
                    onSearch={this.handleSearchPurInvDocNo}
                    notFoundContent={null}
                    loading={purInvDocNoIsLoading}
                    options={purInvDocNoOptions}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
                <Col span={3}>
                  <Button type="dashed" onClick={() => goToDocTypeDocNo('PurInvHdr')} block>
                    {intl.formatMessage({ id: 'add' })}
                  </Button>
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'purchase_return' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={17}>
                  <Field
                    name="pur_rtn_doc_no_select2"
                    component={FormikSelect}
                    showArrow
                    filterOption={false}
                    onSearch={this.handleSearchPurRtnDocNo}
                    notFoundContent={null}
                    loading={purRtnDocNoIsLoading}
                    options={purRtnDocNoOptions}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
                <Col span={3}>
                  <Button type="dashed" onClick={() => goToDocTypeDocNo('PurRtnHdr')} block>
                    {intl.formatMessage({ id: 'add' })}
                  </Button>
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'logistic_sales_order' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={17}>
                  <Field
                    name="lsp_sls_ord_doc_no_select2"
                    component={FormikSelect}
                    showArrow
                    filterOption={false}
                    onSearch={this.handleSearchLspSlsOrdDocNo}
                    notFoundContent={null}
                    loading={lspSlsOrdDocNoIsLoading}
                    options={lspSlsOrdDocNoOptions}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
                <Col span={3}>
                  <Button type="dashed" onClick={() => goToDocTypeDocNo('LspSlsOrdHdr')} block>
                    {intl.formatMessage({ id: 'add' })}
                  </Button>
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'logistic_sales_return' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={17}>
                  <Field
                    name="lsp_sls_rtn_doc_no_select2"
                    component={FormikSelect}
                    showArrow
                    filterOption={false}
                    onSearch={this.handleSearchLspSlsRtnDocNo}
                    notFoundContent={null}
                    loading={lspSlsRtnDocNoIsLoading}
                    options={lspSlsRtnDocNoOptions}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
                <Col span={3}>
                  <Button type="dashed" onClick={() => goToDocTypeDocNo('LspSlsRtnHdr')} block>
                    {intl.formatMessage({ id: 'add' })}
                  </Button>
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'logistic_advanced_shipment_notice' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={17}>
                  <Field
                    name="lsp_adv_ship_doc_no_select2"
                    component={FormikSelect}
                    showArrow
                    filterOption={false}
                    onSearch={this.handleSearchLspAdvShipDocNo}
                    notFoundContent={null}
                    loading={lspAdvShipDocNoIsLoading}
                    options={lspAdvShipDocNoOptions}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
                <Col span={3}>
                  <Button type="dashed" onClick={() => goToDocTypeDocNo('LspAdvShipHdr')} block>
                    {intl.formatMessage({ id: 'add' })}
                  </Button>
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'logistic_delivery_order' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={17}>
                  <Field
                    name="lsp_del_ord_doc_no_select2"
                    component={FormikSelect}
                    showArrow
                    filterOption={false}
                    onSearch={this.handleSearchLspDelOrdDocNo}
                    notFoundContent={null}
                    loading={lspDelOrdDocNoIsLoading}
                    options={lspDelOrdDocNoOptions}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
                <Col span={3}>
                  <Button type="dashed" onClick={() => goToDocTypeDocNo('LspDelOrdHdr')} block>
                    {intl.formatMessage({ id: 'add' })}
                  </Button>
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'production_order' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={17}>
                  <Field
                    name="prod_ord_doc_no_select2"
                    component={FormikSelect}
                    showArrow
                    filterOption={false}
                    onSearch={this.handleSearchProdOrdDocNo}
                    notFoundContent={null}
                    loading={prodOrdDocNoIsLoading}
                    options={prodOrdDocNoOptions}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
                <Col span={3}>
                  <Button type="dashed" onClick={() => goToDocTypeDocNo('ProdOrdHdr')} block>
                    {intl.formatMessage({ id: 'add' })}
                  </Button>
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'production_sheet' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={17}>
                  <Field
                    name="prod_sheet_doc_no_select2"
                    component={FormikSelect}
                    showArrow
                    filterOption={false}
                    onSearch={this.handleSearchProdSheetDocNo}
                    notFoundContent={null}
                    loading={prodSheetDocNoIsLoading}
                    options={prodSheetDocNoOptions}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
                <Col span={3}>
                  <Button type="dashed" onClick={() => goToDocTypeDocNo('ProdSheetHdr')} block>
                    {intl.formatMessage({ id: 'add' })}
                  </Button>
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'outbound_order' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={17}>
                  <Field
                    name="outb_ord_doc_no_select2"
                    component={FormikSelect}
                    showArrow
                    filterOption={false}
                    onSearch={this.handleSearchOutbOrdDocNo}
                    notFoundContent={null}
                    loading={outbOrdDocNoIsLoading}
                    options={outbOrdDocNoOptions}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
                <Col span={3}>
                  <Button type="dashed" onClick={() => goToDocTypeDocNo('OutbOrdHdr')} block>
                    {intl.formatMessage({ id: 'add' })}
                  </Button>
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'inbound_order' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={17}>
                  <Field
                    name="inb_ord_doc_no_select2"
                    component={FormikSelect}
                    showArrow
                    filterOption={false}
                    onSearch={this.handleSearchInbOrdDocNo}
                    notFoundContent={null}
                    loading={inbOrdDocNoIsLoading}
                    options={inbOrdDocNoOptions}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
                <Col span={3}>
                  <Button type="dashed" onClick={() => goToDocTypeDocNo('InbOrdHdr')} block>
                    {intl.formatMessage({ id: 'add' })}
                  </Button>
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'transfer_order' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={17}>
                  <Field
                    name="trf_ord_doc_no_select2"
                    component={FormikSelect}
                    showArrow
                    filterOption={false}
                    onSearch={this.handleSearchTrfOrdDocNo}
                    notFoundContent={null}
                    loading={trfOrdDocNoIsLoading}
                    options={trfOrdDocNoOptions}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
                <Col span={3}>
                  <Button type="dashed" onClick={() => goToDocTypeDocNo('TrfOrdHdr')} block>
                    {intl.formatMessage({ id: 'add' })}
                  </Button>
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'stock_issue' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={17}>
                  <Field
                    name="stk_iss_doc_no_select2"
                    component={FormikSelect}
                    showArrow
                    filterOption={false}
                    onSearch={this.handleSearchStkIssDocNo}
                    notFoundContent={null}
                    loading={stkIssDocNoIsLoading}
                    options={stkIssDocNoOptions}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
                <Col span={3}>
                  <Button type="dashed" onClick={() => goToDocTypeDocNo('StkIssHdr')} block>
                    {intl.formatMessage({ id: 'add' })}
                  </Button>
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'stock_receipt' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={17}>
                  <Field
                    name="stk_rcpt_doc_no_select2"
                    component={FormikSelect}
                    showArrow
                    filterOption={false}
                    onSearch={this.handleSearchStkRcptDocNo}
                    notFoundContent={null}
                    loading={stkRcptDocNoIsLoading}
                    options={stkRcptDocNoOptions}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
                <Col span={3}>
                  <Button type="dashed" onClick={() => goToDocTypeDocNo('StkRcptHdr')} block>
                    {intl.formatMessage({ id: 'add' })}
                  </Button>
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'stock_transfer' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={17}>
                  <Field
                    name="stk_trf_doc_no_select2"
                    component={FormikSelect}
                    showArrow
                    filterOption={false}
                    onSearch={this.handleSearchStkTrfDocNo}
                    notFoundContent={null}
                    loading={stkTrfDocNoIsLoading}
                    options={stkTrfDocNoOptions}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
                <Col span={3}>
                  <Button type="dashed" onClick={() => goToDocTypeDocNo('StkTrfHdr')} block>
                    {intl.formatMessage({ id: 'add' })}
                  </Button>
                </Col>
              </Row>
            </Card>
            <Card>
              <Row type="flex" justify="space-between" gutter={[0, 16]}>
                <Col span={8}>
                  <Button
                    type="primary"
                    // disabled={!isValid}
                    loading={isSubmitting}
                    onClick={historyGoBack}
                    icon={<ArrowLeftOutlined />}
                  >
                    {intl.formatMessage({
                      id: 'back'
                    })}
                  </Button>
                </Col>
                <Col span={6}>
                  <Button
                    type="primary"
                    disabled={!dirty}
                    loading={isSubmitting || modelIsLoading}
                    onClick={handleReset}
                    icon={<UndoOutlined />}
                  >
                    {intl.formatMessage({ id: 'reset' })}
                  </Button>
                  <Field
                    name="submit_action"
                    type="primary"
                    value="update"
                    component={FormikButton}
                    // disabled={values.status >= 50}
                    loading={isSubmitting || modelIsLoading}
                    onClick={handleSubmit}
                    label={intl.formatMessage({
                      id: match.params.action === 'create' ? 'create' : 'update'
                    })}
                    icon={<SaveOutlined />}
                  />
                </Col>
              </Row>
            </Card>
          </Form>
        )}
      </Formik>
    );
  }
}

ModelForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object,
  resetTimestamp: PropTypes.func,

  initModel: PropTypes.func,
  showModel: PropTypes.func,
  createModel: PropTypes.func,
  updateModel: PropTypes.func,
  historyGoBack: PropTypes.func,
  resId: PropTypes.number,
  model: PropTypes.shape({
    status: PropTypes.number
  }),
  modelIsLoading: PropTypes.bool,

  fetchSiteFlowOptions: PropTypes.func,
  siteFlowIsLoading: PropTypes.bool,
  siteFlowOptions: PropTypes.arrayOf(PropTypes.object),

  fetchCompanyOptions: PropTypes.func,
  companyIsLoading: PropTypes.bool,
  companyOptions: PropTypes.arrayOf(PropTypes.object),

  fetchDocNoOptions: PropTypes.func,

  slsOrdDocNoIsLoading: PropTypes.bool,
  slsOrdDocNoOptions: PropTypes.arrayOf(PropTypes.object),

  slsRtnDocNoIsLoading: PropTypes.bool,
  slsRtnDocNoOptions: PropTypes.arrayOf(PropTypes.object),

  delOrdDocNoIsLoading: PropTypes.bool,
  delOrdDocNoOptions: PropTypes.arrayOf(PropTypes.object),

  slsInvDocNoIsLoading: PropTypes.bool,
  slsInvDocNoOptions: PropTypes.arrayOf(PropTypes.object),

  rtnRcptDocNoIsLoading: PropTypes.bool,
  rtnRcptDocNoOptions: PropTypes.arrayOf(PropTypes.object),

  advShipDocNoIsLoading: PropTypes.bool,
  advShipDocNoOptions: PropTypes.arrayOf(PropTypes.object),

  purInvDocNoIsLoading: PropTypes.bool,
  purInvDocNoOptions: PropTypes.arrayOf(PropTypes.object),

  purRtnDocNoIsLoading: PropTypes.bool,
  purRtnDocNoOptions: PropTypes.arrayOf(PropTypes.object),

  lspSlsOrdDocNoIsLoading: PropTypes.bool,
  lspSlsOrdDocNoOptions: PropTypes.arrayOf(PropTypes.object),

  lspSlsRtnDocNoIsLoading: PropTypes.bool,
  lspSlsRtnDocNoOptions: PropTypes.arrayOf(PropTypes.object),

  lspAdvShipDocNoIsLoading: PropTypes.bool,
  lspAdvShipDocNoOptions: PropTypes.arrayOf(PropTypes.object),

  lspDelOrdDocNoIsLoading: PropTypes.bool,
  lspDelOrdDocNoOptions: PropTypes.arrayOf(PropTypes.object),

  prodOrdDocNoIsLoading: PropTypes.bool,
  prodOrdDocNoOptions: PropTypes.arrayOf(PropTypes.object),

  // prodIssDocNoIsLoading: PropTypes.bool,
  // prodIssDocNoOptions: PropTypes.arrayOf(PropTypes.object),

  // prodRcptDocNoIsLoading: PropTypes.bool,
  // prodRcptDocNoOptions: PropTypes.arrayOf(PropTypes.object),

  prodSheetDocNoIsLoading: PropTypes.bool,
  prodSheetDocNoOptions: PropTypes.arrayOf(PropTypes.object),

  outbOrdDocNoIsLoading: PropTypes.bool,
  outbOrdDocNoOptions: PropTypes.arrayOf(PropTypes.object),

  inbOrdDocNoIsLoading: PropTypes.bool,
  inbOrdDocNoOptions: PropTypes.arrayOf(PropTypes.object),

  trfOrdDocNoIsLoading: PropTypes.bool,
  trfOrdDocNoOptions: PropTypes.arrayOf(PropTypes.object),

  stkIssDocNoIsLoading: PropTypes.bool,
  stkIssDocNoOptions: PropTypes.arrayOf(PropTypes.object),

  stkRcptDocNoIsLoading: PropTypes.bool,
  stkRcptDocNoOptions: PropTypes.arrayOf(PropTypes.object),

  stkTrfDocNoIsLoading: PropTypes.bool,
  stkTrfDocNoOptions: PropTypes.arrayOf(PropTypes.object),

  // workOrdDocNoIsLoading: PropTypes.bool,
  // workOrdDocNoOptions: PropTypes.arrayOf(PropTypes.object),

  goToDocTypeDocNo: PropTypes.func
};

ModelForm.defaultProps = {
  intl: {},
  match: {},
  resetTimestamp() {},

  initModel() {},
  showModel() {},
  createModel() {},
  updateModel() {},
  historyGoBack() {},
  resId: 0,
  model: { code: '' },
  modelIsLoading: false,

  fetchSiteFlowOptions() {},
  siteFlowIsLoading: false,
  siteFlowOptions: [],

  fetchCompanyOptions() {},
  companyIsLoading: false,
  companyOptions: [],

  fetchDocNoOptions() {},

  slsOrdDocNoIsLoading: false,
  slsOrdDocNoOptions: [],

  slsRtnDocNoIsLoading: false,
  slsRtnDocNoOptions: [],

  delOrdDocNoIsLoading: false,
  delOrdDocNoOptions: [],

  slsInvDocNoIsLoading: false,
  slsInvDocNoOptions: [],

  rtnRcptDocNoIsLoading: false,
  rtnRcptDocNoOptions: [],

  advShipDocNoIsLoading: false,
  advShipDocNoOptions: [],

  purInvDocNoIsLoading: false,
  purInvDocNoOptions: [],

  purRtnDocNoIsLoading: false,
  purRtnDocNoOptions: [],

  lspSlsOrdDocNoIsLoading: false,
  lspSlsOrdDocNoOptions: [],

  lspSlsRtnDocNoIsLoading: false,
  lspSlsRtnDocNoOptions: [],

  lspAdvShipDocNoIsLoading: false,
  lspAdvShipDocNoOptions: [],

  lspDelOrdDocNoIsLoading: false,
  lspDelOrdDocNoOptions: [],

  prodOrdDocNoIsLoading: false,
  prodOrdDocNoOptions: [],

  // prodIssDocNoIsLoading: false,
  // prodIssDocNoOptions: [],

  // prodRcptDocNoIsLoading: false,
  // prodRcptDocNoOptions: [],

  prodSheetDocNoIsLoading: false,
  prodSheetDocNoOptions: [],

  outbOrdDocNoIsLoading: false,
  outbOrdDocNoOptions: [],

  inbOrdDocNoIsLoading: false,
  inbOrdDocNoOptions: [],

  trfOrdDocNoIsLoading: false,
  trfOrdDocNoOptions: [],

  stkIssDocNoIsLoading: false,
  stkIssDocNoOptions: [],

  stkRcptDocNoIsLoading: false,
  stkRcptDocNoOptions: [],

  stkTrfDocNoIsLoading: false,
  stkTrfDocNoOptions: [],

  // workOrdDocNoIsLoading: false,
  // workOrdDocNoOptions: [],

  goToDocTypeDocNo() {}
};

const mapStateToProps = state => ({
  apiUrl: state.app.apiUrl,
  timestamp: state.divisionDetail.modelTimestamp,
  resId: state.divisionDetail.resId,
  model: state.divisionDetail.model,
  modelIsLoading: state.divisionDetail.modelIsLoading,

  siteFlowIsLoading: state.divisionDetail.siteFlowIsLoading,
  siteFlowOptions: state.divisionDetail.siteFlowOptions,

  companyIsLoading: state.divisionDetail.companyIsLoading,
  companyOptions: state.divisionDetail.companyOptions,

  slsOrdDocNoIsLoading: state.divisionDetail.slsOrdDocNoIsLoading,
  slsOrdDocNoOptions: state.divisionDetail.slsOrdDocNoOptions,

  slsRtnDocNoIsLoading: state.divisionDetail.slsRtnDocNoIsLoading,
  slsRtnDocNoOptions: state.divisionDetail.slsRtnDocNoOptions,

  delOrdDocNoIsLoading: state.divisionDetail.delOrdDocNoIsLoading,
  delOrdDocNoOptions: state.divisionDetail.delOrdDocNoOptions,

  slsInvDocNoIsLoading: state.divisionDetail.slsInvDocNoIsLoading,
  slsInvDocNoOptions: state.divisionDetail.slsInvDocNoOptions,

  rtnRcptDocNoIsLoading: state.divisionDetail.rtnRcptDocNoIsLoading,
  rtnRcptDocNoOptions: state.divisionDetail.rtnRcptDocNoOptions,

  advShipDocNoIsLoading: state.divisionDetail.advShipDocNoIsLoading,
  advShipDocNoOptions: state.divisionDetail.advShipDocNoOptions,

  purInvDocNoIsLoading: state.divisionDetail.purInvDocNoIsLoading,
  purInvDocNoOptions: state.divisionDetail.purInvDocNoOptions,

  purRtnDocNoIsLoading: state.divisionDetail.purRtnDocNoIsLoading,
  purRtnDocNoOptions: state.divisionDetail.purRtnDocNoOptions,

  lspSlsOrdDocNoIsLoading: state.divisionDetail.lspSlsOrdDocNoIsLoading,
  lspSlsOrdDocNoOptions: state.divisionDetail.lspSlsOrdDocNoOptions,

  lspSlsRtnDocNoIsLoading: state.divisionDetail.lspSlsRtnDocNoIsLoading,
  lspSlsRtnDocNoOptions: state.divisionDetail.lspSlsRtnDocNoOptions,

  lspAdvShipDocNoIsLoading: state.divisionDetail.lspAdvShipDocNoIsLoading,
  lspAdvShipDocNoOptions: state.divisionDetail.lspAdvShipDocNoOptions,

  lspDelOrdDocNoIsLoading: state.divisionDetail.lspDelOrdDocNoIsLoading,
  lspDelOrdDocNoOptions: state.divisionDetail.lspDelOrdDocNoOptions,

  prodOrdDocNoIsLoading: state.divisionDetail.prodOrdDocNoIsLoading,
  prodOrdDocNoOptions: state.divisionDetail.prodOrdDocNoOptions,

  prodIssDocNoIsLoading: state.divisionDetail.prodIssDocNoIsLoading,
  prodIssDocNoOptions: state.divisionDetail.prodIssDocNoOptions,

  prodRcptDocNoIsLoading: state.divisionDetail.prodRcptDocNoIsLoading,
  prodRcptDocNoOptions: state.divisionDetail.prodRcptDocNoOptions,

  prodSheetDocNoIsLoading: state.divisionDetail.prodSheetDocNoIsLoading,
  prodSheetDocNoOptions: state.divisionDetail.prodSheetDocNoOptions,

  outbOrdDocNoIsLoading: state.divisionDetail.outbOrdDocNoIsLoading,
  outbOrdDocNoOptions: state.divisionDetail.outbOrdDocNoOptions,

  inbOrdDocNoIsLoading: state.divisionDetail.inbOrdDocNoIsLoading,
  inbOrdDocNoOptions: state.divisionDetail.inbOrdDocNoOptions,

  trfOrdDocNoIsLoading: state.divisionDetail.trfOrdDocNoIsLoading,
  trfOrdDocNoOptions: state.divisionDetail.trfOrdDocNoOptions,

  stkIssDocNoIsLoading: state.divisionDetail.stkIssDocNoIsLoading,
  stkIssDocNoOptions: state.divisionDetail.stkIssDocNoOptions,

  stkRcptDocNoIsLoading: state.divisionDetail.stkRcptDocNoIsLoading,
  stkRcptDocNoOptions: state.divisionDetail.stkRcptDocNoOptions,

  stkTrfDocNoIsLoading: state.divisionDetail.stkTrfDocNoIsLoading,
  stkTrfDocNoOptions: state.divisionDetail.stkTrfDocNoOptions,

  workOrdDocNoIsLoading: state.divisionDetail.workOrdDocNoIsLoading,
  workOrdDocNoOptions: state.divisionDetail.workOrdDocNoOptions
});

const mapDispatchToProps = dispatch => ({
  resetTimestamp: () => dispatch(DivisionDetailActions.divisionDetailResetModelTimestamp()),
  initModel: () => dispatch(DivisionDetailActions.divisionDetailInitModel()),
  showModel: resId => dispatch(DivisionDetailActions.divisionDetailShowModel(resId)),

  updateModel: (formikBag, model) =>
    dispatch(DivisionDetailActions.divisionDetailUpdateModel(formikBag, model)),
  createModel: (formikBag, model) =>
    dispatch(DivisionDetailActions.divisionDetailCreateModel(formikBag, model)),

  fetchSiteFlowOptions: search =>
    dispatch(DivisionDetailActions.divisionDetailFetchSiteFlowOptions(search)),

  fetchCompanyOptions: search =>
    dispatch(DivisionDetailActions.divisionDetailFetchCompanyOptions(search)),

  fetchDocNoOptions: (docType, search) =>
    dispatch(DivisionDetailActions.divisionDetailFetchDocNoOptions(docType, search)),

  goToDocTypeDocNo: docType =>
    dispatch(DivisionDetailActions.divisionDetailGoToDocTypeDocNo(docType)),

  historyGoBack: () => dispatch(AppActions.appHistoryGoBack())
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ModelForm));
