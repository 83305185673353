import { put, call, select, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import ApiService from '../Services/ApiService';
import AppActions from '../Stores/App/Actions';
import LoadListDetailBoardActions, {
  LoadListDetailBoardTypes
} from '../Stores/LoadListDetailBoard/Actions';

const getAppStore = state => state.app;

export function* loadListDetailBoardFetchWipPickList({ currentPage, sorts, filters, pageSize }) {
  try {
    yield put(LoadListDetailBoardActions.loadListDetailBoardFetchWipPickListLoading(true));

    const processedSorts = [];
    Object.entries(sorts).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      processedSorts.push(`${key}:${value === 'ascend' ? 'ASC' : 'DESC'}`);
    });

    const processedFilters = [];
    Object.entries(filters).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      if (value) {
        processedFilters.push(`${key}:${value}`);
      }
    });

    const app = yield select(getAppStore);
    const getData = {
      page: currentPage,
      sorts: processedSorts,
      filters: processedFilters,
      pageSize
    };

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `pickList/indexToDoLoadList/${app.curSiteFlowId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      // if nowCurrentPage is more than lastPage, then nowCurrentPage = lastPage
      let nowCurrentPage = result.data.current_page;
      if (nowCurrentPage > result.data.last_page) {
        nowCurrentPage = result.data.last_page;
      }

      yield put(
        LoadListDetailBoardActions.loadListDetailBoardFetchWipPickListSuccess(
          result.data.data,
          nowCurrentPage,
          result.data.last_page,
          result.data.total,
          result.data.per_page
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(LoadListDetailBoardActions.loadListDetailBoardFetchWipPickListLoading(false));
  }
}

export function* loadListDetailBoardFetchWipLoadList({ currentPage, sorts, filters, pageSize }) {
  try {
    yield put(LoadListDetailBoardActions.loadListDetailBoardFetchWipLoadListLoading(true));

    const processedSorts = [];
    Object.entries(sorts).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      processedSorts.push(`${key}:${value === 'ascend' ? 'ASC' : 'DESC'}`);
    });

    const processedFilters = [];
    Object.entries(filters).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      if (value) {
        processedFilters.push(`${key}:${value}`);
      }
    });

    const app = yield select(getAppStore);
    const getData = {
      page: currentPage,
      sorts: processedSorts,
      filters: processedFilters,
      pageSize
    };

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `loadList/indexToDoWhseJob/${app.curSiteFlowId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      // if nowCurrentPage is more than lastPage, then nowCurrentPage = lastPage
      let nowCurrentPage = result.data.current_page;
      if (nowCurrentPage > result.data.last_page) {
        nowCurrentPage = result.data.last_page;
      }

      yield put(
        LoadListDetailBoardActions.loadListDetailBoardFetchWipLoadListSuccess(
          result.data.data,
          nowCurrentPage,
          result.data.last_page,
          result.data.total,
          result.data.per_page
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(LoadListDetailBoardActions.loadListDetailBoardFetchWipLoadListLoading(false));
  }
}

export function* loadListDetailBoardFetchWipWhseJob({ currentPage, sorts, filters, pageSize }) {
  try {
    yield put(LoadListDetailBoardActions.loadListDetailBoardFetchWipWhseJobLoading(true));

    const processedSorts = [];
    Object.entries(sorts).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      processedSorts.push(`${key}:${value === 'ascend' ? 'ASC' : 'DESC'}`);
    });

    const processedFilters = [];
    Object.entries(filters).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      if (value) {
        processedFilters.push(`${key}:${value}`);
      }
    });

    const app = yield select(getAppStore);
    const getData = {
      page: currentPage,
      sorts: processedSorts,
      filters: processedFilters,
      pageSize
    };

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `whseJob/indexToDoLoadList/${app.curSiteFlowId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      // if nowCurrentPage is more than lastPage, then nowCurrentPage = lastPage
      let nowCurrentPage = result.data.current_page;
      if (nowCurrentPage > result.data.last_page) {
        nowCurrentPage = result.data.last_page;
      }

      yield put(
        LoadListDetailBoardActions.loadListDetailBoardFetchWipWhseJobSuccess(
          result.data.data,
          nowCurrentPage,
          result.data.last_page,
          result.data.total,
          result.data.per_page
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(LoadListDetailBoardActions.loadListDetailBoardFetchWipWhseJobLoading(false));
  }
}

export function* loadListDetailBoardFetchCompleteLoadList({
  currentPage,
  sorts,
  filters,
  pageSize
}) {
  try {
    yield put(LoadListDetailBoardActions.loadListDetailBoardFetchCompleteLoadListLoading(true));

    const processedSorts = [];
    Object.entries(sorts).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      processedSorts.push(`${key}:${value === 'ascend' ? 'ASC' : 'DESC'}`);
    });

    const processedFilters = [];
    Object.entries(filters).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      if (value) {
        processedFilters.push(`${key}:${value}`);
      }
    });

    const app = yield select(getAppStore);
    const getData = {
      page: currentPage,
      sorts: processedSorts,
      filters: processedFilters,
      pageSize
    };

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `loadList/indexToDoPrfDel/${app.curSiteFlowId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      // if nowCurrentPage is more than lastPage, then nowCurrentPage = lastPage
      let nowCurrentPage = result.data.current_page;
      if (nowCurrentPage > result.data.last_page) {
        nowCurrentPage = result.data.last_page;
      }

      yield put(
        LoadListDetailBoardActions.loadListDetailBoardFetchCompleteLoadListSuccess(
          result.data.data,
          nowCurrentPage,
          result.data.last_page,
          result.data.total,
          result.data.per_page
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(LoadListDetailBoardActions.loadListDetailBoardFetchCompleteLoadListLoading(false));
  }
}

export function* loadListDetailBoardShowWipPickListSummary({ docId }) {
  try {
    yield put(LoadListDetailBoardActions.loadListDetailBoardShowWipPickListSummaryLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `pickList/showSummary/${docId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(
        LoadListDetailBoardActions.loadListDetailBoardShowWipPickListSummarySuccess(result.data)
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(LoadListDetailBoardActions.loadListDetailBoardShowWipPickListSummaryLoading(false));
  }
}

export function* loadListDetailBoardShowWipLoadListSummary({ docId }) {
  try {
    yield put(LoadListDetailBoardActions.loadListDetailBoardShowWipLoadListSummaryLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `loadList/showSummary/${docId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(
        LoadListDetailBoardActions.loadListDetailBoardShowWipLoadListSummarySuccess(result.data)
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(LoadListDetailBoardActions.loadListDetailBoardShowWipLoadListSummaryLoading(false));
  }
}

export function* loadListDetailBoardShowWipWhseJobSummary({ docId }) {
  try {
    yield put(LoadListDetailBoardActions.loadListDetailBoardShowWipWhseJobSummaryLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `whseJob/showLoadListSummary/${docId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(
        LoadListDetailBoardActions.loadListDetailBoardShowWipWhseJobSummarySuccess(result.data)
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(LoadListDetailBoardActions.loadListDetailBoardShowWipWhseJobSummaryLoading(false));
  }
}

export function* loadListDetailBoardShowCompleteLoadListSummary({ docId }) {
  try {
    yield put(
      LoadListDetailBoardActions.loadListDetailBoardShowCompleteLoadListSummaryLoading(true)
    );

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `loadList/showSummary/${docId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(
        LoadListDetailBoardActions.loadListDetailBoardShowCompleteLoadListSummarySuccess(
          result.data
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(
      LoadListDetailBoardActions.loadListDetailBoardShowCompleteLoadListSummaryLoading(false)
    );
  }
}

export const saga = [
  takeLatest(
    LoadListDetailBoardTypes.LOAD_LIST_DETAIL_BOARD_FETCH_WIP_PICK_LIST,
    loadListDetailBoardFetchWipPickList
  ),

  takeLatest(
    LoadListDetailBoardTypes.LOAD_LIST_DETAIL_BOARD_FETCH_WIP_LOAD_LIST,
    loadListDetailBoardFetchWipLoadList
  ),

  takeLatest(
    LoadListDetailBoardTypes.LOAD_LIST_DETAIL_BOARD_FETCH_WIP_WHSE_JOB,
    loadListDetailBoardFetchWipWhseJob
  ),

  takeLatest(
    LoadListDetailBoardTypes.LOAD_LIST_DETAIL_BOARD_FETCH_COMPLETE_LOAD_LIST,
    loadListDetailBoardFetchCompleteLoadList
  ),

  takeLatest(
    LoadListDetailBoardTypes.LOAD_LIST_DETAIL_BOARD_SHOW_WIP_PICK_LIST_SUMMARY,
    loadListDetailBoardShowWipPickListSummary
  ),

  takeLatest(
    LoadListDetailBoardTypes.LOAD_LIST_DETAIL_BOARD_SHOW_WIP_LOAD_LIST_SUMMARY,
    loadListDetailBoardShowWipLoadListSummary
  ),

  takeLatest(
    LoadListDetailBoardTypes.LOAD_LIST_DETAIL_BOARD_SHOW_WIP_WHSE_JOB_SUMMARY,
    loadListDetailBoardShowWipWhseJobSummary
  ),

  takeLatest(
    LoadListDetailBoardTypes.LOAD_LIST_DETAIL_BOARD_SHOW_COMPLETE_LOAD_LIST_SUMMARY,
    loadListDetailBoardShowCompleteLoadListSummary
  )
];
