import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  gdsRcptDetailBoardResetWipInbOrdTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  gdsRcptDetailBoardFetchWipInbOrd: ['currentPage', 'sorts', 'filters', 'pageSize'],
  gdsRcptDetailBoardFetchWipInbOrdLoading: ['boolean'],
  gdsRcptDetailBoardFetchWipInbOrdSuccess: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],

  gdsRcptDetailBoardResetWipGdsRcptTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  gdsRcptDetailBoardFetchWipGdsRcpt: ['currentPage', 'sorts', 'filters', 'pageSize'],
  gdsRcptDetailBoardFetchWipGdsRcptLoading: ['boolean'],
  gdsRcptDetailBoardFetchWipGdsRcptSuccess: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],

  gdsRcptDetailBoardResetWipWhseJobTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  gdsRcptDetailBoardFetchWipWhseJob: ['currentPage', 'sorts', 'filters', 'pageSize'],
  gdsRcptDetailBoardFetchWipWhseJobLoading: ['boolean'],
  gdsRcptDetailBoardFetchWipWhseJobSuccess: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],

  gdsRcptDetailBoardResetCompleteGdsRcptTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  gdsRcptDetailBoardFetchCompleteGdsRcpt: ['currentPage', 'sorts', 'filters', 'pageSize'],
  gdsRcptDetailBoardFetchCompleteGdsRcptLoading: ['boolean'],
  gdsRcptDetailBoardFetchCompleteGdsRcptSuccess: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],

  gdsRcptDetailBoardSetWipWhseJobDialogVisible: ['boolean'],
  gdsRcptDetailBoardShowWipWhseJobSummary: ['docId'],
  gdsRcptDetailBoardShowWipWhseJobSummaryLoading: ['boolean'],
  gdsRcptDetailBoardShowWipWhseJobSummarySuccess: ['document'],

  gdsRcptDetailBoardSetWipGdsRcptDialogVisible: ['boolean'],
  gdsRcptDetailBoardShowWipGdsRcptSummary: ['docId'],
  gdsRcptDetailBoardShowWipGdsRcptSummaryLoading: ['boolean'],
  gdsRcptDetailBoardShowWipGdsRcptSummarySuccess: ['document'],

  gdsRcptDetailBoardSetWipInbOrdDialogVisible: ['boolean'],
  gdsRcptDetailBoardShowWipInbOrdSummary: ['docId'],
  gdsRcptDetailBoardShowWipInbOrdSummaryLoading: ['boolean'],
  gdsRcptDetailBoardShowWipInbOrdSummarySuccess: ['document'],

  gdsRcptDetailBoardSetCompleteGdsRcptDialogVisible: ['boolean'],
  gdsRcptDetailBoardShowCompleteGdsRcptSummary: ['docId'],
  gdsRcptDetailBoardShowCompleteGdsRcptSummaryLoading: ['boolean'],
  gdsRcptDetailBoardShowCompleteGdsRcptSummarySuccess: ['document']
});

export const GdsRcptDetailBoardTypes = Types;
export default Creators;
