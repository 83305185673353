import React from 'react';
import { connect } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import treeChanges from 'tree-changes';
import * as Yup from 'yup';
import { SaveOutlined, UndoOutlined, DownloadOutlined } from '@ant-design/icons';
import { Card, Row, Col, Button, Progress, Typography } from 'antd';
import { FormikInput, FormikText, FormikButton } from '../../Components/Formik';

import ProdOrdFServ01Actions from '../../Stores/ProdOrdFServ01/Actions';

const { Text } = Typography;

class ProdOrdFServ01Form extends React.PureComponent {
  componentDidMount() {
    const { curDivisionId, showSyncSetting } = this.props;

    if (curDivisionId > 0) {
      showSyncSetting();
    }
  }

  componentDidUpdate(prevProps) {
    const { curDivisionId, importIsLoading, showSyncSetting, showBatchJobStatus } = this.props;
    const { changed } = treeChanges(prevProps, this.props);

    if (changed('curDivisionId')) {
      if (curDivisionId > 0) {
        showSyncSetting();
      }
    }

    if (importIsLoading) {
      setTimeout(() => {
        showBatchJobStatus();
      }, 10000);
    }
  }

  componentWillUnmount() {}

  render() {
    const {
      intl,
      batchJobStatus,
      fetchIsLoading,
      syncSetting,
      updateSyncSetting,
      fServ01Import
    } = this.props;

    return (
      <Card>
        <Formik
          enableReinitialize
          initialValues={syncSetting}
          onSubmit={(values, formikBag) => {
            if (values.submit_action === 'update') {
              updateSyncSetting(formikBag, values);
            } else if (values.submit_action === 'import') {
              fServ01Import(formikBag);
            }
          }}
          validationSchema={Yup.object().shape({
            url: Yup.string().required(intl.formatMessage({ id: 'url_is_required' }))
          })}
        >
          {({
            // values,
            // handleChange,
            // errors,
            // setFieldTouched,
            dirty,
            // touched,
            isSubmitting,
            // isValid,
            handleSubmit,
            handleReset
          }) => (
            <Form>
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'url' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field name="url" component={FormikInput} loading={fetchIsLoading} />
                </Col>
              </Row>
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'last_synced_at' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field name="last_synced_at" component={FormikText} loading={fetchIsLoading} />
                </Col>
              </Row>

              <Row type="flex" justify="start" gutter={[0, 16]}>
                <Col span={3}>
                  <Button
                    type="primary"
                    disabled={!dirty}
                    loading={isSubmitting}
                    onClick={handleReset}
                    icon={<UndoOutlined />}
                  >
                    {intl.formatMessage({ id: 'reset' })}
                  </Button>
                </Col>
                <Col span={3}>
                  <Field
                    type="primary"
                    name="submit_action"
                    value="update"
                    component={FormikButton}
                    // disabled={values.doc_status >= 50}
                    loading={isSubmitting}
                    onClick={handleSubmit}
                    label={intl.formatMessage({ id: 'update' })}
                    icon={<SaveOutlined />}
                  />
                </Col>
                <Col span={3}>
                  <Field
                    type="primary"
                    name="submit_action"
                    value="import"
                    component={FormikButton}
                    loading={isSubmitting}
                    onClick={handleSubmit}
                    label={intl.formatMessage({
                      id: 'import'
                    })}
                    icon={<DownloadOutlined />}
                  />
                </Col>
              </Row>
              <Row type="flex" justify="center" gutter={[0, 8]}>
                <Col span={24}>
                  <Progress percent={parseInt(batchJobStatus.status_number, 10)} />
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </Card>
    );
  }
}

ProdOrdFServ01Form.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,

  syncSetting: PropTypes.shape({}),
  batchJobStatus: PropTypes.shape({
    status_number: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }),
  fetchIsLoading: PropTypes.bool,
  importIsLoading: PropTypes.bool,

  showSyncSetting: PropTypes.func,
  updateSyncSetting: PropTypes.func,
  showBatchJobStatus: PropTypes.func,
  fServ01Import: PropTypes.func,
  curDivisionId: PropTypes.number
};

ProdOrdFServ01Form.defaultProps = {
  intl: {},

  syncSetting: {},
  batchJobStatus: {
    status_number: '0'
  },
  fetchIsLoading: false,
  importIsLoading: false,

  showSyncSetting() {},
  updateSyncSetting() {},
  showBatchJobStatus() {},
  fServ01Import() {},
  curDivisionId: 0
};

const mapStateToProps = state => ({
  syncSetting: state.prodOrdFServ01.syncSetting,
  batchJobStatus: state.prodOrdFServ01.batchJobStatus,
  fetchIsLoading: state.prodOrdFServ01.fetchIsLoading,
  importIsLoading: state.prodOrdFServ01.importIsLoading,
  curDivisionId: state.app.curDivisionId
});

const mapDispatchToProps = dispatch => ({
  showSyncSetting: () => dispatch(ProdOrdFServ01Actions.prodOrdFServ01ShowSyncSetting()),
  updateSyncSetting: (formikBag, syncSetting) =>
    dispatch(ProdOrdFServ01Actions.prodOrdFServ01UpdateSyncSetting(formikBag, syncSetting)),
  showBatchJobStatus: () => dispatch(ProdOrdFServ01Actions.prodOrdFServ01ShowBatchJobStatus()),
  fServ01Import: formikBag => dispatch(ProdOrdFServ01Actions.prodOrdFServ01Import(formikBag))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ProdOrdFServ01Form));
