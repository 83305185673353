import { put, call, select, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import { push } from 'connected-react-router';
import FileSaver from 'file-saver';
import ApiService from '../Services/ApiService';
import AppActions from '../Stores/App/Actions';
import WhseJob140201Actions, { WhseJob140201Types } from '../Stores/WhseJob140201/Actions';

const getAppStore = state => state.app;

export function* whseJob140201FetchWhseJob140201({ currentPage, sorts, filters, pageSize }) {
  try {
    yield put(WhseJob140201Actions.whseJob140201FetchWhseJob140201Loading(true));

    const processedSorts = [];
    Object.entries(sorts).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      processedSorts.push(`${key}:${value === 'ascend' ? 'ASC' : 'DESC'}`);
    });

    const processedFilters = [];
    Object.entries(filters).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      if (value) {
        processedFilters.push(`${key}:${value}`);
      }
    });

    const app = yield select(getAppStore);
    const getData = {
      page: currentPage,
      sorts: processedSorts,
      filters: processedFilters,
      pageSize
    };

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `whseJob/indexProcess/WHSE_JOB_14_02_01/${app.curSiteFlowId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      // if nowCurrentPage is more than lastPage, then nowCurrentPage = lastPage
      let nowCurrentPage = result.data.current_page;
      if (nowCurrentPage > result.data.last_page) {
        nowCurrentPage = result.data.last_page;
      }

      yield put(
        WhseJob140201Actions.whseJob140201FetchWhseJob140201Success(
          result.data.data,
          nowCurrentPage,
          result.data.last_page,
          result.data.total,
          result.data.per_page
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(WhseJob140201Actions.whseJob140201FetchWhseJob140201Loading(false));
  }
}

export function* whseJob140201PrintWhseJob140201({ hdrIds }) {
  try {
    yield put(WhseJob140201Actions.whseJob140201PrintWhseJob140201Loading(true));

    const app = yield select(getAppStore);

    const getData = {
      hdrIds
    };

    const result = yield call(
      ApiService.downloadGetApi, // function
      app.apiUrl,
      `whseJob/printProcess/WHSE_JOB_14_02/${app.curSiteFlowId}`,
      app.token,
      getData,
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    );

    if (result.isSuccess === true) {
      const selectedDocuments = hdrIds.map(d => ({ id: d }));

      FileSaver.saveAs(result.data, 'WhseJob.pdf');
      yield put(WhseJob140201Actions.whseJob140201RemoveSelectedDocuments(selectedDocuments));
      yield put(WhseJob140201Actions.whseJob140201ResetTimestamp());
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(WhseJob140201Actions.whseJob140201PrintWhseJob140201Loading(false));
  }
}

export function* whseJob140201PrintHandlingUnit({ hdrIds }) {
  try {
    yield put(WhseJob140201Actions.whseJob140201PrintWhseJob140201Loading(true));

    const app = yield select(getAppStore);

    const getData = {
      ids: hdrIds
    };

    const result = yield call(
      ApiService.downloadGetApi, // function
      app.apiUrl,
      `handlingUnit/printProcess/HANDLING_UNIT_LIST_02_02/${app.curSiteFlowId}`,
      app.token,
      getData,
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    );

    if (result.isSuccess === true) {
      const selectedDocuments = hdrIds.map(d => ({ id: d }));

      FileSaver.saveAs(result.data, 'PalletIDs.pdf');
      yield put(WhseJob140201Actions.whseJob140201RemoveSelectedDocuments(selectedDocuments));
      yield put(WhseJob140201Actions.whseJob140201ResetTimestamp());
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(WhseJob140201Actions.whseJob140201PrintWhseJob140201Loading(false));
  }
}

export function* whseJob140201GoToDocument({ hdrId }) {
  const app = yield select(getAppStore);
  yield put(push(`${app.appPath}/whseJob14Detail/update/${hdrId}`));
}

export const saga = [
  takeLatest(
    WhseJob140201Types.WHSE_JOB140201_FETCH_WHSE_JOB140201,
    whseJob140201FetchWhseJob140201
  ),
  takeLatest(
    WhseJob140201Types.WHSE_JOB140201_PRINT_WHSE_JOB140201,
    whseJob140201PrintWhseJob140201
  ),
  takeLatest(WhseJob140201Types.WHSE_JOB140201_PRINT_HANDLING_UNIT, whseJob140201PrintHandlingUnit),
  takeLatest(WhseJob140201Types.WHSE_JOB140201_GO_TO_DOCUMENT, whseJob140201GoToDocument)
];
