/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { GdsRcptProcessTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const gdsRcptProcessFetchGdsRcptProcessLoading = (state, { boolean }) => ({
  ...state,
  processIsLoading: boolean
});

export const gdsRcptProcessFetchGdsRcptProcessSuccess = (state, { process }) => ({
  ...state,
  process
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [GdsRcptProcessTypes.GDS_RCPT_PROCESS_FETCH_GDS_RCPT_PROCESS_LOADING]: gdsRcptProcessFetchGdsRcptProcessLoading,
  [GdsRcptProcessTypes.GDS_RCPT_PROCESS_FETCH_GDS_RCPT_PROCESS_SUCCESS]: gdsRcptProcessFetchGdsRcptProcessSuccess
});
