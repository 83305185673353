import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  auditRes01ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  auditRes01SetResType: ['resType', 'resId', 'auditType'],
  auditRes01FetchAuditTypes: null,
  auditRes01FetchAuditTypeLoading: ['boolean'],
  auditRes01FetchAuditTypeSuccess: ['types'],
  auditRes01FetchAuditRes01: ['currentPage', 'sorts', 'filters', 'pageSize'],
  auditRes01FetchAuditRes01Loading: ['boolean'],
  auditRes01FetchAuditRes01Success: ['documents', 'currentPage', 'lastPage', 'total', 'pageSize'],

  auditRes01SetExpandedRows: ['expandedRows']
});

export const AuditRes01Types = Types;
export default Creators;
