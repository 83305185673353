/**
 * The initial values for the redux state.
 */
export default {
  resId: 0,

  modelTimestamp: 0,
  model: {
    code: '',
    desc_01: '',
    desc_02: '',
    ref_code_01: ''
  },

  modelIsLoading: false
};
