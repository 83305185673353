/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { WhseJob1702Types } from './Actions';
import INITIAL_STATE from './InitialState';

export const whseJob1702ResetTimestamp = (state, { currentPage, sorts, filters, pageSize }) => ({
  ...state,
  currentPage: currentPage || state.currentPage,
  sorts: sorts || state.sorts,
  filters: filters || state.filters,
  pageSize: pageSize || state.pageSize,
  timestamp: Date.now()
  // selectedDocuments: INITIAL_STATE.selectedDocuments
});

export const whseJob1702NewDocument = state => ({
  ...state,
  timestamp: Date.now()
});

export const whseJob1702FetchWhseJob1702Loading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const whseJob1702FetchWhseJob1702Success = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  documents,
  currentPage,
  lastPage,
  total,
  pageSize
});

export const whseJob1702AddSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = state.selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: [
      ...state.selectedDocuments,
      ...selectedDocuments.filter(data => {
        return !selectIds.includes(data.id);
      })
    ]
  };
};

export const whseJob1702RemoveSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: state.selectedDocuments.filter(data => {
      return !selectIds.includes(data.id);
    })
  };
};

export const whseJob1702SetWorkspaceVisible = (state, { boolean }) => ({
  ...state,
  workspaceIsVisible: boolean
});

export const whseJob1702CreateWhseJob1702Loading = (state, { boolean }) => ({
  ...state,
  createIsLoading: boolean
});

export const whseJob1702CreateWhseJob1702Success = (state, { newDocuments }) => ({
  ...state,
  timestamp: Date.now(),
  selectedDocuments: [],
  newDocuments
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [WhseJob1702Types.WHSE_JOB1702_RESET_TIMESTAMP]: whseJob1702ResetTimestamp,
  [WhseJob1702Types.WHSE_JOB1702_FETCH_WHSE_JOB1702_LOADING]: whseJob1702FetchWhseJob1702Loading,
  [WhseJob1702Types.WHSE_JOB1702_FETCH_WHSE_JOB1702_SUCCESS]: whseJob1702FetchWhseJob1702Success,
  [WhseJob1702Types.WHSE_JOB1702_ADD_SELECTED_DOCUMENTS]: whseJob1702AddSelectedDocuments,
  [WhseJob1702Types.WHSE_JOB1702_REMOVE_SELECTED_DOCUMENTS]: whseJob1702RemoveSelectedDocuments,
  [WhseJob1702Types.WHSE_JOB1702_SET_WORKSPACE_VISIBLE]: whseJob1702SetWorkspaceVisible,
  [WhseJob1702Types.WHSE_JOB1702_CREATE_WHSE_JOB1702_LOADING]: whseJob1702CreateWhseJob1702Loading,
  [WhseJob1702Types.WHSE_JOB1702_CREATE_WHSE_JOB1702_SUCCESS]: whseJob1702CreateWhseJob1702Success
});
