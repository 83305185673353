import { put, call, select, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import ApiService from '../Services/ApiService';
import AppActions from '../Stores/App/Actions';
import SummaryBoardActions, { SummaryBoardTypes } from '../Stores/SummaryBoard/Actions';

const getAppStore = state => state.app;

export function* slsOrdSummaryBoardFetchSlsOrds() {
  try {
    yield put(SummaryBoardActions.slsOrdSummaryBoardFetchSlsOrdsLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `outbOrd/indexSlsOrdToDoOutbOrd/${app.curSiteFlowId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(
        SummaryBoardActions.slsOrdSummaryBoardFetchSlsOrdsSuccess(
          result.data.total,
          result.data.data[0] ? result.data.data[0].doc_date_aging : 0
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(SummaryBoardActions.slsOrdSummaryBoardFetchSlsOrdsLoading(false));
  }
}

export function* slsOrdSummaryBoardFetchOutbOrds() {
  try {
    yield put(SummaryBoardActions.slsOrdSummaryBoardFetchOutbOrdsLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `outbOrd/indexSlsOrdToDoInvDoc/${app.curSiteFlowId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(
        SummaryBoardActions.slsOrdSummaryBoardFetchOutbOrdsSuccess(
          result.data.total,
          result.data.data[0] ? result.data.data[0].doc_date_aging : 0
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(SummaryBoardActions.slsOrdSummaryBoardFetchOutbOrdsLoading(false));
  }
}

export function* slsOrdSummaryBoardFetchInvDocs() {
  try {
    yield put(SummaryBoardActions.slsOrdSummaryBoardFetchInvDocsLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `outbOrd/indexSlsOrdInvDocToDoLoadList/${app.curSiteFlowId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(
        SummaryBoardActions.slsOrdSummaryBoardFetchInvDocsSuccess(
          result.data.total,
          result.data.data[0] ? result.data.data[0].doc_date_aging : 0
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(SummaryBoardActions.slsOrdSummaryBoardFetchInvDocsLoading(false));
  }
}

export function* slsOrdSummaryBoardFetchNoPrfDels() {
  try {
    yield put(SummaryBoardActions.slsOrdSummaryBoardFetchNoPrfDelsLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `outbOrd/indexSlsOrdInvDocToDoPrfDel/${app.curSiteFlowId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(
        SummaryBoardActions.slsOrdSummaryBoardFetchNoPrfDelsSuccess(
          result.data.total,
          result.data.data[0] ? result.data.data[0].doc_date_aging : 0
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(SummaryBoardActions.slsOrdSummaryBoardFetchNoPrfDelsLoading(false));
  }
}

export function* lspSlsOrdSummaryBoardFetchSlsOrds() {
  try {
    yield put(SummaryBoardActions.lspSlsOrdSummaryBoardFetchSlsOrdsLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `outbOrd/indexLspSlsOrdToDoOutbOrd/${app.curSiteFlowId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(
        SummaryBoardActions.lspSlsOrdSummaryBoardFetchSlsOrdsSuccess(
          result.data.total,
          result.data.data[0] ? result.data.data[0].doc_date_aging : 0
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(SummaryBoardActions.lspSlsOrdSummaryBoardFetchSlsOrdsLoading(false));
  }
}

export function* lspSlsOrdSummaryBoardFetchOutbOrds() {
  try {
    yield put(SummaryBoardActions.lspSlsOrdSummaryBoardFetchOutbOrdsLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `outbOrd/indexLspSlsOrdToDoInvDoc/${app.curSiteFlowId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(
        SummaryBoardActions.lspSlsOrdSummaryBoardFetchOutbOrdsSuccess(
          result.data.total,
          result.data.data[0] ? result.data.data[0].doc_date_aging : 0
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(SummaryBoardActions.lspSlsOrdSummaryBoardFetchOutbOrdsLoading(false));
  }
}

export function* lspSlsOrdSummaryBoardFetchInvDocs() {
  try {
    yield put(SummaryBoardActions.lspSlsOrdSummaryBoardFetchInvDocsLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `outbOrd/indexLspSlsOrdInvDocToDoLoadList/${app.curSiteFlowId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(
        SummaryBoardActions.lspSlsOrdSummaryBoardFetchInvDocsSuccess(
          result.data.total,
          result.data.data[0] ? result.data.data[0].doc_date_aging : 0
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(SummaryBoardActions.lspSlsOrdSummaryBoardFetchInvDocsLoading(false));
  }
}

export function* lspSlsOrdSummaryBoardFetchNoPrfDels() {
  try {
    yield put(SummaryBoardActions.lspSlsOrdSummaryBoardFetchNoPrfDelsLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `outbOrd/indexLspSlsOrdInvDocToDoPrfDel/${app.curSiteFlowId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(
        SummaryBoardActions.lspSlsOrdSummaryBoardFetchNoPrfDelsSuccess(
          result.data.total,
          result.data.data[0] ? result.data.data[0].doc_date_aging : 0
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(SummaryBoardActions.lspSlsOrdSummaryBoardFetchNoPrfDelsLoading(false));
  }
}

export function* pickListSummaryBoardFetchOutbOrds() {
  try {
    yield put(SummaryBoardActions.pickListSummaryBoardFetchOutbOrdsLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `outbOrd/indexToDoPickList/${app.curSiteFlowId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(
        SummaryBoardActions.pickListSummaryBoardFetchOutbOrdsSuccess(
          result.data.total,
          result.data.data[0] ? result.data.data[0].doc_date_aging : 0
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(SummaryBoardActions.pickListSummaryBoardFetchOutbOrdsLoading(false));
  }
}

export function* pickListSummaryBoardFetchWip() {
  try {
    yield put(SummaryBoardActions.pickListSummaryBoardFetchWipLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `pickList/indexToDoWhseJob/${app.curSiteFlowId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(
        SummaryBoardActions.pickListSummaryBoardFetchWipSuccess(
          result.data.total,
          result.data.data[0] ? result.data.data[0].doc_date_aging : 0
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(SummaryBoardActions.pickListSummaryBoardFetchWipLoading(false));
  }
}

export function* pickListSummaryBoardFetchWhseJobs() {
  try {
    yield put(SummaryBoardActions.pickListSummaryBoardFetchWhseJobsLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `whseJob/indexToDoPickList/${app.curSiteFlowId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(
        SummaryBoardActions.pickListSummaryBoardFetchWhseJobsSuccess(
          result.data.total,
          result.data.data[0] ? result.data.data[0].doc_date_aging : 0
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(SummaryBoardActions.pickListSummaryBoardFetchWhseJobsLoading(false));
  }
}

export function* pickListSummaryBoardFetchCompleted() {
  try {
    yield put(SummaryBoardActions.pickListSummaryBoardFetchCompletedLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `pickList/indexToDoLoadList/${app.curSiteFlowId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(
        SummaryBoardActions.pickListSummaryBoardFetchCompletedSuccess(
          result.data.total,
          result.data.data[0] ? result.data.data[0].doc_date_aging : 0
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(SummaryBoardActions.pickListSummaryBoardFetchCompletedLoading(false));
  }
}

export function* loadListSummaryBoardFetchPending() {
  try {
    yield put(SummaryBoardActions.loadListSummaryBoardFetchPendingLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `pickList/indexToDoLoadList/${app.curSiteFlowId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(
        SummaryBoardActions.loadListSummaryBoardFetchPendingSuccess(
          result.data.total,
          result.data.data[0] ? result.data.data[0].doc_date_aging : 0
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(SummaryBoardActions.loadListSummaryBoardFetchPendingLoading(false));
  }
}

export function* loadListSummaryBoardFetchWip() {
  try {
    yield put(SummaryBoardActions.loadListSummaryBoardFetchWipLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `loadList/indexToDoWhseJob/${app.curSiteFlowId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(
        SummaryBoardActions.loadListSummaryBoardFetchWipSuccess(
          result.data.total,
          result.data.data[0] ? result.data.data[0].doc_date_aging : 0
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(SummaryBoardActions.loadListSummaryBoardFetchWipLoading(false));
  }
}

export function* loadListSummaryBoardFetchWhseJobs() {
  try {
    yield put(SummaryBoardActions.loadListSummaryBoardFetchWhseJobsLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `whseJob/indexToDoLoadList/${app.curSiteFlowId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(
        SummaryBoardActions.loadListSummaryBoardFetchWhseJobsSuccess(
          result.data.total,
          result.data.data[0] ? result.data.data[0].doc_date_aging : 0
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(SummaryBoardActions.loadListSummaryBoardFetchWhseJobsLoading(false));
  }
}

export function* loadListSummaryBoardFetchCompleted() {
  try {
    yield put(SummaryBoardActions.loadListSummaryBoardFetchCompletedLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `loadList/indexToDoPrfDel/${app.curSiteFlowId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(
        SummaryBoardActions.loadListSummaryBoardFetchCompletedSuccess(
          result.data.total,
          result.data.data[0] ? result.data.data[0].doc_date_aging : 0
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(SummaryBoardActions.loadListSummaryBoardFetchCompletedLoading(false));
  }
}

export const saga = [
  takeLatest(
    SummaryBoardTypes.SLS_ORD_SUMMARY_BOARD_FETCH_SLS_ORDS,
    slsOrdSummaryBoardFetchSlsOrds
  ),

  takeLatest(
    SummaryBoardTypes.SLS_ORD_SUMMARY_BOARD_FETCH_OUTB_ORDS,
    slsOrdSummaryBoardFetchOutbOrds
  ),

  takeLatest(
    SummaryBoardTypes.SLS_ORD_SUMMARY_BOARD_FETCH_INV_DOCS,
    slsOrdSummaryBoardFetchInvDocs
  ),

  takeLatest(
    SummaryBoardTypes.SLS_ORD_SUMMARY_BOARD_FETCH_NO_PRF_DELS,
    slsOrdSummaryBoardFetchNoPrfDels
  ),

  takeLatest(
    SummaryBoardTypes.LSP_SLS_ORD_SUMMARY_BOARD_FETCH_SLS_ORDS,
    lspSlsOrdSummaryBoardFetchSlsOrds
  ),

  takeLatest(
    SummaryBoardTypes.LSP_SLS_ORD_SUMMARY_BOARD_FETCH_OUTB_ORDS,
    lspSlsOrdSummaryBoardFetchOutbOrds
  ),

  takeLatest(
    SummaryBoardTypes.LSP_SLS_ORD_SUMMARY_BOARD_FETCH_INV_DOCS,
    lspSlsOrdSummaryBoardFetchInvDocs
  ),

  takeLatest(
    SummaryBoardTypes.LSP_SLS_ORD_SUMMARY_BOARD_FETCH_NO_PRF_DELS,
    lspSlsOrdSummaryBoardFetchNoPrfDels
  ),

  takeLatest(
    SummaryBoardTypes.PICK_LIST_SUMMARY_BOARD_FETCH_OUTB_ORDS,
    pickListSummaryBoardFetchOutbOrds
  ),

  takeLatest(SummaryBoardTypes.PICK_LIST_SUMMARY_BOARD_FETCH_WIP, pickListSummaryBoardFetchWip),

  takeLatest(
    SummaryBoardTypes.PICK_LIST_SUMMARY_BOARD_FETCH_WHSE_JOBS,
    pickListSummaryBoardFetchWhseJobs
  ),

  takeLatest(
    SummaryBoardTypes.PICK_LIST_SUMMARY_BOARD_FETCH_COMPLETED,
    pickListSummaryBoardFetchCompleted
  ),

  takeLatest(
    SummaryBoardTypes.LOAD_LIST_SUMMARY_BOARD_FETCH_PENDING,
    loadListSummaryBoardFetchPending
  ),

  takeLatest(SummaryBoardTypes.LOAD_LIST_SUMMARY_BOARD_FETCH_WIP, loadListSummaryBoardFetchWip),

  takeLatest(
    SummaryBoardTypes.LOAD_LIST_SUMMARY_BOARD_FETCH_WHSE_JOBS,
    loadListSummaryBoardFetchWhseJobs
  ),

  takeLatest(
    SummaryBoardTypes.LOAD_LIST_SUMMARY_BOARD_FETCH_COMPLETED,
    loadListSummaryBoardFetchCompleted
  )
];
