import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  roleList01ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  roleList01GoToDocument: ['hdrId'],
  roleList01FetchRoleList01: ['currentPage', 'sorts', 'filters', 'pageSize'],
  roleList01FetchRoleList01Loading: ['boolean'],
  roleList01FetchRoleList01Success: ['documents', 'currentPage', 'lastPage', 'total', 'pageSize'],
  roleList01AddSelectedDocuments: ['selectedDocuments'],
  roleList01RemoveSelectedDocuments: ['selectedDocuments'],
  roleList01SetWorkspaceVisible: ['boolean']
});

export const RoleList01Types = Types;
export default Creators;
