import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  locationList01ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  locationList01GoToResource: ['hdrId'],
  locationList01NewResource: null,
  locationList01GoToAudit: ['hdrId'],
  locationList01FetchLocationList01: ['currentPage', 'sorts', 'filters', 'pageSize'],
  locationList01FetchLocationList01Loading: ['boolean'],
  locationList01FetchLocationList01Success: [
    'resources',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],
  locationList01AddSelectedResources: ['selectedResources'],
  locationList01RemoveSelectedResources: ['selectedResources'],
  locationList01SetWorkspaceVisible: ['boolean']
});

export const LocationList01Types = Types;
export default Creators;
