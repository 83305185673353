/**
 * The initial values for the redux state.
 */
export default {
  resId: 0,

  modelTimestamp: 0,
  model: {
    status: 2,
    str_status: 'INACTIVE',
    code: '',
    ref_code_01: '',
    ref_code_02: '',
    company_name_01: '',
    company_name_02: ''
  },

  modelIsLoading: false,

  storageRowOptions: [],
  storageRowIsLoading: false
};
