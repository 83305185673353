/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { StkTrfSync01Types } from './Actions';
import INITIAL_STATE from './InitialState';

export const stkTrfSync01ResetTimestamp = (state, { currentPage, sorts, filters, pageSize }) => ({
  ...state,
  currentPage: currentPage || state.currentPage,
  sorts: sorts || state.sorts,
  filters: filters || state.filters,
  pageSize: pageSize || state.pageSize,
  timestamp: Date.now()
  // selectedDocuments: INITIAL_STATE.selectedDocuments
});

export const stkTrfSync01FetchStkTrfSync01Loading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const stkTrfSync01FetchStkTrfSync01Success = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  documents,
  currentPage,
  lastPage,
  total,
  pageSize
});

export const stkTrfSync01AddSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = state.selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: [
      ...state.selectedDocuments,
      ...selectedDocuments.filter(data => {
        return !selectIds.includes(data.id);
      })
    ]
  };
};

export const stkTrfSync01RemoveSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: state.selectedDocuments.filter(data => {
      return !selectIds.includes(data.id);
    })
  };
};

export const stkTrfSync01SetWorkspaceVisible = (state, { boolean }) => ({
  ...state,
  workspaceIsVisible: boolean
});

export const stkTrfSync01SyncLoading = (state, { boolean }) => ({
  ...state,
  syncIsLoading: boolean
});

export const stkTrfSync01SyncNowSuccess = state => ({
  ...state,
  timestamp: Date.now(),
  selectedDocuments: []
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [StkTrfSync01Types.STK_TRF_SYNC01_RESET_TIMESTAMP]: stkTrfSync01ResetTimestamp,
  [StkTrfSync01Types.STK_TRF_SYNC01_FETCH_STK_TRF_SYNC01_LOADING]: stkTrfSync01FetchStkTrfSync01Loading,
  [StkTrfSync01Types.STK_TRF_SYNC01_FETCH_STK_TRF_SYNC01_SUCCESS]: stkTrfSync01FetchStkTrfSync01Success,
  [StkTrfSync01Types.STK_TRF_SYNC01_ADD_SELECTED_DOCUMENTS]: stkTrfSync01AddSelectedDocuments,
  [StkTrfSync01Types.STK_TRF_SYNC01_REMOVE_SELECTED_DOCUMENTS]: stkTrfSync01RemoveSelectedDocuments,
  [StkTrfSync01Types.STK_TRF_SYNC01_SET_WORKSPACE_VISIBLE]: stkTrfSync01SetWorkspaceVisible,
  [StkTrfSync01Types.STK_TRF_SYNC01_SYNC_LOADING]: stkTrfSync01SyncLoading,
  [StkTrfSync01Types.STK_TRF_SYNC01_SYNC_NOW_SUCCESS]: stkTrfSync01SyncNowSuccess
});
