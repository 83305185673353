/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { PutAwayDetailBoardTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const putAwayDetailBoardResetWipGdsRcptTimestamp = (
  state,
  { currentPage, sorts, filters, pageSize }
) => ({
  ...state,
  wipGdsRcptCurrentPage: currentPage || state.wipGdsRcptCurrentPage,
  wipGdsRcptSorts: sorts || state.wipGdsRcptSorts,
  wipGdsRcptFilters: filters || state.wipGdsRcptFilters,
  wipGdsRcptPageSize: pageSize || state.wipGdsRcptPageSize,
  wipGdsRcptTimestamp: Date.now()
});

export const putAwayDetailBoardFetchWipGdsRcptLoading = (state, { boolean }) => ({
  ...state,
  wipGdsRcptIsLoading: boolean
});

export const putAwayDetailBoardFetchWipGdsRcptSuccess = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  wipGdsRcpts: documents,
  wipGdsRcptCurrentPage: currentPage,
  wipGdsRcptLastPage: lastPage,
  wipGdsRcptTotal: total,
  wipGdsRcptPageSize: pageSize
});

export const putAwayDetailBoardResetWipPutAwayTimestamp = (
  state,
  { currentPage, sorts, filters, pageSize }
) => ({
  ...state,
  wipPutAwayCurrentPage: currentPage || state.wipPutAwayCurrentPage,
  wipPutAwaySorts: sorts || state.wipPutAwaySorts,
  wipPutAwayFilters: filters || state.wipPutAwayFilters,
  wipPutAwayPageSize: pageSize || state.wipPutAwayPageSize,
  wipPutAwayTimestamp: Date.now()
});

export const putAwayDetailBoardFetchWipPutAwayLoading = (state, { boolean }) => ({
  ...state,
  wipPutAwayIsLoading: boolean
});

export const putAwayDetailBoardFetchWipPutAwaySuccess = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  wipPutAways: documents,
  wipPutAwayCurrentPage: currentPage,
  wipPutAwayLastPage: lastPage,
  wipPutAwayTotal: total,
  wipPutAwayPageSize: pageSize
});

export const putAwayDetailBoardResetWipWhseJobTimestamp = (
  state,
  { currentPage, sorts, filters, pageSize }
) => ({
  ...state,
  wipWhseJobCurrentPage: currentPage || state.wipWhseJobCurrentPage,
  wipWhseJobSorts: sorts || state.wipWhseJobSorts,
  wipWhseJobFilters: filters || state.wipWhseJobFilters,
  wipWhseJobPageSize: pageSize || state.wipWhseJobPageSize,
  wipWhseJobTimestamp: Date.now()
});

export const putAwayDetailBoardFetchWipWhseJobLoading = (state, { boolean }) => ({
  ...state,
  wipWhseJobIsLoading: boolean
});

export const putAwayDetailBoardFetchWipWhseJobSuccess = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  wipWhseJobs: documents,
  wipWhseJobCurrentPage: currentPage,
  wipWhseJobLastPage: lastPage,
  wipWhseJobTotal: total,
  wipWhseJobPageSize: pageSize
});

export const putAwayDetailBoardResetCompletePutAwayTimestamp = (
  state,
  { currentPage, sorts, filters, pageSize }
) => ({
  ...state,
  completePutAwayCurrentPage: currentPage || state.completePutAwayCurrentPage,
  completePutAwaySorts: sorts || state.completePutAwaySorts,
  completePutAwayFilters: filters || state.completePutAwayFilters,
  completePutAwayPageSize: pageSize || state.completePutAwayPageSize,
  completePutAwayTimestamp: Date.now()
});

export const putAwayDetailBoardFetchCompletePutAwayLoading = (state, { boolean }) => ({
  ...state,
  completePutAwayIsLoading: boolean
});

export const putAwayDetailBoardFetchCompletePutAwaySuccess = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  completePutAways: documents,
  completePutAwayCurrentPage: currentPage,
  completePutAwayLastPage: lastPage,
  completePutAwayTotal: total,
  completePutAwayPageSize: pageSize
});

export const putAwayDetailBoardShowWipGdsRcptSummary = (state, { docId }) => ({
  ...state,
  wipGdsRcptDocId: docId,
  wipGdsRcptDialogIsVisible: true
});

export const putAwayDetailBoardSetWipGdsRcptDialogVisible = (state, { boolean }) => ({
  ...state,
  wipGdsRcptDialogIsVisible: boolean
});

export const putAwayDetailBoardShowWipGdsRcptSummaryLoading = (state, { boolean }) => ({
  ...state,
  wipGdsRcptDocumentIsLoading: boolean
});

export const putAwayDetailBoardShowWipGdsRcptSummarySuccess = (state, { document }) => ({
  ...state,
  wipGdsRcptDocument: document
});

export const putAwayDetailBoardShowWipPutAwaySummary = (state, { docId }) => ({
  ...state,
  wipPutAwayDocId: docId,
  wipPutAwayDialogIsVisible: true
});

export const putAwayDetailBoardSetWipPutAwayDialogVisible = (state, { boolean }) => ({
  ...state,
  wipPutAwayDialogIsVisible: boolean
});

export const putAwayDetailBoardShowWipPutAwaySummaryLoading = (state, { boolean }) => ({
  ...state,
  wipPutAwayDocumentIsLoading: boolean
});

export const putAwayDetailBoardShowWipPutAwaySummarySuccess = (state, { document }) => ({
  ...state,
  wipPutAwayDocument: document
});

export const putAwayDetailBoardShowWipWhseJobSummary = (state, { docId }) => ({
  ...state,
  wipWhseJobDocId: docId,
  wipWhseJobDialogIsVisible: true
});

export const putAwayDetailBoardSetWipWhseJobDialogVisible = (state, { boolean }) => ({
  ...state,
  wipWhseJobDialogIsVisible: boolean
});

export const putAwayDetailBoardShowWipWhseJobSummaryLoading = (state, { boolean }) => ({
  ...state,
  wipWhseJobDocumentIsLoading: boolean
});

export const putAwayDetailBoardShowWipWhseJobSummarySuccess = (state, { document }) => ({
  ...state,
  wipWhseJobDocument: document
});

export const putAwayDetailBoardShowCompletePutAwaySummary = (state, { docId }) => ({
  ...state,
  completePutAwayDocId: docId,
  completePutAwayDialogIsVisible: true
});

export const putAwayDetailBoardSetCompletePutAwayDialogVisible = (state, { boolean }) => ({
  ...state,
  completePutAwayDialogIsVisible: boolean
});

export const putAwayDetailBoardShowCompletePutAwaySummaryLoading = (state, { boolean }) => ({
  ...state,
  completePutAwayDocumentIsLoading: boolean
});

export const putAwayDetailBoardShowCompletePutAwaySummarySuccess = (state, { document }) => ({
  ...state,
  completePutAwayDocument: document
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [PutAwayDetailBoardTypes.PUT_AWAY_DETAIL_BOARD_RESET_WIP_GDS_RCPT_TIMESTAMP]: putAwayDetailBoardResetWipGdsRcptTimestamp,
  [PutAwayDetailBoardTypes.PUT_AWAY_DETAIL_BOARD_FETCH_WIP_GDS_RCPT_LOADING]: putAwayDetailBoardFetchWipGdsRcptLoading,
  [PutAwayDetailBoardTypes.PUT_AWAY_DETAIL_BOARD_FETCH_WIP_GDS_RCPT_SUCCESS]: putAwayDetailBoardFetchWipGdsRcptSuccess,

  [PutAwayDetailBoardTypes.PUT_AWAY_DETAIL_BOARD_RESET_WIP_PUT_AWAY_TIMESTAMP]: putAwayDetailBoardResetWipPutAwayTimestamp,
  [PutAwayDetailBoardTypes.PUT_AWAY_DETAIL_BOARD_FETCH_WIP_PUT_AWAY_LOADING]: putAwayDetailBoardFetchWipPutAwayLoading,
  [PutAwayDetailBoardTypes.PUT_AWAY_DETAIL_BOARD_FETCH_WIP_PUT_AWAY_SUCCESS]: putAwayDetailBoardFetchWipPutAwaySuccess,

  [PutAwayDetailBoardTypes.PUT_AWAY_DETAIL_BOARD_RESET_WIP_WHSE_JOB_TIMESTAMP]: putAwayDetailBoardResetWipWhseJobTimestamp,
  [PutAwayDetailBoardTypes.PUT_AWAY_DETAIL_BOARD_FETCH_WIP_WHSE_JOB_LOADING]: putAwayDetailBoardFetchWipWhseJobLoading,
  [PutAwayDetailBoardTypes.PUT_AWAY_DETAIL_BOARD_FETCH_WIP_WHSE_JOB_SUCCESS]: putAwayDetailBoardFetchWipWhseJobSuccess,

  [PutAwayDetailBoardTypes.PUT_AWAY_DETAIL_BOARD_RESET_COMPLETE_PUT_AWAY_TIMESTAMP]: putAwayDetailBoardResetCompletePutAwayTimestamp,
  [PutAwayDetailBoardTypes.PUT_AWAY_DETAIL_BOARD_FETCH_COMPLETE_PUT_AWAY_LOADING]: putAwayDetailBoardFetchCompletePutAwayLoading,
  [PutAwayDetailBoardTypes.PUT_AWAY_DETAIL_BOARD_FETCH_COMPLETE_PUT_AWAY_SUCCESS]: putAwayDetailBoardFetchCompletePutAwaySuccess,

  [PutAwayDetailBoardTypes.PUT_AWAY_DETAIL_BOARD_SHOW_WIP_GDS_RCPT_SUMMARY]: putAwayDetailBoardShowWipGdsRcptSummary,
  [PutAwayDetailBoardTypes.PUT_AWAY_DETAIL_BOARD_SET_WIP_GDS_RCPT_DIALOG_VISIBLE]: putAwayDetailBoardSetWipGdsRcptDialogVisible,
  [PutAwayDetailBoardTypes.PUT_AWAY_DETAIL_BOARD_SHOW_WIP_GDS_RCPT_SUMMARY_LOADING]: putAwayDetailBoardShowWipGdsRcptSummaryLoading,
  [PutAwayDetailBoardTypes.PUT_AWAY_DETAIL_BOARD_SHOW_WIP_GDS_RCPT_SUMMARY_SUCCESS]: putAwayDetailBoardShowWipGdsRcptSummarySuccess,

  [PutAwayDetailBoardTypes.PUT_AWAY_DETAIL_BOARD_SHOW_WIP_PUT_AWAY_SUMMARY]: putAwayDetailBoardShowWipPutAwaySummary,
  [PutAwayDetailBoardTypes.PUT_AWAY_DETAIL_BOARD_SET_WIP_PUT_AWAY_DIALOG_VISIBLE]: putAwayDetailBoardSetWipPutAwayDialogVisible,
  [PutAwayDetailBoardTypes.PUT_AWAY_DETAIL_BOARD_SHOW_WIP_PUT_AWAY_SUMMARY_LOADING]: putAwayDetailBoardShowWipPutAwaySummaryLoading,
  [PutAwayDetailBoardTypes.PUT_AWAY_DETAIL_BOARD_SHOW_WIP_PUT_AWAY_SUMMARY_SUCCESS]: putAwayDetailBoardShowWipPutAwaySummarySuccess,

  [PutAwayDetailBoardTypes.PUT_AWAY_DETAIL_BOARD_SHOW_WIP_WHSE_JOB_SUMMARY]: putAwayDetailBoardShowWipWhseJobSummary,
  [PutAwayDetailBoardTypes.PUT_AWAY_DETAIL_BOARD_SET_WIP_WHSE_JOB_DIALOG_VISIBLE]: putAwayDetailBoardSetWipWhseJobDialogVisible,
  [PutAwayDetailBoardTypes.PUT_AWAY_DETAIL_BOARD_SHOW_WIP_WHSE_JOB_SUMMARY_LOADING]: putAwayDetailBoardShowWipWhseJobSummaryLoading,
  [PutAwayDetailBoardTypes.PUT_AWAY_DETAIL_BOARD_SHOW_WIP_WHSE_JOB_SUMMARY_SUCCESS]: putAwayDetailBoardShowWipWhseJobSummarySuccess,

  [PutAwayDetailBoardTypes.PUT_AWAY_DETAIL_BOARD_SHOW_COMPLETE_PUT_AWAY_SUMMARY]: putAwayDetailBoardShowCompletePutAwaySummary,
  [PutAwayDetailBoardTypes.PUT_AWAY_DETAIL_BOARD_SET_COMPLETE_PUT_AWAY_DIALOG_VISIBLE]: putAwayDetailBoardSetCompletePutAwayDialogVisible,
  [PutAwayDetailBoardTypes.PUT_AWAY_DETAIL_BOARD_SHOW_COMPLETE_PUT_AWAY_SUMMARY_LOADING]: putAwayDetailBoardShowCompletePutAwaySummaryLoading,
  [PutAwayDetailBoardTypes.PUT_AWAY_DETAIL_BOARD_SHOW_COMPLETE_PUT_AWAY_SUMMARY_SUCCESS]: putAwayDetailBoardShowCompletePutAwaySummarySuccess
});
