import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import { SyncOutlined } from '@ant-design/icons';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { Card, Row, Col, Progress, Typography } from 'antd';
import { FormikDatePicker, FormikSelect, FormikButton } from '../../Components/Formik';

import CountAdjSync02Actions from '../../Stores/CountAdjSync02/Actions';

const { Text } = Typography;

class CountAdjSync02Form extends React.PureComponent {
  constructor() {
    super();

    this.handleCompanySearch = this.handleCompanySearch.bind(this);
    this.handleLocationSearch = this.handleLocationSearch.bind(this);
    this.handleSearchItemGroup01 = this.handleSearchItemGroup01.bind(this);
    this.handleItemSearch = this.handleItemSearch.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate() {
    const { exportIsLoading, showBatchJobStatus } = this.props;

    if (exportIsLoading) {
      setTimeout(() => {
        showBatchJobStatus();
      }, 10000);
    }
  }

  componentWillUnmount() {}

  handleCompanySearch(form, value) {
    const { fetchCompanyOptions } = this.props;

    fetchCompanyOptions(value);
  }

  handleLocationSearch(form, value) {
    const { fetchLocationOptions } = this.props;

    fetchLocationOptions(value);
  }

  handleSearchItemGroup01(form, value) {
    const { fetchItemGroup01Options } = this.props;

    fetchItemGroup01Options(value);
  }

  handleItemSearch(form, value) {
    const { fetchItemOptions } = this.props;

    fetchItemOptions(value);
  }

  render() {
    const {
      intl,
      fetchIsLoading,
      exportIsLoading,
      criteria,
      batchJobStatus,
      fServ02Export,
      companyIsLoading,
      companyOptions,
      locationIsLoading,
      locationOptions,
      itemGroup01Options,
      itemGroup01IsLoading,
      itemIsLoading,
      itemOptions
    } = this.props;

    return (
      <Formik
        enableReinitialize
        initialValues={criteria}
        onSubmit={(values, formikBag) => {
          fServ02Export(formikBag, values);
        }}
        validationSchema={Yup.object().shape({
          doc_date: Yup.date().min(
            new Date(1969, 12, 1),
            intl.formatMessage({ id: 'doc_date_is_required' })
          ),
          company_id_select2: Yup.object().shape({
            value: Yup.number().min(1, intl.formatMessage({ id: 'company_is_required' }))
          }),
          location_id_select2: Yup.object().shape({
            value: Yup.number().min(1, intl.formatMessage({ id: 'location_is_required' }))
          })
        })}
      >
        {({
          // values,
          // handleChange,
          // errors,
          // setFieldTouched,
          // dirty,
          // touched,
          isSubmitting,
          // isValid,
          handleSubmit
          // handleReset
        }) => (
          <Form>
            <Card>
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'stock_balance_date' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field name="doc_date" component={FormikDatePicker} />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'company' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="company_id_select2"
                    component={FormikSelect}
                    showArrow
                    filterOption={false}
                    onSearch={this.handleCompanySearch}
                    notFoundContent={null}
                    loading={companyIsLoading}
                    options={companyOptions}
                    disabled={isSubmitting}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'location' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="location_id_select2"
                    component={FormikSelect}
                    showArrow
                    filterOption={false}
                    onSearch={this.handleLocationSearch}
                    notFoundContent={null}
                    loading={locationIsLoading}
                    options={locationOptions}
                    disabled={isSubmitting}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'brand' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="item_group_01_ids_select2"
                    component={FormikSelect}
                    showArrow
                    filterOption={false}
                    onSearch={this.handleSearchItemGroup01}
                    notFoundContent={null}
                    loading={itemGroup01IsLoading}
                    options={itemGroup01Options}
                    disabled={isSubmitting || fetchIsLoading}
                    selectMode="multiple"
                  />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'item' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="item_ids_select2"
                    component={FormikSelect}
                    showArrow
                    filterOption={false}
                    onSearch={this.handleItemSearch}
                    notFoundContent={null}
                    loading={itemIsLoading}
                    options={itemOptions}
                    disabled={isSubmitting}
                    selectMode="multiple"
                  />
                </Col>
              </Row>

              <Row type="flex" justify="start" gutter={[0, 16]}>
                <Col span={3}>
                  <Field
                    type="primary"
                    name="submit_action"
                    value="export"
                    component={FormikButton}
                    // disabled={values.doc_status >= 50}
                    loading={isSubmitting || fetchIsLoading || exportIsLoading}
                    onClick={handleSubmit}
                    label={intl.formatMessage({ id: 'export' })}
                    icon={<SyncOutlined />}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[0, 8]}>
                <Col span={24}>
                  <Progress percent={parseInt(batchJobStatus.status_number, 10)} />
                </Col>
              </Row>
            </Card>
          </Form>
        )}
      </Formik>
    );
  }
}

CountAdjSync02Form.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,

  criteria: PropTypes.shape({}),
  batchJobStatus: PropTypes.shape({
    status_number: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }),
  fetchIsLoading: PropTypes.bool,
  exportIsLoading: PropTypes.bool,

  showBatchJobStatus: PropTypes.func,
  fServ02Export: PropTypes.func,

  fetchCompanyOptions: PropTypes.func,
  companyIsLoading: PropTypes.bool,
  companyOptions: PropTypes.arrayOf(PropTypes.object),

  fetchLocationOptions: PropTypes.func,
  locationIsLoading: PropTypes.bool,
  locationOptions: PropTypes.arrayOf(PropTypes.object),

  fetchItemGroup01Options: PropTypes.func,
  itemGroup01Options: PropTypes.arrayOf(PropTypes.object),
  itemGroup01IsLoading: PropTypes.bool,

  fetchItemOptions: PropTypes.func,
  itemIsLoading: PropTypes.bool,
  itemOptions: PropTypes.arrayOf(PropTypes.object)
};

CountAdjSync02Form.defaultProps = {
  intl: {},

  criteria: {
    company_id_select2: {
      value: 0,
      label: ''
    },
    location_id_select2: {
      value: 0,
      label: ''
    }
  },
  batchJobStatus: {
    status_number: '0'
  },
  fetchIsLoading: false,
  exportIsLoading: false,

  showBatchJobStatus() {},
  fServ02Export() {},

  fetchCompanyOptions() {},
  companyIsLoading: false,
  companyOptions: [],

  fetchLocationOptions() {},
  locationIsLoading: false,
  locationOptions: [],

  fetchItemGroup01Options() {},
  itemGroup01Options: [],
  itemGroup01IsLoading: false,

  fetchItemOptions() {},
  itemIsLoading: false,
  itemOptions: []
};

const mapStateToProps = state => ({
  criteria: state.countAdjSync02.criteria,
  batchJobStatus: state.countAdjSync02.batchJobStatus,
  fetchIsLoading: state.countAdjSync02.fetchIsLoading,
  exportIsLoading: state.countAdjSync02.exportIsLoading,

  companyIsLoading: state.countAdjSync02.companyIsLoading,
  companyOptions: state.countAdjSync02.companyOptions,

  locationIsLoading: state.countAdjSync02.locationIsLoading,
  locationOptions: state.countAdjSync02.locationOptions,

  itemGroup01Options: state.countAdjSync02.itemGroup01Options,
  itemGroup01IsLoading: state.countAdjSync02.itemGroup01IsLoading,

  itemIsLoading: state.countAdjSync02.itemIsLoading,
  itemOptions: state.countAdjSync02.itemOptions
});

const mapDispatchToProps = dispatch => ({
  showSyncSetting: () => dispatch(CountAdjSync02Actions.countAdjSync02ShowSyncSetting()),
  updateSyncSetting: (formikBag, syncSetting) =>
    dispatch(CountAdjSync02Actions.countAdjSync02UpdateSyncSetting(formikBag, syncSetting)),
  showBatchJobStatus: () => dispatch(CountAdjSync02Actions.countAdjSync02ShowBatchJobStatus()),
  fServ02Export: (formikBag, criteria) =>
    dispatch(CountAdjSync02Actions.countAdjSync02Export(formikBag, criteria)),

  fetchCompanyOptions: search =>
    dispatch(CountAdjSync02Actions.countAdjSync02FetchCompanyOptions(search)),
  fetchLocationOptions: search =>
    dispatch(CountAdjSync02Actions.countAdjSync02FetchLocationOptions(search)),
  fetchItemGroup01Options: search =>
    dispatch(CountAdjSync02Actions.countAdjSync02FetchItemGroup01Options(search)),
  fetchItemOptions: search => dispatch(CountAdjSync02Actions.countAdjSync02FetchItemOptions(search))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CountAdjSync02Form));
