import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  putAwaySync01ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  putAwaySync01GoToDocument: ['hdrId'],
  putAwaySync01FetchPutAwaySync01: ['currentPage', 'sorts', 'filters', 'pageSize'],
  putAwaySync01FetchPutAwaySync01Loading: ['boolean'],
  putAwaySync01FetchPutAwaySync01Success: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],
  putAwaySync01AddSelectedDocuments: ['selectedDocuments'],
  putAwaySync01RemoveSelectedDocuments: ['selectedDocuments'],
  putAwaySync01SetWorkspaceVisible: ['boolean'],

  putAwaySync01SyncNow: ['hdrIds'],
  putAwaySync01SyncNowSuccess: null,
  putAwaySync01SyncLoading: ['boolean']
});

export const PutAwaySync01Types = Types;
export default Creators;
