/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { SlsOrdDetailTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const slsOrdDetailResetTimestamp = state => ({
  ...state,
  timestamp: Date.now()
});

export const slsOrdDetailSetHdrId = (state, { hdrId, itemId }) => ({
  ...state,
  timestamp: Date.now(),
  documentIsLoading: false,
  hdrId,
  itemId,
  detailIsVisible: false,
  salesmanOptions: [],
  deliveryPointOptions: [],
  creditTermOptions: [],
  currencyOptions: []
});

export const slsOrdDetailShowDocumentLoading = (state, { boolean }) => ({
  ...state,
  documentIsLoading: boolean
});

export const slsOrdDetailShowHeaderSuccess = (state, { documentHeader }) => ({
  ...state,
  documentHeader
});

export const slsOrdDetailUpdateDocumentSuccess = (state, { documentHeader, documentDetails }) => ({
  ...state,
  documentHeader,
  documentDetails
});

export const slsOrdDetailShowDetailsSuccess = (state, { documentDetails }) => ({
  ...state,
  documentDetails
});

export const slsOrdDetailSetDetailVisible = (state, { boolean }) => ({
  ...state,
  detailIsVisible: boolean
});

export const slsOrdDetailSetDocumentDetail = (state, { documentDetail }) => ({
  ...state,
  documentDetail,
  itemOptions: [],
  itemBatchOptions: [],
  uomOptions: []
});

export const slsOrdDetailFetchSalesmanOptionLoading = (state, { boolean }) => ({
  ...state,
  salesmanIsLoading: boolean
});

export const slsOrdDetailFetchSalesmanOptionSuccess = (state, { options }) => ({
  ...state,
  salesmanOptions: options
});

export const slsOrdDetailFetchDeliveryPointOptionLoading = (state, { boolean }) => ({
  ...state,
  deliveryPointIsLoading: boolean
});

export const slsOrdDetailFetchDeliveryPointOptionSuccess = (state, { options }) => ({
  ...state,
  deliveryPointOptions: options
});

export const slsOrdDetailFetchCreditTermOptionLoading = (state, { boolean }) => ({
  ...state,
  creditTermIsLoading: boolean
});

export const slsOrdDetailFetchCreditTermOptionSuccess = (state, { options }) => ({
  ...state,
  creditTermOptions: options
});

export const slsOrdDetailFetchCurrencyOptionLoading = (state, { boolean }) => ({
  ...state,
  currencyIsLoading: boolean
});

export const slsOrdDetailFetchCurrencyOptionSuccess = (state, { options }) => ({
  ...state,
  currencyOptions: options
});

export const slsOrdDetailFetchItemOptionLoading = (state, { boolean }) => ({
  ...state,
  itemIsLoading: boolean
});

export const slsOrdDetailFetchItemOptionSuccess = (state, { options }) => ({
  ...state,
  itemOptions: options
});

export const slsOrdDetailFetchItemBatchOptionLoading = (state, { boolean }) => ({
  ...state,
  itemBatchIsLoading: boolean
});

export const slsOrdDetailFetchItemBatchOptionSuccess = (state, { options }) => ({
  ...state,
  itemBatchOptions: options
});

export const slsOrdDetailFetchUomOptionLoading = (state, { boolean }) => ({
  ...state,
  uomIsLoading: boolean
});

export const slsOrdDetailFetchUomOptionSuccess = (state, { options }) => ({
  ...state,
  uomOptions: options
});

// force refresh the formik form
export const slsOrdDetailUpdateDetails = (state, { documentDetails }) => ({
  ...state,
  documentDetail: documentDetails[0]
});

// force refresh the formik form
export const slsOrdDetailCreateDetail = (state, { documentDetail }) => ({
  ...state,
  documentDetail
});

export const slsOrdDetailFetchLocationOptionLoading = (state, { boolean }) => ({
  ...state,
  locationIsLoading: boolean
});

export const slsOrdDetailFetchLocationOptionSuccess = (state, { options }) => ({
  ...state,
  locationOptions: options
});

export const slsOrdDetailFetchProjectOptionLoading = (state, { boolean }) => ({
  ...state,
  projectIsLoading: boolean
});

export const slsOrdDetailFetchProjectOptionSuccess = (state, { options }) => ({
  ...state,
  projectOptions: options
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [SlsOrdDetailTypes.SLS_ORD_DETAIL_RESET_TIMESTAMP]: slsOrdDetailResetTimestamp,
  [SlsOrdDetailTypes.SLS_ORD_DETAIL_SET_HDR_ID]: slsOrdDetailSetHdrId,

  [SlsOrdDetailTypes.SLS_ORD_DETAIL_SHOW_HEADER_SUCCESS]: slsOrdDetailShowHeaderSuccess,

  [SlsOrdDetailTypes.SLS_ORD_DETAIL_UPDATE_DOCUMENT_SUCCESS]: slsOrdDetailUpdateDocumentSuccess,

  [SlsOrdDetailTypes.SLS_ORD_DETAIL_SHOW_DOCUMENT_LOADING]: slsOrdDetailShowDocumentLoading,

  [SlsOrdDetailTypes.SLS_ORD_DETAIL_SHOW_DETAILS_SUCCESS]: slsOrdDetailShowDetailsSuccess,

  [SlsOrdDetailTypes.SLS_ORD_DETAIL_SET_DETAIL_VISIBLE]: slsOrdDetailSetDetailVisible,
  [SlsOrdDetailTypes.SLS_ORD_DETAIL_SET_DOCUMENT_DETAIL]: slsOrdDetailSetDocumentDetail,

  [SlsOrdDetailTypes.SLS_ORD_DETAIL_UPDATE_DETAILS]: slsOrdDetailUpdateDetails,
  [SlsOrdDetailTypes.SLS_ORD_DETAIL_CREATE_DETAIL]: slsOrdDetailCreateDetail,

  [SlsOrdDetailTypes.SLS_ORD_DETAIL_FETCH_SALESMAN_OPTION_LOADING]: slsOrdDetailFetchSalesmanOptionLoading,
  [SlsOrdDetailTypes.SLS_ORD_DETAIL_FETCH_SALESMAN_OPTION_SUCCESS]: slsOrdDetailFetchSalesmanOptionSuccess,

  [SlsOrdDetailTypes.SLS_ORD_DETAIL_FETCH_DELIVERY_POINT_OPTION_LOADING]: slsOrdDetailFetchDeliveryPointOptionLoading,
  [SlsOrdDetailTypes.SLS_ORD_DETAIL_FETCH_DELIVERY_POINT_OPTION_SUCCESS]: slsOrdDetailFetchDeliveryPointOptionSuccess,

  [SlsOrdDetailTypes.SLS_ORD_DETAIL_FETCH_CREDIT_TERM_OPTION_LOADING]: slsOrdDetailFetchCreditTermOptionLoading,
  [SlsOrdDetailTypes.SLS_ORD_DETAIL_FETCH_CREDIT_TERM_OPTION_SUCCESS]: slsOrdDetailFetchCreditTermOptionSuccess,

  [SlsOrdDetailTypes.SLS_ORD_DETAIL_FETCH_CURRENCY_OPTION_LOADING]: slsOrdDetailFetchCurrencyOptionLoading,
  [SlsOrdDetailTypes.SLS_ORD_DETAIL_FETCH_CURRENCY_OPTION_SUCCESS]: slsOrdDetailFetchCurrencyOptionSuccess,

  [SlsOrdDetailTypes.SLS_ORD_DETAIL_FETCH_ITEM_OPTION_LOADING]: slsOrdDetailFetchItemOptionLoading,
  [SlsOrdDetailTypes.SLS_ORD_DETAIL_FETCH_ITEM_OPTION_SUCCESS]: slsOrdDetailFetchItemOptionSuccess,

  [SlsOrdDetailTypes.SLS_ORD_DETAIL_FETCH_ITEM_BATCH_OPTION_LOADING]: slsOrdDetailFetchItemBatchOptionLoading,
  [SlsOrdDetailTypes.SLS_ORD_DETAIL_FETCH_ITEM_BATCH_OPTION_SUCCESS]: slsOrdDetailFetchItemBatchOptionSuccess,

  [SlsOrdDetailTypes.SLS_ORD_DETAIL_FETCH_UOM_OPTION_LOADING]: slsOrdDetailFetchUomOptionLoading,
  [SlsOrdDetailTypes.SLS_ORD_DETAIL_FETCH_UOM_OPTION_SUCCESS]: slsOrdDetailFetchUomOptionSuccess,

  [SlsOrdDetailTypes.SLS_ORD_DETAIL_FETCH_LOCATION_OPTION_LOADING]: slsOrdDetailFetchLocationOptionLoading,
  [SlsOrdDetailTypes.SLS_ORD_DETAIL_FETCH_LOCATION_OPTION_SUCCESS]: slsOrdDetailFetchLocationOptionSuccess,

  [SlsOrdDetailTypes.SLS_ORD_DETAIL_FETCH_PROJECT_OPTION_LOADING]: slsOrdDetailFetchProjectOptionLoading,
  [SlsOrdDetailTypes.SLS_ORD_DETAIL_FETCH_PROJECT_OPTION_SUCCESS]: slsOrdDetailFetchProjectOptionSuccess
});
