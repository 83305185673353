/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { WhseJob140201Types } from './Actions';
import INITIAL_STATE from './InitialState';

export const whseJob140201ResetTimestamp = (state, { currentPage, sorts, filters, pageSize }) => ({
  ...state,
  currentPage: currentPage || state.currentPage,
  sorts: sorts || state.sorts,
  filters: filters || state.filters,
  pageSize: pageSize || state.pageSize,
  timestamp: Date.now(),
  // selectedDocuments: INITIAL_STATE.selectedDocuments,
  fetchIsLoading: false,
  printIsLoading: false
});

export const whseJob140201FetchWhseJob140201Loading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const whseJob140201FetchWhseJob140201Success = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  documents,
  currentPage,
  lastPage,
  total,
  pageSize
});

export const whseJob140201AddSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = state.selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: [
      ...state.selectedDocuments,
      ...selectedDocuments.filter(data => {
        return !selectIds.includes(data.id);
      })
    ]
  };
};

export const whseJob140201RemoveSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: state.selectedDocuments.filter(data => {
      return !selectIds.includes(data.id);
    })
  };
};

export const whseJob140201SetWorkspaceVisible = (state, { boolean }) => ({
  ...state,
  workspaceIsVisible: boolean
});

export const whseJob140201PrintWhseJob140201Loading = (state, { boolean }) => ({
  ...state,
  printIsLoading: boolean
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [WhseJob140201Types.WHSE_JOB140201_RESET_TIMESTAMP]: whseJob140201ResetTimestamp,
  [WhseJob140201Types.WHSE_JOB140201_FETCH_WHSE_JOB140201_LOADING]: whseJob140201FetchWhseJob140201Loading,
  [WhseJob140201Types.WHSE_JOB140201_FETCH_WHSE_JOB140201_SUCCESS]: whseJob140201FetchWhseJob140201Success,
  [WhseJob140201Types.WHSE_JOB140201_ADD_SELECTED_DOCUMENTS]: whseJob140201AddSelectedDocuments,
  [WhseJob140201Types.WHSE_JOB140201_REMOVE_SELECTED_DOCUMENTS]: whseJob140201RemoveSelectedDocuments,
  [WhseJob140201Types.WHSE_JOB140201_SET_WORKSPACE_VISIBLE]: whseJob140201SetWorkspaceVisible,
  [WhseJob140201Types.WHSE_JOB140201_PRINT_WHSE_JOB140201_LOADING]: whseJob140201PrintWhseJob140201Loading
});
