import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import treeChanges from 'tree-changes';
import moment from 'moment';

import { EditOutlined, LaptopOutlined, ReloadOutlined } from '@ant-design/icons';

import { Row, Col, Table, Button, Badge, Card } from 'antd';

import AntDesignTable from '../../Components/AntDesignTable';
import WhseJob0301Actions from '../../Stores/WhseJob0301/Actions';

import WhseJob0301Dialog from './WhseJob0301Dialog';

class WhseJob0301Table extends React.PureComponent {
  constructor() {
    super();

    this.useOnTableChange = this.useOnTableChange.bind(this);
    this.useShowWorkspace = this.useShowWorkspace.bind(this);

    this.handleSearch = this.handleSearch.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.getDocumentColumns = this.getDocumentColumns.bind(this);
  }

  componentDidMount() {
    const { curSiteFlowId, setWorkspaceVisible, resetTimestamp } = this.props;

    if (curSiteFlowId > 0) {
      setWorkspaceVisible(false);
      resetTimestamp();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      curSiteFlowId,
      resetTimestamp,
      fetchWhseJob0301,
      currentPage,
      sorts,
      filters,
      pageSize
    } = this.props;

    const { changed } = treeChanges(prevProps, this.props);

    if (changed('curSiteFlowId')) {
      resetTimestamp();
    }

    if (changed('timestamp')) {
      if (curSiteFlowId > 0) {
        fetchWhseJob0301(currentPage, sorts, filters, pageSize);
      }
    }
  }

  componentWillUnmount() {}

  handleSearch(selectedKeys, confirm) {
    // eslint-disable-next-line no-unused-vars
    const { intl } = this.props;

    confirm();
  }

  handleReset(clearFilters) {
    // eslint-disable-next-line no-unused-vars
    const { intl } = this.props;

    clearFilters();
  }

  getDocumentColumns() {
    const { intl, sorts, filters, goToDocument } = this.props;

    return [
      {
        width: 120,
        align: 'left',
        title: intl.formatMessage({ id: 'doc_code' }),
        // sort field
        dataIndex: 'doc_code',
        ...AntDesignTable.getColumnSortProps(sorts, 'doc_code'),
        // filter field
        key: 'doc_code',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'doc_code' }),
          'doc_code',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.doc_code}</>
      },
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'doc_date' }),
        // sort field
        dataIndex: 'doc_date',
        ...AntDesignTable.getColumnSortProps(sorts, 'doc_date'),
        // filter field
        key: 'doc_date',
        ...AntDesignTable.getColumnSearchDateProps(
          filters,
          intl.formatMessage({ id: 'doc_date' }),
          'doc_date',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{moment(record.doc_date).format('YYYY-MM-DD')}</>
      },
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'doc_status' }),
        // sort field
        dataIndex: 'doc_status',
        ...AntDesignTable.getColumnSortProps(sorts, 'doc_status'),
        // filter field
        ...AntDesignTable.getColumnSearchDocStatusProps(filters, 'doc_status'),
        render: (text, record) => <>{record.str_doc_status}</>
      },

      {
        width: 90,
        align: 'left',
        title: intl.formatMessage({ id: 'area' }),
        // sort field
        dataIndex: 'delivery_point_area_code',
        ...AntDesignTable.getColumnSortProps(sorts, 'delivery_point_area_code'),
        // filter field
        key: 'delivery_point_area',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'area' }),
          'delivery_point_area',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => (
          <>
            {record.areas.map(value => (
              <div key={`${record.id}:${value.code}`}>
                <b>{value.code}</b>
                <br />
                {value.desc_01}
              </div>
            ))}
          </>
        )
      },
      {
        width: 120,
        align: 'left',
        title: intl.formatMessage({ id: 'salesman' }),
        // sort field
        dataIndex: 'salesman_username',
        // filter field
        key: 'salesman',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'salesman' }),
          'salesman',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => (
          <>
            {record.salesmans.map(value => (
              <div key={`${record.id}:${value.username}`}>
                <b>{value.username}</b>
              </div>
            ))}
          </>
        )
      },
      {
        width: 150,
        align: 'left',
        title: intl.formatMessage({ id: 'delivery_point' }),
        // sort field
        dataIndex: 'delivery_point_code',
        ...AntDesignTable.getColumnSortProps(sorts, 'delivery_point_code'),
        // filter field
        key: 'delivery_point',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'delivery_point' }),
          'delivery_point',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => (
          <>
            {record.delivery_points.map(value => (
              <div key={`${record.id}:${value.code}`}>
                <b>{value.code}</b>
                <br />
                {value.company_name_01}
              </div>
            ))}
          </>
        )
      },
      {
        width: 150,
        // fixed: 'right',
        align: 'left',
        title: intl.formatMessage({ id: 'documents' }),
        // sort field
        dataIndex: 'outb_ord_hdr_code',
        ...AntDesignTable.getColumnSortProps(sorts, 'outb_ord_hdr_code'),
        // filter field
        key: 'outb_ord_hdr',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'documents' }),
          'outb_ord_hdr',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => (
          <>
            {record.outb_ord_hdrs.map(value => (
              <div key={`${record.id}:${value.id}`} style={{ width: '100%', textAlign: 'right' }}>
                <div>
                  <b>{value.shipping_doc_code}</b>
                </div>
                <div>
                  <b>{value.ref_code_01}</b>
                </div>
                <div>
                  {new Intl.NumberFormat([], {
                    style: 'decimal',
                    minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                    maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
                  }).format(value.net_amt)}
                </div>
              </div>
            ))}
          </>
        )
      },
      {
        width: 80,
        align: 'right',
        title: intl.formatMessage({ id: 'storage_row' }),
        // sort field
        dataIndex: 'storage_row_qty',
        ...AntDesignTable.getColumnSortProps(sorts, 'storage_row_qty'),
        // filter field
        key: 'storage_row_qty',
        render: (text, record) => (
          <>
            {new Intl.NumberFormat([], {
              style: 'decimal',
              minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
              maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
            }).format(record.storage_row_qty)}
          </>
        )
      },
      {
        width: 80,
        align: 'right',
        title: intl.formatMessage({ id: 'storage_bay' }),
        // sort field
        dataIndex: 'storage_bay_qty',
        ...AntDesignTable.getColumnSortProps(sorts, 'storage_bay_qty'),
        // filter field
        key: 'storage_bay_qty',
        render: (text, record) => (
          <>
            {new Intl.NumberFormat([], {
              style: 'decimal',
              minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
              maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
            }).format(record.storage_bay_qty)}
          </>
        )
      },
      {
        width: 80,
        align: 'right',
        title: intl.formatMessage({ id: 'storage_bin' }),
        // sort field
        dataIndex: 'storage_bin_qty',
        ...AntDesignTable.getColumnSortProps(sorts, 'storage_bin_qty'),
        // filter field
        key: 'storage_bin_qty',
        render: (text, record) => (
          <>
            {new Intl.NumberFormat([], {
              style: 'decimal',
              minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
              maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
            }).format(record.storage_bin_qty)}
          </>
        )
      },
      {
        width: 50,
        fixed: 'right',
        key: 'action',
        render: (text, record) => (
          <>
            <Button type="dashed" icon={<EditOutlined />} onClick={() => goToDocument(record.id)} />
          </>
        )
      }
    ];
  }

  useOnTableChange(pagination, filters, sorter) {
    const { resetTimestamp } = this.props;

    const processedFilters = AntDesignTable.processFilters(filters);
    const processedSorts = AntDesignTable.processSorts(sorter);

    resetTimestamp(pagination.current, processedSorts, processedFilters, pagination.pageSize);
  }

  useShowWorkspace() {
    const { setWorkspaceVisible } = this.props;

    setWorkspaceVisible(true);
  }

  render() {
    const {
      intl,
      resetTimestamp,
      currentPage,
      pageSize,
      total,
      documents,
      fetchIsLoading,
      selectedDocuments,
      addSelectedDocuments,
      removeSelectedDocuments
    } = this.props;

    return (
      <Card
        title={intl.formatMessage({ id: 'pick_lists' })}
        bordered={false}
        headStyle={{ textAlign: 'center' }}
        bodyStyle={{ padding: 0 }}
      >
        <Table
          size="small"
          // rowSelection={rowSelection}
          rowKey="id"
          pagination={{
            current: currentPage,
            pageSize: parseInt(pageSize, 10),
            total,
            showTotal: () => `${total} items`
          }}
          columns={this.getDocumentColumns()}
          dataSource={documents}
          loading={fetchIsLoading}
          bordered
          rowSelection={{
            selectedRowKeys: selectedDocuments.map(value => value.id),
            onChange: () => {
              // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            },
            onSelect: (record, selected) => {
              // console.log(`onSelect record: `, record, 'selected: ', selected, 'selectedRows: ', selectedRows);
              if (selected === true) {
                addSelectedDocuments([record]);
              } else {
                removeSelectedDocuments([record]);
              }
            },
            onSelectAll: (selected, selectedRows, changeRows) => {
              // console.log('onSelectAll selected: ', selected, 'selectedRows: ', selectedRows, 'changeRows: ', changeRows);
              if (selected === true) {
                addSelectedDocuments(changeRows);
              } else {
                removeSelectedDocuments(changeRows);
              }
            },
            onSelectInvert: selectedRows => {
              // console.log('onSelectInvert selectedRows: ', selectedRows);
              removeSelectedDocuments(selectedRows);
            },
            getCheckboxProps: record => ({
              // Column configuration not to be checked
              disabled: record.str_doc_status !== 'WIP'
              // name: record.name,
            })
          }}
          onChange={this.useOnTableChange}
          title={() => (
            <>
              <Row type="flex" justify="space-between" gutter={[0, 16]}>
                <Col span={8}>
                  <Button
                    type="primary"
                    // disabled={!isValid}
                    loading={fetchIsLoading}
                    onClick={() => resetTimestamp()}
                    icon={<ReloadOutlined />}
                  />
                </Col>
              </Row>
            </>
          )}
          scroll={{ x: 950 }}
        />

        <div
          style={{
            position: 'fixed',
            bottom: '50px',
            right: '50px',
            zIndex: 9999
          }}
        >
          <Badge count={selectedDocuments.length}>
            <Button
              size="large"
              type="primary"
              shape="circle"
              icon={<LaptopOutlined />}
              onClick={this.useShowWorkspace}
              loading={fetchIsLoading}
            />
          </Badge>
        </div>

        <WhseJob0301Dialog />
      </Card>
    );
  }
}

WhseJob0301Table.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  resetTimestamp: PropTypes.func,
  goToDocument: PropTypes.func,

  setWorkspaceVisible: PropTypes.func,

  addSelectedDocuments: PropTypes.func,
  removeSelectedDocuments: PropTypes.func,
  documents: PropTypes.arrayOf(PropTypes.object),
  selectedDocuments: PropTypes.arrayOf(PropTypes.object),

  sorts: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  filters: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),

  currentPage: PropTypes.number,
  pageSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  total: PropTypes.number,
  fetchIsLoading: PropTypes.bool,

  curSiteFlowId: PropTypes.number,
  fetchWhseJob0301: PropTypes.func
};

WhseJob0301Table.defaultProps = {
  intl: {},
  resetTimestamp() {},
  goToDocument() {},

  setWorkspaceVisible() {},

  addSelectedDocuments() {},
  removeSelectedDocuments() {},
  documents: [],
  selectedDocuments: [],

  sorts: {},
  filters: {},

  currentPage: 1,
  pageSize: '20',
  total: 0,
  fetchIsLoading: false,

  curSiteFlowId: 0,
  fetchWhseJob0301() {}
};

const mapStateToProps = state => ({
  timestamp: state.whseJob0301.timestamp,
  workspaceIsVisible: state.whseJob0301.workspaceIsVisible,

  documents: state.whseJob0301.documents,
  selectedDocuments: state.whseJob0301.selectedDocuments,

  sorts: state.whseJob0301.sorts,
  filters: state.whseJob0301.filters,

  currentPage: state.whseJob0301.currentPage,
  pageSize: state.whseJob0301.pageSize,
  total: state.whseJob0301.total,
  fetchIsLoading: state.whseJob0301.fetchIsLoading,

  curSiteFlowId: state.app.curSiteFlowId
});

const mapDispatchToProps = dispatch => ({
  resetTimestamp: (currentPage, sorts, filters, pageSize) =>
    dispatch(WhseJob0301Actions.whseJob0301ResetTimestamp(currentPage, sorts, filters, pageSize)),
  goToDocument: hdrId => dispatch(WhseJob0301Actions.whseJob0301GoToDocument(hdrId)),
  setWorkspaceVisible: boolean =>
    dispatch(WhseJob0301Actions.whseJob0301SetWorkspaceVisible(boolean)),

  addSelectedDocuments: selectedDocuments =>
    dispatch(WhseJob0301Actions.whseJob0301AddSelectedDocuments(selectedDocuments)),
  removeSelectedDocuments: selectedDocuments =>
    dispatch(WhseJob0301Actions.whseJob0301RemoveSelectedDocuments(selectedDocuments)),

  fetchWhseJob0301: (currentPage, sorts, filters, pageSize) =>
    dispatch(WhseJob0301Actions.whseJob0301FetchWhseJob0301(currentPage, sorts, filters, pageSize))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(WhseJob0301Table));
