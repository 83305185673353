import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  inbOrd03ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  inbOrd03GoToDocument: ['hdrId'],
  inbOrd03NewDocument: null,
  inbOrd03FetchInbOrd03: ['currentPage', 'sorts', 'filters', 'pageSize'],
  inbOrd03FetchInbOrd03Loading: ['boolean'],
  inbOrd03FetchInbOrd03Success: ['documents', 'currentPage', 'lastPage', 'total', 'pageSize'],
  inbOrd03AddSelectedDocuments: ['selectedDocuments'],
  inbOrd03RemoveSelectedDocuments: ['selectedDocuments'],
  inbOrd03SetWorkspaceVisible: ['boolean'],
  inbOrd03CreateInbOrd03: ['hdrIds'],
  inbOrd03CreateInbOrd03Loading: ['boolean'],
  inbOrd03CreateInbOrd03Success: ['newDocuments']
});

export const InbOrd03Types = Types;
export default Creators;
