/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */
import { createReducer } from 'reduxsauce';
import { OutbOrd07Types } from './Actions';
import INITIAL_STATE from './InitialState';

export const outbOrd07ResetTimestamp = (state, { currentPage, sorts, filters, pageSize }) => ({
  ...state,
  currentPage: currentPage || state.currentPage,
  sorts: sorts || state.sorts,
  filters: filters || state.filters,
  pageSize: pageSize || state.pageSize,
  timestamp: Date.now()
});

export const outbOrd07FetchOutbOrd07Loading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const outbOrd07FetchOutbOrd07Success = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  documents,
  currentPage,
  lastPage,
  total,
  pageSize
});

export const outbOrd07AddSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = state.selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: [
      ...state.selectedDocuments,
      ...selectedDocuments.filter(data => {
        return !selectIds.includes(data.id);
      })
    ]
  };
};

export const outbOrd07RemoveSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: state.selectedDocuments.filter(data => {
      return !selectIds.includes(data.id);
    })
  };
};

export const outbOrd07SetWorkspaceVisible = (state, { boolean }) => ({
  ...state,
  workspaceIsVisible: boolean
});

export const outbOrd07CreateOutbOrd07Loading = (state, { boolean }) => ({
  ...state,
  createIsLoading: boolean
});

export const outbOrd07CreateOutbOrd07Success = (state, { newDocuments }) => ({
  ...state,
  timestamp: Date.now(),
  selectedDocuments: [],
  newDocuments
});

export const outbOrd07SetExpandedRows = (state, { expandedRows }) => ({
  ...state,
  expandedRows
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [OutbOrd07Types.OUTB_ORD07_RESET_TIMESTAMP]: outbOrd07ResetTimestamp,
  [OutbOrd07Types.OUTB_ORD07_FETCH_OUTB_ORD07_LOADING]: outbOrd07FetchOutbOrd07Loading,
  [OutbOrd07Types.OUTB_ORD07_FETCH_OUTB_ORD07_SUCCESS]: outbOrd07FetchOutbOrd07Success,
  [OutbOrd07Types.OUTB_ORD07_ADD_SELECTED_DOCUMENTS]: outbOrd07AddSelectedDocuments,
  [OutbOrd07Types.OUTB_ORD07_REMOVE_SELECTED_DOCUMENTS]: outbOrd07RemoveSelectedDocuments,
  [OutbOrd07Types.OUTB_ORD07_SET_WORKSPACE_VISIBLE]: outbOrd07SetWorkspaceVisible,
  [OutbOrd07Types.OUTB_ORD07_CREATE_OUTB_ORD07_LOADING]: outbOrd07CreateOutbOrd07Loading,
  [OutbOrd07Types.OUTB_ORD07_CREATE_OUTB_ORD07_SUCCESS]: outbOrd07CreateOutbOrd07Success,
  [OutbOrd07Types.OUTB_ORD07_SET_EXPANDED_ROWS]: outbOrd07SetExpandedRows
});
