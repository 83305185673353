import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  outbOrd08ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  outbOrd08GoToDocument: ['hdrId'],
  outbOrd08NewDocument: null,
  outbOrd08FetchOutbOrd08: ['currentPage', 'sorts', 'filters', 'pageSize'],
  outbOrd08FetchOutbOrd08Loading: ['boolean'],
  outbOrd08FetchOutbOrd08Success: ['documents', 'currentPage', 'lastPage', 'total', 'pageSize'],
  outbOrd08AddSelectedDocuments: ['selectedDocuments'],
  outbOrd08RemoveSelectedDocuments: ['selectedDocuments'],
  outbOrd08SetWorkspaceVisible: ['boolean'],
  outbOrd08CreateOutbOrd08: ['hdrIds'],
  outbOrd08CreateOutbOrd08Loading: ['boolean'],
  outbOrd08CreateOutbOrd08Success: ['newDocuments'],
  outbOrd08CheckStock: ['hdrIds'],

  outbOrd08SetExpandedRows: ['expandedRows']
});

export const OutbOrd08Types = Types;
export default Creators;
