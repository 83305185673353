/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { RtnDelProcessTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const rtnDelProcessFetchRtnDelProcessLoading = (state, { boolean }) => ({
  ...state,
  processIsLoading: boolean
});

export const rtnDelProcessFetchRtnDelProcessSuccess = (state, { process }) => ({
  ...state,
  process
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [RtnDelProcessTypes.RTN_DEL_PROCESS_FETCH_RTN_DEL_PROCESS_LOADING]: rtnDelProcessFetchRtnDelProcessLoading,
  [RtnDelProcessTypes.RTN_DEL_PROCESS_FETCH_RTN_DEL_PROCESS_SUCCESS]: rtnDelProcessFetchRtnDelProcessSuccess
});
