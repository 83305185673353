import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  slsOrdExcel01SetStrProcType: ['strProcType'],
  slsOrdExcel01ShowBatchJobStatus: null,
  slsOrdExcel01ShowBatchJobStatusSuccess: ['batchJobStatus'],
  slsOrdExcel01UploadExcel: ['formikBag', 'file'],
  slsOrdExcel01UploadLoading: ['boolean']
});

export const SlsOrdExcel01Types = Types;
export default Creators;
