/**
 * The initial values for the redux state.
 */

export default {
  whsePerformanceChartTimestamp: 0,

  whsePerformanceData: [],
  whsePerformanceDataIsLoading: false,

  criteria: {
    end_date: new Date(),
    time_period: '1m',
    process: 'weekly'
  },

  processOptions: [
    { label: '1 month', value: '1m' },
    { label: '2 months', value: '2m' },
    { label: '3 months', value: '3m' }
  ],

  whsePerformanceChartVisibleAverageKeys: []
};
