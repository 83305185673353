/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { GdsRcptDetailReportTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const gdsRcptDetailReportReportLoading = (state, { boolean }) => ({
  ...state,
  reportIsLoading: boolean
});

export const gdsRcptDetailReportInitGdsRcptDetailSuccess = (state, { criteria }) => ({
  ...state,
  criteria
});

export const gdsRcptDetailReportGdsRcptDetailSuccess = (state, { criteria, reportData }) => ({
  ...state,
  criteria,
  reportData
});

export const gdsRcptDetailReportFetchDivisionOptionLoading = (state, { boolean }) => ({
  ...state,
  divisionIsLoading: boolean
});

export const gdsRcptDetailReportFetchDivisionOptionSuccess = (state, { options }) => ({
  ...state,
  divisionOptions: options
});

export const gdsRcptDetailReportFetchBizPartnerOptionLoading = (state, { boolean }) => ({
  ...state,
  bizPartnerIsLoading: boolean
});

export const gdsRcptDetailReportFetchBizPartnerOptionSuccess = (state, { options }) => ({
  ...state,
  bizPartnerOptions: options
});

export const gdsRcptDetailReportFetchDebtorOptionLoading = (state, { boolean }) => ({
  ...state,
  debtorIsLoading: boolean
});

export const gdsRcptDetailReportFetchDebtorOptionSuccess = (state, { options }) => ({
  ...state,
  debtorOptions: options
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [GdsRcptDetailReportTypes.GDS_RCPT_DETAIL_REPORT_REPORT_LOADING]: gdsRcptDetailReportReportLoading,
  [GdsRcptDetailReportTypes.GDS_RCPT_DETAIL_REPORT_INIT_GDS_RCPT_DETAIL_SUCCESS]: gdsRcptDetailReportInitGdsRcptDetailSuccess,
  [GdsRcptDetailReportTypes.GDS_RCPT_DETAIL_REPORT_GDS_RCPT_DETAIL_SUCCESS]: gdsRcptDetailReportGdsRcptDetailSuccess,

  [GdsRcptDetailReportTypes.GDS_RCPT_DETAIL_REPORT_FETCH_DIVISION_OPTION_LOADING]: gdsRcptDetailReportFetchDivisionOptionLoading,
  [GdsRcptDetailReportTypes.GDS_RCPT_DETAIL_REPORT_FETCH_DIVISION_OPTION_SUCCESS]: gdsRcptDetailReportFetchDivisionOptionSuccess,

  [GdsRcptDetailReportTypes.GDS_RCPT_DETAIL_REPORT_FETCH_BIZ_PARTNER_OPTION_LOADING]: gdsRcptDetailReportFetchBizPartnerOptionLoading,
  [GdsRcptDetailReportTypes.GDS_RCPT_DETAIL_REPORT_FETCH_BIZ_PARTNER_OPTION_SUCCESS]: gdsRcptDetailReportFetchBizPartnerOptionSuccess,

  [GdsRcptDetailReportTypes.GDS_RCPT_DETAIL_REPORT_FETCH_DEBTOR_OPTION_LOADING]: gdsRcptDetailReportFetchDebtorOptionLoading,
  [GdsRcptDetailReportTypes.GDS_RCPT_DETAIL_REPORT_FETCH_DEBTOR_OPTION_SUCCESS]: gdsRcptDetailReportFetchDebtorOptionSuccess
});
