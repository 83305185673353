/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { StockReorderPlanningReportTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const stockReorderPlanningReportReportLoading = (state, { boolean }) => ({
  ...state,
  reportIsLoading: boolean
});

export const stockReorderPlanningReportInitStockReorderPlanningSuccess = (state, { criteria }) => ({
  ...state,
  criteria
});

export const stockReorderPlanningReportStockReorderPlanningSuccess = (
  state,
  { criteria, reportData }
) => ({
  ...state,
  criteria,
  reportData
});

export const stockReorderPlanningReportFetchCompanyOptionLoading = (state, { boolean }) => ({
  ...state,
  companyIsLoading: boolean
});

export const stockReorderPlanningReportFetchCompanyOptionSuccess = (state, { options }) => ({
  ...state,
  companyOptions: options
});

export const stockReorderPlanningReportFetchItemOptionLoading = (state, { boolean }) => ({
  ...state,
  itemIsLoading: boolean
});

export const stockReorderPlanningReportFetchItemOptionSuccess = (state, { options }) => ({
  ...state,
  itemOptions: options
});

export const stockReorderPlanningReportFetchItemGroup01OptionLoading = (state, { boolean }) => ({
  ...state,
  itemGroup01IsLoading: boolean
});

export const stockReorderPlanningReportFetchItemGroup01OptionSuccess = (state, { options }) => ({
  ...state,
  itemGroup01Options: options
});

export const stockReorderPlanningReportFetchItemGroup02OptionLoading = (state, { boolean }) => ({
  ...state,
  itemGroup02IsLoading: boolean
});

export const stockReorderPlanningReportFetchItemGroup02OptionSuccess = (state, { options }) => ({
  ...state,
  itemGroup02Options: options
});

export const stockReorderPlanningReportFetchItemGroup03OptionLoading = (state, { boolean }) => ({
  ...state,
  itemGroup03IsLoading: boolean
});

export const stockReorderPlanningReportFetchItemGroup03OptionSuccess = (state, { options }) => ({
  ...state,
  itemGroup03Options: options
});

export const stockReorderPlanningReportFetchLocationOptionLoading = (state, { boolean }) => ({
  ...state,
  locationIsLoading: boolean
});

export const stockReorderPlanningReportFetchLocationOptionSuccess = (state, { options }) => ({
  ...state,
  locationOptions: options
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [StockReorderPlanningReportTypes.STOCK_REORDER_PLANNING_REPORT_REPORT_LOADING]: stockReorderPlanningReportReportLoading,
  [StockReorderPlanningReportTypes.STOCK_REORDER_PLANNING_REPORT_INIT_STOCK_REORDER_PLANNING_SUCCESS]: stockReorderPlanningReportInitStockReorderPlanningSuccess,
  [StockReorderPlanningReportTypes.STOCK_REORDER_PLANNING_REPORT_STOCK_REORDER_PLANNING_SUCCESS]: stockReorderPlanningReportStockReorderPlanningSuccess,

  [StockReorderPlanningReportTypes.STOCK_REORDER_PLANNING_REPORT_FETCH_COMPANY_OPTION_LOADING]: stockReorderPlanningReportFetchCompanyOptionLoading,
  [StockReorderPlanningReportTypes.STOCK_REORDER_PLANNING_REPORT_FETCH_COMPANY_OPTION_SUCCESS]: stockReorderPlanningReportFetchCompanyOptionSuccess,

  [StockReorderPlanningReportTypes.STOCK_REORDER_PLANNING_REPORT_FETCH_ITEM_OPTION_LOADING]: stockReorderPlanningReportFetchItemOptionLoading,
  [StockReorderPlanningReportTypes.STOCK_REORDER_PLANNING_REPORT_FETCH_ITEM_OPTION_SUCCESS]: stockReorderPlanningReportFetchItemOptionSuccess,

  [StockReorderPlanningReportTypes.STOCK_REORDER_PLANNING_REPORT_FETCH_ITEM_GROUP01_OPTION_LOADING]: stockReorderPlanningReportFetchItemGroup01OptionLoading,
  [StockReorderPlanningReportTypes.STOCK_REORDER_PLANNING_REPORT_FETCH_ITEM_GROUP01_OPTION_SUCCESS]: stockReorderPlanningReportFetchItemGroup01OptionSuccess,

  [StockReorderPlanningReportTypes.STOCK_REORDER_PLANNING_REPORT_FETCH_ITEM_GROUP02_OPTION_LOADING]: stockReorderPlanningReportFetchItemGroup02OptionLoading,
  [StockReorderPlanningReportTypes.STOCK_REORDER_PLANNING_REPORT_FETCH_ITEM_GROUP02_OPTION_SUCCESS]: stockReorderPlanningReportFetchItemGroup02OptionSuccess,

  [StockReorderPlanningReportTypes.STOCK_REORDER_PLANNING_REPORT_FETCH_ITEM_GROUP03_OPTION_LOADING]: stockReorderPlanningReportFetchItemGroup03OptionLoading,
  [StockReorderPlanningReportTypes.STOCK_REORDER_PLANNING_REPORT_FETCH_ITEM_GROUP03_OPTION_SUCCESS]: stockReorderPlanningReportFetchItemGroup03OptionSuccess,

  [StockReorderPlanningReportTypes.STOCK_REORDER_PLANNING_REPORT_FETCH_LOCATION_OPTION_LOADING]: stockReorderPlanningReportFetchLocationOptionLoading,
  [StockReorderPlanningReportTypes.STOCK_REORDER_PLANNING_REPORT_FETCH_LOCATION_OPTION_SUCCESS]: stockReorderPlanningReportFetchLocationOptionSuccess
});
