/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { BinTrfDetailTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const binTrfDetailResetTimestamp = state => ({
  ...state,
  timestamp: Date.now()
});

export const binTrfDetailSetHdrId = (state, { hdrId }) => ({
  ...state,
  timestamp: Date.now(),
  documentIsLoading: false,
  hdrId,
  detailIsVisible: false,
  jobDetailIsVisible: false
});

export const binTrfDetailShowDocumentLoading = (state, { boolean }) => ({
  ...state,
  documentIsLoading: boolean
});

export const binTrfDetailShowHeaderSuccess = (state, { documentHeader }) => ({
  ...state,
  documentHeader
});

export const binTrfDetailUpdateDocumentSuccess = (state, { documentHeader, documentDetails }) => ({
  ...state,
  documentHeader,
  documentDetails
});

export const binTrfDetailShowDetailsSuccess = (state, { documentDetails }) => ({
  ...state,
  documentDetails
});

export const binTrfDetailSetDetailVisible = (state, { boolean }) => ({
  ...state,
  detailIsVisible: boolean
});

export const binTrfDetailSetWhseJobType = (state, { whseJobType }) => ({
  ...state,
  whseJobType
});

export const binTrfDetailSetDocumentDetail = (state, { documentDetail }) => ({
  ...state,
  documentDetail,
  whseJobType: documentDetail.whse_job_type,
  frStorageBinOptions: [],
  quantBalOptions: [],
  itemBatchOptions: [],
  uomOptions: [],
  toStorageBinOptions: []
});

export const binTrfDetailFetchItemOptionLoading = (state, { boolean }) => ({
  ...state,
  itemIsLoading: boolean
});

export const binTrfDetailFetchItemOptionSuccess = (state, { options }) => ({
  ...state,
  itemOptions: options
});

export const binTrfDetailFetchItemBatchOptionLoading = (state, { boolean }) => ({
  ...state,
  itemBatchIsLoading: boolean
});

export const binTrfDetailFetchItemBatchOptionSuccess = (state, { options }) => ({
  ...state,
  itemBatchOptions: options
});

export const binTrfDetailFetchUomOptionLoading = (state, { boolean }) => ({
  ...state,
  uomIsLoading: boolean
});

export const binTrfDetailFetchUomOptionSuccess = (state, { options }) => ({
  ...state,
  uomOptions: options
});

export const binTrfDetailFetchFrStorageBinOptionLoading = (state, { boolean }) => ({
  ...state,
  frStorageBinIsLoading: boolean
});

export const binTrfDetailFetchFrStorageBinOptionSuccess = (state, { options }) => ({
  ...state,
  frStorageBinOptions: options
});

export const binTrfDetailFetchToStorageBinOptionLoading = (state, { boolean }) => ({
  ...state,
  toStorageBinIsLoading: boolean
});

export const binTrfDetailFetchToStorageBinOptionSuccess = (state, { options }) => ({
  ...state,
  toStorageBinOptions: options
});

export const binTrfDetailFetchToHandlingUnitOptionLoading = (state, { boolean }) => ({
  ...state,
  toHandlingUnitIsLoading: boolean
});

export const binTrfDetailFetchToHandlingUnitOptionSuccess = (state, { options }) => ({
  ...state,
  toHandlingUnitOptions: options
});

export const binTrfDetailFetchHandlingUnitOptionLoading = (state, { boolean }) => ({
  ...state,
  handlingUnitIsLoading: boolean
});

export const binTrfDetailFetchHandlingUnitOptionSuccess = (state, { options }) => ({
  ...state,
  handlingUnitOptions: options
});

// force refresh the formik form
export const binTrfDetailUpdateDetails = (state, { documentDetails }) => ({
  ...state,
  documentDetail: documentDetails[0]
});

// force refresh the formik form
export const binTrfDetailCreateDetail = (state, { documentDetail }) => ({
  ...state,
  documentDetail
});

export const binTrfDetailFetchStorageRowOptionLoading = (state, { boolean }) => ({
  ...state,
  storageRowIsLoading: boolean
});

export const binTrfDetailFetchStorageRowOptionSuccess = (state, { options }) => ({
  ...state,
  storageRowOptions: options
});

export const binTrfDetailFetchStorageBayOptionLoading = (state, { boolean }) => ({
  ...state,
  storageBayIsLoading: boolean
});

export const binTrfDetailFetchStorageBayOptionSuccess = (state, { options }) => ({
  ...state,
  storageBayOptions: options
});

export const binTrfDetailFetchQuantBalOptionLoading = (state, { boolean }) => ({
  ...state,
  quantBalIsLoading: boolean
});

export const binTrfDetailFetchQuantBalOptionSuccess = (state, { options }) => ({
  ...state,
  quantBalOptions: options
});

export const binTrfDetailUploadLoading = (state, { boolean }) => ({
  ...state,
  uploadIsLoading: boolean
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [BinTrfDetailTypes.BIN_TRF_DETAIL_RESET_TIMESTAMP]: binTrfDetailResetTimestamp,
  [BinTrfDetailTypes.BIN_TRF_DETAIL_SET_HDR_ID]: binTrfDetailSetHdrId,

  [BinTrfDetailTypes.BIN_TRF_DETAIL_SHOW_HEADER_SUCCESS]: binTrfDetailShowHeaderSuccess,

  [BinTrfDetailTypes.BIN_TRF_DETAIL_UPDATE_DOCUMENT_SUCCESS]: binTrfDetailUpdateDocumentSuccess,

  [BinTrfDetailTypes.BIN_TRF_DETAIL_SHOW_DOCUMENT_LOADING]: binTrfDetailShowDocumentLoading,

  [BinTrfDetailTypes.BIN_TRF_DETAIL_SHOW_DETAILS_SUCCESS]: binTrfDetailShowDetailsSuccess,

  [BinTrfDetailTypes.BIN_TRF_DETAIL_SET_DETAIL_VISIBLE]: binTrfDetailSetDetailVisible,
  [BinTrfDetailTypes.BIN_TRF_DETAIL_SET_DOCUMENT_DETAIL]: binTrfDetailSetDocumentDetail,

  [BinTrfDetailTypes.BIN_TRF_DETAIL_UPDATE_DETAILS]: binTrfDetailUpdateDetails,
  [BinTrfDetailTypes.BIN_TRF_DETAIL_CREATE_DETAIL]: binTrfDetailCreateDetail,

  [BinTrfDetailTypes.BIN_TRF_DETAIL_SET_WHSE_JOB_TYPE]: binTrfDetailSetWhseJobType,

  [BinTrfDetailTypes.BIN_TRF_DETAIL_FETCH_ITEM_OPTION_LOADING]: binTrfDetailFetchItemOptionLoading,
  [BinTrfDetailTypes.BIN_TRF_DETAIL_FETCH_ITEM_OPTION_SUCCESS]: binTrfDetailFetchItemOptionSuccess,

  [BinTrfDetailTypes.BIN_TRF_DETAIL_FETCH_ITEM_BATCH_OPTION_LOADING]: binTrfDetailFetchItemBatchOptionLoading,
  [BinTrfDetailTypes.BIN_TRF_DETAIL_FETCH_ITEM_BATCH_OPTION_SUCCESS]: binTrfDetailFetchItemBatchOptionSuccess,

  [BinTrfDetailTypes.BIN_TRF_DETAIL_FETCH_UOM_OPTION_LOADING]: binTrfDetailFetchUomOptionLoading,
  [BinTrfDetailTypes.BIN_TRF_DETAIL_FETCH_UOM_OPTION_SUCCESS]: binTrfDetailFetchUomOptionSuccess,

  [BinTrfDetailTypes.BIN_TRF_DETAIL_FETCH_FR_STORAGE_BIN_OPTION_LOADING]: binTrfDetailFetchFrStorageBinOptionLoading,
  [BinTrfDetailTypes.BIN_TRF_DETAIL_FETCH_FR_STORAGE_BIN_OPTION_SUCCESS]: binTrfDetailFetchFrStorageBinOptionSuccess,

  [BinTrfDetailTypes.BIN_TRF_DETAIL_FETCH_TO_STORAGE_BIN_OPTION_LOADING]: binTrfDetailFetchToStorageBinOptionLoading,
  [BinTrfDetailTypes.BIN_TRF_DETAIL_FETCH_TO_STORAGE_BIN_OPTION_SUCCESS]: binTrfDetailFetchToStorageBinOptionSuccess,

  [BinTrfDetailTypes.BIN_TRF_DETAIL_FETCH_TO_HANDLING_UNIT_OPTION_LOADING]: binTrfDetailFetchToHandlingUnitOptionLoading,
  [BinTrfDetailTypes.BIN_TRF_DETAIL_FETCH_TO_HANDLING_UNIT_OPTION_SUCCESS]: binTrfDetailFetchToHandlingUnitOptionSuccess,

  [BinTrfDetailTypes.BIN_TRF_DETAIL_FETCH_HANDLING_UNIT_OPTION_LOADING]: binTrfDetailFetchHandlingUnitOptionLoading,
  [BinTrfDetailTypes.BIN_TRF_DETAIL_FETCH_HANDLING_UNIT_OPTION_SUCCESS]: binTrfDetailFetchHandlingUnitOptionSuccess,

  [BinTrfDetailTypes.BIN_TRF_DETAIL_FETCH_STORAGE_ROW_OPTION_LOADING]: binTrfDetailFetchStorageRowOptionLoading,
  [BinTrfDetailTypes.BIN_TRF_DETAIL_FETCH_STORAGE_ROW_OPTION_SUCCESS]: binTrfDetailFetchStorageRowOptionSuccess,

  [BinTrfDetailTypes.BIN_TRF_DETAIL_FETCH_STORAGE_BAY_OPTION_LOADING]: binTrfDetailFetchStorageBayOptionLoading,
  [BinTrfDetailTypes.BIN_TRF_DETAIL_FETCH_STORAGE_BAY_OPTION_SUCCESS]: binTrfDetailFetchStorageBayOptionSuccess,

  [BinTrfDetailTypes.BIN_TRF_DETAIL_FETCH_QUANT_BAL_OPTION_LOADING]: binTrfDetailFetchQuantBalOptionLoading,
  [BinTrfDetailTypes.BIN_TRF_DETAIL_FETCH_QUANT_BAL_OPTION_SUCCESS]: binTrfDetailFetchQuantBalOptionSuccess,

  [BinTrfDetailTypes.BIN_TRF_DETAIL_UPLOAD_LOADING]: binTrfDetailUploadLoading
});
