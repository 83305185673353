import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  itemList02ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  itemList02GoToResource: ['resId'],
  itemList02NewResource: null,
  itemList02GoToAudit: ['hdrId'],
  itemList02FetchItemList02: ['currentPage', 'sorts', 'filters', 'pageSize'],
  itemList02FetchItemList02Loading: ['boolean'],
  itemList02FetchItemList02Success: ['documents', 'currentPage', 'lastPage', 'total', 'pageSize'],
  itemList02AddSelectedDocuments: ['selectedDocuments'],
  itemList02RemoveSelectedDocuments: ['selectedDocuments'],
  itemList02SetWorkspaceVisible: ['boolean']
});

export const ItemList02Types = Types;
export default Creators;
