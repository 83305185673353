import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  layerFarmProcessFetchList: null,
  layerFarmProcessFetchListLoading: ['boolean'],
  layerFarmProcessFetchListSuccess: ['process']
});

export const LayerFarmProcessTypes = Types;
export default Creators;
