/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { PalletLabelList01Types } from './Actions';
import INITIAL_STATE from './InitialState';

export const palletLabelList01ResetTimestamp = (
  state,
  { currentPage, sorts, filters, pageSize }
) => ({
  ...state,
  currentPage: currentPage || state.currentPage,
  sorts: sorts || state.sorts,
  filters: filters || state.filters,
  pageSize: pageSize || state.pageSize,
  timestamp: Date.now()
  // selectedDocuments: INITIAL_STATE.selectedDocuments
});

export const palletLabelList01FetchPalletLabelList01Loading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const palletLabelList01FetchPalletLabelList01Success = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  documents,
  currentPage,
  lastPage,
  total,
  pageSize
});

export const palletLabelList01AddSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = state.selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: [
      ...state.selectedDocuments,
      ...selectedDocuments.filter(data => {
        return !selectIds.includes(data.id);
      })
    ]
  };
};

export const palletLabelList01RemoveSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: state.selectedDocuments.filter(data => {
      return !selectIds.includes(data.id);
    })
  };
};

export const palletLabelList01SetWorkspaceVisible = (state, { boolean }) => ({
  ...state,
  workspaceIsVisible: boolean
});

export const palletLabelList01PrintPalletLabelList01Loading = (state, { boolean }) => ({
  ...state,
  printIsLoading: boolean
});

export const palletLabelList01CreatePalletLabelList01Loading = (state, { boolean }) => ({
  ...state,
  createIsLoading: boolean
});

export const palletLabelList01CreatePalletLabelList01Success = (state, { newDocuments }) => ({
  ...state,
  timestamp: Date.now(),
  selectedDocuments: newDocuments
});
/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [PalletLabelList01Types.PALLET_LABEL_LIST01_RESET_TIMESTAMP]: palletLabelList01ResetTimestamp,
  [PalletLabelList01Types.PALLET_LABEL_LIST01_FETCH_PALLET_LABEL_LIST01_LOADING]: palletLabelList01FetchPalletLabelList01Loading,
  [PalletLabelList01Types.PALLET_LABEL_LIST01_FETCH_PALLET_LABEL_LIST01_SUCCESS]: palletLabelList01FetchPalletLabelList01Success,
  [PalletLabelList01Types.PALLET_LABEL_LIST01_ADD_SELECTED_DOCUMENTS]: palletLabelList01AddSelectedDocuments,
  [PalletLabelList01Types.PALLET_LABEL_LIST01_REMOVE_SELECTED_DOCUMENTS]: palletLabelList01RemoveSelectedDocuments,
  [PalletLabelList01Types.PALLET_LABEL_LIST01_SET_WORKSPACE_VISIBLE]: palletLabelList01SetWorkspaceVisible,
  [PalletLabelList01Types.PALLET_LABEL_LIST01_PRINT_PALLET_LABEL_LIST01_LOADING]: palletLabelList01PrintPalletLabelList01Loading,
  [PalletLabelList01Types.PALLET_LABEL_LIST01_CREATE_PALLET_LABEL_LIST01_LOADING]: palletLabelList01CreatePalletLabelList01Loading,
  [PalletLabelList01Types.PALLET_LABEL_LIST01_CREATE_PALLET_LABEL_LIST01_SUCCESS]: palletLabelList01CreatePalletLabelList01Success
});
