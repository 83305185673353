/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { StorageBayDetailTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const storageBayDetailResetModelTimestamp = state => ({
  ...state,
  modelTimestamp: Date.now()
});

export const storageBayDetailSetResId = (state, { resId }) => ({
  ...state,
  modelTimestamp: Date.now(),
  resId
});

export const storageBayDetailShowModelLoading = (state, { boolean }) => ({
  ...state,
  modelIsLoading: boolean
});

export const storageBayDetailShowModelSuccess = (state, { model }) => ({
  ...state,
  model
});

export const storageBayDetailUpdateModelSuccess = (state, { model }) => ({
  ...state,
  model
});

export const storageBayDetailFetchStorageRowOptionLoading = (state, { boolean }) => ({
  ...state,
  storageRowIsLoading: boolean
});

export const storageBayDetailFetchStorageRowOptionSuccess = (state, { options }) => ({
  ...state,
  storageRowOptions: options
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [StorageBayDetailTypes.STORAGE_BAY_DETAIL_RESET_MODEL_TIMESTAMP]: storageBayDetailResetModelTimestamp,
  [StorageBayDetailTypes.STORAGE_BAY_DETAIL_SET_RES_ID]: storageBayDetailSetResId,
  [StorageBayDetailTypes.STORAGE_BAY_DETAIL_SHOW_MODEL_LOADING]: storageBayDetailShowModelLoading,
  [StorageBayDetailTypes.STORAGE_BAY_DETAIL_SHOW_MODEL_SUCCESS]: storageBayDetailShowModelSuccess,
  [StorageBayDetailTypes.STORAGE_BAY_DETAIL_UPDATE_MODEL_SUCCESS]: storageBayDetailUpdateModelSuccess,

  [StorageBayDetailTypes.STORAGE_BAY_DETAIL_FETCH_STORAGE_ROW_OPTION_LOADING]: storageBayDetailFetchStorageRowOptionLoading,
  [StorageBayDetailTypes.STORAGE_BAY_DETAIL_FETCH_STORAGE_ROW_OPTION_SUCCESS]: storageBayDetailFetchStorageRowOptionSuccess
});
