/**
 * The initial values for the redux state.
 */
export default {
  resId: 0,

  modelTimestamp: 0,
  model: {
    item_code: '',
    batch_serial_control: 1,
    batch_serial_no: '',
    expiry_date: '1970-01-01',
    receipt_date: '1970-01-01'
  },

  modelIsLoading: false
};
