import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import treeChanges from 'tree-changes';

import {
  AuditOutlined,
  EditOutlined,
  ReloadOutlined,
  UsergroupAddOutlined
} from '@ant-design/icons';

import { Row, Col, Table, Button, Tag } from 'antd';

import AntDesignTable from '../../Components/AntDesignTable';
import UserList01Actions from '../../Stores/UserList01/Actions';

import ManageRoleDialog from './ManageRoleDialog';
import BarcodeDialog from './BarcodeDialog';

class UserList01Table extends React.PureComponent {
  constructor() {
    super();

    this.useOnTableChange = this.useOnTableChange.bind(this);

    this.handleSearch = this.handleSearch.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.getDocumentColumns = this.getDocumentColumns.bind(this);
  }

  componentDidMount() {
    const { curSiteFlowId, setManageRoleVisible, resetTimestamp } = this.props;

    if (curSiteFlowId > 0) {
      setManageRoleVisible({}, false);
      resetTimestamp();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      curSiteFlowId,
      resetTimestamp,
      fetchUserList01,
      currentPage,
      sorts,
      filters,
      pageSize
    } = this.props;

    const { changed } = treeChanges(prevProps, this.props);

    if (changed('curSiteFlowId')) {
      resetTimestamp();
    }

    if (changed('timestamp')) {
      if (curSiteFlowId > 0) {
        fetchUserList01(currentPage, sorts, filters, pageSize);
      }
    }
  }

  componentWillUnmount() {}

  handleSearch(selectedKeys, confirm) {
    // eslint-disable-next-line no-unused-vars
    const { intl } = this.props;

    confirm();
  }

  handleReset(clearFilters) {
    // eslint-disable-next-line no-unused-vars
    const { intl } = this.props;

    clearFilters();
  }

  getDocumentColumns() {
    const {
      intl,
      sorts,
      filters,
      goToResource,
      goToAudit,
      setManageRoleVisible,
      showBarcode
    } = this.props;

    return [
      {
        width: 125,
        fixed: 'left',
        align: 'left',
        title: intl.formatMessage({ id: 'username' }),
        // sort field
        dataIndex: 'username',
        ...AntDesignTable.getColumnSortProps(sorts, 'username'),
        // filter field
        key: 'username',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'username' }),
          'username',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.username}</>
      },
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'barcode' }),
        // sort field
        dataIndex: 'barcode',
        // filter field
        key: 'barcode',
        render: (text, record) => (
          <Button
            type="link"
            onClick={
              () =>
                showBarcode(record.barcode, record.username, record.first_name, record.last_name)
              // eslint-disable-next-line react/jsx-curly-newline
            }
          >
            {record.barcode}
          </Button>
        )
      },
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'email' }),
        // sort field
        dataIndex: 'email',
        ...AntDesignTable.getColumnSortProps(sorts, 'email'),
        // filter field
        key: 'email',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'email' }),
          'email',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.email}</>
      },
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'status' }),
        // sort field
        dataIndex: 'str_status',
        ...AntDesignTable.getColumnSortProps(sorts, 'str_status'),
        // filter field
        key: 'str_status',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'status' }),
          'str_status',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.str_status}</>
      },
      {
        width: 120,
        align: 'left',
        title: intl.formatMessage({ id: 'last_login' }),
        // sort field
        dataIndex: 'last_login',
        ...AntDesignTable.getColumnSortProps(sorts, 'last_login'),
        // filter field
        key: 'last_login',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'last_login' }),
          'last_login',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.last_login}</>
      },
      {
        width: 120,
        align: 'left',
        title: intl.formatMessage({ id: 'password_changed_at' }),
        // sort field
        dataIndex: 'password_changed_at',
        ...AntDesignTable.getColumnSortProps(sorts, 'password_changed_at'),
        // filter field
        key: 'password_changed_at',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'password_changed_at' }),
          'password_changed_at',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.password_changed_at}</>
      },
      {
        width: 150,
        align: 'left',
        title: intl.formatMessage({ id: 'first_name' }),
        // sort field
        dataIndex: 'first_name',
        ...AntDesignTable.getColumnSortProps(sorts, 'first_name'),
        // filter field
        key: 'first_name',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'first_name' }),
          'first_name',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.first_name}</>
      },
      {
        width: 150,
        align: 'left',
        title: intl.formatMessage({ id: 'last_name' }),
        // sort field
        dataIndex: 'last_name',
        ...AntDesignTable.getColumnSortProps(sorts, 'last_name'),
        // filter field
        key: 'last_name',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'last_name' }),
          'last_name',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.last_name}</>
      },
      {
        width: 155,
        fixed: 'right',
        align: 'left',
        title: intl.formatMessage({ id: 'roles' }),
        // sort field
        dataIndex: 'roles',
        // filter field
        key: 'roles',
        render: (text, record) => (
          <div>
            {record.roles.map(value => (
              <Tag key={value.id}>{value.desc_01}</Tag>
            ))}
          </div>
        )
      },
      {
        width: 50,
        fixed: 'right',
        align: 'left',
        title: '',
        key: 'action',
        render: (text, record) => (
          <>
            <Button type="dashed" icon={<EditOutlined />} onClick={() => goToResource(record.id)} />
            <Button
              type="dashed"
              icon={<UsergroupAddOutlined />}
              onClick={() => setManageRoleVisible(record, true)}
            />
            <Button
              type="dashed"
              icon={<AuditOutlined />}
              disabled
              onClick={() => goToAudit(record.id)}
            />
          </>
        )
      }
    ];
  }

  useOnTableChange(pagination, filters, sorter) {
    const { resetTimestamp } = this.props;

    const processedFilters = AntDesignTable.processFilters(filters);
    const processedSorts = AntDesignTable.processSorts(sorter);

    resetTimestamp(pagination.current, processedSorts, processedFilters, pagination.pageSize);
  }

  render() {
    const {
      intl,
      curSiteFlowId,
      newResource,
      resetTimestamp,
      currentPage,
      pageSize,
      total,
      resources,
      fetchIsLoading,
      selectedResources,
      addSelectedResources,
      removeSelectedResources
    } = this.props;

    return (
      <>
        <Table
          size="small"
          // rowSelection={rowSelection}
          rowKey="id"
          pagination={{
            current: currentPage,
            pageSize: parseInt(pageSize, 10),
            total,
            showTotal: () => `${total} items`
          }}
          columns={this.getDocumentColumns()}
          dataSource={resources}
          loading={fetchIsLoading}
          bordered
          rowSelection={{
            selectedRowKeys: selectedResources.map(value => value.id),
            onChange: () => {
              // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            },
            onSelect: (record, selected) => {
              // console.log(`onSelect record: `, record, 'selected: ', selected, 'selectedRows: ', selectedRows);
              if (selected === true) {
                addSelectedResources([record]);
              } else {
                removeSelectedResources([record]);
              }
            },
            onSelectAll: (selected, selectedRows, changeRows) => {
              // console.log('onSelectAll selected: ', selected, 'selectedRows: ', selectedRows, 'changeRows: ', changeRows);
              if (selected === true) {
                addSelectedResources(changeRows);
              } else {
                removeSelectedResources(changeRows);
              }
            },
            onSelectInvert: selectedRows => {
              // console.log('onSelectInvert selectedRows: ', selectedRows);
              removeSelectedResources(selectedRows);
            },
            getCheckboxProps: () => ({
              // Column configuration not to be checked
              disabled: true
              // name: record.name,
            })
          }}
          onChange={this.useOnTableChange}
          title={() => (
            <>
              <Row type="flex" justify="start" gutter={[0, 16]}>
                <Col span={1}>
                  <Button
                    type="primary"
                    // disabled={!isValid}
                    loading={fetchIsLoading}
                    onClick={() => resetTimestamp()}
                    icon={<ReloadOutlined />}
                  />
                </Col>
                <Col span={5}>
                  <Button
                    type="primary"
                    disabled={curSiteFlowId === 0}
                    loading={fetchIsLoading}
                    onClick={() => newResource()}
                    // icon={<PlusOutlined />}
                  >
                    {intl.formatMessage({ id: 'new' })}
                  </Button>
                </Col>
              </Row>
            </>
          )}
          scroll={{ x: 950 }}
        />

        <ManageRoleDialog />
        <BarcodeDialog />
      </>
    );
  }
}

UserList01Table.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  resetTimestamp: PropTypes.func,
  goToResource: PropTypes.func,
  newResource: PropTypes.func,
  goToAudit: PropTypes.func,

  setManageRoleVisible: PropTypes.func,

  addSelectedResources: PropTypes.func,
  removeSelectedResources: PropTypes.func,
  resources: PropTypes.arrayOf(PropTypes.object),
  selectedResources: PropTypes.arrayOf(PropTypes.object),

  sorts: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  filters: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),

  currentPage: PropTypes.number,
  pageSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  total: PropTypes.number,
  fetchIsLoading: PropTypes.bool,

  curSiteFlowId: PropTypes.number,
  fetchUserList01: PropTypes.func,

  showBarcode: PropTypes.func
};

UserList01Table.defaultProps = {
  intl: {},
  resetTimestamp() {},
  goToResource() {},
  newResource() {},
  goToAudit() {},

  setManageRoleVisible() {},

  addSelectedResources() {},
  removeSelectedResources() {},
  resources: [],
  selectedResources: [],

  sorts: {},
  filters: {},

  currentPage: 1,
  pageSize: '20',
  total: 0,
  fetchIsLoading: false,

  curSiteFlowId: 0,
  fetchUserList01() {},

  showBarcode() {}
};

const mapStateToProps = state => ({
  timestamp: state.userList01.timestamp,

  resources: state.userList01.resources,
  selectedResources: state.userList01.selectedResources,

  sorts: state.userList01.sorts,
  filters: state.userList01.filters,

  currentPage: state.userList01.currentPage,
  pageSize: state.userList01.pageSize,
  total: state.userList01.total,
  fetchIsLoading: state.userList01.fetchIsLoading,

  curSiteFlowId: state.app.curSiteFlowId
});

const mapDispatchToProps = dispatch => ({
  resetTimestamp: (currentPage, sorts, filters, pageSize) =>
    dispatch(UserList01Actions.userList01ResetTimestamp(currentPage, sorts, filters, pageSize)),
  goToResource: resId => dispatch(UserList01Actions.userList01GoToResource(resId)),
  newResource: () => dispatch(UserList01Actions.userList01NewResource()),
  goToAudit: resId => dispatch(UserList01Actions.userList01GoToAudit(resId)),

  setManageRoleVisible: (resource, boolean) =>
    dispatch(UserList01Actions.userList01SetManageRoleVisible(resource, boolean)),

  addSelectedResources: selectedResources =>
    dispatch(UserList01Actions.userList01AddSelectedResources(selectedResources)),
  removeSelectedResources: selectedResources =>
    dispatch(UserList01Actions.userList01RemoveSelectedResources(selectedResources)),

  fetchUserList01: (currentPage, sorts, filters, pageSize) =>
    dispatch(UserList01Actions.userList01FetchUserList01(currentPage, sorts, filters, pageSize)),
  showBarcode: (barcode, username, firstName, lastName) =>
    dispatch(UserList01Actions.userList01ShowBarcode(barcode, username, firstName, lastName))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(UserList01Table));
