import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  prfDelIndexResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  prfDelIndexGoToPrint: ['document'],
  prfDelIndexGoToDocument: ['hdrId'],
  prfDelIndexGoToAudit: ['hdrId'],
  prfDelIndexFetchPrfDelIndex: ['currentPage', 'sorts', 'filters', 'pageSize'],
  prfDelIndexFetchPrfDelIndexLoading: ['boolean'],
  prfDelIndexFetchPrfDelIndexSuccess: ['documents', 'currentPage', 'lastPage', 'total', 'pageSize'],
  prfDelIndexAddSelectedDocuments: ['selectedDocuments'],
  prfDelIndexRemoveSelectedDocuments: ['selectedDocuments'],
  prfDelIndexSetWorkspaceVisible: ['boolean'],

  prfDelIndexWorkspaceLoading: ['boolean'],
  prfDelIndexResetPrfDelSync01: ['hdrIds'],
  prfDelIndexResetPrfDelSync01Success: null
});

export const PrfDelIndexTypes = Types;
export default Creators;
