/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { ProjectList01Types } from './Actions';
import INITIAL_STATE from './InitialState';

export const projectList01ResetTimestamp = (state, { currentPage, sorts, filters, pageSize }) => ({
  ...state,
  currentPage: currentPage || state.currentPage,
  sorts: sorts || state.sorts,
  filters: filters || state.filters,
  pageSize: pageSize || state.pageSize,
  timestamp: Date.now(),
  // selectedDocuments: INITIAL_STATE.selectedDocuments,
  toDivisionOptions: INITIAL_STATE.toDivisionOptions,
  toDivisionOption: INITIAL_STATE.toDivisionOption
});

export const projectList01FetchProjectList01Loading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const projectList01FetchProjectList01Success = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  documents,
  currentPage,
  lastPage,
  total,
  pageSize
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [ProjectList01Types.PROJECT_LIST01_RESET_TIMESTAMP]: projectList01ResetTimestamp,
  [ProjectList01Types.PROJECT_LIST01_FETCH_PROJECT_LIST01_LOADING]: projectList01FetchProjectList01Loading,
  [ProjectList01Types.PROJECT_LIST01_FETCH_PROJECT_LIST01_SUCCESS]: projectList01FetchProjectList01Success
});
