import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  countAdjFServ01ShowSyncSetting: null,
  countAdjFServ01ShowSyncSettingLoading: ['boolean'],
  countAdjFServ01ShowSyncSettingSuccess: ['syncSetting'],
  countAdjFServ01UpdateSyncSetting: ['formikBag', 'syncSetting'],
  countAdjFServ01ShowBatchJobStatus: null,
  countAdjFServ01ShowBatchJobStatusSuccess: ['batchJobStatus'],

  countAdjFServ01ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  countAdjFServ01GoToDocument: ['hdrId'],
  countAdjFServ01FetchCountAdjFServ01: ['currentPage', 'sorts', 'filters', 'pageSize'],
  countAdjFServ01FetchCountAdjFServ01Loading: ['boolean'],
  countAdjFServ01FetchCountAdjFServ01Success: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],
  countAdjFServ01AddSelectedDocuments: ['selectedDocuments'],
  countAdjFServ01RemoveSelectedDocuments: ['selectedDocuments'],
  countAdjFServ01SetWorkspaceVisible: ['boolean'],

  countAdjFServ01Export: ['hdrIds'],
  countAdjFServ01ExportSuccess: null,
  countAdjFServ01ExportLoading: ['boolean']
});

export const CountAdjFServ01Types = Types;
export default Creators;
