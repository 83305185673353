import { put, call, select, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import { replace } from 'connected-react-router';
import ApiService from '../Services/ApiService';
import AppActions from '../Stores/App/Actions';
import TransportDetailActions, { TransportDetailTypes } from '../Stores/TransportDetail/Actions';

const getAppStore = state => state.app;

export function* transportDetailInitModel() {
  try {
    yield put(TransportDetailActions.transportDetailShowModelLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `transport/initModel/${app.curSiteFlowId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(TransportDetailActions.transportDetailShowModelSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(TransportDetailActions.transportDetailShowModelLoading(false));
  }
}

export function* transportDetailShowModel({ resId }) {
  try {
    yield put(TransportDetailActions.transportDetailShowModelLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `transport/showModel/${resId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(TransportDetailActions.transportDetailShowModelSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(TransportDetailActions.transportDetailShowModelLoading(false));
  }
}

export function* transportDetailUpdateModel({ formikBag, model }) {
  formikBag.setSubmitting(true);
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: model
    };

    const result = yield call(
      ApiService.putApi, // function
      app.apiUrl,
      'transport/updateModel',
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      const { model: retModel } = result.data;

      yield put(TransportDetailActions.transportDetailUpdateModelSuccess(retModel));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* transportDetailCreateModel({ formikBag, model }) {
  formikBag.setSubmitting(true);
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: model
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `transport/createModel`,
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      yield put(replace(`${app.appPath}/transportDetail/update/${result.data}`));
      yield put(TransportDetailActions.transportDetailSetResId(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* transportDetailFetchLocationOptions({ search }) {
  try {
    yield put(TransportDetailActions.transportDetailFetchLocationOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId: app.curSiteFlowId,
      search,
      filters: [
        {
          field: 'location_types',
          value: [
            3 // TRANSITORY_STOCK
          ]
        }
      ]
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `location/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({ value: d.id, label: `${d.code}` }));

      yield put(TransportDetailActions.transportDetailFetchLocationOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(TransportDetailActions.transportDetailFetchLocationOptionLoading(false));
  }
}

export const saga = [
  takeLatest(TransportDetailTypes.TRANSPORT_DETAIL_INIT_MODEL, transportDetailInitModel),
  takeLatest(TransportDetailTypes.TRANSPORT_DETAIL_SHOW_MODEL, transportDetailShowModel),

  takeLatest(TransportDetailTypes.TRANSPORT_DETAIL_UPDATE_MODEL, transportDetailUpdateModel),
  takeLatest(TransportDetailTypes.TRANSPORT_DETAIL_CREATE_MODEL, transportDetailCreateModel),

  takeLatest(
    TransportDetailTypes.TRANSPORT_DETAIL_FETCH_LOCATION_OPTIONS,
    transportDetailFetchLocationOptions
  )
];
