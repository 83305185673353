import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import treeChanges from 'tree-changes';

import { Card, Tabs, Tooltip, Spin } from 'antd';

import GdsRcptProcessActions from '../../Stores/GdsRcptProcess/Actions';

import GdsRcpt01Table from '../GdsRcpt01/GdsRcpt01Table';
import WhseJob1401Table from '../WhseJob1401/WhseJob1401Table';
import WhseJob140101Table from '../WhseJob140101/WhseJob140101Table';
import PutAway01Table from '../PutAway01/PutAway01Table';
import WhseJob1501Table from '../WhseJob1501/WhseJob1501Table';
import WhseJob150101Table from '../WhseJob150101/WhseJob150101Table';
import GdsRcpt0101Table from '../GdsRcpt0101/GdsRcpt0101Table';
import InvDoc03Table from '../InvDoc03/InvDoc03Table';

const { TabPane } = Tabs;

class GdsRcptProcessScreen extends React.PureComponent {
  componentDidMount() {
    const { curSiteFlowId, fetchGdsRcptProcess } = this.props;
    if (curSiteFlowId > 0) {
      fetchGdsRcptProcess();
    }
  }

  componentDidUpdate(prevProps) {
    const { curSiteFlowId, fetchGdsRcptProcess } = this.props;

    const { changed } = treeChanges(prevProps, this.props);

    if (changed('curSiteFlowId')) {
      if (curSiteFlowId > 0) {
        fetchGdsRcptProcess();
      }
    }
  }

  componentWillUnmount() {}

  render() {
    const { intl, match, process, appPath, processIsLoading } = this.props;

    return (
      <Card>
        <Spin spinning={processIsLoading}>
          <Tabs type="card" activeKey={match.params.proc_type}>
            {process.map(value => (
              <TabPane
                tab={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <Tooltip
                    placement="bottom"
                    title={intl.formatMessage({ id: `${value.proc_type.toLowerCase()}_desc` })}
                  >
                    <Link
                      style={value.proc_type !== match.params.proc_type ? { color: 'black' } : {}}
                      to={`${appPath}/gdsRcptProcess/index/${value.proc_type}`}
                    >
                      <FormattedMessage id={`${value.proc_type.toLowerCase()}_label`} />
                    </Link>
                  </Tooltip>
                }
                key={value.proc_type}
              />
            ))}
          </Tabs>
        </Spin>

        {match.params.proc_type === 'GDS_RCPT_01' && <GdsRcpt01Table match={match} />}
        {match.params.proc_type === 'WHSE_JOB_14_01' && <WhseJob1401Table match={match} />}
        {match.params.proc_type === 'WHSE_JOB_14_01_01' && <WhseJob140101Table match={match} />}
        {match.params.proc_type === 'PUT_AWAY_01' && <PutAway01Table match={match} />}
        {match.params.proc_type === 'WHSE_JOB_15_01' && <WhseJob1501Table match={match} />}
        {match.params.proc_type === 'WHSE_JOB_15_01_01' && <WhseJob150101Table match={match} />}
        {match.params.proc_type === 'GDS_RCPT_01_01' && <GdsRcpt0101Table match={match} />}
        {match.params.proc_type === 'INV_DOC_03' && <InvDoc03Table match={match} />}
      </Card>
    );
  }
}

GdsRcptProcessScreen.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object,
  appPath: PropTypes.string,
  process: PropTypes.arrayOf(PropTypes.object),
  processIsLoading: PropTypes.bool,

  curSiteFlowId: PropTypes.number,
  fetchGdsRcptProcess: PropTypes.func
};

GdsRcptProcessScreen.defaultProps = {
  intl: {},
  match: {},
  appPath: '',
  process: [],
  processIsLoading: false,

  curSiteFlowId: 0,
  fetchGdsRcptProcess() {}
};

const mapStateToProps = state => ({
  appPath: state.app.appPath,
  process: state.gdsRcptProcess.process,
  processIsLoading: state.gdsRcptProcess.processIsLoading,

  curSiteFlowId: state.app.curSiteFlowId
});

const mapDispatchToProps = dispatch => ({
  fetchGdsRcptProcess: () => dispatch(GdsRcptProcessActions.gdsRcptProcessFetchGdsRcptProcess())
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(GdsRcptProcessScreen));
