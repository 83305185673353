import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  prodSheet01ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  prodSheet01GoToDocument: ['hdrId'],
  prodSheet01NewDocument: null,
  prodSheet01FetchProdSheet01: ['currentPage', 'sorts', 'filters', 'pageSize'],
  prodSheet01FetchProdSheet01Loading: ['boolean'],
  prodSheet01FetchProdSheet01Success: ['documents', 'currentPage', 'lastPage', 'total', 'pageSize'],
  prodSheet01AddSelectedDocuments: ['selectedDocuments'],
  prodSheet01RemoveSelectedDocuments: ['selectedDocuments'],
  prodSheet01SetWorkspaceVisible: ['boolean'],
  prodSheet01CreateProdSheet01: ['hdrIds'],
  prodSheet01CreateProdSheet01Loading: ['boolean'],
  prodSheet01CreateProdSheet01Success: ['newDocuments']
});

export const ProdSheet01Types = Types;
export default Creators;
