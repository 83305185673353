import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import { SyncOutlined } from '@ant-design/icons';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { Card, Row, Col, Progress, Typography } from 'antd';
import { FormikDatePicker, FormikSelect, FormikButton } from '../../Components/Formik';

import CountAdjFServ02Actions from '../../Stores/CountAdjFServ02/Actions';

const { Text } = Typography;

class CountAdjFServ02Form extends React.PureComponent {
  constructor() {
    super();

    this.handleCompanySearch = this.handleCompanySearch.bind(this);
    this.handleLocationSearch = this.handleLocationSearch.bind(this);
    this.handleItemSearch = this.handleItemSearch.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate() {
    const { exportIsLoading, showBatchJobStatus } = this.props;

    if (exportIsLoading) {
      setTimeout(() => {
        showBatchJobStatus();
      }, 10000);
    }
  }

  componentWillUnmount() {}

  handleCompanySearch(form, value) {
    const { fetchCompanyOptions } = this.props;

    fetchCompanyOptions(value);
  }

  handleLocationSearch(form, value) {
    const { fetchLocationOptions } = this.props;

    fetchLocationOptions(value);
  }

  handleItemSearch(form, value) {
    const { fetchItemOptions } = this.props;

    fetchItemOptions(value);
  }

  render() {
    const {
      intl,
      fetchIsLoading,
      exportIsLoading,
      criteria,
      batchJobStatus,
      fServ02Export,
      companyIsLoading,
      companyOptions,
      locationIsLoading,
      locationOptions,
      itemIsLoading,
      itemOptions
    } = this.props;

    return (
      <Formik
        enableReinitialize
        initialValues={criteria}
        onSubmit={(values, formikBag) => {
          fServ02Export(formikBag, values);
        }}
        validationSchema={Yup.object().shape({
          doc_date: Yup.date().min(
            new Date(1969, 12, 1),
            intl.formatMessage({ id: 'doc_date_is_required' })
          ),
          company_id_select2: Yup.object().shape({
            value: Yup.number().min(1, intl.formatMessage({ id: 'company_is_required' }))
          }),
          location_id_select2: Yup.object().shape({
            value: Yup.number().min(1, intl.formatMessage({ id: 'location_is_required' }))
          })
        })}
      >
        {({
          // values,
          // handleChange,
          // errors,
          // setFieldTouched,
          // dirty,
          // touched,
          isSubmitting,
          // isValid,
          handleSubmit
          // handleReset
        }) => (
          <Form>
            <Card>
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'stock_balance_date' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field name="doc_date" component={FormikDatePicker} />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'company' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="company_id_select2"
                    component={FormikSelect}
                    showArrow
                    filterOption={false}
                    onSearch={this.handleCompanySearch}
                    notFoundContent={null}
                    loading={companyIsLoading}
                    options={companyOptions}
                    disabled={isSubmitting}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'location' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="location_id_select2"
                    component={FormikSelect}
                    showArrow
                    filterOption={false}
                    onSearch={this.handleLocationSearch}
                    notFoundContent={null}
                    loading={locationIsLoading}
                    options={locationOptions}
                    disabled={isSubmitting}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'item' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="item_ids_select2"
                    component={FormikSelect}
                    showArrow
                    filterOption={false}
                    onSearch={this.handleItemSearch}
                    notFoundContent={null}
                    loading={itemIsLoading}
                    options={itemOptions}
                    disabled={isSubmitting}
                    selectMode="multiple"
                  />
                </Col>
              </Row>

              <Row type="flex" justify="start" gutter={[0, 16]}>
                <Col span={3}>
                  <Field
                    type="primary"
                    name="submit_action"
                    value="export"
                    component={FormikButton}
                    // disabled={values.doc_status >= 50}
                    loading={isSubmitting || fetchIsLoading || exportIsLoading}
                    onClick={handleSubmit}
                    label={intl.formatMessage({ id: 'export' })}
                    icon={<SyncOutlined />}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[0, 8]}>
                <Col span={24}>
                  <Progress percent={parseInt(batchJobStatus.status_number, 10)} />
                </Col>
              </Row>
            </Card>
          </Form>
        )}
      </Formik>
    );
  }
}

CountAdjFServ02Form.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,

  criteria: PropTypes.shape({}),
  batchJobStatus: PropTypes.shape({
    status_number: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }),
  fetchIsLoading: PropTypes.bool,
  exportIsLoading: PropTypes.bool,

  showBatchJobStatus: PropTypes.func,
  fServ02Export: PropTypes.func,

  fetchCompanyOptions: PropTypes.func,
  companyIsLoading: PropTypes.bool,
  companyOptions: PropTypes.arrayOf(PropTypes.object),

  fetchLocationOptions: PropTypes.func,
  locationIsLoading: PropTypes.bool,
  locationOptions: PropTypes.arrayOf(PropTypes.object),

  fetchItemOptions: PropTypes.func,
  itemIsLoading: PropTypes.bool,
  itemOptions: PropTypes.arrayOf(PropTypes.object)
};

CountAdjFServ02Form.defaultProps = {
  intl: {},

  criteria: {
    company_id_select2: {
      value: 0,
      label: ''
    },
    location_id_select2: {
      value: 0,
      label: ''
    }
  },
  batchJobStatus: {
    status_number: '0'
  },
  fetchIsLoading: false,
  exportIsLoading: false,

  showBatchJobStatus() {},
  fServ02Export() {},

  fetchCompanyOptions() {},
  companyIsLoading: false,
  companyOptions: [],

  fetchLocationOptions() {},
  locationIsLoading: false,
  locationOptions: [],

  fetchItemOptions() {},
  itemIsLoading: false,
  itemOptions: []
};

const mapStateToProps = state => ({
  criteria: state.countAdjFServ02.criteria,
  batchJobStatus: state.countAdjFServ02.batchJobStatus,
  fetchIsLoading: state.countAdjFServ02.fetchIsLoading,
  exportIsLoading: state.countAdjFServ02.exportIsLoading,

  companyIsLoading: state.countAdjFServ02.companyIsLoading,
  companyOptions: state.countAdjFServ02.companyOptions,

  locationIsLoading: state.countAdjFServ02.locationIsLoading,
  locationOptions: state.countAdjFServ02.locationOptions,

  itemIsLoading: state.countAdjFServ02.itemIsLoading,
  itemOptions: state.countAdjFServ02.itemOptions
});

const mapDispatchToProps = dispatch => ({
  showSyncSetting: () => dispatch(CountAdjFServ02Actions.countAdjFServ02ShowSyncSetting()),
  updateSyncSetting: (formikBag, syncSetting) =>
    dispatch(CountAdjFServ02Actions.countAdjFServ02UpdateSyncSetting(formikBag, syncSetting)),
  showBatchJobStatus: () => dispatch(CountAdjFServ02Actions.countAdjFServ02ShowBatchJobStatus()),
  fServ02Export: (formikBag, criteria) =>
    dispatch(CountAdjFServ02Actions.countAdjFServ02Export(formikBag, criteria)),

  fetchCompanyOptions: search =>
    dispatch(CountAdjFServ02Actions.countAdjFServ02FetchCompanyOptions(search)),
  fetchLocationOptions: search =>
    dispatch(CountAdjFServ02Actions.countAdjFServ02FetchLocationOptions(search)),
  fetchItemOptions: search =>
    dispatch(CountAdjFServ02Actions.countAdjFServ02FetchItemOptions(search))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CountAdjFServ02Form));
