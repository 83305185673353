import { put, call, select, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import ApiService from '../Services/ApiService';
import AppActions from '../Stores/App/Actions';
import SlsOrdTrackingReportActions, {
  SlsOrdTrackingReportTypes
} from '../Stores/SlsOrdTrackingReport/Actions';

const getAppStore = state => state.app;

export function* slsOrdTrackingReportInitSlsOrdTracking() {
  try {
    yield put(SlsOrdTrackingReportActions.slsOrdTrackingReportReportLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `salesReport/initSlsOrdTracking/${app.curSiteFlowId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(
        SlsOrdTrackingReportActions.slsOrdTrackingReportInitSlsOrdTrackingSuccess(result.data)
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(SlsOrdTrackingReportActions.slsOrdTrackingReportReportLoading(false));
  }
}

export function* slsOrdTrackingReportSlsOrdTracking({ formikBag, criteria }) {
  try {
    formikBag.setSubmitting(true);
    yield put(SlsOrdTrackingReportActions.slsOrdTrackingReportReportLoading(true));

    const objCriteria = {};
    if ('start_date' in criteria) {
      objCriteria.start_date = criteria.start_date;
    }
    if ('end_date' in criteria) {
      objCriteria.end_date = criteria.end_date;
    }
    if ('division_ids_select2' in criteria) {
      const values = criteria.division_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.division_ids = values.map(entry => {
          return entry.value;
        });
      }
    }
    if ('biz_partner_ids_select2' in criteria) {
      const values = criteria.biz_partner_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.biz_partner_ids = values.map(entry => {
          return entry.value;
        });
      }
    }
    if ('debtor_ids_select2' in criteria) {
      const values = criteria.debtor_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.debtor_ids = values.map(entry => {
          return entry.value;
        });
      }
    }
    if ('delivery_point_ids_select2' in criteria) {
      const values = criteria.delivery_point_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.delivery_point_ids = values.map(entry => {
          return entry.value;
        });
      }
    }
    if ('transport_ids_select2' in criteria) {
      const values = criteria.transport_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.transport_ids = values.map(entry => {
          return entry.value;
        });
      }
    }

    let columns = [];
    if (criteria.columns.target.length > 0) {
      columns = criteria.columns.target.map(entry => {
        return entry.column;
      });
    }

    const app = yield select(getAppStore);
    const postData = {
      sorts: [],
      criteria: objCriteria,
      columns
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `salesReport/slsOrdTracking/${app.curSiteFlowId}`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      yield put(
        SlsOrdTrackingReportActions.slsOrdTrackingReportSlsOrdTrackingSuccess(
          criteria,
          result.data.data
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
    yield put(SlsOrdTrackingReportActions.slsOrdTrackingReportReportLoading(false));
  }
}

export function* slsOrdTrackingReportFetchDivisionOptions({ search }) {
  try {
    yield put(SlsOrdTrackingReportActions.slsOrdTrackingReportFetchDivisionOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `division/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.name_01}`
      }));

      yield put(
        SlsOrdTrackingReportActions.slsOrdTrackingReportFetchDivisionOptionSuccess(options)
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(SlsOrdTrackingReportActions.slsOrdTrackingReportFetchDivisionOptionLoading(false));
  }
}

export function* slsOrdTrackingReportFetchDeliveryPointOptions({ search }) {
  try {
    yield put(
      SlsOrdTrackingReportActions.slsOrdTrackingReportFetchDeliveryPointOptionLoading(true)
    );

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `deliveryPoint/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.company_name_01}`
      }));

      yield put(
        SlsOrdTrackingReportActions.slsOrdTrackingReportFetchDeliveryPointOptionSuccess(options)
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(
      SlsOrdTrackingReportActions.slsOrdTrackingReportFetchDeliveryPointOptionLoading(false)
    );
  }
}

export function* slsOrdTrackingReportFetchDebtorOptions({ search }) {
  try {
    yield put(SlsOrdTrackingReportActions.slsOrdTrackingReportFetchDebtorOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      debtorType: 1, // 0 = normal, 1 = principal
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `debtor/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.company_name_01} ${d.company_name_02}`
      }));

      yield put(SlsOrdTrackingReportActions.slsOrdTrackingReportFetchDebtorOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(SlsOrdTrackingReportActions.slsOrdTrackingReportFetchDebtorOptionLoading(false));
  }
}

export function* slsOrdTrackingReportFetchTransportOptions({ search }) {
  try {
    yield put(SlsOrdTrackingReportActions.slsOrdTrackingReportFetchTransportOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId: app.curSiteFlowId,
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `transport/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01} ${d.desc_02}`
      }));

      yield put(
        SlsOrdTrackingReportActions.slsOrdTrackingReportFetchTransportOptionSuccess(options)
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(SlsOrdTrackingReportActions.slsOrdTrackingReportFetchTransportOptionLoading(false));
  }
}

export const saga = [
  takeLatest(
    SlsOrdTrackingReportTypes.SLS_ORD_TRACKING_REPORT_INIT_SLS_ORD_TRACKING,
    slsOrdTrackingReportInitSlsOrdTracking
  ),
  takeLatest(
    SlsOrdTrackingReportTypes.SLS_ORD_TRACKING_REPORT_SLS_ORD_TRACKING,
    slsOrdTrackingReportSlsOrdTracking
  ),

  takeLatest(
    SlsOrdTrackingReportTypes.SLS_ORD_TRACKING_REPORT_FETCH_DIVISION_OPTIONS,
    slsOrdTrackingReportFetchDivisionOptions
  ),
  takeLatest(
    SlsOrdTrackingReportTypes.SLS_ORD_TRACKING_REPORT_FETCH_DELIVERY_POINT_OPTIONS,
    slsOrdTrackingReportFetchDeliveryPointOptions
  ),

  takeLatest(
    SlsOrdTrackingReportTypes.SLS_ORD_TRACKING_REPORT_FETCH_DEBTOR_OPTIONS,
    slsOrdTrackingReportFetchDebtorOptions
  ),

  takeLatest(
    SlsOrdTrackingReportTypes.SLS_ORD_TRACKING_REPORT_FETCH_TRANSPORT_OPTIONS,
    slsOrdTrackingReportFetchTransportOptions
  )
];
