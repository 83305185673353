import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  trfFromStoreProcessFetchTrfFromStoreProcess: null,
  trfFromStoreProcessFetchTrfFromStoreProcessLoading: ['boolean'],
  trfFromStoreProcessFetchTrfFromStoreProcessSuccess: ['process']
});

export const TrfFromStoreProcessTypes = Types;
export default Creators;
