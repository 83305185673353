import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import treeChanges from 'tree-changes';

import { Card, Tabs, Tooltip, Spin } from 'antd';

import TrfFromStoreProcessActions from '../../Stores/TrfFromStoreProcess/Actions';

import InbOrd06Table from '../InbOrd06/InbOrd06Table';
import StkIssSync04Table from '../StkIssSync04/StkIssSync04Table';
import StkRcptSync04Table from '../StkRcptSync04/StkRcptSync04Table';
import GdsRcptFServ02Table from '../GdsRcptFServ02/GdsRcptFServ02Table';

const { TabPane } = Tabs;

class TrfFromStoreProcessScreen extends React.PureComponent {
  componentDidMount() {
    const { curDivisionId, fetchTrfFromStoreProcess } = this.props;
    if (curDivisionId > 0) {
      fetchTrfFromStoreProcess();
    }
  }

  componentDidUpdate(prevProps) {
    const { curDivisionId, fetchTrfFromStoreProcess } = this.props;

    const { changed } = treeChanges(prevProps, this.props);

    if (changed('curDivisionId')) {
      if (curDivisionId > 0) {
        fetchTrfFromStoreProcess();
      }
    }
  }

  componentWillUnmount() {}

  render() {
    const { intl, match, process, appPath, processIsLoading } = this.props;

    return (
      <Card>
        <Spin spinning={processIsLoading}>
          <Tabs type="card" activeKey={match.params.proc_type}>
            {process.map(value => (
              <TabPane
                tab={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <Tooltip
                    placement="bottom"
                    title={intl.formatMessage({ id: `${value.proc_type.toLowerCase()}_desc` })}
                  >
                    <Link
                      style={value.proc_type !== match.params.proc_type ? { color: 'black' } : {}}
                      to={`${appPath}/trfFromStoreProcess/index/${value.proc_type}`}
                    >
                      <FormattedMessage id={`${value.proc_type.toLowerCase()}_label`} />
                    </Link>
                  </Tooltip>
                }
                key={value.proc_type}
              />
            ))}
          </Tabs>
        </Spin>

        {match.params.proc_type === 'INB_ORD_06' && <InbOrd06Table match={match} />}
        {match.params.proc_type === 'STK_ISS_SYNC_04' && <StkIssSync04Table match={match} />}
        {match.params.proc_type === 'STK_RCPT_SYNC_04' && <StkRcptSync04Table match={match} />}
        {match.params.proc_type === 'GDS_RCPT_F_SERV_02' && <GdsRcptFServ02Table match={match} />}
      </Card>
    );
  }
}

TrfFromStoreProcessScreen.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object,
  appPath: PropTypes.string,
  process: PropTypes.arrayOf(PropTypes.object),
  processIsLoading: PropTypes.bool,

  curDivisionId: PropTypes.number,
  fetchTrfFromStoreProcess: PropTypes.func
};

TrfFromStoreProcessScreen.defaultProps = {
  intl: {},
  match: {},
  appPath: '',
  process: [],
  processIsLoading: false,

  curDivisionId: 0,
  fetchTrfFromStoreProcess() {}
};

const mapStateToProps = state => ({
  appPath: state.app.appPath,
  process: state.trfFromStoreProcess.process,
  processIsLoading: state.trfFromStoreProcess.processIsLoading,

  curDivisionId: state.app.curDivisionId
});

const mapDispatchToProps = dispatch => ({
  fetchTrfFromStoreProcess: () =>
    dispatch(TrfFromStoreProcessActions.trfFromStoreProcessFetchTrfFromStoreProcess())
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(TrfFromStoreProcessScreen));
