/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { PutAwaySync01Types } from './Actions';
import INITIAL_STATE from './InitialState';

export const putAwaySync01ResetTimestamp = (state, { currentPage, sorts, filters, pageSize }) => ({
  ...state,
  currentPage: currentPage || state.currentPage,
  sorts: sorts || state.sorts,
  filters: filters || state.filters,
  pageSize: pageSize || state.pageSize,
  timestamp: Date.now()
  // selectedDocuments: INITIAL_STATE.selectedDocuments
});

export const putAwaySync01FetchPutAwaySync01Loading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const putAwaySync01FetchPutAwaySync01Success = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  documents,
  currentPage,
  lastPage,
  total,
  pageSize
});

export const putAwaySync01AddSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = state.selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: [
      ...state.selectedDocuments,
      ...selectedDocuments.filter(data => {
        return !selectIds.includes(data.id);
      })
    ]
  };
};

export const putAwaySync01RemoveSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: state.selectedDocuments.filter(data => {
      return !selectIds.includes(data.id);
    })
  };
};

export const putAwaySync01SetWorkspaceVisible = (state, { boolean }) => ({
  ...state,
  workspaceIsVisible: boolean
});

export const putAwaySync01SyncLoading = (state, { boolean }) => ({
  ...state,
  syncIsLoading: boolean
});

export const putAwaySync01SyncNowSuccess = state => ({
  ...state,
  timestamp: Date.now(),
  selectedDocuments: []
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [PutAwaySync01Types.PUT_AWAY_SYNC01_RESET_TIMESTAMP]: putAwaySync01ResetTimestamp,
  [PutAwaySync01Types.PUT_AWAY_SYNC01_FETCH_PUT_AWAY_SYNC01_LOADING]: putAwaySync01FetchPutAwaySync01Loading,
  [PutAwaySync01Types.PUT_AWAY_SYNC01_FETCH_PUT_AWAY_SYNC01_SUCCESS]: putAwaySync01FetchPutAwaySync01Success,
  [PutAwaySync01Types.PUT_AWAY_SYNC01_ADD_SELECTED_DOCUMENTS]: putAwaySync01AddSelectedDocuments,
  [PutAwaySync01Types.PUT_AWAY_SYNC01_REMOVE_SELECTED_DOCUMENTS]: putAwaySync01RemoveSelectedDocuments,
  [PutAwaySync01Types.PUT_AWAY_SYNC01_SET_WORKSPACE_VISIBLE]: putAwaySync01SetWorkspaceVisible,
  [PutAwaySync01Types.PUT_AWAY_SYNC01_SYNC_LOADING]: putAwaySync01SyncLoading,
  [PutAwaySync01Types.PUT_AWAY_SYNC01_SYNC_NOW_SUCCESS]: putAwaySync01SyncNowSuccess
});
