import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  itemManufactureProcessFetchItemProcess: null,
  itemManufactureProcessFetchItemProcessLoading: ['boolean'],
  itemManufactureProcessFetchItemProcessSuccess: ['process']
});

export const ItemManufactureProcessTypes = Types;
export default Creators;
