/**
 * The initial values for the redux state.
 */
export default {
  timestamp: 0,
  resType: '',
  resId: 0,
  auditType: '',
  auditTypes: [],
  auditTypeIsLoading: false,
  documents: [],
  fetchIsLoading: false,
  sorts: {
    id: 'descend'
  },
  filters: {},
  pageSize: '20',
  currentPage: 1,
  lastPage: 10,
  total: 0,

  expandedRows: []
};
