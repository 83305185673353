import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  pickListIndexResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  pickListIndexGoToPrint: ['document'],
  pickListIndexGoToDocument: ['hdrId'],
  pickListIndexGoToAudit: ['hdrId'],
  pickListIndexFetchPickListIndex: ['currentPage', 'sorts', 'filters', 'pageSize'],
  pickListIndexFetchPickListIndexLoading: ['boolean'],
  pickListIndexFetchPickListIndexSuccess: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],
  pickListIndexAddSelectedDocuments: ['selectedDocuments'],
  pickListIndexRemoveSelectedDocuments: ['selectedDocuments'],
  pickListIndexSetWorkspaceVisible: ['boolean'],

  pickListIndexExcelOutbOrdDtls: ['document']
});

export const PickListIndexTypes = Types;
export default Creators;
