import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { injectIntl } from 'react-intl';
import moment from 'moment';

import { Row, Col, Card, Select, Typography } from 'antd';
import WipSlsOrdTable from './WipSlsOrdTable';
import WipOutbOrdTable from './WipOutbOrdTable';
import WipInvDocTable from './WipInvDocTable';
import CompleteLoadListTable from './CompleteLoadListTable';
import WipInvDocDialog from './WipInvDocDialog';
import WipOutbOrdDialog from './WipOutbOrdDialog';
import WipSlsOrdDialog from './WipSlsOrdDialog';
import CompleteLoadListDialog from './CompleteLoadListDialog';

import SlsOrdDetailBoardActions from '../../Stores/SlsOrdDetailBoard/Actions';

const { Option } = Select;
const { Text } = Typography;

class SlsOrdDetailBoardScreen extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      intervalId: 0,
      // milisecond
      refreshInterval: 300000
    };

    this.handleInterval = this.handleInterval.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
  }

  componentDidMount() {
    const { refreshInterval } = this.state;

    if (refreshInterval > 0) {
      const intervalId = setInterval(this.handleInterval, refreshInterval);
      this.setState({
        intervalId
      });
    }
  }

  componentDidUpdate() {}

  componentWillUnmount() {
    const { intervalId } = this.state;

    clearInterval(intervalId);
  }

  handleInterval() {
    const {
      resetWipSlsOrdTimestamp,
      resetWipOutbOrdTimestamp,
      resetWipInvDocTimestamp,
      resetCompleteLoadListTimestamp
    } = this.props;

    resetWipSlsOrdTimestamp();
    resetWipOutbOrdTimestamp();
    resetWipInvDocTimestamp();
    resetCompleteLoadListTimestamp();
  }

  handleSelectChange(value) {
    let { intervalId } = this.state;

    this.setState({
      refreshInterval: value
    });

    clearInterval(intervalId);

    if (value > 0) {
      intervalId = setInterval(this.handleInterval, value);

      this.setState({
        intervalId
      });
    }
  }

  render() {
    const { intl, match, wipSlsOrdTimestamp } = this.props;
    const { refreshInterval } = this.state;

    return (
      <Card
        title={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <>
            <Text>{intl.formatMessage({ id: 'sales_order_kanban_board' })}</Text>
            <br />
            <small>
              {`${intl.formatMessage({ id: 'last_updated' })} ${moment(wipSlsOrdTimestamp).format(
                'YYYY-MM-DD HH:mm:ss'
              )}`}
            </small>
          </>
        }
        extra={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <Select value={refreshInterval} onChange={this.handleSelectChange} style={{ width: 75 }}>
            <Option value={0}>{intl.formatMessage({ id: 'off' })}</Option>
            <Option value={30000}>30s</Option>
            <Option value={60000}>1min</Option>
            <Option value={120000}>2min</Option>
            <Option value={300000}>5min</Option>
          </Select>
        }
      >
        <Row type="flex" justify="center" gutter={[16, 8]}>
          <Col span={6}>
            <WipSlsOrdTable match={match} />
            <WipSlsOrdDialog match={match} />
          </Col>
          <Col span={6}>
            <WipOutbOrdTable match={match} />
            <WipOutbOrdDialog match={match} />
          </Col>
          <Col span={6}>
            <WipInvDocTable match={match} />
            <WipInvDocDialog match={match} />
          </Col>
          <Col span={6}>
            <CompleteLoadListTable match={match} />
            <CompleteLoadListDialog match={match} />
          </Col>
        </Row>
      </Card>
    );
  }
}

SlsOrdDetailBoardScreen.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object,
  wipSlsOrdTimestamp: PropTypes.number,

  resetWipSlsOrdTimestamp: PropTypes.func,
  resetWipOutbOrdTimestamp: PropTypes.func,
  resetWipInvDocTimestamp: PropTypes.func,
  resetCompleteLoadListTimestamp: PropTypes.func
};

SlsOrdDetailBoardScreen.defaultProps = {
  intl: {},
  match: {},
  wipSlsOrdTimestamp: 0,

  resetWipSlsOrdTimestamp() {},
  resetWipOutbOrdTimestamp() {},
  resetWipInvDocTimestamp() {},
  resetCompleteLoadListTimestamp() {}
};

const mapStateToProps = state => ({
  wipSlsOrdTimestamp: state.slsOrdDetailBoard.wipSlsOrdTimestamp
});

const mapDispatchToProps = dispatch => ({
  resetWipSlsOrdTimestamp: (currentPage, sorts, filters, pageSize) =>
    dispatch(
      SlsOrdDetailBoardActions.slsOrdDetailBoardResetWipSlsOrdTimestamp(
        currentPage,
        sorts,
        filters,
        pageSize
      )
    ),
  resetWipOutbOrdTimestamp: (currentPage, sorts, filters, pageSize) =>
    dispatch(
      SlsOrdDetailBoardActions.slsOrdDetailBoardResetWipOutbOrdTimestamp(
        currentPage,
        sorts,
        filters,
        pageSize
      )
    ),
  resetWipInvDocTimestamp: (currentPage, sorts, filters, pageSize) =>
    dispatch(
      SlsOrdDetailBoardActions.slsOrdDetailBoardResetWipInvDocTimestamp(
        currentPage,
        sorts,
        filters,
        pageSize
      )
    ),
  resetCompleteLoadListTimestamp: (currentPage, sorts, filters, pageSize) =>
    dispatch(
      SlsOrdDetailBoardActions.slsOrdDetailBoardResetCompleteLoadListTimestamp(
        currentPage,
        sorts,
        filters,
        pageSize
      )
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SlsOrdDetailBoardScreen));
