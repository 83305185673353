// eslint-disable-next-line import/no-extraneous-dependencies
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import configureStore from './CreateStore';
// eslint-disable-next-line import/no-named-as-default
import rootSaga from '../Sagas';
import { reducer as AppReducer } from './App/Reducers';
import { reducer as SalesProcessReducer } from './SalesProcess/Reducers';
import { reducer as OutbOrd01Reducer } from './OutbOrd01/Reducers';
import { reducer as GdsDelProcessReducer } from './GdsDelProcess/Reducers';
import { reducer as RtnDelProcessReducer } from './RtnDelProcess/Reducers';
import { reducer as GdsRcptProcessReducer } from './GdsRcptProcess/Reducers';
import { reducer as GdsIss01Reducer } from './GdsIss01/Reducers';
import { reducer as GdsIss0101Reducer } from './GdsIss0101/Reducers';
import { reducer as GdsIssDetailReducer } from './GdsIssDetail/Reducers';
import { reducer as GdsIssIndexReducer } from './GdsIssIndex/Reducers';
import { reducer as GdsIssFServ01Reducer } from './GdsIssFServ01/Reducers';
import { reducer as GdsIssSync01Reducer } from './GdsIssSync01/Reducers';
import { reducer as GdsRcpt01Reducer } from './GdsRcpt01/Reducers';
import { reducer as GdsRcptFServ01Reducer } from './GdsRcptFServ01/Reducers';
import { reducer as GdsRcptFServ02Reducer } from './GdsRcptFServ02/Reducers';
import { reducer as GdsRcptFServ03Reducer } from './GdsRcptFServ03/Reducers';
import { reducer as GdsRcptSync01Reducer } from './GdsRcptSync01/Reducers';
import { reducer as PackListDetailReducer } from './PackListDetail/Reducers';
import { reducer as PackListIndexReducer } from './PackListIndex/Reducers';
import { reducer as PickList01Reducer } from './PickList01/Reducers';
import { reducer as PickListDetailReducer } from './PickListDetail/Reducers';
import { reducer as PickListIndexReducer } from './PickListIndex/Reducers';
import { reducer as WhseJob0301Reducer } from './WhseJob0301/Reducers';
import { reducer as WhseJob030102Reducer } from './WhseJob030102/Reducers';
import { reducer as AdvShipDetailReducer } from './AdvShipDetail/Reducers';
import { reducer as OutbOrdDetailReducer } from './OutbOrdDetail/Reducers';
import { reducer as InbOrdDetailReducer } from './InbOrdDetail/Reducers';
import { reducer as InvDoc01Reducer } from './InvDoc01/Reducers';
import { reducer as InvDoc0101Reducer } from './InvDoc0101/Reducers';
import { reducer as InvDoc0102Reducer } from './InvDoc0102/Reducers';
import { reducer as WhseJob1401Reducer } from './WhseJob1401/Reducers';
import { reducer as WhseJob1402Reducer } from './WhseJob1402/Reducers';
import { reducer as GdsRcptDetailReducer } from './GdsRcptDetail/Reducers';
import { reducer as WhseJob140101Reducer } from './WhseJob140101/Reducers';
import { reducer as WhseJob140201Reducer } from './WhseJob140201/Reducers';
import { reducer as PutAway01Reducer } from './PutAway01/Reducers';
import { reducer as PutAway02Reducer } from './PutAway02/Reducers';
import { reducer as PutAway03Reducer } from './PutAway03/Reducers';
import { reducer as WhseJob1501Reducer } from './WhseJob1501/Reducers';
import { reducer as WhseJob1502Reducer } from './WhseJob1502/Reducers';
import { reducer as WhseJob150101Reducer } from './WhseJob150101/Reducers';
import { reducer as WhseJob150201Reducer } from './WhseJob150201/Reducers';
import { reducer as RtnRcptProcessReducer } from './RtnRcptProcess/Reducers';
import { reducer as GdsRcpt02Reducer } from './GdsRcpt02/Reducers';
import { reducer as GdsRcpt0201Reducer } from './GdsRcpt0201/Reducers';
import { reducer as InvAuditProcessReducer } from './InvAuditProcess/Reducers';
import { reducer as CycleCount03Reducer } from './CycleCount03/Reducers';
import { reducer as CycleCountDetailReducer } from './CycleCountDetail/Reducers';
import { reducer as WhseJob1601Reducer } from './WhseJob1601/Reducers';
import { reducer as WhseJob160102Reducer } from './WhseJob160102/Reducers';
import { reducer as CycleCount02Reducer } from './CycleCount02/Reducers';
import { reducer as CycleCount02DetailReducer } from './CycleCount02Detail/Reducers';
import { reducer as CountAdj01Reducer } from './CountAdj01/Reducers';
import { reducer as CountAdjFServ01Reducer } from './CountAdjFServ01/Reducers';
import { reducer as CountAdjFServ02Reducer } from './CountAdjFServ02/Reducers';
import { reducer as CountAdjSync01Reducer } from './CountAdjSync01/Reducers';
import { reducer as CountAdjSync02Reducer } from './CountAdjSync02/Reducers';
import { reducer as StockBalanceReportReducer } from './StockBalanceReport/Reducers';
import { reducer as StockReorderPlanningReportReducer } from './StockReorderPlanningReport/Reducers';
import { reducer as WarehouseMapReducer } from './WarehouseMap/Reducers';
import { reducer as ItemBatchDetailReducer } from './ItemBatchDetail/Reducers';
import { reducer as ItemBatchExcel01Reducer } from './ItemBatchExcel01/Reducers';
import { reducer as ItemProcessReducer } from './ItemProcess/Reducers';
import { reducer as ItemManufactureProcessReducer } from './ItemManufactureProcess/Reducers';
import { reducer as ManufactureItemListReducer } from './ManufactureItemList/Reducers';
import { reducer as UomListReducer } from './UomList/Reducers';
import { reducer as UomDetailReducer } from './UomDetail/Reducers';
import { reducer as LayerFarmProcessReducer } from './LayerFarmProcess/Reducers';
import { reducer as LayerFarmListReducer } from './LayerFarmList/Reducers';
import { reducer as LayerFarmExcelReducer } from './LayerFarmExcel/Reducers';
import { reducer as ItemList01Reducer } from './ItemList01/Reducers';
import { reducer as ItemList02Reducer } from './ItemList02/Reducers';
import { reducer as ItemExcel01Reducer } from './ItemExcel01/Reducers';
import { reducer as ItemManufactureExcelReducer } from './ItemManufactureExcel/Reducers';
import { reducer as ItemSync01Reducer } from './ItemSync01/Reducers';
import { reducer as ItemSync0101Reducer } from './ItemSync0101/Reducers';
import { reducer as StorageBinProcessReducer } from './StorageBinProcess/Reducers';
import { reducer as StorageBinList01Reducer } from './StorageBinList01/Reducers';
import { reducer as StorageBinExcel01Reducer } from './StorageBinExcel01/Reducers';
import { reducer as PalletLabelProcessReducer } from './PalletLabelProcess/Reducers';
import { reducer as PalletLabelList01Reducer } from './PalletLabelList01/Reducers';
import { reducer as PickFaceStrategyProcessReducer } from './PickFaceStrategyProcess/Reducers';
import { reducer as PickFaceStrategyList01Reducer } from './PickFaceStrategyList01/Reducers';
import { reducer as PickFaceStrategyExcel01Reducer } from './PickFaceStrategyExcel01/Reducers';
import { reducer as PickingCriteriaProcessReducer } from './PickingCriteriaProcess/Reducers';
import { reducer as PickingCriteriaList01Reducer } from './PickingCriteriaList01/Reducers';
import { reducer as PickingCriteriaExcel01Reducer } from './PickingCriteriaExcel01/Reducers';
import { reducer as UserProcessReducer } from './UserProcess/Reducers';
import { reducer as UserList01Reducer } from './UserList01/Reducers';
import { reducer as UserExcel01Reducer } from './UserExcel01/Reducers';
import { reducer as RoleProcessReducer } from './RoleProcess/Reducers';
import { reducer as RoleList01Reducer } from './RoleList01/Reducers';
import { reducer as RoleExcel01Reducer } from './RoleExcel01/Reducers';
import { reducer as SlsRtnProcessReducer } from './SlsRtnProcess/Reducers';
import { reducer as InbOrd02Reducer } from './InbOrd02/Reducers';
import { reducer as OutbOrdAnalysisReportReducer } from './OutbOrdAnalysisReport/Reducers';
import { reducer as OutOfStockAnalysisReportReducer } from './OutOfStockAnalysisReport/Reducers';
import { reducer as PurchaseProcessReducer } from './PurchaseProcess/Reducers';
import { reducer as PurchaseReturnProcessReducer } from './PurchaseReturnProcess/Reducers';
import { reducer as InbOrd01Reducer } from './InbOrd01/Reducers';
import { reducer as WhseJob03DetailReducer } from './WhseJob03Detail/Reducers';
import { reducer as WhseJob03IndexReducer } from './WhseJob03Index/Reducers';
import { reducer as WhseJob06IndexReducer } from './WhseJob06Index/Reducers';
import { reducer as WhseJob1401IndexReducer } from './WhseJob1401Index/Reducers';
import { reducer as WhseJob1402IndexReducer } from './WhseJob1402Index/Reducers';
import { reducer as WhseJob1501IndexReducer } from './WhseJob1501Index/Reducers';
import { reducer as WhseJob1502IndexReducer } from './WhseJob1502Index/Reducers';
import { reducer as WhseJob16IndexReducer } from './WhseJob16Index/Reducers';
import { reducer as WhseJob17IndexReducer } from './WhseJob17Index/Reducers';
import { reducer as WhseJob14DetailReducer } from './WhseJob14Detail/Reducers';
import { reducer as PutAwayIndexReducer } from './PutAwayIndex/Reducers';
import { reducer as PutAwayRtnIndexReducer } from './PutAwayRtnIndex/Reducers';
import { reducer as PutAwayDetailReducer } from './PutAwayDetail/Reducers';
import { reducer as PutAwayDetailBoardReducer } from './PutAwayDetailBoard/Reducers';
import { reducer as PutAwayRtnDetailBoardReducer } from './PutAwayRtnDetailBoard/Reducers';
import { reducer as PutAwayFServ01Reducer } from './PutAwayFServ01/Reducers';
import { reducer as WhseJob15DetailReducer } from './WhseJob15Detail/Reducers';
import { reducer as AdvShipIndexReducer } from './AdvShipIndex/Reducers';
import { reducer as SlsOrdIndexReducer } from './SlsOrdIndex/Reducers';
import { reducer as SlsOrdDetailReducer } from './SlsOrdDetail/Reducers';
import { reducer as SlsInvIndexReducer } from './SlsInvIndex/Reducers';
import { reducer as SlsInvDetailReducer } from './SlsInvDetail/Reducers';
import { reducer as SlsRtnIndexReducer } from './SlsRtnIndex/Reducers';
import { reducer as SlsRtnDetailReducer } from './SlsRtnDetail/Reducers';
import { reducer as RtnRcptIndexReducer } from './RtnRcptIndex/Reducers';
import { reducer as RtnRcptDetailReducer } from './RtnRcptDetail/Reducers';
import { reducer as OutbOrdIndexReducer } from './OutbOrdIndex/Reducers';
import { reducer as InbOrdIndexReducer } from './InbOrdIndex/Reducers';
import { reducer as GdsRcptIndexReducer } from './GdsRcptIndex/Reducers';
import { reducer as GdsRcptRtnIndexReducer } from './GdsRcptRtnIndex/Reducers';
import { reducer as CycleCountIndexReducer } from './CycleCountIndex/Reducers';
import { reducer as CountAdjIndexReducer } from './CountAdjIndex/Reducers';
import { reducer as CountAdjDetailReducer } from './CountAdjDetail/Reducers';
import { reducer as BinTrfIndexReducer } from './BinTrfIndex/Reducers';
import { reducer as BinTrfDetailReducer } from './BinTrfDetail/Reducers';
import { reducer as BinTrfProcessReducer } from './BinTrfProcess/Reducers';
import { reducer as BinTrf01Reducer } from './BinTrf01/Reducers';
import { reducer as BinTrf02Reducer } from './BinTrf02/Reducers';
import { reducer as WhseJob1702Reducer } from './WhseJob1702/Reducers';
import { reducer as WhseJob170201Reducer } from './WhseJob170201/Reducers';
import { reducer as WhseJob17DetailReducer } from './WhseJob17Detail/Reducers';
import { reducer as SlsOrdSync01Reducer } from './SlsOrdSync01/Reducers';
import { reducer as SlsInvSync01Reducer } from './SlsInvSync01/Reducers';
import { reducer as SlsRtnSync01Reducer } from './SlsRtnSync01/Reducers';
import { reducer as RtnRcptSync01Reducer } from './RtnRcptSync01/Reducers';
import { reducer as AdvShipSync01Reducer } from './AdvShipSync01/Reducers';
import { reducer as AdvShipSync02Reducer } from './AdvShipSync02/Reducers';
import { reducer as AdvShipFServ02Reducer } from './AdvShipFServ02/Reducers';
import { reducer as StockCardReportReducer } from './StockCardReport/Reducers';
import { reducer as StockAgingReportReducer } from './StockAgingReport/Reducers';
import { reducer as PickListReservedStockReportReducer } from './PickListReservedStockReport/Reducers';
import { reducer as PickingAllocErrorReportReducer } from './PickingAllocErrorReport/Reducers';
import { reducer as CycleCountAnalysisReportReducer } from './CycleCountAnalysisReport/Reducers';
import { reducer as CountAdjAnalysisReportReducer } from './CountAdjAnalysisReport/Reducers';
import { reducer as DeliveryPointProcessReducer } from './DeliveryPointProcess/Reducers';
import { reducer as DeliveryPointList01Reducer } from './DeliveryPointList01/Reducers';
import { reducer as AuditRes01Reducer } from './AuditRes01/Reducers';
import { reducer as AuditUser01Reducer } from './AuditUser01/Reducers';
import { reducer as WhseJob16DetailReducer } from './WhseJob16Detail/Reducers';
import { reducer as LspSlsOrdExcel01Reducer } from './LspSlsOrdExcel01/Reducers';
import { reducer as LspDeliveryProcessReducer } from './LspDeliveryProcess/Reducers';
import { reducer as LspReceivingProcessReducer } from './LspReceivingProcess/Reducers';
import { reducer as LspCustomerReturnProcessReducer } from './LspCustomerReturnProcess/Reducers';
import { reducer as LspSupplierReturnProcessReducer } from './LspSupplierReturnProcess/Reducers';
import { reducer as OutbOrd02Reducer } from './OutbOrd02/Reducers';
import { reducer as LspSlsOrdDetailReducer } from './LspSlsOrdDetail/Reducers';
import { reducer as InvDoc02Reducer } from './InvDoc02/Reducers';
import { reducer as InbOrd03Reducer } from './InbOrd03/Reducers';
import { reducer as LspAdvShipDetailReducer } from './LspAdvShipDetail/Reducers';
import { reducer as InbOrd04Reducer } from './InbOrd04/Reducers';
import { reducer as LspSlsRtnDetailReducer } from './LspSlsRtnDetail/Reducers';
import { reducer as LspAdvShipExcel01Reducer } from './LspAdvShipExcel01/Reducers';
import { reducer as LspSlsRtnExcel01Reducer } from './LspSlsRtnExcel01/Reducers';
import { reducer as LspDelOrdDetailReducer } from './LspDelOrdDetail/Reducers';
import { reducer as LspDelOrdIndexReducer } from './LspDelOrdIndex/Reducers';
import { reducer as LspSlsOrdIndexReducer } from './LspSlsOrdIndex/Reducers';
import { reducer as LspSlsOrdTrackingReportReducer } from './LspSlsOrdTrackingReport/Reducers';
import { reducer as LspSlsRtnIndexReducer } from './LspSlsRtnIndex/Reducers';
import { reducer as LspAdvShipIndexReducer } from './LspAdvShipIndex/Reducers';
import { reducer as LocationList01Reducer } from './LocationList01/Reducers';
import { reducer as LocationDetailReducer } from './LocationDetail/Reducers';
import { reducer as LoadList01Reducer } from './LoadList01/Reducers';
import { reducer as LoadListIndexReducer } from './LoadListIndex/Reducers';
import { reducer as LoadListDetailReducer } from './LoadListDetail/Reducers';
import { reducer as LoadList0101Reducer } from './LoadList0101/Reducers';
import { reducer as LoadListSync01Reducer } from './LoadListSync01/Reducers';
import { reducer as PrfDel01Reducer } from './PrfDel01/Reducers';
import { reducer as PrfDelIndexReducer } from './PrfDelIndex/Reducers';
import { reducer as PrfDelDetailReducer } from './PrfDelDetail/Reducers';
import { reducer as PrfDelSync01Reducer } from './PrfDelSync01/Reducers';
import { reducer as FailDel01Reducer } from './FailDel01/Reducers';
import { reducer as GdsRcpt0101Reducer } from './GdsRcpt0101/Reducers';
import { reducer as PrincipalProcessReducer } from './PrincipalProcess/Reducers';
import { reducer as PrincipalList01Reducer } from './PrincipalList01/Reducers';
import { reducer as PrincipalDetailReducer } from './PrincipalDetail/Reducers';
import { reducer as TransportProcessReducer } from './TransportProcess/Reducers';
import { reducer as TransportList01Reducer } from './TransportList01/Reducers';
import { reducer as TransportDetailReducer } from './TransportDetail/Reducers';
import { reducer as UserDetailReducer } from './UserDetail/Reducers';
import { reducer as SlsOrdTrackingReportReducer } from './SlsOrdTrackingReport/Reducers';
import { reducer as ShipmentTrackingReportReducer } from './ShipmentTrackingReport/Reducers';
import { reducer as TransferInTrackingReportReducer } from './TransferInTrackingReport/Reducers';
import { reducer as TransferOutTrackingReportReducer } from './TransferOutTrackingReport/Reducers';
import { reducer as DeliveryPointDetailReducer } from './DeliveryPointDetail/Reducers';
import { reducer as BizPartnerDetailReducer } from './BizPartnerDetail/Reducers';
import { reducer as PickList0101Reducer } from './PickList0101/Reducers';
import { reducer as ItemDetailReducer } from './ItemDetail/Reducers';
import { reducer as DelOrdIndexReducer } from './DelOrdIndex/Reducers';
import { reducer as DelOrdDetailReducer } from './DelOrdDetail/Reducers';
import { reducer as DelOrdFServ01Reducer } from './DelOrdFServ01/Reducers';
import { reducer as DelOrdSync01Reducer } from './DelOrdSync01/Reducers';
import { reducer as GdsRcptTrackingReportReducer } from './GdsRcptTrackingReport/Reducers';
import { reducer as StorageBinDetailReducer } from './StorageBinDetail/Reducers';
import { reducer as StorageRowList01Reducer } from './StorageRowList01/Reducers';
import { reducer as StorageRowDetailReducer } from './StorageRowDetail/Reducers';
import { reducer as StorageBayList01Reducer } from './StorageBayList01/Reducers';
import { reducer as StorageBayDetailReducer } from './StorageBayDetail/Reducers';
import { reducer as StorageTypeDetailReducer } from './StorageTypeDetail/Reducers';
import { reducer as StorageTypeList01Reducer } from './StorageTypeList01/Reducers';
import { reducer as DebtorList01Reducer } from './DebtorList01/Reducers';
import { reducer as DebtorProcessReducer } from './DebtorProcess/Reducers';
import { reducer as DebtorDetailReducer } from './DebtorDetail/Reducers';
import { reducer as CreditorList01Reducer } from './CreditorList01/Reducers';
import { reducer as CreditorProcessReducer } from './CreditorProcess/Reducers';
import { reducer as CreditorDetailReducer } from './CreditorDetail/Reducers';
import { reducer as OutbOrd04Reducer } from './OutbOrd04/Reducers';
import { reducer as ProductionProcessReducer } from './ProductionProcess/Reducers';
import { reducer as ProdOrdExcel01Reducer } from './ProdOrdExcel01/Reducers';
import { reducer as ProdOrdDetailReducer } from './ProdOrdDetail/Reducers';
import { reducer as ProdOrdIndexReducer } from './ProdOrdIndex/Reducers';
import { reducer as ProdSheet01Reducer } from './ProdSheet01/Reducers';
import { reducer as ProdIssDetailReducer } from './ProdIssDetail/Reducers';
import { reducer as ProdIssIndexReducer } from './ProdIssIndex/Reducers';
import { reducer as ProdRcpt01Reducer } from './ProdRcpt01/Reducers';
import { reducer as ProdSheetDetailReducer } from './ProdSheetDetail/Reducers';
import { reducer as ProdSheetIndexReducer } from './ProdSheetIndex/Reducers';
import { reducer as BinTrf03Reducer } from './BinTrf03/Reducers';
import { reducer as ProdRcptDetailReducer } from './ProdRcptDetail/Reducers';
import { reducer as WhseJob0601Reducer } from './WhseJob0601/Reducers';
import { reducer as WhseJob060101Reducer } from './WhseJob060101/Reducers';
import { reducer as WhseJob0602Reducer } from './WhseJob0602/Reducers';
import { reducer as WhseJob060201Reducer } from './WhseJob060201/Reducers';
import { reducer as WhseJob06DetailReducer } from './WhseJob06Detail/Reducers';
import { reducer as SiteList01Reducer } from './SiteList01/Reducers';
import { reducer as SiteProcessReducer } from './SiteProcess/Reducers';
import { reducer as SiteDetailReducer } from './SiteDetail/Reducers';
import { reducer as DocTypeDocNoIndexReducer } from './DocTypeDocNoIndex/Reducers';
import { reducer as SiteFlowList01Reducer } from './SiteFlowList01/Reducers';
import { reducer as SiteFlowDetailReducer } from './SiteFlowDetail/Reducers';
import { reducer as DivisionProcessReducer } from './DivisionProcess/Reducers';
import { reducer as DivisionList01Reducer } from './DivisionList01/Reducers';
import { reducer as DivisionDetailReducer } from './DivisionDetail/Reducers';
import { reducer as AdvShipExcel01Reducer } from './AdvShipExcel01/Reducers';
import { reducer as AdvShipFServ01Reducer } from './AdvShipFServ01/Reducers';
import { reducer as SlsOrdExcel01Reducer } from './SlsOrdExcel01/Reducers';
import { reducer as SlsOrdFServ01Reducer } from './SlsOrdFServ01/Reducers';
import { reducer as SlsOrdFServ02Reducer } from './SlsOrdFServ02/Reducers';
import { reducer as SlsRtnFServ02Reducer } from './SlsRtnFServ02/Reducers';
import { reducer as ProdOrdFServ01Reducer } from './ProdOrdFServ01/Reducers';
import { reducer as ProdOrdFServ02Reducer } from './ProdOrdFServ02/Reducers';
import { reducer as ProdIssFServ01Reducer } from './ProdIssFServ01/Reducers';
import { reducer as ProdSheetFServ01Reducer } from './ProdSheetFServ01/Reducers';

import { reducer as CompanyProcessReducer } from './CompanyProcess/Reducers';
import { reducer as CompanyList01Reducer } from './CompanyList01/Reducers';
import { reducer as CompanyDetailReducer } from './CompanyDetail/Reducers';
import { reducer as AreaProcessReducer } from './AreaProcess/Reducers';
import { reducer as AreaList01Reducer } from './AreaList01/Reducers';
import { reducer as AreaDetailReducer } from './AreaDetail/Reducers';
import { reducer as CountAdjReasonProcessReducer } from './CountAdjReasonProcess/Reducers';
import { reducer as CountAdjReasonList01Reducer } from './CountAdjReasonList01/Reducers';
import { reducer as CountAdjReasonDetailReducer } from './CountAdjReasonDetail/Reducers';
import { reducer as OutboundExpiryReportReducer } from './OutboundExpiryReport/Reducers';
import { reducer as PickListDetailBoardReducer } from './PickListDetailBoard/Reducers';
import { reducer as LoadListDetailBoardReducer } from './LoadListDetailBoard/Reducers';
import { reducer as LspSlsOrdDetailBoardReducer } from './LspSlsOrdDetailBoard/Reducers';
import { reducer as SlsOrdDetailBoardReducer } from './SlsOrdDetailBoard/Reducers';
import { reducer as GdsRcptDetailBoardReducer } from './GdsRcptDetailBoard/Reducers';
import { reducer as GdsRcptDetailReportReducer } from './GdsRcptDetailReport/Reducers';
import { reducer as GdsRtnDetailReportReducer } from './GdsRtnDetailReport/Reducers';
import { reducer as GdsRcptRtnDetailBoardReducer } from './GdsRcptRtnDetailBoard/Reducers';
import { reducer as SummaryBoardReducer } from './SummaryBoard/Reducers';

import { reducer as PutAwaySync01Reducer } from './PutAwaySync01/Reducers';
import { reducer as SiteTrfProcessReducer } from './SiteTrfProcess/Reducers';
import { reducer as OutbOrd05Reducer } from './OutbOrd05/Reducers';
import { reducer as OutbOrd06Reducer } from './OutbOrd06/Reducers';
import { reducer as OutbOrd07Reducer } from './OutbOrd07/Reducers';
import { reducer as TrfOrdDetailReducer } from './TrfOrdDetail/Reducers';
import { reducer as InbOrd05Reducer } from './InbOrd05/Reducers';
import { reducer as StkIssDetailReducer } from './StkIssDetail/Reducers';
import { reducer as InvDoc03Reducer } from './InvDoc03/Reducers';
import { reducer as TrfOrdIndexReducer } from './TrfOrdIndex/Reducers';
import { reducer as StkIssIndexReducer } from './StkIssIndex/Reducers';
import { reducer as StkRcptIndexReducer } from './StkRcptIndex/Reducers';
import { reducer as StkRcptDetailReducer } from './StkRcptDetail/Reducers';
import { reducer as StkIssSync01Reducer } from './StkIssSync01/Reducers';
import { reducer as StkIssFServ01Reducer } from './StkIssFServ01/Reducers';
import { reducer as StkRcptSync01Reducer } from './StkRcptSync01/Reducers';
import { reducer as StkRcptSync02Reducer } from './StkRcptSync02/Reducers';
import { reducer as StkRcptSync05Reducer } from './StkRcptSync05/Reducers';
import { reducer as TrfToVanProcessReducer } from './TrfToVanProcess/Reducers';
import { reducer as StkRcpt02Reducer } from './StkRcpt02/Reducers';
import { reducer as StkRcpt03Reducer } from './StkRcpt03/Reducers';
import { reducer as StkTrfDetailReducer } from './StkTrfDetail/Reducers';
import { reducer as StkTrfIndexReducer } from './StkTrfIndex/Reducers';
import { reducer as StkTrfSync01Reducer } from './StkTrfSync01/Reducers';
import { reducer as InbOrd07Reducer } from './InbOrd07/Reducers';
import { reducer as TrfFromVanProcessReducer } from './TrfFromVanProcess/Reducers';
import { reducer as StkIssSync05Reducer } from './StkIssSync05/Reducers';
import { reducer as TrfToStoreProcessReducer } from './TrfToStoreProcess/Reducers';
import { reducer as StkIssSync02Reducer } from './StkIssSync02/Reducers';
import { reducer as TrfFromStoreProcessReducer } from './TrfFromStoreProcess/Reducers';
import { reducer as InbOrd06Reducer } from './InbOrd06/Reducers';
import { reducer as StkIssSync04Reducer } from './StkIssSync04/Reducers';
import { reducer as StkRcptSync04Reducer } from './StkRcptSync04/Reducers';
import { reducer as ProjectList01Reducer } from './ProjectList01/Reducers';
import { reducer as ProjectDetailReducer } from './ProjectDetail/Reducers';
import { reducer as OutbOrdReservedStockReportReducer } from './OutbOrdReservedStockReport/Reducers';
import { reducer as DailyStockMovementReportReducer } from './DailyStockMovementReport/Reducers';
import { reducer as PurRtnDetailReducer } from './PurRtnDetail/Reducers';
import { reducer as PurRtnIndexReducer } from './PurRtnIndex/Reducers';
import { reducer as OutbOrd08Reducer } from './OutbOrd08/Reducers';
import { reducer as PurRtnFServ02Reducer } from './PurRtnFServ02/Reducers';
import { reducer as PurRtnSync01Reducer } from './PurRtnSync01/Reducers';
import { reducer as LspSlsOrdFulfillmentChartReducer } from './LspSlsOrdFulfillmentChart/Reducers';
import { reducer as SlsOrdFulfillmentChartReducer } from './SlsOrdFulfillmentChart/Reducers';
import { reducer as WhsePerformanceChartReducer } from './WhsePerformanceChart/Reducers';
import { reducer as WorkerPerformanceChartReducer } from './WorkerPerformanceChart/Reducers';
import { reducer as WorkerGdsRcptAnalysisReportReducer } from './WorkerGdsRcptAnalysisReport/Reducers';
import { reducer as WorkerPutAwayAnalysisReportReducer } from './WorkerPutAwayAnalysisReport/Reducers';
import { reducer as WorkerLoadListAnalysisReportReducer } from './WorkerLoadListAnalysisReport/Reducers';
import { reducer as WorkerPickListAnalysisReportReducer } from './WorkerPickListAnalysisReport/Reducers';
import { reducer as WorkerPackListAnalysisReportReducer } from './WorkerPackListAnalysisReport/Reducers';
import { reducer as StockReorderPlanningV2ReportReducer } from './StockReorderPlanningV2Report/Reducers';
import { reducer as AppSettingReducer } from './AppSetting/Reducers';

export default () => {
  const rootReducer = history =>
    combineReducers({
      router: connectRouter(history),
      /**
       * Register your reducers here.
       * @see https://redux.js.org/api-reference/combinereducers
       */
      app: AppReducer,
      salesProcess: SalesProcessReducer,
      outbOrd01: OutbOrd01Reducer,
      gdsDelProcess: GdsDelProcessReducer,
      rtnDelProcess: RtnDelProcessReducer,
      gdsRcptProcess: GdsRcptProcessReducer,
      gdsIss01: GdsIss01Reducer,
      gdsIss0101: GdsIss0101Reducer,
      gdsIssDetail: GdsIssDetailReducer,
      gdsIssIndex: GdsIssIndexReducer,
      gdsIssFServ01: GdsIssFServ01Reducer,
      gdsIssSync01: GdsIssSync01Reducer,
      gdsRcpt01: GdsRcpt01Reducer,
      gdsRcptFServ01: GdsRcptFServ01Reducer,
      gdsRcptFServ02: GdsRcptFServ02Reducer,
      gdsRcptFServ03: GdsRcptFServ03Reducer,
      gdsRcptSync01: GdsRcptSync01Reducer,
      packListDetail: PackListDetailReducer,
      packListIndex: PackListIndexReducer,
      pickList01: PickList01Reducer,
      pickListDetail: PickListDetailReducer,
      pickListIndex: PickListIndexReducer,
      whseJob0301: WhseJob0301Reducer,
      whseJob030102: WhseJob030102Reducer,
      advShipDetail: AdvShipDetailReducer,
      outbOrdDetail: OutbOrdDetailReducer,
      inbOrdDetail: InbOrdDetailReducer,
      invDoc01: InvDoc01Reducer,
      invDoc0101: InvDoc0101Reducer,
      invDoc0102: InvDoc0102Reducer,
      whseJob1401: WhseJob1401Reducer,
      whseJob1402: WhseJob1402Reducer,
      gdsRcptDetail: GdsRcptDetailReducer,
      whseJob140101: WhseJob140101Reducer,
      whseJob140201: WhseJob140201Reducer,
      putAway01: PutAway01Reducer,
      putAway02: PutAway02Reducer,
      putAway03: PutAway03Reducer,
      whseJob1501: WhseJob1501Reducer,
      whseJob1502: WhseJob1502Reducer,
      whseJob150101: WhseJob150101Reducer,
      whseJob150201: WhseJob150201Reducer,
      rtnRcptProcess: RtnRcptProcessReducer,
      gdsRcpt02: GdsRcpt02Reducer,
      gdsRcpt0201: GdsRcpt0201Reducer,
      invAuditProcess: InvAuditProcessReducer,
      cycleCount03: CycleCount03Reducer,
      cycleCountDetail: CycleCountDetailReducer,
      whseJob1601: WhseJob1601Reducer,
      whseJob160102: WhseJob160102Reducer,
      cycleCount02: CycleCount02Reducer,
      cycleCount02Detail: CycleCount02DetailReducer,
      countAdj01: CountAdj01Reducer,
      countAdjFServ01: CountAdjFServ01Reducer,
      countAdjFServ02: CountAdjFServ02Reducer,
      countAdjSync01: CountAdjSync01Reducer,
      countAdjSync02: CountAdjSync02Reducer,
      stockBalanceReport: StockBalanceReportReducer,
      stockReorderPlanningReport: StockReorderPlanningReportReducer,
      warehouseMap: WarehouseMapReducer,
      itemBatchDetail: ItemBatchDetailReducer,
      itemBatchExcel01: ItemBatchExcel01Reducer,
      itemProcess: ItemProcessReducer,
      itemManufactureProcess: ItemManufactureProcessReducer,
      manufactureItemList: ManufactureItemListReducer,
      uomList: UomListReducer,
      uomDetail: UomDetailReducer,
      layerFarmProcess: LayerFarmProcessReducer,
      layerFarmList: LayerFarmListReducer,
      layerFarmExcel: LayerFarmExcelReducer,
      itemList01: ItemList01Reducer,
      itemList02: ItemList02Reducer,
      itemExcel01: ItemExcel01Reducer,
      itemManufactureExcel: ItemManufactureExcelReducer,
      itemSync01: ItemSync01Reducer,
      itemSync0101: ItemSync0101Reducer,
      storageBinProcess: StorageBinProcessReducer,
      storageBinList01: StorageBinList01Reducer,
      storageBinExcel01: StorageBinExcel01Reducer,
      palletLabelProcess: PalletLabelProcessReducer,
      palletLabelList01: PalletLabelList01Reducer,
      pickFaceStrategyProcess: PickFaceStrategyProcessReducer,
      pickFaceStrategyList01: PickFaceStrategyList01Reducer,
      pickFaceStrategyExcel01: PickFaceStrategyExcel01Reducer,
      pickingCriteriaProcess: PickingCriteriaProcessReducer,
      pickingCriteriaList01: PickingCriteriaList01Reducer,
      pickingCriteriaExcel01: PickingCriteriaExcel01Reducer,
      userProcess: UserProcessReducer,
      userList01: UserList01Reducer,
      userExcel01: UserExcel01Reducer,
      roleProcess: RoleProcessReducer,
      roleList01: RoleList01Reducer,
      roleExcel01: RoleExcel01Reducer,
      slsRtnProcess: SlsRtnProcessReducer,
      inbOrd02: InbOrd02Reducer,
      outbOrdAnalysisReport: OutbOrdAnalysisReportReducer,
      outOfStockAnalysisReport: OutOfStockAnalysisReportReducer,
      purchaseProcess: PurchaseProcessReducer,
      purchaseReturnProcess: PurchaseReturnProcessReducer,
      inbOrd01: InbOrd01Reducer,
      whseJob03Detail: WhseJob03DetailReducer,
      whseJob03Index: WhseJob03IndexReducer,
      whseJob06Index: WhseJob06IndexReducer,
      whseJob1401Index: WhseJob1401IndexReducer,
      whseJob1402Index: WhseJob1402IndexReducer,
      whseJob1501Index: WhseJob1501IndexReducer,
      whseJob1502Index: WhseJob1502IndexReducer,
      whseJob16Index: WhseJob16IndexReducer,
      whseJob17Index: WhseJob17IndexReducer,
      whseJob14Detail: WhseJob14DetailReducer,
      putAwayIndex: PutAwayIndexReducer,
      putAwayRtnIndex: PutAwayRtnIndexReducer,
      putAwayDetail: PutAwayDetailReducer,
      putAwayDetailBoard: PutAwayDetailBoardReducer,
      putAwayRtnDetailBoard: PutAwayRtnDetailBoardReducer,
      putAwayFServ01: PutAwayFServ01Reducer,
      whseJob15Detail: WhseJob15DetailReducer,
      advShipIndex: AdvShipIndexReducer,
      slsOrdIndex: SlsOrdIndexReducer,
      slsOrdDetail: SlsOrdDetailReducer,
      slsInvIndex: SlsInvIndexReducer,
      slsInvDetail: SlsInvDetailReducer,
      slsRtnIndex: SlsRtnIndexReducer,
      slsRtnDetail: SlsRtnDetailReducer,
      rtnRcptIndex: RtnRcptIndexReducer,
      rtnRcptDetail: RtnRcptDetailReducer,
      outbOrdIndex: OutbOrdIndexReducer,
      inbOrdIndex: InbOrdIndexReducer,
      gdsRcptIndex: GdsRcptIndexReducer,
      gdsRcptRtnIndex: GdsRcptRtnIndexReducer,
      cycleCountIndex: CycleCountIndexReducer,
      countAdjIndex: CountAdjIndexReducer,
      countAdjDetail: CountAdjDetailReducer,
      binTrfIndex: BinTrfIndexReducer,
      binTrfDetail: BinTrfDetailReducer,
      binTrfProcess: BinTrfProcessReducer,
      binTrf01: BinTrf01Reducer,
      binTrf02: BinTrf02Reducer,
      whseJob1702: WhseJob1702Reducer,
      whseJob170201: WhseJob170201Reducer,
      whseJob17Detail: WhseJob17DetailReducer,
      slsOrdSync01: SlsOrdSync01Reducer,
      slsInvSync01: SlsInvSync01Reducer,
      slsRtnSync01: SlsRtnSync01Reducer,
      rtnRcptSync01: RtnRcptSync01Reducer,
      advShipSync01: AdvShipSync01Reducer,
      advShipSync02: AdvShipSync02Reducer,
      stockCardReport: StockCardReportReducer,
      stockAgingReport: StockAgingReportReducer,
      pickListReservedStockReport: PickListReservedStockReportReducer,
      pickingAllocErrorReport: PickingAllocErrorReportReducer,
      cycleCountAnalysisReport: CycleCountAnalysisReportReducer,
      countAdjAnalysisReport: CountAdjAnalysisReportReducer,
      deliveryPointProcess: DeliveryPointProcessReducer,
      deliveryPointList01: DeliveryPointList01Reducer,
      auditRes01: AuditRes01Reducer,
      auditUser01: AuditUser01Reducer,
      whseJob16Detail: WhseJob16DetailReducer,
      lspSlsOrdExcel01: LspSlsOrdExcel01Reducer,
      lspDeliveryProcess: LspDeliveryProcessReducer,
      lspReceivingProcess: LspReceivingProcessReducer,
      lspCustomerReturnProcess: LspCustomerReturnProcessReducer,
      lspSupplierReturnProcess: LspSupplierReturnProcessReducer,
      outbOrd02: OutbOrd02Reducer,
      lspSlsOrdDetail: LspSlsOrdDetailReducer,
      invDoc02: InvDoc02Reducer,
      inbOrd03: InbOrd03Reducer,
      lspAdvShipDetail: LspAdvShipDetailReducer,
      inbOrd04: InbOrd04Reducer,
      lspSlsRtnDetail: LspSlsRtnDetailReducer,
      lspAdvShipExcel01: LspAdvShipExcel01Reducer,
      lspSlsRtnExcel01: LspSlsRtnExcel01Reducer,
      lspDelOrdDetail: LspDelOrdDetailReducer,
      lspDelOrdIndex: LspDelOrdIndexReducer,
      lspSlsOrdIndex: LspSlsOrdIndexReducer,
      lspSlsOrdTrackingReport: LspSlsOrdTrackingReportReducer,
      lspSlsRtnIndex: LspSlsRtnIndexReducer,
      lspAdvShipIndex: LspAdvShipIndexReducer,
      locationList01: LocationList01Reducer,
      locationDetail: LocationDetailReducer,
      loadList01: LoadList01Reducer,
      loadListIndex: LoadListIndexReducer,
      loadListDetail: LoadListDetailReducer,
      loadList0101: LoadList0101Reducer,
      loadListSync01: LoadListSync01Reducer,
      prfDel01: PrfDel01Reducer,
      prfDelIndex: PrfDelIndexReducer,
      prfDelDetail: PrfDelDetailReducer,
      prfDelSync01: PrfDelSync01Reducer,
      failDel01: FailDel01Reducer,
      gdsRcpt0101: GdsRcpt0101Reducer,
      principalProcess: PrincipalProcessReducer,
      principalList01: PrincipalList01Reducer,
      principalDetail: PrincipalDetailReducer,
      transportProcess: TransportProcessReducer,
      transportList01: TransportList01Reducer,
      transportDetail: TransportDetailReducer,
      userDetail: UserDetailReducer,
      slsOrdTrackingReport: SlsOrdTrackingReportReducer,
      shipmentTrackingReport: ShipmentTrackingReportReducer,
      transferInTrackingReport: TransferInTrackingReportReducer,
      transferOutTrackingReport: TransferOutTrackingReportReducer,
      deliveryPointDetail: DeliveryPointDetailReducer,
      bizPartnerDetail: BizPartnerDetailReducer,
      pickList0101: PickList0101Reducer,
      itemDetail: ItemDetailReducer,
      delOrdIndex: DelOrdIndexReducer,
      delOrdDetail: DelOrdDetailReducer,
      delOrdFServ01: DelOrdFServ01Reducer,
      delOrdSync01: DelOrdSync01Reducer,
      gdsRcptTrackingReport: GdsRcptTrackingReportReducer,
      storageBinDetail: StorageBinDetailReducer,
      storageRowList01: StorageRowList01Reducer,
      storageRowDetail: StorageRowDetailReducer,
      storageBayList01: StorageBayList01Reducer,
      storageBayDetail: StorageBayDetailReducer,
      storageTypeDetail: StorageTypeDetailReducer,
      storageTypeList01: StorageTypeList01Reducer,
      debtorList01: DebtorList01Reducer,
      debtorProcess: DebtorProcessReducer,
      debtorDetail: DebtorDetailReducer,
      creditorList01: CreditorList01Reducer,
      creditorProcess: CreditorProcessReducer,
      creditorDetail: CreditorDetailReducer,
      outbOrd04: OutbOrd04Reducer,
      productionProcess: ProductionProcessReducer,
      prodOrdExcel01: ProdOrdExcel01Reducer,
      prodOrdDetail: ProdOrdDetailReducer,
      prodOrdIndex: ProdOrdIndexReducer,
      prodSheet01: ProdSheet01Reducer,
      prodIssDetail: ProdIssDetailReducer,
      prodIssIndex: ProdIssIndexReducer,
      prodRcpt01: ProdRcpt01Reducer,
      prodSheetDetail: ProdSheetDetailReducer,
      prodSheetIndex: ProdSheetIndexReducer,
      binTrf03: BinTrf03Reducer,
      prodRcptDetail: ProdRcptDetailReducer,
      whseJob0601: WhseJob0601Reducer,
      whseJob060101: WhseJob060101Reducer,
      whseJob0602: WhseJob0602Reducer,
      whseJob060201: WhseJob060201Reducer,
      whseJob06Detail: WhseJob06DetailReducer,
      siteList01: SiteList01Reducer,
      siteProcess: SiteProcessReducer,
      siteDetail: SiteDetailReducer,
      docTypeDocNoIndex: DocTypeDocNoIndexReducer,
      siteFlowList01: SiteFlowList01Reducer,
      siteFlowDetail: SiteFlowDetailReducer,
      divisionProcess: DivisionProcessReducer,
      divisionList01: DivisionList01Reducer,
      divisionDetail: DivisionDetailReducer,
      advShipExcel01: AdvShipExcel01Reducer,
      advShipFServ01: AdvShipFServ01Reducer,
      advShipFServ02: AdvShipFServ02Reducer,
      slsOrdExcel01: SlsOrdExcel01Reducer,
      slsOrdFServ01: SlsOrdFServ01Reducer,
      slsOrdFServ02: SlsOrdFServ02Reducer,
      slsRtnFServ02: SlsRtnFServ02Reducer,
      prodOrdFServ01: ProdOrdFServ01Reducer,
      prodOrdFServ02: ProdOrdFServ02Reducer,
      prodIssFServ01: ProdIssFServ01Reducer,
      prodSheetFServ01: ProdSheetFServ01Reducer,
      companyProcess: CompanyProcessReducer,
      companyList01: CompanyList01Reducer,
      companyDetail: CompanyDetailReducer,
      areaProcess: AreaProcessReducer,
      areaList01: AreaList01Reducer,
      areaDetail: AreaDetailReducer,
      countAdjReasonProcess: CountAdjReasonProcessReducer,
      countAdjReasonList01: CountAdjReasonList01Reducer,
      countAdjReasonDetail: CountAdjReasonDetailReducer,
      outboundExpiryReport: OutboundExpiryReportReducer,
      pickListDetailBoard: PickListDetailBoardReducer,
      loadListDetailBoard: LoadListDetailBoardReducer,
      lspSlsOrdDetailBoard: LspSlsOrdDetailBoardReducer,
      slsOrdDetailBoard: SlsOrdDetailBoardReducer,
      gdsRcptDetailBoard: GdsRcptDetailBoardReducer,
      gdsRcptDetailReport: GdsRcptDetailReportReducer,
      gdsRtnDetailReport: GdsRtnDetailReportReducer,
      gdsRcptRtnDetailBoard: GdsRcptRtnDetailBoardReducer,
      summaryBoard: SummaryBoardReducer,
      putAwaySync01: PutAwaySync01Reducer,
      siteTrfProcess: SiteTrfProcessReducer,
      outbOrd05: OutbOrd05Reducer,
      outbOrd06: OutbOrd06Reducer,
      outbOrd07: OutbOrd07Reducer,
      trfOrdDetail: TrfOrdDetailReducer,
      inbOrd05: InbOrd05Reducer,
      stkIssDetail: StkIssDetailReducer,
      invDoc03: InvDoc03Reducer,
      trfOrdIndex: TrfOrdIndexReducer,
      stkIssIndex: StkIssIndexReducer,
      stkRcptIndex: StkRcptIndexReducer,
      stkRcptDetail: StkRcptDetailReducer,
      stkIssSync01: StkIssSync01Reducer,
      stkIssFServ01: StkIssFServ01Reducer,
      stkRcptSync01: StkRcptSync01Reducer,
      stkRcptSync02: StkRcptSync02Reducer,
      stkRcptSync05: StkRcptSync05Reducer,
      trfToVanProcess: TrfToVanProcessReducer,
      stkRcpt02: StkRcpt02Reducer,
      stkRcpt03: StkRcpt03Reducer,
      stkTrfIndex: StkTrfIndexReducer,
      stkTrfDetail: StkTrfDetailReducer,
      stkTrfSync01: StkTrfSync01Reducer,
      inbOrd07: InbOrd07Reducer,
      trfFromVanProcess: TrfFromVanProcessReducer,
      stkIssSync05: StkIssSync05Reducer,
      trfToStoreProcess: TrfToStoreProcessReducer,
      stkIssSync02: StkIssSync02Reducer,
      trfFromStoreProcess: TrfFromStoreProcessReducer,
      inbOrd06: InbOrd06Reducer,
      stkIssSync04: StkIssSync04Reducer,
      stkRcptSync04: StkRcptSync04Reducer,
      projectList01: ProjectList01Reducer,
      projectDetail: ProjectDetailReducer,
      outbOrdReservedStockReport: OutbOrdReservedStockReportReducer,
      dailyStockMovementReport: DailyStockMovementReportReducer,
      purRtnDetail: PurRtnDetailReducer,
      purRtnIndex: PurRtnIndexReducer,
      outbOrd08: OutbOrd08Reducer,
      purRtnFServ02: PurRtnFServ02Reducer,
      purRtnSync01: PurRtnSync01Reducer,
      lspSlsOrdFulfillmentChart: LspSlsOrdFulfillmentChartReducer,
      slsOrdFulfillmentChart: SlsOrdFulfillmentChartReducer,
      whsePerformanceChart: WhsePerformanceChartReducer,
      workerPerformanceChart: WorkerPerformanceChartReducer,
      workerGdsRcptAnalysisReport: WorkerGdsRcptAnalysisReportReducer,
      workerPutAwayAnalysisReport: WorkerPutAwayAnalysisReportReducer,
      workerLoadListAnalysisReport: WorkerLoadListAnalysisReportReducer,
      workerPickListAnalysisReport: WorkerPickListAnalysisReportReducer,
      workerPackListAnalysisReport: WorkerPackListAnalysisReportReducer,
      stockReorderPlanningV2Report: StockReorderPlanningV2ReportReducer,
      appSetting: AppSettingReducer
    });

  return configureStore(rootReducer, rootSaga);
};
