import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  debtorList01ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  debtorList01GoToResource: ['resId'],
  debtorList01NewResource: null,
  debtorList01FetchDebtorList01: ['currentPage', 'sorts', 'filters', 'pageSize'],
  debtorList01FetchDebtorList01Loading: ['boolean'],
  debtorList01FetchDebtorList01Success: [
    'resources',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],
  debtorList01AddSelectedResources: ['selectedResources'],
  debtorList01RemoveSelectedResources: ['selectedResources'],
  debtorList01SetWorkspaceVisible: ['boolean']
});

export const DebtorList01Types = Types;
export default Creators;
