import React from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';

import { ArrowLeftOutlined, UndoOutlined, SaveOutlined } from '@ant-design/icons';

import { Button, Row, Col, Typography, Card } from 'antd';
import treeChanges from 'tree-changes';
import { FormikInput, FormikButton } from '../../Components/Formik';
import UomDetailActions from '../../Stores/UomDetail/Actions';
import AppActions from '../../Stores/App/Actions';

const { Text } = Typography;

class ModelForm extends React.PureComponent {
  componentDidMount() {
    const { resId, resetTimestamp } = this.props;

    if (resId > 0) {
      resetTimestamp();
    }
  }

  componentDidUpdate(prevProps) {
    const { resId, resetTimestamp, initModel, showModel } = this.props;

    const { changed } = treeChanges(prevProps, this.props);

    if (changed('resId')) {
      resetTimestamp();
    }
    if (changed('timestamp')) {
      if (resId <= 0) {
        initModel();
      } else {
        showModel(resId);
      }
    }
  }

  componentWillUnmount() {}

  render() {
    const {
      intl,
      match,
      model,
      createModel,
      updateModel,
      historyGoBack,
      modelIsLoading
    } = this.props;

    const disabled = false;

    return (
      <Formik
        enableReinitialize
        initialValues={model}
        onSubmit={(values, formikBag) => {
          if (match.params.action === 'create') {
            createModel(formikBag, values);
          } else {
            updateModel(formikBag, values);
          }
        }}
        validationSchema={Yup.object().shape({
          code: Yup.string().required(intl.formatMessage({ id: 'code_is_required' }))
        })}
      >
        {({ dirty, isSubmitting, handleSubmit, handleReset }) => (
          <Form>
            <Card>
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'code' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="code"
                    component={FormikInput}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'ref_code' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="ref_code_01"
                    component={FormikInput}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'description' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="desc_01"
                    component={FormikInput}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
              </Row>
            </Card>

            <Row type="flex" justify="space-between" gutter={[0, 16]}>
              <Col span={8}>
                <Button
                  type="primary"
                  // disabled={!isValid}
                  loading={isSubmitting}
                  onClick={historyGoBack}
                  icon={<ArrowLeftOutlined />}
                >
                  {intl.formatMessage({
                    id: 'back'
                  })}
                </Button>
              </Col>
              <Col span={5}>
                <Button
                  type="primary"
                  disabled={!dirty}
                  loading={isSubmitting || modelIsLoading}
                  onClick={handleReset}
                  icon={<UndoOutlined />}
                >
                  {intl.formatMessage({ id: 'reset' })}
                </Button>
                <Field
                  name="submit_action"
                  type="primary"
                  value="update"
                  component={FormikButton}
                  // disabled={values.status >= 50}
                  loading={isSubmitting || modelIsLoading}
                  onClick={handleSubmit}
                  label={intl.formatMessage({
                    id: match.params.action === 'create' ? 'create' : 'update'
                  })}
                  icon={<SaveOutlined />}
                />
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    );
  }
}

ModelForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object,
  resetTimestamp: PropTypes.func,

  initModel: PropTypes.func,
  showModel: PropTypes.func,
  createModel: PropTypes.func,
  updateModel: PropTypes.func,
  historyGoBack: PropTypes.func,
  resId: PropTypes.number,
  model: PropTypes.shape({
    code: PropTypes.string
  }),
  modelIsLoading: PropTypes.bool
};

ModelForm.defaultProps = {
  intl: {},
  match: {},
  resetTimestamp() {},

  initModel() {},
  showModel() {},
  createModel() {},
  updateModel() {},
  historyGoBack() {},
  resId: 0,
  model: { code: '' },
  modelIsLoading: false
};

const mapStateToProps = state => ({
  apiUrl: state.app.apiUrl,
  timestamp: state.uomDetail.modelTimestamp,
  resId: state.uomDetail.resId,
  model: state.uomDetail.model,
  modelIsLoading: state.uomDetail.modelIsLoading
});

const mapDispatchToProps = dispatch => ({
  resetTimestamp: () => dispatch(UomDetailActions.uomDetailResetModelTimestamp()),
  initModel: () => dispatch(UomDetailActions.uomDetailInitModel()),
  showModel: resId => dispatch(UomDetailActions.uomDetailShowModel(resId)),

  updateModel: (formikBag, model) =>
    dispatch(UomDetailActions.uomDetailUpdateModel(formikBag, model)),
  createModel: (formikBag, model) =>
    dispatch(UomDetailActions.uomDetailCreateModel(formikBag, model)),

  historyGoBack: () => dispatch(AppActions.appHistoryGoBack())
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ModelForm));
