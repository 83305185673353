/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { WhseJob1401Types } from './Actions';
import INITIAL_STATE from './InitialState';

export const whseJob1401ResetTimestamp = (state, { currentPage, sorts, filters, pageSize }) => ({
  ...state,
  currentPage: currentPage || state.currentPage,
  sorts: sorts || state.sorts,
  filters: filters || state.filters,
  pageSize: pageSize || state.pageSize,
  timestamp: Date.now(),
  // selectedDocuments: INITIAL_STATE.selectedDocuments,
  toStorageBinOptions: INITIAL_STATE.toStorageBinOptions,
  toStorageBinOption: INITIAL_STATE.toStorageBinOption
});

export const whseJob1401FetchWhseJob1401Loading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const whseJob1401FetchWhseJob1401Success = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  documents,
  currentPage,
  lastPage,
  total,
  pageSize
});

export const whseJob1401AddSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = state.selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: [
      ...state.selectedDocuments,
      ...selectedDocuments.filter(data => {
        return !selectIds.includes(data.id);
      })
    ]
  };
};

export const whseJob1401RemoveSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: state.selectedDocuments.filter(data => {
      return !selectIds.includes(data.id);
    })
  };
};

export const whseJob1401SetWorkspaceVisible = (state, { boolean }) => ({
  ...state,
  workspaceIsVisible: boolean
});

export const whseJob1401CreateWhseJob1401Loading = (state, { boolean }) => ({
  ...state,
  createIsLoading: boolean
});

export const whseJob1401CreateWhseJob1401Success = (state, { newDocuments }) => ({
  ...state,
  timestamp: Date.now(),
  selectedDocuments: [],
  newDocuments
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [WhseJob1401Types.WHSE_JOB1401_RESET_TIMESTAMP]: whseJob1401ResetTimestamp,
  [WhseJob1401Types.WHSE_JOB1401_FETCH_WHSE_JOB1401_LOADING]: whseJob1401FetchWhseJob1401Loading,
  [WhseJob1401Types.WHSE_JOB1401_FETCH_WHSE_JOB1401_SUCCESS]: whseJob1401FetchWhseJob1401Success,
  [WhseJob1401Types.WHSE_JOB1401_ADD_SELECTED_DOCUMENTS]: whseJob1401AddSelectedDocuments,
  [WhseJob1401Types.WHSE_JOB1401_REMOVE_SELECTED_DOCUMENTS]: whseJob1401RemoveSelectedDocuments,
  [WhseJob1401Types.WHSE_JOB1401_SET_WORKSPACE_VISIBLE]: whseJob1401SetWorkspaceVisible,
  [WhseJob1401Types.WHSE_JOB1401_CREATE_WHSE_JOB1401_LOADING]: whseJob1401CreateWhseJob1401Loading,
  [WhseJob1401Types.WHSE_JOB1401_CREATE_WHSE_JOB1401_SUCCESS]: whseJob1401CreateWhseJob1401Success
});
