import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  whseJob1601ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  whseJob1601GoToDocument: ['hdrId'],
  whseJob1601FetchWhseJob1601: ['currentPage', 'sorts', 'filters', 'pageSize'],
  whseJob1601FetchWhseJob1601Loading: ['boolean'],
  whseJob1601FetchWhseJob1601Success: ['documents', 'currentPage', 'lastPage', 'total', 'pageSize'],
  whseJob1601AddSelectedDocuments: ['selectedDocuments'],
  whseJob1601RemoveSelectedDocuments: ['selectedDocuments'],
  whseJob1601SetWorkspaceVisible: ['boolean'],
  whseJob1601CreateWhseJob1601: ['hdrIds'],
  whseJob1601CreateWhseJob1601Loading: ['boolean'],
  whseJob1601CreateWhseJob1601Success: ['newDocuments']
});

export const WhseJob1601Types = Types;
export default Creators;
