import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  debtorProcessFetchDebtorProcess: null,
  debtorProcessFetchDebtorProcessLoading: ['boolean'],
  debtorProcessFetchDebtorProcessSuccess: ['process']
});

export const DebtorProcessTypes = Types;
export default Creators;
