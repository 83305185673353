import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  slsInvSync01ShowSyncSetting: null,
  slsInvSync01ShowSyncSettingLoading: ['boolean'],
  slsInvSync01ShowSyncSettingSuccess: ['syncSetting'],
  slsInvSync01UpdateSyncSetting: ['formikBag', 'syncSetting'],
  slsInvSync01ShowBatchJobStatus: null,
  slsInvSync01ShowBatchJobStatusSuccess: ['batchJobStatus'],
  // slsInvSync01SyncNow: ['formikBag', 'divisionId'],
  // slsInvSync01SyncLoading: ['boolean'],

  slsInvSync01ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  slsInvSync01GoToDocument: ['hdrId'],
  slsInvSync01FetchSlsInvSync01: ['currentPage', 'sorts', 'filters', 'pageSize'],
  slsInvSync01FetchSlsInvSync01Loading: ['boolean'],
  slsInvSync01FetchSlsInvSync01Success: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],
  slsInvSync01AddSelectedDocuments: ['selectedDocuments'],
  slsInvSync01RemoveSelectedDocuments: ['selectedDocuments'],
  slsInvSync01SetWorkspaceVisible: ['boolean'],

  slsInvSync01SyncNow: ['hdrIds'],
  slsInvSync01SyncNowSuccess: null,
  slsInvSync01SyncLoading: ['boolean']
});

export const SlsInvSync01Types = Types;
export default Creators;
