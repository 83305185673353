import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import treeChanges from 'tree-changes';

import { ReloadOutlined } from '@ant-design/icons';

import { Row, Col, Table, Button, Upload, notification } from 'antd';

import DivisionDetailActions from '../../Stores/DivisionDetail/Actions';

class TxnFlowTable extends React.PureComponent {
  constructor() {
    super();

    this.state = {
      fileList: []
    };

    this.getDocumentColumns = this.getDocumentColumns.bind(this);
    this.useOnUpload = this.useOnUpload.bind(this);
    this.useOnDownload = this.useOnDownload.bind(this);
  }

  componentDidMount() {
    const { resetUserDivisionTimestamp } = this.props;

    resetUserDivisionTimestamp();
  }

  componentDidUpdate(prevProps) {
    const { resId, showUserDivisions, showUserDivisionsSuccess } = this.props;

    const { changed } = treeChanges(prevProps, this.props);

    if (changed('userDivisionTimestamp')) {
      if (resId === 0) {
        showUserDivisionsSuccess([]);
      } else {
        showUserDivisions(resId);
      }
    }
  }

  componentWillUnmount() {}

  getDocumentColumns() {
    const { intl } = this.props;

    return [
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'username' }),
        // sort field
        dataIndex: 'username',
        // filter field
        key: 'username',
        render: (text, record) => <>{record.username}</>
      },
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'ref_code_01' }),
        // sort field
        dataIndex: 'ref_code_01',
        // filter field
        key: 'ref_code_01',
        render: (text, record) => <>{record.ref_code_01}</>
      },
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'ref_code_02' }),
        // sort field
        dataIndex: 'ref_code_02',
        // filter field
        key: 'ref_code_02',
        render: (text, record) => <>{record.ref_code_02}</>
      }
    ];
  }

  useOnUpload() {
    const { resId, uploadExcel } = this.props;
    const { fileList } = this.state;

    if (fileList.length > 0) {
      uploadExcel(resId, fileList[0]);
    }
  }

  useOnDownload() {
    const { resId, downloadExcel } = this.props;

    downloadExcel(resId);
  }

  render() {
    const {
      intl,
      resetUserDivisionTimestamp,
      resId,
      userDivisions,
      userDivisionsIsLoading,
      uploadIsLoading
    } = this.props;
    const { fileList } = this.state;

    return (
      <>
        <Table
          size="small"
          // rowSelection={rowSelection}
          rowKey="id"
          pagination={{ pageSize: 20 }}
          columns={this.getDocumentColumns()}
          dataSource={userDivisions}
          loading={userDivisionsIsLoading}
          bordered
          title={() => (
            <>
              <Row type="flex" justify="start" gutter={[0, 16]}>
                <Col span={1}>
                  <Button
                    type="primary"
                    // disabled={!isValid}
                    loading={userDivisionsIsLoading}
                    onClick={() => resetUserDivisionTimestamp()}
                    icon={<ReloadOutlined />}
                  />
                </Col>
                <Col span={8}>
                  <Upload
                    disabled={false}
                    onRemove={() => {
                      this.setState({
                        fileList: []
                      });
                    }}
                    beforeUpload={file => {
                      const validFileName = 'USER_DIVISION';
                      if (file && file.name.toLowerCase().includes(validFileName.toLowerCase())) {
                        this.setState({
                          fileList: [file]
                        });
                      } else {
                        notification.error({
                          message: `${intl.formatMessage({
                            id: 'invalid_file_name_must_contain'
                          })} ${validFileName}`,
                          duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
                        });
                      }

                      return false;
                    }}
                    fileList={fileList}
                  >
                    <Button type="primary" disabled={false} loading={userDivisionsIsLoading}>
                      {intl.formatMessage({ id: 'browse' })}
                    </Button>
                  </Upload>
                </Col>
                <Col span={6}>
                  <Button
                    type="primary"
                    onClick={this.useOnUpload}
                    disabled={fileList.length === 0}
                    loading={uploadIsLoading}
                  >
                    {intl.formatMessage({ id: 'upload' })}
                  </Button>
                  <Button
                    type="primary"
                    disabled={userDivisionsIsLoading || resId === 0}
                    onClick={this.useOnDownload}
                    loading={uploadIsLoading}
                  >
                    {intl.formatMessage({ id: 'download' })}
                  </Button>
                </Col>
              </Row>
            </>
          )}
          // scroll={{ x: 950 }}
        />
      </>
    );
  }
}

TxnFlowTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  resetUserDivisionTimestamp: PropTypes.func,
  showUserDivisions: PropTypes.func,
  showUserDivisionsSuccess: PropTypes.func,
  resId: PropTypes.number,
  userDivisionsIsLoading: PropTypes.bool,
  userDivisions: PropTypes.arrayOf(PropTypes.object),

  uploadIsLoading: PropTypes.bool,
  uploadExcel: PropTypes.func,
  downloadExcel: PropTypes.func
};

TxnFlowTable.defaultProps = {
  intl: {},
  resetUserDivisionTimestamp() {},
  showUserDivisions() {},
  showUserDivisionsSuccess() {},
  resId: 0,
  userDivisions: [],
  userDivisionsIsLoading: false,

  uploadIsLoading: false,
  uploadExcel() {},
  downloadExcel() {}
};

const mapStateToProps = state => ({
  userDivisionTimestamp: state.divisionDetail.userDivisionTimestamp,
  resId: state.divisionDetail.resId,
  userDivisions: state.divisionDetail.userDivisions,
  userDivisionsIsLoading: state.divisionDetail.userDivisionsIsLoading,

  uploadIsLoading: state.divisionDetail.userDivisionsUploadIsLoading
});

const mapDispatchToProps = dispatch => ({
  resetUserDivisionTimestamp: () =>
    dispatch(DivisionDetailActions.divisionDetailResetUserDivisionTimestamp()),
  showUserDivisions: resId =>
    dispatch(DivisionDetailActions.divisionDetailShowUserDivisions(resId)),
  showUserDivisionsSuccess: txnFlows =>
    dispatch(DivisionDetailActions.divisionDetailShowUserDivisionsSuccess(txnFlows)),

  uploadExcel: (resId, file) =>
    dispatch(DivisionDetailActions.divisionDetailUserDivisionsUploadExcel(resId, file)),
  downloadExcel: resId =>
    dispatch(DivisionDetailActions.divisionDetailUserDivisionsDownloadExcel(resId))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(TxnFlowTable));
