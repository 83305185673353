import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  trfOrdIndexResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  trfOrdIndexFetchTrfOrdIndex: ['currentPage', 'sorts', 'filters', 'pageSize'],
  trfOrdIndexFetchTrfOrdIndexLoading: ['boolean'],
  trfOrdIndexFetchTrfOrdIndexSuccess: ['documents', 'currentPage', 'lastPage', 'total', 'pageSize'],
  trfOrdIndexAddSelectedDocuments: ['selectedDocuments'],
  trfOrdIndexRemoveSelectedDocuments: ['selectedDocuments'],
  trfOrdIndexSetWorkspaceVisible: ['boolean'],
  trfOrdIndexGoToPrint: ['document'],
  trfOrdIndexGoToDocument: ['hdrId'],
  trfOrdIndexGoToAudit: ['hdrId']
});

export const TrfOrdIndexTypes = Types;
export default Creators;
