import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  stkIssDetailResetTimestamp: null,
  stkIssDetailSetHdrId: ['hdrId', 'itemId'],

  stkIssDetailInitHeader: ['docSubType'],
  stkIssDetailShowHeader: ['hdrId'],
  stkIssDetailShowHeaderSuccess: ['documentHeader'],

  stkIssDetailUpdateHeader: ['formikBag', 'documentHeader'],
  stkIssDetailCreateHeader: ['formikBag', 'documentHeader'],
  stkIssDetailUpdateDocumentSuccess: ['documentHeader', 'documentDetails'],

  stkIssDetailShowDocumentLoading: ['boolean'],
  stkIssDetailTransitionToStatus: ['formikBag', 'hdrId', 'docStatus'],

  stkIssDetailShowDetails: ['hdrId'],
  stkIssDetailShowDetailsSuccess: ['documentDetails'],

  stkIssDetailSetDetailVisible: ['boolean'],
  stkIssDetailSetDocumentDetail: ['documentDetail'],

  stkIssDetailUpdateDetails: ['formikBag', 'hdrId', 'documentDetails'],
  stkIssDetailCreateDetail: ['formikBag', 'hdrId', 'documentDetail'],
  stkIssDetailDeleteDetail: ['hdrId', 'documentDetail'],

  stkIssDetailFetchCurrencyOptions: ['search'],
  stkIssDetailFetchCurrencyOptionLoading: ['boolean'],
  stkIssDetailFetchCurrencyOptionSuccess: ['options'],
  stkIssDetailChangeCurrency: ['formikBag', 'currencyId'],

  stkIssDetailFetchToDivisionOptions: ['docSubType', 'search'],
  stkIssDetailFetchToDivisionOptionLoading: ['boolean'],
  stkIssDetailFetchToDivisionOptionSuccess: ['options'],
  stkIssDetailChangeToDivision: ['formikBag', 'toDivisionId'],

  stkIssDetailFetchToLocationOptions: ['hdrId', 'docSubType', 'search'],
  stkIssDetailFetchToLocationOptionLoading: ['boolean'],
  stkIssDetailFetchToLocationOptionSuccess: ['options'],

  stkIssDetailFetchLocationOptions: ['docSubType', 'search'],
  stkIssDetailFetchLocationOptionLoading: ['boolean'],
  stkIssDetailFetchLocationOptionSuccess: ['options'],

  stkIssDetailFetchHdrToLocationOptions: ['docSubType', 'search'],
  stkIssDetailFetchHdrToLocationOptionLoading: ['boolean'],
  stkIssDetailFetchHdrToLocationOptionSuccess: ['options'],

  stkIssDetailFetchItemOptions: ['search'],
  stkIssDetailFetchItemOptionLoading: ['boolean'],
  stkIssDetailFetchItemOptionSuccess: ['options'],
  stkIssDetailChangeItem: ['formikBag', 'hdrId', 'itemId'],

  stkIssDetailFetchUomOptions: ['itemId', 'search'],
  stkIssDetailFetchUomOptionLoading: ['boolean'],
  stkIssDetailFetchUomOptionSuccess: ['options'],
  stkIssDetailChangeUom: ['formikBag', 'hdrId', 'itemId', 'uomId'],

  stkIssDetailFetchProjectOptions: ['search'],
  stkIssDetailFetchProjectOptionLoading: ['boolean'],
  stkIssDetailFetchProjectOptionSuccess: ['options']
});

export const StkIssDetailTypes = Types;
export default Creators;
