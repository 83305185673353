import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import treeChanges from 'tree-changes';

import { Card, Tabs, Tooltip, Spin } from 'antd';

import LspSupplierReturnProcessActions from '../../Stores/LspSupplierReturnProcess/Actions';

const { TabPane } = Tabs;

class LspSupplierReturnProcessScreen extends React.PureComponent {
  componentDidMount() {
    const { curDivisionId, fetchLspSupplierReturnProcess } = this.props;

    if (curDivisionId > 0) {
      fetchLspSupplierReturnProcess();
    }
  }

  componentDidUpdate(prevProps) {
    const { curDivisionId, fetchLspSupplierReturnProcess } = this.props;
    const { changed } = treeChanges(prevProps, this.props);
    if (changed('curDivisionId')) {
      if (curDivisionId > 0) {
        fetchLspSupplierReturnProcess();
      }
    }
  }

  componentWillUnmount() {}

  render() {
    const { intl, match, process, appPath, processIsLoading } = this.props;

    return (
      <Card>
        <Spin spinning={processIsLoading}>
          <Tabs type="card" activeKey={match.params.proc_type}>
            {process.map(value => (
              <TabPane
                tab={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <Tooltip
                    placement="bottom"
                    title={intl.formatMessage({ id: `${value.proc_type.toLowerCase()}_desc` })}
                  >
                    <Link
                      style={value.proc_type !== match.params.proc_type ? { color: 'black' } : {}}
                      to={`${appPath}/lspSupplierReturnProcess/index/${value.proc_type}`}
                    >
                      <FormattedMessage id={`${value.proc_type.toLowerCase()}_label`} />
                    </Link>
                  </Tooltip>
                }
                key={value.proc_type}
              />
            ))}
          </Tabs>
        </Spin>
      </Card>
    );
  }
}

LspSupplierReturnProcessScreen.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object,
  appPath: PropTypes.string,
  process: PropTypes.arrayOf(PropTypes.object),
  processIsLoading: PropTypes.bool,

  curDivisionId: PropTypes.number,
  fetchLspSupplierReturnProcess: PropTypes.func
};

LspSupplierReturnProcessScreen.defaultProps = {
  intl: {},
  match: {},
  appPath: '',
  process: [],
  processIsLoading: false,

  curDivisionId: 0,
  fetchLspSupplierReturnProcess() {}
};

const mapStateToProps = state => ({
  appPath: state.app.appPath,
  process: state.lspSupplierReturnProcess.process,
  processIsLoading: state.lspSupplierReturnProcess.processIsLoading,

  curDivisionId: state.app.curDivisionId
});

const mapDispatchToProps = dispatch => ({
  fetchLspSupplierReturnProcess: () =>
    dispatch(
      LspSupplierReturnProcessActions.lspSupplierReturnProcessFetchLspSupplierReturnProcess()
    )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(LspSupplierReturnProcessScreen));
