import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  whseJob140201ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  whseJob140201GoToDocument: ['hdrId'],
  whseJob140201FetchWhseJob140201: ['currentPage', 'sorts', 'filters', 'pageSize'],
  whseJob140201FetchWhseJob140201Loading: ['boolean'],
  whseJob140201FetchWhseJob140201Success: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],
  whseJob140201AddSelectedDocuments: ['selectedDocuments'],
  whseJob140201RemoveSelectedDocuments: ['selectedDocuments'],
  whseJob140201SetWorkspaceVisible: ['boolean'],
  whseJob140201PrintWhseJob140201: ['hdrIds'],
  whseJob140201PrintWhseJob140201Loading: ['boolean'],
  whseJob140201PrintHandlingUnit: ['hdrIds']
});

export const WhseJob140201Types = Types;
export default Creators;
