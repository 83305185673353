const EXPIRY_CONTROL_ONLY = 0;
const BATCH_CONTROL_ONLY = 1;
const BATCH_EXPIRY_CONTROL = 2;
const SERIAL_CONTROL_ONLY = 3;

// lock user edit batchNo/expiryDate/receiptDate
const EXPIRY_CONTROL_ONLY_01 = 100;
const BATCH_CONTROL_ONLY_01 = 101;
const BATCH_EXPIRY_CONTROL_01 = 102;
const SERIAL_CONTROL_ONLY_01 = 103;

const isBatchSerialControl = batchSerialControl => {
  let isBatchControl = false;
  if (batchSerialControl === BATCH_CONTROL_ONLY || batchSerialControl === BATCH_CONTROL_ONLY_01) {
    isBatchControl = true;
  } else if (
    batchSerialControl === BATCH_EXPIRY_CONTROL ||
    batchSerialControl === BATCH_EXPIRY_CONTROL_01
  ) {
    isBatchControl = true;
  } else if (
    batchSerialControl === SERIAL_CONTROL_ONLY ||
    batchSerialControl === SERIAL_CONTROL_ONLY_01
  ) {
    isBatchControl = true;
  }

  return isBatchControl;
};

export default {
  isBatchSerialControl,
  EXPIRY_CONTROL_ONLY,
  BATCH_CONTROL_ONLY,
  BATCH_EXPIRY_CONTROL,
  SERIAL_CONTROL_ONLY,

  // lock user edit batchNo/expiryDate/receiptDate
  EXPIRY_CONTROL_ONLY_01,
  BATCH_CONTROL_ONLY_01,
  BATCH_EXPIRY_CONTROL_01,
  SERIAL_CONTROL_ONLY_01
};
