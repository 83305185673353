/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { ItemDetailTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const itemDetailResetModelTimestamp = state => ({
  ...state,
  modelTimestamp: Date.now()
});

export const itemDetailSetResId = (state, { resId }) => ({
  ...state,
  modelTimestamp: Date.now(),
  resId
});

export const itemDetailShowModelLoading = (state, { boolean }) => ({
  ...state,
  modelIsLoading: boolean
});

export const itemDetailShowModelSuccess = (state, { model }) => ({
  ...state,
  model
});

export const itemDetailUpdateModelSuccess = (state, { model }) => ({
  ...state,
  model
});

export const itemDetailFetchItemGroup01OptionLoading = (state, { boolean }) => ({
  ...state,
  itemGroup01IsLoading: boolean
});

export const itemDetailFetchItemGroup01OptionSuccess = (state, { options }) => ({
  ...state,
  itemGroup01Options: options
});

export const itemDetailFetchItemGroup02OptionLoading = (state, { boolean }) => ({
  ...state,
  itemGroup02IsLoading: boolean
});

export const itemDetailFetchItemGroup02OptionSuccess = (state, { options }) => ({
  ...state,
  itemGroup02Options: options
});

export const itemDetailFetchItemGroup03OptionLoading = (state, { boolean }) => ({
  ...state,
  itemGroup03IsLoading: boolean
});

export const itemDetailFetchItemGroup03OptionSuccess = (state, { options }) => ({
  ...state,
  itemGroup03Options: options
});

export const itemDetailFetchUomOptionLoading = (state, { boolean }) => ({
  ...state,
  uomIsLoading: boolean
});

export const itemDetailFetchUomOptionSuccess = (state, { options }) => ({
  ...state,
  uomOptions: options
});

export const itemDetailResetItemBatchTimestamp = (
  state,
  { currentPage, sorts, filters, pageSize }
) => ({
  ...state,
  itemBatchCurrentPage: currentPage || state.itemBatchCurrentPage,
  itemBatchSorts: sorts || state.itemBatchSorts,
  itemBatchFilters: filters || state.itemBatchFilters,
  itemBatchPageSize: pageSize || state.itemBatchPageSize,
  itemBatchTimestamp: Date.now()
});

export const itemDetailShowItemBatchSuccess = (
  state,
  { itemBatches, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  itemBatches,
  itemBatchCurrentPage: currentPage,
  itemBatchLastPage: lastPage,
  itemBatchTotal: total,
  itemBatchPageSize: pageSize
});

export const itemDetailShowItemDocNoLoading = (state, { boolean }) => ({
  ...state,
  modelIsLoading: boolean
});

export const itemDetailShowItemDocNoSuccess = (state, { itemDocNo }) => ({
  ...state,
  itemDocNo
});

export const itemDetailSetItemDocNo = (state, { itemDocNoDtl }) => ({
  ...state,
  itemDocNoDtl
});

export const itemDetailSetFormVisible = (state, { boolean }) => ({
  ...state,
  formIsVisible: boolean
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [ItemDetailTypes.ITEM_DETAIL_RESET_MODEL_TIMESTAMP]: itemDetailResetModelTimestamp,
  [ItemDetailTypes.ITEM_DETAIL_SET_RES_ID]: itemDetailSetResId,
  [ItemDetailTypes.ITEM_DETAIL_SHOW_MODEL_LOADING]: itemDetailShowModelLoading,
  [ItemDetailTypes.ITEM_DETAIL_SHOW_MODEL_SUCCESS]: itemDetailShowModelSuccess,
  [ItemDetailTypes.ITEM_DETAIL_UPDATE_MODEL_SUCCESS]: itemDetailUpdateModelSuccess,

  [ItemDetailTypes.ITEM_DETAIL_FETCH_ITEM_GROUP01_OPTION_LOADING]: itemDetailFetchItemGroup01OptionLoading,
  [ItemDetailTypes.ITEM_DETAIL_FETCH_ITEM_GROUP01_OPTION_SUCCESS]: itemDetailFetchItemGroup01OptionSuccess,

  [ItemDetailTypes.ITEM_DETAIL_FETCH_ITEM_GROUP02_OPTION_LOADING]: itemDetailFetchItemGroup02OptionLoading,
  [ItemDetailTypes.ITEM_DETAIL_FETCH_ITEM_GROUP02_OPTION_SUCCESS]: itemDetailFetchItemGroup02OptionSuccess,

  [ItemDetailTypes.ITEM_DETAIL_FETCH_ITEM_GROUP03_OPTION_LOADING]: itemDetailFetchItemGroup03OptionLoading,
  [ItemDetailTypes.ITEM_DETAIL_FETCH_ITEM_GROUP03_OPTION_SUCCESS]: itemDetailFetchItemGroup03OptionSuccess,

  [ItemDetailTypes.ITEM_DETAIL_FETCH_UOM_OPTION_LOADING]: itemDetailFetchUomOptionLoading,
  [ItemDetailTypes.ITEM_DETAIL_FETCH_UOM_OPTION_SUCCESS]: itemDetailFetchUomOptionSuccess,

  [ItemDetailTypes.ITEM_DETAIL_RESET_ITEM_BATCH_TIMESTAMP]: itemDetailResetItemBatchTimestamp,
  [ItemDetailTypes.ITEM_DETAIL_SHOW_ITEM_BATCH_SUCCESS]: itemDetailShowItemBatchSuccess,

  [ItemDetailTypes.ITEM_DETAIL_SHOW_ITEM_DOC_NO_LOADING]: itemDetailShowItemDocNoLoading,
  [ItemDetailTypes.ITEM_DETAIL_SHOW_ITEM_DOC_NO_SUCCESS]: itemDetailShowItemDocNoSuccess,

  [ItemDetailTypes.ITEM_DETAIL_SET_ITEM_DOC_NO]: itemDetailSetItemDocNo,
  [ItemDetailTypes.ITEM_DETAIL_SET_FORM_VISIBLE]: itemDetailSetFormVisible
});
