import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  advShipFServ01ShowSyncSetting: null,
  advShipFServ01ShowSyncSettingLoading: ['boolean'],
  advShipFServ01ShowSyncSettingSuccess: ['syncSetting'],
  advShipFServ01UpdateSyncSetting: ['formikBag', 'syncSetting'],
  advShipFServ01ShowBatchJobStatus: null,
  advShipFServ01ShowBatchJobStatusSuccess: ['batchJobStatus'],
  advShipFServ01Import: ['formikBag'],
  advShipFServ01ImportLoading: ['boolean']
});

export const AdvShipFServ01Types = Types;
export default Creators;
