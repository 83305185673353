/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { GdsRcptRtnDetailBoardTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const gdsRcptRtnDetailBoardResetWipInbOrdTimestamp = (
  state,
  { currentPage, sorts, filters, pageSize }
) => ({
  ...state,
  wipInbOrdCurrentPage: currentPage || state.wipInbOrdCurrentPage,
  wipInbOrdSorts: sorts || state.wipInbOrdSorts,
  wipInbOrdFilters: filters || state.wipInbOrdFilters,
  wipInbOrdPageSize: pageSize || state.wipInbOrdPageSize,
  wipInbOrdTimestamp: Date.now()
});

export const gdsRcptRtnDetailBoardFetchWipInbOrdLoading = (state, { boolean }) => ({
  ...state,
  wipInbOrdIsLoading: boolean
});

export const gdsRcptRtnDetailBoardFetchWipInbOrdSuccess = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  wipInbOrds: documents,
  wipInbOrdCurrentPage: currentPage,
  wipInbOrdLastPage: lastPage,
  wipInbOrdTotal: total,
  wipInbOrdPageSize: pageSize
});

export const gdsRcptRtnDetailBoardResetWipGdsRcptTimestamp = (
  state,
  { currentPage, sorts, filters, pageSize }
) => ({
  ...state,
  wipGdsRcptCurrentPage: currentPage || state.wipGdsRcptCurrentPage,
  wipGdsRcptSorts: sorts || state.wipGdsRcptSorts,
  wipGdsRcptFilters: filters || state.wipGdsRcptFilters,
  wipGdsRcptPageSize: pageSize || state.wipGdsRcptPageSize,
  wipGdsRcptTimestamp: Date.now()
});

export const gdsRcptRtnDetailBoardFetchWipGdsRcptLoading = (state, { boolean }) => ({
  ...state,
  wipGdsRcptIsLoading: boolean
});

export const gdsRcptRtnDetailBoardFetchWipGdsRcptSuccess = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  wipGdsRcpts: documents,
  wipGdsRcptCurrentPage: currentPage,
  wipGdsRcptLastPage: lastPage,
  wipGdsRcptTotal: total,
  wipGdsRcptPageSize: pageSize
});

export const gdsRcptRtnDetailBoardResetWipWhseJobTimestamp = (
  state,
  { currentPage, sorts, filters, pageSize }
) => ({
  ...state,
  wipWhseJobCurrentPage: currentPage || state.wipWhseJobCurrentPage,
  wipWhseJobSorts: sorts || state.wipWhseJobSorts,
  wipWhseJobFilters: filters || state.wipWhseJobFilters,
  wipWhseJobPageSize: pageSize || state.wipWhseJobPageSize,
  wipWhseJobTimestamp: Date.now()
});

export const gdsRcptRtnDetailBoardFetchWipWhseJobLoading = (state, { boolean }) => ({
  ...state,
  wipWhseJobIsLoading: boolean
});

export const gdsRcptRtnDetailBoardFetchWipWhseJobSuccess = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  wipWhseJobs: documents,
  wipWhseJobCurrentPage: currentPage,
  wipWhseJobLastPage: lastPage,
  wipWhseJobTotal: total,
  wipWhseJobPageSize: pageSize
});

export const gdsRcptRtnDetailBoardResetCompleteGdsRcptTimestamp = (
  state,
  { currentPage, sorts, filters, pageSize }
) => ({
  ...state,
  completeGdsRcptCurrentPage: currentPage || state.completeGdsRcptCurrentPage,
  completeGdsRcptSorts: sorts || state.completeGdsRcptSorts,
  completeGdsRcptFilters: filters || state.completeGdsRcptFilters,
  completeGdsRcptPageSize: pageSize || state.completeGdsRcptPageSize,
  completeGdsRcptTimestamp: Date.now()
});

export const gdsRcptRtnDetailBoardFetchCompleteGdsRcptLoading = (state, { boolean }) => ({
  ...state,
  completeGdsRcptIsLoading: boolean
});

export const gdsRcptRtnDetailBoardFetchCompleteGdsRcptSuccess = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  completeGdsRcpts: documents,
  completeGdsRcptCurrentPage: currentPage,
  completeGdsRcptLastPage: lastPage,
  completeGdsRcptTotal: total,
  completeGdsRcptPageSize: pageSize
});

export const gdsRcptRtnDetailBoardShowWipWhseJobSummary = (state, { docId }) => ({
  ...state,
  wipWhseJobDocId: docId,
  wipWhseJobDialogIsVisible: true
});

export const gdsRcptRtnDetailBoardSetWipWhseJobDialogVisible = (state, { boolean }) => ({
  ...state,
  wipWhseJobDialogIsVisible: boolean
});

export const gdsRcptRtnDetailBoardShowWipWhseJobSummaryLoading = (state, { boolean }) => ({
  ...state,
  wipWhseJobDocumentIsLoading: boolean
});

export const gdsRcptRtnDetailBoardShowWipWhseJobSummarySuccess = (state, { document }) => ({
  ...state,
  wipWhseJobDocument: document
});

export const gdsRcptRtnDetailBoardShowWipGdsRcptSummary = (state, { docId }) => ({
  ...state,
  wipGdsRcptDocId: docId,
  wipGdsRcptDialogIsVisible: true
});

export const gdsRcptRtnDetailBoardSetWipGdsRcptDialogVisible = (state, { boolean }) => ({
  ...state,
  wipGdsRcptDialogIsVisible: boolean
});

export const gdsRcptRtnDetailBoardShowWipGdsRcptSummaryLoading = (state, { boolean }) => ({
  ...state,
  wipGdsRcptDocumentIsLoading: boolean
});

export const gdsRcptRtnDetailBoardShowWipGdsRcptSummarySuccess = (state, { document }) => ({
  ...state,
  wipGdsRcptDocument: document
});

export const gdsRcptRtnDetailBoardShowWipInbOrdSummary = (state, { docId }) => ({
  ...state,
  wipInbOrdDocId: docId,
  wipInbOrdDialogIsVisible: true
});

export const gdsRcptRtnDetailBoardSetWipInbOrdDialogVisible = (state, { boolean }) => ({
  ...state,
  wipInbOrdDialogIsVisible: boolean
});

export const gdsRcptRtnDetailBoardShowWipInbOrdSummaryLoading = (state, { boolean }) => ({
  ...state,
  wipInbOrdDocumentIsLoading: boolean
});

export const gdsRcptRtnDetailBoardShowWipInbOrdSummarySuccess = (state, { document }) => ({
  ...state,
  wipInbOrdDocument: document
});

export const gdsRcptRtnDetailBoardShowCompleteGdsRcptSummary = (state, { docId }) => ({
  ...state,
  completeGdsRcptDocId: docId,
  completeGdsRcptDialogIsVisible: true
});

export const gdsRcptRtnDetailBoardSetCompleteGdsRcptDialogVisible = (state, { boolean }) => ({
  ...state,
  completeGdsRcptDialogIsVisible: boolean
});

export const gdsRcptRtnDetailBoardShowCompleteGdsRcptSummaryLoading = (state, { boolean }) => ({
  ...state,
  completeGdsRcptDocumentIsLoading: boolean
});

export const gdsRcptRtnDetailBoardShowCompleteGdsRcptSummarySuccess = (state, { document }) => ({
  ...state,
  completeGdsRcptDocument: document
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [GdsRcptRtnDetailBoardTypes.GDS_RCPT_RTN_DETAIL_BOARD_RESET_WIP_INB_ORD_TIMESTAMP]: gdsRcptRtnDetailBoardResetWipInbOrdTimestamp,
  [GdsRcptRtnDetailBoardTypes.GDS_RCPT_RTN_DETAIL_BOARD_FETCH_WIP_INB_ORD_LOADING]: gdsRcptRtnDetailBoardFetchWipInbOrdLoading,
  [GdsRcptRtnDetailBoardTypes.GDS_RCPT_RTN_DETAIL_BOARD_FETCH_WIP_INB_ORD_SUCCESS]: gdsRcptRtnDetailBoardFetchWipInbOrdSuccess,

  [GdsRcptRtnDetailBoardTypes.GDS_RCPT_RTN_DETAIL_BOARD_RESET_WIP_GDS_RCPT_TIMESTAMP]: gdsRcptRtnDetailBoardResetWipGdsRcptTimestamp,
  [GdsRcptRtnDetailBoardTypes.GDS_RCPT_RTN_DETAIL_BOARD_FETCH_WIP_GDS_RCPT_LOADING]: gdsRcptRtnDetailBoardFetchWipGdsRcptLoading,
  [GdsRcptRtnDetailBoardTypes.GDS_RCPT_RTN_DETAIL_BOARD_FETCH_WIP_GDS_RCPT_SUCCESS]: gdsRcptRtnDetailBoardFetchWipGdsRcptSuccess,

  [GdsRcptRtnDetailBoardTypes.GDS_RCPT_RTN_DETAIL_BOARD_RESET_WIP_WHSE_JOB_TIMESTAMP]: gdsRcptRtnDetailBoardResetWipWhseJobTimestamp,
  [GdsRcptRtnDetailBoardTypes.GDS_RCPT_RTN_DETAIL_BOARD_FETCH_WIP_WHSE_JOB_LOADING]: gdsRcptRtnDetailBoardFetchWipWhseJobLoading,
  [GdsRcptRtnDetailBoardTypes.GDS_RCPT_RTN_DETAIL_BOARD_FETCH_WIP_WHSE_JOB_SUCCESS]: gdsRcptRtnDetailBoardFetchWipWhseJobSuccess,

  [GdsRcptRtnDetailBoardTypes.GDS_RCPT_RTN_DETAIL_BOARD_RESET_COMPLETE_GDS_RCPT_TIMESTAMP]: gdsRcptRtnDetailBoardResetCompleteGdsRcptTimestamp,
  [GdsRcptRtnDetailBoardTypes.GDS_RCPT_RTN_DETAIL_BOARD_FETCH_COMPLETE_GDS_RCPT_LOADING]: gdsRcptRtnDetailBoardFetchCompleteGdsRcptLoading,
  [GdsRcptRtnDetailBoardTypes.GDS_RCPT_RTN_DETAIL_BOARD_FETCH_COMPLETE_GDS_RCPT_SUCCESS]: gdsRcptRtnDetailBoardFetchCompleteGdsRcptSuccess,

  [GdsRcptRtnDetailBoardTypes.GDS_RCPT_RTN_DETAIL_BOARD_SHOW_WIP_WHSE_JOB_SUMMARY]: gdsRcptRtnDetailBoardShowWipWhseJobSummary,
  [GdsRcptRtnDetailBoardTypes.GDS_RCPT_RTN_DETAIL_BOARD_SET_WIP_WHSE_JOB_DIALOG_VISIBLE]: gdsRcptRtnDetailBoardSetWipWhseJobDialogVisible,
  [GdsRcptRtnDetailBoardTypes.GDS_RCPT_RTN_DETAIL_BOARD_SHOW_WIP_WHSE_JOB_SUMMARY_LOADING]: gdsRcptRtnDetailBoardShowWipWhseJobSummaryLoading,
  [GdsRcptRtnDetailBoardTypes.GDS_RCPT_RTN_DETAIL_BOARD_SHOW_WIP_WHSE_JOB_SUMMARY_SUCCESS]: gdsRcptRtnDetailBoardShowWipWhseJobSummarySuccess,

  [GdsRcptRtnDetailBoardTypes.GDS_RCPT_RTN_DETAIL_BOARD_SHOW_WIP_GDS_RCPT_SUMMARY]: gdsRcptRtnDetailBoardShowWipGdsRcptSummary,
  [GdsRcptRtnDetailBoardTypes.GDS_RCPT_RTN_DETAIL_BOARD_SET_WIP_GDS_RCPT_DIALOG_VISIBLE]: gdsRcptRtnDetailBoardSetWipGdsRcptDialogVisible,
  [GdsRcptRtnDetailBoardTypes.GDS_RCPT_RTN_DETAIL_BOARD_SHOW_WIP_GDS_RCPT_SUMMARY_LOADING]: gdsRcptRtnDetailBoardShowWipGdsRcptSummaryLoading,
  [GdsRcptRtnDetailBoardTypes.GDS_RCPT_RTN_DETAIL_BOARD_SHOW_WIP_GDS_RCPT_SUMMARY_SUCCESS]: gdsRcptRtnDetailBoardShowWipGdsRcptSummarySuccess,

  [GdsRcptRtnDetailBoardTypes.GDS_RCPT_RTN_DETAIL_BOARD_SHOW_WIP_INB_ORD_SUMMARY]: gdsRcptRtnDetailBoardShowWipInbOrdSummary,
  [GdsRcptRtnDetailBoardTypes.GDS_RCPT_RTN_DETAIL_BOARD_SET_WIP_INB_ORD_DIALOG_VISIBLE]: gdsRcptRtnDetailBoardSetWipInbOrdDialogVisible,
  [GdsRcptRtnDetailBoardTypes.GDS_RCPT_RTN_DETAIL_BOARD_SHOW_WIP_INB_ORD_SUMMARY_LOADING]: gdsRcptRtnDetailBoardShowWipInbOrdSummaryLoading,
  [GdsRcptRtnDetailBoardTypes.GDS_RCPT_RTN_DETAIL_BOARD_SHOW_WIP_INB_ORD_SUMMARY_SUCCESS]: gdsRcptRtnDetailBoardShowWipInbOrdSummarySuccess,

  [GdsRcptRtnDetailBoardTypes.GDS_RCPT_RTN_DETAIL_BOARD_SHOW_COMPLETE_GDS_RCPT_SUMMARY]: gdsRcptRtnDetailBoardShowCompleteGdsRcptSummary,
  [GdsRcptRtnDetailBoardTypes.GDS_RCPT_RTN_DETAIL_BOARD_SET_COMPLETE_GDS_RCPT_DIALOG_VISIBLE]: gdsRcptRtnDetailBoardSetCompleteGdsRcptDialogVisible,
  [GdsRcptRtnDetailBoardTypes.GDS_RCPT_RTN_DETAIL_BOARD_SHOW_COMPLETE_GDS_RCPT_SUMMARY_LOADING]: gdsRcptRtnDetailBoardShowCompleteGdsRcptSummaryLoading,
  [GdsRcptRtnDetailBoardTypes.GDS_RCPT_RTN_DETAIL_BOARD_SHOW_COMPLETE_GDS_RCPT_SUMMARY_SUCCESS]: gdsRcptRtnDetailBoardShowCompleteGdsRcptSummarySuccess
});
