import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  whseJob03IndexResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  whseJob03IndexGoToWhseJobDocument: ['hdrId'],
  whseJob03IndexGoToAudit: ['hdrId'],
  whseJob03IndexFetchWhseJob03Index: ['currentPage', 'sorts', 'filters', 'pageSize'],
  whseJob03IndexFetchWhseJob03IndexLoading: ['boolean'],
  whseJob03IndexFetchWhseJob03IndexSuccess: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],
  whseJob03IndexAddSelectedDocuments: ['selectedDocuments'],
  whseJob03IndexRemoveSelectedDocuments: ['selectedDocuments'],
  whseJob03IndexSetWorkspaceVisible: ['boolean']
});

export const WhseJob03IndexTypes = Types;
export default Creators;
