import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  gdsIssDetailResetTimestamp: null,
  gdsIssDetailSetHdrId: ['hdrId'],

  gdsIssDetailInitHeader: null,
  gdsIssDetailShowHeader: ['hdrId'],
  gdsIssDetailShowHeaderSuccess: ['documentHeader'],

  gdsIssDetailUpdateHeader: ['formikBag', 'documentHeader'],
  gdsIssDetailCreateHeader: ['formikBag', 'documentHeader'],
  gdsIssDetailUpdateDocumentSuccess: ['documentHeader', 'documentDetails'],

  gdsIssDetailShowDocumentLoading: ['boolean'],
  gdsIssDetailTransitionToStatus: ['formikBag', 'hdrId', 'docStatus', 'resetMode'],

  gdsIssDetailShowDetails: ['hdrId'],
  gdsIssDetailShowDetailsSuccess: ['documentDetails'],

  gdsIssDetailSetDetailVisible: ['boolean'],
  gdsIssDetailSetDocumentDetail: ['documentDetail'],

  gdsIssDetailUpdateDetails: ['formikBag', 'hdrId', 'documentDetails'],
  gdsIssDetailCreateDetail: ['formikBag', 'hdrId', 'documentDetail'],
  gdsIssDetailDeleteDetail: ['hdrId', 'documentDetail'],

  gdsIssDetailSetWhseJobType: ['whseJobType'],

  gdsIssDetailFetchCompanyOptions: ['search'],
  gdsIssDetailFetchCompanyOptionLoading: ['boolean'],
  gdsIssDetailFetchCompanyOptionSuccess: ['options'],

  gdsIssDetailFetchFrStorageBinOptions: ['search'],
  gdsIssDetailFetchFrStorageBinOptionLoading: ['boolean'],
  gdsIssDetailFetchFrStorageBinOptionSuccess: ['options'],

  gdsIssDetailFetchQuantBalOptions: ['companyId', 'storageBinId', 'itemId', 'search'],
  gdsIssDetailFetchQuantBalOptionLoading: ['boolean'],
  gdsIssDetailFetchQuantBalOptionSuccess: ['options'],
  gdsIssDetailChangeQuantBal: ['formikBag', 'hdrId', 'quantBalId'],

  gdsIssDetailFetchUomOptions: ['itemId', 'search'],
  gdsIssDetailFetchUomOptionLoading: ['boolean'],
  gdsIssDetailFetchUomOptionSuccess: ['options'],
  gdsIssDetailChangeUom: ['formikBag', 'hdrId', 'itemId', 'uomId']
});

export const GdsIssDetailTypes = Types;
export default Creators;
