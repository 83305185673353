/**
 * The initial values for the redux state.
 */
export default {
  timestamp: 0,
  hdrId: 0,
  documentIsLoading: false,
  documentDetail: [],
  workspaceIsVisible: false,

  fetchIsLoading: false,
  sorts: {
    doc_code: 'descend'
  },
  filters: {},
  pageSize: '20',
  currentPage: 1,
  lastPage: 10,
  total: 0,

  documentDetails: [],
  expandedRows: []
};
