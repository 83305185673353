/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { LayerFarmExcelTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const layerFarmExcelShowBatchJobStatusSuccess = (state, { batchJobStatus }) => ({
  ...state,
  batchJobStatus
});

export const layerFarmExcelUploadLoading = (state, { boolean }) => ({
  ...state,
  uploadIsLoading: boolean
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [LayerFarmExcelTypes.LAYER_FARM_EXCEL_SHOW_BATCH_JOB_STATUS_SUCCESS]: layerFarmExcelShowBatchJobStatusSuccess,
  [LayerFarmExcelTypes.LAYER_FARM_EXCEL_UPLOAD_LOADING]: layerFarmExcelUploadLoading
});
