import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { injectIntl } from 'react-intl';
import treeChanges from 'tree-changes';
import moment from 'moment';

import { Card, Typography } from 'antd';
// import CriteriaForm from './CriteriaForm';

import WorkerPerformanceChartActions from '../../Stores/WorkerPerformanceChart/Actions';
import WorkerPerformanceChart from './WorkerPerformanceChart';
import CriteriaForm from './CriteriaForm';

const { Text } = Typography;

class WorkerPerformanceChartScreen extends React.PureComponent {
  componentDidMount() {
    const { curSiteFlowId, resetTimestamp, criteria } = this.props;

    if (curSiteFlowId > 0) {
      resetTimestamp(criteria);
    }
  }

  componentDidUpdate(prevProps) {
    const { fetchWorkerPerformanceData, curSiteFlowId, resetTimestamp, criteria } = this.props;

    const { changed } = treeChanges(prevProps, this.props);

    if (changed('curSiteFlowId')) {
      resetTimestamp(criteria);
    }

    if (changed('timestamp')) {
      if (curSiteFlowId > 0) {
        fetchWorkerPerformanceData(criteria);
      }
    }
  }

  render() {
    const { intl, timestamp } = this.props;

    return (
      <Card
        title={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <>
            <Text>{intl.formatMessage({ id: 'worker_performance' })}</Text>
            <br />
            <small>
              {`${intl.formatMessage({ id: 'last_updated' })} ${moment(timestamp).format(
                'YYYY-MM-DD HH:mm:ss'
              )}`}
            </small>
          </>
        }
      >
        <CriteriaForm />
        <WorkerPerformanceChart />
      </Card>
    );
  }
}

WorkerPerformanceChartScreen.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  timestamp: PropTypes.number,
  // eslint-disable-next-line react/forbid-prop-types
  criteria: PropTypes.object,

  resetTimestamp: PropTypes.func,
  fetchWorkerPerformanceData: PropTypes.func,

  curSiteFlowId: PropTypes.number
};

WorkerPerformanceChartScreen.defaultProps = {
  intl: {},
  criteria: {},
  timestamp: 0,

  resetTimestamp() {},
  fetchWorkerPerformanceData: () => {},

  curSiteFlowId: 0
};

const mapStateToProps = state => {
  return {
    criteria: state.workerPerformanceChart.criteria,
    timestamp: state.workerPerformanceChart.workerPerformanceChartTimestamp,

    curSiteFlowId: state.app.curSiteFlowId
  };
};

const mapDispatchToProps = dispatch => ({
  resetTimestamp: criteria =>
    dispatch(WorkerPerformanceChartActions.workerPerformanceChartResetTimestamp(criteria)),
  fetchWorkerPerformanceData: criteria =>
    dispatch(WorkerPerformanceChartActions.workerPerformanceChartFetch(criteria))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(WorkerPerformanceChartScreen));
