import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  shipmentTrackingReportInitShipmentTracking: null,
  shipmentTrackingReportInitShipmentTrackingSuccess: ['criteria'],
  shipmentTrackingReportShipmentTracking: ['formikBag', 'criteria'],
  shipmentTrackingReportShipmentTrackingSuccess: ['criteria', 'reportData'],
  shipmentTrackingReportReportLoading: ['boolean'],

  shipmentTrackingReportFetchDivisionOptions: ['search'],
  shipmentTrackingReportFetchDivisionOptionLoading: ['boolean'],
  shipmentTrackingReportFetchDivisionOptionSuccess: ['options'],

  shipmentTrackingReportFetchDeliveryPointOptions: ['search'],
  shipmentTrackingReportFetchDeliveryPointOptionLoading: ['boolean'],
  shipmentTrackingReportFetchDeliveryPointOptionSuccess: ['options'],

  shipmentTrackingReportFetchDebtorOptions: ['search'],
  shipmentTrackingReportFetchDebtorOptionLoading: ['boolean'],
  shipmentTrackingReportFetchDebtorOptionSuccess: ['options'],

  shipmentTrackingReportFetchTransportOptions: ['search'],
  shipmentTrackingReportFetchTransportOptionLoading: ['boolean'],
  shipmentTrackingReportFetchTransportOptionSuccess: ['options']
});

export const ShipmentTrackingReportTypes = Types;
export default Creators;
