import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  countAdjDetailResetTimestamp: null,
  countAdjDetailSetHdrId: ['hdrId'],

  countAdjDetailInitHeader: null,
  countAdjDetailShowHeader: ['hdrId'],
  countAdjDetailShowHeaderSuccess: ['documentHeader'],

  countAdjDetailUpdateHeader: ['formikBag', 'documentHeader'],
  countAdjDetailCreateHeader: ['formikBag', 'documentHeader'],
  countAdjDetailUpdateDocumentSuccess: ['documentHeader', 'documentDetails'],

  countAdjDetailShowDocumentLoading: ['boolean'],
  countAdjDetailTransitionToStatus: ['formikBag', 'hdrId', 'docStatus'],

  countAdjDetailShowDetails: ['hdrId'],
  countAdjDetailShowDetailsSuccess: ['documentDetails'],

  countAdjDetailSetDetailVisible: ['boolean'],
  countAdjDetailSetJobDetailVisible: ['boolean'],
  countAdjDetailSetDocumentDetail: ['documentDetail'],

  countAdjDetailUpdateDetails: ['formikBag', 'hdrId', 'documentDetails'],
  countAdjDetailCreateDetail: ['formikBag', 'hdrId', 'documentDetail'],
  countAdjDetailDeleteDetail: ['hdrId', 'documentDetail'],

  countAdjDetailFetchCompanyOptions: ['search'],
  countAdjDetailFetchCompanyOptionLoading: ['boolean'],
  countAdjDetailFetchCompanyOptionSuccess: ['options'],

  countAdjDetailFetchCountAdjReasonOptions: ['search'],
  countAdjDetailFetchCountAdjReasonOptionLoading: ['boolean'],
  countAdjDetailFetchCountAdjReasonOptionSuccess: ['options'],

  countAdjDetailFetchItemOptions: ['search'],
  countAdjDetailFetchItemOptionLoading: ['boolean'],
  countAdjDetailFetchItemOptionSuccess: ['options'],
  countAdjDetailChangeItem: ['formikBag', 'hdrId', 'itemId'],

  countAdjDetailFetchItemBatchOptions: ['itemId', 'search'],
  countAdjDetailFetchItemBatchOptionLoading: ['boolean'],
  countAdjDetailFetchItemBatchOptionSuccess: ['options'],
  countAdjDetailChangeItemBatch: ['formikBag', 'hdrId', 'itemBatchId'],

  countAdjDetailFetchUomOptions: ['itemId', 'search'],
  countAdjDetailFetchUomOptionLoading: ['boolean'],
  countAdjDetailFetchUomOptionSuccess: ['options'],
  countAdjDetailChangeUom: ['formikBag', 'hdrId', 'itemId', 'uomId'],

  countAdjDetailFetchStorageBinOptions: ['search'],
  countAdjDetailFetchStorageBinOptionLoading: ['boolean'],
  countAdjDetailFetchStorageBinOptionSuccess: ['options'],

  countAdjDetailFetchHandlingUnitOptions: ['search'],
  countAdjDetailFetchHandlingUnitOptionLoading: ['boolean'],
  countAdjDetailFetchHandlingUnitOptionSuccess: ['options'],

  countAdjDetailFetchStorageRowOptions: ['search'],
  countAdjDetailFetchStorageRowOptionLoading: ['boolean'],
  countAdjDetailFetchStorageRowOptionSuccess: ['options'],

  countAdjDetailFetchStorageBayOptions: ['search'],
  countAdjDetailFetchStorageBayOptionLoading: ['boolean'],
  countAdjDetailFetchStorageBayOptionSuccess: ['options'],

  countAdjDetailFetchQuantBalOptions: ['companyId', 'storageBinId', 'search'],
  countAdjDetailFetchQuantBalOptionLoading: ['boolean'],
  countAdjDetailFetchQuantBalOptionSuccess: ['options'],
  countAdjDetailChangeQuantBal: ['formikBag', 'hdrId', 'quantBalId'],

  countAdjDetailUploadExcel: ['hdrId', 'file'],
  countAdjDetailUploadLoading: ['boolean'],
  countAdjDetailDownloadExcel: ['hdrId']
});

export const CountAdjDetailTypes = Types;
export default Creators;
