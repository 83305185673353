/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */
import { createReducer } from 'reduxsauce';
import { LspSlsRtnIndexTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const lspSlsRtnIndexResetTimestamp = (state, { currentPage, sorts, filters, pageSize }) => ({
  ...state,
  currentPage: currentPage || state.currentPage,
  sorts: sorts || state.sorts,
  filters: filters || state.filters,
  pageSize: pageSize || state.pageSize,
  timestamp: Date.now(),
  selectedDocuments: []
});

export const lspSlsRtnIndexFetchLspSlsRtnIndexLoading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const lspSlsRtnIndexFetchLspSlsRtnIndexSuccess = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  documents,
  currentPage,
  lastPage,
  total,
  pageSize
});

export const lspSlsRtnIndexAddSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = state.selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: [
      ...state.selectedDocuments,
      ...selectedDocuments.filter(data => {
        return !selectIds.includes(data.id);
      })
    ]
  };
};

export const lspSlsRtnIndexRemoveSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: state.selectedDocuments.filter(data => {
      return !selectIds.includes(data.id);
    })
  };
};

export const lspSlsRtnIndexSetWorkspaceVisible = (state, { boolean }) => ({
  ...state,
  workspaceIsVisible: boolean
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [LspSlsRtnIndexTypes.LSP_SLS_RTN_INDEX_RESET_TIMESTAMP]: lspSlsRtnIndexResetTimestamp,
  [LspSlsRtnIndexTypes.LSP_SLS_RTN_INDEX_FETCH_LSP_SLS_RTN_INDEX_LOADING]: lspSlsRtnIndexFetchLspSlsRtnIndexLoading,
  [LspSlsRtnIndexTypes.LSP_SLS_RTN_INDEX_FETCH_LSP_SLS_RTN_INDEX_SUCCESS]: lspSlsRtnIndexFetchLspSlsRtnIndexSuccess,
  [LspSlsRtnIndexTypes.LSP_SLS_RTN_INDEX_ADD_SELECTED_DOCUMENTS]: lspSlsRtnIndexAddSelectedDocuments,
  [LspSlsRtnIndexTypes.LSP_SLS_RTN_INDEX_REMOVE_SELECTED_DOCUMENTS]: lspSlsRtnIndexRemoveSelectedDocuments,
  [LspSlsRtnIndexTypes.LSP_SLS_RTN_INDEX_SET_WORKSPACE_VISIBLE]: lspSlsRtnIndexSetWorkspaceVisible
});
