import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import treeChanges from 'tree-changes';
import { Link } from 'react-router-dom';

import { Col, Row, Statistic, Spin } from 'antd';
import { Card } from 'react-bootstrap';

import SummaryBoardActions from '../../Stores/SummaryBoard/Actions';
import './SummaryBoardStyle.css';

class SlsOrdSummaryCard extends React.PureComponent {
  componentDidMount() {
    const {
      resetTimestampSlsOrds,
      resetTimestampOutbOrds,
      resetTimestampInvDocs,
      resetTimestampNoPrfDels
    } = this.props;

    const { curSiteFlowId } = this.props;

    if (curSiteFlowId > 0) {
      resetTimestampSlsOrds();
      resetTimestampOutbOrds();
      resetTimestampInvDocs();
      resetTimestampNoPrfDels();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      curSiteFlowId,
      fetchSummarySlsOrds,
      fetchSummaryOutbOrds,
      fetchSummaryInvDocs,
      fetchSummaryNoPrfDels
    } = this.props;

    const {
      resetTimestampSlsOrds,
      resetTimestampOutbOrds,
      resetTimestampInvDocs,
      resetTimestampNoPrfDels
    } = this.props;

    const { changed } = treeChanges(prevProps, this.props);

    if (changed('curSiteFlowId')) {
      resetTimestampSlsOrds();
      resetTimestampOutbOrds();
      resetTimestampInvDocs();
      resetTimestampNoPrfDels();
    }

    if (changed('summarySlsOrdsTimestamp')) {
      if (curSiteFlowId > 0) {
        fetchSummarySlsOrds();
      }
    }

    if (changed('summaryOutbOrdsTimestamp')) {
      if (curSiteFlowId > 0) {
        fetchSummaryOutbOrds();
      }
    }

    if (changed('summaryInvDocsTimestamp')) {
      if (curSiteFlowId > 0) {
        fetchSummaryInvDocs();
      }
    }

    if (changed('summaryNoPrfDelsTimestamp')) {
      if (curSiteFlowId > 0) {
        fetchSummaryNoPrfDels();
      }
    }
  }

  render() {
    const { summarySlsOrds, summaryOutbOrds, summaryInvDocs, summaryNoPrfDels } = this.props;

    const { intl } = this.props;

    const {
      summarySlsOrdsIsLoading,
      summaryOutbOrdsIsLoading,
      summaryInvDocsIsLoading,
      summaryNoPrfDelsIsLoading
    } = this.props;

    return (
      <Link to="/slsOrdDetailBoard" style={{ textDecoration: 'none' }}>
        <Card className="summary-card" text="dark">
          <Card.Header className="sls-ord-header">
            {intl.formatMessage({ id: 'sls_ord_summary' })}
          </Card.Header>
          <Row>
            <Col span={12} style={summarySlsOrdsIsLoading ? { paddingTop: '10%' } : {}}>
              <Card.Body>
                {summarySlsOrdsIsLoading ? (
                  <Spin />
                ) : (
                  <div>
                    <Statistic
                      title={intl.formatMessage({ id: 'sales_orders' })}
                      value={summarySlsOrds.total}
                    />
                    <div style={{ color: 'red' }}>{summarySlsOrds.date_oldest}</div>
                  </div>
                )}
              </Card.Body>
            </Col>
            <Col span={12} style={summaryOutbOrdsIsLoading ? { paddingTop: '10%' } : {}}>
              <Card.Body>
                {summaryOutbOrdsIsLoading ? (
                  <Spin />
                ) : (
                  <div>
                    <Statistic
                      title={intl.formatMessage({ id: 'outbound_orders' })}
                      value={summaryOutbOrds.total}
                    />
                    <div style={{ color: 'red' }}>{summaryOutbOrds.date_oldest}</div>
                  </div>
                )}
              </Card.Body>
            </Col>
            <Col span={12} style={summaryInvDocsIsLoading ? { paddingBottom: '10%' } : {}}>
              <Card.Body>
                {summaryInvDocsIsLoading ? (
                  <Spin />
                ) : (
                  <div>
                    <Statistic
                      title={intl.formatMessage({ id: 'invoices' })}
                      value={summaryInvDocs.total}
                    />
                    <div style={{ color: 'red' }}>{summaryInvDocs.date_oldest}</div>
                  </div>
                )}
              </Card.Body>
            </Col>
            <Col span={12} style={summaryNoPrfDelsIsLoading ? { paddingBottom: '10%' } : {}}>
              <Card.Body>
                {summaryNoPrfDelsIsLoading ? (
                  <Spin />
                ) : (
                  <div>
                    <Statistic
                      title={intl.formatMessage({ id: 'deliveries' })}
                      value={summaryNoPrfDels.total}
                    />
                    <div style={{ color: 'red' }}>{summaryNoPrfDels.date_oldest}</div>
                  </div>
                )}
              </Card.Body>
            </Col>
          </Row>
        </Card>
      </Link>
    );
  }
}

SlsOrdSummaryCard.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,

  // eslint-disable-next-line react/forbid-prop-types
  summarySlsOrds: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  summaryOutbOrds: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  summaryInvDocs: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  summaryNoPrfDels: PropTypes.object,

  summarySlsOrdsIsLoading: PropTypes.bool,
  summaryOutbOrdsIsLoading: PropTypes.bool,
  summaryInvDocsIsLoading: PropTypes.bool,
  summaryNoPrfDelsIsLoading: PropTypes.bool,

  curSiteFlowId: PropTypes.number,
  fetchSummarySlsOrds: PropTypes.func,
  fetchSummaryOutbOrds: PropTypes.func,
  fetchSummaryInvDocs: PropTypes.func,
  fetchSummaryNoPrfDels: PropTypes.func,

  resetTimestampSlsOrds: PropTypes.func,
  resetTimestampOutbOrds: PropTypes.func,
  resetTimestampInvDocs: PropTypes.func,
  resetTimestampNoPrfDels: PropTypes.func
};

SlsOrdSummaryCard.defaultProps = {
  intl: {},

  summarySlsOrds: { total: 0, date_oldest: '0 days' },
  summaryOutbOrds: { total: 0, date_oldest: '0 days' },
  summaryInvDocs: { total: 0, date_oldest: '0 days' },
  summaryNoPrfDels: { total: 0, date_oldest: '0 days' },

  summarySlsOrdsIsLoading: false,
  summaryOutbOrdsIsLoading: false,
  summaryInvDocsIsLoading: false,
  summaryNoPrfDelsIsLoading: false,

  curSiteFlowId: 0,
  fetchSummarySlsOrds() {},
  fetchSummaryOutbOrds() {},
  fetchSummaryInvDocs() {},
  fetchSummaryNoPrfDels() {},

  resetTimestampSlsOrds() {},
  resetTimestampOutbOrds() {},
  resetTimestampInvDocs() {},
  resetTimestampNoPrfDels() {}
};

const mapStateToProps = state => ({
  summarySlsOrdsTimestamp: state.summaryBoard.slsOrdSummarySlsOrdsTimestamp,
  summarySlsOrds: state.summaryBoard.slsOrdSummarySlsOrds,

  summaryOutbOrdsTimestamp: state.summaryBoard.slsOrdSummaryOutbOrdsTimestamp,
  summaryOutbOrds: state.summaryBoard.slsOrdSummaryOutbOrds,

  summaryInvDocsTimestamp: state.summaryBoard.slsOrdSummaryInvDocsTimestamp,
  summaryInvDocs: state.summaryBoard.slsOrdSummaryInvDocs,

  summaryNoPrfDelsTimestamp: state.summaryBoard.slsOrdSummaryNoPrfDelsTimestamp,
  summaryNoPrfDels: state.summaryBoard.slsOrdSummaryNoPrfDels,

  summarySlsOrdsIsLoading: state.summaryBoard.slsOrdSummarySlsOrdsIsLoading,
  summaryOutbOrdsIsLoading: state.summaryBoard.slsOrdSummaryOutbOrdsIsLoading,
  summaryInvDocsIsLoading: state.summaryBoard.slsOrdSummaryInvDocsIsLoading,
  summaryNoPrfDelsIsLoading: state.summaryBoard.slsOrdSummaryNoPrfDelsIsLoading,

  curSiteFlowId: state.app.curSiteFlowId
});

const mapDispatchToProps = dispatch => ({
  resetTimestampSlsOrds: () =>
    dispatch(SummaryBoardActions.slsOrdSummaryBoardResetSlsOrdsTimestamp()),

  fetchSummarySlsOrds: () => dispatch(SummaryBoardActions.slsOrdSummaryBoardFetchSlsOrds()),

  resetTimestampOutbOrds: () =>
    dispatch(SummaryBoardActions.slsOrdSummaryBoardResetOutbOrdsTimestamp()),

  fetchSummaryOutbOrds: () => dispatch(SummaryBoardActions.slsOrdSummaryBoardFetchOutbOrds()),

  resetTimestampInvDocs: () =>
    dispatch(SummaryBoardActions.slsOrdSummaryBoardResetInvDocsTimestamp()),

  fetchSummaryInvDocs: () => dispatch(SummaryBoardActions.slsOrdSummaryBoardFetchInvDocs()),

  resetTimestampNoPrfDels: () =>
    dispatch(SummaryBoardActions.slsOrdSummaryBoardResetNoPrfDelsTimestamp()),

  fetchSummaryNoPrfDels: () => dispatch(SummaryBoardActions.slsOrdSummaryBoardFetchNoPrfDels())
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SlsOrdSummaryCard));
