import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  transferInTrackingReportInitTransferInTracking: null,
  transferInTrackingReportInitTransferInTrackingSuccess: ['criteria'],
  transferInTrackingReportTransferInTracking: ['formikBag', 'criteria'],
  transferInTrackingReportTransferInTrackingSuccess: ['criteria', 'reportData'],
  transferInTrackingReportReportLoading: ['boolean'],

  transferInTrackingReportFetchDivisionOptions: ['search'],
  transferInTrackingReportFetchDivisionOptionLoading: ['boolean'],
  transferInTrackingReportFetchDivisionOptionSuccess: ['options'],

  transferInTrackingReportFetchFrLocationOptions: ['search'],
  transferInTrackingReportFetchFrLocationOptionLoading: ['boolean'],
  transferInTrackingReportFetchFrLocationOptionSuccess: ['options']
});

export const TransferInTrackingReportTypes = Types;
export default Creators;
