import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  gdsRtnDetailReportInitGdsRtnDetail: null,
  gdsRtnDetailReportInitGdsRtnDetailSuccess: ['criteria'],
  gdsRtnDetailReportGdsRtnDetail: ['formikBag', 'criteria'],
  gdsRtnDetailReportGdsRtnDetailSuccess: ['criteria', 'reportData'],
  gdsRtnDetailReportReportLoading: ['boolean'],

  gdsRtnDetailReportFetchDivisionOptions: ['search'],
  gdsRtnDetailReportFetchDivisionOptionLoading: ['boolean'],
  gdsRtnDetailReportFetchDivisionOptionSuccess: ['options'],

  gdsRtnDetailReportFetchDeliveryPointOptions: ['search'],
  gdsRtnDetailReportFetchDeliveryPointOptionLoading: ['boolean'],
  gdsRtnDetailReportFetchDeliveryPointOptionSuccess: ['options'],

  gdsRtnDetailReportFetchDebtorOptions: ['search'],
  gdsRtnDetailReportFetchDebtorOptionLoading: ['boolean'],
  gdsRtnDetailReportFetchDebtorOptionSuccess: ['options']
});

export const GdsRtnDetailReportTypes = Types;
export default Creators;
