/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { AreaDetailTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const areaDetailResetTimestamp = state => ({
  ...state,
  timestamp: Date.now()
});

export const areaDetailSetResId = (state, { resId }) => ({
  ...state,
  timestamp: Date.now(),
  modelIsLoading: false,
  resId
});

export const areaDetailShowModelLoading = (state, { boolean }) => ({
  ...state,
  modelIsLoading: boolean
});

export const areaDetailShowModelSuccess = (state, { model }) => ({
  ...state,
  model
});

export const areaDetailUpdateModelSuccess = (state, { model }) => ({
  ...state,
  model
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [AreaDetailTypes.AREA_DETAIL_RESET_TIMESTAMP]: areaDetailResetTimestamp,
  [AreaDetailTypes.AREA_DETAIL_SET_RES_ID]: areaDetailSetResId,
  [AreaDetailTypes.AREA_DETAIL_SHOW_MODEL_LOADING]: areaDetailShowModelLoading,
  [AreaDetailTypes.AREA_DETAIL_SHOW_MODEL_SUCCESS]: areaDetailShowModelSuccess,
  [AreaDetailTypes.AREA_DETAIL_UPDATE_MODEL_SUCCESS]: areaDetailUpdateModelSuccess
});
