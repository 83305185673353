import { put, call, select, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import { replace } from 'connected-react-router';
import ApiService from '../Services/ApiService';
import UtilService from '../Services/UtilService';
import AppActions from '../Stores/App/Actions';
import TrfOrdDetailActions, { TrfOrdDetailTypes } from '../Stores/TrfOrdDetail/Actions';

const getAppStore = state => state.app;

const getTrfOrdDetailStore = state => state.trfOrdDetail;

export function* trfOrdDetailInitHeader({ docSubType }) {
  try {
    yield put(TrfOrdDetailActions.trfOrdDetailShowDocumentLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `trfOrd/initHeader/${app.curDivisionId}/${docSubType}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(TrfOrdDetailActions.trfOrdDetailShowHeaderSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(TrfOrdDetailActions.trfOrdDetailShowDocumentLoading(false));
  }
}

export function* trfOrdDetailShowHeader({ hdrId }) {
  try {
    yield put(TrfOrdDetailActions.trfOrdDetailShowDocumentLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `trfOrd/showHeader/${hdrId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(TrfOrdDetailActions.trfOrdDetailShowHeaderSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(TrfOrdDetailActions.trfOrdDetailShowDocumentLoading(false));
  }
}

export function* trfOrdDetailTransitionToStatus({ formikBag, hdrId, docStatus }) {
  formikBag.setSubmitting(true);
  yield put(TrfOrdDetailActions.trfOrdDetailShowDocumentLoading(true));
  try {
    const app = yield select(getAppStore);

    const postData = {
      hdrId,
      docStatus
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      'trfOrd/transitionToStatus',
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      const trfOrdDetail = yield select(getTrfOrdDetailStore);

      const {
        documentHeader: oldDocumentHeader,
        documentDetails: oldDocumentDetails
      } = trfOrdDetail;

      const {
        document_header: retDocumentHeader,
        document_details: retDocumentDetails,
        deleted_details: retDeletedDetails
      } = result.data;

      const processed = UtilService.processHeaderDetails(
        oldDocumentHeader,
        oldDocumentDetails,
        retDocumentHeader,
        retDocumentDetails,
        retDeletedDetails
      );

      yield put(
        TrfOrdDetailActions.trfOrdDetailUpdateDocumentSuccess(retDocumentHeader, processed.details)
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
    yield put(TrfOrdDetailActions.trfOrdDetailShowDocumentLoading(false));
  }
}

export function* trfOrdDetailUpdateHeader({ formikBag, documentHeader }) {
  formikBag.setSubmitting(true);
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: documentHeader
    };

    const result = yield call(
      ApiService.putApi, // function
      app.apiUrl,
      'trfOrd/updateHeader',
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      const trfOrdDetail = yield select(getTrfOrdDetailStore);

      const {
        documentHeader: oldDocumentHeader,
        documentDetails: oldDocumentDetails
      } = trfOrdDetail;

      const {
        document_header: retDocumentHeader,
        document_details: retDocumentDetails,
        deleted_details: retDeletedDetails
      } = result.data;

      const processed = UtilService.processHeaderDetails(
        oldDocumentHeader,
        oldDocumentDetails,
        retDocumentHeader,
        retDocumentDetails,
        retDeletedDetails
      );

      yield put(
        TrfOrdDetailActions.trfOrdDetailUpdateDocumentSuccess(retDocumentHeader, processed.details)
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* trfOrdDetailShowDetails({ hdrId }) {
  try {
    yield put(TrfOrdDetailActions.trfOrdDetailShowDocumentLoading(true));

    let result = {
      isSuccess: true,
      data: []
    };

    if (hdrId > 0) {
      const app = yield select(getAppStore);
      const getData = {};

      result = yield call(
        ApiService.getApi, // function
        app.apiUrl,
        `trfOrd/showDetails/${hdrId}`,
        app.token,
        getData,
        'multipart/form-data' // params
      );
    }

    if (result.isSuccess === true) {
      yield put(TrfOrdDetailActions.trfOrdDetailShowDetailsSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(TrfOrdDetailActions.trfOrdDetailShowDocumentLoading(false));
  }
}

export function* trfOrdDetailUpdateDetails({ formikBag, hdrId, documentDetails }) {
  formikBag.setSubmitting(true);
  yield put(TrfOrdDetailActions.trfOrdDetailShowDocumentLoading(true));
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: documentDetails
    };

    const result = yield call(
      ApiService.putApi, // function
      app.apiUrl,
      `trfOrd/updateDetails/${hdrId}`,
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      const trfOrdDetail = yield select(getTrfOrdDetailStore);

      const {
        documentHeader: oldDocumentHeader,
        documentDetails: oldDocumentDetails
      } = trfOrdDetail;

      const {
        document_header: retDocumentHeader,
        document_details: retDocumentDetails,
        deleted_details: retDeletedDetails
      } = result.data;

      const processed = UtilService.processHeaderDetails(
        oldDocumentHeader,
        oldDocumentDetails,
        retDocumentHeader,
        retDocumentDetails,
        retDeletedDetails
      );

      yield put(
        TrfOrdDetailActions.trfOrdDetailUpdateDocumentSuccess(retDocumentHeader, processed.details)
      );

      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_SUCCESS_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
    yield put(TrfOrdDetailActions.trfOrdDetailShowDocumentLoading(false));
  }
}

export function* trfOrdDetailFetchCurrencyOptions({ search }) {
  try {
    yield put(TrfOrdDetailActions.trfOrdDetailFetchCurrencyOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `currency/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code}`
      }));

      yield put(TrfOrdDetailActions.trfOrdDetailFetchCurrencyOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(TrfOrdDetailActions.trfOrdDetailFetchCurrencyOptionLoading(false));
  }
}

export function* trfOrdDetailChangeCurrency({ formikBag, currencyId }) {
  try {
    formikBag.setSubmitting(true);

    const app = yield select(getAppStore);
    const postData = {
      currencyId
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `trfOrd/changeCurrency`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      Object.entries(result.data).forEach(entry => {
        const key = entry[0];
        const value = entry[1];
        formikBag.setFieldValue(key, value);
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* trfOrdDetailFetchToDivisionOptions({ docSubType, search }) {
  try {
    yield put(TrfOrdDetailActions.trfOrdDetailFetchToDivisionOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `trfOrd/divisionSelect2/${app.curSiteFlowId}/${docSubType}`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code}`
      }));

      yield put(TrfOrdDetailActions.trfOrdDetailFetchToDivisionOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(TrfOrdDetailActions.trfOrdDetailFetchToDivisionOptionLoading(false));
  }
}

export function* trfOrdDetailFetchToLocationOptions({ hdrId, docSubType, search }) {
  try {
    yield put(TrfOrdDetailActions.trfOrdDetailFetchToLocationOptionLoading(true));

    let filters = [];
    if (docSubType === 1) {
      // SITE_TRANSFER
      filters = [
        {
          field: 'location_types',
          value: [1, 2]
        }
      ];
    } else if (docSubType === 2) {
      // TRANSFER_TO_STORE
      filters = [
        {
          field: 'location_types',
          value: [
            4 // STORE_STOCK
          ]
        }
      ];
    } else if (docSubType === 3) {
      // TRANSFER_TO_VAN
      filters = [
        {
          field: 'location_types',
          value: [
            5 // VAN_STOCK
          ]
        }
      ];
    }

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `trfOrd/locationSelect2/${hdrId}`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(TrfOrdDetailActions.trfOrdDetailFetchToLocationOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(TrfOrdDetailActions.trfOrdDetailFetchToLocationOptionLoading(false));
  }
}

export function* trfOrdDetailFetchHdrToLocationOptions({ hdrId, docSubType, search }) {
  try {
    yield put(TrfOrdDetailActions.trfOrdDetailFetchHdrToLocationOptionLoading(true));

    const trfOrdDetail = yield select(getTrfOrdDetailStore);

    const { to_division_id: toDivisionId } = trfOrdDetail;

    let filters = [];
    if (docSubType === 1) {
      // SITE_TRANSFER
      filters = [
        {
          field: 'location_types',
          value: [1, 2]
        }
      ];
    } else if (docSubType === 2) {
      // TRANSFER_TO_STORE
      filters = [
        {
          field: 'location_types',
          value: [
            4 // STORE_STOCK
          ]
        }
      ];
    } else if (docSubType === 3) {
      // TRANSFER_TO_VAN
      filters = [
        {
          field: 'location_types',
          value: [
            5 // VAN_STOCK
          ]
        }
      ];
    }

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId: app.curSiteFlowId,
      search,
      filters
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `trfOrd/locationSelect2/${hdrId}/${toDivisionId}`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(TrfOrdDetailActions.trfOrdDetailFetchHdrToLocationOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(TrfOrdDetailActions.trfOrdDetailFetchHdrToLocationOptionLoading(false));
  }
}

export function* trfOrdDetailCreateHeader({ formikBag, documentHeader }) {
  formikBag.setSubmitting(true);
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: documentHeader
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      'trfOrd/createHeader',
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      yield put(replace(`${app.appPath}/trfOrdDetail/update/${result.data}`));
      yield put(TrfOrdDetailActions.trfOrdDetailSetHdrId(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* trfOrdDetailFetchItemOptions({ search }) {
  try {
    yield put(TrfOrdDetailActions.trfOrdDetailFetchItemOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `item/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(TrfOrdDetailActions.trfOrdDetailFetchItemOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(TrfOrdDetailActions.trfOrdDetailFetchItemOptionLoading(false));
  }
}

export function* trfOrdDetailChangeItem({ formikBag, hdrId, itemId }) {
  try {
    formikBag.setSubmitting(true);

    const app = yield select(getAppStore);
    const postData = {
      hdrId,
      itemId
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `trfOrd/changeItem`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      Object.entries(result.data).forEach(entry => {
        const key = entry[0];
        const value = entry[1];
        formikBag.setFieldValue(key, value);
      });

      // reset the select2 cache
      formikBag.setFieldValue('uom_select2', {
        value: 0,
        label: ''
      });
      formikBag.setFieldValue('uom_rate', 1);

      yield put(TrfOrdDetailActions.trfOrdDetailFetchUomOptionSuccess([]));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* trfOrdDetailFetchItemBatchOptions({ itemId, search }) {
  try {
    yield put(TrfOrdDetailActions.trfOrdDetailFetchItemBatchOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: [
        {
          field: 'item_id',
          value: itemId
        }
      ]
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `itemBatch/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.batch_serial_no} | ${d.expiry_date} | ${d.receipt_date}`
      }));
      const finalOptions = [{ value: 0, label: '-' }, ...options];

      yield put(TrfOrdDetailActions.trfOrdDetailFetchItemBatchOptionSuccess(finalOptions));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(TrfOrdDetailActions.trfOrdDetailFetchItemBatchOptionLoading(false));
  }
}

export function* trfOrdDetailChangeItemBatch({ formikBag, hdrId, itemBatchId }) {
  try {
    formikBag.setSubmitting(true);

    const app = yield select(getAppStore);
    const postData = {
      hdrId,
      itemBatchId
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `trfOrd/changeItemBatch`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      Object.entries(result.data).forEach(entry => {
        const key = entry[0];
        const value = entry[1];
        formikBag.setFieldValue(key, value);
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* trfOrdDetailChangeUom({ formikBag, hdrId, itemId, uomId }) {
  try {
    formikBag.setSubmitting(true);

    const app = yield select(getAppStore);
    const postData = {
      hdrId,
      itemId,
      uomId
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `trfOrd/changeItemUom`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      Object.entries(result.data).forEach(entry => {
        const key = entry[0];
        const value = entry[1];
        formikBag.setFieldValue(key, value);
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* trfOrdDetailFetchUomOptions({ itemId, search }) {
  try {
    yield put(TrfOrdDetailActions.trfOrdDetailFetchUomOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: [
        {
          field: 'item_id',
          value: itemId
        }
      ]
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `itemUom/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.uom_id,
        label: `${d.uom_code}`
      }));

      yield put(TrfOrdDetailActions.trfOrdDetailFetchUomOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(TrfOrdDetailActions.trfOrdDetailFetchUomOptionLoading(false));
  }
}

export function* trfOrdDetailCreateDetail({ formikBag, hdrId, documentDetail }) {
  formikBag.setSubmitting(true);
  yield put(TrfOrdDetailActions.trfOrdDetailShowDocumentLoading(true));
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: documentDetail
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `trfOrd/createDetail/${hdrId}`,
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      const trfOrdDetail = yield select(getTrfOrdDetailStore);

      const {
        documentHeader: oldDocumentHeader,
        documentDetails: oldDocumentDetails
      } = trfOrdDetail;

      const {
        document_header: retDocumentHeader,
        document_details: retDocumentDetails,
        deleted_details: retDeletedDetails
      } = result.data;

      const processed = UtilService.processHeaderDetails(
        oldDocumentHeader,
        oldDocumentDetails,
        retDocumentHeader,
        retDocumentDetails,
        retDeletedDetails
      );

      yield put(
        TrfOrdDetailActions.trfOrdDetailUpdateDocumentSuccess(retDocumentHeader, processed.details)
      );

      yield put(TrfOrdDetailActions.trfOrdDetailSetDocumentDetail(trfOrdDetail.initDocumentDetail));

      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_SUCCESS_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
    yield put(TrfOrdDetailActions.trfOrdDetailShowDocumentLoading(false));
  }
}

export function* trfOrdDetailDeleteDetail({ hdrId, documentDetail }) {
  yield put(TrfOrdDetailActions.trfOrdDetailShowDocumentLoading(true));
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: [{ id: documentDetail.id }]
    };

    const result = yield call(
      ApiService.deleteApi, // function
      app.apiUrl,
      `trfOrd/deleteDetails/${hdrId}`,
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      const trfOrdDetail = yield select(getTrfOrdDetailStore);

      const {
        documentHeader: oldDocumentHeader,
        documentDetails: oldDocumentDetails
      } = trfOrdDetail;

      const {
        document_header: retDocumentHeader,
        document_details: retDocumentDetails,
        deleted_details: retDeletedDetails
      } = result.data;

      const processed = UtilService.processHeaderDetails(
        oldDocumentHeader,
        oldDocumentDetails,
        retDocumentHeader,
        retDocumentDetails,
        retDeletedDetails
      );

      yield put(
        TrfOrdDetailActions.trfOrdDetailUpdateDocumentSuccess(retDocumentHeader, processed.details)
      );

      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_SUCCESS_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(TrfOrdDetailActions.trfOrdDetailShowDocumentLoading(false));
  }
}

export function* trfOrdDetailFetchLocationOptions({ docSubType, search }) {
  try {
    yield put(TrfOrdDetailActions.trfOrdDetailFetchLocationOptionLoading(true));

    let filters = [];
    if (docSubType === 4) {
      // TRANSFER_FROM_STORE
      filters = [
        {
          field: 'location_types',
          value: [
            4 // STORE_STOCK
          ]
        }
      ];
    } else if (docSubType === 5) {
      // TRANSFER_FROM_VAN
      filters = [
        {
          field: 'location_types',
          value: [
            5 // VAN_STOCK
          ]
        }
      ];
    }

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId: app.curSiteFlowId,
      search,
      filters
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `location/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(TrfOrdDetailActions.trfOrdDetailFetchLocationOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(TrfOrdDetailActions.trfOrdDetailFetchLocationOptionLoading(false));
  }
}

export function* trfOrdDetailFetchProjectOptions({ siteFlowId, search }) {
  try {
    yield put(TrfOrdDetailActions.trfOrdDetailFetchProjectOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId,
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `project/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(TrfOrdDetailActions.trfOrdDetailFetchProjectOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(TrfOrdDetailActions.trfOrdDetailFetchProjectOptionLoading(false));
  }
}

export const saga = [
  takeLatest(TrfOrdDetailTypes.TRF_ORD_DETAIL_INIT_HEADER, trfOrdDetailInitHeader),
  takeLatest(TrfOrdDetailTypes.TRF_ORD_DETAIL_SHOW_HEADER, trfOrdDetailShowHeader),

  takeLatest(TrfOrdDetailTypes.TRF_ORD_DETAIL_UPDATE_HEADER, trfOrdDetailUpdateHeader),
  takeLatest(TrfOrdDetailTypes.TRF_ORD_DETAIL_CREATE_HEADER, trfOrdDetailCreateHeader),

  takeLatest(TrfOrdDetailTypes.TRF_ORD_DETAIL_TRANSITION_TO_STATUS, trfOrdDetailTransitionToStatus),

  takeLatest(TrfOrdDetailTypes.TRF_ORD_DETAIL_SHOW_DETAILS, trfOrdDetailShowDetails),

  takeLatest(TrfOrdDetailTypes.TRF_ORD_DETAIL_UPDATE_DETAILS, trfOrdDetailUpdateDetails),
  takeLatest(TrfOrdDetailTypes.TRF_ORD_DETAIL_CREATE_DETAIL, trfOrdDetailCreateDetail),
  takeLatest(TrfOrdDetailTypes.TRF_ORD_DETAIL_DELETE_DETAIL, trfOrdDetailDeleteDetail),

  takeLatest(
    TrfOrdDetailTypes.TRF_ORD_DETAIL_FETCH_CURRENCY_OPTIONS,
    trfOrdDetailFetchCurrencyOptions
  ),
  takeLatest(TrfOrdDetailTypes.TRF_ORD_DETAIL_CHANGE_CURRENCY, trfOrdDetailChangeCurrency),

  takeLatest(
    TrfOrdDetailTypes.TRF_ORD_DETAIL_FETCH_TO_DIVISION_OPTIONS,
    trfOrdDetailFetchToDivisionOptions
  ),

  takeLatest(
    TrfOrdDetailTypes.TRF_ORD_DETAIL_FETCH_TO_LOCATION_OPTIONS,
    trfOrdDetailFetchToLocationOptions
  ),
  takeLatest(
    TrfOrdDetailTypes.TRF_ORD_DETAIL_FETCH_HDR_TO_LOCATION_OPTIONS,
    trfOrdDetailFetchHdrToLocationOptions
  ),

  takeLatest(
    TrfOrdDetailTypes.TRF_ORD_DETAIL_FETCH_LOCATION_OPTIONS,
    trfOrdDetailFetchLocationOptions
  ),

  takeLatest(TrfOrdDetailTypes.TRF_ORD_DETAIL_FETCH_ITEM_OPTIONS, trfOrdDetailFetchItemOptions),
  takeLatest(TrfOrdDetailTypes.TRF_ORD_DETAIL_CHANGE_ITEM, trfOrdDetailChangeItem),

  takeLatest(
    TrfOrdDetailTypes.TRF_ORD_DETAIL_FETCH_ITEM_BATCH_OPTIONS,
    trfOrdDetailFetchItemBatchOptions
  ),
  takeLatest(TrfOrdDetailTypes.TRF_ORD_DETAIL_CHANGE_ITEM_BATCH, trfOrdDetailChangeItemBatch),

  takeLatest(TrfOrdDetailTypes.TRF_ORD_DETAIL_CHANGE_UOM, trfOrdDetailChangeUom),
  takeLatest(TrfOrdDetailTypes.TRF_ORD_DETAIL_FETCH_UOM_OPTIONS, trfOrdDetailFetchUomOptions),

  takeLatest(
    TrfOrdDetailTypes.TRF_ORD_DETAIL_FETCH_PROJECT_OPTIONS,
    trfOrdDetailFetchProjectOptions
  )
];
