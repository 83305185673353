import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  advShipSync03ShowSyncSetting: null,
  advShipSync03ShowSyncSettingLoading: ['boolean'],
  advShipSync03ShowSyncSettingSuccess: ['syncSetting'],
  advShipSync03UpdateSyncSetting: ['formikBag', 'syncSetting'],
  advShipSync03ShowBatchJobStatus: null,
  advShipSync03ShowBatchJobStatusSuccess: ['batchJobStatus'],
  advShipSync03SyncNow: ['formikBag'],
  advShipSync03SyncLoading: ['boolean']
});

export const AdvShipSync03Types = Types;
export default Creators;
