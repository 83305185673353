/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { SlsOrdFServ02Types } from './Actions';
import INITIAL_STATE from './InitialState';

export const slsOrdFServ02ShowSyncSettingLoading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const slsOrdFServ02ShowSyncSettingSuccess = (state, { syncSetting }) => ({
  ...state,
  syncSetting
});

export const slsOrdFServ02ShowBatchJobStatusSuccess = (state, { batchJobStatus }) => ({
  ...state,
  batchJobStatus
});

export const slsOrdFServ02ExportLoading = (state, { boolean }) => ({
  ...state,
  exportIsLoading: boolean
});

export const slsOrdFServ02ResetTimestamp = (state, { currentPage, sorts, filters, pageSize }) => ({
  ...state,
  currentPage: currentPage || state.currentPage,
  sorts: sorts || state.sorts,
  filters: filters || state.filters,
  pageSize: pageSize || state.pageSize,
  timestamp: Date.now()
  // selectedDocuments: INITIAL_STATE.selectedDocuments
});

export const slsOrdFServ02FetchSlsOrdFServ02Loading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const slsOrdFServ02FetchSlsOrdFServ02Success = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  documents,
  currentPage,
  lastPage,
  total,
  pageSize
});

export const slsOrdFServ02AddSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = state.selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: [
      ...state.selectedDocuments,
      ...selectedDocuments.filter(data => {
        return !selectIds.includes(data.id);
      })
    ]
  };
};

export const slsOrdFServ02RemoveSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: state.selectedDocuments.filter(data => {
      return !selectIds.includes(data.id);
    })
  };
};

export const slsOrdFServ02SetWorkspaceVisible = (state, { boolean }) => ({
  ...state,
  workspaceIsVisible: boolean
});

export const slsOrdFServ02ExportSuccess = state => ({
  ...state,
  timestamp: Date.now(),
  selectedDocuments: []
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [SlsOrdFServ02Types.SLS_ORD_F_SERV02_SHOW_SYNC_SETTING_LOADING]: slsOrdFServ02ShowSyncSettingLoading,
  [SlsOrdFServ02Types.SLS_ORD_F_SERV02_SHOW_SYNC_SETTING_SUCCESS]: slsOrdFServ02ShowSyncSettingSuccess,
  [SlsOrdFServ02Types.SLS_ORD_F_SERV02_SHOW_BATCH_JOB_STATUS_SUCCESS]: slsOrdFServ02ShowBatchJobStatusSuccess,

  [SlsOrdFServ02Types.SLS_ORD_F_SERV02_RESET_TIMESTAMP]: slsOrdFServ02ResetTimestamp,
  [SlsOrdFServ02Types.SLS_ORD_F_SERV02_FETCH_SLS_ORD_F_SERV02_LOADING]: slsOrdFServ02FetchSlsOrdFServ02Loading,
  [SlsOrdFServ02Types.SLS_ORD_F_SERV02_FETCH_SLS_ORD_F_SERV02_SUCCESS]: slsOrdFServ02FetchSlsOrdFServ02Success,
  [SlsOrdFServ02Types.SLS_ORD_F_SERV02_ADD_SELECTED_DOCUMENTS]: slsOrdFServ02AddSelectedDocuments,
  [SlsOrdFServ02Types.SLS_ORD_F_SERV02_REMOVE_SELECTED_DOCUMENTS]: slsOrdFServ02RemoveSelectedDocuments,
  [SlsOrdFServ02Types.SLS_ORD_F_SERV02_SET_WORKSPACE_VISIBLE]: slsOrdFServ02SetWorkspaceVisible,
  [SlsOrdFServ02Types.SLS_ORD_F_SERV02_EXPORT_LOADING]: slsOrdFServ02ExportLoading,
  [SlsOrdFServ02Types.SLS_ORD_F_SERV02_EXPORT_SUCCESS]: slsOrdFServ02ExportSuccess
});
