import { put, call, select, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import { push } from 'connected-react-router';
import FileSaver from 'file-saver';
import ApiService from '../Services/ApiService';
import AppActions from '../Stores/App/Actions';
import OutbOrd01Actions, { OutbOrd01Types } from '../Stores/OutbOrd01/Actions';

const getAppStore = state => state.app;

export function* outbOrd01FetchOutbOrd01({ currentPage, sorts, filters, pageSize }) {
  try {
    yield put(OutbOrd01Actions.outbOrd01FetchOutbOrd01Loading(true));

    const processedSorts = [];
    Object.entries(sorts).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      processedSorts.push(`${key}:${value === 'ascend' ? 'ASC' : 'DESC'}`);
    });

    const processedFilters = [];
    Object.entries(filters).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      if (value) {
        processedFilters.push(`${key}:${value}`);
      }
    });

    const app = yield select(getAppStore);
    const getData = {
      page: currentPage,
      sorts: processedSorts,
      filters: processedFilters,
      pageSize
    };

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `outbOrd/indexProcess/OUTB_ORD_01/${app.curDivisionId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      // if nowCurrentPage is more than lastPage, then nowCurrentPage = lastPage
      let nowCurrentPage = result.data.current_page;
      if (nowCurrentPage > result.data.last_page) {
        nowCurrentPage = result.data.last_page;
      }

      yield put(
        OutbOrd01Actions.outbOrd01FetchOutbOrd01Success(
          result.data.data,
          nowCurrentPage,
          result.data.last_page,
          result.data.total,
          result.data.per_page
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(OutbOrd01Actions.outbOrd01FetchOutbOrd01Loading(false));
  }
}

export function* outbOrd01CreateOutbOrd01({ hdrIds }) {
  try {
    yield put(OutbOrd01Actions.outbOrd01CreateOutbOrd01Loading(true));

    const app = yield select(getAppStore);

    const postData = {
      hdrIds
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `outbOrd/createProcess/OUTB_ORD_01`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      yield put(OutbOrd01Actions.outbOrd01CreateOutbOrd01Success(result.data));

      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_SUCCESS_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(OutbOrd01Actions.outbOrd01CreateOutbOrd01Loading(false));
  }
}

export function* outbOrd01GoToDocument({ hdrId }) {
  const app = yield select(getAppStore);
  yield put(push(`${app.appPath}/slsOrdDetail/update/${hdrId}`));
}

export function* outbOrd01NewDocument() {
  const app = yield select(getAppStore);
  yield put(push(`${app.appPath}/slsOrdDetail/create/${app.curDivisionId}`));
}

export function* outbOrd01CheckStock({ hdrIds }) {
  try {
    yield put(OutbOrd01Actions.outbOrd01CreateOutbOrd01Loading(true));

    const app = yield select(getAppStore);

    const getData = {
      hdrIds
    };

    const result = yield call(
      ApiService.downloadGetApi, // function
      app.apiUrl,
      `slsOrd/checkStock`,
      app.token,
      getData,
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    );

    if (result.isSuccess === true) {
      FileSaver.saveAs(result.data, 'CHECK_STOCK.XLSX');
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(OutbOrd01Actions.outbOrd01CreateOutbOrd01Loading(false));
  }
}

export const saga = [
  takeLatest(OutbOrd01Types.OUTB_ORD01_FETCH_OUTB_ORD01, outbOrd01FetchOutbOrd01),
  takeLatest(OutbOrd01Types.OUTB_ORD01_CREATE_OUTB_ORD01, outbOrd01CreateOutbOrd01),
  takeLatest(OutbOrd01Types.OUTB_ORD01_GO_TO_DOCUMENT, outbOrd01GoToDocument),
  takeLatest(OutbOrd01Types.OUTB_ORD01_NEW_DOCUMENT, outbOrd01NewDocument),
  takeLatest(OutbOrd01Types.OUTB_ORD01_CHECK_STOCK, outbOrd01CheckStock)
];
