import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  pickingCriteriaList01ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  pickingCriteriaList01GoToDocument: ['hdrId'],
  pickingCriteriaList01GoToAudit: ['hdrId'],
  pickingCriteriaList01FetchPickingCriteriaList01: ['currentPage', 'sorts', 'filters', 'pageSize'],
  pickingCriteriaList01FetchPickingCriteriaList01Loading: ['boolean'],
  pickingCriteriaList01FetchPickingCriteriaList01Success: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],
  pickingCriteriaList01AddSelectedDocuments: ['selectedDocuments'],
  pickingCriteriaList01RemoveSelectedDocuments: ['selectedDocuments'],
  pickingCriteriaList01SetWorkspaceVisible: ['boolean']
});

export const PickingCriteriaList01Types = Types;
export default Creators;
