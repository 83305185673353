/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { StkRcptSync05Types } from './Actions';
import INITIAL_STATE from './InitialState';

export const stkRcptSync05ResetTimestamp = (state, { currentPage, sorts, filters, pageSize }) => ({
  ...state,
  currentPage: currentPage || state.currentPage,
  sorts: sorts || state.sorts,
  filters: filters || state.filters,
  pageSize: pageSize || state.pageSize,
  timestamp: Date.now()
  // selectedDocuments: INITIAL_STATE.selectedDocuments
});

export const stkRcptSync05FetchStkRcptSync05Loading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const stkRcptSync05FetchStkRcptSync05Success = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  documents,
  currentPage,
  lastPage,
  total,
  pageSize
});

export const stkRcptSync05AddSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = state.selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: [
      ...state.selectedDocuments,
      ...selectedDocuments.filter(data => {
        return !selectIds.includes(data.id);
      })
    ]
  };
};

export const stkRcptSync05RemoveSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: state.selectedDocuments.filter(data => {
      return !selectIds.includes(data.id);
    })
  };
};

export const stkRcptSync05SetWorkspaceVisible = (state, { boolean }) => ({
  ...state,
  workspaceIsVisible: boolean
});

export const stkRcptSync05SyncLoading = (state, { boolean }) => ({
  ...state,
  syncIsLoading: boolean
});

export const stkRcptSync05SyncNowSuccess = state => ({
  ...state,
  timestamp: Date.now(),
  selectedDocuments: []
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [StkRcptSync05Types.STK_RCPT_SYNC05_RESET_TIMESTAMP]: stkRcptSync05ResetTimestamp,
  [StkRcptSync05Types.STK_RCPT_SYNC05_FETCH_STK_RCPT_SYNC05_LOADING]: stkRcptSync05FetchStkRcptSync05Loading,
  [StkRcptSync05Types.STK_RCPT_SYNC05_FETCH_STK_RCPT_SYNC05_SUCCESS]: stkRcptSync05FetchStkRcptSync05Success,
  [StkRcptSync05Types.STK_RCPT_SYNC05_ADD_SELECTED_DOCUMENTS]: stkRcptSync05AddSelectedDocuments,
  [StkRcptSync05Types.STK_RCPT_SYNC05_REMOVE_SELECTED_DOCUMENTS]: stkRcptSync05RemoveSelectedDocuments,
  [StkRcptSync05Types.STK_RCPT_SYNC05_SET_WORKSPACE_VISIBLE]: stkRcptSync05SetWorkspaceVisible,
  [StkRcptSync05Types.STK_RCPT_SYNC05_SYNC_LOADING]: stkRcptSync05SyncLoading,
  [StkRcptSync05Types.STK_RCPT_SYNC05_SYNC_NOW_SUCCESS]: stkRcptSync05SyncNowSuccess
});
