import React from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';

import { UndoOutlined, PlayCircleOutlined } from '@ant-design/icons';

import { Button, Row, Col, Typography } from 'antd';
import {
  FormikTransfer,
  FormikDatePicker,
  FormikSelect,
  FormikButton
} from '../../Components/Formik';
import CountAdjAnalysisReportActions from '../../Stores/CountAdjAnalysisReport/Actions';

const { Text } = Typography;

class CriteriaForm extends React.PureComponent {
  constructor() {
    super();

    this.handleSearchCompany = this.handleSearchCompany.bind(this);
    this.handleSearchItem = this.handleSearchItem.bind(this);
    this.handleSearchItemGroup01 = this.handleSearchItemGroup01.bind(this);
    this.handleSearchItemGroup02 = this.handleSearchItemGroup02.bind(this);
    this.handleSearchItemGroup03 = this.handleSearchItemGroup03.bind(this);

    this.handleSearchStorageBin = this.handleSearchStorageBin.bind(this);
    this.handleSearchStorageRow = this.handleSearchStorageRow.bind(this);
    this.handleSearchStorageBay = this.handleSearchStorageBay.bind(this);
    this.handleSearchLocation = this.handleSearchLocation.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate() {}

  componentWillUnmount() {}

  handleSearchCompany(form, value) {
    const { fetchCompanyOptions } = this.props;

    fetchCompanyOptions(value);
  }

  handleSearchItem(form, value) {
    const { fetchItemOptions } = this.props;

    fetchItemOptions(value);
  }

  handleSearchItemGroup01(form, value) {
    const { fetchItemGroup01Options } = this.props;

    fetchItemGroup01Options(value);
  }

  handleSearchItemGroup02(form, value) {
    const { fetchItemGroup02Options } = this.props;

    fetchItemGroup02Options(value);
  }

  handleSearchItemGroup03(form, value) {
    const { fetchItemGroup03Options } = this.props;

    fetchItemGroup03Options(value);
  }

  handleSearchStorageBin(form, value) {
    const { fetchStorageBinOptions } = this.props;

    fetchStorageBinOptions(value);
  }

  handleSearchStorageRow(form, value) {
    const { fetchStorageRowOptions } = this.props;

    fetchStorageRowOptions(value);
  }

  handleSearchStorageBay(form, value) {
    const { fetchStorageBayOptions } = this.props;

    fetchStorageBayOptions(value);
  }

  handleSearchLocation(form, value) {
    const { fetchLocationOptions } = this.props;

    fetchLocationOptions(value);
  }

  render() {
    const {
      intl,
      criteria,
      reportIsLoading,
      countAdjAnalysis,
      companyOptions,
      companyIsLoading,
      itemOptions,
      itemIsLoading,
      storageBinOptions,
      storageBinIsLoading,
      storageRowOptions,
      storageRowIsLoading,
      storageBayOptions,
      storageBayIsLoading,
      locationOptions,
      locationIsLoading
    } = this.props;

    return (
      <Formik
        enableReinitialize
        initialValues={criteria}
        onSubmit={(values, formikBag) => {
          countAdjAnalysis(formikBag, values);
        }}
        validationSchema={Yup.object().shape({
          start_date: Yup.date().min(
            new Date(1969, 12, 1),
            intl.formatMessage({ id: 'start_date_is_required' })
          ),
          end_date: Yup.date().min(
            new Date(1969, 12, 1),
            intl.formatMessage({ id: 'end_date_is_required' })
          )
        })}
      >
        {({
          // values,
          // handleChange,
          // errors,
          // setFieldTouched,
          dirty,
          // touched,
          isSubmitting,
          // isValid,
          handleSubmit,
          handleReset
        }) => (
          <Form>
            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'start_date' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="start_date"
                  component={FormikDatePicker}
                  disabled={isSubmitting || reportIsLoading}
                />
              </Col>
            </Row>
            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'end_date' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="end_date"
                  component={FormikDatePicker}
                  disabled={isSubmitting || reportIsLoading}
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'company' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="company_ids_select2"
                  component={FormikSelect}
                  showArrow
                  filterOption={false}
                  onSearch={this.handleSearchCompany}
                  notFoundContent={null}
                  loading={companyIsLoading}
                  options={companyOptions}
                  disabled={isSubmitting || reportIsLoading}
                  selectMode="multiple"
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'item' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="item_ids_select2"
                  component={FormikSelect}
                  showArrow
                  filterOption={false}
                  onSearch={this.handleSearchItem}
                  notFoundContent={null}
                  loading={itemIsLoading}
                  options={itemOptions}
                  disabled={isSubmitting || reportIsLoading}
                  selectMode="multiple"
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'storage_bin' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="storage_bin_ids_select2"
                  component={FormikSelect}
                  showArrow
                  filterOption={false}
                  onSearch={this.handleSearchStorageBin}
                  notFoundContent={null}
                  loading={storageBinIsLoading}
                  options={storageBinOptions}
                  disabled={isSubmitting || reportIsLoading}
                  selectMode="multiple"
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'storage_row' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="storage_row_ids_select2"
                  component={FormikSelect}
                  showArrow
                  filterOption={false}
                  onSearch={this.handleSearchStorageRow}
                  notFoundContent={null}
                  loading={storageRowIsLoading}
                  options={storageRowOptions}
                  disabled={isSubmitting || reportIsLoading}
                  selectMode="multiple"
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'storage_bay' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="storage_bay_ids_select2"
                  component={FormikSelect}
                  showArrow
                  filterOption={false}
                  onSearch={this.handleSearchStorageBay}
                  notFoundContent={null}
                  loading={storageBayIsLoading}
                  options={storageBayOptions}
                  disabled={isSubmitting || reportIsLoading}
                  selectMode="multiple"
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={3}>
                <Text>{intl.formatMessage({ id: 'location' })}</Text>
              </Col>
              <Col span={1}>
                <Text>:</Text>
              </Col>
              <Col span={20}>
                <Field
                  name="location_ids_select2"
                  component={FormikSelect}
                  showArrow
                  filterOption={false}
                  onSearch={this.handleSearchLocation}
                  notFoundContent={null}
                  loading={locationIsLoading}
                  options={locationOptions}
                  disabled={isSubmitting || reportIsLoading}
                  selectMode="multiple"
                />
              </Col>
            </Row>

            <Row type="flex" justify="center" gutter={[8, 8]}>
              <Col span={24}>
                <Field
                  name="columns"
                  component={FormikTransfer}
                  intl={intl}
                  disabled={isSubmitting || reportIsLoading}
                />
              </Col>
            </Row>

            <Row type="flex" justify="space-between" gutter={[0, 16]}>
              <Col span={24}>
                <Button
                  disabled={!dirty}
                  loading={isSubmitting || reportIsLoading}
                  onClick={handleReset}
                  icon={<UndoOutlined />}
                  type="primary"
                >
                  {intl.formatMessage({ id: 'reset' })}
                </Button>
                <Field
                  name="submit_action"
                  value="run_report"
                  component={FormikButton}
                  loading={isSubmitting || reportIsLoading}
                  onClick={handleSubmit}
                  label={intl.formatMessage({
                    id: 'run_report'
                  })}
                  icon={<PlayCircleOutlined />}
                  type="primary"
                />
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    );
  }
}

CriteriaForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,

  // eslint-disable-next-line react/forbid-prop-types
  criteria: PropTypes.object,
  reportIsLoading: PropTypes.bool,
  countAdjAnalysis: PropTypes.func,
  fetchCompanyOptions: PropTypes.func,
  fetchItemOptions: PropTypes.func,
  fetchItemGroup01Options: PropTypes.func,
  fetchItemGroup02Options: PropTypes.func,
  fetchItemGroup03Options: PropTypes.func,
  fetchStorageBinOptions: PropTypes.func,
  fetchStorageRowOptions: PropTypes.func,
  fetchStorageBayOptions: PropTypes.func,
  fetchLocationOptions: PropTypes.func,

  companyOptions: PropTypes.arrayOf(PropTypes.object),
  companyIsLoading: PropTypes.bool,

  itemOptions: PropTypes.arrayOf(PropTypes.object),
  itemIsLoading: PropTypes.bool,

  storageBinOptions: PropTypes.arrayOf(PropTypes.object),
  storageBinIsLoading: PropTypes.bool,

  storageRowOptions: PropTypes.arrayOf(PropTypes.object),
  storageRowIsLoading: PropTypes.bool,

  storageBayOptions: PropTypes.arrayOf(PropTypes.object),
  storageBayIsLoading: PropTypes.bool,

  locationOptions: PropTypes.arrayOf(PropTypes.object),
  locationIsLoading: PropTypes.bool
};

CriteriaForm.defaultProps = {
  intl: {},

  criteria: {},
  reportIsLoading: false,
  countAdjAnalysis() {},
  fetchCompanyOptions() {},
  fetchItemOptions() {},
  fetchItemGroup01Options() {},
  fetchItemGroup02Options() {},
  fetchItemGroup03Options() {},
  fetchStorageBinOptions() {},
  fetchStorageRowOptions() {},
  fetchStorageBayOptions() {},
  fetchLocationOptions() {},

  companyOptions: [],
  companyIsLoading: false,

  itemOptions: [],
  itemIsLoading: false,

  storageBinOptions: [],
  storageBinIsLoading: false,

  storageRowOptions: [],
  storageRowIsLoading: false,

  storageBayOptions: [],
  storageBayIsLoading: false,

  locationOptions: [],
  locationIsLoading: false
};

const mapStateToProps = state => ({
  timestamp: state.countAdjAnalysisReport.timestamp,
  criteria: state.countAdjAnalysisReport.criteria,

  reportIsLoading: state.countAdjAnalysisReport.reportIsLoading,

  companyOptions: state.countAdjAnalysisReport.companyOptions,
  companyIsLoading: state.countAdjAnalysisReport.companyIsLoading,

  itemOptions: state.countAdjAnalysisReport.itemOptions,
  itemIsLoading: state.countAdjAnalysisReport.itemIsLoading,

  storageBinOptions: state.countAdjAnalysisReport.storageBinOptions,
  storageBinIsLoading: state.countAdjAnalysisReport.storageBinIsLoading,

  storageRowOptions: state.countAdjAnalysisReport.storageRowOptions,
  storageRowIsLoading: state.countAdjAnalysisReport.storageRowIsLoading,

  storageBayOptions: state.countAdjAnalysisReport.storageBayOptions,
  storageBayIsLoading: state.countAdjAnalysisReport.storageBayIsLoading,

  locationOptions: state.countAdjAnalysisReport.locationOptions,
  locationIsLoading: state.countAdjAnalysisReport.locationIsLoading
});

const mapDispatchToProps = dispatch => ({
  countAdjAnalysis: (formikBag, criteria) =>
    dispatch(
      CountAdjAnalysisReportActions.countAdjAnalysisReportCountAdjAnalysis(formikBag, criteria)
    ),

  fetchCompanyOptions: search =>
    dispatch(CountAdjAnalysisReportActions.countAdjAnalysisReportFetchCompanyOptions(search)),
  fetchItemOptions: search =>
    dispatch(CountAdjAnalysisReportActions.countAdjAnalysisReportFetchItemOptions(search)),
  fetchItemGroup01Options: search =>
    dispatch(CountAdjAnalysisReportActions.countAdjAnalysisReportFetchItemGroup01Options(search)),
  fetchItemGroup02Options: search =>
    dispatch(CountAdjAnalysisReportActions.countAdjAnalysisReportFetchItemGroup02Options(search)),
  fetchItemGroup03Options: search =>
    dispatch(CountAdjAnalysisReportActions.countAdjAnalysisReportFetchItemGroup03Options(search)),

  fetchStorageBinOptions: search =>
    dispatch(CountAdjAnalysisReportActions.countAdjAnalysisReportFetchStorageBinOptions(search)),
  fetchStorageRowOptions: search =>
    dispatch(CountAdjAnalysisReportActions.countAdjAnalysisReportFetchStorageRowOptions(search)),
  fetchStorageBayOptions: search =>
    dispatch(CountAdjAnalysisReportActions.countAdjAnalysisReportFetchStorageBayOptions(search)),
  fetchLocationOptions: search =>
    dispatch(CountAdjAnalysisReportActions.countAdjAnalysisReportFetchLocationOptions(search))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CriteriaForm));
