import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  productionProcessFetchProductionProcess: null,
  productionProcessFetchProductionProcessLoading: ['boolean'],
  productionProcessFetchProductionProcessSuccess: ['process']
});

export const ProductionProcessTypes = Types;
export default Creators;
