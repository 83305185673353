import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  storageTypeList01ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  storageTypeList01GoToDocument: ['hdrId'],
  storageTypeList01FetchStorageTypeList01: ['currentPage', 'sorts', 'filters', 'pageSize'],
  storageTypeList01FetchStorageTypeList01Loading: ['boolean'],
  storageTypeList01FetchStorageTypeList01Success: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],
  storageTypeList01AddSelectedDocuments: ['selectedDocuments'],
  storageTypeList01RemoveSelectedDocuments: ['selectedDocuments'],
  storageTypeList01SetWorkspaceVisible: ['boolean'],

  storageTypeList01UploadExcel: ['file'],
  storageTypeList01UploadLoading: ['boolean'],
  storageTypeList01DownloadExcel: null,
  storageTypeList01NewResource: null
});

export const StorageTypeList01Types = Types;
export default Creators;
