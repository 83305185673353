import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  workerPerformanceChartResetTimestamp: ['criteria'],

  workerPerformanceUpdateChartWidth: ['chartWidth'],

  workerPerformanceChartFetch: ['criteria'],
  workerPerformanceChartFetchIsLoading: ['boolean'],
  workerPerformanceChartFetchSuccess: ['data']
});

export const WorkerPerformanceChartTypes = Types;
export default Creators;
