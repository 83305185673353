import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import treeChanges from 'tree-changes';

import { Card, Tabs, Tooltip, Spin } from 'antd';

import DivisionProcessActions from '../../Stores/DivisionProcess/Actions';

import DivisionList01Table from '../DivisionList01/DivisionList01Table';

const { TabPane } = Tabs;

class DivisionProcessScreen extends React.PureComponent {
  componentDidMount() {
    const { curSiteFlowId, fetchDivisionProcess } = this.props;
    if (curSiteFlowId > 0) {
      fetchDivisionProcess();
    }
  }

  componentDidUpdate(prevProps) {
    const { curSiteFlowId, fetchDivisionProcess } = this.props;

    const { changed } = treeChanges(prevProps, this.props);

    if (changed('curSiteFlowId')) {
      if (curSiteFlowId > 0) {
        fetchDivisionProcess();
      }
    }
  }

  componentWillUnmount() {}

  render() {
    const { intl, match, process, appPath, processIsLoading } = this.props;

    return (
      <Card>
        <Spin spinning={processIsLoading}>
          <Tabs type="card" activeKey={match.params.proc_type}>
            {process.map(value => (
              <TabPane
                tab={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <Tooltip
                    placement="bottom"
                    title={intl.formatMessage({ id: `${value.proc_type.toLowerCase()}_desc` })}
                  >
                    <Link
                      style={value.proc_type !== match.params.proc_type ? { color: 'black' } : {}}
                      to={`${appPath}/divisionProcess/index/${value.proc_type}`}
                    >
                      <FormattedMessage id={`${value.proc_type.toLowerCase()}_label`} />
                    </Link>
                  </Tooltip>
                }
                key={value.proc_type}
              />
            ))}
          </Tabs>
        </Spin>

        {match.params.proc_type === 'DIVISION_LIST_01' && <DivisionList01Table match={match} />}
      </Card>
    );
  }
}

DivisionProcessScreen.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object,
  appPath: PropTypes.string,
  process: PropTypes.arrayOf(PropTypes.object),
  processIsLoading: PropTypes.bool,

  curSiteFlowId: PropTypes.number,
  fetchDivisionProcess: PropTypes.func
};

DivisionProcessScreen.defaultProps = {
  intl: {},
  match: {},
  appPath: '',
  process: [],
  processIsLoading: false,

  curSiteFlowId: 0,
  fetchDivisionProcess() {}
};

const mapStateToProps = state => ({
  appPath: state.app.appPath,
  process: state.divisionProcess.process,
  processIsLoading: state.divisionProcess.processIsLoading,

  curSiteFlowId: state.app.curSiteFlowId
});

const mapDispatchToProps = dispatch => ({
  fetchDivisionProcess: () => dispatch(DivisionProcessActions.divisionProcessFetchDivisionProcess())
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(DivisionProcessScreen));
