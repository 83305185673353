import React from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';

import { ArrowLeftOutlined, UndoOutlined, SaveOutlined } from '@ant-design/icons';

import { Button, Row, Col, Typography, Card, Radio } from 'antd';
import treeChanges from 'tree-changes';
import { FormikInput, FormikButton, FormikRadio, FormikInputNumber } from '../../Components/Formik';
import StorageTypeDetailActions from '../../Stores/StorageTypeDetail/Actions';
import AppActions from '../../Stores/App/Actions';

const { Text } = Typography;

class ModelForm extends React.PureComponent {
  constructor() {
    super();

    this.handleSearchStorageRow = this.handleSearchStorageRow.bind(this);
  }

  componentDidMount() {
    const { resId, resetTimestamp } = this.props;

    if (resId > 0) {
      resetTimestamp();
    }
  }

  componentDidUpdate(prevProps) {
    const { match, resId, resetTimestamp, initModel, showModel } = this.props;

    const { changed } = treeChanges(prevProps, this.props);

    if (changed('resId')) {
      resetTimestamp();
    }
    if (changed('timestamp')) {
      if (resId <= 0) {
        initModel(match.params.id);
      } else {
        showModel(resId);
      }
    }
  }

  componentWillUnmount() {}

  handleSearchStorageRow(form, value) {
    const { fetchStorageRowOptions } = this.props;

    fetchStorageRowOptions(value);
  }

  render() {
    const {
      intl,
      match,
      model,
      createModel,
      updateModel,
      historyGoBack,
      modelIsLoading
    } = this.props;

    const handlingTypeOptions = [
      <Radio.Button key={0} value={0}>
        {intl.formatMessage({ id: 'NULL' })}
      </Radio.Button>,
      <Radio.Button key={1} value={1}>
        {intl.formatMessage({ id: 'PALLET' })}
      </Radio.Button>,
      <Radio.Button key={2} value={2}>
        {intl.formatMessage({ id: 'CASE' })}
      </Radio.Button>,
      <Radio.Button key={3} value={3}>
        {intl.formatMessage({ id: 'TOTE_BOX' })}
      </Radio.Button>,
      <Radio.Button key={4} value={4}>
        {intl.formatMessage({ id: 'CASE_BOX' })}
      </Radio.Button>,
      <Radio.Button key={5} value={5}>
        {intl.formatMessage({ id: 'LOOSE' })}
      </Radio.Button>
    ];

    const binTypeOptions = [
      <Radio.Button key={0} value={0}>
        {intl.formatMessage({ id: 'NULL' })}
      </Radio.Button>,
      <Radio.Button key={1} value={1}>
        {intl.formatMessage({ id: 'UNLOADING_AREA' })}
      </Radio.Button>,
      <Radio.Button key={2} value={2}>
        {intl.formatMessage({ id: 'LOADING_AREA' })}
      </Radio.Button>,
      <Radio.Button key={3} value={3}>
        {intl.formatMessage({ id: 'UNLOADING_LOADING_AREA' })}
      </Radio.Button>,
      <Radio.Button key={4} value={4}>
        {intl.formatMessage({ id: 'BULK_STORAGE' })}
      </Radio.Button>,
      <Radio.Button key={5} value={5}>
        {intl.formatMessage({ id: 'PALLET_STORAGE' })}
      </Radio.Button>,
      <Radio.Button key={6} value={6}>
        {intl.formatMessage({ id: 'BROKEN_PALLET_STORAGE' })}
      </Radio.Button>,
      // <Radio.Button key={7} value={7}>
      //   {intl.formatMessage({ id: 'CASE_STORAGE' })}
      // </Radio.Button>,
      <Radio.Button key={8} value={8}>
        {intl.formatMessage({ id: 'BROKEN_CASE_STORAGE' })}
      </Radio.Button>,
      <Radio.Button key={9} value={9}>
        {intl.formatMessage({ id: 'TRANSPORT' })}
      </Radio.Button>,
      <Radio.Button key={10} value={10}>
        {intl.formatMessage({ id: 'PACKING_AREA' })}
      </Radio.Button>,
      <Radio.Button key={11} value={11}>
        {intl.formatMessage({ id: 'BAD_PALLET_STORAGE' })}
      </Radio.Button>,
      <Radio.Button key={12} value={12}>
        {intl.formatMessage({ id: 'BAD_BROKEN_CASE_STORAGE' })}
      </Radio.Button>,
      <Radio.Button key={13} value={13}>
        {intl.formatMessage({ id: 'MACHINE' })}
      </Radio.Button>
    ];

    const rackTypeOptions = [
      <Radio.Button key={0} value={0}>
        {intl.formatMessage({ id: 'NULL' })}
      </Radio.Button>,
      <Radio.Button key={1} value={1}>
        {intl.formatMessage({ id: 'OPEN_RACK' })}
      </Radio.Button>,
      <Radio.Button key={2} value={2}>
        {intl.formatMessage({ id: 'FILO' })}
      </Radio.Button>,
      <Radio.Button key={3} value={3}>
        {intl.formatMessage({ id: 'FIFO' })}
      </Radio.Button>
    ];

    const disabled = false;

    return (
      <Formik
        enableReinitialize
        initialValues={model}
        onSubmit={(values, formikBag) => {
          if (match.params.action === 'create') {
            createModel(formikBag, values);
          } else {
            updateModel(formikBag, values);
          }
        }}
        validationSchema={Yup.object().shape({
          code: Yup.string().required(intl.formatMessage({ id: 'code_is_required' })),
          desc_01: Yup.string().required(intl.formatMessage({ id: 'description_is_required' })),
          handling_type: Yup.number().moreThan(
            0,
            intl.formatMessage({ id: 'max_pallet_must_be_greater_than_0' })
          ),
          bin_type: Yup.number().moreThan(
            0,
            intl.formatMessage({ id: 'max_pallet_must_be_greater_than_0' })
          ),
          rack_type: Yup.number().moreThan(
            0,
            intl.formatMessage({ id: 'max_pallet_must_be_greater_than_0' })
          ),
          hu_max_load_length: Yup.number().moreThan(
            0,
            intl.formatMessage({ id: 'max_pallet_must_be_greater_than_0' })
          ),
          hu_max_load_width: Yup.number().moreThan(
            0,
            intl.formatMessage({ id: 'max_pallet_must_be_greater_than_0' })
          ),
          hu_max_load_height: Yup.number().moreThan(
            0,
            intl.formatMessage({ id: 'max_pallet_must_be_greater_than_0' })
          ),
          hu_max_load_weight: Yup.number().moreThan(
            0,
            intl.formatMessage({ id: 'max_pallet_must_be_greater_than_0' })
          ),
          hu_max_count: Yup.number().moreThan(
            0,
            intl.formatMessage({ id: 'max_pallet_must_be_greater_than_0' })
          )
        })}
      >
        {({
          // values,
          // handleChange,
          // errors,
          // setFieldTouched,
          dirty,
          // touched,
          isSubmitting,
          // isValid,
          handleSubmit,
          handleReset
        }) => (
          <Form>
            <Card title={intl.formatMessage({ id: 'general' })}>
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'code' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="code"
                    component={FormikInput}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'description' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="desc_01"
                    component={FormikInput}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
              </Row>
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3} />
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="desc_02"
                    component={FormikInput}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
              </Row>
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'handling_type' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="handling_type"
                    component={FormikRadio}
                    options={handlingTypeOptions}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
              </Row>
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'bin_type' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="bin_type"
                    component={FormikRadio}
                    options={binTypeOptions}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
              </Row>
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'rack_type' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="rack_type"
                    component={FormikRadio}
                    options={rackTypeOptions}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'min_load_length' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="hu_min_load_length"
                    component={FormikInputNumber}
                    precision={2}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
              </Row>
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'min_load_width' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="hu_min_load_width"
                    component={FormikInputNumber}
                    precision={2}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
              </Row>
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'min_load_height' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="hu_min_load_height"
                    component={FormikInputNumber}
                    precision={2}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
              </Row>
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'min_load_weight' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="hu_min_load_weight"
                    component={FormikInputNumber}
                    precision={2}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'max_load_length' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="hu_max_load_length"
                    component={FormikInputNumber}
                    precision={2}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
              </Row>
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'max_load_width' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="hu_max_load_width"
                    component={FormikInputNumber}
                    precision={2}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
              </Row>
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'max_load_height' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="hu_max_load_height"
                    component={FormikInputNumber}
                    precision={2}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
              </Row>
              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'max_load_weight' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="hu_max_load_weight"
                    component={FormikInputNumber}
                    precision={2}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="center" gutter={[8, 8]}>
                <Col span={3}>
                  <Text>{intl.formatMessage({ id: 'max_pallet' })}</Text>
                </Col>
                <Col span={1}>
                  <Text>:</Text>
                </Col>
                <Col span={20}>
                  <Field
                    name="hu_max_count"
                    component={FormikInputNumber}
                    disabled={disabled || modelIsLoading}
                  />
                </Col>
              </Row>
            </Card>
            <Row type="flex" justify="space-between" gutter={[0, 16]}>
              <Col span={6}>
                <Button
                  type="primary"
                  // disabled={!isValid}
                  loading={isSubmitting}
                  onClick={historyGoBack}
                  icon={<ArrowLeftOutlined />}
                >
                  {intl.formatMessage({
                    id: 'back'
                  })}
                </Button>
              </Col>
              <Col span={5}>
                <Button
                  type="primary"
                  disabled={!dirty}
                  loading={isSubmitting || modelIsLoading}
                  onClick={handleReset}
                  icon={<UndoOutlined />}
                >
                  {intl.formatMessage({ id: 'reset' })}
                </Button>
                <Field
                  name="submit_action"
                  type="primary"
                  value="update"
                  component={FormikButton}
                  // disabled={values.status >= 50}
                  loading={isSubmitting || modelIsLoading}
                  onClick={handleSubmit}
                  label={intl.formatMessage({
                    id: match.params.action === 'create' ? 'create' : 'update'
                  })}
                  icon={<SaveOutlined />}
                />
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    );
  }
}

ModelForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object,
  resetTimestamp: PropTypes.func,

  initModel: PropTypes.func,
  showModel: PropTypes.func,
  createModel: PropTypes.func,
  updateModel: PropTypes.func,
  historyGoBack: PropTypes.func,
  resId: PropTypes.number,
  model: PropTypes.shape({
    status: PropTypes.number
  }),
  modelIsLoading: PropTypes.bool,

  fetchStorageRowOptions: PropTypes.func
};

ModelForm.defaultProps = {
  intl: {},
  match: {},
  resetTimestamp() {},

  initModel() {},
  showModel() {},
  createModel() {},
  updateModel() {},
  historyGoBack() {},
  resId: 0,
  model: { status: 2 },
  modelIsLoading: false,

  fetchStorageRowOptions() {}
};

const mapStateToProps = state => ({
  apiUrl: state.app.apiUrl,
  timestamp: state.storageTypeDetail.modelTimestamp,
  resId: state.storageTypeDetail.resId,
  model: state.storageTypeDetail.model,
  modelIsLoading: state.storageTypeDetail.modelIsLoading,

  storageRowIsLoading: state.storageTypeDetail.storageRowIsLoading,
  storageRowOptions: state.storageTypeDetail.storageRowOptions
});

const mapDispatchToProps = dispatch => ({
  resetTimestamp: () => dispatch(StorageTypeDetailActions.storageTypeDetailResetModelTimestamp()),
  initModel: storageTypeId =>
    dispatch(StorageTypeDetailActions.storageTypeDetailInitModel(storageTypeId)),
  showModel: resId => dispatch(StorageTypeDetailActions.storageTypeDetailShowModel(resId)),

  updateModel: (formikBag, model) =>
    dispatch(StorageTypeDetailActions.storageTypeDetailUpdateModel(formikBag, model)),
  createModel: (formikBag, model) =>
    dispatch(StorageTypeDetailActions.storageTypeDetailCreateModel(formikBag, model)),

  fetchStorageRowOptions: search =>
    dispatch(StorageTypeDetailActions.storageTypeDetailFetchStorageRowOptions(search)),

  historyGoBack: () => dispatch(AppActions.appHistoryGoBack())
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ModelForm));
