import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import treeChanges from 'tree-changes';

import { Card, Tabs, Tooltip, Spin } from 'antd';

import TrfFromVanProcessActions from '../../Stores/TrfFromVanProcess/Actions';

import InbOrd07Table from '../InbOrd07/InbOrd07Table';
import StkIssSync05Table from '../StkIssSync05/StkIssSync05Table';
import StkRcptSync05Table from '../StkRcptSync05/StkRcptSync05Table';

const { TabPane } = Tabs;

class TrfFromVanProcessScreen extends React.PureComponent {
  componentDidMount() {
    const { curDivisionId, fetchTrfFromVanProcess } = this.props;
    if (curDivisionId > 0) {
      fetchTrfFromVanProcess();
    }
  }

  componentDidUpdate(prevProps) {
    const { curDivisionId, fetchTrfFromVanProcess } = this.props;

    const { changed } = treeChanges(prevProps, this.props);

    if (changed('curDivisionId')) {
      if (curDivisionId > 0) {
        fetchTrfFromVanProcess();
      }
    }
  }

  componentWillUnmount() {}

  render() {
    const { intl, match, process, appPath, processIsLoading } = this.props;

    return (
      <Card>
        <Spin spinning={processIsLoading}>
          <Tabs type="card" activeKey={match.params.proc_type}>
            {process.map(value => (
              <TabPane
                tab={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <Tooltip
                    placement="bottom"
                    title={intl.formatMessage({ id: `${value.proc_type.toLowerCase()}_desc` })}
                  >
                    <Link
                      style={value.proc_type !== match.params.proc_type ? { color: 'black' } : {}}
                      to={`${appPath}/trfFromVanProcess/index/${value.proc_type}`}
                    >
                      <FormattedMessage id={`${value.proc_type.toLowerCase()}_label`} />
                    </Link>
                  </Tooltip>
                }
                key={value.proc_type}
              />
            ))}
          </Tabs>
        </Spin>

        {match.params.proc_type === 'INB_ORD_07' && <InbOrd07Table match={match} />}
        {match.params.proc_type === 'STK_ISS_SYNC_05' && <StkIssSync05Table match={match} />}
        {match.params.proc_type === 'STK_RCPT_SYNC_05' && <StkRcptSync05Table match={match} />}
      </Card>
    );
  }
}

TrfFromVanProcessScreen.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object,
  appPath: PropTypes.string,
  process: PropTypes.arrayOf(PropTypes.object),
  processIsLoading: PropTypes.bool,

  curDivisionId: PropTypes.number,
  fetchTrfFromVanProcess: PropTypes.func
};

TrfFromVanProcessScreen.defaultProps = {
  intl: {},
  match: {},
  appPath: '',
  process: [],
  processIsLoading: false,

  curDivisionId: 0,
  fetchTrfFromVanProcess() {}
};

const mapStateToProps = state => ({
  appPath: state.app.appPath,
  process: state.trfFromVanProcess.process,
  processIsLoading: state.trfFromVanProcess.processIsLoading,

  curDivisionId: state.app.curDivisionId
});

const mapDispatchToProps = dispatch => ({
  fetchTrfFromVanProcess: () =>
    dispatch(TrfFromVanProcessActions.trfFromVanProcessFetchTrfFromVanProcess())
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(TrfFromVanProcessScreen));
