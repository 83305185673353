import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import moment from 'moment';

import { DeleteOutlined } from '@ant-design/icons';

import { Row, Col, Modal, Table, Button, Popconfirm } from 'antd';

import InvDoc02Actions from '../../Stores/InvDoc02/Actions';

class InvDoc02Dialog extends React.PureComponent {
  constructor() {
    super();

    this.handleCancel = this.handleCancel.bind(this);
    this.useOnCreateDocument = this.useOnCreateDocument.bind(this);

    this.getSelectedDocumentColumns = this.getSelectedDocumentColumns.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate() {}

  componentWillUnmount() {}

  getSelectedDocumentColumns() {
    const { intl, removeSelectedDocuments } = this.props;

    return [
      {
        align: 'left',
        title: intl.formatMessage({ id: 'doc_code' }),
        // sort field
        dataIndex: 'doc_code',
        sorter: (a, b) => `${a.doc_code}`.localeCompare(b.doc_code),
        // filter field
        key: 'doc_code',
        render: (text, record) => (
          <>
            <b>{record.doc_code}</b>
            <br />
            {record.str_doc_status}
          </>
        )
      },
      {
        align: 'left',
        title: intl.formatMessage({ id: 'doc_date' }),
        // sort field
        dataIndex: 'doc_date',
        sorter: (a, b) => new Date(a.doc_date) - new Date(b.doc_date),
        // filter field
        key: 'doc_date',
        render: (text, record) => <>{moment(record.doc_date).format('YYYY-MM-DD')}</>
      },
      {
        align: 'right',
        title: intl.formatMessage({ id: 'case_decimal_qty' }),
        // sort field
        dataIndex: 'case_decimal_qty',
        sorter: (a, b) => a.case_decimal_qty - b.case_decimal_qty,
        // filter field
        key: 'case_decimal_qty',
        render: (text, record) => (
          <>
            <div style={{ textAlign: 'right' }}>
              {new Intl.NumberFormat([], {
                style: 'decimal',
                minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
              }).format(record.case_decimal_qty)}
            </div>
          </>
        )
      },
      {
        align: 'right',
        title: intl.formatMessage({ id: 'gross_weight' }),
        // sort field
        dataIndex: 'gross_weight',
        sorter: (a, b) => a.gross_weight - b.gross_weight,
        // filter field
        key: 'gross_weight',
        render: (text, record) => (
          <>
            <div style={{ textAlign: 'right' }}>
              {new Intl.NumberFormat([], {
                style: 'decimal',
                minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
              }).format(record.gross_weight)}
            </div>
          </>
        )
      },
      {
        align: 'right',
        title: intl.formatMessage({ id: 'cubic_meter' }),
        // sort field
        dataIndex: 'cubic_meter',
        sorter: (a, b) => a.cubic_meter - b.cubic_meter,
        // filter field
        key: 'cubic_meter',
        render: (text, record) => (
          <>
            <div style={{ textAlign: 'right' }}>
              {new Intl.NumberFormat([], {
                style: 'decimal',
                minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
              }).format(record.cubic_meter)}
            </div>
          </>
        )
      },
      {
        key: 'action',
        render: (text, record) => (
          <>
            <Popconfirm
              placement="left"
              title={intl.formatMessage({ id: 'are_you_sure_to_remove_this_document' })}
              onConfirm={() => {
                removeSelectedDocuments([record]);
              }}
              onCancel={() => {}}
              okText={intl.formatMessage({ id: 'yes' })}
              cancelText={intl.formatMessage({ id: 'cancel' })}
            >
              <Button type="dashed" icon={<DeleteOutlined />} />
            </Popconfirm>
          </>
        )
      }
    ];
  }

  getNewDocumentColumns() {
    const { intl } = this.props;

    return [
      {
        align: 'left',
        title: intl.formatMessage({ id: 'doc_code' }),
        // sort field
        dataIndex: 'doc_code',
        sorter: (a, b) => `${a.doc_code}`.localeCompare(b.doc_code),
        // filter field
        key: 'doc_code',
        render: (text, record) => (
          <>
            <b>{record.doc_code}</b>
          </>
        )
      },
      {
        align: 'left',
        title: intl.formatMessage({ id: 'doc_date' }),
        // sort field
        dataIndex: 'doc_date',
        sorter: (a, b) => new Date(a.doc_date) - new Date(b.doc_date),
        // filter field
        key: 'doc_date',
        render: (text, record) => <>{moment(record.doc_date).format('YYYY-MM-DD')}</>
      },
      {
        align: 'left',
        title: intl.formatMessage({ id: 'created_date' }),
        // sort field
        dataIndex: 'created_at',
        sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
        // filter field
        key: 'created_at',
        render: (text, record) => <>{moment(record.created_at).format('YYYY-MM-DD HH:mm:ss')}</>
      },
      {
        align: 'left',
        title: intl.formatMessage({ id: 'updated_date' }),
        // sort field
        dataIndex: 'updated_at',
        sorter: (a, b) => new Date(a.updated_at) - new Date(b.updated_at),
        // filter field
        key: 'updated_at',
        render: (text, record) => <>{moment(record.updated_at).format('YYYY-MM-DD HH:mm:ss')}</>
      }
    ];
  }

  useOnCreateDocument() {
    const { selectedDocuments, createInvDoc02 } = this.props;

    const hdrIds = selectedDocuments.map(value => {
      return value.id;
    });

    createInvDoc02(hdrIds);
  }

  handleCancel() {
    const { setWorkspaceVisible } = this.props;

    setWorkspaceVisible(false);
  }

  render() {
    const {
      intl,
      newDocuments,
      selectedDocuments,
      workspaceIsVisible,
      createIsLoading
    } = this.props;

    return (
      <>
        <Modal
          visible={workspaceIsVisible}
          title={intl.formatMessage({ id: 'create_return_receipt' })}
          // style={{top:20}}
          width="80%"
          centered
          // onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <Button
              type="primary"
              key="create"
              disabled={selectedDocuments.length === 0}
              loading={createIsLoading}
              onClick={this.useOnCreateDocument}
            >
              {intl.formatMessage({ id: 'create' })}
            </Button>
          ]}
        >
          <Row type="flex" justify="center" gutter={[0, 8]}>
            <Col span={24}>
              <Table
                // rowSelection={rowSelection}
                rowKey="id"
                pagination={false}
                columns={this.getSelectedDocumentColumns()}
                dataSource={selectedDocuments}
                loading={createIsLoading}
                bordered
                title={() => <b>{intl.formatMessage({ id: 'selected_documents' })}</b>}
              />
            </Col>
          </Row>
          <Row type="flex" justify="center" gutter={[0, 8]}>
            <Col span={24}>
              <Table
                size="small"
                // rowSelection={rowSelection}
                rowKey="id"
                pagination={false}
                columns={this.getNewDocumentColumns()}
                dataSource={newDocuments}
                loading={createIsLoading}
                bordered
                title={() => <b>{intl.formatMessage({ id: 'new_return_receipts' })}</b>}
              />
            </Col>
          </Row>
        </Modal>
      </>
    );
  }
}

InvDoc02Dialog.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  setWorkspaceVisible: PropTypes.func,
  workspaceIsVisible: PropTypes.bool,

  removeSelectedDocuments: PropTypes.func,
  newDocuments: PropTypes.arrayOf(PropTypes.object),
  selectedDocuments: PropTypes.arrayOf(PropTypes.object),

  createIsLoading: PropTypes.bool,

  createInvDoc02: PropTypes.func
};

InvDoc02Dialog.defaultProps = {
  intl: {},
  setWorkspaceVisible() {},
  workspaceIsVisible: false,

  removeSelectedDocuments() {},
  newDocuments: [],
  selectedDocuments: [],

  createIsLoading: false,

  createInvDoc02() {}
};

const mapStateToProps = state => ({
  workspaceIsVisible: state.invDoc02.workspaceIsVisible,

  selectedDocuments: state.invDoc02.selectedDocuments,
  newDocuments: state.invDoc01.newDocuments,

  createIsLoading: state.invDoc02.createIsLoading
});

const mapDispatchToProps = dispatch => ({
  setWorkspaceVisible: boolean => dispatch(InvDoc02Actions.invDoc02SetWorkspaceVisible(boolean)),

  removeSelectedDocuments: selectedDocuments =>
    dispatch(InvDoc02Actions.invDoc02RemoveSelectedDocuments(selectedDocuments)),

  createInvDoc02: hdrIds => dispatch(InvDoc02Actions.invDoc02CreateInvDoc02(hdrIds))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(InvDoc02Dialog));
