import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  storageBinDetailResetModelTimestamp: null,
  storageBinDetailSetResId: ['resId'],

  storageBinDetailInitModel: ['storageBinType'],
  storageBinDetailShowModel: ['resId'],
  storageBinDetailShowModelSuccess: ['model'],

  storageBinDetailUpdateModel: ['formikBag', 'model'],
  storageBinDetailCreateModel: ['formikBag', 'model'],
  storageBinDetailUpdateModelSuccess: ['model'],

  storageBinDetailShowModelLoading: ['boolean'],

  storageBinDetailFetchStorageRowOptions: ['search'],
  storageBinDetailFetchStorageRowOptionLoading: ['boolean'],
  storageBinDetailFetchStorageRowOptionSuccess: ['options'],

  storageBinDetailFetchStorageBayOptions: ['search'],
  storageBinDetailFetchStorageBayOptionLoading: ['boolean'],
  storageBinDetailFetchStorageBayOptionSuccess: ['options'],

  storageBinDetailFetchStorageTypeOptions: ['search'],
  storageBinDetailFetchStorageTypeOptionLoading: ['boolean'],
  storageBinDetailFetchStorageTypeOptionSuccess: ['options'],

  storageBinDetailFetchLocationOptions: ['search'],
  storageBinDetailFetchLocationOptionLoading: ['boolean'],
  storageBinDetailFetchLocationOptionSuccess: ['options']
});

export const StorageBinDetailTypes = Types;
export default Creators;
