import { put, call, select, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import { replace } from 'connected-react-router';
import FileSaver from 'file-saver';
import ApiService from '../Services/ApiService';
import UtilService from '../Services/UtilService';
import AppActions from '../Stores/App/Actions';
import ProdSheetDetailActions, { ProdSheetDetailTypes } from '../Stores/ProdSheetDetail/Actions';

const getAppStore = state => state.app;

const getProdSheetDetailStore = state => state.prodSheetDetail;

export function* prodSheetDetailInitHeader() {
  try {
    yield put(ProdSheetDetailActions.prodSheetDetailShowDocumentLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `prodSheet/initHeader/${app.curSiteFlowId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(ProdSheetDetailActions.prodSheetDetailShowHeaderSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(ProdSheetDetailActions.prodSheetDetailShowDocumentLoading(false));
  }
}

export function* prodSheetDetailShowHeader({ hdrId }) {
  try {
    yield put(ProdSheetDetailActions.prodSheetDetailShowDocumentLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `prodSheet/showHeader/${hdrId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(ProdSheetDetailActions.prodSheetDetailShowHeaderSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    // yield put(ProdSheetDetailActions.prodSheetDetailShowDocumentLoading(false));
  }
}

export function* prodSheetDetailTransitionToStatus({ formikBag, hdrId, docStatus }) {
  formikBag.setSubmitting(true);
  yield put(ProdSheetDetailActions.prodSheetDetailShowDocumentLoading(true));
  try {
    const app = yield select(getAppStore);

    const postData = {
      hdrId,
      docStatus
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      'prodSheet/transitionToStatus',
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      const prodSheetDetail = yield select(getProdSheetDetailStore);

      const {
        documentHeader: oldDocumentHeader,
        documentDetails: oldDocumentDetails
      } = prodSheetDetail;

      const {
        document_header: retDocumentHeader,
        document_details: retDocumentDetails,
        deleted_details: retDeletedDetails
      } = result.data;

      const processed = UtilService.processHeaderDetails(
        oldDocumentHeader,
        oldDocumentDetails,
        retDocumentHeader,
        retDocumentDetails,
        retDeletedDetails
      );

      yield put(
        ProdSheetDetailActions.prodSheetDetailUpdateDocumentSuccess(
          retDocumentHeader,
          processed.details
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
    yield put(ProdSheetDetailActions.prodSheetDetailShowDocumentLoading(false));
  }
}

export function* prodSheetDetailUpdateHeader({ formikBag, documentHeader }) {
  formikBag.setSubmitting(true);
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: documentHeader
    };

    const result = yield call(
      ApiService.putApi, // function
      app.apiUrl,
      'prodSheet/updateHeader',
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      const prodSheetDetail = yield select(getProdSheetDetailStore);

      const {
        documentHeader: oldDocumentHeader,
        documentDetails: oldDocumentDetails
      } = prodSheetDetail;

      const {
        document_header: retDocumentHeader,
        document_details: retDocumentDetails,
        deleted_details: retDeletedDetails
      } = result.data;

      const processed = UtilService.processHeaderDetails(
        oldDocumentHeader,
        oldDocumentDetails,
        retDocumentHeader,
        retDocumentDetails,
        retDeletedDetails
      );

      yield put(
        ProdSheetDetailActions.prodSheetDetailUpdateDocumentSuccess(
          retDocumentHeader,
          processed.details
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* prodSheetDetailShowDetails({ hdrId }) {
  try {
    yield put(ProdSheetDetailActions.prodSheetDetailShowDocumentLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `prodSheet/showDetails/${hdrId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(ProdSheetDetailActions.prodSheetDetailShowDetailsSuccess(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(ProdSheetDetailActions.prodSheetDetailShowDocumentLoading(false));
  }
}

export function* prodSheetDetailUpdateDetails({ formikBag, hdrId, documentDetails }) {
  formikBag.setSubmitting(true);
  yield put(ProdSheetDetailActions.prodSheetDetailShowDocumentLoading(true));
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: documentDetails
    };

    const result = yield call(
      ApiService.putApi, // function
      app.apiUrl,
      `prodSheet/updateDetails/${hdrId}`,
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      const prodSheetDetail = yield select(getProdSheetDetailStore);

      const {
        documentHeader: oldDocumentHeader,
        documentDetails: oldDocumentDetails
      } = prodSheetDetail;

      const {
        document_header: retDocumentHeader,
        document_details: retDocumentDetails,
        deleted_details: retDeletedDetails
      } = result.data;

      const processed = UtilService.processHeaderDetails(
        oldDocumentHeader,
        oldDocumentDetails,
        retDocumentHeader,
        retDocumentDetails,
        retDeletedDetails
      );

      yield put(
        ProdSheetDetailActions.prodSheetDetailUpdateDocumentSuccess(
          retDocumentHeader,
          processed.details
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
    yield put(ProdSheetDetailActions.prodSheetDetailShowDocumentLoading(false));
  }
}

export function* prodSheetDetailFetchCompanyOptions({ search }) {
  try {
    yield put(ProdSheetDetailActions.prodSheetDetailFetchCompanyOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `company/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({ value: d.id, label: `${d.code}` }));

      yield put(ProdSheetDetailActions.prodSheetDetailFetchCompanyOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(ProdSheetDetailActions.prodSheetDetailFetchCompanyOptionLoading(false));
  }
}

export function* prodSheetDetailFetchUserOptions({ search }) {
  try {
    yield put(ProdSheetDetailActions.prodSheetDetailFetchUserOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `user/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({ value: d.id, label: `${d.code}` }));

      yield put(ProdSheetDetailActions.prodSheetDetailFetchUserOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(ProdSheetDetailActions.prodSheetDetailFetchUserOptionLoading(false));
  }
}

export function* prodSheetDetailFetchItemOptions({ search }) {
  try {
    yield put(ProdSheetDetailActions.prodSheetDetailFetchItemOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `item/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01} ${d.unit_barcode} ${d.case_barcode}`
      }));

      yield put(ProdSheetDetailActions.prodSheetDetailFetchItemOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(ProdSheetDetailActions.prodSheetDetailFetchItemOptionLoading(false));
  }
}

export function* prodSheetDetailChangeItem({ formikBag, hdrId, itemId }) {
  try {
    formikBag.setSubmitting(true);

    const app = yield select(getAppStore);
    const postData = {
      hdrId,
      itemId
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `prodSheet/changeItem`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      Object.entries(result.data).forEach(entry => {
        const key = entry[0];
        const value = entry[1];
        formikBag.setFieldValue(key, value);
      });

      // reset the select2 cache
      formikBag.setFieldValue('uom_select2', {
        value: 0,
        label: ''
      });
      formikBag.setFieldValue('uom_rate', 1);

      yield put(ProdSheetDetailActions.prodSheetDetailFetchUomOptionSuccess([]));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* prodSheetDetailFetchItemBatchOptions({ itemId, search }) {
  try {
    yield put(ProdSheetDetailActions.prodSheetDetailFetchItemBatchOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: [
        {
          field: 'item_id',
          value: itemId
        }
      ]
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `itemBatch/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.batch_serial_no} | ${d.expiry_date} | ${d.receipt_date}`
      }));

      yield put(ProdSheetDetailActions.prodSheetDetailFetchItemBatchOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(ProdSheetDetailActions.prodSheetDetailFetchItemBatchOptionLoading(false));
  }
}

export function* prodSheetDetailChangeItemBatch({ formikBag, hdrId, itemBatchId }) {
  try {
    formikBag.setSubmitting(true);

    const app = yield select(getAppStore);
    const postData = {
      hdrId,
      itemBatchId
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `prodSheet/changeItemBatch`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      Object.entries(result.data).forEach(entry => {
        const key = entry[0];
        const value = entry[1];
        formikBag.setFieldValue(key, value);
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* prodSheetDetailFetchUomOptions({ itemId, search }) {
  try {
    yield put(ProdSheetDetailActions.prodSheetDetailFetchUomOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: [
        {
          field: 'item_id',
          value: itemId
        }
      ]
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `itemUom/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({ value: d.uom_id, label: `${d.uom_code}` }));

      yield put(ProdSheetDetailActions.prodSheetDetailFetchUomOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(ProdSheetDetailActions.prodSheetDetailFetchUomOptionLoading(false));
  }
}

export function* prodSheetDetailChangeUom({ formikBag, hdrId, itemId, uomId }) {
  try {
    formikBag.setSubmitting(true);

    const app = yield select(getAppStore);
    const postData = {
      hdrId,
      itemId,
      uomId
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `prodSheet/changeItemUom`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      Object.entries(result.data).forEach(entry => {
        const key = entry[0];
        const value = entry[1];
        formikBag.setFieldValue(key, value);
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* prodSheetDetailFetchItemCond01Options({ search }) {
  try {
    yield put(ProdSheetDetailActions.prodSheetDetailFetchItemCond01OptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `itemCond01/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({ value: d.id, label: `${d.code}` }));

      yield put(ProdSheetDetailActions.prodSheetDetailFetchItemCond01OptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(ProdSheetDetailActions.prodSheetDetailFetchItemCond01OptionLoading(false));
  }
}

export function* prodSheetDetailFetchStorageBinOptions({ search }) {
  try {
    yield put(ProdSheetDetailActions.prodSheetDetailFetchStorageBinOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId: app.curSiteFlowId,
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `storageBin/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({ value: d.id, label: `${d.code}` }));

      yield put(ProdSheetDetailActions.prodSheetDetailFetchStorageBinOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(ProdSheetDetailActions.prodSheetDetailFetchStorageBinOptionLoading(false));
  }
}

export function* prodSheetDetailFetchHandlingUnitOptions({ search }) {
  try {
    yield put(ProdSheetDetailActions.prodSheetDetailFetchHandlingUnitOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: [
        {
          field: 'site_flow_id',
          value: app.curSiteFlowId
        },
        {
          field: 'status',
          value: 4 // NEW
        },
        {
          field: 'handling_type',
          value: 1 // PALLET
        }
      ]
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `handlingUnit/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      let options = [{ value: 0, label: `` }];
      options = options.concat(result.data.data.map(d => ({ value: d.id, label: `${d.barcode}` })));

      yield put(ProdSheetDetailActions.prodSheetDetailFetchHandlingUnitOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(ProdSheetDetailActions.prodSheetDetailFetchHandlingUnitOptionLoading(false));
  }
}

export function* prodSheetDetailCreateDetail({ formikBag, hdrId, documentDetail }) {
  formikBag.setSubmitting(true);
  yield put(ProdSheetDetailActions.prodSheetDetailShowDocumentLoading(true));
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: documentDetail
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `prodSheet/createDetail/${hdrId}`,
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      const prodSheetDetail = yield select(getProdSheetDetailStore);

      const {
        documentHeader: oldDocumentHeader,
        documentDetails: oldDocumentDetails
      } = prodSheetDetail;

      const {
        document_header: retDocumentHeader,
        document_details: retDocumentDetails,
        deleted_details: retDeletedDetails
      } = result.data;

      const processed = UtilService.processHeaderDetails(
        oldDocumentHeader,
        oldDocumentDetails,
        retDocumentHeader,
        retDocumentDetails,
        retDeletedDetails
      );

      yield put(
        ProdSheetDetailActions.prodSheetDetailUpdateDocumentSuccess(
          retDocumentHeader,
          processed.details
        )
      );

      yield put(
        ProdSheetDetailActions.prodSheetDetailSetDocumentDetail(prodSheetDetail.initDocumentDetail)
      );

      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_SUCCESS_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
    yield put(ProdSheetDetailActions.prodSheetDetailShowDocumentLoading(false));
  }
}

export function* prodSheetDetailDeleteDetail({ hdrId, documentDetail }) {
  yield put(ProdSheetDetailActions.prodSheetDetailShowDocumentLoading(true));
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: [{ id: documentDetail.id }]
    };

    const result = yield call(
      ApiService.deleteApi, // function
      app.apiUrl,
      `prodSheet/deleteDetails/${hdrId}`,
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      const prodSheetDetail = yield select(getProdSheetDetailStore);

      const {
        documentHeader: oldDocumentHeader,
        documentDetails: oldDocumentDetails
      } = prodSheetDetail;

      const {
        document_header: retDocumentHeader,
        document_details: retDocumentDetails,
        deleted_details: retDeletedDetails
      } = result.data;

      const processed = UtilService.processHeaderDetails(
        oldDocumentHeader,
        oldDocumentDetails,
        retDocumentHeader,
        retDocumentDetails,
        retDeletedDetails
      );

      yield put(
        ProdSheetDetailActions.prodSheetDetailUpdateDocumentSuccess(
          retDocumentHeader,
          processed.details
        )
      );

      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_SUCCESS_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(ProdSheetDetailActions.prodSheetDetailShowDocumentLoading(false));
  }
}

export function* prodSheetDetailCreateHeader({ formikBag, documentHeader }) {
  formikBag.setSubmitting(true);
  try {
    const app = yield select(getAppStore);

    const postData = {
      data: documentHeader
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      'prodSheet/createHeader',
      app.token,
      postData // params
    );

    if (result.isSuccess === true) {
      yield put(replace(`${app.appPath}/prodSheetDetail/update/${result.data}`));
      yield put(ProdSheetDetailActions.prodSheetDetailSetHdrId(result.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* prodSheetDetailFetchStorageRowOptions({ search }) {
  try {
    yield put(ProdSheetDetailActions.prodSheetDetailFetchStorageRowOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId: app.curSiteFlowId,
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `storageRow/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({ value: d.id, label: `${d.code} ${d.desc_01}` }));

      yield put(ProdSheetDetailActions.prodSheetDetailFetchStorageRowOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(ProdSheetDetailActions.prodSheetDetailFetchStorageRowOptionLoading(false));
  }
}

export function* prodSheetDetailFetchStorageBayOptions({ search }) {
  try {
    yield put(ProdSheetDetailActions.prodSheetDetailFetchStorageBayOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      siteFlowId: app.curSiteFlowId,
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `storageBay/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({ value: d.id, label: `${d.code} ${d.desc_01}` }));

      yield put(ProdSheetDetailActions.prodSheetDetailFetchStorageBayOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(ProdSheetDetailActions.prodSheetDetailFetchStorageBayOptionLoading(false));
  }
}

export function* prodSheetDetailFetchQuantBalOptions({ companyId, storageBinId, search }) {
  try {
    yield put(ProdSheetDetailActions.prodSheetDetailFetchQuantBalOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: [
        {
          field: 'company_id',
          value: companyId
        },
        {
          field: 'storage_bin_id',
          value: storageBinId
        }
      ]
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `quantBal/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.item_code} | ${d.handling_unit_barcode} | EXP: ${d.expiry_date} BAL: ${d.case_qty} ${d.item_case_uom_code} ${d.loose_qty} ${d.item_loose_uom_code} RSVD: (${d.reserved_case_qty} ${d.item_case_uom_code} ${d.reserved_loose_qty} ${d.item_loose_uom_code})`
      }));

      yield put(ProdSheetDetailActions.prodSheetDetailFetchQuantBalOptionSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(ProdSheetDetailActions.prodSheetDetailFetchQuantBalOptionLoading(false));
  }
}

export function* prodSheetDetailChangeQuantBal({ formikBag, hdrId, quantBalId }) {
  try {
    formikBag.setSubmitting(true);

    const app = yield select(getAppStore);
    const postData = {
      hdrId,
      quantBalId
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `prodSheet/changeQuantBal`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      Object.entries(result.data).forEach(entry => {
        const key = entry[0];
        const value = entry[1];
        formikBag.setFieldValue(key, value);
      });

      // reset the select2 cache
      formikBag.setFieldValue('uom_select2', {
        value: 0,
        label: ''
      });
      formikBag.setFieldValue('uom_rate', 1);

      yield put(ProdSheetDetailActions.prodSheetDetailFetchUomOptionSuccess([]));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
  }
}

export function* prodSheetDetailUploadExcel({ hdrId, file }) {
  try {
    yield put(ProdSheetDetailActions.prodSheetDetailUploadLoading(true));

    const app = yield select(getAppStore);

    // eslint-disable-next-line no-undef
    const postData = new FormData();
    postData.append('file', file);

    const getData = {};

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `prodSheet/uploadDetails/${hdrId}`,
      app.token,
      postData,
      getData,
      'multipart/form-data'
    );

    if (result.isSuccess === true) {
      // result.data is total
      yield put(ProdSheetDetailActions.prodSheetDetailResetTimestamp());

      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_SUCCESS_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(ProdSheetDetailActions.prodSheetDetailUploadLoading(false));
  }
}

export function* prodSheetDetailDownloadExcel({ hdrId }) {
  try {
    yield put(ProdSheetDetailActions.prodSheetDetailUploadLoading(true));

    const app = yield select(getAppStore);

    const getData = {};

    const result = yield call(
      ApiService.downloadGetApi, // function
      app.apiUrl,
      `prodSheet/downloadDetails/${hdrId}`,
      app.token,
      getData,
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    );

    if (result.isSuccess === true) {
      const prodSheetDetail = yield select(getProdSheetDetailStore);

      const { documentHeader } = prodSheetDetail;

      FileSaver.saveAs(result.data, `PROD_SHEET_${documentHeader.doc_code}.XLSX`);
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(ProdSheetDetailActions.prodSheetDetailUploadLoading(false));
  }
}

export const saga = [
  takeLatest(ProdSheetDetailTypes.PROD_SHEET_DETAIL_INIT_HEADER, prodSheetDetailInitHeader),
  takeLatest(ProdSheetDetailTypes.PROD_SHEET_DETAIL_SHOW_HEADER, prodSheetDetailShowHeader),

  takeLatest(ProdSheetDetailTypes.PROD_SHEET_DETAIL_UPDATE_HEADER, prodSheetDetailUpdateHeader),
  takeLatest(ProdSheetDetailTypes.PROD_SHEET_DETAIL_CREATE_HEADER, prodSheetDetailCreateHeader),

  takeLatest(
    ProdSheetDetailTypes.PROD_SHEET_DETAIL_TRANSITION_TO_STATUS,
    prodSheetDetailTransitionToStatus
  ),

  takeLatest(ProdSheetDetailTypes.PROD_SHEET_DETAIL_SHOW_DETAILS, prodSheetDetailShowDetails),

  takeLatest(ProdSheetDetailTypes.PROD_SHEET_DETAIL_UPDATE_DETAILS, prodSheetDetailUpdateDetails),
  takeLatest(ProdSheetDetailTypes.PROD_SHEET_DETAIL_DELETE_DETAIL, prodSheetDetailDeleteDetail),
  takeLatest(ProdSheetDetailTypes.PROD_SHEET_DETAIL_CREATE_DETAIL, prodSheetDetailCreateDetail),

  takeLatest(
    ProdSheetDetailTypes.PROD_SHEET_DETAIL_FETCH_COMPANY_OPTIONS,
    prodSheetDetailFetchCompanyOptions
  ),

  takeLatest(
    ProdSheetDetailTypes.PROD_SHEET_DETAIL_FETCH_USER_OPTIONS,
    prodSheetDetailFetchUserOptions
  ),

  takeLatest(
    ProdSheetDetailTypes.PROD_SHEET_DETAIL_FETCH_ITEM_OPTIONS,
    prodSheetDetailFetchItemOptions
  ),
  takeLatest(ProdSheetDetailTypes.PROD_SHEET_DETAIL_CHANGE_ITEM, prodSheetDetailChangeItem),

  takeLatest(
    ProdSheetDetailTypes.PROD_SHEET_DETAIL_FETCH_ITEM_BATCH_OPTIONS,
    prodSheetDetailFetchItemBatchOptions
  ),
  takeLatest(
    ProdSheetDetailTypes.PROD_SHEET_DETAIL_CHANGE_ITEM_BATCH,
    prodSheetDetailChangeItemBatch
  ),

  takeLatest(
    ProdSheetDetailTypes.PROD_SHEET_DETAIL_FETCH_UOM_OPTIONS,
    prodSheetDetailFetchUomOptions
  ),
  takeLatest(ProdSheetDetailTypes.PROD_SHEET_DETAIL_CHANGE_UOM, prodSheetDetailChangeUom),

  takeLatest(
    ProdSheetDetailTypes.PROD_SHEET_DETAIL_FETCH_ITEM_COND01_OPTIONS,
    prodSheetDetailFetchItemCond01Options
  ),

  takeLatest(
    ProdSheetDetailTypes.PROD_SHEET_DETAIL_FETCH_STORAGE_BIN_OPTIONS,
    prodSheetDetailFetchStorageBinOptions
  ),

  takeLatest(
    ProdSheetDetailTypes.PROD_SHEET_DETAIL_FETCH_HANDLING_UNIT_OPTIONS,
    prodSheetDetailFetchHandlingUnitOptions
  ),

  takeLatest(
    ProdSheetDetailTypes.PROD_SHEET_DETAIL_FETCH_STORAGE_ROW_OPTIONS,
    prodSheetDetailFetchStorageRowOptions
  ),
  takeLatest(
    ProdSheetDetailTypes.PROD_SHEET_DETAIL_FETCH_STORAGE_BAY_OPTIONS,
    prodSheetDetailFetchStorageBayOptions
  ),
  takeLatest(
    ProdSheetDetailTypes.PROD_SHEET_DETAIL_FETCH_QUANT_BAL_OPTIONS,
    prodSheetDetailFetchQuantBalOptions
  ),
  takeLatest(
    ProdSheetDetailTypes.PROD_SHEET_DETAIL_CHANGE_QUANT_BAL,
    prodSheetDetailChangeQuantBal
  ),
  takeLatest(ProdSheetDetailTypes.PROD_SHEET_DETAIL_UPLOAD_EXCEL, prodSheetDetailUploadExcel),
  takeLatest(ProdSheetDetailTypes.PROD_SHEET_DETAIL_DOWNLOAD_EXCEL, prodSheetDetailDownloadExcel)
];
