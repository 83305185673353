import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  creditorList01ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  creditorList01GoToResource: ['resId'],
  creditorList01NewResource: null,
  creditorList01FetchCreditorList01: ['currentPage', 'sorts', 'filters', 'pageSize'],
  creditorList01FetchCreditorList01Loading: ['boolean'],
  creditorList01FetchCreditorList01Success: [
    'resources',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],
  creditorList01AddSelectedResources: ['selectedResources'],
  creditorList01RemoveSelectedResources: ['selectedResources'],
  creditorList01SetWorkspaceVisible: ['boolean']
});

export const CreditorList01Types = Types;
export default Creators;
