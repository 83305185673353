import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import treeChanges from 'tree-changes';
import moment from 'moment';

import { EditOutlined, LaptopOutlined, ReloadOutlined } from '@ant-design/icons';

import { Row, Col, Table, Button, Badge, Card } from 'antd';

import AntDesignTable from '../../Components/AntDesignTable';
import GdsRcpt0101Actions from '../../Stores/GdsRcpt0101/Actions';

import GdsRcpt0101Dialog from './GdsRcpt0101Dialog';

class GdsRcpt0101Table extends React.PureComponent {
  constructor() {
    super();

    this.useOnTableChange = this.useOnTableChange.bind(this);
    this.useShowWorkspace = this.useShowWorkspace.bind(this);

    this.handleSearch = this.handleSearch.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.getDocumentColumns = this.getDocumentColumns.bind(this);
  }

  componentDidMount() {
    const { curSiteFlowId, setWorkspaceVisible, resetTimestamp } = this.props;

    if (curSiteFlowId > 0) {
      setWorkspaceVisible(false);
      resetTimestamp();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      curSiteFlowId,
      resetTimestamp,
      fetchGdsRcpt0101,
      currentPage,
      sorts,
      filters,
      pageSize
    } = this.props;

    const { changed } = treeChanges(prevProps, this.props);

    if (changed('curSiteFlowId')) {
      resetTimestamp();
    }

    if (changed('timestamp')) {
      if (curSiteFlowId > 0) {
        fetchGdsRcpt0101(currentPage, sorts, filters, pageSize);
      }
    }
  }

  componentWillUnmount() {}

  handleSearch(selectedKeys, confirm) {
    // eslint-disable-next-line no-unused-vars
    const { intl } = this.props;

    confirm();
  }

  handleReset(clearFilters) {
    // eslint-disable-next-line no-unused-vars
    const { intl } = this.props;

    clearFilters();
  }

  getDocumentColumns() {
    const { intl, sorts, filters, goToDocument } = this.props;

    return [
      {
        align: 'left',
        title: intl.formatMessage({ id: 'doc_code' }),
        // sort field
        dataIndex: 'doc_code',
        ...AntDesignTable.getColumnSortProps(sorts, 'doc_code'),
        // filter field
        key: 'doc_code',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'doc_code' }),
          'doc_code',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => (
          <>
            <strong>{record.doc_code}</strong>
            {record.ref_code_01 ? (
              <>
                <br />
                {record.ref_code_01}
              </>
            ) : (
              ''
            )}
            {record.ref_code_02 ? (
              <>
                <br />
                {record.ref_code_02}
              </>
            ) : (
              ''
            )}
            {record.ref_code_03 ? (
              <>
                <br />
                {record.ref_code_03}
              </>
            ) : (
              ''
            )}
            {record.ref_code_04 ? (
              <>
                <br />
                {record.ref_code_04}
              </>
            ) : (
              ''
            )}
            <div>{record.str_doc_status}</div>
          </>
        )
      },
      {
        align: 'left',
        title: intl.formatMessage({ id: 'doc_status' }),
        // sort field
        dataIndex: 'doc_status',
        ...AntDesignTable.getColumnSortProps(sorts, 'doc_status'),
        // filter field
        ...AntDesignTable.getColumnSearchDocStatusProps(filters, 'doc_status'),
        render: (text, record) => <>{record.str_doc_status}</>
      },
      {
        align: 'left',
        title: intl.formatMessage({ id: 'doc_date' }),
        // sort field
        dataIndex: 'doc_date',
        ...AntDesignTable.getColumnSortProps(sorts, 'doc_date'),
        // filter field
        key: 'doc_date',
        ...AntDesignTable.getColumnSearchDateProps(
          filters,
          intl.formatMessage({ id: 'doc_date' }),
          'doc_date',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{moment(record.doc_date).format('YYYY-MM-DD')}</>
      },
      {
        width: 120,
        align: 'left',
        title: intl.formatMessage({ id: 'purchaser' }),
        // sort field
        dataIndex: 'purchaser_username',
        // filter field
        key: 'purchaser',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'purchaser' }),
          'purchaser',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) =>
          record.inb_ord_hdr && <b>{record.inb_ord_hdr.purchaser_username}</b>
      },
      {
        width: 150,
        align: 'left',
        title: intl.formatMessage({ id: 'biz_partner' }),
        // sort field
        dataIndex: 'biz_partner_code',
        ...AntDesignTable.getColumnSortProps(sorts, 'biz_partner_code'),
        // filter field
        key: 'biz_partner',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'biz_partner' }),
          'biz_partner',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) =>
          record.inb_ord_hdr && (
            <>
              <b>{record.inb_ord_hdr.biz_partner_code}</b>
              <br />
              {record.inb_ord_hdr.biz_partner_company_name_01}
            </>
          )
      },
      {
        width: 150,
        // fixed: 'right',
        align: 'left',
        title: intl.formatMessage({ id: 'documents' }),
        // sort field
        dataIndex: 'inb_ord_hdr_code',
        ...AntDesignTable.getColumnSortProps(sorts, 'inb_ord_hdr_code'),
        // filter field
        key: 'inb_ord_hdr',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'documents' }),
          'inb_ord_hdr',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) =>
          record.inb_ord_hdr && (
            <div key={record.inb_ord_hdr.id} style={{ width: '100%', textAlign: 'right' }}>
              <div>
                <b>{record.inb_ord_hdr.shipping_doc_code}</b>
              </div>
              <div>
                <b>{record.inb_ord_hdr.ref_code_01}</b>
              </div>
              <div>
                {new Intl.NumberFormat([], {
                  style: 'decimal',
                  minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                  maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
                }).format(record.inb_ord_hdr.net_amt)}
              </div>
            </div>
          )
      },
      {
        key: 'action',
        render: (text, record) => (
          <>
            <Button type="dashed" icon={<EditOutlined />} onClick={() => goToDocument(record.id)} />
          </>
        )
      }
    ];
  }

  useOnTableChange(pagination, filters, sorter) {
    const { resetTimestamp } = this.props;

    const processedFilters = AntDesignTable.processFilters(filters);
    const processedSorts = AntDesignTable.processSorts(sorter);

    resetTimestamp(pagination.current, processedSorts, processedFilters, pagination.pageSize);
  }

  useShowWorkspace() {
    const { setWorkspaceVisible } = this.props;

    setWorkspaceVisible(true);
  }

  render() {
    const {
      intl,
      resetTimestamp,
      currentPage,
      pageSize,
      total,
      documents,
      fetchIsLoading,
      selectedDocuments,
      addSelectedDocuments,
      removeSelectedDocuments
    } = this.props;

    return (
      <Card
        title={intl.formatMessage({ id: 'goods_receipts' })}
        bordered={false}
        headStyle={{ textAlign: 'center' }}
        bodyStyle={{ padding: 0 }}
      >
        <Table
          size="small"
          // rowSelection={rowSelection}
          rowKey="id"
          pagination={{
            current: currentPage,
            pageSize: parseInt(pageSize, 10),
            total,
            showTotal: () => `${total} items`
          }}
          columns={this.getDocumentColumns()}
          dataSource={documents}
          loading={fetchIsLoading}
          bordered
          rowSelection={{
            selectedRowKeys: selectedDocuments.map(value => value.id),
            onChange: () => {
              // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            },
            onSelect: (record, selected) => {
              // console.log(`onSelect record: `, record, 'selected: ', selected, 'selectedRows: ', selectedRows);
              if (selected === true) {
                addSelectedDocuments([record]);
              } else {
                removeSelectedDocuments([record]);
              }
            },
            onSelectAll: (selected, selectedRows, changeRows) => {
              // console.log('onSelectAll selected: ', selected, 'selectedRows: ', selectedRows, 'changeRows: ', changeRows);
              if (selected === true) {
                addSelectedDocuments(changeRows);
              } else {
                removeSelectedDocuments(changeRows);
              }
            },
            onSelectInvert: selectedRows => {
              // console.log('onSelectInvert selectedRows: ', selectedRows);
              removeSelectedDocuments(selectedRows);
            },
            getCheckboxProps: record => ({
              // Column configuration not to be checked
              disabled: record.str_doc_status !== 'COMPLETE'
              // name: record.name,
            })
          }}
          onChange={this.useOnTableChange}
          title={() => (
            <>
              <Row type="flex" justify="space-between" gutter={[0, 16]}>
                <Col span={8}>
                  <Button
                    type="primary"
                    // disabled={!isValid}
                    loading={fetchIsLoading}
                    onClick={() => resetTimestamp()}
                    icon={<ReloadOutlined />}
                  />
                </Col>
              </Row>
            </>
          )}
        />

        <div
          style={{
            position: 'fixed',
            bottom: '50px',
            right: '50px',
            zIndex: 9999
          }}
        >
          <Badge count={selectedDocuments.length}>
            <Button
              size="large"
              type="primary"
              shape="circle"
              icon={<LaptopOutlined />}
              onClick={this.useShowWorkspace}
              loading={fetchIsLoading}
            />
          </Badge>
        </div>

        <GdsRcpt0101Dialog />
      </Card>
    );
  }
}

GdsRcpt0101Table.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  resetTimestamp: PropTypes.func,
  goToDocument: PropTypes.func,

  setWorkspaceVisible: PropTypes.func,

  addSelectedDocuments: PropTypes.func,
  removeSelectedDocuments: PropTypes.func,
  documents: PropTypes.arrayOf(PropTypes.object),
  selectedDocuments: PropTypes.arrayOf(PropTypes.object),

  sorts: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  filters: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),

  currentPage: PropTypes.number,
  pageSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  total: PropTypes.number,
  fetchIsLoading: PropTypes.bool,

  curSiteFlowId: PropTypes.number,
  fetchGdsRcpt0101: PropTypes.func
};

GdsRcpt0101Table.defaultProps = {
  intl: {},
  resetTimestamp() {},
  goToDocument() {},

  setWorkspaceVisible() {},

  addSelectedDocuments() {},
  removeSelectedDocuments() {},
  documents: [],
  selectedDocuments: [],

  sorts: {},
  filters: {},

  currentPage: 1,
  pageSize: '20',
  total: 0,
  fetchIsLoading: false,

  curSiteFlowId: 0,
  fetchGdsRcpt0101() {}
};

const mapStateToProps = state => ({
  timestamp: state.gdsRcpt0101.timestamp,
  workspaceIsVisible: state.gdsRcpt0101.workspaceIsVisible,

  documents: state.gdsRcpt0101.documents,
  selectedDocuments: state.gdsRcpt0101.selectedDocuments,

  sorts: state.gdsRcpt0101.sorts,
  filters: state.gdsRcpt0101.filters,

  currentPage: state.gdsRcpt0101.currentPage,
  pageSize: state.gdsRcpt0101.pageSize,
  total: state.gdsRcpt0101.total,
  fetchIsLoading: state.gdsRcpt0101.fetchIsLoading,

  curSiteFlowId: state.app.curSiteFlowId
});

const mapDispatchToProps = dispatch => ({
  resetTimestamp: (currentPage, sorts, filters, pageSize) =>
    dispatch(GdsRcpt0101Actions.gdsRcpt0101ResetTimestamp(currentPage, sorts, filters, pageSize)),
  goToDocument: hdrId => dispatch(GdsRcpt0101Actions.gdsRcpt0101GoToDocument(hdrId)),
  setWorkspaceVisible: boolean =>
    dispatch(GdsRcpt0101Actions.gdsRcpt0101SetWorkspaceVisible(boolean)),

  addSelectedDocuments: selectedDocuments =>
    dispatch(GdsRcpt0101Actions.gdsRcpt0101AddSelectedDocuments(selectedDocuments)),
  removeSelectedDocuments: selectedDocuments =>
    dispatch(GdsRcpt0101Actions.gdsRcpt0101RemoveSelectedDocuments(selectedDocuments)),

  fetchGdsRcpt0101: (currentPage, sorts, filters, pageSize) =>
    dispatch(GdsRcpt0101Actions.gdsRcpt0101FetchGdsRcpt0101(currentPage, sorts, filters, pageSize))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(GdsRcpt0101Table));
