/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { PrfDel01Types } from './Actions';
import INITIAL_STATE from './InitialState';

export const prfDel01FetchPrfDel01Loading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const prfDel01CreatePrfDel01Loading = (state, { boolean }) => ({
  ...state,
  createIsLoading: boolean
});

export const prfDel01SearchDocumentLoading = (state, { boolean }) => ({
  ...state,
  documentIsLoading: boolean
});

export const prfDel01SearchDocumentSuccess = (state, { options }) => ({
  ...state,
  documentOptions: options
});

export const prfDel01ScanBarcodeSuccess = (state, { outbOrdHdrs }) => ({
  ...state,
  outbOrdHdrs
});

export const prfDel01UpdateOutbOrds = (state, { outbOrdHdrs }) => ({
  ...state,
  outbOrdTimestamp: Date.now(),
  outbOrdHdrs
});

export const prfDel01CreatePrfDel01Success = (state, { newDocuments }) => ({
  ...state,
  timestamp: Date.now(),
  outbOrdHdrs: [],
  newDocuments
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [PrfDel01Types.PRF_DEL01_FETCH_PRF_DEL01_LOADING]: prfDel01FetchPrfDel01Loading,
  [PrfDel01Types.PRF_DEL01_CREATE_PRF_DEL01_LOADING]: prfDel01CreatePrfDel01Loading,
  [PrfDel01Types.PRF_DEL01_CREATE_PRF_DEL01_SUCCESS]: prfDel01CreatePrfDel01Success,
  [PrfDel01Types.PRF_DEL01_SEARCH_DOCUMENT_LOADING]: prfDel01SearchDocumentLoading,
  [PrfDel01Types.PRF_DEL01_SEARCH_DOCUMENT_SUCCESS]: prfDel01SearchDocumentSuccess,
  [PrfDel01Types.PRF_DEL01_SCAN_BARCODE_SUCCESS]: prfDel01ScanBarcodeSuccess,
  [PrfDel01Types.PRF_DEL01_UPDATE_OUTB_ORDS]: prfDel01UpdateOutbOrds
});
