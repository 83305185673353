/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { ProdIssDetailTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const prodIssDetailResetTimestamp = state => ({
  ...state,
  timestamp: Date.now()
});

export const prodIssDetailSetHdrId = (state, { hdrId, itemId }) => ({
  ...state,
  timestamp: Date.now(),
  documentIsLoading: false,
  hdrId,
  itemId,
  detailIsVisible: false,
  salesmanOptions: [],
  deliveryPointOptions: [],
  creditTermOptions: [],
  currencyOptions: []
});

export const prodIssDetailShowDocumentLoading = (state, { boolean }) => ({
  ...state,
  documentIsLoading: boolean
});

export const prodIssDetailShowHeaderSuccess = (state, { documentHeader }) => ({
  ...state,
  documentHeader
});

export const prodIssDetailUpdateDocumentSuccess = (state, { documentHeader, documentDetails }) => ({
  ...state,
  documentHeader,
  documentDetails
});

export const prodIssDetailShowDetailsSuccess = (state, { documentDetails }) => ({
  ...state,
  documentDetails
});

export const prodIssDetailSetDetailVisible = (state, { boolean }) => ({
  ...state,
  detailIsVisible: boolean
});

export const prodIssDetailSetDocumentDetail = (state, { documentDetail }) => ({
  ...state,
  documentDetail,
  dtlItemOptions: [],
  dtlUomOptions: []
});

export const prodIssDetailFetchCurrencyOptionLoading = (state, { boolean }) => ({
  ...state,
  currencyIsLoading: boolean
});

export const prodIssDetailFetchCurrencyOptionSuccess = (state, { options }) => ({
  ...state,
  currencyOptions: options
});

// force refresh the formik form
export const prodIssDetailUpdateDetails = (state, { documentDetails }) => ({
  ...state,
  documentDetail: documentDetails[0]
});

// force refresh the formik form
export const prodIssDetailCreateDetail = (state, { documentDetail }) => ({
  ...state,
  documentDetail
});

export const prodIssDetailFetchProdStorageBinOptionLoading = (state, { boolean }) => ({
  ...state,
  prodStorageBinIsLoading: boolean
});

export const prodIssDetailFetchProdStorageBinOptionSuccess = (state, { options }) => ({
  ...state,
  prodStorageBinOptions: options
});

export const prodIssDetailFetchDtlItemOptionLoading = (state, { boolean }) => ({
  ...state,
  dtlItemIsLoading: boolean
});

export const prodIssDetailFetchDtlItemOptionSuccess = (state, { options }) => ({
  ...state,
  dtlItemOptions: options
});

export const prodIssDetailFetchDtlUomOptionLoading = (state, { boolean }) => ({
  ...state,
  dtlUomIsLoading: boolean
});

export const prodIssDetailFetchDtlUomOptionSuccess = (state, { options }) => ({
  ...state,
  dtlUomOptions: options
});

export const prodIssDetailFetchDtlLocationOptionLoading = (state, { boolean }) => ({
  ...state,
  dtlLocationIsLoading: boolean
});

export const prodIssDetailFetchDtlLocationOptionSuccess = (state, { options }) => ({
  ...state,
  dtlLocationOptions: options
});

export const prodIssDetailFetchProjectOptionLoading = (state, { boolean }) => ({
  ...state,
  projectIsLoading: boolean
});

export const prodIssDetailFetchProjectOptionSuccess = (state, { options }) => ({
  ...state,
  projectOptions: options
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [ProdIssDetailTypes.PROD_ISS_DETAIL_RESET_TIMESTAMP]: prodIssDetailResetTimestamp,
  [ProdIssDetailTypes.PROD_ISS_DETAIL_SET_HDR_ID]: prodIssDetailSetHdrId,

  [ProdIssDetailTypes.PROD_ISS_DETAIL_SHOW_HEADER_SUCCESS]: prodIssDetailShowHeaderSuccess,

  [ProdIssDetailTypes.PROD_ISS_DETAIL_UPDATE_DOCUMENT_SUCCESS]: prodIssDetailUpdateDocumentSuccess,

  [ProdIssDetailTypes.PROD_ISS_DETAIL_SHOW_DOCUMENT_LOADING]: prodIssDetailShowDocumentLoading,

  [ProdIssDetailTypes.PROD_ISS_DETAIL_SHOW_DETAILS_SUCCESS]: prodIssDetailShowDetailsSuccess,

  [ProdIssDetailTypes.PROD_ISS_DETAIL_SET_DETAIL_VISIBLE]: prodIssDetailSetDetailVisible,
  [ProdIssDetailTypes.PROD_ISS_DETAIL_SET_DOCUMENT_DETAIL]: prodIssDetailSetDocumentDetail,

  [ProdIssDetailTypes.PROD_ISS_DETAIL_UPDATE_DETAILS]: prodIssDetailUpdateDetails,
  [ProdIssDetailTypes.PROD_ISS_DETAIL_CREATE_DETAIL]: prodIssDetailCreateDetail,

  [ProdIssDetailTypes.PROD_ISS_DETAIL_FETCH_CURRENCY_OPTION_LOADING]: prodIssDetailFetchCurrencyOptionLoading,
  [ProdIssDetailTypes.PROD_ISS_DETAIL_FETCH_CURRENCY_OPTION_SUCCESS]: prodIssDetailFetchCurrencyOptionSuccess,

  [ProdIssDetailTypes.PROD_ISS_DETAIL_FETCH_PROD_STORAGE_BIN_OPTION_LOADING]: prodIssDetailFetchProdStorageBinOptionLoading,
  [ProdIssDetailTypes.PROD_ISS_DETAIL_FETCH_PROD_STORAGE_BIN_OPTION_SUCCESS]: prodIssDetailFetchProdStorageBinOptionSuccess,

  [ProdIssDetailTypes.PROD_ISS_DETAIL_FETCH_DTL_ITEM_OPTION_LOADING]: prodIssDetailFetchDtlItemOptionLoading,
  [ProdIssDetailTypes.PROD_ISS_DETAIL_FETCH_DTL_ITEM_OPTION_SUCCESS]: prodIssDetailFetchDtlItemOptionSuccess,

  [ProdIssDetailTypes.PROD_ISS_DETAIL_FETCH_DTL_UOM_OPTION_LOADING]: prodIssDetailFetchDtlUomOptionLoading,
  [ProdIssDetailTypes.PROD_ISS_DETAIL_FETCH_DTL_UOM_OPTION_SUCCESS]: prodIssDetailFetchDtlUomOptionSuccess,

  [ProdIssDetailTypes.PROD_ISS_DETAIL_FETCH_DTL_LOCATION_OPTION_LOADING]: prodIssDetailFetchDtlLocationOptionLoading,
  [ProdIssDetailTypes.PROD_ISS_DETAIL_FETCH_DTL_LOCATION_OPTION_SUCCESS]: prodIssDetailFetchDtlLocationOptionSuccess,

  [ProdIssDetailTypes.PROD_ISS_DETAIL_FETCH_PROJECT_OPTION_LOADING]: prodIssDetailFetchProjectOptionLoading,
  [ProdIssDetailTypes.PROD_ISS_DETAIL_FETCH_PROJECT_OPTION_SUCCESS]: prodIssDetailFetchProjectOptionSuccess
});
