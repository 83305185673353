/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */
import { createReducer } from 'reduxsauce';
import { StkIssIndexTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const stkIssIndexResetTimestamp = (state, { currentPage, sorts, filters, pageSize }) => ({
  ...state,
  currentPage: currentPage || state.currentPage,
  sorts: sorts || state.sorts,
  filters: filters || state.filters,
  pageSize: pageSize || state.pageSize,
  timestamp: Date.now(),
  selectedDocuments: []
});

export const stkIssIndexFetchStkIssIndexLoading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const stkIssIndexFetchStkIssIndexSuccess = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  documents,
  currentPage,
  lastPage,
  total,
  pageSize
});

export const stkIssIndexAddSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = state.selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: [
      ...state.selectedDocuments,
      ...selectedDocuments.filter(data => {
        return !selectIds.includes(data.id);
      })
    ]
  };
};

export const stkIssIndexRemoveSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: state.selectedDocuments.filter(data => {
      return !selectIds.includes(data.id);
    })
  };
};

export const stkIssIndexSetWorkspaceVisible = (state, { boolean }) => ({
  ...state,
  workspaceIsVisible: boolean
});

export const stkIssIndexWorkspaceLoading = (state, { boolean }) => ({
  ...state,
  workspaceIsLoading: boolean
});

export const stkIssIndexResetStkIssFServ01Success = state => ({
  ...state,
  timestamp: Date.now(),
  selectedDocuments: []
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [StkIssIndexTypes.STK_ISS_INDEX_RESET_TIMESTAMP]: stkIssIndexResetTimestamp,
  [StkIssIndexTypes.STK_ISS_INDEX_FETCH_STK_ISS_INDEX_LOADING]: stkIssIndexFetchStkIssIndexLoading,
  [StkIssIndexTypes.STK_ISS_INDEX_FETCH_STK_ISS_INDEX_SUCCESS]: stkIssIndexFetchStkIssIndexSuccess,
  [StkIssIndexTypes.STK_ISS_INDEX_ADD_SELECTED_DOCUMENTS]: stkIssIndexAddSelectedDocuments,
  [StkIssIndexTypes.STK_ISS_INDEX_REMOVE_SELECTED_DOCUMENTS]: stkIssIndexRemoveSelectedDocuments,
  [StkIssIndexTypes.STK_ISS_INDEX_SET_WORKSPACE_VISIBLE]: stkIssIndexSetWorkspaceVisible,
  [StkIssIndexTypes.STK_ISS_INDEX_WORKSPACE_LOADING]: stkIssIndexWorkspaceLoading,
  [StkIssIndexTypes.STK_ISS_INDEX_RESET_STK_ISS_F_SERV01_SUCCESS]: stkIssIndexResetStkIssFServ01Success
});
