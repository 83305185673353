/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { SlsInvDetailTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const slsInvDetailResetTimestamp = state => ({
  ...state,
  timestamp: Date.now()
});

export const slsInvDetailSetHdrId = (state, { hdrId, itemId }) => ({
  ...state,
  timestamp: Date.now(),
  documentIsLoading: false,
  hdrId,
  itemId,
  detailIsVisible: false,
  salesmanOptions: [],
  deliveryPointOptions: [],
  creditTermOptions: [],
  currencyOptions: []
});

export const slsInvDetailShowDocumentLoading = (state, { boolean }) => ({
  ...state,
  documentIsLoading: boolean
});

export const slsInvDetailShowHeaderSuccess = (state, { documentHeader }) => ({
  ...state,
  documentHeader
});

export const slsInvDetailUpdateDocumentSuccess = (state, { documentHeader, documentDetails }) => ({
  ...state,
  documentHeader,
  documentDetails
});

export const slsInvDetailShowDetailsSuccess = (state, { documentDetails }) => ({
  ...state,
  documentDetails
});

export const slsInvDetailSetDetailVisible = (state, { boolean }) => ({
  ...state,
  detailIsVisible: boolean
});

export const slsInvDetailSetDocumentDetail = (state, { documentDetail }) => ({
  ...state,
  documentDetail,
  itemOptions: [],
  uomOptions: []
});

export const slsInvDetailFetchSalesmanOptionLoading = (state, { boolean }) => ({
  ...state,
  salesmanIsLoading: boolean
});

export const slsInvDetailFetchSalesmanOptionSuccess = (state, { options }) => ({
  ...state,
  salesmanOptions: options
});

export const slsInvDetailFetchDeliveryPointOptionLoading = (state, { boolean }) => ({
  ...state,
  deliveryPointIsLoading: boolean
});

export const slsInvDetailFetchDeliveryPointOptionSuccess = (state, { options }) => ({
  ...state,
  deliveryPointOptions: options
});

export const slsInvDetailFetchCreditTermOptionLoading = (state, { boolean }) => ({
  ...state,
  creditTermIsLoading: boolean
});

export const slsInvDetailFetchCreditTermOptionSuccess = (state, { options }) => ({
  ...state,
  creditTermOptions: options
});

export const slsInvDetailFetchCurrencyOptionLoading = (state, { boolean }) => ({
  ...state,
  currencyIsLoading: boolean
});

export const slsInvDetailFetchCurrencyOptionSuccess = (state, { options }) => ({
  ...state,
  currencyOptions: options
});

export const slsInvDetailFetchItemOptionLoading = (state, { boolean }) => ({
  ...state,
  itemIsLoading: boolean
});

export const slsInvDetailFetchItemOptionSuccess = (state, { options }) => ({
  ...state,
  itemOptions: options
});

export const slsInvDetailFetchUomOptionLoading = (state, { boolean }) => ({
  ...state,
  uomIsLoading: boolean
});

export const slsInvDetailFetchUomOptionSuccess = (state, { options }) => ({
  ...state,
  uomOptions: options
});

// force refresh the formik form
export const slsInvDetailUpdateDetails = (state, { documentDetails }) => ({
  ...state,
  documentDetail: documentDetails[0]
});

// force refresh the formik form
export const slsInvDetailCreateDetail = (state, { documentDetail }) => ({
  ...state,
  documentDetail
});

export const slsInvDetailFetchLocationOptionLoading = (state, { boolean }) => ({
  ...state,
  locationIsLoading: boolean
});

export const slsInvDetailFetchLocationOptionSuccess = (state, { options }) => ({
  ...state,
  locationOptions: options
});

export const slsInvDetailFetchProjectOptionLoading = (state, { boolean }) => ({
  ...state,
  projectIsLoading: boolean
});

export const slsInvDetailFetchProjectOptionSuccess = (state, { options }) => ({
  ...state,
  projectOptions: options
});

export const slsInvDetailSetPickedIsVisible = (state, { boolean }) => ({
  ...state,
  pickedIsVisible: boolean,
  pickedTimestamp: boolean === true ? Date.now() : state.pickedTimestamp
});

export const slsInvDetailShowPickedLoading = (state, { boolean }) => ({
  ...state,
  pickedIsLoading: boolean
});

export const slsInvDetailShowPickedDetailsSuccess = (state, { pickedDetails }) => ({
  ...state,
  pickedDetails
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [SlsInvDetailTypes.SLS_INV_DETAIL_RESET_TIMESTAMP]: slsInvDetailResetTimestamp,
  [SlsInvDetailTypes.SLS_INV_DETAIL_SET_HDR_ID]: slsInvDetailSetHdrId,

  [SlsInvDetailTypes.SLS_INV_DETAIL_SHOW_HEADER_SUCCESS]: slsInvDetailShowHeaderSuccess,

  [SlsInvDetailTypes.SLS_INV_DETAIL_UPDATE_DOCUMENT_SUCCESS]: slsInvDetailUpdateDocumentSuccess,

  [SlsInvDetailTypes.SLS_INV_DETAIL_SHOW_DOCUMENT_LOADING]: slsInvDetailShowDocumentLoading,

  [SlsInvDetailTypes.SLS_INV_DETAIL_SHOW_DETAILS_SUCCESS]: slsInvDetailShowDetailsSuccess,

  [SlsInvDetailTypes.SLS_INV_DETAIL_SET_DETAIL_VISIBLE]: slsInvDetailSetDetailVisible,
  [SlsInvDetailTypes.SLS_INV_DETAIL_SET_DOCUMENT_DETAIL]: slsInvDetailSetDocumentDetail,

  [SlsInvDetailTypes.SLS_INV_DETAIL_UPDATE_DETAILS]: slsInvDetailUpdateDetails,
  [SlsInvDetailTypes.SLS_INV_DETAIL_CREATE_DETAIL]: slsInvDetailCreateDetail,

  [SlsInvDetailTypes.SLS_INV_DETAIL_FETCH_SALESMAN_OPTION_LOADING]: slsInvDetailFetchSalesmanOptionLoading,
  [SlsInvDetailTypes.SLS_INV_DETAIL_FETCH_SALESMAN_OPTION_SUCCESS]: slsInvDetailFetchSalesmanOptionSuccess,

  [SlsInvDetailTypes.SLS_INV_DETAIL_FETCH_DELIVERY_POINT_OPTION_LOADING]: slsInvDetailFetchDeliveryPointOptionLoading,
  [SlsInvDetailTypes.SLS_INV_DETAIL_FETCH_DELIVERY_POINT_OPTION_SUCCESS]: slsInvDetailFetchDeliveryPointOptionSuccess,

  [SlsInvDetailTypes.SLS_INV_DETAIL_FETCH_CREDIT_TERM_OPTION_LOADING]: slsInvDetailFetchCreditTermOptionLoading,
  [SlsInvDetailTypes.SLS_INV_DETAIL_FETCH_CREDIT_TERM_OPTION_SUCCESS]: slsInvDetailFetchCreditTermOptionSuccess,

  [SlsInvDetailTypes.SLS_INV_DETAIL_FETCH_CURRENCY_OPTION_LOADING]: slsInvDetailFetchCurrencyOptionLoading,
  [SlsInvDetailTypes.SLS_INV_DETAIL_FETCH_CURRENCY_OPTION_SUCCESS]: slsInvDetailFetchCurrencyOptionSuccess,

  [SlsInvDetailTypes.SLS_INV_DETAIL_FETCH_ITEM_OPTION_LOADING]: slsInvDetailFetchItemOptionLoading,
  [SlsInvDetailTypes.SLS_INV_DETAIL_FETCH_ITEM_OPTION_SUCCESS]: slsInvDetailFetchItemOptionSuccess,

  [SlsInvDetailTypes.SLS_INV_DETAIL_FETCH_UOM_OPTION_LOADING]: slsInvDetailFetchUomOptionLoading,
  [SlsInvDetailTypes.SLS_INV_DETAIL_FETCH_UOM_OPTION_SUCCESS]: slsInvDetailFetchUomOptionSuccess,

  [SlsInvDetailTypes.SLS_INV_DETAIL_FETCH_LOCATION_OPTION_LOADING]: slsInvDetailFetchLocationOptionLoading,
  [SlsInvDetailTypes.SLS_INV_DETAIL_FETCH_LOCATION_OPTION_SUCCESS]: slsInvDetailFetchLocationOptionSuccess,

  [SlsInvDetailTypes.SLS_INV_DETAIL_FETCH_PROJECT_OPTION_LOADING]: slsInvDetailFetchProjectOptionLoading,
  [SlsInvDetailTypes.SLS_INV_DETAIL_FETCH_PROJECT_OPTION_SUCCESS]: slsInvDetailFetchProjectOptionSuccess,

  [SlsInvDetailTypes.SLS_INV_DETAIL_SET_PICKED_IS_VISIBLE]: slsInvDetailSetPickedIsVisible,
  [SlsInvDetailTypes.SLS_INV_DETAIL_SHOW_PICKED_LOADING]: slsInvDetailShowPickedLoading,

  [SlsInvDetailTypes.SLS_INV_DETAIL_SHOW_PICKED_DETAILS_SUCCESS]: slsInvDetailShowPickedDetailsSuccess
});
