import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  itemBatchDetailResetModelTimestamp: null,
  itemBatchDetailSetResId: ['resId'],

  itemBatchDetailInitModel: ['itemId'],
  itemBatchDetailShowModel: ['resId'],
  itemBatchDetailShowModelSuccess: ['model'],

  itemBatchDetailUpdateModel: ['formikBag', 'model'],
  itemBatchDetailCreateModel: ['formikBag', 'model'],
  itemBatchDetailUpdateModelSuccess: ['model'],

  itemBatchDetailShowModelLoading: ['boolean']
});

export const ItemBatchDetailTypes = Types;
export default Creators;
