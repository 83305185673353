import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  prfDelDetailResetTimestamp: null,
  prfDelDetailSetHdrId: ['hdrId'],

  prfDelDetailInitHeader: null,
  prfDelDetailShowHeader: ['hdrId'],
  prfDelDetailShowHeaderSuccess: ['documentHeader'],

  prfDelDetailUpdateHeader: ['formikBag', 'documentHeader'],
  prfDelDetailCreateHeader: ['formikBag', 'documentHeader'],
  prfDelDetailUpdateDocumentSuccess: ['documentHeader', 'outbOrdHdrs'],

  prfDelDetailShowDocumentLoading: ['boolean'],
  prfDelDetailTransitionToStatus: ['formikBag', 'hdrId', 'docStatus'],

  prfDelDetailShowOutbOrdHdrs: ['hdrId'],
  prfDelDetailShowOutbOrdHdrsSuccess: ['outbOrdHdrs'],

  prfDelDetailScanBarcode: ['hdrId', 'value'],
  prfDelDetailScanBarcodeError: ['value'],

  prfDelDetailSearchDocument: ['search'],
  prfDelDetailSearchDocumentLoading: ['boolean'],
  prfDelDetailSearchDocumentSuccess: ['options'],

  prfDelDetailFetchPrfDelDetailLoading: ['boolean'],

  prfDelDetailUpOutbOrd: ['hdrId', 'outbOrdHdr'],
  prfDelDetailDownOutbOrd: ['hdrId', 'outbOrdHdr'],

  prfDelDetailDeleteDetailsByOrdHdrIds: ['hdrId', 'outbOrdHdrIds'],

  prfDelDetailShowDocPhotos: ['hdrId'],
  prfDelDetailShowDocPhotosSuccess: ['docPhotos'],
  prfDelDetailDeleteDocPhoto: ['hdrId', 'docPhotoId']
});

export const PrfDelDetailTypes = Types;
export default Creators;
