import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  stkRcptSync02ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  stkRcptSync02GoToDocument: ['hdrId'],
  stkRcptSync02FetchStkRcptSync02: ['currentPage', 'sorts', 'filters', 'pageSize'],
  stkRcptSync02FetchStkRcptSync02Loading: ['boolean'],
  stkRcptSync02FetchStkRcptSync02Success: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],
  stkRcptSync02AddSelectedDocuments: ['selectedDocuments'],
  stkRcptSync02RemoveSelectedDocuments: ['selectedDocuments'],
  stkRcptSync02SetWorkspaceVisible: ['boolean'],

  stkRcptSync02SyncNow: ['hdrIds'],
  stkRcptSync02SyncNowSuccess: null,
  stkRcptSync02SyncLoading: ['boolean']
});

export const StkRcptSync02Types = Types;
export default Creators;
