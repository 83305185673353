/**
 * The initial values for the redux state.
 */
export default {
  timestamp: 0,
  resId: 0,

  model: {
    username: '',
    email: '',
    first_name: '',
    last_name: ''
  },

  modelIsLoading: false,

  userSiteFlows: [],
  userSiteFlowIsLoading: false,

  siteFlowOption: { value: 0, label: '' },
  siteFlowOptions: [],
  siteFlowOptionIsLoading: false,

  userDivisions: [],
  userDivisionIsLoading: false,

  divisionOption: { value: 0, label: '' },
  divisionOptions: [],
  divisionOptionIsLoading: false
};
