/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { GdsIssDetailTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const gdsIssDetailResetTimestamp = state => ({
  ...state,
  timestamp: Date.now()
});

export const gdsIssDetailSetHdrId = (state, { hdrId }) => ({
  ...state,
  timestamp: Date.now(),
  documentIsLoading: false,
  hdrId,
  detailIsVisible: false
});

export const gdsIssDetailShowDocumentLoading = (state, { boolean }) => ({
  ...state,
  documentIsLoading: boolean
});

export const gdsIssDetailShowHeaderSuccess = (state, { documentHeader }) => ({
  ...state,
  documentHeader
});

export const gdsIssDetailUpdateDocumentSuccess = (state, { documentHeader, documentDetails }) => ({
  ...state,
  documentHeader,
  documentDetails
});

export const gdsIssDetailShowDetailsSuccess = (state, { documentDetails }) => ({
  ...state,
  documentDetails
});

export const gdsIssDetailSetDetailVisible = (state, { boolean }) => ({
  ...state,
  detailIsVisible: boolean
});

export const gdsIssDetailSetWhseJobType = (state, { whseJobType }) => ({
  ...state,
  whseJobType
});

export const gdsIssDetailSetDocumentDetail = (state, { documentDetail }) => ({
  ...state,
  documentDetail,
  whseJobType: documentDetail.whse_job_type,
  companyOptions: [],
  frStorageBinOptions: [],
  quantBalOptions: [],
  uomOptions: []
});

export const gdsIssDetailFetchCompanyOptionLoading = (state, { boolean }) => ({
  ...state,
  companyIsLoading: boolean
});

export const gdsIssDetailFetchCompanyOptionSuccess = (state, { options }) => ({
  ...state,
  companyOptions: options
});

export const gdsIssDetailFetchFrStorageBinOptionLoading = (state, { boolean }) => ({
  ...state,
  frStorageBinIsLoading: boolean
});

export const gdsIssDetailFetchFrStorageBinOptionSuccess = (state, { options }) => ({
  ...state,
  frStorageBinOptions: options
});

export const gdsIssDetailFetchQuantBalOptionLoading = (state, { boolean }) => ({
  ...state,
  quantBalIsLoading: boolean
});

export const gdsIssDetailFetchQuantBalOptionSuccess = (state, { options }) => ({
  ...state,
  quantBalOptions: options
});

export const gdsIssDetailFetchUomOptionLoading = (state, { boolean }) => ({
  ...state,
  uomIsLoading: boolean
});

export const gdsIssDetailFetchUomOptionSuccess = (state, { options }) => ({
  ...state,
  uomOptions: options
});

// force refresh the formik form
export const gdsIssDetailUpdateDetails = (state, { documentDetails }) => ({
  ...state,
  documentDetail: documentDetails[0]
});

// force refresh the formik form
export const gdsIssDetailCreateDetail = (state, { documentDetail }) => ({
  ...state,
  documentDetail
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [GdsIssDetailTypes.GDS_ISS_DETAIL_RESET_TIMESTAMP]: gdsIssDetailResetTimestamp,
  [GdsIssDetailTypes.GDS_ISS_DETAIL_SET_HDR_ID]: gdsIssDetailSetHdrId,

  [GdsIssDetailTypes.GDS_ISS_DETAIL_SHOW_HEADER_SUCCESS]: gdsIssDetailShowHeaderSuccess,

  [GdsIssDetailTypes.GDS_ISS_DETAIL_UPDATE_DOCUMENT_SUCCESS]: gdsIssDetailUpdateDocumentSuccess,

  [GdsIssDetailTypes.GDS_ISS_DETAIL_SHOW_DOCUMENT_LOADING]: gdsIssDetailShowDocumentLoading,

  [GdsIssDetailTypes.GDS_ISS_DETAIL_SHOW_DETAILS_SUCCESS]: gdsIssDetailShowDetailsSuccess,

  [GdsIssDetailTypes.GDS_ISS_DETAIL_SET_DETAIL_VISIBLE]: gdsIssDetailSetDetailVisible,
  [GdsIssDetailTypes.GDS_ISS_DETAIL_SET_DOCUMENT_DETAIL]: gdsIssDetailSetDocumentDetail,

  [GdsIssDetailTypes.GDS_ISS_DETAIL_UPDATE_DETAILS]: gdsIssDetailUpdateDetails,
  [GdsIssDetailTypes.GDS_ISS_DETAIL_CREATE_DETAIL]: gdsIssDetailCreateDetail,

  [GdsIssDetailTypes.GDS_ISS_DETAIL_SET_WHSE_JOB_TYPE]: gdsIssDetailSetWhseJobType,

  [GdsIssDetailTypes.GDS_ISS_DETAIL_FETCH_COMPANY_OPTION_LOADING]: gdsIssDetailFetchCompanyOptionLoading,
  [GdsIssDetailTypes.GDS_ISS_DETAIL_FETCH_COMPANY_OPTION_SUCCESS]: gdsIssDetailFetchCompanyOptionSuccess,

  [GdsIssDetailTypes.GDS_ISS_DETAIL_FETCH_FR_STORAGE_BIN_OPTION_LOADING]: gdsIssDetailFetchFrStorageBinOptionLoading,
  [GdsIssDetailTypes.GDS_ISS_DETAIL_FETCH_FR_STORAGE_BIN_OPTION_SUCCESS]: gdsIssDetailFetchFrStorageBinOptionSuccess,

  [GdsIssDetailTypes.GDS_ISS_DETAIL_FETCH_QUANT_BAL_OPTION_LOADING]: gdsIssDetailFetchQuantBalOptionLoading,
  [GdsIssDetailTypes.GDS_ISS_DETAIL_FETCH_QUANT_BAL_OPTION_SUCCESS]: gdsIssDetailFetchQuantBalOptionSuccess,

  [GdsIssDetailTypes.GDS_ISS_DETAIL_FETCH_UOM_OPTION_LOADING]: gdsIssDetailFetchUomOptionLoading,
  [GdsIssDetailTypes.GDS_ISS_DETAIL_FETCH_UOM_OPTION_SUCCESS]: gdsIssDetailFetchUomOptionSuccess
});
