import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  transferOutTrackingReportInitTransferOutTracking: null,
  transferOutTrackingReportInitTransferOutTrackingSuccess: ['criteria'],
  transferOutTrackingReportTransferOutTracking: ['formikBag', 'criteria'],
  transferOutTrackingReportTransferOutTrackingSuccess: ['criteria', 'reportData'],
  transferOutTrackingReportReportLoading: ['boolean'],

  transferOutTrackingReportFetchDivisionOptions: ['search'],
  transferOutTrackingReportFetchDivisionOptionLoading: ['boolean'],
  transferOutTrackingReportFetchDivisionOptionSuccess: ['options'],

  transferOutTrackingReportFetchToLocationOptions: ['search'],
  transferOutTrackingReportFetchToLocationOptionLoading: ['boolean'],
  transferOutTrackingReportFetchToLocationOptionSuccess: ['options'],

  transferOutTrackingReportFetchTransportOptions: ['search'],
  transferOutTrackingReportFetchTransportOptionLoading: ['boolean'],
  transferOutTrackingReportFetchTransportOptionSuccess: ['options']
});

export const TransferOutTrackingReportTypes = Types;
export default Creators;
