import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  inbOrd07ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  inbOrd07GoToDocument: ['hdrId'],
  inbOrd07NewDocument: null,
  inbOrd07FetchInbOrd07: ['currentPage', 'sorts', 'filters', 'pageSize'],
  inbOrd07FetchInbOrd07Loading: ['boolean'],
  inbOrd07FetchInbOrd07Success: ['documents', 'currentPage', 'lastPage', 'total', 'pageSize'],
  inbOrd07AddSelectedDocuments: ['selectedDocuments'],
  inbOrd07RemoveSelectedDocuments: ['selectedDocuments'],
  inbOrd07SetWorkspaceVisible: ['boolean'],
  inbOrd07CreateInbOrd07: ['hdrIds'],
  inbOrd07CreateInbOrd07Loading: ['boolean'],
  inbOrd07CreateInbOrd07Success: ['newDocuments']
});

export const InbOrd07Types = Types;
export default Creators;
