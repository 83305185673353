import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  invDoc02ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  invDoc02GoToDocument: ['hdrId'],
  invDoc02FetchInvDoc02: ['currentPage', 'sorts', 'filters', 'pageSize'],
  invDoc02FetchInvDoc02Loading: ['boolean'],
  invDoc02FetchInvDoc02Success: ['documents', 'currentPage', 'lastPage', 'total', 'pageSize'],
  invDoc02AddSelectedDocuments: ['selectedDocuments'],
  invDoc02RemoveSelectedDocuments: ['selectedDocuments'],
  invDoc02SetWorkspaceVisible: ['boolean'],
  invDoc02CreateInvDoc02: ['hdrIds'],
  invDoc02CreateInvDoc02Loading: ['boolean'],
  invDoc02CreateInvDoc02Success: ['newDocuments']
});

export const InvDoc02Types = Types;
export default Creators;
