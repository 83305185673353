/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */
import { createReducer } from 'reduxsauce';
import { ProdIssIndexTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const prodIssIndexResetTimestamp = (state, { currentPage, sorts, filters, pageSize }) => ({
  ...state,
  currentPage: currentPage || state.currentPage,
  sorts: sorts || state.sorts,
  filters: filters || state.filters,
  pageSize: pageSize || state.pageSize,
  timestamp: Date.now()
});

export const prodIssIndexFetchProdIssIndexLoading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const prodIssIndexFetchProdIssIndexSuccess = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  documents,
  currentPage,
  lastPage,
  total,
  pageSize
});

export const prodIssIndexAddSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = state.selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: [
      ...state.selectedDocuments,
      ...selectedDocuments.filter(data => {
        return !selectIds.includes(data.id);
      })
    ]
  };
};

export const prodIssIndexRemoveSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: state.selectedDocuments.filter(data => {
      return !selectIds.includes(data.id);
    })
  };
};

export const prodIssIndexSetWorkspaceVisible = (state, { boolean }) => ({
  ...state,
  workspaceIsVisible: boolean
});

export const prodIssIndexWorkspaceLoading = (state, { boolean }) => ({
  ...state,
  workspaceIsLoading: boolean
});

export const prodIssIndexResetProdIssFServ01Success = state => ({
  ...state,
  timestamp: Date.now(),
  selectedDocuments: []
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [ProdIssIndexTypes.PROD_ISS_INDEX_RESET_TIMESTAMP]: prodIssIndexResetTimestamp,
  [ProdIssIndexTypes.PROD_ISS_INDEX_FETCH_PROD_ISS_INDEX_LOADING]: prodIssIndexFetchProdIssIndexLoading,
  [ProdIssIndexTypes.PROD_ISS_INDEX_FETCH_PROD_ISS_INDEX_SUCCESS]: prodIssIndexFetchProdIssIndexSuccess,
  [ProdIssIndexTypes.PROD_ISS_INDEX_ADD_SELECTED_DOCUMENTS]: prodIssIndexAddSelectedDocuments,
  [ProdIssIndexTypes.PROD_ISS_INDEX_REMOVE_SELECTED_DOCUMENTS]: prodIssIndexRemoveSelectedDocuments,
  [ProdIssIndexTypes.PROD_ISS_INDEX_SET_WORKSPACE_VISIBLE]: prodIssIndexSetWorkspaceVisible,
  [ProdIssIndexTypes.PROD_ISS_INDEX_WORKSPACE_LOADING]: prodIssIndexWorkspaceLoading,
  [ProdIssIndexTypes.PROD_ISS_INDEX_RESET_PROD_ISS_F_SERV01_SUCCESS]: prodIssIndexResetProdIssFServ01Success
});
