/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { PutAwayRtnIndexTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const putAwayRtnIndexResetTimestamp = (
  state,
  { currentPage, sorts, filters, pageSize }
) => ({
  ...state,
  currentPage: currentPage || state.currentPage,
  sorts: sorts || state.sorts,
  filters: filters || state.filters,
  pageSize: pageSize || state.pageSize,
  timestamp: Date.now(),
  // selectedDocuments: INITIAL_STATE.selectedDocuments,
  toStorageBinOptions: INITIAL_STATE.toStorageBinOptions,
  toStorageBinOption: INITIAL_STATE.toStorageBinOption
});

export const putAwayRtnIndexFetchPutAwayRtnIndexLoading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const putAwayRtnIndexFetchPutAwayRtnIndexSuccess = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  documents,
  currentPage,
  lastPage,
  total,
  pageSize
});

export const putAwayRtnIndexAddSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = state.selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: [
      ...state.selectedDocuments,
      ...selectedDocuments.filter(data => {
        return !selectIds.includes(data.id);
      })
    ]
  };
};

export const putAwayRtnIndexRemoveSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: state.selectedDocuments.filter(data => {
      return !selectIds.includes(data.id);
    })
  };
};

export const putAwayRtnIndexSetWorkspaceVisible = (state, { boolean }) => ({
  ...state,
  workspaceIsVisible: boolean
});

export const putAwayRtnIndexWorkspaceLoading = (state, { boolean }) => ({
  ...state,
  workspaceIsLoading: boolean
});

export const putAwayRtnIndexResetPutAwaySync01Success = state => ({
  ...state,
  timestamp: Date.now(),
  selectedDocuments: []
});

export const putAwayRtnIndexResetPutAwayFServ01Success = state => ({
  ...state,
  timestamp: Date.now(),
  selectedDocuments: []
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [PutAwayRtnIndexTypes.PUT_AWAY_RTN_INDEX_RESET_TIMESTAMP]: putAwayRtnIndexResetTimestamp,
  [PutAwayRtnIndexTypes.PUT_AWAY_RTN_INDEX_FETCH_PUT_AWAY_RTN_INDEX_LOADING]: putAwayRtnIndexFetchPutAwayRtnIndexLoading,
  [PutAwayRtnIndexTypes.PUT_AWAY_RTN_INDEX_FETCH_PUT_AWAY_RTN_INDEX_SUCCESS]: putAwayRtnIndexFetchPutAwayRtnIndexSuccess,
  [PutAwayRtnIndexTypes.PUT_AWAY_RTN_INDEX_ADD_SELECTED_DOCUMENTS]: putAwayRtnIndexAddSelectedDocuments,
  [PutAwayRtnIndexTypes.PUT_AWAY_RTN_INDEX_REMOVE_SELECTED_DOCUMENTS]: putAwayRtnIndexRemoveSelectedDocuments,
  [PutAwayRtnIndexTypes.PUT_AWAY_RTN_INDEX_SET_WORKSPACE_VISIBLE]: putAwayRtnIndexSetWorkspaceVisible,

  [PutAwayRtnIndexTypes.PUT_AWAY_RTN_INDEX_WORKSPACE_LOADING]: putAwayRtnIndexWorkspaceLoading,
  [PutAwayRtnIndexTypes.PUT_AWAY_RTN_INDEX_RESET_PUT_AWAY_SYNC01_SUCCESS]: putAwayRtnIndexResetPutAwaySync01Success,
  [PutAwayRtnIndexTypes.PUT_AWAY_RTN_INDEX_RESET_PUT_AWAY_F_SERV01_SUCCESS]: putAwayRtnIndexResetPutAwayFServ01Success
});
