import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  whseJob150201ResetTimestamp: ['currentPage', 'sorts', 'filters', 'pageSize'],
  whseJob150201GoToDocument: ['hdrId'],
  whseJob150201FetchWhseJob150201: ['currentPage', 'sorts', 'filters', 'pageSize'],
  whseJob150201FetchWhseJob150201Loading: ['boolean'],
  whseJob150201FetchWhseJob150201Success: [
    'documents',
    'currentPage',
    'lastPage',
    'total',
    'pageSize'
  ],
  whseJob150201AddSelectedDocuments: ['selectedDocuments'],
  whseJob150201RemoveSelectedDocuments: ['selectedDocuments'],
  whseJob150201SetWorkspaceVisible: ['boolean'],
  whseJob150201PrintWhseJob150201: ['hdrIds'],
  whseJob150201PrintWhseJob150201Loading: ['boolean'],
  whseJob150201PrintHandlingUnit: ['hdrIds']
});

export const WhseJob150201Types = Types;
export default Creators;
