import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  binTrfProcessFetchBinTrfProcess: null,
  binTrfProcessFetchBinTrfProcessLoading: ['boolean'],
  binTrfProcessFetchBinTrfProcessSuccess: ['process']
});

export const BinTrfProcessTypes = Types;
export default Creators;
