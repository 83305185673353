import { put, call, select, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import FileSaver from 'file-saver';
import { push } from 'connected-react-router';
import ApiService from '../Services/ApiService';
import AppActions from '../Stores/App/Actions';
import StorageBinList01Actions, { StorageBinList01Types } from '../Stores/StorageBinList01/Actions';

const getAppStore = state => state.app;

export function* storageBinList01FetchStorageBinList01({ currentPage, sorts, filters, pageSize }) {
  try {
    yield put(StorageBinList01Actions.storageBinList01FetchStorageBinList01Loading(true));

    const processedSorts = [];
    Object.entries(sorts).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      processedSorts.push(`${key}:${value === 'ascend' ? 'ASC' : 'DESC'}`);
    });

    const processedFilters = [];
    Object.entries(filters).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      if (value) {
        processedFilters.push(`${key}:${value}`);
      }
    });

    const app = yield select(getAppStore);
    const getData = {
      page: currentPage,
      sorts: processedSorts,
      filters: processedFilters,
      pageSize
    };

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `storageBin/indexProcess/STORAGE_BIN_LIST_01/${app.curSiteFlowId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      // if nowCurrentPage is more than lastPage, then nowCurrentPage = lastPage
      let nowCurrentPage = result.data.current_page;
      if (nowCurrentPage > result.data.last_page) {
        nowCurrentPage = result.data.last_page;
      }

      yield put(
        StorageBinList01Actions.storageBinList01FetchStorageBinList01Success(
          result.data.data,
          nowCurrentPage,
          result.data.last_page,
          result.data.total,
          result.data.per_page
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(StorageBinList01Actions.storageBinList01FetchStorageBinList01Loading(false));
  }
}

export function* storageBinList01GoToResource({ hdrId }) {
  const app = yield select(getAppStore);
  yield put(push(`${app.appPath}/storageBinDetail/update/${hdrId}`));
}

export function* storageBinList01GoToAudit({ hdrId }) {
  const app = yield select(getAppStore);
  yield put(push(`${app.appPath}/auditResource/STORAGE_BIN/${hdrId}/StorageBin`));
}

export function* storageBinList01PrintStorageBinList01({ ids }) {
  try {
    yield put(StorageBinList01Actions.storageBinList01PrintStorageBinList01Loading(true));

    const app = yield select(getAppStore);

    const getData = {
      ids
    };

    const result = yield call(
      ApiService.downloadGetApi, // function
      app.apiUrl,
      `storageBin/printProcess/STORAGE_BIN_LIST_01/${app.curSiteFlowId}`,
      app.token,
      getData,
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    );

    if (result.isSuccess === true) {
      const selectedResources = ids.map(d => ({ id: d }));

      FileSaver.saveAs(result.data, 'StorageBinLabel.pdf');
      yield put(StorageBinList01Actions.storageBinList01RemoveSelectedResources(selectedResources));
      // yield put(StorageBinList01Actions.palletLabelList01ResetTimestamp());
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(StorageBinList01Actions.storageBinList01PrintStorageBinList01Loading(false));
  }
}

export function* storageBinList01NewResource() {
  const app = yield select(getAppStore);
  yield put(push(`${app.appPath}/storageBinDetail/create/${app.curSiteFlowId}`));
}

export const saga = [
  takeLatest(
    StorageBinList01Types.STORAGE_BIN_LIST01_FETCH_STORAGE_BIN_LIST01,
    storageBinList01FetchStorageBinList01
  ),
  takeLatest(StorageBinList01Types.STORAGE_BIN_LIST01_GO_TO_AUDIT, storageBinList01GoToAudit),
  takeLatest(StorageBinList01Types.STORAGE_BIN_LIST01_GO_TO_RESOURCE, storageBinList01GoToResource),
  takeLatest(
    StorageBinList01Types.STORAGE_BIN_LIST01_PRINT_STORAGE_BIN_LIST01,
    storageBinList01PrintStorageBinList01
  ),
  takeLatest(StorageBinList01Types.STORAGE_BIN_LIST01_NEW_RESOURCE, storageBinList01NewResource)
];
